import { GetterTree } from "vuex";
import { ResponsesState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<ResponsesState, RootState> = {
	getResponsesOrFilteredResponses: state => {
		return state.filter ? state.filteredResponses : state.responses;
	},
	getAllResponsesRetrieved: state => {
		return state.filter ? state.allFilteredResponsesRetrieved : state.allResponsesRetrieved;
	}
};
