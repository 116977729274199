import { MutationTree } from "vuex";
import { SignUpState, ContactInfoModel, CompanyInfoModel, SecurityInfoModel } from "./types";
import { getDefaultState } from "./state";
import router from "@/router"

export const mutations: MutationTree<SignUpState> = {
	setContactInfo(state, contactInfo: ContactInfoModel) {
	    state.contactInfo = contactInfo;
	},
    setSecurityInfo(state, securityInfo: SecurityInfoModel) {
	    state.securityInfo = securityInfo;
    },
	setCompanyInfo(state, companyInfo: CompanyInfoModel) {
		state.companyInfo = companyInfo;
	},
    async advanceCurrentStep(state) {
	    let currentStep = state.steps.find(s => router.currentRoute.path.includes(s.route));
        if (!currentStep) {
            currentStep = state.steps[0];
        }

        let nextStepRoute = state.steps.find(s => s.number === currentStep.number + 1).route;
        await router.push("/signup" + nextStepRoute);
    },
    setRegistrationComplete(state, isComplete) {
	    state.isComplete = isComplete;
    },
    setCustomerId(state, customerId: number) {
	    state.customerId = customerId;
    },
    setIsCreditCardRequired(state, value) {
	    state.isCreditCardRequired = value;
    },
    resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
