
    import { Datetime } from "vue-datetime";
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import vSelect3 from "vselect3";
    import moment from "moment";
    import supportEngagementApi, { SupportEngagementRequest, SupportEngagementDto } from "@/services/api.supportEngagement.service";

    interface EngagementOption {
        title: string,
        duration()
    }

    export interface SupportEngagementRow {
        id: number;
        grantedByUser: string;
        startsFrom: Date;
        expiresAt: Date;
        comment: string;
    }

    @Component({
        components: {
            "v-select-3": vSelect3,
            Datetime
        }
    })
    export default class Support extends Vue {
        @Prop({default: false, type: Boolean}) public readonly value;

        private isModalShown = false;
        private engagementDurationOptions: EngagementOption[] = [
            {
                title: "1 Hour",
                duration: () => {
                    return moment().add("hour", 1).toDate();
                }
            },
            {
                title: "1 Day",
                duration: () => {
                    return moment().add("day", 1).toDate();
                }
            },
            {
                title: "1 Week",
                duration: () => {
                    return moment().add("day", 7).toDate();
                }
            },
            {
                title: "1 Month",
                duration: () => {
                    return moment().add("month", 1).toDate();
                }
            },
            {
                title: "Custom",
                duration: () => {}
            }
        ];
        private comment: string = "";
        private customStartDate: Date | string = null;
        private customExpiryDate: Date | string = null;
        private selectedEngagementOption: EngagementOption = {
            title: "1 Hour",
            duration: () => {
                return moment().add("hour", 1).toDate();
            }
        };
        private supportEngagements: SupportEngagementRow[] = [];

        private tableFields: any[] = [
            {
                key: 'grantedByUser',
                label: 'Granted By',
                sortable: true,
            },
            {
                key: 'startsFrom',
                sortable: true
            },
            {
                key: 'expiresAt',
                sortable: true
            },
            {
                key: 'comment',
                label: 'Reason',
                sortable: true
            },
            {
                key: 'action',
                label: ''
            }
        ];

        private supportEngagementToDelete: SupportEngagementRow = null;
        private isConfirmDeleteVisible: boolean = false;
        private isLoading: boolean = false;

        private tableHeight: string = "";

        private async mounted(): Promise<void> {
            this.tableHeight = this.calculateTableHeight();
            window.addEventListener('resize', this.updateTableHeight);
        }

        private destroyed(): void {
            window.removeEventListener('resize', this.updateTableHeight);
        }

        private updateTableHeight(): void {
            this.tableHeight = this.calculateTableHeight();
        }

        private calculateTableHeight(): string {
            const minHeight = 50;
            let result = document.body.clientHeight - 1000;
            if (result < minHeight) {
                result = minHeight;
            }
            return `${result}px`;
        }

        @Watch("isModalShown")
        public onModalChange(): void {
            this.$emit("input", this.isModalShown);
            this.comment = "";
        }

        @Watch("value")
        public async onValueChanged(newValue: boolean): Promise<void> {
            this.isModalShown = newValue;
            if (this.isModalShown) {
                this.selectedEngagementOption = this.engagementDurationOptions[0];
                await this.updateSupportEngagements();
            }
        }

        @Watch("selectedSupportEngagement")
        private onSelectedSupportEngagementUpdated(): void {
            if (this.selectedEngagementOption.title === "Custom") {
                const currentDate = new Date(Date.now());
                this.customStartDate = currentDate;
                currentDate.setHours(currentDate.getHours() + 1);
                this.customExpiryDate = currentDate;
            }
        }

        public async addSupportEngagement(): Promise<void> {
			try
			{
				this.isLoading = true;
				let supportEngagementRequest: SupportEngagementRequest;
				if (this.selectedEngagementOption.title === "Custom")
				{
					supportEngagementRequest = {
							comment: this.comment,
							expiresAt: this.convertDateStringToUtcDate(this.customExpiryDate as string),
							startsFrom: this.convertDateStringToUtcDate(this.customStartDate as string),
						}
				}
				else
				{
					supportEngagementRequest = {
						comment: this.comment,
						expiresAt: this.selectedEngagementOption.duration(),
						startsFrom: new Date(Date.now())
					}
				}
				await supportEngagementApi.create(supportEngagementRequest);
				this.selectedEngagementOption = {
					title: "1 Hour",
					duration: () => {
						return moment().add("hour", 1).toDate();
					}
				};
				this.comment = "";
				await this.updateSupportEngagements();
			}
			catch
			{
				this.isLoading = false;
			}
        }

        public confirmDelete(supportEngagement: SupportEngagementRow): void {
            this.supportEngagementToDelete = supportEngagement;
            this.isConfirmDeleteVisible = true;
        }

        public cancelDelete(): void {
            this.isConfirmDeleteVisible = false;
            this.supportEngagementToDelete = null;
        }

        public async deleteSupportEngagement(): Promise<void> {
            if (!this.supportEngagementToDelete) {
                this.isConfirmDeleteVisible = false;
                return;
            }
            await supportEngagementApi.delete(this.supportEngagementToDelete.id);
            this.isConfirmDeleteVisible = false;
            await this.updateSupportEngagements();
        }

		private formatDate(date: Date): string {
            return moment(date).local().format("MMM Do YYYY, h:mm:ss A");
		}

        private async updateSupportEngagements(): Promise<void> {
            this.isLoading = true;
            try {
                const supportEngagementsResponse: SupportEngagementDto[] = await supportEngagementApi.get();
                this.supportEngagements = supportEngagementsResponse.map(s => {
                    return {
                        id: s.id,
                        startsFrom: s.startsFrom,
                        expiresAt: s.expiresAt,
                        comment: s.comment,
                        grantedByUser: s.grantedByUser
                    }
                });
            } catch {
                this.$notify({
                    type: "error",
                    title: "Error",
                    text: "Failed to load Active SureView Engineer Access periods. Please try again and contact support if the problem persists.",
                });
            }
            this.isLoading = false;
        }

        private convertDateStringToUtcDate(dateString: string): Date {
            let date = new Date(dateString);
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
                date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
        }
    }
