import { GetterTree } from "vuex";
import { UserManagementState } from "./types";
import { RootState } from "@/store/types";

export const userManagementGetters: GetterTree<UserManagementState, RootState> = {
	getPermissions: state => state.Permissions,
	getUserRoles: state => state.UserRoles,
	getUserGroupById: state => (id: number) => state.userGroups.find(ur => ur.userGroupId === id),
	getUserGroupTitle: state => (id: number) => {
		return state.userGroupDictionary[id];
	},
	getUserRoleTitle: state => (id: number) => {
		return state.userRoleDictionary[id];
	}
};
