
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";
import api from "@/services/api.ccure.service";
import { ActionPlanTask } from "@/store/tasks/types";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";

@Component({
	components: {}
})
export default class CCureTask extends Vue {
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Prop(Boolean)
	public editing: Boolean;

	@Prop()
	public task!: ActionPlanTask;

	public ccureMessageInput: string = "";

	public logTypes: any[] = [
		{ logTypeID: -1, title: "[Custom Message]" },
		{ logTypeID: 3, title: "1-Dispatch FC/PD" },
		{ logTypeID: 4, title: "1-Dispatch Facilities" },
		{ logTypeID: 5, title: "1-Contact Responsible Party" },
		{ logTypeID: 6, title: "2-Review Recorded Video" },
		{ logTypeID: 7, title: "2-Arrived / Investigating Locally" },
		{
			logTypeID: 8,
			title: "6-Disposition: Area Secure / Situation Normal"
		},
		{ logTypeID: 9, title: "6-Disposition: Situation in progress" },
		{ logTypeID: 10, title: "8-Action: Incident Report Filed" },
		{ logTypeID: 11, title: "8-Action: Task Created" }
	];
	public logType: any = this.logTypes[0];
	public logTypeAll: any = this.logTypes[0];

	public hasValidationErrors: boolean = false;
	public showErrorAnimation: boolean = false;

	public mounted() {
		if (this.editing) {
			// No validation needed so call valid task by default.
			this.onValidateTask();
		}
	}

	@Emit("validate-task")
	public onValidateTask() {
		return true;
	}

	@Emit("complete-task")
	public onCompleteTask() {
		return {
			...this.task,
			completed: true,
			result: this.task.taskText
		};
	}

	public async logToCCure() {
		// Check to see if user input should be used.
		if (this.task.taskText === "") {
			this.task.taskText = this.ccureMessageInput;
		}

		try {
			await api.postLogMessageAll(
				this.logTypeAll.logTypeID == -1 ? "" : this.logTypeAll.title,
				this.task.taskText,
				this.task.eventID
			);
		} catch (err) {
			console.error(`Could not post CCure message: ${err}`);
		}

		this.onCompleteTask();
	}

	private get featureEnabled() {
		return get(this.featuresList, ["Actions", "CCure"], false)
	}
}
