import { axiosInstance } from "@/axios.instance";

class ImmixCloudV2ApiHttpCCure {
	private axios: any;
	constructor() {
		this.axios = axiosInstance;
	}

	public async postLogMessageAll(
		predefinedMessage: string,
		freeFormMessage: string,
		eventID: number | string
	): Promise<any> {
		return this.axios.post("/CCure/Log/All", {
			PredefinedMessage: predefinedMessage,
			FreeFormMessage: freeFormMessage,
			EventID: eventID
		});
	}

	public async postLogMessage(
		eventRecordID: number | string,
		predefinedMessage: string,
		freeFormMessage: string,
		eventID: number | string
	): Promise<any> {
		return this.axios.post(`/CCure/Log/${eventRecordID}`, {
			PredefinedMessage: predefinedMessage,
			FreeFormMessage: freeFormMessage,
			EventID: eventID
		});
	}

	public async resetActions(eventRecordID: any) {
		return this.axios.get(`/CCure/ResetActions/${eventRecordID}`);
	}

	public async resetAllActions(eventID: any) {
		return this.axios.get(`/CCure/ResetAllActions/${eventID}`);
	}

	public async resetAllActionsWithRegion(eventID: any, regionID: any) {
		return this.axios.get(`/CCure/ResetAllActions/${eventID}/${regionID}`);
	}
}

const ccureapi = new ImmixCloudV2ApiHttpCCure();
export default ccureapi;
