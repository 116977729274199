
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import RulesSelector from './RulesSelector.vue';
import { isEqual, cloneDeep } from 'lodash';
import { v4 as uuidv4 } from "uuid";
import { RuleCondition, CardValidation, RuleStep, CardValidationMap } from '@/store/rules-engine/types';
import { namespace } from "vuex-class";

const RuleStore = namespace("rulesEngine");
@Component({
    components:
    {
        "rules-selector" : RulesSelector,
        "rules-logic-step-nest": () => import("./RulesLogicStep.vue")
    }
})
export default class RulesLogicStep extends Vue {
    //Props
    @Prop()
    private parentRuleCondition: RuleCondition;

    @Prop({type: Boolean, default: false})
    private allowAnd: boolean;

    @Prop({type: Boolean, default: false})
    private allowOr: boolean;

    @Prop()
    private section: string;

    @Prop({type: String, default: "Criteria"})
    private rootText: string;

    @Prop({type:Boolean, default: false})
    private isRoute: boolean

    @Prop()
    private nestedLevel: number;

    @Prop({required: false})
    private value: RuleStep;

	@Prop({ type: Boolean, default: false })
	private readOnly: boolean;

    //Variables
    private logicStep: RuleStep = null;
    private valueComponentValidation: CardValidation = { isValid: null, message: ""};
    private selectorComponentValidation: CardValidation = { isValid: null, message: ""};
    private ruleValidation: CardValidation = { isValid: false, message: ""};
    private subRulesValid: CardValidationMap = new CardValidationMap();
    private valueComponent: any = null;
	private showDeleteConfirmation: boolean = false;

    //Actions
    @RuleStore.Action private retrieveComponentForTypeId: (request: {section: string, typeId: number}) => Promise<any>;

    //Getters
    private get ruleScopeLevel(): number {
        return this.nestedLevel + 1;
    }

    private get operatorText(): string {
		if (this.section == "Action") {
			return "Action"
		} else {
			return this.isRoute ? this.rootText : this.logicStep.operator
		}
    }

    private get valueComponentSet(): boolean {
        return !!this.valueComponent?.component;
    }

    private get sidebarClass(): string {
        return this.ruleValidation?.isValid ? "border-left-green" : "border-left-red";
    }

    private get hasValueCell(): boolean {
        if(!this.logicStep || !this.logicStep.condition) {
            return true;
        }
        return this.logicStep.condition.typeId ? !!this.valueComponent?.component : true;
    }

    //Methods
    private mounted(): void {
        this.valueComponentValidation = { isValid: false, message: "" };
        this.selectorComponentValidation = { isValid: false, message: "" };
        this.$emit("isValid", { isValid: false, message: "" });
        this.updateValue();
    }

    @Emit("input")
    private addSubRule(operator: string): RuleStep {
        this.logicStep.subRules.push({id: uuidv4(), operator: operator, condition: { typeId: null, value: null}, subRules: []});
        return this.logicStep;
    }

    @Emit("deleted-step")
    private deleteRule(): RuleStep {
		// Rule is being deleted so it can now be flagged as valid to align validation
        this.$emit("isValid", { isValid: true, message: "" });
		this.showDeleteConfirmation = false;
        return this.logicStep;
    }

    @Emit("input")
    private subRuleUpdated(rule: RuleStep): RuleStep {
        var index = this.logicStep.subRules.findIndex(c => c.id == rule.id);
        if(index != null && index != undefined && index > -1){
            this.logicStep.subRules[index] = rule;
            this.$forceUpdate();
        }
        return this.logicStep;
    }

    @Emit("input")
    private subRuleDeleted(rule: RuleStep): RuleStep {
        this.logicStep.subRules = this.logicStep.subRules.filter((sr: RuleStep) => sr.id != rule.id);
        return this.logicStep;
    }

    @Watch("value")
    private updateValue(): void {
        if(!this.value) {
            this.logicStep = null;
            return;
        }

        if(!isEqual(this.logicStep, this.value)) {
            this.logicStep = cloneDeep(this.value);
        }

        this.$forceUpdate();
    }

    // Clears the condition value if the type is changed;
    @Watch("logicStep.condition.typeId")
    private conditionTypeChanged(newValue: number, oldValue: number): void {
        if(oldValue && newValue != oldValue && this.logicStep && this.logicStep.condition)
        {
            this.logicStep.condition.value = null;
            this.valueComponentValidation = { isValid: !this.hasValueCell, message: "" };
        }
        this.validateRule();
    }

    @Watch("logicStep", { deep:true })
    @Emit("input")
    private logicStepUpdate(): any {
        return this.logicStep;
    }

	@Watch("logicStep.condition.typeId")
    private async updateComponent(): Promise<void> {
        if(!this.logicStep || !this.logicStep.condition) {
            this.valueComponent = null;
        }
        this.valueComponent = null;
        this.valueComponent = await this.retrieveComponentForTypeId({section: this.section, typeId: this.logicStep.condition.typeId});
        this.$forceUpdate();
    }

    private valueComponentValueUpdated(value: any): void {
        //Implement if component value is an object
    }

	private selectorComponentValidationUpdated(value: CardValidation): void {
		this.selectorComponentValidation = value;
		this.validateRule();
	}

    private valueComponentValidationUpdated(value: CardValidation): void {
        this.valueComponentValidation = value;
        this.validateRule();
    }

    @Emit("isValid")
    private validateRule(): CardValidation {
        var isValid: boolean = false;

		// If the selector component is invalid that takes priority
		if (this.selectorComponentValidation.isValid) {
			if(this.hasValueCell) {
				isValid = this.valueComponentValidation.isValid && !!this.logicStep.condition.typeId;
			}
			else {
				isValid = !!this.logicStep.condition.typeId;
			}
		}
        this.ruleValidation.isValid = isValid;
        return this.ruleValidation;
    }

    @Emit("isValid")
    private subRuleValidationUpdated(isValid: CardValidation, ruleId: string): CardValidation {
		this.subRulesValid[ruleId] = isValid;
        let notValid = {isValid: false, message: `One or more ${this.section}s are not valid`};

        if(!this.ruleValidation.isValid)
        {
            return notValid;
        }

        for (var ruleValid in this.subRulesValid)
        {
            if(!this.subRulesValid[ruleValid].isValid)
            {
                return notValid;
            }
        }
        return { isValid: true, message: "" };
    }

    private get selectorCols(): number
    {
        if(this.nestedLevel == 1)
        {
            return 5;
        }
        else if(this.nestedLevel == 2)
        {
            return 4;
        }
        else if(this.nestedLevel == 3)
        {
            return 3;
        }
        else if (this.nestedLevel > 3 && this.nestedLevel < 8)
        {
            return 2
        }
        return 1;
    }
}
