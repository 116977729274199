import Vue from "vue";
import Router from "vue-router";

import { get } from "lodash";

import Login from "@/views/Login.vue";
import LoginSaml from "@/views/LoginSaml.vue";
import TempLogin from "@/views/TempLogin.vue";
import EventQueue from "@/views/EventQueue.vue";
import SiteMonitor from "@/views/SiteMonitor.vue";
import SiteMonitorCameras from "@/views/SiteMonitorCameras.vue";
import Cameras from "@/views/Cameras.vue";
import EventSearchPage from "@/views/EventSearchPage.vue";
import ConfigureTasks from "@/views/ConfigureTasks.vue";
import SiteAdminLanding from "@/views/SiteAdminLanding.vue";
import MobileCore from "@/views/MobileCore.vue";
import UserManagementSetup from "./views//UserSetup/UserManagementSetup.vue";
import Contacts from "./views/Contacts.vue";
import ContactRoles from "./views/ContactRoles.vue";
import Reports from "@/views/Reports.vue";
import ActivityLogManagement from "./views/ActivityLogManagement.vue";
import FeatureSetup from "@/views/FeatureSetup.vue";
import TourSetup from "./views/TourSetup.vue";
import Permissions from "./views/Permissions.vue";
import AlarmSetup from "./views/AlarmSetup.vue"
import MobileRaiseSetup from "./views/MobileRaiseSetup.vue";
import SubscriptionDetails from "./views/SubscriptionDetails.vue";
import SignUp from "@/views/SignUp/SignUp.vue";
import CompanyInfo from "@/views/SignUp/CompanyInfo.vue";
import ContactInfo from "@/views/SignUp/ContactInfo.vue";
import SecurityInfo from "@/views/SignUp/SecurityInfo.vue";
import PaymentInfo from "@/views/SignUp/PaymentInfo.vue";
import EventCategorySetup from "./views/EventCategorySetup.vue";
import SetPassword from "@/views/SetPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import AreaSetupPage from "@/views/AreaSetup/AreaSetupPage.vue";
import AssetSetup from "@/views/AssetSetup.vue";
import MapSetup from "./views/MapSetup.vue";
import RulesEngineSetup from "@/views/RulesEngineSetup.vue";
import SystemView from "@/views/SystemView.vue";
import ExternalMapLayerSetup from "@/views/ExternalMapLayerSetup.vue";
import Schedules from "@/views/Schedules.vue";
import MaskingReasonsSetup from "@/views/Masking/MaskingReasonsSetup.vue";
import NotificationTemplateSetup from "@/views/NotificationTemplateSetup.vue";

Vue.use(Router);

export default new Router({
	routes: [
		{
			path: "/",
			name: "home",
			redirect: "login",
			meta: { auth: true }
		},
		{
			path: "/login",
			name: "login",
			component: Login,
			meta: { loadFeatures: false }
		},
		{
			path: "/login/saml",
			name: "loginSaml",
			component: LoginSaml,
			meta: { loadFeatures: false }
		},
		{
			path: "/Access/:sessionID?",
			name: "singleEventLogin",
			component: TempLogin
		},
		{
			path: "/Views/Access/:sessionID",
			name: "singleEventLoginForViews",
			component: TempLogin
		},
		{
			path: "/event-queue",
			name: "event-queue",
			component: EventQueue,
			meta: {
				auth: true,
				title: "Alarm Processing",
				show: (permissions, featuresList) => permissions.canHandleAlarms,
				iconClass: "fas fa-bell",
				menuGroup: 1,
				menuOrder: 1,
			}
		},
		{
			path: "/site-monitor/:eventId",
			name: "site-monitor",
			component: SiteMonitor,
			meta: { auth: true }
		},
		{
			path: "/site-monitor-cameras",
			name: "site-monitor-cameras",
			component: SiteMonitorCameras,
			meta: { auth: true, closeOnLogout: true }
		},
		{
			path: "/activity-log",
			name: "activity-log",
			component: ActivityLogManagement,
			meta: {
				auth: true,
				title: "Activity Log",
				show: (permissions, featuresList) =>
					get(featuresList, ["ActivityLog"]) && permissions.canViewActivityLogManagement,
				iconClass: "fa fa-list-alt",
				menuGroup: 1,
				menuOrder: 3,
			}
		},
		{
			path: "/field-ops",
			name: "field-ops",
			component: () => import("@/views/FieldOps.vue"),
			meta: {
				auth: true,
				title: "Field Ops",
				iconClass: "fas fa-street-view",
				show: (permissions, featuresList) =>
					get(featuresList, ["MobileUsers"]) && (permissions.canViewFieldOps || permissions.canEditFieldOps),
				menuGroup: 1,
				menuOrder: 2,
			}
		},
		{
			path: "/cameras",
			name: "cameras",
			component: Cameras,
			meta: { auth: true }
		},
		{
			path: "/eventsearch/:eventId?",
			name: "eventsearch",
			component: EventSearchPage,
			meta: {
				auth: true,
				title: "Event Search",
				show: (permissions, featuresList) =>
					permissions.canViewEvents && get(featuresList, ["Alarms", "EventSearch"]),
				iconClass: "fas fa-search",
				menuGroup: 1,
				menuOrder: 4,
			}
		},
		{
			path: "/admin",
			name: "admin",
			component: SiteAdminLanding,
			meta: { auth: true }
		},
		{
			path: "/mobile",
			name: "mobile",
			component: MobileCore,
			meta: { auth: true, redirectOnLogin: true }
		},
		{
			path: "/reports/",
			name: "reports",
			component: Reports,
			meta: {
				auth: true,
				title: "Insight",
				show: (permissions, featuresList) => permissions.canViewReports && get(featuresList, ["Insights"]),
				iconClass: "fas fa-chart-bar",
				menuGroup: 1,
				menuOrder: 5,
			}
		},
		{
			path: "/reports/:reportType",
			name: "reports-loaded-report",
			component: Reports,
			meta: { auth: true }
		},
		{
			path: "/contacts/",
			name: "contacts",
			component: Contacts,
			meta: {
				auth: true,
				title: "Contacts",
				show: (permissions, featuresList) =>
					(permissions.canEditSiteSetup || permissions.canViewSiteSetup) && get(featuresList, ["Contacts"]),
				iconClass: "fas fa-address-book",
				menuGroup: 2,
			}
		},
		{
			path: "/devices/",
			name: "devices",
			component: () => import("@/views/Devices.vue"),
			meta: {
				auth: true,
				title: "devices",
				show: (permissions, featuresList) => permissions.canEditSiteSetup && get(featuresList, ["Devices"]),
				iconClass: "fas fa-power-off",
				menuGroup: 2,
			}
		},
		{
			path: "/device-setup/",
			name: "device-setup",
			component: () => import("@/views/DeviceSetup.vue"),
			meta: {
				auth: true,
				title: "Device Setup",
				show: (permissions, featuresList) => (permissions.isSystemAdmin || permissions.isAccountAdmin) && get(featuresList, ["Devices", "AdvancedSetup"]),
				iconClass: "fas fa-power-off",
				menuGroup: 2,
			}
		},
		{
			path: "/contact-roles/",
			name: "contact-roles",
			component: ContactRoles,
			meta: { auth: true }
		},
		{
			path: "/areas",
			name: "areas",
			component: AreaSetupPage,
			meta: {
				auth: true,
				title: "Areas",
				show: (permissions, featuresList) => get(featuresList, ["Areas"]) && permissions.canViewSiteSetup,
				iconClass: "fas fa-city",
				menuGroup: 2,
			}
		},
		{
			path: "/admin/configure-action-plans",
			name: "configure-tasks",
			component: ConfigureTasks,
			meta: {
				auth: true,
				title: "Actions",
				show: (permissions, featuresList) => permissions.canEditSettings,
				iconClass: "fas fa-clipboard-list-check",
				menuGroup: 2,
			}
		},
		{
			path: "/event-configuration",
			name: "event-configuration",
			component: () => import("@/views/EventConfigurationPage.vue"),
			meta: {
				title: "Event Types",
				show: (permissions, featuresList) =>
					get(featuresList, ["EventTypeSetup"]) && permissions.canEditSettings,
				iconClass: "fas fa-cog",
				menuGroup: 2,
			}
		},
		{
			path: "/users/",
			name: "users",
			component: UserManagementSetup,
			meta: {
				auth: true,
				title: "Users",
				show: (permissions, featuresList) =>
					(permissions.isSystemAdmin ||
							permissions.canEditUserPermissions ||
							permissions.canViewUserSetup) &&
					get(featuresList, ["Users"]),
				iconClass: "fas fa-users",
				menuGroup: 2,
			}
		},
		{
			path: "/permissions/",
			name: "permissions",
			component: Permissions,
			meta: {
				auth: true,
				title: "User Permissions",
				show: (permissions, featuresList) =>
					(permissions.isSystemAdmin || permissions.canEditUserGroups) && get(featuresList, ["UserGroups"]),
				iconClass: "fas fa-user-check",
				menuGroup: 2,
			}
		},
		{
			path: "/tours",
			name: "tours",
			component: TourSetup,
			meta: {
				auth: true,
				title: "Video Tour",
				show: (permissions, featuresList) => permissions.canEditSettings && get(featuresList, ["VideoTours"]),
				iconClass: "far fa-play-circle",
				menuGroup: 2,
			}
		},
		{
			path: "/mobile-raise-setup",
			name: "mobile-raise-setup",
			component: MobileRaiseSetup,
			meta: {
				auth: true,
				title: "Mobile Raise",
				show: (permissions, featuresList) => {
					const userHavePermission = permissions.canViewMobileRaise || permissions.canEditMobileRaise
					const featureEnabled = get(featuresList, ["Mobile", "MobileRaise"])
					return featureEnabled && userHavePermission
				},
				iconClass: "fas fa-mobile-alt",
				menuGroup: 2,
			}
		},
		{
			path: "/feature-setup/",
			name: "feature-setup",
			component: FeatureSetup,
			meta: {
				auth: true,
				title: "Features",
				show: (permissions, featuresList) => permissions.isSystemAdmin,
				iconClass: "fas fa-cubes",
				menuGroup: 2,
			}
		},
		{
			path: "/map-setup",
			name: "map-setup",
			component: MapSetup,
			meta: {
				auth: true,
				title: "Map Setup",
				show: (permissions, featuresList) => get(featuresList, ["MapSetup"]) && permissions.canEditSiteSetup,
				iconClass: "fas fa-map",
				menuGroup: 2,
			}
		},
		{
			path: "/organization",
			name: "organization",
			component: SubscriptionDetails,
			meta: {
				auth: true,
				title: "Organization Settings",
				show: (permissions, featuresList) =>
					(get(featuresList, ["Billing"]) ||
						get(featuresList, ["SAMLAuthentication"]) ||
						get(featuresList, ["Suite"]) ||
						get(featuresList, ["Alarms","SiteMonitor","QuickEnd"]) ||
						get(featuresList, ["Alarms","AlarmAgeWarning"]) ||
						get(featuresList, ["Alarms","EventQueue","PriorityColors"]) ||
						get(featuresList, ["TenantSettings"])) &&
					(permissions.isSystemAdmin ||
                        permissions.isAccountAdmin ||
                        permissions.canViewSubscriptionDetails),
				iconClass: "fas fa-clipboard-check",
				menuGroup: 2,
			}
		},
        {
            path: "/signup",
            name: "signup",
            component: SignUp,
            meta: { loadFeatures: false },
            children: [
                {
                    path: "contactinfo",
                    component: ContactInfo,
					meta: { loadFeatures: false },

                },
                {
                    path: "companyinfo",
                    component: CompanyInfo,
           			meta: { loadFeatures: false },
				},
                {
                    path: "securityinfo",
                    component: SecurityInfo,
					meta: { loadFeatures: false },
                },
                {
                    path: "paymentInfo",
                    component: PaymentInfo,
					meta: { loadFeatures: false },
                },
            ]
        },
		{
			path: "/alarmSetup",
			name: "alarmSetup",
			component: AlarmSetup,
			meta: {
				auth: true,
				title: "Alarms",
				show: (permissions, featuresList) =>
					permissions.canEditSiteSetup &&
					get(featuresList, ["AlarmSetup"]),
				iconClass: "fas fa-bell-plus",
				menuGroup: 2,
			}
		},
		{
			path: "/EventCategorySetup",
			name: "eventCategorySetup",
			component: EventCategorySetup,
			meta: {
				auth: true,
				title: "Event Categories",
				show: (permissions, featuresList) =>
						permissions.canEditEventCategorySetup && get(featuresList, "EventCategorySetup"),
				iconClass: "far fa-object-ungroup",
				menuGroup: 2,
			}
		},
		{
			path: "/AssetSetup",
			name: "assetSetup",
			component: AssetSetup,
			meta: {
				auth: true,
				title: "Asset Setup",
				show: (permissions, featuresList) =>
					get(featuresList, ["MobileUsers"])  && (permissions.canViewAssetSetup || permissions.canEditAssetSetup),
				iconClass: "fas fa-walking",
				iconMask: "far fa-star",
				menuGroup: 2,
			}
		},
		{
			path: "/Schedules",
			name: "schedules",
			component: Schedules,
			meta: {
				auth: true,
				title: "Schedules",
				show: (permissions, featuresList) =>
					get(featuresList, ["Schedules"]) && (permissions.canViewSiteSetup || permissions.canEditSiteSetup || permissions.canViewSchedules || permissions.canEditSchedules),
				iconClass: "fad fa-calendar-alt",
				iconMask: "fad fa-clock",
				menuGroup: 2,
			}
		},
		{
			path: "/setPassword",
			name: "set-password",
			component: SetPassword,
			meta: { loadFeatures: false },
		},
		{
			path: "/resetPassword",
			name: "reset-password",
			component: ResetPassword,
			meta: { loadFeatures: false },
		},
		{
			path: "/rules-engine",
			name: "rules-engine",
			component: RulesEngineSetup,
			meta: {
				auth: true,
				title: "Automate",
				iconClass: "far fa-pencil-ruler",
				show: (permissions, features) => (permissions.canViewRulesEngine || permissions.canEditRulesEngine || permissions.isSystemAdmin) && get(features, ["RulesEngine"]),
				menuGroup: 2
			}
		},
		{
			path: "/system-view",
			name: "system-view",
			component: SystemView,
			meta: {
				auth: true,
				title: "Status",
				show: (permissions, featuresList) =>
					(permissions.canViewSystemView || permissions.isSystemAdmin) &&
					get(featuresList, ["SystemView"]),
				iconClass: "fas fa-sitemap",
				menuGroup: 2,
			}
		},
		{
			path: "/ExternalMapLayerSetup",
			name: "externalMapLayerSetup",
			component: ExternalMapLayerSetup,
			meta: {
				auth: true,
				title: "External Map Layers",
				show: (permissions, featuresList) =>
					(permissions.isSystemAdmin || permissions.canViewExternalMapLayers || permissions.canEditExternalMapLayers) &&
					get(featuresList, ["ExternalMapLayers"]),
				iconClass: "fas fa-layer-group",
				menuGroup: 2,
			}
		},
		{
			path: "/masking-reasons-setup",
			name: "maskingReasonsSetup",
			component: MaskingReasonsSetup,
			meta: {
				auth: true,
				title: "Masking Reasons",
				show: (permissions, featuresList) =>
					(permissions.canEditMaskReasons || permissions.canViewMaskReasons) && get(featuresList, ["Alarms","Masking", "Advanced", "Reasons"]),
				iconClass: "fas fa-shield",
				menuGroup: 2,
			}
		},
		{
			path: "/event-edit/:eventId",
			name: "eventEdit",
			component: () => import("@/views/EventEdit.vue"),
			meta: {
				auth: true,
				title: "Edit Event",
				show: () => false,
			}
		},
		{
			path: "/NotificationTemplates",
			name: "notificationTemplates",
			component: NotificationTemplateSetup,
			meta:{
				auth: true,
				title: "Notification Templates",
				show: (permissions, featuresList) =>
					(permissions.canEditNotificationTemplates || permissions.canViewNotificationTemplates) && get(featuresList, ["Alarms", "SiteMonitor", "Email", "NotificationTemplates"]),
				iconClass: "fas fa-comment-alt-edit",
				menuGroup: 2,
			}
		},
		{
			// handle a non-existing route - redirect to root route
			path: "*",
			redirect: "/"
		},
	]
});
