
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import VueTreeselect from "@riophae/vue-treeselect";
import { LOAD_CHILDREN_OPTIONS, ASYNC_SEARCH } from '@riophae/vue-treeselect'
import { ALL_WITH_INDETERMINATE, BRANCH_PRIORITY } from '@riophae/vue-treeselect/src/constants';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import api from "@/services/api.service";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import { namespace } from "vuex-class";
import { TenantOverview } from "@/store/user-context/types";
import { AreaMaskingCount } from "@/store/areas/types";

const UserContext = namespace("userContext");
const Areas = namespace("areas");

@Component({
	components: {
		VuePerfectScrollbar: VuePerfectScrollbar,
		"vue-treeselect": VueTreeselect
	}
})
export default class AreaTreeSelect extends Vue {
	$refs!: {
		treeSelect: any
	};

	@Prop() public value;
	@Prop({ default: () => { return ["Any"]}  }) public requiredPermissions: string [];
	@Prop({ default: false }) public appendToBody: boolean;
	@Prop({ default: true }) public clearable: boolean;
	@Prop({ default: false }) public multiple: boolean;
	@Prop({ default: false }) public readonly: boolean;
	@Prop({ type: Function, default: option => option }) public reduce: (areaNode: AreaNode) => string;
	@Prop({ default: "Select an area" }) public placeholder: string;
	@Prop({ default: false }) public fullPathValue: boolean;
	@Prop({ default: false }) public showState: boolean;

	@UserContext.Getter getTenantSize: string;
	@UserContext.State("isSet") isUserContextSet: boolean;
	@UserContext.State tenantOverview: TenantOverview;
	@UserContext.Action("fetchOverview") fetchTenantOverview: () => Promise<void>;

	@Areas.Action fetchAreaDictionary: () => Promise<void>;
	@Areas.State areaDictionary: Map<number, string>;

	// The currently loaded AreaNodes (Note: these values are not what is shown when searching.)
	private areaTree: AreaNode[] = [];
	private isSearching: boolean = false;
	private isSearchComplete: boolean = true;

	// True if the user exists within a tenant that has over 500 groups.
	// Forces the user to search for an area before showing results.
	private isAsyncSearchEnabled: boolean = false;

	private isLoadingAreas: boolean = false;

	private fullPath = ALL_WITH_INDETERMINATE;
	private groupOnly = BRANCH_PRIORITY;

	// This is required due to VueTreeselect not re-rendering correctly
	// See https://github.com/riophae/vue-treeselect/issues/483
	private rerenderKey: number = 0;

	private async mounted() {
		if (this.areaDictionary.size === 0) {
			await this.fetchAreaDictionary();
		}
		await this.loadAreaTree();
	}

	// Loads the area tree based on the number of groups in the current users tenant.
	@Watch("tenantOverview.areaCount")
	private async loadAreaTree() {
		if (this.isUserContextSet) {
			this.isLoadingAreas = true;
			switch(this.getTenantSize) {
				case "small":
				case "medium":
					this.areaTree = this.setLeafNodes(await api.getFullGroupsTree(this.requiredPermissions));
					break;
				case "large":
					this.isAsyncSearchEnabled = true;
					break;
				default:
			}
			this.isLoadingAreas = false;
		} else {
			await this.fetchTenantOverview();
		}
	}

	@Watch("value")
	private async onValueChanged(): Promise<void> {
		if (!this.showState) {
			return;
		}

		// This is a work around to force VueTreeselect to re-render
		this.rerenderKey += 1;
		await this.loadAreaTree();
	}

	private async loadOptions({ action, parentNode, callback, searchQuery }) {
		if (action === LOAD_CHILDREN_OPTIONS) {
			let groupTreeNode = await api.getGroupTreeNode(parentNode.id, this.requiredPermissions);
			parentNode.children = groupTreeNode.children;
			callback();
		}

		if (action === ASYNC_SEARCH) {
			this.isSearching = true;
			this.isSearchComplete = false;

			let areaNodeSearchResult = await api.searchGroupTree(searchQuery, this.requiredPermissions);
			if (!areaNodeSearchResult) {
				callback(null, []);
			}
			else {
				callback(null, [ areaNodeSearchResult ]);
			}

			this.isSearchComplete = true;
		}
	}

	@Emit("is-open")
	private close() :boolean {
		this.isSearching = false;
		return false;
	}

	@Emit("is-open")
	private open() :boolean {
		return true;
	}

	private searchValueChanged(searchQuery) {
		this.isSearching = searchQuery.length > 0;
	}

	private onInputChanged(node) {
		if (!node) {
			if(this.clearable){
				this.$emit("input", null);
			}

			return;
		}
		if (this.fullPathValue && node.length > 0) {
			let nodesToRemove: number [] = [];
			let selectedNode = node[0];
			let setNodesToRemove = (n) => {
				if (n.children && n.children.length > 0) {
					n.children.forEach(cn => {
						nodesToRemove.push(cn.id);
						setNodesToRemove(cn);
					});
				}
			}
			setNodesToRemove(selectedNode);
			let result = node.filter(n => !nodesToRemove.includes(n.id)).reverse();
			this.$emit("input", result);

			this.$refs.treeSelect.closeMenu();
			return;
		}
	}

	private selected(node) {
		if (this.fullPathValue === false) {
			if (this.multiple) {
				let result = [];
				if (this.value) {
					result = [...this.value];
				}
				result.push(this.reduce(node));
				this.$emit("input", result);
			} else {
				this.$emit("input", this.reduce(node));
			}
		}
	}

	private deselect(node) {
		if (this.multiple) {
			let result = [...this.value];
			let nodeToRemove = result.findIndex(n => n === node.id);
			if (nodeToRemove !== -1) {
				result.splice(nodeToRemove, 1);
			}

			this.$emit("input", result);
		} else {
			this.$emit("input", this.reduce(node));
		}
	}

	private setLeafNodes(areaNodes: AreaNode []): AreaNode[] {
		if(!areaNodes)
			return;

		areaNodes.forEach(areaNode => {
			if (areaNode.children && areaNode.children.length > 0) {
				areaNode.children = this.setLeafNodes(areaNode.children);
			}
			else {
				areaNode.children = undefined;
			}
		});
		return areaNodes;
	}

	private get valueFormat(): string {
		if (this.fullPathValue) {
			return "node";
		}

		if (this.multiple) {
			return "id";
		}
		return typeof(this.value) === 'number' ? 'id' : 'node';
	}
}
