
import { Component, Vue } from "vue-property-decorator";
import { UserPermissions, FeaturesList } from "@/store/types";
import { Getter, namespace } from "vuex-class";
import { get } from "lodash";
import NavHeader from "@/components/NavHeader.vue";
import { Datetime } from "vue-datetime";
import { formatDateMixin } from "@/mixins";
import ActivityLogTable from "@/components/activity-log/ActivityLogTable.vue";
import vselect3 from "vselect3";
const EventSearchStore = namespace("eventSearch");

Vue.mixin(formatDateMixin);

@Component({
	components: {
		"nav-header": NavHeader,
		Datetime,
		vselect3,
		ActivityLogTable
	}
})
export default class ActivityLogManagement extends Vue {
	$refs!: {
		filterModal: any;
	};

	@Getter getPermissions: any;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@EventSearchStore.Action loadSearchableEventTypes: any;
	@EventSearchStore.Getter searchableEventTypes: any[];

	private MaxDateTime = new Date().toISOString();
	private TimeZone = "UTC";
	private FromDateTime = new Date().toISOString();
	private ToDateTime = new Date().toISOString();

	private tmpFromDate = new Date().toISOString();
	private tmpToDate = new Date().toISOString();

	private showLiveData = true;
	private filterApplied = false;

	private triggerUpdate = false;

	private CanViewActivityLogHistory = false;

	private CanViewActivityLogManagment = false;

	private ShowAdvancedSearch = false;

	private selectedEventTypes = []

	async created() {
		// setup permissions
		this.CanViewActivityLogHistory = this.getPermissions.canViewActivityLogHistory;
		this.CanViewActivityLogManagment = this.getPermissions.canViewActivityLogManagement;

		// if user does not have access take them to the event queue
		if (!this.isActivityLogEnabled || !this.CanViewActivityLogManagment) {
			this.$router.push("/event-queue");
		}

		// set timezone based on browser
		this.TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		if(this.isNonActivityLogEventsEnabled) await this.setUpSearchableEventTypes()
	}

	async setUpSearchableEventTypes() {
		await this.loadSearchableEventTypes()
		const defaultEventType = this.searchableEventTypes
			.find(({ eventTypeID }) => eventTypeID === 11)

		if(defaultEventType) this.selectedEventTypes.push(defaultEventType)
	}

	private onSetDate(dates) {
		if (dates) {
			try {
				this.FromDateTime = dates.minDate ? new Date(dates.minDate).toISOString() : new Date().toISOString();
			} catch {
				this.FromDateTime = new Date().toISOString();
			}
			this.ToDateTime = this.showLiveData ? new Date().toISOString() : new Date(dates.maxDate).toISOString();
		}
	}

	private setDateRange() {
		this.showLiveData = false;
		this.FromDateTime = this.tmpFromDate;
		this.ToDateTime = this.ShowAdvancedSearch ? this.tmpToDate : this.tmpFromDate;

		// if doing the standard day's search
		if (!this.ShowAdvancedSearch) {
			// set the start to be 00:00:00.000
			let startOfDay = new Date(this.FromDateTime);
			startOfDay.setHours(0);
			startOfDay.setMinutes(0);
			startOfDay.setSeconds(0);
			startOfDay.setMilliseconds(0);

			// set the end to be 23:59:59.999
			let endofDay = new Date(this.ToDateTime);
			endofDay.setHours(23);
			endofDay.setMinutes(59);
			endofDay.setSeconds(59);
			endofDay.setMilliseconds(999);

			this.FromDateTime = startOfDay.toISOString();
			this.ToDateTime = endofDay.toISOString();
		}

		this.filterApplied = true;
		this.triggerUpdate = true;
		this.$refs.filterModal.hide();
	}

	private resetLiveStatus() {
		this.showLiveData = !this.showLiveData;
		this.triggerUpdate = false;
	}

	private updateLiveStatus(status: boolean) {
		this.showLiveData = status;
	}

	public get isActivityLogEnabled() {
		return get(this.featuresList, ["ActivityLog"]);
	}

	public get isNonActivityLogEventsEnabled() {
		return get(this.featuresList, ["ActivityLog", "NonActivityLogEvents"]);
	}
}
