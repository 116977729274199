
	import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
	import UrlInputLine from "@/components/UrlInputLine.vue";
	import NotificationSetup from "@/components/tasks/notification/NotificationSetup.vue";
	import { EventTask } from "@/store/tasks/types";
	import ChoicesTaskSetup from "@/components/tasks/choices/ChoicesTaskSetup.vue";
	import CallContactSetup from "@/components/tasks/call-contact/CallContactSetup.vue";
	import { Getter, namespace } from "vuex-class";
	import { TaskType } from "@/store/tasks/types";
	import BasicTask from "@/components/tasks/task-types/BasicTask.vue";
    import RaiseToCasesTask from "@/components/tasks/task-types/RaiseToCasesTask.vue";
	import InputTask from "@/components/tasks/task-types/InputTask.vue";
	import YesNoTask from "@/components/tasks/task-types/YesNoTask.vue";
	import UrlTask from "@/components/tasks/task-types/UrlTask.vue";
	import SitRepTask from "@/components/tasks/task-types/SitRepTask.vue";
	import CCureTask from "@/components/tasks/task-types/CCureTask.vue";
	import AuditTask from "@/components/tasks/task-types/AuditTask.vue";
	import DynamicDataList from "@/components/tasks/task-types/DynamicDataList.vue"
	import EventOutcomeTask from "@/components/tasks/task-types/EventOutcomeTask.vue"
	import ControlTask from "@/components/tasks/control/ControlSetup.vue"
	import { FeaturesList } from "@/store/types";
	import { stringMixin } from "@/mixins";

	import { get } from "lodash";
	import VueSelect from "vue-select";
	import TaskTypeIds from '@/types/sv-data/enums/TaskTypeIds';
	const { isNullOrWhitespace } = stringMixin.methods;

	// @techdebt @refactor break up into sub-components
	const Tasks = namespace("tasks");
	const SiteMonitor = namespace("siteMonitor");

	// component for adding new task to event
	@Component({
		components: {
			"url-input": UrlInputLine,
			"notification-setup": NotificationSetup,
			"basic-task": BasicTask,
			"input-task": InputTask,
			"yes-no-task": YesNoTask,
			"url-task": UrlTask,
			"sitrep-task": SitRepTask,
			"ccure-task": CCureTask,
			"audit-task": AuditTask,
			"choices-task-setup": ChoicesTaskSetup,
			"call-contact-setup": CallContactSetup,
			"vue-select": VueSelect,
			"dynamic-data-list": DynamicDataList,
			"event-outcome-task" : EventOutcomeTask,
			"control-task": ControlTask,
            "raise-to-cases-task": RaiseToCasesTask,
		}
	})
	export default class AddNewEventTask extends Vue {
		@Tasks.Getter("getEnabledActionTypes") enabledActionTypes: TaskType[];
		@Tasks.Getter("getTaskTypesByTitle") taskTypesByTitle: string[];

		@SiteMonitor.Mutation setActivity;

		@Getter("getFeaturesList") featuresList: FeaturesList;

		@Prop() public eventId: number;
		@Prop() public taskCategoryTitle: string;

		private newTask: EventTask = {
			taskID: null,
			taskTypeID: 1,
			taskText: "",
			taskData: null,
			taskCategoryTitle: "",
			eventTaskID: null,
			eventID: this.eventId,
			required: false,
			result: null,
			isMine: false,
			completed: false
		};

		private TaskTypeIdEnum: typeof TaskTypeIds = TaskTypeIds;

		private createId: string = Math.random().toString();
		private selectedTaskType: any = null;
		private taskToRender: string = "basic-task";
		private isTaskValid: boolean = false;

		private get massNotificationEnabled() {
			return get(this.featuresList, ["Actions", "MassNotification"], false);
		}

		private get choicesEnabled() {
			return get(this.featuresList, ["Actions", "Choices"], false);
		}

		private get callContactsEnabled() {
			return get(this.featuresList, ["Actions", "CallContacts"], false);
		}

		private get eventOutcomeEnabled(): boolean {
			return get(this.featuresList, ["Actions", "EventOutcome"], false);
		}

		/**
		 * Computed prop, handles validation of the action type.
		 */
		public get isValid() {
			let isValid = this.isTaskValid;

			// taskType defaults to basic
			if (!this.newTask.taskTypeID) {
				this.newTask.taskTypeID = 1;
			}

			return isValid;
		}

		/**
		 * Handles the task type selection change.
		 */
		@Watch("selectedTaskType")
		public onTaskTypeChange(newTaskType: TaskType) {
			this.setActivity();
			if (newTaskType === null || newTaskType === undefined) return;

			// Reset any content back to default
			this.resetValue();

			// Reset the task type ID
			this.newTask.taskTypeID = newTaskType.taskTypeID;

			this.loadCorrectComponent();
		}

		public mounted() {
			this.loadCorrectComponent();

			this.selectedTaskType = this.enabledActionTypes.find(
				taskType => taskType.taskTypeID == 1
			);
		}

		/**
		 * Resets the value of newTask back to default.
		 */
		public resetValue() {
			this.newTask = {
				taskID: null,
				taskTypeID: 1,
				taskText: "",
				taskData: null,
				taskCategoryTitle: "",
				eventTaskID: null,
				eventID: this.eventId,
				required: false,
				result: null,
				isMine: false,
				completed: false
			};
		}

		public loadCorrectComponent() {
			let task = this.enabledActionTypes.find(
				(taskType: TaskType) =>
					taskType.taskTypeID === this.newTask.taskTypeID &&
					taskType.componentFile !== null
			);

			// Avoid property undefined errors if a task was not returned.
			if (task !== undefined) {
				this.taskToRender = task.componentFile;
			}
		}

		// Called when a task component verifies that it is valid.
		public onTaskValidated(valid: boolean) {
			this.isTaskValid = valid;
		}

		/**
		 * Performs a validation check and, if vaild, emits a save event.
		 */
		public onSave() {
			if (this.isValid && !isNullOrWhitespace(this.taskCategoryTitle)) {
				this.newTask.taskCategoryTitle = this.taskCategoryTitle;
				this.$emit("save", this.newTask);
				this.resetValue();
			}
		}

		@Watch("newTask", { deep: true })
		private onNewTaskUpdated() {
			this.setActivity();
		}

		@Emit("cancel")
		public onCancel() {}
	}
