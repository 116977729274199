


























import { Vue, Prop, Component, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";
import OnTestScheduleForm from "@/components/alarm-points/OnTestScheduleForm.vue";
import { OnTestRequest, OnTestScheduleType } from "@/store/alarm-points/types";
import { EventDetails } from "@/store/site-monitor/types";
import moment from "moment";

const siteMonitor = namespace("siteMonitor");

/**
 * Modal dialog which allows users to put an in-processing event into On Test mode via the site monitor.
 */
@Component({
	components: {
		OnTestScheduleForm
	}
})
export default class PutEventOnTestModal extends Vue {
	@siteMonitor.Getter getPutEventOnTestShown: boolean;
	@siteMonitor.Getter("getEventDetails") eventDetails: EventDetails;

	@siteMonitor.Mutation setPutEventOnTestShown: (shown: boolean) => void;

	@siteMonitor.Action fetchEventDetails: (eventId: number) => Promise<void>;
	@siteMonitor.Action putEventOnTest: ({
		eventId,
		groupId,
		hours,
		minutes,
		date,
		scheduleType,
		auditMode,
		onTestNote
	}) => Promise<any>;

	/**
	 * Is our form data valid?
	 */
	private isValid: boolean = false;

	/**
	 * Request object.
	 */
	private onTestRequest: OnTestRequest = null;

	/**
	 * Indicates the request is being processed.
	 */
	private isProcessing: boolean = false;

	/**
	 * Checks if we've got valid data, and if we have, submits it to the data service.
	 */
	public async submit() {
		if (this.isValid && typeof this.onTestRequest !== "undefined" && this.onTestRequest !== null) {
			this.isProcessing = true;

			

			// Attempt to submit the request, fetch the updated event data, and close the modal
			try {
				// If someone has entered either minutes or hours, set the other to 0
				if (this.onTestRequest.scheduleType == OnTestScheduleType.ForPeriod) {
					if (this.onTestRequest.hours || this.onTestRequest.minutes) {
						this.onTestRequest.hours = this.onTestRequest.hours || 0;
						this.onTestRequest.minutes = this.onTestRequest.minutes || 0;
					}

					await this.putEventOnTest({
						eventId: this.eventDetails.eventID,
						groupId: this.eventDetails.groupID,
						...this.onTestRequest
					});
				} else {
					// Combine the Date and Time parts of the request for submission
					var momentTime = moment(this.onTestRequest.time, "HH:mm", true);
					var hours = momentTime.hours();
					var minutes = momentTime.minutes();

					// Calculate the datetime when this alarm is to be un-masked, and convert it to UTC
					var formattedDate = moment(this.onTestRequest.date)
						.set({ hours, minutes, seconds: 0 })
						.utc()
						.format();

					await this.putEventOnTest({
						eventId: this.eventDetails.eventID,
						groupId: this.eventDetails.groupID,
						...this.onTestRequest,
						date: formattedDate
					});
				}

				// Fetch updated event details which will trigger the 'extend test' etc buttons to appear
				await this.fetchEventDetails(this.eventDetails.eventID);
			} catch (err) {
				console.error(`Could not put event into on test mode: ${err}`);
			} finally {
				// Set processing back to false and close the modal
				this.isProcessing = false;
				this.close();
			}
		}
	}

	/**
	 * Close the modal.
	 */
	public close() {
		this.setPutEventOnTestShown(false);
	}
}
