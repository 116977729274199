
import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import api from "@/services/api.service";
import QRCode from "qrcode";
import speakeasy from "speakeasy";

interface MfaAuth {
	tempSecret: string;
	tempQrURL: string;
	otpURL: string;
}

@Component({
	components: {}
})
export default class MFA extends Vue {
    @Prop({ type: String }) public username: string;
    @Prop({ type: String }) public pass: string;

	@Ref() readonly mfaContainer: any;

    public localAuthCode: string = "";
	public mfaError: string = "";
    private authObj: MfaAuth = null;

    private async mounted() {
        // Create our MFA QR code
        var secret = speakeasy.generateSecret({
            length: 20,
            name: ("Sureview Operations (" + this.username + ")"),
            issuer: "Sureview Operations"});
        QRCode.toDataURL(secret.otpauth_url, (err, data_url)=>{
            this.authObj = {
                tempSecret: secret.base32,
                tempQrURL: data_url,
                otpURL: secret.otpauth_url};
        });
    }

    private async submitSecret() {
    	let mfaRequestBody;
    	if (this.username && this.pass) {
			mfaRequestBody = {
				username: this.username,
				password: this.pass,
				authCode: this.localAuthCode,
				token: this.authObj.tempSecret
			};
		}
    	else {
			mfaRequestBody = {
				authCode: this.localAuthCode,
				token: this.authObj.tempSecret
			};
		}

        try {
			await api.setMfa(mfaRequestBody);
			this.$emit('mfa-set');
		} catch (ex) {
			this.mfaError = ex.response.data;
		}
    }

	private copySecretToClipboard() {
		// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
		// attaching temporary textarea to body works on login screen, but not works in account details
		// attaching it to container div works in both cases
		var textArea = document.createElement("textarea");
		textArea.value = this.authObj.tempSecret;
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";
		this.mfaContainer.appendChild(textArea);
		textArea.focus();
		textArea.select();

		document.execCommand("copy");

		this.mfaContainer.removeChild(textArea);
	}
}
