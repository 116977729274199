
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { AudioDevice, AudioX } from "@/types/audio/audio";
import MicMixin from '@/mixins/audio/MicMixin';
import AudioAddressMixin from "@/mixins/audio/AudioAddressMixin";
import AudioMixin from '@/mixins/audio/AudioMixin';

@Component({})
export default class Transmit extends Mixins(MicMixin, AudioAddressMixin, AudioMixin) {
    @Prop({ default: null, type: Object }) 
    private audioTransmit: AudioDevice;

    @Prop({default: "", type: String})
    private deviceAuth: string; 

    @Prop() 
    private eventId: number;

    @Prop({default: "", type: String})
    private deviceServiceEndpoint: string;

    private active: boolean = false;
    private address: string = "";
    private loading: boolean = false;

    public async mounted(): Promise<void> {
        this.auth = this.deviceAuth;
        await this.updateAudioUrl();
        window.addEventListener('beforeunload', this.unloadHook)
    }

    private unloadHook(): void {
        if (this.audioTransmit) {
            this.auditAudioStop(this.audioTransmit.deviceId, this.audioTransmit.title)
        }
    }

    @Watch("audioTransmit")
    private async audioDeviceChanged(newDevice: AudioDevice | null, oldDevice: AudioDevice | null): Promise<void> {
        if(!newDevice || (newDevice && oldDevice && newDevice.deviceId != oldDevice.deviceId)){
            await this.auditAudioStop(newDevice.deviceId, newDevice.title);
        }
        this.updateAudioUrl();
    }


    @Watch("eventId")
    private async updateAudioUrl(): Promise<void> {
        try {
            this.errorMessage = "";
            this.active = false;
            this.loading = true;
            if (this.initialized) {
                await this.toggleMic(false);
            }

            await this.closeMicContext();

            this.eventRecordId = null;
            if (!this.eventId || !this.audioTransmit) return;

            this.eventRecordId = await this.generateEventRecord(this.eventId, this.audioTransmit.deviceId, `Audio transmit opened for device: ${this.audioTransmit.title}`);
            var audioDevice = {...this.audioTransmit};
            audioDevice.eventRecordId = this.eventRecordId;
            this.address = this.constructAddress(this.deviceServiceEndpoint, AudioX.transmit, audioDevice);
            await this.setMic();
            await this.requestPermission();
        } catch (ex) {
            console.error(ex);
        }
        this.loading = false;
    }

    @Watch("errorMessage")
    private errorMessageWatch(): void {
        if (this.errorMessage) {
            this.active = false;
            this.toggleMic(false);
        }
    }

    /**
     * @summary computed property which will return the varient of the transmit button
     * depending on the status of the mic, if it has permission, has ssl, enabled etc.
     * @returns { string } 'warning', 'primary' or 'secondary'
     */
    private get variant(): string {
        if (!this.deviceProtocol) {
            return "warning";
        }

        if (this.errorMessage) {
            return "danger";
        }

        return this.active ? "primary" : "secondary";
    }

    /**
     * @summary method which will toggle the state of the active for transmit
     */
    private async toggleActiveState(): Promise<void> {
        this.active = !this.active;
        if (!this.active || this.errorMessage) {
            if (this.eventId && this.audioTransmit && this.audioTransmit.deviceId) {
                await this.auditAudioStop(this.audioTransmit.deviceId, this.audioTransmit.title);
            }
            await this.updateAudioUrl();
        } else {
            await this.toggleMic(true);
        }
    }

    /**
     * @summary method which will setup the mic instance
     */
    private async setMic() {
        if (!this.address) 
            return;
            
        await this.setupMic({
            url: this.address,
            playingState: this.active
        });
    }

    /**
     * @summary method which will request the permission from the user if they didn't give the page mic permission
     */
    private async requestPermission(): Promise<void> {
        await this.requestDeviceAuth();
    }

    private toggleState(): void {
        if (this.loading) {
            return;
        }

        if (this.errorMessage || ((!this.deviceProtocol && !this.micPermissionStatus) || (this.micPermissionStatus && this.deviceProtocol) && this.micReady)) {
            this.toggleActiveState();
            return;
        }

        if (!this.micPermissionStatus) {
            this.requestPermission();
        }
    }

    private async auditAudioStop(deviceId: number, deviceTitle: string): Promise<void> {
        await this.generateEventRecord(this.eventId, deviceId, `Audio transmit closed for device: ${deviceTitle}`);
    }

    private async destroyed() {
        if(this.eventId && this.audioTransmit){
            await this.auditAudioStop(this.audioTransmit.deviceId, this.audioTransmit.title)
        }
        window.removeEventListener("beforeunload", this.unloadHook);
    }
}
