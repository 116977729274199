





































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import vselect3 from "vselect3";
import { get } from 'lodash'

const CSVParser = namespace("csvParser");
const CSVFormHandler = namespace("csvFormHandler");
import { cloneDeep, debounce, isArray } from 'lodash'

@Component({
	components: {
		scrollbar: VuePerfectScrollbar,
		"vue-select": vSelect,
		vselect3
	}
})
export default class CsvForm extends Vue {
	@Prop({ type: Array, default: () => []}) csvData: any;
	@Prop({ type: String, required: true }) name: string;
	@Prop({ type: Boolean, default: false }) isUploading: string;
	@Prop({ type: Object, default: () => ({}) }) progress: any;
	@Prop({ type: Boolean, default: false}) batchUploadErrors: boolean;

	private localData = []
	private errorProgressList = {}

	loading = false

	@CSVParser.Action setValidHeadersName: any;
	@CSVParser.Action setMandatoryFields: any;
	@CSVParser.Action loadSCVData: any;
	@CSVParser.Getter body:any;

	@CSVFormHandler.Action setParserOptions: any;
	@CSVFormHandler.Action resetErrorsForm: any;

	@CSVFormHandler.Action setDataCsv: any;
	@CSVFormHandler.Action validateCell: any
	@CSVFormHandler.Action validateAll: any
	@CSVFormHandler.Action setValue: any
	@CSVFormHandler.Action removeRow: any
	@CSVFormHandler.Getter errorsCSV: any
	@CSVFormHandler.Getter formData: any
	@CSVFormHandler.Getter headersForm: any
	@CSVFormHandler.Getter mandatoryFields: any
	@CSVFormHandler.Getter validHeaders: any
	@CSVFormHandler.State errorsForm: any
	@CSVFormHandler.Getter validModalItems: any;
	@CSVFormHandler.Getter isValidForm: any;
	@CSVFormHandler.Getter errorList: any;
	@CSVFormHandler.Getter errorsRow: any;
	@CSVFormHandler.Getter errorsColumn: any;

	get localRemoveRow() {
		return option => {
			this.removeRow(option)
			this.localValidateAll(option)
		}
	}

	get localValidateAll() {
		return debounce(this.validateAll, 300)
	}

	get localErrorsCSV() {
		return this.errorsCSV(this.name)
	}

	get localBody() {
		return this.body(this.name)
	}

	get localHeadersForm() {
		return this.headersForm(this.name)
	}

	get localFormData() {
		return this.formData({ name: this.name })
	}

	get localErrorsForm() {
		return (this.errorsForm && this.errorsForm[this.name]) || {}
	}

	get localIsValidForm() {
		return this.isValidForm(this.name)
	}

	get localErrorList() {
		return this.errorList(this.name)
	}

	get localErrorsRow() {
		return this.errorsRow(this.name)
	}

	get localErrorsColumn() {
		return this.errorsColumn(this.name)
	}

	get gridData() {
		return this.isUploading ? this.localData : this.localFormData
	}

	get debounceSetData() {
		return debounce(this.localSetValue, 500)
	}

	get isShowGrid() {
		const isCsvErrors = this.localErrorsCSV.info || this.localErrorsCSV.error
		const isSucceed = this.progress.status === 'succeed'

		return !isCsvErrors && !this.loading && !isSucceed
	}

	formattedMultipleValue(formValue) {
		const data = isArray(formValue) ? formValue : formValue.split(',')
		return data.filter(v => v)
	}

	isProgressError(row) {
		const rowId = get(row, '[0].rowId')

		return rowId !== undefined && this.errorProgressList[`${rowId}`]
	}

	async localSetValue(option) {
		if(this.isUploading) return
		await this.setValue(option);
		const data = {
			...option.data,
			csvValue: option.value
		}
		this.validateCell({ name: this.name, data })
	}

	@Watch('batchUploadErrors')
	removeSuccessfulUploads(): void {
		// Replace form data with the failed rows
		if (this.batchUploadErrors) {
			const currentForm = [...this.localFormData];

			let uploadsWithErrors = [];
			for (let i = 0; i < this.progress.bulkErrors.length; i++) {
				const rowError = currentForm.find(r => r[0].rowId == this.progress.bulkErrors[i].rowId);
				uploadsWithErrors.push(rowError);
			}

			this.localData = uploadsWithErrors;
		}
	}

	@Watch('localFormData', { deep: true, immediate: true })
	changeLocalBody() {
		if(this.isUploading) return
		this.$emit('get-current-data', this.localFormData)
	}

	@Watch('localFormData', { deep: true, immediate: true })
	setLocalData() {
		this.localData = cloneDeep(this.localFormData)
	}

	@Watch('progress', {deep: true})
	removeRowById() {
		if(this.progress.status === 'error') this.errorProgressList[this.progress.rowIndex] = this.progress
		if(this.isUploading && this.progress.rowIndex !== undefined && this.isRemoveRow) {
			const index = this.localData.findIndex((row) => Number(row[0].rowId) === Number(this.progress.rowIndex))
			this.localData.splice(index, 1)
		}
	}

	get isRemoveRow() {
		const status = this.progress.status

		return status === 'progress' || status === 'succeed'
	}

	async initForm() {
		await this.setDataCsv({ csvData: this.csvData, name: this.name });
		await this.validateAll({ name: this.name });
	}

	async beforeDestroy() {
		await this.setDataCsv({ csvData: {}, name: this.name });
		await this.resetErrorsForm({ name: this.name })
	}

	async mounted() {
		this.loading = true
		await this.initForm()
		this.loading = false
	}
}
