import { GetterTree } from "vuex";
import { CSVParser } from "./types";
import { RootState } from "../types";

import _ from "lodash"

export const getters: GetterTree<CSVParser, RootState> = {
	headers: (state, getters) => name =>
		getters.isContextExist(name) && getters.formattedHeaders(name),
	body: (state, getters) => name =>
		(getters.isContextExist(name) && getters.formattedBody(name)) || [],

	isDataEmpty: (state, getters) => name => !getters.isContextExist(name),

	// Validations
	isTableValid: (state, getters) => name =>
		Object.values(getters.errorsCSV(name)).every(v => v === ""),

	errorsCSV: (state, getters) => name => {
		const errors = [];
		if (!getters.isHeadersExits(name)) return { info: "Data is empty", error: "" };
		if (!getters.isValidHeadersKey(name))
			errors.push(`Not allowed header keys ${getters.notValidHeaderKey(name)}. `);
		if (getters.requiredHeaders(name).length)
			errors.push(
				`Headers keys aren't valid, required ${getters.requiredHeaders(name)} csv header(s)`
			);
		return { info: "", error: errors.join("") };
	},

	requiredHeaders: (state, getters) => name =>
		_.difference(Object.keys(state.mandatoryFields[name] || {}), getters.headers(name)),

	isValidHeadersKey: (state, getters) => name => !getters.notValidHeaderKey(name),

	notValidHeaderKey: (state, getters) => name =>
		getters.isHeadersExits(name) &&
		Object.keys(state.validHeadersName[name] || {}).length &&
		getters.headers(name)
			.filter((header: string) => !state.validHeadersName[name][header])
			.join(", "),

	formattedBody: (state, getters) => name =>
		getters.isBodyExits(name) &&
		_.get(state.csvParsed[name], "body")
			.map((row: string[]) =>
				row.reduce(
					(acc, cur, i) => ({
						...acc,
						[getters.headers(name)[i]]: cur
					}),
					{}
				)
			),

	// UTILS getters
	formattedHeaders: (state, getters) => name =>
		getters.isHeadersExits(name) &&
		_.get(state.csvParsed[name], "headers").filter((head: string) => head),

	isContextExist: (state, getters) => name =>
		getters.isHeadersExits(name) && getters.isBodyExits(name),

	isHeadersExits: state => name => !!_.get(state.csvParsed[name], "headers"),
	isBodyExits: state => name => !!_.get(state.csvParsed[name], "body"),

	getCsvLinkDownloadByString: () => (text: string) => {
		const blob = new Blob([text], { type: "text/csv" });

		return window.URL.createObjectURL(blob);
	}
};
