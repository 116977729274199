import { Module } from "vuex";
import { GenericTableGetters } from "./getters";
import { mutations } from "./mutations";
import { GenericTableState } from "./types";
import { RootState } from "@/store/types";
import { getDefaultState } from "./state";

export const state: GenericTableState = getDefaultState();

const namespaced: boolean = true;

export const GenericTable: Module<GenericTableState, RootState> = {
	namespaced,
	state,
	getters: GenericTableGetters,
	mutations
};
