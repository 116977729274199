
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import vSelect3 from "vselect3";
import { validationMixin } from "vuelidate";
import { email, maxLength, required } from "vuelidate/lib/validators";
import { filter } from "lodash";
import api from "@/services/api.service";
import { Getter } from "vuex-class";
import { UserPermissions } from "@/store/types";
import NotificationTemplate from "@/types/sv-data/notification-templates/NotificationTemplate";
import SearchDropDown from "@/components/form/SearchDropDown.vue";
import notificationTemplatesApi from "@/services/api.notificationTemplate.service";
import NotificationDocumentTypeId from "@/types/sv-data/enums/NotificationDocumentTypeIds";
import NotificationTemplateTypeId from "@/types/sv-data/enums/NotificationTemplateTypeIds";
import SetEventOutcomeAndNote from "@/components/site-monitor/SetEventOutcomeAndNote.vue";
import EventOutcomeAndNote from "@/types/sv-data/EventOutcomeAndNote";

import {
	NotificationTemplateManualField
} from "@/types/sv-data/notification-templates/NotificationTemplateManualField";
import NotificationTemplateCreateRequest
	from "@/types/sv-data/notification-templates/NotificationTemplateCreateRequest";

interface ContactOption {
	email: string;
	fullName: string | null;
	userID?: number;
}

interface TemplateFormatOption {
	id: number;
	label: string;
}

interface ManualFieldInput {
	field: NotificationTemplateManualField;
	value: string;
}

const MAX_NOTE_LENGTH = 5000;

@Component({
	components: {
		"v-select-3": vSelect3,
		"search-drop-down": SearchDropDown,
		"set-event-outcome-and-note": SetEventOutcomeAndNote
	},
	mixins: [validationMixin],
	validations: {
		note: {
			maxLength: maxLength(MAX_NOTE_LENGTH)
		},
		addresses: {
			required,
			$each: {
				email: { email }
			}
		}
	}
})
export default class NotificationModal extends Vue {
	@Prop({ type: Number }) readonly groupId: number;
	@Prop({ type: Number }) readonly eventId: number;
	@Model("change", { type: Boolean }) readonly isVisible: boolean;

	@Getter("getPermissions") private permissions: UserPermissions;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	private emails: ContactOption[] = [];
	private emailsLoaded = false;

	private addresses: ContactOption[] = [];
	private note = "";
	private attachSitRep = true;
	private notificationTemplates: NotificationTemplate[] = [];
	private selectedNotificationTemplate: NotificationTemplate = null;
	private selectedTemplateFormat: TemplateFormatOption = null;
	private availableManualFields: ManualFieldInput[] = null;
	private eventOutcome: EventOutcomeAndNote = {
		valid: true,
		eventOutcomeNote: null,
		eventOutcomeId: null
	};

	private notificationTemplateFormats: TemplateFormatOption[] = [
		{ id: NotificationDocumentTypeId.Pdf, label: "Attachment" },
		{ id: NotificationDocumentTypeId.Png, label: "Embedded" }];

	private async mounted(): Promise<void> {
		await this.loadContacts();
		await this.loadNotificationTemplates();
	}

	private get isNotificationTemplatesEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "Email", "NotificationTemplates"]);
	}

	private get hasNotificationTemplatePermissions(): boolean {
		return this.permissions.canViewNotificationTemplates || this.permissions.canEditNotificationTemplates;
	}

	@Watch("groupId")
	private async loadNotificationTemplates(): Promise<void> {
		if (!this.isNotificationTemplatesEnabled || !this.hasNotificationTemplatePermissions) {
			return;
		}
		try {
			this.notificationTemplates = await notificationTemplatesApi.getAllNotificationTemplates(NotificationTemplateTypeId.Event, this.groupId);
		} catch (ex) {
			console.error("Unexpected error fetching notification templates: " + ex);
		}
	}

	@Watch("groupId")
	private async loadContacts() {
		this.emailsLoaded = false;
		this.emails = await api.getContactsForGroup(this.groupId);
		this.emailsLoaded = true;
	}

	private createOption(newOption: string): ContactOption {
		const newContact = {
			email: newOption,
			fullName: null
		};

		this.$emit("option:created", newContact);
		return newContact;
	}

	private getOptionLabel(option: ContactOption) {
		if (option.fullName) {
			return option.fullName;
		}
		return option.email;
	}

	private get noteInvalidFeedback() {
		if (!this.$v.note.maxLength) {
			return `Note must have no more than ${this.$v.note.$params.maxLength.max} letters.`;
		}
		return "";
	}

	private get addressesInvalidFeedback() {
		const { required, $each: each } = this.$v.addresses;
		if (!required) {
			return "Please specify at lest one recipient";
		}

		const invalidEmails = filter(each.$iter, v => !v.email.email).map(v => v.email.$model);
		if (invalidEmails.length > 0) {
			const adrWord = invalidEmails.length === 1 ? "address" : "addresses";
			const addresses = invalidEmails.map(a => `"${a}"`).join(", ");
			return `The ${adrWord} ${addresses} in the "Email Address" field was not recognized. Please make sure that all addresses are properly formed.`;
		}

		return "";
	}

	private get noteValidFeedback() {
		const charactersUsed = this.note !== null ? this.note.length : 0;
		return `Characters used: ${charactersUsed}/${MAX_NOTE_LENGTH}`;
	}

	private onHide() {
		this.addresses = [];
		this.note = "";
		this.attachSitRep = true;
		this.$v.$reset();
	}

	private async onSend() {
		let createNotificationDocument: NotificationTemplateCreateRequest = null;

		if (this.selectedNotificationTemplate != null) {
			createNotificationDocument = {
				notificationTemplateId: this.selectedNotificationTemplate.notificationTemplateId,
				notificationDocumentTypeId: this.selectedTemplateFormat.id,
				note: this.note,
				manualInputFields: this.availableManualFields.reduce((map, obj) => {
					map[obj.field.id] = obj.value;
					return map;
				}, {})
			};
		}

		const payload = {
			contacts: [...this.addresses],
			note: this.note,
			attachSitRep: this.attachSitRep,
			createNotificationDocumentRequest: createNotificationDocument
		};

		if (this.eventOutcome.eventOutcomeId != null || (this.eventOutcome.eventOutcomeNote != null && this.eventOutcome.eventOutcomeNote.length > 0)) {
			const eventOutcomeAndNoteForm = this.$refs.eventOutcomeAndNote as any;
			try {
				await eventOutcomeAndNoteForm.submit();
			}
			catch(err) {
				console.error(err);
				this.$notify({
					type: "error",
					title: "Updating Event Outcome",
					text: err.message
				});

				// Break out of function so notification doesn't get sent
				return;
			}
		}
		await api.sendEventNotification(this.eventId, payload);
	}

	private onModalVisibilityChange(isVisible: boolean) {
		this.$emit("change", isVisible);
	}

	private async onPreviewTemplate(): Promise<void> {

		let override = {};
		if (this.eventOutcome?.eventOutcomeNote != null && this.eventOutcome?.eventOutcomeNote.length > 0) {
			override["eventOutcomeNote"] = this.eventOutcome.eventOutcomeNote;
		}

		// Hard coded to PDF as XSS middleware doesn't like HTML file responses
		// and this will need to be investigated
		await notificationTemplatesApi.previewNotificationTemplate(this.eventId, {
			notificationTemplate: this.selectedNotificationTemplate,
			notificationDocumentTypeId: NotificationDocumentTypeId.Pdf,
			eventOutcomeOverride: this.eventOutcome?.eventOutcomeId,
			placeholderOverrides: override,
			note: this.note,
			manualInputFields: this.availableManualFields.reduce((map, obj) => {
				map[obj.field.id] = obj.value;
				return map;
			}, {})
		});
	}

	@Watch("selectedNotificationTemplate")
	private updateAvailableManualFields(): void {
		this.availableManualFields = [];
		if (this.selectedNotificationTemplate == null) {
			return;
		}
		let fields = this.selectedNotificationTemplate.manualFields;
		if (fields != null) {
			fields.forEach((element) => {
				this.availableManualFields.push({
					field: element,
					value: ""
				});
			});
		}
	}

	private get canSendNotification(): boolean {
		if (this.$v.$invalid) {
			return false;
		}

		if (this.selectedNotificationTemplate != null) {
			if(!this.eventOutcome.valid) {
				return false;
			}
			return this.selectedTemplateFormat !== null;
		}
		return true;
	}

	private eventOutcomeDetailsChanged(newValue: EventOutcomeAndNote): void {
		this.eventOutcome = newValue;
	}
}
