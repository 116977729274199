import { GetterTree } from "vuex";
import { MapLayerItemTypeIds, MapLayersState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<MapLayersState, RootState> = {
	getSortedElevations: state => state.mapData.elevations.slice().sort((a, b) => a.elevationValue - b.elevationValue),
	getMapLayerItemTypes(state) {
		// For now only exclude Guard types as these are still needed to be enabled in the db for the guard feature until its fully removed
		const excludeTypes = [MapLayerItemTypeIds.Guard];
		return state.mapLayerItemTypes.filter(x => !excludeTypes.includes(x.mapLayerItemTypeId));
	},
	triggerDefaultView(state): boolean {
		return (state.computeDefaultView.assets === true && state.computeDefaultView.areas === true);
	}
};
