export interface SessionsState {
	sessions: any;
	requestStatus: number;
}

export enum SessionResource {
	AuditServiceSession = 2,
	DeviceServiceSession = 3,
	StandardLoginSession = 5,
	MobileUserSession = 6,
}

export interface UpdateSessionParams {
	resourceId: ResourceType;
	eventId?: number;
	regionId?: number | null;
}

export enum ResourceType {
	Reports = 1,
	Audit = 2,
	Proxy = 3,
	Admin = 4,
	Other = 5,
	Mobile = 6
}