














import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import _ from 'lodash'

const Emails = namespace("emails");

@Component
export default class EmailFromTo extends Vue {
    @Prop({
        type: [Object, Array],
        required: true,
    })
    value:object|any[];

    public get isValueArray() {
		return _.isArray(this.value)
    }

    public get isValueObject() {
		return !_.isArray(this.value) && _.isObject(this.value)
    }

    public get getEmail() {
        return fromTo => fromTo && fromTo.email
    }
}
