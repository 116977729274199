

import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter, Action, State } from "vuex-class";
import { get, groupBy, sortBy } from "lodash";
import { FeaturesList, UserPermissions } from "@/store/types";
import ShortcutHelp from "./ShortcutHelp.vue";

import MyAccount from "../components/my-account/my-account.vue";
import SwitchTenant from "../components/system-admin/SwitchTenant.vue";
import NavMenuIcon from "@/components/NavMenuIcon.vue";
import GettingStartedModal from "@/components/GettingStartedModal.vue";
import ZendeskHelp from "./ZendeskHelp.vue";
import { SubscriptionDto } from '@/store/subscription/types';
import Support from "@/components/Support.vue";

const CaseManagement = namespace("caseManagement");
const Subscription = namespace("subscription");

@Component({
		components: {
			"shortcut-help": ShortcutHelp,
			"my-account": MyAccount,
			"switch-tenant": SwitchTenant,
			"nav-menu-icon": NavMenuIcon,
			"zendeskHelp" : ZendeskHelp,
			"getting-started-modal": GettingStartedModal,
			"support": Support
		}
	})
	export default class NavMenu extends Vue {
		@Getter getPermissions: UserPermissions;
		@Getter("getVersion") webVersion;
		@Getter("getFeaturesList") featuresList: FeaturesList;
		@Getter("getIsFieldOpsLicensed") private isFieldOpsLicensed: () => boolean;
		@Getter("getIsResponseLicensed") private isResponseLicensed: () => boolean;
		@Getter("getIsCasesLicenced") private isCasesLicenced: () => boolean;
		@Getter("getFieldOpsOnlyRoutes") private fieldOpsOnlyRoutes: string[];
		@Getter("getFieldOpsLicencedRoutes") private fieldOpsLicencedRoutes: string[];
		@Getter("getFieldOpsAndResponseLicencedRoutes") private fieldOpsAndResponseLicencedRoutes: string[];

		@Action serverLogout: any;
		@Action logout: () => void;

		@CaseManagement.Action casesLogin: (suiteSession: boolean) => Promise<{ url: string, token: string | null, suiteTenantId: string | null  }>;

		@State User;

		@Subscription.State private subscription: SubscriptionDto;
		@Subscription.State("isLoaded") private isSubscriptionLoaded: Boolean;

		private menuOpened: boolean = false;
		private isGettingStartedShown: boolean = false;
		private isSupportModalShown: boolean = false;

		private mounted() {
			if (this.getPermissions.isAccountAdmin && this.User.isFirstLogin) {
				this.isGettingStartedShown = true;
			}
		}

		get routes(): any[] {
			let routes = (this.$router as any).options.routes
				.filter(
					route =>
						route.meta &&
						route.meta.title &&
						(!route.meta.show || route.meta.show(this.getPermissions, this.featuresList))
				)
				.map(route => {
					return {
						routeName: route.name,
						linkText: route.meta.title,
						show: route.meta.show,
						iconClass: route.meta.iconClass,
						iconMask: route.meta.iconMask,
						target: this.getRouteTarget(route.name), // if the link should open in a new window or not
						group: route.meta.menuGroup,
						order: route.meta.menuOrder,
					};
				});

			// sort the list alphabetically;
			routes = routes.sort((a, b) => a.linkText.localeCompare(b.linkText));

			if(this.isSubscriptionLoaded && !this.subscription.isActiveState){
				routes = routes.filter(route => ["organization", "event-queue"].includes(route.routeName));
			}

			if(this.isSuiteEnabled) {
				if(!this.isFieldOpsLicensed || !this.isResponseLicensed)
				{
					routes = routes.filter(route => !this.fieldOpsAndResponseLicencedRoutes.includes(route.routeName))
				}
				if(this.isResponseLicensed && !this.isFieldOpsLicensed){
					routes = routes.filter(route => !this.fieldOpsOnlyRoutes.includes(route.routeName))
				}
				if(this.isFieldOpsLicensed && !this.isResponseLicensed){
					routes = routes.filter(route => this.fieldOpsLicencedRoutes.includes(route.routeName));
				}
			}

			return routes;
		}

		private get isSuiteEnabled(): boolean {
			return get(this.featuresList, ["Suite"]);
		}

		private get routeGroups(): any[] {
			const routeGroups = [];
			const groups = groupBy(this.routes, route => route.group);
			for (let key in groups) {
				routeGroups.push({ group: key, routes: sortBy(groups[key], g => g.order) });
			}
			return routeGroups;
		}

		private getRouteTarget(routeName) {
			// default to open in same window
			let target = "_self";

			// get any OpenInNewTab settings
			let openInNewTabRoutes = get(this.featuresList, ["NavMenu", "OpenInNewTab"]);

			// if there are some, look for one that matches our link name
			if (openInNewTabRoutes) {
				if (
					// if there is one that matched the route name (lower cased)
					openInNewTabRoutes[
						Object.keys(openInNewTabRoutes).find(key => key.toLowerCase() === routeName.toLowerCase())
					]
				) {
					// open the link in a new window
					target = "_blank";
				}
			}

			return target;
		}

		async logoutUser() {
			await this.serverLogout();
			this.logout();
		}

		get canOpenCaseManagement() {
			return !!get(this.featuresList, ["CaseManagement"]) &&
				!!this.getPermissions["canAccessCaseManagement"] &&
				!!this.isCasesLicenced;
		}

		async toCases() {
			const loginResult = await this.casesLogin(this.isSuiteEnabled);
			let url = loginResult.url + (loginResult.url.endsWith("/") ? "" : "/");
			if (loginResult.suiteTenantId) {
				// Login to Cases - this may redirect to Suite
				window.open(`${url}login/VerifySuiteLogin?email=${encodeURIComponent(this.User.username)}&tenantId=${loginResult.suiteTenantId}`)
			} else {
				window.open(`${url}login/withToken/${loginResult.token}`);
			}
		}

		private get isGettingStartedModalEnabled(): boolean
		{
			return this.getPermissions?.isAccountAdmin && !this.isSuiteEnabled
		}

	}
