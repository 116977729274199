import { GetterTree } from "vuex";
import { RootState } from "../types";
import { DevicesState } from "./types";

export const getters: GetterTree<DevicesState, RootState> = {
    getSortedServerTypes(state) {
        const serverTypes = state.serverTypes ? [...state.serverTypes] : [];
		return serverTypes.sort(({ title: a }, { title: b }) => {
			if (a < b) return -1;

			return b < a ? 1 : 0
		});
	}
}
