import { DeviceDetails, DeviceTypeCounts, ServerDetails, DeviceResponseRecordsRetrievalStatus } from "@/store/devices/types";

export const getNewServerDetailsTemplate = (): ServerDetails => {
	return {
		serverID: null,
		serverTypeID: null,
		title: null,
		groupID: null,
		host: null,
		port: null,
		username: null,
		password: null,
		extraValue: null,
		logDisarmedAlarms: false,
		showEdge: false,
		edgePath: null,
		edgeUser: null,
		edgePassword: null,
		deviceTypeCounts: getDeviceTypeCountsDefaults(),
		accountNumber: "",
		serverLineProfileId: null,
	};
};

export const getNewDeviceDetailsTemplate = (): DeviceDetails => {
	return {
		deviceID: null,
		serverID: null,
		deviceTypeID: null,
		title: null,
		quality: null,
		speed: null,
		hasPtz: null,
		auditServiceEndpoint: null,
		deviceServerEndpoint: null,
		eventRecordId: null,
		presets: null,
		input1: null,
		input2: null,
		extraValue: null,
		settingsMeta: null,
		cameraSettings: null,
		audioSettings: null,
		relaySettings: null,
		deviceServiceSession: null,
		comment: null,
		orderNum: null,
		relayType: null,
		eventRecordID: null,
		liveVideoDelay: null,
		supportsAudio: null,
		receivesAudio: null,
		transmitsAudio: null,
		transcodingAllowed: false,
	};
};

export const getAudioSettingsDefaults = () => {
	return {
		receiveEnabled: false,
		transmitEnabled: false
	};
};

export const getCameraSettingsDefaults = () => {
	return {
		qual: "Lowest",
		telemServerId: -1,
		telemSpeed: "Default"
	};
};

export const getDoorRelaySettingsDefaults = () => {
	return {
		onOffEnabled: false,
		pulseEnabled: false,
		stateSwitchEnabled: false,
		stateSwitchDefaultState: false
	};
};

export const getOutputRelaySettingsDefaults = () => {
	return {
		...getDoorRelaySettingsDefaults(),
		relayType: "Output"
	};
};

export const getDeviceTypeCountsDefaults = (): DeviceTypeCounts => {
	return {
		devices: 0,
		cameras: 0,
		audios: 0,
		doors: 0,
		outputs: 0,
		alarms: 0
	};
};

export const getDeviceResponseRecordsRetrievalStatusDefaults = (): DeviceResponseRecordsRetrievalStatus => {
	return {
		allAlarmsRetrieved: false,
		allServersRetrieved: false,
		allFilteredServersRetrieved: false,
		allCamerasRetrieved: false,
		allFilteredCamerasRetrieved: false,
		allAudiosRetrieved: false,
		allFilteredAudiosRetrieved: false,
		allDoorsRetrieved: false,
		allFilteredDoorsRetrieved: false,
		allOutputsRetrieved: false,
		allFilteredOutputsRetrieved: false
	};
};
