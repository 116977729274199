






























import { Component, Vue, Prop } from "vue-property-decorator";
const CSVFormHandler = namespace("csvFormHandler");
const CSVParser = namespace("csvParser");
import { namespace } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CsvUploader from './csv-uploader.vue'
import { ModalItemCSV } from '@/store/csv-form-handler/types'

export interface DefaultFile {
	name: string,
	data: string
}

@Component({
	components: {
		scrollbar: VuePerfectScrollbar,
		'csv-uploader': CsvUploader,
	}
})
export default class CsvUploaderWrapper extends Vue {
	@Prop({ required: true }) modalItemsCsv: ModalItemCSV[];
	@Prop({ default: 'Bulk upload' }) title: string;
	@Prop({ type: String, required: true }) name: string
	@Prop({ type: Object, required: true }) defaultFile
	@Prop({ type: Object, default: () => ({}) }) progressBulk
	@Prop({ type: Boolean, default: false}) isMultipleError
	@Prop({ type: String, default: 'modalBulk'}) modalId
	@CSVFormHandler.Getter isValidForm: any
	@CSVFormHandler.Action validateAll: any
	@CSVFormHandler.Getter errorsRow: any
	@CSVParser.Getter errorsCSV:any;

	private formData = []

	get localIsValidForm() {
		return this.isValidForm(this.name)
	}

	private setCurrentData(data) {
		this.formData = data
	}

	get localErrorsCSV() {
		return this.errorsCSV(this.name)
	}

	get isUploading() {
		return Object.keys(this.progressBulk).length > 0
	}

	get disabledButtonSubmit() {
		const isErrorCsv = !!this.localErrorsCSV.error || !!this.localErrorsCSV.info
		return !this.localIsValidForm || this.isUploading || isErrorCsv
	}
	get isInvalidForm() {
		return Object.keys(this.errorsRow(this.name) || {}).length > 0
	}

	beforeDestroy(){
		this.$emit('on-close')
	}

	async submitForm() {
		await this.validateAll({ name: this.name })

		if(this.isInvalidForm) return

		const formData = this.formData.map(row => row.reduce((acc, { key, csvValue, rowId }) => ({
			...acc,
			[key]: csvValue,
			rowId
		}), {}))
		this.$emit('on-submit', { formData })
	}

	closeForm(close) {
		this.$emit('on-close')
		if(typeof close === "function") close()
	}
}
