export {};

declare global {
	interface Array<T> {
		remove(removeItme: T): void;
		removeWhere(removeFunction: (T) => boolean): void;
		firstOrDefault(where: (T) => boolean): T;

		/*
		* Sorts arrray by key (Optionally descending)
		*/
		sortBy(key: keyof T, desc?: boolean): T[];

		/*
		* Creates new array with only distinct members of other array
		*/
		distinct(key: keyof T): T[];
	}
}

if (!Array.prototype.sortBy) {
	Array.prototype.sortBy = function sortBy<T> (this: T[], key: keyof T, desc: boolean = false): T[] {
		return this.sort((a: T, b: T) => {
			const aValue = a[key] as any;
			const bValue = b[key] as any;

			if (typeof a[key] === "string") {
				const compare = aValue.localeCompare(bValue);
				return desc ? compare * -1 : compare;
			} else {
				if (desc) {
					return bValue - aValue;
				} else {
					return aValue - bValue;
				}
			}
		})
	}
}

if (!Array.prototype.distinct) {
	Array.prototype.distinct = function distinct<T> (this: T[], key: keyof T): T[] {
		const newArray = [];
		const addedValues = new Set();

		var i = 0;
		while (i < this.length) {
			if (!addedValues.has(this[i][key])) {
				addedValues.add(this[i][key]);
				newArray.push(this[i]);
			}
			i++;
		}

		return newArray
	}
}

if (!Array.prototype.remove) {
	Array.prototype.remove = function remove<T> (this: T[], removeItem: T): void {
		const removeIndex = this.indexOf(removeItem);
		if (removeIndex > -1) {
			this.splice(removeIndex, 1);
		}
	}
}

if (!Array.prototype.removeWhere) {
	Array.prototype.removeWhere = function removeWhere<T> (this: T[], removeFunction: (T) => boolean): void {
		var i = 0;
		while (i < this.length) {
			if (removeFunction(this[i])) {
				this.splice(i, 1);
			}
			else {
				++i;
			}
		}
	}
}

if (!Array.prototype.firstOrDefault) {
	Array.prototype.firstOrDefault = function T<T> (this: T[], where: (T) => boolean): T {
		if (where) {
			var i = 0;
			while (i < this.length) {
				if (where(this[i])) {
					return this[i];
				}
				i++;
			}
			return null;
		} else {
			return this.length > 0 ? this[0] : null;
		}
	}
}