import { ActionTree } from "vuex";
import { CSVParser } from "./types";
import { RootState } from "@/store/types";
import Papa from "papaparse"

const invalidKeys = {
	undefined: true,
	id: true
}

export const actions: ActionTree<CSVParser, RootState> = {
	setValidHeadersName({ commit }, validHeadersName) {
		commit("putValidHeadersName", validHeadersName);
	},

	setMandatoryFields({ commit }, mandatoryFields) {
		commit("putMandatoryFields", mandatoryFields);
	},

	loadSCVData({ commit }, { name, csvText }) {
		const { data: csvContext } = Papa.parse(csvText, { skipEmptyLines: true });
		const body = csvContext.slice(1);
		const headers = csvContext[0];

		commit("putCSVParsed", {
			name,
			headers: headers && headers.length ? headers : null,
			body: body.length ? body : null
		});
	},

	convertToSCV(store, { body, headers }) {
		const rowSeparator = "\r\n";
		const header = headers.join(",");
		const content = body
			.map(<T extends { [s: string]: string }[]>(v: T) =>
				Object.entries(v)
					.reduce(
						(acc, [key, value]) => (invalidKeys[key] ? acc : [...acc, value]),
						[]
					)
					.join(",")
			)
			.join(rowSeparator);
		return `${header}${rowSeparator}${content}`;
	},

	async linkDownloadCSV({ dispatch, getters }, option) {
		const text = await dispatch("convertToSCV", option);
		return getters.getCsvLinkDownloadByString(text);
	},
}
