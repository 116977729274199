
import { Component, Vue, Prop } from "vue-property-decorator";
import { MobileRaiseLinkEditModel } from "@/store/mobile-raise-templates/types";
import vSelect from "vue-select";
import { uniqueId } from "lodash";
import RoundIcon from "./RoundIcon.vue";
import RaiseTitle from "./RaiseTitle.vue";

@Component({
	components: {
		"vue-select": vSelect,
		"round-icon": RoundIcon,
		"raise-title": RaiseTitle,
	}
})
export default class QuickSelectSlot extends Vue {
	@Prop() slotNumber: number;
	@Prop() disabled: boolean;
	@Prop() options: MobileRaiseLinkEditModel[];
	@Prop() value: MobileRaiseLinkEditModel;
	@Prop() isValid: boolean;
	@Prop() iconClass: string;

	private onInput(option: MobileRaiseLinkEditModel) {
		if (option) {
			this.$emit("input", {
				typeID: option.typeID,
				manualRaiseType: option.manualRaiseType
			});
		} else {
			this.$emit("input", null)
		}
	}

	private get selectInputId() {
		return uniqueId("quick-select-slot-");
	}

	private optionsFilter(options: MobileRaiseLinkEditModel[], searchTerm: string) {
		const termLowerCase = searchTerm.toLowerCase()
		const result = options.filter(o => o.titleList.some(t => t.toLowerCase().includes(termLowerCase)));
		return result;
	}
}
