
import { Component, Watch, Vue } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";

import { EventTask } from "@/store/tasks/types";

import AddNewEventTask from "./AddNewEventTask.vue";
import MultipleAssignToAction from "./MultipleAssignToAction.vue";
import ActionLibrarySimpleList from "./ActionLibrarySimpleList.vue";
import EventTaskGroup from "@/components/tasks/EventTaskGroup.vue";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { UserPermissions } from "@/store/types";

import { stringMixin } from "@/mixins";

const Tasks = namespace("tasks");
const SiteMonitor = namespace("siteMonitor");

const { isNullOrWhitespace } = stringMixin.methods;

// @note
// Entity called "Action" used to be called "Task", and is still
// Called "Task" in API and UI internals. For display purposes
// "tasks" are "actions", please use the new terminology
// whenver doing new work, sorry for the confusion.

@Component({
	components: {
		"add-new-event-task": AddNewEventTask,
		"multiple-assign": MultipleAssignToAction,
		"action-library-simple-list": ActionLibrarySimpleList,
		"event-task-group": EventTaskGroup,
		"vue-perfect-scrollbar": VuePerfectScrollbar
	}
})
export default class TaskContainer extends Vue {
	$refs!: {
		actionLibrary: any;
		actionLibraryList: any;
	};

	@Getter("getPermissions") permissions: UserPermissions;
	@Getter("getCanAddIndividualActionsOverride") canAddIndividualActionsOverride: boolean;

	@Tasks.Getter("getTaskTypes") taskTypes: any;
	@Tasks.Getter("getEventTasks") eventTasks: any;
	@Tasks.Getter selectedActions: any;

	@Tasks.Action("fetchTaskTypes") private fetchTaskTypes: () => Promise<void>;
	@Tasks.Action fetchEventTasks: any;
	@Tasks.Action assignUserToTaskCategory: any;
	@Tasks.Action addTaskToEvent: any;

	@Tasks.Mutation toggleActionListModal: any;

	@SiteMonitor.Mutation setActivity: () => void;
	@SiteMonitor.Getter("getEventShare") eventShares!: any[];

	/**
	 * The binding for a new category title when creating one
	 */
	private newTaskCategoryTitle: string = "";

	/**
	 * Indicates that we're creating a new category & action
	 */
	private addingNewTaskToNewGroup: boolean = false;

	/**
	 * Indicates that the new task being added is required
	 */
	private isNewTaskRequired: boolean = false;

	/**
	 * Indicates that the task category title is invalid, and should show
	 * the error animation
	 */
	private hasValidationErrors: boolean = false;
	private showErrorAnimation: boolean = false;

	private updateTimeout: NodeJS.Timer | null = null;
	private isDestroyed: boolean = false;
	private focusedActionTask: string = null;
	/** Computed Props */

	/**
	 * Gets the event ID for the current event, based on our route.
	 */
	public get eventId() {
		return parseInt(this.$route.params.eventId);
	}

	/**
	 * Checks if the task category currently being added (if any), is valid.
	 */
	public get isTaskCategoryTitleValid() {
		return isNullOrWhitespace(this.newTaskCategoryTitle);
	}

	/**
	 * Checks if the user has permission to add individual actions, or just action groups.
	 */
	public get canAddIndividualActions() {
		return this.canAddIndividualActionsOverride ? true : this.permissions.canAddIndividualActions;
	}

	/** Methods */

	/**
	 * Mounted lifecycle handler, sets up our poll to fetch updated event tasks and resets component state.
	 */
	public async mounted() {
		this.isDestroyed = false;
		await this.fetchTaskTypes();
		this.reset();
		this.$refs.actionLibrary.hide();
		this.updateEventTasks();
	}

	/**
	 * Before Destroyed lifecycle handler, clears the update poll.
	 */
	public beforeDestroy() {
		this.isDestroyed = true;
		if (this.updateTimeout != null) clearTimeout(this.updateTimeout);
	}

	/**
	 * Performs a poll to fetch updated event tasks on a 5 second interval.
	 */
	@Watch("getTaskTypes", { deep : true })
	public updateEventTasks() {
		this.updateTimeout = null;
		// @todo investigate, move to store
		if (this.eventId && !this.isDestroyed) {
			this.fetchEventTasks({ eventId: this.eventId }).then(() => {
				if (!this.isDestroyed) this.updateTimeout = setTimeout(() => this.updateEventTasks(), 5000);
			});
		}
	}

	@Watch("newTaskCategoryTitle")
	private onTaskTitleChange() {
		this.setActivity();
	}

	/**
	 * Resets the state of the inputs within the component.
	 */
	private reset() {
		this.addingNewTaskToNewGroup = false;
		this.newTaskCategoryTitle = "";
		this.setActivity();
	}

	/**
	 * Opens up the action list modal so our user can add actions/action plans to the current event.
	 */
	public onAddNewTaskToGroupFromLibrary() {
		this.reset();
		this.toggleActionListModal();
		this.$refs.actionLibrary.show();
	}

	/**
	 * Adds the tasks the user has selected in the action plan modal to the current event.
	 */
	public onAddTasksToGroupFromLibrary() {
		this.$refs.actionLibraryList.selectedActions.forEach(action => {
			let newEventTask: EventTask = {
				...action,
				required: this.isNewTaskRequired
			};

			this.onSaveTask(newEventTask, false);
		});

		this.$refs.actionLibrary.hide();
		this.setActivity();
	}

	/**
	 * Handler for when the user wishes to add an entirely new group and task.
	 */
	public onAddNewTaskToNewGroup() {
		this.reset();
		this.addingNewTaskToNewGroup = true;
		this.setActivity();
	}

	/**
	 * Handler for when the user clicks the 'cancel' button in the action plan modal.
	 */
	public onCancelAddNewTaskToGroupFromLibrary() {
		this.reset();
		this.$refs.actionLibrary.hide();
		this.setActivity();
	}

	/**
	 * Handler for when the user saves a task/group that they're adding in the container.
	 */
	public onSaveTask(task: EventTask, newGroup: boolean) {
		if (!newGroup || !isNullOrWhitespace(this.newTaskCategoryTitle)) {
			this.addTaskToEvent({
				task,
				eventId: this.eventId
			});

			this.reset();
			this.setActivity();
		} else {
			this.hasValidationErrors = true;
			this.showErrorAnimation = true;

			setTimeout(() => {
				this.showErrorAnimation = false;
			}, 1000);
		}
	}

	private get taskList() {
		if(!this.eventTasks || this.eventTasks.length === 0)
			return [];

		return this.eventTasks.map(taskGroup => {
			return taskGroup.tasks.map((task) => {
				return {...task, taskGroupRef: taskGroup.title + taskGroup.index}
			});
		})
		.flat(1) //converts the array to a 1 dimensional object array
		.map((task, index) =>  {return {...task, index: index}});
	}

	private async focusOnIncompletedGroup() {
		if(!this.eventTasks || this.eventTasks.length === 0)
			return;

		let incompleteTasks = this.taskList.filter(task => !task.completed);

		if(this.focusedActionTask){
			let currentActionTask = this.taskList.find(task => task.eventTaskID === this.focusedActionTask);
			const tasksAfter = currentActionTask ? currentActionTask.index : -1;

			if (!(incompleteTasks && incompleteTasks.length === 1 && incompleteTasks[0].eventTaskID === this.focusedActionTask)){
				let filteredTasks = incompleteTasks.filter(task => task.index > tasksAfter);

				//If not uncompleted tasks after, check before
				if(!filteredTasks || filteredTasks.length === 0)
					filteredTasks.filter(task => task.index < tasksAfter);

				//only set if we have another task otherwise let it remain on the only incompleted task
				if(filteredTasks.length > 0)
					incompleteTasks = filteredTasks;
			}
		}

		//If we don't have any uncompleted tasks left return to the first task
		var nextTask = incompleteTasks.find(task => !task.completed);
		if(!nextTask)
			nextTask = this.taskList[0];

		const groupRef = this.$refs[nextTask.taskGroupRef][0];
		if(!groupRef)
			return;

		//find the ref and focus
		const taskRef = groupRef.$refs["eventTask-"+ nextTask.eventTaskID][0];
		if(!taskRef)
			return;

		this.focusedActionTask = nextTask.eventTaskID;
		taskRef.focus();
	}

	private focusTask(value: string){
		this.focusedActionTask = value;
	}
}
