



































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import vselect3 from "vselect3";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import api from "@/services/api.service";
import AssetStatusInput from "@/components/mobile/assets/AssetStatusInput.vue"
import { UserAssetStatus } from '@/store/mobile/types';

const Mobile = namespace("mobile");

@Component({
	mixins: [validationMixin],
	components: {
		vselect3,
		"asset-status-input" : AssetStatusInput
	},

	validations: {
		formName: {
			userName: {
				required,
			}
		}
	}
})
export default class UserDetails extends Vue {
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter getUserId: number;
	@Mobile.Getter getUserAssetMapDisplayName: string;
	@Mobile.Action setUserAssetStatus:(mapStatus: UserAssetStatus) => Promise<void>;
	@Mobile.Action loadUserAssetMapItem:() => Promise<void>;
	@Mobile.State("UserAssetMapItem") userMapStatus: UserAssetStatus;

	@Prop({ type:Boolean, default: false })
	private hideStatusUpdateButton: boolean;
	private mountedDisplayName: string = "";
	private editDisplayName: boolean = false;
	private userStatusPollingTimeout: NodeJS.Timeout = null;
	private refreshStatusRequired: boolean = false;
	private statusInputsActive: boolean = false;

	private formName = {
		userName: ""
	};

	private userStatus : UserAssetStatus = {
		assetId: 0,
		status: "",
		statusNote: ""
	};

	private currentUserStatus : UserAssetStatus = {
		assetId: 0,
		status: "",
		statusNote: ""
	};

	@Watch("userMapStatus", {deep : true})
	private userMapStatusWatch(): void {
		this.setUserStatusFromCurrentUserStatus();

		if(this.userMapStatus){
			if(!this.userStatusUpdateDisabled || !this.statusInputsActive){
				this.setStatuses();
			}

			if (this.statusChanged) {
				this.refreshStatusRequired = true;
			}
		}
	}

	private setUserStatusFromCurrentUserStatus(): void {
		if(this.currentUserStatus && !this.statusInputsActive){
			this.userStatus = {...this.currentUserStatus}
		}
	}

	// Check if status has been updated by the mobile user
	private get statusChanged(): boolean {
		if (this.currentUserStatus.status != this.userMapStatus.status
		|| this.currentUserStatus.statusNote != this.userMapStatus.statusNote) {
			return true;
		}

		return false;
	}

	private assetStatusActive(): void {
		this.$emit("search:focus");
		this.setStatusInput(true);
	}

	// Sets statusInputsActive property to true or false
	private setStatusInput(value: boolean): void {
		this.statusInputsActive = value;
	}

	private setStatuses(): void {
		const status = {...this.userMapStatus};
		this.currentUserStatus = status;
		this.setUserStatusFromCurrentUserStatus();

		if(this.userStatus.assetId === 0){
			this.userStatus = status;
		}
	}

	// Trigger the status refresh
	private refreshStatus(): void {
		this.setStatuses();
		this.refreshStatusRequired = false;
	}

	public userStatusUpdateDisabled(): boolean {
		if((this.userStatus.status === this.currentUserStatus.status
			&& ((!this.currentUserStatus.statusNote && !this.userStatus.statusNote)
					? true : this.currentUserStatus.statusNote === this.userStatus.statusNote)
			)
			|| (this.userStatus.status && !this.userStatus.status.trim())){
			return true;
		}
		return false;
	}

	private cancelUpdateName(): void {
		this.formName.userName = this.mountedDisplayName;
		this.editDisplayName = false;
	}

	private async updateName(): Promise<void> {
		this.$v.formName.$touch();

		if (this.$v.formName.$invalid) return;

		if(this.formName.userName !== this.mountedDisplayName){
			await api.setUserMapDisplayName(this.formName.userName);
			this.mountedDisplayName = this.formName.userName;
		}

		this.editDisplayName = false;
	}

	private async updateUserMapStatus(): Promise<void> {
		await this.setUserAssetStatus(this.userStatus);
		(this.$refs.recentStatuses as any).reloadStatusList();
		this.refreshStatusRequired = false;
	}

	private close(): void {
		this.$v.$reset();
	}

	private async created(): Promise<void> {
		await this.populateUserData();
	}

	private async mounted(): Promise<void>{
		await this.populateUserData();
	}

	@Watch("openLeftSidebar")
	private async populateUserData(): Promise<void>{
		await this.loadUserAssetMapItem();

		this.formName.userName = this.getUserAssetMapDisplayName;
		this.mountedDisplayName = this.getUserAssetMapDisplayName;
		if(this.currentUserStatus && !this.statusInputsActive){
			this.userStatus = {...this.currentUserStatus}
		}
	}
}
