
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { ExternalMapLayer } from "@/store/external-map-layers/types";
import { FeaturesList, UserPermissions } from "@/store/types";
import { get } from "lodash";

const ExternalMapLayerStore = namespace("externalMapLayers");

@Component({})
export default class ExternalMapLayerSelector extends Vue {

	@Getter("getPermissions") permissions: UserPermissions;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@ExternalMapLayerStore.State("externalMapLayers") layers: ExternalMapLayer[];
	@ExternalMapLayerStore.Action loadExternalMapLayers: () => Promise<void>;
	@ExternalMapLayerStore.Action updateExternalMapLayerVisibility: (externalMapLayer: ExternalMapLayer) => Promise<void>;

	private showExternalLayers: boolean = false;

	private async mounted(): Promise<void> {
		if (this.isExternalMapLayersEnabled) {
			await this.loadExternalMapLayers();
		}
	}

	private get isExternalMapLayersEnabled(): boolean {
		return (
			get(this.featuresList, ["ExternalMapLayers"]) &&
			(this.permissions.canViewExternalMapLayers || this.permissions.canEditExternalMapLayers)
		);
	}

	private async toggleLayerDisplay(mapLayer: ExternalMapLayer): Promise<void> {
		await this.updateExternalMapLayerVisibility(mapLayer);
	}
}
