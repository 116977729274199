
import Vue from 'vue';
import organizationApi from "@/services/api.organizationSettings.ts";
import { Getter } from "vuex-class";
import { TenantSystemPrefs } from "@/store/types";
import Component from "vue-class-component";
import EventOutcomeSelect from "@/components/form/EventOutcomeSelect.vue";
import { get } from "lodash";
import { FeaturesList } from "@/store/types";
import vSelect3 from "vselect3";
import { MaskOption } from '@/store/alarm-masking/types';
import { numberFormattingMixins } from '@/mixins';

const { enforceWholeNumber } = numberFormattingMixins.methods;

@Component({
	components:{
		"event-outcome-select": EventOutcomeSelect,
		"v-select-3": vSelect3,
	}
})

export default class TenantSystemPrefsForm extends Vue {
	// Getters
	@Getter("getFeaturesList") featuresList: FeaturesList;

	public get tenantDefaultMaskingValueEnabled(): boolean {
		return get(this.featuresList, ["TenantSettings", "DefaultMasking"], false);
	}

	public get tenantDefaultParkingValueEnabled(): boolean {
		return get(this.featuresList, ["TenantSettings", "DefaultParking"], false);
	}

	private get isAlarmAgeWarningEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "AlarmAgeWarning"], false);
	}

	public get tenantDefaultMaskActionEnabled(): boolean {
		return get(this.featuresList, ["TenantSettings", "ChangeMaskAction"], false);
	}

	private tenantSystemPrefs: TenantSystemPrefs;

	private tenantSettings: TenantSystemPrefs = {
		defaultMaskValue: 10,
		defaultParkValue: 10,
		alarmAgeWarningSeconds: null,
		defaultMaskAction: false
	};

	private newDefaultMaskValue: number = 10;
	private newDefaultParkValue: number = 10;
	private newAlarmAgeWarningSeconds: number | null = null;
	private newDefaultMaskAction: boolean = false;
	private maxIntPrefValeInteger: number = 2147483647;
	private minIntPrefValeInteger: number = 1;
	private maxMaskDuration: number = 52560;
	private maskOptions: MaskOption[] = [{ title: `Ignore`, value: false }, { title: `AutoHandle`, value: true }];
	private maxParkValueInMinutes: number = 1440; // 24 hours

	private async beforeMount(): Promise<void> {
		try {
			this.tenantSystemPrefs = await organizationApi.retrieveTenantSystemPrefs(true);
		} catch (e) {
			console.log("Failed to get tenant system prefs");
		}

		if (!this.tenantSettings) {
			this.newDefaultMaskValue = null;
			this.newDefaultParkValue = null;
			this.newAlarmAgeWarningSeconds = null;
			this.newDefaultMaskAction = false;
			return;
		}

		this.tenantSettings.defaultMaskValue = this.tenantSystemPrefs?.defaultMaskValue;
		this.tenantSettings.defaultParkValue = this.tenantSystemPrefs?.defaultParkValue;
		this.tenantSettings.alarmAgeWarningSeconds = this.tenantSystemPrefs?.alarmAgeWarningSeconds;
		this.tenantSettings.defaultMaskAction = this.tenantSystemPrefs?.defaultMaskAction;

		this.newDefaultMaskValue = this.tenantSettings?.defaultMaskValue;
		this.newDefaultParkValue = this.tenantSettings?.defaultParkValue;
		this.newAlarmAgeWarningSeconds = this.tenantSettings?.alarmAgeWarningSeconds;
		this.newDefaultMaskAction = this.tenantSettings?.defaultMaskAction;
	}

	private get haveSettingsChanged(): boolean {
		return this.tenantSettings?.defaultMaskValue !== this.newDefaultMaskValue ||
			this.tenantSettings?.defaultParkValue !== this.newDefaultParkValue ||
			this.tenantSettings?.alarmAgeWarningSeconds !== this.newAlarmAgeWarningSeconds ||
			this.tenantSettings?.defaultMaskAction !== this.newDefaultMaskAction;
	}

	private async resetChanges(): Promise<void> {
		this.newDefaultMaskValue = this.tenantSettings.defaultMaskValue;
		this.newDefaultParkValue = this.tenantSettings.defaultParkValue;
		this.newAlarmAgeWarningSeconds = this.tenantSettings.alarmAgeWarningSeconds;
		this.newDefaultMaskAction = this.tenantSettings.defaultMaskAction;
	}

	private get parkState(): boolean{
		if(this.newDefaultParkValue && this.newDefaultParkValue < this.minIntPrefValeInteger){
			return false;
		}

		if(!this.tenantSettings.defaultParkValue)
		{
			return true;
		}

		return this.newDefaultParkValue <= this.maxParkValueInMinutes;
	}

	private get maskState(): boolean{
		if(this.newDefaultMaskValue && this.newDefaultMaskValue < this.minIntPrefValeInteger){
			return false;
		}

		if(!this.tenantSettings.defaultMaskValue)
		{
			return true;
		}

		return this.newDefaultMaskValue <= this.maxMaskDuration;
	}

	private get alarmAgeWarningState(): boolean {
		if (!!this.newAlarmAgeWarningSeconds || this.newAlarmAgeWarningSeconds === 0) {
			return this.newAlarmAgeWarningSeconds >= this.minIntPrefValeInteger && this.newAlarmAgeWarningSeconds <= this.maxIntPrefValeInteger;
		}

		return true;
    }

	private tenantSettingsFormatter(tenantSettingValue: number): number {
		return enforceWholeNumber(tenantSettingValue);
	}

	private async confirmUpdateTenantSettings(): Promise<void> {
		const newTenantSettings: TenantSystemPrefs = {
			defaultMaskValue: this.newDefaultMaskValue,
			defaultParkValue: this.newDefaultParkValue,
			alarmAgeWarningSeconds: this.newAlarmAgeWarningSeconds,
			defaultMaskAction: this.newDefaultMaskAction
		};

		try {
			await organizationApi.createOrUpdateTenantSystemPrefs(newTenantSettings);
		} catch (e) {
			console.log("Failed to add or update tenant system prefs");
		}

		this.tenantSettings.defaultParkValue = newTenantSettings.defaultParkValue;
		this.tenantSettings.defaultMaskValue = newTenantSettings.defaultMaskValue;
		this.tenantSettings.alarmAgeWarningSeconds = newTenantSettings.alarmAgeWarningSeconds;
		this.tenantSettings.defaultMaskAction = newTenantSettings.defaultMaskAction;

		this.$notify({
			type: "success",
			title: "Tenant settings updated"
		});
	}
}
