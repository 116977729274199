import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ResponsesState } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: ResponsesState = getDefaultState();
const namespaced: boolean = true;

export const responses: Module<ResponsesState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
