
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { UserPermissions, RoutingGroup } from "@/store/types";
import { AlarmQueueFilterEditModel, AlarmTag } from "@/store/eventqueue/types";
import { get } from 'lodash'
import { AreaNode } from '@/types/sv-data/groups/AreaNode';
import AreaTreeSelect from './form/AreaTreeSelect.vue';
import { truncateString } from '@/filters';
const Eventqueue = namespace("eventqueue");

const removeWhiteSpace = (value: string) => {
	return  value.toLowerCase().replace(/\s/g, "")
}

interface AreaFilterItem {
	id: number,
	label: string,
	inherited: boolean,
	excluded: boolean
}

@Component({
	mixins: [validationMixin],
	filters:  { truncateString },
	validations() {
		return {
			newFilter: {
				title: {
					required,
					minLength: minLength(1),
					maxLength: maxLength(250),
					validateDuplicate: formTitle => {
						const title: undefined | string = get(this.filter, 'title')?.toLowerCase()
						const isValidTitleEdit = title !== undefined
							                     && (!formTitle || removeWhiteSpace(formTitle) === removeWhiteSpace(title))
						if(isValidTitleEdit) return true

						return this.allAvailableFilters
							.every(({ title }) => removeWhiteSpace(title) !== removeWhiteSpace(formTitle))
					}
				},
				// if value is not empty - the operator is required
				priorityOperator: {
					required: requiredIf(function() {
						return !isNaN(parseInt(this.newFilter.priorityValue, 10));
					})
				},
				numberOfEventsOperator: {
					required: requiredIf(function() {
						return !isNaN(parseInt(this.newFilter.numberOfEventsValue, 10));
					})
				},
				alarmAgeOperator: {
					required: requiredIf(function() {
						return !isNaN(parseInt(this.newFilter.alarmAgeValue, 10));
					})
				}
			}
		}
	},
	components: {
		"vue-select": vSelect,
		"area-tree-select": AreaTreeSelect,
		VuePerfectScrollbar
	}
})
export default class AlarmQueueFilterEdit extends Vue {
	$refs!: {
		modal: any;
	};

	@Prop(Object) filter: any;
	@Prop({ type: Array, default: () => [] }) allAvailableFilters: any[];

	@Eventqueue.Getter isNewFilterModalVisible: boolean;
	@Eventqueue.Getter routingGroups: RoutingGroup[];
	@Eventqueue.Getter alarmTags: AlarmTag[];
	@Eventqueue.Mutation setNewFilterModalShown: any;
	@Eventqueue.Action createAlarmQueueFilter: any;
	@Eventqueue.Action editAlarmQueueFilter: any;
	@Eventqueue.Action loadRoutingGroups: any;
	@Eventqueue.Action loadAlarmTags: any;
	@Eventqueue.Action setActiveFilter: any;
	@Eventqueue.Action fetchGroupsForFilter: any;

 	// Getters
	@Getter getPermissions: UserPermissions;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	public defaultFilter: AlarmQueueFilterEditModel = {
		title: "",
		groups: [],
		groupID: null,
		priorityValue: null,
		priorityOperator: "Greater than",
		numberOfEventsValue: null,
		numberOfEventsOperator: "Greater than",
		alarmTagID: null,
		alarmAgeValue: null,
		alarmAgeOperator: "Greater than",
		shared: false,
		combinedFilters: false
	};

	public newFilter: any = {};
	public comparisonOperators: string[] = ["Greater than", "Less than", "Equal to"];
	public hasValidationErrors: boolean = false;
	public showErrorAnimation: boolean = false;
	public showAdvancedCriteria: boolean = false;
	private isLoading: boolean = false;
	private areaList: AreaFilterItem[] = [];

	@Watch("isNewFilterModalVisible")
	public onEventDetailsShownChanged(value: boolean) {
		if (value) {
			this.prepareInitialData().then(() => {});
			this.$refs.modal.show();
		} else {
			this.$refs.modal.hide();
		}
	}

	@Watch("newFilter.title")
	public onCallsignChanged(title: string) {
		this.hasValidationErrors = !title.length;
	}


	private areaSelected(selectedArea: AreaNode): void {
		if (!selectedArea) {
			return;
		}

		var groupIndex = this.areaList.findIndex((area: any) => area.id == selectedArea.id);
		if (groupIndex < 0 || groupIndex === null || groupIndex === undefined) {
			this.areaList.push({
				id: selectedArea.id,
				label: selectedArea.label,
				inherited: !!selectedArea.children,
				excluded: false
			});
		} else {
			this.areaList.splice(groupIndex, 1);
		}
	}

	public get submitButtonTitle() {
		return this.filter ? "Save" : "Add";
	}

	public get alarmTag() {
		return typeof this.newFilter.alarmTagID === "number"
			? this.alarmTags.filter(tag => tag.responseAlarmTypeID === this.newFilter.alarmTagID)[0]
			: null;
	}

	public set alarmTag(value: AlarmTag | null) {
		this.newFilter.alarmTagID = value ? value.responseAlarmTypeID : value;
	}

	public mounted() {
		this.$v.$reset();
		this.setNewFilterModalShown(false);
		this.loadRoutingGroups();
		this.loadAlarmTags();
	}

	public async prepareInitialData() {
		this.newFilter = { ...this.defaultFilter };

		this.areaList = [];
		if (this.filter) {
			this.newFilter = {
				...this.newFilter,
				...this.filter
			};

			if (this.newFilter.groups) {
				const filterGroups = await this.fetchGroupsForFilter(this.newFilter.alarmQueueFilterID);
				if (filterGroups) {
					this.newFilter.groups.forEach((filterGroup: any) => {
						let groups = filterGroups.filter((listGroup: { groupID: any; }) => listGroup.groupID == filterGroup.groupID);
						if (groups && groups.length > 0) {
							this.areaList.push({
								id: filterGroup.groupID,
								label: groups[0].title,
								inherited: filterGroup.inherited,
								excluded: filterGroup.excluded
							});
						}
					});
				}
			}

			this.showAdvancedCriteria = true;
		}
	}

	@Emit("submit")
	public async onSubmit() {
		if (this.isLoading) {
			return;
		}

		this.isLoading = true;
		try
		{
			this.$v.$touch();

			if (this.$v.newFilter.$error) {
				this.showErrorAnimation = true;

				setTimeout(() => {
					this.showErrorAnimation = false;
				}, this.$config.ANIMATION_DURATION);
			} else {
				//Map the groups to the required Api format
				this.newFilter.groups = this.areaList.map((area: {id: number, inherited: boolean, excluded:boolean}) => {
					return {
						groupID: area.id,
						inherited: area.inherited,
						excluded: area.excluded
					}
				});
				this.newFilter.created = new Date().toISOString();

				const saveFn = this.filter ? "editAlarmQueueFilter" : "createAlarmQueueFilter";
				const alarmQueueFilterID = await this[saveFn](this.newFilter);
				this.setNewFilterModalShown(false);

				this.setActiveFilter({
					filterId: alarmQueueFilterID,
					regionId: null
				});

				return alarmQueueFilterID;
			}
		}
		catch(ex)
		{
			throw ex;
		}
		finally
		{
			this.isLoading = false;
		}
	}

	@Emit("cancel")
	public onCancel() {
		this.setNewFilterModalShown(false);
		this.$v.$reset()
		return;
	}

	// Getter Methods
	private get excludedFeatureEnabled(): boolean {
			return this.getFeature(["Alarms", "EventQueue", "ExcludedFilters"]);
		}
}
