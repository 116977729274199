import { ActionTree } from "vuex";
import { RootState } from "../types";
import { MobileRaiseState } from "./types";
import * as api from "@/services/api.mobileRaiseTemplates.service";

export const actions: ActionTree<MobileRaiseState, RootState> = {
	async loadTemplateProjections({ commit }) {
		commit("setIsProjectionsLoaded", false);
		const projections = await api.loadTemplateProjections();
		commit("setTemplateProjections", projections);
		commit("setIsProjectionsLoaded", true);
	}
};
