
// Vue
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, integer, required } from "vuelidate/lib/validators";
import { get } from "lodash";
import { MapLayer } from "@/store/map-layers/types";
import api from "@/services/api.service";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { State } from "vuex-class";
import { FeaturesList } from "@/store/types";
import ElevationSelect from "@/components/form/ElevationSelect.vue"

@Component({
	mixins: [validationMixin],
	validations: {
		updatedMapLayer: {
			title: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(250)
			},
			groupId: {
				required
			},
			minElevation: {
				required,
				integer
			},
			maxElevation: {
				integer
			},
			nw: {
				required
			}
		},
		file: {
			required
		}
	},
	components: {
		"area-tree-select" : AreaTreeSelect,
		"elevation-select": ElevationSelect
	}
})
export default class MapLayerSetup extends Vue {
	@Prop({ default: null }) public mapLayer: MapLayer;
	@Prop({ default: null }) public dimensions: object;
	@Prop({ default: 1 }) public viewingFloor: number;
	@Prop({ default: false }) public isEditing: boolean;

	@State private featuresList: FeaturesList;

	private mapLayerTemplate: MapLayer = null;
	private updatedMapLayer: MapLayer = {
		mapLayerId: 0,
		groupId: null,
		image: "",
		title: "",
		sw: "",
		se: "",
		ne: "",
		nw: "",
		rotation: 0,
		minElevation: 0,
		maxElevation: 0,
		calculatedNw: "",
		calculatedNe: "",
		calculatedSe: "",
		calculatedSw: "",
	};
	private isLoading: boolean = false;
	private isDeleteMapLayerVisible: boolean = false;
	private selectedFloor: number = 1;
	private isChangingImage: boolean = false;
	private file = null;
	private previewImgSrc = null;

	private readonly IMAGE_SIZE_LIMIT = 4194304; // 4MB

	private mounted() {
		this.onMapLayerUpdated(this.mapLayer);

		this.selectedFloor = this.viewingFloor; 

		this.mapLayerTemplate = Object.assign({}, this.updatedMapLayer);
	}

	private get isMapSetupElevationsEnabled(): boolean {
		return get(this.featuresList, ["MapSetup", "Elevations"]);
	}

	private get isMapSetupUseElevationLabels(): boolean {
		return get(this.featuresList, ["MapSetup", "ElevationLabels"]);
	}

	private getDefaultValueForElevationOptions(elevation: number): number {
		if (this.isEditing) {
			return elevation;
		} else {
			return this.viewingFloor
		} 
	}

	@Watch("mapLayer", { deep: true })
	private onMapLayerUpdated(mapLayer: MapLayer) {
		if (!mapLayer) {
			return;
		}
		this.updatedMapLayer = { ...mapLayer };
		if (this.updatedMapLayer.image && !this.updatedMapLayer.image.includes("data:image")) {
			this.updatedMapLayer.image = "data:image/png;base64, " + this.updatedMapLayer.image;
		}
	}

	@Watch("selectedFloor")
    private onSelectedFloorUpdated() {
        if (this.isMapSetupUseElevationLabels) {
            if(this.isMapSetupElevationsEnabled)
            {
                this.mapLayer.minElevation = this.updatedMapLayer.minElevation;
                this.mapLayer.maxElevation = this.updatedMapLayer.maxElevation;
            }
            else
            {
				this.updatedMapLayer.minElevation = this.selectedFloor;
            	this.updatedMapLayer.maxElevation = this.selectedFloor;
            }
        } else{
			this.updatedMapLayer.minElevation = this.selectedFloor - 1;
            this.updatedMapLayer.maxElevation = this.selectedFloor - 1;
        }
		
		this.layerInfoUpdate();
    }

	private async saveMapLayerDetails() {
		this.isLoading = true;
		let updatedLayer = { ...this.updatedMapLayer };

		if (!updatedLayer.mapLayerId) {
			updatedLayer.mapLayerId = 0;
		}

		if (this.file && this.file.size > this.IMAGE_SIZE_LIMIT) {
			this.$notify({
				type: "error",
				title: "Error",
				text: `Invalid image size. Limit is ${this.IMAGE_SIZE_LIMIT / 1024 / 1024}MB.`
			});
			this.isLoading = false;
			return;
		} else if (this.file) {
			updatedLayer.image = this.file;
		} else {
			// @ts-ignore
			updatedLayer.image = this.updatedMapLayer.image ? this.dataURItoBlob(this.updatedMapLayer.image) : undefined;
		}

		let formData = new FormData();

		for (let [name, value] of Object.entries(updatedLayer)) {
			formData.append(name, value as any);
		}

		try {
			updatedLayer = await api.putMapLayer(formData);
			this.$emit("mapLayerSaved", updatedLayer);
		} catch (error) {
			const errorTitle = "Error Saving Map Layer";
			const permissionsErrorAction = this.updatedMapLayer.mapLayerId === 0 ? "create" : "update";
			if (error.response &&
				error.response.data) {
				switch(error.response.status) {
					case 403:
						this.notifyError(errorTitle, `You do not have permissions to ${permissionsErrorAction} Map Layers`);
						break;
					case 500:
						this.notifyError(errorTitle, "Unexpected error saving map layer. Please contact support if this problem persists.");
						break;
					case 400:
						this.notifyError(errorTitle, error.response.data);
						break;
				}
			}
			console.error(error);
		} finally {
			this.isLoading = false;
		}
	}

	private dataURItoBlob(dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		let byteString;
		if (dataURI.split(",")[0].indexOf("base64") >= 0) {
			byteString = atob(dataURI.split(",")[1]);
		} else {
			byteString = unescape(dataURI.split(",")[1]);
		}

		// separate out the mime component
		let mimeString = dataURI
			.split(",")[0]
			.split(":")[1]
			.split(";")[0];

		// write the bytes of the string to a typed array
		let ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	}

	private resetMapLayerData() {
		this.updatedMapLayer = Object.assign({}, this.mapLayerTemplate);
		this.isChangingImage = false;
		this.previewImgSrc = null;
		this.$emit("mapLayerEditToggled");
		if (this.updatedMapLayer.mapLayerId === 0) {
			this.closeMapLayerSetup();
		} else {
			this.$emit("layerImageChanged", this.updatedMapLayer);
		}
	}

	private closeMapLayerSetup() {
		this.$emit("closeLayerSetup");
	}

	private deleteMapLayerDialog() {
		this.isDeleteMapLayerVisible = true;
	}

	private async deleteLayer(): Promise<void> {
		try {
			this.isLoading = true;
			await api.deleteMapLayer(this.updatedMapLayer.mapLayerId);
			this.$emit("mapLayerDeleted", this.updatedMapLayer.mapLayerId);
			this.isDeleteMapLayerVisible = false;
		} catch (error) {
			const errorTitle = "Error Deleting Map Layer";
			if (error.response && 
				error.response.data) {
				switch (error.response.status) {
					case 403:
						this.notifyError(errorTitle, `You do not have permissions to delete this map layer`);
						break;
					case 500:
						this.notifyError(errorTitle, "Unexpected error deleting map layer. Please contact support if this problem persists.");
						break;
				}
			}
			console.error(error);
		} finally {
			// errors will be handled by interceptors - no catch needed.
			this.isLoading = false;
		}
	}

	private onMapLayerImageClick() {
		if (this.isEditing) {
			this.isChangingImage = !this.isChangingImage;
		}
	}

	private notifyError(title: string, text: string) : void {	
		this.$notify({
			type: "error",
			title: title,
			text: text
		});
	}

	@Watch("file")
	private onFileChanged(val) {
		if (!val) {
			return;
		}

		if (this.previewImgSrc) {
			this.previewImgSrc = null;
		}

		const fileReader = new FileReader();

		fileReader.onload = e => {
			this.previewImgSrc = e.target.result;
			// @ts-ignore
			this.updatedMapLayer.image = e.target.result;
			this.$emit("layerImageChanged", this.updatedMapLayer);
		};

		fileReader.readAsDataURL(this.file);
	}

	private layerInfoUpdate(): void {
		this.$emit("layerInfoChanged", this.updatedMapLayer);
	}
}
