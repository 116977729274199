
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep, get } from 'lodash';
import vSelect from "vue-select";
import { ActionOption } from "@/store/rules-engine/types";
import { Getter } from "vuex-class";
import { FeaturesList } from "@/store/types";

@Component({
		components: {
			"vue-select": vSelect
		}
	})
export default class RulesSelector extends Vue {
    //Move all getters into a vuex store
    private get triggerOptions(): any[] {
        return [
            {
                id: 1,
                title: "Device Type",
                excludes: [1,4,5,6,7]
            },
            {
                id: 2,
                title: "Alarm Type",
                excludes: [1,4,5,6,7]
            },
            {
                id: 3,
                title: "X seconds after the Scope was triggered"
            },
            {
                id: 4,
                title: "Device",
                excludes: [4],
                requires: [1, 4, 5, 6, 7]
            },
            {
                id: 5,
                title: "Event Type",
                excludes: [5, 4],
                requires: [1, 4, 5, 6, 7]
            },
            {
                id: 6,
                title: "Input 1",
                excludes: [6],
                requires: [1, 4, 5, 6, 7]
            },
            {
                id: 7,
                title: "Input 2",
                excludes: [7],
                requires: [1, 4, 5, 6, 7]
            },
            {
                id: 8,
                title: "X seconds before the Scope was triggered",
            },
        ];
    }

	private get defaultActionOptions(): ActionOption[] {
        return [
            {
                id: 1,
                title: "Change Priority"
            },
            {
                id: 2,
                title: "Action Plan"
            },
            {
                id: 3,
                title: "Combine Alarms"
            },
            {
                id: 4,
                title: "Close Event"
            }
        ];
	}

    private get actionOptions(): ActionOption[] {
		const options = this.defaultActionOptions;

		if (this.isActionPlanRegexEnabled) {
			options.push({
                id: 5,
                title: "Action Plan Regex"
            });
		}

		return options;
    }

    private get scopeOptions(): any[] {
        return [
            {
                id: 1,
                title: "Device Type"
            },
            {
                id: 2,
                title: "Alarm Type"
            }
        ];
    }

    private get selectorOptions(): any
    {
        switch(this.conditionSection){
            case "Trigger":
                    return this.triggerOptions
                        .filter(to =>
                            (!to.requires || to.requires.includes(this.parentTypeId))
                            && (!to.excludes || !to.excludes.includes(this.parentTypeId))
                        );
            case "Action":
                return this.actionOptions;
                //TODO: Filter out any action options that have a limit of 1
            case "Scope":
                return this.scopeOptions;
                //TODO: Filter out any scope options that are dependent on another field
            default:
                return [];
        }
    }

    //Action, Trigger, Scope
    @Prop()
    private conditionSection: string;

    @Prop()
    private value: any;

    @Prop()
    private parentTypeId: number;

	@Prop({ type: Boolean, default: false })
	private readOnly: boolean;

	@Getter("getFeaturesList") featuresList: FeaturesList;

    private selectedValue: any = null;

    private mounted() {
        this.valueUpdated();
    }

	private get isActionPlanRegexEnabled(): boolean {
		return get(this.featuresList, ["RulesEngine", "ActionPlanRegex"])
	}

    @Watch("value")
    private valueUpdated() {
        if(this.value == null) {
            this.selectedValue = null;
        }
        else if(this.value == this.selectedValue?.id) {
            this.$emit("isValid", { isValid: true, message: "" });
            return;
        }
        else {
            var optionMatch = this.selectorOptions.firstOrDefault(so => so.id == this.value)

			if (!optionMatch && !!this.value) {
            	this.$emit("isValid", { isValid: false, message: "Selected option is not available" });
				return;
			}

			this.selectedValue = optionMatch ? cloneDeep(optionMatch) : null;
        }
		this.$emit("isValid", { isValid: true, message: "" });
    }

    @Emit("input")
    private selectedValueUpdated(value) {
        if(value) {
            return value.id;
        }
        return null;
    }
}
