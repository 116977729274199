
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import { get, invert, Dictionary } from "lodash";

import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { FeaturesList, PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
import api from "@/services/api.service";
import CameraSelect from "@/components/form/CameraSelect.vue";
import { truncateString } from "@/filters";
import { ModalItemCSV } from "@/store/csv-form-handler/types";
import csvUploaderWrapper, { DefaultFile } from "@/components/csv-uploader/csv-uploader-wrapper.vue";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import PaginatedSearch from "@/mixins/PaginatedSearch";
import ResponsesPagedResponse from "@/types/sv-data/responses/ResponsesPagedResponse";
import { AlarmDecoderServer } from "@/store/devices/types";
import ResponseExtended from "@/types/sv-data/alarms/ResponseExtended";
import { AlarmGrouping, Script, YesNoOption } from '@/store/responses/types';
import ResponseActionSetup from "./ResponseActionSetup.vue";
import { ResponseAction } from "@/store/response-actions/types";
import AutomationMixin from "@/mixins/AutomationMixin";
import CustomFieldTableView from "@/components/custom-fields/CustomFieldModalTableView.vue";
import { CustomFieldDto, CustomFieldTableTypes } from "@/store/custom-fields/types";
import { MapCustomFieldToModalItem } from "@/utils/CustomFieldLogic";
import { customFieldMixins } from "@/mixins/customFieldMixins";

const Areas = namespace("areas");
const CustomFields = namespace("customFields");

const { mapArrayFieldsToObject, mapObjectFieldsToArray } = customFieldMixins.methods;

interface Server {
    serverId: number,
    title: string
}

interface ResponseRow extends ResponseExtended {
    readOnly?: boolean;
    readOnlyMessage?: string;
    grouping: AlarmGrouping;
    script: Script;
    rowId: number;
    systemIdentifier: SystemIdentifierOption;
    automations?: ResponseAction[];
}

interface SystemIdentifierOption {
    responseId: number;
    title: string;
    extraValue: string;
}

@Component({
    components: {
        "generic-table": GenericTable,
        "csv-uploader-wrapper": csvUploaderWrapper,
		"custom-field-modal-table-view": CustomFieldTableView
    },
    filters: {
        truncateString
    }
})
export default class HttpAlarmSetupTable extends Mixins(PaginatedSearch, AutomationMixin) {
	$refs!: {
		customFieldTableView: CustomFieldTableView;
	}

    @Prop({ required: false, default: null }) private selectedServerId: number;
    @Prop({ required: false, default: false }) private readonly: boolean;

    @Getter("getFeaturesList") featuresList: FeaturesList;

    @Areas.Action fetchAreaDictionary: () => Promise<void>;
    @Areas.Getter getAreaTitle: (id: number) => string;
    @Areas.State areaDictionary: Map<number, string> [];

	@CustomFields.State private alarmCustomFields: CustomFieldDto[];
	@CustomFields.Action retrieveCustomFields: ({ tableType, live }) => Promise<void>;

    @Getter("getPermissions") private permissions: UserPermissions;

    private get hasAlarmSetupEditAreaFlag(): boolean {
        return get(this.featuresList, ["AlarmSetup", "EditArea"])
    }

    private totalRecords: number = 0;
    private isLoading: boolean = false;

    private responses: ResponseRow[] = [];
    private responseScripts: Script[] = [];
    private progressBulk = {}
    private alarmDecoderServers: AlarmDecoderServer [] = [];
    private selectedResponse: any = {}; // Find a way to pass group id into automations without using any
    private tooltipToShow: string = "";
    private httpReceiverApiKey: string = "";

	private readonly customFieldTableType: number = CustomFieldTableTypes.Alarm;

    /**
    *	@returns Table columns
    */
    public get columns (): TableHeader[] {
        var columns: TableHeader[] = [
            {
                title: "Alarm Name",
                key: "title",
                order: 1,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "Alarm name",
                searchable: true,
                visible: true,
                dataType: "input",
                isTermLabel: true,
                sortable: true,
                isSortedByDefault: true,
            },
            {
                title: "Area",
                key: "groupTitle",
                order: 2,
                sortOrder: 1,
                sortOrderReversed: false,
                description: "The area the alarm belongs to",
                searchable: true,
                visible: true,
                dataType: "input",
                sortable: true,
                sortKey: "Area",
            },
            {
                title: "Action Plan",
                key: "scriptTitle",
                order: 3,
                sortOrder: 3,
                sortOrderReversed: false,
                visible: true,
                searchable: true,
                sortable: true,
                sortKey: "ActionPlan"
            },
            {
                title: "System Identifier",
                key: "extraValue",
                order: 4,
                sortOrder: 4,
                sortOrderReversed: false,
                visible: true,
                searchable: true,
                addQuickCopy: true,
                useCustomCell: true,
                sortable: true,
            },
            {
                title: "Response Code",
                key: "responseCode",
                order: 5,
                sortOrder: 5,
                sortOrderReversed: false,
                description: "The Response code of the alarm",
                searchable: true,
                visible: false,
                dataType: "input",
                sortable: true,
            },
            {
                title: "Priority",
                key: "priority",
                order: 6,
                sortOrder: 6,
                sortOrderReversed: false,
                description: "priority of the alarm",
                searchable: true,
                visible: true,
                dataType: "input",
                sortable: true,
            },
            {
                title: "Grouping",
                key: "grouping",
                order: 7,
                sortOrder: 7,
                sortOrderReversed: false,
                visible: true,
                searchable: true,
                useCustomCell: true,
                sortable: true,
            },
            {
                title: "Linked Cameras",
                key: "linkedCameras",
                order: 8,
                sortOrder: 8,
                sortOrderReversed: false,
                dataType: "list",
                searchable: true,
                useCustomCell: true,
				visible: !get(this.featuresList, ["Alarms", "CameraAutomation"]),
                disable: !get(this.featuresList, ["Devices"]),
            },
        ];

        if(this.showAutomations){
            columns.push(
                {
                    title: "Automations",
                    key: "automations",
                    order: 9,
                    sortOrder: 9,
                    sortOrderReversed: false,
                    dataType: "list",
                    searchable: true,
                    useCustomCell: true,
                    visible: this.showAutomations,
                    disable: !get(this.featuresList, ["Alarms", "CameraAutomation"]), // ToDo: this needs to be set by permission canEditAutomation
                }
            );
        }

        if (this.showMaskDurationLimit){
            columns.push(
                {
                    title: "Max Mask Duration (minutes)",
                    key: "maskDurationLimit",
                    order: 10,
                    sortOrder: 10,
                    sortOrderReversed: false,
                    searchable: false,
                    visible: this.showMaskDurationLimit,
                    disable: !get(this.featuresList, ["Alarms", "Masking", "MaxMaskDuration"]),
                    dataType: "number",
                    sortable: false
                }
            );
        }

        return columns;
    }

    /**
    *	@returns Model items list
    */
    public get modalItems (): ModalItemCSV[] {
		var items: ModalItemCSV[] = [
            {
                title: "Alarm name",
                key: "title",
                dataType: "text",
                maxLength: 250,
                required: true,
                placeholder: "Enter an alarm name",
                csvComponent: 'input',
                csvValidation: {
                    validationCell({ currentElement }) {
                        return { isValid: !!currentElement.csvValue }
                    }
                },
				order: 1
            },
            {
                title: "Area",
                key: "groupID",
                dataType: "component",
                newOnly: !this.hasAlarmSetupEditAreaFlag,
                required: true,
                data: AreaTreeSelect,
                props: {
                    reduce: (a) => a.id,
                    clearable: false
                },
                csvComponent: 'select',
                csvData: Object.keys(this.areasByTitle),
                csvValidation: {
                    validationCell: ({ currentElement }) => {
                        return {
                            isValid: !!this.areasByTitle[currentElement.csvValue]
                        }
                    },
                },
				order: 2
            },
            {
                title: "Linked cameras",
                key: "linkedCameras",
                dataType: "component",
                required: false,
                visible: this.showLinkedCamerasInModal,
                data: CameraSelect,
                props: {
                    multiple: true
                },
                csvExclude: true,
				order: 3
            },
            {
                title: "Action Plan",
                key: "script",
                dataType: "vselect3",
                data: this.responseScripts,
                required: false,
                placeholder: "Select an action plan",
                csvComponent: 'select',
                csvData: this.responseScripts.map( v => v.title),
                csvValidation: {
                    validationCell: ({ currentElement }) => {
                        if(this.responseScripts.length === 0) {
                            return { isValid: !currentElement.csvValue }
                        }

                        return { isValid: this.responseScripts.some(({ title }) => title === currentElement.csvValue) }
                    }
                },
				order: 4
            },
            {
                title: "System Identifier",
                key: "systemIdentifier",
                dataType: "vselect3",
                data: this.getSystemIdentifierOptions(),
                defaultValue: { responseId: 0, title: "Auto Generated"},
                description: "Identifier for the alarm, can be reused against multiple response codes",
                visibleMethod: () => { return this.responses.length > 0 || this.alarmDecoderServers.length > 0},
                readOnly: false,
                updateOnly: false,
                csvExclude: true,
                required: true,
                readOnlyIfEdit: true,
				order: 5
            },
            {
                title: "Alarm Decoder",
                key: "server",
                dataType: "vselect3",
                data: this.alarmDecoderServers,
                readOnlyIfEdit: true,
                placeholder: "Select an alarm decoder",
                description: "Used to decode the provided alarm text in a http request - automatically extracts response and event codes.",
                visibleMethod: (value) => { return value?.systemIdentifier?.title == "Use Alarm Decoder" },
                requiredMethod: (value) => { return value?.systemIdentifier?.title == "Use Alarm Decoder" },
                csvData: this.alarmDecoderServers.map(v => v.serverID),
                csvComponent: 'select',
				order: 6
            },
            {
                title: "Response Code",
                key: "responseCode",
                description: "Identifier for what alarm response to use when raising an alarm",
                dataType: "text",
                maxLength: 100,
                visibleMethod: (value) => { return this.isResponseCodeFieldVisible(value) },
				/* If the ResponseCode field is visible then it is required */
                requiredMethod: (value) => { return this.isResponseCodeFieldVisible(value) },
                placeholder: "Enter a response code",
                csvComponent: 'input',
				order: 7
            },
            {
                title: "Priority",
                key: "priority",
                dataType: "number",
                defaultValue: null,
                min: 0,
                max: 2147483647,
                required: true,
                placeholder: "Enter the alarm priority",
                csvComponent: 'number',
                csvValidation: {
					validationCell({ currentElement }) {
						return {
							isValid: currentElement.csvValue
								? Number(currentElement.csvValue) <= 2147483647 && Number(currentElement.csvValue) >= 0
								: true
						}
					}
				},
				order: 8
            },
            {
                title: "Grouping",
                key: "grouping",
                dataType: "vselect3",
                data: this.alarmGroupingOptions,
                required: true,
                placeholder: "Select an alarm grouping",
                csvData: this.alarmGroupingOptions.map( v => v.title),
                csvComponent: 'select',
                csvValidation: {
                    validationCell: ({ currentElement }) => {
                        return { isValid: this.alarmGroupingOptions.some(({ title }) => title === currentElement.csvValue) }
                    }
                },
				order: 9
            },
            {
                title: "Auto Handle",
                key: "autoHandle",
                dataType: "vselect3",
                data: this.yesNoNotSet,
                required: true,
                placeholder: "Select an auto handle setting",
                props:
				{
					reduce: (ah: YesNoOption) => ah.value
				},
                csvData: this.yesNoNotSet.map(v => v.title),
                csvComponent: 'select',
                csvValidation: {
                    validationCell: ({ currentElement }) => {
                        return { isValid: this.yesNoNotSet.some(({ title }) => title === currentElement.csvValue) }
                    }
                },
				order: 10
            }
		];

        if (this.showAutomations) {
            items.push({
                title: "Automations",
                key: "automations",
                dataType: "component",
                required: false,
                visible: this.showAutomations,
                data: ResponseActionSetup,
                csvExclude: true,
                updateOnly: true,
                props: {
                    groupID: this.selectedResponse.groupID
                },
				order: 11
            });
        }

        if (this.showMaskDurationLimit) {
            items.push({
                title: "Max Mask Duration (minutes)",
                key: "maskDurationLimit",
                dataType: "number",
                maxLength: 10,
                min: 0,
                csvComponent: 'input',
                visible: this.showMaskDurationLimit,
				order: 12
            });
        }

		if (this.isCustomFieldAlarmsEnabled && this.alarmCustomFields != null){
			var shellModalItem: ModalItemCSV = {
				title: "",
				key: "",
				dataType: "",
				readOnly: false,
				order: items.length + 1,
				csvComponent: null
			};

			for (let i = 0; i < this.alarmCustomFields.length; i++) {
				let alarmCustomFieldModalItem = MapCustomFieldToModalItem(this.alarmCustomFields[i], shellModalItem);
				items.push(alarmCustomFieldModalItem as ModalItemCSV);
			}
		}

		return items;
    }

    private alarmGroupingOptions: AlarmGrouping[] = [
        {
            title: "By Area",
            isRaiseIndividual: false,
            isRaiseGrouped: false
        },
        {
            title: "By Alarm",
            isRaiseIndividual: false,
            isRaiseGrouped: true
        },
        {
            title: "Not Grouped",
            isRaiseIndividual: true,
            isRaiseGrouped: false
        },
        {
            title: "Not Set",
            isRaiseIndividual: null,
            isRaiseGrouped: null
        }
    ];

    private yesNoNotSet: YesNoOption[] = [
        {
            title: "Yes",
            value: 1,
			actualValue: true
        },
        {
            title: "No",
            value: 2,
			actualValue: false
        },
        {
            title: "Not set",
            value: 3,
			actualValue: null
        }
    ];

    private get isBulkAddEnabled() : boolean {
        return get(this.featuresList, ["AlarmSetup", "BulkHttpAdd"]) && !this.readonly;
    }

	private get showAutomations(): boolean {
		return !!get(this.featuresList, ["Alarms", "CameraAutomation"]) &&
		(!!this.permissions.canViewAutomations || !!this.permissions.canEditAutomations);
	}

	private get showLinkedCamerasInModal(): boolean {
		return !!get(this.featuresList, ["Devices"]) && !get(this.featuresList, ["Alarms", "CameraAutomation"]);
	}

	private get showMaskDurationLimit(): boolean {
		return get(this.featuresList, ["Alarms", "Masking", "MaxMaskDuration"], false);
	}

	private get isCustomFieldAlarmsEnabled(): boolean {
		return get(this.featuresList, ["CustomFields", "Alarms"]);
	}

	public get hasCanViewCustomFieldsPermission(): boolean {
		return this.permissions.canViewCustomFields;
	}

    private async retrieveAlarmDecoderServers(): Promise<void> {
        let alarmDecoderServers = await api.getAlarmDecoderServerList();
        if(!alarmDecoderServers || alarmDecoderServers.length == 0){
            this.alarmDecoderServers = [];
            return;
        }

        this.alarmDecoderServers = [];
        for (const [key, value] of Object.entries(alarmDecoderServers)) {
            this.alarmDecoderServers.push({
                title: value.toString(),
                serverID: parseInt(key)
            });
        }
    }

    private async mounted(): Promise<void> {
        this.responseScripts = await api.getResponseScripts();
        await this.updateResponses();
        if (this.responses.length > 0) {
            const organizationSettings = await api.getOrganizationSettings();
            const httpReceiverApiKey = organizationSettings.apiKeySettings.find(x => x.keyType === "HttpReceiver");
            if (httpReceiverApiKey) {
                this.httpReceiverApiKey = httpReceiverApiKey.keyValue;
            } else {
                this.httpReceiverApiKey = await api.createHttpAlarmReceiverApiKey();
            }
        }

		if (this.isCustomFieldAlarmsEnabled) {
			await this.retrieveCustomFields({ tableType: CustomFieldTableTypes.Alarm, live: true });
		}

        await this.fetchAreaDictionary();
    }

    private async putAlarm(response: ResponseRow): Promise<void> {
        if (response.script) {
            response.scriptID = response.script.scriptID;
            response.scriptTitle = response.script.title;
        } else {
            response.scriptID = null;
            response.scriptTitle = null;
        }

        //Converts bulk upload autohandle setting to actual setting
		if (typeof(response.autoHandle) === "string")
		{
            const ahTitle = response.autoHandle as string;
			response.autoHandle = this.yesNoNotSet.find(yn => yn.title == ahTitle)?.value
		}

		//Converts the autohandle to its actual value
		if (typeof(response.autoHandle) === "number")
		{
			response.autoHandle = this.yesNoNotSet.find(yn => yn.value == response.autoHandle)?.actualValue
		}

        response.isRaiseIndividual = response.grouping.isRaiseIndividual;
        response.isRaiseGrouped = response.grouping.isRaiseGrouped;

        if (response.server) {
            response.serverID = response.server?.serverID;
            // if its a new alarm and the serverID isn't set clear out the response code
            if(!response.serverID && !response.responseID){
                response.responseCode = null;
            }
        }

        response.extraValue = response.systemIdentifier.extraValue;

		if (this.isCustomFieldAlarmsEnabled) {
			response.customFields = mapObjectFieldsToArray(response, "id", "value", "cf");
		} else {
			response.customFields = null;
		}

        if (!response.responseID) {
            try {
                await api.createNewHttpResponse(response);
            }
            catch (error) {
                this.$notify({
                    type: "error",
                    title: "Failed to create alarm",
                    text: error.response.data
                });
            }
            const isFirstHttpResponse = this.responses.length === 0;
            if (isFirstHttpResponse) {
                this.httpReceiverApiKey = await api.createHttpAlarmReceiverApiKey();
            }
        } else {
            await api.updateResponse(response);
            await this.updateAutomations(response.responseID, response.automations ?? [], this.selectedResponse.automations ?? []);
        }
        await this.updateResponses(this.mostRecentSearchParams);
        this.$emit("triggerUpdate");
    }

	private async updateAutomations(responseId: number,newAutomations: ResponseAction[], oldAutomations:  ResponseAction[]){
		// Cant use foreach loop as it does not play nicely with async calls
		// Create/Update responseActions
		if(newAutomations != null)
		{
			for(let i = 0; i < newAutomations.length; i++) {
				try {
					var newAutomation = newAutomations[i];
                    newAutomation.description = null;

					// If we have no response its a new automation
					if(!newAutomation.responseActionID)
					{
						newAutomation.responseID = responseId;

						await api.createAlarmAutomation(responseId, newAutomation);
					}
					else{

						// Check to see if changees have been made to an item before we try and hit the backend
						// Shallow compare
						var oldAutomation = oldAutomations.find(r => r.responseActionID == newAutomations[i].responseActionID)

						var updated = newAutomation.objectID != oldAutomation.objectID
									|| newAutomation.responseActionTypeID != oldAutomation.responseActionTypeID
									|| newAutomation.settingsMeta != oldAutomation.settingsMeta
						if(updated)
						{
							await api.updateAlarmAutomation(responseId, newAutomation);
						}
					}
				}
				catch (error) {
					this.$notify({
					type: "error",
					title: "Failed to create/update automation",
					text: error.response.data
					});
				}
			}
		}
		// Delete responseActions that no longer exist
		if(oldAutomations  && oldAutomations.length > 0)
		{
			for(let i = 0; i < oldAutomations.length ; i++)
			{
				if(!newAutomations.find(r => r.responseActionID == oldAutomations[i].responseActionID))
				{
                    try {
                        await api.deleteAlarmAutomation(this.selectedResponse.responseID, oldAutomations[i].responseActionID)
                    }
                    catch (error) {
                        this.$notify({
                            type: "error",
                            title: "Failed to delete automation",
                            text: error.response.data
					    });
				    }
				}
			}
		}
	}

    private onModalOpen(response: ResponseExtended): void {
        if (response) {
            response.script = this.responseScripts.find(rs => rs.scriptID === response.scriptID);
            this.selectedResponse = response;
        }

		if (this.isCustomFieldAlarmsEnabled) {
			mapArrayFieldsToObject(this.selectedResponse, this.selectedResponse.customFields, this.alarmCustomFields,"id", "value", "cf");
		}
    }

    private async deleteAlarm(response: ResponseExtended): Promise<void> {
        if (response && response.responseID) {
            await api.deleteResponse(response.responseID);
            await this.updateResponses(this.mostRecentSearchParams);
            this.$emit("triggerUpdate");
        }
    }

    public async updateResponses(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
        try {
            this.isLoading = true;
            this.mostRecentSearchParams = paginatedSearchQueryParams;
            await this.retrieveAlarmDecoderServers();
            const cancellableQuery = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);

            if (this.selectedServerId) {
                cancellableQuery.params.serverId = this.selectedServerId;
            }

            let result: ResponsesPagedResponse = await api.getHttpResponses(cancellableQuery);
            let responseData: ResponseRow[];

            if (result.data) {
                responseData = result.data.map((response: any) => {
                    let isReadOnly = !!response.groupSyncId && !this.permissions.canOverrideGroupSync && !this.permissions.isSystemAdmin;
                    response.readOnly = isReadOnly;
                    response.readOnlyMessage = isReadOnly ? "You do not have permission to edit Sync Alarms": null;
                    return response;
                })
            }

            this.responses = responseData;
            const systemIdentifierOptions = this.getSystemIdentifierOptions();
            if (this.responses)
            {
                for(var i = 0; i < this.responses.length; i++)
				{
                    var response = this.responses[i];
                    response.automations = this.showAutomations ? await api.getAlarmAutomations(response.responseID) : [];
                    response.script = this.responseScripts.find(rs => rs.scriptID === response.scriptID);
                    response.autoHandle = this.yesNoNotSet.find(yn => yn.actualValue == response.autoHandle)?.value
                    response.grouping = this.alarmGroupingOptions.find(ag =>
                        ag.isRaiseGrouped == response.isRaiseGrouped &&
                        ag.isRaiseIndividual == response.isRaiseIndividual);
                    const server = this.alarmDecoderServers ? this.alarmDecoderServers.find(ad => ad.serverID == response.serverID) : null;
                    if (server) {
                        response.server = {
                            title: server ? server.title : null,
                            serverID: response.serverID
                        }
                    } else {
                        response.server = null;
                    }
                    response.showOriginalEmail = false;
                    response.systemIdentifier = systemIdentifierOptions.firstOrDefault(si => si.extraValue == response.extraValue);
                    if (!response.systemIdentifier) {
                        // check to see if it's using an alarm decoder
                        const isUsingAlarmDecoder = this.alarmDecoderServers.some(ad => ad.serverID == response.server.serverID);
                        if (isUsingAlarmDecoder) {
                            response.systemIdentifier = { responseId: 0, title: "Use Alarm Decoder", extraValue: "" };
                        }
                    }
                    Vue.set(response, "server", response.server);
                }
            }
            this.totalRecords = result.totalRecords;
        }
        catch (ex) {
            console.error("Unexpected error searching alarms: " + ex);
        } finally {
            this.isLoading = false;
        }
    }

    private convertDataToRest(formData): ResponseRow[] {
        return formData.map(value => {
            return {
                ...value,
                groupID: this.areasByTitle[`${value.groupID}`],
                priority: Number(value.priority),
                grouping: this.alarmGroupingOptions.find(({ title }) => title === value.grouping),
                script: this.responseScripts.find(({ title }) => title === value.script)
            }
        })
    }

    private bulkOnSubmit({ formData }): void {
        const max = formData.length;
        let counterSucceed = 0;
        let progress: any = {
            max,
            value: 0,
            status: 'init',
            successfulMessage: `Successfully added ${max} Alarms`,
        };

        this.progressBulk = {
            ...progress
        };

        const convertedData = this.convertDataToRest(formData);

        convertedData.forEach(async (alarm) => {
            const id = alarm.rowId;

            return this.putAlarm(alarm)
                .then(() => {
                    progress.rowIndex = id;
                    counterSucceed += 1;
                    progress.value = progress.value + 1;

                    this.progressBulk = {
                        ...progress,
                        status: counterSucceed === max ? 'succeed' : 'progress',
                    };
                })
                .catch(() => {
                    const failedContact = convertedData.find(({ rowId }) => rowId === id);
                    this.progressBulk = {
                        ...progress,
                        rowIndex: id,
                        status: 'error',
                        error: {
                            name: failedContact.title,
                            message: `Bulk upload has failed to add alarm ${failedContact.title}`
                        }
                    };
                })
        })
    }

    private onCloseBulk(): void {
        this.resetProgress()
    }

    private resetProgress(): void {
        this.progressBulk = {}
    }

    private get defaultFile(): DefaultFile {
        const modalItems = this.modalItems
        const headers = modalItems.filter(({ csvExclude }) => !csvExclude).map(({ title }) => title)
        const body = headers.map( v => '')
        return {
            name: 'AlarmTemplate.csv',
            data: `${headers.join()}\r\n${body.join()}`
        }
    }

    private get areasByTitle(): Dictionary<string> {
        return invert(this.areaDictionary);
    }

    private copySystemIdentifier(responseID: number): void {
        let idToCopy = `copy-to-clipboard-target-${responseID}`;
        let node = document.getElementById(idToCopy);
        let range = document.createRange();
        range.selectNodeContents(node);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");

        this.tooltipToShow =`copy-to-clipboard-tooltip-${responseID}`;
        setTimeout(() => {
            this.tooltipToShow = "";
        } , 2000);
    }

    private getSystemIdentifier(response: ResponseExtended): string {
        // If a http alarm is linked to an alarm decoder it will take on the email of the decoder in the rootResponseEmailProperty
        // but we only want the system identifier part of the email for http alarms
        return response.rootResponseEmail ? response.rootResponseEmail.split('@')[0] : response.extraValue
    }

    private getSystemIdentifierOptions(): SystemIdentifierOption[] {
        let result = [];
        result.push({ responseId: -1, title: "Auto Generated", extraValue: "" });
        if (this.alarmDecoderServers.length > 0) {
            result.push({ responseId: 0, title: "Use Alarm Decoder", extraValue: "" });
        }

        this.responses.forEach(r => {
            if (!r.responseCode) {
                result.push({ responseId: r.responseID, title: `${r.extraValue} (${r.groupTitle} - ${r.title})`, extraValue: r.extraValue });
            }
        });
        return result;
    }

    private isResponseCodeFieldVisible(value: ResponseRow): boolean {
		if(!value)
        {
            return false;
        }

		// Do not show if set to Auto Generated
		if (value?.systemIdentifier?.title === "Auto Generated") {
			return false;
		} else {

			// This checks if a response is the root
			// If it is don't show the Response Code field
			if (value?.systemIdentifier?.extraValue !== "" &&
				(value?.responseCode === null  || value?.responseCode === "") &&
				value?.extraValue === value?.systemIdentifier?.extraValue) {
				return false;
			}

			// This is a non-root alarm so show the ResponseCode field
			return true;
		}
    }

	private async openCustomFieldModal(): Promise<void> {
		this.$refs.customFieldTableView.showCustomFieldModal();
	}
}
