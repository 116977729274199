
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import { CardValidation, RuleCondition, RuleDropdownOptions, RuleGenericDropdownItem } from "@/store/rules-engine/types";
import { namespace } from "vuex-class";

const RuleStore = namespace('rulesEngine');

@Component({
	components: {
		"vue-select": vSelect
	}
})
export default class RuleGenericDropdown extends Vue {

	@RuleStore.Action private retrieveOptionsForRuleDropdownOptions: (request: {optionType: RuleDropdownOptions, id: number}) => Promise<RuleGenericDropdownItem[]>

	@Prop({type: [Number, null], required: false, default: null})
	private value: number | null;

    @Prop({type:String, default: ""})
    private placeholderText: string;

	@Prop({type: Array, default: []})
    private options: RuleGenericDropdownItem[];

	@Prop({ type: Boolean, default: false})
	private allowNull: boolean;

	@Prop({ type: Boolean, default: false})
	private readOnly: boolean;

	@Prop({type: Object, default: null})
	private parentCondition: RuleCondition | null;

	@Prop()
	private ruleDropdownOption: RuleDropdownOptions;

	private selectedValue: string = null;
	private selectedOptions: RuleGenericDropdownItem[] = [];
	private inputValidation: CardValidation = { isValid: false, message: ""};

    private async mounted() {
		this.valueUpdated();
		await this.setupOptionsFromParentCondition();
    }

	@Watch("parentCondition", {deep: true})
	private async setupOptionsFromParentCondition(): Promise<void> {
		if (this.ruleDropdownOption) {
			this.selectedOptions = await this.retrieveOptionsForRuleDropdownOptions({optionType: this.ruleDropdownOption, id: this.parentCondition.value});
			this.selectedValue = this.selectedOptions.firstOrDefault((o: RuleGenericDropdownItem) => o.id === this.value)?.title;
		} else {
			this.selectedOptions = this.options;
		}
        this.validationUpdated();
	}

	@Watch("options")
	private onOptionsChanged(): void {
		if (!this.ruleDropdownOption) {
			this.selectedOptions = this.options;
			this.selectedValue = this.selectedOptions.firstOrDefault((o: RuleGenericDropdownItem) => o.id === this.value)?.title;
		}
	}

	@Watch("value")
	private valueUpdated(): void {
		if (!this.ruleDropdownOption) {
			this.selectedOptions = this.options;
			this.selectedValue = this.selectedOptions.firstOrDefault((o: RuleGenericDropdownItem) => o.id === this.value)?.title;
		}
        this.validationUpdated();
	}

	@Emit("isValid")
    private validationUpdated(): CardValidation {

        if(!this.allowNull && (!this.selectedValue || this.selectedValue === "")) {
			this.inputValidation.isValid=false;
			this.inputValidation.message="Option must be selected";
        } else {
			this.inputValidation.isValid=true;
			this.inputValidation.message="";
		}

		return this.inputValidation
    }

    @Emit("input")
    private valueSelected(value: RuleGenericDropdownItem) {
        this.validationUpdated();
		this.selectedValue = value?.title;
        return value?.id;
    }
}
