
import { Component, Vue, Prop } from "vue-property-decorator";
import vSelect3 from "vselect3";
import { CaseType } from "@/store/case-management/types";

@Component({
	components: {
		"v-select-3": vSelect3,
	},
})
export default class CaseTypeSelect extends Vue {
	@Prop()
	public value: any;

	@Prop({ type: Boolean, default: false })
	readonly: boolean;

	@Prop()
	public caseTypes: CaseType[];

	public caseTypeSelected: CaseType = this.value;

	/**
	 * Entry changed - emit to parent
	 */
	public entryChanged(newValue: CaseType) {
		this.$emit("input", newValue);
	}
}
