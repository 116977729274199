
import { Component, Vue, } from "vue-property-decorator";
import { UserPermissions, TimeZone } from "@/store/types";
import { Getter, namespace, State, Action } from "vuex-class";
import AreaTreeSelect from "../form/AreaTreeSelect.vue";
import ScheduleModal from "./ScheduleModal.vue";
import SchedulesCalendar from "./SchedulesCalendar.vue";
import SchedulesTable from "./SchedulesTable.vue";
import { ScheduleType } from "@/store/schedules/types";
import { EventColor, ScheduleInfo, ScheduleTypes } from "./ScheduleEnums";
import vSelect3 from "vselect3";

const SchedulesStore = namespace("schedules");

@Component({
	components: {
		"schedules-calendar": SchedulesCalendar,
		"schedules-table": SchedulesTable,
		"area-tree-select": AreaTreeSelect,
		"schedule-modal": ScheduleModal,
		"v-select-3": vSelect3
	}
})

export default class ScheduleSetup extends Vue {
	// Root store
	@State private timeZones: TimeZone[];
	@Getter("getPermissions") private permissions: UserPermissions;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Action getTimeZones: () => Promise<void>;

	// Schedule store.
	@SchedulesStore.State private scheduleTypes: ScheduleType[];
	@SchedulesStore.State private enabledScheduleFilter: boolean | null;
	@SchedulesStore.State selectedGroup: number;
	@SchedulesStore.Mutation setIsReloadRequired: (isReloadRequired: boolean) => void;
	@SchedulesStore.Mutation setIsScheduleModalVisible: (isVisible: boolean) => void;
	@SchedulesStore.Mutation updateSearchString: (searchString: string) => void;
	@SchedulesStore.Mutation addSelectedScheduleType: (scheduleTypeId: number) => void;
	@SchedulesStore.Mutation removeSelectedScheduleType: (scheduleTypeId: number) => void;
	@SchedulesStore.Mutation setSelectedGroup: (selectedGroup: number) => void;
	@SchedulesStore.Mutation resetSelectedGroup: () => void;
	@SchedulesStore.Mutation setEnabledScheduleFilter: (disabled: boolean | null) => void;
	@SchedulesStore.Action loadScheduleTypes: () => Promise<void>;
	@SchedulesStore.Getter isScheduleTypeSelected: (scheduleTypeId: number) => boolean;

	private filterSearch: string = "";

	public async mounted(): Promise<void> {
		await this.loadScheduleTypes();

		this.setEnabledScheduleFilter(true);
		this.scheduleTypes.forEach(scheduleType => {this.scheduleTypeSelect(scheduleType.scheduleTypeId)});

		this.selectedArea = this.selectedGroup > 0 ? this.selectedGroup : null;

		if (!this.timeZones) {
			await this.getTimeZones();
		}
	}

	beforeDestroy(): void {
		this.resetSelectedGroup();
	}

	private get canViewSchedules(): boolean {
		return this.permissions.isSystemAdmin || this.permissions.canViewSchedules;
	}

	private get canEditSchedules(): boolean {
		return this.permissions.isSystemAdmin || this.permissions.canEditSchedules;
	}

	private get enabledFilter(): boolean | null {
		return this.enabledScheduleFilter;
	}

	private get isMaskingEnabled(): boolean {
		return this.getFeature(["Schedules", "Masking"]);
	}

	private get isEventRaiseEnabled(): boolean {
		return this.getFeature(["Schedules", "EventRaise"]);
	}

	private get filteredScheduleTypes(): ScheduleType[] {
		let schedulesTypeList = this.scheduleTypes;

		if (!this.isMaskingEnabled) {
			schedulesTypeList = schedulesTypeList.filter(st => st.scheduleTypeId != ScheduleTypes.Masking);
		}

		if (!this.isEventRaiseEnabled) {
			schedulesTypeList = schedulesTypeList.filter(st => st.scheduleTypeId != ScheduleTypes.EventRaise);
		}

		return schedulesTypeList;
	}

	private set enabledFilter(value: boolean | null) {
		this.setEnabledScheduleFilter(value);
		this.triggerFiltering();
	}

	private async handleSelectedGroup(): Promise<void> {
		this.setSelectedGroup(this.selectedArea);
		this.triggerFiltering();
	}

	private getScheduleTypeState(scheduleTypeId: number): boolean {
		return this.isScheduleTypeSelected(scheduleTypeId);
	}

	private scheduleTypeSelect(scheduleTypeId: number): void {
		if (!this.isScheduleTypeSelected(scheduleTypeId)) {
			this.addSelectedScheduleType(scheduleTypeId);
		}
		this.triggerFiltering();
	}

	private scheduleTypeToggled(scheduleTypeId: number): void {
		if (!this.isScheduleTypeSelected(scheduleTypeId)) {
			this.addSelectedScheduleType(scheduleTypeId);
		} else {
			this.removeSelectedScheduleType(scheduleTypeId);
		}
		this.triggerFiltering();
	}

	private showCreateModal(): void {
		this.setIsScheduleModalVisible(true);
	}

	private handleSearchString(): void {
		this.updateSearchString(this.filterSearch);
		this.triggerFiltering();
	}

	private triggerFiltering(): void {
		this.setIsReloadRequired(true);
	}

	private reloadData(): void {
		this.setIsReloadRequired(true);
	}

	private getColor(scheduleTypeId: number): string {
		let color = ScheduleInfo.find((x) => x.id == scheduleTypeId)?.color;
		return "background-color:" + (color ?? EventColor.DefaultColour);
	}

	private get selectedArea(): number | null {
		return this.selectedGroup > 0 ? this.selectedGroup : null;
	}

	private set selectedArea(value: number | null) {
		this.setSelectedGroup(value);
	}
}
