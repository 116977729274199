import api from "@/services/api.service";
import axios from "axios";
import { ActionTree } from "vuex";
import { ResponsesState, Response, ResponseTestResult } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<ResponsesState, RootState> = {
	async fetchResponses({ commit, state }, cancelToken) {
		if (state.filter === "") {
			const { data } = await api.fetchResponses(state.pageIndex, state.pageSize, state.serverId, state.filter, cancelToken);

			commit("setResponses", data);

			if (state.pageSize > data.length) {
				commit("setAllResponsesRetrieved", true);
			}
		} else {
			var response = await api.fetchResponses(state.filterPageIndex, state.pageSize, state.serverId, state.filter, cancelToken);

			if(!response) return;

			commit("setFilteredResponses", response.data);

			if (state.pageSize > response.data.length) {
				commit("setAllFilteredResponsesRetrieved", true);
			}
		}
	},
	async refreshResponses({ commit, state }) {
		commit("setResponseTableIsBusy", true);

		if (state.filter === "") {
			const pageSize = state.responses.length;

			commit("resetResponses");

			const { data } = await api.fetchResponses(state.pageIndex, pageSize, state.serverId, state.filter, null);

			commit("setResponses", data);
		} else {
			const pageSize = state.filteredResponses.length;

			commit("resetFilteredResponses");

			const { data } = await api.fetchResponses(state.filterPageIndex, pageSize, state.serverId, state.filter, null);

			commit("setFilteredResponses", data);
		}

		commit("setResponseTableIsBusy", false);
	},
	async createNewResponse({ commit }, response: Response) {
		const { data } = await api.createNewResponse(response);

		if (data && data.responseID > 0) {
			commit("addNewResponses", data);
			return true;
		} else {
			commit("setfailedResponse", data);
			return false;
		}
	},
	async updateResponse({ commit }, response: Response) {
		let { data } = await api.updateResponse(response);
		if (data) {
			commit("updateResponse", data);
		}
	},
	async deleteResponse({ commit }, responseId: number) {
		let { data } = await api.deleteResponse(responseId);
		if (data) {
			commit("deleteResponse", responseId);
		}
	},
	async searchResponse({ commit }, searchTerm: string) {
		const cancelTokenSource = axios.CancelToken.source();

		commit("setCancelTokenSource", cancelTokenSource);
		commit("setResponses", await api.responseSearch(cancelTokenSource.token, searchTerm));
	},
	async getResponseById({ commit }, responseId: number) {
		const data = await api.responseGet(responseId);
		commit("setCurrentAlarm", data);
	}
};
