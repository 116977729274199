
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import settingsApi from "@/services/api.settings.service";
import apiKeyApi from "@/services/api.apiKey.service";
import { PlatformTypeEnum } from "@/store/types";

const Eventqueue = namespace("eventqueue");

@Component({
})

export default class VirtualOperatorsPopUp extends Vue {
	@Eventqueue.Mutation setVirtualOperatorControlPanelShown: (shown: boolean) => void;
	@Eventqueue.Getter("getVirtualOperatorControlPanelShown") virtualOperatorControlPanelShown!: boolean;
	@Getter getUserTenantGroupId: number;

	private aiUIVirtualOperatorUrl: string;
	private aiUIUrl: string;
	private aiAPIKey: string;
	private errorMessage: string;

	public async mounted() : Promise<void> {
		const tenantId = this.getUserTenantGroupId;
		try {
			this.aiUIUrl = await settingsApi.getAiUiUrl();
			this.aiAPIKey = await apiKeyApi.getApiKey("VirtualOperator");
		}
		catch (ex) {
			this.errorMessage = "Error fetching control panel";
			console.error(this.errorMessage)
		}

		this.aiUIVirtualOperatorUrl = `${this.aiUIUrl}/vo-control-panel?platform=${PlatformTypeEnum.Response}&apiKey=${this.aiAPIKey}&externalKey=${tenantId}`;
	}

	private cancel(): void {
		this.setVirtualOperatorControlPanelShown(false);
	}
}

