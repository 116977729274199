
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import RegionalHandlerMixin from "@/mixins/RegionalHandlerMixin";
import { FilteredEvent } from "@/store/eventqueue/types";
import EventQueueAreaInfoIcon from "@/components/event-queue/EventQueueAreaInfoIcon.vue";
import ResetActionsCCure from "@/components/ResetActionsCCure.vue";
import { get } from "lodash";

const Eventqueue = namespace("eventqueue");

@Component({
	components: {
		VuePerfectScrollbar: VuePerfectScrollbar,
		"event-queue-area-info-icon": EventQueueAreaInfoIcon,
		"reset-actions-ccure": ResetActionsCCure
	}
})
export default class PatrolQueue extends Mixins(RegionalHandlerMixin) {
	@Eventqueue.Getter getActiveEvents!: FilteredEvent[];
	@Eventqueue.Mutation setCountByType: any;

	public get events(): FilteredEvent[] {
		let events = this.getActiveEvents;

		if (events == null) return null;

		let filteredEvents = events.filter((q: any) => q.eventTypeID == 2 || q.eventTypeID == 3);
		this.setCountByType({ countType: "patrols", count: filteredEvents.length });

		return filteredEvents;
	}

	public get noEvents() {
		return typeof this.events == "undefined" || this.events == null || this.events.length == 0;
	}

	public get resetOnTourEnabled() {
		// We use lodash here as native ES does not have null propagation
		return get(this.featuresList, ["Integration", "CCure", "UI", "ResetOnTour"]);
	}

}
