import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { UserContextState, TenantOverview } from "./types";
import { RootState } from "../types";
import { PollingStatus } from "@/types/PollingStatus";

export const actions: ActionTree<UserContextState, RootState> = {
	async startPolling({ commit, state, rootState }) {
		if (rootState.User.loggedIn && !state.pollingStatus.isPolling) {
			let fetchOverview = async () => {
				let tenantOverview: TenantOverview = await api.getTenantOverview();
				commit("setTenantOverview", tenantOverview);
			}

			await fetchOverview();

			// if we currently have an interval running, clear it
			clearInterval(state.pollingStatus.intervalHandlerId);

			let intervalHandlerId = window.setInterval(fetchOverview, 30000);

			let pollingStatus: PollingStatus = {
				isPolling: true,
				intervalHandlerId: intervalHandlerId
			}

			commit("setPollingStatus", pollingStatus);
		}
	},
	async stopPolling({ commit, state }) {
		if (state.pollingStatus.isPolling) {
			clearInterval(state.pollingStatus.intervalHandlerId);

			let pollingStatus: PollingStatus = {
				isPolling: false,
				intervalHandlerId: 0
			}

			commit("setPollingStatus", pollingStatus);
		}
	},
	async fetchOverview({ commit, state }) {
		let tenantOverview: TenantOverview = await api.getTenantOverview();
		commit("setTenantOverview", tenantOverview);
	}
};
