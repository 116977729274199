
import { Component, Vue, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import {
	EventRecordFilter,
	EventRecord,
	EventDetails,
	EventRecordDetails,
	MaskedAlarm
} from "@/store/site-monitor/types";
import { CameraType, ClipType, MultipleClipsIndex } from "@/store/site-monitor-cameras/types";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CreateNewResponse from "./CreateNewResponse.vue";
import EmailHolder from '@/components/emails/email-holder.vue';
import { FeaturesList, UserPermissions } from "@/store/types";
import { AuditService } from "@/services/auditService";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { get } from "lodash";
import { SessionResource } from "@/store/sessions/types";
import { MessageWithAttachment } from "@/store/guard-chat/types";
import MaskingModal from "@/components/alarm-masking/MaskingModal.vue";
import MessagesList from "@/components/mobile/messages/MessagesList.vue";
import * as messagesApi from "@/services/api.guardChat.service";
import { NewestAt } from '@/components/mobile/messages/NewestAt';
import { EventRecordHasClipStatus } from "@/store/event-search/types";
import EventRecordTypes from "@/types/sv-data/enums/EventRecordTypes";
import EventRecordDetailsPopout from "./EventRecordDetailsPopout.vue";

const SessionStore = namespace("sessions");
const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Emails = namespace("emails");
const GuardChat = namespace("guardChat");
const EventSearchStore = namespace("eventSearch");
const AlarmPoints = namespace("alarmPoints");

// There is no clear unique identity in filters objects.
// In order to prevent multiple messages filter pushes in created hook,
// filter title used as unique prop (assuming no filters with duplicate titles should be added).
// In created hook filters checked for it's presence (by title), and messages filter is not added when found.
const messagesFilterTitle = "Messages";

@Component({
	components: {
		'masking-modal': MaskingModal,
		VuePerfectScrollbar: VuePerfectScrollbar,
		CreateNewResponse,
		EmailHolder,
		MessagesList,
		'event-record-popout': EventRecordDetailsPopout
	},
	mixins: [validationMixin],
	validations: {
		auditNote: {
			required
		}
	}
})
export default class EventRecords extends Vue {
	$refs!: {
		maskAlarmDialog: any;
		fileUploadForm: any;
		fileUploader: any;
	};

	@Prop(Number)
	eventid!: number;

	@Prop({ default: "siteMonitor/fetchEventRecords" })
	fetchMutation: string = "siteMonitor/fetchEventRecords";

	@Prop(Boolean)
	reviewMode!: boolean;

	@Prop(String)
	eventFilesPreservedUntil!: string;

	@Prop(Boolean)
	eventDetailsAvailable!: Boolean;

	@Prop({ type: Boolean, default: false })
	showVideoAvailableColumn: Boolean;

	@Prop({ type: Boolean, default: false })
	private peekMode: Boolean;

	@Prop({ type: String, default: "" })
	private peekUser: string;

	@Prop({ type: Boolean, default: false })
	private hideMessages: boolean;

	@SessionStore.Getter getSession: any;
	@SessionStore.Action updateSession: any;

	@Getter("getPermissions") permissions: UserPermissions;

	@SiteMonitor.Getter("getAuditService") auditService: AuditService;
	@SiteMonitor.Getter("getEventRecords") eventRecords!: EventRecord[];
	@SiteMonitor.Getter("getCurrentRecordFilter")
	currentFilter!: null | EventRecordFilter;
	@SiteMonitor.Getter getSelectedEventRecord: EventRecord;
	@SiteMonitor.Getter getEventDetails: EventDetails;
	@SiteMonitor.Getter filterIndex: number;
	@SiteMonitor.Getter("getFilteredRecords") filteredRecords!: EventRecord[];
	@SiteMonitor.Getter("getEventRecordFilters") filters!: EventRecordFilter[];
	@SiteMonitor.Getter("getEventRecordsAwaitingAckCount") awaitingAck: number;
	@SiteMonitor.Getter("getEventRecordsWithFiles")
	eventRecordsWithFiles: number[];

	@SiteMonitor.Getter groupID!: number | null;
	@SiteMonitor.Getter maskedAlarms!: MaskedAlarm[];
	@SiteMonitor.Getter getEventRecordsNoFilterRenderIndex: any;

	@SiteMonitor.Mutation setEventRecordFilterRenderIndex: any;
	@SiteMonitor.Mutation setEventRecordsNoFilterRenderIndex: any;
	@SiteMonitor.Mutation resetEventRecords: any;
	@SiteMonitor.Mutation setGoToLocation: (location: string) => void;
	@SiteMonitor.Mutation setSelectedEventRecord: any;
	@SiteMonitor.Mutation setRequestingEventRecords: any;
	@SiteMonitor.Mutation setActivity: () => void;
	@SiteMonitor.Mutation setForceRestoreEventRecord: any;
	@SiteMonitor.Mutation setIsUploadingFile: any;
	@SiteMonitor.Mutation setEventRecordExpanded: any;
	@SiteMonitor.Mutation setEventRecordEventRecordDetails: any;
	@SiteMonitor.Mutation setEventRecordFilters: any;

	@SiteMonitor.Action setEventRecordFilter: any;
	@SiteMonitor.Action eventRecordAcknowledgement: any;
	@SiteMonitor.Action createAuditRecord: any;
	@SiteMonitor.Action createReviewRecord: any;
	@SiteMonitor.Action fetchEventRecords: any;
	@SiteMonitor.Action("unlinkCamera") sendUnlinkCameraRequest: any;
	@SiteMonitor.Action eventRecordAllAcknowledgement: any;
	@SiteMonitor.Action loadMaskedAlarms: any;
	@SiteMonitor.Action ackEventRecord: any;
	@SiteMonitor.Action fetchEventRecordDetails: ({ eventRecordID }) => Promise<EventRecordDetails>;
	@SiteMonitor.Action private fetchEventDetails: (eventId: number) => Promise<EventDetails>;

	@SMCameras.Mutation setAwaitingCamera: any;
	@SMCameras.Mutation setLinkRecord: any;
	@SMCameras.Mutation setSaveLink: any;
	@SMCameras.Getter("getLayoutIndex") layoutIndex!: number;
	@SMCameras.Getter("getLayouts") layouts!: number[][];
	@SMCameras.Getter("getDeviceControllerClips") currentClips: ClipType[];

	@Emails.Action loadEmailData: any;

	@Getter getTimeZoneAbbr: string;
	@Getter isFullUser: boolean;
	@Getter getUserName: string;
	@Getter getPermissions: UserPermissions;

	// Getter and setter for toggling the display of the map.
	@SiteMonitor.Action setHideMapFlag: any;
	@SiteMonitor.Getter("getHideMapFlag") hideMapFlag: boolean;

	@GuardChat.State(s => s.numberOfUnread) numberOfUnread: number;
	@GuardChat.Getter("getMessages") messages: MessageWithAttachment[];
	@GuardChat.Mutation setNumberOfUnread: (newValue: number) => void;
	@GuardChat.Mutation resetState: () => void;
	@GuardChat.Action loadMessages: (eventId: number) => Promise<void>;

	@Ref() readonly messagesList: MessagesList;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	@EventSearchStore.State EventRecordsWithClips: EventRecordHasClipStatus[];

	@AlarmPoints.Mutation setDisplayResponseId: (responseId: number | null) => void;

	public error: string = "";
	public lastRecord: number = 0;
	public filteredTypeIds: any[] = [];
	public auditNote: string = "";
	public ps: any;
	public updateInterval: number | null = null;
	public ackingAll: boolean = false;
	private firstUpdateEventId: number | null = null;
	private fileList: FileList | null = null;
	private fileListName: string = "";
	private currentDownloadingRecord: number = 0;
	private invalidNote: boolean = false;
	private showErrorAnimation: boolean = false;
	private peekLastUpdated: string = "";

	//Error Modal
	private errorList: string[] = [];
	private errorExists: boolean = false;

	private ended: boolean = false;
	private _renderedFilterIndex: number = -1;
	private _renderedIndex: number = -1;
	private _renderedRecords: any[] = null;

	private rendering: boolean = false;
	private savingRecord: boolean = false;
	private awaitingNew: boolean = true;
	private downloading: boolean = false;

	private isMaskingModalShown: boolean = false;
	private selectedResponseId: number = null;

	// Details Popout Modal
	private isDetailsPopoutModalShown: boolean = false;
	private poppedOutEventRecord: EventRecord = null;

	public get getRestoredIconEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "RestoredIcon"]);
	}

	public get fileAttachmentsEnabled(): boolean {
		if (this.isMessagesFilter) {
			// commented until until we re-introduce
			// get(this.featuresList, ["Alarms", "SiteMonitor", "Messages", "Attachments"]);
			return false;
		}
		return get(this.featuresList, ["Alarms", "SiteMonitor", "NoteAttachments"]);
	}

	/** Checks if the supplemental notes feature is enabled */
	public get isSupplementalNotesEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventSearch", "SupplementalNotes"], false);
	}

	/** Checks if the Expandable event details feature is enabled */
	public get isEventRecordDetailsEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "ExpandableEventDetails"], false);
	}

	/** Checks if the AcknowledgeAllEnabled feature is enabled */
	public get isAcknowledgeAllEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "AcknowledgeAll"], false);
	}

	public get isDownloadRawEmailEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "DownloadRawEmail"], false);
	}

	public get isDevicesEnabled(): boolean {
		return get(this.featuresList, ["Devices"], false);
	}

	public get isMessagesEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "Messages"], false);
	}

	public get isAlarmMaskingEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Masking"], false) &&
			(this.getPermissions.canDisarmSites || this.getPermissions.canDisarmSitesExtended);
	}

	public get isOnTestEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "AlarmPoints", "OnTest"], false) && !!this.permissions.canPutAlarmPointsOnTest;
	}

	public get isCustomFieldAlarmsEnabled(): boolean {
		return get(this.featuresList, ["CustomFields", "Alarms"], false);
	}

	/** Currently rendered event records. Caches, and updates on filter change or render index update */
	public get renderedRecords() {
		let currentFilter = this.currentFilter;
		if (currentFilter == null) {
			let noFilterIndex = this.getEventRecordsNoFilterRenderIndex;
			if (
				this._renderedRecords == null ||
				this._renderedFilterIndex != -1 ||
				this._renderedIndex != noFilterIndex
			) {
				this._renderedFilterIndex = -1;
				this._renderedIndex = noFilterIndex;
				this._renderedRecords = this.filteredRecords.slice(0, noFilterIndex);
			}
		} else {
			if (
				this._renderedRecords == null ||
				this._renderedFilterIndex != currentFilter.filterIndex ||
				this._renderedIndex != currentFilter.renderIndex
			) {
				this._renderedFilterIndex = currentFilter.filterIndex;
				this._renderedIndex = currentFilter.renderIndex;
				this._renderedRecords = this.filteredRecords.slice(0, currentFilter.renderIndex);
			}
		}

		return this._renderedRecords;
	}

	public get parsedEventRecords(): EventRecord[] {
		return this.filteredRecords.map(x => {
			let y = { ...x };
			if (y.details) {
				y.hasFiles = y.details.includes("{FilesUploaded}");
				y.details = y.details.replace("{FilesUploaded}", "");
			}

			if (this.eventRecordsWithFiles) {
				y.hasFiles = this.eventRecordsWithFiles.includes(y.eventRecordID);
			}

			y.hasClip = this.EventRecordsWithClips?.find(event => event.eventRecordId === x.eventRecordID)?.hasClip ?? false;

			return y;
		});
	}

	/** If the current filter uses a Priority column  */
	public get hasPriorityColumn(): boolean {
		return this.currentFilter == null || this.currentFilter.hasPriorityColumn;
	}

	/** If the current filter shows the Linked Camera column */
	public get hasLinkedCameraColumn(): boolean {
		return this.currentFilter == null || this.currentFilter.hasLinkedCameraColumn;
	}

	public get hasVideoAvailableColumn(): boolean {
		return this.showVideoAvailableColumn &&
			(this.currentFilter == null || this.currentFilter.hasVideoAvailableColumn);
	}

	public get hasMatrixGridLocationColumn(): boolean {
		return ((this.currentFilter == null || this.currentFilter.showMatrixGridLocation) && !this.reviewMode);
	}

	private canDeviceHaveClip(event: EventRecord): Boolean {
		if (!event) {
			return false;
		}

		return event.eventRecordTypeID === EventRecordTypes.Alarm ||
			event.eventRecordTypeID === EventRecordTypes.CameraEvent;
	}

	/** If the current filter shows the "Acknowledge All" bar */
	public get showAckAllBar() {
		return this.filterIndex <= 0 && this.isAcknowledgeAllEnabled && !this.peekMode;
	}

	/** If we are ready to satr requesting event records */
	public get startRequestingEventRecords() {
		return !this.reviewMode || this.eventDetailsAvailable;
	}

	/** Currently selected event record ID  */
	public get selectedEventRecordID() {
		let selectedRecord = this.getSelectedEventRecord;
		if (selectedRecord == null) return 0;

		return selectedRecord.eventRecordID;
	}

	/** the readable title for the current timezone */
	public get areaTimeZoneTitle() {
		return this.getEventDetails === null ? "" : this.getEventDetails.timeZoneTitle;
	}

	public get selectedFileNames() {
		let fileNamesUploaded = "";

		if (this.fileListHasValue) {
			Array.from(Array(this.fileList.length).keys()).map(x => {
				fileNamesUploaded = `${fileNamesUploaded}${fileNamesUploaded ? ",\n" : "Files attached:\n"}${this.fileList[x].name
					}`;
			});
		} else {
			fileNamesUploaded = "No files selected";
		}

		return fileNamesUploaded;
	}

	public get fileListHasValue() {
		if (this.fileList && this.fileList.length) {
			return true;
		}

		return false;
	}

	// Feature flag for map display toggling functionality.
	private get isToggleMapEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Maps", "ToggleSiteMonitor"]);
	}

	public get layoutHorizontal(): number {
		return this.layouts[this.layoutIndex][0];
	}

	public get layoutVertical(): number {
		return this.layouts[this.layoutIndex][1];
	}

	public get isAutoPopulateEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "AutoPopulate"]);
	}

	/** On mount, configure records  */
	public async mounted(): Promise<void> {
		this.resetEventRecords();
		this.ended = false;
		this.setRequestingEventRecords(false);
		await this.fetchEventDetails(this.eventid);
		this.update();
		this.setSaveLink(true);
		await this.updateSession({
			resourceId: SessionResource.AuditServiceSession,
			eventId: this.eventid
		});

		this.configureFilters();
	}

	/** Stop updating when component is closed  */
	public beforeDestroy() {
		this.ended = true;

		if (this.updateInterval != null) {
			clearInterval(this.updateInterval);
		}
	}

	/** Refresh current event records
	 *  If the Event Peek modal is closed, don't keep updating
	*/
	public update(): void {
		if (this.ended) {
			return;
		}

		if (this.peekMode && this.eventid == null) {
			return;
		}

		//this.loadMaskedAlarms(this.groupID);
		if (this.fetchMutation != undefined && Number(this.eventid) > 0 && this.startRequestingEventRecords) {
			let update = this.fetchEventRecords({ eventID: this.eventid, reviewMode: this.reviewMode });
			if (update != null) {
				update.then(
					eventRecordCount => {
						if (this.firstUpdateEventId == null || this.firstUpdateEventId != this.eventid) {
							this.firstUpdateEventId = this.eventid;

							if (this.getSelectedEventRecord) {
								this.$emit("eventRecordSelected", this.getSelectedEventRecord);
							}

							if (
								!this.reviewMode &&
								this.getSelectedEventRecord &&
								this.getSelectedEventRecord.awaitingAcknowledgement
							) {
								this.ackEventRecord(this.getSelectedEventRecord);
							}
						}

						setTimeout(() => this.update(), 2000);

						if (this.awaitingNew && eventRecordCount > 0) {
							//console.log("Rendering more event records ... ");
							Vue.nextTick(() => {
								this.onScrollToEnd();
							});
						}
					},
					error => {
						setTimeout(() => this.update(), 2000);
					}
				);
			}
		} else {
			setTimeout(() => this.update(), 2000);
		}

		// Update time for event peek
		if (this.peekMode) {
			this.peekLastUpdated = new Date().toLocaleString();
		}
	}

	private timeSincePeekUpdate(): string {
		return `Last Updated: ${this.peekLastUpdated}`;
	}

	// To handle peeking different events, we refresh the peek when the eventid changes
	@Watch('eventid')
	private onEventIdChanged(): void {
		// clear guard chat state when the eventid changes
		this.resetState();

		if (this.eventid != null) {
			if (this.peekMode) {
				this.resetEventRecords();
				this.update();
				this.configureFilters();
			}
		}
	}

	// Moved existing code to this function as it will now be used with event peek too
	private configureFilters(): void {
		let filters = Object.assign([], this.filters);

		let deviceFilter = filters.find(f => f.filterTitle === "Devices");
		deviceFilter.enabled = this.isDevicesEnabled;

		const messagesFilter = filters.find(f => f.filterTitle === messagesFilterTitle);

		if (this.peekMode || this.hideMessages) {
			messagesFilter.enabled = false;
		} else {
			messagesFilter.enabled = this.isMessagesEnabled;
		}

		this.setEventRecordFilters(filters);
	}

	public filterOn(filter: EventRecordFilter) {
		this.setEventRecordFilter(filter.filterIndex);
		this.setActivity();
	}

	public filterOff() {
		this.setEventRecordFilter(-1);
		this.setActivity();
	}

	public async jumpToLocation(eventRecord: EventRecord): Promise<void> {
		if (eventRecord.latLong != "") {
			// if the toggle map feature is on, hide the map.
			if (this.isToggleMapEnabled) {
				this.setHideMapFlag(false);
			}
			this.setGoToLocation(null);
			await this.$nextTick();
			this.setGoToLocation(eventRecord.latLong);
		}
	}

	@Emit("eventRecordSelected")
	public async viewRecord(eventRecord: EventRecord) {
		if (this.peekMode) {
			return;
		}

		this.$emit("eventRecordSelected", eventRecord);
		this.setSelectedEventRecord(eventRecord);

		if (!this.reviewMode && eventRecord.awaitingAcknowledgement) {
			this.ackEventRecord(eventRecord);
		}
	}

	public async ackAll() {
		if (this.ackingAll && !this.isAcknowledgeAllEnabled) {
			return;
		}

		if (this.reviewMode || this.peekMode) {
			return;
		}

		this.ackingAll = true;
		await this.eventRecordAllAcknowledgement(this.eventid);
		this.ackingAll = false;
	}

	/*** Adds new event record for audit note. Resets activity.
	 * If user is in peek mode, audit note is appended with user name
	*/
	public async postAudit() {
		if (this.peekMode) {
			this.auditNote = `${this.auditNote} - ${this.peekUser} in Peek`;
		}
		if (!this.savingRecord) {
			this.$v.$touch();
			if (this.$v.auditNote.$error) {
				this.invalidNote = true;
				this.showErrorAnimation = true;
				return;
			}

			this.savingRecord = true;
			let fileNamesUploaded = "";

			const eventRecordId = await this.createRecord();

			if (this.fileListHasValue) {
				await this.setIsUploadingFile(true);
				await this.renewAuditSession();

				Array.from(this.fileList).forEach(async (file, index) => {
					await this.auditService
						.uploadFileForEventRecord(
							this.fileListName,
							file,
							this.getSession(SessionResource.AuditServiceSession),
							this.eventid,
							eventRecordId
						)
						.then(async (response: any) => {
							await this.createAuditRecord({
								eventId: this.eventid,
								eventRecordTypeId: 23,
								details: `File ${file.name} upload complete for EventRecordID: ${eventRecordId}`,
								objectId: eventRecordId
							});

							await this.resetFileList(index);
						})
						.catch(async err => {
							await this.resetFileList(index);
							console.log(`Upload failed!`, err);
							if (err.response && err.response.data) {
								this.errorList.push(err.response.data);
							} else if (err.message) {
								this.errorList.push(err.message);
							} else {
								this.errorList.push(`Unknown error occurred`);
							}
							this.errorExists = true;
						});
				});
			} else {
				await this.setActivity();
				this.auditNote = "";
				this.savingRecord = false;
			}
		}
	}

	private get isMessagesFilter(): boolean {
		return this.filters[this.filterIndex] && this.filters[this.filterIndex].filterTitle === messagesFilterTitle;
	}

	private async createRecord(): Promise<number> {
		if (this.isMessagesFilter) {
			try {
				return await messagesApi.sendMessage({
					eventId: this.eventid,
					messageType: "operator",
					message: this.auditNote
				});
			} catch {
				console.error(`Failed to send Message.`);
			}
		} else {
			const eventRecord = await this.createAuditRecord({
				eventId: this.eventid,
				eventRecordTypeId: 103,
				details: this.auditNote
			});
			return eventRecord.eventRecordID;
		}
	}

	/***
	 * adds new event record for supplemental notes
	 * Used in event search
	 */
	public async postSupplemental() {
		if (!this.savingRecord) {
			this.$v.$touch();
			if (this.$v.auditNote.$error) {
				this.invalidNote = true;
				this.showErrorAnimation = true;
				return;
			}
			this.savingRecord = true;
			const record = await this.createReviewRecord({
				eventId: this.eventid,
				eventRecordTypeId: 17,
				details: `Supplemental Information: ${this.auditNote}`
			});
			if (this.fileListHasValue) {
				await this.setIsUploadingFile(true);
				await this.renewAuditSession();
				Array.from(this.fileList).forEach(async (file, index) => {
					await this.auditService
						.uploadFileForEventRecord(
							this.fileListName,
							file,
							this.getSession(SessionResource.AuditServiceSession),
							this.eventid,
							record.eventRecord.eventRecordID
						)
						.then(async (response: any) => {
							await this.createReviewRecord({
								eventId: this.eventid,
								eventRecordTypeId: 23,
								details: `Supplemental Information: File ${file.name} upload complete for EventRecordID: ${record.eventRecord.eventRecordID}`,
								objectId: record.eventRecord.eventRecordID
							});
							await this.resetFileList(index);
						})
						.catch(async err => {
							await this.resetFileList(index);
							console.log("Upload failed! Error", err);
							if (err.response && err.response.data) {
								this.errorList.push(err.response.data);
							} else if (err.message) {
								this.errorList.push(err.message);
							} else {
								this.errorList.push(`Unknown error occurred`);
							}
							this.errorExists = true;
						});
				});
			} else {
				await this.setActivity();
				this.auditNote = "";
				this.savingRecord = false;
			}
		}
	}

	private resetErrorModal() {
		this.errorExists = false;
		this.errorList = [];
	}

	private async resetFileList(index) {
		if (index + 1 == this.fileList.length) {
			await this.setActivity();
			this.auditNote = "";
			this.savingRecord = false;
			await this.setIsUploadingFile(false);
			this.fileList = null;
			this.$refs.fileUploadForm.reset();
		}
	}

	private async downloadAttachedFiles(eventRecordId: number) {
		await this.renewAuditSession();
		this.downloading = true;
		this.currentDownloadingRecord = eventRecordId;
		await this.auditService
			.getAllFiles(this.getSession(SessionResource.AuditServiceSession), eventRecordId)
			.then(async (response: any) => {
				const fileName = response.headers["x-suggested-filename"];
				let link = document.createElement("a");
				link.href = window.URL.createObjectURL(response.data);
				link.download = fileName;
				link.click();

				await this.createReviewRecord({
					eventId: this.eventid,
					eventRecordTypeId: 23,
					details: `Files downloaded by user: ${this.getUserName}`
				});
			})
			.catch(err => {
				console.log(`Download failed: ${err.response.data}`);
				this.errorList.push(`Download failed: ${err.response.data}`);
				this.errorExists = true;
			});

		this.downloading = false;
	}

	/**
	 * Opens the Mask Alarm Dialog specified by the name
	 */
	public showMaskAlarmDialog(event: EventRecord) {
		this.$refs.maskAlarmDialog.show(event.eventID, event.objectID, this.maskedAlarmForEvent(event));
	}

	/**
	 * Returns the masked alarm (response) for the given event, if any. Otherwise, this returns undefined.
	 */
	public maskedAlarmForEvent(event: EventRecord) {
		return this.maskedAlarms.find(alarm => alarm.responseID == event.objectID);
	}

	/**
	 * Performs a check to see if the given event record has had it's alarm response masked.
	 */
	public isAlarmMasked(event: EventRecord) {
		return typeof this.maskedAlarmForEvent(event) != "undefined";
	}

	public displayCamera(camera: CameraType) {
		this.setAwaitingCamera({
			objectID: camera.deviceID,
			title: camera.title
		});
	}

	public linkCamera(record: EventRecord) {
		this.setLinkRecord(record);
	}

	public async unlinkCamera(eventRecord: EventRecord, camera: CameraType) {
		await this.sendUnlinkCameraRequest({
			objectId: eventRecord.objectID,
			deviceId: camera.deviceID
		});
		let cameraIndex = eventRecord.attachedCameras.findIndex(
			linkedCamera => linkedCamera.deviceID == camera.deviceID
		);
		eventRecord.attachedCameras.splice(cameraIndex, 1);
	}

	public setForceRestoreOpen(eventRecord: EventRecord) {
		this.setForceRestoreEventRecord(eventRecord);
	}

	onScrollToEnd() {
		if (this.filteredRecords && this.filteredRecords.length == 0) {
			this.awaitingNew = true;
			return;
		}

		if (this.rendering) return;

		this.rendering = true;
		let currentFilter = this.currentFilter;

		if (currentFilter == null) {
			let noFilterIndex = this.getEventRecordsNoFilterRenderIndex;
			let max = this.filteredRecords.length;
			let renderIndex = Math.min(max, noFilterIndex + 50);

			this.setEventRecordsNoFilterRenderIndex(renderIndex);

			this.awaitingNew = noFilterIndex + 50 > max;
		} else {
			let max = currentFilter.filterRecords.length;
			let renderIndex = Math.min(max, currentFilter.renderIndex + 50);

			this.setEventRecordFilterRenderIndex({
				filterIndex: currentFilter.filterIndex,
				renderIndex: renderIndex
			});

			this.awaitingNew = currentFilter.renderIndex + 50 > currentFilter.filterRecords.length;
		}

		Vue.nextTick(() => {
			this.rendering = false;
		});
	}

	private filesChanged(name, files: FileList) {
		const arrayFiles = Array.from(files);
		const invalidFiles = arrayFiles.filter(file => file.name.length > 240);

		if (invalidFiles.length > 0) {
			this.errorList.push(
				`${invalidFiles.length} File(s) are exceeding the character limit. The limit is 240 characters.`
			);
		}

		if (
			arrayFiles.reduce((acc, file) => acc + file.size, 0) >=
			this.getEventDetails.fileSizeLimit * 1000000
		) {
			this.errorList.push(`Maximum upload size is ${this.getEventDetails.fileSizeLimit}MB`);
		}

		if (arrayFiles.filter(file => file.name.toLowerCase().endsWith("exe")).length > 0) {
			this.errorList.push(`.exe files can not be uploaded`);
		}

		if (this.errorList.length > 0) {
			this.errorExists = true;
		} else {
			this.fileListName = name;
			this.fileList = files;
		}

		this.$refs.fileUploader.files = null;
		this.$refs.fileUploader.value = this.fileList;
	}

	private async renewAuditSession() {
		await this.updateSession({
			resourceId: SessionResource.AuditServiceSession,
			eventId: this.eventid
		});
	}

	private checkCurrentlyDownloading(eventRecordId: number) {
		return this.currentDownloadingRecord == eventRecordId && this.downloading;
	}

	/** Expands  the event details for a record */
	private async showEventRecordDetails(eventRecord: EventRecord) {
		this.hasRawData = false;
		//Show EventRecordDetails
		let EventRecordDetails = await this.fetchEventRecordDetails({ eventRecordID: eventRecord.eventRecordID });

		this.setEventRecordEventRecordDetails({
			eventRecordID: eventRecord.eventRecordID,
			EventRecordDetails: EventRecordDetails
		});

		this.setEventRecordExpanded({
			eventRecordID: eventRecord.eventRecordID,
			expanded: true
		});

		// no need to await
		this.checkForRawData(eventRecord.eventRecordID);
	}

	/** unexpands the event details for a record */
	private async hideEventRecordDetails(eventRecord: EventRecord) {
		//Hide EventRecordDetails
		this.setEventRecordExpanded({
			eventRecordID: eventRecord.eventRecordID,
			expanded: false
		});
	}

	@Watch("auditNote")
	private onAuditNoteChanged() {
		this.setActivity();
	}

	@Watch("EventRecordsWithClips")
	private onEventRecordsWithClipsChanged() {
		// Updated parsedEventRecords
		this.parsedEventRecords;
	}


	/*
		TEMP RawDataLogic section - This may move / get changed hence why its at the bottom of file instead of properly
		grouped
	 */
	private hasRawData: boolean = false;
	private emailId: string = "";
	private showRawDataModal: boolean = false;
	private gettingRawDataStatus: boolean = false;

	/**
	 * Download the raw data and put it into rawDataHtml to be leverage in modal
	 * @param eventRecordId
	 */
	public async downloadRawData(eventRecordId: number) {
		const recordId = `${eventRecordId}`;
		const auth = this.getSession(SessionResource.AuditServiceSession);

		await this.loadEmailData({ auth, recordId });
		this.emailId = recordId;
		this.$bvModal.show('rawDataModal');
	}

	/**
	 * Check to see if this filestore contains a raw data .eml file for this event record
	 * @param eventRecordID
	 */
	private async checkForRawData(eventRecordID: number) {
		// if we're already getting clips
		if (this.gettingRawDataStatus) {
			console.log("Already fetching raw data status.");
			return;
		}

		// mark that we're getting clips; prevent duplicate operations.
		this.gettingRawDataStatus = true;
		let auth = this.getSession(SessionResource.AuditServiceSession);
		// do request to audit service to get media list.
		this.auditService.mediaList(auth, eventRecordID).then(
			(response: any) => {
				try {
					// get the raw clips
					let rawClips = response.data;
					// check if any of the items are `RAW DATA` / .eml items.
					this.hasRawData = rawClips.some((clip) => {
						return clip.MediaType == "RAW DATA";
					});
				} catch (err) {
					console.error(err);
				}

				this.gettingRawDataStatus = false;
			},
			(errorResponse: any) => {
				this.hasRawData = false;
				this.gettingRawDataStatus = false;
			}
		);
	}

	private launchMaskingModal(event: EventRecord) {
		this.selectedResponseId = event.objectID;
		this.isMaskingModalShown = true;
	}

	private onMaskingModalClose() {
		this.isMaskingModalShown = false;
	}

	private updatedTask: (() => void) | undefined;

	@Watch("isMessagesFilter")
	private onMessagesVisibilityChange(newVal, oldVal) {
		if (newVal) {
			this.updatedTask = () => { this.messagesList.restoreScrolling(); };
		} else {
			this.messagesList.storeScrolling();
			this.updatedTask = undefined;
		}
	}

	private updated() {
		if (this.updatedTask) {
			this.updatedTask();
			this.updatedTask = undefined;
		}
	}

	private getNewCount(filter: EventRecordFilter) {
		if (filter.filterTitle === messagesFilterTitle) {
			return this.numberOfUnread;
		} else {
			return filter.newCount;
		}
	}

	private get newestMessagesAt(): NewestAt {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "ChatMessagesNewestOnTop"]) ? "top" : "bottom";
	}

	private isEventRecordOfExpandableType(parsedEventRecord) {
		return parsedEventRecord.eventRecordTypeID === 1 || parsedEventRecord.eventRecordTypeID === 149;
	}

	private enableDetailsPopout(event: EventRecord): void {
		this.poppedOutEventRecord = event;
		this.isDetailsPopoutModalShown = true;
	}

	private disableDetailsPopout(): void {
		this.isDetailsPopoutModalShown = false;
	}

	private get areEventFilesAvailable(): boolean {
		return new Date(this.eventFilesPreservedUntil ? this.eventFilesPreservedUntil : 0) > new Date();
	}

	private get canUploadFiles(): boolean {
		if (this.reviewMode) {
			return this.fileAttachmentsEnabled && this.areEventFilesAvailable;
		} else {
			return this.fileAttachmentsEnabled;
		}
	}

	private downloadEmail(): void {
		if (!this.emailId) {
			return;
		}

		this.downloadAttachedFiles(Number(this.emailId));
	}

	private getExpandedUrls(eventRecordDetails: EventRecordDetails): string[] {
		var httpRegex = new RegExp("^http[s]?:([\\]{2}|[/]{2})", "i");
		var internalRegex = new RegExp("^[#]?/");
		var httpsPrefix = "https://";
		var internalPrefix = "#/";

		return (eventRecordDetails.url ?? "").split(',').reduce(function (urls, item) {
			let urlPath = item.trim();

			// checks if the trimmed string is empty
			if (urlPath) {
				if (internalRegex.test(urlPath)) {
					// prefix is internal path
					urls.push(internalPrefix.concat(urlPath.split('/')[1]));
				}
				else if (!httpRegex.test(urlPath)) {
					// has no prefix
					urls.push(httpsPrefix.concat(urlPath));
				}
				else {
					// has valid http prefix
					urls.push(urlPath);
				}
			}

			return urls;
		}, []);
	}

	public cameraIconColor(clips: number[], i: number, j: number): string {
		if (clips.length == 0) {
			return "empty-clip";
		}

		let indexCalc = ((i - 1) * this.layoutHorizontal + j);

		if (clips.includes(indexCalc)) {
			return "red-camera-indicator";
		}

		return "grey-camera-indicator";
	}

	public recordClips(recordId: number): MultipleClipsIndex {
		let clipIndexes: MultipleClipsIndex = {
			indexes: []
		};

		if (!this.currentClips) {
			return clipIndexes;
		}

		let clipsForRecord = this.currentClips.filter(clip => clip.EventRecordId == recordId);

		if (clipsForRecord.length > 0) {
			let indexes = clipsForRecord.map(clip => clip.index);
			clipIndexes.indexes = indexes;
			return clipIndexes;
		}

		return clipIndexes;
	}

	public recordHasClips(recordId: number): boolean {
		let clipsExist = this.recordClips(recordId);
		if (clipsExist !== null && clipsExist.indexes.length > 0) {
			return true;
		}

		return false;
	}
}
