import { SiteMonitorCamerasState } from "./types";

export function getDefaultState(): SiteMonitorCamerasState {
    return {
        layoutIndex: 3,
        layouts: [[1, 1], [2, 2], [2, 3], [3, 3], [4, 3], [4, 4]],
        matrixContents: {},
        matrixPushContents: {},
        mediaMatrixCameras: Array(16),
        mediaMatrixClips: [],
        mediaMatrixLayers: [],
        mediaMatrixLastChanged: 0,
        deviceControllerCameras: [],
        deviceControllerOutputs: [],
        deviceControllerClips: [],
        deviceControllerAudioDevices: [],
        areaCameras: [],
        searchedCameras: [],
        searchedOutputs: [],
        mediaMatrixIsNew: false,
        fetchingMatrix: false,
        mediaMatrixFilter: "",
        mediaMatrixEventId: null,
        awaitingCamera: null,
        awaitingClip: null,
        saveLink: false,
        linkRecord: null,
        requiresEventDetails: false,
        highlightCellIndex: null,
        waitingForEvent: false,
        shownAuditClips: [],
		areaOutputs: [],
		areaCameraCount: 0,
		searchedCameraCount: 0,
		searchedGroupCameraCount: 0,
    }
}
