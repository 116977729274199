import Vue from "vue";
import { MutationTree } from "vuex";
import { isEqual } from "lodash";
import { SiteMonitorCamerasState, DeviceControllerOutput } from "./types";
import { axiosInstance } from "@/axios.instance";
import { getDefaultState } from "./state";

export const mutations: MutationTree<SiteMonitorCamerasState> = {
	setMediaMatrixEventId(state, eventId: number) {
		state.mediaMatrixEventId = eventId;
	},
	setMediaMatrixCameras(state, cameras: any[]) {
		state.mediaMatrixCameras = cameras;
	},
	setDeviceControllerOutputs(state, devices: any) {
		devices.forEach(output => {
			Vue.set(output, "controlRequest", false);
			Vue.set(output, "controlReason", "");
			Vue.set(output, "controlError", "");
			Vue.set(output, "sendingCommand", false);
		});
		state.deviceControllerOutputs = devices;
	},
	setDeviceControllerAudioDevices(state, devices: any) {
		state.deviceControllerAudioDevices = devices;
	},
	setAreaCameras: (state, devices) => state.areaCameras = devices,
	setAreaOutputs: (state, devices: DeviceControllerOutput[]) => state.areaOutputs = devices,
	setDeviceControllerCameras(state, devices: any) {
		if (devices) {
			const matrixDevices: { [deviceId: string]: number } = {};
			const matrixContents = state.matrixContents;

			for (const matrixIndex in matrixContents) {
				if (matrixContents[matrixIndex].camera !== undefined) {
					const camera = matrixContents[matrixIndex].camera;
					if (camera != null) {
						matrixDevices[camera.objectID.toString()] = parseInt(
							matrixIndex,
							10
						);
					}
				}
			}

			devices.forEach(item => {
				const objectID = item.objectID ? item.objectID : item.objectId;
				if (objectID) {
					const deviceID = objectID.toString();

					if (matrixDevices[deviceID] !== undefined) {
						Vue.set(item, "index", matrixDevices[deviceID]);
					} else {
						Vue.set(item, "index", null);
					}
				}
			});
		}

		state.deviceControllerCameras = devices;
	},
	clearDeviceControllerCameras(state) {
		state.deviceControllerCameras = [];
	},
	setDeviceControllerClips(state, clips: any) {
		state.deviceControllerClips = clips;
	},
	setLayoutIndex(state, index: number) {
		state.layoutIndex = index;

		const width = state.layouts[index][0];
		const height = state.layouts[index][1];
		const maxIndex = width * height;

		state.deviceControllerCameras.forEach(camera => {
			if (camera.index != null && camera.index > maxIndex) {
				camera.index = null;
			}
		});

		state.deviceControllerClips.forEach(clip => {
			if (clip.index != null && clip.index > maxIndex) {
				clip.index = null;
			}
		});

		// Remove any media matrix cells which will be clipped from view on resize
		let deletedCell = false;
		for (const cellIndex of Object.keys(state.matrixContents)) {
			if (parseInt(cellIndex, 10) > maxIndex) {
				Vue.delete(state.matrixContents, cellIndex);
				deletedCell = true;
			}
		}

		// Only update the last changed time if we've removed any cells
		if (deletedCell) {
			state.mediaMatrixLastChanged = new Date().valueOf();
		}
	},
	setAwaitingCamera(state, camera: any) {
		state.awaitingCamera = camera;
	},
	setAwaitingClip(state, clip: any) {
		state.awaitingClip = clip;
	},
	setMatrixContents(state, { index, contents }: any) {
		if (contents.camera) {
			Vue.set(contents.camera, "index", index);
		} else if (contents.clip) {
			Vue.set(contents.clip.clip, "index", index);
		}

		Vue.set(state.matrixContents, index.toString(), contents);
		state.mediaMatrixLastChanged = new Date().valueOf();
	},
	setRequiresEventDetails(state, required: boolean) {
		state.requiresEventDetails = required;
	},
	setPushContents(state, { index, newContents }: { index: number, newContents: any }) {
		const idx = index.toString();
		if (state.matrixPushContents) {
			const currentContents = state.matrixPushContents[idx];
			// If the new contents are the same as the current, and also not empty, don't update the matrixPushContent
			if (isEqual(currentContents, newContents) && Object.keys(newContents).length !== 0) {
				return;
			}
		}
		Vue.set(state.matrixPushContents, idx, newContents);
	},
	setMediaMatrixIsNew(state, isNew: boolean) {
		state.mediaMatrixIsNew = isNew;
		state.fetchingMatrix = false;
	},
	setFetchingMatrix(state, fetchingMatrix: boolean) {
		state.fetchingMatrix = fetchingMatrix;
	},
	clearMatrixContents(state) {
		state.matrixContents = {};
		state.matrixPushContents = {};
	},
	highlightCell(state, index: number) {
		state.highlightCellIndex = index;
	},
	unhighlightCell(state) {
		state.highlightCellIndex = null;
	},
	setLinkRecord(state, record: any) {
		state.linkRecord = record;
	},
	setSaveLink(state, save) {
		state.saveLink = save;
	},
	setCameraLink(state, camera: any) {
		// @techdebt avoid api requests in mutations
		if (state.saveLink) {
			if (state.linkRecord.attachedCameras == null) {
				state.linkRecord.attachedCameras = [];
			}

			const existingLinks = state.linkRecord.attachedCameras.filter(
				linkedCamera => linkedCamera.deviceID === camera.deviceId
			);
			if (existingLinks.length === 0) {
				axiosInstance
					.get(
						"/Responses/Link/" +
							state.linkRecord.objectID +
							"/" +
							camera.deviceId
					)
					.then(response => {
						if (response.data.deviceID) {
							state.linkRecord.attachedCameras.push({
								deviceID: response.data.deviceID,
								title: response.data.title
							});
						}

						state.linkRecord = null;
					});
			} else {
				state.linkRecord = null;
			}
		} else {
			state.linkRecord = null;
		}
	},
	setWaitingForEvent(state, waiting: boolean) {
		state.waitingForEvent = waiting;
	},
	setSearchedCameras(state, payload: any) {
		let mappedDevices = payload.devices.map(camera => {
			return {
				objectID: camera.deviceId,
				itemType: 1,
				title: camera.title,
				distance: camera.distance,
				imperial: camera.imperial,
				areaTitle: camera.areaTitle,
				index: null
			};
		});

		// If the state needs to be added to instead of overwritten, filter out duplicates keys for media matrix
		state.searchedCameras = payload.reset ?
			mappedDevices :
			state.searchedCameras.concat(mappedDevices.filter(({ objectID }) => !state.searchedCameras.find(c => c.objectID == objectID)));
	},
	clearSearchedCameras(state) {
		state.searchedCameras = [];
	},
	setAreaCameraCount(state, payload: number) {
		state.areaCameraCount = payload;
	},
	setSearchedCameraCount(state, payload: number) {
		state.searchedCameraCount = payload;
	},
	setSearchedGroupCameraCount(state, payload: number) {
		state.searchedGroupCameraCount = payload;
	},
	setSearchedOutputs(state, payload: any) {
		state.searchedOutputs = payload.devices.map(output => {
			return {
				objectId: output.deviceId,
				itemType: 3,
				title: output.title,
				distance: output.distance,
				imperial: output.imperial,
				areaTitle: output.areaTitle,
				controlRequest: output.controlRequest,
				controlReason: output.controlReason,
				controlError: output.controlError,
				isOutput: output.isOutput,
				canPulse: output.canPulse,
				canOnOff: output.canOnOff
			};
		});
	},
	updateMediaMatrixContents(state, { contents, index }) {
		const matrixContents = state.matrixContents;

		const cellContents = matrixContents[index.toString()];
		if (cellContents != null) {
			if (cellContents.camera) {
				cellContents.camera.index = null;
			} else if (cellContents.clip) {
				cellContents.clip.clip.index = null;
			}
		}

		const deviceControllerCameras = state.deviceControllerCameras;
		if (deviceControllerCameras != null) {
			deviceControllerCameras.forEach(camera => {
				const cameraObjectID = contents?.camera?.objectID ? contents?.camera?.objectID : contents?.camera?.objectId;
				if (camera.index === index) {
					camera.index = null;
				}
				if (
					contents.camera &&
					camera.objectId === cameraObjectID
				) {
					camera.index = index;
				}
			});
		}
		const deviceControllerClips = state.deviceControllerClips;
		if (deviceControllerClips != null) {
			deviceControllerClips.forEach(clip => {
				if (clip.index === index) {
					clip.index = null;
				}
				if (
					contents.clip &&
					contents.clip.clip.UniqueFileIdentifier === clip.UniqueFileIdentifier
				) {
					clip.index = index;
				}
			});
		}
	},
	setShownAuditClips(state, clips: string[]) {
		state.shownAuditClips = clips;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
