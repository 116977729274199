import { UserContextState } from "./types";

export function getDefaultState(): UserContextState {
	return {
		tenantOverview: {
			areaCount: 9999,
			groupMaskingOverview: {
				maskedCount: 0,
				partialMaskCount: 0,
				unmaskedCount: 0
			}
		},
		pollingStatus: {
			isPolling: false,
			intervalHandlerId: 0
		},
		isSet: false
	}
}
