
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, Action } from "vuex-class";
import { ICamera } from "@/store/views/types";
import { SessionResource } from "@/store/sessions/types";
import Draggable from '../utilities/draggable.vue';

const SessionStore = namespace("sessions");

export enum CameraModalSize {
	Small = 1,
	Medium = 2,
	Large = 3
}

@Component({
		components: {
			"draggable" : Draggable,
		}
})
export default class CameraModal extends Vue {
	@SessionStore.Action updateSession: any;

	@Prop() private deviceID: number | null;
	@Prop() private title: string | null;
	@Prop({ default: false }) public showControls: boolean;
	@Prop({ default: false }) public showPtzOverlay: boolean;
	// Not currently required but leaving here for visibility
	// @Prop({ default: false }) private showManualRaise: boolean;
	// @Prop({ default: false }) private manualRaiseRecording: boolean;

	$refs!: {
		camera: any;
	};

	private readonly sizes = CameraModalSize;
	private size: number = this.sizes.Small;

	async mounted(){
		await this.$nextTick(); //Give page a chance to load be starting the camera
		if(this.deviceID){
			await this.onDeviceIdChanged();
		}
	}

	@Watch("deviceID")
	private async onDeviceIdChanged() {
		await this.updateSession({ resourceId: SessionResource.DeviceServiceSession });
		this.$refs.camera.stop();
		this.$refs.camera.startCamera(this.deviceID);
	}

	@Emit("closeCamera")
	private close(){
		this.$refs.camera.stop();
		return this.deviceID;
	}

	private increaseSize(): void {
		if (this.size < this.sizes.Large) {
			this.size++;
		}
	}

	private decreaseSize(): void {
		if (this.size > this.sizes.Small) {
			this.size--;
		}
	}
}

