import router from "@/router";
import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { get } from "lodash";
import {
	AuthenticationStatus,
	LoginRequest,
	LoginResult,
	RootState,
	LogoutPayload,
	ElevationLabelType,
	AppOptions
} from "./types";
import HealthCheckRequest from "@/types/system-health/HealthCheckRequest";
import organizationApi from "@/services/api.organizationSettings";

export const actions: ActionTree<RootState, RootState> = {
	async initApp({ dispatch, commit }, payload: any) {
		commit("setTimeZoneAbbr", new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]);
		commit("setAppInitialisedFlag", true);
	},

	async login({ commit, dispatch }, data: LoginRequest) {
		let response = await api.login(data);
		if (response.authenticationStatus === AuthenticationStatus.Success) {
			commit("login", response);
			if (response.subscription) {
				commit("subscription/setSubscriptionDetails", response.subscription);
			}
			dispatch("userContext/startPolling");
		}
		return response;
	},

	async processLoginResponse({ commit }, response: LoginResult): Promise<void> {
		if (response.authenticationStatus === 0) {
			commit("login", response);
			commit("setFeaturesList", response.features);
		}
	},

	async getLoginSession({ commit, dispatch }, data: LoginRequest) {
		let response = await api.getLoginSession();
		if (response.authenticationStatus === AuthenticationStatus.Success) {
			commit("login", response);
			if (response.subscription) {
				commit("subscription/setSubscriptionDetails", response.subscription);
			}
			dispatch("userContext/startPolling");
		}
		return response;
	},

	singleEventLogin({ commit }, data: any) {
		return new Promise((resolve, reject) => {
			const loginData = {
				sessionID: data.sessionID,
				accessCode: data.accessCode
			};

			api.singleEventLogin(loginData)
				.then(response => {
					const loginResult = response.data;

					if (response.data.status === 0) {
						loginResult.isFullUser = false;
						commit("login", loginResult);
						resolve(loginResult);
					} else {
						reject(loginResult);
					}
				})
				.catch(error => {
					reject({ error });
				});
		});
	},

	logout({ commit, dispatch }: any, payLoad: LogoutPayload) {
		commit("setDataError", false);
		commit("logout");

		const currentRoute = router.currentRoute;
		if (currentRoute && currentRoute.meta && currentRoute.meta.closeOnLogout) {
			window.close();
		} else if (currentRoute.meta.redirectOnLogin) {
			if(payLoad && payLoad.errorMessage) {
				router.push({ path: `/login?redirect=${currentRoute.fullPath}&error=${payLoad.errorMessage}` });
			} else {
				router.push({
					path: `/login?redirect=${currentRoute.fullPath}`
				});
			}
		} else if (currentRoute.fullPath.startsWith("/login?redirect=")) {
			router.push({
				path: `${currentRoute.fullPath}`
			});
		} else if (payLoad && payLoad.hasSessionExpired) {
			let path = "/login?hasSessionExpired=true";
			if (payLoad.playAudioAlert) {
				path = `${path}&playAudioAlert=true`;
			}
			router.push({ path: path });
		} else if(payLoad && payLoad.errorMessage) {
			router.push({ path: `/login?error=${payLoad.errorMessage}` });
		} else {
			router.push({ path: "/login" });
		}

		api.stopHeartbeat();

		dispatch("userContext/stopPolling");
		dispatch("areas/stopPollingAreaMaskingCount");
		dispatch("siteMonitor/stopPollingApplianceServerAndDeviceServerDetails");
		dispatch("resetState");
	},

	resetState({ commit }) {
		commit("resetState");
		commit("areaNotes/resetState");
		commit("siteMonitor/resetState");
		commit("tasks/resetState");
		commit("userManagement/resetState");
		commit("siteMonitorCameras/resetState");
		commit("sessions/resetState");
		commit("mapLayers/resetState");
		commit("eventSearch/resetState");
		commit("devices/resetState");
		commit("mobile/resetState");
		commit("reports/resetState");
		commit("areas/resetState");
		commit("tours/resetState");
		commit("GenericTable/resetState");
		commit("eventqueue/resetState");
		commit("views/resetState");
		commit("mobileRaiseTemplates/resetState");
		commit("subscription/resetState");
		commit("caseManagement/resetState");
		commit("userContext/resetState");
		commit("manualRaise/resetState");
		commit("customFields/resetState");
		commit("areaEventHistory/resetState");
	},

	async serverLogout() {
		return await api.serverLogout();
	},

	async fetchUserGroups({ commit }: any) {
		const userGroups = await api.loadUserGroups();
		commit("setUserGroups", userGroups);
	},

	async searchGroup({ commit }, SearchTerm: string) {
		const userGroups = await api.searchGroup(SearchTerm);
		commit("setUserGroups", userGroups);
	},

	notInEvent() {
		router.push({ path: "/event-queue" });
	},

	async fetchRoutingGroups({ commit }: any) {
		const { data } = await api.loadRoutingGroups(); // MOVE TO EVENT QUEUE STORE
		commit("setRoutingGroups", data);
	},

	setMapType({ commit }, mapType) {
		commit("setMapType", mapType);
	},

	loginToRegion({ commit, state }: any, data: any) {
		return new Promise((resolve, reject) => {
			const loginData = data;

			api.loginToRegion(loginData)
				.then(function(response: any) {
					// save the result
					const loginResult = response.data;

					// check if login was successful
					if (loginResult.status === 0) {
						// we need to hold on to the current login details
						commit("storeLocalLogin");

						// set isFullUser for the remote login
						loginResult.isFullUser = true;

						// login with the remote region data
						commit("login", response.data);
						resolve(loginResult);
					} else {
						reject(loginResult);
					}

					console.log("loginToRegion complete");
					return response.data;
				})
				.catch(function(error: any) {
					reject({
						error
					});
				});
		});
	},

	openMediaMatrix({ getters }) {
		const isEnabled = get(getters.getFeaturesList, ["Alarms", "MediaMatrix"]);

		if (isEnabled) {
			const routeData = router.resolve({
				path: "/site-monitor-cameras"
			});
			window.open(
				routeData.href,
				"site-monitor-cameras",
				"location=yes,scrollbars=yes,status=yes"
			);
		}
	},

	openEventReview({ getters }, eventId: number) {
		const routeData = router.resolve({
			path: `/eventsearch/${eventId}?`,
		});
		window.open(
			routeData.href,
			`eventsearch/${eventId}?`,
			"location=yes,scrollbars=yes,status=yes"
		);
	},

	startApiHeartbeat() {
		api.startHeartbeat();
	},
	stopApiHeartbeat() {
		api.stopHeartbeat();
	},

	async getInterDataServiceLoginToken(context, regionId: number) {
		return await api.getInterDataServiceLoginToken(regionId);
	},

	updateActiveRegion({ commit, state }, { regionId, regionUrl }) {
		// If we're setting the active region back to 0, and we've got
		// the original (local) user login cached, then restore it.
		if (regionId === 0 && state.localUserData != null) {
			commit("resetUserData");
		}

		// if the current url doesn't end with /api/ and isnt our local host... add api to the end.
		if (
			!regionUrl.toLowerCase().endsWith("/api/") &&
			!regionUrl.toLowerCase().endsWith("/api") &&
			!regionUrl.startsWith("http://local.sureviewops.com")
		) {
			regionUrl = regionUrl + "/api/";
		}

		commit("setActiveRegion", { regionId, regionUrl });
	},

	async loadFeaturesList({ commit }) {
		const { data } = await api.fetchFeaturesList();
		commit("setFeaturesList", data);
	},

	async getTimeZones({ commit }: any) {
		const timeZones = await api.getTimeZones();
		commit("setTimeZones", timeZones);
	},

	async getTenantSystemPrefs({ commit }, live ) {
		const prefs = await organizationApi.retrieveTenantSystemPrefs(live);
		commit("setTenantSystemPrefs", prefs);
	},

	async raiseSystemLog(context, data: HealthCheckRequest): Promise<void> {
		if(!data.ObjectId || !data.ObjectTypeId)
		{
			return;
		}

		try
		{
			await api.raiseSystemLog(data);
		}
		catch(error)
		{
			console.error("Failed to Raise System Log", error);
		}
	},

	async getElevationLabels({ commit }) {
		let elevationLabels : ElevationLabelType[] = []

		try {
			elevationLabels = await api.getElevationLabels();
		}
		catch (err) {
			console.error(`Get Elevation Labels - Error: ${err}`);
		} finally {
			commit("setElevationLabels", elevationLabels);
		}
	},

	async fetchAppOptions({ commit, state }) : Promise<void>
	{
		if(!state.appOptions)
		{
			let appOptions: AppOptions;

			try {
				appOptions = await api.getAppOptions();
			}
			catch (err)
			{
				console.error(err);
			}
			finally {
				commit("setAppOptions", appOptions);
			}
		}
	},

	async fetchRequiresConfig({ commit, state }) : Promise<void>
	{
		if(state.requiresConfiguration === false)
		{
			return;
		}

		const requiresConfig = await api.requiresConfiguration();
		// only set it
		if(requiresConfig === false)
		{
			commit("setRequiresConfiguration", requiresConfig);
		}
	},
};
