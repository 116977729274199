
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import moment from "moment";
import { DateTime, Settings } from "luxon";
import DatePicker from "@sureview/vuejs-datepicker";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

import { UserPermissions } from "@/store/types";
import { AreaNote } from "@/store/area-notes/types";

const AreaNotes = namespace("areaNotes");

Settings.defaultLocale = "en";

@Component({
	mixins: [validationMixin],
	components: {
		"date-picker": DatePicker
	},
	validations: {
		newAreaNote: {
			entry: {
				required: function(value) {
					return this.isAreaNoteValid;
				},
			},
			endAt: {
				required: function(value) {
					if (this.isEndTimeOptional()) {
						return true;
					}
					return value !== null;
				},
				minTime: function(value) {
					if (this.isEndTimeOptional() || value == "") {
						return true;
					}
					return moment(this.newAreaNote.endAt).isAfter(this.newAreaNote.startFrom);
				}
			}
		},
		newAreaNoteEndTime: {
			minTime: function(value) {
				if (this.isEndTimeOptional()) {
					return true;
				}
				return moment(this.newAreaNote.endAt).isAfter(this.newAreaNote.startFrom);
			}
		}
	}
})
export default class AreaNotesForm extends Vue {
	@Getter("getUserName")
	public currentUserName!: string;
	@Getter getPermissions: UserPermissions;

	@AreaNotes.Action public addAreaNote: any;
	@AreaNotes.Action public cancelEditingNote: any;
	@AreaNotes.Action public editAreaNote: any;
	@AreaNotes.Getter public getCurrentGroupId: number;
	@AreaNotes.Getter public getCurrentGroupTitle: string;
	@AreaNotes.Getter public getIsFormShown: boolean;
	@AreaNotes.Getter public getAreaNoteToEdit: AreaNote;
	@AreaNotes.Getter public getIsEditing: boolean;
	@AreaNotes.Mutation public setIsFormShown: any;

	public mounted(): void {
		this.defaultAreaNote.groupId = this.getCurrentGroupId;

		if (this.getIsEditing) {
			this.newAreaNoteStartTime = this.getTimeFromDate(this.getAreaNoteToEdit.startFrom);
			this.newAreaNoteEndTime = this.getTimeFromDate(this.getAreaNoteToEdit.endAt);
			this.newAreaNote = { ...this.getAreaNoteToEdit };
		}
		this.newAreaNote = { ...this.defaultAreaNote };
	}

	@Watch("getAreaNoteToEdit")
	private getAreaNoteToEditUpdated(newVal, oldVal): void {
		if (newVal !== null) {
			this.newAreaNoteStartTime = this.getTimeFromDate(newVal.startFrom);
			this.newAreaNoteEndTime = this.getTimeFromDate(newVal.endAt);
			this.newAreaNote = { ...newVal };
		}
	}

	private defaultAreaNote: AreaNote = {
		groupId: this.getCurrentGroupId,
		entry: "",
		requiresAcknowledgement: false,
		noteType: "normal",
		startFrom: null,
		endAt: null,
		created: "",
		userName: this.currentUserName
	};
	private newAreaNote: AreaNote = { ...this.defaultAreaNote };

	private defaultTime = "00:00";
	private newAreaNoteStartTime: string = this.defaultTime;
	private newAreaNoteEndTime: string = this.defaultTime;
	private maxNoteLength: number = 500;
	private isSaving = false;

	private async onSave(): Promise<void> {
		this.isSaving = true;
		if (this.isNewAreaNoteValid()) {
			if (this.getIsEditing) {
				this.editAreaNote(this.newAreaNote)
					.then(wasSuccess => {
						if (wasSuccess) {
							this.resetForm();
						} else {
							this.$notify({
								type: "error",
								title: "Failed to edit note",
								text: "Please drop the guard inside an existing patrol zone"
							});
						}
					})
					.finally(() => {
						this.isSaving = false;
					});
			} else {
				this.addAreaNote(this.newAreaNote)
					.then(newNote => {
						// If a note has been returned, adding the note was a success
						if (newNote) {
							this.resetForm();
						}
					})
					.finally(() => {
						this.isSaving = false;
					});
			}
		}
	}

	private onCancel(): void {
		if (this.getIsEditing) {
			this.cancelEditingNote();
		} else {
			this.setIsFormShown(false);
		}
		this.resetForm();
	}

	private isNewAreaNoteValid(): boolean {
		this.$v.$touch();
		return !this.$v.$error;
	}

	private resetForm(): void {
		this.setIsFormShown(false);
		this.newAreaNote = { ...this.defaultAreaNote };
		this.newAreaNote.groupId = this.getCurrentGroupId;
		this.newAreaNoteStartTime = this.defaultTime;
		this.newAreaNoteEndTime = this.defaultTime;
		this.$v.$reset();
	}

	private combineDateWithTime(date: string, time: string): string {
		if (date === null || date === "") {
			return date;
		}

		date = this.removeUTCTimeOffset(date);
		// time is in format HH:mm
		const hoursAndMinutes = time.split(":");
		const isoFormatWithoutTimeZoneOffset = "yyyy-MM-dd'T'HH:mm:ss";

		// set the time and return DateTime as string.
		return DateTime.fromJSDate(new Date(date))
		               .set({ hour: hoursAndMinutes[0], minute: hoursAndMinutes[1] })
					   .toFormat(isoFormatWithoutTimeZoneOffset);
	}

	private getTimeFromDate(date: string): string {
		if (!date) {
			return this.defaultTime;
		}

		date = this.removeUTCTimeOffset(date);

		const time = DateTime.fromJSDate(new Date(date)).toFormat("HH:mm");
		const hoursAndMinutes = time.split(":");

		return `${hoursAndMinutes[0]}:${hoursAndMinutes[1]}`;
	}

	private removeUTCTimeOffset(date: string): string {
		if (typeof(date) === "string") {
			const utcOffset = "Z";
			// for areas in UTC timezone there is a 'Z' at the end of the DateTime string.
			// remove the offset from the string.
			if (date.endsWith(utcOffset)) {
				date = date.replace(utcOffset, "");
			}
		}
		return date;
	}

	private clearEndDate(): void {
		this.newAreaNote.endAt = null;
		this.newAreaNoteEndTime = this.defaultTime;
		this.isNewAreaNoteValid();
	}

	private clearStartDate(): void {
		this.newAreaNote.startFrom = null;
		this.newAreaNoteStartTime = this.defaultTime;
		this.isNewAreaNoteValid();
	}

	// Disables the selection of an end date that is before the start date
	private getDisabledEndDates() {
		return {
			to: moment(this.calendarDateFormatter(this.newAreaNote.startFrom), "MM/DD/YYYY").toDate()
		};
	}

	private calendarDateFormatter(date: string): string {
		if (date) {
			return moment(date).format("MM/DD/YYYY");
		} else {
			return null;
		}
	}

	private isEndTimeOptional(): boolean {
		return this.newAreaNote.startFrom === null || this.newAreaNote.startFrom === "";
	}

	@Watch("newAreaNote", { deep: true })
	private onNewAreaNoteChange(newVal, oldVal): void {
		this.isNewAreaNoteValid();
	}

	@Watch("newAreaNote.startFrom")
	private onNewAreaNoteStartFromChange(newVal, oldVal): void {
		this.newAreaNote.startFrom = this.combineDateWithTime(this.newAreaNote.startFrom, this.newAreaNoteStartTime);
	}

	@Watch("newAreaNote.endAt")
	private onNewAreaNoteEndAtChange(newVal, oldVal): void {
		this.newAreaNote.endAt = this.combineDateWithTime(this.newAreaNote.endAt, this.newAreaNoteEndTime);
	}

	@Watch("newAreaNoteStartTime")
	private onNewNoteStartTimeChange(newVal, oldVal): void {
		this.newAreaNote.startFrom = this.combineDateWithTime(this.newAreaNote.startFrom, newVal);
		this.isNewAreaNoteValid();
	}

	@Watch("newAreaNoteEndTime")
	private onNewNoteEndTimeChange(newVal, oldVal): void {
		this.newAreaNote.endAt = this.combineDateWithTime(this.newAreaNote.endAt, newVal);
		this.isNewAreaNoteValid();
	}

	private hasPermissionsToEditNotes(): boolean {
		return this.getPermissions.canViewSiteSetup;
	}

	private get isAreaNoteValid(): boolean {
		return !!this.newAreaNote && !!this.newAreaNote.entry && !!this.newAreaNote.entry.length && this.newAreaNote.entry.length > 0 &&  this.newAreaNote.entry.length <= this.maxNoteLength;
	}
}
