import { ViewsState, ViewMode } from "./types";

export function getDefaultState(): ViewsState {
    return {
        pluginTypes: [],
        views: [],
        userViews: [],
        selectedView: null,
        viewMode: ViewMode.None,
        selectedTileType: {},
        sideBarExtended: false,
        sideBarPinned: false,
        latestEvent: null,
        cellContents: {
            6: {
                lat: 37.476787168126386,
                lng: -122.14975492960721,
                zoom: 19,
                elevation: 1
            }
        },
        groupsList: [],
        eventHandlers: {},
        viewSessionId: ""
    }
}
