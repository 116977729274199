
import {
	Component,
	Vue,
	Prop,
	Watch,
	Emit,
	Mixins
} from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { validationMixin } from "vuelidate";
import {
	required,
	requiredIf,
	minValue,
	maxValue,
	numeric
} from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import moment from "moment";

import vselect3 from "vselect3";
import AreaTreeSelect from '../form/AreaTreeSelect.vue';

import api from "../../services/api.service";
import { Group } from '@/store/eventqueue/types';

const AlarmPoints = namespace("alarmPoints");
const SiteMonitor = namespace("siteMonitor");
const Eventqueue = namespace("eventqueue");

@Component({
	components: {
		VuePerfectScrollbar,
		"v-select-3": vselect3,
		"area-tree-select": AreaTreeSelect
	}
})
export default class AlarmPointFinder extends Vue {
	@AlarmPoints.State("cancelTokenSource") source: any;
	@AlarmPoints.State("responses") responses: any[];
	@AlarmPoints.Action responseSearch: any;
	@AlarmPoints.Mutation setSelectedResponse: any;
	@AlarmPoints.Mutation setSelectedArea: any;

	@AlarmPoints.Mutation setDisplayResponseId: any;

	@SiteMonitor.Action loadMaskReasonsForAlarm: any;

	@Eventqueue.Action getSelectedGroupByIDSetState: ({groupID, state}: {groupID: number, state: string}) => Promise<void>;
	@Eventqueue.Action updateSelectedGroup: (selectedGroup: Group) => void;

	public responseSearchLoading: boolean = false;
	public selectedResponse: any = null;
	public disarmedResponses: any[] = null;
	public responseFilter: string = "";

	public get filteredDisarmedResponses() {
		if (!this.disarmedResponses) return null;

		if (this.responseFilter == "") return this.disarmedResponses;

		const lowerFilter = this.responseFilter.toLowerCase();

		return this.disarmedResponses.filter(
			response =>
				response.title.toLowerCase().includes(lowerFilter) ||
				response.groupTitle.toLowerCase().includes(lowerFilter)
		);
	}

	public async selected(selectedOption, id) {
		var responseDetails = await api.responseGet(selectedOption.responseID);
		this.setSelectedResponse(responseDetails);
		this.setSelectedArea(null);

		this.loadMaskReasonsForAlarm(responseDetails.groupID);
	}

	public async areaSelected(selectedAreaNode: any) {
		this.setSelectedArea(selectedAreaNode);
		this.setSelectedResponse(null);

		if (selectedAreaNode && selectedAreaNode.id) {
			await this.getSelectedGroupByIDSetState({groupID: selectedAreaNode.id, state: selectedAreaNode.state });
		} else {
			this.updateSelectedGroup(null);
		}
	}

	public async viewMasked() {
		this.disarmedResponses = await api.responsesDisarmed();
	}

	public async unviewMasked() {
		this.disarmedResponses = null;
	}

	public async selectDisarmedResponse(response: any) {
		var responseDetails = await api.responseGet(response.responseID);
		this.setSelectedResponse(responseDetails);
		this.setSelectedArea(null);

		this.loadMaskReasonsForAlarm(responseDetails.groupID);
	}

	public async updateResponseStatus(response: any) {
		if (this.disarmedResponses) {
			if (response.armed) {
				let responseIndex = this.disarmedResponses.findIndex(
					disarmedResponse =>
						disarmedResponse.responseID == response.responseID
				);
				if (responseIndex > -1) {
					this.disarmedResponses.splice(responseIndex, 1);
				}
			} else {
				this.disarmedResponses = await api.responsesDisarmed();
			}
		}
	}
}
