import _ from 'lodash'
import emlformat from 'eml-format'

export function extractFieldId(id) {
	return id.replace(/\<|\>/g, '')
}

export function uniteBase64String(contentType, stringBase64) {
	return `data:${contentType};base64,${stringBase64}`
}

export function removeExtraSymbolsFromHtmlType(html) {
	// remove '= \n' symbols and symbol 3D
	return html.replace(/(\=\s+\n|3D|cid:)/g, '')
}

export function getMatchAllArray(str, regexp) {
	return Array.from(str.matchAll(regexp), v => v.index)
}

export function extractIdFromSrc(template) {
	try
	{
		let srcStrChar = '"';
		let srcStr = 'src=';
		let match = template.match(srcStr+srcStrChar);
	
		if(!match)
		{
			srcStrChar = "'";
			match = template.match(srcStr+srcStrChar);
		}
	
		if(!match)
		{
			return null
		}
	
		const beginIndexId = template.match(srcStr).index + srcStr.length;
		const substr = template.slice(beginIndexId);
		const indexEnd = substr.match(srcStrChar).index;
		const id = template.slice(beginIndexId, beginIndexId + indexEnd);
	
		return {
			id,
			beginKeySrc: beginIndexId,
			endKeySrc: beginIndexId + indexEnd,
		}
	}
	catch
	{
		return null;
	}
}

export function getTagIndexEnd(html, beginDefaultIndex) {
	const beginImgString = html.slice(beginDefaultIndex)
	const endIndexSubstring = beginImgString.match('>').index
	const tagLengthDefault = endIndexSubstring + 1
	const endDefaultIndex = beginDefaultIndex + tagLengthDefault

	return endDefaultIndex
}

export function parseAllOpenTags(localHtml, regExp) {
    return getMatchAllArray(localHtml, regExp).map(beginDefaultIndex => {
        const endDefaultIndex = getTagIndexEnd(localHtml, beginDefaultIndex)
        const template = localHtml.slice(beginDefaultIndex, endDefaultIndex)
        const lastIndexTemplate = template.indexOf('>')

        return {
            endDefaultIndex,
            beginDefaultIndex,
            template,
            lastIndexTemplate,
        }
    })
}

export function extractImgFromHtml(localHtml) {
    const images = parseAllOpenTags(localHtml, /<img/g)

    return images.map(image => ({
        ...image,
        ...extractIdFromSrc(image.template)
    }))
}

export function extractLinksFromHtml(localHtml) {
    return parseAllOpenTags(localHtml, /<a/g)
}

export function uint8ArrayToString(uint8Array) {
	return Array.from(uint8Array, (el, i) =>
		String.fromCharCode(uint8Array[i])
	).join('')
}

export function isUint8Array(value) {
	return value instanceof Uint8Array
}

export function getImageExtension(contentType) {
	return ['.png', '.jpg', '.jpeg'].find(ext => contentType.includes(ext))
}

export function isOctetStream(contentType) {
	if (!contentType) return
	return contentType.includes('octet-stream;')
}

export function isTextHtml(contentType) {
	if (!contentType) return
	return contentType.includes('text/html;')
}

export function isTextPlain(contentType) {
	if (!contentType) return
	return contentType.includes('text/plain;')
}

export function isNessaryContentExist(contentType, type, data) {
	return (
		typeof contentType === 'string' &&
		contentType.includes(type) &&
		isUint8Array(data)
	)
}

export function isPdf(contentType, data) {
	return isNessaryContentExist(contentType, 'application/pdf;', data)
}

export function isVideo(contentType, data) {
	return isNessaryContentExist(contentType, 'video/', data)
}

export function convertToFile(data, type) {
	return new Blob(data, { type })
}

export function getObjectUrl(data, type) {
	const blob = convertToFile(data, type)
	return URL.createObjectURL(blob)
}

export function isImage(contentType, data) {
	return (
		typeof contentType === 'string' &&
		(contentType.includes('image/') || isOctetStreamImage(contentType)) &&
		isUint8Array(data)
	)
}

export function isOctetStreamImage(contentType) {
	return isOctetStream(contentType) && getImageExtension(contentType)
}

export function isMultipartAlternative(contentType) {
	return contentType.includes('multipart/alternative;')
}

export function getArrayLinksFromAttachments(attachments) {
	return attachments.map(attach => {
		const contentType = getContentType(attach.contentType)
		return {
			url: getObjectUrl([attach.data], contentType),
			name: attach.name || '',
			id: attach.id || '',
		}
	})
}

export function setImgSrc(option, imagesById) {
	const image = imagesById[option.id]
	const template = option.template
		.split(' ')
		.map(v => (v.match('src=') && image ? `src="${image.url}"` : v))
		.join(' ')

	return {
		...option,
		template,
	}
}

export function setLinksToHtml(html) {
    const newLinks = setAttributeBlankToLinks(html)

    if(newLinks && newLinks.length === 0) return html

    return setNewTagsToHtml(newLinks, html, extractLinksFromHtml)
}

export function setNewTagsToHtml(tags, localHtml, handler) {
    return tags.reduce((html, { template }, i) => {
        const { beginDefaultIndex, endDefaultIndex } = handler(html)[i]

        const beginStr = html.slice(0, beginDefaultIndex)
        const endStr = html.slice(endDefaultIndex)

        return `${beginStr}${template}${endStr}`
    }, localHtml)
}

export function setAttributeBlankToLinks(html){
    const localHtml = removeExtraSymbolsFromHtmlType(html)
    const extractedLinks = extractLinksFromHtml(localHtml)

    return extractedLinks.filter(({ template }) => !template.includes(`target="_blank"`))
    .map(option => ({
        ...option,
        template: `${option.template.slice(0, option.lastIndexTemplate)} target="_blank" >`
    }))
}

export function setImagesToHTML(html, imagesById) {
	const localHtml = removeExtraSymbolsFromHtmlType(html)
	const extractedImage = extractImgFromHtml(localHtml)
	const imagesWithNewLink = extractedImage.map(optionImage =>
		setImgSrc(optionImage, imagesById)
	)

	return setNewTagsToHtml(imagesWithNewLink, localHtml, extractImgFromHtml)
}

export function parseMultiPartByType(attachment) {
	return attachment.data.map(content => {
		const { body, headers } = content.part
		const contentType = headers['Content-Type']

		if (isTextHtml(contentType)) {
			return {
				type: 'text/html;',
				body,
			}
		}

		if (isTextPlain(contentType)) {
			return {
				type: 'text/plain;',
				body,
			}
		}
	})
}

export function getContentType(contentType) {
	return contentType.split(';')[0]
}

export function getEmailFrom(email) {
	if (_.get(email, 'headers.From')) return _.get(email, 'headers.From')
	if (_.get(email, 'from.email') && _.get(email, 'from.name')) {
		const email = _.get(email, 'from.email')
		const name = _.get(email, 'from.name')
		return `${name} ${email}`
	}
}


export function filteredAttachments(attachments, condition) {
	return _.isEmpty(attachments) ? [] : attachments.filter(attach => {
		return (
			attach.contentType &&
			condition(attach.contentType, attach.data)
		)
	})
}


export function getAttachmentsLinks(attachments, condition) {
	const localFilteredAttachments = filteredAttachments(attachments, condition)

	return getArrayLinksFromAttachments(setContentTypeImgOctetType(localFilteredAttachments))
}

export function getFormattedEmlData(data) {
	// Replace
	// Header-Name:(newline)(whitespace)header-value
	// With
	// Header-Name: header-value
	// This is to fix an issue with the email parser library merging multiple header values together if the value immediately starts with a newline character
	let  cleanedEmailData = data.replace(/^([\w\d\-]+):+([\r\n]+)+([\s\t]+)*([^\r\n]+)/gm, "$1: $4");

	// Replace 'Content-type' header with 'Content-Type' as this doesnt get parsed correctly
	cleanedEmailData = cleanedEmailData.replace("Content-type:", "Content-Type:");

	// Ensure there is a space after each 'Header-Name:' so that the emails can get parsed correctly ie, 'Header-Name: '
	// cleanedEmailData = cleanedEmailData.replace(/:[s]*/g, ": ");
	cleanedEmailData = cleanedEmailData.replace(/^([\w\d\-]+):+(^[ ]+)*([^\r\n]+)/gm, "$1: $3");

	return new Promise(function(resolve, reject) {
		emlformat.read(cleanedEmailData, (error, formattedData) => {
			if (error)
			{
				console.error("Failed to parse email");
			 	reject();
			}
			resolve(formattedData);
		})
	})
}


export function setContentTypeImgOctetType(attachments) {
    return attachments.map(attach => {
        const extension = getImageExtension(attach.contentType)
        const isOctetStreamExtensionExist = !isOctetStreamImage(attach.contentType) || !extension

        return isOctetStreamExtensionExist ?
        attach:
        {
            ...attach,
            contentType: `image/${extension.split('.').filter(v => v)[0]}`
        }

    })
}
