import { axiosJsonInstance } from "@/axios.instance";
import { AxiosInstance } from "axios";

class MobileApi {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axiosJsonInstance;
    }

    // Mobile
    public async extendMobileSession(): Promise<any> {
        await this.axios.post("/Mobile/Session/Extend");
    }

    public async loadUserPermissions(): Promise<any> {
        const response = await this.axios.get(`/Mobile/Session/GetPermissionsForUser`);
        return response && response.data ? response.data : null;
    }

    public async registerUserDevice(deviceID: string, deviceTypeID: number): Promise<any> {
        const userDevicePayload = {
            DeviceID: deviceID,
            DeviceType: deviceTypeID
        };

        await this.axios.post("/Mobile/Device/Register", userDevicePayload);
    }

    public async fetchAuditEndpoint(): Promise<any> {
        const response = await this.axios.get(`/Mobile/FetchAuditEndpoint`);
        return response && response.data ? response.data : null;
    }

    // Mobile Dispatch Guard
	public async setLocationForGuard(location: string) {
		await this.axios.post("/DispatchGuard/Session/SetLocation", location);
	}

    public async mobileGuardLogout(guardID: number, deviceID: string, deviceTypeID: number): Promise<any> {
        const logoutPayload = {
            GuardID: guardID,
            UserDevice: {
                DeviceID: deviceID,
                DeviceType: deviceTypeID
            }
        };

        await this.axios.post("/DispatchGuard/Session/Logout", logoutPayload);
    }

    public async addEventRecordForGuard(
        eventId: number,
        details: string,
        objectId: number,
        eventRecordTypeId: number
    ): Promise<number> {
        const eventRecordPayload = {
            EventID: eventId,
            Details: details,
            ObjectID: objectId,
            EventRecordTypeID: eventRecordTypeId
        };

        const response = await this.axios.post(`/DispatchGuard/EventRecord/Add`, eventRecordPayload);
        return response && response.data ? response.data : null;
    }

    public async setGuardForUser(guardId: number): Promise<any> {
        const response = await this.axios.post(`/DispatchGuard/Session/SetGuardForUser`, guardId);
        return response && response.data ? response.data : null;
    }

    public async loadGuardForUser(): Promise<any> {
        const response = await this.axios.get(`/DispatchGuard/Session/GetGuardForUser`);
        return response && response.data ? response.data : null;
    }

    public async loadGuardStatus(guardId: number): Promise<any> {
        const response = await this.axios.get(`/DispatchGuard/Status/`, {
            params: {
                guardId
            }
        });
        return response && response.data ? response.data : null;
    }

    public async loadUnavailableStatuses(): Promise<any> {
        const response = await this.axios.get(`/DispatchGuard/UnavailableStatus/LoadAll`);
        return response && response.data ? response.data : null;
    }

    public async setUnavailableStatus(
        guardId: number,
        unavailableStatusId: number,
        unavailableReason: string
    ): Promise<any> {
        const unavailableStatusPayload = {
            GuardId: guardId,
            UnavailableStatusId: unavailableStatusId,
            UnavailableReason: unavailableReason
        };

        await this.axios.post(`/DispatchGuard/UnavailableStatus/Set`, unavailableStatusPayload);
    }

    public async loadDispatchRequestsForGuard(GuardID: number): Promise<any> {
        const response = await this.axios.get(`/DispatchGuard/DispatchRequest/LoadAll`, {
            params: {
                GuardID
            }
        });
        return response && response.data ? response.data : null;
    }

    public async claimDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
        const claimPayload = {
            DispatchQueueId: dispatchQueueId,
            GuardId: guardId
        };

        await this.axios.post(`/DispatchGuard/DispatchRequest/Claim`, claimPayload);
    }

    public async unclaimDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
        const unclaimPayload = {
            DispatchQueueId: dispatchQueueId,
            GuardId: guardId
        };

        await this.axios.post(`/DispatchGuard/DispatchRequest/Unclaim`, unclaimPayload);
    }

    public async getGuardTourByEvent(eventId: number): Promise<any> {
        return await this.axios.get(`/GuardTour/getGuardTourByEvent/${eventId}`);
    }

    public async activateDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
        const activatePayload = {
            DispatchQueueId: dispatchQueueId,
            GuardId: guardId
        };

        await this.axios.post(`/DispatchGuard/DispatchRequest/Activate`, activatePayload);
    }

    public async inactivateDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
        const inactivatePayload = {
            DispatchQueueId: dispatchQueueId,
            GuardId: guardId
        };

        await this.axios.post(`/DispatchGuard/DispatchRequest/Inactivate`, inactivatePayload);
    }

    public async guardArrived(dispatchQueueId: number, guardId: number): Promise<void> {
        const statusPayload = {
            DispatchQueueId: dispatchQueueId,
            GuardId: guardId
        };

        await this.axios.post(`/DispatchGuard/Arrived`, statusPayload);
    }

    public async guardAvailable(guardId: number): Promise<any> {
        await this.axios.post(`/DispatchGuard/Available`, guardId);
    }

    public async addDispatchRequest(
        preferredGuardId: number,
        patrolZoneId: number,
        eventId: number,
        requester: string,
        requesterDescription: string,
        location: string,
        siteTitle: string
    ): Promise<any> {
        const dispatchRequestPayload = {
            PreferredGuardID: preferredGuardId,
            PatrolZoneID: patrolZoneId,
            EventID: eventId,
            Requester: requester,
            RequesterDescription: requesterDescription,
            Location: location,
            SiteTitle: siteTitle
        };

        return await this.axios.post("/DispatchGuard/DispatchRequest/Add", dispatchRequestPayload);
    }

    public async finishDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
        const finishPayload = {
            DispatchQueueId: dispatchQueueId,
            GuardId: guardId
        };
        await this.axios.post(`/DispatchGuard/DispatchRequest/Finish`, finishPayload);
    }

    public async loadNotesForEvent(eventId: number): Promise<any> {
        const response = await this.axios.get(`/DispatchGuard/Notes/ForEvent`, {
            params: {
                eventId
            }
        });
        return response && response.data ? response.data : null;
    }

    public async addNoteForEvent(guardNote: string, eventId: number): Promise<any> {
        const mobileNotePayload = {
            Note: guardNote,
            EventId: eventId
        };

        await this.axios.post(`/DispatchGuard/Notes/Add`, mobileNotePayload);
    }

    // Starred Devices for user.
    public async toggleDeviceStarred(deviceID: number) {
        await this.axios.post("/MobileLiveCameras/Device/ToggleStar/", {
            DeviceId: deviceID
        })
    }

    public async getAllStarredDevices() {
        let devices = await this.axios.get("/MobileLiveCameras/Device/GetAllStarredDevices");
        return devices.data;
    }

    public async getQuickCloseOutcomes() {
        let outcomes = await this.axios.get("/DispatchGuard/QuickClose/GetAll");
        return outcomes.data;
    }
}

const mobileApi = new MobileApi();
export default mobileApi;
