import { Module } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { UserContextState } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: UserContextState = getDefaultState();

export const userContext: Module<UserContextState, RootState> = {
	namespaced: true,
	getters,
	state,
	actions,
	mutations
};
