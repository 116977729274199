import { PollingStatus } from "@/types/PollingStatus";
import { MutationTree } from "vuex";
import { AreaMaskingCount, AreaState, FlatAreaList } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<AreaState> = {
	setAreaList(state, data: any[]) {
		state.AreaList = data;
	},
	setAreaDictionary(state, areaDictionary: Map<number, string>) {
		state.areaDictionary = areaDictionary;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	setAreaMaskingCount(state, areaMaskingCount: AreaMaskingCount) {
		state.areaMaskingCount = areaMaskingCount;
	},
	setAreaMaskingCountPollingStatus(state, areaMaskingCountPollingStatus: PollingStatus) {
		state.areaMaskingCountPollingStatus = areaMaskingCountPollingStatus;
	},
	setFlatAreaList(state, flatAreaList: FlatAreaList){
		state.flatAreaList = flatAreaList;
	}
};
