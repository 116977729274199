
import NavHeader from "@/components/NavHeader.vue";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
import { Component, Mixins } from "vue-property-decorator";
import { Getter } from 'vuex-class';
import { MaskingReason } from "@/types/sv-data/masking/MaskingReason.ts";
import maskingApi from '@/services/api.masking.service';
import PaginatedSearch from "@/mixins/PaginatedSearch";
import MaskingReasonsPagedResponse from "@/types/sv-data/masking/MaskingReasonsPagedResponse";
import { ModalItem } from "@/components/table/generic-update-modal.vue";

@Component({
	components: {
        "nav-header": NavHeader,
        "generic-table": GenericTable
	}
})
export default class MaskingReasonsSetup extends Mixins(PaginatedSearch) {
    // Getters
    @Getter("getPermissions") private permissions: UserPermissions;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

    // Private Vars
    private maskingReasons: MaskingReason[] = [];
    private isLoading: boolean = false;
    private totalRecords: number = 0;

    // Private Methods
    private async mounted(): Promise<void> {
        if (!this.isMaskingAdvancedFeature){
            this.$router.push({ path: "/event-queue" });
        }

        await this.loadMaskingReasons();
    }

    public async loadMaskingReasons(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {     
        if (!this.isMaskingAdvancedEnabled){
            return;
        }

        try {
            this.isLoading = true;
            const cancellableQuery = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);
            const getMaskingReasonsPagedResponse: MaskingReasonsPagedResponse = await maskingApi.getPagedMaskingReasons(cancellableQuery);
            this.totalRecords = getMaskingReasonsPagedResponse.totalRecords;
            this.maskingReasons = getMaskingReasonsPagedResponse.data;
        }
        catch (ex) {
            console.log("Unexpected error fetching masking reasons: " + ex);
        }
        finally {
            this.isLoading = false;
        }
    }

    private async postMaskingReason(maskingReason: MaskingReason): Promise<void> {
        await maskingApi.createMaskingReason(maskingReason);
        await this.loadMaskingReasons(this.mostRecentSearchParams);
    }

    private async putMaskingReason(maskingReason: MaskingReason): Promise<void> {
        await maskingApi.updateMaskingReason(maskingReason);
        await this.loadMaskingReasons(this.mostRecentSearchParams);
    }

    private async deleteMaskingReason(maskingReason: MaskingReason): Promise<void> {
        await maskingApi.deleteMaskingReason(maskingReason.maskingReasonId);
        await this.loadMaskingReasons(this.mostRecentSearchParams);
    }

    private validateForm(maskingReason: MaskingReason): boolean {
        if(maskingReason?.title && maskingReason.title.trim() === "")
        {
            return false;
        }

        if(!maskingReason.isGroup){
            maskingReason.isGroup = false;
        }

        if(!maskingReason.isResponse){
            maskingReason.isResponse = false;
        }

        return maskingReason.isGroup || maskingReason.isResponse;
    }

     private deleteMaskingReasonMessage(maskingReason: MaskingReason): string[] {
        return [`All alarms/areas currently masked with ${maskingReason.title} will lose their masking reason.`];
    }

    // Get Methods
	private get isMaskingAdvancedEnabled(): boolean {
		return this.isMaskingAdvancedFeature && 
        (this.permissions.canViewMaskReasons || this.permissions.canEditMaskReasons);
	}

    private get isMaskingAdvancedFeature(): boolean {
		return this.getFeature(["Alarms", "Masking", "Advanced", "Reasons"]);
	}

    public get columns (): TableHeader[] {
        return [
            {
                title: "Title",
                key: "title",
                order: 1,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "The masking reason title",
                searchable: true,
                visible: true,
                dataType: "input",
                isTermLabel: true,
                sortable: true,
                isSortedByDefault: true
            },
            {
                title: "Area",
                key: "isGroup",
                dataType: "checkbox",
                order: 2,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "If the reason applies to an area",
                visible: true,
                isTermLabel: true,
                sortable: true,
            },
            {
                title: "Alarm",
                key: "isResponse",
                dataType: "checkbox",
                order: 3,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "If the reason applies to an alarm",
                visible: true,
                isTermLabel: true,
                sortable: true,
            }
        ];
    }

    public get modalItems (): ModalItem[] {
        return [
            {
                title: "Title",
                key: "title",
                dataType: "text",
                maxLength: 250,
                required: true,
                placeholder: "Enter the masking reason title",
                validations: this.validateForm,
            },            
            {
                title: "Area",
                key: "isGroup",
                dataType: "checkbox",
                description: "If the reason applies to an area",
                validations: this.validateForm, 
            },
            {
                title: "Alarm",
                key: "isResponse",
                dataType: "checkbox",
                description: "If the reason applies to an alarm",
                validations: this.validateForm,
            },
        ]
    }
}
