
import { Component, Prop, Vue } from "vue-property-decorator";
import { SessionResource } from "@/store/sessions/types";
import { AuditService } from "@/services/auditService";
import { namespace } from "vuex-class";
import api from "@/services/api.service";
import { siteMonitor } from "@/store/site-monitor";

const SessionStore = namespace("sessions");
const SiteMonitor = namespace("siteMonitor");
const Emails = namespace("emails");

@Component
export default class EmailRawDataControl extends Vue {
	@SessionStore.Getter getSession: any;
	@SiteMonitor.Getter("getAuditService") auditService: AuditService;
	@SiteMonitor.Mutation("setAuditService") private setAuditService: () => void;
	@Emails.Action loadEmailData: any;
	@Emails.Mutation("setEventRecordIdToShowEmailFor") private setEventRecordIdToShowEmailFor: (number) => void;

	@Prop({type: Number, required: true, default: null})  private eventRecordId: number;

	private hasRawData: boolean = false;
	private gettingRawDataStatus: boolean = false;
	private checkForRawDataTimeout: NodeJS.Timeout = null;
	private timeoutDurationSeconds: number = 10;
	private readonly maxRetryAttempts: number = 5;
	private retryAttempts: number = 0;

	private mounted(): void {
		if (!this.auditService) {
			this.setAuditService();
		}
		this.checkForRawData();
	}

	/**
	 * Check to see if this filestore contains a raw data .eml file for this event record
	 * @param eventRecordID
	 */
	private async checkForRawData(): Promise<void> {
		// if we're already getting clips
		if (this.gettingRawDataStatus) {
			return;
		}

		// mark that we're getting clips; prevent duplicate operations.
		this.gettingRawDataStatus = true;
		let auth = this.getSession(SessionResource.AuditServiceSession);
		// do request to audit service to get media list.
		 this.auditService.mediaList(auth, this.eventRecordId).then(
			(response: any) => {
				try {
					// get the raw clips
					let rawClips = response.data;

					if (response.data.length == 0) {
						// take a blank string as something we should retry
						this.retryCheckForData();
						return;
					}
					// check if any of the items are `RAW DATA` / .eml items.
					this.hasRawData = rawClips.some((clip) => {
						return clip.MediaType == "RAW DATA";
					});
				} catch (err) {
					console.error(err);
					this.retryCheckForData();
				}

				this.gettingRawDataStatus = false;
			},
			(errorResponse: any) => {
				this.hasRawData = false;
				this.gettingRawDataStatus = false;
				this.retryCheckForData();
			}
		);
	}

	private retryCheckForData() : void {
		if (this.checkForRawDataTimeout !== null) {
			clearTimeout(this.checkForRawDataTimeout);
		}

		if (this.retryAttempts > this.maxRetryAttempts) {
			// we've done enough attempts; dont bother continuing here
			return;
		}

		// exponential backoff retry
		this.timeoutDurationSeconds = this.timeoutDurationSeconds + this.timeoutDurationSeconds;

		this.retryAttempts++;
		this.checkForRawDataTimeout = setTimeout(this.checkForRawData, this.timeoutDurationSeconds * 1000);
	}

	private destroyed() {
		if (this.checkForRawDataTimeout !== null) {
			clearTimeout(this.checkForRawDataTimeout);
		}
	}

	private openOriginalEmail() : void {
		this.setEventRecordIdToShowEmailFor(this.eventRecordId);
	}
}
