import { MutationTree } from "vuex";
import getDefaultState from "./state";
import { MobileRaiseState, MobileRaiseTemplate, MobileRaise, IconSet } from "./types";

export const mutations: MutationTree<MobileRaiseState> = {
	setMobileRaiseTemplates(state, mobileRaiseTemplates: MobileRaiseTemplate[]) {
		state.mobileRaiseTemplates = mobileRaiseTemplates;
	},
	setMobileRaises(state, mobileRaises: MobileRaise[]) {
		state.mobileRaises = mobileRaises;
	},
	setIconSets(state, iconSets: IconSet[]) {
		state.iconSets = iconSets;
	},
	addMobileRaiseTemplate(state, template: MobileRaiseTemplate) {
		state.mobileRaiseTemplates.push(template);
	},
	updateMobileRaiseTemplate(state, template: MobileRaiseTemplate) {
		const index = state.mobileRaiseTemplates.findIndex(
			t => t.mobileRaiseTemplateID === template.mobileRaiseTemplateID
		);
		state.mobileRaiseTemplates.splice(index, 1, template);
	},
	deleteMobileRaiseTemplate(state, templateId: number) {
		const index = state.mobileRaiseTemplates.findIndex(t => t.mobileRaiseTemplateID === templateId);
		state.mobileRaiseTemplates.splice(index, 1);
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
