
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { ExternalMapLayer, ExternalMapLayerType, ExternalMapLayerParameter } from "@/store/external-map-layers/types";
// SV components
import NavHeader from "@/components/NavHeader.vue";
import vSelect from "vue-select";
import EnhancedSingleMap from "@/components/EnhancedSingleMap.vue";
import { FeaturesList, UserPermissions, VueBootstrapField } from "@/store/types";
import { get } from "lodash";

import ExternalMapLayerPropertiesSetup from "@/components/external-map-layer/ExternalMapLayerPropertiesSetup.vue";
import ExternalMapLayerModal from "@/components/external-map-layer/ExternalMapLayerModal.vue";
import DeleteExternalMapLayerModal from "@/components/external-map-layer/DeleteExternalMapLayerModal.vue";
import SureViewIcon from "@/components/SureViewIcon.vue"
import { stringMixin } from "@/mixins";

const ExternalMapLayerStore = namespace("externalMapLayers");

const { ellipseAfterX } = stringMixin.methods;

@Component({
	components: {
		"nav-header": NavHeader,
		"vue-select": vSelect,
		"enhanced-single-map": EnhancedSingleMap,
		"external-properties": ExternalMapLayerPropertiesSetup,
		"external-map-layer-modal": ExternalMapLayerModal,
		"delete-external-map-layer-modal": DeleteExternalMapLayerModal,
		"sureview-icon": SureViewIcon,
	}
})
export default class ExternalMapLayerSetup extends Vue {
	@ExternalMapLayerStore.Getter("getExternalMapLayers") layersList!: ExternalMapLayer[];
	@ExternalMapLayerStore.Getter("getExternalMapLayerTypes") typesList!: ExternalMapLayerType[];
	@ExternalMapLayerStore.Action loadExternalMapLayers: () => Promise<void>;
	@ExternalMapLayerStore.Action loadExternalMapLayerTypes: () => Promise<void>;
	@ExternalMapLayerStore.Action createOrUpdateExternalMapLayer: (externalMapLayer: ExternalMapLayer) => Promise<void>;
	@ExternalMapLayerStore.Action deleteExternalMapLayer: (externalMapLayerID: number) => Promise<void>;
	@ExternalMapLayerStore.Action updateExternalMapLayerVisibility: (externalMapLayer: ExternalMapLayer) => Promise<void>;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getPermissions: UserPermissions;

	// new bootstrap table data
	private fields: VueBootstrapField[] = [
		{ key: "title", label: "Title", sortable: true },
		{ key: "url", label: "URL", sortable: true },
		{ key: "displaying", label: "Displaying", sortable: true },
		{ key: "actions", label: "Actions", sortable: false },
	];

	private mapLayerTableIsBusy: boolean = true;
	private selectedMapLayer: ExternalMapLayer = null;
	private mapLayerModal: boolean = false;
	private deleteLayerModal: boolean = false;
	private filterSearch: string = "";

	externalMapLayerParameters: ExternalMapLayerParameter[];
	externalMapLayerType: number = 0;

	private setDeleteMapLayer(mapLayer: ExternalMapLayer): void {
		this.selectedMapLayer = mapLayer;
		this.deleteLayerModal = !this.deleteLayerModal;
	}

	private setSelectMapLayer(mapLayer: ExternalMapLayer): void {
		this.selectedMapLayer = mapLayer;
		this.mapLayerModal = !this.mapLayerModal;
	}

	private async toggleLayerVisible(mapLayer: ExternalMapLayer): Promise<void> {
		this.updateExternalMapLayerVisibility(mapLayer);
	}

	// If feature is on / off
	private get isExternalMapLayersEnabled(): boolean {
		return (
			get(this.featuresList, ["ExternalMapLayers"]) &&
			(this.getPermissions.canViewExternalMapLayers || this.getPermissions.canEditExternalMapLayers)
		);
	}

	// If feature is on / off
	private get isExternalMapLayerPropertiesEnabled(): boolean {
		return get(this.featuresList, ["ExternalMapLayers", "AutoSyncAttributes"]);
	}

	private async mounted(): Promise<void> {
		if (this.isExternalMapLayersEnabled) {
			await this.loadExternalMapLayerTypes();
			await this.loadExternalMapLayers();
		}
		this.mapLayerTableIsBusy = false;
	}

	public get canEditExternalMapLayers(): boolean {
		return  this.getPermissions.canEditExternalMapLayers;
	}

	private get getMapLayerTitles(): string[] {
		if (this.layersList && this.layersList.length > 0) {
			return this.layersList.map(layer => layer.title);
		}

		return [];
	}

	private ellipseUrl(row: ExternalMapLayer): string {
		return ellipseAfterX(row.url, 100);
	}
}
