import { render, staticRenderFns } from "./account-details.vue?vue&type=template&id=5d3ba72a&scoped=true"
import script from "./account-details.vue?vue&type=script&lang=ts"
export * from "./account-details.vue?vue&type=script&lang=ts"
import style0 from "./account-details.vue?vue&type=style&index=0&id=5d3ba72a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d3ba72a",
  null
  
)

export default component.exports