import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { CSVFormHandler } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: CSVFormHandler = getDefaultState();
const namespaced: boolean = true;

export const csvFormHandler: Module<CSVFormHandler, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};
