



































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import api from "@/services/api.service";
import vselect3 from "vselect3";

@Component({
	components: {
		vselect3
	}
})
export default class AssetTypeSelect extends Vue {
	@Prop() private value: any;
	@Prop({default: false, type:Boolean }) private readonly?: boolean;
	@Prop({default: false, type:Boolean }) private assetOnlyTypes: boolean;
	@Prop({default: false, type:Boolean}) private clearable: boolean;

	private assetTypesList: any[] = [];
    private localValue: any = {};
    private initialValue: any = {};
	private isRemoveAssetTypeDialogOpen: boolean = false;

	private async mounted() {
        const value = this.value;
        this.initialValue = value
        this.localValue = value;
		this.assetTypesList = (await api.getCachedAssetTypes()).filter(x => x.canBeUser == !this.assetOnlyTypes)
	}

	@Emit('input')
	private valueChanged(value) {
		this.localValue = value ? value.assetTypeId : null;
		if(!this.localValue && this.initialValue && this.clearable){
			this.isRemoveAssetTypeDialogOpen = true;
		} else {
			return this.localValue;
		}
	}

    public get currentAssetType() {
        if(this.localValue){
            return this.assetTypesList.find(x => x.assetTypeId === this.localValue);
        }
        return null;
	}

	@Emit('input')
	private confirmRemove(){
		this.isRemoveAssetTypeDialogOpen = false;
		this.localValue = null;
		return null;
	}

	private cancelRemove(){
		this.localValue = this.initialValue;
		this.isRemoveAssetTypeDialogOpen = false;
	}
}
