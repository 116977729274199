
import vSelect3 from "vselect3";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import { Tenant } from "@/store/areas/types";
import api from "@/services/api.service";
import supportEngagementApi from "@/services/api.supportEngagement.service";
import { UserDetails } from "@/store/user-management/types";
import { truncateString } from "@/filters";
import AvailableTenantsResponse, { AvailableTenant } from "@/types/sv-data/responses/AvailableTenantsResponse";

const UserManagement = namespace("userManagement");
const Areas = namespace("areas");

@Component({
	components: {
		"v-select-3": vSelect3
	},
	filters: {
		truncateString
	}
})
export default class SwitchTenant extends Vue {
	@Action logout;
	@Getter("getUserId") currentUserId: number;
	@Getter("getUserTenantGroupId") currentTenantId: number;

	@Areas.Action fetchAreaDictionary: () => Promise<void>;
	@Areas.State areaDictionary: Map<number, string>;


	private showTenantSelect: boolean = false;
	private tenantsList: any[] = [];
	private availableTenants: AvailableTenant[] = [];
	private tenant: AvailableTenant = null;
	private isSettingTenant: boolean = false;
	private originalTenant: Tenant = null;
	private isLoading: boolean = false;

	// Can and should only be set when unit testing,
	// sets the modal to static so its content can be accessed from within a unit test.
	// Normally we can set modals to static without issue, but because this is used from within
	// the NavMenu, the modal pops up from within the menu if static is true.
	private isUnitTest: boolean = false;

	private async mounted() {
		if (this.areaDictionary.size === 0) {
			await this.fetchAreaDictionary();
		}
	}

	private async getTenants() {
		try {
			this.isLoading = true;
			this.tenantsList = (await api.getTenants()).data;
			this.tenantsList.sortBy("groupID");

			let currentUser = await api.getUserById(this.currentUserId);

			if (currentUser.comment) {
				let originalTenantGroupId = parseInt(currentUser.comment);
				this.originalTenant = this.tenantsList.find(t => t.groupID === originalTenantGroupId);
			}

			const availableTenantsResponse: AvailableTenantsResponse = await supportEngagementApi.getAvailableTenants();
			this.availableTenants = availableTenantsResponse.tenants;
		} catch {
			this.$notify({
				type: "error",
				title: "Error",
				text: "Failed to load tenant details.",
			});
		}

		this.isLoading = false;
	}

	private async ok() {
		this.isSettingTenant = true;
		await api.setTenant(this.tenant.tenantId);
		this.logout();
		this.isSettingTenant = false;
	}

	private async resetTenant() {
		this.isSettingTenant = true;
		await api.resetTenant();
		this.logout();
		this.isSettingTenant = false;
	}
}
