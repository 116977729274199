
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace, Getter, Mutation, Action } from "vuex-class";
import api from "@/services/api.service";
import {
	PasswordModel,
	PasswordChangeResult
} from "@/store/my-account/types.ts";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";
import { BModal } from "bootstrap-vue";
import AccountDetails from "./account-details.vue";
import UserDTO from "@/types/sv-data/users/UserDTO";

const UserManagement = namespace("userManagement");

@Component({
	components: {
		"account-details": AccountDetails
	}
})
export default class MyAccount extends Vue {
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getUserId") currentUserId: number;

	private redirectMFAUrl = "";
	private isSuiteUser: boolean = false;
	
	async mounted() {
		const user = await api.getUserById(this.currentUserId);
		this.isSuiteUser = user?.isSuiteUser;
		this.redirectMFAUrl = await api.mfaRedirectUrl();
	}

	private showAccountDetails: boolean = false;

	// If feature is on / off
	public get IsAccountDetailsEnabled() {
		return get(this.featuresList, ["AccountDetails"]);
	}

	private checkForSuiteUser() : void {
		if (this.isSuiteUser) {
			window.location.href = this.redirectMFAUrl + "?page=" + window.location.href;
		} else {
			this.showAccountDetails = !this.showAccountDetails;
		}
	}
}
