














































import {
	Vue,
	Component,
	Prop,
	Watch,
	Emit
} from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import {
	MatrixContents,
	MatrixCamera
} from "@/store/site-monitor-cameras/types.ts";
import { EventDetails } from "@/store/site-monitor/types";
import { renderDistance } from "@/filters";
import { stringMixin } from "@/mixins";
import SystemViewCameraIcon from "@/components/system-view/SystemViewCameraIcon.vue";

const SMCameras = namespace("siteMonitorCameras");
const SiteMonitor = namespace("siteMonitor");

const { isNullOrWhitespace } = stringMixin.methods;

// Utility extensions
import "@/scripts/array-operations";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";

/**
 * Represents a camera that is visible within the media matrix
 */
interface VisibleMatrixCamera {
	areaTitle: string;
	deviceId: number;
	distance: number;
	groupId: number;
	imperial: boolean | null | undefined;
	index: number;
	latLong: string;
	title: string;
}

@Component({
	components: {
		"system-view-camera-icon": SystemViewCameraIcon
	},
	filters: {
		renderDistance
	}
})
export default class MatrixContent extends Vue {
	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;
	@SiteMonitor.Getter("getMapCircleCenter") mapCircleCenter: string;

	@SMCameras.Getter("getAllMatrixContents") contents!: MatrixContents;
	@SMCameras.Getter("getLayouts") layouts!: number[][];
	@SMCameras.Getter("getLayoutIndex") layoutIndex!: number;

	@SMCameras.Action getAllDeviceDetails: ({
		deviceIDs,
		eventID,
		latLong
	}) => Promise<any[]>;

	@SMCameras.Mutation highlightCell: (index: number) => void;
	@SMCameras.Mutation unhighlightCell: () => void;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	// Props
	@Prop() open!: boolean;
	@Prop() searchText!: string;

	/**
	 * List of cameras (+ details) that are visible in the media matrix.
	 */
	public visibleCameras: VisibleMatrixCamera[] = [];

	/**
	 * Gets the number of columns in the matrix.
	 */
	public get horizontalColumnCount() {
		return this.layouts[this.layoutIndex][0];
	}

	/**
	 * Gets the number of rows in the matrix.
	 */
	public get verticalColumnCount() {
		return this.layouts[this.layoutIndex][1];
	}

	/**
	 * Returns the visible cameras list, sorted by index and filtered by the search query.
	 */
	public get filteredVisibleCameras () {
		return this.visibleCameras
			.distinct("index")
			.filter(
				cam => isNullOrWhitespace(this.searchText) ||
					cam.title.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
			)
			.sortBy("index");
	}

	/**
	 * Called on component mount. Triggers a refresh of the visibleCameras list
	 * (only ever necessary when doing hot-reload during development).
	 */
	public mounted() {
		this.onContentsChanged(this.contents, null, true);
	}

	/**
	 * Trigger an update of device data when the map circle is moved, as this will mean
	 * distance needs to be re-calculated.
	 */
	@Watch("mapCircleCenter")
	public async onMapCircleCenterChanged() {
		await this.onContentsChanged(this.contents, null, true);
	}

	/**
	 * Handles the update of device details, usually triggered when the matrix contents
	 * is changed (ie camera deleted/added, etc).
	 */
	@Watch("contents", { deep: true })
	public async onContentsChanged(
		newContents: MatrixContents,
		oldContents: MatrixContents,
		mapCircleChanged: boolean = false
	) {

		// If the contents are empty then clear the visible cameras list and return.
		if (!this.contents) {
			this.visibleCameras = [];
			return;
		}

		// Fetch all cameras within the matrix contents
		let cameras = Object.values(this.contents)
			.filter(item => item.camera !== undefined && item.camera !== null)
			.map(item => item.camera)
			.filter(camera => camera.index !== undefined && camera.index !== null && (camera.objectID !== undefined || camera.objectId !== undefined));

		let camerasToFetch: number[] = [];

		for (let camera of cameras) {
			const cameraObjectID = camera.objectID ? camera.objectID : camera.objectId;
			// Search the current visible cameras to see if we've already fetched details for this camera.
			let currentCamDetails = this.visibleCameras.find(
				cam => cam.deviceId == cameraObjectID
			);

			// If the map circle *has* changed, then we need to re-fetch as the distance needs to be recalculated.
			if (!mapCircleChanged && currentCamDetails) {
				if (currentCamDetails.index != camera.index) {
					// If just the index has changed, then set the new index and continue.
					currentCamDetails.index == camera.index;
				}

				continue;
			}

			// If the map circle has changed, or the camera doesn't already have a title, areaTitle and distance,
			// then we need to add this camera to the list of devices to fetch updated details for.
			if (
				mapCircleChanged ||
				((isNullOrWhitespace(camera.title) ||
					isNullOrWhitespace(camera.areaTitle) ||
					camera.distance === undefined ||
					camera.distance === null) &&
					cameraObjectID !== undefined)
			) {
				// Push this cameras ID
				camerasToFetch.push(cameraObjectID);
			}
		}

		let updatedCameraDetails = [];

		// If we've got any cameras to fetch...
		if (camerasToFetch.length > 0) {
			try {
				// Make the call to the back-end
				updatedCameraDetails = await this.getAllDeviceDetails({
					deviceIDs: camerasToFetch,
					eventID: this.eventDetails.eventID,
					latLong: this.mapCircleCenter
				});
			} catch (err) {
				console.error(`Unable to fetch camera details: ${err}`);

				this.visibleCameras = [];
				return;
			}
		}

		// Update the visible cameras list
		this.visibleCameras = cameras.map(camera => {
			const cameraObjectID = camera.objectID ? camera.objectID : camera.objectId;
			// Check to see if there are *new* details for this camera within our updatedCameraDetails array.
			// If there aren't, we'll just use the data we currently have for it from the matrix content.
			let newDetails = updatedCameraDetails.find(
				details => details.deviceId == cameraObjectID
			);

			if (newDetails) {
				// If we've got updated details for the current camera, then return them
				return {
					...newDetails,
					index: camera.index,
					objectID: cameraObjectID
				};
			} else {
				// Otherwise, return the current data for the camera... failing that,
				// we'll just return the camera data we got from the matrix itself
				let currentCamDetails = this.visibleCameras.find(
					cam => cam.deviceId == cameraObjectID
				);

				if (currentCamDetails) {
					currentCamDetails.index = camera.index;
					return currentCamDetails;
				}

				return camera;
			}
		});
	}

	/**
	 * Returns the icon colour for the given camera and column/row.
	 */
	public cameraIconColor(camera: MatrixCamera, i: number, j: number) {
		return (i - 1) * this.horizontalColumnCount + j === camera.index
			? "redCameraIndicator"
			: "greyCameraIndicator";
	}

	private get isSystemViewEnabled() {
		return get(this.featuresList, ["SystemView"]);
	}

	// Events
	@Emit()
	public onOpened() {}
}
