
import { Prop, Mixins, Component, Watch } from "vue-property-decorator";
import api from "@/services/api.service";
import SystemViewTreeNode from '@/types/sv-data/system-view/SystemViewTreeNode';
import { SetupSummaryResponse, SystemViewAlarmOverview } from '@/types/sv-data/system-view/SetupSummary';
import { Getter, namespace } from 'vuex-class';
import { UserPermissions, FeaturesList } from '@/store/types';
import { get } from 'lodash';
import SystemViewMap from "./SystemViewMap.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { MapLayerItem } from "@/types/sv-data/system-view/MapLayerItem";
import SystemViewAlarmAutomations from './SystemViewAlarmAutomations.vue';
import { truncateString } from "@/filters";
import HideMapMixin from "@/mixins/HideMapMixin";
import customFieldsApi from "@/services/api.customField.service";
import CustomDefinedFieldsDisplay from "@/components/custom-fields/CustomDefinedFieldsDisplay.vue";
import { CustomFieldDto, CustomFieldTableTypes, CustomFieldValues } from "@/store/custom-fields/types";

const CustomFields = namespace("customFields");

@Component({
	components:{
		"system-view-map": SystemViewMap,
		"system-view-alarm-automations": SystemViewAlarmAutomations,
		"custom-fields-display": CustomDefinedFieldsDisplay,
		VuePerfectScrollbar
	},
	filters: {
		truncateString
	}
})
export default class SetupOverviewAlarm extends Mixins(HideMapMixin) {

	@Prop({ required: true, default: null }) private selectedNode: SystemViewTreeNode;

	@Getter getPermissions: UserPermissions;
	@Getter getFeaturesList: FeaturesList;

	@CustomFields.Action private retrieveCustomFields: ({ tableType: number, live: boolean }) => Promise<void>;
	@CustomFields.State private alarmCustomFields: CustomFieldDto[];

	private setupResponse: SetupSummaryResponse =  null;
	private alarmSummary: SystemViewAlarmOverview = null;
	private locationDetails: MapLayerItem[] = [];
	private customFieldsForObject: CustomFieldValues[] = [];
	private maxCharacters: number = 27;

	private readonly alarmCustomFieldType = CustomFieldTableTypes.Alarm;
	private readonly defaultMaxCharacters = 27;
	private readonly maxCharacterDivider = 70

	private async mounted(): Promise<void> {
		await this.retrieveAlarmDetails();

		window.addEventListener("resize", this.onWindowResized);
	}

	@Watch("selectedNode", { deep: true })
	private async retrieveAlarmDetails(): Promise<void> {
		if(this.selectedNode && this.canUseSystemView)
		{
			this.alarmSummary = null; // Reset previous display data

			this.setupResponse = await api.getSetupSummary(this.selectedNode.objectTypeId, this.selectedNode.objectId)

			if(this.setupResponse && !this.setupResponse?.error ) {
				try {
					this.alarmSummary =  JSON.parse(this.setupResponse.summary) as SystemViewAlarmOverview;
				}
				catch (e) {
					console.error("Failed to Parse Alarm summary: " + e);
					this.setupResponse.error = "Failed to Parse Alarm summary";
				} finally {
					this.updateMapData();
				}
			}

			if (this.isCustomFieldAlarmsEnabled) {
				this.customFieldsForObject = await customFieldsApi.retrieveCustomFieldsForObject(this.selectedNode.objectId, CustomFieldTableTypes.Alarm);
			}
		}
	}

	private get canUseSystemView(): boolean {
		return get(this.getFeaturesList, ["SystemView"]) && (this.getPermissions.canViewSystemView || this.getPermissions.isSystemAdmin);
	}

	private get showAutomations(): boolean {
		return !!get(this.getFeaturesList, ["Alarms", "CameraAutomation"]);
	}

	private get isCustomFieldAlarmsEnabled(): boolean {
		return get(this.getFeaturesList, ["CustomFields", "Alarms"]);
	}

	private get getSystemOverviewCols(): string{
		return this.isHideMapsEnabled ? '12' : '6';
	}

	private updateMapData(): void {
		if (!!this.alarmSummary && !!this.alarmSummary.mapLocations) {
			this.locationDetails = this.alarmSummary.mapLocations;
		} else {
			this.locationDetails = [];
		}
	}

	private get alarmGrouping(): string {
		switch(this.alarmSummary.isRaiseIndividual) {
			case false:
				return this.alarmSummary.isRaiseGrouped ? "By Alarm" : "By Area";
			case true:
				return "Not Grouped";
			default:
				return "Not Set"
		}
	}

	private get hasLinkedCameras(): boolean {
		return !!this.alarmSummary.automations && this.alarmSummary.automations.length > 0;
	}

	private get serverTypeEventTitle(): string {
		return this.alarmSummary?.serverTypeEventTitle ?? "Default" ;
	}

	private onWindowResized(): void {
		this.maxCharacters = Math.min(this.defaultMaxCharacters, window.innerWidth / this.maxCharacterDivider);
	}
}
