import { GetterTree } from "vuex";
import { SessionsState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<SessionsState, RootState> = {
	getSession: state => (resourceId: number) => {
		return typeof state.sessions[resourceId] === "undefined"
			? null
			: state.sessions[resourceId];
	}
};
