
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import vSelect3 from "vselect3";
import { EventDetails } from "@/store/site-monitor/types";
import { EventOutcome, EventOutcomeResponse } from "@/types/EventOutcome";
import { UserPermissions } from "@/store/types";
import NestedSelect from "@/components/utilities/NestedSelect.vue";
import EventOutcomeAndNote from "@/types/sv-data/EventOutcomeAndNote";
import UpdateEventOutcomeRequest from "@/types/sv-data/requests/UpdateEventOutcome";
import api from "@/services/api.service";

const SiteMonitor = namespace("siteMonitor");
const Tasks = namespace("tasks");

@Component({
	components: {
		"v-select-3": vSelect3,
		"nested-select": NestedSelect
	}
})
export default class SetEventOutcomeAndNote extends Vue {
	@Prop(Number) eventId?: number;
	@Getter getFeature: (featuresList: string[]) => boolean;
	@Getter getPermissions: UserPermissions;
	@SiteMonitor.Getter getEventDetails: EventDetails;
	@SiteMonitor.Getter("eventOutcomesFlatList") private outcomesFlatList: EventOutcome[];
	@SiteMonitor.Mutation setEventDetails: any;
	@SiteMonitor.Mutation resetEventRecords: () => void;
	@SiteMonitor.Action("loadOutcomesForEvent") loadOutcomes: (eventId: number) => Promise<EventOutcomeResponse>;
	@SiteMonitor.Action private fetchEventDetails: (eventId: number) => Promise<EventDetails>;
	@Tasks.Getter hasEventOutcomeTask: boolean;

	private outcomeTree: any[] = [];
	private outcomeSelectionPath: EventOutcome[] = [];
	private outcomeSelectionValid: boolean = true;
	private outcomeNote: string = "";

	public async submit(): Promise<void> {
		if (!this.isOutcomeNoteValid || !this.outcomeSelectionValid) {
			throw Error("Unable to update event outcome. Outcome or note is invalid");
		}
		try {
			let payload: UpdateEventOutcomeRequest = {
				eventId: this.eventId,
				eventOutcomeId: this.eventOutcome!.eventOutcomeID,
				note: this.outcomeNote
			};
			await api.updateEventOutcomeByNotification(payload);
		} catch (err) {
			console.error(err);

			throw Error("Unable to update event outcome, please try again later. If the problem persists, contact your support provider.");
		}
	}

	private async mounted(): Promise<void> {
		await this.loadOutcomesForEvent();
		await this.fetchEventDetails(this.eventId);
		this.outcomeNote = this.getEventDetails?.eventOutcomeNote ?? "";
	}

	private async loadOutcomesForEvent(): Promise<void> {
		let response = await this.loadOutcomes(this.eventId);
		this.outcomeTree = response.eventOutcomeTree;

		this.buildOutcomePath();
	}

	@Watch("eventOutcome")
	private validate(): boolean {
		this.outcomeSelectionValid = !(this.eventOutcome != null && this.eventOutcome.childOutcomes && this.eventOutcome.childOutcomes.length > 0);

		let response: EventOutcomeAndNote = {
			valid: (this.isOutcomeNoteValid && this.outcomeSelectionValid),
			eventOutcomeNote: this.outcomeNote,
			eventOutcomeId: this.eventOutcome!.eventOutcomeID
		};
		this.$emit("on-change", response);

		return (this.isOutcomeNoteValid && this.outcomeSelectionValid);
	}

	private buildOutcomePath(): void {
		if (this.getEventDetails.eventOutcomeID) {
			let idToFind = this.getEventDetails.eventOutcomeID;

			for (let outcome of this.outcomeTree) {
				if (this.checkOutcomeInPath(outcome, idToFind)) {
					this.outcomeSelectionPath = [outcome, ...this.outcomeSelectionPath];
					return;
				}
			}
		}
		this.outcomeSelectionPath = [];
	}

	private checkOutcomeInPath(outcome: any, idToFind: number): boolean {
		if (outcome.eventOutcomeID == idToFind) {
			return true;
		}

		if (!!outcome.childOutcomes && outcome.childOutcomes.length > 0) {
			for (let child of outcome.childOutcomes) {
				if (this.checkOutcomeInPath(child, idToFind)) {
					this.outcomeSelectionPath = [child, ...this.outcomeSelectionPath];
					return true;
				}
			}
		}
		return false;
	}

	private get eventOutcome(): EventOutcome | null {
		return this.outcomeSelectionPath.length === 0
			? null
			: this.outcomeSelectionPath[this.outcomeSelectionPath.length - 1];
	}

	private get isOutcomeNoteValid(): boolean {
		return this.outcomeNote.length <= 5000;
	}

	private get noteStatus(): string {
		let status = `${this.outcomeNote.length} | 5000`;

		if (this.outcomeNote.length > 5000) {
			status = status + ` - Exceeds maximum allowed length`;
		}

		return status;
	}
}
