
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import {
	getEnhancedMapping,
	IEnhancedMapping,
	IMap,
	ILatLng,
	IMarker,
} from "@sureview/v2-mapping-saas";
import { Getter } from "vuex-class";
import { getMapStyle } from "@/scripts/mapping/mapStyle";
import { LocationBounds } from "@/services/gps.service";
import FieldOpsMixin from "@/mixins/FieldOpsMixin";
import { MapLayerItem } from "@/types/sv-data/system-view/MapLayerItem";

@Component({})
export default class SystemViewMap extends Mixins(FieldOpsMixin) {

	public $refs: any = {
		mappingContainer: HTMLElement
	};

	@Prop({required: false, default: []}) private locationDetails: MapLayerItem[];

	@Getter("getMapKey") private mapKey: any;
	@Getter private getMapType: any;

	private enhancedMapping: IEnhancedMapping | null = null;
	private map: IMap = null;
	private markers: IMarker[] = [];
	private readonly noLocation: string[] = ["0", "", "0"];

	private async mounted(): Promise<void> {
		this.enhancedMapping = await getEnhancedMapping("google", this.mapKey);
		this.setupMap();
		this.setMarkerLocation();
	}

	private destroyed() {
		this.removeMarkers();
	}

	@Watch("locationDetails")
	private onLocationChanged(): void {
		this.removeMarkers();
		this.setupMap();
		this.setMarkerLocation();
	}

	private removeMarkers(): void {
		if (!!this.markers && this.markers.length > 0) {
			this.markers.forEach(marker => {
				marker.visible = false;
				marker.remove();
			});
		}
	}

	private setupMap(): void {
		// Use the first location as the map view area
		var locationArray: string[] = this.getLocationArray(this.locationDetails[0]?.latLong ?? null);

		let pos: LocationBounds = {
			location: { lat: +locationArray[0], lng: +locationArray[2] },
			viewport: null
		};

		this.map = this.enhancedMapping.createMap(
			this.$refs.mappingContainer,
			pos.location,
			0,
			false,
			this.getMapType,
			getMapStyle(this.getMapType, false)
		);

		this.map.map.setZoom(0);
	}

	private getLocationArray(location: string): string[] {
		if (location) {
			return location.split(/(\s+)/);
		}

		return this.noLocation;
	}

	private setMarkerLocation(): void {

		if (!this.locationDetails || this.locationDetails.length === 0) {
			this.map.map.setZoom(0);
			return;
		}

		let bounds = new google.maps.LatLngBounds();

		this.locationDetails.forEach(location => {

			var locationArray: string[] = this.getLocationArray(location.latLong);

			if (locationArray === this.noLocation) {
				return;
			}

			// Only showing for camera for now
			const markerLocation: ILatLng = {
				lat: +locationArray[0],
				lng: +locationArray[2]
			};

			const id = !!this.markers && this.markers.length > 0 ? this.markers.length : 0;

			// We are only concerned with displaying so only provide Type and location
			const marker = this.enhancedMapping.createMarker(
				this.map,
				id, // mapLayerItemId // This will be used to identify it to kill it
				null, // eventRecordId
				location.mapLayerItemTypeId, // mapLayerItemTypeId: number | string | MarkerType
				null, // asset type
				location.title, // title
				markerLocation, // location
				null, // region
				null, // minElevation
				null, // maxElevation
				true // visible: boolean
			);

			this.markers.push(marker);
			bounds.extend(marker.position);
		});

		this.map.map.fitBounds(bounds);
	}
}
