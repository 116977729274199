
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import RulesLogicSteps from './RulesLogicStep.vue'
import isEqual from 'lodash.isequal';
import { v4 as uuidv4 } from "uuid";
import { RulesLogicAllowed, RuleStep, CardValidation, CardValidationMap } from '@/store/rules-engine/types';

@Component(
    {
        components:
        {
            "rules-logic-step": RulesLogicSteps
        }
    })
export default class RulesLogicBuilder extends Vue {
    //Props
    @Prop()
    private allowedLogic: RulesLogicAllowed;

    @Prop()
    private section: string;

    @Prop()
    private value: RuleStep[];

    @Prop()
    private rootText: string;

	@Prop({ type: Boolean, default: false })
	private readOnly: boolean;

    //Variables
    private logicSteps: RuleStep[] = [];
	private ruleValids: CardValidationMap = new CardValidationMap();

    //Getters
    private get allowAnd(): boolean
    {
        return this.allowedLogic ? this.allowedLogic.allowAnd : false;
    }

    private get allowOr(): boolean
    {
        return this.allowedLogic ? this.allowedLogic.allowOr : false;
    }

    private get allowSubRules(): RulesLogicAllowed
    {
        return this.allowedLogic.subRules ? this.allowedLogic.subRules : { allowAnd: false, allowOr: false };
    }

    //Watchers
    @Watch("value")
    private updateValue(): void
    {
        if(!this.value || this.value.length == 0)
        {
            if(this.section == "Trigger"){
                this.logicSteps = [];
                this.$emit("isValid", {isValid: true, message:""})
            }else{
                this.logicSteps = [{ id: uuidv4(), operator:"And", condition: { typeId: null, value: null }, subRules:[]}];
            }
            return;
        }

        if(!isEqual(this.logicSteps, this.value))
        {
            this.logicSteps = this.value;
        }

        this.$forceUpdate();
        this.logicStepsUpdated();
    }

    //Methods
    private mounted(): void
    {
        this.updateValue();
    }

    private logicStepsUpdated(): void
    {
        this.$emit("input", this.logicSteps);
        this.$forceUpdate();
    }

    private addRule(operator: string): void
    {
        this.logicSteps.push({id: uuidv4(), operator: operator, condition: {typeId: null, value: null}, subRules: []});
        this.$forceUpdate();
    }

    private stepUpdated(step: RuleStep): void
    {
        var index = this.logicSteps.findIndex(c => c.id == step.id)
        if(index != null && index != undefined && index > -1)
        {
            this.logicSteps[index] = step;
            this.$forceUpdate();
        }
        else
        {
            console.error("match could not be found")
        }

        this.logicStepsUpdated();
    }

    private stepDeleted(step: RuleStep): void
    {
        if(!this.logicSteps)
        {
            return;
        }

        this.logicSteps = this.logicSteps.filter(c => c.id != step.id)
        if(this.ruleValids[step.id])
        {
            delete this.ruleValids[step.id];
        }

        this.logicStepsUpdated();
    }

    @Emit("isValid")
    private ruleValidationUpdated(isValid: CardValidation, ruleId: string): CardValidation
    {
        this.ruleValids[ruleId] = isValid;
        for (var ruleValid in this.ruleValids)
        {
            if(!this.ruleValids[ruleValid].isValid)
            {
                return {isValid: false, message: `One or more ${this.rootText}s are not valid`};
            }
		}

        return { isValid: true, message: "" };
    }

	private get triggerExists(): boolean {
		return this.logicSteps?.length > 0;
	}
}
