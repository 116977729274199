export interface ExternalMapLayerState {
	externalMapLayers: ExternalMapLayer[];
	externalMapLayerTypes: ExternalMapLayerType[];
}

export interface ExternalMapLayer {
	externalMapLayerID?: number;
	title: string;
	externalMapLayerTypeID: ExternalMapLayerTypeID;
	url: string;
	dataObjectURL?: string;
	customClickHandler?: string;
	groupID?: number;
	defaultVisible: boolean;
	displaying?: boolean;
	customClickHandlerAttribute?: string;
	parameters?: ExternalMapLayerParameter[];
	username?: string;
	password?: string;
	tokenServiceUrl?: string;
	refreshInterval?: number;
}

export interface ExternalMapLayerUserLink {
	userId: number;
	externalMapLayerID: number;
	display: boolean;
}

export interface ExternalMapLayerType {
	externalMapLayerTypeId: number;
	title: string;
}

// Class that handles converting ArcGis Fields
export class ExternalMapLayerParameter {
	constructor(properties = null, externalMapLayerId: number) {
		if (properties) {
			this.title = this.checkNameOrTitle(properties);
			this.externalMapLayerId = externalMapLayerId;
			this.defaultValue = this.setDefaultValue(properties);
			this.type = properties.type;
		}
	}

	externalMapLayerParameterId: number = 0;
	externalMapLayerId: number = 0;
	type: string = "";
	title: string = "";
	defaultValue: string = "";
	isVisible: boolean = true;

	// Check the properties object for the appropriate field to assign to title
	private checkNameOrTitle(properties: any): string {
		if (properties.hasOwnProperty("name")) {
			return properties.name;
		} else if (properties.hasOwnProperty("alias")) {
			return properties.alias;
		} else return "";
	}

	// Sets an appropriate default value
	private setDefaultValue(properties: any): string {
		if (properties.type.includes("integer" || "double")) {
			return "0";
		} else if (properties.type.includes("float")) {
			return "0.0";
		} else return "";
	}
}

export const enum ExternalMapLayerTypeID {
	KML = 1,
	GeoJSON = 2,
	ArcGIS = 3,
	ImageMap = 4,
	FirstTwo = 5,
}
