
import SystemViewTreeNode from "@/types/sv-data/system-view/SystemViewTreeNode";
import { Prop, Vue, Component, Watch } from "vue-property-decorator";
import api from "@/services/api.service";
import { BAlert, BTable, BButton, BTooltip, BCardFooter } from "bootstrap-vue";
import { last } from "lodash";
import { HealthHistoryEntry } from "@/types/sv-data/system-view/HealthHistory";
import moment from "moment";

@Component({
	components: {
		BAlert,
		BTable,
		BButton,
		BTooltip,
		BCardFooter
	}
})

export default class HealthHistory extends Vue
{
	@Prop({ required: true, default: null }) private selectedNode: SystemViewTreeNode

	private hasNextPage: boolean = false;
	private logs: HealthHistoryEntry[] = [];
	private lastLogId: number | null = null;
	private loading: boolean = false;
	private fields =
	[
		{
			label: "Time (UTC)",
			key: "time",
			formatter: (time: Date) => moment(time).format("YYYY-MM-DD h:mm:ss A"),
			thStyle:"width:175px!important"
		},
		"status"
	];

	@Watch("selectedNode", { immediate: true, deep: true })
	private async onNodeChange() : Promise<void>
	{
		this.logs = [];
		this.hasNextPage = false;
		this.lastLogId = null;

		await this.fetchHealthHistory();
	}

	private async fetchHealthHistory() : Promise<void>
	{
		if (!this.loading) {
			if(this.selectedNode == null)
			{
				return;
			}

			this.loading = true;
			const { objectId, objectTypeId } = this.selectedNode;

			try
			{
				const {logs, hasNextPage} = await api.getSystemLogs(objectTypeId, objectId, 10, this.lastLogId);
				this.logs = this.logs.concat(logs);
				this.hasNextPage = hasNextPage;
				this.lastLogId = last(this.logs)?.logId;
			}
			catch(error)
			{
				this.logs = [];
				this.hasNextPage = false;
				this.lastLogId = null;
			}

			this.loading = false;
		}
	}

	private async mounted() : Promise<void>
	{
		await this.fetchHealthHistory();
	}

	private get isDisabled() : boolean
	{
		if(this.loading)
		{
			return true;
		}

		return !this.hasNextPage;
	}

	private get loadMoreButtonText(): string {
		return this.isDisabled && !this.loading ? "No more entries" : "Load More"
	}
}
