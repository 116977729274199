import { Component, Vue } from "vue-property-decorator";
import { SvAudioContext } from "./AudioMixin";
import { downsample } from "@/utils/audioSampler";

/**
 * Mixin which adds helpers and methods to consume and use the microphone
 */
@Component({})
export default class MicMixin extends Vue {
    private audioStream: MediaStream = null;
    private wsMic: WebSocket = null;
    private micSource: MediaStreamAudioSourceNode = null;
    private micContext: AudioContext = null;
    public initialized: boolean = false;
    public errorMessage: string = "";
    private webSocketOpen: boolean = false;

    // TODO change this out to be a audio web worker in the future,
    // this is currently deprecated
    private scriptNode: ScriptProcessorNode = null;

    private micContextTemplate: SvAudioContext = {
        url: "",
        dataType: "arraybuffer",
        mimeType: "audio/mp4;codecs=\"mp4a.40.2\"",
        id: "1",
        playingState: false
    };

    private transmitContext: SvAudioContext = { ...this.micContextTemplate };

    public async mounted(): Promise<void> {
        // Get permission to access the mic
        this.audioStream = await this.getAudioAuth();
    }

    /**
     * @summary Method which setups the websocket, audio context and any identional stuff for audio!
     * @param context our audio context
     */
    public async setupMic(context: SvAudioContext): Promise<void> {
        this.errorMessage = "";
        // close all connections and audio before spinning up a new ws/ audio instance.
        await this.closeMicContext();

        if (!this.deviceProtocol) {
            console.warn("Ssl is not enabled, mic context couldn't be created");
            return;
        }

        // Setup our audio context.
        this.setupMicContext(context);

        this.micContext = new AudioContext();

        // Get permission to access the mic
        this.audioStream = await this.getAudioAuth();

        if (!this.audioStream) {
            console.warn(
                "couldn't get microphone authentication. \
                this could be due to not using SSL, no devices connected"
            );
            this.errorMessage = "Mic Authentication failed."
            // close all connections and audio before spinning up a new ws/ audio instance.
            await this.closeMicContext();

            return;
        }

        this.setupWebsocket();

        // Check if our mic is on by default, if so turn it off.
        if (
            !this.transmitContext.playingState &&
            this.micContext.state === "running"
        ) await this.toggleMic(false);

        this.initialized = true;
    }

    private setupMicContext(context: SvAudioContext) {
        if (!context.url) {
            this.errorMessage = "Address is required when setting up mic audio"
            throw new Error("address is required when setting up mic audio");
        }
        this.transmitContext.url = context.url;
        this.transmitContext.dataType = context.dataType ?? this.transmitContext.dataType;
        this.transmitContext.id = context.id ?? this.transmitContext.id;
        this.transmitContext.mimeType = context.mimeType ?? this.transmitContext.mimeType;
        this.transmitContext.playingState = context.playingState ?? this.transmitContext.playingState;
    }

    /**
     * @summary method which sets up the websocket and websocket callbacks.
     */
    private setupWebsocket(): void {
        this.wsMic = new WebSocket(this.transmitContext.url);

        this.wsMic.onerror = (event: any) => {
            console.error("close mic context - on error", event);
            if (event && event.reason) {
                this.errorMessage = event.reason;
            } else {
                this.errorMessage = "Unknown error occurred"
            }
            this.closeMicContext()
        };

        this.wsMic.onclose = (event: any) => {
            if (event && event.reason) {
                this.webSocketOpen = false;
                console.error("close mic context", event);
                this.errorMessage = event.reason;
                this.closeMicContext();
            }
        }

        // Setup the data type
        this.wsMic.binaryType = this.transmitContext.dataType;
        this.wsMic.onmessage = evt => {
            if (evt.data === "133 Audio Transmit started") {
                this.webSocketOpen = true;
            }
        }
        this.wsMic.onopen = evt => this.wsOnOpen(evt);
    }

    /**
     * @summary callback which is called when the websocket is open.
     * @param evt current event for the websocket openning.
     */
    private wsOnOpen(evt: Event): void {
        this.micSource = this.micContext.createMediaStreamSource(this.audioStream);

        this.scriptNode = this.micContext.createScriptProcessor(1024, 1, 1);

        this.micSource.connect(this.scriptNode);
        this.scriptNode.connect(this.micContext.destination);

        //  NOTE: Change this process to the updated way. This onaudioprocess is currently deprecated.
        this.scriptNode.onaudioprocess = evt => {
            this.sendMicData(evt);
        }
    }

    /**
     * @summary method which is called everytime our onaudioprocess fires.
     * this method deals with downsampling the audio and sending it via
     * the websocket created at init.
     * @param evt the current audio processing event
     */
    private sendMicData(evt: AudioProcessingEvent) {
        const audioBuffer: Float32Array = evt.inputBuffer.getChannelData(0);
        const dSampledBuffer = downsample(audioBuffer, this.micContext.sampleRate, 8000);
        if (this.wsMic.readyState != WebSocket.OPEN) {
            this.errorMessage = "Websocket unexpectedly closed"
            return;
        }
        this.wsMic.send(dSampledBuffer.buffer);
    }

    /**
    * @summary method to get auth for using mic within the browser
    * @returns MediaStream
    */
    private async getAudioAuth() {
        if (!navigator || !navigator.mediaDevices)
            return;

        return await navigator.mediaDevices.getUserMedia({
            audio: true
        });
    }

    /**
     * @summary method which toggles the status of the mic, we do this by the mic context
     * resume or suspend methods.
     * @param status the current state which the mic is going to be put into.
     */
    public async toggleMic(status: boolean): Promise<void> {
        // If our context is not setup, lets try to set it up here.
        if (!this.micContext) {
            if (!this.initialized)
                throw new Error("please first setup transmitContext via setupMic");

            await this.setupMic(this.transmitContext);

            if (!this.micContext)
                throw new Error(`tried to toggle mic to '${status}', mic context was not initialized.
                Tried to initialized mic however failed doing so.`
            );
        }

        status ? await this.micContext.resume() : await this.micContext.suspend();
    }

    /**
     * @summary method which closes mic/audio websocket/s & any audio nodes.
     */
    public async closeMicContext(): Promise<void> {
        this.webSocketOpen = false;
        this.initialized = false;
        if (this.micContext) {
            await this.micContext.suspend();
            await this.micContext.close();
            this.micContext = null;
        }

        if (this.audioStream) {
            this.audioStream.getAudioTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
            this.audioStream = null;
        }

        if (this.micSource) {
            this.micSource.disconnect();
            this.micSource = null;
        }

        if (this.wsMic) {
            this.wsMic.close();
            this.wsMic = null;
        }
    }

    public get micReady(): boolean {
        return (!!this.audioStream && this.deviceProtocol && !!this.micContext && this.webSocketOpen);
    }

    /**
     * @summary vue lifecycle hook which gets called at the descruction of the component
     * which has consumed this mixin.
     */
    public async beforeDestroy(): Promise<void> {
        await this.closeMicContext();
    }

    /**
     * @summary method which gets us the status of the mic permission
     */
    public get micPermissionStatus(): boolean {
        return !!this.audioStream;
    }

    /**
     * @summary method which returns the current status of our client ssl.
     * this is used to detect if we should be doing none-secure or secure connections
     * note: most modern browsers require https (wss) to be enabled before mic audio
     * can be captured.
     */
    public get deviceProtocol(): boolean {
        return location.protocol === "https:";
    }

    /**
     * @summary computed property to help give a basic front facing error message
     * is SSL isn't setup/configured. Which is for the most part required
     * when capturing micrphone device audio.
     */
    public get protocolError(): string {
        return this.deviceProtocol ? "" : "SSL (https) is required to transmit audio";
    }

    /**
     * @summary computed property to give a basic front facing error message
     * if the user haven't given permission access to device
     * typeof microphone
     */
    public get permissionError(): string {
        return this.micPermissionStatus ? "" : "Permission required to access mic via the browser.";
    }

    /**
     * @summary method which calls for device auth if it currently isn't given.
     * @returns returns a boolean if the operation was successful or not.
     * @async this method is asynchronous
     */
    public async requestDeviceAuth(): Promise<boolean> {
        this.audioStream = await this.getAudioAuth();
        return !!this.audioStream;
    }
}