import { RootState } from "./types";

// @ref https://github.com/kelektiv/node-uuid
const windowId = new Date().valueOf().toString();

export function getDefaultState(): RootState {
	return {
		appInitialised: false,
		dataError: false,
		showRoutingGroupsLegend: false,
		windowId,
		User: {
			userId: null,
			userGroupId: null,
			loggedIn: false,
			username: null,
			fullName: "",
			error: "",
			isFullUser: false,
			userPermissions: {
				canManageContactRoles: false,
				canEditSettings: false,
				canHandleAlarms: false,
				canViewReports: false,
				canAddIndividualActions: false,
				canEditUserPermissions: false,
				canViewSiteSetup: false,
				canEditSiteSetup: false,
				canRaiseActivityLog: false,
				canToggleLogVisibility: false,
				canEditOwnActivityLog: false,
				canEditOtherActivityLogs: false,
				isSystemAdmin: false,
				canDisarmSites: false,
				canDisarmSitesExtended: false,
				canSetResponseToMasked: false,
				canSetSiteToOnTest: false,
				canSetSiteToAuditMode: false,
				canViewEvents: false,
				canAccessQuickControl: false,
				canViewMediaMatrixControls: false,
				canAccessManualRaise: false,
				canAccessManualTour: false,
				canViewSitRep: false,
				canUseMobile: false,
				canViewMobileRaise: false,
				canEditMobileRaise: false,
				canDownloadEventSearchSitRep: false,
				canDownloadEventSearchMedia: false,
				canViewSubscriptionDetails: false,
				canProcessActiveAlarms: false,
				canDownloadSingleClip: false,
				isAccountAdmin: false,
                canViewExternalMapLayers: false,
				canEditExternalMapLayers: false,
				canEditInsightsTitle: false,
				canExtendAlerts: false,
				canEditMaps: false,
				canEditSystemSettings: false,
				canEditEventCategorySetup: false,
				canEditGuardTours: false,
				canAssignGuardTour: false,
				canConductGuardTour: false,
				canClaimFromMobileRaise: false,
				canPreserveEvents: false,
				canRequestSupport: false,
				canEditAssetSetup: false,
				canViewFieldOps: false,
				canEditFieldOps: false,
				canOverrideGroupSync: false,
				canUpdateEventLocation: false,
				canUpdateEndedEventLocation: false,
				canEditAutomations: false,
				canViewAutomations: false,
				canViewSystemView: false,
				canPromoteSituationalAwarenessEvent: false,
				canDismissSituationalAwarenessEvent: false,
				canViewSituationalAwarenessEvents: false,
				canViewRulesEngine: false,
				canEditRulesEngine: false,
				canUpdateEventCategory: false,
				canEditSchedules: false,
				canViewSchedules: false,
				canEndMultipleEvents: false,
				canEditMaskReasons: false,
				canViewMaskReasons: false,
				canMaskUntilDateTime: false,
				canEditPostEvent: false,
				canChangeMaskAction: false,
				canViewEventHistory: false,
				canPutAlarmPointsOnTest: false,
				canUseVirtualAssistSummary: false,
				canViewNotificationTemplates: false,
				canEditNotificationTemplates: false,
				canConfigureSuiteSync: false,
				canEditInvolvedParties: false,
				canUseVirtualOperators: false,
				canConfigureDeviceSync: false,
				canViewCustomFields: false,
				canEditCustomFields: false
			},
			dynamicLinkConfig: null,
			isFirstLogin: false,
			tenantGroupId: 0,
			userPrefs: {
				useImperialMeasurements: false
			},
			mobileAppEnabled: false,
			suiteUserId: "",
			suiteResponseLicenced: false,
			suiteCasesLicenced: false
		},
		systemPrefs: {
			alarmAgeWarningSeconds: null,
			alarmScreenTimeoutSeconds: null,
			canUsersAddIndividualActions: false,
			hideLabels: false,
			requireMapPin: false,
			mapType: "",
		},
		mobilePrefs: {
			mapStyle: ""
		},
		timeZoneAbbr: "",
		UserGroups: [],
		keyboardShortcuts: [],
		legendKeys: [],
		routingGroups: [],
		activeRegion: {
			regionId: 0, // 0 = default region
			regionUrl: process.env.VUE_APP_HTTP_API_URI // local API url
		},
		localUserData: null,
		featuresList: null,
		timeZones: null,
		isSessionHeartBeatActive: false,
		isSystemAdminAccessTimeModalHidden: false,
		elevationLabels: [],
		appOptions: null,
		requiresConfiguration: null,
		tenantSystemPrefs: {
			defaultMaskValue: 10,
			defaultParkValue: 10,
			alarmAgeWarningSeconds: null,
			defaultMaskAction: false
		},
		reloadEventQueue: false
	};
}
