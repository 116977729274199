
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";
import { ActionPlanTask } from "@/store/tasks/types";
import { stringMixin } from "@/mixins";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";

const { isNullOrWhitespace } = stringMixin.methods;

@Component({
	components: {},
	mixins: [validationMixin],
	validations: {
		inputTaskModel: {
			required: requiredIf(function() { 
				return this.task.required;
			})
		}
	}
})
export default class InputTask extends Vue {
	@Prop(Boolean)
	public editing: Boolean;

	@Prop()
	public task!: ActionPlanTask;

	public inputTaskModel: string = "";
	public hasValidationErrors: boolean = false;
	public showErrorAnimation: boolean = false;

	public mounted() {
		if (this.editing) {
			this.onValidateTask();
		}
	}

	@Emit("complete-task")
	public onCompleteTask() {
		return {
			...this.task,
			result: this.inputTaskModel,
			completed: true
		};
	}

	// Ensure the task was setup correctly.
	@Emit("validate-task")
	public onValidateTask() {
		let isValid = !isNullOrWhitespace(this.task.taskText);

		if (!isValid) {
			this.hasValidationErrors = true;
			this.showErrorAnimation = true;

			setTimeout(() => {
				this.showErrorAnimation = false;
			}, this.$config.ANIMATION_DURATION);
		}

		return isValid;
	}
}
