
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter, State, Action } from "vuex-class";
import { TimeZone } from "@/store/types";

@Component({
})

export default class AreaDateTimeDisplay extends Vue {
	@Prop({type: Number}) public timeZoneId: number;
	@Prop({type: Number}) public groupId: number;
	@Prop({type: Boolean, default: true}) showDate;
	@Prop({type: Boolean, default: true}) showTime;
	@Prop({type: Boolean, default: true}) showTimeZone;

	@State private timeZones: TimeZone[];
	@Getter getTimeZone: (timeZoneId: number) => TimeZone;
	@Action getTimeZones: () => Promise<void>;

	private timeStringArea: string = "en-US";
	private areaTime: string = "";
	private areaDate: string = "";
	private timeZone: TimeZone;
	private timeZoneOptions: Intl.DateTimeFormatOptions;
	private dateOptions: Intl.DateTimeFormatOptions;
	private timeoutInterval: NodeJS.Timeout = null; // Used to stop updating the area time when no longer on the page

	public async mounted(): Promise<void> {
		await this.getTimeZones();

		// If the timezone does not yet have a value, set it
		if(this.timeZone == null)
		{
			this.setTimeZone();
		}
	}

	@Watch("timeZoneId")
	private setTimeZone(): void {
		this.timeZone = this.getTimeZone(this.timeZoneId);

		if (!!this.timeZone && !!this.timeZone.iana){
			this.timeZoneOptions = {
				timeZone: this.timeZone.iana,
				hour12: true,
				hour: "numeric",
				minute: "numeric",
				second: "numeric",

			}

			this.dateOptions = {
				timeZone: this.timeZone.iana,
			}

			this.setAreaDateTime();
		}
	}

	// Sets the area time, using local time and applying timezone. Recursively calls every second.
	private setAreaDateTime(): void {
		const date = new Date();
		this.areaTime = date.toLocaleTimeString(this.timeStringArea, this.timeZoneOptions);
		this.areaDate = date.toLocaleDateString(this.timeStringArea, this.dateOptions);

		this.timeoutInterval = setTimeout(this.setAreaDateTime, 1000);
	}

	beforeDestroy(): void {
		// Stop updating the area time when no longer on the page
		clearTimeout(this.timeoutInterval);
	}
}
