
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import RegionalHandlerMixin from "@/mixins/RegionalHandlerMixin";
import { FilteredEvent } from "@/store/eventqueue/types";
import { truncateString } from "@/filters";
import EventQueueAreaInfoIcon from "@/components/event-queue/EventQueueAreaInfoIcon.vue";

const Eventqueue = namespace("eventqueue");

@Component({
	components: {
		VuePerfectScrollbar: VuePerfectScrollbar,
		"event-queue-area-info-icon": EventQueueAreaInfoIcon
	},
	filters: {
		truncateString
	}
})
export default class OnTestQueue extends Mixins(RegionalHandlerMixin) {
	@Eventqueue.Getter getActiveEvents!: FilteredEvent[];
	@Eventqueue.Mutation setCountByType: any;
	@Eventqueue.Action handleEvent: any;
	@Eventqueue.Mutation setHoverEvent: any;

	get events(): FilteredEvent[] {
		let events = this.getActiveEvents;

		if (events == null) return null;

		let filteredEvents = events.filter((q: any) => q.eventTypeID == 6 || q.eventTypeID == 10);
		this.setCountByType({ countType: "onTest", count: filteredEvents.length });

		return filteredEvents;
	}

	hoverEvent(event: FilteredEvent) {
		this.setHoverEvent(event);
	}

	unhoverEvent(event: FilteredEvent) {
		this.setHoverEvent({});
	}

	public get noEvents() {
		return typeof this.events == "undefined" || this.events == null || this.events.length == 0;
	}
}
