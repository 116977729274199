





















































/* istanbul ignore file */
import { Vue, Prop, Component, Emit } from "vue-property-decorator";

@Component({})
export default class NavMenuIcon extends Vue {
	@Prop({ default: false }) public menuOpen: boolean;
}
