import { ResponsesState } from "./types";

export function getDefaultState(): ResponsesState {
	return {
		responses: [],
		filteredResponses: [],
		failedResponse: null,
		pageIndex: 1,
		pageSize: 30,
		filter: "",
		filterPageIndex: 1,
		allResponsesRetrieved: false,
		allFilteredResponsesRetrieved: false,
		responseTableIsBusy: false,
		alarmModalOpened: false,
		currentAlarm: null,
		alarmSetupModalLoaded: false,
		serverId: null
	};
}
