
import ActionState from "@/types/sv-data/events/ActionState";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace, Getter } from 'vuex-class';

const Eventqueue = namespace('eventqueue');

export class Invitation {
	public eventUserID: string = "";
	public SharedBy: string | null = null;
	public EventTitle: string = "";
	public ignored: boolean = false;
}

@Component({
	components: {
	}
})
export default class InvitationCheck extends Vue {
	@Getter('getActiveRegion') activeRegion: any;

	@Eventqueue.Action loadEventShareInvites: any;
	@Eventqueue.Action acceptEventShareInvite: any;
	@Eventqueue.Action ignoreEventShareInvite: any;
	@Eventqueue.Getter("getClosingAction") closingAction: ActionState;

	public isDestroyed: boolean = false;
	public currentInvites: Invitation[] = [];
	public hide: boolean = true;
	public updateTimeout: NodeJS.Timeout = null;

	@Watch('activeRegion', { deep: true })
	public onActiveRegionChanged(newRegion: any, oldRegion: any) {
		if (newRegion.regionId !== 0) {
			this.isDestroyed = true;
		} else {
			this.isDestroyed = false;
			this.update();
		}
	}

	public get unIgnoredInvites() {
		return this.currentInvites.filter(invite => !invite.ignored);
	}

	public mounted() {
		this.isDestroyed = false;
		this.update();
	}

	public destroyed() {
		this.isDestroyed = true;

		if (this.updateTimeout != null) {
			clearTimeout(this.updateTimeout!);
			this.updateTimeout = null;
		}
	}

	public async update() {
		if (this.isDestroyed) {
			return;
		}

		// if the closing action is in progress then don't show the invites.
		if (this.closingAction == ActionState.InProcess){
			return;
		}

		try {
			let invites: Invitation[] = await this.loadEventShareInvites();

			invites.forEach((invite) => {
				invite.ignored = false;
			});

			this.hide = invites.length == 0;

			this.$set(this, "currentInvites", invites);

			if (this.updateTimeout != null) {
				clearTimeout(this.updateTimeout);
			}

			this.updateTimeout = setTimeout(() => this.update(), 5000);
		} catch (err) {
			//On error, wait longer before retry.
			this.updateTimeout = setTimeout(() => this.update(), 10000);
		}
	}

	public async acceptInvite(invite: Invitation) {
		const result = await this.acceptEventShareInvite(invite.eventUserID);
		if (result.allowAccess) {
				this.$router.push({ path: '/site-monitor/' + result.eventID });
		}
	}

	public async ignoreInvite(invite: Invitation) {
		await this.ignoreEventShareInvite(invite.eventUserID);

		invite.ignored = true;
		/* let unignoredCount =
			this.currentInvites.filter(invite => !invite.ignored).length; */

		if (this.unIgnoredInvites.length == 0) {
				this.hide = true;
		}  
	}
}
