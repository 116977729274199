
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { GroupWithPermissions } from "@/store/areas/types";

const GenericTable = namespace("GenericTable");

@Component({})
export default class AreaSetupDisableAreaButton extends Vue {
	@Prop() public areaProp!: GroupWithPermissions;
	@Prop() public readonly!: boolean;

	private area: GroupWithPermissions = null;

	private showConfirmation: boolean = false;

	@GenericTable.Getter getModalRow: any;
	@GenericTable.Mutation setModalRow: any;

	async created() {
		this.area = this.areaProp ? this.areaProp : await this.getModalRow;
	}

	private areaIsDisabled() {
		let disabled = true;

		if (
			this.area &&
			this.area.disabledAt !== undefined &&
			this.area.disabledAt == null
		) {
			disabled = false;
		}

		return disabled;
	}

	private async disableAction() {
		let tmp = { ...this.area };

		if (this.areaIsDisabled()) {
			tmp.disabledAt = null;
		} else {
			tmp.disabledAt = new Date();
		}

		this.area = tmp;

		this.$emit("input", tmp.disabledAt);

		this.showConfirmation = false;
	}

}
