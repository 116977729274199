import { render, staticRenderFns } from "./InsightsGraph.vue?vue&type=template&id=6a1f2c32&scoped=true"
import script from "./InsightsGraph.vue?vue&type=script&lang=ts"
export * from "./InsightsGraph.vue?vue&type=script&lang=ts"
import style0 from "./InsightsGraph.vue?vue&type=style&index=0&id=6a1f2c32&prod&scoped=true&lang=scss"
import style1 from "./InsightsGraph.vue?vue&type=style&index=1&id=6a1f2c32&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a1f2c32",
  null
  
)

export default component.exports