
// Vue
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { validationMixin } from "vuelidate";
// Components
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import { ExternalMapLayer } from "@/store/external-map-layers/types";

// Namespaces
const ExternalMapLayerStore = namespace("externalMapLayers");

@Component({
	mixins: [validationMixin],
	validations: {},
	components: {
		"vue-select": vSelect,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
	},
})
export default class DeleteExternalMapLayerModal extends Vue {
	@Prop({ default: null, type: Object }) selectedMapLayer: ExternalMapLayer;
	@Prop({ default: false, type: Boolean }) visible: boolean;

	@ExternalMapLayerStore.Action deleteExternalMapLayer: (externalMapLayerID: number) => Promise<void>;
	@ExternalMapLayerStore.Action updateExternalMapLayerVisibilityForDelete: (externalMapLayerId: number) => Promise<void>;

	@Emit("toggle-modal")
	@Emit("delete-external-map-layer")
	private closeModal(): void {}

	private async removeExternalMapLayer(): Promise<void> {
		await this.updateExternalMapLayerVisibilityForDelete(this.selectedMapLayer.externalMapLayerID);
		await this.deleteExternalMapLayer(this.selectedMapLayer.externalMapLayerID);
		this.closeModal();
		this.$emit("delete-successful");
	}
}
