import { render, staticRenderFns } from "./AreaSetupDisableAreaButton.vue?vue&type=template&id=3552eeb6&scoped=true"
import script from "./AreaSetupDisableAreaButton.vue?vue&type=script&lang=ts"
export * from "./AreaSetupDisableAreaButton.vue?vue&type=script&lang=ts"
import style0 from "./AreaSetupDisableAreaButton.vue?vue&type=style&index=0&id=3552eeb6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3552eeb6",
  null
  
)

export default component.exports