import { axiosJsonInstance as axios } from "@/axios.instance";
import { MobileRaiseTemplate, MobileRaise, IconSet } from "@/store/mobile-raise-templates/types";
import {  MobileRaiseTemplateProjection } from "@/store/mobile-raise/types";

const TEMPLATES_URL = "/MobileRaiseTemplate";
const PROJECTIONS_URL = "/MobileRaiseTemplateProjection"
const MOBILE_RAISES_URL = "/MobileRaise";
const ICONSETS_URL = "/IconSet";

const getTemplateUrl = (id: number) => `${TEMPLATES_URL}/${id}`;
const getByEventNumUrl = (evtNum: number) => `${TEMPLATES_URL}/ByEvent?eventNum=${evtNum}`;

export const loadTemplates = async (): Promise<MobileRaiseTemplate[]> => {
	const { data } = await axios.get(TEMPLATES_URL);
	return data;
}

export const loadTemplateProjections = async (): Promise<MobileRaiseTemplateProjection[]> => {
	const { data } = await axios.get(PROJECTIONS_URL);
	return data;
}

export const loadMobileRaises = async (): Promise<MobileRaise[]> => {
	const { data } = await axios.get(MOBILE_RAISES_URL);
	return data;
}

export const loadIconSets = async (): Promise<IconSet[]> => {
	const { data } = await axios.get(ICONSETS_URL);
	return data;
}

export const addTemplate = async (template: MobileRaiseTemplate): Promise<MobileRaiseTemplate> => {
	const { data } = await axios.post(TEMPLATES_URL, template);
	return data;
}

export const saveTemplate = async (template: MobileRaiseTemplate): Promise<void> => {
	await axios.put(getTemplateUrl(template.mobileRaiseTemplateID), template);
}

export const deleteTemplate = async (templateId: number): Promise<void> => {
	await axios.delete(getTemplateUrl(templateId));
}

export const getTemplateNamesByEventNum = async (eventNum: number): Promise<string[]> => {
	const { data } = await axios.get(getByEventNumUrl(eventNum));
	return data;
}
