import { axiosInstance, axiosJsonDirectInstance } from "@/axios.instance";
import { AxiosInstance } from "axios";

class ApiKeyApi {
	private axios: AxiosInstance;

	constructor() {
		this.axios = axiosJsonDirectInstance;
	}

	public async getApiKey(
        keyType: string)
        : Promise<string> {
            const { data } = await this.axios.get(`/ApiKey?keyType=${keyType}`);
            return data;
    }
}

const apiKeyApi = new ApiKeyApi();
export default apiKeyApi;
