
import { Component, Vue } from "vue-property-decorator";
import Draggable from '../utilities/draggable.vue';
import supportEngagementApi from "@/services/api.supportEngagement.service";
import moment from "moment";
import { Mutation } from "vuex-class";

@Component({
	components: {
		"draggable" : Draggable,
	}
})
export default class AccessTimerModal extends Vue {
	@Mutation hideSysAdminAccessTimerModal: () => void;

	private accessExpiryDateTime: Date = null;
	private isLoading: boolean = false;
	private refreshCheckInterval;

	private async mounted(): Promise<void> {
		await this.refreshAccessDuration();
		this.refreshCheckInterval = setInterval(this.checkRemainingTime, 60000);
	}

	private destroyed(): void {
		clearInterval(this.refreshCheckInterval);
	}

	private async checkRemainingTime(): Promise<void> {
		const currentTimeDifference = moment.duration(moment(this.accessExpiryDateTime).diff(new Date()));
		const minuteDifference = currentTimeDifference.asMinutes();
		if (minuteDifference <= 1) {
			await this.refreshAccessDuration();
		}
	}

	public async refreshAccessDuration(): Promise<void> {
		try {
			this.isLoading = true;

			this.accessExpiryDateTime = await supportEngagementApi.getCurrentSupportEngagementExpiry();
			if (moment(this.accessExpiryDateTime).year() === 9999) {
				this.hideSysAdminAccessTimerModal();
			}
		} catch {
			this.$notify({
				type: "error",
				title: "Error",
				text: "Failed to load access duration.",
			});
		}

		this.isLoading = false;
	}

	public get expiryTime(): string {
		return moment(this.accessExpiryDateTime).format("MMM Do YYYY, h:mm:ss A");
	}
}

