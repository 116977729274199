
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { AdvancedFilters, MapLayerItemType, MapLayerItemTypeIds } from '@/store/map-layers/types';
import { MarkerIcons, IMarker } from "@sureview/v2-mapping-saas";
import { getAssetIcon, getTrackedAssetIcon } from '@/assets/svgIcons';
import vselect3 from "vselect3";
import AssetType from '@/types/sv-data/AssetType';

const MapLayers = namespace("mapLayers");
const FieldOpsStore = namespace("fieldOps");

@Component({
	components: {
		"v-select-3": vselect3,
	}
})
export default class AdvancedMapFilters extends Vue {
	@MapLayers.State("advancedFilters") private advancedFilters: AdvancedFilters;
	@MapLayers.State("mapItemIcons") private mapIcons!: MarkerIcons;
	@MapLayers.Getter("getMapLayerItemTypes") private mapItemTypes: MapLayerItemType[];
	@MapLayers.Mutation("setAdvancedFilters") private setAdvancedFilters: (filters: AdvancedFilters) => void;
	@MapLayers.Mutation("setFilterChanged") private setFilterChanged: (filter: number) => void;
	@MapLayers.Mutation("resetState") private resetAdvancedFilters: () => void;

	@FieldOpsStore.State("assetTypes") private allAssetTypes: Map<string, AssetType>;

	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	private alarmFilterOptions: string[] = ["All", "Active", "Inactive"];
	private selectedAlarmFilter: string = "All";
	private showRegions: boolean = true;

	private showMapItems: boolean = false;
	private showUsersAndAssets: boolean = false;

	//Object to be passed to icon function for "Assets" header
	private assetHeaderIcon: MapLayerItemType = {
		mapLayerItemTypeId: MapLayerItemTypeIds.Asset,
		title: "Asset",
		groupID: null,
		isAsset: true,
		visible: true,
		any: true,
		hideByDefault: false,
		icon: null
	}

	//Object to be passed to icon function for "Map Items" header
	private mapItemsHeaderIcon: MapLayerItemType = {
		mapLayerItemTypeId: MapLayerItemTypeIds.Camera,
		title: "Camera",
		groupID: null,
		isAsset: true,
		visible: true,
		any: true,
		hideByDefault: false,
		icon: null
	}

	private get isAreaLocationEnabled(): boolean {
		return this.getFeature(["Areas", "ShowOnMap"]);
	}

	private get isAreaRegionsEnabled(): boolean {
		return this.getFeature(["Areas", "Regions"]);
	}

	private get isTrackedAssetsEnabled(): boolean {
		return this.getFeature(["Mobile", "FieldOps", "TrackedAssets"]);
	}

	private get enabledMapItemTypes(): MapLayerItemType[] {
		if(!this.mapItemTypes || this.mapItemTypes.length < 1){
			return []
		}

		let itemList = [...this.mapItemTypes];

		itemList = itemList.filter(i => i.title != "Asset" && i.title != "User" && i.title != "TrackedAsset");

		if(!this.isAreaLocationEnabled){
			itemList = itemList.filter(t => t.mapLayerItemTypeId != MapLayerItemTypeIds.Area);
		}

		return itemList;
	}

	private get assetTypes(): AssetType[]
	{
		if(!this.allAssetTypes || this.allAssetTypes.size < 1){
			return [];
		}

		let assetTypeArray = Array.from(this.allAssetTypes, ([name, value]) => value);
		return assetTypeArray.filter(at => at.shownOnMap);
	}

	private mounted(): void {
		//Set up the asset type list
		this.setupAssetList();
	}

	private beforeDestroy(): void {
		//Reset advancedFilter state
		this.resetAdvancedFilters();
	}

	private setupAssetList(): void {
		if(!this.assetTypes || this.assetTypes.length < 1){
			return;
		}

		this.assetTypes.forEach(asset => {
			if(asset.shownOnMap){
				this.toggleAssetTypeFilter(asset);
			}
		});
	}

	private advancedAlarmFilter(currentVisibility: boolean): Function | boolean {
		//Assigns the relevant function depending on the advanced alarm dropdown selected
		if(this.selectedAlarmFilter == "Active"){
			return this.activeAlarm;
		} else if (this.selectedAlarmFilter == "Inactive") {
			return this.inactiveAlarm;
		} else {
			return currentVisibility;
		}
	}

	private activeAlarm = (mapItem: IMarker) => mapItem && mapItem.state && mapItem.state.includes("active");
	private inactiveAlarm = (mapItem: IMarker) => mapItem && mapItem.state && mapItem.state.includes("processing");

	private toggleAdvancedFilter(mapItemType: MapLayerItemType, advanced: boolean = false): void {
		//configures the advancedFilters state depending on the options selected
		if(this.advancedFilters == null || this.advancedFilters == undefined){
			return;
		}

		let currentFilters = {...this.advancedFilters};

		switch (mapItemType.mapLayerItemTypeId) {
			case MapLayerItemTypeIds.Alarm:
				if(advanced){
					currentFilters.alarm.filterType = this.selectedAlarmFilter == "All" ? "Basic" : "Advanced";
				} else {
					currentFilters.alarm.filterType = "Basic";
				}

				currentFilters.alarm.filterCondition = advanced ? this.advancedAlarmFilter(mapItemType.visible) : mapItemType.visible;
				this.selectedAlarmFilter = !mapItemType.visible ? "All" : this.selectedAlarmFilter;

				this.setFilterChanged(MapLayerItemTypeIds.Alarm.valueOf());
				this.setAdvancedFilters(currentFilters);
				break;
			case MapLayerItemTypeIds.Camera:
				currentFilters.camera.filterType = advanced ? "Advanced" : "Basic";
				currentFilters.camera.filterCondition = mapItemType.visible;

				this.setFilterChanged(MapLayerItemTypeIds.Camera.valueOf());
				this.setAdvancedFilters(currentFilters);
				break;
			case MapLayerItemTypeIds.Audio:
				currentFilters.audio.filterType = advanced ? "Advanced" : "Basic";
				currentFilters.audio.filterCondition = mapItemType.visible;

				this.setFilterChanged(MapLayerItemTypeIds.Audio.valueOf());
				this.setAdvancedFilters(currentFilters);
				break;
			case MapLayerItemTypeIds.Relay:
				currentFilters.relay.filterType = advanced ? "Advanced" : "Basic";
				currentFilters.relay.filterCondition = mapItemType.visible;

				this.setFilterChanged(MapLayerItemTypeIds.Relay.valueOf());
				this.setAdvancedFilters(currentFilters);
				break;
			case MapLayerItemTypeIds.Output:
				currentFilters.output.filterType = advanced ? "Advanced" : "Basic";
				currentFilters.output.filterCondition = mapItemType.visible;

				this.setFilterChanged(MapLayerItemTypeIds.Output.valueOf());
				this.setAdvancedFilters(currentFilters);
				break;
			case MapLayerItemTypeIds.Asset:
				currentFilters.asset.filterType = advanced ? "Advanced" : "Basic";
				currentFilters.asset.filterCondition = mapItemType.visible;

				this.setFilterChanged(MapLayerItemTypeIds.Asset.valueOf());
				this.setAdvancedFilters(currentFilters);
				break;
			case MapLayerItemTypeIds.TrackedAsset:
				currentFilters.trackedAsset.filterType = advanced ? "Advanced" : "Basic";
				currentFilters.trackedAsset.filterCondition = mapItemType.visible;

				this.setFilterChanged(MapLayerItemTypeIds.TrackedAsset.valueOf());
				this.setAdvancedFilters(currentFilters);
				break;
			case MapLayerItemTypeIds.Area:
				if (this.isAreaLocationEnabled){
					currentFilters.area.filterType = advanced ? "Advanced" : "Basic";
					currentFilters.area.filterCondition = mapItemType.visible;

					this.setFilterChanged(MapLayerItemTypeIds.Area.valueOf());
					this.setAdvancedFilters(currentFilters);
				}
				break;
		}
	}

	private toggleAssetTypeFilter(assetMapItem: AssetType): void {
		//Configures the assetTypes array in the store depending on the toggled asset type
		if(this.advancedFilters == null || this.advancedFilters == undefined){
			return;
		}

		let currentFilters = {...this.advancedFilters};

		let assetTypeExists = currentFilters.assetTypes.filter(at => at == assetMapItem.assetTypeId);

		if (assetTypeExists.length > 0){
			currentFilters.assetTypes = currentFilters.assetTypes.filter(at => at != assetMapItem.assetTypeId);
		} else {
			currentFilters.assetTypes.push(assetMapItem.assetTypeId);
		}

		this.setFilterChanged(-1);
		this.setAdvancedFilters(currentFilters);
	}

	private toggleRegionFilter(): void {
		if(this.isAreaRegionsEnabled){
			let currentFilters = {...this.advancedFilters};

			currentFilters.regions.filterType = "Basic";
			currentFilters.regions.filterCondition = this.showRegions;

			//Set the filterChanged to null which tells EnhancedSingleMap that regions have been toggled, not an asset/map item type
			this.setFilterChanged(-2);
			this.setAdvancedFilters(currentFilters);
		}
	}

	private assetIcon(asset: AssetType): string {
		//Check if the toggled asset is a user asset and assigns the relevant SVG
		if(asset.canBeUser){
			const assetType = this.allAssetTypes.get(asset.assetTypeId.toString());
			const iconColor = asset.iconColor ? asset.iconColor : (assetType ? assetType.iconColor : "#000000");
			return getAssetIcon(iconColor, false, false);
		} else {
			return getAssetIcon(asset.iconColor, true, false);
		}

	}

	private mapIconForType(mapItemType: MapLayerItemType, iconColor: string = "black"): string {
		if (mapItemType.title === "Asset") {
			return getAssetIcon(iconColor, true, false, false);
		}

		if (mapItemType.title === "TrackedAsset") {
			return getTrackedAssetIcon();
		}

		if (mapItemType.mapLayerItemTypeId === MapLayerItemTypeIds.User) {
			return getAssetIcon(iconColor, false, false, false);
		}

		return this.mapIcons[mapItemType.mapLayerItemTypeId];
	}

	private checkAssetAvailable(asset: AssetType): boolean {
		//Assigns the checkbox state of assets depending on the assetType array in the store
		let assetTypeToggled = this.advancedFilters.assetTypes.filter(at => at == asset.assetTypeId);

		if(assetTypeToggled.length > 0){
			return true;
		} else {
			return false;
		}
	}

	private isAlarmType(mapItemTypeId: number): boolean {
		return mapItemTypeId == MapLayerItemTypeIds.Alarm;
	}

	private get trackedMapItemType(): MapLayerItemType[] {
		if(this.isTrackedAssetsEnabled){
			return this.mapItemTypes.filter(t => t.mapLayerItemTypeId == MapLayerItemTypeIds.TrackedAsset);
		} else {
			return [];
		}
	}
}
