import { axiosInstance } from "@/axios.instance";
import { AxiosInstance } from "axios";

class SettingsApi {
	private axios: AxiosInstance;

	constructor() {
		this.axios = axiosInstance;
	}

	public async getAiDataUrl()
        : Promise<string> {
            const { data } = await this.axios.get(`settings/GetAiDataUrl`);
            return data;
    }

	public async getAiUiUrl()
        : Promise<string> {
            const { data } = await this.axios.get(`settings/GetAiUiUrl`);
            return data;
    }
}

const settingsApi = new SettingsApi();
export default settingsApi;
