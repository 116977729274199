
import { truncateString } from "@/filters";
import { BaseDeviceSummary } from "@/types/sv-data/system-view/SetupSummary";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
	filters: { truncateString }
})
export default class SystemOverview extends Vue {
	@Prop({required: true})
	private value: BaseDeviceSummary;

	@Prop({required: false, default: false, type: Boolean})
	private showLastAlarm: boolean;
}
