import { MutationTree } from "vuex";
import { Schedule, ScheduleState, ScheduleType } from "./types";

export const mutations: MutationTree<ScheduleState> = {
	setIsLoading(state, isLoading: boolean){
		state.isLoading = isLoading;
	},

	setSchedules(state, schedules: Schedule[]) {
		state.schedules = schedules;
	},

	setScheduleTypes(state, scheduleTypes: ScheduleType[]) {
		state.scheduleTypes = scheduleTypes;
	},

	setIsScheduleModalVisible(state, isVisible: boolean) {
		state.isCreateModalVisible = isVisible;
	},

	setIsDeleteConfirmationVisible(state, isVisible: boolean) {
		state.isDeleteConfirmationVisible = isVisible;
	},

	setIsReloadRequired(state, isReloadRequired: boolean) {
		state.isReloadRequired = isReloadRequired;
	},

	setSelectedScheduleId(state, { selectedScheduleId, selectedScheduleStart }: { selectedScheduleId: string, selectedScheduleStart: Date }) {
		state.selectedScheduleId = selectedScheduleId;
		state.selectedScheduleStart = selectedScheduleStart;
	},

	setTempSchedule(state, tempSchedule: Schedule) {
		state.tempSchedule = tempSchedule;
	},

	removeTempSchedule(state) {
		state.tempSchedule = null;
	},

	setEndTimeForTempSchedule(state, endTime: string) {
		if (state.tempSchedule !== null) {
			state.tempSchedule.endDateTime = endTime;
		}
	},

	setStartTimeForTempSchedule(state, startTime: string) {
		if (state.tempSchedule !== null) {
			state.tempSchedule.startDateTime = startTime;
		}
	},

	updateSearchString(state, searchString: string) {
		state.searchString = searchString;
	},

	addSelectedScheduleType(state, selectedScheduleType: number) {
		state.selectedScheduleTypes.push(selectedScheduleType);
	},

	removeSelectedScheduleType(state, selectedScheduleType: number) {
		state.selectedScheduleTypes = state.selectedScheduleTypes.filter(s => s != selectedScheduleType);
	},

	setSelectedGroup(state, selectedGroup: number) {
		state.selectedGroup = selectedGroup;
	},

	setEnabledScheduleFilter(state, enabled: boolean | null) {
		state.enabledScheduleFilter = enabled;
	},

	setAreaTimeZoneId(state, timeZoneId: number | null) {
		state.areaTimeZoneId = timeZoneId;
	},

	resetSelectedGroup(state) {
		state.selectedGroup = null;
	}
};
