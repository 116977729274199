
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import { namespace } from "vuex-class";
import { ActionPlan } from "@/store/tasks/types";

const Tasks = namespace("tasks");

@Component({
	components: {
		"vue-select": vSelect
	}
})
export default class EditActionPlan extends Vue {
	@Prop({
		default: () => ({
			title: {
				type: String,
				required: true
			},
			groupID: {
				type: Number,
				required: true
			}
		})
	})
	private actionPlan!: ActionPlan;

	@Tasks.Getter("getActionPlans") private actionPlans: ActionPlan[];

	private get hasValidationErrors(): boolean {
		return !!this.validationMessage;
	}

	private showErrorAnimation: boolean = false;
	private validationMessage: string = "";

	@Watch("actionPlan.title", { immediate: true })
	private onTitleChanged(title: string): void {
		if (!title) {
			this.validationMessage = "Action plan title can not be empty";
			return;
		}

		const titleAlreadyExist = this.actionPlans
			.find((ap: ActionPlan) => ap.scriptID != this.actionPlan.scriptID && ap.title.toLowerCase() == title.toLowerCase());

		if (titleAlreadyExist) {
			this.validationMessage = "Action plan title already exists";
			return;
		}

		this.validationMessage = "";
	}

	public onSave() {
		this.onTitleChanged(this.actionPlan.title);
		if (!this.hasValidationErrors) {
			this.$emit("save", this.actionPlan);
		} else {
			this.showErrorAnimation = true;
			setTimeout(() => {
				this.showErrorAnimation = false;
			}, this.$config.ANIMATION_DURATION);
		}
	}

	public onCancel() {
		this.$emit("cancel");
	}
}
