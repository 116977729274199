















import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Mobile = namespace("mobile");

@Component
export default class NotificationBar extends Vue {
	public showNotification: boolean = false;
	public notificationDisplayTimeout: any = null;
	public notificationContainsError: boolean = false;

	@Mobile.State(state => state.NotificationText) notificationText: string;
	@Mobile.Mutation setNotificationText: (notificationText: string) => void;

	@Watch("notificationText")
	onNotificationChanged(value: string, oldValue: string) {
		this.showNotification = false;
		this.notificationContainsError = false;

		// Don't show a blank notification
		if (value && value !== "") {
			// Changes the colour of the notification bar if it's an error
			if (value.toLowerCase().includes("error")) {
				this.notificationContainsError = true;
			}

			this.setNotificationValue(value);
		}
	}

	public setNotificationValue(notificationText: string) {
		// Animate the notification bar
		setTimeout(
			function() {
				this.showNotification = true;
			}.bind(this),
			100
		);

		// Timeouts as variables (so it's easier to follow)
		let notificationTimeToShow = 6000;

		// Single process mode - clear previous timeouts - remove if stacking notifications
		window.clearTimeout(this.notificationDisplayTimeout);
		this.notificationDisplayTimeout = null;

		// Set a timeout for the length of time to display the notification
		this.notificationDisplayTimeout = setTimeout(
			function() {
				this.showNotification = false;
				this.setNotificationText("");
			}.bind(this),
			notificationTimeToShow
		);
	}
}
