import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RulesEngineState } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: RulesEngineState = getDefaultState();
const namespaced: boolean = true;

export const rulesEngine: Module<RulesEngineState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
