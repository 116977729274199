import { MutationTree } from "vuex";
import { MapLayersState, MapData, MapBounds, AdvancedFilters, DefaultViewTriggers } from "./types";
import Vue from "vue";
import { getDefaultState } from "./state";

export const mutations: MutationTree<MapLayersState> = {
	setMapData(state, mapData: MapData) {
		state.mapData = mapData;
	},
	setDefaultMapBounds(state, defaultMapBounds: MapBounds) {
		state.defaultMapBounds = defaultMapBounds;
	},
	setSelectedMapElevation(state, elevation: number) {
		state.mapSelectedElevation = elevation;
	},
	setMapLayersItemTypes(state, data) {
		state.mapLayerItemTypes = data;
	},
	setMapLayersItemType(state, { id, data }) {
		let idx = state.mapLayerItemTypes.findIndex(m => m.mapLayerItemTypeId === id);
		Vue.set(state.mapLayerItemTypes, idx, data);
	},
	setAdvancedFilters(state, filters: AdvancedFilters) {
		state.advancedFilters = filters;
	},
	setFilterChanged(state, filter: number) {
		state.filterChanged = filter;
	},
	setComputeDefaultView(state, defaultView: DefaultViewTriggers) {
		state.computeDefaultView = defaultView;
	},
	setAssetComputeDefaultView(state, compute: boolean) {
		state.computeDefaultView.assets = compute;
	},
	setAreaComputeDefaultView(state, compute: boolean) {
		state.computeDefaultView.areas = compute;
	},
	extendDefaultViewBounds(state, bounds: google.maps.LatLngLiteral) {
		if (!state.defaultViewForUser){
			state.defaultViewForUser = new google.maps.LatLngBounds();
		}
		state.defaultViewForUser.extend(bounds);
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
};
