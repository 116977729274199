
import { Component, Watch, Mixins, Ref } from "vue-property-decorator";
import { AssetMapLayerItem } from "../../mobile-assets/types";
import { namespace, Getter } from "vuex-class";
import vselect3 from "vselect3";
import MobileAssetHistory from '@/components/mobile-assets/MobileAssetHistory.vue';
import FieldOpsMixin from '@/mixins/FieldOpsMixin';
import MobileAssetList from '@/components/mobile-assets/MobileAssetList.vue';
import MobileEventShare from './Events/MobileEventShare.vue';

const Mobile = namespace("mobile");
const FieldOpsStore = namespace("fieldOps");


@Component({
    components:{
        "v-select3" : vselect3,
        "mobile-status-history" : MobileAssetHistory,
        "mobile-asset-list" : MobileAssetList,
        "event-share-queue" : MobileEventShare
    }
})

export default class AssetControl extends Mixins(FieldOpsMixin) {
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@FieldOpsStore.Mutation("setViewingAssetId") private setViewingAsset: (assetId: number) => void;
	@FieldOpsStore.State("viewingAssetId") private viewingAssetId: number;
	@Mobile.State("EventShareItems") private eventShareItems: () => void;
	@Mobile.State("UserAssetMapItem") private userAssetStatus: AssetMapLayerItem;
	@Mobile.State private isRightSideBarOpen: boolean;
    private currentControlTabIndex: number = 0;
    private selectedAssetId: number | null = null;
	@Ref() statusHistory: MobileAssetHistory;
	@Ref() eventQueue: MobileEventShare;


    @Watch("isRightSideBarOpen")
    private shouldFocusEventShare(){
        if(this.isRightSideBarOpen && this.eventShareItems && this.eventShareItems.length > 0){
            this.currentControlTabIndex = 2;
        }
    }

    private onAssetSelected(asset: AssetMapLayerItem | null){
        if(asset)
            this.selectedAssetId = asset.assetId;
        else
            this.selectedAssetId = null;

        this.setViewingAsset(asset ? asset.assetId : null);
    }

    private get userAssetId() : number | null {
        return this.userAssetStatus ? this.userAssetStatus.assetId : null;
    }

	private get isMobileEventShareEnabled(): boolean {
		return (this.getFeature(["Mobile", "FieldOps", "EventShare"]) && this.userAssetStatus && this.userAssetStatus.shareable) ;
	}

    @Watch("viewingAssetId")
    private mapViewingAssetChanged(){
        this.selectedAssetId = this.viewingAssetId;
    }

    private get filteredAssets() : AssetMapLayerItem[] {
        return this.assetAndUsersItems.filter(ast => ast.latLng && ast.assetId !== this.userAssetId)
    }

    @Watch("assetAndUsersItems", { deep: true })
    private assetAndUsersWatch(){
        if(this.statusHistory){
            this.statusHistory.reloadHistory();
        }
    }
}
