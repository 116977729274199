
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { AuditClip } from "@/services/auditService";

@Component({
	components: {}
})
export default class AuditVideo extends Vue {
	$refs!: {
		videoControl: HTMLVideoElement;
		videoControlDuration: HTMLVideoElement;
	};

	public auditClip: AuditClip | null = null;

	private readonly MEDIA_ERR_ABORTED = 1;
	private readonly MEDIA_ERR_NETWORK = 2;
	private readonly MEDIA_ERR_DECODE = 3;
	private readonly MEDIA_ERR_SRC_NOT_SUPPORTED = 4;

	private currentURL: string | null = null;
	private hasSetupVideoPlayer: boolean = false;
	private newDataAdded: boolean = false;

	private playCheck: NodeJS.Timeout = null;
	private lastCheckTime: number | null = null;

	/**
	 * Gets the playback ended state.
	 * @returns True if the video player has ended.
	 */
	public get ended(): boolean {
		return this.$refs.videoControl.ended;
	}

	/**
	 * Initializes the audit video player.
	 */
	public open() {
		this.auditClip = new AuditClip();
	}

	/**
	 * Resets the audit video player.
	 */
	public close() {
		if (this.playCheck) {
			clearInterval(this.playCheck);
			this.playCheck = null;
		}

		if (this.$refs.videoControl) {
			this.$refs.videoControl.pause();
			this.$refs.videoControl.src = "";
		}
		if (this.$refs.videoControlDuration) {
			this.$refs.videoControlDuration.src = "";
		}

		this.hasSetupVideoPlayer = false;

		if (this.auditClip) {
			this.auditClip.endClip();
			this.auditClip = null;
		}
		if (this.currentURL) {
			window.URL.revokeObjectURL(this.currentURL);
			this.currentURL = null;
		}
	}

	/**
	 * Starts playing the video from it's current position.
	 */
	public play() {
		try {
			this.$refs.videoControl.play();
		} catch (err) {
			// play can sometimes throw an exception when the video player is given an incorrectly
			// formatted MP4 (looking at you, genetec).
			console.log("AuditVideo - Error: Media type is unsupported, playback may be affected");
		}
	}

	/**
	 * Pauses the video.
	 */
	public pause() {
		this.$refs.videoControl.pause();
	}

	/**
	 * Appends remaining video data to the player
	 */
	public processRemainingData(): void {
		if (this.auditClip.hasDataToProcess()) {
			this.auditClip.clearQueue();
		}
	}

	/**
	 * Seeks to a specified time in the video.
	 * @param time the time, in seconds, to seek to.
	 */
	public seek(time: number) {
		this.$refs.videoControl.currentTime = time;
	}

	/**
	 * Sets up the video player to play a video at a specific URL.
	 * @param url the url of the video to play.
	 */
	public playURL(url: string) {
		this.$refs.videoControl.src = url;
		this.$refs.videoControl.onloadedmetadata = () => this.handleOnLoadedMetadata(this.$refs.videoControl);
		this.$refs.videoControl.ontimeupdate = () => this.onTimeUpdate();
		this.$refs.videoControl.style.backgroundColor = "#000000";
		this.$refs.videoControl.play();
	}

	/**
	 * Sets up the video player to stream video, appended with the addVideoData method.
	 */
	private playBlob() {
		try {
			if (!this.hasSetupVideoPlayer) {
				this.hasSetupVideoPlayer = true;

				this.$refs.videoControl.src = window.URL.createObjectURL(this.auditClip.mediaSource);
				this.$refs.videoControl.onerror = ev => this.onVideoControlError(ev as Event);
				this.$refs.videoControl.ontimeupdate = () => this.onTimeUpdate();
				this.$refs.videoControl.onended = () => this.onVideoEnded();
				this.$refs.videoControl.style.backgroundColor = "#000000";
				this.auditClip.setSourceOpenHandler();

				let playPromise = this.$refs.videoControl.play();

				if (playPromise) {
					playPromise.catch(error => {
						this.$refs.videoControl.src = window.URL.createObjectURL(this.auditClip.toBlob());
						this.$refs.videoControl.play();
					});
				}

				if (this.playCheck) {
					clearInterval(this.playCheck);
					this.playCheck = null;
				}

				this.playCheck = setInterval(() => {
					if (!this.$refs.videoControl) {
						if (this.playCheck) {
							clearInterval(this.playCheck);
							this.playCheck = null;
						}
					} else if (!this.$refs.videoControl.paused) {
						if (this.lastCheckTime === this.$refs.videoControl.currentTime) {
							this.$refs.videoControl.currentTime = 0;
						}
						this.lastCheckTime = this.$refs.videoControl.currentTime;
					}
				}, 1000);
			}

			this.newDataAdded = false;

			// Duration video gets video as blob files, and can read duration where media source can't.
			this.currentURL = window.URL.createObjectURL(this.auditClip.toBlob());
			this.$refs.videoControlDuration.src = this.currentURL;
			this.$refs.videoControlDuration.onloadedmetadata = () => {
				this.handleOnLoadedMetadata(this.$refs.videoControlDuration);
			};
		} catch (err) {
			console.log(`AuditVideo - Error: ${err}`);
		}
	}

	/**
	 * Appends video data to the player.
	 * @param data the byte array containing the video data to append.
	 */
	public addVideoData(data: ArrayBuffer) {
		try {
			this.auditClip.addData(data);
			this.newDataAdded = true;
			this.playBlob();
		} catch (err) {
			console.log(`AuditVideo - Error: ${err}`);
		}
	}

	/**
	 * Handler for when the video control raises an error.
	 * @param event this is the error event raised by the video control.
	 */
	public onVideoControlError(event: Event) {
		const videoElement = event.currentTarget as HTMLVideoElement;
		const { code, message } = videoElement.error;

		switch (code) {
			case this.MEDIA_ERR_ABORTED:
			case this.MEDIA_ERR_NETWORK:
			case this.MEDIA_ERR_SRC_NOT_SUPPORTED: {
				// Unlikely that a reset will help us here, so we'll have to notify the control we've finished streaming.
				console.log(`AuditVideo - Error: ${message}`);
				this.onVideoEnded();
				break;
			}
			case this.MEDIA_ERR_DECODE: {
				if (message == "PIPELINE_ERROR_DECODE: Failed to send video packet for decoding: EOS") {
					// We don't need to reset, the video likely streamed fine but no EOS packet was sent. Note: This is
					// unreliable as it relies on a Chrome-specific error message. We need to either remove this logic, or
					// investigate a browser-agnostic solution.
					console.log("AuditVideo - Error: End of sequence not found.");
					this.onVideoEnded();
				} else {
					// If we reach this point, there's a pretty good chance that we've been sent an incomplete packet
					// (ie, Genetec), so emit an error event and request a restart in 5 seconds when we may have more data.
					console.log(`AuditVideo - Error: ${message}`);
					this.$emit("onVideoError", { restart: true });
				}
				break;
			}
		}
	}

	/**
	 * Handler for when the video control raises an end event.
	 */
	public onVideoEnded() {
		try {
			// If new data has been added since the end event, and the player hasn't encountered an error,
			// attempt to resume playback.
			if (this.newDataAdded) {
				this.playBlob();
			} else {
				// Otherwise, emit an end event, including the error state
				this.hasSetupVideoPlayer = false;
				clearInterval(this.playCheck);
				this.$emit("onVideoEnded");
			}
		} catch (err) {
			console.log(`AuditVideo - Error: ${err}`);
		}
	}

	/**
	 * Handler for when metadata is loaded by the player, updates the video duration
	 * and emits an event.
	 * @param videoElement the video element that raised the event.
	 */
	private handleOnLoadedMetadata(videoElement: HTMLVideoElement) {
		try {
			this.updateDuration(videoElement);
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * Re-calculates the video duration in minutes and seconds, and emits them
	 * in an event.
	 * @param videoElement the video element to calculate the duration for.
	 */
	private updateDuration(videoElement: HTMLVideoElement) {
		try {
			var duration = videoElement.duration;
			var minutes = Math.floor(duration / 60);
			var seconds = Math.round(duration % 60);

			this.$emit("durationChanged", {
				time: duration,
				minutes: minutes,
				seconds: seconds,
				totalSeconds: minutes * 60 + seconds
			});
		} catch (err) {
			console.log(`AuditVideo - Error: ${err}`);
		}
	}

	/**
	 * Handler for when the video player raises a time updated event. Calculates the
	 * current time in minutes and seconds, and emits them in an event.
	 */
	private onTimeUpdate() {
		if (!this.$refs.videoControl)
			return;

		try {
			var currentTime = this.$refs.videoControl.currentTime;
			var minutes = Math.floor(currentTime / 60);
			var seconds = Math.ceil(currentTime % 60);

			this.$emit("timeChanged", {
				time: currentTime,
				minutes: minutes,
				seconds: seconds,
				totalSeconds: minutes * 60 + seconds
			});
		} catch (err) {
			console.log(`AuditVideo - Error: ${err}`);
		}
	}

	/**
	 * Sets the current playback speed of the video.
	 * @param speed the percentage playback rate, where 1 is the default speed.
	 */
	public setPlaybackSpeed(speed: number) {
		this.$refs.videoControl.playbackRate = speed;
	}

	/**
	 * Gets the current image in the video player, and returns it.
	 * @returns a data URL to the image.
	 */
	public currentImage(): string {
		var canvas = document.createElement("canvas");
		canvas.width = this.$refs.videoControl.videoWidth;
		canvas.height = this.$refs.videoControl.videoHeight;
		canvas.getContext("2d")!.drawImage(this.$refs.videoControl, 0, 0, canvas.width, canvas.height);

		var image = canvas.toDataURL("image/jpeg");
		return image;
	}

	/**
	 * Checks the current media for video and audio tracks
	 * @returns a data URL to the image.
	 */
	public OnLoadedData(e: any): void {
		//any used as cannot find correct event type
		const mediaHasAudio = e.target.webkitAudioDecodedByteCount && e.target.webkitAudioDecodedByteCount > 0;
		const mediaHasVideo = e.target.webkitVideoDecodedByteCount && e.target.webkitVideoDecodedByteCount > 0;

		this.$emit("mediaHasAudio", mediaHasAudio);
		this.$emit("mediaHasVideo", mediaHasVideo);
	}

	/**
	 * Sets the current mute status of the video.
	 * @param muted muted status
	 */
	public muteUnMute(muted: boolean) {
		this.$refs.videoControl.muted = muted;
	}
}
