import { MapLayersState } from "./types";
import {
    MarkerIcons
} from "@sureview/v2-mapping-saas";

export function getDefaultState(): MapLayersState {
    return {
        mapData: {
        	layers: [],
			items: [],
            elevations: [],
			areas: []
        },
        defaultMapBounds: {
        	north: -90,
			south: 90,
			east: -180,
        	west: 180
		},
        mapSelectedElevation: 1,
        mapItemIcons: MarkerIcons,
        mapLayerItemTypes: [],
		advancedFilters: {
			assetTypes: [],
			asset: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			trackedAsset: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			user: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			alarm: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			relay: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			output: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			audio: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			camera: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			area: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
			regions: {
				filterType: "Basic",
				filterCondition: true,
				mapLayerItemTypeId: null
			},
		},
		filterChanged: null,
		defaultViewForUser: null,
		computeDefaultView: {
			assets: false,
			areas: false
		}
    }
}
