
import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator";
import api from "@/services/api.service";
import vselect3 from "vselect3";

@Component({
    components: { "v-select3" : vselect3 }
})
export default class AssetStatusInput extends Vue {
    @Prop()
    public assetId: any;

    @Prop({ type: Boolean, default: false})
    public isMultiAsset: boolean;

    @Prop()
    public assetIds: number[];

    @Prop()
    public value: string

    @Prop({ type: Boolean, default: false})
    public disabled;

    private recentStatuses: string[] = [];
    private status: string = "";

    private async mounted() {
        if(this.isMultiAsset) {
            this.recentStatuses = await api.getRecentStatusesMultiAssets(this.assetIds);
        }
        else if(this.assetId)
        {
            this.status = this.value;
            this.recentStatuses = await api.getRecentStatuses(this.assetId);
        }
    }

    public async reloadStatusList(){
        if(this.isMultiAsset){
            this.recentStatuses = await api.getRecentStatusesMultiAssets(this.assetIds);
        } else if(this.assetId){
            this.recentStatuses = await api.getRecentStatuses(this.assetId);
        }
    }

    @Watch("value")
    private currentStatusUpdated(){
        this.status = this.value;
    }

    @Watch("assetIds")
    private async mapItemsUpdated(){
        if(this.isMultiAsset){
            this.recentStatuses = await api.getRecentStatusesMultiAssets(this.assetIds);
        }
      }


    @Watch("assetId", { deep : true})
    private async mapItemUpdated(assetId: number | null){
        if(assetId && !this.isMultiAsset){
            this.recentStatuses = await api.getRecentStatuses(this.assetId);
            this.status = this.value;
        } else {
            this.recentStatuses = [];
            this.status = this.value;
        }
    }

    @Emit("input")
    private statusChanged(status : string | null){
        return status;
    }
}
