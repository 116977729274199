var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.manualRaiseShown && _vm.permissions.canAccessManualRaise)?_c('b-modal',{attrs:{"hide-backdrop":!_vm.disableStyles,"modal-class":_vm.disableStyles ? '' : 'manual-raise-modal',"body-class":"d-flex flex-column","content-class":"mx-5","static":true,"centered":true,"size":"lg","visible":_vm.manualRaiseShown && _vm.permissions.canAccessManualRaise},on:{"hidden":function($event){return _vm.setManualRaiseShown(false)}}},[_c('template',{slot:"modal-title"},[_vm._v("Manual Raise")]),(!_vm.disableMapAlert)?_c('div',{staticClass:"alert",class:{
			'alert-danger': this.pinNeedsDrop(),
			'alert-primary': !this.pinNeedsDrop()
		}},[_vm._v("Drop a pin on the map to set alarm location")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1 d-flex flex-column mt-1 mr-2",staticStyle:{"width":"45%"}},[_c('span',{staticClass:"flex-grow-0"},[_vm._v(" Area: "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('area-tree-select',{attrs:{"value":_vm.selectedGroup,"clearable":false,"append-to-body":true},on:{"input":_vm.setSelectedGroup}})],1),(_vm.selectedGroup != null)?_c('div',{staticClass:"flex-grow-1 d-flex flex-column",staticStyle:{"width":"50%"}},[_c('span',{staticClass:"flex-grow-0"},[_vm._v("Floor:")]),(_vm.mapLayersForSelectedGroup.length > 0)?_c('div',[_c('v-select-3',{attrs:{"label":"title","placeholder":"Alarm Floor","max-height":150,"options":_vm.mapLayersForSelectedGroup,"searchable":true,"clearable":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
		var option = ref.option;
return [_c('span',[_vm._v("Floor: "+_vm._s(option))])]}}],null,false,2620855188),model:{value:(_vm.selectedLayer),callback:function ($$v) {_vm.selectedLayer=$$v},expression:"selectedLayer"}},[_c('template',{slot:"noOptions"},[_c('span',[_vm._v("No floors available")])])],2)],1):_vm._e(),(_vm.mapLayersForSelectedGroup.length === 0)?_c('div',{staticClass:"flex-grow-1 d-flex justify-content-center flex-column"},[_c('span',[_vm._v("No floor plans for "+_vm._s(_vm.selectedGroup.label))])]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_vm._v(" Event Type: "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('v-select-3',{attrs:{"id":"ms-manual-raise-event-type","label":"title","placeholder":"Select event type","max-height":150,"options":_vm.eventTypes,"searchable":true,"clearable":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
		var option = ref.option;
return [_c('span',[_vm._v("Event Type: "+_vm._s(option))])]}}],null,false,1090672504),model:{value:(_vm.selectedEventType),callback:function ($$v) {_vm.selectedEventType=$$v},expression:"selectedEventType"}})],1),_c('div',{staticClass:"text-container"},[_c('label',{attrs:{"for":"tbNotes"}},[_vm._v("Notes:")]),(_vm.noteRequiredForManualRaise)?_c('span',{staticClass:"text-danger",attrs:{"data-cy":"note-required-icon"}},[_vm._v(" *")]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.notes),expression:"notes"}],staticClass:"custom-textarea",attrs:{"id":"tbNotes","rows":"5","placeholder":"Notes","maxlength":_vm.maxNotesLength},domProps:{"value":(_vm.notes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.notes=$event.target.value}}}),_c('label',{staticClass:"text-wordcount",class:{ 'text-danger': _vm.notes.length > _vm.maxNotesLength }},[_vm._v(_vm._s(_vm.notes.length)+" | "+_vm._s(_vm.maxNotesLength))])]),_c('div',{staticClass:"text-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.raisedBy),expression:"raisedBy"}],staticClass:"form-control",staticStyle:{"font-size":"inherit"},attrs:{"id":"tbRaisedBy","type":"text","placeholder":"Requested by","maxlength":_vm.maxRaisedByLength},domProps:{"value":(_vm.raisedBy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.raisedBy=$event.target.value}}}),_c('label',{staticClass:"text-wordcount",class:{
					'text-danger': _vm.raisedBy.length > _vm.maxRaisedByLength
				}},[_vm._v(_vm._s(_vm.raisedBy.length)+" | "+_vm._s(_vm.maxRaisedByLength))])]),_c('template',{slot:"modal-footer"},[(_vm.error)?_c('div',{staticClass:"alert alert-primary"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(!_vm.disableAutomaticClaim)?_c('div',{staticClass:"custom-control custom-checkbox",class:[_vm.claimCheckboxFocused ? 'focused':'']},[(_vm.claimCheckboxFocused)?_c('a',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['space']),expression:"['space']",modifiers:{"once":true}}],staticClass:"hidden",on:{"shortkey":function($event){_vm.autoHandle = !_vm.autoHandle}}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.autoHandle),expression:"autoHandle"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customCheck1","checked":""},domProps:{"checked":Array.isArray(_vm.autoHandle)?_vm._i(_vm.autoHandle,null)>-1:(_vm.autoHandle)},on:{"focus":function($event){_vm.claimCheckboxFocused = true},"blur":function($event){_vm.claimCheckboxFocused = false},"change":function($event){var $$a=_vm.autoHandle,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.autoHandle=$$a.concat([$$v]))}else{$$i>-1&&(_vm.autoHandle=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.autoHandle=$$c}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customCheck1"}},[_vm._v("Automatically claim")])]):_vm._e(),_c('b-button',{staticClass:"pull-right ksConfirm",class:{ disabled: _vm.noteNotEntered },attrs:{"variant":"primary","id":"confirmBtn","data-dismiss":"modal","title":_vm.raiseTitle,"disabled":_vm.$v.$invalid || _vm.isRaising || _vm.noteNotEntered},on:{"click":function($event){return _vm.raise()}}},[_vm._v("OK")]),_c('button',{staticClass:"btn btn-secondary pull-right",attrs:{"data-dismiss":"modal","id":"cancelBtn"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }