import { RootState } from "./types";

export const postLoginAppBootstrap = async (store: any) => {
	let isPendingInitialAppData = false;
	store.subscribe(async (mutation: any, state: RootState) => {
		if (
			mutation.type === "login" ||
			(!state.appInitialised && !isPendingInitialAppData)
		) {
			isPendingInitialAppData = true;
			await store.dispatch("initApp");
		}
	});
};
