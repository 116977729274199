
import { FeaturesList, VueBootstrapField } from "@/store/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AlarmAutomation } from '@/types/sv-data/system-view/SetupSummary';

interface stringFormattedDictionaryObject {
	emphasised: boolean;
	value: string;
}

@Component({
	components: {
		VuePerfectScrollbar
	}
})
export default class SystemViewAlarmAutomations extends Vue {
	@Prop({required: true}) private alarmAutomations: AlarmAutomation [];
	@Getter getFeaturesList: FeaturesList;

	private get alarmActionsFields(): VueBootstrapField[] {
		return [
			{
				key: "responseActionTypeId",
				label: "Type",
				sortable: false
			},
			{
				key: "description",
				label: "Description",
				sortable: false
			},
		];
	}

	/**
	 * @summary This function uses a regular expression to match strings <***>like this</***>.
	 * @param rawString The string to match the regular expression on.
	 * @returns A dictionary, containing a variable amount of entries.
	 * 			Each property contains an object stating the string value and if it should be emphasised or not.
	 */
	private getDescriptionParts(rawString: string): Record<string, stringFormattedDictionaryObject> {
		const boldPartRegEx = /<\*\*\*>([^<]{1,})<\/\*\*\*>/g;

		let match;
		let startIndex = 0;
		let currentStringNumber = 1;
		let stringFormattedDictionary = {} as Record<string, stringFormattedDictionaryObject>;

		if(!rawString){
			return stringFormattedDictionary;
		}

		while ((match = boldPartRegEx.exec(rawString)) !== null) {

			const descriptionBeginningPart = rawString.substring(startIndex, rawString.indexOf(match[0])).trim();

			// our non-matches should not be emphasised
			if (descriptionBeginningPart) {
				stringFormattedDictionary[`string${currentStringNumber++}`] = {
					emphasised: false,
					value: descriptionBeginningPart
				};
			}

			// our regex match should be emphasised
			stringFormattedDictionary[`string${currentStringNumber++}`] = {
				emphasised: true,
				value: match[1].trim()
			};

			// set startIndex for next loop
			startIndex += boldPartRegEx.lastIndex - startIndex;
		}

		// add whatever is leftover as non-emphasised
		if (startIndex < rawString.length) {
			stringFormattedDictionary[`string${currentStringNumber}`] = {
				emphasised: false,
				value: rawString.substring(startIndex).trim()
			};
		}

		return stringFormattedDictionary;
	}
}
