
import { Component, Mixins, Emit, Watch, Prop } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import { get } from "lodash";
import { Datetime } from "vue-datetime";
import { Settings } from "luxon";
import { setTimeout } from "timers";
import GoogleMap from "@/scripts/mapping/map";
import vSelect3 from "vselect3";

import { getMapStyle } from "@/scripts/mapping/mapStyle";
import getScript from "@/services/insertScript.service";
import { formatDateMixin } from "@/mixins";
import { FeaturesList } from "@/store/types";
import MapSearch from "@/components/mapping/MapSearch.vue";
import EventOutcomeSelector from "@/components/EventOutcomeSelector.vue";
import { Tag } from "@/types";
import IncidentReport from "@/components/IncidentReport.vue";
import api from "@/services/api.service";
import ResponseAlarmType from "@/types/sv-data/events/ResponseAlarmType";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { MapBounds } from "@/store/map-layers/types";
import HideMapMixin from "@/mixins/HideMapMixin";

const MapLayers = namespace("mapLayers");
const EventSearchStore = namespace("eventSearch");

const {
	weekAgoDateFormatted,
	endOFDayFormatted,
	toStartOfDay,
	toEndOfDay
} = formatDateMixin.methods;

Settings.defaultLocale = "en";

@Component({
	components: {
		"v-select-3": vSelect3,
		"map-search": MapSearch,
		datetime: Datetime,
		"event-outcome-selector": EventOutcomeSelector,
		"incident-report": IncidentReport,
		"area-tree-select": AreaTreeSelect
	}
})
export default class EventSearch extends Mixins(HideMapMixin) {
	public $refs!: {
		mappingContainer: HTMLElement;
		eventTypeFilterSelector: any;
	};

	@EventSearchStore.Getter events: any[];
	@EventSearchStore.Getter searchableEventTypes: any[];
	@EventSearchStore.Getter eventCategories: any[];
	@EventSearchStore.Getter eventDeviceTypes: any[];
	@EventSearchStore.Getter eventOperators: any[];
	@EventSearchStore.Getter eventAlarmTypes: any[];
	@EventSearchStore.Action loadSearchableEventTypes: any;
	@EventSearchStore.Action loadDeviceTypes: any;
	@EventSearchStore.Action loadOperators: any;
	@EventSearchStore.Action loadEventCategories: any;
	@EventSearchStore.Action loadAlarmTypes: any;

	@EventSearchStore.Mutation setAlarmTypes: any;

	@MapLayers.State("defaultMapBounds") mapLayersBounds!: MapBounds;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getHideLabels: any;
	@Getter getUserTenantGroupId: number;
	@Getter getMapType: any;
	@Action setMapType: any;

	private mapType: string = "";
	private isMapVisible: boolean = false;
	private map: GoogleMap | null = null;
	private selectedMapArea: google.maps.Polygon | null = null;
	private mapInitialized: boolean = false;
	private clearClicked: boolean = false;
	private dataLoaded: boolean = false;
	private tags: Tag[] = [];
	private alarmTagOptions: ResponseAlarmType[] = [];

	@Prop({default: 0})
	private eventId: number;

	@Prop({ type:Boolean, default: false })
	private searchPending: boolean;	

	private allEventTypeFilterOption = {
		eventTypeID: -1,
		title: "All",
		searchable: true
	};

	private searchModel = {
		...this.defaultSearchFilters,
		boundaryPath: null,
		eventTypeId: null,
		deviceType: null,
		alarmType: null,
		category: null,
		operator: null,
		flaggedEventsOnly: false,
		tags: [],
		allTags: false,
		alarmTags: [],
		allAlarmTags: false,
		includeAutoHandled: true,
	};		
	
	// Watchers
	@Emit("setSearchFilter")
	private search() {
		if(this.searchPending)
		{
			return;
		}

		// Temporary solution. Waiting for API changes to remove this.
		Object.keys(this.searchModel).forEach(key => {
			if (
				this.searchModel[key] === null ||
				this.searchModel[key] === ""
			) {
				delete this.searchModel[key];
			}
		});

		const search = {
			...this.defaultSearchFilters,
			...this.searchModel
		};

		if (search.boundaryPath) {
			delete search.groupID;
		}

		if (search.eventTypeId === this.allEventTypeFilterOption.eventTypeID) {
			delete search.eventTypeId;
		}
		return search;
	}

	@Watch("isMapVisible")
	private onMapVisibilityChange(value: boolean) {
		// Clear polygons on the map when hiding it
		if (!value && this.selectedMapArea) {
			this.selectedMapArea.setMap(null);
			this.selectedMapArea = null;
		}
	}

	@Watch("selectedMapArea")
	private onMapFilterChange(zone) {
		if (zone) {
			const paths = this.getZonePaths(zone);
			this.searchModel.boundaryPath = paths.length
				? JSON.stringify(paths)
				: null;
		} else {
			this.searchModel.boundaryPath = null;
		}
	}

	@Watch("searchModel.deviceType")
	private onDeviceTypeChange(newValue: number | null, oldValue: number | null) {
		// Alarm types depend on selected deviceType
		if (newValue && newValue != oldValue) {
			this.loadAlarmTypes(newValue);
		} else {
			this.setAlarmTypes([]);
		}

		this.searchModel.alarmType = null;
	}

	@Watch("searchModel.groupID")
	private async onGroupIdChange() {
		if (this.isFeatureAlarmTagSearchEnabled) {
			this.alarmTagOptions = await api.responseAlarmTypes(this.searchModel.groupID);
		}
	}

	// Private Methods
	private initMap() {
		if (this.mapInitialized || this.isHideMapsEnabled) {
			return;
		}

		this.mapInitialized = true;
		getScript(
			`https://maps.googleapis.com/maps/api/js?key=` +
				`${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}` +
				`&libraries=drawing,places,geometry`
		).then(() => {
			const mapPosition = { lat: 0, lng: 0, zoom: 25 };

			this.map = new GoogleMap(
				this.$refs.mappingContainer,
				mapPosition,
				mapPosition.zoom,
				false,
				this.getMapType,
				getMapStyle(this.getMapType, this.getHideLabels)
			);
			this.mapType = this.getMapType;

			this.map!.registerEvent("onMapTypeChanged", (mapType: string) => {
				this.mapType = mapType;
				this.setMapType(mapType);

				this.map!.map.setOptions({
					styles: getMapStyle(this.getMapType, this.getHideLabels)
				});
			});

			const bounds = new google.maps.LatLngBounds(
				new google.maps.LatLng(
					this.mapLayersBounds.south,
					this.mapLayersBounds.west
				),
				new google.maps.LatLng(
					this.mapLayersBounds.north,
					this.mapLayersBounds.east
				)
			);
			this.map!.map.fitBounds(bounds);

			const drawingManager = new google.maps.drawing.DrawingManager({
				drawingMode: google.maps.drawing.OverlayType.POLYGON,
				drawingControl: true,
				drawingControlOptions: {
					position: google.maps.ControlPosition.TOP_CENTER,
					drawingModes: ["polygon"]
				},
				polygonOptions: this.polygonOptions
			} as any);
			drawingManager.setMap(this.map!.map);

			// on zone add
			google.maps.event.addListener(
				drawingManager,
				"polygoncomplete",
				(zone: google.maps.Polygon) => {
					if (this.selectedMapArea != null) {
						this.selectedMapArea.setMap(null);
						this.selectedMapArea = null;
					}

					// @todo search by map position
					this.selectedMapArea = zone;
				}
			);
		});
	}

	private clear() {
		Object.keys(this.searchModel).forEach(key => {
			this.$set(this.searchModel, key, null);
		});

		this.clearClicked = true;
		this.$set(this, "searchModel", {
			...this.searchModel,
			...this.defaultSearchFilters
		});
	}

	private getZonePaths(zone: google.maps.Polygon) {
		return zone
			.getPath()
			.getArray()
			.map(point => {
				return { lat: point.lat(), lng: point.lng() };
			});
	}

	private setBounds(bounds: any) {
		if (bounds) {
			this.map!.map.fitBounds(
				new google.maps.LatLngBounds(
					{ lat: bounds.south, lng: bounds.west },
					{ lat: bounds.north, lng: bounds.east }
				)
			);
		}
	}

	private setMapLocation(gpsData: any) {
		if (gpsData) {
			if (gpsData.viewport) {
				this.map!.map.fitBounds(gpsData.viewport);
			} else if (gpsData.location) {
				this.map!.map.setCenter(gpsData.location);
				this.map!.map.setZoom(17);
			}
		}
	}

	private toggleMapVisible() {
		if (this.isMapVisible) {
			this.isMapVisible = false;
		} else {
			this.isMapVisible = true;
			setTimeout(() => {
				this.initMap();
			}, 500);
		}
	}

	private checkIfEventTypeHasBeenCleared(inputValue) {
		let userHasClearedSelection = inputValue === null;
		if (userHasClearedSelection) {
			this.$refs.eventTypeFilterSelector.$emit("cleared");
		}
	}

	private async created() {
		this.loadDeviceTypes();
		if (this.isEventTypeFilteringEnabled) {
			await this.loadSearchableEventTypes();
			this.searchModel.eventTypeId = this.allEventTypeFilterOption.eventTypeID;
		}

		if (this.isEventTagSearchEnabled) {
			this.tags = await api.eventTags();
		}

		this.loadOperators();
		await this.loadEventCategories();

		this.searchModel = {
			...this.defaultSearchFilters,
			boundaryPath: null,
			eventTypeId: null,
			deviceType: null,
			alarmType: null,
			category: null,
			operator: null,
			flaggedEventsOnly: false,
			tags: [],
			allTags: false,
			alarmTags: [],
			allAlarmTags: false,
			includeAutoHandled: !this.isIncludeAutoHandledEnabled
		};
		this.dataLoaded = true;
	}

	private outcomeChanged(value) {
		this.$set(this.searchModel, "category", value);
	}

	// Getters
	private get eventCategoriesMap() {
		if (!this.eventCategories) {
			return [];
		}

		return this.eventCategories.map(category => {
			return {
				id: category.eventOutcomeID,
				parentId: category.parentID,
				title: category.title
			};
		});
	}

	private get defaultSearchFilters () {
		return {
			from: weekAgoDateFormatted(),
			until: endOFDayFormatted(),
			groupID: this.getUserTenantGroupId,
			tags: []
		};
	}

	private get timeZone(): string {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	private get searchText(): string
	{
		return this.searchPending ? "Searching..." : "Search";
	}

	private get polygonOptions() {
		return {
			strokeColor: this.$config.colors.POLYGON_STROKE,
			strokeOpacity: 1,
			strokeWeight: 4,
			fillColor: this.$config.colors.POLYGON_FILL,
			fillOpacity: 0.35
		};
	}

	private get isEventTypeFilteringEnabled() {
		return get(
			this.featuresList,
			["Alarms", "EventSearch", "EventTypeFilter"],
			false
		);
	}

	private get isEventTagSearchEnabled() {
		return get(
			this.featuresList,
			["Alarms", "EventSearch", "EventTagSearch"],
			false
		);
	}

	private get isFeatureAlarmTagSearchEnabled() {
		return get(
			this.featuresList,
			["Alarms", "EventSearch", "AlarmTag"],
			false
		);
	}

	private get allSearchableEventTypes() {
		return [
			this.allEventTypeFilterOption,
			...this.searchableEventTypes
		]
	}

	private get isIncludeAutoHandledEnabled(): boolean {
		return get(
			this.featuresList,
			["Alarms", "EventSearch", "AutoHandledFilter"],
			false
		);
	}

	private get showMap() : Boolean {
		return this.isMapVisible && !this.isHideMapsEnabled;
	}
}
