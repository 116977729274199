
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import api from "@/services/api.service";
import AreaDetails from "./AreaDetails.vue";
import AreaDateTimeDisplay from "./AreaDateTimeDisplay.vue";

const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		"area-details": AreaDetails,
		"area-date-time-display": AreaDateTimeDisplay,
	}
})
export default class EventDetails extends Vue {
	@SiteMonitor.Getter("getEventDetailsShown") eventDetailsShown!: boolean;
	@SiteMonitor.Mutation setEventDetailsShown: any;

	constructor() {
		super();
	}

	@Prop({ type: String }) siteTitle;
	@Prop({ type: Number }) groupId;

	private timeZoneId: number = null;
	private leaveModal: boolean = false;

	created() {
		this.setEventDetailsShown(false);
	}

	@Watch("groupId")
	private async setTimeZoneId(): Promise<void> {
		let { timeZoneID } : { timeZoneID: number } = await api.loadGroup(this.groupId);
		this.timeZoneId = timeZoneID;
	}

	@Watch("eventDetailsShown")
	onEventDetailsShownChanged(value: boolean, oldValue: boolean) {
		if (value) {
			this.leaveModal = true;
		} else {
			this.leaveModal = false
		}
	}

	closeDetails() {
		this.setEventDetailsShown(false);
	}
}
