
	import { Component, Prop, Vue, Emit } from "vue-property-decorator";
	import UrlInputLine from "@/components/UrlInputLine.vue";
	import { ActionPlanTask } from "@/store/tasks/types";

	@Component({
		components: {
			"url-input": UrlInputLine
		}
	})
	export default class UrlTask extends Vue {
		@Prop(Boolean)
		public editing: Boolean;

		@Prop()
		public task!: ActionPlanTask;

		public mounted() {
			this.moveUrlToTaskData();
			if (this.editing) {
				this.onUrlValidate(false);
			}
		}

    	private moveUrlToTaskData(): void {
			//If an old URL task Type where the url is saved on the taskText is loaded
			// move the url to the taskData
			if (this.task && !this.task.taskData) {
				this.task.taskData = this.task.taskText;
				this.task.taskText = "";
			}
			}

		/**
		 * Validation event from UrlInputLine is passed straight up
		 */
		@Emit("validate-task")
		private onUrlValidate(isValid: boolean) {
			return isValid;
		}

		/**
		 * Marks the task as completed but does not prevent the default action
		 * @returns true so we do not prevent the default action of the link
		 */
		public onCompleteTask() {
			this.$emit("complete-task", {
				...this.task,
				completed: true
			});

			return true;
		}

		private get urlLink(): string {
			return this.task.taskData ? this.task.taskData : this.task.taskText;
		}

		public get urlText () {
			return this.task.taskText ? this.task.taskText : this.task.taskData;
		}

		private onLabelChanged() {
			this.onUrlValidate(true);
		}
	}
