import { RootState } from "../types";
import axios from "axios";
import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { AlarmPointState } from "./types";

export const actions: ActionTree<AlarmPointState, RootState> = {
    async responseSearch({ commit }, { groupId, query }) {
        const cancelTokenSource = axios.CancelToken.source();

        commit("setCancelTokenSource", cancelTokenSource);
        commit("setResponses", await api.getAllResponsesByArea(groupId, query));
    },
    async fetchDisableAutomaticLoad({ commit }) {
        const { data } = await api.disableAutomaticLoad();

        commit("setDisableAutomaticLoad", data);
    }
}