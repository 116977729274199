
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { UserPermissions } from "@/store/types";
import { Constants } from "@/types/Constants";

	@Component
	export default class AlarmMaskDurationInput extends Vue {
		@Prop({default: 10}) public readonly value : number;
		@Prop({default: 1440}) public readonly maxMaskDurationWithNoExtendedPermissions : number;

		@Getter getPermissions: UserPermissions;
		@Getter getDefaultMaskValue: number;

		private localValue: string = "10";
		private maxMaskDurationLimit: number = Constants.MAX_MASKING_DURATION;

		private mounted() {
			this.localValue = this.getDefaultMaskValue > this.maxMaskDuration ? this.maxMaskDuration.toString() : this.getDefaultMaskValue.toString();
		}

		@Watch("localValue")
		private onLocalValueChanged() {
			this.$emit("input", this.localValue);
		}

		private get maxMaskDuration() {
			return this.getPermissions.canDisarmSitesExtended ? this.maxMaskDurationLimit : this.maxMaskDurationWithNoExtendedPermissions;
		}

		private maxMaskInputFormatter(value): string {
			if (value > this.maxMaskDuration) {
				return this.maxMaskDuration.toString();
			} else if (value < 1) {
				return "1";
			} else {
				return value;
			}
		}

		private get isMaxMaskDuration(): boolean {
			return this.localValue === this.maxMaskDuration.toString();
		}

		private get getWarningTitle(): string {
			return this.getPermissions.canDisarmSitesExtended ?
			"Unable to mask an alarm for longer than " + this.maxMaskDurationLimit + " minutes":
			 "You do not have permission to mask an alarm for longer than " + this.maxMaskDuration + " minutes";
		}
	}
