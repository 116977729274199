
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { DeviceService } from "@sureview/camera";

import Sessions from "@/components/Sessions.vue";
import AreaTreeSelect from "./form/AreaTreeSelect.vue";
import ClipsList from "@/components/media/ClipsList.vue";
import AreaCameras from "@/components/media/AreaCameras.vue";
import MatrixContent from "@/components/media/MatrixContent.vue";
import Tour from "@/components/media/Tour.vue";
import AudioControl from "@/components/audio/AudioControl.vue";
import ControlDevicesList from "@/components/media/ControlDevicesList.vue";
import { defaultDeviceControllerOutput, MatrixContents, SearchedCamera } from "@/store/site-monitor-cameras/types";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Multiselect from "vue-multiselect";
import VueScrollTo from "vue-scrollto";
import { CameraType, ClipType, DeviceControllerOutput, NearbyCameraType } from "@/store/site-monitor-cameras/types";
import { EventDetails } from "@/store/site-monitor/types";
import { SiteTreeNode } from "@/store/eventqueue/types";
import { FeaturesList, UserPermissions } from "@/store/types";
import { get, debounce } from "lodash";

import { renderDistance } from "@/filters";
import { SessionResource } from "@/store/sessions/types";
import api from "@/services/api.service";
import { MapLayerItemTypeIds } from '@/store/map-layers/types';
import { truncateString } from '@/filters';
import SystemViewCameraIcon from "./system-view/SystemViewCameraIcon.vue";

const Eventqueue = namespace("eventqueue");
const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Session = namespace("sessions");
const Tours = namespace("tours");

export interface SelectedAreaNode {
	address: string;
	cameras: any[] | null;
	children: any | null;
	id: number;
	isDefaultExpanded: boolean;
	label: string;
	latLong: string;
}

@Component({
	components: {
		session: Sessions,
		VuePerfectScrollbar: VuePerfectScrollbar,
		multiselect: Multiselect,
		"area-tree-select": AreaTreeSelect,
		"clips-list": ClipsList,
		"area-cameras": AreaCameras,
		tour: Tour,
		"matrix-content": MatrixContent,
		"audio-control": AudioControl,
		"control-devices-list": ControlDevicesList,
		"system-view-camera-icon": SystemViewCameraIcon
	},
	filters: {
		renderDistance,
		truncateString
	}
})
export default class DeviceController extends Vue { 
	@Eventqueue.Getter("getSiteTree") tree: SiteTreeNode[];
	@Eventqueue.Mutation setSiteTreeNodeValues: any;
	@Eventqueue.Mutation resetTree: any;
	@SMCameras.Getter("getAreaCameras") areaCameras!: CameraType[];
	@SMCameras.Getter("getDeviceControllerCameras") cameras!: any[];
	@SMCameras.Getter("getDeviceControllerClips") clips!: ClipType[];
	@SMCameras.Getter("getDeviceControllerOutputs") outputs!: any[];
	@SMCameras.State("areaOutputs") areaOutputs!: DeviceControllerOutput[];
	@SMCameras.State("deviceControllerAudioDevices") private audioDevices!: any[];
	@SMCameras.State("searchedCameraCount") private searchedCameraCount: number;
	@SMCameras.State("areaCameraCount") public areaCameraCount: number;

	//@SMCameras.Getter('getMediaMatrixEventId') eventId: any;
	@SMCameras.Getter("getLayouts") layouts!: number[][];
	@SMCameras.Getter("getMediaMatrixLayers") layers!: any[];
	@SMCameras.Getter("getLayoutIndex") layoutIndex!: number;
	@SMCameras.Getter getMatrixContents: any;
	@SMCameras.Getter("getMediaMatrixIsNew") mediaMatrixIsNew: any;
	@SMCameras.Getter("getFetchingMatrix") fetchingMediaMatrix: any;
	@SMCameras.Getter("getAllMatrixContents") getAllMatrixContents: MatrixContents;
	@SMCameras.Getter("getMatrixAssignedCameras") getMatrixAssignedCameras: number[];
	@SMCameras.Getter("getMediaMatrixLastChanged") mediaMatrixLastChanged: any;
	@SMCameras.Getter searchedCameras: SearchedCamera[];
	@SMCameras.Getter searchedOutputs: any;
	@SMCameras.Getter("getAreaCameras") groupCameras: CameraType[];

	@SMCameras.Mutation setMediaMatrixEventId: any;
	@SMCameras.Mutation setDeviceControllerCameras: (cameras: any[]) => void;
	@SMCameras.Mutation setDeviceControllerClips: (clips: any[]) => void;
	@SMCameras.Mutation setDeviceControllerOutputs: (outputs: any[]) => void;
	@SMCameras.Mutation setDeviceControllerAudioDevices: (audioDevices: any[]) => void;
	@SMCameras.Mutation setLayoutIndex: any;
	@SMCameras.Mutation setPushContents: any;
	@SMCameras.Mutation setAwaitingCamera: any;
	@SMCameras.Mutation setAwaitingClip: any;
	@SMCameras.Mutation setMediaMatrixIsNew: any;
	@SMCameras.Mutation highlightCell: any;
	@SMCameras.Mutation unhighlightCell: any;
	@SMCameras.Mutation clearSearchedCameras: () => void;

	@SMCameras.Action activeMapItemsFromStorage: any;
	@SMCameras.Action searchForOutputs: (params: { query: string, groupID: number }) => Promise<void>;
	@SMCameras.Action searchForCameras: (params: { query: string, groupID: number, page: number, reset: boolean }) => Promise<void>;
	@SMCameras.Action fetchAreaCameras: (params: { groupID: number, pageNumber: number, paginated: boolean }) => Promise<void>;

	@Session.Action updateSession: any;
	@Session.Getter getSession: any;
	@Session.Action clearSessions: any;

	@Tours.Getter tourInProgress: any;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getPermissions") permissions: UserPermissions;

	@SiteMonitor.Getter("getEventDetails") getEventDetails: EventDetails;
	@SiteMonitor.Getter("getActiveMapItems") mapItems: any;
	@SiteMonitor.Mutation setActiveMapItemsRequired: any;

	@SiteMonitor.Getter("getAuditService") auditService: any;
	@SiteMonitor.Getter("getIsController") isController: any;
	@SiteMonitor.Getter("getSelectedEventRecord") selectedEventRecord: any;
	@SiteMonitor.Mutation setActivity: () => void;

	@Prop({ default: "", type: String }) tourName;

	@SiteMonitor.Getter("getIsCameraEnabled") isCameraEnabled: (deviceId: number, test: string) => boolean;
	@SiteMonitor.Getter("getApplianceOfflineNotification") private applianceOfflineNotification: (deviceId: number) => string;

	private setInitialRecordForEventId: number = 0;
	private showArea: boolean = true;
	private cameraSearchQuery: string = "";
	private selected: any = null;
	private isLoading: boolean = false;
	private debounceUpdateSearchFilter;

	private outputSearchQuery: string = "";

	private showOutput: boolean = false;
	private selectedOutput: any = null;
	private selectedOutputID: string = "outputBtn";
	private actionReason: string = "";

	private initialEventRecordID: number | null = null;
	private populateNearbyCamerasModal: boolean = false;

	private isLoadingAreas: boolean = false;
	private isLoadingAreaCameras: boolean = false;
	private selectedArea: SelectedAreaNode = null;
	private pageNumber: number = 1;
	private tourEnded: boolean = false;
	private isAreaSearch: boolean = false;

	public open = {
		cameras: true,
		clips: true,
		outputs: true,
		areas: false,
		audio: true,
		nearbyCameras: true,
		searchCameras: true,
		matrixContent: true,
		tours: true,
		searchedOutputs: false,
		nearbyOutputs: false,
		areaOutputs: false,
	};

	// have to make it initially undefined
	// to make it non-rective to avoid additional update,
	// after changing from true to false in updated() hook.
	private scrollToAreaScheduled: boolean | undefined;

	@Watch("tourInProgress")
	private minimizeCollapseMenu() {
		if (this.tourInProgress) {
			Object.keys(this.open).forEach(v => (this.open[v] = false));
			this.open.tours = true;
			this.open.cameras = true;
		} else {
			Object.keys(this.open).forEach(v => (this.open[v] = true));
		}
	}

	public get showAreas() {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "AreaTree"]);
	}

	public get devicesEnabled() {
		return get(this.featuresList, ["Devices"]);
	}

	public get toursEnabled() {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "VideoTours"]);
	}

	public get showMatrixCameras() {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "OpenCameraList"]);
	}

	public get allowSpotlightScaling() {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "Map", "SpotlightScaling"]);
	}

	public get showClipsList() {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "ClipsList"]);
	}

	public get nearbyCamerasEnabled() {
		return get(this.featuresList, ["Alarms", "MediaMatrix","NearbyCameras"]);
	}

	private get isAudioEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "AudioList"], false);
	}

	private get isSystemViewEnabled() {
		return get(this.featuresList, ["SystemView"]);
	}

	public get eventId() {
		return this.getEventDetails ? this.getEventDetails.eventID : 0;
	}

	public get showOutputs() {
		return this.permissions.canViewMediaMatrixControls;
	}

	/** Get all loaded cameras within the media matrix
	 * filter to only show the objectID with the cell index.
	 */
	public get loadedCameras() {
		return Object.values(this.getAllMatrixContents)
			.filter(m => m && m.camera)
			.map(c => c.camera)
			.filter(g => g.objectID)
			.map(c => c.objectID);
	}

	public get filteredNearbyCameras() {
		let camerasToFilter: NearbyCameraType[];

		// If we've got the matrix cameras section turned on, then hide any cameras
		// that we've already got open.
		camerasToFilter = this.cameras;

		if (this.cameraSearchQuery === "") {
			return camerasToFilter;
		}

		const filterValue = this.cameraSearchQuery.toLocaleLowerCase();
		return camerasToFilter.filter(camera => camera.title.toLocaleLowerCase().indexOf(filterValue) > -1);
	}

	@Watch("eventId")
	public onEventChanged(eventId: number, oldEventId: number) {
		this.resetTree();
		this.resetCameraState();

		this.setAwaitingCamera(null);

		if (eventId == 0) {
			this.mapItemsSet = false;
		} else {
			if (this.showAreas && this.open.areas && this.getEventDetails && this.tree) {
				this.openArea(this.getEventDetails.groupID);
			}
		}
	}

	public async resetCameraState(): Promise<void> {
		this.cameraSearchQuery = "";
		this.pageNumber = 1;
		await this.fetchAreaCameras({ groupID: this.getEventDetails.groupID, pageNumber: this.pageNumber, paginated: true });
	}

	private checkCamerasComparison(newData: any, oldData: any): boolean {
		return newData.count == oldData.count && JSON.stringify(newData) !== JSON.stringify(oldData);
	}

	public get totalCells() {
		return this.layouts[this.layoutIndex][0] * this.layouts[this.layoutIndex][1];
	}

	private async loadAlarmEvent(newData: any, oldData: any, dataChanged: boolean) {
		if (!dataChanged || this.tourInProgress || !this.allowSpotlightScaling || !this.getEventDetails) {
			return;
		}

		this.populateNearbyCamerasModal = true;
	}

	@Watch("cameras")
	public async loadNearbyCameraLayer(newData: any, oldData: any) {
		/** Check to see if any of the new or old data incoming is invalid for this type of operation. */
		if (!newData || newData.length <= 0 || !oldData || oldData.length <= 0) return;

		if (this.getEventDetails && !this.getEventDetails.useNearbyCameras) return;

		// Event Types 'EventTypeID'
		const alarmEvent = 1;
		const patrolEvent = 2;

		const dataChanged = this.checkCamerasComparison(newData, oldData);
		if (this.getEventDetails.eventTypeID == alarmEvent) {
			this.loadAlarmEvent(newData, oldData, dataChanged);
		} else if (this.getEventDetails.eventTypeID == patrolEvent) {
			await this.loadNearbyCameras();
		}
	}

	@Watch("areaCameras")
	public async loadAreaCameras(newData: any, oldData: any) {
		/**
		 * Note - this function has been added to populate the media matrix with area cameras
		 * This will likely be turned off when nearby cameras are set up in ops.
		 */
		// if nearby cameras are enabled, dont use area cameras and add check if we have cameras to add
		if (this.nearbyCamerasEnabled || !newData || newData.length <= 0) return;

		// populate the media matrix with area cameras
		await this.loadCameras(this.areaCameras);
	}

	// Watch the pageNumber and reuse the same search query to update list
	@Watch("pageNumber")
	public async onPageNumberChanged(): Promise<void> {
		if (!this.cameraSearchQuery && !this.isAreaSearch) {
			this.pageNumber = 1;
			this.clearSearchedCameras();
		} else if (!this.isAreaSearch) {
			this.findCameras(this.cameraSearchQuery, false);
		} else {
			await this.areaSelected(this.selectedArea);
		}
	}

	@Watch("cameraSearchQuery")
	public async onCameraSearchQueryChanged(value: string) {
		this.pageNumber = 1;
		this.debounceUpdateSearchFilter(value);
	}

	@Watch("outputSearchQuery")
	public onOutputSearchQueryChanged(value: string) {
		this.findOutputs(value);
	}

	@Watch("mediaMatrixLastChanged")
	public onMatrixChanged(value: any) {
		if (this.searchedCameras != null && this.searchedCameras.length > 0) {
			this.setSearchCameraIndexes();
		}
	}

	@Watch("mapItems")
	public onMapItemsChanged(value: any, oldValue: any) {
		if (value) {
			this.setDevicesFromMapLayerItems(value);
		} else {
			this.setDeviceControllerCameras([]);
			this.mapItemsSet = false;
		}
	}

	@Watch("areaOutputs")
	private onAreaOutputsChanges(value: DeviceControllerOutput, oldValue: DeviceControllerOutput): void {

		// Nearby uses areaOuputs data so ensure up to date.
		this.setNearByOutputs(this.mapItems);
	}

	public async mounted() {
		this.setActiveMapItemsRequired(true);
		this.debounceUpdateSearchFilter = debounce(async (value) => {
			await this.findCameras(value, true);
		}, 250);
	}

	public updated() {
		if (this.scrollToAreaScheduled) {
			var element = document.getElementById("TreeGroup_" + this.getEventDetails.groupID);
			VueScrollTo.scrollTo(element, 50, {
				container: "#collapse-areas"
			});
			this.scrollToAreaScheduled = false;
		}
	}

	public async loadNearbyCameras() {
		await this.loadCameras(this.cameras);
		this.populateNearbyCamerasModal = false;
	}

	private async loadCameras(cameras:any[]) {
		let camerasToPush = [];
		const attachedCameras: any[] = this.selectedEventRecord?.attachedCameras.filter(ac => !this.getMatrixAssignedCameras.includes(ac.objectID));
		if (attachedCameras && attachedCameras.length > 0) {
			const attached = attachedCameras
				.map(c => ({ objectID: c.deviceID, title: c.title }));
			const notAttached = cameras.filter(c => !attached.find(ac => ac.objectID === c.objectID));
			camerasToPush = [...attached, ...notAttached]
		} else {
			camerasToPush = cameras.filter(ac => !this.getMatrixAssignedCameras.includes(ac.objectID));
		}
		// add a camera to every cell (as long as we have enough cameras)
		var cameraIndex = 0;
		for (let index = 0; index < this.totalCells && index < camerasToPush.length; index++) {
			if (this.getMatrixContents[index]) {
				continue;
			}
			let newContents = {
					camera: {
						objectID: camerasToPush[cameraIndex].objectID,
						title: camerasToPush[cameraIndex].title,
						index: index + 1
					}
				};

			await this.setPushContents({
				index: index + 1,
				newContents: newContents
			});
			cameraIndex++;
		}
	}

	public created() {
		this.updateSession({ resourceId: SessionResource.DeviceServiceSession, eventId: this.eventId });
		this.setMediaMatrixEventId(this.eventId);
	}

	private mapItemsSet: boolean = false;
	public async setDevicesFromMapLayerItems(devices: any[]): Promise<void> {
		this.mapItemsSet = true;
		if(!devices) {
			devices = [];
		}

		let cameras = devices.filter(device => device.itemType === MapLayerItemTypeIds.Camera);

		let audioDevices = devices.filter(device => device.itemType === MapLayerItemTypeIds.Audio);
		await this.setNearByOutputs(devices);
		this.setDeviceControllerCameras(cameras);
		this.setDeviceControllerAudioDevices(audioDevices);

		if (this.fillStartIndex != null) {
			this.fillCellsWithMapItems();
		}
	}

	private async setNearByOutputs(devices: any[]): Promise<void> {
		// We want Outputs/Devices not MapLayerItems
		let outputs: DeviceControllerOutput[] = await this.getOutputsFromMapLayerItems(devices);
		this.setDeviceControllerOutputs(outputs);
	}

	private async getOutputsFromMapLayerItems(devicesAsMapLayerItems: any[]): Promise<DeviceControllerOutput[]> {
		devicesAsMapLayerItems = devicesAsMapLayerItems
		        .filter(device =>
						device.itemType === MapLayerItemTypeIds.Relay ||
						device.itemType === MapLayerItemTypeIds.Output);

		let deviceIds: number[] = [];
		let outputs: DeviceControllerOutput[] = [];

		// We need to convert MapLayerItems to devices
		devicesAsMapLayerItems.forEach(device => {
			const result = this.areaOutputs.firstOrDefault(o => o.objectId == device.objectId);

			// Any devices (MapLayerItems) we don't have locally will have to be requested.
			if (!result) {
				deviceIds.push(device.objectId);
				const output = {
					...defaultDeviceControllerOutput(),
					... {
						title: device.title,
						distance: device.distance,
						objectId: device.objectId
					}
				};
				outputs.push(output);
			} else {
				const newOutput = {...result};
				newOutput.distance = device.distance;
				outputs.push(newOutput);
			}
		});

		// Request device data for missing MapLayerItems
		if (deviceIds.length > 0) {
		    const missingOutputs = await api.getDevicesByIds(deviceIds);

			// Update devices with missing data.
			missingOutputs.forEach(mOutput => {
				var index = outputs.findIndex(output => output.objectId === mOutput.deviceID);
				if (index > -1) {
					outputs[index].isOutput = mOutput.settingsMeta.includes("<relaytype>output</relaytype>");
					outputs[index].canPulse = mOutput.settingsMeta.includes("<FeaturePulse/>");
					outputs[index].canOnOff = mOutput.settingsMeta.includes("<FeatureOnOff>1</FeatureOnOff>");
				}
			});
		}

		return outputs;
	}

	private fillStartIndex: number | null = null;
	public fillCellsWithMapItems() {
		if (this.fillStartIndex != null) {
			let index = this.fillStartIndex;
			this.fillStartIndex = null;

			let cellCount = this.layoutHorizontal * this.layoutVertical;

			this.cameras.filter((c: any) => !this.getMatrixAssignedCameras.includes(c.hasOwnProperty('objectId') ? c.objectId : c.objectID)).some((camera: any) => {
				if (camera.index == null) {
					this.setPushContents({
						index: index,
						newContents: {
							camera: {
								objectID: camera.objectID ? camera.objectID : camera.objectId,
								title: camera.title
							}
						},
						eventId: this.eventId
					});
					index++;
				}

				return index > cellCount;
			});
		}
	}

	private getMatrixClipsSet(): Set<string> {
		const matrixClips = new Set<string>();
		let matrixContents = this.getAllMatrixContents;

		// iterate over current cells in the matrix
		for (var matrixIndex in matrixContents) {
			if (matrixContents[matrixIndex].clip !== undefined) {
				// get the cells clip
				let clip = matrixContents[matrixIndex].clip.clip;
				if (clip != null) {
					// add index value to the array under the clips unique file identifier
					let clipIdentifier = clip.UniqueFileIdentifier;
					matrixClips.add(clipIdentifier);
				}
			}
		}

		return matrixClips;
	}

	clipsQueue: any[] = [];
	private clipsRetrieved(clips: any) {
		if (this.fetchingMediaMatrix) {
			this.clipsQueue.push(clips);
		} else {
			this.fillIfNew(clips);
		}
	}

	@Watch("fetchingMediaMatrix")
	fetchingMediaMatrixChanged(value: boolean, oldValue: boolean) {
		if (!value) {
			while (this.clipsQueue.length > 0) {
				this.fillIfNew(this.clipsQueue.shift());
			}
		}
	}

	private async fillIfNew({ eventRecordID, cameras, newClips }) {
		let mediaMatrixIsNew = this.mediaMatrixIsNew;
		if (mediaMatrixIsNew) {
			console.log("Matrix: DeviceController-fillIfNew: Matrix is new!");

			this.setMediaMatrixIsNew(false);
			this.initialEventRecordID = eventRecordID;

			this.setInitialRecordForEventId = this.selectedEventRecord.eventID;

			let cellCount = this.layoutHorizontal * this.layoutVertical;
			let index = 1;

			// Set clips - CRITICAL, This has to be done before loading the live streams, otherwise the clips will never populate if there
			//	are 9 or more nearby cameras for the alarm
			if (this.clips && this.clips.length > 0) {
				this.clips.some((clip: any) => {
					let cellContents = this.getMatrixContents(index);
					if (cellContents == null || (cellContents.clip == null && cellContents.camera == null)) {
						this.setPushContents({
							index: index,
							newContents: {
								clip: {
									eventRecordID: clip.EventRecordId,
									clip: clip
								}
							},
							eventId: this.eventId
						});
					}
					index++;

					return index > cellCount;
				});
			}

			var addedCameras = [];

			//Set event record linked cameras
			cameras = cameras ? cameras.filter(c => !this.getMatrixAssignedCameras.includes(c.objectID)): [];
			if (cameras && cameras.length > 0 && index <= cellCount) {
				cameras.some((camera: any) => {
					if(!addedCameras.includes(camera.deviceID)) {
						this.setPushContents({
							index: index,
							newContents: {
								camera: {
									objectID: camera.deviceID,
									title: camera.title
								}
							},
							eventId: this.eventId
						});
						index++;
						addedCameras.push(camera.deviceID);
						return index > cellCount;
					}
				});
			}

			if (this.nearbyCamerasEnabled)
			{
				const nearbyCameras = this.cameras ? this.cameras.filter((c: any) => !this.getMatrixAssignedCameras.includes(c.objectId)): [];
				if (nearbyCameras && nearbyCameras.length > 0 && index <= cellCount) {
					nearbyCameras.some((camera: any) => {
						if(!addedCameras.includes(camera.objectId)) {
							this.setPushContents({
								index: index,
								newContents: {
									camera: {
										objectID: camera.objectId ? camera.objectId : camera.objectID,
										title: camera.title
									}
								},
								eventId: this.eventId
							});
							index++;
							addedCameras.push(camera.objectId);
							return index > cellCount;
						}
					});
				}
			}

			await this.$nextTick()

			//Set area cameras
			if (this.areaCameras && this.areaCameras.length > 0 && index <= cellCount) {
				this.areaCameras.filter(c => !this.getMatrixAssignedCameras.includes(c.objectID)).some((camera: CameraType) => {
					if(!addedCameras.includes(camera.objectID)) {
						this.setPushContents({
							index: index,
							newContents: {
								camera: {
									objectID: camera.objectID,
									title: camera.title
								}
							},
							eventId: this.eventId
						});
						index++;
						addedCameras.push(camera.objectID);
						return index > cellCount;
					}
				});
			}

		} else if (newClips && newClips.length > 0) {
			let takenIndexes = new Set<number>();

			let matrixClips = this.getMatrixClipsSet();

			newClips.forEach(clip => {
				if (matrixClips.has(clip.UniqueFileIdentifier)){
					return;
				}

				let emptyIndex = 1;
				let finalIndex = this.layoutHorizontal * this.layoutVertical;
				while (emptyIndex <= finalIndex) {
					let cellContents = this.getMatrixContents(emptyIndex);
					if (cellContents == null && !takenIndexes.has(emptyIndex)) {
						break;
					} else {
						emptyIndex++;
					}
				}

				if (emptyIndex > finalIndex) {
					finalIndex = this.layoutHorizontal * this.layoutVertical;
					while (finalIndex > 0) {
						let cellContents = this.getMatrixContents(finalIndex);
						if ((cellContents == null || (cellContents.clip == null && cellContents.camera == null)) && !takenIndexes.has(finalIndex)) {
							break;
						} else {
							finalIndex--;
						}
					}
				} else {
					finalIndex = emptyIndex;
				}

				if (finalIndex > 0) {
					takenIndexes.add(finalIndex);

					this.setPushContents({
						index: finalIndex,
						newContents: {
							clip: {
								eventRecordID: clip.EventRecordId,
								clip: clip
							}
						},
						eventId: this.eventId
					});
				}
			});
		}
	}

	public cameraSelect(camera: CameraType | NearbyCameraType) {
		if (this.isController) {
			this.setAwaitingCamera(camera);
		}
	}

	public clipSelect(clip: any) {
		if (this.isController && clip?.EventRecordId) {
			this.setAwaitingClip({
				eventRecordID: clip.EventRecordId,
				clip: clip
			});
		}
	}

	public get layoutHorizontal() {
		return this.layouts[this.layoutIndex][0];
	}

	public get layoutVertical() {
		return this.layouts[this.layoutIndex][1];
	}

	public cameraIconColor(camera: any, i: number, j: number) {
		return (i - 1) * this.layoutHorizontal + j === camera.index ? "redCameraIndicator" : "greyCameraIndicator";
	}

	public isSelectedCamera(camera: CameraType) {
		return camera.index !== null && !isNaN(camera.index) ? "selectedCamera" : "";
	}

	public pulseOutput(output: any) {
		output.controlRequest = true;
	}

	public async doPulseOutput(output: any, outputList: string) {
		if (output.controlReason == "") return;

		if (output.sendingCommand) return;

		this.setActivity();

		output.sendingCommand = true;

		const device = await api.deviceAction(
			output.objectId,
			{
				eventId: this.eventId,
				deviceAction: "pulse",
				outputReason: output.controlReason
			}
		);

		let auth = this.getSession(SessionResource.DeviceServiceSession);
		let deviceService = new DeviceService(api.getDeviceServiceAddress(), device.deviceServerEndpoint);

		deviceService.sendOutputCmd(auth, output.objectId, "output", "pulse").then(
			response => {
				output.controlRequest = false;
				output.controlReason = "";
				output.sendingCommand = false;
			},
			error => {
				console.error(error);
				if (error) {
					output.controlError = error.statusText;
				} else {
					output.controlError = "Error triggering relay";
				}
				output.sendingCommand = false;
			}
		);
	}

	public cancelPulse(output: any) {
		output.controlRequest = false;
		output.controlReason = "";
	}

	public async setOutput(output: any, on: boolean) {
		const action = on ? "on" : "off";

		const device = await api.deviceAction(
			output.objectId,
			{
				eventId: this.eventId,
				deviceAction: "pulse",
				outputReason: output.controlReason
			}
		);

		const auth = this.getSession(SessionResource.DeviceServiceSession);
		const deviceService = new DeviceService(api.getDeviceServiceAddress(), device.deviceServerEndpoint);

		deviceService.sendOutputCmd(auth, output.objectId, "output", action).then(
			response => {
				output.controlRequest = false;
				output.controlReason = "";
				output.sendingCommand = false;
			},
			error => {
				console.error(error);
				if (error) {
					output.controlError = error.statusText;
				} else {
					output.controlError = "Error triggering relay";
				}
				output.sendingCommand = false;
			}
		);
	}

	private async findCameras(query: any, reset: boolean) {
		this.isLoading = true;
		let groupID = this.getEventDetails.groupID;
		let page = this.pageNumber;
		await this.searchForCameras({ query, groupID, page, reset });
		this.setSearchCameraIndexes();
		this.isLoading = false;
	}

	private async findOutputs(query: string) {
		this.isLoading = true;
		let groupID = this.getEventDetails.groupID;
		await this.searchForOutputs({ query, groupID });
		this.isLoading = false;
	}

	private setSearchCameraIndexes() {
		let deviceIndexes = {};
		let maxtrixContents = this.getAllMatrixContents;

		for (var matrixIndex in maxtrixContents) {
			if (maxtrixContents[matrixIndex] != null && maxtrixContents[matrixIndex].camera != null) {
				deviceIndexes[maxtrixContents[matrixIndex].camera.objectID] = parseInt(matrixIndex);
			}
		}

		this.searchedCameras.forEach(camera => {
			camera.index = deviceIndexes[camera.objectID] != null ? deviceIndexes[camera.objectID] : null;
		});
	}

	public async openArea(groupID: number) {
		const openIfMatch = async (node: SiteTreeNode, groupID: number) => {
			if (node.group.groupID == groupID) {
				if (node.isOpen || !node.group.selected) {
					this.setSiteTreeNodeValues({
						treeNode: node,
						isOpen: false,
						selected: true
					});
				}

				if (node.subGroups && node.subGroups.length > 0) {
					const loadPromises = node.subGroups.map(sn => openIfMatch(sn, groupID));
					await Promise.all(loadPromises);
				}

				if (!node.loadingContents && node.contents == null) {
					this.setSiteTreeNodeValues({
						treeNode: node,
						loadingContents: true,
						contentsOpen: true
					});

					await this.fetchAreaCameras({ groupID: node.group.groupID, pageNumber: this.pageNumber, paginated: true });

					this.setSiteTreeNodeValues({
						treeNode: node,
						contents: {
							cameras: this.areaCameras
						},
						loadingContents: false
					});
				}

				return true;
			} else {
				let matchesChild: boolean = false;

				if (node.subGroups && node.subGroups.length > 0) {
					node.subGroups.forEach(async (subnode: SiteTreeNode) => {
						let match = await openIfMatch(subnode, groupID);
						if (match) {
							matchesChild = match;
						}
					});
				}

				if (node.isOpen != matchesChild || node.group.selected) {
					this.setSiteTreeNodeValues({
						treeNode: node,
						isOpen: matchesChild,
						selected: false
					});
				}

				return matchesChild;
			}
		};

		const openPromises = this.tree.map(n => openIfMatch(n, groupID));
		await Promise.all(openPromises);

		this.scrollToAreaScheduled = true;
	}

	public async areaSelected(selectedAreaNode: SelectedAreaNode): Promise<void> {
		this.selectedArea = selectedAreaNode;

		if (selectedAreaNode && selectedAreaNode.id) {

			this.isLoadingAreaCameras = true;

			await this.fetchAreaCameras({ groupID: selectedAreaNode.id, pageNumber: this.pageNumber, paginated: true });

			this.selectedArea.cameras = this.areaCameras;

			this.isLoadingAreaCameras = false;
		} else {
			await this.resetCameraState();
		}
	}

	public showAreaCamera(camera: any, treeSelectCamera: boolean): void {
		if (this.isController) {
			this.setAwaitingCamera({
				objectID: treeSelectCamera ? camera.objectID : camera.deviceID,
				itemType: 1,
				title: camera.title
			});
		}
	}

	private setTourName(data: any) {
		this.tourName = data;
	}

	private async toggleAreasSection() {
		this.open.areas = !this.open.areas;
		if (this.open.areas) {
			try {
				this.isLoadingAreas = true;
				await this.openArea(this.getEventDetails.groupID);
				this.isLoadingAreas = false;
			}
			catch (ex) {
				console.log("Unexpected error loading site tree: " + ex);
			}
			finally {
				this.isLoadingAreas = false;
			}
		}
	}

	// Increasing the pageNumber will trigger the watcher on pageNumber
	public showMoreSearchCameras(): void {
		this.isAreaSearch = false;
		this.pageNumber++;
	}

	public showMoreAreaCameras(): void {
		this.isAreaSearch = true;
		this.pageNumber++;
	}

	public resetCameraPageNumber(): void {
		this.tourEnded = true;
	}

	public completeCameraReset(): void {
		this.tourEnded = false;
	}

	private get showSearchLoadMoreButton(): boolean {
		return this.searchedCameras.length < this.searchedCameraCount;
	}

	private get showAreaLoadMoreButton(): boolean {
		return this.areaCameras.length < this.areaCameraCount;
	}
}
