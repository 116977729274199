
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import api from '@/services/api.service';
import EventUser from '@/types/sv-data/events/EventUser';
import { email } from 'vuelidate/lib/validators';

const Mobile = namespace("mobile");

@Component({
   components:{}
})
export default class MobileEventRecords extends Vue {
   @Mobile.State("isRightSideBarOpen") private rightSideBarOpen: boolean;

   @Prop()
   private eventID: number;

   private eventRecords: any[] = [];
   private eventUsers: EventUser[] = [];
   private loading: boolean = false;
   private recordPoll: any = null;

   private async mounted(){
      this.loading = true;
      this.loadEvents();
      if(this.rightSideBarOpen){
         this.recordPoll = setInterval(async () => this.loadEvents(), 5000);
      }
      this.loading = false;
   }

   private async loadEvents() : Promise<void>{
      this.eventRecords = await api.loadEventRecordsForMobile(this.eventID);
      this.eventUsers = await api.getEventUsersForEvent(this.eventID);
   }

   @Watch("eventRecords")
   private eventRecordsWatch(newRecords: any[] | null, oldRecords: any[] | null){
      if(newRecords && oldRecords && oldRecords.length != 0 && oldRecords.length != newRecords.length){
         this.$emit("newRecords", newRecords.length - oldRecords.length);
      }
   }

   @Watch("rightSideBarOpen")
   public async sideBarWatcher(){
      // To ensure we don't nuke the app with loading the events only allow polling when the banner is open
      if(this.rightSideBarOpen){
         this.loadEvents();
         this.recordPoll = setInterval(async () => this.loadEvents(), 5000);
      } else {
         clearInterval(this.recordPoll);
      }
   }

   private getRaisedBy(eventRecord: any) {
      if(eventRecord.eventUserID){
         const eventUser = this.eventUsers.find(x => x.eventUserID === eventRecord.eventUserID);
         if(eventUser)
            return eventUser.name;
         return ""
      }

      return "";
   }

   private beforeDestroy() {
		clearInterval(this.recordPoll);
	}
}
