
	import { Component, Vue } from "vue-property-decorator";
	import { Action, namespace, Getter } from "vuex-class";
	import { AuthenticationStatus, LoginResult, FeaturesList } from "@/store/types";

	const UserContext = namespace("userContext");

@Component({
	data() { return { AuthenticationStatus } }
})
export default class LoginSaml extends Vue {

	private error: string = "";
	private authenticationState: AuthenticationStatus = AuthenticationStatus.None;

	@Action("getLoginSession") private getLoginSession: () => Promise<LoginResult>;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	
	@Action startApiHeartbeat: any;
	@Action getElevationLabels: () => Promise<void>;

	@UserContext.Action("fetchOverview") fetchOverviewUserContext: () => Promise<void>;

	private get isSSOLoginEnabled() {
		return process.env.VUE_APP_AUTHENTICATION_SAML_ENABLED === "true";
	}

	private async beforeCreated() {
		if (!this.isSSOLoginEnabled) {
			this.$router.push("/login");
		}
	}

	private async created() {
		try {

			let loginResult = await this.getLoginSession();
			let redirectPath = "/event-queue";
			
			const storedRedirectUrl = this.GetRedirectCookie();
			
			if (storedRedirectUrl) {
				let convertedPath = decodeURIComponent(await storedRedirectUrl);
				if(convertedPath !== "login" && !convertedPath.includes("login")){
					redirectPath = convertedPath;
				}
			}

			switch (loginResult.authenticationStatus) {
				case AuthenticationStatus.Success:
					//If we have a redirect route and we aren't on mobile use that route
					if (loginResult.redirectRoute) {
						redirectPath = loginResult.redirectRoute;
					}

					this.startApiHeartbeat();

					// dont await this as it could take a while to return
					this.fetchOverviewUserContext();

					this.$router.push(redirectPath);

					await this.getElevationLabels();
					break;

				case AuthenticationStatus.DisabledUser:
					this.error = "Your account has been disabled.";
					break;

				case AuthenticationStatus.LockedOut:
					this.error = "You have been locked out due to too many password attempts.";
					return;

				case AuthenticationStatus.PasswordExpired:
					this.error = "Your account credentials are expired.";
					break;

				case AuthenticationStatus.ConfigureMfa:
					this.authenticationState = AuthenticationStatus.ConfigureMfa;
					return;
			}
		} catch (ex) {
			throw ex;
		}
	}

	private async GetRedirectCookie() {
		var match = document.cookie.match(new RegExp("(^| )" + "SsoRedirect" + "=([^;]+)"));
		if (match) {
			return match[2];
		}
	}
}
