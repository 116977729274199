
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ActionPlanTask } from "@/store/tasks/types";

import api from "@/services/api.service";
import vSelect from "vue-select";
import { CaseData, CaseType } from "@/store/case-management/types";
import { stringMixin } from "@/mixins";

const { isNullOrWhitespace } = stringMixin.methods;

const CaseManagement = namespace("caseManagement");

@Component({
	components: {
		"vue-select": vSelect,
	},
})
export default class RaiseToCasesTask extends Vue {
	@CaseManagement.State((state) => state.caseTypes) caseTypes: CaseType[];

	@Prop(Boolean) public editing: Boolean;

	@Prop()
	public task!: ActionPlanTask;

	public raisingToCases: boolean = false;
	public selectedCaseType: any = null;
	public caseData: CaseData = {
		EventId: null,
		TemplateId: null,
		Summary: null,
	};

	public mounted() {
		this.$nextTick(() => {
			if (this.editing) {
				this.onValidateTask();
			}
		});
		this.task.taskText = "Raise to case management";
	}

	/**
	 * Gets the event ID for the current event, based on our route.
	 */
	public get eventId() {
		return parseInt(this.$route.params.eventId);
	}

	/**
	 * Task by default is always validated as it requires no initial editing.
	 */
	@Emit("validate-task")
	public onValidateTask() {
		return true;
	}

	/**
	 * Method to raise event to case management system.
	 */
	private async raiseToCaseManagement() {
		// When case type is not selected and summary is not entered, dont run method.
		if (this.selectedCaseType == null || isNullOrWhitespace(this.caseData.Summary)) {
			return;
		}

		// Set loading icon to show.
		this.raisingToCases = true;
		// Update data.
		this.caseData.EventId = this.eventId;
		this.caseData.TemplateId = this.selectedCaseType.id;

		this.task.taskData = {
			Template: this.selectedCaseType.title,
		};

		try {
			// Raise the event to case management.
			await api.raiseToCases(this.caseData);
			// No exception thrown, emit that the task is completed.
			this.$emit("complete-task", {
				...this.task,
				result: this.selectedCaseType.title,
				completed: true,
			});
		} catch (ex) {
			// Notify user with response exception msg in data property.
			this.$notify({
				type: "error",
				title: "Error",
				text: ex.response.data,
			});
		} finally {
			// In all cases, hide loading icon.
			this.raisingToCases = false;
		}
	}
}
