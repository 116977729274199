
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from 'vuex-class';

const SiteMonitorCameras = namespace('siteMonitorCameras');

@Component({
    components: {
    }
})
export default class LinkingCamera extends Vue {

    isDestroyed = false
    hide: boolean =  true

    @SiteMonitorCameras.Getter("getLinkRecord") linkRecord: any;
    @SiteMonitorCameras.Mutation setLinkRecord: any;
    
    public cancelLink() {
        this.setLinkRecord(null);
    }
}

