/**
 * @license
 *
 * Copyright 2011 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Map Label.
 *
 * @author Luke Mahe (lukem@google.com),
 *         Chris Broadfoot (cbro@google.com)
 * https://github.com/googlemaps/js-map-label/
 */

import {} from "googlemaps";

export default class MapLabel extends google.maps.OverlayView {
	public canvas: HTMLCanvasElement | null = null;

	constructor(options: any) {
		super();
		this.set("fontFamily", "sans-serif");
		this.set("fontSize", 8);
		this.set("fontColor", "#d13935");
		this.set("strokeWeight", 4);
		this.set("strokeColor", "#ffffff");
		this.set("align", "center");
		this.set("zIndex", 1e3);
		this.setValues(options);
	}

	public changed(prop: string) {
		switch (prop) {
			case "fontFamily":
			case "fontSize":
			case "fontColor":
			case "strokeWeight":
			case "strokeColor":
			case "align":
			case "text":
				return this.drawCanvas_();
			case "maxZoom":
			case "minZoom":
			case "position":
				return this.draw();
		}
	}

	public onAdd() {
		const canvas = (this.canvas = document.createElement("canvas"));
		const style = canvas.style;
		style.position = "absolute";

		const ctx = canvas.getContext("2d");
		ctx!.lineJoin = "round";
		ctx!.textBaseline = "top";

		this.drawCanvas_();

		const panes = this.getPanes();
		if (panes) {
			panes.mapPane.appendChild(canvas);
		}
	}

	public draw() {
		const projection = this.getProjection();

		if (!projection) {
			// The map projection is not ready yet so do nothing
			return;
		}

		if (!this.canvas) {
			// onAdd has not been called yet.
			return;
		}

		const latLng: google.maps.LatLng = this.get("position");
		if (!latLng) {
			return;
		}
		const pos = projection.fromLatLngToDivPixel(latLng);
		const style = this.canvas.style;

		style["top"] = pos.y + "px";
		style["left"] = pos.x + "px";
		style["visibility"] = this.getVisible_();
	}

	public onRemove() {
		const canvas = this.canvas;
		if (canvas && canvas.parentNode) {
			canvas.parentNode.removeChild(canvas);
		}
	}

	private drawCanvas_() {
		const canvas = this.canvas;
		if (!canvas) {
			return;
		}
		const style = canvas.style;
		style.zIndex = this.get("zIndex");

		const ctx = canvas.getContext("2d");
		ctx!.clearRect(0, 0, canvas.width, canvas.height);
		ctx!.strokeStyle = this.get("strokeColor");
		ctx!.fillStyle = this.get("fontColor");
		ctx!.font = this.get("fontSize") + "px " + this.get("fontFamily");

		const strokeWeight = Number(this.get("strokeWeight"));

		const text = this.get("text");
		if (text) {
			if (strokeWeight) {
				ctx!.lineWidth = strokeWeight;
				ctx!.strokeText(text, strokeWeight, strokeWeight);
			}

			ctx!.fillText(text, strokeWeight, strokeWeight);

			const textMeasure = ctx!.measureText(text);
			const textWidth = textMeasure.width + strokeWeight;
			style.marginLeft = this.getMarginLeft_(textWidth) + "px";
			// Bring actual text top in line with desired latitude.
			// Cheaper than calculating height of text.
			style.marginTop = "-1.5em";
		}
	}

	private getMarginLeft_(textWidth: number) {
		switch (this.get("align")) {
			case "left":
				return 0;
			case "right":
				return -textWidth;
		}
		return textWidth / -2;
	}

	private getVisible_() {
		const minZoom: number = this.get("minZoom");
		const maxZoom: number = this.get("maxZoom");

		if (minZoom === undefined && maxZoom === undefined) {
			return "";
		}

		const map = this.getMap();
		if (!map) {
			return "";
		}

		const mapZoom = map.getZoom();
		if (mapZoom < minZoom || mapZoom > maxZoom) {
			return "hidden";
		}
		return "";
	}
}
