import { GetterTree } from "vuex";
import { ReportState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<ReportState, RootState> = {
	getAvailableReports: state => state.AvailableReports,
	getReport: state => state.Report,
	getCurrentReportUrl: state => state.CurrentReportUrl,
	getResultCountNumber: state => state.ResultCount,
	getReportLoadingStatus: state => state.ReportLoadingStatus,
	getReportErrorStatus: state => state.ReportErrorStatus,
	getReportApiEndpoint: state => state.ReportApiEndpoint,
	getReportTypes: state => state.ReportTypes
};
