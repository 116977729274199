/**
 * Converts the given meters into miles
 * @param meters The meters to convert
 * @param decimalPlaces The number of decimal places; defaults to 4
 */
export function convertMetersToMiles(meters: number, decimalPlaces: number): string {
    const milesMultiplier: number = 0.000621371;
    const miles = meters * milesMultiplier;
    return miles.toFixed(decimalPlaces);
}
