
import { Component, Prop, Watch, Vue, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import vSelect from "vue-select";
import { validationMixin } from "vuelidate";
import {
	required,
	requiredIf,
	minLength,
	numeric
} from "vuelidate/lib/validators";
import { renderChoiceEnum } from "@/models/Tasks/renderType";
import { ActionPlanTask } from "../../../store/tasks/types";

const Tasks = namespace("tasks");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		"vue-select": vSelect
	},
	mixins: [validationMixin],
	validations: {
		choicesData: {
			choiceDescription: {
				required
			},
			choiceOptions: {
				required,
				minLength: minLength(2),
				$each: {
					required
				}
			}
		}
	}
})
export default class ChoicesTaskSetup extends Vue {
	@Prop() public value!: ActionPlanTask;

	private choiceListTypes: string[] = [];

	public choicesData: any = {
		choiceDescription: "",
		choiceOptions: ["", ""],
		selectedChoice: renderChoiceEnum.Listbox
	};

	public mounted() {
		// Get types from Enum and put them within choicesList string array. Couldn't directly use enum within template
		for (let item in renderChoiceEnum) {
			this.choiceListTypes.push(item);
		}

		this.loadFromTask();
		this.onValidationChange(this.$v.$invalid);
	}

	/** Watcher, when data changes within 'ChoicesData' call 'ChoicesSettingsChange'*/
	@Watch("choicesData", { deep: true, immediate: true })
	public onChoicesSettingsChange(newValue) {
		this.$v.$touch();

		if (!this.$v.$error) {
			this.onInput();
		}
	}

	/**
	 * Fires an input event containing the new task.
	 */
	@Emit("input")
	private onInput() {
		return {
			...this.value,
			taskText: this.choicesData.choiceDescription,
			taskData: {
				...this.choicesData
			}
		};
	}

	/**
	 * Watcher/emitter for the validation state of this component,
	 * bubbles up vuelidate's validation state when it changes.
	 */
	@Watch("$v.$invalid")
	private onValidationChange($invalid: boolean) {
		this.$emit("validate-task", !$invalid);
	}

	/**
	 * Loads the choice data from the current task
	 */
	private loadFromTask() {
		if (
			this.value &&
			this.value.taskData !== null &&
			this.value.taskData !== undefined
		) {
			this.choicesData = {
				...this.value.taskData
			};
		}
	}
}
