
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { CardValidation, RuleGenericDropdownItem } from "@/store/rules-engine/types";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import RuleGenericDropdown from "@/components/rules-engine-setup/data-components/RuleGenericDropdown.vue";
import { namespace } from "vuex-class";
import api from "@/services/api.service";
import { clone } from 'lodash';

const RuleStore = namespace("rulesEngine");

@Component({
	components: {
		"area-tree-select": AreaTreeSelect,
		"rule-generic-dropdown": RuleGenericDropdown
	}
})
export default class RulesAlarmDropdown extends Vue {
	@RuleStore.Action private retrieveRuleAlarmsForGroupId: (groupId: number) => Promise<void>;
	@RuleStore.State private alarmsForGroupId: Map<number, RuleGenericDropdownItem[]>;

	@Prop({type: Boolean})
	private allowNulls: boolean;

	@Prop({type: Boolean})
	private readOnly: boolean;

	@Prop({type: [String, Number]})
	private value: number | string;

	private groupIdInput: AreaNode = null;

	private responseId: number = null;

	private inputValidation: CardValidation = { isValid: false, message: "" };

	private get inputValid(): boolean {
		if ((this.allowNulls && this.responseId?.toString().length < 1) || this.groupIdInput == null) {
			return true;
		} else {
			return !!this.responseId;
		}
	}

	private mounted(): void {
		this.responseId = this.checkValueType();
		this.validateInput();
		this.configureExistingSelection();
	}

	private checkValueType(): number {
		if (typeof this.value === "string") {
			return parseInt(this.value);
		} else {
			return clone(this.value);
		}
	}

	private async configureExistingSelection(): Promise<void> {
		this.groupIdInput = null;

		if (this.value) {
			const data = await api.responseGet(this.value as number);
			let area: AreaNode = {
				id: data.groupID,
				label: data.groupTitle
			}
			await this.retrieveRuleAlarmsForGroupId(area.id);
			this.groupIdInput = area;
			this.responseId = clone(this.value as number);
		}
	}

	@Watch("responseId")
    @Emit("input")
    private responseValueUpdated(value: RuleGenericDropdownItem): number
    {
		this.validateInput();
        return value?.id;
    }

	@Watch("areaSelected")
    private async valueUpdated(newValue: number, oldValue: number): Promise<void>
    {
		if (!!oldValue && newValue != oldValue) {
			this.responseId = null;
		}

		if (newValue) {
			await this.retrieveRuleAlarmsForGroupId(newValue);
			this.$forceUpdate();
			this.validateInput();
		} else {
			this.responseId = null;
		}
    }

	private get areaSelected(): number {
		return this.groupIdInput?.id;
	}

	@Emit("isValid")
    private validateInput(): CardValidation
    {
		if(this.inputValid){
			this.inputValidation.isValid = true;
            return this.inputValidation;
        } else {
			this.inputValidation.isValid = false;
			this.inputValidation.message = "*Field must contain a valid value";
			return this.inputValidation;
		}
	}
}
