
import { AreaMaskingCount } from "@/store/areas/types";
import { Component, Vue, Watch, Emit, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Areas = namespace("areas");

@Component
export default class MaskingModalGroupBadge extends Vue {

	@Prop({ default: false }) private disabled: boolean;
	@Prop({ default: "" }) private modalGroupFilter: string;
	@Prop({ default: false }) private isModalShown: boolean;

	@Areas.State areaMaskingCount: AreaMaskingCount;

	private selectedFilter: string = "";

	@Watch("selectedFilter")
	private onGroupFilterChanged(): void {
		if (this.selectedFilter !== "") {
			this.emitBadgeSelected();
		}
	}

	@Watch("modalGroupFilter")
	private onModalGroupFilter(): void {
		if (this.selectedFilter !== this.modalGroupFilter) {
			this.selectedFilter = this.modalGroupFilter;
		}
	}

	@Emit("badgeSelected")
	private emitBadgeSelected(): string {
		return this.selectedFilter;
	}
}
