import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { SubscriptionState } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<SubscriptionState, RootState> = {
	async fetchSubscription({ commit }, tenantGroupId: number) {
		let subscriptionDetailsDto = await api.getSubscription(tenantGroupId);
		commit("setSubscriptionDetails", subscriptionDetailsDto);
	},
	async reloadSubscription({ commit }) {
		let subscriptionDetailsDto = await api.reloadSubscription();
		commit("setSubscriptionDetails", subscriptionDetailsDto);
	},
	async fetchBilledUserPermissions({ commit }) {
		let billedUserPermissions = await api.getBilledUserPermissions();
		commit("setBilledUserPermissions", billedUserPermissions);
	},
	async updateSubscription({ commit }, subscriptionUpdateRequest: []) {
		let subscriptionDetailsDto = await api.updateSubscription(subscriptionUpdateRequest);
		commit("setSubscriptionDetails", subscriptionDetailsDto);
	}
};
