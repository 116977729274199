import { PollingStatus } from "@/types/PollingStatus";

export interface AreaState {
	AreaList: GroupWithPermissions[];
	areaDictionary: Map<number, string>;
	areaMaskingCount: AreaMaskingCount;
	areaMaskingCountPollingStatus: PollingStatus;
	flatAreaList: FlatAreaList;
}

export interface Group {
	address: string | null;
	armed: boolean;
	disabledAt: Date | null;
	disarmReasonID: number | null;
	groupID: number;
	groupTypeID: number;
	licenseTypeID: number;
	licenseValid: boolean;
	notes: string;
	onTest: boolean;
	parentGroupID: number | null;
	telephone: string;
	telephone2: string;
	telephoneFire: string;
	telephonePolice: string;
	title: string;
	timeZoneID: number;
	toggleArmAt: Date | null;
	latLong: string;
}

// Deprecated.
export interface GroupWithPermissions extends Group {
	hasChildren: boolean;
	isOpen: boolean;
	permissions: any[] | null;
}

export interface Tenant extends Group {
	isUsersTenant: boolean;
}

export interface AreaMaskingCount {
	totalGroups: number;
	masked: number;
	partiallyMasked:number;
	unmasked: number;
}

export interface GroupWithState {
	groupId: number;
	title: string;
	armed: boolean;
	state: string;
}

export interface FlatAreaList {
	totalAvailableGroups: number,
	groupsWithState: GroupWithState[]
}

export const defaultFlatAreaList = (): FlatAreaList => {
	return {
		totalAvailableGroups: null,
		groupsWithState: Array<GroupWithState>()
	}
}

export interface FlatAreaListRequest {
	page: number,
	pageSize: number,
	searchTerm: string,
	filter: string
}
