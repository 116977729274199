import { RootState } from "./types";
import { get } from "lodash";

export const getters = {
	isLoggedIn: (state: RootState) => state.User.loggedIn,
	isAppInitialised: (state: RootState) => state.appInitialised,
	getUserName: (state: RootState) => state.User.fullName,
	getUserEmail: (state: RootState) => state.User.username,
	getLoginError: (state: RootState) => state.User.error,
	getDataError: (state: RootState) => state.dataError,
	getShortcuts: (state: RootState) => state.keyboardShortcuts,
	getLegendKeys: (state: RootState) => state.legendKeys,
	isFullUser: (state: RootState) => state.User.isFullUser,
	getTimeZoneAbbr: (state: RootState) => state.timeZoneAbbr,
	getUserGroups: (state: RootState) => state.UserGroups,
	getShowRoutingGroupsLegend: (state: RootState) => state.showRoutingGroupsLegend,
	getPermissions: (state: RootState) => state.User.userPermissions,
	getUseImperialMeasurements: (state: RootState) => state.User.userPrefs.useImperialMeasurements,
	getMapType: (state: RootState) => state.systemPrefs.mapType,
	getMapStyle: (state: RootState) => state.mobilePrefs.mapStyle,
	getHideLabels: (state: RootState) => state.systemPrefs.hideLabels,
	getRequireMapPin: (state: RootState) => state.systemPrefs.requireMapPin,
	getRoutingGroups: (state: RootState) => state.routingGroups,
	getAlarmScreenTimeoutSeconds: (state: RootState) => state.systemPrefs.alarmScreenTimeoutSeconds,
	getAlarmAgeWarningSeconds: (state: RootState) => state.tenantSystemPrefs.alarmAgeWarningSeconds,
	getActiveRegion: (state: RootState) => state.activeRegion,
	getVersion: (state: RootState) => state.appOptions?.version,
	getMapKey: (state: RootState) => process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
	getFeaturesList: (state: RootState) => state.featuresList,
	getFeature: state => (featureNames: string[]) => {
		return get(state.featuresList, featureNames, false);
	},
	getUserId: (state: RootState) => state.User.userId,
	getUserGroupId: (state: RootState) => state.User.userGroupId,
	getUserTenantGroupId: (state: RootState) => state.User.tenantGroupId,
	getUserSuiteTenantId:(state: RootState) => state.User.suiteTenantId,
	getMaskMaxHours: (state: RootState) => {
		if (state.User.userPermissions.canDisarmSitesExtended) {
			return 72;
		} else if (state.User.userPermissions.canDisarmSites) {
			return 24;
		}
	},
	getIsFieldOpsLicensed: (state: RootState) => {
		return !state.User ||
			(state.User && (!state.User.suiteUserId || (state.User.suiteUserId && state.User.mobileAppEnabled)));
	},
	getIsResponseLicensed: (state: RootState) => {
		return !state.User ||
		(state.User && (!state.User.suiteUserId || (state.User.suiteUserId && state.User.suiteResponseLicenced)));
	},
	getFieldOpsLicencedRoutes: (state: RootState) => {
		// Routes that can be accessed with just FieldOps Licence
		return ["assetSetup", "field-ops", "users", "map-setup", "login", "loginSaml", "mobile", "areas", "contacts"];
	},
	getFieldOpsOnlyRoutes: (state: RootState) => {
		// Routes which require the FieldOps Licence
		return ["assetSetup", "field-ops", "mobile"];
	},
	getFieldOpsAndResponseLicencedRoutes: (state: RootState) =>
	{
		// Routes which require both the FieldOps and Response Licence
		return ["mobile-raise-setup"]
	},
	getTimeZones: (state: RootState) => state.timeZones,
	getCanAddIndividualActionsOverride: (state: RootState) => state.systemPrefs.canUsersAddIndividualActions,
	getDynamicLinkConfig: (state: RootState) => state.User.dynamicLinkConfig,
	isSessionExpiryAudioSoundEnabled: (state: RootState) => state.featuresList?.Alarms?.SessionExpiryAudioSound,
	getIsCasesLicenced: (state: RootState) =>
	{
		return !state.User || (state.User && (!state.User.suiteUserId || (state.User.suiteUserId && state.User.suiteCasesLicenced)));
	},
	getIsOnPrem: (state: RootState) => {
		return state.appOptions == null ? null : state.appOptions.deployed === "OnPrem";
	},
	getRequiresConfiguration: (state: RootState) => state.requiresConfiguration,
	getDefaultMaskValue: (state: RootState) => state.tenantSystemPrefs.defaultMaskValue,
	getDefaultParkValue: (state: RootState) => state.tenantSystemPrefs.defaultParkValue,
	getTimeZone: state => (timeZoneId: number) => state.timeZones?.find(item => item.timeZoneID === timeZoneId),
	getDefaultMaskAction: (state: RootState) => state.tenantSystemPrefs.defaultMaskAction,
};
