import { MutationTree } from "vuex";
import { EmailData } from "./types";

export const mutations: MutationTree<EmailData> = {
	putEmail(state, { name, formattedData }) {
		state.emailsStorage = {
			...state.emailsStorage,
			[name]: formattedData,
		}
	},
	setEventRecordIdToShowEmailFor(state, eventRecordIdToShowEventEmailFor:number) {
		state.eventRecordIdToShowEmailFor = eventRecordIdToShowEventEmailFor;
	}
};
