
import { Component, Vue, Watch } from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { namespace } from "vuex-class";
import api from "../../services/api.service";

const AlarmPoints = namespace("alarmPoints");

@Component({
	components: {
		VuePerfectScrollbar
	}
})
export default class AccessControlHistory extends Vue {
	$refs!: {
		accessHistory: any;
	};

	public fetchingHistory: boolean = false;
	public fetchingHistoryFailed: boolean = false;
	public historyItems: any[] = [];

	public filter: string = "";

	@AlarmPoints.State("selectedResponse") response;

	public mounted(): void {
		if (this.response != null) {
			this.getHistory(this.response.responseID);
		}
	}

	public get filteredHistory(): any[] {
		if (this.filter == "") return this.historyItems;

		var filterLower = this.filter.toLowerCase();

		return this.historyItems.filter(
			historyItem => historyItem.text.toLowerCase().indexOf(filterLower) > -1
		);
	}

	@Watch("response")
	public async onResponseChanged(value: any, oldValue: any) {
		if (value && value.canGetHistoryLatest) {
			this.getHistory(value.responseID);
		} else {
			this.historyItems = [];
		}
	}

	public async getHistory(responseID): Promise<void> {
		this.fetchingHistory = true;
		this.fetchingHistoryFailed = false;

		try {
			var history = await api.accessControlHistory(responseID);

			this.historyItems = history;
			this.fetchingHistory = false;
		} catch (err) {
			this.fetchingHistoryFailed = true;
			this.fetchingHistory = false;
		}
	}
}
