import { render, staticRenderFns } from "./EventEnded.vue?vue&type=template&id=01d6c498&scoped=true"
import script from "./EventEnded.vue?vue&type=script&lang=ts"
export * from "./EventEnded.vue?vue&type=script&lang=ts"
import style0 from "./EventEnded.vue?vue&type=style&index=0&id=01d6c498&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01d6c498",
  null
  
)

export default component.exports