
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import "@/scripts/string-operations";
import vSelect from "vue-select";
import ReportFilters from "./ReportFilters.vue";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";

interface TimeRangeHours {
	range: string,
	hours: number
}

const Reports = namespace("reports");
@Component({
	components: {
		"vue-select": vSelect,
		"report-filters": ReportFilters
	}
})
export default class TemplateReportBuilder extends Vue {
	@Reports.Getter("getReport") report: any;
	@Reports.Getter("getReportApiEndpoint") reportApiEndpoint: string;
	@Reports.Action getReportUrl: (request: { templateId: number; selectedFilters: any; }) => Promise<string>;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	// variables

	private timeRanges: TimeRangeHours[] = [
		{
			range: "4 Hours",
			hours: 4
		},
		{
			range: "8 Hours",
			hours: 8
		},
		{
			range: "12 Hours",
			hours: 12
		},
		{
			range: "1 Day",
			hours: 24
		},
		{
			range: "7 Days",
			hours: 168
		},
		{
			range: "14 Days",
			hours: 336
		},
		{
			range: "30 Days",
			hours: 720
		},
		{
			range: "60 Days",
			hours: 1440
		},
		{
			range: "90 Days",
			hours: 2160
		},
		{
			range: "180 Days",
			hours: 4320
		},
		{
			range: "365 Days",
			hours: 8760
		},
	];

	private selectedTemplate: any = null;
	private timeZone: string = "UTC";
	private selectedTimeRange: any = null;
	private settingTimeRange: boolean = false;

	// Props
	@Prop()
	public value: any;

	// Private methods

	private mounted(): void {
		this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	private async reportUrl(endpoint: string): Promise<string> {
		const selectedFilters = this.value;
		return this.reportApiEndpoint.trimEndOf(["/"]) + "/" +
			"Reports/" + endpoint +
			await this.getReportUrl({
				templateId: this.template.reportTemplateID,
				selectedFilters
			}) + "&timeZone=" + this.timeZone;
	}

	private async generateReport(isCSVReport: boolean): Promise<void> {
		if (!this.template)	{
			return;
		}

		const link = document.createElement("a");
		link.href = link.download = isCSVReport ? await this.reportUrl("DownloadReportCSV") : await this.reportUrl("DownloadReportPDF");
		
		link.click();
	}

	// Watchers

	@Watch("selectedTimeRange")
	private timeRangeUpdated(newRange: TimeRangeHours | null): void {
		if (!newRange || !this.value) {
			return;
		}

		try
		{
			this.settingTimeRange = true;
			let newStartDate = new Date();
			newStartDate.setHours(newStartDate.getHours() - newRange.hours);
			this.value.endDate = new Date().toISOString();
			this.value.startDate = newStartDate.toISOString();
		}
		catch(ex)
		{
			console.error(ex)
		}
		finally
		{
			setTimeout(() =>{ this.settingTimeRange = false; },100)

		}
	}

	@Watch("value.startDate", { deep:true })
	@Watch("value.endDate", { deep:true })
	private dateFieldUpdated(newDate: any, oldDate: any): void {
		if (!oldDate || !this.selectedTimeRange || this.settingTimeRange) {
			return;
		}

		if (newDate != oldDate) {
			this.selectedTimeRange = null;
		}
	}

	// Getters

	private get template (): any {
		if (!this.report || !this.report.templates) {
			return null;
		}

		if (this.report.templates.length == 1) {
			return this.report.templates[0];
		}
		else
		{
			return this.selectedTemplate;
		}
	}

	private get isActivityReportCsvEnabled(): boolean {
		return get(this.featuresList, ["Insights", "ActivityReportCSV"]);
	}
}
