import { MutationTree } from "vuex";
import {  CSVFormHandler } from "./types";
import { get } from "lodash"

export const mutations: MutationTree<CSVFormHandler> = {
	setErrors(state, errors) {
		state.errorsForm = {
			...state.errorsForm,
			...errors
		}
	},

	setModalItems(state, { modalItemsCsv, name }) {
		state.modalItemsCsv = {
			...state.modalItemsCsv,
			[name]: modalItemsCsv
		}
	},

	setCsvData(state, { csvData, name }) {
		state.csvData = {
			...state.csvData,
			[name]: csvData
		}
	},

	setOriginalCsvData(state, { csvData, name }) {
		state.originalCsvData = {
			...state.csvData,
			[name]: csvData
		}
	},

	resetErrorsForm(state, { name }) {
		state.errorsForm[name] = {}
	},

	setErrorsForm(state, { error, name }) {
		const { uniqId } = error;

		state.errorsForm = {
			...state.errorsForm,
			[name]: {
				...state.errorsForm[name],
				[uniqId]: error
			}
		}
	},

	deleteErrorById(state, { uniqId, name }) {
		if(!state.errorsForm[name]) {
			state.errorsForm[name] = {}
		}
		const isExist = state.errorsForm[name][uniqId];
		if (isExist) {
			state.errorsForm = {
				...state.errorsForm,
				[name]: Object.entries(state.errorsForm[name]).reduce((acc, [key, value]) => {
					return key === uniqId ? acc : { ...acc, [key]: value }
				}, {})
			};
		}
	},

	updateCsvValue(state, { value, data, name }) {
		const item = get(state.csvData, `${name}[${data.rowId}]`)

		if (item) {
			const localItem = {
				...item,
				[data.title]: value
			}
			const localData = state.csvData[name]

			localData[data.rowId] = localItem

			state.csvData = {
				...state.csvData,
				[name]: localData
			}
		}
	}
};
