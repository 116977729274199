import { GetterTree } from "vuex";
import { EventSearchState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<EventSearchState, RootState> = {
	events: state => state.Events,
	eventAlarmTypes: state => state.AlarmTypes,
	eventCategories: state => state.Categories,
	eventDeviceTypes: state => {
		var deviceTypes = !state.DeviceTypes ? [] : [...state.DeviceTypes];
		deviceTypes.unshift({ serverTypeID: 435, title: "Manual Raise" });
		return deviceTypes;
	},
	eventOperators: state => state.Operators,
	searchableEventTypes: state => state.SearchableEventTypes,
	searching: state => state.searching
};
