













import axios from "axios";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { AuditService } from "@/services/auditService";
import { SessionResource } from "@/store/sessions/types";
import mobileApi from "@/services/api.mobile.service";
import api from "@/services/api.service";
import { EventDetails } from "@/store/site-monitor/types";

const Mobile = namespace("mobile");
const SessionStore = namespace("sessions");
const SiteMonitor = namespace("siteMonitor");

const OPERATOR_NOTE_TYPE_ID = 103;

@Component
export default class UploadFileButton extends Vue {
	public cancelToken;
	public source;
	public selectedFile = null;
	public auditService: AuditService | null;

	@Prop(Number)
	public eventId: number;

	@Prop({ type: Number, default: OPERATOR_NOTE_TYPE_ID })
	public eventRecordTypeId: number;

	@Prop({type: Boolean, default: false})
	private useIconBtn: boolean;

	@SessionStore.Getter getSession: any;
	@SessionStore.Action updateSession: any;

	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getUserName") currentUserName!: string;
	@Getter("getUserId") userID: number;

	@SiteMonitor.Action private fetchEventDetails: (eventId: number) => Promise<EventDetails>;

	@Prop({type: Boolean, default: true})
	private isVisible: boolean;

	@Prop(Boolean)
	public uploadCancel: boolean;


	@Watch("isVisible", { deep: true })
	private async onIsVisibleChanged(value: boolean, oldValue: boolean) {
		await this.updateSession({ resourceId: SessionResource.AuditServiceSession });
	}

	@Watch("uploadCancel", { deep: true })
	async onUploadCancelChanged(value: boolean, oldValue: boolean) {
		this.source.cancel();

		if (value === true) {
			await mobileApi.addEventRecordForGuard(
				this.eventId,
				`${this.currentUserName} has cancelled the file upload`,
				null,
				this.eventRecordTypeId,
			);
		}
	}

	public triggerUpload() {
		var cameraInputElement = this.$refs.cameraInput as HTMLInputElement;
		cameraInputElement.click();
	}

	public async onFileSelected(event) {
		this.selectedFile = event.target.files[0];
		await this.updateSession({ resourceId: SessionResource.AuditServiceSession });
		this.uploadFile(this.updateProgressBar);

		var cameraInputElement = this.$refs.cameraInput as HTMLInputElement;
		cameraInputElement.value = null;
	}

	public updateProgressBar(prog) {
		this.emitUploadProgress(prog);
	}

	public async uploadFile(callback) {
		const eventDetails = await this.fetchEventDetails(this.eventId);

		const formData = new FormData();
		formData.append("image", this.selectedFile, this.selectedFile.name);

		const attatchedFileEventRecordType = 23;

		this.cancelToken = axios.CancelToken;
		this.source = this.cancelToken.source();

		const config = {
			onUploadProgress: function(progressEvent) {
				var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

				callback(percentCompleted);
			},
			cancelToken: this.source.token
		};

		this.emitIsUploading({ name: this.selectedFile.name });

		// Get the previously saved (in triggerUpload()) auth string for audit session

		let auth = this.getSession(SessionResource.AuditServiceSession);

		this.auditService = new AuditService(api.getAuditServiceAddress(), eventDetails?.auditEndpoint);

		// Create an event record, then upload a file and attach it to this event record
		const eventRecordId = await mobileApi.addEventRecordForGuard(
			this.eventId,
			`${this.currentUserName} has uploaded a file`,
			null,
			this.eventRecordTypeId
		);

		await this.auditService
			.uploadFileForEventRecord(
				"image",
				this.selectedFile,
				auth, this.eventId,
				eventRecordId,
				60000,
				config
			)
			.then(async (response: any) => {
				// Finally, finish the sequence with a confirmation
				await mobileApi.addEventRecordForGuard(
					this.eventId,
					`File ${this.selectedFile.name} upload complete for EventRecordID: ${eventRecordId}`,
					eventRecordId,
					attatchedFileEventRecordType
				);
				this.emitUploadSuccess();
				this.emitIsUploading(false);
				setTimeout(() => {
					this.emitUploadProgress(0);
				}, 5000);
			})
			.catch(async err => {
				console.log(`Upload failed! ${err}`);
				this.emitIsUploading(false);
				this.emitUploadProgress(0);
				this.emitUploadError(err);
			});
	}

	public emitIsUploading(state) {
		this.$emit("isUploading", { state: state });
	}
	public emitUploadSuccess() {
		this.$emit("uploadSuccess");
	}
	public emitUploadError(err) {
		this.$emit("uploadError", { error: err });
	}
	public emitUploadProgress(value) {
		this.$emit("uploadProgress", { progress: value });
	}
}
