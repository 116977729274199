import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { ActivityLogState, ActivityLogDTO } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<ActivityLogState, RootState> = {
	async getActivityLog({ commit }, queryStr) {
		return await api.getActivityLog(queryStr);
	},
	async addActivityLog({ commit }, newLog: ActivityLogDTO) {
		return await api.addActivityLog(newLog);
	},
	async editActivityLog({ commit }, activityLog: ActivityLogDTO) {
		return await api.editActivityLog(activityLog);
	},
	async hideActivityLog({ commit }, activityLogId: number) {
		return await api.hideActivityLog(activityLogId);
	},
	async unHideActivityLog({ commit }, activityLogId: number) {
		return await api.unHideActivityLog(activityLogId);
	},
	async getActivityLogOutcomes({ commit }, areaId: null | boolean) {
		return await api.getActivityLogOutcomes(areaId ? areaId : null);
	},
	async getActivityLogEntry({ commit }, activityLogId: number): Promise<ActivityLogDTO> {
		return await api.getActivityLogEntry(activityLogId);
	},
};
