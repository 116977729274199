import { MutationTree } from "vuex";
import { SubscriptionState, SubscriptionDto } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<SubscriptionState> = {
	setSubscriptionDetails(state, subscription: SubscriptionDto) {
		state.subscription = subscription;
		state.isLoaded = true;
	},
	setBilledUserPermissions(state, billedUserPermissions) {
		state.billedUserPermissions = billedUserPermissions;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
