
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { ServerEventType } from "@/store/eventqueue/types";
import GPSService from "@/services/gps.service";
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import SVButton from "@/components/form/SVButton.vue";
import { UserPermissions } from "../store/types";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import api from "@/services/api.service";
import { MapBounds, MapLayer } from "@/store/map-layers/types";
import { convertStringToLatLng } from "@sureview/v2-mapping-saas";
import vSelect3 from "vselect3";
import HideMapMixin from "@/mixins/HideMapMixin";

const Eventqueue = namespace("eventqueue");
const MapLayers = namespace("mapLayers");
const ManualRaiseStore = namespace("manualRaise");

const MaxNotesLength = 1000;
const MaxRaisedByLength = 50;

@Component({
	mixins: [validationMixin],
	validations: {
		selectedGroup: {
			required: requiredIf(function() {
				return !this.selectedLayer;
			})
		},
		selectedLayer: {
			required: requiredIf(function() {
				return !this.selectedGroup;
			})
		},
		alarmMarkerPosition: {
			required: requiredIf(function() {
				return this.pinNeedsDrop();
			})
		},
		selectedEventType: {
			eventNum: {
				required
			}
		},
		notes: {
			maxLength: maxLength(MaxNotesLength)
		},
		raisedBy: {
			maxLength: maxLength(MaxRaisedByLength)
		}
	},
	components: {
		multiselect: Multiselect,
		SVButton: SVButton,
		"area-tree-select": AreaTreeSelect,
		"v-select-3": vSelect3,
	}
})
export default class ManualRaise extends Mixins(HideMapMixin) {
	// Manual raise store
	@ManualRaiseStore.State manualRaiseShown: boolean;
	@ManualRaiseStore.State alarmMarkerPosition: string;
	@ManualRaiseStore.State selectedGroup: AreaNode;

	@ManualRaiseStore.Mutation setManualRaiseShown: (visible: boolean) => void;
	@ManualRaiseStore.Mutation("setAreaMapBounds") setSelectedAreaMapBounds: (bounds: MapBounds) => void;
	@ManualRaiseStore.Mutation setAlarmMarkerPosition: (position: string) => void;
	@ManualRaiseStore.Mutation setSelectedGroup: (areaNode: AreaNode) => void;

	@ManualRaiseStore.Action("clearSettings") clearManualRaiseSettings: () => void;
	@ManualRaiseStore.Action raiseManualAlarm: ({ payload, autoHandle }) => Promise<void>;

	// Event queue
	@Eventqueue.Getter("serverEventTypes") eventTypes: ServerEventType[];
	@Eventqueue.Getter("getProcessingError") error: string;

	@Eventqueue.Action loadServerEventTypes: (params: object) => Promise<void>;
	@Eventqueue.Action searchGroups: any;

	// Map layers
	@MapLayers.State selectedMapElevation: number;
	@MapLayers.State defaultMapBounds: MapBounds;

	@Getter getRequireMapPin: any;
	@Getter("getPermissions") permissions: UserPermissions;
	@Getter getFeature: any;

	@Prop({ default: false }) public disableStyles: boolean;
	@Prop({ default: false }) public disableAutomaticClaim: boolean;
	@Prop({ default: false }) public disableMapAlert: boolean;

	public readonly maxNotesLength = MaxNotesLength;
	public readonly maxRaisedByLength = MaxRaisedByLength;

	// Payload properties
	public raisedBy: string = "";
	public notes: any = "";
	public selectedEventType: any = null;
	public mapLayersForSelectedGroup: MapLayer[] = [];
	public autoHandle: boolean = true;
	public isRaising: boolean = false;

	public settings: any = {};
	public groupSearchLoading = false;
	public selectedLayer: MapLayer = null;
	public gpsService: GPSService = null;

	private claimCheckboxFocused: boolean = false;
	private manualRaiseServerTypeId = 435;
	

	public get raiseTitle() {
		if (this.pinNeedsDrop()) {
			return "You must drop a pin on the map";
		}

		if (this.selectedGroup == null && this.selectedLayer == null) return "You must select an area";

		if (this.selectedEventType == null || this.selectedEventType.eventNum == null)
			return "You must select an event type";

		return "Raise alarm";
	}

	private pinNeedsDrop() {
		return !this.alarmMarkerPosition && this.getRequireMapPin;
	}

	@Watch("selectedMapElevation")
	public onSelectedMapElevationChanged(value: any, oldValue: any) {
		let existingLayer = this.mapLayersForSelectedGroup.find(layer => layer.minElevation == value);

		if (existingLayer) {
			this.selectedLayer = existingLayer;
		}
	}

	public async created() {
		await this.loadServerEventTypes({ serverType: this.manualRaiseServerTypeId, groupID: null });
	}

	public async mounted() : Promise<void> {
		
		this.setSelectedAreaMapBounds(null);
		this.setAlarmMarkerPosition(null);
		this.setSelectedGroup(null);
		this.$set(this, "selectedLayer", null);

		// Turn off auto handle if we've disabled it
		if (this.disableAutomaticClaim) {
			this.autoHandle = false;
		}

		this.gpsService = new GPSService();
	}

	@Watch("selectedGroup")
	public async selectedGroupChanged(area: any) {
		if(!area)
			return;

		await this.loadServerEventTypes({ serverType: this.manualRaiseServerTypeId, groupID: area.id });

		const bounds = await api.getBoundsForArea(area.id);
		const isDefaultBounds = bounds.north === this.defaultMapBounds.north &&
			bounds.south === this.defaultMapBounds.south &&
			bounds.east === this.defaultMapBounds.east &&
			bounds.west === this.defaultMapBounds.west;

		if (isDefaultBounds) {
			if (area.latLong) {
				const latLng = convertStringToLatLng(area.latLong);
				const isLatAndLngSet = !isNaN(latLng.lat) && !isNaN(latLng.lng);
				if (isLatAndLngSet) {
					this.setSelectedAreaMapBounds({
						north: latLng.lat,
						east: latLng.lng,
						south: latLng.lat,
						west: latLng.lng
					});
				} else {
					// fallback to using the address if the lat long is invalid.
					await this.setSelectedAreaBounds(area);
				}
			} else if (area.address) {
				await this.setSelectedAreaBounds(area);
			}
		} else {
			this.setSelectedAreaMapBounds(bounds);
		}

		this.mapLayersForSelectedGroup = await api.loadMapLayersByGroupId(area.id);		

		if (this.mapLayersForSelectedGroup && this.mapLayersForSelectedGroup.length > 0) {
			this.selectedLayer = this.mapLayersForSelectedGroup[0];
		}
	}

	public async setSelectedAreaBounds(area: AreaNode): Promise<void> {
		if (this.isHideMapsEnabled) {
			return;
		}

		try {
			const geoCodeResponse = await this.gpsService.GeoCodeLookup(
				area.address,
				process.env.VUE_APP_GOOGLE_MAPS_API_KEY
			);

			if (geoCodeResponse.results && geoCodeResponse.results.length > 0) {
				let addressGeometry = geoCodeResponse.results[0].geometry;

				if (addressGeometry.viewport) {
					this.setSelectedAreaMapBounds({
						north: addressGeometry.viewport.northeast.lat,
						east: addressGeometry.viewport.northeast.lng,
						south: addressGeometry.viewport.southwest.lat,
						west: addressGeometry.viewport.southwest.lng
					});
				} else if (addressGeometry.location) {
					this.setSelectedAreaMapBounds({
						north: addressGeometry.location.lat,
						east: addressGeometry.location.lng,
						south: addressGeometry.location.lat,
						west: addressGeometry.location.lng
					});
				}

				if (addressGeometry.location && !area.latLong) {
					try {
						await api.updateAreaLatLong(area.id, { lat: addressGeometry.location.lat, lng: addressGeometry.location.lng });
					}
					catch (e) {
						//ignore error
					}
				}
			}
		} catch (err) {
			this.$notify({
				type: "error",
				title: "Manual Raise - Address Lookup",
				text: "Unable to lookup the address you have entered, please ensure the address is valid."
			});
		}
	}

	public clear() {
		this.$set(this, "selectedLayer", null);
		this.setSelectedGroup(null);
		this.$set(this, "selectedEventType", null);
		this.$set(this, "notes", "");
		this.$set(this, "raisedBy", "");
		this.clearManualRaiseSettings();
	}

	public async raise() {
		this.$v.$touch();
		if (this.$v.$error) {
			return;
		}

		if (!this.isRaising) {
			this.isRaising = true;
			const location = this.alarmMarkerPosition;
			const selectedLayerInfo =
				this.selectedLayer == null ? "" : `, MapLayerID: ${this.selectedLayer.mapLayerId}`;
			const alarmLocation = location ? location + selectedLayerInfo : "";
			const groupID = !this.selectedLayer ? this.selectedGroup.id : this.selectedLayer.groupId;
			const payload = {
				mapLayerId: this.selectedLayer
					? this.selectedLayer.mapLayerId
					: null,
				serverTypeEvent: this.selectedEventType.eventNum,
				notes: this.notes,
				raisedBy: this.raisedBy,
				location: alarmLocation,
				groupID: groupID
			};
			await this.raiseManualAlarm({
				payload,
				autoHandle: this.autoHandle
			});
			this.isRaising = false;
			this.setManualRaiseShown(false);
			this.clear();
		}
	}

	public cancel() {
		this.clear();
		this.setManualRaiseShown(false);
		this.setSelectedAreaMapBounds(null);
	}

	public get noteRequiredForManualRaise() {
		return this.getFeature(["Alarms", "EventQueue", "ManualRaise", "RequireNote"])
	}

	public get noteNotEntered() {

		if (!this.noteRequiredForManualRaise) {
			return false;
		}

		return this.notes.length == 0;
	}
}
