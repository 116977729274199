





import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import getScript from "@/services/insertScript.service";
import { axiosInstance } from "@/axios.instance";
import { FeaturesList } from "@/store/types";
import {
	getEnhancedMapping,
	IEnhancedMapping,
	IMap,
	IMarker,
	ILayer,
	ICircle,
	ILatLng,
	ILatLngBounds,
	convertStringToLatLng,
	addMeterToCoord,
	stringToBounds,
	union,
	extend,
	MarkerIcons
} from "@sureview/v2-mapping-saas";
import { get, keyBy } from "lodash";
import { darkMapStyle, satelliteMapStyle, getMapStyle } from "@/scripts/mapping/mapStyle";
import { EventDetails } from "@/store/site-monitor/types";

import { config } from "@vue/test-utils";
import GPSService from "@/services/gps.service";
import { MapBounds, MapData } from "@/store/map-layers/types";

class LocationBounds {
	public location: ILatLng;
	public viewport: {
		northeast: ILatLng;
		southwest: ILatLng;
	};
}

class MapLayerData {
	public mapLayerId: number = 0;
	public minElevation: number | null = null;
	public maxElevation: number | null = null;

	public title: string = "";
	public ne: string = "";
	public sw: string = "";

	public rotation: number = 0;
}

class MapItemData {
	public mapLayerItemId: number = 0;
	public mapLayerItemTypeId: number = 0;

	public minElevation: number | null = null;
	public maxElevation: number | null = null;

	public objectId: number | null = null;
	public extraValue: string = "";

	public title: string = "";
	public regionPath: string | null = null;
	public latLong: string | null = null;
}

class MapItemTypeData {
	public mapLayerItemTypeId: number = 0;
	public title: string = "";
	public visible: boolean = true;
	public any: boolean = false;
	public hideByDefault: boolean = false;
}

class MapItemIcon {}

class LayerMap {
	[s: number]: ILayer;
}
class ItemMap {
	[s: number]: IMarker;
}
class ItemObjectMap {
	[s: number]: IMarker[];
}
class ItemTypeObjectMap {
	[s: number]: ItemObjectMap;
}

class LayerTypeMap {
	[s: number]: boolean;
}

const MapLayers = namespace("mapLayers");
const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Eventqueue = namespace("eventqueue");

@Component({
	components: {}
})
export default class EnhancedMap extends Vue {
	@Prop({ default: () => 19 })
	public zoomLevel: number;

	@Prop({ default: () => true })
	public showCircle: boolean;

    // Flag to specify if toggle map feature flag enabled, is it enabled for this instance of the re-usable component.
	@Prop({ default: () => false })
	private showMapToggle: boolean;

	public $refs: any = {
		mappingContainer: HTMLElement
	};

	@Getter("getMapKey") public mapKey: any;

	public elevation: number = 0;
	public displayElevation: string = "";

	public layersAdded = new LayerMap();
	public itemsAdded = new ItemMap();
	public markersByTypeAndObject = new ItemTypeObjectMap();

	public alarmsAdded = [];
	public eventIcons = {};

	public activeItems = new Array<IMarker>();
	public activeAlarmIDs = new Set<number>();
	public activeItemIDs = new Set<number>();

	public adHocAlarmMarkers = new ItemMap();
	public pendingAdHocMarkers: any[] = [];
	public mapCenter: ILatLng | null = null;

	public enhancedMapping: IEnhancedMapping | null = null;
	public map: IMap | null = null;

	public clickCircleEventID: number = 0;
	public clickCircle: ICircle | null = null;

	public layersVisible = false;

	public elevationList: any[] = [];

	public noMapData: boolean = true;
	public mapType: string = "";
	public marker: any = null

    // Getter and setter for toggling the map.
	@SiteMonitor.Action setHideMapFlag: any;
	@SiteMonitor.Getter('getHideMapFlag') hideMapFlag: boolean;

	@MapLayers.Action public loadMapDataByBounds: (bounds: MapBounds) => Promise<void>;
	@MapLayers.Action public loadMapLayersItemTypes: () => Promise<void>;
	@MapLayers.Getter("getMapLayerItemTypes") public mapItemTypes;
	@MapLayers.State("mapItemIcons") private mapIcons!: MarkerIcons;
	@MapLayers.State private mapData: MapData;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@SiteMonitor.Getter("getEventDetails") public EventDetails: EventDetails;
	@SiteMonitor.Getter("getActiveMapItemsRequired")
	public activeMapItemsRequired: any;
	@SiteMonitor.Getter("getActiveMapItems") public activeMapItems: any;
	@SiteMonitor.State public AdhocLocationEventRecords!: any[];
	@SiteMonitor.Getter("getEventLocationLastSet")
	public eventLocationLastSet: number;
	@SiteMonitor.Getter("getActiveResponseIDs") public activeResponseIDs: any;
	@SiteMonitor.Mutation public setActiveMapItems: any;
	@SiteMonitor.Mutation public setActiveMapItemsRequired: any;
	@SiteMonitor.Mutation public setMapCircleCenter: any;
	@SiteMonitor.Mutation setActivity;

	@Eventqueue.Getter("getActiveEvents") events!: any[];
	@Eventqueue.Action("fetchEventQueue") fetchEventQueue: any;

	@SMCameras.Mutation public setAwaitingCamera: any;
	@SMCameras.Mutation clearDeviceControllerCameras: any;

	@Getter public getUseImperialMeasurements: any;
	@Getter public getMapType: any;
	@Getter public getHideLabels: any;
	@Mutation public setMapType: any;
	@MapLayers.Mutation public setMapLayersItemType: any;

	public get formattedEventsByEventId() {
		return this.events && keyBy(this.events, 'eventID')
	}

	public get eventLocation() {
		if (this.EventDetails == null) return "";

		return this.EventDetails.latLong;
	}

	public get allowSpotlightScaling() {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "Map", "SpotlightScaling"]);
	}

	public get canGoUp() {
		return this.elevationList.some(elevation => elevation.elevationValue > this.elevation);
	}
	public get canGoDown() {
		const index = this.elevationList.findIndex(x => x.elevationValue == this.elevation);
		if(index == 0 || index == -1) {
			return false;
		}

		return this.elevationList.some(elevation => elevation.elevationValue < this.elevation);
	}

	public get nearbyCameraRadius() {
		if (this.EventDetails == null ||
			this.EventDetails.cameraNearbyRadius == null ||
			this.EventDetails.cameraNearbyRadius == undefined ||
			this.EventDetails.cameraNearbyRadius < 1)  {
			return 30;
		}
		return this.EventDetails.cameraNearbyRadius;
	}

	public get staticMapIcon(): boolean {
		if (this.EventDetails == null ||
			this.EventDetails.staticMapIcon == null ||
			this.EventDetails.staticMapIcon == undefined)  {
			return false;
		}
		return this.EventDetails.staticMapIcon;
	}

	public get eventElevations() {
		var details = this.EventDetails;

		if (details == null) {
			return {
				minElevation: null,
				maxElevation: null
			};
		}

		return {
			minElevation: details.minElevation,
			maxElevation: details.maxElevation
		};
	}

	// Feature flag checker for toggle maps feature.
	private get isToggleMapEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Maps", "ToggleSiteMonitor"]) && this.showMapToggle;
	}

	private async decodeLocation(location: string): Promise<LocationBounds> {
		if (/^-?[\d.]+[NS]? -?[\d.]+[WE]?($|,)/.test(location)) {
			return {
				location: convertStringToLatLng(location),
				viewport: null
			};
		} else {
			let geoCoder = new GPSService();
			let response = await geoCoder.GeoCodeLookup(location, this.mapKey);

			if (response.results.length == 0)
				return null;

			let addressGeometry = response.results[0].geometry;

			if (addressGeometry.viewport) {
				return {
					location: {
						lat: addressGeometry.location.lat,
						lng: addressGeometry.location.lng
					},
					viewport: {
						northeast: addressGeometry.viewport.northeast,
						southwest: addressGeometry.viewport.southwest
					}
				};
			} else {
				return {
					location: {
						lat: addressGeometry.location.lat,
						lng: addressGeometry.location.lng
					},
					viewport: null
				};
			}
		}
	}

	@Watch("eventLocationLastSet")
	public async onEventLocationChanged(value: string, oldValue: string) {
		if (this.map != null && this.EventDetails != null) {
			let elevationBounds = this.eventElevations;

			if (this.EventDetails.alarmElevation) {
				this.elevation = this.EventDetails.alarmElevation;
			} else if (elevationBounds.minElevation != null && this.elevation <= elevationBounds.minElevation) {
				this.elevation = elevationBounds.minElevation;
			} else if (elevationBounds.maxElevation != null && this.elevation <= elevationBounds.maxElevation) {
				this.elevation = elevationBounds.maxElevation;
			} else {
				this.elevation = elevationBounds.minElevation;
			}

			if (this.eventLocation == "") {
				this.noMapData = true;
			} else {
				let gpsLocation = await this.decodeLocation(this.eventLocation);

				if (gpsLocation) {
					this.mapCenter = gpsLocation.location;

					if (this.showCircle)
						this.loadRadius(this.mapCenter);

					if (gpsLocation.viewport) {
						gpsLocation;
						this.map.mapBounds = {
							north: gpsLocation.viewport.northeast.lat,
							east: gpsLocation.viewport.northeast.lng,
							south: gpsLocation.viewport.southwest.lat,
							west: gpsLocation.viewport.southwest.lng
						};
					} else {
						this.map.center = this.mapCenter;
						this.map.zoom = this.zoomLevel;
					}

					this.noMapData = false;
				} else {
					this.noMapData = true;
				}
			}
		}
	}

	public get adhocLocationEventRecords() {
		return this.AdhocLocationEventRecords;
	}

	@Watch("adhocLocationEventRecords")
	public onAdhocLocationEventRecordsChange(records: any[], oldRecords: any[]) {
		this.removeAdhocAlarmMarkers();

		if (this.map != null) {
			records.forEach(record => {
				if (this.adHocAlarmMarkers[record.eventRecordID] == null) {
					this.createAdHocMarker(record);
				}
			});
		}
	}

	@Watch("activeMapItemsRequired")
	public onActiveMapItemsRequiredChange(required: boolean, oldRequired: boolean) {
		if (required) {
			this.setActiveMapItems(this.activeMapItems);
			this.setActiveMapItemsRequired(false);
		}
	}

	public async mounted() {
		await this.loadMapLayersItemTypes();
		await this.init();

		if (this.EventDetails) {
			this.onEventLocationChanged(null, null);
		}
	}

	@Watch("elevationList", { deep: true })
	private updateDisplayElevation() {
		if(this.displayElevation == "" && this.elevationList.length > 0) {
			this.displayElevation = this.elevationList[0].name;
		}
	}

	public beforeDestroy() {
		this.map.reset();
		this.clearMap();
		if(this.marker) this.marker.remove()

		this.removeAdhocAlarmMarkers();

		if (this.clickCircle != null) {
			this.clickCircle.remove();
			this.clickCircle = null;
		}
	}

	public async init() {
		this.enhancedMapping = await getEnhancedMapping("google", this.mapKey);

		let pos: LocationBounds = {
			location: { lat: 0, lng: 0 },
			viewport: null
		};

		if (this.eventLocation != null && this.eventLocation != "") {
			pos = await this.decodeLocation(this.eventLocation);

			let elevationBounds = this.eventElevations;

			if (this.EventDetails.alarmElevation) {
				this.elevation = this.EventDetails.alarmElevation;
			} else if (elevationBounds.minElevation != null && this.elevation <= elevationBounds.minElevation) {
				this.elevation = elevationBounds.minElevation;
			} else if (elevationBounds.maxElevation != null && this.elevation <= elevationBounds.maxElevation) {
				this.elevation = elevationBounds.maxElevation;
			} else {
				this.elevation = elevationBounds.minElevation;
			}

			if (pos) {
				this.noMapData = false;
			} else {
				this.noMapData = true;
				pos = {
					location: { lat: 0, lng: 0 },
					viewport: null
				};
			}
		} else {
			this.noMapData = true;
		}

		this.mapCenter = pos.location;
		this.map = this.enhancedMapping.createMap(
			this.$refs.mappingContainer,
			pos.location,
			0,
			false,
			this.getMapType,
			getMapStyle(this.getMapType, this.getHideLabels)
		);

		if (pos.viewport) {
			const sw = pos.viewport.southwest;
			const ne = pos.viewport.northeast;

			this.map.mapBounds = {
				north: ne.lat,
				east: ne.lng,
				south: sw.lat,
				west: sw.lng
			};
		}


		this.map!.staticMapIcon = this.staticMapIcon;
		this.mapType = this.getMapType;

		this.map!.registerEvent("onMapTypeChanged", (mapType: string) => {
			this.setActivity();
			this.mapType = mapType;
			this.setMapType(mapType);

			this.map!.map.setOptions({
				styles: getMapStyle(this.getMapType, this.getHideLabels)
			});
		});

		this.map!.registerEvent("onViewportChangedDelayed", () => {
			this.setActivity();
			if (this.map!.currentViewState == "narrow") {
				this.loadInBounds(this.map!.mapBounds, true);
			} else {
				this.clearMap();
			}
		});

		this.map!.registerEvent("onDisplayTypeChanged", (data: any) => {
			this.setActivity();
			var layersVisible = data.viewState === "narrow";

			for (var layerId in this.layersAdded) {
				this.layersAdded[layerId].visible = layersVisible;
			}

			for (var layerItemId in this.itemsAdded) {
				this.itemsAdded[layerItemId].visible = layersVisible;
			}
		});

		this.AdhocLocationEventRecords.forEach((record: any) => {
			this.createAdHocMarker(record);
		});
	}

	private getLatLngPolarity(latLng: string) {
		var regex = /([1-8]?[1-9]|[1-9])\.{1}\d{1,16}([A-Z])/g;

		var result = regex.exec(latLng);
		if (typeof result !== "undefined" && result !== null && result.length === 3) {
			return result[2];
		}

		return "";
	}

	private async loadInBounds(mapBounds: any, layersVisible: boolean) {
		if (this.layersVisible != layersVisible) {
			for (var eventrecordId in this.adHocAlarmMarkers) {
				this.adHocAlarmMarkers[eventrecordId].visible = layersVisible;
			}
		}
		this.layersVisible = layersVisible;

		let bounds = {
			north: mapBounds.north,
			south: mapBounds.south,
			east: mapBounds.east,
			west: mapBounds.west
		} as MapBounds;

		if (this.elevation) bounds.elevation = this.elevation;

		await this.loadMapDataByBounds(bounds);

		const elevations = this.mapData.elevations.slice(); // make a deep copy,
		// preventing mutation state outside mutation handlers.
		// Proper way would be to spread this logic accross the
		// `action -> service -> mutation -> getter` chain

		this.elevationList = elevations.sort(function(a, b) {
			return a.elevationValue - b.elevationValue;
		});

		if (this.elevation) {
			let currentElevation = elevations.find(
				(elevationFloor: any) => elevationFloor.elevationValue == this.elevation
			);
			if (currentElevation != null) {
				this.displayElevation = currentElevation.name;
			}
		} else if (elevations.length > 0) {
			let currentElevation = this.elevationList[0];
			if (currentElevation != null) {
				this.elevation = currentElevation.elevationValue;
				this.displayElevation = currentElevation.name;
			}
		}

		this.displayLayers(this.mapData.layers as MapLayerData[]);

		const isShowMarkerByEventDetail = this.showCircle && !this.mapData.items.length && !this.AdhocLocationEventRecords.length

		if(isShowMarkerByEventDetail) this.setMarkerByEventDetails()
		else this.displayItems(this.mapData.items as MapItemData[]);

		if (this.showCircle) {
			if (this.clickCircle == null || this.clickCircleEventID != this.EventDetails.eventID) {
				this.clickCircleEventID = this.EventDetails.eventID;
				this.loadRadius(this.mapCenter);
			}
		}
	}

	private displayLayers(layers: MapLayerData[]) {
		const itemLayerIDs: string[] = [];

		layers.forEach(layer => {
			/* let isElevated = (layer.minElevation == null || layer.minElevation! <= this.elevation) &&
												                (layer.maxElevation == null || layer.maxElevation! >= this.elevation); */
			const elevation = this.elevation;

			let isElevated =
				(layer.minElevation == null || layer.minElevation! <= elevation) &&
				(layer.maxElevation == null || layer.maxElevation! >= elevation);

			if (isElevated) {
				itemLayerIDs.push(layer.mapLayerId.toString());

				if (this.layersAdded[layer.mapLayerId] == undefined) {
					this.layersAdded[layer.mapLayerId] = this.enhancedMapping.createOverlay(
						this.map!,
						layer.mapLayerId,
						layer.title,
						stringToBounds(layer.ne, layer.sw), // boundaries
						layer.rotation, // rotation
						axiosInstance.defaults.baseURL + "/MapLayers/Image/" + layer.mapLayerId.toString(), //imageUrl
						layer.minElevation, // minElevation,
						layer.maxElevation, // maxElevation
						this.layersVisible // , visible
					);
				}
			}
		});

		for (var layerId in this.layersAdded) {
			if (itemLayerIDs.indexOf(layerId) == -1) {
				let layer = this.layersAdded[layerId];

				layer.remove();
				delete this.layersAdded[layerId];
			}
		}
	}

	public async setMarkerByEventDetails() {
		const marker = this.enhancedMapping.createMarker(
			this.map!,
			null,
			null,
			4,
			null,
			this.EventDetails.eventTitle,
			convertStringToLatLng(this.EventDetails.latLong),
			null,
			null,
			null,
			true
		);
		await this.fetchEventQueue();
		const inProcessing = get(this.formattedEventsByEventId, `[${this.EventDetails.eventID}].inProcessing`)
		const isProcessing = inProcessing || this.EventDetails.unParkAt !== null

		marker.state = isProcessing ? "processing" : "active";
		this.marker = marker
	}

	public displayItems(mapItems: MapItemData[]) {
		let mapItemIDs: string[] = [];
		let markerTypeVisibilityByID = new LayerTypeMap();

		this.mapItemTypes.forEach(markerType => {
			markerTypeVisibilityByID[markerType.mapLayerItemTypeId] = markerType.visible;
		});

		mapItems.forEach(mapItem => {
			let mapLayerItemId = mapItem.mapLayerItemId;

			if (
				(mapItem.minElevation == null || mapItem.minElevation! <= this.elevation) &&
				(mapItem.maxElevation == null || mapItem.maxElevation! >= this.elevation)
			) {
				mapItemIDs.push(mapItem.mapLayerItemId.toString());

				if (this.itemsAdded[mapLayerItemId] == undefined) {
					let mapMarkerItem = this.enhancedMapping.createMarker(
						this.map!,
						mapItem.mapLayerItemId,
						null,
						mapItem.mapLayerItemTypeId,
						mapItem.objectId,
						mapItem.title,
						convertStringToLatLng(mapItem.latLong!),
						mapItem.regionPath,
						mapItem.minElevation,
						mapItem.maxElevation,
						this.layersVisible
					);

					this.itemsAdded[mapLayerItemId] = mapMarkerItem;

					if (markerTypeVisibilityByID[mapItem.mapLayerItemTypeId] != null) {
						this.itemsAdded[mapLayerItemId].markerTypeVisible =
							markerTypeVisibilityByID[mapItem.mapLayerItemTypeId];
					}

					if (mapItem.objectId != null) {
						if (this.markersByTypeAndObject[mapItem.mapLayerItemTypeId] == null) {
							this.markersByTypeAndObject[mapItem.mapLayerItemTypeId] = new ItemObjectMap();
						}

						if (!this.markersByTypeAndObject[mapItem.mapLayerItemTypeId][mapItem.objectId!]) {
							this.markersByTypeAndObject[mapItem.mapLayerItemTypeId][mapItem.objectId!] = [];
						}

						this.markersByTypeAndObject[mapItem.mapLayerItemTypeId][mapItem.objectId!].push(mapMarkerItem);

						if (mapItem.mapLayerItemTypeId == 4) {
							const activeStatus = this.activeResponseIDs[mapItem.objectId!.toString()];
							if (activeStatus == null) {
								mapMarkerItem.state = "";
							} else if (activeStatus.awaitingAcknowledgement) {
								mapMarkerItem.state = "active";
							} else {
								mapMarkerItem.state = "processing";
							}
						} else {
							if (mapItem.mapLayerItemTypeId == 1) {
								mapMarkerItem.isClickable = true;
								mapMarkerItem.click = () => {
									this.$emit("onCameraIconClick");
									this.setAwaitingCamera({
										objectId: mapItem.objectId,
										title: mapItem.title
									});
								};
							}

							if (this.activeItemIDs.has(mapLayerItemId)) {
								mapMarkerItem.state = "active";
							}
						}
					}
				}
			}
		});

		for (var itemId in this.itemsAdded) {
			if (mapItemIDs.indexOf(itemId) == -1) {
				var item = this.itemsAdded[itemId];

				item.state = "";

				if (this.markersByTypeAndObject[item.mapLayerItemTypeId] != null) {
					if (this.markersByTypeAndObject[item.mapLayerItemTypeId][item.objectId!] != null) {
						delete this.markersByTypeAndObject[item.mapLayerItemTypeId][item.objectId!];
					}
				}

				item.remove();
				delete this.itemsAdded[itemId];
			}
		}
	}

	public loadRadius(position: any) {
		if (this.clickCircle != null) {
			this.clickCircle.remove();
			this.clickCircle = null;
		}

		this.clickCircle = this.enhancedMapping.createCircle(
			this.map!,
			"#0d87e9",
			0.8,
			2,
			"#0d87e9",
			0.05,
			position,
			this.nearbyCameraRadius,
			this.allowSpotlightScaling
		);

		this.clickCircle.editted = latLng => this.getItemsInCircle();
		this.getItemsInCircle();
	}

	private rad(x: number) {
		return (x * Math.PI) / 180;
	}

	public getDistance(p1: any, p2: any): number {
		let R = 6378137; // Earth's mean radius in meters

		let dLat = this.rad(p2.lat - p1.lat);
		let dLong = this.rad(p2.lng - p1.lng);
		let a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = R * c;

		return Math.round(d);
	}

	public async getItemsInCircle() {
		this.clearDeviceControllerCameras();
		const bounds = this.clickCircle.squareBounds;

		let center = this.clickCircle!.center;
		this.setMapCircleCenter(center.lat + " " + center.lng);

		let mapBounds = {
			north: bounds.north,
			south: bounds.south,
			east: bounds.east,
			west: bounds.west
		} as MapBounds;

		if (this.elevation) {
			mapBounds.elevation = this.elevation;
		} else {
			// If we don't have any elevation at this point,
			// set elevation to be default: 0
			this.elevation = 0;
		}

		await this.loadMapDataByBounds(mapBounds);

		let items = this.mapData.items as Array<MapItemData>;

		let newActiveItems = new Array<IMarker>();
		this.activeItemIDs = new Set<number>();

		let centerPosition = { lat: center.lat, lng: center.lng };
		let radius = this.clickCircle!.radius;
		let itemDistances = new Array<any>();

		let activeMapLayerItems = new Set();

		items.forEach(item => {
			if (item.mapLayerItemTypeId != 4) {
				let marker = this.itemsAdded[item.mapLayerItemId];
				let distance;

				if (marker != null) {
					distance = this.getDistance(centerPosition, {
						lat: marker.position.lat,
						lng: marker.position.lng
					});

					if (distance <= radius) {
						marker.state = "active";

						itemDistances.push({
							distance: distance,
							itemType: item.mapLayerItemTypeId,
							title: marker.title,
							objectId: marker.objectId
						});

						newActiveItems.push(marker);
						activeMapLayerItems.add(item.mapLayerItemId);
						this.activeItemIDs.add(item.mapLayerItemId);
					}
				} else if (item!.latLong != null) {
					const position = convertStringToLatLng(item!.latLong!);
					distance = this.getDistance(centerPosition, {
						lat: position.lat,
						lng: position.lng
					});

					if (distance <= radius && item.minElevation == this.elevation) {
						this.activeItemIDs.add(item.mapLayerItemId);
						activeMapLayerItems.add(item.mapLayerItemId);
						itemDistances.push({
							distance: distance,
							itemType: item.mapLayerItemTypeId,
							title: item.title,
							objectId: item.objectId,
							imperial: false
						});
					} else {
						return;
					}
				}
			}
		});

		if (this.activeItems != null) {
			this.activeItems.forEach(marker => {
				if (!activeMapLayerItems.has(marker.mapLayerItemId)) {
					if (marker.mapLayerItemTypeId != 4) marker.state = "";
				}
			});
		}
		this.activeItems = newActiveItems;

		itemDistances = itemDistances.sort((a, b) => {
			return a.distance - b.distance;
		});

		var uniqueDistances = [];
		itemDistances.forEach(distance => {
			if (
				!uniqueDistances.find(
					item => item.itemType == distance.itemType && item.objectId == distance.objectId
				)
			) {
				uniqueDistances.push(distance);
			}
		});
		itemDistances = uniqueDistances;

		if (this.getUseImperialMeasurements) {
			itemDistances.forEach(distance => {
				distance.distance = this.metersToFeet(distance.distance);
				distance.imperial = true;
			});
		}

		this.setActiveMapItems(itemDistances);
		this.setActiveMapItemsRequired(false);

		if (this.clickCircle && this.allowSpotlightScaling) this.clickCircle!.editable = true;
	}

	private metersToFeet(meters) {
		if (meters < 0) return 0;
		else return Math.round(meters / 0.3048);
	}

	private createAdHocMarker(record: any) {
		var location = record.location.split(",");
		this.adHocAlarmMarkers[record.eventRecordID] = this.enhancedMapping.createMarker(
			this.map!,
			null,
			record.eventRecordID.toString(),
			4,
			record.objectId,
			record.details,
			convertStringToLatLng(location[0]),
			null,
			null,
			null,
			true
		);

		this.adHocAlarmMarkers[record.eventRecordID].state = record.awaitingAcknowledgement ? "active" : "processing";
	}

	public clearMap() {
		for (var layerId in this.layersAdded) {
			let layer = this.layersAdded[layerId];

			layer.remove();
			delete this.layersAdded[layerId];
		}

		for (var itemId in this.itemsAdded) {
			let item = this.itemsAdded[itemId];

			item.state = "";

			if (this.markersByTypeAndObject[item.mapLayerItemTypeId] != null) {
				if (this.markersByTypeAndObject[item.mapLayerItemTypeId][item.objectId!] != null) {
					delete this.markersByTypeAndObject[item.mapLayerItemTypeId][item.objectId!];
				}
			}

			item.remove();
			delete this.itemsAdded[itemId];
		}
	}

	public upLevel() {
		let higherFloors = this.elevationList.filter(elevation => elevation.elevationValue > this.elevation);
		if (higherFloors.length > 0) {
			this.elevation = higherFloors[0].elevationValue;
			this.updateElevation();
		}
	}

	public downLevel() {
		let lowerFloors = this.elevationList.filter(elevation => elevation.elevationValue < this.elevation);
		if (lowerFloors.length > 0) {
			this.elevation = lowerFloors[lowerFloors.length - 1].elevationValue;
			this.updateElevation();
		}
	}

	public gotoElevation(elevation: any) {
		this.elevation = elevation.elevationValue;
		this.displayElevation = elevation.name;
		this.updateElevation();
	}

	public updateElevation() {
		this.clearMap();

		this.loadInBounds(this.map!.mapBounds, this.map!.currentViewState == "narrow");
		this.getItemsInCircle();
	}

	public toggleVisibility(mapItemType: any) {
		var newMapItem = {
			...mapItemType,
			visible: !mapItemType.visible
		};

		this.setMapLayersItemType({ id: mapItemType.mapLayerItemTypeId, data: newMapItem });

		if (this.markersByTypeAndObject[newMapItem.mapLayerItemTypeId] != null) {
			var ByObject = this.markersByTypeAndObject[newMapItem.mapLayerItemTypeId];

			for (var objectId in ByObject) {
				ByObject[objectId].forEach(marker => (marker.markerTypeVisible = newMapItem.visible));
			}
		}

		if (newMapItem.mapLayerItemTypeId == 4) {
			for (let eventRecordID in this.adHocAlarmMarkers) {
				this.adHocAlarmMarkers[eventRecordID].visible = newMapItem.visible;
			}
		}
	}

	public destroy() {
		this.clearMap();
	}

	public removeAdhocAlarmMarkers() {
		for (let eventRecordId in this.adHocAlarmMarkers) {
			// if this object isn't null and it has the remove function
			if (this.adHocAlarmMarkers[eventRecordId] !== null
				&& typeof this.adHocAlarmMarkers[eventRecordId].remove === "function") {
				this.adHocAlarmMarkers[eventRecordId].remove();
			}
			this.adHocAlarmMarkers[eventRecordId] = null;
		}
	}
}
