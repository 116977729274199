
import { Component, Vue } from "vue-property-decorator";

import NavHeader from "@/components/NavHeader.vue";
import AssetOnlySetup from "@/components/asset/AssetOnlySetup.vue";
import AssetTypeSetup from "@/components/asset/AssetTypeSetup.vue";
import { FeaturesList, LogoutPayload, UserPermissions } from '@/store/types';
import { Action, Getter } from 'vuex-class';
import { Constants } from "@/types/Constants";


@Component({
	components: {
		"asset-only-setup": AssetOnlySetup,
		"asset-type-setup": AssetTypeSetup,
		"nav-header": NavHeader,
	}
})
export default class AssetSetup extends Vue {
	@Getter getPermissions: UserPermissions;
	@Getter getIsFieldOpsLicensed: boolean;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	// logout
	@Action logout: (payload?: LogoutPayload) => void;
	@Action serverLogout: any;

	private async created() {
		if(this.isSuiteEnabled && !this.getIsFieldOpsLicensed) {
			this.logoutUser(Constants.FIELD_OPS_NO_LICENCE_ERROR);
		}
	}

	private async logoutUser(errorMessage: string = null): Promise<void> {
		await this.serverLogout();
		if(errorMessage) {
			const logoutPayload: LogoutPayload = {
				hasSessionExpired: false,
				playAudioAlert: false,
				errorMessage: errorMessage
			}
			await this.logout(logoutPayload);
		} else {
			await this.logout();
		}
	}

	private get isSuiteEnabled(): boolean {
		return this.getFeature(["Suite"]);
	}
}
