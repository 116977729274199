
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import MatrixContainer from "@/components/MatrixContainer.vue"; // TODO: encapsulate AlarmClip inside Camera
import DeviceController from "@/components/DeviceController.vue";
import { CameraType, ClipType, NearbyCameraType } from "@/store/site-monitor-cameras/types";
import { EventDetails } from "@/store/site-monitor/types";
import { axiosInstance } from "@/axios.instance";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";

import api from "@/services/api.service";
import { SessionResource } from "@/store/sessions/types";
import ZendeskHelp from "./ZendeskHelp.vue";
import Matrix from "@/types/sv-data/media-matrix/Matrix";

const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const SessionStore = namespace("sessions");
const Tours = namespace("tours");

@Component({
	components: {
		"matrix-container": MatrixContainer,
		"device-controller": DeviceController,
		"zendeskHelp" : ZendeskHelp,
	}
})
export default class Cameras extends Vue {
	$refs!: {
		expandedCamera: any;
	};

	@SMCameras.Getter("getLayouts") layouts!: any[];
	@SMCameras.Getter("getLayoutIndex") getLayoutIndex!: number;
	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;
	@SiteMonitor.Getter("getIsController") isController: any;

	@SMCameras.Action saveMatrix: any;
	@SMCameras.Action fetchMatrixForEvent: any;

	@SMCameras.Mutation clearMatrixContents: any;
	@SMCameras.Mutation setRequiresEventDetails: any;
	@SMCameras.Mutation setMediaMatrixIsNew: any;
	@SMCameras.Mutation setFetchingMatrix: any;
	@SMCameras.Mutation setPushContents: any;
	@SMCameras.Mutation setLayoutIndex: any;
	@SMCameras.Mutation setShownAuditClips: any;

	@SMCameras.Mutation setDeviceControllerCameras: (cameras: any[]) => void;
	@SMCameras.Mutation setDeviceControllerClips: (clips: any[]) => void;
	@SMCameras.Mutation setDeviceControllerOutputs: (outputs: any[]) => void;
	@SMCameras.Mutation setDeviceControllerAudioDevices: (audioDevices: any[]) => void;

	@SMCameras.Getter("getDeviceControllerCameras") cameras!: any[];
	@SMCameras.Getter("getDeviceControllerClips") clips!: ClipType[];
	@SMCameras.Getter("getAreaCameras") areaCameras!: CameraType[];

	@SMCameras.Getter getMatrixPushContents: any;
	@SMCameras.Getter getMatrixContents: any;
	@SMCameras.Getter getAllMatrixContents: any;

	@SessionStore.Action updateSession: any;
	@SessionStore.Action clearSessions: any;

	@SMCameras.Mutation setLinkRecord: any;
	@SMCameras.Mutation setWaitingForEvent: any;

	@SMCameras.Mutation setAwaitingCamera: any;
	@SMCameras.Mutation setAwaitingClip: any;

	@SiteMonitor.Action createAuditRecord: any;

	@Tours.Getter tourInProgress: any;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	@SiteMonitor.Action private fetchDeviceServerDetails: (deviceIds: number[]) => Promise<void>;
	@SiteMonitor.Getter("getIsCameraEnabled") isCameraEnabled: (deviceId: number) => boolean;

	private updateTimer: NodeJS.Timeout | null = null;
	private loadedEventId: number = 0;
	private loadedMatrix: boolean = false;
	private showMenu: boolean = true;

	private get isClearEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "Clear"]);
	}

	private get featureHideMenuEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "HideMenu"]);
	}

	private get hideMenuDefault(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "HideMenu", "CollapseByDefault"]);
	}

	private get displayMediaMatrixContent(): boolean {
		return !!this.eventDetails && !this.eventDetails.eventClosed
	}

	private async clearAllMatrixCells() {
		var matrix = this.getAllMatrixContents;
		matrix = Object.entries(matrix);

		matrix.forEach(cell => {
			this.setPushContents(
				{
					index: cell[0],
					newContents: {},
					eventID: this.eventId
			});
		});

		const auditRecord = {
			eventId: this.eventId,
			eventRecordTypeId: 4,
			details: "Media Matrix Cleared"
		}

		await this.createAuditRecord(auditRecord);
	}

	public get layoutIndex() {
		return this.getLayoutIndex;
	}

	public get layout() {
		return this.layouts[this.layoutIndex];
	}

	public get layoutHorizontal() {
		return this.layouts[this.layoutIndex][0];
	}

	public get layoutVertical() {
		return this.layouts[this.layoutIndex][1];
	}

	public get cameraRowStyle() {
		return {
			display: "grid",
			"grid-template-rows": 1 / this.layoutHorizontal,
			"grid-template-columns": 1 / this.layoutVertical
		};
	}

	public get eventId() {
		return this.eventDetails ? this.eventDetails.eventID : 0;
	}

	public created() {
		this.clearMatrixContents();
		this.setRequiresEventDetails(true);
		this.setMediaMatrixIsNew(false);
	}

	public mounted() {
		if(this.hideMenuDefault) {
			this.showMenu = false;
		}

		this.loadEvent(this.eventId);
		this.updateTimer = setInterval(() => this.update(), 2000);
	}

	public destroyed() {
		if (this.updateTimer != null) {
			clearInterval(this.updateTimer);
		}
		this.setLinkRecord(null);
	}

	// Using "deep" to ensure all updates to matrixContents are seen.
	@Watch("getAllMatrixContents", {deep: true})
	public onMatrixUpdated(matrixContents: any) {
		let clipsLoaded: Array<string> = [];

		for (let i in matrixContents) {
			if (matrixContents[i].clip !== undefined) {
				let clipIdentifier = matrixContents[i].clip.clip.UniqueFileIdentifier;
				clipsLoaded.push(clipIdentifier);
			}
		}

		this.setShownAuditClips(clipsLoaded);
	}

	@Watch("isController")
	async onIsControllerChanged(value: boolean, oldValue: boolean) {
		if (value && this.eventId > 0 && this.loadedMatrix) {
			await this.updateMatrix(this.eventId);
		}
	}

	@Watch("eventId")
	public onEventChanged(eventId: number, oldEventId: number) {
		this.setWaitingForEvent(eventId == 0);

		this.clearMatrixContents();
		this.setMediaMatrixIsNew(false);
		this.loadEvent(this.eventId);
	}

	async updateMatrix(eventId: number): Promise<void> {
		this.setMediaMatrixIsNew(false);
		this.setFetchingMatrix(true);
		const matrix = await api.matrixGet(eventId);

		if (matrix) {
			if (this.isController) {
				// Check if any cell has non-empty data, if so, consider new.
				const allBlank = !matrix.cells.some(
					cell =>
						cell.contents !== "{}" &&
						cell.contents !== '{"clip":null,"camera":null}'
				);
				const isNew = matrix.cells.length == 0 || allBlank;

				// Mark for default setup if has no cells or all cells are empty.
				this.setMediaMatrixIsNew(isNew);
				if (!isNew) {
					console.log("Got existing matrix ");
					this.setMatrix(matrix);
				} else {
					console.log("Empty matrix");
				}
			} else {
				this.setMediaMatrixIsNew(false);
				this.setMatrix(matrix);
			}
		} else {
			console.log("New matrix for event " + eventId);

			if (this.isController) {
				let layout = this.layout;

				// Save new matrix
				await api.matrixSave({
					eventID: this.eventId,
					matrixColumns: layout[0],
					matrixRows: layout[1],
					cells: []
				});

				this.setMediaMatrixIsNew(true);
			} else {
				console.log("Not the controller - matrix will not be saved");
			}
			this.loadedMatrix = true;
		}
	}

	async loadEvent(eventId: number) {
		if (this.loadedEventId == eventId) {
			return;
		}

		this.loadedEventId = eventId;

		this.clearMatrixContents();
		if (eventId != undefined && eventId != 0) {
			// update our session keys for audit - we pass the baseURL in here because we sometimes encounter a situation where the axios baseURL
			// hasn't been updated to point at our remote region
			await this.updateSession({
				resourceId: SessionResource.AuditServiceSession,
				eventId: eventId
			});
			await this.updateSession({
				resourceId: SessionResource.DeviceServiceSession,
				eventId: eventId
			});

			await this.updateMatrix(eventId);
		} else {
			this.setDeviceControllerCameras([]);
			this.setDeviceControllerClips([]);
			this.setDeviceControllerOutputs([]);
			this.setDeviceControllerAudioDevices([]);

			this.setAwaitingCamera(null);
			this.setAwaitingClip(null);

			this.setLinkRecord(null);

			this.resetBaseUrl();
			this.loadedMatrix = false;
		}
	}

	public async setMatrix(matrix: Matrix): Promise<void> {
		console.log("Setting up matrix ... ");

		const layoutIndex = this.layouts.findIndex(
			layout =>
				layout[0] == matrix.matrixRows && layout[1] == matrix.matrixColumns
		);

		if (layoutIndex > -1) {
			this.setLayoutIndex(layoutIndex)
		}

		if (matrix.cells != null) {

			const cells =  matrix.cells.map(cell =>	JSON.parse(cell.contents));
			const deviceIds = cells.filter(cell => !!cell.camera).map(cell => cell.camera.deviceId);
			await this.fetchDeviceServerDetails(deviceIds);

			matrix.cells.forEach((cell, index) => {
				// Try to parse the cell contents
				let contents = cells[index];

				// If the cell is empty/invalid, don't do anything
				if (!contents) {
					return;
				}

				//Set contents of cell.

				// If camera, try to find camera in list, but if not, open anyway.
				// Do not get new event record.
				// Only send event record if user is controller.

				// If clip try to find in list, but if not, open anyway.
				let currentContents = this.getMatrixContents(cell.cellIndex);

				if (contents.camera) {
					if (
						currentContents == null ||
						currentContents.camera == null ||
						currentContents.camera.objectID != contents.camera.deviceId
					) {
						// Find an existing nearbyCamera or areaCamera
						const nearbyCameraList: any[] = this.cameras.filter(
							camera => camera.objectId == contents.camera.deviceId
						);
						const areaCameraList: any[] = this.areaCameras.filter(
							camera => camera.objectID == contents.camera.deviceId
						);

						const haveNearbyCamera = nearbyCameraList != null && nearbyCameraList.length > 0;
						const haveAreaCamera = areaCameraList != null && areaCameraList.length > 0
						let haveCameras = haveNearbyCamera || haveAreaCamera;

						let camera = null;

						if (haveCameras) {
							camera = haveNearbyCamera ? nearbyCameraList[0] : areaCameraList[0];
						} else {
							camera = {
								objectID: contents.camera.deviceId,
								title: ""
							};
						}

						const objectId = camera.hasOwnProperty('objectID') ? camera.objectID : camera.objectId;

						if (this.isCameraEnabled(objectId)) {
							this.setPushContents({
								index: cell.cellIndex,
								newContents: {
									camera
								},
								eventId: this.eventId
							});
						}
					}
				} else if (contents.clip) {
					if (
						currentContents == null ||
						currentContents.clip == null ||
						currentContents.clip.clip.UniqueFileIdentifier != contents.clip.UniqueFileIdentifier
					) {
						this.setPushContents({
							index: cell.cellIndex,
							newContents: {
								clip: {
									eventRecordID: contents.clip.eventRecordID,
									clip: {
										FileIdentifier: contents.clip.fileIdentifier,
										UniqueFileIdentifier: contents.clip.uniqueFileIdentifier
									}
								}
							},
							eventId: this.eventId
						});
					}
				} else {
					if (
						currentContents != null &&
						(currentContents.camera != null || currentContents.clip != null)
					) {
						this.setPushContents({
							index: cell.cellIndex,
							newContents: {},
							eventId: this.eventId
						});
					}
				}
			});
		}
	}

	public async update() {
		if (!this.isController && this.eventId != undefined && this.eventId > 0) {
			let matrix = await this.fetchMatrixForEvent(this.eventId);
			if (matrix) {
				this.setMatrix(matrix);
			}
		}
	}

	public async layoutChanged(evt) {
		const newLayoutIndex = parseInt(evt.target.value);

		if (this.eventId > 0) {
			let [matrixColumns, matrixRows] = this.layouts[newLayoutIndex];
			await this.saveMatrix({
				eventID: this.eventId,
				matrixColumns,
				matrixRows
			});
		}

		// layout change will trigger '/Matrix/Cell' updates from MatrixContainer
		// waiting for matrix update to finish first and change layout after that,
		// otherwise increase of size will cause errors during /Matrix/Cell reqs
		this.setLayoutIndex(newLayoutIndex);
	}

	public cameraColStyle(layoutIndex: number) {
		let hindex = Math.floor(layoutIndex / this.layoutHorizontal) + 1;
		let vindex = (layoutIndex % this.layoutHorizontal) + 1;

		let style = {
			margin: "2px",
			"grid-column-start": vindex,
			"grid-column-end": vindex,
			"grid-row-start": hindex,
			"grid-row-end": hindex
		};

		return style;
	}

	private expandedItem: HTMLElement | null = null;
	private expandParent: HTMLElement | null = null;
	private itemExpanded: boolean = false;

	public expand(matrixElement: HTMLElement) {
		if (this.itemExpanded) {
			this.itemExpanded = false;
			this.expandParent.appendChild(matrixElement);
			this.expandParent = null;
			this.expandedItem = null;
		} else {
			this.expandedItem = matrixElement;
			this.itemExpanded = true;
			this.expandParent = matrixElement.parentElement;
			this.$refs.expandedCamera.appendChild(matrixElement);
		}
	}

	public contract(matrixElement: HTMLElement) {
		if (this.itemExpanded && this.expandedItem == matrixElement) {
			this.itemExpanded = false;
			if (this.expandParent != null) {
				this.expandParent.appendChild(matrixElement);
				this.expandParent = null;
			}
		}
	}

	public resetBaseUrl() {
		axiosInstance.prototype.resetBackToOriginalBaseUrl();
	}
}
