import { GetterTree } from "vuex";
import { RootState } from "../types";
import { Schedule, ScheduleState } from "./types";

export const getters: GetterTree<ScheduleState, RootState> = {
    getScheduleByIdAndDate: state => (scheduleId: string, scheduleStart: string) => {
        return state.schedules.find((schedule: Schedule) =>
            schedule.scheduleId === scheduleId && schedule.startDateTime === scheduleStart
    )},
    isScheduleTypeSelected: state => (scheduleTypeId: number) =>
        state.selectedScheduleTypes.find(x => x == scheduleTypeId) != null,
};
