
import { Component, Watch } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { FeaturesList } from "@/store/types";
import SiteMonitorHeader from "@/components/SiteMonitorHeader.vue";
import EnhancedMap from "@/components/EnhancedMap.vue";
import EventTaskContainer from "@/components/tasks/EventTaskContainer.vue";
import EventClose from "@/components/EventClose.vue";
import EventRaise from "@/components/EventRaise.vue";
import AreaNotes from "@/components/AreaNotes.vue";
import EventSharing from "@/components/EventSharing.vue";
import EventLeave from "@/components/EventLeave.vue";
import IncidentReport from "@/components/IncidentReport.vue";
import ErrorWarning from "@/components/ErrorWarning.vue";
import EventEnded from "@/components/EventEnded.vue";
import ForceRestore from "@/components/ForceRestore.vue";
import InactivityWarning from "@/components/site-monitor/InactivityWarning.vue";
import AreaNotesModal from "@/components/area-notes/AreaNotesModal.vue";
import PutEventOnTestModal from "@/components/site-monitor/PutEventOnTestModal.vue";
import FieldOps from './FieldOps.vue';
import EnhancedSingleMap from '@/components/EnhancedSingleMap.vue';
import EqStatusInfoBar from '@/components/EqStatusInfoBar.vue';
import { SessionResource } from "@/store/sessions/types";
import { AuditService } from "@/services/auditService";
import { EventDetails, EventRecord } from "@/store/site-monitor/types";
import { CameraType, DeviceControllerOutput } from "@/store/site-monitor-cameras/types";
import { MapLayerItemTypeIds } from "@/store/map-layers/types";
import api from "@/services/api.service";
import EnhancedMapSidebar from '@/components/EnhancedMapSidebar.vue';
import SiteMonitorMixin from "@/mixins/SiteMonitorMixin";
import AlarmPointDialog from "@/components/alarm-points/AlarmPointDialog.vue";
import InvolvedParties from "@/components/site-monitor/InvolvedParties.vue";
import AreaEventHistoryPopout from "@/components/area-event-history/AreaEventHistoryPopout.vue";

const UserContext = namespace("userContext");
const SiteMonitorState = namespace("siteMonitor");
const Eventqueue = namespace("eventqueue");
const Tasks = namespace("tasks");
const FieldOpsStore = namespace("fieldOps");
const Session = namespace("sessions");
const SMCameras = namespace("siteMonitorCameras");

@Component({
	components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"site-monitor-header": SiteMonitorHeader,
		"event-records": () => import("@/components/EventRecords.vue"),
		"event-records-ccure": () => import("@/components/EventRecordsCCure.vue"),
		"enhanced-map": EnhancedMap,
		"enhanced-single-map": EnhancedSingleMap,
		"task-container": EventTaskContainer,
		"event-close": EventClose,
		"event-raise": EventRaise,
		"area-notes": AreaNotes,
		"area-notes-modal": AreaNotesModal,
		"event-sharing": EventSharing,
		"event-leave": EventLeave,
		"incident-report": IncidentReport,
		"error-warning": ErrorWarning,
		"event-ended": EventEnded,
		"force-restore": ForceRestore,
		"inactivity-warning": InactivityWarning,
		"put-event-on-test-modal": PutEventOnTestModal,
		"field-ops": FieldOps,
		"eq-status-info-bar": EqStatusInfoBar,
		"enhanced-map-side-bar": EnhancedMapSidebar,
		"alarm-point-dialog": AlarmPointDialog,
		"involved-parties": InvolvedParties,
		"area-event-history-popout": AreaEventHistoryPopout,
	}
})
export default class SiteMonitor extends SiteMonitorMixin {
	@SiteMonitorState.Getter("getActiveMapItems") mapItems: any;
	@SiteMonitorState.Getter("getFilteredRecords") filteredRecords!: EventRecord[];

	@Eventqueue.Mutation setJoiningEventID: any;
	@Eventqueue.Getter getJoiningEventID: any;

	@Action openMediaMatrix: any;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	@SMCameras.Action fetchAreaCameras: (params: { groupID: number, pageNumber: number, paginated: boolean }) => Promise<void>;
	@SMCameras.Action fetchAreaOutputs: (params: { groupID: number, pageNumber: number, paginated: boolean }) => Promise<void>;
	@SMCameras.Getter("getDeviceControllerCameras") nearbyCameras!: CameraType[];
	@SMCameras.Getter("getAreaCameras") areaCameras!: CameraType[];
	@SMCameras.State("areaOutputs") areaOutputs!: DeviceControllerOutput[];
	@Session.Getter getSession: (resourceId: number) => string;
	private auditService: AuditService;
	private nearbyDevicePresent: boolean | null = null;
	private mediaMatrixOpened: boolean = false;

	public created(): void {
		// Call the base created method.
		this.onCreate();

		if (this.getJoiningEventID != this.$route.params.eventId) {
			// If a user navigates directly to this event, ensure the event is being handled
			this.handleEvent({
				eventID: parseInt(this.$route.params.eventId),
				pickupMethod: "Direct navigation to event"
			});
		} else {
			// Otherwise we have already handled this event, clear out the joining variable
			this.setJoiningEventID(null);
		}
	}

	@Watch("mapItems")
	private mapItemsWatcher(value: any | null, _oldValue: any | null): void {
		let cameras = value.filter(device => device.itemType === MapLayerItemTypeIds.Camera);
		let audioDevices = value.filter(device => device.itemType === MapLayerItemTypeIds.Audio);
		let outputs = value.filter(device =>
							device.itemType === MapLayerItemTypeIds.Relay ||
							device.itemType === MapLayerItemTypeIds.Output);

		this.nearbyDevicePresent = cameras.length > 0 || audioDevices.length > 0 || outputs.length > 0;

        // If we already have the EventDetails and haven't opened MediaMatrix
		// any nearby devices will open it so check again.
		if (this.nearbyDevicePresent && this.eventDetails && !this.mediaMatrixOpened) {
			this.displayMediaMatrix(this.eventDetails.groupID);
		}
	}

	@Watch("eventDetails")
	private async eventDetailsWatcher(newValue: EventDetails | null, _oldValue: EventDetails | null): Promise<void> {
		if (!!newValue && !!newValue.groupID && _oldValue?.groupID != newValue?.groupID) {
			await this.displayMediaMatrix(newValue.groupID);
		}
	}

	private async displayMediaMatrix(groupId: number): Promise<void> {
		const canOpenMediaMatrix = this.getFeature(["Alarms", "MediaMatrix"]);
		const shouldNotAutoOpen = this.getFeature(["Alarms", "MediaMatrix", "NoAutoOpen"]);

		if (!canOpenMediaMatrix || shouldNotAutoOpen) {
			return;
		}

		await this.fetchAreaCameras({ groupID: groupId, pageNumber: 1, paginated: true });
		await this.fetchAreaOutputs({ groupID: groupId, pageNumber: 1, paginated: true });

		const onlyShowMatrixWithData = this.getFeature(['Alarms', 'MediaMatrix', 'OnlyShowMatrixWithData']);

		if (onlyShowMatrixWithData) {
			try {
				if (!(await this.hasMediaItems()) && !(await this.checkAnyDevicesAvailableForMediaMatrix())) {
					return;
				}
			} catch(err) {
				this.showMediaMatrix();
				return;
			}
		}
		this.showMediaMatrix();
	}

	private showMediaMatrix(): void {
		if (!this.mediaMatrixOpened){
			this.mediaMatrixOpened = true;
			this.openMediaMatrix();
		}
	}

	private async hasMediaItems(): Promise<boolean> {
		const canGetMediaList = this.getFeature(['Alarms', 'MediaMatrix', 'ClipsList']);
		let hasMediaItems = false;

		if (canGetMediaList) {
			const response = await this.getMediaList();
			hasMediaItems = response?.data?.length > 0 ?? false;
		}

		return hasMediaItems;
	}

	private async checkAnyDevicesAvailableForMediaMatrix(): Promise<boolean> {
		return this.nearbyDevicePresent ||
			this.hasLinkedCameras() ||
			(this.areaCameras && this.areaCameras.length > 0 ) ||
			(await this.hasAreaAudioDevices()) ||
			(this.areaOutputs && this.areaOutputs.length > 0)
	}

	private hasLinkedCameras(): boolean {
		if (this.filteredRecords) {
			const record = this.filteredRecords.firstOrDefault(record => record.attachedCameras && record.attachedCameras.length > 0);
			return !!record;
		}
		return false;
	}

	private async hasAreaAudioDevices(): Promise<boolean> {
		const canGetAudioDevices = this.getFeature(["Alarms", "MediaMatrix", "AudioList"]);

		if (canGetAudioDevices) {
			const result = await api.fetchAudioDevicesByGroupId(this.eventDetails.groupID);
			return result?.length > 0 ?? false;
		}
		return false;
	}

	private async getMediaList(): Promise<any> {
		const auth = this.getSession(SessionResource.AuditServiceSession);
		this.auditService = this.getAuditService();
		return await this.auditService.mediaList(auth, this.eventDetails.initialEventRecordId);
	}

	private getAuditService(): AuditService {
		return new AuditService(api.getAuditServiceAddress(), this.eventDetails?.auditEndpoint);
	}
}
