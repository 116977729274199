import api from "@/services/api.schedules.service";
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { Schedule, ScheduleState } from "./types";

export const actions: ActionTree<ScheduleState, RootState> = {
	async filterSchedules({ commit }, { filterString, selectedGroup, selectedScheduleTypes, startDate, endDate, enabled, cancelToken }) {
		commit("setIsLoading", true);
		const schedules = await api.filterSchedules(
			filterString,
			selectedGroup,
			selectedScheduleTypes,
			startDate,
			endDate,
			enabled,
			cancelToken);
		if (schedules) {
			let data: Schedule[] = schedules;
			commit("setSchedules", data);
		}
	},

	async loadScheduleTypes({ commit }) {
		const scheduleTypes = await api.getAllScheduleTypes();
		if (scheduleTypes) {
			commit("setScheduleTypes", scheduleTypes);
		}
	},

	async addOrUpdateSchedule({ commit }, schedule: Schedule) {
		await api.createOrUpdateSchedule(schedule);
	},

	async deleteSchedule({ commit }, { scheduleId }) {
		await api.deleteSchedule(scheduleId);
	}
};
