import { ActionTree } from "vuex";
import {
	CSVFormHandler,
} from "./types";
import { cloneDeep, get }from "lodash"

function validateCells({ array, name, isOrigin = false, formData, existErrors }) {
	return array.reduce((errors, data) => {
		const validationCell = data?.csvValidation?.validationCell

		const validateOption = { currentElement: data, formData }
		const validateResult = (validationCell && validationCell(validateOption)) || {};

		const errorData = {
			message: "",
			title: data.title,
			isValid: true,
			uniqId: data.uniqId,
			rowId: data.rowId,
			indexCell: data.indexCell,
			...validateResult
		};

		if (!errorData.isValid) {
			if(errors[name]) {
				errors[name][data.uniqId] = errorData
			} else {
				errors[name] = { [data.uniqId]: errorData }
			}
		}

		return errors
	}, cloneDeep(existErrors))
}

export const actions: ActionTree<CSVFormHandler, any> = {

	async resetErrorsForm({ commit },{ name }) {
		commit("resetErrorsForm", { name })
	},

	async removeRow({ state, commit, dispatch }, { row, name }) {
		const currentCsvData = get(state, `csvData[${name}]`)
		const rowId = get(row, "[0].rowId")

		if(rowId === undefined) return

		await dispatch("setDataCsv", { csvData: currentCsvData.filter((el , i) => i !== rowId), name })
		await dispatch("resetErrorsForm", { name })
	},

	async addNewRowCsv({ state, dispatch }, { name }) {
		const currentCsvData = get(state, `csvData[${name}]`)
		const firstElement = get(currentCsvData, "[0]")
		if(firstElement) {
			const emptyElement = Object.keys(firstElement)
										.reduce((acc, cur) => ({
											...acc,
											[cur]: ""
										}) ,{})

			const newCsvData = cloneDeep([...currentCsvData, emptyElement])
			await dispatch("setDataCsv", { csvData: newCsvData, name })
		}
	},

	setModalItemsCsv({ commit }, option) {
		commit("setModalItems", option);
	},

	setDataCsv({ commit }, { csvData, name }) {
		commit("setCsvData", { csvData: cloneDeep(csvData), name });
		commit("setOriginalCsvData", { csvData: cloneDeep(csvData), name });
	},

	async setValue({ commit }, { value, data, name }) {
		commit("updateCsvValue", { value, data, name });
	},

	validateAll: ({ getters, state, commit }, { name, isOrigin }) => {
		const arr = getters.formData({ name, isOrigin })
		const newArr = arr.flat().filter(data => data?.csvValidation?.validationCell)
		const errors = validateCells({
			array: newArr,
			name,
			 formData: getters.formData({ name }),
			 existErrors: state.errorsForm
			})

		commit("setErrors", errors)
	},

	validateCell({ commit, getters }, { data, name, isOrigin }: any): void {
		const validationCell = data?.csvValidation?.validationCell;
		const validateOption = { currentElement: data, formData: getters.formData({ name, isOrigin }) }
		const validateResult = (validationCell && validationCell(validateOption)) || {};
		const errorData = {
			message: "",
			title: data.title,
			isValid: true,
			uniqId: data.uniqId,
			rowId: data.rowId,
			indexCell: data.indexCell,
			...validateResult
		};

		if (errorData.isValid) {
			commit("deleteErrorById", { uniqId: data.uniqId, name });
		} else {
			commit("setErrorsForm", { error: errorData, name });
		}
	}
};
