
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import EventOutcomeSelector from "@/components/EventOutcomeSelector.vue";
import vSelect from "vue-select";
import { Datetime } from "vue-datetime";
import moment from "moment";
import { formatDateMixin } from "@/mixins";
import TemplateReportBuilder from "./TemplateReportBuilder.vue";
import AlarmPointSelect from "@/components/alarm-points/AlarmPointSelect.vue";
import InsightsGraph from "./InsightsGraph.vue"
import { stringify } from "qs";
const Reports = namespace("reports");
const { relativeHoursDateFormatted } = formatDateMixin.methods;

// Utility extensions
import "@/scripts/array-operations";
import AreaTreeSelect from '../form/AreaTreeSelect.vue';

@Component({
	components: {
		"vue-select": vSelect,
		datetime: Datetime,
		"event-outcome-selector": EventOutcomeSelector,
		"template-report-builder": TemplateReportBuilder,
		"insights-graph": InsightsGraph,
		"area-tree-select": AreaTreeSelect,
		"alarm-point-select": AlarmPointSelect
	}
})
export default class ReportBuilder extends Vue {
	@Reports.Action downloadReport: any;
	@Reports.Action fetchReportServiceEndpoint: any;
	@Reports.Getter("getReport") report;
	@Reports.Getter("getCurrentReportUrl") currentReportUrl;
	@Reports.Getter("getReportLoadingStatus") loading;
	@Reports.Getter("getReportErrorStatus") error;
	@Reports.Getter("getReportApiEndpoint") reportApiEndpoint;
	@Reports.Getter("getReportTypes") reportTypes;
	@Reports.Mutation setRelativeHoursToReport: any;
	@Reports.Mutation resetState: any;
	@Reports.Mutation setIsDateRangeRelative: (isRelative: boolean) => void;
	@Reports.State isDateRangeRelative: boolean;
	@Reports.State("RelativeHoursToReport") relativeHoursToReport: number;

	$refs: {
		chartContainer: any;
		insights: any;
	};

	private reportPermalink = "";
	private showPermalinkCopy = false;
	private filterModal: boolean = false;
	private filterLockStatus = false;
	public chartType = "ColumnChart";
	private timeZone = "UTC";
	private errorMessage: string = "";
	private selectedUrl: string = "";
	private relativeHoursOptions = [
		{ label: "4 Hours", value: 4 },
		{ label: "8 Hours", value: 8 },
		{ label: "12 Hours", value: 12 },
		{ label: "24 Hours", value: 24 },
		{ label: "7 Days", value: 168 },
		{ label: "14 Days", value: 336 },
		{ label: "30 Days", value: 720 },
		{ label: "60 Days", value: 1440 },
		{ label: "90 Days", value: 2160 },
		{ label: "180 Days", value: 4320 },
		{ label: "365 Days", value: 8760 }
	];

	// object to hold report filtering options
	public selectedFilters: any = {
		groupId: [],
		referenceId: [],
		responseId: [],
		eventTypeId: [],
		userId: [],
		eventOutcomeId: [],
		serverTypeEventNum: [],
		excludeAutoHandled: { id: 1, title: "Yes" },
		resultCount: { id: 100, title: "100" },
		startDate: relativeHoursDateFormatted(this.relativeHoursToReport),
		endDate: relativeHoursDateFormatted(0)
	};

	// used to store the filtering options that have been applied to the current report to be passed to the backend when requesting an export of the report data.
	private reportOptionsPayload = {};

	// Insights graph chart
	private get chartObject() {
		return {
			chartType: this.chartType,
			canDrilldown: true
		}
	}

	// set a flag as to if we have applied any filters
	private get filterApplied() {
		return (
			(this.selectedFilters.groupId && this.selectedFilters.groupId.length > 0) ||
			(this.selectedFilters.referenceId && this.selectedFilters.referenceId.length > 0) ||
			(this.selectedFilters.responseId && this.selectedFilters.responseId.length > 0) ||
			(this.selectedFilters.eventTypeId && this.selectedFilters.eventTypeId.length > 0) ||
			(this.selectedFilters.userId && this.selectedFilters.userId.length > 0) ||
			(this.selectedFilters.eventOutcomeId && this.selectedFilters.eventOutcomeId.length > 0) ||
			(this.selectedFilters.serverTypeEventNum && this.selectedFilters.serverTypeEventNum.length > 0) ||
			(this.selectedFilters.resultCount && this.selectedFilters.resultCount.id != 100) ||
			(this.selectedFilters.excludeAutoHandled && this.selectedFilters.excludeAutoHandled.id == 0)
		);
	}

	// return if the current report has any other graph types available
	private get chartOptionsAvailable() {
		return this.report.metricColumns && this.report.metricColumns.length > 0;
	}

	@Watch("report")
	// when a report is requested, update the chart type as set on the new report
	onReportChanged(newReport: any, oldReport: any) {
		this.chartType = newReport.chartType;
	}

	private async created() {
		await this.fetchReportServiceEndpoint();
	}

	private mounted() {
		this.setRelativeHoursToReport(this.relativeHoursOptions.find(h => h.label === "7 Days").value);
	}

	async destroyed() {
		this.resetState();
	}

	private goToReport(url) {
		if (this.selectedUrl === url) return;
		this.selectedUrl = url;

		if (!this.filterLockStatus) {
		  this.resetFilters();
		  this.$refs.insights.resetFilters();
		}

		this.$router.push(`/reports${url}`);
		this.chartType = this.report.chartType;
	}

	private updateChartType(type) {
		this.chartType = type;
	}

	private setSelectedRelativeHours(hours: number): void {
		this.setRelativeHoursToReport(+hours["value"]);
	}

	private async downloadReportCsv() {
		await this.downloadReport(this.reportOptionsPayload);
	}

	private copyReportLink() {
		// @TODO: REFACTOR - REMOVE DOM MANIPULATION
		var input = document.getElementById("link-copy-input") as HTMLInputElement;
		this.showPermalinkCopy = true;

		input.setAttribute("value", this.reportPermalink);
		input.select();
		var result = document.execCommand("copy");
	}

	private formatDate(dateStr: string): string {
		dateStr = this.fixDate(dateStr);
		let date = new Date(dateStr);

		let monthNames = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

		let day = date.getDate();
		let monthIndex = date.getMonth();
		let year = date.getFullYear();
		let hours = date.getHours();
		let mins = date.getMinutes();

		return day + " " + monthNames[monthIndex] + " " + year + " " + hours + ":" + (mins < 10 ? "0" : "") + mins;
	}

	private outcomeChanged(eventOutcomeId: number): void {
		if (eventOutcomeId) {
			this.selectedFilters.eventOutcomeId.splice(0, 9, { id: eventOutcomeId });
		} else {
			this.selectedFilters.eventOutcomeId = [];
		}
	}

	private callReport(): void {
		const filters = Object.assign({}, this.selectedFilters);

		filters.startDate = this.fixDate(this.selectedFilters.startDate);
		filters.endDate = this.fixDate(this.selectedFilters.endDate);
		this.setIsDateRangeRelative(false);
		this.$refs.insights.runV2Report(this.currentReportUrl, true, filters);
		this.filterModal = false;
	}

	private get getErrorMessage() {
		return this.errorMessage !== "" ? this.errorMessage : 'An error has occurred retrieving the requested report, please try again';
	}

	private resetFilters() {
		this.selectedFilters = {
			groupId: [],
			referenceId: [],
			responseId: [],
			eventTypeId: [],
			userId: [],
			eventOutcomeId: [],
			serverTypeEventNum: [],
			excludeAutoHandled: { id: 1, title: "Yes" },
			resultCount: { id: 100, title: "100" },
			startDate: relativeHoursDateFormatted(this.relativeHoursToReport),
			endDate: relativeHoursDateFormatted(0)
		};
	}

	private fixDate(date): string {
		let currentTz = date.includes("+") ? date.split('+')[1] : date.split('-')[1];
		currentTz = Number(currentTz.split(":")[0]);

		if (currentTz <= 14 && currentTz >= -14) {
			return date;
		}

		const dateTime = date.split('+')[0];
		let d = new Date(dateTime);
		d.setDate(d.getDate()-1);

		let stillUtc = moment.utc(d).toDate();
		let local = moment(stillUtc).local().format();
		return local;
	}


	private createReportUrl(reportUrl: string, urlFiltered: any): void {
		urlFiltered.startDate = encodeURIComponent(this.fixDate(urlFiltered.startDate));
		urlFiltered.endDate = encodeURIComponent(this.fixDate(urlFiltered.endDate));

		if (this.isDateRangeRelative) {
			delete urlFiltered.startDate;
			delete urlFiltered.endDate;
		}

		// Create the parameters to append to the reports URL
		reportUrl +=
			(stringify(urlFiltered, { encode: false }) ? `&${stringify(urlFiltered, { encode: false })}` : "") +
			`&timeZone=${this.timeZone}`;

		if (this.isDateRangeRelative) {
			reportUrl += `&relativeHours=${this.relativeHoursToReport}`;
		}

		// set the permalink variable so this report url can be copied by the user
		this.reportPermalink = `${window.location.origin}${window.location.pathname}#/reports${reportUrl}`;
	}

	private updatePermaLinkUrl(paramLinkParams: any): void {
		this.createReportUrl(paramLinkParams.reportUrl, paramLinkParams.urlFiltered);
		this.selectedUrl = paramLinkParams.reportUrl;
	}

	// report title is used to check permissions so this workaround is needed to change UI title
	private renameReportTitle(title: string): string {
		return title === 'Alarm Count by Outcome' ? 'Alarm Count by Category' : title;
	}

	private areaFilterUpdated(area: any): void {
		if (!area || !this.selectedFilters || this.selectedFilters["groupId"] === undefined) {
			return;
		}

		var areaIndex = this.selectedFilters["groupId"].map(a => a.id).indexOf(area.id);
		if (areaIndex >= 0) {
			this.selectedFilters["groupId"].splice(areaIndex, 1);
			return;
		}
		this.selectedFilters["groupId"].push(area);
	}
}
