import { GuardChat } from "./types";

export default function getDefaultState(): GuardChat {
	return {
		messages: [],
		numberOfUnread: 0,
		twoWayMessages: [],
		twoWayNumberOfUnread: 0
	};
}
