
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Multiselect from "vue-multiselect";
import { EventDetails } from "@/store/site-monitor/types";

const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		multiselect: Multiselect
	}
})
export default class EventRaise extends Vue {
	$refs!: {
		eventRaiseModal: any;
	};

	public raiseError: string = "";
	public selectedRoutingGroup: any = {};
	public groups: any[] = [];

	@Prop(Number) eventid?: number;

	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;
	@SiteMonitor.Getter("getEventRaiseShown") eventRaiseShown!: boolean;
	@SiteMonitor.Action changeEventRoutingGroup: any;
	@SiteMonitor.Action loadRoutingGroupsByEvent: any;
	@SiteMonitor.Action setEventDetails: any;
	@SiteMonitor.Mutation setEventRaiseShown: any;

	@Watch("eventRaiseShown")
	async onEventRaiseShownChanged(value: boolean) {
		if (value) {
			await this.show();
		} else {
			this.$refs.eventRaiseModal.hide();
		}
	}

	async show() {
		this.groups = await this.loadRoutingGroupsByEvent(this.eventDetails.eventID);
		this.$refs.eventRaiseModal.show();
	}

	mounted() {}

	async raiseEvent() {
		const data = {
			eventId: this.eventid,
			RoutingGroupId: this.selectedRoutingGroup.routingGroupID
		};

		var result = await this.changeEventRoutingGroup(data);

		if (result.success) {
			this.setEventRaiseShown(false);
			this.setEventDetails(null);

			this.$router.push({ path: "/event-queue" });
		}
	}

	cancelRaiseEvent() {
		this.setEventRaiseShown(false);
	}
}
