
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter, Mutation, Action } from "vuex-class";
import NavHeader from "@/components/NavHeader.vue";
import { UserPermissions, FeaturesList } from "@/store/types";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { ModalItem } from "@/components/table/generic-update-modal.vue";
import { get } from "lodash";
import { ContactRoleEx } from "@/store/user-management/types";
import api from "@/services/api.service";
import vselect3 from "vselect3";

const UserManagement = namespace("userManagement");

@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable
	}
})
export default class ContactsPage extends Vue {
	@Getter getPermissions: UserPermissions;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getUserId") public currentUserId!: number;
	@Getter getFeature: (featuresList: string[]) => boolean;

	// show or hide the page header
	@Prop({ default: true, type: Boolean }) showHeader;

	//show or hide the ID column in the table and modal
	@Prop({ default: true, type: Boolean }) showIdColumn;

	public roleList: ContactRoleEx[] = [];

	public columns: TableHeader[] = [
		{
			title: "Contact Role ID",
			key: "contactRoleId",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The contact role ID",
			searchable: false,
			visible: this.showIdColumn,
			dataType: "input",
			width: "130px"
		},
		{
			title: "Role Name",
			key: "role",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The role name",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true
		}
	];

	private modalItems: ModalItem[] = [
		{
			title: "Contact Role ID",
			key: "contactRoleId",
			dataType: "text",
			updateOnly: true,
			readOnly: true,
			visible: this.showIdColumn,
			order: 0
		},
		{
			title: "Role Name",
			key: "role",
			dataType: "text",
			readOnly: false,
			required: true,
			placeholder: "Manager",
			order: 1
		},
		{
			title:"External Ids",
			key:"externalIdsArray",
			dataType: "component",
			data: vselect3,
			props: {
				"create-option": id => id,
				placeholder: "External Ids",
				taggable: true, 
				appendToBody: true, 
				multiple: true,
			},
			required: false, 
			description: "External ids to match during syncing",
			visible: this.syncSetupEnabled,
			order: 2
		}
	];

	// Feature check for the SyncSystem feature flag.
	private get syncSetupEnabled(): boolean {
		return get(this.featuresList, ["Devices", "AdvancedSetup", "SyncSystem"]);
	}

	private async openUpdateModal(row) {}

	// Function to update the ContactRole object with JSON formatted string of External Ids.
	private SetContactRoleExternalIds(ids: string[]): string {
		let output = "[]";

		if (ids && ids.length > 0) {
			try
			{
				output = JSON.stringify(ids);
			} 
			catch(er)
			{
				console.error(er)
			}
		}

		return output;
	}

	private async updateContactRoleDetails(contactToUpdate: ContactRoleEx): Promise<void> {
		var roleIndex = this.roleList.findIndex(x => x.contactRoleId == contactToUpdate.contactRoleId);

		contactToUpdate.externalIds = this.SetContactRoleExternalIds(contactToUpdate.externalIdsArray);

		await api.updateContactRole(contactToUpdate);
		
		this.updateData();
	}

	private async newContactRoleDetails(roleToAdd: ContactRoleEx): Promise<void> {
		roleToAdd.externalIds = this.SetContactRoleExternalIds(roleToAdd.externalIdsArray);

		let newRole = await api.createContactRole(roleToAdd);
		newRole.externalIdsArray = roleToAdd.externalIdsArray;
		this.roleList.push(newRole);
	}

	private async deleteContactRoleDetails(roleToDelete: ContactRoleEx): Promise<void> {
		const updateIndex = this.roleList.findIndex((x: ContactRoleEx) => x.contactRoleId == roleToDelete.contactRoleId);

		if (updateIndex >= 0) {
			this.roleList.splice(updateIndex, 1);
		}

		await api.deleteContactRole(roleToDelete.contactRoleId);
	}

	private async updateData(): Promise<void> {
		const contactRoles = await api.loadContactRoleList();

		this.roleList = contactRoles.map(contactRole => {
			var externalIdList = [];
			if (contactRole.externalIds && contactRole.externalIds.length > 0)
			{
				try
				{
					externalIdList = JSON.parse(contactRole.externalIds)
				}
				catch(er)
				{
					console.error(er)
				}
			}

			return {
				...contactRole,
				externalIdsArray: externalIdList
			} as ContactRoleEx;
		});	
	}

	private get isSuiteEnabled(): boolean {
		return this.getFeature(["Suite"]);
	}

	async mounted() {
		await this.updateData();
	}
}
