
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import MobileEventSharingStatus from '@/types/sv-data/MobileEventSharingStatus';


@Component({
	components: {}
})
export default class EventSharingAsset extends Vue {
	@Prop()
	private assetShare: MobileEventSharingStatus

	@Prop()
	private iconStyle: string

	@Prop({default: false, type: Boolean})
	private nested: string

	private stateText(state: number): string {
		switch(state){
			case 1: //Maps to sent back we will use pending
				return "Pending";
			case 2:
				return "Accepted";
			case 3:
				return "Rejected";
			case 4:
				return "Left";
			case 5:
				return "Removed"
			case 6:
				return "Event Ended";
		}
	}

	@Emit("removeEventShare")
	private removeEventShare(){
		return this.assetShare;
	}

	private get stateClass(): string {
		let stateClass = this.nested ? "smallText " : "";
		if(this.assetShare.state != 1 && this.assetShare.state != 2){
			stateClass += "disabled text-muted"
		}
		return stateClass;
	}

	private get circleStateClass() {
		let stateClass = this.nested ? "smallCirlce " :"circle ";
		if(this.assetShare.state != 1 && this.assetShare.state != 2){
			stateClass += "disabled text-muted"
		}
		return stateClass;
	}
}
