import { ResponseAction } from "@/store/response-actions/types";
import { Vue, Component } from "vue-property-decorator";

export interface AutomationBadgeInfo
{
    objectId: number,
    deviceTitle: string,
    description: string
}

@Component({})
export default class AutomationMixin extends Vue {
    public retrieveAutomationBadges(automations: ResponseAction[]): AutomationBadgeInfo[]
    {
        var badges = [];
        if(automations && automations.length > 0)
        {
            automations.forEach(automation => {
                var badgeEntry = badges.find(b => b.objectID == automation.objectID);
                if (badgeEntry)
                {
                    badgeEntry.description = badgeEntry.description + ", " + automation.description
                }
                else
                {
                    badges.push({ objectID: automation.objectID, deviceTitle: automation.deviceTitle, description: automation.description })
                }
            })
        }
        return badges;
    }
}
