
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { IconSet } from "@/store/mobile-raise-templates/types";
import RoundIconButton from "./RoundIconButton.vue";

const MobileRaiseTemplates = namespace("mobileRaiseTemplates");

@Component({
	components: {
		"round-button": RoundIconButton
	}
})
export default class IconsSelector extends Vue {
	@Prop() isVisible: boolean;
	@MobileRaiseTemplates.Getter searchIconSets: (searchTerm: string) => IconSet[];

	private iconsSearchTerm: string = "";

	private onIconClick(iconSetID: number) {
		this.$emit("iconSelected", iconSetID);
		this.$emit("hide");
	}

	private onIconResetToDefault() {
		this.$emit("iconSelected", null);
	}

	private get filteredIconSets() {
		return this.searchIconSets(this.iconsSearchTerm);
	}
}
