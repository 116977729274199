
import { Component, Vue, Prop } from "vue-property-decorator";
import api from "@/services/api.service";
import { KeyContact } from '@/store/user-management/types';
import { ValidatePinCodeRequest } from '@/store/types';

@Component({
})

export default class ValidatePinCodeModal extends Vue {
	@Prop() public contact: KeyContact;
	@Prop() public eventId: number;

	private modalVisible: boolean = false;
	private pinCodeToValidate: string = null;
	private validationResult: string = null;
	private validating: boolean = false;

	private showValidatePinCodeModal(): void {
		this.modalVisible = true;
	}

	private async validatePinCode(): Promise<void> {
		if (this.validating) {
			return;
		}

		this.validating = true;

		const validatePinCodeRequest: ValidatePinCodeRequest = {
			UserId: this.contact.userId,
			EventId: this.eventId,
			PinCode: this.pinCodeToValidate
		}

		let pinCodeIsValid: boolean = false;

		// Only make the api call if a pin code has been provided
		if (this.pinCodeToValidate) {
			try {
				pinCodeIsValid = await api.validatePinCode(validatePinCodeRequest);
			}
			catch (ex) {
				console.log("Unexpected error ocurred validating Pin Code: " + ex)
			}
		}

		this.validating = false;

		this.validationResult = pinCodeIsValid ? "valid" : "invalid";
	}

	private resetModalValues(): void {
		this.modalVisible = false;
		this.pinCodeToValidate = null;
		this.validationResult = null;
		this.validating = false;
	}

	beforeDestroy(): void {
		this.resetModalValues();
	}
}
