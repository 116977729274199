






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Datetime } from 'vue-datetime';
import vSelect from "vue-select";

@Component({
	components: {
		"vue-select": vSelect,
		'datetime': Datetime
	}
})
export default class DatePicker extends Vue {

	$refs: {
		minute: HTMLInputElement,
		hour: HTMLInputElement,
		day: HTMLInputElement,
		month: HTMLInputElement,
		year: HTMLInputElement
	}

	@Prop(String)
	value: string;

	public dateValueParsed: Date = null;

	mounted() {
		if (this.value) {
			this.dateValueParsed = new Date(this.value);
			this.ampm = this.dateValueParsed.getUTCHours() >= 12 ? "PM" : "AM";
		}
	}

	@Watch("value")
	valueChanged(value: string) {
		if (value) {
			this.dateValueParsed = new Date(value);
			this.ampm = this.dateValueParsed.getUTCHours() >= 12 ? "PM" : "AM";
		}
	}

	get dateValue() {
		return this.dateValueParsed;
	}

	get day() {
		if (!this.dateValue)
			return "";

		return this.dateValue.getUTCDate();
	}
	get month() {
		if (!this.dateValue)
			return "";
		return this.dateValue.getUTCMonth() + 1;
	}
	get year() {
		if (!this.dateValue)
			return "";
		return this.dateValue.getUTCFullYear();
	}

	get hour() {
		if (!this.dateValue)
			return "";

		let hours = this.dateValue.getUTCHours();
		if (hours > 12)
			return hours - 12;

		return hours
	}
	get minute() {
		if (!this.dateValue)
			return "";
		return this.dateValue.getUTCMinutes();
	}

	ampm: any;
	ampmList = ["AM", "PM"];
	updateDate() {
		if (!this.dateValue)
			return;

		let hours = parseInt(this.$refs.hour.value);
		if (this.ampm == "PM" && hours < 12)
			hours += 12;
		if (this.ampm == "AM" && hours == 12)
			hours = 0;

		const newDate = new Date(Date.UTC(parseInt(this.$refs.year.value),
			parseInt(this.$refs.month.value) - 1,
			parseInt(this.$refs.day.value),
			hours,
			parseInt(this.$refs.minute.value),
			0));
		const isoString = newDate.toISOString();
		this.$emit('input', isoString);
	}

	addDate() {
		const currentDate = new Date();
		currentDate.setTime(currentDate.getTime() - currentDate.getTimezoneOffset() * 60 * 1000);
		const isoString = currentDate.toISOString();

		this.$emit('input', isoString);
	}

	clearDate() {
		this.$emit('input', "");
	}

	open_calender(event, which) {
		this.$refs[which].open(event);
	}
}

