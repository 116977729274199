enum TaskTypeIds {
	Basic = 1,
	Input = 2,
	YesNo = 3,
	URL = 5,
	MassNotification = 6,
	Choices = 7,
	CallContact = 8,
	DownloadSitRep = 9,
	CCure = 10,
	ShowAudit = 11,
	DynamicDataList = 12,
	ControlRelay = 13,
	RaiseToCaseManagement = 14,
	EventOutcome = 16,
}

export default TaskTypeIds;
