
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { UserPermissions } from "@/store/types";
import { parseTaskText } from "@/filters";
import { stringMixin } from "@/mixins";

const Tasks = namespace("tasks");

const { isNullOrWhitespace } = stringMixin.methods;

@Component({
	components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar
	},
	filters: {
		parseTaskText
	}
})
export default class ActionLibrarySimpleList extends Vue {
	@Getter("getPermissions") permissions: UserPermissions;
	@Getter("getCanAddIndividualActionsOverride")
	canAddIndividualActionsOverride: boolean;

	@Tasks.Getter("getTaskLibrary") taskLibrary: any;
	@Tasks.Getter("getTaskCategories") taskCategories: any;
	@Tasks.Getter("getTaskLibraryByCategoryID") taskLibraryByCategoryID: any;
	@Tasks.Getter("getTaskTypes") taskTypes: any;
	@Tasks.Getter("getSelectedActions") selectedActions: any;
	@Tasks.Getter("getTaskTypesByTitle") taskTypesByTitle: any;
	@Tasks.Getter toggleActionListModal: any;

	@Tasks.Mutation resetTasksSelected: () => void;
	@Tasks.Mutation setCategorySelected: ({ categoryId, selected }) => void;
	@Tasks.Mutation setActionSelected: ({
		categoryId,
		taskId,
		selected
	}) => void;

	@Tasks.Action fetchActionLibrary: any;
	@Tasks.Action fetchTaskCategories: any;

	public filter: string = "";
	public actionsInActionPlansByCategory: any = {};
	public selected: any = {};
	public selectedCategories: string[] = [];

	public get canAddIndividualActions() {
		return this.canAddIndividualActionsOverride
			? true
			: this.permissions.canAddIndividualActions;
	}

	public get filterLowerCase() {
		return this.filter.toLowerCase();
	}

	public get taskCategoriesWithTasks() {
		if (this.canAddIndividualActions) {
			return this.taskCategories.filter(
				(category: any) =>
					this.filteredTasks(category, category.tasks).length > 0
			);
		} else {
			if (isNullOrWhitespace(this.filter)) {
				return this.taskCategories;
			} else {
				return this.taskCategories.filter(this.categoryInFilter);
			}
		}
	}

	public categoryInFilter(category: any) {
		return (
			this.filter !== null &&
			this.filter.length > 0 &&
			category.tasks.length > 0 &&
			category.title.toLowerCase().indexOf(this.filterLowerCase) > -1
		);
	}

	public filteredTasks(category: any, taskList: any[]) {
		if (isNullOrWhitespace(this.filter)) return taskList;
		if (this.categoryInFilter(category)) return taskList;

		return taskList.filter((task: any) => {
			return (
				(task.taskText
					.toLowerCase()
					.indexOf(this.filterLowerCase)) > -1
			);
		});
	}

	public selectCategory(category: any) {
		if (!category) {
			return;
		}
		
		const categoryPrevState = { ...category };

		this.setCategorySelected({
			categoryId: category.taskCategoryID,
			selected: categoryPrevState.selected
		});

		this.filteredTasks(category, category.tasks).forEach((action: any) => {
			this.setActionSelected({
				categoryId: category.taskCategoryID,
				taskId: action.taskID,
				selected: categoryPrevState.selected
			});
		});
	}

	@Watch("toggleActionListModal", { immediate: true })
	public onModalVisibilityChange() {
		this.resetTasksSelected();
	}

	public async created() {
		await this.fetchActionLibrary();
		await this.fetchTaskCategories();
	}
}
