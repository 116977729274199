import { GetterTree } from "vuex";
import { TasksState } from "./types";
import { RootState } from "../types";
import { TasksService } from "@/services/tasks.service";
import TaskTypeIds from "@/types/sv-data/enums/TaskTypeIds";
const tasksService = new TasksService(); // @todo modify service to return instance not class

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<TasksState, RootState> = {
	// @refactor to be part of event state
	getEventTasks: state => tasksService.responseToModel(state.Tasks),
	getTasks: state => state.Tasks, // gets event-specific tasks

	// Tasks / Actions - related getters
	getTaskLibrary: state => state.TaskLibrary,
	getTaskTypes: state => state.TaskTypes,
	getTaskLibraryByCategoryID: state => {
		// @todo refactor this entire getter
		const tasksByCategoryID = {};
		let categoryKey = null;
		state.TaskLibrary.forEach((t: any) => {
			if (t.taskCategoryID === 0 || t.taskCategoryID === undefined) {
				categoryKey = 0;
			} else {
				categoryKey = t.taskCategoryID;
			}
			if (!tasksByCategoryID[categoryKey]) {
				tasksByCategoryID[categoryKey] = [];
			}
			tasksByCategoryID[categoryKey].push(t);
		});
		return tasksByCategoryID;
	},
	getSelectedActions: state => {
		const selected: any[] = [];

		state.TaskCategories.forEach((category: any) => {
			category.tasks.forEach((action: any) => {
				if (action.selected) {
					selected.push(action);
				}
			});
		});

		return selected;
	},
	getTaskTypesByTitle: (state, getters) => {
		let taskTypes: any = {};
		getters.getEnabledActionTypes.forEach((type: any) => {
			taskTypes[type.taskTypeID] = type.title;
		});
		return taskTypes;
	},

	// Task / Action Categories - related getters
	getTaskCategories: state => state.TaskCategories,

	getScriptCategoryLinks: state => state.ScriptCategoryLinks,

	// Action Plan - related getters
	getActionPlans: state => state.ActionPlans,
	getOutstandingRequiredTasks: state => state.OutstandingRequiredTasks,
	getOutstandingRequiredTaskCount: state => state.OutstandingRequiredTasks?.length,
	toggleActionListModal: state => state.ToggleActionListModal,
	getNotificationServers: state => state.NotificationServers,
	getNotificationTemplates: state => state.NotificationTemplates,
	getEditTriggerAction: state => state.EditTriggerAction,
	getEnabledActionTypes: (state: TasksState, getters, rootState: RootState, rootGetters) => {
		return state.TaskTypes.filter(task => {
			switch (task.taskTypeID) {
				case TaskTypeIds.MassNotification:
					return rootGetters.getFeature(["Actions","MassNotification"]);
				case TaskTypeIds.Choices:
					return rootGetters.getFeature(["Actions", "Choices"]);
				case TaskTypeIds.CallContact:
					return rootGetters.getFeature(["Actions","CallContacts"]);
				case TaskTypeIds.DownloadSitRep:
					return rootGetters.getFeature(["Actions", "SitRep"]);
				case TaskTypeIds.CCure:
					return rootGetters.getFeature(["Actions", "CCure"]);
				case TaskTypeIds.ShowAudit:
					return rootGetters.getFeature(["Actions", "ViewAudit"])
				case TaskTypeIds.DynamicDataList:
					return rootGetters.getFeature(["Actions", "DynamicDataList"]);
				case TaskTypeIds.ControlRelay:
					return rootGetters.getFeature(["Actions", "Control"]);
				case TaskTypeIds.RaiseToCaseManagement:
					return rootGetters.getFeature(["CaseManagement", "RaiseToCaseManagement"]);
				case TaskTypeIds.EventOutcome:
					return rootGetters.getFeature(["Actions", "EventOutcome"]);
				case TaskTypeIds.Basic:
				case TaskTypeIds.Input:
				case TaskTypeIds.YesNo:
				case TaskTypeIds.URL:
					return true;
				default:
					return false;
			}
		});
	},
	getCallContacts: state => state.CallContacts,
	getDynamicDatalist: state => state.DynamicDataList,
	getDynamicData: state => state.DynamicData,
	hasEventOutcomeTask: (state: TasksState) => state.Tasks && state.Tasks.some(task => task.taskTypeID == TaskTypeIds.EventOutcome),
};
