
import { Component, Vue, Prop, Inject, Emit } from "vue-property-decorator";
import { namespace, Getter, Mutation, Action } from "vuex-class";
import { ICamera } from "@/store/views/types";
import { StarredDevice } from "@/store/mobile/types";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const Mobile = namespace("mobile");

@Component({
	components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar
	}
})
export default class StarredCameras extends Vue {
	@Mobile.Mutation setLiveDevice: (camera: ICamera) => void;
	@Mobile.State("StarredDevices") starredDevices: StarredDevice[];
	@Mobile.Getter("getLiveDeviceId") liveDeviceId: number;

	private async showAreaCamera(camera) {
		this.setLiveDevice(camera);
		this.deviceSelected();
	}

	@Emit("deviceSelected")
	public deviceSelected() {}
}
