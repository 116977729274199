
import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator";
import vselect3 from "vselect3";
import { ElevationLabelType } from "../../store/types";
import { State } from "vuex-class";

@Component({
    components: { "v-select3" : vselect3 }
})
export default class ElevationSelect extends Vue {
    @Prop()
    private value: number

    @Prop({ type: Boolean, default: false})
    private isDisabled;

    @Prop({ type: Array, required: false, default: () => { return [] } })
	private providedElevations: number[];

    @Prop({ type: Number, default: null})
    private presetValue;

    @Prop({ type: Boolean, default: false})
    private useProvidedOnly;

    @Prop({type: String, default: "Select an Elevation"})
    private placeholder: string;

    private loaded: boolean = false;

    @State("elevationLabels")
	private elevationLabels: ElevationLabelType[];

    private selectedElevation: any = null;

    private options: ElevationLabelType[] = [];

    private mounted(): void
    {
        this.setOptions();
        this.setSelectElevation();
        this.loaded = true;
    }

    @Watch("elevationLabels")
    @Watch("providedElevations")
    public setOptions (): void
    {
        var options = [];
        if(this.providedElevations && this.providedElevations.length > 0 && this.useProvidedOnly)
        {
            options = [...this.providedElevations
                .map((elevation) =>
                {
                    return {
                        title: this.elevationLabels.find(el => el.elevation == elevation)?.title || elevation,
                        elevation: elevation
                        }
                })];
        }
        else
        {
            options = [...this.elevationLabels];
        }

        options.sort(function(a,b){return a.elevation-b.elevation});
        this.options = options;
    }

    @Watch("value")
    private setSelectElevation(): void
    {
        if (this.value == this.selectedElevation?.elevation)
        {
            return;
        }

		if (this.value == null) {
            this.selectedElevation = null;
			return;
		}

        let valueToAdd = null;
        const needToAddDefaultOptionOnLoad = this.presetValue != null;

        if (needToAddDefaultOptionOnLoad) {
            valueToAdd = this.presetValue;
        } else {
            valueToAdd = this.value;
        }

        var foundOption = this.options.find((o) => o.elevation == valueToAdd);
        if(!foundOption)
        {
            this.options.push({title: valueToAdd.toString(), elevation: valueToAdd})
            this.options.sort(function(a,b){return a.elevation-b.elevation});
            foundOption = this.options.find((o) => o.elevation == valueToAdd);
        }
		this.selectedElevation = foundOption;
		this.$emit("input", foundOption.elevation)
	}

    @Emit("input")
    private elevationSelected(elevationLabel : ElevationLabelType): number
    {
        return elevationLabel?.elevation;
    }

    private isNumber(e) {
        let char = String.fromCharCode(e.keyCode);
        if (/^[0-9]+$/.test(char)) {
            return true;
        }
        else {
            e.preventDefault();
        }
    }
}
