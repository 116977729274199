import { GetterTree } from "vuex";
import { EmailData } from "./types";
import { RootState } from "../types";
import {
    getEmailFrom,
    getAttachmentsLinks,
    isPdf,
    isVideo,
    isImage,
    isMultipartAlternative,
    filteredAttachments,
    parseMultiPartByType,
    extractFieldId,
    setImagesToHTML,
    getObjectUrl,
    removeExtraSymbolsFromHtmlType,
    extractImgFromHtml,
    setLinksToHtml
} from "@/utils/formatterEmailAttachments";
import _ from "lodash"

export const getters: GetterTree<EmailData, RootState> = {
	mainHTMLWithImages: (state, getters) => (name:string) =>
		setImagesToHTML(
			getters.getMainHTML(name),
			getters.formattedImagesById(name)
		),

	getMainHTML: (state, getters) => (name:string) =>
		setLinksToHtml(
			removeExtraSymbolsFromHtmlType(
				_.get(getters.getEmail(name), "html") || ""
			)
		) || "",

	getAllHTML: (state, getters) => name =>
		[
			...getters.getMultipartAlternativeHTMLs(name),
			getters.getMainHTML(name),
		].filter(html => html),

    getExtraImages: (state, getters) => (name:string) =>
        (
            getters.areImagesExists(name) &&
            getters.getInlineImagesId(name) &&
            getters.getImages(name).filter(({ id }) => !getters.getInlineImagesId(name)[extractFieldId(id)])
        ),

    getInlineImagesId: (state, getters) => (name:string) =>
                    getters.getIdsInlinesImages(name) && _.groupBy(getters.getIdsInlinesImages(name)),

    getIdsInlinesImages:(state, getters) => (name:string) =>
					getters.getAllHTML(name)
                    .map(html => extractImgFromHtml(removeExtraSymbolsFromHtmlType(html)).map(({ id }) => id))
                    .flat(),

    formattedImagesById: (state, getters) => (name:string) =>
                        getters.areImagesExists(name) &&
                        getters.getImages(name).reduce((prev, cur) => ({
                                ...prev,
                                [extractFieldId(cur.id)]: cur,
                        }), {}),

    getMultipartAlternativeHTMLWithImages: (state, getters) => (name:string) =>
                getters.getMultipartAlternativeHTMLs(name)
                .map(html => setLinksToHtml(html))
                .map(html =>
                    setImagesToHTML(html, getters.formattedImagesById(name))
                ) || [],

    getMultipartAlternativeHTMLs: (state, getters) => (name:string) => getters.getMultipartAlternativeByType(name)
                                .filter(({ type }) => type === "text/html;")
                                .map(({ body }) => body),

    getMultipartAlternativeByType: (state, getters) => (name:string) =>
                        getters.isAttachmentsArray &&
                        getters.getMultipartAlternative(name)
                                    .map(attachment => parseMultiPartByType(attachment))
                                    .flat(),

    getMultipartAlternative: (state, getters) =>
		(name:string) =>
			getters.isAttachmentsArray && filteredAttachments(getters.getAttachments(name), isMultipartAlternative),
    getImages: (state, getters) =>
		(name:string) =>
			getters.isAttachmentsArray && getAttachmentsLinks(getters.getAttachments(name), isImage),
    getPdfs:(state, getters) =>
		(name:string) =>
			getters.isAttachmentsArray && getAttachmentsLinks(getters.getAttachments(name), isPdf),
    getVideos: (state, getters) =>
		(name:string) =>
			getters.isAttachmentsArray && getAttachmentsLinks(getters.getAttachments(name), isVideo),
    getAttachmentsHTML:(state, getters) =>
		(name:string) =>
			getters.getMultipartAlternativeHTMLWithImages(name).map(html => getObjectUrl([html], "text/html")),
    isAttachmentObject: (state, getters) => (name:string) => _.isObject(getters.getAttachments(name)),
    isAttachmentsArray: (state, getters) => (name:string) => _.isArray(getters.getAttachments(name)),
    areImagesExists:(state, getters) => (name:string) => !!_.get(getters.getImages(name), "length"),
    getAttachments: (state, getters) => (name:string) => _.get(getters.getEmail(name), "attachments"),
    getEmailFrom: (state, getters) => (name:string) => getEmailFrom(getters.getEmail(name)),
    getEmail: state => (name:string) => state.emailsStorage[name],
};
