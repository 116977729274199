/* istanbul ignore file */
export class ViewEventHandler {
	[s: string]: (data) => void;
}

export class ViewEventManager {
	[s: string]: ViewEventHandler;
}

export interface Dimensions {
	x: number;
	y: number;
}

export interface MouseProperties {
	x: number;
	y: number;
	width: number;
	widthPerColumn: number;
	col: number;
	height: number;
	heightPerRow: number;
	row: number;
	height2: number;
	cellAt: ViewCellObject;
}

export interface ViewCellObject {
	height: number;
	width: number;
	pluginTypeId: any;
	viewCellId: number;
	x: number;
	y: number;
	contents: any;
}

export interface ViewObject {
	height: number;
	width: number;
	title: string;
	viewId: number;
	public: boolean;
	cells: ViewCellObject[];
	groupId: number | null;
	ownerUserId: number;
	dateLoaded?: string;
	pages?: ViewPage[];
	locked: boolean;
}

export interface ViewPage {
	viewId: number;
	pageNumber: number;
	columns: number;
	rows: number;
	cells: ViewCellObject[];
	locked: boolean;
}

export enum ViewMode {
	None,
	Edit,
	Add
}

export interface ViewsState {
	pluginTypes: any[];
	views: ViewObject[];
	userViews: ViewObject[];
	selectedView: ViewObject;
	selectedTileType: any;
	viewMode: ViewMode;
	sideBarExtended: boolean;
	sideBarPinned: boolean;
	latestEvent: any;
	cellContents: any;
	groupsList: any[];
	eventHandlers: ViewEventManager;
	viewSessionId: string;
}

export interface UpdatedViewCellData {
	viewId: number;
	viewCellId: number;
	contents: any;
	defaultContents: any;
}

export interface CellPosition {
	width: number;
	height: number;
	x: number;
	y: number;
}

export interface ICamera {
	deviceID: number;
	deviceTypeID: number;
	groupId: number;
	settingsMeta: string;
	title: string;
}
