
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class ConfirmationModal extends Vue {
	@Prop() visible: boolean;
	@Prop() danger: boolean;
	@Prop() title: string;
	@Prop() okBtnText: string;
	@Prop() cancelBtnText: string;
	@Prop({default: false, type: Boolean }) disabled: boolean;
}
