
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import { CameraType, SearchedCamera, NearbyCameraType } from "@/store/site-monitor-cameras/types";
import { EventDetails } from "@/store/site-monitor/types";
import SystemViewCameraIcon from "@/components/system-view/SystemViewCameraIcon.vue";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";

const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Session = namespace("sessions");

@Component({
	components: {
		"system-view-camera-icon": SystemViewCameraIcon
	}
})
export default class AreaCameras extends Vue {
	@SiteMonitor.Getter("getEventDetails") getEventDetails: EventDetails;
	@SiteMonitor.Getter("getAuditService") auditService: any;
	@SiteMonitor.Getter("getSelectedEventRecord") selectedEventRecord: any;
	@SiteMonitor.Getter("getIsController") isController: any;

	@SMCameras.Getter("getLayoutIndex") layoutIndex!: number;
	@SMCameras.Getter("getLayouts") layouts!: number[][];

	@SMCameras.Getter("getMediaMatrixLastChanged") mediaMatrixLastChanged: any;

	@SMCameras.Getter("getAllMatrixContents") getAllMatrixContents: any;
	@SMCameras.Getter("getMediaMatrixIsNew") mediaMatrixIsNew: any;

	@SMCameras.Mutation setAwaitingCamera: any;

	@SMCameras.Mutation highlightCell: any;
	@SMCameras.Mutation unhighlightCell: any;

	@SMCameras.Action fetchAreaCameras: (params: { groupID: number, pageNumber: number, paginated: boolean }) => Promise<void>;
	@SMCameras.Getter("getAreaCameras") groupCameras: CameraType[];
	@SMCameras.Mutation setAreaCameras: any;
	@SMCameras.Getter searchedCameras: SearchedCamera[];
	@SMCameras.State("areaCameraCount") public areaCameraCount: number;
	@SMCameras.State("searchedGroupCameraCount") public searchedGroupCameraCount: number;

	@Session.Getter getSession: any;

	@SiteMonitor.Getter("getIsCameraEnabled") isCameraEnabled: (deviceId: number) => boolean;
	@SiteMonitor.Getter("getApplianceOfflineNotification") private applianceOfflineNotification: (deviceId: number) => string;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Prop(String)
	public filter: string = "";

	@Prop()
	public tourEnded: boolean = false;

	open: boolean = false;

	public isLoading: boolean = false;

	public pageNumber: number = 1;
	public searchPageSize: number = 20;

	public get eventId() {
		return this.getEventDetails ? this.getEventDetails.eventID : 0;
	}

	siteTitle: string = "";

	@Watch("tourEnded")
	public async resetCameraList(): Promise<void> {
		if (this.tourEnded) {
			this.pageNumber = 1;
			this.getGroupCameras(this.getEventDetails.groupID, this.pageNumber);
			this.$emit("completeCameraReset");
		}
	}

	@Watch("eventId")
	public async onEventChanged(eventId: number, oldEventId: number) {
		if (this.getEventDetails) {
			this.siteTitle = this.getEventDetails.siteTitle;
			this.getGroupCameras(this.getEventDetails.groupID, this.pageNumber);
		} else {
			this.siteTitle = "";
			this.setAreaCameras([]);
		}
	}

	mounted() {
		if (this.getEventDetails) {
			this.siteTitle = this.getEventDetails.siteTitle;
			this.getGroupCameras(this.getEventDetails.groupID, this.pageNumber);
		}
	}

	beforeDestroy() {
		this.setAreaCameras([]);
	}

	public get layoutHorizontal() {
		return this.layouts[this.layoutIndex][0];
	}

	public get layoutVertical() {
		return this.layouts[this.layoutIndex][1];
	}

	isSelectedCamera(camera: CameraType) {
		return camera.index !== null && !isNaN(camera.index)
			? "selectedCamera"
			: "";
	}

	public cameraIconColor(camera: any, i: number, j: number) {
		return (i - 1) * this.layoutHorizontal + j === camera.index
			? "redCameraIndicator"
			: "greyCameraIndicator";
	}

	cameraSelect(camera: CameraType | NearbyCameraType) {
		if (this.isController) {
			this.setAwaitingCamera(camera);
		}
	}

	@Watch("mediaMatrixLastChanged")
	onMatrixChanged(value: any) {
		if (this.groupCameras && this.groupCameras.length > 0) {
			this.setCameraIndexes();
		}
	}

	setCameraIndexes() {
		let deviceIndexes = {};
		let matrixContents = this.getAllMatrixContents;

		for (var matrixIndex in matrixContents) {
			if (
				matrixContents[matrixIndex] != null &&
				matrixContents[matrixIndex].camera != null
			) {
				deviceIndexes[matrixContents[matrixIndex].camera.objectID] = parseInt(
					matrixIndex
				);
			}
		}

		this.groupCameras.forEach(camera => {
			Vue.set(
				camera,
				"index",
				deviceIndexes[camera.objectID] != null
					? deviceIndexes[camera.objectID]
					: null
			);
		});
	}

	async getGroupCameras(groupID: number, pageNumber: number): Promise<void> {
		await this.fetchAreaCameras({ groupID, pageNumber, paginated: true });
		this.setCameraIndexes();
	}

	get areaCameras(): any[] {
		if (!this.filterActive) return this.groupCameras;

		return this.searchedCameras.filter(
			camera => camera.areaTitle == this.getEventDetails.siteTitle
		).slice(0, this.searchPageSize);
	}

	public showMoreCameras(): void {
		if (!this.filterActive) {
			this.pageNumber++;
			this.getGroupCameras(this.getEventDetails.groupID, this.pageNumber);
		}
		else
		{
			this.$emit("expandSearch");
			this.pageNumber = 1;
			this.searchPageSize += 20;
		}
	}

	private get filterActive(): boolean {
		return !!this.filter;
	}

	@Watch("filterActive")
	private resetPageSize(): void {
		this.pageNumber = 1;
		this.searchPageSize = 20;
	}

	private get showLoadMoreButton(): boolean {
		if (this.filterActive) {
			return this.areaCameras.length < this.searchedGroupCameraCount;
		} else {
			return this.groupCameras.length < this.areaCameraCount;
		}
	}

	private get isSystemViewEnabled() {
		return get(this.featuresList, ["SystemView"]);
	}
}
