import { CustomFieldsState } from "./types";

export function getDefaultState(): CustomFieldsState {
	return {
		areaCustomFields: [],
		contactCustomFields: [],
		alarmCustomFields: [],
		totalCustomFields: 0
	};
}
