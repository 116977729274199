var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"csv-form"},[(_vm.isShowGrid)?_c('table',[_c('tr',{staticClass:"header-table"},[_vm._l((_vm.localHeadersForm),function(head){return _c('th',{key:head},[_vm._v(_vm._s(head))])}),(_vm.localData.length > 1)?_c('th',{staticClass:"grid-action"}):_vm._e()],2),_vm._l((_vm.gridData),function(row,i){return _c('div',{key:i},[_c('tr',{class:{
						'error-row': _vm.localErrorsRow[i],
						'error-progress-row': _vm.isProgressError(row),
					},attrs:{"rowspan":"2"}},[_vm._l((row),function(item,rowId){return _c('td',{key:item.uniqId},[(item.csvComponent === 'number')?_c('div',{staticClass:"grid-cell",class:{
								'error-item': _vm.localErrorsForm[item.uniqId],
								'error-column': _vm.localErrorsColumn[("" + rowId)]
							}},[_c('b-form-input',{staticClass:"input-form",attrs:{"value":item.csvValue,"id":item.uniqId,"min":item.min || 0,"max":item.max || 2147483647,"type":"number"},on:{"input":function($event){return _vm.debounceSetData({
									value: $event,
									data: item,
									name: _vm.name
								})}}})],1):(item.csvComponent === 'input')?_c('div',{staticClass:"grid-cell",class:{
								'error-item': _vm.localErrorsForm[item.uniqId],
								'error-column': _vm.localErrorsColumn[("" + rowId)]
							}},[_c('b-form-input',{staticClass:"input-form",attrs:{"value":item.csvValue,"id":item.uniqId,"type":"text"},on:{"input":function($event){return _vm.debounceSetData({
									value: $event,
									data: item,
									name: _vm.name
								})}}})],1):(item.csvComponent === 'select')?_c('div',{staticClass:"grid-cell",class:{
							'error-item': _vm.localErrorsForm[item.uniqId],
							'error-column': _vm.localErrorsColumn[("" + rowId)],
							}},[_c('vselect3',{staticClass:"dark w-100 select-variables element-form select-form",attrs:{"value":item.csvValue,"options":item.csvUnitedKey
											? item.unionData
											: item.csvData,"label":item.key,"multiple":false,"maxHeight":"150px","placeholder":"Select","searchable":false,"clearable":true,"closeOnSelect":true,"appendToBody":""},on:{"input":function($event){return _vm.localSetValue({
									value: $event,
									data: item,
									name: _vm.name
								})}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option[item.key]))])]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option[item.key]))])]}}],null,true)})],1):(item.csvComponent === 'select-multiple')?_c('div',{staticClass:"grid-cell",class:{
							'error-item': _vm.localErrorsForm[item.uniqId],
							'error-column': _vm.localErrorsColumn[("" + rowId)],
							}},[_c('vselect3',{staticClass:"dark w-100 select-variables element-form select-form",attrs:{"value":_vm.formattedMultipleValue(item.csvValue),"options":item.csvUnitedKey
											? item.unionData
											: item.csvData,"label":item.key,"multiple":true,"maxHeight":"150px","placeholder":"Select","searchable":false,"clearable":true,"closeOnSelect":false,"appendToBody":""},on:{"input":function($event){return _vm.localSetValue({
									value: $event,
									data: item,
									name: _vm.name
								})}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option[item.key]))])]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option[item.key]))])]}}],null,true)})],1):(item.csvComponent === 'checkbox')?_c('div',{staticClass:"grid-cell",class:{
							'error-item': _vm.localErrorsForm[item.uniqId],
							'error-column': _vm.localErrorsColumn[("" + rowId)],
							}},[_c('b-form-group',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":item.uniqId},domProps:{"checked":item.csvValue,"value":item.csvValue},on:{"input":function($event){return _vm.debounceSetData({
										value: $event.target.checked,
										data: item,
										name: _vm.name
									})}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":item.uniqId}})])],1):_c('div',{staticClass:"grid-cell"},[_vm._v(" "+_vm._s(item.csvValue)+" ")])])}),(_vm.localData.length > 1 && !_vm.isUploading)?_c('td',{staticClass:"grid-action"},[_c('i',{staticClass:"fas fa-trash hover-icon p-r-10",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.localRemoveRow({ row: row, name: _vm.name })}}})]):_vm._e()],2),(_vm.progress.bulkErrors)?_c('tr',[_c('div',{staticClass:"small text-danger"},[_vm._v(" "+_vm._s(_vm.progress.bulkErrors[i].errorMessage)+" ")])]):(_vm.localErrorList.length > 0)?_c('tr',[_c('div',{staticClass:"small text-danger"},[_vm._v(" "+_vm._s(_vm.localErrorList.find(function (e) { return e.includes(("Row " + (row[0].rowId + 1) + ",")); }))+" ")])]):_vm._e()])})],2):(_vm.localErrorsCSV.info)?_c('div',[_vm._v(" "+_vm._s(_vm.errorsCSV.info)+" ")]):(_vm.localErrorsCSV.error)?_c('div',[_vm._v(" "+_vm._s(_vm.errorsCSV.error)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }