import { render, staticRenderFns } from "./SituationalAwarenessAlertsControl.vue?vue&type=template&id=997362da&scoped=true"
import script from "./SituationalAwarenessAlertsControl.vue?vue&type=script&lang=ts"
export * from "./SituationalAwarenessAlertsControl.vue?vue&type=script&lang=ts"
import style0 from "./SituationalAwarenessAlertsControl.vue?vue&type=style&index=0&id=997362da&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "997362da",
  null
  
)

export default component.exports