



















































































import { SiteTreeNode } from "@/store/eventqueue/types";
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Eventqueue = namespace("eventqueue");

@Component({
	components: {
		tree: () => import("./Tree.vue")
	}
})
export default class tree extends Vue {
	@Prop(Object) group: any;
	@Prop(String) title: string;
	@Prop(Array) subGroups: any[];
	@Prop(Boolean) open: boolean;
	@Prop(Object) node: any;
	@Prop(Boolean) isClickableChildNode: boolean;
	@Prop(Boolean) showState: boolean;
	private isFocused = false;
	private isOpen: boolean = true;

	//Trigger is string translating to which method to trigger with the shortcut
	private treeShortcut(trigger: string): void{
		if(trigger === "select"){
			this.nodeSelected({
				group: this.group,
				tree: this,
				node: this.node
			});
		} else if(trigger === "toggle"){
			this.openNode()
		}
	}

	@Prop({ default: () => { return [] } })
	public selectedGroupIDs: number[];

	@Prop({ default: true }) allowDoubleclick: boolean;

	clicks: number = 0;
	clickTimer: any;

	@Eventqueue.Mutation toggleSiteTreeNodeOpen: any;
	@Eventqueue.Mutation collapseSiteTreeNode: (node: SiteTreeNode) => void;

	public openNode() {
		this.isOpen = !this.isOpen;
		this.toggleSiteTreeNodeOpen(this.node);
		this.nodeOpened(this.node);
	}

	public selectNode() {
		if (this.allowDoubleclick) {
			this.clicks++;

			if (this.clicks === 1) {
				this.clickTimer = setTimeout(() => {
					this.nodeSelected({
						group: this.group,
						tree: this,
						node: this.node
					});
					this.clicks = 0;
				}, 0.5 * this.$config.ANIMATION_DURATION);
			} else {
				if (this.clickTimer != null) clearTimeout(this.clickTimer);

				this.clicks = 0;
				this.nodeDoubleClick({
					group: this.group,
					tree: this,
					node: this.node
				});
			}
		} else {
			this.nodeSelected({
				group: this.group,
				tree: this,
				node: this.node
			});
		}
	}

	@Emit("onSelected")
	public nodeSelected(args: any) {
		return args;
	}

	@Emit("onDoubleClick")
	public nodeDoubleClick(args: any) {
		return args;
	}

	@Emit("onOpenNode")
	public nodeOpened(args: any) {
		return args;
	}

	private mouseDown() {

	}

	@Watch("node")
	private nodeUpdated() {
		if (!this.isOpen) {
			this.collapseSiteTreeNode(this.node);
		}
	}
}
