import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { AreaNotesState, AreaNote } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<AreaNotesState, RootState> = {
	async addAreaNote({ commit }, note: AreaNote ) {
		// If dates are left as null the dataservice will fail to bind to the model
		// clone the note to leave the model in the UI unchanged
		let noteRequest = { ... note };
		if (noteRequest.startFrom === null) {
			noteRequest.startFrom = "";
		}
		if (noteRequest.endAt === null) {
			noteRequest.endAt = "";
		}
		let newAreaNoteResponse = await api.addAreaNote(noteRequest);
		const mappedResponse = { ...newAreaNoteResponse.data, startFrom: noteRequest.startFrom, endAt: noteRequest.endAt };
		commit("addAreaNote", mappedResponse);
		return mappedResponse;
	},

	async editAreaNote({ commit }, note: AreaNote ) {
		// If dates are left as null the dataservice will fail to bind to the model
		// clone the note to leave the model in the UI unchanged
		let noteRequest = { ... note };
		if (noteRequest.startFrom === null) {
			noteRequest.startFrom = "";
		}
		if (noteRequest.endAt === null) {
			noteRequest.endAt = "";
		}
		let newAreaNoteResponse = await api.editAreaNote(noteRequest);
		let editWasSuccessful = newAreaNoteResponse.data.success;
		if (editWasSuccessful) {
			commit("updateNote", note);
			commit("setEditingNote", null);
			commit("setIsEditing", false);
		}
		return editWasSuccessful;
	},

	async deleteAreaNote({ commit }, noteId: number) {
		await api.deleteAreaNote(noteId);
		commit("deleteAreaNote", noteId);
	},

	async fetchAreaNotesForEvent({ commit }, eventId: number) {
		const notes = await api.loadAreaNotesForEvent(eventId);
		commit("setAreaNotes", notes);
	},

	async fetchAreaNotesForGroup({ commit }, groupId: number) {
		const notes = await api.loadAreaNotesForGroup(groupId);
		commit("setAreaNotes", notes);
	},

	startEditingNote({ commit }, note: AreaNote) {
		commit("setEditingNote", note);
		commit("setIsEditing", true);
		commit("setIsFormShown", true);
	},

	cancelEditingNote({ commit }) {
		commit("setEditingNote", null);
		commit("setIsEditing", false);
		commit("setIsFormShown", false);
	},

	closeModal({ commit }) {
		commit("siteMonitor/setAreaNotesVisible", false, { root: true });
		commit("setEditingNote", null);
		commit("setIsEditing", false);
		commit("setIsFormShown", false);
	}
};
