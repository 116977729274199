
/*
SVButton Component
This is a reusable button component that can be used instead of a standard <button> or <a>.
It has click debouncing built into it, ie you can click the button 10 times but only 1 request will be triggered.

Example Use:

<sv-button type="a" class="btn btn-secondary" title="No" @click="onCompleteTask('No')" data-cy="yesno-task-todo-no" :disabled="btnDisabled">
	Click Me!
</sv-button>


!!!! IMPORTANT !!!!
When writing unit tests that click this button you will need to use jest.useFakeTimers(); and call jest.runAllTimers(); BEFORE your expect() condition

*/
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { debounce } from "lodash";
import { async } from "rxjs/internal/scheduler/async";

@Component({
	components: {}
})
export default class SVButton extends Vue {
	/*
		type : ie button, a, submit
	*/
	@Prop(String) type: string;

	/*
		title : The title text to be displayed on the button when hovering with a mouse
	*/
	@Prop(String) title: any;

	/*
		href : The href of the button, when the type an an anchor tag
	*/
	@Prop(String) href: any;

	private ahref = undefined;
	private debounceTimeout = 200;

	// variable to hold our debounce funtion
	private debouncedFunction: any;

	async created() {
		// setup the debouce funtion
		this.debouncedFunction = debounce(async e => {
			// when we are finished debouncing, emmit a click event,
			// tgus will trigger the original click handler on the button
			this.$emit("click");
		}, this.debounceTimeout);

		// if a href is set, and our type is an <a> set the href
		this.ahref = this.href != undefined && this.href.length > 0 && this.btnType == "a" ? this.href : this.ahref;
	}

	private get btnType() {
		// if no button type is set, default to button
		return this.type !== undefined ? this.type : "button";
	}
}
