
	import SystemViewHealthStatusCount from '@/types/sv-data/system-view/SystemViewHealthStatusCount';
	import { Component, Vue, Emit, Prop } from "vue-property-decorator";
	import { namespace } from "vuex-class";

	const SystemHealth = namespace("systemHealth");

	@Component
	export default class SystemViewHealthRadioFilter extends Vue {
		@SystemHealth.Getter('getHealthStatus') private healthStatusCount: SystemViewHealthStatusCount;
		@SystemHealth.Getter('getStatusFilter') private statusFilter: string;
		@SystemHealth.Mutation setHealthStatus: (status: SystemViewHealthStatusCount) => void;
		@SystemHealth.Mutation setStatusFilter: (filter: string) => void;

        @Prop({type: String, required: true})
        private value: string;
        
		private selectedFilter: string = null;

        @Emit("change")
		private onSelectedFilterChanged(filter: string): string {
            return filter;
		}
	}
