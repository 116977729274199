import { render, staticRenderFns } from "./EventTaskGroup.vue?vue&type=template&id=245ec5f5&scoped=true"
import script from "./EventTaskGroup.vue?vue&type=script&lang=ts"
export * from "./EventTaskGroup.vue?vue&type=script&lang=ts"
import style0 from "./EventTaskGroup.vue?vue&type=style&index=0&id=245ec5f5&prod&scoped=true&lang=css"
import style1 from "./EventTaskGroup.vue?vue&type=style&index=1&id=245ec5f5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245ec5f5",
  null
  
)

export default component.exports