
import { Component, Emit, Vue, Watch, Prop } from "vue-property-decorator";
import { Getter, namespace, State } from "vuex-class";
import { validationMixin } from "vuelidate";
import { Schedule, ScheduleType, IntervalTypeOptions, PublicHolidaySettingOptions, IntervalType } from "@/store/schedules/types";
import { Response } from "@/store/responses/types";
import DatePicker from "@sureview/vuejs-datepicker";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import vSelect3 from "vselect3";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import ConfirmationModal from "@/components/mobile-raise-templates/ConfirmationModal.vue";
import { maxLength, minLength, minValue, numeric, required, requiredIf, maxValue } from "vuelidate/lib/validators";
import { EndTypes, ScheduleTypes } from "@/components/Schedules/ScheduleEnums";
import { cloneDeep, debounce, isEmpty, isNil, isEqual } from "lodash";
import axios, { CancelTokenSource } from "axios";
import { UserPermissions, TimeZone } from "@/store/types";
import api from '@/services/api.service';
import ScheduleMaskDuration from "./ScheduleMaskDuration.vue";
import { EventType } from "@/store/event-search/types";
import { Datetime } from "vue-datetime";

const SchedulesStore = namespace("schedules");
const ResponseStore = namespace("responses");

const debouncePeriod: number = process.env.NODE_ENV === "test" ? 1 : 500;
const minIntervalValue: number = 1;
const isoFormatWithoutDst: string = 'YYYY-MM-DDTHH:mm:ss';


@Component({
	mixins: [validationMixin],
	validations: {
		schedule: {
			title: {
				required,
				minLength: minLength(1),
				maxLength: maxLength(300),
			},
			scheduleTypeId: {
				required,
			},
			groupId: {
				required,
			},
			responseId: {
				required: requiredIf(function () {
					return this.schedule.scheduleTypeId == ScheduleTypes.ExpectedAlarm && isNil(this.schedule.responseId);
				}),
			},
			serverTypeEvent: {
				required: requiredIf(function () {
					return this.schedule.scheduleTypeId == ScheduleTypes.EventRaise && isNil(this.schedule.serverTypeEvent);
				}),
			},
			startDateTime: {
				required: requiredIf((value) => moment(value).isValid()),
				minTime: function (value) {
					if (this.schedule.scheduleTypeId == ScheduleTypes.EventRaise) {
						return true;
					}

					let isStartDateValid = moment(this.schedule.startDateTime).isBefore(this.schedule.endDateTime);
					if (this.isOnEndType) {
						return (
							isStartDateValid && moment(this.schedule.startDateTime).isBefore(this.schedule.expiresOn)
						);
					} else {
						return isStartDateValid;
					}
				},
			},
			endDateTime: {
				required: requiredIf(function (value) {
					if (this.schedule.scheduleTypeId == ScheduleTypes.EventRaise) {
						return false;
					}

					let isValid = moment(value).isValid();
					return isValid;
				}),
				minTime: function () {
					if (this.schedule.scheduleTypeId == ScheduleTypes.EventRaise) {
						return true;
					}

					let isEndDateValid = moment(this.schedule.endDateTime).isAfter(this.schedule.startDateTime);
					if (this.isOnEndType) {
						return isEndDateValid && moment(this.schedule.endDateTime).isBefore(this.schedule.expiresOn);
					}
					return isEndDateValid;
				},
			},
			expiresOn: {
				required: requiredIf(function () {
					return this.isOnEndType && moment(this.schedule.expiresOn).isValid();
				}),
				minTime: function () {
					if (this.isOnEndType) {
						return moment(this.schedule.expiresOn).isAfter(this.schedule.endDateTime) && moment(this.schedule.expiresOn).isAfter(this.schedule.startDateTime);
					}
					return true;
				},
			},
			intervalValue: {
				numeric,
				required,
				minValue: minValue(minIntervalValue),
				maxValue: maxValue(2147483647),
			},
			intervalTypeId: {
				required: requiredIf(function () {
					return this.schedule.intervalValue > 0;
				}),
				isValid: function() {
					return this.isScheduleLengthValid;
				}
			},
			maxOccurrences: {
				required: requiredIf(function () {
					return this.isAfterOccurencesType;
				}),
				minValue: function() {
					if (this.isAfterOccurencesType) {
						return this.schedule.maxOccurrences > 0;
					}
					return true;
				},
				maxValue: maxValue(2147483647),
			},
			publicHolidayTypeId: {
				required
			},
			responseMaskingMeta: {
				responseIds: {
					required: requiredIf(function () {
						return this.schedule.responseMaskingMeta != null && !this.schedule.responseMaskingMeta.allAlarms;
					}),
				}
			}
		},
		hasDataChanged: {
			isValid: function() {
				return !isEqual(this.originalSchedule, this.schedule);
			}
		}
	},
	components: {
		"date-picker": DatePicker,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"v-select-3": vSelect3,
		"area-tree-select": AreaTreeSelect,
		"confirmation-modal": ConfirmationModal,
		"schedule-mask-duration": ScheduleMaskDuration,
		"date-time": Datetime
	},
})

export default class ScheduleModal extends Vue {
	// Root store
	@State private timeZones: TimeZone[];
	@Getter("getPermissions") permissions: UserPermissions;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter getTimeZone: (timeZoneId: number) => TimeZone;

	@SchedulesStore.Getter getScheduleByIdAndDate: (id: string, start: Date) => Schedule;
	@SchedulesStore.State("isCreateModalVisible") isScheduleModalVisible: boolean;
	@SchedulesStore.State isDeleteConfirmationVisible: boolean;
	@SchedulesStore.State selectedScheduleId!: string;
	@SchedulesStore.State selectedScheduleStart!: Date;
	@SchedulesStore.State tempSchedule!: Schedule;
	@SchedulesStore.State selectedGroup: number;
	@SchedulesStore.State areaTimeZoneId: number | null;
	@SchedulesStore.State scheduleTypes: ScheduleType[];
	@SchedulesStore.Mutation removeTempSchedule: () => void;
	@SchedulesStore.Mutation setIsScheduleModalVisible: (isVisible: boolean) => void;
	@SchedulesStore.Mutation setIsDeleteConfirmationVisible: (isVisible: boolean) => void;
	@SchedulesStore.Mutation setSelectedGroup: (selectedGroup: number) => void;
	@SchedulesStore.Mutation setSelectedScheduleId: ({ selectedScheduleId, selectedScheduleStart }: {selectedScheduleId: number, selectedScheduleStart: Date }) => void;
	@SchedulesStore.Mutation setIsReloadRequired: (isReloadRequired: boolean) => void;
	@SchedulesStore.Action addOrUpdateSchedule: (scheduleToAdd: Schedule) => void;
	@SchedulesStore.Action deleteSchedule: ({ scheduleId }: { scheduleId: string }) => Promise<void>;

	// Response Store
	@ResponseStore.Action getResponseById: (responseId: number) => Promise<void>;
	@ResponseStore.State("currentAlarm") private currentAlarm;

	@Prop({ type: Boolean, default: false })
	private readonly: boolean;

	private defaultSchedule: Schedule = this.getDefaultSchedule();
	private schedule: Schedule = { ...this.defaultSchedule };
	private originalSchedule: Schedule | null = null;
	private selectedEndType: EndTypes = EndTypes.None;
	private scheduleToEdit: Schedule;
	public responseSearchToken: CancelTokenSource;
	private alarms: Response[] = [];
	private eventTypes: EventType[] = [];
	private safeDeleteInput: string | null = "";
	private currentTimeZoneId: number | null = null;

	private manualRaiseServerTypeId = 435;

	// Lifecycle methods
	public mounted(): void {
		this.schedule = cloneDeep(this.defaultSchedule);
		this.setDefaultToLocalFields();
	}

	beforeDestroy(): void {
		this.setIsScheduleModalVisible(false);
	}

	// Getters
	private get canEditSchedules(): boolean {
		if (this.readonly) {
			return false;
		}

		// feature flags
		if (!this.isMaskingEnabled && this.schedule.scheduleTypeId == ScheduleTypes.Masking){
			return false;
		}

		if (!this.isEventRaiseEnabled && this.schedule.scheduleTypeId == ScheduleTypes.EventRaise){
			return false;
		}

		// permissions
		return this.permissions.isSystemAdmin || this.permissions.canEditSchedules;
	}

	private get isEdit(): boolean {
		if (!this.canEditSchedules) {
			return false;
		}

		return this.schedule !== null && !!this.schedule?.scheduleId;
	}

	private get getTitle(): string {
		return this.isEdit ? "Update Schedule" : "Add Schedule";
	}

	private get getExpiresOn(): string {
		let v = this.schedule.endDateTime;
		return moment.utc(v).add(1, "days").toISOString();
	}

	private get intervalTypes(): { id: number, title: string }[] {
		return IntervalTypeOptions;
	}

	private get publicHolidayTypes(): { id: number, title: string }[]{
		return PublicHolidaySettingOptions;
	}

	private get getEndTypes(): EndTypes[] {
		return [EndTypes.None, EndTypes.OnDate, EndTypes.After];
	}

	private get isOnEndType(): boolean {
		return this.selectedEndType === EndTypes.OnDate;
	}

	private get isAfterOccurencesType(): boolean {
		return this.selectedEndType === EndTypes.After;
	}

	private get isScheduleEndTypeNone(): boolean {
		return this.selectedEndType === EndTypes.None;
	}

	private get scheduleTypeEditEnabled(): boolean {
		return !!this.canEditSchedules;
	}

	private get alarmOptions(): Response[] {
		if (this.alarms && !!this.currentAlarm && !this.isAlarmInAlarmList(this.currentAlarm, this.alarms) && this.currentAlarm.groupId == this.schedule?.groupId) {
			return [this.currentAlarm, ...this.alarms];
		}
		return this.alarms ? this.alarms : [];
	}

	private get isResponseExpected(): boolean {
		return this.isExpectedAlarmSchedule || this.isMaskingSchedule;
	}

	private get isScheduleTypeRaiseEvent(): boolean {
		return this.isEventRaiseSchedule;
	}

	private get safeDeleteMatches(): boolean {
		let expectedInput = `delete ${this.schedule.title}`;
		return this.safeDeleteInput == expectedInput;
	}

	private get locale(): string {
		const { locale } = Intl.DateTimeFormat().resolvedOptions();
		return locale;
	}

	private get isScheduleLengthValid(): boolean {
		// RaiseEvent scheduletype has no enddatetime so is always valid
		if (this.isScheduleTypeRaiseEvent){
			return true;
		}

		let isDailySchedule = this.schedule.intervalTypeId == IntervalType.Day;

		if (isDailySchedule) {
			let duration = moment.duration(moment(this.schedule.endDateTime).diff(moment(this.schedule.startDateTime)));
			return duration.asHours() < 24;
		}

		return true;
	}

	private get timeZoneId(): number | null {
		// If this brand new and Area has not been set yet, button pressed.
		if (!this.currentTimeZoneId) {
			return this.areaTimeZoneId;
		}

		// Is new or existing and the Area has been changed within the modal.
		if (this.currentTimeZoneId) {
			return this.currentTimeZoneId;
		}

		// Existing (clicked from Calendar) - gets timezone from schedule.
		if (this.schedule.timeZoneId) {
			return this.schedule.timeZoneId;
		}

		// If new but is dragged.
		return this.currentTimeZoneId;
	}

	private get timeZone(): TimeZone {
		return this.getTimeZone(this.timeZoneId);
	}

	private get timeZoneIana(): string {
		// Get timezone based on selected area or browser if area not set
		return this.timeZone?.iana ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	private get hasScheduleChanged(): boolean {
		if (!this.isEdit) {
			return true;
		}

		let unchangedSchedule = this.getScheduleByIdAndDate(this.selectedScheduleId, this.selectedScheduleStart);
		let currentSchedule = this.schedule;

		if (!unchangedSchedule || !currentSchedule) {
			return true;
		}

		return !(
			unchangedSchedule.title === currentSchedule.title &&
			unchangedSchedule.scheduleTypeId == currentSchedule.scheduleTypeId &&
			unchangedSchedule.responseId == currentSchedule.responseId &&
			unchangedSchedule.serverTypeEvent == currentSchedule.serverTypeEvent &&
			moment(currentSchedule.startDateTime).isSame(moment(unchangedSchedule.startDateTime)) &&
			moment(currentSchedule.endDateTime).isSame(moment(unchangedSchedule.endDateTime)) &&
			moment(currentSchedule.expiresOn).isSame(moment(unchangedSchedule.expiresOn)) &&
			unchangedSchedule.groupId == currentSchedule.groupId &&
			unchangedSchedule.intervalTypeId == currentSchedule.intervalTypeId &&
			unchangedSchedule.intervalValue == currentSchedule.intervalValue &&
			unchangedSchedule.disabled == currentSchedule.disabled &&
			unchangedSchedule.publicHolidayTypeId == currentSchedule.publicHolidayTypeId
		);
	}

	private set alarmOptions(alarms: Response[]) {
		if (alarms && !!this.currentAlarm && !this.isAlarmInAlarmList(this.currentAlarm, this.alarms)) {
			this.alarms = [this.currentAlarm, ...alarms];
		}

		this.alarms = alarms;
	}

	private get isExpectedAlarmSchedule(): boolean {
		return this.schedule.scheduleTypeId == ScheduleTypes.ExpectedAlarm;
	}

	private get isMaskingSchedule(): boolean {
		return this.schedule.scheduleTypeId == ScheduleTypes.Masking;
	}

	private get isEventRaiseSchedule(): boolean {
		return this.schedule.scheduleTypeId == ScheduleTypes.EventRaise;
	}

	private get hideResponseSelector(): boolean {
		return this.isMaskingSchedule && this.schedule.responseMaskingMeta != null && this.schedule.responseMaskingMeta.allAlarms;
	}

	private set timeZoneId(timeZoneId: number) {
		this.currentTimeZoneId = timeZoneId;
	}

	private get isMaskingEnabled(): boolean {
		return this.getFeature(["Schedules", "Masking"]);
	}

	private get isEventRaiseEnabled(): boolean {
		return this.getFeature(["Schedules", "EventRaise"]);
	}

	private get filteredScheduleTypes(): ScheduleType[] {
		let schedulesTypeList = this.scheduleTypes;

		if (!this.isMaskingEnabled) {
			schedulesTypeList = schedulesTypeList.filter(st => st.scheduleTypeId != ScheduleTypes.Masking);
		}

		if (!this.isEventRaiseEnabled) {
			schedulesTypeList = schedulesTypeList.filter(st => st.scheduleTypeId != ScheduleTypes.EventRaise);
		}

		return schedulesTypeList;
	}

	private get scheduleTypeTitle(): string {
		let scheduleType = this.scheduleTypes.find(st => st.scheduleTypeId == this.schedule.scheduleTypeId);

		if (scheduleType){
			return scheduleType.title;
		}

		return null;
	}

	// Watchers
	@Watch("schedule.intervalValue")
	private isReoccurenceAvailable(): boolean {
		return this.schedule?.intervalValue > 0;
	}

	@Watch("isDeleteConfirmationVisible")
	private async onDeleteModalVisibilityChange(): Promise<void> {
		if (!this.isDeleteConfirmationVisible) {
			return;
		}

		if (this.tempSchedule) {
			await this.updateScheduleForModal(this.tempSchedule);
		}
	}

	@Watch("isScheduleModalVisible")
	private async onModalVisibilityChange(): Promise<void> {
		if (!this.isScheduleModalVisible) {
			return;
		}

		// If Schedule already exists
		if (this.selectedScheduleId) {
			this.scheduleToEdit = this.getScheduleByIdAndDate(this.selectedScheduleId, this.selectedScheduleStart);
			await this.updateScheduleForModal(this.scheduleToEdit);
		}

		// If we're dragging then set some defaults.
		if (this.tempSchedule) {
			await this.updateScheduleForModal(this.tempSchedule);
		}

		// If the Schedule is new.
		if (!isEmpty(this.schedule)) {
			this.selectedEndType = this.getSelectedEndType();

			this.schedule.groupId = this.selectedGroup;
			this.schedule.timeZoneId = this.areaTimeZoneId;
		}

		this.originalSchedule = cloneDeep(this.schedule);
	}

	@Watch("schedule.groupId")
	private async onGroupChanged(newGroupId?: number, oldGroupId?: number): Promise<void> {
		if (!!newGroupId && newGroupId != oldGroupId) {
			await this.performAlarmSearch("");
			await this.loadEventTypes(newGroupId);

			// Reset the alarm & event type.
			if (oldGroupId) {
				this.schedule.responseId = null;
				this.schedule.serverTypeEvent = null;
			}

			this.setAreaTimeZone();
		}
	}

	@Watch("schedule.startDateTime")
	private onStartDateTimeChanged(): void {
		this.triggerDatesValidation();
	}

	@Watch("schedule.endDateTime")
	private onEndDateTimeChanged(): void {
		this.triggerDatesValidation();
	}

	@Watch("schedule.expiresOn")
	private onExpiresOnChanged(): void {
		this.triggerDatesValidation();
		this.triggerValidationOnEndType();
	}

	@Watch("schedule.responseMaskingMeta.allAlarms")
	private onAllAlarmsChanged(newValue: boolean, oldValue: boolean): void {
		if (oldValue == null) {
			return;
		}

		if (!oldValue && newValue) {
			this.schedule.responseMaskingMeta.responseIds = [];
		}
	}

	@Watch("schedule.scheduleTypeId")
	private onScheduleTypeChanged(newValue: number, oldValue: number): void {
		if (newValue == ScheduleTypes.Masking && newValue !== oldValue) {
			this.schedule.responseMaskingMeta = this.schedule.responseMaskingMeta ?? { responseIds: [], allAlarms: false, disarmReason: "", disarmUntil: this.schedule.startDateTime };
			this.schedule.responseId = null;
			this.schedule.serverTypeEvent = null;
		}
		else if (newValue == ScheduleTypes.ExpectedAlarm) {
			this.schedule.responseMaskingMeta = null;
			this.schedule.serverTypeEvent = null;
		}
		else if (newValue == ScheduleTypes.EventRaise) {
			this.schedule.responseMaskingMeta = null;
			this.schedule.responseId = null;
		}
	}

	// Emitters
	@Emit("close-modal")
	private closeModal(): void {
		this.schedule = cloneDeep(this.defaultSchedule);
		this.originalSchedule = null;
		this.scheduleToEdit = null;
		this.setDefaultToLocalFields();
		this.setSelectedScheduleId({ selectedScheduleId: null, selectedScheduleStart: null });
		this.removeTempSchedule();
		this.setIsScheduleModalVisible(false);
		this.$v.$reset();
	}

	@Emit("submit")
	public async onSubmit(): Promise<void> {
		this.$v.$touch();

		if (this.$v.$invalid) {
			return;
		}

		if(this.schedule.scheduleTypeId == ScheduleTypes.EventRaise){
			let startDateTime = this.schedule.startDateTime;
			this.schedule.endDateTime =  moment(startDateTime).add(1, "minutes").format(isoFormatWithoutDst);
		}

		await this.addOrUpdateSchedule(this.schedule);
		this.setSelectedGroup(this.schedule.groupId);
		this.closeModal();
		this.setIsReloadRequired(true);
	}

	private setDefaultToLocalFields(): void {
		this.selectedEndType = EndTypes.None;
		this.scheduleToEdit = null;
	}

	private async updateScheduleForModal(scheduleToEdit: Schedule): Promise<void> {
		if (!scheduleToEdit) {
			return;
		}

		this.schedule.scheduleId = scheduleToEdit.scheduleId;
		this.schedule.title = scheduleToEdit.title;
		this.schedule.startDateTime = scheduleToEdit.startDateTime;
		this.schedule.endDateTime = scheduleToEdit.endDateTime;
		this.schedule.scheduleTypeId = scheduleToEdit.scheduleTypeId;
		this.schedule.intervalValue = scheduleToEdit.intervalValue;
		this.schedule.expiresOn = scheduleToEdit.expiresOn;
		this.schedule.maxOccurrences = scheduleToEdit.maxOccurrences;
		this.schedule.intervalTypeId = scheduleToEdit.intervalTypeId;
		this.schedule.publicHolidayTypeId = scheduleToEdit.publicHolidayTypeId;
		this.schedule.responseId = scheduleToEdit.responseId;
		this.schedule.groupId = scheduleToEdit.groupId;
		this.schedule.disabled = scheduleToEdit.disabled;
		this.schedule.responseMaskingMeta = scheduleToEdit.responseMaskingMeta ?? null;
		this.schedule.timeZoneId = scheduleToEdit.timeZoneId;
		this.schedule.serverTypeEvent = scheduleToEdit.serverTypeEvent;

		this.selectedEndType = this.getSelectedEndType();

		if (!!this.schedule.responseId && this.isExpectedAlarmSchedule) {
			await this.getResponseById(this.schedule.responseId);
		}
	}

	private getSelectedEndType(): EndTypes {
		if (this.schedule.maxOccurrences) {
			return EndTypes.After;
		} else if (this.schedule.expiresOn) {
			return EndTypes.OnDate;
		} else {
			return EndTypes.None;
		}
	}

	private calendarDateFormatter(date: string): string | null {
		if (date) {
			let momentDate = moment(date).toDate();
			let format = new Intl.DateTimeFormat(this.locale).format(momentDate);
			return format;
		} else {
			return null;
		}
	}

	private combineDateWithTime(date: string, time: string): string | null {
		let hours: number = +time.split(":")[0];
		let minutes: number = +time.split(":")[1];
		return moment(date).utc(true).set("hour", hours).set("minute", minutes).toISOString();
	}

	private displayDeleteConfirmation(): void {
		this.setIsDeleteConfirmationVisible(true);
	}

	private async confirmDelete(): Promise<void> {
		if (!this.safeDeleteMatches) {
			return;
		}

		await this.deleteItem();
	}

	private async deleteItem(): Promise<void> {
		this.setIsDeleteConfirmationVisible(false);
		await this.deleteSchedule({
			scheduleId: this.schedule.scheduleId
		});
		this.safeDeleteInput = "";
		this.setIsReloadRequired(true);
		this.closeModal();
	}

	private cancelDeletion(): void {
		this.setIsDeleteConfirmationVisible(false);
		this.safeDeleteInput = "";

		if (!this.isScheduleModalVisible){
			this.removeTempSchedule();
		}
	}

	private async handleAlarmSearchInput(searchTerm: string): Promise<void> {
		await this.performAlarmSearch(searchTerm);
	}

	private performAlarmSearch: (searchTeam: string) => Promise<void> = debounce(
		async function (searchTerm: string) {
			if (this.responseSearchToken) {
				this.responseSearchToken.cancel("Cancel Search");
			}

			this.responseSearchToken = axios.CancelToken.source();
			if (!this.schedule.groupId) {
				return;
			}
			try {
				let responses = await api.getAllResponsesByArea(this.schedule.groupId, searchTerm);
				this.alarmOptions = responses;
			} catch (e) {
				return; //Return when past requests are cancelled
			}
		},
		debouncePeriod,
		{ leading: false, trailing: true }
	);

	private onSelectedScheduleTypeChanged(): void {
		if (this.schedule.scheduleTypeId != ScheduleTypes.ExpectedAlarm) {
			this.setDefaultResponseId();
		}

		if (this.schedule.scheduleTypeId != ScheduleTypes.EventRaise) {
			this.setDefaultEventTypeId();
		}
	}

	private setDefaultResponseId(): void {
		this.schedule.responseId = null;
	}

	private setDefaultEventTypeId(): void {
		this.schedule.serverTypeEvent = null;
	}

	private onSelectedEndTypeChanged(): void {
		if (this.isScheduleEndTypeNone) {
			this.clearExpiresOn();
			this.setDefaultMaxOccurences();
		} else if (this.isOnEndType) {
			this.setDefaultMaxOccurences();
			this.setDefaultExpiresOn();
		} else if (this.isAfterOccurencesType) {
			this.clearExpiresOn();
			this.setDefaultMaxOccurences();
		}
		this.triggerValidationOnEndType();
	}

	/*
	* Clear expiry ie. Expiry is set to 'None' so we dont want to limit the repeat value to a default expiry date.
	*/
	private clearExpiresOn(): void {
		this.schedule.expiresOn = null;
	}

	private setDefaultExpiresOn(): void {
		this.schedule.expiresOn = this.getExpiresOn;
	}

	private setDefaultMaxOccurences(): void {
		this.schedule.maxOccurrences = 0;
	}

	private triggerValidationOnEndType(): void {
		this.triggerDatesValidation();
		this.$v!.schedule!.maxOccurrences!.$touch();
	}

	private triggerDatesValidation(): void {
		this.$v!.schedule!.startDateTime!.$touch();
		this.$v!.schedule!.endDateTime!.$touch();

		if (this.selectedEndType !== EndTypes.None) {
			this.$v!.schedule!.expiresOn!.$touch();
		}
	}

	private getDefaultSchedule(): Schedule {
		let startDateTime = new Date().toISOString();
		let endDateTime = moment(startDateTime).add(1, "days").toDate().toISOString();
		return {
			scheduleTypeId: null,
			groupId: this.selectedGroup,
			scheduleId: null,
			startDateTime: startDateTime,
			endDateTime: endDateTime,
			title: "",
			intervalValue: minIntervalValue,
			intervalTypeId: null,
			maxOccurrences: null,
			expiresOn: null,
			responseId: null,
			publicHolidayTypeId: null,
			daysToRun: "",
			disabled: false,
			responseMaskingMeta: null,
			serverTypeEvent: null,
			nextOccurrence: null
		}
	}

	private isAlarmInAlarmList(alarm: Response, alarmList: Response[]): boolean {
		if (!alarm || !alarmList) {
			return false;
		}

		return alarmList.findIndex((alarm) => alarm.responseID == alarm.responseID) > -1;
	}

	private async setAreaTimeZone(): Promise<void> {
		if(!this.schedule.groupId){
			return;
		}

		let { timeZoneID } : { timeZoneID: number } = await api.loadGroup(this.schedule.groupId);
		this.timeZoneId = timeZoneID;
	}

	private getDateOnly(date: Date): string {
		return moment(date).utc(true).startOf("day").toISOString();
	}

	private getTimeOnly(date: Date): string {
		let hours = date.getHours().toString().padStart(2, "0");
		let mins = date.getMinutes().toString().padStart(2, "0");

		return `${hours}:${mins}`;
	}

	private async loadEventTypes(groupId: number){
		let { data } = await api.loadServerEventTypes(this.manualRaiseServerTypeId, groupId);
		this.eventTypes = data;
	}
}
