
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { TimeZone } from "../../store/types";
import vSelect from "vue-select";
import { CompanyInfoModel, ContactInfoModel } from "@/store/signup/types";
import api from "@/services/api.service";
import { Customer } from "@/store/subscription/types";
import AreaLocationSetup from "@/views/AreaSetup/AreaLocationSetup.vue";
import { AreaLocation } from "@/views/AreaSetup/AreaLocationSetup.vue";

const SignUp = namespace("signup");

@Component({
    components: {
        "vue-select": vSelect,
        "location-setup": AreaLocationSetup
    }
})
export default class CompanyInfo extends Vue {
    @SignUp.State companyInfo: CompanyInfoModel;
    @SignUp.State contactInfo: ContactInfoModel;
    @SignUp.State timeZones: TimeZone[];
    @SignUp.State isCreditCardRequired: boolean;
    @SignUp.Mutation setCompanyInfo: any;
    @SignUp.Mutation advanceCurrentStep: any;
    @SignUp.Mutation setRegistrationComplete: any;
    @SignUp.Mutation setCustomerId: any;

    public $refs!: {
        locationSetup: AreaLocationSetup;
    }

    private companyInfoInput: CompanyInfoModel = {
        name: "",
        address: "",
        latLong: "",
        timeZoneId: 37
    };

    private async mounted() {
        // if company info has already been provided
        if (this.companyInfo.name !== "") {
            this.companyInfoInput = { ...this.companyInfo };
        }
        else
        {
            this.companyInfoInput.address = `${this.contactInfo.addressLine1 ? this.contactInfo.addressLine1 + ', ' : ''}` +
            `${this.contactInfo.addressLine2 ? this.contactInfo.addressLine2 + ', ' : ''}` +
            `${this.contactInfo.city ? this.contactInfo.city + ', ' : ''}` +
            `${this.contactInfo.state ? this.contactInfo.state + ', ' : ''}` +
            `${this.contactInfo.zipCode ? this.contactInfo.zipCode : ''}`;

            this.companyInfoInput.address = this.companyInfoInput.address.trimEnd();
            if (this.companyInfoInput.address.charAt(this.companyInfoInput.address.length - 1) === ',') {
                this.companyInfoInput.address =  this.companyInfoInput.address.slice(0, -1);
            }
        }

        //Give time for the location setup control to mount
        await this.$nextTick();
        if (this.companyInfoInput.address && !this.companyInfoInput.latLong && this.$refs.locationSetup) {
            this.$refs.locationSetup.setLatLongBasedOnAddress(true);
        }
    }

    private errorMessage: string = "";

    @Watch("companyInfoInput", { deep: true })
    private onCompanyInfoChange() {
        this.setCompanyInfo({ ...this.companyInfoInput });

        if (!this.isCreditCardRequired) {
            this.setRegistrationComplete(this.isValid());
        }
    }

    private isValid() {
        if (!this.companyInfoInput.name || !this.companyInfoInput.address || !this.companyInfo.latLong)
        {
            return false;
        }

        return true;
    }

    private async submit() {
        if (!this.isValid()) {
            return;
        }

        let customer = await this.createCustomer();
        if (customer.hasSubscription) {
            this.errorMessage = "An account already exists with this email address."
        } else {
            this.errorMessage = "";
            this.advanceCurrentStep();
        }
    }

    private async createCustomer() {
        let customerDetails: Customer = {
            firstName: this.contactInfo.firstName,
            lastName: this.contactInfo.lastName,
            email: this.contactInfo.email,
            telephone: this.contactInfo.telephone,
            companyName: this.companyInfoInput.name,
            address1: this.contactInfo.addressLine1,
            address2: this.contactInfo.addressLine2,
            country: this.contactInfo.country,
            state: this.contactInfo.state,
            city: this.contactInfo.city,
            zipCode: this.contactInfo.zipCode,
            billingPortalLink: {
                expiresAt: new Date(),
                url: ""
            },
            hasSubscription: false,
            id: -1
        }

        let newCustomer = await api.putCustomer(customerDetails);

        this.setCustomerId(newCustomer.id);

        return newCustomer;
    }

    private locationChanged(location: AreaLocation | null): void {
        if (!location) {
            this.companyInfoInput.address = null;
            this.companyInfoInput.latLong = null;
            return;
        }
        this.companyInfoInput.address = location.address;
        this.companyInfoInput.latLong = location.latLong;
    }
}
