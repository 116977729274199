import {
	IMarker,
	ILayer,
	ILatLng,
} from "@sureview/v2-mapping-saas";

export class LocationBounds {
	public location: ILatLng;
	public viewport: {
		northeast: ILatLng;
		southwest: ILatLng;
	};
}

export class MapItemIcon {}

export class LayerMap {
	[s: number]: ILayer;
}
export class ItemMap {
	[s: number]: IMarker;
}
export class ItemObjectMap {
	[s: number]: IMarker[];
}
export class ItemTypeObjectMap {
	[s: number]: ItemObjectMap;
}

export class LayerTypeMap {
	[s: number]: boolean;
}

export class MarkerArrayMap {
	[s: number]: IMarker[];
}

export class SubscriberExpiry {
	public subscriber: string;
	public expiry: number;
}

export class MapCameraDevice {
	public id: number;
	public title: string;
}

export enum EnhancedMapMode {
	SiteMonitor = 1,
	FieldOps = 2,
	EventQueue = 3,
	EventSearch = 4,
}