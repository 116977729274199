import { MutationTree } from "vuex";
import getDefaultState from "./state";
import { MobileRaiseState, MobileRaiseTemplateProjection } from "./types";

const caseInsensitiveComparer = (a: string, b: string) => {
	const aLowerCase = a.toLowerCase();
	const bLowerCase = b.toLowerCase();
	if (aLowerCase < bLowerCase) {
		return -1;
	}
	if (aLowerCase > bLowerCase) {
		return 1;
	}
	return 0;
};

export const mutations: MutationTree<MobileRaiseState> = {
	setTemplateProjections(state, projections: MobileRaiseTemplateProjection[]) {
		state.templateProjections = projections;

		if (state.templateProjections.length === 0) {
			return;
		}

		state.templateProjections.sort((a, b) => caseInsensitiveComparer(a.title, b.title));

		const templateIdx = state.templateProjections.findIndex(
			p => p.mobileRaiseTemplateID === state.lastUsedTemplateId
		);
		if (templateIdx !== -1) {
			state.currentTemplateProjectionIdx = templateIdx;
		} else {
			state.currentTemplateProjectionIdx = 0;
			state.lastUsedTemplateId = state.templateProjections[0].mobileRaiseTemplateID;
		}
	},
	setIsProjectionsLoaded(state, val) {
		state.isProjectionsLoaded = val;
	},
	previousTemplateProjection(state) {
		if (state.templateProjections.length === 0) {
			return;
		}
		if (state.currentTemplateProjectionIdx === 0) {
			state.currentTemplateProjectionIdx = state.templateProjections.length - 1;
		} else {
			state.currentTemplateProjectionIdx--;
		}
		state.lastUsedTemplateId = state.templateProjections[state.currentTemplateProjectionIdx].mobileRaiseTemplateID;
	},
	nextTemplateProjection(state) {
		if (state.templateProjections.length === 0) {
			return;
		}
		if (state.currentTemplateProjectionIdx === state.templateProjections.length - 1) {
			state.currentTemplateProjectionIdx = 0;
		} else {
			state.currentTemplateProjectionIdx++;
		}
		state.lastUsedTemplateId = state.templateProjections[state.currentTemplateProjectionIdx].mobileRaiseTemplateID;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
