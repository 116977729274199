
import { Component, Vue, Mixins, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { FilteredEvent, PendingEventPickup } from "@/store/eventqueue/types";
import { get } from "lodash";
import RegionalHandlerMixin from '@/mixins/RegionalHandlerMixin';

const Eventqueue = namespace("eventqueue");

@Component({components: {}})
export default class ProcessAlarmConfirmation extends Mixins(RegionalHandlerMixin) {
    @Getter("getFeature") private getFeature: (featureName: string[]) => boolean;
	@Eventqueue.State("pendingEventPickup") private pendingEventPickup: PendingEventPickup;

    private showModal: boolean = false;
    private alarmDesc: string = "";
    private alarmArea: string = "";

    private async processAlarm(): Promise<void>{
        if(this.pendingEventPickup.highestPriorityAlarm) {
            await this.processTopAlarm(this.pendingEventPickup.currentFilterID, this.pendingEventPickup.pickupMethod, true);
        } else {
            await this.handleEventQueueItem(
                this.pendingEventPickup.eventQueueItem, 
                this.pendingEventPickup.checkInProcessing,
                this.pendingEventPickup.openInNewWindow, 
                this.pendingEventPickup.pickupMethod, 
                true //bypass the confirmation as we have already confirmed
            );
        }
    }

    private cancelAlarm(): void {
        this.setPendingEventPickup(null);
    }
    
    @Watch("pendingEventPickup", {deep: true})
    private pendingEventWatch(){
        if(this.pendingEventPickup){
            this.showModal = true;
            if(this.pendingEventPickup.eventQueueItem){
                this.alarmDesc = this.pendingEventPickup.eventQueueItem?.eventTitle;
                this.alarmArea = this.pendingEventPickup.eventQueueItem?.groupTitle;
            }

            return;
        }
        this.showModal = false;
    }

}
