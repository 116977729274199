
import { namespace } from "vuex-class";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import { Datetime } from "vue-datetime";
import EventOutcomeSelector from "@/components/EventOutcomeSelector.vue";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import AlarmPointSelect from "@/components/alarm-points/AlarmPointSelect.vue";

const Reports = namespace("reports");
@Component({
	components: {
		"vue-select": vSelect,
		"event-outcome-selector": EventOutcomeSelector,
		datetime: Datetime,
		"area-tree-select": AreaTreeSelect,
		"alarm-point-select": AlarmPointSelect
	}
})
export default class ReportFilters extends Vue {
	@Reports.Getter("getReport") report;

	private created(): void {
		this.TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	@Prop()
	public value: any;

	public selectedFilters: any;

	public mounted (): void {
		if (this.value)
			this.selectedFilters = this.value;
	}

	@Watch("value")
	private valueChanged(value:any ): void {
		this.selectedFilters = value;
	}

	private TimeZone = "UTC";

	private outcomeChanged(value): void {
		if (value) {
			this.value.eventOutcomeId.splice(0, 9, { id: value });
		} else {
			this.value.eventOutcomeId = [];
		}
	}

	private areaFilterUpdated(area: any): void {
		if (!area || !this.value || this.value["groupId"] === undefined) {
			return;
		}

		var areaIndex = this.value["groupId"].map(a => a.id).indexOf(area.id);
		if (areaIndex >= 0) {
			this.value["groupId"].splice(areaIndex, 1);
			return;
		}
		this.value["groupId"].push(area);
	}
}
