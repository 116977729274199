
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { axiosInstance } from "@/axios.instance";
import { FilteredEvent } from "@/store/eventqueue/types";

const Eventqueue = namespace("eventqueue");

@Component
export default class ResetActionsCCure extends Vue {

	@Prop()
	public event: FilteredEvent;

	@Eventqueue.Action public resetAllActions: any;
	@Eventqueue.Action public resetAllActionsWithRegion: any;

	private resetting: boolean = false;
	// Flag used to turn resetAllActions icon red when true.
	private resetActionsFailed: boolean = false;
	// Tooltip for resetAllActions icon. This updates when request failed with error.
	private resetActionsTooltip: string = "Reset All Actions";
	// Used to store the ID of the setTimeout.
	private resetActionsTimeout: any = null;

	public async resetActions(eventQueue: any) {
		if (!this.resetting) {
			this.resetting = true;
			// On clicking the icon, tootltip should always be set to its default. If icon is clicked when tooltip has error message, the request is resent thus tooltip is reset to default.
			this.resetActionsTooltip = "Reset All Actions";
			// On the case the operator clicks before the setTimeout is completed, timeout must be cleared.
			if(this.resetActionsTimeout != null) {
				clearTimeout(this.resetActionsTimeout);
				this.resetActionsTimeout = null;
			} 

			try {
				var resetEndpoint = "";

				if (
					typeof eventQueue.regionID == "undefined" ||
					eventQueue.regionID == null ||
					eventQueue.regionID <= 0
				) {
					await this.resetAllActions(eventQueue.eventID);
				} else {
					await this.resetAllActionsWithRegion({
						eventId: eventQueue.eventID,
						regionId: eventQueue.regionID
					});
				}
				// Reset the properties back to default state when successful.
				this.resetting = false;
				this.resetActionsFailed = false;
				this.resetActionsTimeout = null;
			} catch (err) {
				console.error(`Couldn't reset all actions for event ${eventQueue.eventID}: ${err}`);

				// Set the resetting property back to false as the operation failed.
				this.resetting = false;
				// The reset has failed so we set this property true to turn the icon red.
				this.resetActionsFailed = true;
				// Failed message updated so hovering the icon will present the message.
				this.resetActionsTooltip = `Couldn't reset all actions for event ${eventQueue.eventID}: Error: ${err.message}`;
				// Remove red color on icon and reset tooltip after ten seconds.
				this.resetActionsTimeout = setTimeout(()=> {
					// Reset the rest of the properties after 10 seconds. Icon color will return to default.
					this.resetActionsFailed = false;
					this.resetActionsTooltip = "Reset All Actions";
					this.resetActionsTimeout = null;
				}, 10000);

				this.$notify({
					type: "error",
					title: "Reset All Actions",
					text:
						"Unable to reset all actions - please try again later, or contact support if the problem persists"
				});
			}
		}
	}

	public beforeDestroy() {
		if(this.resetActionsTimeout != null) {
			clearTimeout(this.resetActionsTimeout);
		} 
	}


}


