
import { Prop, Vue, Watch, Component } from "vue-property-decorator";
import { CustomFieldDto, CustomFieldTypes } from "@/store/custom-fields/types";
import { CustomFieldTypesForDisplay } from "@/types/sv-data/custom-fields/Constants";
import { numberFormattingMixins } from "@/mixins"
import api from "@/services/api.customField.service";
import vSelect3 from "vselect3";

const { enforceWholeNumber } = numberFormattingMixins.methods;

@Component({
	components: {
		"v-select-3": vSelect3
	}
})
export default class CustomFieldUpdateView extends Vue {
	@Prop({ required: true }) public customFieldTableTypeId: CustomFieldTypes;
	@Prop({ required: true }) public actionTitle: string;
	@Prop({ required: true }) public selectedCustomField: CustomFieldDto;
	@Prop({ required: true }) public customFieldToUpdate: CustomFieldDto;
	@Prop({ required: true }) public isUpdateViewShown: boolean;

	private readonly maxLength = 100;
	private readonly minLength = 1;
	private readonly maxValue = 2147483647;
	private readonly minValue = 0;
	private readonly addActionTitle = "Add";
	private readonly editActionTitle = "Edit";
	private customFieldTypeOptions = CustomFieldTypesForDisplay;

	private get customFieldValid(): boolean {
		return !(this.selectedCustomField.title == null ||
			this.selectedCustomField.title.length < this.minLength ||
			this.selectedCustomField.title.length > this.maxLength ||
			this.selectedCustomField.customFieldTypeId == null ||
			this.selectedCustomField.placeholder.length > this.maxLength ||
			this.selectedCustomField.order < 0
		);
	}

	private get hasChanged(): boolean {
		return !(this.selectedCustomField == null ||
			this.selectedCustomField.title == this.customFieldToUpdate.title &&
			this.selectedCustomField.customFieldTypeId == this.customFieldToUpdate.customFieldTypeId &&
			this.selectedCustomField.required == this.customFieldToUpdate.required &&
			this.selectedCustomField.placeholder == this.customFieldToUpdate.placeholder &&
			this.selectedCustomField.order === this.customFieldToUpdate.order
		);
	}

	private async createCustomField(): Promise<void> {
		try {
			this.selectedCustomField.customFieldTableTypeId = this.customFieldTableTypeId;
			if (this.selectedCustomField.order.toString() == "") {
				this.selectedCustomField.order = null;
			}
			await api.createCustomField(this.selectedCustomField);
			this.showTableView();
		} catch (ex) {
			console.error("Failed to add custom field: " + ex);
		}
	}

	private async updateCustomField(): Promise<void> {
		try {
			await api.updateCustomField(this.selectedCustomField);
			this.showTableView();
		} catch (ex) {
			console.error("Failed to update custom field: " + ex);
		}
	}

	private customFieldOrderFormatter(orderValue: number): number {
		return enforceWholeNumber(orderValue);
	}

	@Watch('selectedCustomField.type')
	private checkRequiredValid(): void {
		if (this.selectedCustomField.customFieldTypeId == CustomFieldTypes.Checkbox) {
			this.selectedCustomField.required = false;
		}
	}

	private showTableView(): void {
		this.$emit("show-table-view");
	}
}
