
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { MobileRaiseProjection } from "@/store/mobile-raise/types";
import Title from "./Title.vue";
import api from "@/services/api.service";
import mobileApi from "@/services/api.mobile.service";
import UploadFileButton from "@/components/mobile/core/UploadFileButton.vue";
import { UserPermissions } from "@/store/types";

const Mobile = namespace("mobile");

@Component({
	components: {
		"raise-title": Title,
		"upload-file-button": UploadFileButton
	}
})
export default class ManualRaiseDetails extends Vue {
	@Prop() raise: MobileRaiseProjection;
	@Prop() eventId: number;
	@Prop() eventLocation: string;
	@Mobile.Action addNoteForEvent: any;
	@Getter getFeature: (featureName: string[]) => boolean;
	@Getter getPermissions: UserPermissions;
	@Getter getUserName: string;

	private notes: string = "";
	private cancelUpload: boolean = false;
	private isUploading = false;

	private onIsUploading({ state }: { state: boolean }) {
		this.isUploading = state;
	}

	private onUploadSuccess() {
		this.isUploading = false;
	}

	private async onComplete() {
		await this.addNote();
		this.$emit("close");
	}

	private async addNote() {
		if (this.notes.length > 0) {
			await this.addNoteForEvent({
				guardNote: this.notes,
				eventId: this.eventId
			});
		}
	}
}
