









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";

const Emails = namespace("emails");

@Component
export default class EmailAttachments extends Vue {
    @Prop(String)
    emailId!:string;

    @Emails.Getter('getVideos') getVideos: any;
    @Emails.Getter('getPdfs') getPdfs: any;
    @Emails.Getter('areImagesExists') areImagesExists: any;
    @Emails.Getter('getAttachmentsHTML') getAttachmentsHTML: any;
    @Emails.Getter('getExtraImages') getExtraImages: any;

    showAttachment: object = {};

	@Watch("showAttachmentsHTML", { deep: true, immediate: true })
	public setShowAttachment (showAttachmentsHTML) {
		if(!showAttachmentsHTML) return

    	this.showAttachment = Object.keys(showAttachmentsHTML)
			.reduce((prev, key) => ({
				...prev,
				[key]: false
			}), {})
	}

    public get extraImages() {
        return this.getExtraImages(this.emailId)
    }

    public get isPdfExist() {
        return this.pdfs && this.pdfs.length
    }

    public get isVideoExist() {
        return this.videos && this.videos.length
    }

    public get isAttachmentsExist() {
        return this.isImageListExist || this.isPdfExist || this.isVideoExist
    }

    public get pdfs() {
        return this.getPdfs(this.emailId)
    }

    public get videos() {
        return this.getVideos(this.emailId)
    }

    public get attachmentsHTML() {
        return this.getAttachmentsHTML(this.emailId)
    }

	public get showAttachmentsHTML() {
		return this.attachmentsHTML
			&& this.attachmentsHTML.reduce((prev, src, i) => ({
				...prev,
				[`${i}-html-type`]: {
					key: `${i}-html-type`,
					src,
				}
			}), {})
	}

    public get isImageListExist() {
        return !!this.areImagesExists(this.emailId)
    }

	public setElementIframe($event, key) {
		this.showAttachment[key] = true

		this.$nextTick(() => {
			const elementIframe: HTMLIFrameElement = $event.target
			const scrollHeight = elementIframe.contentWindow.document.body.scrollHeight
			const bottomIndent = 10
			const height = `${scrollHeight + bottomIndent}px`

			elementIframe.style.maxHeight = height
			elementIframe.style.minHeight = height
		})
	}
}
