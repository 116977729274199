


































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "@/store";
import { Getter, Action, State } from "vuex-class";
import AccessTimerModal from "@/components/system-admin/AccessTimerModal.vue";
import { UserPermissions } from "@/store/types";

// ENV variables available at runtime like so:
// console.log(process.env);
// @ref https://cli.vuejs.org/guide/mode-and-env.html


declare global {
	interface Window {
		// declare interface to ZenDesk widget
		zE: any;
		executivesummarywidget: any;
	}
}

@Component({
	components: {
		"access-timer-modal": AccessTimerModal,
	}
})
export default class ImmixCloudAppV2 extends Vue {
	// Refresh root store featuresList
	@Action loadFeaturesList: any;
	@Getter isLoggedIn: boolean;
	@Action startApiHeartbeat: any;
	@Getter getPermissions: UserPermissions;
	@State isSystemAdminAccessTimeModalHidden: boolean;

	@Watch("$route")
	async onRouteChange(to, from) {
		let loadFeatures = true;

		if (
			to.hasOwnProperty("meta") &&
			to.meta.hasOwnProperty("loadFeatures") &&
			typeof to.meta.loadFeatures === "boolean"
		) {
			loadFeatures = to.meta.loadFeatures;
		}

		if (loadFeatures) {
			if (this.isLoggedIn) {
				// Call and get featureList from the cache.
				await this.loadFeaturesList();
			}
		}

		this.renderZendeskWidget();
	}

	private renderZendeskWidget(){
		// if we're on the login page, show the help button
		if (this.$route.name == "login" && this.$route.query.redirect !== "/mobile") {
			window.zE("webWidget", "show");
		} else {
			// hide the help button
			window.zE("webWidget", "hide");
		}
	}

	private mounted(){
		this.renderZendeskWidget();
	}

	private confirmModal(){
		const activeModal = document.getElementsByClassName("modal show")[0];
		//If no active modal, ignore
		if(!activeModal)
			return;

		//If the active modal has a custom ksConfirm trigger that
		const taggedConfirmButton = activeModal.getElementsByClassName(".ksConfirm")[0];
		if(taggedConfirmButton){
			(taggedConfirmButton as any).click();
			return;
		}

		//Otherwise trigger the default ok button
		const okBtn = activeModal.getElementsByClassName("btn btn-primary")[0];
		if(okBtn){
			(okBtn as any).click();
			return;
		}
	}
}
