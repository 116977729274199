
import { Component, Vue, Watch, Ref, Prop, Emit } from "vue-property-decorator";
import NavHeader from "@/components/NavHeader.vue";
import api from "@/services/api.service";

import MobileAssetList from "@/components/mobile-assets/MobileAssetList.vue";
import { AssetMapLayerItem, AssetStatusInfo, ActionRequest, ActionType } from "@/components/mobile-assets/types";
import Sidebar from "@/components/Sidebar.vue";
import AssetStatusInput from '@/components/mobile/assets/AssetStatusInput.vue';
import MobileAssetHistory from '@/components/mobile-assets/MobileAssetHistory.vue';
import MessagesList from "@/components/mobile/messages/MessagesList.vue";
import MapDataSources from '@/components/situational-awareness/MapDataSources.vue';
import { Action, Getter, namespace } from "vuex-class";
import { MessageWithAttachment } from "@/store/guard-chat/types";
import * as messagesApi from "@/services/api.guardChat.service";
import EnhancedSingleMap from '@/components/EnhancedSingleMap.vue';
import AssetMove from "@/components/mobile/assets/AssetMove.vue";
import { SubscriberExpiry, EnhancedMapMode } from '@/types/EnhancedSingleMapTypes';
import EqStatusInfoBar from "@/components/EqStatusInfoBar.vue";
import { UserPermissions, LogoutPayload } from '@/store/types';
import { Constants } from '@/types/Constants';
import { DefaultViewTriggers } from '@/store/map-layers/types';
import DesktopLeftSidebar from '@/components/DesktopLeftSidebar.vue';
import SituationalAwarenessAlertsControl from '@/components/situational-awareness/SituationalAwarenessAlertsControl.vue';
import { SituationalAwarenessEventQueue } from '@/store/situational-awareness/types';


const stringToLatLng = (s: string) => {

	// if the lat long is null dont do anything with it
	if (!s) {
		return null;
	}

	const coords = s.split(" ");
	return {
		lat: parseFloat(coords[0]),
		lng: parseFloat(coords[1])
	};
};

const GuardChat = namespace("guardChat");
const FieldOpsStore = namespace("fieldOps");
const MapLayers = namespace("mapLayers");

@Component({
	components: {
		"nav-header": NavHeader,
		"mobile-asset-list": MobileAssetList,
		"side-bar": Sidebar,
		"asset-audit-history" : MobileAssetHistory,
		"asset-move" : AssetMove,
		"asset-status-input" : AssetStatusInput,
		"messages-list": MessagesList,
		"enhanced-map-single" : EnhancedSingleMap,
		"eq-status-info-bar": EqStatusInfoBar,
		"data-sources": MapDataSources,
		"situational-awareness-alerts": SituationalAwarenessAlertsControl,
		"desktop-left-sidebar": DesktopLeftSidebar
	}
})
export default class FieldOps extends Vue {
	@Ref() readonly messagesList: MessagesList;
	@Ref() auditHistory: MobileAssetHistory;
	@Ref() statusHistory: MobileAssetHistory;

	// logout
	@Action logout: (payload?: LogoutPayload) => void;
	@Action serverLogout: any;

	@Getter getPermissions: UserPermissions;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter getIsFieldOpsLicensed: boolean;

	@GuardChat.State(s => s.twoWayNumberOfUnread) numberOfUnread: number;
	@GuardChat.Getter("getTwoWayMessages") messages: MessageWithAttachment[];
	@GuardChat.Mutation setTwoWayNumberOfUnread: (newValue: number) => void;
	@GuardChat.Mutation resetTwoWayState: () => void;
	@GuardChat.Action loadTwoWayMessages: (eventId: number) => Promise<void>;

	@FieldOpsStore.Action("loadOnlineAssets") private loadOnlineAssets: () => void;
	@FieldOpsStore.Action("initAssetPoll") private initaliseAssetPolling: () => void;
	@FieldOpsStore.Action("addOrUpdateActiveSubscriber") private subscribeToPoll: (subscriberExpiry: SubscriberExpiry) => string;
	@FieldOpsStore.Action("loadAssetTypes") private loadAssetTypes: () => void;
	@FieldOpsStore.State("assetTypes") private assetTypes: Map<string, any>;
	@FieldOpsStore.State("onlineAssets") private onlineAssets: AssetMapLayerItem[];
	@FieldOpsStore.State("viewingAssetId") private viewingAssetId: number;
	@FieldOpsStore.Action("updateAssetLocation") private updateAssetLocation: (asset: AssetMapLayerItem) => void;
	@FieldOpsStore.Mutation("setViewingAssetId") private setViewingAsset: (assetId: number) => void;
	@FieldOpsStore.State private situationalAwarenessEventQueue: SituationalAwarenessEventQueue[];

	@MapLayers.Mutation private setComputeDefaultView: (computation: DefaultViewTriggers) => void;

	private assetItems: any[] = [];
	private wide: boolean = false;
	private selectedItem: AssetMapLayerItem = null;
	private editAsset: AssetMapLayerItem = null;
	private requestPending: boolean = false;
	private editedAssetsId: Set<number> = new Set(); //Set only allows unique values
	private message: string = "";
	private currentActionTabIndex : number = 0;
	private currentControlTabIndex : number = 0;
	private refreshEditAsset: boolean = false;
	private settingAssetLocation: AssetMapLayerItem = null;
	private loadChat: boolean = true;
	private scrollChatToLastMessage: boolean = true;
	private subscriberTimer = null;
	private alertCount: number = 50;

	private enhancedMapMode = EnhancedMapMode;

	private async created() {
		if(this.isSuiteEnabled && !this.getIsFieldOpsLicensed) {
			this.logoutUser(Constants.FIELD_OPS_NO_LICENCE_ERROR);
		}
		await this.loadAssetTypes();
		await this.loadOnlineAssets();
		this.assetItems = [...this.onlineAssets];

		await this.initaliseAssetPolling();
		this.subscriberTimer = setInterval(async () => {
			this.subscribeToPoll({ subscriber: "FieldOps", expiry: Date.now() + 5100});
		}, 5000);
	}

	mounted() {
		this.removeZendeskIcon();
		this.setComputeDefaultView({assets: false, areas: false});
	}

	private removeZendeskIcon() {
		let zenDesk = document.getElementById("launcher");

		// if we only do this action once it doesn't always add the class, so doing it on every update is required.
		if(zenDesk && !zenDesk.classList.contains("hideLauncher")){
			// hide zendesk icon
			zenDesk.classList.add("hideLauncher");
		}
	}

	private get isSuiteEnabled(): boolean {
		return this.getFeature(["Suite"]);
	}

	private get isMobileStatusUpdateEnabled(): boolean {
		return this.getFeature(["Mobile", "FieldOps", "EditMobileStatus"]);
	}

	@Watch("viewingAssetId")
	private async viewedAssetChange(){
		if(this.viewingAssetId && this.assetItems && this.assetItems.length > 0){
			const selectedAsset = this.assetItems.find(u => u.assetId === this.viewingAssetId);
			this.selectedItem = selectedAsset;

			if(!this.$refs.mobileMap)
				await this.$nextTick();

			if(this.$refs.mobileMap && selectedAsset){
				(this.$refs.mobileMap as any).centerOnPosition(selectedAsset.latLng,17,false);
			}
			this.setViewingAsset(null);
		}
	}

	@Watch("onlineAssets", { deep:true })
	private async assetWatcher(newAssets: AssetMapLayerItem[] | null, oldAssets: AssetMapLayerItem[] | null){
		await this.getStatusNotificationChanges(oldAssets, newAssets);
		this.assetItems = [...newAssets];
		this.viewedAssetChange();
		if(this.selectedItem !== null){
			const selectedAsset = this.assetItems.find(u => u.assetId === this.selectedItem.assetId);
			// clear selected user, if it goes offline
			if (this.selectedItem && !selectedAsset) {
				this.selectedItem = null;
				this.editAsset = null;
			}

		} else if (this.currentActionTabIndex === ActionType.History && this.auditHistory){
			this.auditHistory.reloadHistory();
		}

		if(this.statusHistory){
			this.statusHistory.reloadHistory();
		}
	}

	private async logoutUser(errorMessage: string = null): Promise<void> {
		await this.serverLogout();
		if(errorMessage) {
			const logoutPayload: LogoutPayload = {
				hasSessionExpired: false,
				playAudioAlert: false,
				errorMessage: errorMessage
			}
			await this.logout(logoutPayload);
		} else {
			await this.logout();
		}
	}

	// Checks a clone of the beforeAsset and currentAsset list and checks for any differences
	private async getStatusNotificationChanges(preAssets: AssetMapLayerItem[], postAssets: AssetMapLayerItem[]){
		if(preAssets && preAssets.length > 0){
			//Map the before assets into a status array
			const beforeAssets = preAssets.map(x => { return {
				assetId : x.assetId,
				title: x.title,
				status: x.status,
				statusNote: x.statusNote
			}});

			//Map the before assets into a status array
			const afterAssets = postAssets.map(x => { return {
				assetId : x.assetId,
				title: x.title,
				status: x.status,
				statusNote: x.statusNote
			}})

			//Find all the assets that have changed
			var changedAssets = afterAssets.filter((asset) => {
				var result = beforeAssets.find(x => x.assetId === asset.assetId
                    && x.status === asset.status
                    && x.statusNote === asset.statusNote);
				if(result)
					return false;
				return true;
			});

			// If we have any create a notification for each one
			if(changedAssets){
				changedAssets.forEach(asset => {
					if(this.editedAssetsId.has(asset.assetId)){
						this.editedAssetsId.delete(asset.assetId);
						return;
					}

					//find what the asset state was so we can compare which properties have changed
					const beforeAsset = beforeAssets.find(x => x.assetId === asset.assetId)

					// If the beforeAsset is undefined/null - it means we just came from offline to online
					//	and as such have no appropriate previous state.
					if(!beforeAsset){
						return;
					}

					if(this.selectedItem
						&& this.selectedItem.assetId === asset.assetId
						&& this.currentActionTabIndex === ActionType.Status) {
						//If the editAsset is set and has been edited, display the refresh notification
						if(this.editAsset && this.editAsset.isAsset &&
							(beforeAsset.statusNote !== this.editAsset.statusNote || beforeAsset.status !== this.editAsset.status))
						{
							this.refreshEditAsset = true;
						}
						else
						{
							const updatedAsset = postAssets.find(u => u.assetId === this.selectedItem.assetId);
							if(updatedAsset){
								this.editAsset = updatedAsset
							}
						}
					}

					var notifyBody = asset.title;
					if (beforeAsset.status !== asset.status || beforeAsset.statusNote !== asset.statusNote) {
						notifyBody += this.getStatusChangeMessage(beforeAsset, asset)
					}

					this.$notify({
						group: "fieldOpsNotification",
						type: "info",
						title: "Status change:",
						text : notifyBody,
						duration: 5000
					});

				});
			}

			//clean up edited ids
			if(this.editedAssetsId && this.editedAssetsId.size > 0) {
				let newEditAssetIds: Set<number> = new Set();

				//create a new edit list for any Ids which have not yet received the update
				this.editedAssetsId.forEach(element => {
					const before = beforeAssets.find(u => u.assetId === element);
					const after = afterAssets.find(u => u.assetId === element);
					if(before.statusNote !== after.statusNote && before.status !== after.status){
						newEditAssetIds.add(element);
					}
				});
				this.editedAssetsId = newEditAssetIds;
			}
		}
	}

	// Creates the status notification message
	private getStatusChangeMessage(previousAssetState: AssetStatusInfo, newAssetState: AssetStatusInfo) : string {
		//Note same logic exists in Data asset update any updates must also be made here to the data
		const defaultStatus = "No status";
		const clearedStatus = "Status cleared";
		const defaultNote = "No status note";
		const clearedNote = "Status note cleared";

		var message = " : "
						+ (newAssetState.status ? newAssetState.status
							: (previousAssetState.status ? clearedStatus : defaultStatus))
						+ " - "
						+ (newAssetState.statusNote ? newAssetState.statusNote
							: (previousAssetState.statusNote ?  clearedNote : defaultNote))

		return message;
	}

	beforeDestroy() {
		clearInterval(this.subscriberTimer);
	}

	// Called when we manually update the location of a map pin
	private async updateMapItemLocation(item: AssetMapLayerItem){
		this.updateAssetLocation(item);
		this.$emit("selectedItem", item);
	}

	private async onMoveAssetToLatLong(item: AssetMapLayerItem){
		await this.updateMapItemLocation(item);
		if(this.$refs.mobileMap){
			(this.$refs.mobileMap as any).centerOnPosition(item.latLng,0,true);
		}
	}

	private async onMapClick(clickLocation: string){
		// If we are currently trying to add an asset to the map
		if (this.settingAssetLocation) {
			// Update our currently selected asset to house the new lat-long data
			this.settingAssetLocation.latLng = stringToLatLng(clickLocation);
			this.settingAssetLocation.latLong = clickLocation;

			// Update our map item location
			this.updateMapItemLocation(this.settingAssetLocation);

			// Update the AssetList interface
			(this.$refs.assetListComp as any).isSettingLocation(false);

			// Clear out our currently selected asset to place (we just placed it)
			this.settingAssetLocation = null;
		}
	}

	private async onItemSelected(item: AssetMapLayerItem) {
		this.resetTwoWayState();
		this.selectedItem = item;
		if(this.isMobileUserSelected){
			this.loadChat = false;
			await this.$nextTick();
			this.loadChat = true;
			this.scrollChatToLastMessage = true;
		}

		if(this.$refs.mobileMap && this.isMobileUserSelected){
			(this.$refs.mobileMap as any).centerOnAssetItem(this.selectedItem.assetId);
		}
	}

	private assetStatusDisabled(){
		var result = this.assetItems.find(
            x => x.assetId === this.editAsset.assetId
                    && x.status === this.editAsset.status
                    && x.statusNote === this.editAsset.statusNote
        );
        if (result
			|| this.requestPending
			|| !this.editAsset.status
			|| !this.editAsset.status.trim()) {
            return true;
        }
        return false;
	}

	private async setAssetStatus(){
		try{
			this.requestPending = true;
			await api.setAssetStatus(this.editAsset);
			this.editedAssetsId.add(this.editAsset.assetId);
			//Update the asset entry in the onlineAssets list
			var index = this.assetItems.findIndex(
				x => x.assetId === this.editAsset.assetId
			);
			if (index !== -1){
				this.assetItems[index] = {...this.editAsset};
			}
		} finally {
			this.requestPending = false;
		}
	}

	private findAndSetAsset(assetId: number){
		if(!assetId)
			return;

		this.refreshEditAsset = false;
		const selectedAsset = this.assetItems.find(u => u.assetId === this.selectedItem.assetId);
		if (selectedAsset) {
			this.selectedItem = selectedAsset;
			this.editAsset = selectedAsset;
		}
	}

	private async setAction(actionRequest: ActionRequest | null) {
		if (actionRequest) {
			// Set our default state (not placing an item on the map)
			this.settingAssetLocation = null;

			// If we want to set a location, then save our selected item
			if(actionRequest.action === ActionType.SetLocation){
				this.settingAssetLocation = actionRequest.item;
			}

			if(actionRequest.action === ActionType.CancelSetLocation){
				this.settingAssetLocation = null;
			}

			if(actionRequest.action === ActionType.HideLocation || actionRequest.action === ActionType.ShowLocation){
				this.updateMapItemLocation(actionRequest.item);
			}

			if(actionRequest.asset){
				this.findAndSetAsset(actionRequest.asset.assetId);
			}

			await this.$nextTick();
			this.currentActionTabIndex = actionRequest.action;

		} else {
			this.editAsset = null;
		}

		// Update the AssetList interface
		(this.$refs.assetListComp as any).isSettingLocation((this.settingAssetLocation != null));
	}

	//assetListComp

	@Watch("selectedItem")
	private selectedItemChange(){
		if(this.selectedItem){
			this.actionTabIndexChange();
			this.$emit("selectedItem");
		}
	}

	@Watch("currentActionTabIndex", { deep:true })
	private async actionTabIndexChange() {
		if (this.currentActionTabIndex === ActionType.Status) {
			const selectedAsset = this.assetItems.find(u => u.assetId === this.selectedItem.assetId);
			if (selectedAsset) {
				this.editAsset = {...selectedAsset};
			}
			return;
		}

		//When we access the chat tab scroll to bottom if required
		if(this.currentActionTabIndex === ActionType.Chat && this.scrollChatToLastMessage && this.isMobileUserSelected){
			this.scrollChatToLastMessage = false;
			await this.$nextTick();
			await this.$nextTick();
			this.messagesList.scrollToLastMessage();
		}

		this.editAsset = null;
	}

	private get isMobileUserSelected() {
		return this.selectedItem && !this.selectedItem.isAsset
	}

	private get canHaveStatusSet() {
		if (this.selectedItem.isAsset || this.isMobileStatusUpdateEnabled) {
			return true;
		}

		return false;
	}

	private get messagesListData() {
		return this.isMobileUserSelected ? this.messages : [];
	}

	private get getLoadMessagesAction() {
		return this.isMobileUserSelected ? () => this.loadTwoWayMessages(this.selectedItem.userId) : () => Promise.resolve();
	}

	private async sendMessage() {
		if(!this.message){
			return;
		}

		const message = this.message;
		this.message = "";
		await messagesApi.sendTwoWayMessage(this.selectedItem.userId, {
			messageType: "operator",
			message
		});
	}

	private get isChatShouldBeMounted() {
		return this.isMobileUserSelected && this.loadChat;
	}

	private get isChatVisible() {
		return this.isChatShouldBeMounted && this.currentActionTabIndex === ActionType.Chat;
	}

	private updatedTask: (() => void) | undefined;

	@Watch("isChatVisible")
	private onMessagesVisibilityChange(newVal, oldVal) {
		if (newVal) {
			this.updatedTask = () => {
				if(this.messagesList) this.messagesList.restoreScrolling();
			}
		} else {
			if (this.messagesList) this.messagesList.storeScrolling();
			this.updatedTask = undefined;
		}
	}

	private updated() {
		if (this.updatedTask) {
			this.updatedTask();
			this.updatedTask = undefined;
		}
	}

	private async onScrollToLastMessage() {
		if (this.selectedItem && this.currentActionTabIndex === ActionType.Chat) {
			const chatUserId = this.selectedItem.userId;
			await messagesApi.acknowledgeTwoWayForUser(chatUserId);
		}
	}

	private get unackedMessageCount() : string {
		if(!this.editAsset || (this.editAsset && this.editAsset.isAsset))
			return "";

		//get the live unacked value
		const liveValue = this.assetItems.find(x => x.assetId === this.editAsset.assetId)
		if(!liveValue)
			return "";

		return liveValue ? (liveValue.unackedMessageCount > 99 ? "99+" : liveValue.unackedMessageCount.toString()) : "";
	}

	private splitLines(str: string): string[] {
		return str.split("\n");
	}

	private tryCenterOnMarker(data, closeHandler) {
		if (this.$refs.mobileMap && data && data.eventRecordId) {
			(this.$refs.mobileMap as any).centerOnEventQueueItem(data.eventRecordId);
		}
		closeHandler();
	}

	private get isStatusInfoBarEnabled() {
		return this.getFeature(["SystemInfoBar"]);
	}

	private get isSituationalAwarenessEnabled(): boolean {
		return this.getFeature(["SituationalAwareness"]);
	}

	private get isSituationalAwarenessExternalAlertEnabled() : boolean {
		let isFeatureEnabled: boolean = this.getFeature(["SituationalAwareness","ExternalAlerts"]);
		let userHasPermission: boolean = (this.getPermissions.canViewSituationalAwarenessEvents || this.getPermissions.canDismissSituationalAwarenessEvent || this.getPermissions.canPromoteSituationalAwarenessEvent) || this.getPermissions.isSystemAdmin;

		return isFeatureEnabled && userHasPermission;
	}

	private get alertCountBadge(): string {
		return this.situationalAwarenessEventQueue.length > 0 ? `${this.alertCount}/${this.situationalAwarenessEventQueue.length}` : "0";
	}

	public setAlertQueueSize(alerts: number): void {
		this.alertCount = alerts;
	}
}
