
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, namespace, Getter, State } from "vuex-class";
import api from "@/services/api.service";
import vSelect from "vue-select";
import { v4 as uuidv4 } from "uuid";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import ContactInfo from "@/views/SignUp/ContactInfo.vue";
import CompanyInfo from "@/views/SignUp/CompanyInfo.vue";
import SecurityInfo from "@/views/SignUp/SecurityInfo.vue";
import PaymentInfo from "@/views/SignUp/PaymentInfo.vue";
import {
    CompanyInfoModel,
    ContactInfoModel,
    PaymentInfoModel,
    SecurityInfoModel,
    SignUpStep
} from "@/store/signup/types";

interface RegisterModel {
    customerId: number;
	firstName: string
	lastName: string;
	emailAddress: string;
	telephone: string;
	password: string;
	confirmPassword: string;
	companyName: string;
	companyAddress: string;
    latLong: string,
    currency: string;
	timeZoneId: number;
	paymentProfileId: number;
}

const SignUpStore = namespace("signup");

@Component({
	components: {
        PaymentInfo,
        SecurityInfo,
        CompanyInfo,
		"vue-select": vSelect,
		scrollbar: VuePerfectScrollbar,
        "contact-info": ContactInfo
	}
})
export default class SignUp extends Vue {
	@Action processLoginResponse: any;
	@Getter("getRequiresConfiguration") private requiresConfiguration: boolean;
	@Getter("getIsOnPrem") private isOnPrem: boolean;
	@Action fetchAppOptions: () => Promise<void>;
	@Action fetchRequiresConfig: () => Promise<void>;

    @SignUpStore.Getter private currentStep: () => SignUpStep;
    @SignUpStore.Getter private steps: () => SignUpStep[];
    @SignUpStore.State private contactInfo: ContactInfoModel;
    @SignUpStore.State private companyInfo: CompanyInfoModel;
    @SignUpStore.State private securityInfo: SecurityInfoModel;
    @SignUpStore.State private customerId: number;
    @SignUpStore.State private isComplete: boolean;
    @SignUpStore.State private isCreditCardRequired: boolean;
    @SignUpStore.Mutation private resetState;
    @SignUpStore.Action private fetchIsCreditCardRequired;

	private get lastStep() {
        return this.steps[this.steps.length - 1];
    }

	public loggingIn: boolean = false;
	private errorMessage = "";

	private paymentInfo: PaymentInfoModel = {
	    billingAddress: {
	        addressLine1: "",
            addressLine2: "",
            state: "",
            city: "",
            country: "",
            zipCode: ""
        },
        cardInfo: {
	        firstName: "",
            lastName: "",
            cardNumber: {
                part1: "",
                part2: "",
                part3: "",
                part4: ""
            },
            expirationMonth: undefined,
            expirationYear: undefined,
            cvc: undefined
        }
    }

    private registrationModel: RegisterModel = {
        customerId: null,
        firstName: null,
        lastName: null,
        emailAddress: null,
        password: null,
        confirmPassword: null,
        telephone: null,
        companyName: null,
        companyAddress: null,
        latLong: null,
        currency: null,
        timeZoneId: 35,
        paymentProfileId: 0
    };

	private async created(): Promise<void>
	{
		if(this.isOnPrem == null)
		{
			await this.fetchAppOptions();

			if(this.isOnPrem)
			{
				await this.fetchRequiresConfig();
			}
		}

		this.redirectIfAlreadyConfigured();
	}

	private async mounted() {
        await this.fetchIsCreditCardRequired();
    }

	private async Signup(): Promise<void> {
		this.loggingIn = true;

		this.registrationModel.firstName = this.contactInfo.firstName;
        this.registrationModel.lastName = this.contactInfo.lastName;
        this.registrationModel.emailAddress = this.contactInfo.email;
        this.registrationModel.telephone = this.contactInfo.telephone;
        this.registrationModel.companyName = this.companyInfo.name;
        this.registrationModel.companyAddress = this.companyInfo.address;
        this.registrationModel.timeZoneId = this.companyInfo.timeZoneId;
        this.registrationModel.customerId = this.customerId;
        this.registrationModel.password = this.securityInfo.password;
        this.registrationModel.confirmPassword = this.securityInfo.passwordConfirmation;
        this.registrationModel.currency = this.contactInfo.currency;
        this.registrationModel.latLong = this.companyInfo.latLong;

        try {
            this.registrationModel.paymentProfileId = await this.createPaymentProfileForCustomer();
        }
        catch (error) {
            this.errorMessage = error.response.data;
            this.loggingIn = false;
            return;
        }

        try {
			let result = await api.register(this.registrationModel);

			if (result.error) {
				this.errorMessage = result.error;
				this.loggingIn = false;
				return;
			}
			if(!this.isOnPrem && !this.isSureViewSystemsEmail(this.contactInfo.email)) {
				this.postToHubSpot();
			}

            this.errorMessage = "";
			await this.processLoginResponse(result);
            await this.$nextTick();
			this.resetState();
			await this.$nextTick();
			this.$router.push("/event-queue");
			this.loggingIn = false;
		} catch {
			this.loggingIn = false;
		}
	}

	private async createPaymentProfileForCustomer() {
	    if (!this.isCreditCardRequired){
	        return;
        }

        let cardNumberParts = this.paymentInfo.cardInfo.cardNumber;
        let fullCardNumber = `${cardNumberParts.part1}${cardNumberParts.part2}${cardNumberParts.part3}${cardNumberParts.part4}`;
        let paymentProfile = {
            firstName: this.paymentInfo.cardInfo.firstName,
            lastName: this.paymentInfo.cardInfo.lastName,
            address1: this.paymentInfo.billingAddress.addressLine1,
            address2: this.paymentInfo.billingAddress.addressLine2,
            country: this.paymentInfo.billingAddress.country,
            state: this.paymentInfo.billingAddress.state,
            city: this.paymentInfo.billingAddress.city,
            zipCode: this.paymentInfo.billingAddress.zipCode,
            customerId: this.customerId,
            cardNumber: fullCardNumber,
            expirationYear: this.paymentInfo.cardInfo.expirationYear,
            expirationMonth: this.paymentInfo.cardInfo.expirationMonth,
            cvc: this.paymentInfo.cardInfo.cvc
        }

        return await api.createPaymentProfileForCustomer(paymentProfile);
    }

	private onPaymentStepComplete(paymentInfo: PaymentInfoModel) {
        this.paymentInfo = paymentInfo;
    }

    private isSureViewSystemsEmail(email) {
		const isIncludesSV = email.toLocaleLowerCase().includes('@sureviewsystems.com')

		return isIncludesSV && !email.includes('+hubspot')
	}

    /**
     * post new contact info to HubSpot
     */
    private postToHubSpot() {
        try {
            if (!process.env.VUE_APP_HUBSPOT_URI || process.env.VUE_APP_HUBSPOT_URI === "") {
                return;
            }
            // our current portal id
            let portalId = 6208914;
            // new guid to make request unique
            let uuid = uuidv4();

            // create Payload
            let data = {
                contactFields: { email: this.contactInfo.email, firstName: this.contactInfo.firstName },
                formSelectorClasses: "",
                formSelectorId: "",
                formValues: {
                    Phone: this.contactInfo.telephone,
                    "Company Name": this.companyInfo.name,
                    "Street address": this.companyInfo.address,
                    TimezoneId: this.companyInfo.timeZoneId
                },
                labelToNameMap: { Phone: "", "Company Name": "", "Street address": "", TimezoneId: "" },
                pageTitle: document.title,
                pageUrl: window.location.href,
                portalId: portalId,
                type: "SCRAPED",
                uuid: uuid,
                version: "collected-forms-embed-js-static-1.170"
            };

            /**
             * DG - Note
             * using XMLHttpRequest to bypass cors issues.
             * AxioInstance and axiosJsonInstance both throw cors issue occasionally
             */
            let xhr = new XMLHttpRequest();
            let payload = JSON.stringify(data);

            xhr.open("POST", process.env.VUE_APP_HUBSPOT_URI);
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.onreadystatechange = function() {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    console.log(xhr.responseText); // Returns a 200 response if the submission is successful.
                } else if (xhr.readyState == 4 && xhr.status == 400) {
                    console.log(xhr.responseText); // Returns a 400 error the submission is rejected.
                } else if (xhr.readyState == 4 && xhr.status == 403) {
                    console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
                } else if (xhr.readyState == 4 && xhr.status == 404) {
                    console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
                }
            };

            xhr.send(payload);
        } catch (err) {
            console.log(`Unable to post to hubspot`);
            console.error(err);
        }
    }

	@Watch("requiresConfiguration")
	private redirectIfAlreadyConfigured(): void
	{
		if (this.isOnPrem && this.requiresConfiguration === false)
		{
			this.$router.push({ path: "/login" });
		}
	}
}
