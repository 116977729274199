import { axiosInstance } from "@/axios.instance";

import { ActionTree } from "vuex";
import { SiteMonitorCamerasState, DeviceControllerOutput } from "./types";
import { RootState } from "../types";
import api from "@/services/api.service";
import { DeviceType } from "../devices/types";
import { DeviceServerDetails } from "./../site-monitor/types";

export const actions: ActionTree<SiteMonitorCamerasState, RootState> = {
	activeMapItemsFromStorage() {
		return new Promise((resolve, reject) => {
			const activeMapItems = localStorage.getItem("activeMapItems") as string;

			if (activeMapItems) {
				const data = JSON.parse(activeMapItems);
				resolve(data);
			} else {
				reject();
			}
		});
	},
	async fetchAreaCameras({ commit, state }, { groupID, pageNumber, paginated }) {
		const newDevices = (
			await api.devicesByGroup(groupID, {
				pageSize: 20,
				page: pageNumber,
				noCamRefOnly: false,
				deviceTypeId: DeviceType.Camera,
				paginated
			})
		).data;
		const devices = newDevices.devices;
		const totalCameraCount = newDevices.totalDeviceCount;

		commit("setAreaCameraCount", totalCameraCount);

		let camerasMapped = devices
			.map(camera => {
				return {
					objectID: camera.deviceID,
					itemType: DeviceType.Camera,
					title: camera.title,
					index: null,
					lastError: camera.lastError,
					lastInteractionHealthy: camera.lastInteractionHealthy
				};
			});

		let cameraList = [];

		if (paginated) {
			if (pageNumber == 1) {
				cameraList = camerasMapped;
			} else {
				cameraList = state.areaCameras.concat(camerasMapped);
			}
		} else {
			cameraList = camerasMapped;
		}

		commit("setAreaCameras", cameraList);

		const deviceServerDetails: DeviceServerDetails[] =
			devices
				.map(dev => {
					return {
						deviceId: dev.deviceID,
						serverId: dev.serverID
					}
				});

		commit("siteMonitor/setDeviceServerDetails", deviceServerDetails, { root: true });
	},
	async fetchAreaOutputs({ commit, state }, { groupID, pageNumber, paginated }) {
		const pageSize: number = 1;

		let deviceOutputs = (
			await api.devicesByGroup(groupID, {
				pageSize: pageSize,
				page: pageNumber,
				deviceTypeId: DeviceType.Relay,
				paginated: paginated
			})
		).data;

		if (deviceOutputs.totalDeviceCount == 0)
		{
			deviceOutputs = (
				await api.devicesByGroup(groupID, {
					pageSize: pageSize,
					page: pageNumber,
					deviceTypeId: DeviceType.Output,
					paginated: paginated
				})
			).data;
		}

		const outputsMapped: DeviceControllerOutput[] = deviceOutputs.devices
		.map(output => {
			return {
				controlRequest: false,
				controlReason: "",
				controlError: "",
				sendingCommand: false,
				distance: output.distance,
				imperial: output.imperial,
				deviceTypeID: output.deviceTypeID,
				objectId: output.deviceID,
				title: output.title,
				isOutput: output.settings.RelayType == "Output",
				canPulse: output.settingsMeta.includes("<FeaturePulse/>"),
				canOnOff: output.settingsMeta.includes("<FeatureOnOff>1</FeatureOnOff>"),
			}
		});

		let devicesList = [];

		if (paginated) {
			if (pageNumber == 1) {
				devicesList = outputsMapped;
			} else {
				devicesList = state.areaOutputs.concat(outputsMapped);
			}
		} else {
			devicesList = outputsMapped;
		}

		commit(
			"setAreaOutputs",
			devicesList
		);
	},
	updateMediaMatrixContents({ commit }, { index, eventID, contents, isController }: any) {
		commit("updateMediaMatrixContents", { contents, index });

		commit("setMatrixContents", {
			index,
			contents
		});

		if (isController && eventID > 0) {
			const saveContents = {
				clip: null as any,
				camera: null as any
			};
			if (contents.clip != null) {
				saveContents.clip = {
					fileIdentifier: contents.clip.clip ? contents.clip.clip.FileIdentifier : "",
					uniqueFileIdentifier: contents.clip.clip ? contents.clip.clip.UniqueFileIdentifier : "",
					eventRecordID: contents.clip.eventRecordID
				};
			} else if (contents.camera != null) {
				saveContents.camera = { deviceId: contents.camera.objectID };
			}

			axiosInstance.post("/Matrix/Cell", {
				eventID,
				cellIndex: index,
				contents: JSON.stringify(saveContents)
			});
		}
	},
	async fetchDevicesByGroup({ commit }, { groupID, pageSize, page, noCamRefOnly, paginated }) {
		const data = await api.devicesByGroup(groupID, { pageSize, page, noCamRefOnly, paginated });
		return data;
	},
	async fetchAllDevicesByGroup({ commit }, { groupID, pageSize, noCamRefOnly, paginated }) {
		const devices = await api.allDevicesByGroup(groupID, { pageSize, noCamRefOnly, paginated });
		return devices;
	},
	async loadDeviceById(context, { deviceId, params }) {
		return await api.loadDeviceById(deviceId, params);
	},
	async saveMatrix(context, payload: any) {
		const { data } = await api.saveMatrix(payload);
		return data;
	},
	async fetchMatrixForEvent(context, eventID: number) {
		const { data } = await api.fetchMatrixForEvent(eventID);
		return data;
	},
	async searchDevicesNear({ rootGetters }, { search, deviceTypeID, groupID, page }) {
		const { data } = await api.deviceSearchNear({
			LatLong: rootGetters["siteMonitor/getMapCircleCenter"],
			search,
			deviceTypeID,
			groupID,
			page
		});

		if (rootGetters.getUseImperialMeasurements) {
			data.devices.forEach(distance => {
				if (distance.distance) {
					distance.distance = metersToFeet(distance.distance);
					distance.imperial = true;
				}
			});
		}

		return data;
	},
	async searchForOutputs({ commit, dispatch }, { query, groupID }) {
		const data = await dispatch("searchDevicesNear", {
			search: query,
			deviceTypeID: DeviceType.Relay,
			groupID
		});

		data.devices.forEach(output => {
			output.controlRequest = false;
			output.controlReason = "";
			output.controlError = "";
			output.sendingCommand = false;
			output.isOutput = output.settingsMeta.includes("<RelayType>Output</RelayType>");
			output.canPulse = output.settingsMeta.includes("<FeaturePulse/>");
			output.canOnOff = output.settingsMeta.includes("<FeatureOnOff>1</FeatureOnOff>");
		});

		commit("setSearchedOutputs", data);
	},
	async searchForCameras({ commit, dispatch, state }, { query, groupID, page, reset }) {
		const data = await dispatch("searchDevicesNear", {
			search: query,
			deviceTypeID: DeviceType.Camera,
			groupID,
			page
		});

		commit("setSearchedCameraCount", data.totalDeviceCount);
		commit("setSearchedGroupCameraCount", data.groupDeviceCount);

		if (data.devices.length < 1) {
			commit("setSearchedCameras", { devices: data.devices, reset: false });
		}

		commit("setSearchedCameras", { devices: data.devices, reset: reset });

		const deviceServerDetails: DeviceServerDetails[] = data.devices.map(d => {
			return {
				deviceId: d.deviceId,
				serverId: d.serverId
			}
		});
		commit("siteMonitor/setDeviceServerDetails", deviceServerDetails, { root: true });
	},
	async getAllDeviceDetails({ rootGetters }, { deviceIDs, eventID, latLong }) {
		const details = await api.getMatrixDeviceDetails(deviceIDs, eventID, latLong);

		if (details !== null || details !== undefined) {
			for (const deviceDetails of details) {
				if (rootGetters.getUseImperialMeasurements && deviceDetails.distance) {
					deviceDetails.distance = metersToFeet(deviceDetails.distance);
					deviceDetails.imperial = true;
				}
			}
		}

		return details;
	},
	async setCameraScreenshot({ commit }, data: any) {
		api.saveCameraScreenshot(data);
	},
	async isNetworkAddressReachable(context, networkAddress: string): Promise<Boolean> {
		if (!networkAddress) {
			return false;
		}
		return await api.isUrlReachable(networkAddress, (networkAddress.endsWith("/") ? "" : "/") + "devices/info");
	},
};

// Pure helper fn
const metersToFeet = (meters: number) => {
	if (meters < 0) {
		return 0;
	} else {
		return Math.round(meters / 0.3048);
	}
};
