export interface SiteMonitorCamerasState {
	layoutIndex: number;
	layouts: number[][];
	matrixContents: MatrixContents;
	matrixPushContents: MatrixContents;
	mediaMatrixCameras: any[];
	mediaMatrixClips: any[];
	mediaMatrixLayers: any[];
	areaCameras: CameraType[];
	deviceControllerCameras: any[];
	deviceControllerOutputs: any[];
	deviceControllerAudioDevices: any[];
	deviceControllerClips: any[];
	mediaMatrixIsNew: boolean;
	fetchingMatrix: boolean;
	mediaMatrixFilter: string;
	mediaMatrixEventId: number | null;
	awaitingCamera: any;
	awaitingClip: any;
	linkRecord: any;
	saveLink: boolean;
	requiresEventDetails: boolean;
	highlightCellIndex: number | null;
	mediaMatrixLastChanged: number;
	waitingForEvent: boolean;
	searchedCameras: any[];
	searchedOutputs: any[];
	shownAuditClips: string[];
	areaOutputs: DeviceControllerOutput[];
	areaCameraCount: number;
	searchedCameraCount: number;
	searchedGroupCameraCount: number;
}

export interface MatrixContents {
	[key: number]: {
		camera?: MatrixCamera;
		clip?: MatrixClip;
	};
}

export interface MatrixCamera {
	objectID: number;
	title: string;
	index: number;
}

export interface ClipType {
	title: string;
	active: boolean;
	FileIdentifier: string;
	UniqueFileIdentifier: string;
	Subtitle: string;
	EventRecordId: number;
	DeviceTitle: string;
	FileSize: number;
	FileSizeError: boolean;
	MediaType: string;
	StartTime?: Date;
	FriendlyStartTime?: string;
	index?: number;
	Error?: string;
	DeviceId?: number;
	CreatedGroupTimezone?: string;
	CreatedUTC: string;
}

export interface MultipleClipsIndex {
	indexes: number[]
}

export interface CameraType {
	title: string;
	distance: number;
	deviceID: number;
	objectID: number;

	active?: boolean;
	index?: any;
	layoutIndex?: any;
	lastError?: string;
	lastInteractionHealthy?: boolean;
}

export interface NearbyCameraType {
	distance: number;
	itemType: number;
	objectId: number;
	title: string;
	index?: any;
}

export interface MatrixClip {
	clip: {
		FileIdentifier: string;
		index?: any;
		UniqueFileIdentifier: string;
	};
	eventRecordID: number;
}

/* interface mapItemType {
  extraValue: string;
  latLong: string;
  mapLayerItemId: number;
  mapLayerItemTypeId: number;
  maxElevation: number;
  minElevation: number;
  objectID: number;
  regionPath: string;
  title: string;

  active?: boolean;
  index?: any;
  layoutIndex?: any;
} */

export interface  DeviceControllerOutput {
	controlRequest: boolean;
	controlReason: string;
	controlError: string;
	sendingCommand: boolean;
	distance: number;
	imperial: boolean;
	deviceTypeID: number;
	objectId: number;
	title: string;
	isOutput: boolean;
	canPulse: boolean,
	canOnOff: boolean,
}

export interface SearchedCamera {
	areaTitle: string;
	distance?: string;
	imperial?: boolean;
	index: number;
	itemType: number;
	objectID: number;
	title: string;
}

export function defaultDeviceControllerOutput(): DeviceControllerOutput {
	return {
		controlRequest: false,
		controlReason: "",
		controlError: "",
		sendingCommand: false,
		distance: null,
		imperial: null,
		deviceTypeID: null,
		objectId: null,
		title: "",
		isOutput: false,
		canPulse: false,
		canOnOff: false
	};
}
