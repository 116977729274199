import { ActionTree } from "vuex";
import { Mobile, UserAssetStatus, MobileEventShareInfo } from "./types";
import { RootState } from "../types";
import mobileApi from "@/services/api.mobile.service";
import api from "@/services/api.service";
import MobileEventShareStates from "@/types/sv-data/enums/MobileEventShareStates";
import GPSService from "@/services/gps.service";
import { convertStringToLatLng } from "@sureview/v2-mapping-saas";

const geoCoder = new GPSService();

export const actions: ActionTree<Mobile, RootState> = {
	async loadUserPermissions({ commit }) {
		const permissions = await mobileApi.loadUserPermissions();
		commit("setUserPermissions", permissions);
	},

	async registerUserDevice(_, { deviceId, deviceType }) {
		return await mobileApi.registerUserDevice(deviceId, deviceType);
	},

	async extendMobileSession(_) {
		await mobileApi.extendMobileSession();
	},

	async fetchAuditEndpoint({ commit }) {
		const endpoint = await api.getAuditServiceAddress();
		commit("setAuditEndpoint", endpoint);
	},

	async addNoteForEvent({ state }, { guardNote, eventId }) {
		await mobileApi.addNoteForEvent(guardNote, eventId);
	},

	async addEventRecordForGuard(
		_,
		{ eventId, details, objectId, eventRecordTypeId }
	) {
		return await mobileApi.addEventRecordForGuard(
			eventId,
			details,
			objectId,
			eventRecordTypeId
		);
	},

	async loadStarredDevices({ commit }) {
		const devices = await mobileApi.getAllStarredDevices();
		commit("setStarredDevices", devices);
	},

	async toggleDeviceStarred({ dispatch }, deviceId) {
		await mobileApi.toggleDeviceStarred(deviceId);
		await dispatch("loadStarredDevices");
	},

	async loadQuickCloseOutcomes({ commit }) {
		const outcomes = await mobileApi.getQuickCloseOutcomes();
		commit("setQuickCloseList", outcomes);
	},

	async loadUserMapOnlineStatus({ commit }, userId: number | null){
		if (userId){
			const currentStateId = await api.getCurrentUserMobileStateId(userId);
			// stateId of 2 = offline,
			commit("setUserMapOnlineStatus", (!currentStateId || currentStateId === 2) ? false : true);
		}
	},

	async setUserMapOnlineStatus({ commit }, online: boolean) {
		await api.toggleCurrentMobileUserState(online);
		commit("setUserMapOnlineStatus", online);
	},

	async loadUserAssetMapItem({ commit }){
		const mapItem = await api.getAssetMapItemForUser();
		commit("setUserAssetMapItem", mapItem);
		if(mapItem && mapItem.assetTypeId){
			const assetType = await api.getAssetTypeByID(mapItem.assetTypeId)
			commit("setUserAssetType", assetType)
		}
	},

	async setUserAssetStatus({ state, commit }, assetStatus: UserAssetStatus){
		await api.setAssetStatus(
			{
				assetId : assetStatus.assetId,
				status : assetStatus.status,
				statusNote : assetStatus.statusNote
			});

		let userAssetMapItem = { ...state.UserAssetMapItem };
		userAssetMapItem.status = assetStatus.status;
		userAssetMapItem.statusNote = assetStatus.statusNote;
		commit("setUserAssetMapItem", userAssetMapItem);
	},
	async loadEventShareItems({ commit, state }){
		const newItems = await api.getMobileEventShareQueueForUser();

		if(newItems){
			var formattedItems = [];
			newItems.forEach(async i => {
				var latLng;
				var eventItemInState = state.EventShareItems.find(x => x.eventID == i.eventID);

				// If we already have the event share, and the location or address hasn't change and we have a latLng use that rather than
				// call the google api again (which can cause a delay in rendering)
				if(eventItemInState
					&& eventItemInState.latLng
					&& eventItemInState.eventLocation == i.eventLocation
					&& eventItemInState.eventAddress == i.eventAddress)
				{
					latLng = eventItemInState.latLng;
				}
				else
				{
					// If we have a location converted it into a LatLng otherwise do an address lookup
					latLng = i.eventLocation ?
					convertStringToLatLng(i.eventLocation)
					: (i.eventAddress ? (await geoCoder.decodeLocation(i.eventAddress, process.env.VUE_APP_GOOGLE_MAPS_API_KEY)).location : "")
				}

				formattedItems.push({
					...i,
					latLng: latLng
				});
			}
			);

			if(state.ActiveEventShareItem){
				const itemIndex = formattedItems.findIndex(x => x.eventID === state.ActiveEventShareItem.eventID);
				if(itemIndex === -1 )
					commit("setActiveEventShareItem", null)
			}

			const activeItem = formattedItems.find(x => x.state === MobileEventShareStates.Accepted);
			if(activeItem)
				commit("setActiveEventShareItem", activeItem)

			commit("setEventShareItems", formattedItems);
		} else {
			commit("setActiveEventShareItem", null)
			commit("setEventShareItems", []);
		}

	},
	async setActiveEventShareItem({ commit, state }, item: MobileEventShareInfo){
		if(item){
			// check if item is in the event share list
			const itemIndex = state.EventShareItems.findIndex(x => x.eventID == item.eventID);
			if(itemIndex === -1 )
				throw "selected item does not exist in the event share queue";
		}

		commit("setActiveEventShareItem", item);
	}
};
