
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import vSelect from "vue-select"; // @ref https://sagalbot.github.io/vue-select/
import UrlInputLine from "@/components/UrlInputLine.vue";
import NotificationSetup from "@/components/tasks/notification/NotificationSetup.vue";
import NewTask from "@/models/new-task.model";
import { TaskType, TaskCategory } from "@/store/tasks/types";
import BasicTask from "@/components/tasks/task-types/BasicTask.vue";
import InputTask from "@/components/tasks/task-types/InputTask.vue";
import YesNoTask from "@/components/tasks/task-types/YesNoTask.vue";
import UrlTask from "@/components/tasks/task-types/UrlTask.vue";
import SitRepTask from "@/components/tasks/task-types/SitRepTask.vue";
import CCureTask from "@/components/tasks/task-types/CCureTask.vue";
import AuditTask from "@/components/tasks/task-types/AuditTask.vue";
import ChoicesTaskSetup from "@/components/tasks/choices/ChoicesTaskSetup.vue";
import CallContactSetup from "@/components/tasks/call-contact/CallContactSetup.vue";
import DynamicDataList from "@/components/tasks/task-types/DynamicDataList.vue"
import ControlTask from "@/components/tasks/control/ControlSetup.vue";
import EventOutcomeTask from "@/components/tasks/task-types/EventOutcomeTask.vue";

import { FeaturesList } from "@/store/types";
import TaskTypeIds from "@/types/sv-data/enums/TaskTypeIds";

const Tasks = namespace("tasks");

@Component({
	components: {
		"vue-select": vSelect,
		"url-input": UrlInputLine,
		"notification-setup": NotificationSetup,
		"basic-task": BasicTask,
		"input-task": InputTask,
		"yes-no-task": YesNoTask,
		"url-task": UrlTask,
		"sitrep-task": SitRepTask,
		"ccure-task": CCureTask,
		"audit-task": AuditTask,
		"choices-task-setup": ChoicesTaskSetup,
		"call-contact-setup": CallContactSetup,
		"dynamic-data-list": DynamicDataList,
		"control-task": ControlTask,
		"event-outcome-task": EventOutcomeTask
	}
})
export default class EditLibraryTask extends Vue {
	public hasValidationErrors: boolean = false;
	public showErrorAnimation: boolean = false; // @otodo move to app-wide setting
	public taskTypeSelectPlaceholder: any = null;

	public placeholderText = {
		1: "Action text",
		2: "Specify question that will require user input to complete action",
		3: "Specify question that will require user to choose Yes/No to complete action"
	};

	@Tasks.Getter("getEnabledActionTypes") enabledActionTypes: TaskType[];
	@Getter("getFeaturesList") featuresList: FeaturesList;

	private TaskTypeIdEnum: typeof TaskTypeIds = TaskTypeIds;

	@Prop(Array)
	public taskTypes!: TaskType[];

	@Prop(Array)
	public taskCategories!: TaskCategory[];

	@Prop({
		default: () => ({
			taskID: {
				// non-editable
				type: Number,
				required: true
			},
			taskText: {
				type: String,
				required: true
			},
			taskTypeID: {
				type: Number,
				required: false
			},
			taskCategoryID: {
				type: Number,
				required: true
			}
		})
	})
	public readonly libraryTask!: any;

	public taskToRender: string = "basic-task";
	public isTaskValid: boolean = false;

	/**
	 * A clone of the libraryTask passed in from the parent. Changes are applied to the clone when editing.
	 */
	private taskToEdit = {
		taskID: 0,
		taskText: "",
		taskTypeID: 0,
		taskCategoryID: 0,
		taskData: null
	};

	// === Watchers === //
	@Watch("libraryTask")
	public onLibraryTaskChanged() {
		this.taskToEdit = { ...this.libraryTask };
	}

	@Watch("libraryTask.title")
	public onTitleChanged(title: string) {
		this.hasValidationErrors = !title.length;
	}

	// === Computed Props === //
	public get libraryTaskCategory() {
		return this.taskCategories.filter(
			category =>
				category.taskCategoryID === this.taskToEdit.taskCategoryID
		)[0];
	}

	public set libraryTaskCategory(taskCategory: TaskCategory) {
		this.taskToEdit.taskCategoryID = taskCategory.taskCategoryID || 1;
	}

	public get libraryTaskType() {
		return this.enabledActionTypes.filter(
			type => type.taskTypeID === this.taskToEdit.taskTypeID
		)[0];
	}

	public set libraryTaskType(taskType: TaskType) {
		if (taskType === null || taskType === undefined) return;

		this.taskToEdit.taskTypeID = taskType.taskTypeID || 1;

		this.loadCorrectComponent();
	}

	public get isValid() {
		let isValid = this.isTaskValid;

		switch (this.taskToEdit.taskTypeID) {
			case 4: // no validation for dispatch type
				isValid = true;
				break;
			default:
				isValid = this.isTaskValid;
		}

		// taskType defaults to basic
		if (!this.taskToEdit.taskTypeID) {
			this.taskToEdit.taskTypeID = 1;
		}

		return isValid;
	}

	// === Methods === //
	public mounted() {
		this.onLibraryTaskChanged();
		this.loadCorrectComponent();
	}

	public loadCorrectComponent() {
		let task = this.enabledActionTypes.find(
			(taskType: any) =>
				taskType.taskTypeID === this.libraryTask.taskTypeID &&
				taskType.componentFile !== null
		);

		// Avoid property undefined errors if a task was not returned.
		if (task !== undefined) {
			this.taskToRender = task.componentFile;
		}
	}

	// === Events === //
	@Emit("save")
	public save() {
		return this.taskToEdit;
	}

	@Emit("cancel")
	public cancel() {
		return this.taskToEdit.taskID;
	}

	// Called when a task component verifies that it is valid.
	public onTaskValidated(valid: boolean) {
		this.isTaskValid = valid;
	}

	public onSaveClicked() {
		if (this.isValid) {
			this.confirmUrlTaskHasTitle();
			this.save();
		}
	}

	private confirmUrlTaskHasTitle() {
		if (this.taskToEdit.taskTypeID === TaskTypeIds.URL) {
			if (this.taskToEdit.taskText === "") {
				this.taskToEdit.taskText = this.taskToEdit.taskData;
				this.taskToEdit.taskData = null;
			}
		}
	}
}
