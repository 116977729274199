
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { TemplateModalMode } from "./TemplateModalMode";
import {
	TemplateDetailsModel,
	MobileRaiseLinkEditModel,
	IconSet,
	RaiseId,
	MobileRaiseTemplate
} from "@/store/mobile-raise-templates/types";
import { namespace } from "vuex-class";
import { UserGroup } from "@/store/user-management/types";
import vSelect from "vue-select";
import createRangeArray from "./createRangeArray";
import LinksSelector from "./LinksSelector.vue";
import { newTemplateDetailsModel, raiseIdsEqual } from "@/store/mobile-raise-templates/typesFunctions";
import { isEqual, cloneDeep, find } from "lodash";
import ConfirmationModal from "./ConfirmationModal.vue";
import RaiseTitle from "./RaiseTitle.vue";
import QuickSelectSlot from "./QuickSelectSlot.vue";
import MobileRaisesLinksControl from "./MobileRaisesLinksControl.vue";
import UserGroupsControl from "./UserGroupsControl.vue";
import TitleControl from "./TitleControl.vue";
import * as titleFeedbacks from "./titleFeedbacks";

const EMPTY_ICON_CLASS = "fas fa-ellipsis-h";
const UserManagement = namespace("userManagement");
const MobileRaiseTemplates = namespace("mobileRaiseTemplates");

@Component({
	components: {
		"vue-select": vSelect,
		"links-selector": LinksSelector,
		"confirmation-modal": ConfirmationModal,
		"raise-title": RaiseTitle,
		"quick-select-slot": QuickSelectSlot,
		"mobile-raises-links": MobileRaisesLinksControl,
		"user-groups-control": UserGroupsControl,
		"title-control": TitleControl
	}
})
export default class TemplateModal extends Vue {
	@Prop() isVisible: boolean;
	@Prop() mode: TemplateModalMode;
	@Prop() templateId: number | null;

	@MobileRaiseTemplates.State mobileRaiseTemplates: MobileRaiseTemplate[];
	@UserManagement.State userGroups: UserGroup[];
	@MobileRaiseTemplates.Getter getIconSetById: (id: number) => IconSet;
	@MobileRaiseTemplates.Getter getIconSetByRaiseId: (raiseId: RaiseId) => IconSet;
	@MobileRaiseTemplates.Getter getTemplateModelById: (templateId: number) => TemplateDetailsModel;

	private originalEditModel: TemplateDetailsModel = newTemplateDetailsModel();
	private editModel: TemplateDetailsModel = newTemplateDetailsModel();
	private isLinksSelectorVisible = false;
	private isDeleteConfirmationShown = false;
	private isCloseConfirmationShown = false;
	private titleFeedback = "";

	@Watch("isVisible", { immediate: true })
	private onVisibilityChange(newValue: boolean) {
		if (newValue) {
			if (this.templateId) {
				this.editModel = this.getTemplateModelById(this.templateId);
				this.originalEditModel = cloneDeep(this.editModel);
			} else {
				this.editModel = newTemplateDetailsModel();
				this.originalEditModel = newTemplateDetailsModel();
			}
		}
	}

	private get isEditMode(): boolean {
		return this.mode === TemplateModalMode.edit;
	}

	private get isAddMode(): boolean {
		return this.mode === TemplateModalMode.add;
	}

	private get isViewMode(): boolean {
		return this.mode === TemplateModalMode.view;
	}

	private get isUnsavedChanges(): boolean {
		return !isEqual(this.editModel, this.originalEditModel);
	}

	private get modalTitle(): string {
		switch (this.mode) {
			case TemplateModalMode.view:
				return "Mobile Raise Template Details";
			case TemplateModalMode.edit:
				return "Edit Mobile Raise Template";
			case TemplateModalMode.add:
				return "Add Mobile Raise Template";
			default:
				throw new Error("Unknown TemplateModalMode");
		}
	}

	private getOptionsForSlot(link: MobileRaiseLinkEditModel | null): MobileRaiseLinkEditModel[] {
		const links = this.editModel.mobileRaiseLinks
		.filter(l => {
			return raiseIdsEqual(l, link)
			|| !find(this.editModel.quickRaiseIds, qr => raiseIdsEqual(l, qr));
		});
		return links;
	}

	private getIconClassForSlot(raiseId: RaiseId | null): string {
		const link = this.getLinkByRaiseId(raiseId);
		return link ? this.getIconClassForLink(link) : EMPTY_ICON_CLASS;
	}

	private getLinkByRaiseId(raiseId: RaiseId): MobileRaiseLinkEditModel {
		const link = this.editModel.mobileRaiseLinks.find(l => raiseIdsEqual(l, raiseId));
		return link;
	}

	private getIconClassForLink(link: MobileRaiseLinkEditModel): string {
		const iconSet = this.getIconSetForLink(link);
		return iconSet ? iconSet.iconData : EMPTY_ICON_CLASS;
	}

	private getIconSetForLink(link: MobileRaiseLinkEditModel): IconSet | undefined {
		if (link.iconSetID === null) {
			return this.getIconSetByRaiseId(link);
		}
		return this.getIconSetById(link.iconSetID);
	}

	private slotInputHandler(slot: number, raiseId: RaiseId | null): void {
		const qr = [...this.editModel.quickRaiseIds];
		qr[slot] = raiseId;
		this.editModel.quickRaiseIds = qr;
	}

	private removeSlot(slot: number): void {
		this.editModel.quickRaiseIds.splice(slot, 1);
	}

	private addSlot(): void {
		this.editModel.quickRaiseIds.push(null);
	}

	private get isAddSlotVisible(): boolean {
		return this.editModel.quickRaiseIds.length < 9;
	}

	private linkKey(slot: number, link: MobileRaiseLinkEditModel | null): string {
		if (!link) {
			return slot.toString();
		}
		return `${slot}${link.typeID}${link.manualRaiseType}`;
	}

	private linksClicked(): void {
		this.isLinksSelectorVisible = true;
	}

	private onLinksSelectorHide(): void {
		this.isLinksSelectorVisible = false;
	}

	private onLinksApply(linkedModels: MobileRaiseLinkEditModel[]): void {
		this.editModel.mobileRaiseLinks = linkedModels;
		const quickRaiseIds = [...this.editModel.quickRaiseIds];
		for (let i = 0; i < quickRaiseIds.length; i++) {
			if (!linkedModels.find(lm => raiseIdsEqual(lm, quickRaiseIds[i]))) {
				quickRaiseIds[i] = null;
			}
		}
		this.editModel.quickRaiseIds = quickRaiseIds;
	}

	private onDelete(): void {
		this.isDeleteConfirmationShown = true;
	}

	private deleteConfirmed(): void {
		this.isDeleteConfirmationShown = false;
		this.$emit("delete", this.editModel.mobileRaiseTemplateID);
	}

	private deleteCancelled(): void {
		this.isDeleteConfirmationShown = false;
	}

	private onAdd(): void {
		this.$emit("add", this.editModel);
	}

	private onSave(): void {
		this.$emit("save", this.editModel);
	}

	private confirmClosing(evt): void {
		// prevent modal from closing
		evt.preventDefault();

		if (this.isUnsavedChanges) {
			this.isCloseConfirmationShown = true;
		} else {
			this.$emit("close");
		}
	}

	private closeConfirmed(): void {
		this.isCloseConfirmationShown = false;
		this.$emit("close");
	}

	private closeCancelled(): void {
		this.isCloseConfirmationShown = false;
	}

	private isSlotValid(slot: number): boolean {
		return !!this.editModel.quickRaiseIds[slot];
	}

	private isMobileRaiseLinksValid(): boolean {
		return this.editModel.mobileRaiseLinks && this.editModel.mobileRaiseLinks.length !== 0;
	}

	private isTitleValid(): boolean {
		const { title } = this.editModel;

		if (!title) {
			this.titleFeedback = titleFeedbacks.required;
			return false;
		}

		if (title.length > 250) {
			this.titleFeedback = titleFeedbacks.maxLengthExceeded;
			return false;
		}

		if (this.isTitleNotUnique()) {
			this.titleFeedback = titleFeedbacks.nonUnique;
			return false;
		}

		this.titleFeedback = "";
		return true;
	}

	private isTitleNotUnique(): boolean {
		const { title, mobileRaiseTemplateID } = this.editModel;
		return this.mobileRaiseTemplates.some(
			t => t.mobileRaiseTemplateID !== mobileRaiseTemplateID && t.title === title
		);
	}

	private isFormValid(): boolean {
		if (!this.isMobileRaiseLinksValid()) {
			return false;
		}

		for (let i = 0; i < this.editModel.quickRaiseIds.length; i++) {
			if (!this.isSlotValid(i)) {
				return false;
			}
		}

		return this.isTitleValid();
	}

	private get isSaveDisabled() {
		return !this.isUnsavedChanges || !this.isFormValid();
	}
}
