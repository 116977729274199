import { MutationTree } from "vuex";
import { SessionsState } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<SessionsState> = {
	setResourceSessionId(state, { resourceId, sessionId }: any) {
		state.sessions[resourceId.toString()] = sessionId;
	},
	clearResourceSessionId(state, resourceId: number) {
		delete state.sessions[resourceId.toString()];
	},
	setResourceStatus(state, status: number) {
		state.requestStatus = status;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
