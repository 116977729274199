
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";

@Component
export default class BufferSeek extends Vue {
	$refs!: {
		downloadProgress: HTMLElement
	}

	/**
	 * Amount of data downloaded
	 */
	@Prop({ type: Number, default: 0 })
	public downloaded: number;

	/**
	 * Total data available
	 */
	@Prop({ type: Number, default: 0 })
	public totalSize: number;

	/**
	 * Current play time
	 */
	@Prop({ type: Number, default: 0 })
	public value: number;

	/**
	 * Clip duration
	 */
	@Prop({ type: Number, default: 0 })
	public duration: number;

	circleHover: boolean = false;
	seeking: boolean = false;
	seekPosition: number = 0;

	/**
	 * Reset seek bar state
	 */
	public reset(): void {
		this.seeking = false;
	}

	public get downloadProgressStyle(): any {
		return {
			width: ((this.downloaded / this.totalSize) * 100) +"%"
		}
	}

	private get playPercentage(): number {
		return (this.downloaded / this.totalSize) * (this.value / this.duration) * 100;
	}

	public get playProgressStyle(): any {
		return {
			width: this.playPercentage +"%"
		}
	}

	public get seekCircleStyle(): any {
		if (this.seeking) {
			return {
				left: (this.seekPosition - 7) + "px"
			}
		} else {
			return {
				left: "calc(" + this.playPercentage +"% " +
					(this.circleHover ? "- 7px" : "- 4px") +")"
			}
		}
	}

	@Emit("input")
	seekToTime(seekPosition: number) {
		// Find proportion of seek bar, then multiply by duration to find time to seek to
		let width = this.$refs.downloadProgress.clientWidth;
		let seekTime = (seekPosition / width) * this.duration;

		this.$emit("change", seekTime);

		return seekTime;
	}

	setHover(hovered: boolean) {
		this.circleHover = hovered;
	}

	public startSeek() {
		this.seeking = true;
	}

	public endSeek() {
		if (this.seeking) {
			this.seeking = false;
			this.seekToTime(this.seekPosition);
		}
	}

	seek(ev: MouseEvent) {
		if (this.seeking && ev.buttons === 1) {
			this.seekPosition = ev.offsetX;
		}
		ev.preventDefault();
	}

	seekTo(ev: MouseEvent) {
		if ((ev.target as HTMLElement).id === "downloadProgressBar") {
			this.seekToTime(ev.offsetX);
			this.seeking = false;
		} else if ((ev.target as HTMLElement).id === "seekCircle") {
			ev.stopPropagation();
			ev.preventDefault();
		}
	}
}
