
import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
import { AuditService } from "@/services/auditService";
import { namespace, Getter, Action } from "vuex-class";
import api from "@/services/api.service";
import { EventDetails } from "@/store/site-monitor/types";

const SiteMonitor = namespace("siteMonitor");
const SessionStore = namespace("sessions");
const Mobile = namespace("mobile");

@Component({})
export default class MessageBlock extends Vue {
	@Prop() msgBlock: any;

	@Getter getUserName: string;
	@SessionStore.Getter getSession: any;
	@SessionStore.Action updateSession: any;
	@SiteMonitor.Action createReviewRecord: any;
	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;

	private intervalId: number;
	private createdFromNow: string = "";

	private updateFromNow(): void {
		this.createdFromNow = moment(this.msgBlock.createdAt + "Z").fromNow();
	}

	created() {
		this.updateFromNow();
		this.intervalId = setInterval(this.updateFromNow.bind(this), 10000) as any;
	}

	beforeUpdate() {
		this.updateFromNow();
	}

	beforeDestroy() {
		clearInterval(this.intervalId);
	}

	private async downloadAttachedFiles(eventRecordId: number) {
		try {
			await this.renewAuditSession();

			const response = await this.getAllFiles(eventRecordId);
			const fileName = response.headers["x-suggested-filename"];
			const url = URL.createObjectURL(response.data);
			this.downloadFile(url, fileName);

			await this.createReviewRecord({
				eventId: this.msgBlock.eventId,
				eventRecordTypeId: 23,
				details: `Files downloaded by user: ${this.getUserName}`
			});
		} catch (error) {
			this.$notify({
				type: "error",
				title: "Attachment Download",
				text:
					"Unable to Download attachment - please try again later, or contact support if the problem persists.",
			});
		}
	}

	private downloadFile(url: string, fileName: string) {
		let link = document.createElement("a");
		link.href = url;
		link.download = fileName;
		link.click();
	}

	private async getAllFiles(eventRecordId: number) {
		let auditService = new AuditService(api.getAuditServiceAddress(), this.eventDetails?.auditEndpoint)

		const result = await auditService.getAllFiles(this.getSession("2"), eventRecordId);
		return result;
	}

	private async renewAuditSession() {
		await this.updateSession({
			resourceId: 2,
			eventId: this.msgBlock.eventid
		});
	}
}
