
import { Component, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import GenericTable, { TableHeader } from '../table/generic-table.vue';
import GenericUpdateModal, { ModalItem } from '../table/generic-update-modal.vue';
import { EventQueuePriorityColors, EventQueuePriorityColorsExample } from '@/store/eventqueue/types.ts';
import { PaginatedSearchQueryParams } from '@/store/types.ts'
import organizationApi from "@/services/api.organizationSettings.ts";
import VSwatches from 'vue-swatches'
import ColorModalItem from './ColorModalItem.vue';
import PaginatedSearch from '@/mixins/PaginatedSearch';
import { namespace } from "vuex-class";
import { numberFormattingMixins } from '@/mixins';

const { enforceWholeNumber } = numberFormattingMixins.methods;
const GenericTableState = namespace("GenericTable");

@Component({
	components: {
		"generic-table": GenericTable,
		"generic-update-modal": GenericUpdateModal,
		"color-swatch" : VSwatches,
		"color-modal-item" : ColorModalItem
	}
})
export default class AlarmQueuePriorityColors extends Mixins(PaginatedSearch){
	@Getter getUserGroupId: number;
	@GenericTableState.Getter getModalRow: any;
	private eventQueuePriorityColors: EventQueuePriorityColorsExample[] = [];
	private modalItemMinPriority: number = 0;
	private modalItemMaxPriority: number = 999999;
	private modalItemMaxLength: number = 10;
	private isLoading: boolean = false;
	private totalRecords: number = 0;
	private paginatedSearchQueryParams: PaginatedSearchQueryParams = null;

	private async beforeMount(): Promise<void> {
		try
		{
			this.retrieveEventQueuePriorityColors();
		} catch (e) {
			console.log("Failed to get Alarm Queue Priority Colors");
		}
	}

	public async retrieveEventQueuePriorityColors(): Promise<void> {
		try {
			this.isLoading = true;
			this.eventQueuePriorityColors = [];
			const request = this.generateNewPaginatedSearchRequest(this.paginatedSearchQueryParams);
			let eventColorsWithPagination = await organizationApi.retrieveAlarmQueuePriorityColors(request);
			this.totalRecords = eventColorsWithPagination.totalRecords;
			this.eventQueuePriorityColors = eventColorsWithPagination.data.map(item => { return {...item, examplePriority: item.minPriority + " - " + item.maxPriority }});
		}
		catch (ex) {
			console.error("Unexpected error fetching priority colors: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	private getPriorityStyle(eventQueuePriorityColor: EventQueuePriorityColors): string
	{
		return `background-color: ${eventQueuePriorityColor.backgroundColor}; color: ${eventQueuePriorityColor.foregroundColor};`;
	}

	private async createEventQueuePriorityColor(eventQueuePriorityColor: EventQueuePriorityColors): Promise<void> {
		try {
			this.isLoading = true;
			await organizationApi.createAlarmQueuePriorityColor(eventQueuePriorityColor);
		} catch (ex) {
			console.error("Failed to add event queue priority color: " + ex);
		} finally{
			this.isLoading = false;
		}

		await this.retrieveEventQueuePriorityColors();
	}

	private async updateEventQueuePriorityColor(eventQueuePriorityColor: EventQueuePriorityColors): Promise<void> {
		try {
			this.isLoading = true;
			await organizationApi.updateAlarmQueuePriorityColor(eventQueuePriorityColor);
		} catch (ex) {
			console.error("Failed to update event queue priority color: " + ex);
		} finally{
			this.isLoading = false;
		}

		await this.retrieveEventQueuePriorityColors();
	}

	private async deleteEventQueuePriorityColor(eventQueuePriorityColor: EventQueuePriorityColors): Promise<void> {
		try {
			this.isLoading = true;
			await organizationApi.deleteAlarmQueuePriorityColor(eventQueuePriorityColor.eventQueuePriorityColorId);
		} catch (ex) {
			console.error("Failed to delete event queue priority color: " + ex);
		} finally {
			this.isLoading = false;
		}

		await this.retrieveEventQueuePriorityColors();
	}

	public columns: TableHeader[] = [
		{
			title: "Minimum Priority",
			key: "minPriority",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The minimum priority for a color",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true
		},
		{
			title: "Maximum Priority",
			key: "maxPriority",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The maximum priority for a color",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true,
			sortable: true
		},
		{
			title: "Example Priority",
			key: "examplePriority",
			order: 3,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Example of how the priority will be colored",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true,
			sortable: false,
			useCustomCell: true
		}
	];

	public get modalItems (): ModalItem[] {
		return [
			{
				title: "Minimum Priority",
				key: "minPriority",
				dataType: "number",
				maxLength: this.modalItemMaxLength,
				min: this.modalItemMinPriority,
				max: this.modalItemMaxPriority,
				required: true,
                placeholder: "Enter a minimum priority",
				readOnly: false,
				defaultValue: "0",
				formatter: (value) => {
					if (value > this.modalItemMaxPriority){
						value = value.toString().substring(0, 6);
					}

					return enforceWholeNumber(value);
				}
            },
			{
				title: "Maximum Priority",
				key: "maxPriority",
				dataType: "number",
				maxLength: this.modalItemMaxLength,
				min: this.modalItemMinPriority,
				max: this.modalItemMaxPriority,
				required: true,
                placeholder: "Enter a maximum priority",
				readOnly: false,
				defaultValue: "0",
				formatter: (value) => {
					if (value > this.modalItemMaxPriority){
						value = value.toString().substring(0, 6);
					}

					return enforceWholeNumber(value);
				}
            },
			{
				title: "Example Priority",
				key: "examplePriority",
				dataType: "component",
				data: ColorModalItem,
				required: false,
				readOnly: true,
            },
			{
				title: "Background Color",
				key: "backgroundColor",
				dataType: "component",
				data: VSwatches,
				maxLength: this.modalItemMaxLength,
				required: true,
                placeholder: "Enter the background color",
				readOnly: false,
				props: {
					"show-fallback": true,
					"fallback-input-type":"color",
					"popover-y":"top",
					"inline": true,
					"fallback-input-class": "width-100px"
				}
            },
			{
				title: "Foreground Color",
				key: "foregroundColor",
				dataType: "component",
				data: VSwatches,
				maxLength: this.modalItemMaxLength,
				required: true,
                placeholder: "Enter the foreground color",
				readOnly: false,
				props: {
					"show-fallback": true,
					"fallback-input-type":"color",
					"popover-y":"top",
					"inline": true,
					"fallback-input-class": "width-100px"
				}
            },
        ]
    }

	private async updatePaginationParameters(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		this.paginatedSearchQueryParams = paginatedSearchQueryParams;
		await this.retrieveEventQueuePriorityColors();
	}
}
