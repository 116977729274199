



























































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { OnTestRequest, OnTestScheduleType } from "@/store/alarm-points/types";
import { validationMixin } from "vuelidate";
import { required, requiredIf, minValue, maxValue, numeric, minLength } from "vuelidate/lib/validators";

import moment from "moment";
import DatePicker from "@sureview/vuejs-datepicker";
import { stringMixin } from "@/mixins";

const { isNullOrWhitespace } = stringMixin.methods;

@Component({
	mixins: [validationMixin],
	validations: {
		onTestRequest: {
			hours: {
				numeric,
				required: requiredIf(function() {
					return this.onTestRequest.scheduleType == this.scheduleType.forPeriod && !this.onTestRequest.minutes;
				}),
				minValue: minValue(0)
			},
			minutes: {
				numeric,
				required: requiredIf(function() {
					return this.onTestRequest.scheduleType == this.scheduleType.forPeriod && !this.onTestRequest.hours;
				}),
				minValue: minValue(0)
			},
			date: {
				required: requiredIf(function() {
					return this.onTestRequest.scheduleType == this.scheduleType.untilDate;
				}),
				minValue: minValue(new Date().setHours(0, 0, 0, 0))
			},
			time: {
				pattern: function(value) {
					if (typeof value == "undefined" || value == null || value == "") return true;
					return /^[0-9]{2}:[0-9]{2}$/.test(value);
				},
				minTime: function(value) {
					let timeHasValue = typeof value !== "undefined" && !isNullOrWhitespace(value);
					let dateHasValue =
						typeof this.onTestRequest.date !== "undefined" && this.onTestRequest.date !== null;
					let needsChecking = this.onTestRequest.scheduleType === this.scheduleType.untilDate;

					if (!timeHasValue || !dateHasValue || !needsChecking) {
						return true;
					}

					const now = new Date().setHours(0, 0, 0, 0);
					const requestDate = this.onTestRequest.date.setHours(0, 0, 0, 0);

					if (now === requestDate) {
						// If the user has chosen today as the re-arm date, we need to make sure that the time they've
						// entered is greater than the current time.
						var momentTime = moment(value, "HH:mm", true).toDate();
						return momentTime > new Date();
					} else {
						// If the user hasn't chosen today, just make sure that the date they've entered is in the future.
						return requestDate > now;
					}
				},
				required: requiredIf(function() {
					return this.onTestRequest.scheduleType == this.scheduleType.untilDate;
				})
			},
			onTestNote: {
				required,
				minLength: minLength(1)
			}
		}
	},
	components: {
		"date-picker": DatePicker
	}
})
export default class OnTestScheduleForm extends Vue {
	@Prop() public readonly value: OnTestRequest;

	/** Consts */
	public scheduleType = {
		forPeriod: OnTestScheduleType.ForPeriod,
		untilDate: OnTestScheduleType.UntilDate
	};

	public readonly today = new Date();

	/**
	 * Copy of the value, where the edits are to be applied.
	 */
	private onTestRequest: OnTestRequest = {
		hours: null,
		minutes: null,
		date: new Date(),
		time: this.defaultTime,
		scheduleType: OnTestScheduleType.ForPeriod,
		auditMode: true,
		onTestNote: null
	};

	/**
	 * Disables any dates before today in the calendar.
	 */
	private calendarDisabledDates = {
		to: new Date(this.today.setDate(this.today.getDate() - 1))
	};

	/**
	 * May not be entirely required - this is just used to set the min property for the HTML5 time input,
	 * but we don't actually use HTML5 validation.
	 */
	public get minTimeForSelectedDate() {
		const today = new Date().setHours(0, 0, 0, 0);
		const defaultMinTime = "00:00";

		if (typeof this.onTestRequest.date == "undefined" || this.onTestRequest.date == null) {
			return defaultMinTime;
		}

		var selectedDate = this.onTestRequest.date.setHours(0, 0, 0, 0);

		if (selectedDate === today) {
			return moment().format("HH:mm");
		} else {
			return defaultMinTime;
		}
	}

	/**
	 * Returns a default time (current time + 1 hour) to end the test.
	 */
	public get defaultTime() {
		return moment()
			.add({ hours: 1 })
			.format("HH:mm");
	}

	/**
	 * Watcher to ensure our edit model is kept up-to-date.
	 */
	@Watch("value")
	public onValueChanged(newValue) {
		this.onTestRequest = newValue;
	}

	/**
	 * Watcher which allows us to bubble up validation, and trigger a v-model update if we have valid data.
	 */
	@Watch("$v.$invalid", { immediate: true })
	public onValidate(hasErrors) {
		if (!hasErrors) {
			this.onInput();
		}

		this.$emit("validate", !hasErrors);
	}

	/**
	 * Emitter to update our v-model binding
	 */
	@Emit("input")
	public onInput() {
		return this.onTestRequest;
	}

	/**
	 * Formatting function for the date picker.
	 */
	public calendarDateFormatter(date: string) {
		return moment(date).format("L");
	}
}
