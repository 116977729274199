/// <reference types="@types/googlemaps" />
// import { } from 'googlemaps';
import CornerBounds from "@/scripts/mapping/cornerBounds";

declare global {
	interface Window {
		google: any;
	}
}

window.google = window.google || {};

// tslint:disable-next-line:class-name
class eventMap {
	[s: string]: Function[];
}

export default class GoogleMap {
	public get bounds(): CornerBounds {
		const bounds = this.map.getBounds();
		return new CornerBounds(bounds!, bounds!.getNorthEast(), bounds!.getSouthWest());
	}

	public container: HTMLElement;
	public mapTypeId: google.maps.MapTypeId;

	public map: google.maps.Map;

	public minLevelForFullIcons: number = 17;
	public minLevelForItems = 14;
	public currentViewState: string | null = null;

	public viewportChangedTimeout: NodeJS.Timeout = null;

	public events: eventMap = new eventMap();

	public constructor(
		containerElement: HTMLElement,
		center: any,
		zoomLevel: number,
		streetview: boolean,
		mapViewType: string,
		mapStyles: any,
		mobileMode: boolean = false
	) {
		if (typeof window.google === "undefined") {
			throw new ReferenceError("Google Maps is not defined");
		}

		this.container = containerElement;

		this.mapTypeId = google.maps.MapTypeId.HYBRID;
		if (mapViewType) {
			switch (mapViewType.toLowerCase()) {
				case "terrain":
					this.mapTypeId = google.maps.MapTypeId.TERRAIN;
					break;
				case "roadmap":
					this.mapTypeId = google.maps.MapTypeId.ROADMAP;
					break;
				case "satellite":
					this.mapTypeId = google.maps.MapTypeId.SATELLITE;
					break;
				case "hybrid":
					this.mapTypeId = google.maps.MapTypeId.HYBRID;
					break;
			}
		}

		this.events["onViewportChanged"] = new Array<Function>();
		this.events["onViewportChangedDelayed"] = new Array<Function>();
		this.events["onDisplayTypeChanged"] = new Array<Function>();
		this.events["onClick"] = new Array<Function>();
		this.events["onMapTypeChanged"] = new Array<Function>();

		this.map = new google.maps.Map(this.container, {
			center,
			zoom: zoomLevel,
			mapTypeId: this.mapTypeId,
			mapTypeControl: !mobileMode,
			zoomControl: !mobileMode,
			scaleControl: false,
			streetViewControl: streetview,
			fullscreenControl: false,
			rotateControl: false,
			styles: mapStyles,
			gestureHandling: "greedy",
			backgroundColor: "none"
		});
		this.map.setTilt(0);

		this.map.addListener("zoom_changed", () => this.handleZoomLevelChanged());
		this.map.addListener("bounds_changed", () => this.handleBoundsChanged());
		this.map.addListener("click", event => {
			this.raise("onClick", event.latLng);
		});
		this.map.addListener("maptypeid_changed", () => {
			this.raise("onMapTypeChanged", this.map.getMapTypeId());
		});

		this.zoomLevelSet(zoomLevel, false);
	}

	public handleZoomLevelChanged() {
		this.zoomLevelSet(this.map.getZoom(), true);
	}

	public handleBoundsChanged() {
		this.raise("onViewportChanged", {});

		if (this.viewportChangedTimeout != null) {
			clearTimeout(this.viewportChangedTimeout!);
		}

		this.viewportChangedTimeout = setTimeout(() => {
			this.raise("onViewportChangedDelayed", this.map.getBounds());
		}, 600);
	}

	public zoomLevelSet(currentZoomLevel: number, raise: boolean) {
		let hasChanged = false;
		if (currentZoomLevel < this.minLevelForItems) {
			if (this.currentViewState === "wide") {
				return;
			}

			this.currentViewState = "wide";
			hasChanged = true;
		} else if (currentZoomLevel <= this.minLevelForFullIcons && currentZoomLevel >= this.minLevelForItems) {
			if (this.currentViewState === "medium") {
				return;
			}

			this.currentViewState = "medium";
			hasChanged = true;
		} else if (currentZoomLevel > this.minLevelForItems) {
			if (this.currentViewState === "narrow") {
				return;
			}

			this.currentViewState = "narrow";
			hasChanged = true;
		}

		if (hasChanged && raise) {
			this.raise("onDisplayTypeChanged", {
				viewState: this.currentViewState
			});
		}
	}

	public raise(event: string, arg: any) {
		this.events[event].forEach(function(handler) {
			handler(arg);
		});
	}

	public registerEvent(event: string, callback: Function) {
		this.events[event].push(callback);
	}

	public buildBoundsForLayerOnPositions(NE: string, SW: string) {
		const neArray = NE.split(" ");
		const neLat = neArray[0];
		const neLng = neArray[1];

		const swArray = SW.split(" ");
		const swLat = swArray[0];
		const swLng = swArray[1];

		const trueNE = { lat: parseFloat(neLat), lng: parseFloat(neLng) };
		const trueSW = { lat: parseFloat(swLat), lng: parseFloat(swLng) };

		return new google.maps.LatLngBounds(trueSW, trueNE);
	}

	public getLatLngPolarity(latLng: string) {
		const regex = /([1-8]?[1-9]|[1-9])\.{1}\d{1,16}([A-Z])/g;

		const result = regex.exec(latLng);
		if (typeof result !== "undefined" && result !== null && result.length === 3) {
			return result[2];
		}

		return "";
	}
}
