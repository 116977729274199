
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CustomFieldDto, CustomFieldTypes, CustomFieldValues } from "@/store/custom-fields/types";
import { truncateString } from "@/filters";

@Component({
	filters: { truncateString }
})
export default class CustomDefinedField extends Vue {
	@Prop({ type: Object, required: true }) public value: CustomFieldValues;
	@Prop({ type: Object, required: true }) public setting: CustomFieldDto;
	@Prop({ type: Number, default: 250 }) public maxCharacters: number;

	private type: string;

	@Watch("type")
	private beforeMount(): void {
		this.type = CustomFieldTypes[this.setting?.customFieldTypeId];
	}

	private checkboxValue(): boolean{
		return this.value.value == "true" ? true : false;
	}
}
