
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";

import { UserPermissions} from "@/store/types";

const SiteMonitor = namespace("siteMonitor");

@Component({})
export default class PreserveEventControl extends Vue {
	@Getter getPermissions: UserPermissions;
	@SiteMonitor.Action preserveEvent: ({ eventId, preserved }) => Promise<boolean>;

	@Prop(Boolean) public isPreserved: boolean;
	@Prop(Number) public eventId: number;
	@Prop(String) public eventPreservedUntil: string;
	@Prop(String) public eventFilesPreservedUntil: string;

	private preserved: boolean = false;
	private isPreserveEventModalShown: boolean = false;

	private mounted() {
		this.preserved = this.isPreserved;
	}

	@Watch("isPreserved")
	private isPreservedUpdated() {
		this.preserved = this.isPreserved;
	}

	private async confirmEventPreservedUpdate() {
		try {
			this.preserved = !this.preserved;
			await this.preserveEvent({
				eventId: this.eventId,
				preserved: this.preserved
			});
			this.preservedUpdated();
		} catch (err) {
			console.error(err);
			this.$notify({
				type: "error",
				title: "Preserve",
				text: "Unable to Preserve event - please try again later, or contact support if the problem persists."
			});

			this.preserved = !this.preserved;
			this.preservedUpdated();
		}
	}

	@Emit("preserved-updated")
	private preservedUpdated() {
		return this.preserved;
	}

	private get isPreservedDateInThePast(): boolean {
		return new Date(this.eventPreservedUntil) < new Date();
	}
}
