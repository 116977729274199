
import { Component, Mixins } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { helpers } from "vuelidate/lib/validators";

import NavHeader from "@/components/NavHeader.vue";
import { UserPermissions, FeaturesList, PaginatedSearchQueryParams } from "@/store/types";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import GenericUpdateModal, { ModalItem } from "@/components/table/generic-update-modal.vue";
import { isArray, cloneDeep, get, invert } from "lodash";
import { ContactFor, ContactRole } from "@/store/user-management/types";
import ContactRoles from "@/views/ContactRoles.vue";
import csvUploaderWrapper from "@/components/csv-uploader/csv-uploader-wrapper.vue";
import { ModalItemCSV } from "@/store/csv-form-handler/types";
import api from "@/services/api.service";
import ContactWithRole from "@/types/sv-data/users/ContactWithRole";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import PaginatedSearch from "@/mixins/PaginatedSearch";
import ContactsPagedResponse from "@/types/sv-data/users/ContactsPagedResponse";
import { stringMixin } from '@/mixins';
import ContactsFor from "@/components/form/ContactsFor.vue";
import { customFieldMixins } from "@/mixins/customFieldMixins";
import { MapCustomFieldToModalItem } from "@/utils/CustomFieldLogic";
import { CustomFieldDto } from '@/store/custom-fields/types';

const Areas = namespace("areas");
const GenericTableStore = namespace("GenericTable");
const CustomFields = namespace("customFields");

const { ellipseAfterX } = stringMixin.methods;
const { mapArrayFieldsToObject } = customFieldMixins.methods;

@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable,
		"generic-update-modal": GenericUpdateModal,
		"contact-roles": ContactRoles,
		"csv-uploader-wrapper": csvUploaderWrapper
	}
})
export default class ContactsPage extends Mixins(PaginatedSearch) {
	@Getter getPermissions: UserPermissions;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getUserId") public currentUserId!: number;
	@Getter getFeature: (featuresList: string[]) => boolean;

	@Areas.Action fetchAreaDictionary: () => Promise<void>;
	@Areas.Getter getAreaTitle: (id: number) => Promise<string>;
	@Areas.State areaDictionary: Map<number, string>;

	@GenericTableStore.Getter private getModalRow: any

	@CustomFields.Action retrieveCustomFields: ({ tableType: number, live: boolean }) => Promise<void>;
	@CustomFields.State contactCustomFields: CustomFieldDto[];

	private showContactRoleModal: boolean = false;
	private progressBulk = {}

	public emailAsUser: boolean = false;
	public isUser: boolean = false;
	private customFieldTableType: number = 2;

	public roleList: ContactRole[] = [];

	public contacts: ContactWithRole[] = [];

	public get columns(): TableHeader[] {

		var columns = [
		{
			title: "Full Name",
			key: "fullName",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The contact's full name",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
		},
		{
			title: "Primary Location",
			key: "groupTitle",
			order: 3,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The area that the user belongs to",
			searchable: true,
			visible: true,
			dataType: "input",
			sortable: true,
			sortKey: "Area",
		},
		{
			title: "Email",
			key: "email",
			order: 4,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The contact's email address",
			searchable: true,
			visible: true,
			dataType: "email",
			sortable: true,
		},
		{
			title: "Telephone",
			key: "telephone",
			order: 5,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The contact's phone number",
			searchable: true,
			visible: true,
			dataType: "telephone",
			sortable: true,
		},
		{
			title: "Cell",
			key: "mobile",
			order: 6,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The contact's cell phone number",
			searchable: true,
			visible: true,
			dataType: "telephone",
			sortable: true,
		},
		{
			title: "Roles",
			key: "contactRoles",
			order: 7,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The contact's role",
			searchable: true,
			visible: true,
			dataType: "list",
			useCustomCell: true
		},
		{
			title: "Contact for",
			key: "contactRoleGroups",
			order: 8,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The areas the contact belongs to",
			searchable: true,
			visible: true,
			dataType: "list",
			useCustomCell: true
		}];

		return columns;
	}

	private get getModalItems() : ModalItemCSV[] {
		var modalItems = [
			{
				title: "Full Name",
				key: "fullName",
				dataType: "text",
				readOnly: this.isRowSuiteUser,
				placeholder: "John Doe",
				order: 1,
				maxLength: 50,
				required: true,
				csvComponent:'input',
				csvValidation: {
					validationCell: ({ currentElement }) => {
						return {
							isValid: !!currentElement.csvValue
						}
					}
				}
			},
			{
				title: this.isUser ? "Primary Location - Please configure on Users page" : "Primary Location",
				key: "groupID",
				dataType: "component",
				readOnly: this.isRowSuiteUser || this.isUser,
				required: !this.isUser,
				data: AreaTreeSelect,
				props: {
					reduce: (a) => a.id,
					clearable: false
				},
				order: 2,
				csvData: Object.keys(this.areasByTitle),
				csvComponent: 'select',
				csvValidation: {
					validationCell: ({ currentElement }) => {
						return {
							isValid: this.areasByTitle[currentElement.csvValue] !== undefined
						}
					},
				}
			},
			{
				title: this.isUser ? "Email - Please configure on users page" : "Email",
				key: "email",
				dataType: "text",
				readOnly: this.isRowSuiteUser || this.isUser,
				placeholder: "John@Doe.com",
				csvComponent:'input',
				maxLength: 150,
				order: 3,
				validations: {
					isEmailValid: helpers.withParams(
						{ type: "isEmailValid", errorMessage: "Email address is not valid" },
						value => {
							var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
							return !helpers.req(value) || re.test(String(value).toLowerCase());
						}
					)
				},
				csvValidation: {
					validationCell: ({ currentElement }) => {
						const value = currentElement.csvValue
						const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						const isValid = !helpers.req(value) || re.test(String(value).toLowerCase());
						return {
							isValid,
							message: `Email address is not valid`
						}
					},
				}
			},
			{
				title: "Phone",
				key: "telephone",
				dataType: "tel",
				readOnly: this.isRowSuiteUser,
				csvComponent:'input',
				maxLength: 50,
				order: 4,
			},
			{
				title: "Cell",
				key: "mobile",
				dataType: "tel",
				readOnly: this.isRowSuiteUser,
				csvComponent:'input',
				maxLength: 50,
				order: 5,
			},
			{
				title: "Roles",
				key: "contactRoles",
				dataType: "vselect3",
				props: {
					multiple: true,
					closeOnSelect: false
				},
				data: this.roleList,
				required: !this.isContactInheritanceEnabled,
				readOnly: this.isSuiteEnabled,
				placeholder: "Select a Role",
				order: 6,
				csvData: Object.keys(this.formattingRoleList),
				csvComponent: 'select-multiple',
				csvValidation: {
					validationCell: ({ currentElement }) => {
						const value = currentElement.csvValue && isArray(currentElement.csvValue) && (currentElement.csvValue || currentElement.csvValue.split(','));
						const localData = (value && value.filter(v => v)) || [];

						if(localData.length === 0) return { isValid: true }
						return {
							isValid: !!localData.length && localData.every(name => this.formattingRoleList[name] !== undefined)
						}
					},
				},
				visible: !this.isContactInheritanceEnabled
			},
			{
				title: "Contact for",
				key: "contactRoleGroups",
				required: !this.isContactInheritanceEnabled,
				dataType: "component",
				readOnly: false,
				data: AreaTreeSelect,
				props: {
					multiple: true,
					appendToBody: true,
					reduce: (a) => a.id,
					clearable: false
				},
				order: 7,
				csvData: Object.keys(this.areasByTitle),
				csvComponent: 'select-multiple',
				csvValidation: {
					validationCell: ({ currentElement }) => {
						const value = currentElement.csvValue && isArray(currentElement.csvValue) && (currentElement.csvValue || currentElement.csvValue.split(','));
						const localData = (value && value.filter(v => v)) || [];

						if(localData.length === 0) return { isValid: true };
						return {
							isValid: !!localData.length && localData.every(name => this.areasByTitle[name] !== undefined)
						};
					},
				},
				visible: !this.isContactInheritanceEnabled
			},
			{
				title: "Contact For",
				dataType: "component",
				key: "contactsFor",
				readOnly: this.isRowSuiteUser,
				data: ContactsFor,
				required: !!this.isContactInheritanceEnabled,
				componentHasValidation: !!this.isContactInheritanceEnabled,
				defaultValue: [],
				props: {
					allRoles: this.roleList,
					areaTitles: this.areaDictionary,
				},
				order: 8,
				csvExclude: true,
				visible: !!this.isContactInheritanceEnabled
			},
			{
				title: "Has Pin Code",
				key: "hasPinCode",
				dataType: "checkbox",
				visible: this.isRowSuiteUser,
				readOnly: true,
				order: 9,
			}] as ModalItemCSV[];

			if (this.isCustomFieldsEnabled) {
				try {
					var genericModalItem: ModalItemCSV = {
						title: "",
						key: "",
						dataType: null,
						readOnly: true,
						order: modalItems.length + 1
					};

					const customFieldIds: number[] = this.getModalRow?.customFieldValues ? this.getModalRow.customFieldValues.map(cf => cf.id) : [];
					const populatedCustomFields = this.contactCustomFields.filter(cf => customFieldIds.includes(cf.id));

					for (let i = 0; i < populatedCustomFields.length; i++) {
						let customFieldModalItem = MapCustomFieldToModalItem(populatedCustomFields[i], cloneDeep(genericModalItem));
						modalItems.push(customFieldModalItem as ModalItemCSV);
					}
				}
				catch (err) {
					console.error("Unexpected error displaying custom fields: ", err);
				}
			}

		return modalItems;
	}

	private pageNumber: number = 1;
	private pageSize: number = 25;
	private totalRecords: number = 0;
	private isLoading: boolean = false;
	private searchQuery: string = "";
	private debounceSearch;

	private get isRowSuiteUser (): boolean {
		return (this.getModalRow && this.getModalRow.isSuiteUser) || this.isSuiteEnabled;
	}

	private get formattingRoleList () {
		return this.roleList.reduce(<T extends ContactRole & { title: string }>(acc, cur: T) => ({
			...acc,
			[cur.title]: cur
		}),{})
	}

	private deleteActionMessages(contactForDelete): string[] {
		if (contactForDelete.isContact) {
			return [
				`Remove this Contact from the associated User '${contactForDelete.fullName}'`,
				"Delete this Contact",
				"User will remain unchanged"
			];
		}
		else {
			return [];
		}
	}

	private async openUpdateModal(row): Promise<void> {
		this.isUser = !!row && !!row.username;

		if (!!row && this.isCustomFieldsEnabled && !!row.customFieldValues) {
			mapArrayFieldsToObject(row, row.customFieldValues, this.contactCustomFields, "id", "value", "cf");
		}
	}

	// show the contact role component in a modal
	private async openContactRoleModal(row): Promise<void> {
		this.showContactRoleModal = true;
	}

	// close the modal and refresh the data in the table
	private async onContactRolesClose() : Promise<void> {
		this.roleList = await this.formatRoleList();
	}

	private getUpdatedRoleRequest(contactToUpdate: ContactWithRole, isBulkUpload: boolean = false) : { user: { userID: number, username: string, fullName: string, groupID: number }, contactForItems: ContactFor[] }
	{
		const { userID, username, fullName, contactsFor, contactRoleGroups, groupID } = contactToUpdate;

		// techdebt (missing type for contactForRequest) - when the data comes in, it is remapped - from a Group to an array of GroupIds
		let contactForRequest;

		if(!this.isContactInheritanceEnabled || isBulkUpload)
		{
			contactForRequest = contactRoleGroups.map(crg => {
				return {
					groupId: crg,
					isInherited: false,
					roleIds: contactToUpdate.contactRoles.map(cr => cr.key)
				}
			});
		}
		else
		{
			contactForRequest = contactsFor.map(cf => {
				return {
					groupId: cf.groupId,
					isInherited: cf.isInherited,
					roleIds: cf.roleIds
				}
			});
		}

		return {
			user: {
				userID,
				username,
				fullName,
				groupID
			},
			contactForItems: contactForRequest
		};
	}

	public async updateUserDetails(contactToUpdate) : Promise<void> {
		if (contactToUpdate.address == null) {
			contactToUpdate.address = "";
		}
		if (contactToUpdate.mobile == null) {
			contactToUpdate.mobile = "";
		}

		await api.createOrUpdateContactUser(contactToUpdate);

		let roleRequest = this.getUpdatedRoleRequest(contactToUpdate);

		await api.updateRole(roleRequest);
		await this.updateData(this.mostRecentSearchParams);
	}

	public async newUserDetails(contactToAdd) : Promise<void> {
		await this.saveNewUser(contactToAdd)
		await this.updateData(this.mostRecentSearchParams);
	}

	public async saveNewUser(contactToAdd: any, isBulkUpload: boolean = false) : Promise<void> {
		let newUser = await api.createOrUpdateContactUser(contactToAdd);

		contactToAdd.userID = newUser.data;

		let roleRequest = this.getUpdatedRoleRequest(contactToAdd, isBulkUpload);

		await api.updateRole(roleRequest);
	}

	public async deleteUserDetails(contactToDelete): Promise<void> {
        if (contactToDelete.isContact) {
			contactToDelete.isContact = false;
			await api.updateUser(contactToDelete);
		} else {
			await api.deleteUser(contactToDelete.userID);
		}

		let userIndex = this.contacts.findIndex(element => element.userID == contactToDelete.userID);
		this.contacts.splice(userIndex, 1);
	}

	public async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		try {
			this.isLoading = true;

			// ensure we have an up-to-date dependant data.
			await this.fetchAreaDictionary();
			this.roleList = await this.formatRoleList();

			const cancellableQuery = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);
			let contactsWithRolesResponse: ContactsPagedResponse = await api.getContacts(cancellableQuery);

			this.totalRecords = contactsWithRolesResponse.totalRecords;

			this.contacts = this.formatContacts(contactsWithRolesResponse.data);
		}
		catch (ex) {
			console.log("Unexpected error fetching contacts: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	private formatContacts(contactsWithRoles) {
		return contactsWithRoles.map(contact => {

			if (contact.contactRoleGroups) {
				contact.contactRoleGroups = contact.contactRoleGroups.map(rg => {
					return rg.groupID;
				});
			}
			if (contact.contactRoles) {
				contact.contactRoles = contact.contactRoles.map(cr => {
					return {
						key: cr.contactRoleId,
						title: cr.role
					}
				});
			}

			var isReadOnly = !!contact.groupSyncId && !this.getPermissions.canOverrideGroupSync && !this.getPermissions.isSystemAdmin;
			contact.readOnly = isReadOnly,
			contact.readOnlyMessage = isReadOnly ? "You do not have permission to edit Sync contacts": null

			contact.groupTitle = this.getAreaTitle(contact.groupID);

			contact.hideDelete = this.isSuiteEnabled;

			return contact;
		});
	}

	public async formatRoleList() {
		let newRoleList = await api.loadContactRoleList();
		let friendlyRoleList = [];
		if (newRoleList) {
			for (let i = 0; i < newRoleList.length; i++) {
				friendlyRoleList.push({
					title: newRoleList[i].role,
					key: newRoleList[i].contactRoleId
				});
			}
		}
		return friendlyRoleList;
	}

	// If feature is on / off
	public get isContactsEnabled(): boolean {
		if (get(this.featuresList, ["Contacts"]) == null) {
			return false;
		} else {
			return get(this.featuresList, ["Contacts"]);
		}
	}

	// If ManageContactRoles feature is on / off
	public get isManageContactRolesEnabled(): boolean {
		return (
			get(this.featuresList, ["Contacts", "ManageContactRoles"]) &&
			(this.getPermissions.isSystemAdmin || this.getPermissions.canManageContactRoles)
		);
	}

	async mounted() {
		if (get(this.featuresList, ["Contacts"]) == null) {
			this.emailAsUser = false;
		} else {
			this.emailAsUser = get(this.featuresList, ["Contacts"]);
		}

		if (this.isCustomFieldsEnabled){
			await this.retrieveCustomFields({ tableType: this.customFieldTableType, live: true });
		}

		await this.updateData();
	}

	private convertDataToRest({ body }) {
		return body
				.map(value => {
					const contactRoleGroupsValue = isArray(value.contactRoleGroups) ? value.contactRoleGroups : value.contactRoleGroups.split(',');
					const rolesValue = isArray(value.contactRoles) ? value.contactRoles : value.contactRoles.split(',');
					return {
						...value,
						contactRoles:  (rolesValue && rolesValue.map(name => this.formattingRoleList[name])) || [],
						groupID: this.areasByTitle[value.groupID],
						contactRoleGroups: (contactRoleGroupsValue && contactRoleGroupsValue.map(name => this.areasByTitle[name])) || [],
					}
				}).map(v =>
					 Object.entries(v).reduce((acc, [key, value]) => {
						if(value === '') return acc

						return {
							...acc,
							[key]: value
						}
					} ,{})
			)
	}

	private get areasByTitle() {
		return invert(this.areaDictionary);
	}

	private get csvHeaders()
	{
		const items = this.getModalItems;
		return items.filter(mi => mi.csvExclude != true).map(mi => mi.title)
	}

	private get defaultFile() {
		const headers = this.csvHeaders;
		const body = headers.map( v => '')
		return {
			name: 'ContactTemplate.csv',
			data: `${headers.join()}\r\n${body.join()}`
		}
	}

	async onSave({ body }) {
		const max = body.length
		let counterSucceed = 0;
		let progress: any = {
			max,
			value: 0,
			status: 'init',
			successfulMessage: `Successfully added ${max} Contacts`,
		}

		this.progressBulk = {
			...progress
		}

		const convertedData = this.convertDataToRest({ body })
		const contacts = convertedData.map(async (contact) => {
				const localContact = cloneDeep(contact)
				const id = localContact.rowId

				return this.saveNewUser(localContact, true)
					.then(() => {
						progress.rowIndex = id
						counterSucceed += 1
						progress.value = progress.value + 1

						this.progressBulk = {
							...progress,
							status: counterSucceed === max ? 'succeed' : 'progress',
						}

					})
					.catch((exception) => {
						console.error(exception)
						const failedContact = convertedData.find(({ rowId }) => rowId === id)
						this.progressBulk = {
							...progress,
							rowIndex: id,
							status: 'error',
							error: {
								name: failedContact.fullName,
								message: `Bulk upload has failed to add contact ${failedContact.fullName}`
							}
						}
					})
			}
		)

		await Promise.all(contacts)
	}

	async bulkOnSubmit({ formData }) {
		await this.onSave({ body: formData })
		await this.updateData(this.mostRecentSearchParams);
	}

	onCloseBulk() {
		this.resetProgress()
	}

	resetProgress() {
		this.progressBulk = {}
	}

	private get isBulkAddEnabled(): boolean {
		return get(this.featuresList, ["Contacts", "BulkAdd"]) && !this.isSuiteEnabled;
	}

	private get isCustomFieldsEnabled(): boolean {
		return get(this.featuresList, ["CustomFields"]);
	}

	private get isSuiteEnabled(): boolean {
		return this.getFeature(["Suite"]);
	}

	private ellipseAreaTitle(title: string): string {
		return ellipseAfterX(title);
	}

	private get isContactInheritanceEnabled(): boolean
	{
		return this.getFeature(["Contacts", "Inheritance"]);
	}
}
