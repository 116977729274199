import { RulesEngineState, RuleGenericDropdownItem, RuleServer, RuleServerTypeEvent } from "./types";

export function getDefaultState(): RulesEngineState {
	return {
		rules: {
			pageNumber: 0,
			pageSize: 0,
			totalRecords: 0,
			data: [],
		},
		editingRule: null,
		alarmsForGroupId: new Map<number, RuleGenericDropdownItem[]>(),
		serverTypes: [],
		actionsPlans: [],
		serverTypeEventsForServerType: [],
		serversForServerType: [],
	};
}
