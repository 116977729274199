import { GetterTree } from "vuex";
import { AreaState, GroupWithPermissions } from "./types";
import { RootState } from "@/store/types";

export const areaGetters: GetterTree<AreaState, RootState> = {
	getAreaList: state => state.AreaList as GroupWithPermissions[],
	getAreaTitle: state => (id: number) => {
		return state.areaDictionary[id];
	}
};
