import { axiosInstance, axiosJsonInstance } from "@/axios.instance";
import { PaginatedSearchRequestCustomField } from "@/mixins/PaginatedSearch";
import PagedResponse from "@/types/sv-data/PagedResponse";
import { CustomFieldDto, CustomFieldTableTypes, CustomFieldValues } from "@/store/custom-fields/types";
import { AxiosInstance, } from "axios";

const CustomFieldRoute = "/CustomField";

class CustomFieldsApi {
	private axios: AxiosInstance;
	private axiosJson: any;

	constructor() {
		this.axios = axiosInstance;
		this.axiosJson = axiosJsonInstance;
	}

	public async createCustomField(customField: CustomFieldDto): Promise<CustomFieldDto> {
		const { data } = await this.axiosJson.post(`${CustomFieldRoute}/Create`, customField);
		return data;
	}

	public async retrieveCustomFields(paginatedSearchRequest: PaginatedSearchRequestCustomField) : Promise<PagedResponse> {
		const { data } = await this.axiosJson.get(`${CustomFieldRoute}`, paginatedSearchRequest);
		return data;
    }

	public async retrieveCustomFieldsForObject(objectId: number, tableTypeId: CustomFieldTableTypes) :Promise<CustomFieldValues[]> {
		const { data } = await this.axios.get(`${CustomFieldRoute}/Retrieve/CustomFieldsForObject/${objectId}`, {
			params: {
				tableTypeId: tableTypeId
			}
		});
		return data;
	}

	public async updateCustomField(customField: CustomFieldDto): Promise<CustomFieldDto> {
		const { data } = await this.axiosJson.put(`${CustomFieldRoute}/Update`, customField);
		return data;
	}

	public async deleteCustomField(customFieldId: number): Promise<CustomFieldDto> {
		const { data } = await this.axios.delete(`${CustomFieldRoute}/Delete/${customFieldId}`);
		return data;
	}
}

const customFieldsApi = new CustomFieldsApi();
export default customFieldsApi;
