import { MutationTree } from "vuex";
import { FieldOps } from "./types";
import { AssetMapLayerItem, MapLayerItemWithExpiry } from "@/components/mobile-assets/types";
import { SubscriberExpiry } from "@/types/EnhancedSingleMapTypes";
import { getDefaultState } from "./state";
import { SituationalAwarenessEventQueue } from "../situational-awareness/types";

export const mutations: MutationTree<FieldOps> = {
    setOnlineAssets(state, onlineAssets: AssetMapLayerItem[] | null){
        state.onlineAssets = onlineAssets;
    },
    setAssetTypes(state, assetTypes: Map<string, any>){
        state.assetTypes = assetTypes;
    },
    addOrUpdateManuallyChangedAsset(state, asset: MapLayerItemWithExpiry){
		var assetIndex = state.manuallyChangedAssets.findIndex(i=> i.mapLayerItem.assetId === asset.mapLayerItem.assetId);

        if(assetIndex >= 0)
			state.manuallyChangedAssets = state.manuallyChangedAssets
            .filter(x => state.manuallyChangedAssets[assetIndex].mapLayerItem.assetId !== x.mapLayerItem.assetId);

        if(asset.checksRemaining < 1)
            return;

        state.manuallyChangedAssets.push(asset);
    },
    removeManuallyChangedAsset(state, asset: MapLayerItemWithExpiry){
        var assetIndex = state.manuallyChangedAssets.findIndex(i=>i.mapLayerItem.assetId === asset.mapLayerItem.assetId);

        if(assetIndex >= 0)
			state.manuallyChangedAssets.remove(state.manuallyChangedAssets[assetIndex]);

    },
    updateAsset(state, item: AssetMapLayerItem){
        // Get our desired asset
        var index = state.onlineAssets.findIndex(i => i.assetId === item.assetId);
        if(!index)
            return;

        state.onlineAssets[index] = item;
    },
    addOrUpdateActiveSubscriber(state, subscriberExpiry: SubscriberExpiry){
        if(!state.activeSubscribers.length){
            state.activeSubscribers = [];
        }

        var index = state.activeSubscribers.findIndex(i => i.subscriber === subscriberExpiry.subscriber);
        if(index >= 0){
            state.activeSubscribers[index].expiry = subscriberExpiry.expiry;
            return;
        }
        state.activeSubscribers.push(subscriberExpiry);

    },
    setTimer(state, timer: any | null){
        // if the new timer is null, end the current timer or we have an active timer
        if(!timer || state.timer){
			clearTimeout(state.timer);
        }
        state.timer = timer;
    },
    setLastPollCall(state, pollCallTime) {
		state.lastPollCall = pollCallTime;
	},
    setViewingAssetId(state, assetId: number | null){
        state.viewingAssetId = assetId
    },
    cleanUpSubscribers(state){
        state.activeSubscribers = state.activeSubscribers.filter(x => x.expiry > Date.now())
    },
	setSituationalAwarenessEventQueue(state: FieldOps, situationalAwarenessEventQueue: SituationalAwarenessEventQueue[]){
		state.situationalAwarenessEventQueue = situationalAwarenessEventQueue;
	},
	setSelectedSituationalAwarenessEvent(state: FieldOps, situationalAwarenessEvent: SituationalAwarenessEventQueue){
		state.selectedSituationalAwarenessEvent = situationalAwarenessEvent;
	},
	resetState(state) {
        // re-assign the state means we lose focus on the state timer (if active)
        // ensure we stop the timer before we lose focus
        if(state.timer)
            clearTimeout(state.timer);
        state.timer = null;
        Object.assign(state, getDefaultState());
	}
}
