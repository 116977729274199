
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Multiselect from "vue-multiselect";
import { DeviceDetails, DeviceService } from "@sureview/camera";

import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import api from "@/services/api.service";

const Eventqueue = namespace("eventqueue");
const Devices = namespace("devices");

@Component({
	mixins: [validationMixin],
	validations: {
		manualActionRequest: {
			device: {
				deviceId: {
					required
				}
			},
			reason: {
				required
			}
		}
	},
	components: {
		multiselect: Multiselect
	}
})
export default class ManualAction extends Vue {
	$refs!: {
		actionModal: any;
		deviceFinder: Multiselect;
	};

	@Eventqueue.Getter("getManualActionShown") manualActionShown!: boolean;
	@Eventqueue.Mutation setManualActionShown: any;

	@Devices.State("cancelTokenSource") source: any;
	@Devices.State doors: DeviceDetails[];
	@Devices.Action deviceSearch: (query: string) => Promise<void>;

	/**
	 * Holds the request data for the quick control action
	 */
	public manualActionRequest = {
		device: null,
		reason: ""
	};

	// Indicates that we're loading the search results
	public doorSearchLoading = false;

	// Indicates that we're currently pulsing a relay
	public pulsing: boolean = false;

	/**
	 * Constructor.
	 */
	public created() {
		// Set the modal to hidden on creation
		this.setManualActionShown(false);
	}

	/**
	 * Async handler for the vue-multiselect. Handles the real time search.
	 */
	public async asyncFind(query: string) {
		if (this.source) {
			this.source.cancel("Operation canceled by the user.");
		}

		this.doorSearchLoading = true;
		await this.deviceSearch(query);
		this.doorSearchLoading = false;
	}

	/**
	 * Reset the modal/form back to it's original state.
	 */
	public reset() {
		this.manualActionRequest = {
			device: null,
			reason: ""
		};

		this.pulsing = false;
	}

	/**
	 * Creates a note in the system indicating that the user has pulsed the selected device,
	 * and attaches the reason. Then we issue a command to the device service indicating we wish
	 * to pulse a relay.
	 */
	public async pulse() {
		// Trigger validation
		this.$v.$touch();
		if (this.$v.$error) {
			return;
		}

		this.pulsing = true;

		try {
			// Get the details reqd to trigger the relay, and attach the note
			const device = await api.deviceQuickControl(
				this.manualActionRequest.device.deviceId,
				this.manualActionRequest.reason
			);

			// If we got a device back, we can trigger it with the device service
			if (device && device.eventRecordID) {
				const deviceSvce = new DeviceService(api.getDeviceServiceAddress(), device.deviceServerEndpoint);

				try {
					// Pulse the relay
					await deviceSvce.sendOutputCmd(
						device.deviceServiceSession,
						device.deviceID,
						null, // outputType does not exist in device model so pass null at the moment
						"pulse"
					);

					this.reset();
					this.setManualActionShown(false);
				} catch (err) {
					this.pulsing = false;

					// Notify the user that an error has occurred
					this.$notify({
						type: "error",
						title: "Quick Control",
						text: err
							? err.statusText
							: "Unable to trigger the relay - please contact support if this problem persists."
					});
				}
			} else {
				// We haven't got any specific errors, so just throw null here
				// so we can hit the catch block and notify the user that something
				// has gone wrong
				throw null;
			}
		} catch (err) {
			this.pulsing = false;

			this.$notify({
				type: "error",
				title: "Quick Control",
				text: err
					? err.statusText
					: "Unable to trigger the relay - please contact support if this problem persists."
			});
		}
	}

	public focusDeviceFinder() {
		(this.$refs.deviceFinder.$el as HTMLElement).focus();
	}

	/**
	 * Resets the modal back to it's original state and hides it.
	 */
	public cancel() {
		this.reset();
		this.setManualActionShown(false);
	}
}
