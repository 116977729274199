
    import { Component, Vue, Prop, Watch, Emit, Mixins } from "vue-property-decorator";
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import { validationMixin } from 'vuelidate';
	import { required, requiredIf, minValue, maxValue, numeric, minLength } from 'vuelidate/lib/validators';
    import { namespace, Getter } from 'vuex-class';
    import moment from "moment";
    import DatePicker from "@sureview/vuejs-datepicker";

    import api from '../../services/api.service';
	import { EventDetails } from "@/store/site-monitor/types";
    import AccessControlHistory from '../access-control/History.vue';
    import AlarmPointHistory from './AlarmPointHistory.vue';
    import MaskingForm from '../alarm-masking/MaskingForm.vue';

    import { truncateString } from "@/filters";

    import { UserPermissions } from "@/store/types";
    import maskingApi from '@/services/api.masking.service';

    const AlarmPoints = namespace('alarmPoints');
    const SiteMonitor = namespace("siteMonitor");

	class OnTestRequest {
		groupID: number;
		hours: number | null;
		minutes: number | null;
		date: string;
		onTestUntilDate: Date;
		time: string;
		scheduleType: number;
		auditMode: boolean;
		onTestNote: string;
	}

    @Component({
		mixins: [validationMixin],
        components: {
            VuePerfectScrollbar,
            'access-history': AccessControlHistory,
            "maskingForm": MaskingForm,
            'alarm-point-history': AlarmPointHistory,
            "DatePicker": DatePicker
        },
        validations: {
            onTestRequest: {
                hours: {
                    numeric,
                    required: requiredIf(function () {
                        return this.onTestRequest.scheduleType == this.ScheduleType.ForPeriod;
                    }),
                    minValue: minValue(0)
                },
                minutes: {
                    numeric,
                    required: requiredIf(function () {
                        return this.onTestRequest.scheduleType == this.ScheduleType.ForPeriod;
                    }),
                    minValue: minValue(0)
                },
				onTestUntilDate: {
                    required: requiredIf(function () {
                        return this.onTestRequest.scheduleType == this.ScheduleType.UntilDate;
                    }),
                    minValue: minValue(new Date().setHours(0, 0, 0, 0))
                },
                time: {
                    pattern: function (value) {
                        if (typeof (value) == "undefined" || value == null || value == "") return true;
                        return /^[0-9]{2}:[0-9]{2}$/.test(value);
                    },
                    minTime: function (value) {
                        /**
                         * Validator to ensure that the time entered by the user is in the future
                         */
                        if (typeof (value) == "undefined"
                            || value == null
                            || value == ""
							|| typeof (this.onTestRequest.onTestUntilDate) == "undefined"
							|| this.onTestRequest.onTestUntilDate == null
                            || this.onTestRequest.scheduleType !== this.ScheduleType.UntilDate) return true;

                        const now = new Date().setHours(0, 0, 0, 0);
						const currentRequestDate = new Date(this.onTestRequest.onTestUntilDate).setHours(0, 0, 0, 0);

                        if (now == currentRequestDate) {
                            // If the user has chosen today as the re-arm date, we need to make sure that the time they've
                            // entered is greater than the current time.
                            var momentTime = moment(value, "HH:mm", true).toDate();
                            return momentTime > (new Date());
                        } else {
                            // If the user hasn't chosen today, just make sure that the date they've entered is in the future.
                            return currentRequestDate > now;
                        }
                    },
                    required: requiredIf(function () {
                        return this.onTestRequest.scheduleType == this.ScheduleType.UntilDate;
                    })
				},
				onTestNote: {
					required,
					minLength: minLength(1)
				}
            }
        },
        filters: {
            displayDate: function (value: Date) {
                return moment(value).format("LL LT");
            },
            truncateString
        }
    })
    export default class AreaAlarms extends Vue {

        @AlarmPoints.State("selectedArea") selectedArea;

        @AlarmPoints.State("displayResponseId") getDisplayResponseId;
        @AlarmPoints.Mutation setDisplayResponseId: any;
        @AlarmPoints.Mutation setSelectedResponse: any;
        @AlarmPoints.Mutation armSelectedArea: any;

        @SiteMonitor.Getter getEventDetails: EventDetails;

        @Getter getPermissions: UserPermissions;

        showOnTest: boolean = false;

        public areaAlarmPoints: any[] = null;
        public areaPermissions: any = null;
        public areaOnTestEvent: any = null;

        public testAlarmsLoaded: boolean = false;
        public testAlarms: any[] = [];
        public maxEventRecordID: number = 0;

        filter: string = "";
        get filteredAlarmPoints(): any[] {
            if (this.filter == "")
                return this.areaAlarmPoints;

            var filterLower = this.filter.toLowerCase();

            return this.areaAlarmPoints.filter((alarmPoint) => alarmPoint.title.toLowerCase().indexOf(filterLower) > -1);
        }

        public ScheduleType = {
            ForPeriod: 1,
            UntilDate: 2
        }

        // == Consts == //
        public Today = new Date();

        public calendarDisabledDates = { to: new Date(this.Today.setDate(this.Today.getDate() - 1)) };
        /**
         * May not be entirely required - this is just used to set the min property for the HTML5 time input,
         * but we don't actually use HTML5 validation.
         */
        public get minTimeForSelectedDate() {
            const today = (new Date()).setHours(0, 0, 0, 0);
            const defaultMinTime = "00:00";

			if (typeof (this.onTestRequest.onTestUntilDate) == "undefined" || this.onTestRequest.date == null)
                return defaultMinTime;
			var selectedDate = this.onTestRequest.onTestUntilDate.setHours(0, 0, 0, 0);

            if (selectedDate == today) {
                return moment().format("HH:mm");
            } else {
                return defaultMinTime;
            }
        }

        calendarDateFormatter(date: string) {
            return moment(date).format("L");
        }

        onTestAudit: boolean = false;
		public onTestRequest = {
			groupID: null,
			hours: null,
			minutes: null,
			onTestUntilDate: new Date(),
			date: null,
			time: moment().add({ minutes: 5 }).format("HH:mm"),
			scheduleType: this.ScheduleType.ForPeriod,
			auditMode: false,
			onTestNote: ''
		} as OnTestRequest;

        mounted() {
            if (this.selectedArea) {
                this.loadResponses(this.selectedArea);
            }
        }

        @Watch("selectedArea")
        async onSelectedAreaChanged(value: any) {
            if (value) {
                await this.loadResponses(value);
            }
        }

        async loadResponses(area: any) {
            this.showOnTest = false;

            this.areaOnTestEvent = null;
            this.areaPermissions = null;
            this.areaAlarmPoints = null;

            this.maxEventRecordID = 0;
            this.testAlarms = [];
            this.testAlarmsLoaded = false;

            this.areaOnTestEvent = await api.loadGroupOnTestEvent(area.id);
            this.areaPermissions = await api.loadGroupPermissions(area.id);
            this.areaAlarmPoints = await api.responsesByArea(area.id);
        }

        selectResponse(alarmPoint: any) {
            this.setSelectedResponse(alarmPoint);
        }

        async startTest() {
            this.$v.$reset();

            this.$nextTick(async () => {
                // force re-validation
                this.$v.$touch();

                // if there are validation errors, stop the submission
                if (this.$v.$error) {
                    console.log("Validation error: Hours: " + this.$v.onTestRequest.hours.$error +
                        ", Minutes: " + this.$v.onTestRequest.minutes.$error +
						", Date: " + this.$v.onTestRequest.onTestUntilDate.$error);
                    return;
				}

                this.onTestRequest.groupID = this.selectedArea.groupID;

                var testRequest = this.onTestRequest;
				if (this.onTestRequest.scheduleType == this.ScheduleType.UntilDate) {
					// Get the hours and minutes out of the time value
					var momentTime = moment(this.onTestRequest.time, "HH:mm", true);
					var hours = momentTime.hours();
					var minutes = momentTime.minutes();

					// Calculate the datetime when this alarm is to be un-masked, and convert it to UTC
					var formattedDate = moment(this.onTestRequest.onTestUntilDate)
						.set({ hours, minutes, seconds: 0 })
						.utc().format();

					// Update the date value within our mask request, set hours and mins to 0
					// (nulls don't work - unsure why, probably because we're posting the form and not a JSON object)
					testRequest = { ...this.onTestRequest, date: formattedDate, hours: 0, minutes: 0 };
				} else {
					testRequest = { ...this.onTestRequest };

					delete testRequest.date;
					delete testRequest.time;
				}

                this.areaOnTestEvent = await api.startTest(testRequest);
            });

        }

        async endTest() {
            if (this.areaOnTestEvent) {
                await api.endTest(this.areaOnTestEvent.eventID);
                this.areaOnTestEvent = null;

                this.testAlarms = [];
                this.testAlarmsLoaded = false;
            }
        }

        async loadOnTestRecords() {
            if (this.areaOnTestEvent) {
                var onTestRecords = await api.loadGroupOnTestRecords(this.areaOnTestEvent.eventID, 0);

                this.maxEventRecordID = onTestRecords.maxEventRecordID;
                this.testAlarms = onTestRecords.records;
                this.testAlarmsLoaded = true;
            }
        }

        async unMaskArea() {
            await maskingApi.unmaskArea(this.selectedArea.groupID);
            this.armSelectedArea();
        }
    }

