
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import Multiselect from "vue-multiselect";
import { get } from 'lodash';
import { FeaturesList } from "@/store/types";
import { EventDetails } from "@/store/site-monitor/types";

const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		multiselect: Multiselect
	}
})
export default class EventLeave extends Vue {
	$refs!: {
		leaveModal: any;
		parkNotes: HTMLInputElement;
	};

	@Getter getDefaultParkValue: number;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@SiteMonitor.Getter("getEventLeaveShown") eventLeaveShown!: boolean;
	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;
	@SiteMonitor.Getter
	getEventShare!: any[];
	@SiteMonitor.Getter getUserID!: number;
	@SiteMonitor.Getter getIsUploadingFile: boolean;
	@SiteMonitor.Action parkEvent: any;
	@SiteMonitor.Action eventPassControl: any;
	@SiteMonitor.Action fetchEventDetails: any;
	@SiteMonitor.Action removeAdhocUsersFromEvent: any;
	@SiteMonitor.Mutation setEventLeaveShown: any;

	@Prop(Number) public eventid?: number;

	public otherOperator: any = null;
	public parkHours: number = 0;
	public parkMinutes: number = 10;
	public parkSeconds: number = 0;
	public parkNote: string = "";
	public parkNoteRequired: boolean = true;
	public defaultPark: number = 10;

	private isParkEventChecked: boolean = false;

	public get sharedOperators() {
		return this.getEventShare.filter(
			(user: any) => user.userID != null && user.userID != this.getUserID && !user.removed && user.isActive
		);
	}

	public get otherUserCount() {
		return this.sharedOperators == null ? 0 : this.sharedOperators.length;
	}

	public get canLeave() {
		if (this.otherOperator != null) return true;

		if (this.otherUserCount > 0) {
			return true;
		} else {
			if (this.parkNoteRequired) {
				return this.parkNote.length > 0;
			}

			return true;
		}
	}

	@Watch("eventLeaveShown")
	private onEventRaiseShownChanged(value: boolean, oldValue: boolean) {
		if (value) {
			this.$refs.leaveModal.show();
		} else {
			this.$refs.leaveModal.hide();
		}
	}

	// Triggered with @shown on b-modal to focus on and highlight parked notes
	private highlightNote(): void {
		if (this.isAutoFillEnabled && this.$refs.parkNotes) {
			this.$refs.parkNotes.focus();
			this.$refs.parkNotes.select();
		}
	}

	public async created() {
		this.setEventLeaveShown(false);
		await this.fetchEventDetails(this.eventid);
		this.parkNoteRequired = this.eventDetails.parkNoteRequired;

		if (this.isAutoFillEnabled) {
			this.parkNote = this.eventDetails.parkedNote;
		}
	}

	public async mounted(){
		await this.getDefaultParkToHoursAndMinutes();
	}

	public async ok() {
		if (this.canLeave) {
			if (this.otherOperator != null) {
				await this.eventPassControl({
					eventID: this.eventid,
					userID: this.otherOperator.userID
				});
			} else {
				this.removeAdhocUsersFromEvent();

				if (this.parkNote.length > 500 || (this.parkNote.length == 0 && this.parkNoteRequired)) {
					return;
				}

				if (!this.isParkEventChecked && this.isParkingAnEventOptional) {
					this.parkHours = 0;
					this.parkMinutes = 0;
					this.parkSeconds = 0;
				}

				if(!this.eventid){
					this.$notify({
					type: "error",
					title: "Park Event",
					text:
						"Unable to Park Event - there is no valid event Id"
					});
					return;
				}

				await this.parkEvent({
						eventId: this.eventid,
						hours: this.parkHours,
						minutes: this.parkMinutes,
						seconds: this.parkSeconds,
						parkNote: this.parkNote,
						parkedByMap: false
					});
			}

			this.$router.push({ path: "/event-queue" });
		}
	}

	public cancel() {
		this.isParkEventChecked = false;
		this.setEventLeaveShown(false);
	}

	private validateHours(): void {
		if (this.parkHours < 0) {
			this.parkHours = 0;
		} else if (this.parkHours >= 24) {
			this.parkHours = 24;
			this.parkMinutes = 0;
		}
	}

	private validateMinutes(): void {

		if (this.parkHours >= 24 ) {
			this.parkMinutes = 0;
		}
		else if (this.parkMinutes < 0) {
			this.parkMinutes = 0;
		} else if (this.parkMinutes > 59) {
			this.parkMinutes = 59;
		}

	}

	private get isParkingAnEventOptional() {
		return get(this.featuresList, ["Alarms", "EventQueue", "OptionalParkOnLeave"]);
	}

	private get isAutoFillEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "Park", "AutoFill"]);
	}

	private async getDefaultParkToHoursAndMinutes(): Promise<void> {
		if(this.getDefaultParkValue == 10)
		{
			this.parkHours = Math.floor(this.getDefaultParkValue / 60)
			this.parkMinutes = (this.getDefaultParkValue % 60);
			return;
		}

		this.parkHours = Math.floor(this.getDefaultParkValue / 60)
		this.parkMinutes = (this.getDefaultParkValue % 60);
	}

	/* validateSeconds() {
								        if (this.parkSeconds < 0) {
								            this.parkSeconds = 0;
								        } else if (this.parkSeconds > 59) {
								            this.parkSeconds = 59;
								        }
								    } */
}
