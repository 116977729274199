import { Subscription, noop } from "rxjs";

class VueSubscriptions$ {
	private subscriptions = {};

	public set(name: string, observable: Subscription) {
		this.subscriptions[name] = observable;
	}

	public get(name: string) {
		return this.subscriptions[name];
	}

	// @todo: useful for testing, remove later
	public getAll() {
		return this.subscriptions;
	}

	public unsubscribe(name: string, shouldDelete: boolean) {
		this.subscriptions[name].unsubscribe();
		// tslint:disable-next-line:no-unused-expression
		shouldDelete && this.deleteSubscription(name);
	}

	public unsubscribeAll() {
		Object.keys(this.subscriptions).forEach(name => {
			this.subscriptions[name].unsubscribe();
			this.deleteSubscription(name);
		});
	}

	public deleteSubscription(name: string) {
		delete this.subscriptions[name];
	}
}

const VueSubscriptionsStore = {
	install(Vue, options) {
		const subscriptions$ = new VueSubscriptions$();

		Vue.observables$ = subscriptions$;
		Object.defineProperties(Vue.prototype, {
			subscriptions$: {
				get() {
					return subscriptions$;
				}
			}
		});
	}
};

export default VueSubscriptionsStore;
