export function renderDistance(distance: number, isImperial: boolean) {
	if (distance === undefined || distance == null || isNaN(distance)) {
		return "";
	}

	if (isImperial) {
		if (distance > 5280) {
			return (distance / 5280).toFixed(2) + " miles";
		}

		return distance.toFixed(2) + " ft";
	} else {
		if (distance > 1000) {
			return (distance / 1000).toFixed(2) + " km";
		}

		return distance.toFixed(2) + " m";
	}
}

export function truncateString(
	input: string,
	length: number = 30,
	overflowText: string = "..."
) {
	if (!input || input.length <= length) {
		return input;
	}
	const truncatedString = input.substring(0, length - overflowText.length);
	return truncatedString + overflowText;
}

/** Function to parse the task data within the action setup list display */
export function parseTaskText(task) {
	if (!task) return "";

	var parsedData;

	try{
		parsedData = JSON.parse(task.taskData);
	} catch {
		parsedData = task.taskData;
	}

	try {
		switch (task.taskTypeID) {
			case 6:
				return `Title: ${parsedData.title}`;
			case 7:
				return `${parsedData.choiceDescription}: ${parsedData.choiceOptions.length} options, render type: ${parsedData.selectedChoice}`;
			case 8:
				return `Call ${parsedData.name}${parsedData.value.role ? ` : ${parsedData.value.role}` : ""}`;
			default:
				return task.taskText;
		}
	} catch (err) {
		console.error(`Could not parse text for task ID ${task.taskID}: `, err);

		// *Always* return something, at least
		return task.taskText || "Unknown";
	}
}
