
import {  Prop, Vue, Component, Watch } from "vue-property-decorator";
import SystemViewTreeNode from '@/types/sv-data/system-view/SystemViewTreeNode';
import api from "@/services/api.service";
import { Getter } from 'vuex-class';
import { UserPermissions, FeaturesList } from '@/store/types';
import { get } from 'lodash';
import { SetupSummaryResponse, ServerOverview } from '@/types/sv-data/system-view/SetupSummary';
import { truncateString } from "@/filters";

@Component({
	filters: {
		truncateString
	}
})
export default class SetupOverviewServer extends Vue {
	@Prop({ required: true, default: null }) private selectedNode: SystemViewTreeNode;

	@Getter getPermissions: UserPermissions;
	@Getter getFeaturesList: FeaturesList;

	private setupResponse: SetupSummaryResponse =  null;
	private serverOverview: ServerOverview = null;

	private showPassword: boolean = false;
	private passwordButtonText: string = "Show";

	private async mounted(): Promise<void> {
		await this.retrieveServerDetails();
	}



	@Watch("selectedNode", { deep: true })
	private async retrieveServerDetails(): Promise<void> {
		if(this.selectedNode && this.canUseSystemView)
		{
			this.serverOverview = null; // Reset previous display data

			this.setupResponse = await api.getSetupSummary(this.selectedNode.objectTypeId, this.selectedNode.objectId)

			if(this.setupResponse && !this.setupResponse?.error ) {
				try {
					this.serverOverview = JSON.parse(this.setupResponse.summary) as ServerOverview
				}
				catch(e) {
					console.error("Failed to Parse server summary: "+ e)
					this.setupResponse.error = "Failed to Parse server summary";
				}
			}
		}
	}

	private get canUseSystemView(): boolean {
        return get(this.getFeaturesList, ["SystemView"]) && (this.getPermissions.canViewSystemView || this.getPermissions.isSystemAdmin);
    }
}

