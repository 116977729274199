
import {  Prop, Vue,  Component } from "vue-property-decorator";
import SystemViewTreeNode from "@/types/sv-data/system-view/SystemViewTreeNode";
import SetupOverviewCamera from "./SetupOverviewCamera.vue";
import SetupOverviewDevice from "./SetupOverviewDevice.vue";
import SetupOverviewAppliance from "./SetupOverviewAppliance.vue";
import SetupOverviewServer from "./SetupOverviewServer.vue";
import ObjectTypeId from '@/types/sv-data/enums/ObjectTypeIds';
import HealthHistory from "./HealthHistory.vue";
import SetupOverviewGroupSync from "./SetupOverviewGroupSync.vue";
import SetupOverviewAlarm from './SetupOverviewAlarm.vue';

@Component({
	components: {
		"setup-overview-camera": SetupOverviewCamera,
		"setup-overview-device": SetupOverviewDevice,
		"setup-overview-appliance": SetupOverviewAppliance,
		"setup-overview-server" : SetupOverviewServer,
		"setup-overview-groupSync" : SetupOverviewGroupSync,
		"setup-overview-alarm": SetupOverviewAlarm,
		"health-history": HealthHistory
	}
})

	export default class SetupOverview extends Vue {
		@Prop({ required: true, default: null }) private selectedNode: SystemViewTreeNode

		private get getComponentToLoad(): string{
			if(!this.selectedNode?.objectTypeId){
				return ""
			}

			const type = this.selectedNode.objectTypeId;
			switch (+type) {
				case ObjectTypeId.Camera:
					return "setup-overview-camera";
				case ObjectTypeId.Door:
				case ObjectTypeId.Relay:
				case ObjectTypeId.Audio:
					return "setup-overview-device";
				case ObjectTypeId.Appliance:
					return "setup-overview-appliance";
				case ObjectTypeId.SyncSystem:
				case ObjectTypeId.GroupSync:
					return "setup-overview-groupSync";
				case ObjectTypeId.Server:
					return "setup-overview-server";
				case ObjectTypeId.Alarm:
					return "setup-overview-alarm";
				default:
					return "";
			}
		}

		private get selectedComponentText(): string
		{
			if(!this.selectedNode?.objectTypeId){
				return ""
			}

			const type = this.selectedNode.objectTypeId;
			switch (+type) {
				case ObjectTypeId.Camera:
					return "Camera: ";
				case ObjectTypeId.Door:
					return "Door: ";
				case ObjectTypeId.Relay:
					return "Relay: ";
				case ObjectTypeId.Audio:
					return "Audio: ";
				case ObjectTypeId.Appliance:
					return "Appliance: ";
				case ObjectTypeId.SyncSystem:
					return "Sync System: ";
				case ObjectTypeId.GroupSync:
					return "Sync: ";
				case ObjectTypeId.Server:
					return "Server: ";
				case ObjectTypeId.Alarm:
					return "Alarm: ";
				default:
					return "Item:";
			}
		}
	}
