import { Vue, Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
	IMarker,
} from "@sureview/v2-mapping-saas";
import { getAssetIcon, getTrackedAssetIcon } from "@/assets/svgIcons";
import { MarkerState, MarkerType } from "@/scripts/mapping/markerTypes";
import { AssetMapLayerItem } from "@/components/mobile-assets/types";
import moment from "moment";

const FieldOpsStore = namespace("fieldOps");

@Component({})
export default class FieldOpsMixin extends Vue {
	public assetItemMarkers: Map<number, IMarker> = new Map<number, IMarker>();
	public trackedAssetItemMarkers: Map<number, IMarker> = new Map<number, IMarker>();

	@FieldOpsStore.State("assetTypes") public assetTypes: Map<string, any>;
	@FieldOpsStore.State("onlineAssets") public assetAndUsersItems: AssetMapLayerItem[];

    public setMarkerIconForItem(itemId: number, selected: boolean) {
		const marker = this.assetItemMarkers.get(itemId);
		if (marker) {
			const item = this.assetAndUsersItems.find(i => i.assetId === itemId);
			const icon = this.getIconForAssetType(item, selected);
			marker.marker.setIcon(icon);
		}
	}

	public getIconForAssetType(asset: AssetMapLayerItem, selected: boolean) {
		let imageUrl: string = "";
		if(asset.isTrackedAsset)
		{
			imageUrl = getTrackedAssetIcon();
		}
		else
		{
			const assetType = this.assetTypes.get(asset.assetTypeId.toString());
			const iconColor = asset.iconColor ? asset.iconColor : (assetType ? assetType.iconColor : "#000000");
			imageUrl = getAssetIcon(iconColor, asset.isAsset, selected, asset.activeEventShare);
		}

		const icon: google.maps.Icon = { url: imageUrl };
		if (!asset.isTrackedAsset && selected) {
			icon.scaledSize = new google.maps.Size(40, 50)
		}
		return icon;
	}

	public getMarkerType(item: AssetMapLayerItem): MarkerType {
		if(item.isTrackedAsset)
		{
			return this.getTrackedAssetMarkerType();
		}
		const assetType = this.assetTypes.get(item.assetTypeId.toString()) ? this.assetTypes.get(item.assetTypeId.toString()) : { iconColor: "#000000" };
		const iconColor = item.iconColor ? item.iconColor : (assetType ? assetType.iconColor : "#000000");
		const imageUrl = getAssetIcon(iconColor, item.isAsset, false, item.activeEventShare);
		const markerType = new MarkerType({ imageUrl } as MarkerState, null);
		return markerType;
	}

	public getTrackedAssetMarkerType(): MarkerType {
        const imageUrl = getTrackedAssetIcon();
        const markerType = new MarkerType({ imageUrl } as MarkerState, null);
        return markerType;
    }

	public clearAssetMarkers(): void
	{
		if(this.assetItemMarkers)
		{
			this.assetItemMarkers.forEach(m => {
				try
				{
					m.remove();
				}
				catch
				{
					// ...
				}
			});
		}

		if(this.trackedAssetItemMarkers)
		{
			this.trackedAssetItemMarkers.forEach(m => {
				try
				{
					m.remove();
				}
				catch
				{
					// ...
				}
			});
		}
	}
}
