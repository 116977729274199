
	import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
	import { namespace, Getter, Mutation } from "vuex-class";
    import { ActionPlanTask } from "@/store/tasks/types";
    import vSelect from "vue-select";
	import { stringMixin } from "@/mixins";

    const { isNullOrWhitespace } = stringMixin.methods;
    const Tasks = namespace("tasks");

	@Component({
		components: {
            "vue-select": vSelect
        }
	})
	export default class DynamicDataList extends Vue {

        @Tasks.Action fetchDynamicDatalist: any;
        @Tasks.Getter getDynamicDatalist: any;

        @Tasks.Action fetchDynamicData: any;
        @Tasks.Getter getDynamicData: any;

		@Prop(Boolean) public editing: Boolean;

		@Prop() public task!: ActionPlanTask;

		public hasValidationErrors: boolean = false;
		public showErrorAnimation: boolean = false;
		public isValid: boolean = false;

		//Setup
		public dynamicDataTypes: any[] = [];
		public selectedTaskDataType: object = {};

		//View-Complete
		public dynamicData: any[] = [];
		public selectedTaskOutcome: object = {};
		private dataTypeId: number = 0;

		public async mounted() {
			this.isValid = false;
			await this.loadDependencys();
		}

		private async loadDependencys() {
			this.dataTypeId = this.task.taskData["dataTypeId"];

			if (this.editing) {
                this.onValidateTask();
                await this.fetchDynamicDatalist();
                this.dynamicDataTypes = this.getDynamicDatalist;
			} else {
				const payload = {
					DataTypeId: this.dataTypeId,
					EventTaskId: this.task.eventTaskID
				}
				await this.fetchDynamicData(payload);


				this.getDynamicData.forEach(element => {
					if (this.task.eventTaskID == element.eventTaskId) {
						this.dynamicData.push(element);
					}
				});
			}
		}

		// Used for Setup.
		/** Watcher, when data changes within 'selectedTaskDataType' call 'onselectedTaskData'*/
        @Watch("selectedTaskDataType", { deep: true, immediate: true })
        public onselectedTaskData(newValue) {
			if(this.editing) {
				this.task.taskData = this.selectedTaskDataType;
				this.onValidateTask();
			}
        }

		// Used for Task view/complete
		@Emit("complete-task")
		public onCompleteTask() {
			let result = {
				...this.task,
				result: this.selectedTaskOutcome["value"],
				taskData: this.selectedTaskOutcome["dataTypeId"],
				completed: true };
			return result;
		}

		// Ensure the task was setup correctly.
        @Watch("selectedTaskOutcome", { deep: true, immediate: true })
		@Emit("validate-task")
		public onValidateTask() {
            if(this.editing) {
				this.isValid = !isNullOrWhitespace(this.task.taskText) && !isNullOrWhitespace(this.task.taskData["name"]) ? true : false;
            } else {
				 this.isValid = !isNullOrWhitespace(this.selectedTaskOutcome["value"]);
            }

            if(!this.editing &&  this.isValid) {
                this.onCompleteTask();
			}

			return this.isValid;
		}
	}
