import { Vue, Component } from "vue-property-decorator";
import { DeviceType } from "@/store/mobile/types";
@Component({})
export default class MobileMixin extends Vue {
	public isLoadedInApp() {
		var mobileDevice = this.isiOS() || this.isAndroid();
		return mobileDevice;
	}
	public isiOS() {
		var iOS = window.notificationSettings?.DeviceType === DeviceType.CCiOS;
		return iOS;
	}
	public isAndroid() {
		var android = window.notificationSettings?.DeviceType === DeviceType.CCAndroid;
		return android;
	}
}
