
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { get } from "lodash";
import { FeaturesList, UserPermissions } from "@/store/types";
import NavHeader from "@/components/NavHeader.vue";
import AlarmSetupTable from "@/components/setup/alarm-setup/AlarmSetupTable.vue";
import HttpAlarmSetupTable from "@/components/setup/alarm-setup/HttpAlarmSetupTable.vue";
import AlarmDecoderTable from '@/components/setup/alarm-setup/decoder-setup/AlarmDecoderTable.vue';
import DistanceConfigurationTable from "@/components/setup/alarm-setup/distance-configuration/DistanceConfigurationTable.vue";

@Component({
    components: {
        "nav-header": NavHeader,
        "alarm-setup-table": AlarmSetupTable,
        "http-alarm-setup-table": HttpAlarmSetupTable,
        "alarm-decoder-table": AlarmDecoderTable,
		"distance-configuration-table": DistanceConfigurationTable
    }
})
export default class AlarmSetup extends Vue {
    $refs!: {
        alarmSetupTable: AlarmSetupTable;
        alarmDecoderTable: AlarmDecoderTable;
        httpAlarmSetupTable: HttpAlarmSetupTable;
    };

    @Getter("getFeaturesList") featuresList: FeaturesList;
    @Getter getPermissions: UserPermissions;

    private get isAlarmSetupEnabled() {
        return get(this.featuresList, ["AlarmSetup"])
    }

    private get isHttpAlarmsSetupEnabled() {
        return get(this.featuresList, ["Alarms", "HttpAlarms"])
    }

	private get isDistanceConfigurationEnabled(): boolean {
		return get(this.featuresList, ["AlarmSetup", "DistanceConfiguration"]);
	}

    //Triggers the update method for the provided tab
    private triggerUpdate(tab: string) {
        if (tab === "decoder-setup"){
            this.$refs.alarmDecoderTable.loadDecoders();
            this.$refs.alarmSetupTable.updateResponses();
            if (this.isHttpAlarmsSetupEnabled) {
                this.$refs.httpAlarmSetupTable.updateResponses();
            }
        } else if (tab === "alarm-setup"){
            this.$refs.alarmSetupTable.updateResponses();
        } else if (tab === "http-alarm-setup"){
            this.$refs.alarmSetupTable.updateResponses();
            this.$refs.httpAlarmSetupTable.updateResponses();
        }
    }
}
