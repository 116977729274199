import { GetterTree } from "vuex";
import { SiteMonitorCamerasState } from "./types";
import { RootState } from "../types";
import Vue from "vue";

export const getters: GetterTree<SiteMonitorCamerasState, RootState> = {
	getMediaMatrixCameras: state => state.mediaMatrixCameras,
	getMediaMatrixLayers: state => state.mediaMatrixLayers,
	getMediaMatrixClips: state => state.mediaMatrixClips,
	getAreaCameras: state => state.areaCameras,
	getDeviceControllerCameras: state => state.deviceControllerCameras,
	getDeviceControllerOutputs: state => state.deviceControllerOutputs,
	getDeviceControllerClips: state => state.deviceControllerClips,
	getLayoutIndex: state => state.layoutIndex,
	getLayouts: state => state.layouts,
	getFilter: state => state.mediaMatrixFilter,
	getMediaMatrixEventId: state => state.mediaMatrixEventId,
	getAwaitingCamera: state => state.awaitingCamera,
	getAwaitingClip: state => state.awaitingClip,
	getAllMatrixContents: state => state.matrixContents,
	getMediaMatrixLastChanged: state => state.mediaMatrixLastChanged,
	getMatrixContents: state => {
		return (index: number) => {
			return state.matrixContents[index.toString()];
		};
	},
	getMatrixAssignedCameras: state => {
		if(!state.matrixContents){
			return [];
		}

		var assignedCameras = [];
		for (const [index, content] of Object.entries(state.matrixContents)) {
			if (content && content.camera && content.camera.objectID && !assignedCameras.includes(content.camera.objectID)) {
				assignedCameras.push(content.camera.objectID)
			}
	  	}
		return assignedCameras;
	},
	getMatrixPushContents: state => {
		return (index: number) => {
			if (state.matrixPushContents === undefined) {
				Vue.set(state.matrixPushContents, index.toString(), null);
			}

			return state.matrixPushContents[index.toString()];
		};
	},
	getRequiresEventDetails: state => state.requiresEventDetails,
	getMediaMatrixIsNew: state => state.mediaMatrixIsNew,
	getFetchingMatrix: state => state.fetchingMatrix,
	getHighlightCellIndex: state => state.highlightCellIndex,
	getLinkRecord: state => state.linkRecord,
	getWaitingForEvents: state => state.waitingForEvent,
	areaCameras: state => state.areaCameras,
	searchedCameras: state => state.searchedCameras,
	searchedOutputs: state => state.searchedOutputs,
	getShownAuditClips: state => state.shownAuditClips,
};
