
	import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
	import VueTreeselect from "@riophae/vue-treeselect";
	import SystemViewTreeNode from "@/types/sv-data/system-view/SystemViewTreeNode";
	import ObjectTypeId from "@/types/sv-data/enums/ObjectTypeIds";
	import api from "@/services/api.service";
	import { BTooltip } from "bootstrap-vue";
    import SystemViewRequestTreeParams from "@/types/sv-data/system-view/SystemViewRequestTreeParams";

	@Component({
		components: {
			VueTreeselect,
			BTooltip
		}
	})
	export default class TreePicker extends Vue {
		$refs!: {
			treeSelect: any
		};

		@Prop({required: false, default: false}) isLoading: boolean;
		@Prop({required: true}) tree: SystemViewTreeNode[];
		@Prop({required: true}) healthFilters: SystemViewRequestTreeParams

		public loadMoreLabel: string = "Load more...";

		// This is required due to VueTreeselect not re-rendering correctly
		// See https://github.com/riophae/vue-treeselect/issues/483
		private rerenderKey: number = 0;

		private get treeIsReady(): boolean {
			return !!this.tree &&
				this.tree.length > 0 &&
				!!this.tree[0].id;
		}

		@Watch("tree")
		private onTreeChanged(): void {
			this.rerenderKey++;
		}

		private async loadOptions({ action, parentNode, callback, searchQuery }) {
			this.updateNodeWithMoreOptions(parentNode);
			callback();
		}

		private async updateNodeWithMoreOptions(parentNode: SystemViewTreeNode): Promise<void> {
			var totalDevices = 0;
			var totalResponses = 0;

			// Remove the load more node if it exists
			if (!!parentNode.children && parentNode.children.length > 0 &&
				parentNode.children[parentNode.children.length -1].label === this.loadMoreLabel) {
				parentNode.children.pop();
			}

			if (!!parentNode && !!parentNode.children && parentNode.children.length > 0) {
				// We need to get the total number of responses in the child collection
				totalResponses = parentNode.children.filter((node) => node.objectTypeId === ObjectTypeId.Alarm).length;

				// Using the totalResponses we can now get the total devices
				totalDevices = parentNode.children.length - totalResponses;
			}

			let updatedParent = await api.getSystemViewSetupEntities(parentNode.objectTypeId, parentNode.objectId, totalDevices, totalResponses, this.healthFilters);

			updatedParent.children.forEach(node => {
				node.label = node.title;
				node.id = `${node.objectTypeId}-${node.objectId}-${node.title}`;
				node.children = undefined;
			});

			if (!!parentNode.children && parentNode.children.length > 0) {
				parentNode.children.push(...updatedParent.children);
			} else {
				parentNode.children = updatedParent.children;
			}
			parentNode.totalChildItems = updatedParent.totalChildItems;

			if (parentNode.children.length < parentNode.totalChildItems) {
				// This is a placeholder node to trigger an add more children if the parentNode has more
				var loadMoreNode: SystemViewTreeNode = {
					title: this.loadMoreLabel,
					label: this.loadMoreLabel,
					id: `${parentNode.objectTypeId}-${parentNode.objectId}-${this.loadMoreLabel}`,
					objectId: parentNode.objectId,
					objectTypeId: parentNode.objectTypeId,
					isExpandable: false,
					shouldDisplayData: true,
					children: undefined,
					totalChildItems: 0,
				};

				parentNode.children.push(loadMoreNode);
			}

			this.onTreeUpdated();
		}

		private getNodeIcon(node: SystemViewTreeNode): string {

			var css = "";

			if(node.title === this.loadMoreLabel) {
				return css;
			}

			switch(node.objectTypeId) {
				case ObjectTypeId.Server:
					css += "fa-server";
					break;
				case ObjectTypeId.GroupSync:
					css += "fa-sync";
					break;
				case ObjectTypeId.Alarm:
					css += "fa-bell";
					break;
				case ObjectTypeId.Camera:
					css += "fa-cctv";
					break;
				case ObjectTypeId.Door:
					css += "fa-door-open";
					break;
				case ObjectTypeId.Audio:
					css += "fa-volume";
					break;
				case ObjectTypeId.Relay:
					css += "fa-sign-out";
					break;
				default:
					css += "fa-circle";
			}

			if(node.lastInteractionHealthy === true)
			{
				css += " healthy-item";
			}
			else if(node.lastInteractionHealthy === false)
			{
				css += " unhealthy-item";
			}

			return css;
		}

		private async itemSelected(node: SystemViewTreeNode): Promise<void> {
			// Vue-TreeSelect only triggers loadOptions once so we need to manually trigger
			// a load when the LoadMore item is selected.
			if (node.label === this.loadMoreLabel) {
				this.loadMoreOptions(node);

				// We do not want the Load More button selected.
				this.$refs.treeSelect.clear();
			} else {
				this.emitItemSelected(node);
			}
		}

		private async loadMoreOptions(node: SystemViewTreeNode): Promise<void> {
			// Due to this update being a special case (Vue-TreeSelect only triggers loadOptions once)
			// We need to find the parent node (GroupSync node) for the devices to load them manually
			this.tree.forEach(applianceNode => {
				if (!!applianceNode.children && applianceNode.children.length > 0) {
					applianceNode.children.forEach(serverNode => {
						if (!!serverNode.children && serverNode.children.length > 0) {
							serverNode.children.forEach(groupSyncNode => {
								if (groupSyncNode.objectId === node.objectId && groupSyncNode.objectTypeId === node.objectTypeId) {
									// Now update the node with more children.
									this.updateNodeWithMoreOptions(groupSyncNode);
								}
							});
						}
					});
				}
			});
		}

		@Emit("itemSelected")
		private emitItemSelected(node: SystemViewTreeNode): SystemViewTreeNode {
			if (node.shouldDisplayData) {
				return node;
			}

			return null;
		}

		@Emit("treeUpdated")
		private onTreeUpdated(): SystemViewTreeNode[] {
			return this.tree;
		}
	}
