export enum CustomFieldTypes {
	Text = 1,
	TextArea = 2,
	Number = 3,
	Checkbox = 4
}

export interface UserFacingCustomFieldType{
	customFieldTypeId: number,
	typeName: string
}

export const CustomFieldTypesForDisplay: UserFacingCustomFieldType[] = [
	{
		customFieldTypeId: CustomFieldTypes.Text,
		typeName: "Text"
	},
	{
		customFieldTypeId: CustomFieldTypes.TextArea,
		typeName: "Text Area"
	},
	{
		customFieldTypeId: CustomFieldTypes.Number,
		typeName: "Number"
	},
	{
		customFieldTypeId: CustomFieldTypes.Checkbox,
		typeName: "Checkbox"
	},
];
