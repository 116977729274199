

import { ResponseAction, ResponseActionIndexed, ResponseActionType } from "@/store/response-actions/types";
import { VueBootstrapField } from "@/store/types";
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const responseActions = namespace("responseActions");

interface stringFormattedDictionaryObject {
	emphasised: boolean;
	value: string;
}

@Component({})
export default class ResponseActionTable extends Vue {

	@Prop({required: true}) responseActions: ResponseAction[];
	@Prop({default: true, type: Boolean }) private readonly allowEdit!: boolean;

	@responseActions.State('supportedActionTypes') supportedActionTypes: ResponseActionType[];

	private tableFields: VueBootstrapField[] = [
		{
			key: "responseActionTypeID",
			label: "Type",
			sortable: true,
			formatter: alarmActionTypeId => {
				return this.getActionTitle(alarmActionTypeId);
			},
			filterByFormatted: true
		},
		{ key: "description", label: "Description", sortable: true },
		{ key: "actions", label: "Actions", sortable: false }
	];

	private showConfirmDeleteResponseAction: boolean = false;
	private responseActionToDelete: ResponseAction = null;
	private updateTable: number = 0;
    private editingIndex: number = -1;

	private getActionTitle(alarmActionTypeId: number): string {
		if (this.supportedActionTypes) {
			const actionType = this.supportedActionTypes.find(x => x.responseActionTypeId === alarmActionTypeId);
			if(actionType) {
				return actionType.title;
			}
		}

		return "";
	}

	private onCancelDelete(): void {
		this.showConfirmDeleteResponseAction = false;
		this.editingIndex = -1;
		this.responseActionToDelete = null;
	}

	@Emit('deleteResponseAction')
	private onConfirmDelete(): ResponseAction {
		this.showConfirmDeleteResponseAction = false;
		this.editingIndex = -1;
		return this.responseActionToDelete;
	}

	@Emit("editResponseAction")
	public handleEditClick(responseAction: ResponseAction, index: number): ResponseActionIndexed {
		return { responseAction: responseAction, index: index} as ResponseActionIndexed
	}

	public handleDeleteClick(responseAction: ResponseAction, index: number): void {
		if (this.allowEdit) {
			this.responseActionToDelete = responseAction;
			this.showConfirmDeleteResponseAction = true;
			this.editingIndex = index;
		}
	}

	@Watch("responseActions")
	public onResponseActionsChange(): void {
		this.responseActionToDelete = null;
		this.updateTable++;
	}

	/**
	 * @summary This function uses a regular expression to match strings <***>like this</***>.
	 * @param rawString The string to match the regular expression on.
	 * @returns A dictionary, containing a variable amount of entries.
	 * 			Each property contains an object stating the string value and if it should be emphasised or not.
	 */
	private getDescriptionParts(rawString: string): Record<string, stringFormattedDictionaryObject> {
		const boldPartRegEx = /<\*\*\*>([^<]{1,})<\/\*\*\*>/g;

		let match;
		let startIndex = 0;
		let currentStringNumber = 1;
		let stringFormattedDictionary = {} as Record<string, stringFormattedDictionaryObject>;

		if(!rawString){
			return stringFormattedDictionary;
		}

		while ((match = boldPartRegEx.exec(rawString)) !== null) {

			const descriptionBeginningPart = rawString.substring(startIndex, rawString.indexOf(match[0])).trim();

			// our non-matches should not be emphasised
			if (descriptionBeginningPart) {
				stringFormattedDictionary[`string${currentStringNumber++}`] = {
					emphasised: false,
					value: descriptionBeginningPart
				};
			}

			// our regex match should be emphasised
			stringFormattedDictionary[`string${currentStringNumber++}`] = {
				emphasised: true,
				value: match[1].trim()
			};

			// set startIndex for next loop
			startIndex += boldPartRegEx.lastIndex - startIndex;
		}

		// add whatever is leftover as non-emphasised
		if (startIndex < rawString.length) {
			stringFormattedDictionary[`string${currentStringNumber}`] = {
				emphasised: false,
				value: rawString.substring(startIndex).trim()
			};
		}

		return stringFormattedDictionary;
	}
}
