
import { Component, Vue, Watch } from "vue-property-decorator";
import { passwordValidationMixins } from "@/mixins";
import { namespace } from "vuex-class";
import { SecurityInfoModel } from "@/store/signup/types";

const { getPasswordStrength } = passwordValidationMixins.methods;

const SignUp = namespace("signup");

@Component({})
export default class SecurityInfo extends Vue {

    @SignUp.Mutation advanceCurrentStep: any;
    @SignUp.Mutation setSecurityInfo: any;
    @SignUp.State securityInfo;

    private securityInfoInput: SecurityInfoModel = {
        password: "",
        passwordConfirmation: ""
    }

    private passwordStrength = "";

    private mounted() {
        this.securityInfoInput = { ... this.securityInfo };
    }

    @Watch("securityInfoInput.password")
    private updatePasswordStrength() {
        this.passwordStrength = getPasswordStrength(this.securityInfoInput.password);
    }

    private isValid() {
        if (
            (this.securityInfoInput.password.length === 0 || this.securityInfoInput.passwordConfirmation.length === 0) ||
            (this.securityInfoInput.passwordConfirmation != this.securityInfoInput.password)
        ) {
            return false;
        }

        if (this.passwordStrength === "Weak") {
            return false;
        }

        return true;
    }

    private submit() {
        if (this.isValid()) {
            this.setSecurityInfo({ ...this.securityInfoInput });
            this.advanceCurrentStep();
        }
    }
}
