
import {
	Component,
	Vue,
} from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select"; // @ref https://sagalbot.github.io/vue-select/
import draggable from "vuedraggable";
import { namespace } from "vuex-class";
import { parseTaskText } from "@/filters";
import api from "../../services/api.service";
import { cloneDeep } from "lodash";

const Tasks = namespace("tasks");

@Component({
	components: {
		VuePerfectScrollbar,
		draggable,
		"vue-select": vSelect
	},
	filters: {
		parseTaskText
	}
})
export default class TaskTriggerDialog extends Vue {
	error: string = null;
	@Tasks.Getter("getTaskTypes") taskTypes: any; // fetched automatically by initApp action
	@Tasks.Getter("getEditTriggerAction") triggerAction: any;
	@Tasks.Mutation setEditTriggerAction: any;
	@Tasks.Getter("getTaskLibraryByCategoryID") taskLibraryByCategoryID: any;

	get taskTypesByTitle(): any {
		let taskTypes: any = {};
		this.taskTypes.forEach(type => {
			taskTypes[type.taskTypeID] = type.title;
		});
		return taskTypes;
	}

	yesNoOptions: any[] = ["Yes", "No"];
	taskList: any[] = [];

	removeTrigger(trigger) {
		let triggerIndex = this.triggerAction.trigger.indexOf(trigger);
		this.triggerAction.trigger.splice(triggerIndex, 1);
	}

	async save() {
		var triggersPayload = cloneDeep(this.triggerAction.trigger);
		triggersPayload = triggersPayload.map(trigger => {
			var taskTrigger = {...trigger, taskData: JSON.stringify(trigger.taskData)}
			if (taskTrigger.trigger)
			{
				taskTrigger.trigger.taskData = null;
			}
			return taskTrigger;
		});

		await api.triggeredTasksSave(
			this.triggerAction.taskID,
			triggersPayload
		);
		this.triggerAction.triggeredTaskCount = this.triggerAction.trigger.length;

		this.setEditTriggerAction(null);
	}

	cancel() {
		this.setEditTriggerAction(null);
	}
}
