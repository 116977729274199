import { MutationTree } from "vuex";
import {  CSVParser } from "./types";

export const mutations: MutationTree<CSVParser> = {
	putCSVParsed(state, { headers, body, name }) {
		state.csvParsed = {
			...state.csvParsed,
			[name]: {
				headers,
				body
			}
		};
	},

	putMandatoryFields(state, { mandatoryFields, name }) {
		state.mandatoryFields = {
			...state.mandatoryFields,
			[name]: mandatoryFields
		}
	},

	putValidHeadersName(state, { validHeadersName, name }) {
		state.validHeadersName = {
			...state.validHeadersName,
			[name]: validHeadersName
		}
	}
};
