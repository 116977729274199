
    import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
    import { namespace, Getter, Mutation, Action } from 'vuex-class';

    import Multiselect from 'vue-multiselect';
	import api from "@/services/api.service";
	import { MapBounds } from "@/store/map-layers/types";

    const MapLayers = namespace('mapLayers');

    @Component({
        components: {
            'multiselect': Multiselect
        }
    })
    export default class MapSearch extends Vue {

        public autocomplete: google.maps.places.Autocomplete | null = null;

        @Action searchGroup: any;
        public groupSearchLoading: boolean = false;

        public searchType: string = 'area';
        public selectedGroup: any = {};
        public createdAutocomplete: boolean = false;

        @Getter('getUserGroups') groups: any;

        @MapLayers.State('defaultMapBounds') MapBounds: MapBounds;

        @Watch('searchType')
        onSearchTypeChanged(value: string) {
            if (value === 'location' && !this.createdAutocomplete) {
                this.createdAutocomplete = true;
                this.setAutocomplete();
            }
        }

        public async findGroup(query: string) {
            this.groupSearchLoading = true;
            await this.searchGroup(query);
            this.groupSearchLoading = false;
        }

        @Emit("setBounds")
        public async areaSelected(area: any) {
            const bounds = await api.getBoundsForArea(area.groupID);
            if (bounds) {
                return bounds;
            }
        }

        public setAutocomplete() {

            setTimeout(() => {
                this.autocomplete = new google.maps.places.Autocomplete(
                    document.getElementById('tbLocation') as HTMLInputElement);

                //this.autocomplete!.bindTo('bounds', this.map!.map);
                (this.autocomplete as any).setFields(['address_components', 'geometry', 'name']);

                this.autocomplete!.addListener('place_changed', () => {

                    let place = this.autocomplete!.getPlace();

                    if (place.geometry) {


                        if (place.geometry.viewport) {
                            console.log("setMapLocation - viewport");
                            this.$emit("setMapLocation", { viewport: place.geometry.viewport });
                        } else if (place.geometry.location) {
                            this.$emit("setMapLocation", { location: place.geometry.location });
                        }
                    }
                })
            }, 0.5 * this.$config.ANIMATION_DURATION);
        }
    }

