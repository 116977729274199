
import { Component, Mixins, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { helpers } from "vuelidate/lib/validators";
import TextHighlight from "vue-text-highlight";
import { cloneDeep, get, isArray, uniqBy, transform, invert } from "lodash";

import NavHeader from "@/components/NavHeader.vue";
import { UserPermissions, FeaturesList, PaginatedSearchQueryParams } from "@/store/types";
import { UserDetails, UserGroup } from "@/store/user-management/types";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import UserGroupInput from "./UserGroupInput.vue";
import { passwordValidationMixins } from "@/mixins";
import api from "@/services/api.service";
import { SubscriptionBilledPermission } from "@/store/subscription/types";
import { ModalItemCSV } from "@/store/csv-form-handler/types";
import csvUploaderWrapper from "@/components/csv-uploader/csv-uploader-wrapper.vue";
import UsersPagedResponse from "@/types/sv-data/users/UsersPagedResponse";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import ClearMFA from "./ClearMFA.vue";
import AssetTypeSelect from './AssetTypeSelect.vue';
import PaginatedSearch from "@/mixins/PaginatedSearch";

const Eventqueue = namespace("eventqueue");
const GenericTableStore = namespace("GenericTable");
const Subscription = namespace("subscription");
const Areas = namespace("areas")

@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable,
		"text-highlight": TextHighlight,
		"csv-uploader-wrapper": csvUploaderWrapper
	}
})
export default class UserManagementSetup extends Mixins(PaginatedSearch) {
	@Getter getPermissions: UserPermissions;
	@Getter getUserTenantGroupId: number;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getUserId") public currentUserId!: number;
	@Getter("getUserGroupId") public currentGroupId!: number;
	@Getter getFeature: (featuresList: string[]) => boolean;

	@Areas.Action fetchAreaDictionary: () => Promise<void>;
	@Areas.Getter getAreaTitle: (id: number) => string;
	@Areas.State areaDictionary: Map<number, string>;

	@Eventqueue.Mutation setTreeGroupSelected: any;

    @Subscription.State billedUserPermissions: SubscriptionBilledPermission[];
	@Subscription.Action fetchSubscription: any;
	@Subscription.Action fetchBilledUserPermissions: any;

	@GenericTableStore.Getter getModalRow: any;

	private userList: UserDetails[] = [];
	private userGroups: UserGroup[] = [];

	private emailAsUser: boolean = false;
	private progressBulk = {}
	private userGroupLookup = [];
	private originalUserGroups = [];
	private assetTypesList = [];

	private get uniqueModalItemsByTitle(): ModalItemCSV[] {
		return uniqBy(this.getModalItems(), 'title');
	}

	private isSendingInvite: boolean = false;
	private userToInvite: number = 0;
	private isLoading: boolean = false;
	private totalRecords: number = 0;

	private getTableHeaders() {
		return [
			{
				title: "Full Name",
				key: "fullName",
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The users full name",
				searchable: true,
				visible: this.visibleColumns.includes("fullName"),
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
			{
				title: "Email",
				key: "username",
				order: 2,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The users username",
				searchable: true,
				visible: this.visibleColumns.includes("username"),
				dataType: "email",
				sortable: true,
				sortKey: "Email"
			},
			{
				title: "Area",
				key: "groupTitle",
				order: 3,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The area that the user belongs to",
				searchable: this.visibleColumns.includes("groupTitle"),
				visible: true,
				dataType: "input",
				sortable: true,
				sortKey: "Area",
			},
			{
				title: "Asset Type",
				key: "assetType",
				order: 4,
				sortOrder: 0,
				description: "",
				searchable: true,
				visible: this.visibleColumns.includes("assetType"),
			},
			{
				title: "User Group",
				key: "permissionGroupIds",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The permissions group the user is part of",
				searchable: true,
				visible: this.visibleColumns.includes("permissionGroupIds"),
				dataType: "list",
				useCustomCell: true
			},
			{
				title: "Address",
				key: "address",
				order: 6,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The users address",
				searchable: true,
				visible: this.visibleColumns.includes("address"),
				dataType: "input",
				sortable: true,
			},
			{
				title: "Telephone",
				key: "telephone",
				order: 7,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The users phone number",
				searchable: true,
				visible: this.visibleColumns.includes("telephone"),
				dataType: "telephone",
				sortable: true,
			},
			{
				title: "Cell",
				key: "mobile",
				order: 8,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The users cell number",
				searchable: true,
				visible: this.visibleColumns.includes("mobile"),
				dataType: "telephone",
				sortable: true,
				sortKey: "Mobile",
			},
			{
				title: "Available as Contact",
				key: "isContact",
				order: 9,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Is the user available as a contact",
				searchable: false,
				visible: this.visibleColumns.includes("isContact"),
				dataType: "checkbox",
				sortable: true,
				sortKey: "AvailableAsContact",
			},
			{
				title: "Registration Status",
				key: "registrationStatus",
				order: 10,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The users current registration status",
				searchable: true,
				visible: this.visibleColumns.includes("registrationStatus"),
				dataType: "input",
				useCustomCell: true,
				sortable: true
			},
			{
				title: "2FA set",
				key: "userMFASet",
				order: 11,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Does the user have MFA enabled",
				searchable: false,
				visible: this.visibleColumns.includes("userMFASet"),
				dataType: "checkbox",
				sortable: true,
				sortKey: "TwoFactorAuthSet",
			},
			{
				title: "2FA req.",
				key: "requiresMFA",
				order: 12,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Is MFA required for user",
				searchable: false,
				visible: this.visibleColumns.includes("requiresMFA"),
				dataType: "checkbox",
				sortable: true,
				sortKey: "TwoFactorAuthRequired",
			},
		] as TableHeader[];
	}

	private getModalItems(): ModalItemCSV[] {
		let modalItems = [
			{
				title: !this.emailAsUser ? "Username" : "Email",
				key: "username",
				dataType: "text",
				required: true,
				order: 1,
				maxLength: 150,
				validations: {
					isEmailValid: helpers.withParams(
						{ type: "isEmailValid", errorMessage: "Email address is not valid" },
						(value) => {
							if (!this.emailAsUser) {
								return true;
							}
							var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
							return !helpers.req(value) || re.test(String(value).toLowerCase());
						}
					)
				},
				csvComponent: 'input',
				csvValidation: {
					validationCell: ({ currentElement, formData }) => {
						const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						const isValidEmail = re.test(String(currentElement.csvValue).toLowerCase())

						if(!isValidEmail) return { isValid: false }

						const isEmailAlreadyExist = this.userList.some(({ email }) => email === currentElement.csvValue)
						if(isEmailAlreadyExist) {
							return {
								isValid: false,
								message: `Email: "A user with this email already exists"`
							}
						}

						const emails = formData.reduce((emails, row) => {
							const emailField = row[0]

							if(emailField.uniqId === currentElement.uniqId) return emails
							emails.push(emailField.csvValue)
							return emails
						}, [])

						return {
							isValid: !emails.some(email => email === currentElement.csvValue),
						}
					}
				},
				readOnly: this.isSuiteEnabled,
			},
			{
				title: "Use Active Directory",
				key: "useAD",
				visible: this.canEnableAD,
				dataType: "checkbox",
				readOnly: false,
				newOnly: true,
				required: false,
				order: 3,
				csvComponent: 'checkbox',
				csvExclude: !this.canEnableAD
			},
			{
				title: "Full Name",
				key: "fullName",
				dataType: "text",
				placeholder: "John Doe",
				order: 4,
				maxLength: 50,
				required: true,
				csvComponent: 'input',
				csvValidation: {
					validationCell({ currentElement }) {
						return { isValid: !!currentElement.csvValue }
					}
				},
				readOnly: this.isSuiteEnabled,
			},
			{
				title: "Asset Type",
				key: "assetTypeId",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				searchable: true,
				visible: this.canSetUserType,
				dataType: "component",
				data: AssetTypeSelect,
				props: {
					clearable: true
				},
				csvExclude: true,
				readOnlyMethod: (item) => item.SuiteUserId && !item.mobileAppEnabled,
				readOnlyMessage: "Asset types can only be assigned to users with the Field ops licence."
			},
			{
				title: "User Group",
				key: "permissionGroupIds",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				searchable: true,
				visible: true,
				dataType: "component",
				data: UserGroupInput,
				componentHasValidation: true,
				csvData: Object.keys(this.userGroupLookup),
				csvComponent: 'select-multiple',
				readOnly: this.isSuiteEnabled,
			},
			{
				title: "Address",
				key: "address",
				dataType: "text",
				order: 7,
				maxLength: 200,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
			},
			{
				title: "Telephone",
				key: "telephone",
				dataType: "tel",
				order: 8,
				maxLength: 50,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
			},
			{
				title: "Cell",
				key: "mobile",
				dataType: "tel",
				order: 8,
				maxLength: 50,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
			},
			{
				title: "Available as Contact",
				key: "isContact",
				dataType: "checkbox",
				readOnly: false,
				order: 9,
				csvComponent: 'checkbox',
			},
			{
				title: "Email",
				key: "email",
				dataType: "email",
				visible: !this.emailAsUser,
				order: 4,
				maxLength: 150,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
			},
			{
				title: "Requires Two-Factor Authentication",
				key: "requiresMFA",
				dataType: "checkbox",
				csvExclude: true,
				order: 10,
				readOnly: this.isSuiteEnabled,
			},
			{
				title: "Two-Factor Authentication status",
				key: "userMFASet",
				dataType: "component",
				data: ClearMFA,
				updateOnly: true,
				csvExclude: true,
				order: 11,
			},
		] as ModalItemCSV[];

		let areasField = {
			title: "Area",
			key: "groupID",
			dataType: "component",
			readOnly: false,
			required: true,
			data: AreaTreeSelect,
			props: {
				reduce: (a) => a.id,
				clearable: false
			},
			order: 6,
			csvComponent: 'select',
			csvData: Object.keys(this.areasByTitle),
			csvValidation: {
				validationCell: ({ currentElement }) => {
					return {
						isValid: !!this.areasByTitle[currentElement.csvValue]
					}
				},
			}
		} as ModalItemCSV;

		if (!this.hideAreasFeature) {
			modalItems.push(areasField);
		}

		return modalItems;
	}

	private visibleColumns: string[] = [
		"fullName",
		"username",
		"groupTitle",
		"permissionGroupIds",
		"address",
		"telephone",
		"mobile",
	];

	private async openUpdateModal(): Promise<void> {
		await this.fetchAreaDictionary();
	}

	private get areasByTitle() {
		return invert(this.areaDictionary);
	}

	private get canEnableAD(): boolean {
		if (get(this.featuresList, ["Users", "CanSetActiveDirectory"]) == null) {
			return false;
		} else {
			return get(this.featuresList, ["Users", "CanSetActiveDirectory"]);
		}
	}

	private get canSetUserType(): boolean {
		return this.getFeature(["Users", "CanSetUserType"]);
	}

	private get hideAreasFeature(): boolean {
		return get(this.featuresList, ["Users", "HideArea"]);
	}

	private get isSuiteEnabled(): boolean {
		return get(this.featuresList, ["Suite"]);
	}

	private get isADChecked() {
		if (this.getModalRow != null && this.getModalRow.useAD != 0) {
			return this.getModalRow.useAD;
		} else {
			return false;
		}
	}

	private async updateUserDetails(userToUpdate): Promise<void> {
		this.setUserPermissions(userToUpdate);
		if (this.emailAsUser) {
			userToUpdate.email = userToUpdate.username;
		}

		try {
			await api.updateUser(userToUpdate);
			await this.fetchSubscription(this.getUserTenantGroupId);
		} catch (ex) {
			if(ex.response && ex.response.status === 566){
				Vue.prototype.$notify({
					type: "error",
					title: userToUpdate.userId >= 0 ? "User not updated." : "User not added.",
					text: ex.response.data
				});
			} else {
				throw ex;
			}
		}

		await this.updateData(this.mostRecentSearchParams);
	}

	private async newUserDetails(userToAdd): Promise<void> {
		if (this.isADChecked) {
			delete userToAdd.useAD;
			userToAdd.password = "-";
		}

		this.setUserPermissions(userToAdd);
		if (this.emailAsUser) {
			userToAdd.email = userToAdd.username;
		}

		if (this.hideAreasFeature) {
			userToAdd.groupID = this.getUserTenantGroupId;
		}

		try {
			await api.createUser(userToAdd);
			await this.fetchSubscription(this.getUserTenantGroupId);
		} catch (ex) {
			if(ex.response && ex.response.status === 566){
				Vue.prototype.$notify({
					type: "error",
					title: "Error",
					text: ex.response.data
				});
			} else {
				throw ex;
			}
		}
		await this.updateData(this.mostRecentSearchParams);
	}

	private async deleteUserDetails(userToDelete): Promise<void> {
		await api.deleteUser(userToDelete.userID);
		await this.fetchSubscription(this.getUserTenantGroupId);
		let userIndex = this.userList.findIndex(element => element.userID == userToDelete.userID);
		this.userList.splice(userIndex, 1);
	}

	private setUserPermissions(user): void {
		var permissions = [];
		for (var item in user.permissionGroupIds) {
			if (user.permissionGroupIds[item].userGroupId) {
				permissions[item] = user.permissionGroupIds[item].userGroupId;
			} else if (user.permissionGroupIds[item].key) {
				permissions[item] = user.permissionGroupIds[item].key;
			}
		}
		user.permissionGroupIds = permissions;
	}

	private getPermissionTitle(userGroupId: number): string {
		let result = this.userGroups.find(p => p.userGroupId === userGroupId);
		if (result) {
			return result.title;
		}
		return "";
	}

	private getRowId(): string {
		if (this.getModalRow && this.getModalRow.userID) {
			return this.getModalRow.userID;
		}
		return "";
	}

	private async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		try {
			this.isLoading = true;

			await this.fetchAreaDictionary();
			this.userGroups = await api.getUserGroup();

			const cancellableQuery = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);
			let usersPaginatedResponse: UsersPagedResponse = await api.getUsers(cancellableQuery);

			this.totalRecords = usersPaginatedResponse.totalRecords;

			this.userList = this.formatUsers(usersPaginatedResponse.data);
		}
		catch (ex) {
			console.log("Unexpected error fetching users: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	private formatUsers(users) {
		return users.map(user => {
			user.permissionGroupIds = user.permissionGroupIds.map(p => {
				return {
					key: p,
					title: this.getPermissionTitle(p)
				};
			});

			if (this.currentUserId == user.userID) {
				user.readOnly = true;
			}
			else
			{
				var isReadOnly = !!user.groupSyncId && !this.getPermissions.canOverrideGroupSync && !this.getPermissions.isSystemAdmin;
				user.readOnly = isReadOnly,
				user.readOnlyMessage = isReadOnly ? "You do not have permission to edit Sync Users": null
			}

			if (user.assetTypeId !== undefined) {
				user.assetType = this.formattedAssetTypesById[user.assetTypeId]
			} else {
				user.assetType = ""
			}

			user.groupTitle = this.getAreaTitle(user.groupID);

			return user;
		});
	}

	// If feature is on / off
	private get isUsersEnabled(): boolean {
		return get(this.featuresList, ["Users"]);
	}

	private get isUsersBulkAddEnabled(): boolean {
		return get(this.featuresList, ["Users", "BulkAdd"]);
	}

	private async mounted(): Promise<void> {
		this.isLoading = true;
		this.assetTypesList = await api.getCachedAssetTypes();
		this.emailAsUser = get(this.featuresList, ["Users", "EmailAsUser"]);
		await this.updateData();
		this.fetchSubscription();
		this.fetchBilledUserPermissions();
		this.fetchUserGroup();

		if (this.canSetUserType) {
			this.visibleColumns.push("assetType");
		}
	}

	private get formattedAssetTypesById() {
		return this.assetTypesList?.reduce((acc, {assetTypeId, title}) => ({
			...acc,
			[assetTypeId]: title
		}), {}) ?? {}
	}

	private fetchUserGroup(): void {
		api.getUserGroup().then(userGroups => {
			this.originalUserGroups = userGroups
			this.userGroupLookup = userGroups.reduce((acc, ug) => {
				return {
					...acc,
					[ug.title]: ug.userGroupId
				}
			}, {})
		})
	}

	private get isBillingEnabled(): boolean {
		return get(this.featuresList, ["Billing"]);
	}

	private async deleteActionMessages(userToDelete) {
		if (!this.isBillingEnabled) {
			return;
		}

		if (!userToDelete || !userToDelete.userID || userToDelete.permissionGroupIds.length == 0) {
			return;
		}

		let permissionsForUserGroups = await api.getPermissionsForUserGroups(userToDelete.permissionGroupIds.map(x => x.key));
		let selectedUserGroupPermissions = permissionsForUserGroups.map(permission => permission.permissionId);

		let operatorPermissionId = this.billedUserPermissions.find(up => up.title === "Operators").permissionID;
		let guardPermissionId = this.billedUserPermissions.find(up => up.title === "Mobile Officers").permissionID;

		let userHasOperatorPermissions = selectedUserGroupPermissions.includes(operatorPermissionId);
		let userHasGuardPermissions = selectedUserGroupPermissions.includes(guardPermissionId);

		let result = [];
		if (userHasOperatorPermissions) {
			result.push(`Reduce the number of Operators used by your subscription by 1`);
		}
		if (userHasGuardPermissions) {
			result.push(`Reduce the number of Mobile Officers used by your subscription by 1`);
		}

		return result;
	}

	private async inviteUser(userId: number): Promise<void> {
		if (this.isSendingInvite) {
			return;
		}
		try {
			this.isSendingInvite = true;
			this.userToInvite = userId;
			await api.inviteUser(userId);
			let invitedUser = this.userList.find(u => u.userID === userId);
			invitedUser.registrationStatus = "Invited";
			this.isSendingInvite = false;
			this.userToInvite = 0;
		}
		catch (ex) {
			this.$notify({
				type: "error",
				title: "Failed to invite User",
				text:
					"Something when wrong sending user invite - please try again later, or contact support if the problem persists."
			});
		}
	}

	private get defaultFile() {
		const modalItems = this.uniqueModalItemsByTitle;
		const headers = modalItems.filter(({ csvExclude }) => !csvExclude).map(({ title }) => title)
		const body = headers.map( v => '')
		return {
			name: 'UserTemplate.csv',
			data: `${headers.join()}\r\n${body.join()}`
		}
	}

	private onCloseBulk(): void {
		this.resetProgress()
	}

	private resetProgress(): void {
		this.progressBulk = {}
	}

	private async bulkOnSubmit({ formData }): Promise<void> {
		await this.onSaveBulk({ formData })
		await this.updateData(this.mostRecentSearchParams);
	}

	private prepareDataBulk(formData) {
		return formData.map(user => {
			const localUser = transform(user, (result, value, key) => {
				if(value === '' || isArray(value) && value.length === 0) return
				result[key] = value
			}, {}) as { permissionGroupIds };

			if(!isArray(localUser.permissionGroupIds)) {
				localUser.permissionGroupIds = [localUser.permissionGroupIds]
					.filter(v => v)
					.map(key => this.userGroupLookup[key])
			} else {
				localUser.permissionGroupIds = localUser.permissionGroupIds.map(key => this.userGroupLookup[key])
			}

			if(localUser.permissionGroupIds.length === 0) delete localUser.permissionGroupIds

			return this.convertDataToRest(localUser)
		})
	}

	private convertDataToRest(userToAdd) {
		if (this.isADChecked) {
			delete userToAdd.useAD;
			userToAdd.password = "-";
		}

		userToAdd.groupID = parseInt(this.areasByTitle[userToAdd.groupID]);

		if (this.emailAsUser) {
			userToAdd.email = userToAdd.username;
		}

		return userToAdd
	}

	private async onSaveBulk({ formData }): Promise<void> {
		const max = formData.length
		let counterSucceed = 0;
		let progress: any = {
			max,
			value: 0,
			status: 'init',
			successfulMessage: `Successfully added ${max} Users`,
		}

		this.progressBulk = {
			...progress
		}

		const convertedData = this.prepareDataBulk(formData)

		const users = convertedData.map(async (user) => {
				const localContact = cloneDeep(user)
				const id = localContact.rowId


				return api.createUser(user)
					.then(() => {
						progress.rowIndex = id
						counterSucceed += 1
						progress.value = progress.value + 1

						this.progressBulk = {
							...progress,
							status: counterSucceed === max ? 'succeed' : 'progress',
						}

					})
					.catch(() => {
						const failedContact = convertedData.find(({ rowId }) => rowId === id)
						this.progressBulk = {
							...progress,
							rowIndex: id,
							status: 'error',
							error: {
								name: failedContact.fullName,
								message: `Bulk upload has failed to add user ${failedContact.fullName}`
							}
						}
					})
			}
		)

		await Promise.all(users)
	}


	private updateColumnFilters(column: TableHeader): void {
		let visibleColumn = this.visibleColumns.find(c => c === column.key);
		if (visibleColumn) {
			this.visibleColumns.remove(column.key);
		} else {
			this.visibleColumns.push(column.key);
		}
	}
}
