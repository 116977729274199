
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class MobileRaisesLinksControl extends Vue {
	@Prop() disabled: boolean;
	@Prop() raisesNumber: number;
	@Prop() isValid: boolean

	private onClick() {
		this.$emit("click");
	}
}
