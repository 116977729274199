
import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AssetMapLayerItem, ActionType, ActionRequest } from "./types";
import { namespace } from "vuex-class";
const Mobile = namespace("mobile");

const maxUnackedCount = 99;

@Component({
	components: { scrollbar: VuePerfectScrollbar }
})
export default class MobileAssetList extends Vue {
	@Prop() assets!: AssetMapLayerItem[];
	@Prop() assetTypes!: Map<string, any>;
	@Prop() selectedAssetID: number | null;
	@Ref() bTable: any;
    @Prop() settingLocation: boolean | false;
	@Prop({default: false, type:Boolean}) mobileMode;


	@Mobile.Mutation toggleRightSideBarVisibility: () => void;

	private localSet: boolean = false;
	private filter: string = "";
	private fields = [
		{
			key: "title",
			label: "Name",
			sortable: true,
		},
		{
			key:"type",
			label:"Type",
			sortable: true
		},
		{
			key:"status",
			label:"Status",
			sortable: true
		},
	];

	private get filteredAssets() {
		const assetList = this.assets.filter(ast => !ast.isTrackedAsset).map(asset => { return {
				...asset,
				type: this.getTypeTitle(asset)
			}});

		if (this.filter !== "") {
			return assetList.filter(u => u.title.toLowerCase().includes(this.filter.toLowerCase()))
		}
		return assetList;
	}

	private getColor(item: AssetMapLayerItem) : string {
		if(item.iconColor)
			return item.iconColor;

		if(!this.assetTypes || (this.assetTypes && !this.assetTypes.get))
			return this.mobileMode ? "" : "#000000";

		return this.assetTypes.get(item.assetTypeId.toString()).iconColor
				? this.assetTypes.get(item.assetTypeId.toString()).iconColor
				: ( this.mobileMode ? "": "#000000")
	}

	private onRowClicked(item: AssetMapLayerItem) {
		this.$emit("itemSelected", item);
	}

	private rowClass(item: AssetMapLayerItem) : string[] {
		if (item.assetId === this.selectedAssetID) {
			return [ (this.mobileMode ? "mobileSelectedRow" : "table-primary"), "cursor-pointer"];
		}
		return ["cursor-pointer"];
	}

	public isSettingLocation(currentlySetting: boolean){
		this.localSet = currentlySetting;
	}

	@Emit("setAction")
	private setAction(asset: AssetMapLayerItem, action: ActionType): ActionRequest {
		if(this.mobileMode){
			this.toggleRightSideBarVisibility();
			return;
		}

		switch(action){
			case ActionType.ShowLocation: asset.hideOnMap = false; break;
			case ActionType.HideLocation: asset.hideOnMap = true; break;
		}

		this.onRowClicked(asset);
		return {
			asset : asset,
			action : action,
			item : asset
		};
	}

	private getTypeTitle(item: AssetMapLayerItem) : string{
		var typeTitle;
		try{
			typeTitle = this.assetTypes.get(item.assetTypeId.toString()).title ? this.assetTypes.get(item.assetTypeId.toString()).title : "";
		} catch {
			typeTitle = "";
		} finally {
			typeTitle ? typeTitle : "";
		}

		return typeTitle;
	}

	private limitUnackedCount(unackedCount: number) {
		return unackedCount > maxUnackedCount ? maxUnackedCount : unackedCount;
	}
}
