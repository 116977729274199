
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ShareWithPerson, EventDetails } from "@/store/site-monitor/types";
import { DeviceService } from "@sureview/camera";
import { formatDateMixin } from "@/mixins";
import Multiselect from "vue-multiselect";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { SessionResource } from "@/store/sessions/types";
import api from "@/services/api.service";
import { DeviceDetails } from "@/store/devices/types";

const Tasks = namespace("tasks");
const SiteMonitor = namespace("siteMonitor");
const SessionStore = namespace("sessions");
const Devices = namespace("devices");

const { displayDateFromISOString } = formatDateMixin.methods;

@Component({
	components: {
		multiselect: Multiselect,
		VuePerfectScrollbar: VuePerfectScrollbar
	},
	mixins: [validationMixin],
	validations: {
		template: {
			subject: {
				required
			},
			message: {
				required
			}
		},
		totalUsers: {
			required,
			minLength: minLength(1)
			// name: {
			// 	required
			// },
			// email: {
			// 	required
			// },
			// telephone: {
			// 	required
			// }
		}
	}
})
export default class NotificationTask extends Vue {
	@SessionStore.Getter getSession: any;
	@SessionStore.Action updateSession: any;
	@SiteMonitor.Action eventShareSearch: any;
	@SiteMonitor.Action createAuditRecord: any;
	@SiteMonitor.Getter shareWithPersons: ShareWithPerson[];
	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;
	@Devices.State("device") private deviceFromStore: DeviceDetails;
	@Tasks.Action fetchTemplateDetails: any;

	@Prop(String) taskText: string;
	@Prop(Object) taskData: object;
	@Prop(String) result: string;

	public isSearching: boolean = false;
	public isSending: boolean = false;
	public showAdvanced: boolean = false;
	public showUserSearch: boolean = false;
	public selectedUsers: any[] = [];
	public serverId: number = null;
	public templateId: number = null;
	public notificationName: string = null;
	public template: any = {};
	public templateUsers: any[] = [];

	get totalUsers() {
		return this.templateUsers.concat(this.selectedUsers);
	}

	get searchedUsers() {
		return this.shareWithPersons.filter(x => !this.templateUsers.map(y => y.userID).includes(x.userID));
	}

	async eventShareFind(query: any) {
		this.isSearching = true;
		try {
			await this.eventShareSearch(query);
			this.isSearching = false;
		} catch (e) {
			this.isSearching = false;
		}
	}

	removeUser(userId: number) {
		this.templateUsers = this.templateUsers.filter(x => x.userID !== userId);
		this.selectedUsers = this.selectedUsers.filter(x => x.userID !== userId);
	}

	replaceKeywords(message: string) {
		return message
			.replace("{SiteName}", this.eventDetails.siteTitle)
			.replace("{EventName}", this.eventDetails.eventTitle)
			.replace("{EventTime}", displayDateFromISOString(this.eventDetails.created))
			.replace("{EventID}", this.eventDetails.eventID.toString());
	}

	async sendNotification() {
		this.$v.$reset();
		this.$v.$touch();

		if (!this.isSending && !this.$v.template.$error && !this.$v.totalUsers.$error) {
			this.isSending = true;
			let deviceService = new DeviceService(api.getDeviceServiceAddress(), this.eventDetails?.devicesEndpoint);
			let authSessionID = this.getSession(SessionResource.DeviceServiceSession);
			let recipients = [];

			this.totalUsers.forEach((user: any) => {
				recipients.push({
					Fullname: user.name,
					ExternalID: user.externalID,
					Email: user.email,
					Telephone: user.telephone
				});
			});

			const message = {
				Content: this.replaceKeywords(this.template.message),
				Subject: this.replaceKeywords(this.template.subject),
				Parameters: this.template.parameters
			};

			try {
				await this.createAuditRecord({
					eventId: this.eventDetails.eventID,
					eventRecordTypeId: 41,
					details: `Sending notification to Server with ID ${this.serverId}`
				});

				await deviceService.sendNotification(authSessionID, this.serverId, recipients, message);

				this.$emit("complete");

				await this.createAuditRecord({
					eventId: this.eventDetails.eventID,
					eventRecordTypeId: 41,
					details: `Completed sending notification to Server with ID ${this.serverId}`
				});
			} catch (error) {
				console.error(error);
				alert("There has been an error sending this notification. Please try again later or contact support.");
				await this.createAuditRecord({
					eventId: this.eventDetails.eventID,
					eventRecordTypeId: 41,
					details: `Failed sending notification to Server with ID ${this.serverId}`
				});
			}

			this.isSending = false;
		}
	}

	created() {
		this.updateSession({ resourceId: SessionResource.DeviceServiceSession });
	}

	async mounted() {
		if (this.taskData) {
			if (Object.keys(this.taskData).length === 3) {
				this.notificationName = this.taskData["title"];
				this.serverId = this.taskData["serverId"];
				this.templateId = this.taskData["templateId"];
			}
		}

		if (this.templateId) {
			this.template = await this.fetchTemplateDetails(this.templateId);
			this.templateUsers = this.template.users.map(x => ({
				userID: x.userID,
				name: x.fullName,
				email: x.email,
				telephone: x.telephone,
				externalID: x.externalID,
				default: true
			}));
		}
	}
}
