
































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";

import UserDetails from "@/components/mobile/UserDetails.vue";
import { MobileFeatures } from "@/store/mobile/types";
import { FeaturesList, UserPermissions } from "@/store/types";
import { AssetStatusInfo, AssetMapLayerItem } from '@/components/mobile-assets/types';
import vselect3 from "vselect3";
import api from '@/services/api.service';
import AssetStatusInput from '../assets/AssetStatusInput.vue';
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const Mobile = namespace("mobile");

@Component({
	components: {
		"user-details": UserDetails,
		"v-select3" : vselect3,
        "asset-status-input" : AssetStatusInput,
		"scrollbar": VuePerfectScrollbar
	}
})
export default class LeftSideBar extends Vue {
	@Getter getPermissions: UserPermissions;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getUserId: number;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getMapStyle") mapStyle: string;
	@Mutation setMapStyle: (style: string) => void;

	@Mobile.State isRightSideBarOpen: boolean;
	@Mobile.State isLeftSideBarOpen: boolean;
	@Mobile.State("MobileFeatures") mobileFeatures: MobileFeatures;
	@Mobile.State("UserMapOnlineStatus") userMapOnlineStatus: boolean;
	@Mobile.State("NavigationMode") navigationMode: boolean;
	@Mobile.State("UserAssetMapItem") private userMapStatus: AssetMapLayerItem;
	@Mobile.Mutation setShowDirections: (showDirections: boolean) => void;
	@Mobile.Mutation toggleLeftSideBarVisibility: () => void;
	@Mobile.Mutation("setNavigationMode") setNavigationMode: (mode: string) => void;
	@Mobile.Action("loadUserMapOnlineStatus") loadUserMapOnlineStatus: (userId: number) => Promise<void>;
	@Mobile.Action("setUserMapOnlineStatus") toggleUserOnlineStatus: (online: boolean) => void;
	@Mobile.Action loadUserAssetMapItem:() => Promise<void>;

	public showUnavailablePicker: boolean = false;
	public showBreakCard: boolean = false;
	public pauseAssetPolling: boolean = false;

	public directionsToggle: boolean = true;
	private onlineToggle: boolean = true;
	private requestPending: boolean = false;
	private assetStatusPollingTimeout: NodeJS.Timeout = null;
	private pollAssetPauseTimeout: NodeJS.Timeout = null;

	//Asset properties
	private assets: AssetStatusInfo[] =
    [
        {
            assetId: 0,
            title: "You do not have any assets",
            status: "",
            statusNote: ""
        }
    ];

    private currentAsset: AssetStatusInfo = {
        assetId: 0,
        status: "",
        statusNote:"",
        title: ""
    }
    private selectedAssetIds: number[] = [];
    private selectedAssetsValue: AssetStatusInfo[] = [];
	//Asset properties - end

	private get isMobileEventShareEnabled(): boolean {
		return (this.getFeature(["Mobile", "FieldOps", "EventShare"]) && this.userMapStatus && this.userMapStatus.shareable) ;
	}

	private get assetStatusPollingEnabled(): boolean {
		return this.getFeature(["Mobile", "AssetStatusPolling"]);
	}

	@Watch("onlineToggle")
	private userMapStatusToggle(newValue: boolean): void {
		this.toggleUserOnlineStatus(newValue);
	}

	@Watch("userMapOnlineStatus")
	private userMapOnlineStatusWatch(): void {
		this.onlineToggle = this.userMapOnlineStatus;
	}

	private async mounted(): Promise<void> {
		this.toggleUserOnlineStatus(true);
        this.loadAssets();

		// If feature flag is off then don't try and poll assets.
		if (!this.assetStatusPollingEnabled) {
			return;
		}

		await this.pollAssets();
	}

	private async pollAssets(): Promise<void>
	{
		if(this.assetStatusPollingTimeout != null && this.showLeftSidebar && !this.pauseAssetPolling) {
			await this.loadAssets();
			await this.loadUserAssetMapItem();
		}

		this.assetStatusPollingTimeout = setTimeout(this.pollAssets, 2000);
	}

	private destroyed(): void {
		if (this.assetStatusPollingTimeout){
			clearTimeout(this.assetStatusPollingTimeout);
			this.assetStatusPollingTimeout = null;
		}

		if (this.pollAssetPauseTimeout){
			clearTimeout(this.pollAssetPauseTimeout);
			this.pollAssetPauseTimeout = null;
		}
	}

	private canUpdateUserStatus() : boolean {
		return (this.featuresList.MobileUsers && this.$refs.userDetails)
			? !(this.$refs.userDetails as any).userStatusUpdateDisabled()
			: false;
	}

	private async updateUserAndOrAssets(): Promise<void> {
		if(this.requestPending){
			return;
		}

		try {
			if(!this.assetStatusDisabled()){
				this.requestPending = true;
				await this.setAssetStatus();
				setTimeout(() => this.requestPending = false, 1000);
			}
		} catch (ex){
			console.log(ex);
		}

		if (this.canUpdateUserStatus()) {
			await (this.$refs.userDetails as any).updateUserMapStatus();
		}
	}

	private updateDisabled(): boolean {
		if(!this.assetStatusDisabled() || this.canUpdateUserStatus() || this.requestPending){
			return false;
		}
		return true;
	}

	private scrollToElement(elementId: string): void {
		document.getElementById(elementId).scrollIntoView();
	}

	@Watch("isLeftSideBarOpen")
	private onLeftSideBarChanged() : void {
        this.loadAssets();
		this.pickUnavailable(false);
	}

	@Watch("directionsToggle")
	private onDirectionsToggleChanged(value: boolean, oldValue: boolean): void {
		this.setShowDirections(value);
	}

	@Watch("currentAsset.statusNote")
	private onCurrentAssetStatusNoteChanged(newVal: string, oldVal: string): void {
		if (oldVal != newVal) {
			this.suspendAssetPolling();
		}
	}

	@Watch("currentAsset.status")
	private onCurrentAssetStatusChanged(newVal: string, oldVal: string): void {
		if (oldVal != newVal) {
			this.suspendAssetPolling();
		}
	}

	public suspendAssetPolling(): void {
		if (this.pollAssetPauseTimeout){
			clearTimeout(this.pollAssetPauseTimeout);
		}

		this.pauseAssetPolling = true;
		this.pollAssetPauseTimeout = setTimeout(() => this.pauseAssetPolling = false, 5000);
	}

	public pickUnavailable(show: boolean): void {
		this.showUnavailablePicker = show;
	}

	public async logout(): Promise<void> {

		const value = await this.$bvModal.msgBoxConfirm(
			"Are you sure you want to log out?", {
				title: "Logout",
				okTitle: "CONFIRM",
				cancelTitle: "CANCEL"
			});

		if (!value) {
			return;
		}

		this.toggleUserOnlineStatus(false); // Set the user to offline
		this.$emit("logout"); // change the interface once logged out
	}

	private get showRightSidebar(): boolean {
		return this.isRightSideBarOpen && !this.isLeftSideBarOpen;
	}

	private get showLeftSidebar(): boolean {
		return this.isLeftSideBarOpen && !this.isRightSideBarOpen;
	}

	private get isFieldOpsEnabled(): boolean {
		return this.featuresList.Mobile.FieldOps && (this.getPermissions.canViewFieldOps || this.getPermissions.canEditFieldOps);
	}

	//Asset Methods
	private get multipleAssetsSelected() : boolean {
        if(this.selectedAssetIds && this.selectedAssetIds.length > 1){
            return true;
        }
        return false;
    }

	private async loadAssets(): Promise<void>
	{
        var assets = await api.getMapLayerAssets(true);

        if(assets)
		{
            this.assets = assets.filter(ast => !ast.isTrackedAsset);

			//If we have selected assets we need to re-assign the observer values
			if(this.selectedAssetsValue && this.selectedAssetsValue.length > 0){
				const selectedAssets = [...this.selectedAssetsValue];
				this.selectedAssetsValue = [];
				selectedAssets.forEach(asset =>
				{
					const assetObsObj = this.assets.find(x => x.assetId === asset.assetId);
					if(assetObsObj){
						this.selectedAssetsValue.push(assetObsObj);
					}
				});
				this.selectedAssetIds = this.selectedAssetsValue.map(asset => asset.assetId);
        	} else {
				this.selectedAssetsValue = [];
				this.selectedAssetIds = [];
				this.currentAsset = {
				    assetId: 0,
				    status: "",
				    statusNote:"",
				    title: ""
				};
			}
		}
	}

	private async setAssetStatus(): Promise<void> {
        if(this.multipleAssetsSelected){
            await api.setMultiMapLayerAssetStatuses(
                this.currentAsset.status,
                this.currentAsset.statusNote,
                this.selectedAssetIds);
        } else {
            const assetupdate = {
                assetId: this.currentAsset.assetId,
                status: this.currentAsset.status,
                statusNote:this.currentAsset.statusNote
            };

            await api.setAssetStatus(assetupdate);
        }
        this.loadAssets();
        (this.$refs.recentStatuses as any).reloadStatusList();
    }

	private assetStatusDisabled(): boolean {
        //If we can find the asset it hasn't been changed
        var result = this.assets.find(
            x => x.assetId === this.currentAsset.assetId
                    && x.status === this.currentAsset.status
                    && x.statusNote === this.currentAsset.statusNote
        );

		//If single asset and found match, disable or if note and status are whitespace
		if((!this.multipleAssetsSelected && result) || !this.currentAsset.status || !this.currentAsset.status.trim()){
			return true;
		}

		//If any of the multiple assets don't match enable the control
		if(this.multipleAssetsSelected){
			let assetStatusesDontMatch: boolean = this.selectedAssetsValue.some(asset => {
				if(asset.statusNote !== this.currentAsset.statusNote
					|| asset.status !== this.currentAsset.status){
						return true;
					}
			});
			return !assetStatusesDontMatch;
		}

        return false;
    }

	@Watch("selectedAssetsValue", { deep: true })
    private async assetChanged(): Promise<void> {
        //If only 1 asset has been selected use the single asset logic
        if(this.selectedAssetsValue && this.selectedAssetsValue.length === 1){
            this.currentAsset = {...this.selectedAssetsValue[0]};
            this.selectedAssetIds = [];
        }
        else if (this.selectedAssetsValue && this.selectedAssetsValue.length > 1) {
			//If multiple assets selected map the items and clear the currentAsset Model
			this.selectedAssetIds = this.selectedAssetsValue.map(x => x.assetId);

			//If they have the same status and note we can show
			const firstStatus = this.selectedAssetsValue[0].status;
			const firstStatusNote = this.selectedAssetsValue[0].statusNote;
			let assetStatusesDontMatch: boolean = this.selectedAssetsValue.some(asset => {
				if(asset.statusNote !== firstStatusNote
					|| asset.status !== firstStatus){
						return true;
					}
			});

			if(assetStatusesDontMatch){
				this.currentAsset = {
					assetId: 0,
					status: "",
					statusNote:"",
					title: ""
				};
			} else {
				this.currentAsset = {
					assetId: 0,
					status: firstStatus,
					statusNote:firstStatusNote,
					title: ""
				}
			}
        } else {
            //If null, wipe out everything
            this.selectedAssetIds = [];
            this.currentAsset = {
                assetId: 0,
                status: "",
                statusNote:"",
                title: ""
            };
        }
    }
	//Asset methods - end
}
