
import { Component, Watch, Vue } from "vue-property-decorator";
import { namespace, Getter, Action } from "vuex-class";
import StarredCameras from "@/components/mobile/live/StarredCameras.vue";
import { StarredDevice } from "@/store/mobile/types";
import { ICamera } from "@/store/views/types";
import { SessionResource } from "@/store/sessions/types";

const SessionStore = namespace("sessions");
const Mobile = namespace("mobile");

@Component({
	components: {
		"starred-cameras": StarredCameras
	}
})
export default class CameraPlayer extends Vue {
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@SessionStore.Action updateSession: any;

	@Mobile.Action toggleDeviceStarred: (devicedId: number) => void;

	@Mobile.Getter("getLiveDeviceId") liveDeviceId: number;
	@Mobile.State("StarredDevices") starredDevices: StarredDevice[];
	@Mobile.State(state => state.LiveDevice) camera: ICamera;
	@Mobile.Action loadStarredDevices: () => Promise<void>;

	$refs!: {
		camera: any;
	};

	public get isMobileLiveCamerasEnabled() {
		return this.getFeature(["Mobile", "Live"]);
	}

	async mounted() {
		if(this.isMobileLiveCamerasEnabled)
		{
			this.loadStarredDevices();
		}
	}

	@Watch("liveDeviceId")
	private async onLiveDeviceIdChanged(value: number, oldValue: number) {
		await this.updateSession({ resourceId: SessionResource.DeviceServiceSession });
		this.$refs.camera.stop();
		this.$refs.camera.startCamera(value);
	}

	public isStarredDevice() {
		return this.starredDevices.some(device => device.deviceID == this.liveDeviceId);
	}

	public async toggleStarredDevice() {
		await this.toggleDeviceStarred(this.liveDeviceId);
	}
}
