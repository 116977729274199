import { Schedule, ScheduleState, ScheduleType } from "./types";

export function getDefaultState(): ScheduleState {
	return {
		isLoading: false,
		schedules: [] as Schedule[],
		tempSchedule: null,
		scheduleTypes: [] as ScheduleType[],
		isCreateModalVisible: false,
		isDeleteConfirmationVisible: false,
		isReloadRequired: false,
		selectedScheduleId: null,
		selectedScheduleStart: null,
		searchString: "",
		selectedGroup: 0,
		selectedScheduleTypes: [],
		enabledScheduleFilter: true,
		areaTimeZoneId: null,
	};
}
