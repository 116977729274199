
import { Component, Vue } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import NavHeader from "@/components/NavHeader.vue";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { ModalItem } from "@/components/table/generic-update-modal.vue";
import api from "@/services/api.service";
import EventOutcomeSelect from "@/components/form/EventOutcomeSelect.vue";
//@ts-ignore
import TextHighlight from "vue-text-highlight";
import { UserPermissions } from "@/store/types";
import { CaseType } from "@/store/case-management/types";
import CaseTypeSelect from "@/components/form/CaseTypeSelect.vue";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import EventCategory from "@/types/sv-data/events/EventCategory";

const GenericTableStore = namespace("GenericTable");
const Eventqueue = namespace("eventqueue");
const CaseManagement = namespace("caseManagement");

@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable,
		"text-highlight": TextHighlight
	}
})
export default class EventCategorySetup extends Vue {
	@Getter getPermissions: UserPermissions;
	@Getter getFeature: (featuresList: string[]) => boolean;
	@Getter getUserTenantGroupId: number;
	@GenericTableStore.Getter getModalRow: any;
	@GenericTableStore.Mutation setModalRow: any;
	@CaseManagement.State caseTypesForUser: CaseType[];
	@CaseManagement.Action fetchCaseTypesForUser: () => void;

	private eventCategoriesList: EventCategory[] = [];
	private caseTypesForUserObj: object = {};
	private isLoading: boolean = false;
	private caseManagementLoaded: boolean = false;

	private async mounted() {
		if (!this.isEventCategorySetupEnabled) {
			this.$router.go(-1);
		}

		await this.updateState();

		if (this.getFeature(["CaseManagement"])) {
			await this.fetchCaseTypesForUser();

			// Build object for O(1) look up when populating
			// title via ID in generic table row.
			this.caseTypesForUser.forEach((caseType) => {
				this.caseTypesForUserObj[caseType.id] = caseType.title;
			});
			this.caseManagementLoaded = true;
		}
	}

	private async updateState(): Promise<void> {
		try {
			this.isLoading = true;
			this.eventCategoriesList = await api.fetchEventOutcomesForUser();
		} catch (ex) {
			console.log("Unexpected error updating component state: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private get isEventCategorySetupEnabled(): boolean {
		return this.getFeature(["EventCategorySetup"]) && this.getPermissions.canEditEventCategorySetup;
	}

	private get isCaseManagementEnabled(): boolean {
		return this.getFeature(["CaseManagement"]) && this.caseManagementLoaded;
	}

	private get isManualTourEnabled(): boolean {
		return this.getFeature(["Alarms", "EventQueue", "ManualTour"]);
	}

	private get isQuickEndSettingsEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "QuickEnd"]);
	}
	
	private get isParentSelected(): boolean {
		return !(this.getModalRow != null && this.getModalRow.parentID != null);
	}

	private getTitleForTemplateId(templateId: string) {
		if (templateId && this.caseTypesForUserObj[templateId]) {
			return this.caseTypesForUserObj[templateId];
		}
		return "";
	}

	private async addOutcome(outcome: EventCategory): Promise<void> {
		if (outcome) {
			try {
				const eventOutcome: EventCategory = {
					eventOutcomeID: outcome.eventOutcomeID,
					parentID: outcome.parentID,
					title: outcome.title,
					groupID: outcome.groupID ? outcome.groupID : -1,
					isMobileClose: outcome.isMobileClose ? outcome.isMobileClose : false,
					autoPreserve: outcome.autoPreserve ? outcome.autoPreserve : false,
					raiseDispatch: outcome.raiseDispatch ? outcome.raiseDispatch : false,
					raiseIncidentReport: outcome.raiseIncidentReport ? outcome.raiseIncidentReport : false,
					isActivityLog: outcome.isActivityLog ? outcome.isActivityLog : false,
					isAlarm: outcome.isAlarm ? outcome.isAlarm : false,
					isPatrol: outcome.isPatrol ? outcome.isPatrol : false,
					isIncident: outcome.isIncident ? outcome.isIncident : false,
					isOpen: outcome.isOpen,
					hidden: outcome.hidden,
					raiseToCasesTemplate: outcome.raiseToCasesTemplate
				};
				await api.createEventOutcome(eventOutcome);
				await this.updateState();
			} catch (ex) {
				console.log("Unexpected error adding Event Outcome: " + ex);
			} finally {
				this.isLoading = false;
			}
		}
	}

	private async updateOutcome(outcome: EventCategory): Promise<void> {
		if (outcome) {
			try {
				this.isLoading = true;
				await api.updateEventOutcome(outcome);
				await this.updateState();
			} catch (ex) {
				this.$notify({
					type: "error",
					title: "Error",
					text: ex.response.data
				});
			} finally {
				this.isLoading = false;
			}
		}
	}

	private async deleteOutcome(outcome: EventCategory): Promise<void> {
		if (outcome) {
			try {
				this.isLoading = true;
				await api.deleteEventOutcome(outcome);
				await this.updateState();
			} catch (ex) {
				console.log("Unexpected error deleting Event Outcome: " + ex);
			} finally {
				this.isLoading = false;
			}
		}
	}

	private getOutcomeLevel(level: number, outcome: EventCategory): number {
		if (!outcome || !outcome.parentID) {
			return level;
		} else {
			let parent = this.eventCategoriesList.find((a) => {
				return a.eventOutcomeID == outcome.parentID;
			});
			let newLevel = level + 1;
			return this.getOutcomeLevel(newLevel, parent);
		}
	}

	private showChildren(outcome: EventCategory): void {
		this.toggleTreeIconState(outcome.eventOutcomeID, "open");
		this.getChildren(outcome.eventOutcomeID).forEach((child) => {
			if (child.hasChildren) {
				this.showChildrenRecur(child);
			}
			child.hidden = false;
		});
	}

	private showChildrenRecur(outcome: EventCategory): void {
		if (outcome.hasChildren == true && outcome.isOpen == false) {
			return;
		}
		this.getChildren(outcome.eventOutcomeID).forEach((child) => {
			if (child.hasChildren) {
				this.showChildrenRecur(child);
			}
			child.hidden = false;
		});
	}

	private hideChildren(outcome: EventCategory): void {
		this.toggleTreeIconState(outcome.eventOutcomeID, "closed");
		this.getChildren(outcome.eventOutcomeID).forEach((child) => {
			if (child.hasChildren) {
				this.hideChildrenRecur(child);
			}
			child.hidden = true;
		});
	}

	private hideChildrenRecur(outcome: EventCategory): void {
		this.getChildren(outcome.eventOutcomeID).forEach((child) => {
			if (child.hasChildren) {
				this.hideChildrenRecur(child);
			}
			child.hidden = true;
		});
	}

	private toggleTreeIconState(outcomeId: number, state: string): void {
		let outcome = this.eventCategoriesList.find((a) => {
			return a.eventOutcomeID == outcomeId;
		});
		outcome.isOpen = state == "open";
	}

	private getChildren(outcomeId: number): EventCategory[] {
		return this.eventCategoriesList.filter((outcome) => {
			return outcome.parentID == outcomeId;
		});
	}

	private get TableHeadings(): TableHeader[] {
		return [
			{
				title: "Title",
				key: "title",
				order: 0,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Title of event outcome",
				searchable: true,
				visible: true,
				dataType: "text",
				useCustomCell: true,
				width: "20%",
				isTermLabel: true
			} as TableHeader,
			{
				title: "Area Name",
				key: "areaName",
				order: 1,
				sortOrder: 1,
				sortOrderReversed: false,
				description: "Area Name",
				visible: true,
				searchable: true,
				dataType: "text"
			} as TableHeader,
			{
				title: "Alarm",
				key: "isAlarm",
				order: 2,
				sortOrder: 2,
				sortOrderReversed: false,
				description: "Alarm",
				searchable: false,
				visible: true,
				dataType: "checkbox",
				useCustomCell: true
			} as TableHeader,
			{
				title: "Tour",
				key: "isPatrol",
				order: 3,
				sortOrder: 3,
				sortOrderReversed: false,
				description: "Tour",
				searchable: false,
				visible: this.isManualTourEnabled,
				dataType: "checkbox",
				useCustomCell: true
			} as TableHeader,
			{
				title: "Activity Log",
				key: "isActivityLog",
				order: 4,
				sortOrder: 4,
				sortOrderReversed: false,
				description: "Activity Log",
				searchable: false,
				visible: true,
				dataType: "checkbox",
				useCustomCell: true
			} as TableHeader,
			{
				title: "Preserve",
				key: "autoPreserve",
				order: 7,
				sortOrder: 7,
				sortOrderReversed: false,
				description: "Preserve",
				searchable: false,
				visible: true,
				dataType: "checkbox",
				useCustomCell: true
			} as TableHeader,
			{
				title: "Raise to Case Management",
				key: "raiseToCasesTemplate",
				order: 8,
				sortOrder: 8,
				sortOrderReversed: false,
				description: "Case Type",
				visible: true,
				disable: !this.isCaseManagementEnabled,
				searchable: true,
				dataType: "text",
				useCustomCell: true
			} as TableHeader
		];
	}

	private GetModalItems(): ModalItem[] {
		return [
			{
				title: "Title",
				key: "title",
				dataType: "text",
				placeholder: "Event Title",
				maxLength: 50,
				required: true
			},
			{
				title: "Parent Event Outcome",
				key: "parentID",
				dataType: "component",
				readOnly: false,
				data: EventOutcomeSelect,
				required: false,
				visible: true
			},
			{
				title: "Area",
				key: "groupID",
				dataType: "component",
				readOnly: false,
				defaultValue: this.getUserTenantGroupId,
				data: AreaTreeSelect,
				props: {
					reduce: a => a.id
				},
				required: this.isParentSelected,
				visible: true
			},
			{
				title: "Alarm",
				key: "isAlarm",
				dataType: "checkbox",
				defaultValue: true,
				visible: true,
				readOnly: false
			},
			{
				title: "Tour",
				key: "isPatrol",
				dataType: "checkbox",
				defaultValue: true,
				visible: this.isManualTourEnabled,
				readOnly: false
			},
			{
				title: "Activity Log",
				key: "isActivityLog",
				dataType: "checkbox",
				readOnly: false
			},
			{
				title: "Auto Preserve",
				key: "autoPreserve",
				dataType: "checkbox",
				readOnly: false
			},
			{
				title: "Case Type",
				key: "raiseToCasesTemplate",
				dataType: "component",
				readOnly: false,
				data: CaseTypeSelect,
				props: {
					caseTypes: this.caseTypesForUser
				},
				required: false,
				visible: this.isCaseManagementEnabled
			}

		] as ModalItem[];
	}

	private deleteActionMessages(): string[] {
		let deleteActionMessagesArr = [
			"Delete the Category so it can no longer be used by SureView Users",
			"Delete the Category Link for all history",
			`Historic Events using this category will change to "No Category Assigned"`,
		];
		if (this.isQuickEndSettingsEnabled) {
			deleteActionMessagesArr.push("Deleting the Category will remove it from any Quick End settings");
		}

		return deleteActionMessagesArr;
	}
	
	private deleteMessageAlternativeText: string = `Alternatively You can disable this category to retain the history by
		Unticking Event, Tour and Activity Log options in the category edit screen`;
}
