
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { UserPermissions } from '@/store/types';

const Mobile = namespace("mobile");

@Component
export default class MobileMenu extends Vue {
	@Getter getPermissions: UserPermissions;
	@Mobile.Mutation setMobileMode: (mode: string) => void;
	@Mobile.Action loadUserPermissions: () => Promise<void>;
	@Mobile.State(state => state.UserPermissions) permissions: {
		[permissionName: string]: number | undefined;
	};
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	private showMenu: boolean = false;

	private get fieldOpsEnabled(){
		return this.getFeature(["Mobile", "FieldOps"]) && (this.getPermissions.canViewFieldOps || this.getPermissions.canEditFieldOps);
	}

	private get canViewCameras() {
		return this.permissions && this.permissions.hasOwnProperty("canViewCameras") && this.permissions["canViewCameras"]
		&& this.liveCameraEnabled;
	}

	private get liveCameraEnabled() {
		return this.getFeature(["Mobile", "Live"]);
	}

	async mounted() {
		await this.loadUserPermissions();
		// This sets the order of what is loaded first, if they have permission
		if (this.fieldOpsEnabled) {
			this.setMobileMode("fieldOps")
		} else {
			this.setMobileMode("none");
		}
	}

	public async modeClicked(mode: string) {
		this.setMobileMode(mode);
		this.showMenu = false;
	}

	private triggerMenu(){
		if(!this.liveCameraEnabled){
			this.showMenu = false;
			return;
		}
		this.showMenu = !this.showMenu;
	}
}
