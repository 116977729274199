import { render, staticRenderFns } from "./MapSetup.vue?vue&type=template&id=06902793&scoped=true"
import script from "./MapSetup.vue?vue&type=script&lang=ts"
export * from "./MapSetup.vue?vue&type=script&lang=ts"
import style0 from "./MapSetup.vue?vue&type=style&index=0&id=06902793&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06902793",
  null
  
)

export default component.exports