
import NavHeader from "@/components/NavHeader.vue";
import SystemViewTreeNode from "@/types/sv-data/system-view/SystemViewTreeNode";
import SystemViewTree from "@/components/system-view/SystemViewTree.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import { FeaturesList, UserPermissions } from "@/store/types";
import { get } from "lodash";
import SetupOverview from '@/components/system-view/SetupOverview.vue';

const SystemHealth = namespace("systemHealth");

@Component({
	components:{
		SystemViewTree,
		NavHeader,
		"setup-overview": SetupOverview
	}
})
export default class SystemView extends Vue {
	@Getter getPermissions: UserPermissions;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@SystemHealth.Action retrieveHealthStatuses: (refreshCache: boolean) => Promise<void>;

	private selectedItem: SystemViewTreeNode = null;
	private canViewSystemView: boolean = false;

	private async mounted(): Promise<void> {
		this.canViewSystemView = this.getPermissions.canViewSystemView || this.getPermissions.isSystemAdmin;
		// if user does not have access take them to the event queue
		if (!this.isSystemViewEnabled || !this.canViewSystemView) {
			this.$router.push("/event-queue");
		}
		await this.retrieveHealthStatuses(true);
	}

	private updateSelectedItem(selectedItem: SystemViewTreeNode): void {
		this.selectedItem = selectedItem;
	}

	private get isSystemViewEnabled(): boolean {
		return get(this.featuresList, ["SystemView"]);
	}
}
