import { MutationTree } from "vuex";
import { DevicesState, DeviceDetails, ServerDetails,
	     DeviceTypeCounts,
		 ServerType, ServerLineProfile,
		 DeviceTypeIdentifier,
		 DeviceConfiguration,
		 DeviceConfigurationErrors,
		 SyncSystem,
		 ServerModalStartTab,
		 DevicePreset} from "./types";
import { getDefaultState } from "./state";
import Vue from "vue";

export const mutations: MutationTree<DevicesState> = {
	setCancelTokenSource(state, cancelTokenSource) {
		state.cancelTokenSource = cancelTokenSource;
	},
	setDoors(state, doors: DeviceDetails[]) {
		state.doors = doors;
	},
	setGettingConfig(state: DevicesState, gettingConfig: boolean) {
		state.gettingConfig = gettingConfig;
	},
	setServersList(state: DevicesState, serversList: ServerDetails[]): void {
		state.serversList = serversList;
	},
	setServerConfig(state: DevicesState, serverConfig: DeviceConfiguration[]): void {
		state.serverConfig = serverConfig;
	},
	setSettingConfig(state: DevicesState, settingConfig: boolean) {
		state.settingConfig = settingConfig;
	},
	setTotalServers(state: DevicesState, totalServers: number): void {
		state.totalServers = totalServers;
	},
	addServersNotAlreadyInStore(state: DevicesState, servers: ServerDetails[]): void {
		servers = servers.filter(
			newServer => !state.serversList.some(existingServer => existingServer.serverID === newServer.serverID)
		);

		state.serversList.push(...servers);
	},
	setFilteredServersList(state: DevicesState, data: ServerDetails[]): void {
		state.filteredServersList = data;
	},
	setExtraValue(state: DevicesState, data: string): void {
		state.currentServer.extraValue = data;
	},
	setCurrentServer(state: DevicesState, server: ServerDetails): void {
		state.currentServer = server;
	},
	setDeviceConfigErrors(state: DevicesState, errors: DeviceConfigurationErrors) {
		state.deviceConfigurationErrors = errors;
	},
	setServerModalVisible(state: DevicesState, isVisible: boolean) {
		state.serverModalOpened = isVisible;
	},
	addDeviceTypeCountsToCurrentServer(state: DevicesState, deviceTypeCounts: DeviceTypeCounts): void {
		Vue.set(state.currentServer, "deviceTypeCounts", deviceTypeCounts);
	},
	addAlarmCountsToCurrentServer(state: DevicesState, alarmCounts: number): void {
		Vue.set(state.currentServer, "alarms", alarmCounts);
	},
	addServer(state: DevicesState, data: ServerDetails): void {
		state.serversList.push(data);
	},
	updateServer(state: DevicesState, server: ServerDetails): void {
		let index = state.serversList.findIndex(s => s.serverID == server.serverID);

		if (index !== -1) {
			Vue.set(state.serversList, index, server);
		}
	},
	setDevices(state: DevicesState, devices: DeviceDetails[]): void {
		state.devices = devices;
	},
	setTotalDevices(state: DevicesState, totalDevices: number): void {
		state.totalDevices = totalDevices;
	},
	setDevice(state: DevicesState, device: DeviceDetails): void {
		state.device = device;
	},
	setCurrentDeviceTypeIdentifier(state: DevicesState, deviceTypeIdentifier: DeviceTypeIdentifier): void {
		state.currentDeviceTypeIdentifier = deviceTypeIdentifier;
	},
	setServerModalStartTab(state: DevicesState, tab: ServerModalStartTab): void {
		state.serverModalStartTab = tab;
	},
	setServerTypes(state: DevicesState, serverTypes: ServerType[]): void {
		state.serverTypes = serverTypes;
	},
	deleteServer(state: DevicesState, serverId: number): void {
		state.serversList = state.serversList.filter(x => x.serverID !== serverId);
		state.devices = state.devices.filter(x => x.serverID !== serverId);
	},
	deleteDevice(state: DevicesState, deviceId: number): void {
		state.devices = state.devices.filter(x => x.deviceID !== deviceId);
	},
	setDeviceTypeCounts(state: DevicesState, deviceTypeCounts: DeviceTypeCounts): void {
		state.deviceTypeCounts = deviceTypeCounts;
	},
	setAlarmCounts(state: DevicesState, alarmCounts: number): void {
		state.alarms = alarmCounts;
	},
	addDevice(state: DevicesState, device: DeviceDetails): void {
		state.devices.push(device);
	},
	updateDevice(state: DevicesState, device: DeviceDetails): void {
		let index = state.devices.findIndex(d => d.deviceID == device.deviceID);

		if (index !== -1) {
			Vue.set(state.devices, index, device);
		}
	},
	addDevicesNotAlreadyInStore(state: DevicesState, devices: DeviceDetails[]): void {
		devices = devices.filter(
			newDevice => !state.devices.some(existingDevice => existingDevice.deviceID === newDevice.deviceID)
		);

		state.devices.push(...devices);
	},
	updateSyncSystem(state: DevicesState, syncSystem: SyncSystem): void {
		let index = state.syncSystems.findIndex(s => s.syncSystemId == syncSystem.syncSystemId);

		if (index !== -1) {
			Vue.set(state.syncSystems, index, syncSystem);
		}
	},
	setDeleteChildDeviceModalVisibility(state: DevicesState, visible: boolean): void {
		state.deleteChildDeviceModalVisible = visible;
	},
	setEditChildDeviceModalVisibility(state: DevicesState, visible: boolean): void {
		state.editChildDeviceModalVisible = visible;
	},
	setCameraPreviewModalVisibility(state: DevicesState, visible: boolean): void {
		state.cameraPreviewModalVisible = visible;
	},
	setNotificationOptions(state: DevicesState, notificationOptions: NotificationOptions | null): void {
		state.notificationOptions = notificationOptions;
	},
	setServerLineProfiles(state: DevicesState, serverLineProfiles: ServerLineProfile[]): void {
		state.serverLineProfiles = serverLineProfiles;
	},
	setSyncSystems(state: DevicesState, syncSystems: SyncSystem[]): void {
		state.syncSystems = syncSystems;
	},
	setTotalSyncSystems(state: DevicesState, totalSyncSystems: number): void {
		state.totalSyncSystems = totalSyncSystems;
	},
	setDevicePresets(state: DevicesState, presets: DevicePreset[]) : void
	{
		state.device.presets = presets;
	},
	resetState(state: DevicesState): void {
		Object.assign(state, getDefaultState());
	},
};
