
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
	SituationalAwarenessEventQueue,
	SituationalAwarenessEventQueueActionRequest,
	SituationalAwarenessEventQueueActionResult,
} from "@/store/situational-awareness/types";
import api from "@/services/api.service";
import { namespace, Getter } from "vuex-class";
import EmailRawDataControl from "@/components/emails/EmailRawDataControl.vue";
import EmailRawDataModal from "@/components/emails/EmailRawDataModal.vue";

const FieldOpsStore = namespace("fieldOps");

interface SituationalAwarenessAlertConfirmation {
	actionType: "Promote" | "Dismiss",
	message: string,
	title: string,
	note: string
}

@Component({
	components: {
		'email-raw-data-control': EmailRawDataControl,
		'email-raw-data-modal': EmailRawDataModal
	},
})
export default class SituationalAwarenessAlertsControl extends Vue {

	@FieldOpsStore.Mutation private setSituationalAwarenessEventQueue: (situationalAwarenessEventQueue: SituationalAwarenessEventQueue[]) => void;
	@FieldOpsStore.Mutation private setSelectedSituationalAwarenessEvent: (situationalAwarenessEvent: SituationalAwarenessEventQueue) => void;
	@FieldOpsStore.State private situationalAwarenessEventQueue: SituationalAwarenessEventQueue[];
	@FieldOpsStore.State private selectedSituationalAwarenessEvent: SituationalAwarenessEventQueue;
	@FieldOpsStore.Action private loadSituationalAwarenessEventQueue: () => Promise<void>;

	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	@Prop({type: Boolean, default: false})
	private fieldOpsMode: boolean;

	private alertConfirmation: SituationalAwarenessAlertConfirmation = {
		actionType: null,
		title: "",
		message: "",
		note: ""
	}

	private showConfirmation: boolean = false;
	private selectedAlert: SituationalAwarenessEventQueue = null;
	private orderByDescending: boolean = true;
	private queueCount: number = 50;
	private queueCountIncrement: number = 50;

	private situationalEventQueueTimeout: NodeJS.Timeout = null;

	public mounted(): void {
		this.triggerEventQueueRefresh();
	}

	private get isRequireNoteEnabled(): boolean {
		return this.getFeature(["SituationalAwareness", "ExternalAlerts", "RequireNoteForPromoteAndDismiss"]);
	}

	private get requireNoteValidation(): boolean {
		if(!this.isRequireNoteEnabled){
			return false;
		}

		return this.alertConfirmation.note.length < 1
	}

	private triggerEventQueueRefresh() : void {
		let timeoutFunction = async function() {
			try {
				await this.updateSituationalAwarenessEventQueue();
			} catch(e) {
				console.error(e);
			}

			this.triggerEventQueueRefresh();
		}.bind(this);

		this.clearTimeout();
		this.situationalEventQueueTimeout = setTimeout(timeoutFunction, 5000);
	}

	private async updateSituationalAwarenessEventQueue() : Promise<void> {
		await this.loadSituationalAwarenessEventQueue();
	}

	private beforeDestroy(): void {
		this.clearTimeout();
		this.setSituationalAwarenessEventQueue([]);
	}

	private clearTimeout(): void {
		if (this.situationalEventQueueTimeout) {
			clearTimeout(this.situationalEventQueueTimeout);
		}
	}

	private showDismissAlertModal(situationalAwarenessEventQueueEntry: SituationalAwarenessEventQueue) : void {
		this.selectedAlert = situationalAwarenessEventQueueEntry;
		this.alertConfirmation = {
			actionType: "Dismiss",
			title: "Confirm Dismiss",
			message: `Please confirm you want to dismiss alert '${situationalAwarenessEventQueueEntry.description}' and remove it from the alerts queue`,
			note: ""
		}
		this.showConfirmation = true;
	}

	private showPromoteAlertModal(situationalAwarenessEventQueueEntry: SituationalAwarenessEventQueue) : void {
		this.selectedAlert = situationalAwarenessEventQueueEntry;
		this.alertConfirmation = {
			actionType: "Promote",
			title: "Confirm Promote",
			message: `Please confirm you want to promote alert '${situationalAwarenessEventQueueEntry.description}' to an event to be processed`,
			note: ""
		}
		this.showConfirmation = true;
	}

	private async handleAlert(situationalAwarenessEventQueueEntry: SituationalAwarenessEventQueue): Promise<void> {
		try {
			let request: SituationalAwarenessEventQueueActionRequest = {
				situationalAwarenessEventQueueId: situationalAwarenessEventQueueEntry.situationalAwarenessEventQueueId,
				note: this.alertConfirmation.note
			};

			let result: SituationalAwarenessEventQueueActionResult;

			if(this.alertConfirmation.actionType == "Promote"){
				result = await api.promoteSituationalAwarenessQueueEntry(request);
			} else if(this.alertConfirmation.actionType == "Dismiss"){
				result = await api.dismissSituationalAwarenessQueueEntry(request);
			}

			if (!result.success) {
				alert(result.message);
			}
			await this.updateSituationalAwarenessEventQueue();

		} catch(err) {
			console.error(`AlertControl - Error: ${err}`);
		}
	}

	private clearAlertConfirmation(): void {
		this.alertConfirmation = {
			actionType: null,
			title: "",
			message: "",
			note: ""
		}

		this.selectedAlert = null;
	}

	private goToLocationForAlert(situationalAwarenessEventQueueEntry: SituationalAwarenessEventQueue) : void {
		if(situationalAwarenessEventQueueEntry.situationalAwarenessEventQueueId == this.selectedSituationalAwarenessEvent?.situationalAwarenessEventQueueId){
			this.setSelectedSituationalAwarenessEvent(null);
		}
		this.setSelectedSituationalAwarenessEvent(situationalAwarenessEventQueueEntry);
	}

	// Only render alerts within the queueCount limit and set the order
	private get alertsForQueue(): SituationalAwarenessEventQueue[] {
		let alertQueue = [...this.situationalAwarenessEventQueue];

		// The queue is already ordered by date when retrieved so we only need to reverse, not sort manually
		alertQueue = this.orderByDescending ? alertQueue : alertQueue.reverse();

		// If the queueCount is more than the length of the array, it will just take the length of the array
		alertQueue = alertQueue.slice(0, this.queueCount);
		return alertQueue;
	}

	// When the user scrolls to the bottom of the alerts container, render more events if available
	private onScroll({ target: { scrollTop, clientHeight, scrollHeight }}): void {
		// Calculate scroll position to load more if scroll is at the bottom. Add 10 for a smoother experience
		if (scrollTop + clientHeight + 10 >= scrollHeight && this.situationalAwarenessEventQueue.length > this.queueCount) {
			this.queueCount += this.queueCountIncrement;
		}
	}

	// Calculate the number of alerts shown. If the queueCount exceeds the number of events, display the number of events
	private get alertsShown(): number {
		let alertCount = this.situationalAwarenessEventQueue.length > this.queueCount ? this.queueCount : this.situationalAwarenessEventQueue.length;
		return alertCount;
	}

	// When the alertsShown changes, emit the value to the fieldOps component to show on the alerts tab badge
	@Watch("alertsShown")
	public onAlertCountChange(): void {
		this.$emit("setAlertQueueSize", this.alertsShown);
	}

	// Toggle the order to display the events
	public toggleAlertOrder(): void {
		this.orderByDescending = !this.orderByDescending;
	}
}
