
import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import { namespace, Getter, Action } from "vuex-class";
import moment from "moment";
import MessageBlock from "./MessageBlock.vue";
import { MessageWithAttachment } from "@/store/guard-chat/types";
import { NewestAt } from "./NewestAt";
import { debounce } from "lodash";

const Mobile = namespace("mobile");
const GuardChat = namespace("guardChat");
// Sometimes scrollTop values get fractional value,
// which differs by pixel fraction from min/max ones when scrolled to top/bottom.
// Introduced 2px threshold during scroll position checks to determine
// scroll to top/bottom reliable.
const scrollThreshold = 2;

@Component({
	components: { MessageBlock }
})
export default class MessagesList extends Vue {
	@Getter getUserId: number;

	@Prop() messages: MessageWithAttachment[];
	@Prop() loadMessagesAction: () => Promise<void>;
	@Prop() newestAt: NewestAt;
	@Ref() readonly scrollContainer: HTMLDivElement;

	private timeOutId: number | undefined;

	private dataFetched: boolean = false;
	private stickToLastMessage: boolean = true;
	private scrollOffsetFromFirstMessage: number = 0;
	private scrollHandler = debounce(this.onScroll.bind(this), 250);
	private scrollUpdateTask: (() => void) | undefined;
	private processMessageUpdates: boolean = false;
	private fetchingUpdate: boolean = false;

	async created() {
		await Promise.all([this.loadMessagesAction()]);
		this.dataFetched = true;
		if (this.newestAt === "bottom") {
			await this.$nextTick();
			this.scrollToLastMessage();
		}
		this.setupMessagesUpdate();
	}

	private setupMessagesUpdate() {
		this.timeOutId = setInterval(async () => {
			if (!this.fetchingUpdate) {
				this.fetchingUpdate = true;
				await this.loadMessagesAction();
				this.fetchingUpdate = false;
			}
		}, 2000) as any;
	}

	private beforeDestroy() {
		if (this.timeOutId) {
			clearTimeout(this.timeOutId);
		}
		this.$emit("onBeforeDestroy");
	}

	private get getMessageBlocks() {
		try
		{
			if (this.messages.length === 0) {
				return [];
			}

			let uniqueMessages = this.messages.filter((message,index) => {
				return this.messages.findIndex(x => x.eventRecordId === message.eventRecordId) == index
			});
			let currentBlock = this.createBlockFromMessage(uniqueMessages[0]);
			const blocks = [currentBlock];

			for (let i = 1; i < uniqueMessages.length; i++) {
				if (
					uniqueMessages[i].author && uniqueMessages[i].author.userId === currentBlock.userId &&
					this.getAbsTimeDiff(uniqueMessages[i].createdAtUtc, currentBlock.createdAt) < 60000
				) {
					this.addElement(currentBlock.messages, {
						eventRecordId: uniqueMessages[i].eventRecordId,
						details: uniqueMessages[i].details,
						hasFiles: uniqueMessages[i].hasFiles
					});
				} else {
					currentBlock = this.createBlockFromMessage(uniqueMessages[i]);
					this.addElement(blocks, currentBlock);
				}
			}
			return blocks;
		} catch(ex){
			console.log(ex)
		}
		return [];
	}

	private addElement<T>(array: T[], element: T) {
		if (this.newestAt === "bottom") {
			array.push(element);
		} else {
			array.unshift(element);
		}
	}

	private getAbsTimeDiff(t1: string | Date, t2: string | Date) {
		return Math.abs(moment(t1 + "Z").diff(t2 + "Z"));
	}

	private createBlockFromMessage(message: MessageWithAttachment) {
		var author = message.author;
		if(!message.author) {
			author = {
				userId: -1,
				colorIndex: null,
				userName: "Unknown User",
				initials: ""
			}
		}
		return {
			eventId: message.eventId,
			userId: author.userId,
			isMine: this.getUserId === author.userId,
			userInitials: author.initials,
			colorIndex: author.colorIndex,
			userName: author.userName,
			messageType: message.messageType,
			createdAt: message.createdAtUtc,
			messages: [
				{
					eventRecordId: message.eventRecordId,
					details: message.details,
					hasFiles: message.hasFiles
				}
			]
		};
	}

	// would be accessed via ref in Chat
	public restoreScrolling() {
		this.scrollContainer.addEventListener("scroll", this.scrollHandler);
		this.restoreScrollPosition();
		this.processMessageUpdates = true;
	}

	// would be accessed via ref in Chat
	public storeScrolling() {
		this.storeScrollPosition();
		this.processMessageUpdates = false;
		this.scrollContainer.removeEventListener("scroll", this.scrollHandler);
	}

	private onScroll() {
		if (this.isScrolledToLastMessage()) {
			this.stickToLastMessage = true;
			this.$emit("onScrollToLastMessage");
		} else {
			this.stickToLastMessage = false;
			this.storeScrollPosition();
		}
	}

	private isScrolledToLastMessage() {
		const container = this.scrollContainer;
		if (this.newestAt === "bottom") {
			// https://stackoverflow.com/a/24356615
			return container.scrollTop >= container.scrollHeight - container.clientHeight - scrollThreshold;
		} else {
			return container.scrollTop <= scrollThreshold;
		}
	}

	private storeScrollPosition() {
		const container = this.scrollContainer;
		if (this.newestAt === "bottom") {
			this.scrollOffsetFromFirstMessage = container.scrollTop;
		} else {
			this.scrollOffsetFromFirstMessage = container.scrollHeight - container.clientHeight - container.scrollTop;
		}
	}

	@Watch("messages")
	private onMessagesUpdate() {
		if (this.processMessageUpdates) {
			this.scrollUpdateTask = this.updateScroll;
		}
	}

	private updateScroll() {
		if (this.stickToLastMessage) {
			this.scrollToLastMessage();
		} else {
			this.restoreScrollPosition();
		}
	}

	private updated() {
		if (this.scrollUpdateTask) {
			this.scrollUpdateTask();
			this.scrollUpdateTask = undefined;
		}
	}

	private restoreScrollPosition() {
		const container = this.scrollContainer;
		if (this.newestAt === "bottom") {
			container.scrollTop = this.scrollOffsetFromFirstMessage;
		} else {
			container.scrollTop = container.scrollHeight - container.clientHeight - this.scrollOffsetFromFirstMessage;
		}

		if (this.isScrolledToLastMessage()) {
			this.$emit("onScrollToLastMessage");
		}
	}

	public scrollToLastMessage() {
		const container = this.scrollContainer;
		if (!container) 
			return;

		const newScrollTop = this.newestAt === "bottom" ? container.scrollHeight - container.clientHeight : 0;
		if (container.scrollTop != newScrollTop) {
			// will fire 'scroll' event for container, which in turn will emit 'onScrollToLastMessage'
			container.scrollTop = newScrollTop;
		} else {
			// scroll event would not be fired, just emit 'onScrollToLastMessage'
			this.$emit("onScrollToLastMessage");
		}
	}
}
