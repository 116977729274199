import { MutationTree } from "vuex";
import { GenericTableState } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<GenericTableState> = {
	setModalRow(state, data: any[]) {
		state.GenericTableModalRow = data;
	},
	setRowHasChanges(state, hasChanges) {
		state.rowHasChanges = hasChanges;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
