
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace, Getter } from 'vuex-class';

const SessionStore = namespace('sessions');

@Component({
    components: {}
})
export default class Sessions extends Vue {
    // on the assumption that 0 can't be used as default value since it might be a valid ID,
    // else get rid of null in type and replace default value
    @Prop(Number)
    public resourceId!: number;

    @Prop(Number)
    public eventId!: number | null;

    public updateTimeout: NodeJS.Timer | null = null;

    @SessionStore.Action updateSession: any;
    @SessionStore.Mutation clearResourceSessionId: any;

    @Getter('getActiveRegion') activeRegion: any;

    public mounted() {
        this.update();
    }

    public destroyed() {
        if (this.updateTimeout != null) {
            clearTimeout(this.updateTimeout);
        }
    } 

    public async update() {
        // Remove the existing update timeout
        if (this.updateTimeout != null) {
            clearTimeout(this.updateTimeout);
        }

        if (this.resourceId != null && this.resourceId != undefined && this.eventId != undefined) {
            var responseStatus = await this.updateSession({ resourceId: this.resourceId, eventId: this.eventId });

            if (responseStatus == 401) {
                const unwatch = this.$store.watch(state => this.$store.getters.isLoggedIn, n => {
                    this.update();
                    unwatch();
                });
            } else {
                this.updateTimeout = setTimeout(() => { this.update() }, 60000);
            }
        } else {
            this.updateTimeout = setTimeout(() => { this.update() }, 1000);
        }
    }

    /**
     * Watches for when the current region is changed, clears out the current resource session 
     * ID and fetches a new key from the new region.
     * 
     * @todo:   We may not need this, however there have been situations during testing
     *          where the session keys are not immediately refreshed when changing regions. 
     *          This watcher ensures that we don't encounter that situation.
     */
    @Watch('activeRegion', { deep: true })
    public async onActiveRegionChanged(newRegion: any) {
        this.clearResourceSessionId(this.resourceId);
        await this.$nextTick();
        this.update();
    }
}
