
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";

import Sessions from "../components/Sessions.vue";
import AuditControl from "../components/AuditControl.vue";
import AreaCameras from "../components/AreaCameras.vue";
import { AuditService, MediaFile } from "../services/auditService";

@Component({
	components: {
		"audit-control": AuditControl,
		"area-cameras": AreaCameras,
		session: Sessions
	}
})
export default class Cameras extends Vue {
	$refs!: {
		camera: any;
	};

	public deviceSelected(camera: any) {
		this.$refs.camera.startCamera(camera.deviceID);
	}

	public mounted() {}
}
