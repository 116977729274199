
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";

import AuditControl from "@/components/AuditControl.vue";
import { FeaturesList } from "@/store/types";
import { EventDetails } from "@/store/site-monitor/types";
import { get } from "lodash";

/* import { AuditService, MediaFile } from "@/services/auditService"; */
// @note Breaking Vue reactivity when not using this.$set with
// nonprimitive data may cause errors & nasty bugs

const SiteMonitor = namespace("siteMonitor");
const SiteMonitorCameras = namespace("siteMonitorCameras");
const Tours = namespace("tours");

@Component({
	components: {
		"audit-control": AuditControl
	}
})
export default class MatrixContainer extends Vue {
	constructor() {
		super();

		this.created = Date.now().toString();
	}

	$refs!: {
		cameraControl: any;
		player: AuditControl;
		childElement: HTMLDivElement;
	};

	@Prop(Number) index?: any;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	@SiteMonitorCameras.Getter getLayoutIndex: any;
	@SiteMonitorCameras.Getter getAwaitingCamera: any;
	@SiteMonitorCameras.Getter("getAwaitingClip") awaitingClip: any;
	@SiteMonitorCameras.Getter("getLinkRecord") linkRecord: any;
	@SiteMonitorCameras.Getter getMatrixPushContents: any;
	@SiteMonitorCameras.Getter getMatrixContents: any;
	@SiteMonitorCameras.Getter getHighlightCellIndex: number | null;
	@SiteMonitorCameras.Getter("getWaitingForEvents") getWaitingForEvent: any;

	@SiteMonitorCameras.Action updateMediaMatrixContents: any;
	@SiteMonitorCameras.Mutation setAwaitingCamera: any;
	@SiteMonitorCameras.Mutation setAwaitingClip: any;
	@SiteMonitorCameras.Mutation setPushContents: any;

	@SiteMonitor.Getter("getIsController") isController: any;
	@SiteMonitor.Getter("getAuditService") auditService: any;

	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;

	@SiteMonitor.Mutation setActivity: () => void;

	@SiteMonitorCameras.Mutation setLinkRecord: any;
	@SiteMonitorCameras.Mutation setCameraLink: any;

	@Tours.Getter tourInProgress: any;
	@Tours.Getter getActiveCell: any;
	@Tours.Getter getPredefinedTour: any;

	public deviceId: number = 0;
	public deviceTitle: string | null = null;
	public created: string;
	public _camera: any = null;
	public _clip: any;
	public hasClip: boolean = false;
	public clipDetails: any = {};
	public highlight: boolean = false;
	private streamingData: boolean = false;
	private isActive: boolean = false;

	private get camera() {
		return this._camera;
	}

	private set camera(value: any) {
		if (value == undefined) {
			this._camera = null;
			this.deviceId = -2;
			this.deviceTitle = null;
		} else {
			if (value == null) {
				this.deviceId = -1;
				this.deviceTitle = null;
			} else {
				this.deviceId = value.objectId ? value.objectId : value.objectID;
				this.deviceTitle = value.title;
			}

			this._camera = value;
		}
	}

	private get clip() {
		return this._clip;
	}

	private set clip(value: any) {
		if (value == null) {
			this.hasClip = false;
			this.clipDetails = {};
		} else {
			this.hasClip = true;
			this.clipDetails = value;
		}

		this._clip = value;
	}

	private get isTourMaskInactiveCameras(): boolean {
		if (!this.tourInProgress || !this.getPredefinedTour) {
			return false;
		}
		const result = get(this.featuresList, ["Alarms", "MediaMatrix", "VideoTours", "MaskInActiveCamera"]);
		return result;
	}

	private get isCellActive() {
		if (!this.tourInProgress || this.getPredefinedTour == null) {
			return false;
		}
		if (this.getActiveCell.index == this.index && this.getActiveCell.isActive) {
			return true;
		}

		return false;
	}

	private get displayBg() {
		return {
			display: this.camera || this.clip ? "none" : "flex",
			border: "2px solid #dee2e6 !important",
			borderRadius: "5px",
			backgroundSize: "cover",
			backgroundImage: "url(/images/placeholder_blank.jpg)",
			zIndex: 100
		};
	}

	private get cameraBackground() {
		let style: any = {
			position: "absolute",
			top: "0px",
			left: "0px",
			right: "0px",
			bottom: "0px",
			zIndex: 99
		};

		if (this.getAwaitingCamera) {
			style = {
				...style,
				border: "2px solid #dee2e6 !important",
				backgroundSize: "cover",
				backgroundImage: "url(/images/placeholder_blank.jpg)"
			};
		} else if (this.getHighlightCellIndex == this.index) {
			style = {
				...style,
				border: "2px solid #eee !important"
			};
		} else {
			style = {
				...style,
				border: "none !important"
			};
		}

		return style;
	}

	private get auditClipStyle() {
		let style: any = { display: this.clip ? "flex" : "none" };

		if (this.getHighlightCellIndex == this.index) {
			style = {
				...style,
				border: "2px solid #eee !important"
			};
		}

		return style;
	}

	private get pushContents() {
		return this.getMatrixPushContents(this.index);
	}

	private get waitingForEvent() {
		return this.getWaitingForEvent;
	}

	private get matrixContent() {
		return this.getMatrixContents(this.index);
	}

	private get contents() {
		return this.getMatrixContents(this.index);
	}

	private get hasContents() {
		return this.deviceId > 0 || this.hasClip;
	}

	private get eventId() {
		return this.eventDetails ? this.eventDetails.eventID : 0;
	}

	private get displayStyle() {
		return { display: this.getAwaitingCamera ? "flex" : "none" };
	}

	private get manualRaiseEnabled() {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "ManualRaise"], false);
	}

	private get manualRaiseRecordingEnabled() {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "ManualRaise", "Recording"], false);
	}

	private get isVideoAuditingEnabled() {
		return get(this.featuresList, ["VideoAuditing"], false);
	}

	@Watch("matrixContent")
	private onMatrixUpdated() {
		this.setContents(this.getMatrixContents(this.index));
	}

	@Watch("pushContents")
	private onPushContentsChanged(contents: any, oldContents: any) {
		if (contents != null) {
			console.groupCollapsed("pushContents " + this.index);
			console.log(contents);
			console.groupEnd();

			let changed = this.setContents(contents);

			if (changed) {
				this.updateMediaMatrixContents({
					index: this.index,
					contents,
					isController: this.isController,
					eventID: this.eventId
				});
			}
		}
	}

	@Watch("waitingForEvent")
	private onWaitingForEvent(waitingForEvent: any, oldWaitingForEvent: any) {
		// if we're waiting for an event
		if (waitingForEvent == true) {
			Vue.nextTick(() => {
				// clear down any content we currently have (and stop cameras recording!)
				this.clear(false);
			});
		}
	}

	@Watch("eventId")
	private onEventChanged(eventId: number, oldEventId: number): void {
		if (eventId != oldEventId) {
			if (this.clip != null && this.$refs.player)
				this.$refs.player.stop();

			if (this.camera != null && this.$refs.cameraControl)
				this.$refs.cameraControl.stop();

			this.clip = null;
			this.camera = null;
		}
	}

	public mounted() {
		if (this.pushContents != null) {
			if (this.pushContents.eventId === this.eventId) {
				this.setContents(this.pushContents);

				this.updateMediaMatrixContents({
					index: this.index,
					contents: this.pushContents,
					isController: false,
					eventID: this.eventId
				});

				//Clear push contents
				this.setPushContents({
					index: this.index,
					newContents: null,
					eventId: null
				});
			} else {
				this.setPushContents({
					index: this.index,
					newContents: null,
					eventId: null
				});
			}
		}
	}

	private setContents(contents: any): boolean {
		let changed = false;
		if (contents) {
			if (contents.camera) {
				if (this.hasClip) {
					this.$refs.player.stop();
					this.$set(this, "clip", null);
				}

				// Need to confirm if the Id for the camera is either 'objectId' or 'objectID'.
				let objectId: number = contents.camera.objectID ? contents.camera.objectID : contents.camera.objectId;

				if (this.deviceId <= 0 || this.deviceId != objectId) {
					changed = true;
					if (this.deviceId > 0) {
						this.$refs.cameraControl.stop();
					}

					this.$set(this, "camera", contents.camera);
					this.deviceId = objectId;

					this.deviceTitle = contents.camera.title;

					this.$refs.cameraControl.startCamera(this.deviceId, this.eventId, this.isVideoAuditingEnabled ? this.isController : false);
				}
			} else if (contents.clip) {
				if (this.deviceId > 0) {
					this.$set(this, "camera", null);
					this.$refs.cameraControl.stop();
				}

				if (!this.hasClip || this.clipDetails.clip.UniqueFileIdentifier != contents.clip.clip.UniqueFileIdentifier) {
					changed = true;

					if (this.hasClip) {
						this.$refs.player.stop();
						this.$set(this, "clip", null);
					}

					this.$set(this, "clip", contents.clip);

					if (this.auditService == null || this.auditService.mediaUrl == null) {
						//TODO: Add a watch on the audit service, then remove it when it's not null.

						setTimeout(() => {
							this.$refs.player.playClip(this.auditService, contents.clip.eventRecordID, contents.clip.clip);
						}, 1.5 * this.$config.ANIMATION_DURATION);
					} else {
						this.$refs.player.playClip(this.auditService, contents.clip.eventRecordID, contents.clip.clip);
					}
				}
			} else {
				if (this.hasClip) {
					this.$refs.player.stop();
					this.$set(this, "clip", null);
					changed = true;
				}

				if (this.deviceId > 0) {
					this.$set(this, "camera", null);
					changed = true;
					this.$refs.cameraControl.stop();
				}
			}

			return changed;
		}

		return this.contents;
	}

	private cameraSelected() {
		if (this.isController) {
			this.setActivity();

			if (this.hasClip) {
				this.$refs.player.stop();
				this.clip = null;
			}

			let selectedCamera = this.getAwaitingCamera;

			if (selectedCamera) {
				this.setAwaitingCamera(null);
				this.deviceId = selectedCamera.objectId ? selectedCamera.objectId : selectedCamera.objectID;

				this.updateMediaMatrixContents({
					index: this.index,
					contents: {
						camera: selectedCamera
					},
					isController: this.isController,
					eventID: this.eventId
				});

				this.$refs.cameraControl.startCamera(this.deviceId, this.eventId, this.isVideoAuditingEnabled);
				this.camera = selectedCamera;
			}
		}
	}

	private onClipSelected() {
		if (this.isController) {
			this.setActivity();

			if (this.deviceId > 0) {
				this.camera = null;
				this.$refs.cameraControl.stop();
			}
			if (this.hasClip) {
				this.$refs.player.stop();
				this.clip = null;
			}

			let awaitingClip = this.awaitingClip;
			this.clipDetails = awaitingClip;

			this.setAwaitingClip(null);

			this.updateMediaMatrixContents({
				index: this.index,
				contents: {
					clip: awaitingClip
				},
				isController: this.isController,
				eventID: this.eventId
			});

			this.$refs.player.playClip(this.auditService, awaitingClip.eventRecordID, awaitingClip.clip);
			this.clip = awaitingClip;
		}
	}

	private clear(updateSavedContents: boolean) {
		this.$emit("contract", this.$refs.childElement);

		try {
			if (this.$refs.cameraControl != null) {
				this.$refs.cameraControl.stop();
			}
		} catch (err) {
			console.log(err);
		}

		try {
			if (this.$refs.player != null) {
				this.$refs.player.stop();
			}
		} catch (err) {
			console.log(err);
		}

		this.setContents({});
		this.updateMediaMatrixContents({
			index: this.index,
			contents: {},
			isController: this.isController,
			eventID: updateSavedContents ? this.eventId : 0
		});
	}

	private expand() {
		this.$emit("expand", this.$refs.childElement);
	}

	private makeLinkRecord() {
		this.setCameraLink({
			deviceId: this.deviceId,
			title: this.deviceTitle
		});
	}
}
