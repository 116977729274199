import { MutationTree } from "vuex";
import {
	EventsQueueState,
	AlarmQueueFilter,
	FilteredEvent,
	Group,
	SiteTreeNode,
	AlarmTag,
	ServerEventType,
	AlarmQueueFilterID,
	EventQueueInfo,
	PendingEventPickup,
} from "./types";
import { RoutingGroup } from "@/store/types";
import Vue from "vue";
import { getDefaultState } from "./state";
import { ICamera } from "../views/types";
import ActionState from "@/types/sv-data/events/ActionState";

export const mutations: MutationTree<EventsQueueState> = {
	setAlarmQueueFilters(state, data: AlarmQueueFilter[]) {
		state.AlarmQueueFilters = data;
	},

	setTourNotesRequired(state, data: any) {
		state.TourNoteRequired = data; // Mutation to update argument data to state data.
	},

	setCurrentSort(state, sortOption: any) {
		state.CurrentSort.sort = sortOption.sort;
		state.CurrentSort.desc = sortOption.desc;
		state.CurrentSort.reversed = sortOption.reversed;
	},

	setCurrentSortChanged(state, hasChanged: boolean) {
		state.CurrentSort.changed = hasChanged;
	},

	setActiveAlarmCount(state, alarmCount: number) {
		state.activeAlarmCount = alarmCount;
	},

	setAlarmQueueFilterAlarmCount(state, { filter, alarmCount }: any) {
		filter.activeAlarmCount = alarmCount;
	},

	setAlarmQueueFilterIDs(state, data: AlarmQueueFilterID[]) {
		state.AlarmQueueFilterIDs = data;
	},

	addFilteredEvents(state, { storedEvent, event }) {
		if (storedEvent) {
			Vue.set(state.FilteredEvents, state.FilteredEvents.indexOf(storedEvent), event);
		} else {
			state.FilteredEvents.push(event);
		}

		state.EventsLastUpdated = new Date().valueOf();
	},

	removeFilteredEvents(state, index: number | null) {
		if (index !== null) {
			state.FilteredEvents.splice(index, 1);
			state.EventsLastUpdated = new Date().valueOf();
		}
	},

	setMaxEventsToShow(state, maxCount : number){
		if(maxCount !== null) {
			state.maxEventsToShow = maxCount;
		}
	},

	setFilteredEvents(state, data: FilteredEvent[]) {
		state.FilteredEvents = data;
		state.EventsLastUpdated = new Date().valueOf();
	},

	setFilteredEventIds(state, data: number[]) {
		state.FilteredEventIds = data;
	},

	setActiveFilter(state, alarmFilter: AlarmQueueFilterID) {
		if (state.AlarmQueueFilters === null || state.AlarmQueueFilters.length === 0) {
			return;
		}

		state.AlarmQueueFilters.filter(filter => filter.isActive).forEach(filter => (filter.isActive = false));

		state.AlarmQueueFilters.filter(
			filter => filter.alarmQueueFilterID === alarmFilter.filterId && filter.regionID === alarmFilter.regionId
		).forEach(filter => (filter.isActive = true));

		state.AlarmActiveFilter = alarmFilter;
		state.EventsLastUpdated = new Date().valueOf();
	},

	setHoverEvent(state, event: MouseEvent) {
		state.HoverEvent = event;
	},

	setDisplayedEvent(state, event: FilteredEvent) {
		state.DisplayedEvent = event;
	},

	setManualPatrolShown(state, shown: boolean) {
		state.ManualPatrolShown = shown;
	},

	setActivityLogShown(state, shown: boolean) {
		state.ActivityLogShown = shown;
	},

	setManualActionShown(state, shown: boolean) {
		state.ManualActionShown = shown;
	},

	setNewFilterModalShown(state, shown: boolean) {
		state.NewFilterModalShown = shown;
	},

	setSiteTree(state, siteTree: SiteTreeNode[]) {
		state.SiteTree = siteTree;
	},

	setSiteNodeContents(state, { treeNode, content, isLoading = false }) {
		treeNode.contents = content;
		if (isLoading) {
			treeNode.loadingContents = true;
			treeNode.contentsOpen = true;
			treeNode.isOpen = true;
		} else {
			treeNode.loadingContents = false;
		}
	},

	toggleSiteNodeContentsOpen(state, { treeNode }) {
		treeNode.contentsOpen = !treeNode.contentsOpen;
	},

	setTree(state, data) {
		const setSelected = (node: SiteTreeNode) => {
			node.group.selected = false;

			if (node.subGroups != null && node.subGroups.length > 0) {
				node.subGroups = node.subGroups.map(setSelected);
			}

			return node;
		};

		if (!data) {
			state.SiteTree = [];
			return;
		}

		const tree = data.map(setSelected);
		const setLocalProperties = (node: SiteTreeNode) => {
			Vue.set(node, "show", true);
			Vue.set(node, "contents", null);
			Vue.set(node, "loadingContents", false);
			Vue.set(node, "contentsOpen", false);

			if (node.subGroups && node.subGroups.length > 0) {
				node.subGroups.forEach(setLocalProperties);
			} else {
				// if there are no subGroups there may be contents of the child most group which should be closed initially
				node.isOpen = false;
			}
		};

		tree.forEach(setLocalProperties);

		state.SiteTree = tree;
	},

	// Updates the content of the group site tree adding the supplied devices to the relevant group
	setDevicesAndGroupsInSiteTree(state, { devices, query }) {
		// Hides all nodes and removes all devices from the tree
		const resursiveRemoveAllDevices = (tree: SiteTreeNode) => {
			tree.contents = null;
			tree.show = false;
			tree.isOpen = false;
			tree.contentsOpen = false;
			if (tree.subGroups && tree.subGroups.length > 0) {
				for (var i = 0; i < tree.subGroups.length; i++) {
					resursiveRemoveAllDevices(tree.subGroups[i]);
				}
			}
		};

		// Shows all of the children of a given node
		const recursiveShowChild = (tree: SiteTreeNode) => {
			tree.show = true;

			if (tree.subGroups && tree.subGroups.length > 0) {
				for (var i = 0; i < tree.subGroups.length; i++) {
					recursiveShowChild(tree.subGroups[i]);
				}
			}
		};

		// Shows all the groups with a matching title
		const recursiveShowGroups = (tree: SiteTreeNode, query: string) => {
			// Leaf first seach, check if title matches and enable if true
			query = query.toLocaleLowerCase();
			if (tree.subGroups && tree.subGroups.length > 0) {
				for (var i = 0; i < tree.subGroups.length; i++) {
					// Check the children first
					recursiveShowGroups(tree.subGroups[i], query);

					// If any of the children have been opened, open parent
					if (tree.subGroups[i].show) {
						tree.isOpen = true;
						tree.show = true;
						tree.contentsOpen = true;
					}
				}
			}
			// If we match query enable the contents of that node and all its children
			if (tree.group.title.toLocaleLowerCase().includes(query)) {
				tree.show = true;

				recursiveShowChild(tree);
			}
		};

		// Adds the devices to the tree
		const recursiveAddDevice = (tree: SiteTreeNode, device: ICamera) => {
			// Leaf first seach, check if
			if (tree.subGroups && tree.subGroups.length > 0) {
				for (var i = 0; i < tree.subGroups.length; i++) {
					recursiveAddDevice(tree.subGroups[i], device);

					// If any of the children have been opened, open parent
					if (tree.subGroups[i].isOpen) {
						tree.isOpen = true;
						tree.show = true;
						tree.contentsOpen = true;
					}
				}
			}

			// Add devices that belong to the current nodes group
			if (tree.group.groupID === device.groupId) {
				if (tree.contents && tree.contents.devices) {
					let deviceAlreadyAdded = tree.contents.devices.some(d => d.deviceId == device.deviceID);
					if (!deviceAlreadyAdded) {
						tree.contents.devices.push(device);
					}
				} else {
					Vue.set(tree, "contents", { devices: [device] });
				}
				tree.contentsOpen = true;
				tree.show = true;
				tree.isOpen = true;
			}
		};

		// Performs all the actions to set the tree
		state.SiteTree.forEach(siteTree => {
			resursiveRemoveAllDevices(siteTree);
			recursiveShowGroups(siteTree, query);
			devices.forEach(device => {
				recursiveAddDevice(siteTree, device);
			});

			// Ensure root node is open
			siteTree.show = true;
			siteTree.isOpen = true;
		});
	},

	// Updates the content of the group site tree adding the supplied devices to the relevant group
	setDevicesForGroupInSiteTree(state, devices) {
		const resursiveRemoveAllDevices = (tree: SiteTreeNode) => {
			tree.contents = null;
			tree.show = false;
			tree.isOpen = false;
			tree.contentsOpen = false;
			if (tree.subGroups && tree.subGroups.length > 0) {
				for (var i = 0; i < tree.subGroups.length; i++) {
					resursiveRemoveAllDevices(tree.subGroups[i]);
				}
			}
		};

		const recursiveAddDevice = (tree: SiteTreeNode, device) => {
			if (tree.group.groupID === device.groupId) {
				if (tree.contents && tree.contents.devices) {
					let deviceAlreadyAdded = tree.contents.devices.some(d => d.deviceId == device.deviceId);
					if (!deviceAlreadyAdded) {
						tree.contents.devices.push(device);
					}
				} else {
					Vue.set(tree, "contents", { devices: [device] });
				}
				tree.contentsOpen = true;
				tree.show = true;
				tree.isOpen = true;
			}

			if (tree.subGroups && tree.subGroups.length > 0) {
				for (var i = 0; i < tree.subGroups.length; i++) {
					recursiveAddDevice(tree.subGroups[i], device);
                    tree.isOpen = true;
                    tree.show = true;
				}
			}
		};
		state.SiteTree.forEach(siteTree => {
			resursiveRemoveAllDevices(siteTree);
			devices.forEach(device => {
				recursiveAddDevice(siteTree, device);
			});
			if (devices.length > 0) {
                siteTree.show = true;
                siteTree.isOpen = true;
            }
		});
	},

	setSiteTreeFilter(state, areaSearch: string) {
		// Sets show to true for entire tree. Used to reset search.
		const recursiveShow = (tree: SiteTreeNode) => {
			tree.show = true;
			if (tree.isOpenDefault !== null) {
				tree.isOpen = tree.isOpenDefault;
				tree.isOpenDefault = null;
			}

			if (tree.subGroups && tree.subGroups.length > 0) {
				tree.subGroups.forEach((node: SiteTreeNode) => {
					recursiveShow(node);
				});
			}
		};

		// Set show for anything that matches search term and any nodes higher in the tree
		const recursiveSearchApply = (tree: SiteTreeNode, searchTerm: string): boolean => {
			let thisHasTerm = tree.group!.title!.toLowerCase().indexOf(searchTerm) > -1;

			if (tree.subGroups && tree.subGroups.length > 0) {
				tree.subGroups.forEach((node: SiteTreeNode) => {
					const lowerInTree = recursiveSearchApply(node, searchTerm);
					if (lowerInTree) {
						thisHasTerm = true;
					}
				});
			}

			tree.show = thisHasTerm;
			if (tree.isOpenDefault == null) {
				tree.isOpenDefault = tree.isOpen;
			}
			tree.isOpen = true;

			return thisHasTerm;
		};

		const isEmptyOrSpaces = (str: string) => str == null || str.match(/^ *$/) != null;

		// If the search string is empty, just return the tree directly
		if (isEmptyOrSpaces(areaSearch)) {
			state.SiteTree.forEach((node: SiteTreeNode) => {
				recursiveShow(node);
			});
		} else {
			// Filter the tree, setting show for nodes that match filter
			state.SiteTree.forEach((node: SiteTreeNode) => {
				recursiveSearchApply(node, areaSearch.toLowerCase());
			});
		}
	},

	setSiteTreeFilterByState(state, areaState: string) {
		// Sets show to true for entire tree. Used to reset search.
		const recursiveShow = (tree: SiteTreeNode) => {
			tree.show = true;

			if (tree.subGroups && tree.subGroups.length > 0) {
				tree.subGroups.forEach((node: SiteTreeNode) => {
					recursiveShow(node);
				});
			}
		};

		// Set show for anything that matches search term and any nodes higher in the tree
		const recursiveSearchApply = (tree: SiteTreeNode, areaState: string): boolean => {
			let groupHasState = tree.group!.state === areaState;

			if (tree.subGroups && tree.subGroups.length > 0) {
				tree.subGroups.forEach((node: SiteTreeNode) => {
					const lowerInTree = recursiveSearchApply(node, areaState);
					if (lowerInTree) {
						groupHasState = true;
					}
				});
			}

			tree.show = groupHasState;
			if (tree.isOpenDefault == null) {
				tree.isOpenDefault = tree.isOpen;
			}
			tree.isOpen = true;

			return groupHasState;
		};

		// If not filtering just return the tree directly
		if (areaState === "All") {
			state.SiteTree.forEach((node: SiteTreeNode) => {
				recursiveShow(node);
			});
		} else {
			// Filter the tree, setting show for nodes that match masking status
			state.SiteTree.forEach((node: SiteTreeNode) => {
				recursiveSearchApply(node, areaState);
			});
		}
	},

	setSiteTreeFilterOn(state, { treeNode, shown }) {
		// : any { SiteTreeNode, boolean }
		treeNode.show = shown;
		if (treeNode.isOpenDefault == null) {
			treeNode.isOpenDefault = treeNode.isOpen;
		}
		treeNode.isOpen = true;
	},

	setSiteTreeFilterOff(state, treeNode) {
		treeNode.show = true;
		if (treeNode.isOpenDefault !== null) {
			treeNode.isOpen = treeNode.isOpenDefault;
			treeNode.isOpenDefault = null;
		}
	},

	setSiteTreeNodeValues(state, { treeNode, isOpen, selected, contents, loadingContents, contentsOpen }) {
		if (open !== undefined) {
			treeNode.isOpen = open;

			if (treeNode.isOpenDefault == null) {
				treeNode.isOpenDefault = treeNode.isOpen;
			}
		}
		if (selected !== undefined) {
			treeNode.group.selected = selected;
		}
		if (contents !== undefined) {
			treeNode.contents = contents;
		}
		if (loadingContents !== undefined) {
			treeNode.loadingContents = loadingContents;
		}
		if (contentsOpen !== undefined) {
			treeNode.contentsOpen = contentsOpen;
		}

		if (!state.ChangedTreeNodes.some(n => n.group.groupID === treeNode.group.groupID)) {
			state.ChangedTreeNodes.push(treeNode);
		}
	},

	setTreeGroupSelected(state, { group, multiSelect }) {
		if (!multiSelect) {
			if (state.selectedGroups) {
				state.selectedGroups.forEach((selectedGroup: any) => {
					if (selectedGroup) {
						selectedGroup.selected = false;
					}
				});
			}

			state.selectedGroups = [];
		}

		state.selectedGroups.push(group);

		// state.searchSelectedGroup = {};
		//      state.treeSelectedGroup = group;
		if (group) {
			group.selected = true;
		}
	},

	setTreeGroupSelectedByGroupId(state, groupId) {
		if (!groupId) {
			return;
		}

		state.selectedGroups = [];

		const recursiveSetSelected = (treeNode: SiteTreeNode, groupId: number): boolean => {
			let found = treeNode.group!.groupID === groupId;
			if (found) {
				treeNode.group.selected = true;
				state.selectedGroups.push(treeNode.group);

				return found;
			}

			if (treeNode.subGroups && treeNode.subGroups.length > 0) {
				treeNode.subGroups.forEach((treeNode: SiteTreeNode) => {
					recursiveSetSelected(treeNode, groupId);
				});
			}

			return false;
		};

		state.SiteTree.forEach((treeNode: SiteTreeNode) => {
			let successful = recursiveSetSelected(treeNode, groupId);
			if (successful) {
				return;
			}
		});
	},

	updateSiteTreeSelected(state) {
		const recursiveUpdateAll = (tree: SiteTreeNode) => {
			let selectedGroupToUpdateIndex = state.selectedGroups.findIndex(g => g.groupID == tree.group.groupID);
			if (selectedGroupToUpdateIndex !== -1) {
				state.selectedGroups.splice(selectedGroupToUpdateIndex, 1, tree.group);
			}

			if (tree.subGroups && tree.subGroups.length > 0) {
				tree.subGroups.forEach((node: SiteTreeNode) => {
					recursiveUpdateAll(node);
				});
			}
		};

		state.SiteTree.forEach((node: SiteTreeNode) => {
			recursiveUpdateAll(node);
		});
	},

	resetTree(state) {
		state.ChangedTreeNodes.forEach(treeNode => {
			if (treeNode.isOpenDefault !== null) {
				treeNode.isOpen = treeNode.isOpenDefault;
				treeNode.isOpenDefault = null;
			}

			treeNode.contents = null;
			treeNode.contentsOpen = false;
		});

		state.ChangedTreeNodes = [];
	},

	toggleSiteTreeNodeContentsOpen(state, treeNode: any) {
		treeNode.contentsOpen = !treeNode.contentsOpen;
	},

	closeSiteTreeNodeContents(state, treeNode: any) {
		treeNode.contentsOpen = false;
	},

	toggleSiteTreeNodeOpen(state, treeNode: any) {
		treeNode.isOpen = !treeNode.isOpen;
	},

	collapseSiteTreeNode(state, treeNode) {
		treeNode.isOpen = false;
	},

	expandSiteTreeNode(state, treeNode) {
		treeNode.isOpen = true;
	},

	setSearchGroupSelected(state, group: Group) {
		if (typeof state.searchSelectedGroup.groupID !== "undefined") {
			state.searchSelectedGroup.selected = false;
		}

		state.selectedGroups.push(group);
		state.treeSelectedGroup = {};

		state.searchSelectedGroup = group;
		group.selected = true;
	},

	setQueueMapBounds(state, queueMapBounds: google.maps.LatLngBoundsLiteral) {
		state.queueMapBounds = queueMapBounds;
	},

	setCountByType: (state, { countType, count }: any) => {
		state.eventCountsByType[countType] = count;
	},

	setEventQueueGetOverride: (state, path: string | null) => {
		state.eventQueueGetOverride = path;
	},

	setProcessingError: (state: EventsQueueState, error: string | null) => {
		state.processingError = error;
	},

	setAlarmTags(state, data: AlarmTag[]) {
		state.AlarmTags = data;
	},

	setRoutingGroups(state, data: RoutingGroup[]) {
		state.RoutingGroups = data;
	},

	setUserZone(state, group: Group) {
		state.userZone = group;
	},

	setLastEventHandled(state, date) {
		state.lastEventHandled = date || new Date().valueOf();
	},

	setServerEventTypes(state, data: ServerEventType[]) {
		state.ServerEventTypes = data;
	},

	addServerEventType(state, data: ServerEventType) {
		state.ServerEventTypes.push(data);
	},

	setFilteredServerEventTypes(state, data: ServerEventType[]) {
		state.filteredServerEventTypes = data;
	},

	setHandlingEvent: (state, handling) => (state.HandlingEvent = handling),
	setAdvancedTreeSearchCancelToken: (state, cancelToken) => (state.advancedTreeSearchCancelToken = cancelToken),
	setJoiningEventID: (state, eventID) => (state.JoiningEventID = eventID),
	setHideMapFlag: (state, hideMapFlag: boolean) => (state.hideMapFlag = hideMapFlag),
	setBulkHandleShown:(state, shown: boolean) => (state.bulkHandleShown = shown),
	setVirtualOperatorControlPanelShown:(state, shown: boolean) => (state.virtualOperatorControlPanelShown = shown),
	setAllAlarmsSelected:(state, allSelected: boolean) => (state.allAlarmsSelected = allSelected),
	setSelectedAlarms(state, data: number[]) {
		state.selectedAlarms = data;
	},
	addSelectedAlarm(state, data: number)  {
		state.selectedAlarms.push(data);
	},
	removeSelectedAlarm(state, data: number)  {
		const alarmIndex: number = state.selectedAlarms.indexOf(data);
		state.selectedAlarms.splice(alarmIndex,1);
	},
	setRefreshQueue:(state, refresh:boolean) => (state.refreshQueue = refresh),
	setClosingAction:(state, action:ActionState) => (state.closingAction = action),
	setLostConnectionCount: (state, count: number | null) => (state.lostConnectionCount = count),
	setEventQueueUpdateInfoHistory: (state, info: EventQueueInfo) => {
		if(state.eventQueueUpdateInfoHistory.length >= 300) {
			state.eventQueueUpdateInfoHistory.pop();
		}
		state.eventQueueUpdateInfoHistory.unshift(info);
	},
	clearEventQueueUpdateInfoHistory:state => (state.eventQueueUpdateInfoHistory = []),
	setDebugMode: (state, debugMode: boolean) => (state.debugMode = debugMode),
	setPendingEventPickup: (state, pendingEvent: PendingEventPickup) => (state.pendingEventPickup = pendingEvent),
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	setSelectedGroup: (state, selectedGroup: Group | null) => {
		state.selectedGroup = selectedGroup
	},
	setPeekEventId: (state, eventId: number | null) => {
		state.peekEventId = eventId;
	}
};
