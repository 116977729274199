import api from "../../services/api.views.service";
import { ActionTree } from "vuex";
import { ViewsState, ViewObject, ViewCellObject, Dimensions } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<ViewsState, RootState> = {
	async loadPluginTypes({ commit }) {
		// get list of installed plugins from the data service
		let plugins = await api.getViewPlugins();
		commit("setPluginTypes", plugins);
	},

	async loadViews({ commit }) {
		let viewData = [];

		// get a list of views that the user can see
		// the data service takes care of permissions and groups etc...
		let views = await api.getViewsList();

		viewData = views.map(v => {
			// map view objects
			return {
				viewId: v.viewId,
				title: v.title,
				width: 1,
				height: 1,
				cells: [],
				groupId: v.groupId,
				public: v.isPublic,
				ownerUserId: v.ownerUserId
			} as ViewObject;
		});

		commit("setViews", viewData);
	},

	async loadUserViews({ commit }) {
		let userViews = [];

		// get the list of views the user has choosen to persist on screen
		let views = await api.getUserViews();

		userViews = views.map(v => {
			// map view objects
			return {
				viewId: v.viewId,
				title: v.title,
				width: 1,
				height: 1,
				cells: [],
				groupId: v.groupId,
				public: v.isPublic,
				ownerUserId: v.ownerUserId,
				locked: v.isLocked
			} as ViewObject;
		});

		commit("setUserViews", userViews);
	},

	async loadAdhocUserView({ commit }, sessionId) {
		let view = await api.getSharedAdhocView(sessionId);

		var views = [
			{
				viewId: view.viewId,
				title: view.title,
				width: 1,
				height: 1,
				cells: [],
				groupId: view.groupId,
				public: view.isPublic,
				ownerUserId: view.ownerUserId
			} as ViewObject
		];

		commit("setUserViews", views);
	},

	async getViewProperties({ commit, state, rootGetters }, viewId) {
		let userId = await rootGetters.getUserId;

		// go and get the details, pages, cells, plugins etc...
		return await api.getViewDetails(viewId, userId);

	},

	async getViewDetails({ commit, state, rootGetters }, viewId) {
		// current userId
		let userId = await rootGetters.getUserId;

		// views currently in the store
		let views = JSON.parse(JSON.stringify(state.userViews));

		// find the one we have selected
		let viewIndex = views.findIndex(v => v.viewId == viewId);

		let currentView = views[viewIndex];

		// go and get the details, pages, cells, plugins etc...
		let viewDetails = await api.getViewDetails(viewId, userId);

		// update our copy of the view with the details
		currentView.groupId = viewDetails.groupId;
		currentView.public = viewDetails.isPublic;
		currentView.ownerUserId = viewDetails.ownerUserId;
		currentView.dateLoaded = viewDetails.dateLoaded;

		// TODO : handle pagination

		// map each cell
		let cells = viewDetails.pages[0].cells.map(c => {
			return {
				height: c.rowSpan,
				width: c.columnSpan,
				x: c.x,
				y: c.y,
				pluginTypeId: c.pluginId,
				viewCellId: c.viewCellId,
				contents: viewDetails.isPublic ? c.defaultContents : c.contents
			} as ViewCellObject;
		});

		// update the object
		views[viewIndex] = currentView;

		// commit updated view data to the store
		commit("setUserViews", views);

		// update the selected view
		commit("setSelectedView", currentView);

		// update the cells
		commit("setSelectedViewCells", cells);

		// set our session id
		commit("setViewSessionId", viewDetails.sessionId);

		let viewDimentions: Dimensions = {
			x: viewDetails.pages[0].columns,
			y: viewDetails.pages[0].rows
		};

		commit("setViewDimensions", viewDimentions);
	},

	async deleteView({ commit }, viewId) {
		await api.deleteView(viewId);
	},

	async saveView({ commit }, view: ViewObject) {
		const viewCells = view.cells.map(cell => {
			return {
				viewCellId: cell.viewCellId < 0 ? 0 : cell.viewCellId,
				viewId: view.viewId || 0,
				pageNumber: 1,
				x: cell.x,
				y: cell.y,
				columnSpan: cell.width,
				rowSpan: cell.height,
				pluginId: cell.pluginTypeId,
				contents: cell.contents
			};
		});

		let viewDTO = {
			pages: [
				{
					cells: viewCells,
					viewId: view.viewId || 0,
					pageNumber: 1,
					columns: view.width,
					rows: view.height
				}
			],
			viewId: view.viewId || 0,
			title: view.title,
			ownerUserId: view.ownerUserId,
			isPublic: view.public,
			groupId: view.groupId
		};

		return await api.saveView(viewDTO);
	},

	async addUserView({ commit }, viewId) {
		// add the view to the list of views the user would like to persist on screen
		// duplicates and permissions are handled by the data service
		await api.addUserView(viewId);
	},

	async removeUserView({ commit }, viewId) {
		await api.removeUserView(viewId);
	},

	async loadViewUpdateTime({ commit }, viewId) {
		return await api.fetchViewUpdateTime(viewId);
	},

	async loadViewContentUpdateTime({ commit }, viewId) {
		return await api.fetchViewContentUpdateTime(viewId);
	},

	async saveCellContent({ getters }, { viewId, viewCellId, content }) {
		let sessionId = getters.getViewSessionId;
		await api.saveViewCellContent(viewId, sessionId, viewCellId, content);
	},

	async toggleLock({ commit }, viewId) {
		await api.toggleLockedView(viewId);
	},

	async loadUpdatedCellContent({ getters, commit }, viewId) {
		let sessionId = getters.getViewSessionId;
		let updatedContent = await api.fetchUpdatedCellContent(viewId, sessionId);
		commit("setUpdatedCellContents", updatedContent);
	}
};

class Guid {
	static newGuid() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
			var r = (Math.random() * 16) | 0,
				v = c == "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}
}
