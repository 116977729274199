
import PaginatedSearch from "@/mixins/PaginatedSearch";
import { Mixins, Component, Prop, Watch } from "vue-property-decorator";
import { TimeZone, PaginatedQueryParams, PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
import { Getter, Action, namespace } from "vuex-class";
import {
	AreaEventHistoryPaginatedSearchParams,
	AreaEventHistory as AreaEventHistoryType
} from "@/types/sv-data/AreaEventHistory";
import DatePicker from "@sureview/vuejs-datepicker";
import vselect3 from "vselect3";
import GenericTable, { TableHeader } from "../table/generic-table.vue";
import { ModalItem } from "../table/generic-update-modal.vue";
import api from "@/services/api.service";
import { truncateString } from "@/filters";
import axios, { CancelTokenSource } from "axios";
import { debounce } from "lodash";
import { getDebouncePeriod } from "@/utils/debounce-helper";
import { Response } from "@/store/responses/types";

const SiteMonitor = namespace("siteMonitor");
const AreaEventHistoryStore = namespace("areaEventHistory");

@Component({
	components: {
		"date-picker": DatePicker,
		"v-select3": vselect3,
		"generic-table": GenericTable,
	},
	filters: {
		truncateString
	}
})
export default class AreaEventHistory extends Mixins(PaginatedSearch) {
	@Getter getTimeZone: (timeZoneId: number) => TimeZone;
	@Action openEventReview: (eventId: number) => void;

	@SiteMonitor.Mutation setActivity: () => void;
	@SiteMonitor.Mutation setIncidentReportShown: (shown: boolean) => void;

	@AreaEventHistoryStore.Mutation setIsPopoutModeEnabled: (isEnabled: boolean) => void;
	@AreaEventHistoryStore.Mutation setFilter: (filter: AreaEventHistoryPaginatedSearchParams) => void;
	@AreaEventHistoryStore.Mutation setTimeZone: (timeZoneId: number) => void;
	@AreaEventHistoryStore.Mutation setTotalRecords: (totalRecords: number) => void;
	@AreaEventHistoryStore.Mutation resetState: () => void;

	@AreaEventHistoryStore.State areaEventHistory: AreaEventHistoryType[];
	@AreaEventHistoryStore.State totalRecords: number;
	@AreaEventHistoryStore.State isPopoutModeEnabled: boolean;
	@AreaEventHistoryStore.State filter: AreaEventHistoryPaginatedSearchParams;

	@AreaEventHistoryStore.Action fetchAreaEventHistory: () => Promise<void>;

	@Getter getPermissions: UserPermissions;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	@Prop({ type: Number, required: true }) groupId: number;
	@Prop({ type: Number, required: false }) timeZoneId: number;
	@Prop({ type: Boolean, required: false }) poppedOut: boolean;

    private isDestroyed: boolean = false;
    private readonly fallbackUtcTimezoneId: number = 35;

	private newDataFilter: AreaEventHistoryPaginatedSearchParams = {
		fromDate: this.createFromDate(),
		toDate: this.createToDate(),
		groupId: this.groupId,
		eventHistoryTypeIds: [1],
		includeAutohandled: false,
		responseIds: [],
		page: 1,
		pageSize: 25
	};

	private internalFilter: AreaEventHistoryPaginatedSearchParams = {
		...this.newDataFilter,
	};

	private responsesForArea: Response[] = [];
	private paginationParams: PaginatedQueryParams;
	private isLoading: boolean = false;
	private isFilterDataValid: boolean = true;
	private validationErrorMessage: string = "";
	private modalItems: ModalItem[] = [];

	private responseSearchToken: CancelTokenSource;

	private get disabledDates(): any {
		let date = new Date();
		date.setDate(date.getDate() - 31);
		return { to: date };
	}

	private get getTimeZoneName(): string {
		if (this.timeZone) {
			return this.timeZone.id;
		}

		return "";
	}

	private handleSearchInput = debounce(async (searchTerm: string): Promise<void> => {
			await this.fetchResponses(searchTerm);
		},
		getDebouncePeriod(),
		{ leading: false, trailing: true });

	private async fetchResponses(searchTerm: string): Promise<void> {
		if (this.responseSearchToken) {
			this.responseSearchToken.cancel("Cancel Search");
		}

		this.responseSearchToken = axios.CancelToken.source();

		if (!this.groupId) {
			return;
		}
		try {
			this.responsesForArea = await api.getAllResponsesByArea(this.groupId, searchTerm);
		} catch (e) {
			return; // Return when past requests are cancelled
		}
	}

	private get columns(): TableHeader[]
	{
		return [
			{
				title: "Created (Area Time)",
				key: "createdAreaTime",
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				description: this.getTimeZoneName,
				visible: true,
				sortable: true
			},
			{
				title: "Created (Local)",
				key: "createdLocalTime",
				order: 2,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: false,
				sortable: false
			},
			{
				title: "Closed (Area Time)",
				key: "closedAreaTime",
				order: 3,
				sortOrder: 0,
				sortOrderReversed: false,
				description: this.getTimeZoneName,
				visible: true,
				sortable: true
			},
			{
				title: "Closed (Local)",
				key: "closedLocalTime",
				order: 4,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: false,
				sortable: false
			},
			{
				title: "Type",
				key: "type",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true,
				sortable: true,
				isTermLabel: true
			},
			{
				title: "User",
				key: "user",
				order: 6,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true,
				sortable: true,
				useCustomCell: true,
				isTermLabel: true
			},
			{
				title: "Details",
				key: "details",
				order: 7,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true,
				sortable: true,
				isTermLabel: true,
				style: "max-width:10vw!important;"
			},
			{
				title: "Priority",
				key: "priority",
				order: 8,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true,
				sortable: true,
				isTermLabel: true
			},
			{
				title: "Category",
				key: "category",
				order: 9,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true,
				sortable: true,
				style: "max-width:8vw!important;",
				isTermLabel: true
			},
			{
				title: "Note",
				key: "note",
				order: 10,
				sortOrder: 0,
				style: "max-width:10vw!important;",
				sortOrderReversed: false,
				visible: true,
				sortable: true,
				useCustomCell: true,
				isTermLabel: true
			},
			{
				title: "Event Id",
				key: "eventId",
				order: 11,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true,
				sortable: true,
				useCustomCell: true,
				isTermLabel: true
			}
		];
	}

	private timeZone: TimeZone =
		{
			timeZoneID: 35,
			id: "UTC",
			iana: "Etc/Utc",
			displayName: ""
		};

	private timeZoneOptions: Intl.DateTimeFormatOptions = {
		month: "numeric",
		day: "numeric",
		year: "numeric",
		hour: "2-digit",
		minute: "numeric",
		second: "numeric",
		hour12: true
	};

	private eventHistoryTypeOptions = [
		{
			eventTypeId: 1,
			eventTypeName: "Event"
		},
		{
			eventTypeId: 2,
			eventTypeName: "Masking"
		}
	];

	private showLiveData: boolean = false;
	private liveDataTimeoutId: NodeJS.Timeout = null;

	public get sitRepEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "SitRep"]) && this.getPermissions.canViewSitRep;
	}

	private created(): void {
		this.fetchTimeZone();
	}

	private fetchTimeZone(): void {
		try {
			this.timeZone = this.getTimeZone(this.timeZoneId ? this.timeZoneId : this.fallbackUtcTimezoneId);
			if (!this.timeZone) {
				this.timeZone = this.getTimeZone(this.fallbackUtcTimezoneId);
			}
		} catch (err) {
			console.error("Failed to get timezone.", err);
			this.timeZone = this.getTimeZone(this.fallbackUtcTimezoneId);
		}
	}

    private async mounted(): Promise<void> {
        try {
			if (!this.filter) {

				this.internalFilter = {
					...this.newDataFilter
				};
				await this.getAreaEventHistory();
			}
			else {
				this.newDataFilter = {
					...this.filter,
				}
			}
            await this.fetchResponses("");
        } catch (err) {
            console.error("Failed to load Area Event History data.", err);
        }
    }

	private beforeDestroy(): void {
		this.isDestroyed = true;
		clearTimeout(this.liveDataTimeoutId);

		if (!this.isPopoutModeEnabled) {
			this.resetState();
		}
	}

	private createToDate(): Date {
		var toDate = new Date(Date.now());
		// Set date to 23:59:59.999 for today's date
		toDate.setHours(23, 59, 59, 999);
		return toDate;
	}

	private createFromDate(): Date {
		let currentMilliseconds = Date.now();
		// Set date to 12:00AM, 7 days before current date/time
		let fromDate = new Date(currentMilliseconds - 7 * 24 * 60 * 60 * 1000);
		fromDate.setHours(0, 0, 0, 0);
		return fromDate;
	}

	private incidentReport(): void {
		this.setIncidentReportShown(true);
		this.setActivity();
	}

	private showEventReview(eventId: number): void {
		this.openEventReview(eventId);
	}

	@Watch("timeZoneId")
	private async updateRowsWithNewTimeZone(): Promise<void> {
		this.fetchTimeZone();
	}

	@Watch("groupId")
	private async updateFilterGroupId() {
		this.filter.groupId = this.groupId;
		this.newDataFilter.groupId = this.groupId;
		await this.getAreaEventHistory();
		this.responsesForArea = await api.responsesByArea(this.groupId);
	}

    private async fetchHistoryWithNewData(): Promise<void> {
		this.internalFilter = { ...this.newDataFilter };
        await this.getAreaEventHistory();
    }

	private async getAreaEventHistory(): Promise<void> {
		if (!this.showLiveData) {
			this.isLoading = true;
		}

        try {
            if (this.internalFilter.groupId) {
                var request: AreaEventHistoryPaginatedSearchParams = {
                    ...this.internalFilter,
                    ...this.paginationParams
                }
                this.setFilter(request);
                await this.fetchAreaEventHistory();
                this.setTotalRecords(this.areaEventHistory.length);
            }
        } catch (err) {
            console.error("Failed to retrieve Area Event History.", err);
        } finally {
            this.isLoading = false;
        }
    }

	private get mapAreaEventHistoryData(): AreaEventHistoryType[] {
		let mappedEvents: AreaEventHistoryType[] = this.areaEventHistory.map(history => {
			if (history.closed && !history.category) {
                history.category = "No Category Assigned";
            }
			return ({
				...history,
				userInitials: this.nameToInitials(history.user),
				createdAreaTime: history.created && this.timeZone ? new Date(history.created).toLocaleString(undefined, {
					...this.timeZoneOptions,
					timeZone: this.timeZone.iana
				}).toUpperCase() : "",
				createdLocalTime: history.created ? new Date(history.created).toLocaleString(undefined).toUpperCase() : "",
				closedAreaTime: history.closed && this.timeZone ? new Date(history.closed).toLocaleString(undefined, {
					...this.timeZoneOptions,
					timeZone: this.timeZone.iana
				}).toUpperCase() : "",
				closedLocalTime: history.closed ? new Date(history.closed).toLocaleString(undefined).toUpperCase() : "",
				actions: null
			});
		});

		return mappedEvents;
	}

	private get liveDataStatus(): string {
		return this.showLiveData ? "Live Data On" : "Live Data Off";
	}

	private get liveDataDivClass(): string {
		return this.poppedOut ? "live-data-popped-out" : "live-data";
	}

	private get liveDataButtonClass(): string {
		return this.showLiveData ? "fas fa-toggle-on" : "fas fa-toggle-off";
	}

	private toggleLiveData(): void {
		this.showLiveData = !this.showLiveData;
		if (this.showLiveData) {
			this.pollLiveData();
		}
	}

	private enablePopoutMode(): void {
		this.$bvModal.hide("leaveModal");
		this.setTimeZone(this.timeZoneId);
		this.setIsPopoutModeEnabled(true);
	}

	@Watch("isDestroyed")
	private async pollLiveData(): Promise<void> {
		if (!this.showLiveData || this.isDestroyed) {
			clearTimeout(this.liveDataTimeoutId);
		} else {
			await this.getAreaEventHistory();
			this.liveDataTimeoutId = setTimeout(this.pollLiveData, 10000);
		}
	}

	@Watch("newDataFilter.fromDate")
	@Watch("newDataFilter.toDate")
	@Watch("newDataFilter.eventHistoryTypeIds")
	private validateFilterDates(): void {
		if (!this.newDataFilter.fromDate || !this.newDataFilter.toDate) {
			return;
		}

		this.sanitiseDatePickerValues();

		if (this.newDataFilter.eventHistoryTypeIds.length < 1) {
			this.newDataFilter.eventHistoryTypeIds.push(1);
			return;
		}

		if (this.newDataFilter.fromDate > this.newDataFilter.toDate) {
			this.isFilterDataValid = false;
			this.validationErrorMessage = "'To' date cannot be before the 'From' date";
			return;
		}
		let absoluteDifference = this.newDataFilter.toDate.getTime() - this.newDataFilter.fromDate.getTime();
		let differenceInDays = absoluteDifference / (1000 * 60 * 60 * 24);
		if (differenceInDays >= 32) {
			this.isFilterDataValid = false;
			this.validationErrorMessage = "Date range cannot exceed 31 days";
			return;
		}

		this.isFilterDataValid = true;
		this.validationErrorMessage = "";
	}

	private sanitiseDatePickerValues(): void {
		try {
			if (typeof(this.newDataFilter.fromDate) == "string") 
			{
				this.newDataFilter.fromDate = new Date(this.newDataFilter.fromDate);
				if (isNaN(this.newDataFilter.fromDate.getTime())) {
					this.newDataFilter.fromDate = this.createFromDate();
				}
			}
		} catch {
			this.newDataFilter.fromDate = this.createFromDate();
		}

		try {
			if (typeof(this.newDataFilter.toDate) == "string") 
			{
				this.newDataFilter.toDate = new Date(this.newDataFilter.toDate);
				if (isNaN(this.newDataFilter.toDate.getTime())) {
					this.newDataFilter.toDate = this.createToDate();
				}
			}
		} catch {
			this.newDataFilter.toDate = this.createToDate();
		}
	}

	private async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		this.paginationParams = paginatedSearchQueryParams;
		await this.getAreaEventHistory();
	}

	private nameToInitials(userName: string): string {
		if (!userName) {
			return "";
		}
		const words = userName.split(" ");
		const initials = words.map(word => word.charAt(0).toUpperCase());

        return initials.join("");
    }

	private get selectedEventHistoryTypes(): number[] {
		return this.newDataFilter.eventHistoryTypeIds;
	}

	private set selectedEventHistoryTypes(types: number[]) {
		this.newDataFilter.eventHistoryTypeIds = types;
	}
}
