import { Module } from "vuex";
import { GuardChat } from "./types";
import { RootState } from "../types";
import getDefaultState from "./state";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

export const guardChat: Module<GuardChat, RootState> = {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	actions,
	getters
};
