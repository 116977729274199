
import { Component, Vue, Prop } from "vue-property-decorator";
import { uniqueId } from "lodash";

@Component({})
export default class TitleControl extends Vue {
	@Prop() isValid: boolean;
	@Prop() disabled: boolean;
	@Prop() value: number;
	@Prop() invalidFeedback: string;

	private onInput(val: string) {
		this.$emit("input", val);
	}

	private get getId() {
		return uniqueId("template-title-input-");
	}
}
