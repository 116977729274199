import { MutationTree } from "vuex";
import { ResponsesState, Response, ResponseTestResult } from "./types";
import { getDefaultState } from "./state";
import Vue from "vue";

export const mutations: MutationTree<ResponsesState> = {
	setResponses(state: ResponsesState, data: Response[]) {
		const responseIds = state.responses.map(x => x.responseID);
		state.responses.push(...data.filter(x => !responseIds.includes(x.responseID)));
	},
	setFilteredResponses(state: ResponsesState, data: Response[]) {
		state.filteredResponses.push(...data);
	},
	resetResponses(state: ResponsesState) {
		state.responses = [];
		state.pageIndex = 1;
	},
	resetFilteredResponses(state: ResponsesState) {
		state.filteredResponses = [];
		state.filterPageIndex = 1;
	},
	addNewResponses(state: ResponsesState, response: Response) {
		state.responses.push(response);
	},
	updateResponse(state: ResponsesState, response: Response) {
		let index = state.responses.findIndex(x => x.responseID == response.responseID);

		if (index !== -1) {
			Vue.set(state.responses, index, response);
		}

		index = state.filteredResponses.findIndex(x => x.responseID === response.responseID);
		if (index !== -1) {
			Vue.set(state.filteredResponses, index, response);
		}
	},
	deleteResponse(state: ResponsesState, responseId: number) {
		if (responseId <= 0) {
			return;
		}

		let index = state.responses.findIndex(x => x.responseID === responseId);
		if (index !== -1) {
			state.responses.splice(index, 1);
		}

		index = state.filteredResponses.findIndex(x => x.responseID === responseId);
		if (index !== -1) {
			state.filteredResponses.splice(index, 1);
		}
	},
	setfailedResponse(state: ResponsesState, response: Response | null) {
		state.failedResponse = response;
	},
	setAlarmModalVisible(state: ResponsesState, isVisible: boolean) {
		state.alarmModalOpened = isVisible;
	},
	setCurrentAlarm(state: ResponsesState, alarm: Response) {
		state.currentAlarm = alarm;
	},
	setPageIndex(state: ResponsesState, pageIndex: number) {
		state.pageIndex = pageIndex;
	},
	setFilter(state: ResponsesState, filter: string) {
		state.filter = filter;
	},
	setFilterPageIndex(state: ResponsesState, pageIndex: number) {
		state.filterPageIndex = pageIndex;
	},
	setAllResponsesRetrieved(state: ResponsesState, allRetrieved: boolean) {
		state.allResponsesRetrieved = allRetrieved;
	},
	setAllFilteredResponsesRetrieved(state: ResponsesState, allRetrieved: boolean) {
		state.allFilteredResponsesRetrieved = allRetrieved;
	},
	setResponseTableIsBusy(state: ResponsesState, isBusy: boolean) {
		state.responseTableIsBusy = isBusy;
	},
	setAlarmSetupModalLoaded(state: ResponsesState, flag: boolean) {
		state.alarmSetupModalLoaded = flag;
	},
	setServerId(state: ResponsesState, serverId: number | null) {
		state.serverId = serverId;
	},
	resetState(state: ResponsesState) {
		Object.assign(state, getDefaultState());
	}
};
