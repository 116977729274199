
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Action, Getter, State, namespace } from "vuex-class";
import { AuthenticationStatus, FeaturesList, LoginResult, User } from "@/store/types";
import MFA from "./MFA.vue";
import axios from "axios";
import api from "@/services/api.service";
import MobileMixin from "@/mixins/MobileMixin";

const UserContext = namespace("userContext");

export enum LoginSteps {
	Email = 0,
	Password = 1,
	SAML = 2,
}

@Component({
	components: { "mfa" : MFA },
	data() { return { AuthenticationStatus } },
})
export default class Login extends Mixins(MobileMixin) {
	public readonly steps = LoginSteps;
	public readonly isSamlAuthEnabled: boolean = process.env.VUE_APP_AUTHENTICATION_SAML_ENABLED === "true";

	public user: string = "";
	public password: string = "";
	public loggingIn: boolean = false;
	public error: string = "";
	public redirectPath: string = "";
	public authenticationState: AuthenticationStatus = AuthenticationStatus.None;
	public authCode: string = "";

	public step: LoginSteps = this.steps.Email;
	private IsMFAEnabled: boolean = false;
	private isAudioAlertActive: boolean = false;
	private audioAlertInterval;

	private axiosInstance;

	@Action("login") private loginUser: (LoginRequest) => Promise<LoginResult>;
	@Action serverLogout: any;
	@Action logout: () => void;
	@State private User: User;

	@Getter("getVersion") private webVersion;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Action startApiHeartbeat: any;
	@UserContext.Action("fetchOverview") fetchOverviewUserContext: () => Promise<void>;
	@Getter getUserEmail: string;
	@Getter ("getIsOnPrem") private isOnPrem: boolean;
	@Getter("getRequiresConfiguration") private requiresConfiguration: boolean;
	@Action fetchAppOptions: () => Promise<void>;
	@Action fetchRequiresConfig: () => Promise<void>;

	@Action getElevationLabels: () => Promise<void>;

	private async beforeCreate(): Promise<void> {

		// We create a new axios instance here so we can bi-pass the interceptors.
		// Otherwise when viewing the login page an error would be displayed information the user that their session has expired.
		this.axiosInstance = axios.create({
			baseURL: process.env.VUE_APP_HTTP_API_URI,
			withCredentials: true,
			headers: {
				Accept: "application/json, text/javascript, */*; q=0.01",
				"Content-Type": "application/x-www-form-urlencoded"
			}
		});

		try {
			let result = await this.axiosInstance.get("/session/HeartbeatLoginSession");
			if (result.status === 200) {
				this.authenticationState = AuthenticationStatus.Success;
				this.$router.push("event-queue");
			}
		} catch (ex) {
			if (ex.response.status !== 401) {
				console.log(ex);
			}
		}
	}

	private async created() {
		// display error on ui and remove error param from query string
		if (this.$route.query.error) {
			this.error = this.$route.query.error.toString();
			this.removeQueryParam("error");
		}

		const username = this.$route.query.user as string;
		if(username)
		{
			if(username.toLowerCase() === this.getUserEmail?.toLowerCase()){
				this.$router.push("event-queue");
			}
			else {
				this.user = decodeURI(username);
				await this.suiteLogin(this.user);
				return;
			}
		}

		if(this.isOnPrem == null)
		{
			await this.fetchAppOptions();

			if(this.isOnPrem)
			{
				await this.fetchRequiresConfig();
			}
		}

		this.redirectIfRequiresConfiguration();
	}

	private mounted(): void {
		this.playSessionExpiryAudioAlertIfEnabled();
	}

	private playSessionExpiryAudioAlertIfEnabled(): void {
		const sessionExpiredValue = this.$route.query.hasSessionExpired;
		const playAudioAlertValue = this.$route.query.playAudioAlert;
		this.isAudioAlertActive = sessionExpiredValue && sessionExpiredValue === "true" && playAudioAlertValue && playAudioAlertValue === "true";
		if (this.isAudioAlertActive) {
			this.audioAlertInterval = setInterval(() => {
				const audio = document.getElementById("sessionExpiredAlert");
				audio && (audio as HTMLAudioElement).play();
			}, 1000);
		}
	}

	private destroyed() {
		clearInterval(this.audioAlertInterval);
	}

	private async suiteLogin(user: string): Promise<void> {
		this.loggingIn = true;
		let generateSaml = await api.suiteSamlGenerate(user, this.isLoadedInApp());
		try
		{
			const suiteEndpoint = generateSaml.data.authorizationEndpoint;
			if (suiteEndpoint)
			{
				this.checkIfRedirect();
				window.location.href=suiteEndpoint;
			}
			else
			{
				this.loggingIn = false;
			}
		}
		catch
		{
			this.error = "Unknown error occurred"
			this.loggingIn = false;
		}
	}

	public async login() {
		this.loggingIn = true;

		if (this.isAudioAlertActive) {
			this.isAudioAlertActive = false;
			clearInterval(this.audioAlertInterval);
		}

		// Save our redirect path once we've successfully logged in
		if (this.$route.query.redirect && this.$route.query.redirect !== "") {
			this.redirectPath = this.$route.query.redirect.toString();
		}

		try {
			let loginResult: LoginResult = await this.loginUser({
				username: this.user,
				password: this.password,
				authCode: this.authCode
			});

			let redirectPath = "event-queue";

			switch (loginResult.authenticationStatus) {
				case AuthenticationStatus.Success:
					// If we saved a redirect when this page was first loaded, now we've logged in, go to the redirect path
					if (this.redirectPath && this.redirectPath !== "") {
						redirectPath = this.redirectPath;
					} else if (loginResult.redirectRoute) {
						redirectPath = loginResult.redirectRoute;
					}

					this.startApiHeartbeat();

					// don't await this as it could take a while to return
					this.fetchOverviewUserContext();

					this.$router.push(redirectPath);

					await this.getElevationLabels();

					break;

				case AuthenticationStatus.DisabledUser:
					this.error = "Your account has been disabled.";
					break;

				case AuthenticationStatus.LockedOut:
					this.error = "You have been locked out due to too many password attempts.";
					return;

				case AuthenticationStatus.PasswordExpired:
					this.error = "Your account credentials are expired.";
					break;

				case AuthenticationStatus.ConfigureMfa:
					this.authenticationState = AuthenticationStatus.ConfigureMfa;
					return;
			}
		} catch (ex) {
			if (ex.response && ex.response.status === 401) {
				this.error = "Username, Password, or MFA token are missing or incorrect."
			} else {
				if (ex.message === "Network Error") {
					this.error = "Error establishing connection to SureView Operations.";
				} else {
					console.log(`Unexpected error occurred: ${ex.message}. Stack trace - ${ex.stack || ex}`);
					this.error = "Something went wrong, please try again later and contact support if the issue persists";
				}
			}
		} finally {
			this.loggingIn = false;
		}
	}

	private async SetRedirectCookie(url: string) {
		const now = new Date();
		const time = now.getTime();
		const expireTime = time + 1000 * 360; // 5 mins
		now.setTime(expireTime);
		document.cookie = `SsoRedirect=${url};expires=${now.toUTCString()};path=/`;
	}

	private mfaSet() {
		this.authenticationState = AuthenticationStatus.None
		this.IsMFAEnabled = true;
	}

	private async checkIfAccountAdminWithSaml(): Promise<boolean> {
		let adminWithSamlResult = await this.axiosInstance.get("/login/IsAccountAdminWithSaml?username=" + encodeURIComponent(this.user));
		if (adminWithSamlResult.status === 200) {
			this.IsMFAEnabled = adminWithSamlResult.data.isMfaEnabled;
			if (adminWithSamlResult.data.isAccountAdminWithSaml) {
				//if the account is a account admin with saml, move to the SAML Step
				this.step = this.steps.SAML;
				return true;
			}
			return false;
		}
		return false;
	}

	private async checkIfAccountSuite(): Promise<boolean> {
		try
		{
			let response = await this.axiosInstance.get("/login/IsAccountSuite?username=" + encodeURIComponent(this.user));
			if (response.status === 200) {
				return response.data;
			}
		}
		catch(err)
		{
			console.error(err)
		}
		return false;
	}

	private async next(skipAccountAdminCheck: boolean = false) {
		this.loggingIn = true;

		if (this.$route.query.hasSessionExpired) {
			this.removeQueryParam("hasSessionExpired");
		}
		this.error = "";

		try
		{
			if (await this.checkIfAccountSuite())
			{
				await this.suiteLogin(this.user);
				return;
			}

			//If we don't skip the admin saml check and the account is an admin with saml don't try logging in just yet
			if (!skipAccountAdminCheck && await this.checkIfAccountAdminWithSaml()) {
				this.loggingIn = false;
				return;
			}

			let loginProviderResult = await this.axiosInstance.get("/login/provider?username=" + encodeURIComponent(this.user));
			if (loginProviderResult.status === 200) {
				if (loginProviderResult.data.isExternal) {
					this.checkIfRedirect();
					location.href = loginProviderResult.data.authorizationEndpoint;
				} else {
					this.step = this.steps.Password;
					this.IsMFAEnabled = loginProviderResult.data.isMFAEnabled;
				}
			}
		} catch (ex) {
			this.error = "Something went wrong, please try again later and contact support if the issue persists";
		}
		this.loggingIn = false;
	}

	private checkIfRedirect(): void {
		// Get the desired root before we change pages, so we can reset it after login
		if (window.location.hash) {
			const decodedHash = decodeURIComponent(window.location.hash);
			let route = undefined;
			if (window.location.hash.includes("redirect=")) {
				route = decodedHash.split("redirect=");
			} else {
				route = decodedHash.split("#/");
			}

			if (route && route[1] && route[1] != "" && route[1] != " " && route[1] != "undefined") {
				// set a short life cookie that will store the intended destination, so we can redirect after successful login
				this.SetRedirectCookie(route[1]);
			}
		}
	}

	private back() {
		this.step = this.steps.Email;
	}

	private toPasswordStep(): void {
		this.step = this.steps.Password;
	}

	private removeQueryParam(param: string): void {
		const query = Object.assign({}, this.$route.query);
		delete query[param];
		this.$router.replace({ query });
	}

	@Watch("requiresConfiguration")
	private redirectIfRequiresConfiguration(): void
	{
		if(this.isOnPrem && this.requiresConfiguration !== false)
		{
			this.$router.push( {path: "/signup"} );
		}
	}
}
