
// Vue
import { TimeZone, UserPermissions } from "@/store/types";
import {
	Schedule,
	CalendarRangeType,
	CalendarRangeWeek,
	CalendarRangeTypeOptions,
	PublicHolidayDto
} from "@/store/schedules/types";
import moment, { unitOfTime } from "moment";
import vSelect3 from "vselect3";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, namespace, State } from "vuex-class";
import { ScheduleInfo, EventColor } from "./ScheduleEnums";
import { debounce, isNil } from "lodash";
import axios, { CancelTokenSource } from "axios";
import SureViewIcon from "../SureViewIcon.vue";
import { CalendarTimestamp, CalendarEvent } from "vuetify";
import api from "@/services/api.service";
import schedulesApi from "@/services/api.schedules.service";
import AreaDateTimeDisplay from "@/components/AreaDateTimeDisplay.vue";

// Namespaces
const SchedulesStore = namespace("schedules");

const MinSlotPeriod = 5; // minutes
const debouncePeriod: number = process.env.NODE_ENV === "test" ? 1 : 500;
const LeftMouseButton: number = 0;

@Component({
	components: {
		"v-select-3": vSelect3,
		"sureview-icon": SureViewIcon,
		"area-date-time-display": AreaDateTimeDisplay,
	},
})
export default class SchedulesCalendar extends Vue {
	$refs!: {
		calendar: any;
	};

	// Root store.
	@State private timeZones: TimeZone[];
	@Getter("getPermissions") private permissions: UserPermissions;
	@Getter getTimeZone: (timeZoneId: number) => TimeZone;

	// Schedule store
	// State
	@SchedulesStore.State("isLoading") private isLoading: boolean;
	@SchedulesStore.State("schedules") private schedules: Schedule[];
	@SchedulesStore.State("tempSchedule") private tempSchedule!: Schedule;
	@SchedulesStore.State("isReloadRequired") private isReloadRequired: boolean;
	@SchedulesStore.State("searchString") private searchString: string;
	@SchedulesStore.State("selectedGroup") private selectedGroup: number;
	@SchedulesStore.State("selectedScheduleTypes") private selectedScheduleTypes: number[];
	@SchedulesStore.State("enabledScheduleFilter") private enabledScheduleFilter: boolean | null;
	@SchedulesStore.State("areaTimeZoneId") private areaTimeZoneId: number | null;

	// Mutations
	@SchedulesStore.Mutation setIsLoading: (isLoading: boolean) => void;
	@SchedulesStore.Mutation setIsScheduleModalVisible: (isVisible: boolean) => void;
	@SchedulesStore.Mutation setSelectedScheduleId: ({ selectedScheduleId, selectedScheduleStart }: {selectedScheduleId: string; selectedScheduleStart: Date; }) => void;
	@SchedulesStore.Mutation setIsReloadRequired: (isReloadRequired: boolean) => void;
	@SchedulesStore.Mutation setTempSchedule: (tempSchedule: Schedule) => void;
	@SchedulesStore.Mutation removeTempSchedule: () => void;
	@SchedulesStore.Mutation setEndTimeForTempSchedule: (endTime: string) => void;
	@SchedulesStore.Mutation setStartTimeForTempSchedule: (startTime: string) => void;
	@SchedulesStore.Mutation setAreaTimeZoneId: (timeZoneId: number | null) => void;

	// Actions
	@SchedulesStore.Action filterSchedules: ({
		filterString,
		selectedGroup,
		selectedScheduleTypes,
		startDate,
		endDate,
		cancelToken,
		enabled,
    }) => Promise<void>;

	private focus: string | null = null;
	private type: CalendarRangeType = CalendarRangeWeek;
	private types: CalendarRangeType[] = CalendarRangeTypeOptions;
	private events: CalendarEvent[] = [];
	private publicHolidays: PublicHolidayDto[] = [];

	private createEvent: CalendarEvent | null = null;
	private createStart: number | null = null;

	private creatingEvent: boolean = false;
	private filterToken: CancelTokenSource | null = null;
	private calendarViewStart: string | null = null;
	private calendarViewEnd: string | null = null;

	private isoFormatWithoutDst: string = 'YYYY-MM-DDTHH:mm:ss';

	private get canEditSchedules(): boolean {
		if (this.permissions.isSystemAdmin !== undefined && this.permissions.isSystemAdmin) {
			return this.permissions.isSystemAdmin;
		}

		if (this.permissions.canEditSchedules !== undefined && this.permissions.canEditSchedules) {
			return this.permissions.canEditSchedules;
		}

		return false;
	}

	private get isDisabled(): boolean {
		return this.selectedGroup <= 0;
	}

	private get timeZone() : string {
		if (!this.areaTimeZoneId || !this.timeZones) {
			return "";
		}

		return this.getTimeZone(this.areaTimeZoneId)?.id;
	}

	beforeDestroy(): void {
		this.setAreaTimeZoneId(null);
	}

	private async next(): Promise<void> {
		this.$refs.calendar.next();
		await this.triggerGetEvents();
	}
	private async prev(): Promise<void> {
		this.$refs.calendar.prev();
		await this.triggerGetEvents();
	}

	private async setToday(): Promise<void> {
		this.focus = "";
		await this.triggerGetEvents();
	}

	private async setAreaTimeZone(): Promise<void> {
		let { timeZoneID } : { timeZoneID: number } = await api.loadGroup(this.selectedGroup);
		this.setAreaTimeZoneId(timeZoneID);
	}

	@Watch("schedules")
	@Watch("tempSchedule")
	private onChangesInSchedules(): void {
		if (this.tempSchedule === null) {
			this.reSetEvents();
		}
		this.setIsLoading(false);
	}

	@Watch("isReloadRequired")
	private async onReloadRequiredChanged(changeRequired: boolean): Promise<void> {
		if (changeRequired) {
			await this.triggerGetEvents();
			this.setIsReloadRequired(false);
		}
	}

	@Watch("selectedGroup")
	private async onGroupChange(): Promise<void> {
		if (this.selectedGroup) {
			await this.setAreaTimeZone();
		}

		await this.triggerGetEvents();
	}

	public async mounted(): Promise<void> {}

	private reSetEvents(): void {
		const endDateOfCalendar = new Date(this.calendarViewEnd);

		const scopedYear = endDateOfCalendar.getFullYear();

		this.events = [];
		this.schedules.map((schedule) => {
			this.events.push({
				id: schedule.scheduleId,
				name: `${schedule.disabled ? "Disabled - " : ""}${schedule.title}`,
				timestampStartDate: schedule.startDateTime,
				start: moment(schedule.startDateTime).valueOf(),
				end: this.getEndTimestamp(schedule),
				color: this.getEventColor(schedule),
				timed: true,
				timeZone: this.timeZone
			} as CalendarEvent)
		});

		this.publicHolidays.map((publicHoliday) => {
			let startDate = new Date(publicHoliday.year == 0 ? scopedYear : publicHoliday.year,
				publicHoliday.month - 1,
				publicHoliday.day).toString();

			this.events.push({
				id: publicHoliday.publicHolidayId,
				name: publicHoliday.title,
				timestampStartDate: startDate,
				start: moment(startDate).valueOf(),
				color: EventColor.Green,
				timed: false,
				timeZone: this.timeZone,
				isPublicHoliday: true,
			} as CalendarEvent);
		});
	}

	private getEndTimestamp(schedule: Schedule): number {
		return moment(schedule.endDateTime).valueOf();
	}

	private getEventColor(schedule: { scheduleTypeId?: number, color?: string, disabled: boolean }): string {
		if (!schedule) {
			return EventColor.DefaultColour;
		}

		if (schedule.disabled) {
			return EventColor.Grey;
		}

		if (schedule.scheduleTypeId) {
			return ScheduleInfo.find((x) => x.id == schedule.scheduleTypeId)?.color ?? EventColor.DefaultColour;
		}

		if (schedule.color) {
			return schedule.color;
		}

		return EventColor.DefaultColour;
	}

	private showEvent(eventId: string, eventStart: Date): void {
		this.setSelectedScheduleId({ selectedScheduleId: eventId, selectedScheduleStart: eventStart });
		this.setIsScheduleModalVisible(true);
	}

	private startDrag({ event, nativeEvent }: { event: CalendarEvent, nativeEvent: MouseEvent }): boolean {
		if (event.isPublicHoliday) {
			return false;
		}

		if (nativeEvent.button != LeftMouseButton){
			return false;
		}

		if (event.id != null && event.id != undefined) {
			this.endDrag(null, nativeEvent);
			this.cancelDrag();
			this.showEvent(event.id, event.timestampStartDate);
			nativeEvent.stopImmediatePropagation();
			return false;
		}

		return true;
	}

	private startTime(tms: {year: number, month: number, day: number, hour: number, minute: number}, mouseEvent: MouseEvent): void {
		if(mouseEvent.button != LeftMouseButton){
			return;
		}

		if (!this.canEditSchedules) {
			return;
		}

		const mouse = this.toTime(tms);
		this.creatingEvent = true;
		this.createStart = this.roundTime(mouse);
		this.createEvent = {
			name: `Event #${this.events.length}`,
			start: this.createStart,
			end: this.createStart,
			timed: true,
		} as CalendarEvent;

		let tempSchedule = {
			scheduleId: "",
			title: this.createEvent!.name,
			startDateTime: moment(this.createEvent!.start).format(this.isoFormatWithoutDst).toString(),
			endDateTime: moment(this.createEvent!.end).format(this.isoFormatWithoutDst).toString(),
			scheduleTypeId: 1,
			intervalValue: 1,
			intervalTypeId: null,
			expiresOn: null,
			maxOccurrences: null,
			timeZoneId: this.areaTimeZoneId,
		} as Schedule;

		this.setTempSchedule(tempSchedule);
		this.events.push(this.createEvent);
	}

	private mouseMove(tms: { year: number, month: number, day: number, hour: number, minute: number }): void {
		const mouse: number = this.toTime(tms);
		if (this.createEvent && this.createStart !== null) {
			const mouseRounded = this.roundTime(mouse, false);
			const min = Math.min(mouseRounded, this.createStart);
			const max = Math.max(mouseRounded, this.createStart);

			this.createEvent.start = min;
			this.createEvent.end = max;

			this.setStartTimeForTempSchedule(moment(this.createEvent.start).format(this.isoFormatWithoutDst));
			this.setEndTimeForTempSchedule(moment(this.createEvent.end).format(this.isoFormatWithoutDst));
		}
	}

	private endDrag(event: {date: string, time: string }, mouseEvent: MouseEvent): void {
		if(mouseEvent.button != LeftMouseButton){
			return;
		}

		if (this.creatingEvent) {
			if (!isNil(event)) {
				this.setNewEndDate(event);
			}

			this.setDefaultToInterationVariables();
			this.setIsScheduleModalVisible(true);
			this.creatingEvent = false;
		}
	}

	private setNewEndDate(event: { date: string, time: string }): void {
		const newEndDate = moment(event.date + " " + event.time).valueOf();
		const newEndDateRounded = this.roundTime(newEndDate, false);
		if (newEndDateRounded !== this.createEvent.start) {
			this.setEndTimeForTempSchedule(moment(newEndDateRounded).toISOString());
		}
	}

	private setDefaultToInterationVariables(): void {
		this.createEvent = null;
		this.createStart = null;
	}

	private cancelDrag(): void {
		if (this.createEvent) {
			const i = this.events.indexOf(this.createEvent);
			if (i !== -1) {
				this.events.splice(i, 1);
			}
			this.removeTempSchedule();
		}
	}

	private roundTime(time: number, down: boolean = true): number {
		const roundDownTime = MinSlotPeriod * 60 * 1000;

		return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
	}

	private toTime(tms: {year: number, month: number, day: number, hour: number, minute: number}): number {
		return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
	}

	private async triggerGetEvents(): Promise<void> {
		if (this.isDisabled) {
			return;
		}

		await this.performGetEvents(this.calendarViewStart, this.calendarViewEnd);
	}

	private performGetEvents: (calendarViewStart: string, calenderViewEnd: string) => Promise<void> = debounce(
		async function (calendarViewStart: string, calendarViewEnd: string) {
			if (this.filterToken) {
				this.filterToken.cancel("Cancel Search");
			}

			this.filterToken = axios.CancelToken.source();
			try {
				await this.getEvents(calendarViewStart, calendarViewEnd);
			} catch (e) {
				return; //Return when past requests are cancelled
			}
		},
		debouncePeriod,
		{ leading: false, trailing: true }
	);

	private async getEvents(calendarViewStart: string, calendarViewEnd: string): Promise<void> {
		if (this.isDisabled) {
			return;
		}

		let searchStartDate = moment.utc(calendarViewStart).set({ hours: 0, minutes: 0, seconds: 0 }).toDate();
		let searchEndDate = moment.utc(calendarViewEnd).set({ hours: 23, minutes: 59, seconds: 59 }).toDate();

		await this.filterSchedules({
			filterString: this.searchString,
			selectedGroup: this.selectedGroup,
			selectedScheduleTypes: this.selectedScheduleTypes,
			startDate: searchStartDate,
			endDate: searchEndDate,
			cancelToken: this.filterToken,
			enabled: this.enabledScheduleFilter,
		});

		this.publicHolidays = await schedulesApi.getPublicHolidaysBetweenDates(
			searchStartDate.toISOString(),
			searchEndDate.toISOString(),
			this.selectedGroup);


		this.reSetEvents();
	}

	private async triggerChange({ start, end }: { start: CalendarTimestamp, end: CalendarTimestamp }): Promise<void> {
		let calendarRangeType: unitOfTime.StartOf = this.type as unitOfTime.StartOf;

		this.calendarViewStart = moment(start.date).startOf(calendarRangeType).format();
		this.calendarViewEnd = moment(end.date).endOf(calendarRangeType).format();

		await this.triggerGetEvents();
	}

	private getCalendarTypeLabel(value: string): string {
		return value[0].toUpperCase() + value.slice(1);
	}
}
