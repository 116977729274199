
import { namespace } from "vuex-class";
import { Component, Vue, Watch, Emit, Prop } from "vue-property-decorator";
import vSelect from "vue-select";
import RulesText from "@/components/rules-engine-setup/RulesText.vue";
import RulesNumberInput from "@/components/rules-engine-setup/data-components/RulesNumberInput.vue";
import RulesAlarmDropdown from "@/components/rules-engine-setup/data-components/RulesAlarmDropdown.vue";
import {
	CardValidation,
	RuleGenericDropdownItem,
	RuleScope,
	RuleServer,
	RuleServerType,
	RuleServerTypeEvent,
	ServersForServerType,
	ServerTypeEventsForServerType
} from '@/store/rules-engine/types';
import RuleGenericDropdown from '@/components/rules-engine-setup/data-components/RuleGenericDropdown.vue';
import { isEqual } from "lodash";

const RuleStore = namespace('rulesEngine');

enum SelectedValueType {
	device = 1,
	alarm = 2,
}

@Component({
	components: {
		"vue-select": vSelect,
		"rules-text": RulesText,
		"rules-number-input": RulesNumberInput,
		"rules-alarm-dropdown": RulesAlarmDropdown,
		"rule-generic-dropdown": RuleGenericDropdown
	}
})
export default class RulesScope extends Vue {
	@RuleStore.Getter private getScopeCriteria: any[];
	@RuleStore.Action private retrieveComponentForTypeId: (request: {section: string, typeId: number}) => Promise<any>;
	@RuleStore.Action private retrieveRuleServersForServerType :(serverTypeId: number) => Promise<void>;
	@RuleStore.Action private retrieveRuleServerTypeEventsForServerType :(serverTypeId: number) => Promise<void>;
	@RuleStore.State private serversForServerType: ServersForServerType[];
	@RuleStore.State private serverTypeEventsForServerType : ServerTypeEventsForServerType[];
	@RuleStore.State private serverTypes: RuleServerType[];

	@Prop()
	private value: RuleScope;

	@Prop({ type: Boolean, default: false })
	private readOnly: boolean;

    private section: string = "Scope";

	private alarmText: string = "";
    private deviceTypeComponent: any = null;
	private serverOptions: RuleGenericDropdownItem[] = [];
	private serverTypeEventOptions: RuleGenericDropdownItem[] = [];

	private scopeDetails: RuleScope = {
		responseId: null,
		serverId: null,
		serverTypeId: null,
		eventNum: null,
		input1: null,
		input2: null
	};

	private selectedValue: string = null;

    private get deviceTypeComponentSet(): boolean
    {
        return !!this.deviceTypeComponent?.component;
    }

	private async selectedCriteriaUpdated(value): Promise<void>
    {
		this.resetScopeDetails();
		this.selectedValue = value.title;

		if (value.id == SelectedValueType.alarm) {
			this.alarmTypeSelected();
		} else {
			this.deviceTypeSelected();
			await this.setDeviceComponent();
		}
    }

	private async setDeviceComponent(): Promise<void> {
		this.deviceTypeComponent = null;
		this.deviceTypeComponent = await this.retrieveComponentForTypeId({section: this.section, typeId: SelectedValueType.device});
	}

	@Watch("value")
	private async onRuleScopeChanged(newValue: RuleScope, oldValue: RuleScope): Promise<void> {
		if (!!this.value && !isEqual(newValue, oldValue)) {
			this.scopeDetails.responseId = this.value.responseId;
			this.scopeDetails.eventNum = this.value.eventNum;
			this.scopeDetails.serverId = this.value.serverId;
			this.scopeDetails.serverTypeId = this.value.serverTypeId;
			this.scopeDetails.input1 = this.value.input1;
			this.scopeDetails.input2 = this.value.input2;

			if (this.scopeDetails.responseId) {
				this.selectedValue = this.getScopeCriteria.firstOrDefault(c => c.title == "Alarm Type").title;
				this.alarmText = this.scopeDetails.responseId.toString();
			} else if (this.scopeDetails.serverTypeId) {
				this.selectedValue = this.getScopeCriteria.firstOrDefault(c => c.title == "Device Type").title;
				this.setDeviceComponent();

				const server = this.serversForServerType?.find((s: ServersForServerType) => s.serverTypeId === this.scopeDetails.serverTypeId);
				const event = this.serverTypeEventsForServerType?.find((s: ServerTypeEventsForServerType) => s.serverTypeId === this.scopeDetails.serverTypeId);

				if(!server) {
					await this.retrieveRuleServersForServerType(this.scopeDetails.serverTypeId);
				}

				if(!event) {
					await this.retrieveRuleServerTypeEventsForServerType(this.scopeDetails.serverTypeId);
				}

				this.onServersChanged();
				this.onServerTypeEvents();
			}
		}
	}

	@Watch("selectedValue")
	private onSelectedValueChanged(): void {
		if(this.selectedValue === "Device Type") {
			this.setDeviceComponent();
		}
	}

	@Emit("isValid")
	private validationChanged(value: CardValidation): CardValidation {
		return value;
	}

	private deviceTypeComponentValid(value: CardValidation): void {
		this.validationChanged(value);
	}

	private alarmTypeSelected(): void {
		this.resetScopeDetails();
	}

	@Emit("input")
	private resetScopeDetails(): RuleScope {
		this.alarmText = "";

		this.scopeDetails = {
			responseId: null,
			serverId: null,
			serverTypeId: null,
			eventNum: null,
			input1: null,
			input2: null
		};

		return this.scopeDetails;
	}

	private deviceTypeSelected(): void {
		this.scopeDetails.responseId = null;
	}

	@Emit("input")
	private alarmTypeUpdated(value: string): RuleScope {
		this.scopeDetails.responseId = value ? parseInt(value) : null;
		return this.scopeDetails;
	}

	@Emit("input")
	private deviceTypeUpdated(value: number): RuleScope {
		this.resetScopeDetails();
		this.scopeDetails.serverTypeId = value;
		this.retrieveRuleServersForServerType(this.scopeDetails.serverTypeId);
		this.retrieveRuleServerTypeEventsForServerType(this.scopeDetails.serverTypeId);

		return this.scopeDetails;
	}

	@Watch("scopeDetails", {deep: true})
	@Emit("input")
	private onScopeDetailsChanged(): RuleScope {
		return this.scopeDetails;
	}

	@Watch("serversForServerType")
	private onServersChanged(): void {

		if (!!this.scopeDetails.serverTypeId && !!this.serversForServerType) {
			const servers = this.serversForServerType.firstOrDefault(
				(s: ServersForServerType) => s.serverTypeId === this.scopeDetails.serverTypeId)?.servers ?? [];

			this.serverOptions = servers.map((s: RuleServer) => { return { id: s.serverId, title: s.title}});
		} else {
			this.serverOptions = [];
		}
	}

	@Watch("serverTypeEventsForServerType")
	private onServerTypeEvents(): void {
		if (!!this.scopeDetails.serverTypeId && !!this.serverTypeEventsForServerType) {
			const events =  this.serverTypeEventsForServerType?.firstOrDefault(
				(s: ServerTypeEventsForServerType) => s.serverTypeId === this.scopeDetails.serverTypeId)?.serverTypeEvents ?? [];
			this.serverTypeEventOptions = events.map((s: RuleServerTypeEvent) => {return {id: s.eventNum, title: s.title}});

		} else {
			this.serverTypeEventOptions = [];
		}
	}

	private serverIdUpdated(serverId: number): void {
		this.scopeDetails.serverId = serverId;
	}

	private serverTypeEventUpdated(eventNum: number): void {
		this.scopeDetails.eventNum = eventNum;
	}
}
