import { MutationTree } from "vuex";
import { ExternalMapLayerState, ExternalMapLayer, ExternalMapLayerType } from "./types";
import { getDefaultState } from "./state";
import Vue from "vue";

export const mutations: MutationTree<ExternalMapLayerState> = {
	setExternalMapLayers(state, externalMapLayers: ExternalMapLayer[]) {
		state.externalMapLayers = externalMapLayers;
	},
	setExternalMapLayerTypes(state, externalMapLayerTypes: ExternalMapLayerType[]) {
		state.externalMapLayerTypes = externalMapLayerTypes;
	},
	updateExternalMapLayer(state, updateExternalMapLayer: ExternalMapLayer) {
		const index = state.externalMapLayers.findIndex(
			(item: ExternalMapLayer) => item.externalMapLayerID === updateExternalMapLayer.externalMapLayerID
		);

		if (index >= 0) {
			Vue.set(state.externalMapLayers, index, updateExternalMapLayer);
		}
	},
	addNewExternalMapLayer(state, externalMapLayers: ExternalMapLayer) {
		if (
			state.externalMapLayers.findIndex(
				(x: ExternalMapLayer) => x.externalMapLayerID == externalMapLayers.externalMapLayerID
			) == -1
		) {
			state.externalMapLayers.push(externalMapLayers);
		}
	},
	removeExternalMapLayer(state, externalMapLayerId: number) {
		const index = state.externalMapLayers.findIndex(
			(item: ExternalMapLayer) => item.externalMapLayerID === externalMapLayerId
		);
		state.externalMapLayers.splice(index, 1);
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
