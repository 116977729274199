import { Module } from "vuex";
import { RootState } from "@/store/types";
import { ManualRaiseState } from "./types";
import { mutations } from "./mutations";
import { actions } from "./actions";

export function getDefaultState(): ManualRaiseState {
	return {
		manualRaiseShown: false,
		onCompleteHandler: null,
		areaMapBounds: null,
		alarmMarkerPosition: null,
		selectedGroup: null,
	};
}

export const manualRaise: Module<ManualRaiseState, RootState> = {
	state: getDefaultState(),
	actions,
	mutations,
	namespaced: true
};
