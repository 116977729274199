import { TextValidationResult } from "@/types/text-validation-result";

/**
 * Extracts a named property from XML content.
 * @param xml The xml content.
 * @param propertyName The name of the property to extract.
 */
export function extractPropertyFromXml(xml: string = "", propertyName: string): string {
	const xmlToLower = xml.toLowerCase();
	const propertyNameToLower = propertyName.toLowerCase();

	const propertyStartTag = `<${propertyNameToLower}>`;
	const propertyEndTag = `</${propertyNameToLower}>`;

	return xml.substring(
		xmlToLower.indexOf(propertyStartTag) + propertyStartTag.length,
		xmlToLower.indexOf(propertyEndTag)
	);
}

/**
 * Extracts a named property from XML content - return "" if not found
 * @param xml The xml content.
 * @param propertyName The name of the property to extract.
 */
export function extractPropertyFromXmlWithContainsCheck(xml: string = "", propertyName: string): string {
	const xmlToLower = xml.toLowerCase();
	const propertyNameToLower = propertyName.toLowerCase();
	if (xmlToLower.includes(propertyNameToLower)) {
		const propertyStartTag = `<${propertyNameToLower}>`;
		const propertyEndTag = `</${propertyNameToLower}>`;
		return xml.substring(
			xmlToLower.indexOf(propertyStartTag) + propertyStartTag.length,
			xmlToLower.indexOf(propertyEndTag)
		);
	}
	return "";
}

/**
 * Confirms if the provided input is a valid regex pattern.
 * Return the valid status and an error message if invalid.
 * @param input The regex pattern to check
 */
export function isRegexValid(input: string): TextValidationResult {
	try {
		new RegExp(input);
		return { isValid: true, message: "" };
	} catch {
		return { isValid: false, message: "Provided regex is invalid" };
	}
}
