export enum EndTypes {
    None = "None",
    OnDate = "On date",
    After = "After"
}

export enum EventColor {
	Blue = "#051882",
	Yellow = "#FFD700",
	Orange = "#FF8C00",
	Cyan = "#008B8B",
	Green = "#008000",
	Red = "#FF6347",
	Purple = "#800080",
	DarkSlateBlue = "#483D8B",
	Grey = "#808080",
	DefaultColour = "transparent"
}

export enum ScheduleTypes {
    ExpectedAlarm = 1,
	Masking = 2,
	EventRaise = 3
}

export let ScheduleInfo: IScheduleInfo[] = [
	{ color: EventColor.Cyan, id: ScheduleTypes.ExpectedAlarm, title: ScheduleTypes[ScheduleTypes.ExpectedAlarm] },
	{ color: EventColor.Orange, id: ScheduleTypes.Masking, title: ScheduleTypes[ScheduleTypes.Masking] },
	{ color: EventColor.Purple, id: ScheduleTypes.EventRaise, title: ScheduleTypes[ScheduleTypes.EventRaise] },
];

export interface IScheduleInfo {
    color: EventColor,
    id: ScheduleTypes,
    title: string
}

export enum DeletionType {
    Single = 1,
    All = 2
}
