import { MutationTree } from "vuex";
import Vue from "vue";
import { TasksState, TaskGroup, ActionPlanTask, UserContactDetails, Task } from "./types";
import { getDefaultState } from "./state";
import { ScriptCategoryLink } from "@/store/tasks/types";
import { orderBy } from "lodash";

const newAction = {
	eventID: "",
	taskCategoryTitle: "",
	taskText: "",
	completed: false
};

export const mutations: MutationTree<TasksState> = {
	setActionPlans(state, data) {
		state.ActionPlans = data;
	},

	setTasks(state, data: any[]) {
		state.Tasks = data;

		// @todo code below is prob related to this.updateEventTasks(); in EventTaskContainer component
		// disabling for now since it's broken: when navigating between different events old tasks persist,
		// new ones load but don't get written to store
		// const tasksGroups: any = {};
		// const tasksGroupsByID: any = {};
		// data.forEach((taskGroup: any) => {
		//   const tasksByID: any = {};
		//   taskGroup.tasks.forEach((task: any) => {
		//     tasksByID[task.eventTaskID] = task;
		//   });
		//   tasksGroupsByID[taskGroup.title] = tasksByID;
		//   tasksGroups[taskGroup.title] = taskGroup;
		// });

		// state.Tasks.forEach((taskGroup: any) => {
		//   const updatedTaskGroup = tasksGroupsByID[taskGroup.title];
		//   if (updatedTaskGroup != null) {
		//     taskGroup.tasks.forEach((task: any) => {
		//       const updatedTask = updatedTaskGroup[task.eventTaskID];
		//       if (updatedTask != null) {
		//         task.completed = updatedTask.completed;
		//         task.assignedToEventUser = updatedTask.assignedToEventUser;
		//       }
		//       delete updatedTaskGroup[task.eventTaskID];
		//     });

		//     // tslint:disable-next-line:prefer-const
		//     for (let newTaskID in updatedTaskGroup) {
		//       taskGroup.tasks.push(updatedTaskGroup[newTaskID]);
		//     }

		//     delete tasksGroupsByID[taskGroup.title];
		//   }
		// });

		// tslint:disable-next-line:prefer-const
		// for (let newTaskCategory in tasksGroupsByID) {
		//   // console.log('New category: ' + newTaskCategory);
		//   state.Tasks.push(tasksGroups[newTaskCategory]);
		// }
	},

	setTaskLibrary(state, data: any) {
		state.TaskLibrary = data;
	},

	setTaskTypes(state, data: any) {
		state.TaskTypes = data;
	},

	setTaskCategories(state, data: any) {
		state.TaskCategories = data;
	},

	addNewTaskToGroup(state, taskGroup: TaskGroup) {
		// @techdebt replace type any with type TaskGroup after tasksService.NEW_TASK refactoring
		state.Tasks = state.Tasks.map((group: any) => {
			if (group.title === taskGroup.title) {
				group.tasks.push({
					...newAction,
					...{
						isNew: true,
						isMine: true,
						taskCategoryTitle: taskGroup.title
					}
				});
			}
			return group;
		});
	},

	addNewTaskToNewGroup(state) {
		state.Tasks = [
			...state.Tasks,
			{
				title: "",
				isNew: true,
				tasks: [
					{
						...newAction,
						...{
							isNew: true,
							isMine: true
						}
					}
				]
			} as any
		];
	},

	/* cancelAddingTask(state, taskGroup: TaskGroup) {
	const groupIndex = state.Tasks.indexOf(taskGroup);
	if (groupIndex > -1) {
	  state.Tasks[groupIndex].tasks = state.Tasks[groupIndex].tasks.filter(
		(task: any) => !task.isNew
	  );
	}
  },

  cancelAddingGroup(state, taskGroup: TaskGroup) {
	const index = state.Tasks.indexOf(taskGroup);
	if (index > -1) {
	  state.Tasks.splice(index, 1);
	}
  }, */

	setOutstandingRequiredTasks(state: TasksState, outstandingTasks: Task[]) {
		state.OutstandingRequiredTasks = outstandingTasks;
	},

	updateTaskAssignedToEventUser(state, userShares) {
		(state.Tasks || []).forEach(task => {
			if (
				task.assignedToEventUserID != null &&
				userShares[task.assignedToEventUserID]
			) {
				Vue.set(
					task,
					"assignedToEventUser",
					userShares[task.assignedToEventUserID]
				);
			}
		});
	},

	toggleActionListModal(state) {
		state.ToggleActionListModal = !state.ToggleActionListModal;
	},

	setNotificationServers(state, data: any[]) {
		state.NotificationServers = data;
	},

	setNotificationTemplates(state, data: any[]) {
		state.NotificationTemplates = data;
	},

	setEditTriggerAction(state, action) {
		state.EditTriggerAction = action;
	},

	/**
	 * This method will alter the ordering of tasks for a given script and category
	 * in the front-end, without having to go back to the data service to do a full-reload.
	 *
	 * Effectively performs the same operation as we have going on in the Data Service when the
	 * user alters the order of an item, but does it locally so we can avoid items jumping between
	 * places while the POST is awaited.
	 *
	 * @param state the Vuex store state.
	 * @param param1 the re-order payload.
	 */
	setActionOrder(state, { taskCategoryId, taskId, scriptId, newOrder }) {
		// Get all the tasks associated with this taskCategory and script,
		// and hold on to the original index of that task within the TaskLibrary array
		// so we can update it later.
		let relevantTasks: {
			task: ActionPlanTask;
			libraryIndex: number;
		}[] = state.TaskLibrary.map((task, libraryIndex) => ({
			task,
			libraryIndex
		})).filter(({ task, libraryIndex }) => {
			// Get the script ID's this task is associated with
			const associatedScripts = Object.keys(task.scripts);

			// Only include this task item if it is part of the script,
			// and matches the category we're working on.
			return (
				task.taskCategoryID === taskCategoryId &&
				associatedScripts.includes(scriptId.toString())
			);
		});

		// Sort the array by the scripts 'order' property
		relevantTasks = orderBy(
			relevantTasks,
			libraryItem => libraryItem.task.scripts[scriptId].order,
			["asc"]
		);

		// Remove the task from it's old location in the array, and hold on to a copy of it
		const oldIndex = relevantTasks.findIndex(
			libraryItem => libraryItem.task.taskID === taskId
		);
		const taskToReinsert = relevantTasks[oldIndex];
		relevantTasks.splice(oldIndex, 1);

		// Re-add the task at it's new position
		// (scripts are 1-indexed, so we need to subtract 1 to get it's place in the array)
		relevantTasks.splice(newOrder - 1, 0, taskToReinsert);

		// Create a new list of tasks, where each task has been assigned it's new order
		const newTasks = relevantTasks.map((task, scriptIndex) => {
			// Assign the new order
			task.task.scripts[scriptId].order = scriptIndex + 1;
			return task;
		});

		// Update our task library with the modified task objects
		relevantTasks.forEach(({ task, libraryIndex }) => {
			Vue.set(state.TaskLibrary, libraryIndex, task);
		});
	},

	/**
	 * Updates a set of tasks within the TaskLibrary. Only touches the tasks specified in the parameters.
	 * @param state the Vuex store state.
	 * @param tasksToUpdate the array of Tasks to be updated.
	 */
	setUpdatedTasks(state, tasksToUpdate: ActionPlanTask[]) {
		tasksToUpdate.forEach(task => {
			const indexInLibrary = state.TaskLibrary.findIndex(
				libTask => libTask.taskID === task.taskID
			);

			// Parse any task data that this task might have
			if (task.taskData) {
				task.taskData = JSON.parse(task.taskData);
			}

			Vue.set(state.TaskLibrary, indexInLibrary, task);
		});
	},

	/**
	 * Sets the selected state of a Task Category within the store.
	 * @param state the Vuex store state.
	 * @param param1 the category ID and the new selected state.
	 */
	setCategorySelected(state, { categoryId, selected }) {
		let category = state.TaskCategories.find(
			cat => cat.taskCategoryID === categoryId
		);

		category.selected = selected;
	},

	/**
	 * Sets the selected state of a Task Action within the store.
	 * @param state the Vuex store state.
	 * @param param1 the category ID, task ID, and the new selected state.
	 */
	setActionSelected(state, { categoryId, taskId, selected }) {
		let task = state.TaskLibrary.find(
			t => t.taskCategoryID === categoryId && t.taskID === taskId
		);

		task.selected = selected;
	},

	/**
	 * Resets all the selected states of the categories/actions in the store.
	 * @param state the Vuex store state.
	 */
	resetTasksSelected(state) {
		state.TaskCategories.forEach(category => {
			category.selected = false;
			state.TaskLibrary.filter(
				task => task.taskCategoryID === category.taskCategoryID
			).forEach(task => (task.selected = false));
		});
	},

	setCallContacts(state, data: UserContactDetails[]) {
		state.CallContacts = data;
	},
	setDynamicDataList(state, data: any[]) {
		state.DynamicDataList = data;
	},
	setDynamicData(state, data : any[]) {
		state.DynamicData = data;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	setScriptCategories(state, data: ScriptCategoryLink[]) {
		state.ScriptCategoryLinks = data;
	},
	setUpdatedTaskCategories(state, taskCategoryToUpdate: ScriptCategoryLink[]){
		taskCategoryToUpdate.forEach(taskCategory => {
			const indexInLibrary = state.ScriptCategoryLinks.findIndex(
				libTaskCat =>libTaskCat.scriptId === taskCategory.scriptId &&
				libTaskCat.taskCategoryId === taskCategory.taskCategoryId
			);

			Vue.set(state.ScriptCategoryLinks, indexInLibrary, taskCategory);
		})
	}
};
