







































































































































































































import { Component, Watch, Mixins } from "vue-property-decorator";
import { namespace, Getter, Action, Mutation } from "vuex-class";

import { MobileFeatures, TrackingToggle, WebAppInterface, MobileEventShareInfo, UserAssetStatus } from "@/store/mobile/types";
import { LogoutPayload, UserPermissions } from "@/store/types";
import { MobileRaiseProjection } from "@/store/mobile-raise/types";

import MobileMenu from "@/components/mobile/core/MobileMenu.vue";
import MobileMap from "@/components/mobile/core/MobileMap.vue";
import RightSideBar from "@/components/mobile/core/RightSideBar.vue";
import LeftSideBar from "@/components/mobile/core/LeftSideBar.vue";
import NotificationBar from "@/components/mobile/core/NotificationBar.vue";
import CameraPlayer from "@/components/mobile/live/CameraPlayer.vue";
import RaiseSelect from "@/components/mobile/mobile-raise/RaiseSelect.vue";
import { SubscriptionDto } from "@/store/subscription/types";
import RaiseActivityLog from "@/components/mobile/mobile-raise/RaiseActivityLog.vue";
import RaiseManualRaise from "@/components/mobile/mobile-raise/RaiseManualRaise.vue";
import ManualRaiseDetails from "@/components/mobile/mobile-raise/ManualRaiseDetails.vue";
import Chat from "@/components/mobile/messages/Chat.vue";

// Mixins
import { SessionResource } from "@/store/sessions/types";
import MobileMixin from "@/mixins/MobileMixin";
import { AssetMapLayerItem } from '@/components/mobile-assets/types';
import mobileApi from "@/services/api.mobile.service";

const Mobile = namespace("mobile");
const SessionStore = namespace("sessions");
const Subscription = namespace("subscription");

declare var android: WebAppInterface;

declare global {
	interface Window {
		notificationSettings: any;
	}
}

@Component({
	components: {
		"mobile-menu": MobileMenu,
		"mobile-map": MobileMap,
		"right-side-bar": RightSideBar,
		"left-side-bar": LeftSideBar,
		"notification-bar": NotificationBar,
		"camera-player": CameraPlayer,
		"raise-select": RaiseSelect,
		"raise-activity-log": RaiseActivityLog,
		"raise-manual-raise": RaiseManualRaise,
		"manual-raise-details": ManualRaiseDetails,
		"chat": Chat
	}
})
export default class MobileCore extends Mixins(MobileMixin) {
	// Feature toggle
	@Getter getUserId: number;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getIsFieldOpsLicensed") private isFieldOpsLicensed: () => boolean;
	@Mobile.State("MobileFeatures") mobileFeatures: MobileFeatures;
	@Getter getPermissions: UserPermissions;
	@Mobile.State(state => state.MobileMode) mobileMode: string;
	@Mobile.State(state => state.MapEnabled) mapEnabled: boolean;
	@Mobile.Mutation setMapEnabled: any;
	@Mobile.Mutation setNotificationText: any;
	@Mobile.Mutation setMobileMode: (mode: string) => void;
	@Mobile.Mutation toggleRightSideBarVisibility: () => void;
	@Mobile.Mutation toggleLeftSideBarVisibility: () => void;
	@Mobile.State("UserMapOnlineStatus") private userMapOnlineStatus: boolean;
	@Mobile.State("EventShareItems") private eventShareItems: MobileEventShareInfo[];

	// Availability
	@Mobile.Action loadUserAssetMapItem:() => void;
	@Mobile.State("UserAssetMapItem") private userMapStatus: AssetMapLayerItem;

	// Sessions
	@Mobile.Action extendMobileSession: any;
	@SessionStore.Action updateSession: any;

	// Device
	@Mobile.Action registerUserDevice: any;

	// Maps
	@Mobile.Action("loadEventShareItems") private loadEventShareItems: () => void;

    //Subscription
    @Subscription.State subscription: SubscriptionDto;
	@Subscription.Action fetchSubscription: any;

	// Logout
	@Action serverLogout: any;
	@Action logout: (payload?: LogoutPayload) => void;

	// Left and right sidebars
	@Mobile.State isRightSideBarOpen: boolean;
	@Mobile.State isLeftSideBarOpen: boolean;

	@Getter("getUserId") loggedInUserId: number;

	//Event Share
	@Mobile.State("ActiveEventShareItem") private activeEvent: MobileEventShareInfo;


	// Other vars
	private isLoggedOut: boolean = false;
	private eventPoll: any = null;

	// Dynamic component names
	private mainComponentName: string = "";
	private isSubscriptionValid: boolean = false;
	private isRaiseSelectVisible = false;
    private isLoading: boolean = true;
    private isRaiseActivityLogVisible = false;
	private isRaiseManualRaiseVisible = false;
	private isManualRaiseDetailsVisible = false;
	private raise: MobileRaiseProjection = null;
	private createdEventId: number | null = null;
	private liveGpsTrack: boolean = true;
	private isDestroying = false;

	private get isPrototype(): boolean {
		if (window.location.toString().startsWith("http://local")) {
			return true;
		} else {
			return false;
		}
	}

	private get isSuiteEnabled(): boolean {
       return this.getFeature(["Suite"]);
     }

	public get isMobileEnabled() {
	    return this.getFeature(["Mobile"]) && this.isSubscriptionValid;
	}

	private get isManualLocationEnabled() {
	    return this.getFeature(["Mobile", "ManualLocation"]);
	}

	get isTriggerMobileRaiseEnabled() {
		return this.getFeature(["Mobile", "MobileRaise"]) && this.getPermissions.canUseMobile && this.getPermissions.canAccessManualRaise;
	}

	private get isFieldOpsEnabled() {
		return this.getFeature(["Mobile", "FieldOps"]);
	}

	private get isFieldOpsUserOnline() : boolean {
		// If FieldOps is enabled and we are on the field op page, check to see if the user is online
		return (this.isFieldOpsEnabled && this.mobileMode == "fieldOps") ? this.userMapOnlineStatus : true;
	}

	private get showFieldOps() : boolean {
		if (this.getPermissions.canViewFieldOps || this.getPermissions.canEditFieldOps) {
			return this.isFieldOpsUserOnline;
		}
		return false;
	}

	private get isMobileEventShareEnabled(): boolean {
		return (this.getFeature(["Mobile", "FieldOps", "EventShare"]) && this.userMapStatus && this.userMapStatus.shareable) ;
	}

    @Watch("liveGpsTrack")
   private onGpsMode(): void{
        if(this.liveGpsTrack){
            this.startBackgroundLocationTracking();
        }
        else{
            this.stopBackgroundLocationTracking();
        }
    }

    @Watch("userMapOnlineStatus")
   private onOnlineStatusChange(): void{
        if(this.userMapOnlineStatus){
            this.startBackgroundLocationTracking();
        }
        else{
            this.stopBackgroundLocationTracking();
        }
	}

	// add a watch so when we can check so we only trigger the pool once we know the user is shareable.
	@Watch("isMobileEventShareEnabled")
	private enabledEventShare(): void {
		if(this.isMobileEventShareEnabled){
			this.loadEventShareItems();
			if(this.eventPoll) clearInterval(this.eventPoll);
			this.eventPoll = setInterval(() => this.loadEventShareItems(), 5000);
		}
	}

	@Watch("mobileMode")
	onMobileModeChanged(value: string, oldValue: string) {
		this.setDynamicComponent();
	}

	async created() {
		// hide the widget
		window.zE('webWidget', 'hide');
	}

	async mounted() {
		try {
			// Fix for getting 100vh to calculate correctly on iOS
			window.scrollTo(0, document.body.scrollHeight);

			this.setMapEnabled(false); // default to map off

			await this.fetchSubscription();
			this.isSubscriptionValid = this.subscription.isActiveState;
			
			if(this.isSuiteEnabled && !this.isFieldOpsLicensed) {
				this.logoutUser("User does not have a Field Ops Licence");
			}

			if (this.isMobileEnabled) {
				// Extend the user session for the mobile user
				await this.updateSession({ resourceId: SessionResource.MobileUserSession });
				await this.extendMobileSession();
			}

			if (this.isLoadedInApp()) {
				this.pollForDeviceId();
			}

			if (!window.location.protocol.toString().startsWith("https")) {
				this.setNotificationText("HTTPS must be enabled to use device location.");
			}


			this.loadUserAssetMapItem();
			if(this.isMobileEventShareEnabled){
				this.loadEventShareItems();
				if(this.eventPoll) clearInterval(this.eventPoll);
				this.eventPoll = setInterval(() => this.loadEventShareItems(), 5000);
			}
		} catch(ex) {
			console.log(ex);
		}

		this.isLoading = false;
	}

	beforeDestroy() {
		this.isDestroying = true;
		this.stopBackgroundLocationTracking();
		if(this.eventPoll){
			clearInterval(this.eventPoll);
		}

		// Clear the polling for device ID
		clearInterval(this.deviceIdInterval);
	}

	private deviceIdInterval = null;
	private pollForDeviceId() {
		clearInterval(this.deviceIdInterval);

		this.deviceIdInterval = setInterval(async () => {
			if (window.notificationSettings && window.notificationSettings.Id) {
				await this.registerUserDevice({
					deviceId: window.notificationSettings.Id,
					deviceType: window.notificationSettings.DeviceType,
				});

				if(this.userMapOnlineStatus) {
					this.startBackgroundLocationTracking();
				}

				clearInterval(this.deviceIdInterval);
			}
		}, 5000);
	}

	setDynamicComponent() {
		switch (this.mobileMode) {
			case "live":
				this.mainComponentName = "camera-player";
				break;
			default:
				this.mainComponentName = "none"
		}
	}

	async logoutUser(errorMessage: string = null) {
		if (typeof window.notificationSettings === "undefined") {
			window.notificationSettings = { Id: "", DeviceType: 0 };
		}

		await mobileApi.mobileGuardLogout(null, window.notificationSettings.Id, window.notificationSettings.DeviceType);

		// Update the interface
		this.toggleLeftSideBarVisibility()

		// Actually log out
		await this.serverLogout();
		if(errorMessage) {
			const logoutPayload: LogoutPayload = {
				hasSessionExpired: false,
				playAudioAlert: false,
				errorMessage: errorMessage
			}
			await this.logout(logoutPayload);
		} else {
			await this.logout();
		}
		
		this.isLoggedOut = true;
	}

	// @techdebt - will be reintroduced in a future phase
	userLost() {
		window.location.href = "immix-mobile://lost";
	}

	private get eventShareCount(): number {
		return this.eventShareItems ? this.eventShareItems.length : 0;
	}

	onRaise(raise: MobileRaiseProjection) {
		this.raise = raise;
		if (!raise.manualRaiseType) {
			this.isRaiseActivityLogVisible = true;
		} else {
			this.isRaiseManualRaiseVisible = true;
		}
	}

	private get isMobileUsersEnabled() {
		return this.getFeature(["MobileUsers"]);
	}

	private startBackgroundLocationTracking() {
		if (this.isLoadedInApp()) {
			var trackingPayload: TrackingToggle = {
				toggle: "on",
			};
			this.sendTrackingRequestToDevice(trackingPayload);
		}
	}

	private sendTrackingRequestToDevice(trackingPayload: TrackingToggle) {
		if (this.isiOS()) {
			(window as any).webkit.messageHandlers.trackingRequestHandler.postMessage(trackingPayload);
		} else if (this.isAndroid()) {
			const trackingPayloadSerialized = JSON.stringify(trackingPayload);
			android.trackingRequest(trackingPayloadSerialized);
		}
	}

	private stopBackgroundLocationTracking() {
		if (this.isLoadedInApp()) {
			var trackingPayload: TrackingToggle = {
				toggle: "off",
			};
			this.sendTrackingRequestToDevice(trackingPayload);
		}
	}


}
