
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { namespace } from "vuex-class";
import api from "../../services/api.service";
import { VueBootstrapField } from '@/store/types';
import SureViewIcon from '../SureViewIcon.vue';

const AlarmPoints = namespace("alarmPoints");

@Component({
	components: {
		VuePerfectScrollbar,
		"sureview-icon": SureViewIcon,
	}
})
export default class AlarmPointHistory extends Vue {
	$refs!: {
		accessHistory: any;
	};

	public fetchingHistory = false;
	public historyItems: any[] = [];

	public filter: string = "";

	@AlarmPoints.State("selectedResponse") response;

	async mounted() {
		if (this.response != null) {
			this.fetchingHistory = true;
			this.historyItems = await api.responseHistory(this.response.responseID);
			this.fetchingHistory = false;
		}
	}

	get filteredHistory(): any[] {
		if (this.filter == "") return this.historyItems;

		var filterLower = this.filter.toLowerCase();

		return this.historyItems.filter(
			historyItem =>
				historyItem.details &&
				historyItem.details.toLowerCase().indexOf(filterLower) > -1
		);
	}

	private get tableFields() {
		let fields: VueBootstrapField[] = [];
		fields.push({ key: "created", label: "Created" });
		fields.push({ key: "eventId", label: "Event ID", thClass: "text-nowrap" });
		fields.push({ key: "details", label: "Details" });
		fields.push({ key: "eventOutcome", label: "Outcome" });

		return fields;
	}

	@Watch("response")
	async onResponseChanged(value: any, oldValue: any) {
		if (value) {
			this.fetchingHistory = true;

			const history = await api.responseHistory(value.responseID);
			this.historyItems = history;
			this.fetchingHistory = false;
		} else {
			this.historyItems = [];
		}
	}
}
