
import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator";

import vSelect3 from "vselect3"

interface NestedValue {
	title: string
	children: any[]
}

@Component({
	components: {
		"v-select-3": vSelect3
	}
})
export default class NestedSelect extends Vue {
	// --- Props ---
	@Prop({ type: Array })
	value!: any[];

	@Prop({ type: Array, required: true })
	optionTree!: any[];

	@Prop({ type: Boolean, default: false })
	disabled!: any[];

	@Prop({ type: String, default: "" })
	placeholder!: string;

	@Prop({ type: String, default: "title" })
	titlePath!: string;

	@Prop({ type: String, default: "children" })
	childrenPath!: string;

	@Prop({type: Boolean, default: true, required: false})
	immediateFocus!: boolean;

	// --- Computed Properties ---

	/**
	 * List of current selection controls
	 * @returns {NestedValue[]} - Values for selection
	 */
	private get selectionControls (): NestedValue[] {
		const controls: NestedValue[] = [
			{
				title: "root",
				children: this.optionTree ? this.optionTree : []
			}
		];

		if (this.value && this.value.length > 0) {
			for (var outcomeSelection of this.value) {
				// Push additional control layers for value path
				if (outcomeSelection[this.childrenPath] && outcomeSelection[this.childrenPath].length > 0) {
					controls.push({
						title: outcomeSelection[this.titlePath],
						children: outcomeSelection[this.childrenPath]
					});
				}
			}
		}

		return controls;
	}

	// --- Lifecycle Hooks ---

	private mounted () {
		if (this.selectionControls.length > 0) {

			// Auto-open last control in list if immediate focus is enabled
			if(this.immediateFocus) {
				this.focusOn(this.selectionControls.length - 1);
			}
		}
	}

	// --- Event Handlers ---

	/**
	 * Sets the value of an item for the given index
	 * @param {number} index - Index in path to apply
	 * @param {any} item - Set item
	 */
	@Emit("input")
	private selected (index: number, item: any) {
		if (item) {
			if (item[this.childrenPath] && item[this.childrenPath].length > 0) {
				this.focusOn(index + 1);
			} else {
				this.blur(index);
			}
		}

		if (this.value[index]) {
			if (item) {
				return [
					...this.value.slice(0, index),
					item
				];
			} else {
				return this.value.slice(0, index);
			}
		} else {
			return [
				...this.value,
				item
			];
		}
	}

	// --- Utility ---

	/**
	 * Focus on a control
	 * @param index Index of control to focus on
	 */
	 private focusOn (index: number) {
		setTimeout(() => {
			const outcomeSelect = this.$refs["outcomeSelect" + index.toString()]?.[0];
			if (outcomeSelect) {
				outcomeSelect?.$el.querySelector("input").focus();
			}
		}, 0.5 * this.$config.ANIMATION_DURATION);
	}

	/**
	 * Blur a control
	 * @param index Index of control to blur
	 */
	 private blur (index: number) {
		setTimeout(() => {
			const outcomeSelect = this.$refs["outcomeSelect" + index.toString()]?.[0];
			if (outcomeSelect) {
				outcomeSelect.$el.querySelector("input").blur();
			}
		}, 0.5 * this.$config.ANIMATION_DURATION);
	}
}
