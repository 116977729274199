import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { RootState } from "../types";
import { getDefaultState } from "./state";
import { DistanceConfigurationState } from "./types";

export const state: DistanceConfigurationState = getDefaultState();
const namespaced: boolean = true;

export const distanceConfiguration: Module<DistanceConfigurationState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};
