
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { GroupSyncAdditionalConfig } from '@/types/sv-data/devices/SyncSystem';
import { Getter } from "vuex-class";
@Component({
	components: {}
})
export default class GroupSyncConfig extends Vue {
    @Getter getFeature: (featuresList: string[]) => boolean;

    @Prop()
    public value: string;

    @Prop()
    private readonly: boolean;

    private storedConfig: GroupSyncAdditionalConfig = this.getDefaultConfig();
    private editingConfig: GroupSyncAdditionalConfig = this.getDefaultConfig();
	private showAllowDelete: boolean = false;

    private getDefaultConfig(): GroupSyncAdditionalConfig {
        return {
            AllowDelete: false,
            UpdateGroupId: false,
            UpdateSettingsMeta: false,
			MaskNewAlarmPoints: false
        }
    }

    private created(): void {
        this.assignValue();
    }

	private get maskNewAlarmPointsEnabled(): boolean {
		return this.getFeature(["Devices", "AdvancedSetup", "SyncSystem", "MaskNewAlarmPoints"]);
	}

    @Watch("value", { deep: true })
    private assignValue(): void {
        if(!this.value){
            this.storedConfig = this.getDefaultConfig();
            this.editingConfig = this.getDefaultConfig();
            this.pushConfigUpdate();
            return;
        }
        this.storedConfig = JSON.parse(this.value);
        this.editingConfig = JSON.parse(this.value);
    }

    @Watch("editingConfig", {deep:true})
    private editConfigWatch(): void {
        if(this.readonly ||(
                this.editingConfig.AllowDelete == this.storedConfig.AllowDelete
                && this.editingConfig.UpdateGroupId == this.storedConfig.UpdateGroupId
                && this.editingConfig.UpdateSettingsMeta == this.storedConfig.UpdateSettingsMeta
				&& this.editingConfig.MaskNewAlarmPoints == this.storedConfig.MaskNewAlarmPoints))
        {
            return;
        }
        this.pushConfigUpdate();
    }

    @Emit("input")
    private pushConfigUpdate(): string {
        return `{"AllowDelete":${this.editingConfig.AllowDelete},"UpdateGroupId":${this.editingConfig.UpdateGroupId},"UpdateSettingsMeta":${this.editingConfig.UpdateSettingsMeta},"MaskNewAlarmPoints":${this.editingConfig.MaskNewAlarmPoints ?? false}}`;
    }
}
