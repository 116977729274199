
import { FlatAreaList, GroupWithState } from "@/store/areas/types";
import { Component, Vue, Watch, Emit, Prop } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import MaskingModalGroupBadge from "./MaskingModalGroupBadge.vue";
import SureViewIcon from "../SureViewIcon.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { FeaturesList, UserPermissions } from "@/store/types";
import { get } from "lodash";
import { truncateString } from '@/filters';
import { numberFormattingMixins } from '@/mixins';

const Areas = namespace("areas");

const { enforceWholeNumber } = numberFormattingMixins.methods;

@Component({
	filters:  { truncateString },
	components: {
		"masking-modal-group-badge": MaskingModalGroupBadge,
		"sureview-icon": SureViewIcon,
		"scrollbar": VuePerfectScrollbar
	}
})
export default class MaskingModalFilteredAreas extends Vue {

	@Prop({ default: "All" }) private initialSelectedFilter: string;
	@Prop({ default: true }) public isFilterModalShown: boolean;

	@Areas.Action fetchFlatListWithState: (params: any) => void;
	@Areas.State flatAreaList: FlatAreaList;

	@Getter getPermissions: UserPermissions;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	private selectedFilter: string = "";
	private selectedArea: GroupWithState = null;
	private isLoading: boolean = true;
	private recordsPage: number = 1;
	private recordsPageSize: number = 25;
	private preferredRecordsPageSize: number = 25;
	private readonly maxRecordsPageSize = 200;
	private searchTerm: string = "";
	private isSearching: boolean = false;
	private filteredFlatList: GroupWithState[] = null;

	private mounted(): void {
		this.selectedFilter = this.initialSelectedFilter;
	}

	private getLatestState(): void {
		this.filteredFlatList = [];
		this.fetchFlatListWithState({
			page: this.recordsPage,
			pageSize: this.recordsPageSize,
			searchTerm: this.searchTerm,
			filter: this.selectedFilter
		});
	}

	public get isAlarmMaskingEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Masking"], false) &&
			(this.getPermissions.canDisarmSites || this.getPermissions.canDisarmSitesExtended);
	}

	@Watch("flatAreaList")
	private onFlatAreaListChanged(): void {
		this.filteredFlatList = this.flatAreaList.groupsWithState;
		this.isLoading = false;
		this.isSearching = false;
	}

	@Watch("searchTerm")
	private onSearchTermChanged(): void {
		this.isSearching = true;
		this.getLatestState();
	}

	private onPageClick(page: number): void {
		if (this.isLoading) {
			return;
		}

		this.recordsPage = page;
		this.isLoading = true;
		this.getLatestState();
	}

	private close(): void {
		this.selectedArea = null;
		this.filteredAreaSelected();
	}

	private groupBadgeSelected(result: string): void {
		this.recordsPage = 1;
		this.selectedFilter = result;
		this.isLoading = true;
		this.getLatestState();
	}

	@Emit("selectedArea")
	private filteredAreaSelected(): number {
		return this.selectedArea?.groupId ?? null;
	}

	private areaSelected(area: GroupWithState): void {
		this.selectedArea = area;
	}

	private enforceWholeNumberFormatter(value: number): number {
		return enforceWholeNumber(value);
	}

	private updatePerPageLimit(): void {
		if (this.preferredRecordsPageSize > 0 &&
			this.recordsPageSize !== this.preferredRecordsPageSize) {

			if(this.preferredRecordsPageSize > this.maxRecordsPageSize) {
				this.preferredRecordsPageSize = this.maxRecordsPageSize;
			}

			this.recordsPageSize = this.preferredRecordsPageSize;
			this.isLoading = true;
			this.getLatestState();
		} else {
			this.preferredRecordsPageSize = this.recordsPageSize;
		}
	}
}
