
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";

import { Permission, PermissionExtended } from "@/store/user-management/types";

import vSelect from "vue-select";
import "@/scripts/dictionary";
import "@/scripts/array-operations";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";
import { SubscriptionBilledPermission, SubscriptionDto } from "@/store/subscription/types";

const UserManagement = namespace("userManagement");
const eventQueue = namespace("eventqueue");
const Subscription = namespace("subscription");

@Component({
	components: {
		"vue-select": vSelect
	}
})
export default class UserRolePermissions extends Vue {
	@Prop({ type: Array, default: [] })
	public value: any[];
	@Prop({type: Boolean, default: false}) public readonly: boolean;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@UserManagement.Getter("getPermissions") public permissions: Permission[];
	@eventQueue.Getter public routingGroups: any[];
	@eventQueue.Action public loadRoutingGroups: () => void;

    @Subscription.State subscription: SubscriptionDto;
    @Subscription.State billedUserPermissions: SubscriptionBilledPermission[];
    @Subscription.Action fetchBilledUserPermissions: any;

	currentPermissions: any = {};

	/**
	 * Role permissions members
	 */
	private _rolePermissions: PermissionExtended[] = [];

	private async mounted() {
	    await this.fetchBilledUserPermissions();
    }

	private addRolePermission(permission: PermissionExtended) {
		this._rolePermissions.push(permission);
	}

	private removeRolePermission(permission: PermissionExtended) {
		this._rolePermissions = this._rolePermissions.filter(rp => rp.permissionId !== permission.permissionId);
	}

	private updateOrAddExtendedRolePermission(permission: PermissionExtended) {
		// check if we already have a permission in the list
		let existing: PermissionExtended = this._rolePermissions.firstOrDefault(
			rp => rp.permissionId === permission.permissionId
		);

		if (existing) {
			// update it
			existing.extended = permission.extended;
		} else {
			// add it to the list
			this.addRolePermission(permission);
		}
	}

	/**
	 * Emit input and update the related arrays
	 */
	private raiseValueChanged() {
		this.$emit("input", this._rolePermissions);
		this.onValueChanged(this._rolePermissions);
	}

	@Watch("value", { deep: true, immediate: true })
	onValueChanged(newValue: PermissionExtended[]) {
		this._rolePermissions = [...newValue];
		this.currentPermissions = newValue ? newValue.toDictionary("permissionId") : {};
	}

	/*
	 *	Matches permissions against value model and groups by permissions category
	 */
	private get groupedPermissions(): any {
		if (!this.permissions) {
			return [];
		}

		const valueDictionary = this.currentPermissions;

		const rolePermissions: PermissionExtended[] = [];
		this.permissions.forEach(permission => {
			const permissionValue = valueDictionary[permission.permissionId.toString()];

			let tempPermission: PermissionExtended = {
				permissionId: permission.permissionId,
				title: permission.title,
				description: permission.description,
				hasPermission: permissionValue ? true : false,
				extendedValueDataType: permission.extendedValueDataType,
				extendedValueTitle: permission.extendedValueTitle,
				extended: permissionValue ? permissionValue.extended : null,
				category: permission.category,
				secondPermission: null,
                billedPermission: this.getBilledPermission(permission.permissionId)
			};

			if (this.isPluginPermission(tempPermission)) {
				var pluginPerm = rolePermissions.filter(
					e =>
						e.title.split("/")[0] === tempPermission.title.split("/")[0] && e.title !== tempPermission.title
				);

				if (pluginPerm.length > 0) {
					pluginPerm[0].secondPermission = tempPermission;
				} else {
					rolePermissions.push(tempPermission);
				}
			} else {
				rolePermissions.push(tempPermission);
			}
		});

		// Group by category
		const categories = rolePermissions.reduce((group: any, permission: Permission) => {
			var section = permission.category ? permission.category : "General";
			(group[section] = group[section] || []).push(permission);
			return group;
		}, {});

		// Sort
		return Object.keys(categories)
			.map(categoryName => {
				return {
					title: categoryName,
					permissions: categories[categoryName]
				};
			})
			.sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0));
	}

	private getBilledPermission(permissionId: number) {
		let isBillingEnabled = get(this.featuresList, ["Billing"]);
		if (!isBillingEnabled) {
			return;
		}

		if (this.billedUserPermissions.length === 0) {
			return undefined;
		}

		const operatorPermissionId = this.billedUserPermissions.find(up => up.title === "Operators").permissionID;
		const mobileOfficerPermissionId = this.billedUserPermissions.find(up => up.title === "Mobile Officers").permissionID;

		if (permissionId === operatorPermissionId) {
			return "Operators";
		}

		if (permissionId === mobileOfficerPermissionId) {
			return "Mobile Officers";
		}

		return undefined;
	}

	public permissionChangedCheckBox(permission: any, checked: boolean) {
		let permissionId = permission.permissionId;
		// if checked
		if (checked) {
			permission.hasPermission = true;
			// does this permission exist in our list?
			if (this._rolePermissions.some(p => p.permissionId === permissionId)) {
				// do nothing
				return;
			}
			this.addRolePermission(permission);
		} else {
			permission.hasPermission = false;
			// remove permission from list
			this.removeRolePermission(permission);
		}

		// notify anything that is listening that the array has been updated
		this.raiseValueChanged();
	}

	/*
	 *	Adds or removes permissions from model.
	 */
	public permissionChanged(permission: any) {
		let permissionId = permission.permissionId;
		// if checked
		if (permission.hasPermission) {
			// does this permission exist in our list?
			if (this._rolePermissions.some(p => p.permissionId === permissionId)) {
				// do nothing
				return;
			}

			this.addRolePermission(permission);
		} else {
			// remove permission from list
			this.removeRolePermission(permission);
		}

		// notify anything that is listening that the array has been updated
		this.raiseValueChanged();
	}

	/*
	 *	Update extended value for permissions
	 */
	private permissionExtendedValueChanged(permission: any) {
		this.updateOrAddExtendedRolePermission(permission);
		this.raiseValueChanged();
	}

	/*
	 * Checks if the permission is related to a plugin
	 */
	public isPluginPermission(permission) {
		return permission.title.includes("/CanBeViewed") || permission.title.includes("/CanBeInteractedWith");
	}

	/*
	 * Checks which plugin permission is given
	 */
	public isViewPluginPermission(permission) {
		return permission.title.includes("/CanBeViewed");
	}

	/*
	 * Returns the permission name in a readable format
	 */
	public getPluginName(permission) {
		var title = permission.title
			.split("/")[0]
			.replace(/([A-Z])/g, " $1")
			.trim();
		title = title.charAt(0).toUpperCase() + title.slice(1);
		return title + " Plugin";
	}
}
