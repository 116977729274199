
    import { Component, Vue, Prop, Watch, Emit, Mixins } from "vue-property-decorator";
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import { validationMixin } from 'vuelidate';
    import { required, requiredIf, minValue, maxValue, numeric } from 'vuelidate/lib/validators';
    import { namespace } from 'vuex-class';
    import moment from "moment";

    import api from '../../services/api.service';
    import { SiteTreeNode, Group } from '@/store/eventqueue/types'

    import AlarmPoint from './AlarmPoint.vue'
    import AreaAlarmPoints from './AreaAlarms.vue'
    import AlarmPointFinder from './AlarmPointFinder.vue'

    const Eventqueue = namespace('eventqueue');
    const AlarmPoints = namespace('alarmPoints');
    const SiteMonitor = namespace("siteMonitor");

    @Component({
        components: {
            VuePerfectScrollbar,
            'alarm-point': AlarmPoint,
            'alarm-point-finder': AlarmPointFinder,
            'area-alarm-points': AreaAlarmPoints
        }
    })
    export default class AlarmPointManagerDialog extends Vue {
        $refs!: {
			alarmDialog: any;
			alarmPointFinder: AlarmPointFinder
        };

        @Eventqueue.Getter('getSiteTree') tree: SiteTreeNode[];
        @Eventqueue.Action sitesTreeGet: any;

        @AlarmPoints.State("selectedResponse") getSelectedResponse;
        @AlarmPoints.State("alarmPointManagerOpen") getAlarmPointManagerOpen;
        @AlarmPoints.Mutation setAlarmPointManagerOpen;
        @AlarmPoints.Mutation setSelectedResponse: any;

        @AlarmPoints.Mutation setSelectedArea: any;
        @AlarmPoints.State("selectedArea") selectedArea;

        @AlarmPoints.State("displayResponseId") getDisplayResponseId;

        public mounted(): void {
            this.setAlarmPointManagerOpen(false);
            this.setSelectedResponse(null);
            this.setSelectedArea(null);
        }

        @Watch("getAlarmPointManagerOpen")
		public async onGetAlarmPointManagerOpen(value: boolean, oldValue: boolean | null): Promise<void> {
            if (value) {
                this.setSelectedArea(null);
                this.setSelectedResponse(null);
                if (this.$refs.alarmDialog && this.$refs.alarmDialog.show)
                    this.$refs.alarmDialog.show();
            } else {
                this.setSelectedResponse(null);
                if (this.$refs.alarmDialog && this.$refs.alarmDialog.hide)
                    this.$refs.alarmDialog.hide();
            }
        }
        
        public cancel(): void {
            this.setAlarmPointManagerOpen(false);
        }

        public groupFind(groupID: number): SiteTreeNode {
            const recursiveFind = (tree: SiteTreeNode) => {
                if (tree.group.groupID == groupID)
                    return tree;

                if (tree.subGroups && tree.subGroups.length > 0) {
                    let matchingSubgroup = null;
                    tree.subGroups.forEach((node: SiteTreeNode) => {
                        if (matchingSubgroup == null)
                            matchingSubgroup = recursiveFind(node);
                    });

                    return matchingSubgroup;
                }
            }

            let matchingGroup = null;
            this.tree.forEach((node: SiteTreeNode) => {
                if (matchingGroup == null)
                    matchingGroup = recursiveFind(node);
            });

            return matchingGroup;
        }

        public setTreeArmedStatus(response: any): void {
            let responseGroup = this.groupFind(response.groupID);
            if (responseGroup && responseGroup.contents && responseGroup.contents.responses) {

                let responses = responseGroup.contents.responses.filter((treeResponse) => treeResponse.responseID == response.responseID);

                responses.forEach((treeResponse) => {
                    treeResponse.armed = response.armed;
                });
            }
        }

		public onMaskedAlarm(result: any): void {
            if (result && result.response) {
				this.setTreeArmedStatus(result.response);

				if (this.$refs.alarmPointFinder) {
					this.$refs.alarmPointFinder.updateResponseStatus(result.response);
				}
            }
        }

        public onUnmaskedAlarm(result: any): void {
            if (result && result.response) {
				this.setTreeArmedStatus(result.response);

				if (this.$refs.alarmPointFinder) {
					this.$refs.alarmPointFinder.updateResponseStatus(result.response);
				}
            }
        }

        public backToGroup(): void {
            this.setSelectedResponse(null);
        }
    }

