import api from "@/services/api.service";
import { CompleteTaskPayload } from "@/services/api.service";
import TaskCategory from "@/models/task-category.model";
import { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";
import { ContactRole, UserContactDetails } from "@/store/user-management/types";
import { ScriptCategoryLink, CategoryReorderRequest } from "@/store/tasks/types";

// @refactor consider creating a separate ConfigureTasks service
export class TasksService {
	// @refactor type
	public async loadTaskTypes() {
		const response = await api.loadTaskTypes();
		return response && response.data ? response.data : [];
	}

	public async loadActionPlans() {
		return await api.loadActionPlans();
	}

	public async createActionPlan(payload: any) {
		const response = await api.createActionPlan(payload);
		return response;
	}

	public async updateActionPlan(payload: any) {
		return await api.updateActionPlan(payload);
	}

	public async deleteActionPlan(payload: any) {
		return await api.deleteActionPlan(payload);
	}

	public async addActionToPlan(payload: any) {
		return await api.addActionToPlan(payload);
	}

	public async updateActionForPlan(payload: any) {
		return await api.updateActionForPlan(payload);
	}

	public async removeActionFromPlan(payload: any) {
		return await api.removeActionFromPlan(payload);
	}

	public async saveTaskCategory(taskCategory: TaskCategory) {
		return await api.createTaskCategory(taskCategory);
	}

	public async updateTaskCategory(taskCategory: TaskCategory) {
		return await api.updateTaskCategory(taskCategory);
	}

	public async loadTasksForEvent(eventId: number) {
		const response = await api.loadTasksForEvent(eventId);
		return response.data;
	}

	public async searchTasks(word: string) {
		const response = await api.searchTasks(word);
		return response.data;
	}

	public async loadTasks() {
		const response = await api.loadTasks();
		return response.data;
	}

	// save a task to task library - to a general "library" of tasks
	public async createTask(data: any) {
		return await api.createTask(data);
	}

	public async deleteTask(data: any) {
		return await api.deleteTask(data);
	}

	// save a task to task library - to a general "library" of tasks
	public async updateTask(data: any) {
		return await api.updateTask(data);
	}

	public async assignAllTasks(data: any) {
		return await api.assignAllTasks(data);
	}

	// save an event-specific task
	public async saveEventTask({ task, eventId }) {
		const payload = {
			...task,
			eventID: eventId,
			taskData: task.taskData ? JSON.stringify(task.taskData) : null
		};

		return await api.saveEventTask(payload);
	}

	public async completeTask(payload: CompleteTaskPayload) {
		return await api.completeTask(payload);
	}

	public async createTaskCategory(payload: TaskCategory) {
		return await api.createTaskCategory(payload);
	}

	public async deleteTaskCategory(payload: any) {
		return await api.deleteTaskCategory(payload);
	}

	public async loadTaskCategories() {
		const response = await api.loadTaskCategories();
		return response.data;
	}

	public async loadTasksByCategory(taskCategoryID: number) {
		const response = await api.loadTasksByCategory(taskCategoryID);
		return response.data;
	}

	public async assignTaskToUser(payload: any) {
		const response = await api.assignTask(payload);
		return response.data;
	}

	public async assignUserToTaskCategory(payload: any) {
		return await api.assignAllTask(payload);
	}

	public async getScriptCategoryLinks(): Promise<ScriptCategoryLink[]> {
		return await api.getScriptCategoryLinks();
	}

	public async updateCategoryOrderInScript(categoryReorderRequest: CategoryReorderRequest): Promise<ScriptCategoryLink[]> {
		return await api.updateCategoryOrderInScript(categoryReorderRequest);
	}

	// @test write unit tests for pure function
	// Helper method, @purefn
	// Converts an array of event-specific tasks as they arrive from API
	// to a structure where tasks are broken up by category, used by EventTasks UI component
	// @techdebt as we long-poll for event tasks - move this logic to getters (whcih benefit from caching)
	public responseToModel(responseData: any) {
		return responseData
			.map((t: any) => t.taskCategoryTitle) // get task categories including duplicates
			.filter(
				(value: any, index: number, self: any) =>
					self.indexOf(value) === index
			) // distinct
			.map((taskCategoryTitle: string, index:number) => {
				// set up task categories and assign tasks
				return {
					index: index,
					title: taskCategoryTitle,
					isNew: false,
					tasks: responseData
						.filter(
							(t: any) =>
								t.taskCategoryTitle === taskCategoryTitle
						)
						.map((t: any) => {
							return {
								...t
							};
						})
				};
			});
	}

	public async getContactsForRole(contactRoleId: number, groupId: number): Promise<UserContactDetails[]> {
		return await api.getContactsForRole(contactRoleId, groupId);
	}

	public async getContactRoles(paginatedSearchRequest: PaginatedSearchRequest): Promise<ContactRole[]> {
		const { data } = await api.getContactRoles(paginatedSearchRequest);
		return data;
	}

	public async fetchNotificationServers() {
		return await api.fetchNotificationServers();
	}

	public async fetchNotificationTemplates(serverId: number) {
		return await api.fetchNotificationTemplates(serverId);
	}

	public async fetchTemplateDetails(templateId: number) {
		return await api.loadTemplateDetails(templateId);
	}

	public async updateActionOrderInPlan(payload: any) {
		return await api.updateActionOrderInPlan(payload);
	}

	public async fetchCallContacts(payload: any) {
		return await api.fetchCallContacts(payload);
	}
}
