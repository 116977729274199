
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mutation, namespace, Getter, Action, State } from "vuex-class";
import { get } from "lodash";
import SiteStatus from "@/components/SiteStatus.vue";
import AlarmQueueHeader from "@/components/AlarmQueueHeader.vue";
import AlarmQueueFooter from "@/components/AlarmQueueFooter.vue";
import PatrolQueue from "@/components/event-queue/PatrolQueue.vue";
import ParkedQueue from "@/components/ParkedQueue.vue";
import ProcessingQueue from "@/components/ProcessingQueue.vue";
import ProcessingError from "@/components/ProcessingError.vue";
import InvitationCheck from "@/components/InvitationCheck.vue";
import ManualPatrol from "@/components/ManualPatrol.vue";
import ManualRaise from "@/components/ManualRaise.vue";
import ErrorWarning from "@/components/ErrorWarning.vue";
import ManualAction from "@/components/ManualAction.vue";
import { UserPermissions, FeaturesList, User, LogoutPayload } from "@/store/types";
import QueueInactivityWarning from "@/components/event-queue/InactivityWarning.vue";
import ActivityLog from "@/components/activity-log/ActivityLogPopup.vue";
import VirtualOperatorsPopUp from "@/components/ai-virtual-operator/VirtualOperatorsPopup.vue";

const UserContext = namespace("userContext");

import RegionalHandlerMixin from "@/mixins/RegionalHandlerMixin.ts";
import EnhancedSingleMap from '@/components/EnhancedSingleMap.vue';
import { SubscriberExpiry, EnhancedMapMode } from '@/types/EnhancedSingleMapTypes';
import ProcessAlarmConfirmation from '@/components/event-queue/ProcessAlarmConfirmation.vue';
import EventRecords from "@/components/EventRecords.vue";
import EventRecordTypes from "@/types/sv-data/enums/EventRecordTypes";
import MobileMixin from '@/mixins/MobileMixin';
import EnhancedMapSidebar from "@/components/EnhancedMapSidebar.vue";
import { FilteredEvent } from '@/store/eventqueue/types';
import EventClose from "@/components/EventClose.vue";
import HideMapMixin from "@/mixins/HideMapMixin";
import EventDetails from '@/components/EventDetails.vue';
import AlarmPointManagerDialog from "@/components/alarm-points/AlarmPointManagerDialog.vue";
import AreaEventHistoryPopout from "@/components/area-event-history/AreaEventHistoryPopout.vue";

const Eventqueue = namespace("eventqueue");
const FieldOpsStore = namespace("fieldOps");
const Subscription = namespace("subscription");
const SiteMonitor = namespace("siteMonitor");
const AreaNotes = namespace("areaNotes");
const AreaEventHistory = namespace("areaEventHistory");

@Component({
	components: {
		"site-status": SiteStatus,
		"alarm-queue-header": AlarmQueueHeader,
		"alarm-queue": () => import("../components/AlarmQueue.vue"),
		"alarm-queue-ccure": () => import("../components/AlarmQueueCCure.vue"),
		"alarm-queue-footer": AlarmQueueFooter,
		"enhanced-single-map": EnhancedSingleMap,
		"patrol-queue": PatrolQueue,
		"parked-queue": ParkedQueue,
		"processing-queue": ProcessingQueue,
		"invitation-check": InvitationCheck,
		"manual-patrol": ManualPatrol,
		"manual-raise": ManualRaise,
		"error-warning": ErrorWarning,
		"manual-action": ManualAction,
		"processing-error": ProcessingError,
		"inactivity-warning": QueueInactivityWarning,
		"activity-log": ActivityLog,
		"vo-control-panel": VirtualOperatorsPopUp,
		"alarm-confirmation" : ProcessAlarmConfirmation,
		"event-records": EventRecords,
		"enhanced-map-side-bar": EnhancedMapSidebar,
		"event-close": EventClose,
		"event-details": EventDetails,
		"alarm-points": AlarmPointManagerDialog,
		"area-event-history-popout": AreaEventHistoryPopout,
	}
})
export default class EventQueue extends Mixins(RegionalHandlerMixin, MobileMixin, HideMapMixin) {
	public events: any[] = [];
	public error: string = "";
	public activeFilters: any = null;
	public currentFilter: number = -1;
	public updating: boolean = false;
	private subscriberTimer = null;

	private enhancedMapMode = EnhancedMapMode;

	@Eventqueue.Action fetchUserZone: any;

	@Eventqueue.Getter getCountByType: any;
	@Eventqueue.Getter("getActivityLogShown") activityLogShown!: boolean;
	@Eventqueue.Getter("getVirtualOperatorControlPanelShown") virtualOperatorControlPanelShown!: boolean;
	@Eventqueue.Getter("getBulkHandleShown") bulkHandleShown!: boolean;
	@Eventqueue.Getter("getAllAlarmsSelected") allAlarmsSelected!: boolean;
	@Eventqueue.Getter("getSelectedAlarms") selectedAlarms: number[];

	@Eventqueue.Mutation setLastEventHandled: () => void;
	@Eventqueue.Mutation("setDebugMode") private setDebugMode: (debugMode: boolean) => void;
	@Eventqueue.Mutation setPeekEventId: (peekEventId: number | null) => void;
	@Eventqueue.Mutation setAllAlarmsSelected: (allSelected: boolean) => void;
	@Eventqueue.Mutation setSelectedAlarms: (data: number[]) => void;

	@Eventqueue.State peekEventId: number;
	@AreaNotes.State currentGroupId: number;
	@AreaNotes.State currentGroupTitle: string;

	@AreaEventHistory.State isPopoutModeEnabled: boolean;

	// Flag for toggling the map.
	@Eventqueue.Action setHideMapFlag: any;
	@Eventqueue.Getter("getHideMapFlag") hideMapFlag: boolean;
	@Eventqueue.Getter getActiveEvents!: FilteredEvent[];

	@Action fetchRoutingGroups: any;
	@UserContext.Action("startPolling") startPollingUserContext: () => Promise<void>;
	@UserContext.Action("stopPolling") stopPollingUserContext: () => Promise<void>;

	@FieldOpsStore.Action("addOrUpdateActiveSubscriber") private subscribeToPoll: (subscriberExpiry: SubscriberExpiry) => void;

	@Mutation setShortcuts: any;
	@Mutation setLegendKeys: any;
	@Mutation setRoutingGroupsLegend: any;
	@Mutation setActiveRegion: any;

	@Getter getPermissions: UserPermissions;
	@Getter getAlarmScreenTimeoutSeconds: number | null;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getIsFieldOpsLicensed: boolean;
	@Getter getFeature: (featureName: string[]) => boolean;

	@Subscription.Getter isSubscriptionActive: boolean;
	@SiteMonitor.Action createAuditRecord: (request: any) => Promise<void>;

	@SiteMonitor.Mutation setEventCloseShown: any;

	@State User: User;
	@State reloadEventQueue: boolean;

	@Mutation setReloadEventQueue: (boolean) => void;

	@Action serverLogout: () => Promise<void>;
	@Action logout: (payload?: LogoutPayload) => Promise<void>;

	public created() {
		if (this.reloadEventQueue)
		{
			this.setReloadEventQueue(false);
			location.reload(true);
		}

		this.resetRegion();
		this.fetchUserZone();
		this.fetchRoutingGroups();
		this.setLastEventHandled();
	}

	$refs!: {
		peekModal: any;
	};

	private get isSuiteEnabled(): boolean {
		return get(this.featuresList, ["Suite"]);
	}

	private get isSituationalAwarenessEnabled(): boolean {
		return this.getFeature(["SituationalAwareness"]);
	}

	public async mounted(): Promise<void> {
		if (this.isSuiteEnabled && this.isLoadedInApp())
		{
			if(this.getIsFieldOpsLicensed)
			{
				this.$router.push({
					path: `/mobile`
				});
			}
			else
			{
				await this.serverLogout();
				const logoutPayload: LogoutPayload = {
					hasSessionExpired: false,
					playAudioAlert: false,
					errorMessage: "User does not have a Field Ops Licence"
				};
				await this.logout(logoutPayload);
			}
		}

		this.setShortcuts([
			{ keys: "Shift+R", action: "Manual Raise" },
			{ keys: "Shift+M", action: "Manual Tour" },
			{
				keys: "Shift+P",
				action: "Process Top Alarm",
				featureFlag: "Alarms.SiteMonitor"
			},
			{ keys: "Shift+Q", action: "Quick Control", featureFlag: "Alarms.QuickControl" },
			{ keys: "Shift+[1-0]", action: "Change between first 10 filters" },
			{ keys: "Shift+Enter", action: "Triggers the confirm event on a pop up" },
		]);
		this.setLegendKeys([
			{
				icon: "fa-bell text-danger",
				details: "Alarms are awaiting a response"
			},
			{ icon: "fa-hourglass-end", details: "Previously parked event" }
		]);
		this.setRoutingGroupsLegend(true);
		this.startPollingUserContext();
		this.updateEventQueueDebugMode();
		this.subscriberTimer = setInterval(async () => {
			this.subscribeToPoll({ subscriber: "EventQueue", expiry: Date.now() + 5100});
		}, 5000);
	}

	@Watch("debugMode")
	private updateEventQueueDebugMode() {
		this.setDebugMode(this.debugMode);
	}

	@Watch('peekEventId')
	private peekEvent(): void{
		if (this.$refs.peekModal && this.peekEventId == null) {
			this.$refs.peekModal.hide();
		} else {
			this.$refs.peekModal.show();
		}
	}

	private joinPeekedEvent(): void
	{
		if(!this.peekEventId)
		{
			return;
		}
		var event: FilteredEvent = this.getActiveEvents.firstOrDefault(ae => ae.eventID == this.peekEventId);
		if(!event)
		{
			return;
		}
		this.handleEventQueueItem(event, undefined, undefined, "Picked up from peek");
	}

	public beforeDestroy() {
		this.stopPollingUserContext();
		clearInterval(this.subscriberTimer);
	}

	public get patrolEventCount() {
		return this.getCountByType("patrols");
	}

	public get parkedEventCount() {
		return this.getCountByType("parked");
	}

	public get processingEventCount() {
		return this.getCountByType("inProcess");
	}

	public get debugMode() {
		return get(this.featuresList, ["DebugMode"]);
	}

	public get alarmQueueType() {
		// We use lodash here as native ES does not have null propagation
		return get(this.featuresList, ["Integration", "CCure", "UI"]) ? "alarm-queue-ccure" : "alarm-queue";
	}

	public get isActivityLogEnabled() {
		return get(this.featuresList, ["ActivityLog"]);
	}

	// Feature flag check for toggle maps.
	private get isToggleMapEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Maps", "ToggleEventQueue"]);
	}

	public get virtualOperatorsEnabled() {
		return get(this.featuresList, ["AI", "VirtualOperators"]) && this.getPermissions.canUseVirtualOperators;
	}

	// Audit when a user peeks an event and when they leave
	// An exit record is put on the peek modal @hide attribute to ensure a record is created regardless of how the modal was closed (button or clicking outside)
	private async createPeekRecord(action: string): Promise<void> {
		switch(action){
			case "enter":
				await this.createAuditRecord({
					eventId: this.peekEventId,
					eventRecordTypeId: EventRecordTypes.PeekStarted,
					details: `Event peeked by user: ${this.User.fullName}`
				});
				break;
			case "exit":
				await this.createAuditRecord({
					eventId: this.peekEventId,
					eventRecordTypeId: EventRecordTypes.PeekEnded,
					details: `${this.User.fullName} has ended peek`
				});
				this.setPeekEventId(null);
				break;
		}
	}

	private hidePeekModal(): void {
		if(this.$refs.peekModal) {
			this.$refs.peekModal.hide();
		}
	}

	private get isEventHistoryEnabled(): boolean {
		return this.getFeature(["Areas", "AreaEventHistory"]) && this.getPermissions.canViewEventHistory;
	}

	public get bulkHandleEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventQueue", "EndMultipleEvents"]);
	}

	public get bulkHandlePermission(): boolean {
		return this.getPermissions.canEndMultipleEvents;
	}

	public get eventBulkHandleAllowed(): boolean {
		return this.bulkHandleEnabled && this.bulkHandlePermission && this.bulkHandleShown;
	}

	// public get / set that wrapper the store to avoid warnings binding to the checkbox
	// get
	public set allSelected(allAlarmsSelected:boolean) {
		this.setAllAlarmsSelected(allAlarmsSelected);
	}

	// set
	public get allSelected(): boolean {
		return this.allAlarmsSelected;
	}

	// Controls the state when 'SelectAll' toggled
	public allAlarmsToggled(): void {
		// if all selected checkbox is not selected clear the selected alarms
		if (!this.allSelected){
			this.setSelectedAlarms([]);
		}

	}

	public endSelectedAlarms(): void {
		this.setEventCloseShown(true);
	}
}
