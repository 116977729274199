
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import Multiselect from "vue-multiselect";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { renderChoiceEnum } from "@/models/Tasks/renderType";

const Tasks = namespace("tasks");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		multiselect: Multiselect,
		VuePerfectScrollbar: VuePerfectScrollbar
	}
})
export default class ChoicesTask extends Vue {
	@Getter getUserName: string;
	@SiteMonitor.Action createAuditRecord: any;
	@SiteMonitor.Getter("getEventDetails") eventDetails: any;

	@Prop() public task: any;
	@Prop(String) result: string;

	private choiceDescription: string = "";
	private taskOutcomes: any[] = [];
	private selectedChoice: string = "";
	private choiceListTypes: object = renderChoiceEnum;

	/** Method to emit to parent and  Complete Task. */
	private async submitOutcome(outcome) {
		// Emit for the result within parent
		this.$emit("result-changed", outcome);
		// Emit for the @complete within parent.
		this.$emit("complete");
	}

	public async mounted() {
		try {
			let {
				choiceDescription,
				choiceOptions,
				selectedChoice
			} = this.task.taskData;

			this.choiceDescription = choiceDescription;
			this.taskOutcomes = choiceOptions;
			this.selectedChoice = selectedChoice;
		} catch (Ex) {
			console.error("error occured: ChoicesTask " + Ex);
		}
	}
}
