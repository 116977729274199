
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { MobileRaiseProjection, MobileRaiseTemplateProjection } from "@/store/mobile-raise/types";
import Title from "./Title.vue";
import { Group } from "@/store/eventqueue/types";
import { ManualRaiseResult } from "@/store/manual-raise/types";
import getPosition from "@/services/device.position.service";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { ILatLng } from "@sureview/v2-mapping-saas";
import { AreaNode } from '@/types/sv-data/groups/AreaNode';
import { FeaturesList } from '@/store/types';

const ManualRaiseStore = namespace("manualRaise");
const EventQueue = namespace("eventqueue");
const mobile = namespace("mobile");
const MobileRaiseTemplates = namespace("mobileRaise");

@Component(
{
	components: {
		"raise-title": Title,
		"area-tree-select": AreaTreeSelect
	}
})
export default class RaiseManualRaise extends Vue
{
	@Getter("getFeature") private getFeature: (featureName: string[]) => boolean;
	@Prop()
	private raise: MobileRaiseProjection;

	@EventQueue.Action private fetchUserZone: () => Promise<void>;
	@EventQueue.Getter private getUserZone: Group;
	@ManualRaiseStore.Action private raiseManualAlarm: ({ payload, autoHandle, raiseAndJoin, templateId }) => Promise<void>;
	@ManualRaiseStore.Mutation private setOnCompleteHandler: (handler: (result: ManualRaiseResult) => void) => void;
	@MobileRaiseTemplates.State(state => state.templateProjections) private templates: MobileRaiseTemplateProjection[];
	@MobileRaiseTemplates.State(state => state.currentTemplateProjectionIdx) private templateIdx: number | null;

	@mobile.Getter("getCurrentLocationRegionGroupID") private regionGroupID: number;
	private dataLoaded: boolean = false;
	private createdEventId: number | null = null;
	private selectedGroup: AreaNode = null;
	private raisingAlarm: boolean = false;

	private async created(): Promise<void> {
		this.onCreate();
	}

	private async onCreate(): Promise<void> {
		this.setOnCompleteHandler(this.onRaiseFinished.bind(this));
		await this.fetchUserZone();
		this.dataLoaded = true;
	}

	private beforeDestroy(): void {
		this.setOnCompleteHandler(null);
	}

	private onRaiseFinished(result: ManualRaiseResult): void {
		this.createdEventId = result.eventID;
	}

	private get shouldManualRaiseAndJoin(): boolean {
		return this.getFeature(["Mobile", "MobileRaise", "AutoShare"]);
	}

	private get isManualRaiseToAreaEnabled(): boolean {
		return this.getFeature(["Mobile", "MobileRaise", "RaiseToArea"]);
	}

	private get isAreaRegionsEnabled(): boolean {
		return this.getFeature(["Areas", "Regions"]);
	}

	private mounted(): void {
		if(this.isAreaRegionsEnabled && this.regionGroupID) {
			this.selectedGroup = {
				id: this.regionGroupID,
				label: null //Label will be retrieved from the Area Tree Select
			};
		}
	}

	private async onConfirm(): Promise<void> {
		if(this.raisingAlarm) {
			return;
		}

		this.raisingAlarm = true;
		try {
			let posString;
			try {
				const pos: ILatLng = await getPosition(4000);
				posString = `${pos.lat} ${pos.lng}`;
			}
			catch {
				posString = "";
			}

			const payload = {
				serverTypeEvent: this.raise.typeID,
				groupID: this.selectedGroup ? this.selectedGroup.id : this.getUserZone.groupID,
				location: posString
			};
			await this.raiseManualAlarm({ payload, autoHandle: false, raiseAndJoin: this.shouldManualRaiseAndJoin, templateId: this.templates[this.templateIdx].mobileRaiseTemplateID });
			this.$emit("confirm", this.createdEventId);
		}
		catch(ex) {
			throw ex;
		} 
		finally {
			this.raisingAlarm = false;
		}
	}

	private onCancel(): void {
		this.$emit('cancel');
	}
}
