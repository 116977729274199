
import maskingApi from "@/services/api.masking.service";
    import { Response } from "@/store/alarm-masking/types";
    import { TimeZone, UserPermissions } from "@/store/types";
    import { MaskingReason } from "@/types/sv-data/masking/MaskingReason";
    import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
    import { Getter, namespace } from "vuex-class";
    import vSelect3 from "vselect3";
    import MaskType from "@/types/sv-data/enums/MaskType";
    import { Datetime } from "vue-datetime";
    import { Group } from "@/store/eventqueue/types";
    import { validationMixin } from "vuelidate";
    import { requiredIf, minValue, numeric } from "vuelidate/lib/validators";
    import moment from "moment";
	import { numberFormattingMixins } from '@/mixins';
    import { Constants } from "@/types/Constants";

    const Eventqueue = namespace("eventqueue");

	const { enforceWholeNumber } = numberFormattingMixins.methods;

	@Component({
        components: {
            "v-select-3": vSelect3,
            "date-time": Datetime
        },
        mixins: [validationMixin],
        validations() {
		    return {
                selectedMaskingReason: {
                    required: requiredIf(function() {
                        return this.isMaskingReasonsFeatureEnabled
                    }),
                    validate: function(maskingReasonId: number){
                        if (!this.isMaskingReasonsFeatureEnabled){
                            return true;
                        }

                        return maskingReasonId > 0;
                    }
                },
                maskHours: {
                    numeric,
                    required: requiredIf(function() { return this.isHoursMinutesInputValid}),
                    minValue: minValue(0),
					validateRange: function(newValue: number) {
                        if (!this.isHoursMinutesInputValid) {
                            return true;
                        }

                        return newValue >= 0 && newValue <= this.maxMaskHoursDuration;
                    },
                    validateMaxDuration: this.validateMaskDuration
                },
                maskMinutes: {
                    numeric,
                    required: requiredIf(function() { return this.isHoursMinutesInputValid}),
                    validateRange: function(newValue: number) {
                        if (!this.isHoursMinutesInputValid){
                            return true;
                        }

                        return newValue >= 0 && newValue < 60;
                    },
                    validateMaxDuration: this.validateMaskDuration
                },
                maskUntilDateTime: {
                    minTime: function () {
                        if (!this.isDateTimeInputEnabled || !this.maskUntilDateTime) {
                            return true;
                        }
                        return moment(this.maskUntilDateTime).isAfter();
                    },
                    maxTime: function () {
                        if (!this.isDateTimeInputEnabled || !this.maskUntilDateTime) {
                            return true;
                        }

						const duration = moment.duration(moment(this.maskUntilDateTime).diff(moment())).asMinutes();
						return !duration || duration < this.maxMaskDuration;
                    },
                    required: requiredIf(function() { return this.isDateTimeInputEnabled && !!this.maskUntilDateTime })
                },
                maskingNote: {
                    required: requiredIf(function() { return this.isMaskingReasonNoteFeatureEnabled})
                }
            }
        }
	})

	export default class MaskingForm extends Vue {
        // Props
        @Prop({ type: Array, default(){ return [] }})
        public selectedResponses: Response[];

        @Prop({default: false, type: Boolean})
        public readonly showConfirmationButtons: boolean;

        // Getters
        @Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	    @Getter("getPermissions") permissions: UserPermissions;
        @Getter getTimeZone: (timeZoneId: number) => TimeZone;

        // State
        @Eventqueue.State("selectedGroup") selectedArea: Group;

        // Private Vars
        private maskingReasons: MaskingReason[] = [];
        private maskType: MaskType = MaskType.duration;
        private selectedMaskingReason: number = null;
        private maskHours: number = null;
        private maskMinutes: number = null;
        private maskUntilDateTime: string = "";
        private maximumMaskUntilDate: string = "";
        private maskingNote: string = "";
        private maxMaskDurationLimit: number = Constants.MAX_MASKING_DURATION;

		private async mounted(): Promise<void> {
            if (this.isMaskingReasonsFeatureEnabled)
            {
                this.maskingReasons = await maskingApi.getMaskingReasons(this.hasResponses);
            }
		}

        // Getter Methods
        private get isMaskingReasonsFeatureEnabled(): boolean {
			return this.getFeature(["Alarms", "Masking", "Advanced", "Reasons"]);
		}

        private get isMaskingReasonNoteFeatureEnabled(): boolean {
			return this.getFeature(["Alarms", "Masking", "Advanced", "Reasons", "NoteRequired"]);
		}

        private get isMaskUntilFeatureEnabled(): boolean {
			return this.getFeature(["Alarms", "Masking", "Advanced", "MaskUntil"]);
		}

        private get isMaskUntilValid(): boolean {
            return this.isMaskUntilFeatureEnabled &&
            this.permissions.canMaskUntilDateTime &&
            this.hasResponses;
        }

        private get isHoursMinutesInputValid(): boolean {
            return (this.isMaskUntilValid && this.maskType == MaskType.duration) || !this.isMaskUntilValid;
        }

        private get isDateTimeInputEnabled(): boolean {
            return this.isMaskUntilValid && this.maskType == MaskType.until;
        }

        public get timeZoneIana(): string {
		    // Get timezone based on selected area or browser if area not set
		    return this.getTimeZone(this.selectedArea.timeZoneID)?.iana ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
	    }

        private get hasResponses(): boolean {
            return this.selectedResponses != null && this.selectedResponses.length != 0;
        }

        public get formInvalid(): boolean {
            return this.$v.$invalid;
        }

        private get maxMaskDuration(): number {
            if (this.permissions.canDisarmSitesExtended) {
                return this.maxMaskDurationLimit;
            }

            var responsesMin = Math.min(...this.selectedResponses.map(response => response.maskDurationLimit));
            return this.hasResponses ? responsesMin : this.selectedArea.maskDurationLimit;
        }

        private get getMaskDuration(): number {
            if(!this.maskHours){
                return this.maskMinutes;
            }

            return +(this.maskHours * 60) + +this.maskMinutes;
        }

		private get maxMaskHoursDuration(): number {
			var maxHours = Math.floor(this.maxMaskDuration / 60);
			if((this.maxMaskDuration % 60) ==0){
				maxHours--;
			}
            return maxHours;
        }

		private get hoursMinutesDurationWarning(): string {
			if(this.maxMaskDuration <= 60) {
				return (`Masking duration must be more than 0 and less than max mask duration: ${this.maxMaskDuration} minutes.`);
			}
			var hours = Math.floor(this.maxMaskDuration / 60);
			return (`Masking duration must be more than 0 minutes and less than max mask duration: ${hours} hours and ${(this.maxMaskDuration % 60)} minutes.`);
		}

		private get hoursRangeWarning(): string {
			if(this.maxMaskDuration <= 60) {
				return ("Hours must be 0.");
			}
			return (`Hours must be between 0 and ${this.maxMaskHoursDuration}.`);
		}

        // Watchers
        @Watch("maskType")
        private onMaskTypeChange(): void {
            this.maskHours = null;
            this.maskMinutes = null;
            this.maskUntilDateTime = "";
        }

        @Watch("maskUntilDateTime")
        private onMaskDateTimeChanged(): void {
            this.$v.maskUntilDateTime.$touch();
            var futureDate = new Date(new Date().getTime() + this.maxMaskDuration * 60000);
            this.maximumMaskUntilDate = futureDate.toLocaleString()
        }

        @Watch("$v.$invalid")
        private evaluateMaskingFormValid(){
            this.$emit('masking-form-valid', this.$v.$invalid);
	    }

        // Public Methods
        public async maskAlarms(): Promise<void> {
            if (this.hasResponses) {
                this.selectedResponses.forEach(response => {
                    maskingApi.maskAlarm(
                        {
                            responseID: response.responseID,
                            groupID: response.groupID,
                            eventID: response.eventID,
                            disarmReasonID: this.selectedMaskingReason,
                            disarmNote: this.maskingNote,
                            maskType: this.maskType,
                            hours: this.maskHours,
                            minutes: this.maskMinutes,
                            date: this.maskUntilDateTime
                        }
                    );
                });
            }
            else
            {
                await maskingApi.maskAllAlarmsInArea(
                    this.selectedArea.groupID,
                    this.getMaskDuration,
                    this.selectedMaskingReason,
                    this.maskingNote,
                    );
            }

            if (this.showConfirmationButtons) {
                this.$emit('masked-alarm');
            }
        }

        public async maskAlarm(): Promise<void> {
            var response = this.selectedResponses.find(r => r !== undefined);
            await maskingApi.maskAlarm({
                        responseID: response.responseID,
                        groupID: response.groupID,
                        eventID: response.eventID,
                        disarmReasonID: this.selectedMaskingReason,
                        disarmNote: this.maskingNote,
                        maskType: this.maskType,
                        hours: this.maskHours,
                        minutes: this.maskMinutes,
                        date: this.maskUntilDateTime
                    }
                );

            this.$emit('masked-alarm');
        }

        // Private Methods
        private validateMaskDuration(): boolean {
            if (!this.isHoursMinutesInputValid){
                return true;
            }

            var maskDuration = this.getMaskDuration;
			if(maskDuration == null) {
				return true;
			}
            return maskDuration > 0 && maskDuration < this.maxMaskDuration;
        }

		private formatterForHoursAndMinutes(value:number): number {
			return enforceWholeNumber(value);
		}

        private destroyed(): void {
            this.clearState();
	    }

        private clearState(): void {
            // Clear State
		    this.selectedResponses = null;
            this.maskingReasons = [];
            this.maskType = MaskType.duration;
            this.selectedMaskingReason = null;
            this.maskHours = null;
            this.maskMinutes = null;
            this.maskUntilDateTime = "";
            this.maskingNote = "";
            this.$v.$reset();
        }

        // Emitters
        @Emit("close")
        private close(): void {
            this.clearState();
	    }
    }
