
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { clone } from 'lodash';
import { TextValidationResult } from "@/types/text-validation-result";
import { RulesTextValidator } from "@/store/rules-engine/types";

@Component({})
export default class RulesText extends Vue {
    @Prop({type:String, default: ""})
    private fieldName: string;

    @Prop({type: String, default: ""})
    private value: string;

	@Prop({ type: Boolean, default: false })
	private readOnly: boolean;

	@Prop({type: Function, required: false})
	private readonly validation: RulesTextValidator | null;

    private inputText: string = "";

    private mounted() {
        this.valueUpdate();
    }

    @Watch("value")
    private valueUpdate(): void {
        if(this.inputText == this.value) {
            return;
        }
        this.inputText = this.value ? clone(this.value): "";
        this.validationUpdated();
    }

    //Methods
    private validationUpdated(): void {
		if (this.validation) {
			const validationResult: TextValidationResult = this.validation(this.inputText);
			if (!validationResult.isValid) {
            	this.$emit("isValid", { isValid: false, message: validationResult.message });
				return;
			}
		}

        if(this.inputText.length > 0) {
            this.$emit("isValid", {isValid: true, message:""});
            return;
        }
        this.$emit("isValid", {isValid: false, message:`*${this.fieldName ? this.fieldName : "Field"} must be at least one character`});
    }

    @Watch("inputText")
    @Emit("input")
    private valueUpdated(value: any): any {
        this.validationUpdated();
        return value;
    }
}
