import { GetterTree } from "vuex";
import { TourState, Tour } from "./types";
import { RootState } from "@/store/types";
import { get, cloneDeep } from "lodash";
import { State } from "vuex-class";
import moment from "moment";

export const userManagementGetters: GetterTree<TourState, RootState> = {
	/** Tours Setup */
	getAllTours: (state: TourState) => { return state.Tour; },
	getDeviceList: (state: TourState) =>  {  return cloneDeep( state.DeviceList ) },
	getTour: (state: TourState) => (tourId: number) => {
		return cloneDeep(state.Tour.find(x => x.tourId === tourId));
	},
	getSearchDevices: (state: TourState) => {return state.searchedDeviceList; },

	/** Predefined Tours */
	getPredefinedTour: (state: TourState) => { return state.PredefinedTour; },
	getPrefinedTourCurrentCamera: (state: TourState) => {
		return state.PredefinedTour.currentCamera[state.PredefinedTour.currentCameraIndex];
	},
	tourInProgress: (state: TourState) => {  return state.isInTour; },
	getActiveCell: (state: TourState) => { return state.PredefinedTour.isActive; }
}