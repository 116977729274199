
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter, Action } from "vuex-class";
import { axiosInstance } from "@/axios.instance";
import api from "@/services/api.service";
import { EventDetails, EventShareUser } from "@/store/site-monitor/types";
import { retry } from "rxjs/operators";

const EventQueue = namespace("eventqueue");

@Component({
	components: {}
})
export default class QueueInactivityWarning extends Vue {
	@Getter("getAlarmScreenTimeoutSeconds") alarmScreenTimeoutSeconds: any;
	@EventQueue.Getter("getLastEventHandled") lastEventHandled;
	@EventQueue.Mutation setLastEventHandled: () => void;
	@Action logout: () => void;

	public hide: boolean = true;
	public checkTimer: NodeJS.Timeout = null;
	public countdownSeconds: number = 20;
	public inactivityWarningSeconds: number = 20;

	mounted() {
		if (!this.alarmScreenTimeoutSeconds) return;

		if (this.alarmScreenTimeoutSeconds < this.countdownSeconds)
			this.countdownSeconds = this.alarmScreenTimeoutSeconds;

		let startWarningAt =
			this.alarmScreenTimeoutSeconds * 1000 -
			this.countdownSeconds * 1000 +
			1000;

		this.checkTimer = setInterval(() => {
			let lastActivity = this.lastEventHandled;
			if (lastActivity) {
				let inactiveTime = new Date().valueOf() - lastActivity;

				if (inactiveTime > startWarningAt) {
					if (this.hide) {
						this.inactivityWarningSeconds = this.countdownSeconds;
						this.hide = false;
					} else {
						if (this.inactivityWarningSeconds <= 0) {
							api.logout().then(() => {
								this.logout();
							});
						} else {
							this.inactivityWarningSeconds--;
						}
					}
				} else {
					if (!this.hide) {
						this.hide = true;
					}
				}
			}
		}, 1000);
	}

	beforeDestroy() {
		clearInterval(this.checkTimer);
		this.checkTimer = null;
	}

	cancelCountdown() {
		this.setLastEventHandled();
	}
}
