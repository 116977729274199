
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import VueSelect from 'vue-select';
import { BTable } from 'bootstrap-vue';

export interface AlarmDecoderEvent {
    serverTypeID: number;
    eventNum: number;
    title: string;
    eventCode: string;
    defaultPriority: string;
    isRaiseIndividual: boolean; // Default Grouping
}

@Component({
    components: {
        "vue-select": VueSelect,
    }
})
export default class AlarmDecoderEventSetup extends Vue {
    $refs!: {
		eventTypes: BTable;
	};

    @Prop()
    private value: AlarmDecoderEvent[]

    @Prop({type: Boolean, default: false})
    private readonly: boolean;

    private localEvents: any[] = [];
    private selectedEvent: any = {};
    private addOrEdit: boolean = false;
    private deleting: boolean = false;
    private editingIndex: number = -1;

    private defaultEventType: AlarmDecoderEvent = {
        serverTypeID: null,
        eventNum: null,
        title: "",
        eventCode: "",
        defaultPriority: "",
        isRaiseIndividual: false,
    };

    private selectedEventType: AlarmDecoderEvent = {
        serverTypeID: null,
        eventNum: null,
        title: "",
        eventCode: "",
        defaultPriority: "",
        isRaiseIndividual: false,
    };

    @Watch("value")
    private valueWatcher(): void {
        this.setLocalEvents();
    }

    private setLocalEvents(): void {
        this.localEvents = this.value ? [...this.value] : [];
    }

	private async mounted(): Promise<void> {
		this.setLocalEvents();
    }

    private fields = [
        {
            key: "title",
            label: "Event Type Name",
            thStyle: { width: '25%'}
        },
        {
            key: "eventCode",
            label: "Event Code",
            thStyle: { width: '25%'}
        },
        {
            key: "defaultPriority",
            label: "Default Priority",
            thStyle: { width: '21%'}
        },
        {
            key: "isRaiseIndividual",
            label: "Default Grouping",
        },
        {
            key: "edit",
            label: "",
            thStyle: { width: '100px'}
        }
    ];


    @Emit("input")
    private saveEventType(): AlarmDecoderEvent[] {
        this.addOrEdit = false;
        const eventType = {...this.selectedEventType};
        if(!this.localEvents) {
            this.localEvents = [];
        }

        if(this.editingIndex >= 0){
            var events = [...this.localEvents];
            events[this.editingIndex] = eventType;
            this.localEvents = [];
            this.localEvents = events;
        } else {
            this.localEvents.push(eventType);
        }
        this.editingIndex = -1;
        this.selectedEventType = {...this.defaultEventType};
        return this.localEvents;
    }

    @Emit("input")
    private deleteEventType(index: number): AlarmDecoderEvent[] {
        this.$refs.eventTypes.clearSelected();
        this.editingIndex = -1;
        this.deleting = false;
        this.localEvents.splice(index,1);
        return this.localEvents;
    }

    private setEditEventType(data: any, setDelete: boolean): void {
        const resultEventType = {...data.item};
        this.selectedEventType = resultEventType;
        if(setDelete){
            this.addOrEdit = false;
            this.deleting = true;
        } else {
            this.addOrEdit = true;
            this.deleting = false;
        }
        this.$refs.eventTypes.selectRow(data.index)
        this.editingIndex = data.index;
    }

    private cancelEventType(): void {
        this.addOrEdit = false;
        this.deleting = false;
        this.selectedEventType = {...this.defaultEventType};
        this.$refs.eventTypes.clearSelected();
        this.editingIndex = -1;
    }

    private groupings: any[] = [
        {
            label: "Inherit",
            value: false
        },
        {
            label: "No Grouping",
            value: true
        }
    ];

    private get isSaveDisabled(): boolean {
        if (this.selectedEventType.title && this.selectedEventType.eventCode && this.selectedEventType.defaultPriority)
            return false;
        return true;
    }

    private setDefaultGrouping(value: any): void {
        this.selectedEventType.isRaiseIndividual = value.value;
    }

    private addNewEventType(): void{
        this.addOrEdit = true;
        this.deleting = false;
        this.selectedEventType = {...this.defaultEventType};
        this.$refs.eventTypes.clearSelected();
        this.editingIndex = -1;
    }
}
