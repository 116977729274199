import { MutationTree } from "vuex";
import { DistanceConfigurationPagedResponse, DistanceConfigurationState } from "./types";

export const mutations: MutationTree<DistanceConfigurationState> = {
	setDistanceConfigurations(state: DistanceConfigurationState, data: DistanceConfigurationPagedResponse) {
		const defaultdata: DistanceConfigurationPagedResponse = {
			pageNumber: 0,
			pageSize: 0,
			totalRecords: 0,
			data: []
		}

		state.distanceConfigurations = data ? data : defaultdata;
	},
	setMaxRadius(state: DistanceConfigurationState, maxRadius: number) {
		state.maxRadius = maxRadius;
	}
};
