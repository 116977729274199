import { axiosInstance, axiosJsonInstance } from "@/axios.instance";
import { AxiosInstance } from "axios";
import AvailableTenantsResponse from "@/types/sv-data/responses/AvailableTenantsResponse";

export interface SupportEngagementRequest {
	startsFrom: Date;
	expiresAt: Date;
	comment: string;
}

export interface SupportEngagementDto {
	id: number;
	grantedByUser: string;
	startsFrom: Date;
	expiresAt: Date;
	comment: string;
}

class SupportEngagementApi {
	private axios: AxiosInstance;
	private axiosJson: any;

	constructor() {
		this.axios = axiosInstance;
		this.axiosJson = axiosJsonInstance;
	}

	public async getAvailableTenants(): Promise<AvailableTenantsResponse> {
		const { data } = await this.axios.get(`/SupportEngagement/AvailableTenants`);
		return data;
	}

	public async create(supportEngagementRequest: SupportEngagementRequest): Promise<void> {
		await this.axiosJson.post(`/SupportEngagement`, supportEngagementRequest);
	}

	public async get(): Promise<SupportEngagementDto[]> {
		const { data } = await this.axios.get(`/SupportEngagement`);
		return data;
	}

	public async delete(id: number) {
		await this.axiosJson.delete(`/SupportEngagement/${id}`);
	}

	public async getCurrentSupportEngagementExpiry() {
		const { data } =  await this.axiosJson.get("/SupportEngagement/CurrentSupportEngagementExpiry");
		return data;
	}

}

const supportEngagementApi = new SupportEngagementApi();
export default supportEngagementApi;
