export interface CustomFieldsState {
	areaCustomFields: CustomFieldDto[],
	contactCustomFields: CustomFieldDto[],
	alarmCustomFields: CustomFieldDto[],
	totalCustomFields: number;
}

export interface CustomFieldValues {
	id: string;
	value: any;
}

export interface CustomFieldValueAndSettings{
	value: CustomFieldValues,
	setting: CustomFieldDto
}

export interface CustomField {
	id: number;
	customFieldTableTypeId: number;
	customFieldTypeId: number;
	title: string;
	required: boolean;
	placeholder: string;
	order: number;
}

export interface CustomFieldDto extends CustomField {
}

export interface CustomFieldForDisplay extends CustomField {
	customFieldType: string;
}

export interface CustomFieldToggleCollapseState {
	id: number;
	isOpen: boolean;
}

export enum CustomFieldTableTypes {
	Area = 1,
	Contact = 2,
	Alarm = 3
}

export enum CustomFieldTypes {
	Text = 1,
	TextArea = 2,
	Number = 3,
	Checkbox = 4
}
