
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { EventQueuePriorityColorsExample, EventQueuePriorityColors } from '@/store/eventqueue/types';
import organizationApi from "@/services/api.organizationSettings.ts";
import { debounce } from "lodash";

@Component({})
export default class ColorModalItem extends Vue {
    @Prop()
    private model: EventQueuePriorityColorsExample;
    private result: boolean = false;
    private errorMessage: string = "";
    private lowestPriority: number = 0;
	private highestPriority: number = 999999;
    private minPriorityNumberConverted: number = 0;
    private maxPriorityNumberConverted: number = 0;
    private debouncedValidateColorPriority: (() => Promise<void>) | null = null;

    private get examplePriority(): string
    {
        return this.model.minPriority + " - " + this.model.maxPriority;
    }

    private get priorityStyle(): string
	{
		return `background-color: ${this.model.backgroundColor}; color: ${this.model.foregroundColor};font-size:14px`;
	}

    private created(): void {
        this.debouncedValidateColorPriority = debounce(async () => {
            await this.validateColorPriority();
        }, 1000);
    }

    @Watch("model.minPriority")
    @Watch("model.maxPriority")
	private onPriorityChange(): void {
        if (this.debouncedValidateColorPriority !== null) {
            this.debouncedValidateColorPriority();
        }
    }

    private async validateColorPriority(): Promise<void> {
        this.minPriorityNumberConverted = Number(this.model.minPriority);
        this.maxPriorityNumberConverted = Number(this.model.maxPriority);
        if (this.model.minPriority == null || this.model.minPriority.toString() == "") { // int 0 is valid but no value is invalid
            this.result = false;
            this.errorMessage = "Invalid Priority: Minimum priority cannot be empty";
        }
        else if (this.model.maxPriority == null || this.model.maxPriority.toString() == "") {
            this.result = false;
            this.errorMessage = "Invalid Priority: Maximum priority cannot be empty";
        }
        else if (this.minPriorityNumberConverted < this.lowestPriority) {
            this.result = false;
            this.errorMessage = `Invalid Priority: Minimum priority cannot be less than ${this.lowestPriority}`;
        }
        else if (this.maxPriorityNumberConverted < this.lowestPriority) {
            this.result = false;
            this.errorMessage = `Invalid Priority: Maximum priority cannot be less than ${this.lowestPriority}`;
        }
        else if (this.minPriorityNumberConverted > this.highestPriority) {
            this.result = false;
            this.errorMessage = `Invalid Priority: Minimum priority cannot be greater than ${this.highestPriority}`;
        }
        else if (this.maxPriorityNumberConverted > this.highestPriority) {
            this.result = false;
            this.errorMessage = `Invalid Priority: Maximum priority cannot be greater than ${this.highestPriority}`;
        }
        else if (this.minPriorityNumberConverted > this.maxPriorityNumberConverted){
            this.result = false;
            this.errorMessage = "Invalid Priority: Minimum Priority cannot be greater than maximum priority";
        } else {
            var fullModel = { eventQueuePriorityColorId: this.model.eventQueuePriorityColorId ?? 0, backgroundColor: this.model.backgroundColor, foregroundColor: this.model.foregroundColor, minPriority: this.minPriorityNumberConverted, maxPriority: this.maxPriorityNumberConverted  } as EventQueuePriorityColors
            this.result = await organizationApi.validateAlarmQueuePriorityColors(fullModel);
            if (!this.result){
                this.errorMessage = "Invalid Priority: Priority range overlaps with existing priority range";
            }
        }
        this.$emit("isValid", { isValid: this.result });
    }
}
