
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { EventRecord, EventDetails } from "@/store/site-monitor/types";
import { ServerEventType } from "@/store/eventqueue/types";

const SiteMonitor = namespace("siteMonitor");
const Eventqueue = namespace("eventqueue");
const SMCameras = namespace("siteMonitorCameras");

@Component
export default class CreateNewResponse extends Vue {
	$refs!: {
		createResponseDialog: any;
	};

	@Prop(Number)
	eventId!: number;

	@Prop(Object)
	selectedEventRecord!: EventRecord;

	@Prop(Number)
	groupId: number;

	@SiteMonitor.Getter getEventDetails: EventDetails;
	@Eventqueue.Getter("serverEventTypes") eventTypes: ServerEventType[];
	@SiteMonitor.Action createResponseActionForEventRecord: any;
	@SiteMonitor.Mutation setEventRecordResponse: any;
	@SMCameras.Mutation setLinkRecord: any;

	public responseBuilderForm: any = {
		title: ""
	};

	mounted() {
		this.responseBuilderForm.eventRecordID = this.selectedEventRecord.eventRecordID;
		this.responseBuilderForm.title = this.getDefaultResponseTitle();
	}

	getDefaultResponseTitle() {
		let title = "";
		let matchingEventType = this.eventTypes.find(
			eventType =>
				eventType.eventNum ==
				this.selectedEventRecord.serverTypeEventNum
		);
		if (matchingEventType) {
			title = `${matchingEventType.title} from input ${this.selectedEventRecord.input1}`;
		} else {
			title = `Alarm type ${this.selectedEventRecord.serverTypeEventNum} from input ${this.selectedEventRecord.input1}`;
		}
		return title;
	}

	isValid() {
		return (
			this.responseBuilderForm.priority &&
			!isNaN(Number(this.responseBuilderForm.priority))
		);
	}

	async submit(shouldLinkCamera: boolean) {
		if (this.isValid()) {
			await this.createResponseActionForEventRecord({
				eventRecordId: this.responseBuilderForm.eventRecordID,
				title: this.responseBuilderForm.title,
				priority: this.responseBuilderForm.priority,
				groupId: this.groupId
			});
			if (shouldLinkCamera) {
				this.setLinkRecord(this.selectedEventRecord);
			}
		}
	}
}
