
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import GPSService from "@/services/gps.service";
import { namespace } from 'vuex-class';

import {
	convertStringToLatLng,
} from "@sureview/v2-mapping-saas";
import { MobileEventShareInfo } from '@/store/mobile/types';


const geoCoder = new GPSService();
const Mobile = namespace("mobile");

@Component({
    components:{}
})

export default class EventShareItem extends Vue {
	@Mobile.State("DeviceLocation") private userLocation: any;
    @Mobile.State("NavigationMode") private navMode: string;
    @Mobile.State("NavigateToActiveAlarm") private navigateToAlarm: boolean;
    @Mobile.Mutation("setNavigateToActiveAlarm") private setNavigateToActiveAlarm: (navigateTo: boolean) => void;
    @Mobile.Mutation("setFocusedEventID") private setFocusedEvent: (eventID: number) => void;
	@Mobile.Mutation("toggleRightSideBarVisibility") private toggleRightSideBar: () => void;
	@Mobile.Mutation("setChatTab") private setChatTab: (tab: string) => void;
	@Mobile.Mutation("setChatOpen") private setChatOpen: (open: boolean) => void;

    @Prop({default: false, type: Boolean})
    private pendingUpdate: boolean;

    @Prop()
    private item: MobileEventShareInfo;

    @Prop({default: false, type:Boolean})
    private active;


    private get distanceFromUser(): string {
        try {
            //If we can't work out the distance don't bother showing it
            if(!this.item.latLng || !this.item.latLng.lat || !this.userLocation)
                return "";

            // the device location is a function we need to retrieve the lat and Long and parse into the
            // google maps latLng interface for the decoder to work
            const deviceLocation = convertStringToLatLng(this.userLocation.lat() + " " + this.userLocation.lng());
            var distanceInMeters = geoCoder.getDistance(this.item.latLng, deviceLocation);
            if(distanceInMeters < 1001){
                return Math.round(distanceInMeters) + " m"
            } else {
                return geoCoder.metersToKm(distanceInMeters).toFixed(2) + " km"
            }
        } catch {
            return "";
        }
    }

    @Emit("updateEvent")
    private updateEventState(state: number): MobileEventShareInfo {
        let eventShare = {...this.item};
        eventShare.state = state;
        return eventShare;
    }

    //Triggers the map to focus on the alarm in question
    private focus(): void{
        if(!this.distanceFromUser)
            return;

        this.setFocusedEvent(this.item.eventID);
        this.toggleRightSideBar();
    }

    private openChat(): void {
        this.setChatTab("EventShare");
        this.toggleRightSideBar();
        this.setChatOpen(true);
    }
}
