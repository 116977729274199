import { MutationTree } from "vuex";
import { TenantOverview, UserContextState } from "./types";
import { getDefaultState } from "./state";
import { PollingStatus } from "@/types/PollingStatus";

export const mutations: MutationTree<UserContextState> = {
	setTenantOverview(state, tenantOverview: TenantOverview) {
		state.tenantOverview = tenantOverview;
		state.isSet = true;
	},
	setPollingStatus(state, pollingStatus: PollingStatus) {
		state.pollingStatus = pollingStatus;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
