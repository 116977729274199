import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { SystemHealthState } from "./types";

export const actions: ActionTree<SystemHealthState, RootState> = {
	async retrieveHealthStatuses({ state, commit },  refresh :boolean = false): Promise<void> {
        var healthStatuses = await api.getSystemHealthStatus(refresh);
        try
        {
            commit("setHealthStatus", healthStatuses);
        }
        catch(ex)
        {
            console.error(ex);
        }
	},
};