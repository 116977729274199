import { TourState, Tour, TourDevices } from "./types";

export function getDefaultState(): TourState {
    return {
        Tour: Array<Tour>(),
        DeviceList: Array<TourDevices>(),
        PredefinedTour: null,
        isInTour: false,
        searchedDeviceList: Array<TourDevices>()
    }
}