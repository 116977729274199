import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { ContactInfoModel, SignUpState } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<SignUpState, RootState> = {
    async saveContactInfo({ commit }, contactInfo: ContactInfoModel) {
        commit("setContactInfo", contactInfo);
        commit("advanceCurrentStep");
    },
    async fetchIsCreditCardRequired({ commit }) {
        let result = await api.isCreditCardRequired();
        commit("setIsCreditCardRequired", result);
    }
};
