
import { Component, Vue, Prop } from "vue-property-decorator";
import { MobileRaiseProjection } from "@/store/mobile-raise/types";
import { sortBy } from "lodash";
import Title from "./Title.vue";

@Component({
	components: {
		"raise-title": Title
	}
})
export default class QuickSelect extends Vue {
	@Prop() raises: MobileRaiseProjection[];

	get sortedRaises(): MobileRaiseProjection[] {
		return sortBy(this.raises, ["gridSetSlot"]);
	}

	get raiseItemClass(): string[] {
		return ["quick-item", this.getItemsClass()];
	}

	private getItemsClass(): string {
		if (this.raises.length <= 2) {
			return "one-col";
		} else if (this.raises.length <= 6) {
			return "two-col";
		} else {
			return "three-col";
		}
	}
}
