
import { Component, Vue, Watch } from "vue-property-decorator";
import api from "@/services/api.service";
import { passwordValidationMixins } from "@/mixins";

const { getPasswordStrength } = passwordValidationMixins.methods;

@Component
export default class SetPassword extends Vue {

	private password: string = "";
	private passwordConfirmation: string = "";
	private passwordStrength = "";
	private isLoading: boolean = false;
	private errorMessage: string = "";

 	private async submit() {
		if (!this.isValid()) {
			return;
		}

		try {
			this.isLoading = true;
			let token = this.$route.query.token as string;
			await api.setPassword(token, this.password);
			this.isLoading = false;
			await this.$router.push({ path: "/login", query: { hasSetPassword: "true" }});
		}
		catch (error) {
			this.errorMessage = error.response.data;
			this.isLoading = false;
			return;
		}
	}

	private isValid(): boolean {
		if (
			(this.password.length === 0 || this.passwordConfirmation.length === 0) ||
			(this.passwordConfirmation != this.password)
		) {
			return false;
		}

		if (this.passwordStrength === "Weak") {
			return false;
		}

		return true;
	}

	@Watch("password")
	private updatePasswordStrength() {
		this.passwordStrength = getPasswordStrength(this.password);
	}
}
