import { TasksState } from "./types";

export function getDefaultState(): TasksState {
    return {
        Tasks: [],
        TaskTypes: [],
        TaskCategories: [],
        TaskLibrary: [],
        ActionPlans: [],
        OutstandingRequiredTasks: [],
        ToggleActionListModal: false,
        NotificationServers: [],
        NotificationTemplates: [],
        EditTriggerAction: null,
        CallContacts: [],
        DynamicDataList: [],
        DynamicData: [],
		ScriptCategoryLinks: []
    }
}
