import { TextValidationResult } from "@/types/text-validation-result";

export interface RuleStep
{
    id: string;
    operator: string;
    condition: RuleCondition;
    subRules: RuleStep[];
}

export interface RuleCondition
{
    typeId: number;
    value: any;
}

export interface TriggerCondition
{
    triggerTypeId: number;
    value: any;
}

export interface RuleTrigger
{
    id: string;
    operator: string;
    condition: TriggerCondition;
    subTriggers: RuleTrigger[];
}

export interface RuleAction
{
	id: string;
	actionTypeId: number;
	value: any;
}

export interface Rule
{
	ruleId: number,
	title: string,
	tenantId: number,
	serverTypeId?: number,
	grouping: number,
	responseId?: number,
	serverId?: number,
	serverTypeEventNum?: number,
	input1?: number,
	input2?: number,
	triggers: RuleTrigger[];
	actions: RuleAction[];
	serverTitle?: string;
	serverTypeTitle?: string;
	responseTitle?: string;
}

export interface RulesWithTableActions extends Rule {
	controls: boolean;
}

export interface RulesWithPagination
{
	pageNumber: number;
	pageSize: number;
	totalRecords: number;
	data: Rule[];
}

export interface ServersForServerType {
	serverTypeId: number;
	servers: RuleServer[];
}

export interface ServerTypeEventsForServerType {
	serverTypeId: number;
	serverTypeEvents: RuleServerTypeEvent[];
}

export interface RulesEngineState
{
	rules: RulesWithPagination,
	editingRule?: EditingRule,
	alarmsForGroupId: Map<number, RuleGenericDropdownItem[]>,
	serverTypes: RuleServerType[],
	actionsPlans: RuleActionPlan[],
	serverTypeEventsForServerType: ServerTypeEventsForServerType[]
	serversForServerType: ServersForServerType[]
}

export interface RulesLogicAllowed
{
    allowAnd: boolean
    allowOr: boolean
    subRules?: RulesLogicAllowed
}

export interface ComponentToValue
{
    value: any;
    component: any
    props: any
}

export interface CardComponent
{
    component: any,
    props: any
}

export interface CardValidation
{
    isValid: boolean,
    message: string
}

export class CardValidationMap
{
	[s: string]: CardValidation;
}

export interface EditingRule
{
	ruleId: number,
	title: string,
	tenantId: number,
	serverTypeId?: number,
	grouping: number,
	responseId?: number,
	serverId?: number,
	serverTypeEventNum?: number,
	input1?: number,
	input2?: number,
	ruleTriggers: RuleStep[];
	ruleActions: RuleStep[];
}

export interface RuleScope
{
	responseId: number,
	serverId: number,
	serverTypeId: number,
	eventNum: number,
	input1: number,
	input2: number
}

export interface Grouping {
	id: number;
	title: string;
}

export interface RuleAlarm
{
	responseId: number,
	title: string,
	serverTypeId: number,
	groupId: number
}

export interface RuleServer
{
	serverId: number,
	title: string,
	serverTypeId: number
}

export interface RuleActionPlan
{
	actionPlanId: number,
	title: string
}

export interface RuleServerTypeEvent
{
	serverTypeId: number,
	eventNum: number,
	title: string,
	groupId?: number
}

export interface RuleServerType {
	serverTypeId: number,
	title: string,
	tenantId?: number
}

export interface RuleAlarm
{
	responseId: number,
	title: string,
	serverTypeId: number,
	groupId: number
}

export interface RuleServer
{
	serverId: number,
	title: string,
	serverTypeId: number
}

export interface RuleActionPlan
{
	actionPlanId: number,
	title: string
}

export interface RuleServerTypeEvent
{
	serverTypeId: number,
	eventNum: number,
	title: string,
	groupId?: number
}

export interface RuleServerType {
	serverTypeId: number,
	title: string,
	tenantId?: number
}

export enum RuleDropdownOptions {
	serverType = 1,
	server = 2,
	serverTypeEvent = 3
}

export interface RuleGenericDropdownItem {
	id: number;
	title: string;
}

export interface RulesTextValidator {
	(text: string): TextValidationResult;
}

export interface ActionOption {
	id: number;
	title: string;
}
