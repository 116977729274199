import { Module } from "vuex";
import { getters } from "./getters"
import { actions } from "./actions";
import { mutations } from "./mutations";
import { SubscriptionState } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: SubscriptionState = getDefaultState();

export const subscription: Module<SubscriptionState, RootState> = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
