
import { Prop, Vue, Component } from "vue-property-decorator";
import { CustomFieldDto } from "@/store/custom-fields/types";
import api from "@/services/api.customField.service";

@Component({})
export default class CustomFieldDeleteView extends Vue {
	@Prop({ required: true }) public selectedCustomField: CustomFieldDto;
	@Prop({ required: true }) public isDeleteViewShown: boolean;

	private safeDeleteInput: string = "";

	private get isDeleteConfirmed(): boolean {
		if (!this.selectedCustomField || !this.selectedCustomField.title) {
			return false;
		}

		return this.safeDeleteInput !== `delete ${this.selectedCustomField.title}`;
	}

	private async deleteCustomField(): Promise<void> {
		try {
			await api.deleteCustomField(this.selectedCustomField.id);
			this.showTableView();
		} catch (ex) {
			console.error("Failed to delete custom field: " + ex);
		}
	}

	private showTableView(): void {
		this.$emit("show-table-view", true);
	}
}
