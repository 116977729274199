import { GetterTree } from "vuex";
import { SetupState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<SetupState, RootState> = {
	getAllFeatures: state => state.allFeatures,
	getActionPlans: state => {
		return state.actionPlans;
	},
	getHasCurrentArea: state => (state.currentAreas ? true : false),
	getResponseAlarmTypes: state => state.responseAlarmTypes,
	getAreaActionPlans: state => {
		if (!state.currentAreas) {
			return state.actionPlans;
		}

		return state.actionPlans.filter(
			actionPlan => !actionPlan.groupID || state.currentAreas.has(actionPlan.groupID)
		);
	},
	getAreaResponseAlarmTypes: state => {
		if (!state.currentAreas) {
			return state.responseAlarmTypes.filter(responseAlarmType => !responseAlarmType.groupId);
		}

		return state.responseAlarmTypes.filter(
			responseAlarmType => !responseAlarmType.groupId || state.currentAreas.has(responseAlarmType.groupId)
		);
	}
};
