import _Vue from "vue";
import appConfig from "@/app.config.json";

const VueAppConfig = {
	install(Vue: typeof _Vue) {
		Vue.prototype.$config = appConfig;
	}
};

export default VueAppConfig;
