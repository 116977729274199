export {};

declare global {
	interface Array<T> {
		toDictionary(key: keyof T): any;
	}
}

if (!Array.prototype.toDictionary) {
	Array.prototype.toDictionary = function toDictionary<T> (this: T[], key: keyof T): { [key: string]: T } {
		let dictionary: any = {};
		for (let i = 0; i < this.length; i++) {
			let keyValue = this[i][key];
			dictionary[keyValue] = this[i];
		}
		return dictionary as { [key: string]: T };
	}
}