
import api from "@/services/api.service";
import { DistanceConfigurationDto } from "@/store/distance-configuration/types";
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class DistanceConfigurationDeleteModal extends Vue {
	@Prop({ type: Object, default: null })
	private distanceConfiguration: DistanceConfigurationDto;

	@Prop({ default: false, type: Boolean })
	private showModal: boolean;

	private confirmation: string = "";

	private get isDeleteButtonEnabled(): boolean {
		return this.confirmation.toLowerCase() == "delete";
	}

	@Emit("closeDeleteModal")
	private closeModal(): void { }

	private async onDelete(): Promise<void> {
		if (!this.isDeleteButtonEnabled) {
				return;
		}

		try {
			await api.deleteDistanceConfiguration(this.distanceConfiguration.distanceConfigurationId);
			this.$emit("reloadDistanceConfigurations");
			this.closeModal();				
		} catch (ex) {
			console.error("Unexpected error fetching alarm decoders ", ex);
		}
	}
}
