
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { get } from "lodash";

const SetupStore = namespace("setup");

@Component({
	components: {
		"feature-tree": () => import("@/components/setup/FeatureTree.vue")
	}
})
export default class FeatureTree extends Vue {
	@Prop(Array) features: any;
	@Prop(Boolean) parentDisabled: boolean;
	@Prop(String) parentName: string;

	private localFeatures: any[] = [];

	private async mounted() {
		this.localCopyFeatures();
	}

	private featureHasChildren(feature) {
		return feature.children && feature.children.length > 0;
	}

	private async localCopyFeatures() {
		this.localFeatures = this.features.map(feature => {
			return { ...feature };
		});
	}

	@Emit("statusChanged")
	private async toggleFeature(feature: any, localFeature: any = null) {
		// Captures the status changed event bubbled up from child component (checkbox changed) and emits the object to the parent component
		if (localFeature && feature.enabled) {
			localFeature.enabled = feature.enabled;
		}

		// Appends the parent name onto the bubbled up child feature to form the full feature path
		return {
			name: !localFeature && this.parentName ? `${this.parentName}/${feature.name}` : feature.name,
			enabled: feature.enabled
		};
	}

	@Watch("features", { immediate: true, deep: true })
	private onFeaturesChanged(value: any) {
		this.localCopyFeatures();
	}

	@Watch("parentDisabled", { immediate: true, deep: true })
	private onParentDisabled(value: boolean, oldValue: boolean) {
		// only toggle the child features if we're going from true to false
		if (!value && oldValue) {
			this.localFeatures.forEach(localFeature => {
				// toggle the child feature that are enabled, ignore the disabled child feature.
				if(localFeature.enabled) {
					localFeature.enabled = value;
					this.toggleFeature(localFeature);
				}
			});
		}
	}
}
