import { axiosInstance, axiosJsonInstance } from "@/axios.instance";
import { AxiosInstance } from "axios";
import { QuickEndSettingsDto } from "@/types/organization-settings/QuickEndSettings";
import { TenantSystemPrefs } from "@/store/types";
import { EventQueuePriorityColors } from "@/store/eventqueue/types";
import { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";

const OrganizationQuickEndSettingsUrl = "/OrganizationSettings/QuickEnd";
const OrganizationSettingsURL = "/OrganizationSettings";

class OrganizationApi {
	private axios: AxiosInstance;
	private axiosJson: AxiosInstance;

	constructor() {
		this.axios = axiosInstance;
		this.axiosJson = axiosJsonInstance;
	}

	public async retrieveTenantSystemPrefs(liveValue: boolean) : Promise<TenantSystemPrefs> {
		const { data } = await this.axiosJson.get(`${OrganizationSettingsURL}/TenantPrefs?liveValue=${liveValue}`);
		return data;
	}

	public async createOrUpdateTenantSystemPrefs(tenantSystemPrefs: TenantSystemPrefs) : Promise<TenantSystemPrefs> {
		const { data } = await this.axiosJson.put(`${OrganizationSettingsURL}/TenantPrefs`, tenantSystemPrefs);
		return data;
	}

	public async retrieveQuickEndSettings(groupID: number, liveValue: boolean) : Promise<QuickEndSettingsDto> {
		const { data, status } = await this.axios.get(`${OrganizationQuickEndSettingsUrl}/?groupId=${groupID}&liveValue=${liveValue}`);
		if (status == 204){
			return null;
		}

		return data;
	}

	public async createOrUpdateQuickEndSettings(quickEndSettingsRequest: QuickEndSettingsDto) : Promise<QuickEndSettingsDto> {
		const { data } = await this.axiosJson.put(OrganizationQuickEndSettingsUrl, quickEndSettingsRequest);
		return data;
	}

	public async deleteQuickEndSettings() : Promise<void> {
		await this.axios.delete(OrganizationQuickEndSettingsUrl);
	}

	public async createAlarmQueuePriorityColor(eventQueuePriorityColor: EventQueuePriorityColors): Promise<EventQueuePriorityColors>{
		const { data } = await this.axiosJson.post(`${OrganizationSettingsURL}/EventQueuePriorityColors`, eventQueuePriorityColor);
		return data;
	}

	public async retrieveAlarmQueuePriorityColors(paginatedSearchRequest: PaginatedSearchRequest): Promise<any>{
		const { data } = await this.axios.get(`${OrganizationSettingsURL}/EventQueuePriorityColors`, paginatedSearchRequest);
		return data;
	}

	public async updateAlarmQueuePriorityColor(eventQueuePriorityColor: EventQueuePriorityColors): Promise<EventQueuePriorityColors>{
		const { data } = await this.axiosJson.put(`${OrganizationSettingsURL}/EventQueuePriorityColors`, eventQueuePriorityColor);
		return data;
	}

	public async deleteAlarmQueuePriorityColor(eventQueuePriorityColorId: number): Promise<EventQueuePriorityColors>{
		const { data } = await this.axios.delete(`${OrganizationSettingsURL}/EventQueuePriorityColors/${eventQueuePriorityColorId}`);
		return data;
	}

	public async validateAlarmQueuePriorityColors(eventQueuePriorityColor: EventQueuePriorityColors): Promise<boolean> {
		const response = await this.axiosJson.post(`${OrganizationSettingsURL}/EventQueuePriorityColorsValidate`, eventQueuePriorityColor);
		return response.data;
	}
}

const organizationApi = new OrganizationApi();
export default organizationApi;
