import customFieldsApi from "@/services/api.customField.service";
import { ActionTree } from "vuex";
import { PaginatedSearchQueryParamsCustomField, RootState } from "../types";
import { CustomFieldsState, CustomFieldTableTypes } from "./types";
import PagedResponse from "@/types/sv-data/PagedResponse";
import { paginatedSearchExtendedMixins } from "@/mixins/paginatedSearchExtendedMixins";

const { generateNewPaginatedSearchQueryParamsCustomField, fetchAllResults } =
		paginatedSearchExtendedMixins.methods;

export const actions: ActionTree<CustomFieldsState, RootState> = {
	async retrieveCustomFields({ commit }, { tableType, live }) {
		const params: PaginatedSearchQueryParamsCustomField = {
			customFieldTableType: tableType,
			page: 1,
			pageSize: 250,
			live: live,
		}

		const request = generateNewPaginatedSearchQueryParamsCustomField(params);

		const results = await fetchAllResults<PagedResponse>(customFieldsApi, customFieldsApi.retrieveCustomFields, request);

		switch(tableType) {
			case CustomFieldTableTypes.Area:
				commit("setAreaCustomFields", results);
				break;
			case CustomFieldTableTypes.Contact:
				commit("setContactCustomFields", results);
				break;
			case CustomFieldTableTypes.Alarm:
				commit("setAlarmCustomFields", results);
				break;
		}
	},
};
