
import {  Prop, Vue, Component, Watch } from "vue-property-decorator";
import SystemViewTreeNode from '@/types/sv-data/system-view/SystemViewTreeNode';
import api from "@/services/api.service";
import { Getter } from 'vuex-class';
import { UserPermissions, FeaturesList } from '@/store/types';
import { get } from 'lodash';
import  { SetupSummaryResponse, GroupSyncSummary } from '@/types/sv-data/system-view/SetupSummary';
import GroupSyncConfig from '../device-setup/sync/GroupSyncConfig.vue';
import { truncateString } from "@/filters";

@Component({
	components: {
		"group-sync-config": GroupSyncConfig
	},
	filters: {
		truncateString
	}
})
export default class SetupOverviewGroupSync extends Vue {
	@Prop({ required: true, default: null }) private selectedNode: SystemViewTreeNode;

	@Getter getPermissions: UserPermissions;
	@Getter getFeaturesList: FeaturesList;

	private setupResponse: SetupSummaryResponse =  null;
	private groupSyncSummary: GroupSyncSummary = null;

	private async mounted(): Promise<void> {
		await this.retrieveGroupSyncDetails();
	}

	@Watch("selectedNode", { deep: true })
	private async retrieveGroupSyncDetails(): Promise<void> {
		if(this.selectedNode && this.canUseSystemView)
		{
			this.groupSyncSummary = null; // Reset previous display data

			this.setupResponse = await api.getSetupSummary(this.selectedNode.objectTypeId, this.selectedNode.objectId)

			if(this.setupResponse && !this.setupResponse?.error ) {
				try {
					this.groupSyncSummary =  JSON.parse(this.setupResponse.summary) as GroupSyncSummary
				}
				catch(e) {
					console.error("Failed to Parse server summary: "+ e)
					this.setupResponse.error = "Failed to Parse server summary";
				}
			}
		}
	}

	private get canUseSystemView(): boolean {
        return get(this.getFeaturesList, ["SystemView"]) && (this.getPermissions.canViewSystemView || this.getPermissions.isSystemAdmin);
    }
}

