
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import NavHeader from "@/components/NavHeader.vue";
import { get, cloneDeep } from "lodash";
import { FeaturesList } from "@/store/types";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { Tour } from "@/store/tours/types";
import TourSetupModal from "@/components/TourSetupModal.vue";

const Areas = namespace("areas");
const Tours = namespace("tours");

@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable,
		"tour-modal": TourSetupModal
	}
})
export default class TourSetup extends Vue {
	/** Store Getter/Setter */
	@Getter getUserTenantGroupId: number;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Tours.Action fetchAllTours: any;
	@Tours.Action removeTour: any;
	@Tours.Action fetchDeviceList: any;
	@Tours.Action removeDevice: any;
	@Tours.Action searchDeviceList: any;
	@Tours.Getter getTour: any;
	@Tours.Getter getAllTours: any;
	@Tours.Getter getDeviceList: any;
	@Tours.Getter getSearchDevices: any;
	@Tours.Mutation addSearchDevices: any;

	@Tours.Mutation addDevice: any;

	@Areas.Action getAreaList: any;
	@Areas.Getter("getAreaList") areaList!: any[];

	private isInvalid: boolean = true;
	private tourId: number = 0;

	// Modal display values
	private confirmModalVisible: boolean = false;
	private tourModalVisible: boolean = false;
	private detailsModalVisible: boolean = false;

	public $refs: Vue["$refs"] & {
		tourSetupRef: TourSetupModal;
	};

	private currentTour: Tour = {
		groupId: 0,
		title: "",
		tourId: 0,
		tourOrder: 0,
		tourSteps: [],
		functionality: null,
		tourStepsCount: 0,
		matrixColumnSize: 3, 
		matrixRowSize: 3
	};

	private get IsTourManagementEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "VideoTours"]);
	}

	private get tourGroupTitle(): string {
		const area = this.areaList.find(y => y.groupID == this.currentTour.groupId);
		return area ? area.title : "Area not found.";
	}

	private async mounted() {
		await this.getAreaList();
		await this.fetchAllTours();
		this.tourId = 0;
	}

	private cloneTourTemplate(): Tour {
		const tourTemplate: any = {
			groupId: this.getUserTenantGroupId,
			title: "",
			tourId: 0,
			tourOrder: 0,
			tourSteps: [],
			functionality: null,
			tourStepsCount: 0,
			matrixColumnSize: 3, 
			matrixRowSize: 3
		};
		// Deep cloned our template due to wanting it to be passed by VALUE not reference.
		return cloneDeep(tourTemplate);
	}

	/** Cancel new tour modal */
	private cancel(): void {
		this.tourModalVisible = false;
		this.currentTour = this.cloneTourTemplate();
		this.isInvalid = true;
		this.tourId = 0;
	}

	/** Save new or update tour */
	private async save() {
		await this.$refs.tourSetupRef.save();
		this.cancel();
	}

	private editTourDetails(tourId: number) {
		this.tourModalVisible = true;
		this.tourId = tourId;
	}

	private modalRemoveTour(tourId: number): void {
		this.currentTour = this.getTour(tourId);
		this.confirmModalVisible = true;
	}

	private showDetailsModal(tourId: number): void {
		this.currentTour = this.getTour(tourId);
		this.detailsModalVisible = true;
	}

	private removeTourFromList(): void {
		this.removeTour(this.currentTour.tourId);
		this.currentTour = this.cloneTourTemplate();
		this.confirmModalVisible = false;
	}

	private addTourSetup(): void {
		this.tourModalVisible = true;
		this.currentTour = this.cloneTourTemplate();
	}

	/** Generic table column headers */
	public columns: TableHeader[] = [
		{
			title: "Tour Title",
			key: "title",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "List of tours names",
			searchable: true,
			visible: true,
			dataType: "text",
			isTermLabel: true
		},
		{
			title: "Tour Steps",
			key: "tourStepsCount",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Number of Tour steps",
			searchable: false,
			visible: true,
			dataType: "text",
			useCustomCell: true
		},
		{
			title: "",
			key: "functionality",
			order: 3,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Edit or Delete",
			searchable: false,
			visible: true,
			dataType: "text",
			useCustomCell: true
		}
	];

	private validateSetup(value: any) {
		this.isInvalid = value;
	}
}
