
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { DeviceService } from "@sureview/camera";
import api from "@/services/api.service";

const SiteMonitor = namespace("siteMonitor");

@Component({})
export default class ControlTask extends Vue {
	@Getter getUserName: string;
	@SiteMonitor.Action createAuditRecord: any;
	@SiteMonitor.Getter("getEventDetails") eventDetails: any;

	@Prop() public task: any;
	@Prop(String) result: string;

	private deviceId: any = 0;
	private deviceTitle: string = "";
	private controlActionType: any = {};
	private description: string = "";

	/** Method to emit to parent and  Complete Task. */
	private async submitOutcome() {
		// Get the details reqd to trigger the relay, and attach the note
		const device = await api.deviceQuickControl(this.deviceId, "Action triggered in event");

		// If we got a device back, we can trigger it with the device service
		if (device && device.eventRecordID) {
			const deviceSvce = new DeviceService(api.getDeviceServiceAddress(), device.deviceServerEndpoint);

			try {
				await deviceSvce.sendOutputCmd(
					device.deviceServiceSession,
					device.deviceID,
					null, // outputType does not exist in device model so pass null at the moment
					this.controlActionType.title
				);
				// Emit for the result within parent
				this.$emit("result-changed", true);
				// Emit for the @complete within parent.
				this.$emit("complete");
			} catch (err) {
				this.$emit("result-changed", false);
			}
		}
	}

	public async mounted() {
		try {
			this.deviceId = this.task.taskData.control.deviceId;
			this.deviceTitle = this.task.taskData.control.title;
			this.controlActionType = this.task.taskData.controlAction;
			this.description = this.task.taskText;
		} catch (Ex) {
			console.error("error occured: ControlTask " + Ex);
		}
	}
}
