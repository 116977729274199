import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { EventSearchState } from "./types";
import { RootState } from "../types";
import { formatDateMixin } from "@/mixins";
import { devicesApi } from "@/services/api.devices.service";
import { ServerType } from "../devices/types";
const { currentDateFormatted, weekAgoDateFormatted } = formatDateMixin.methods;

const defaultSearchFilter = {
	from: weekAgoDateFormatted(),
	until: currentDateFormatted(),
	groupID: 1
};

export const actions: ActionTree<EventSearchState, RootState> = {
	async loadEvents({ commit }, filter = defaultSearchFilter) {
		commit("setEvents", []);
		commit("setSearching", true);
		const events = await api.eventSearch(filter);
		commit("setEvents", events);
		commit("setSearching", false);
	},

	async loadSearchableEventTypes({ state, commit }) {
		const searchableEventsNotSet = state.SearchableEventTypes.length === 0;
		if (searchableEventsNotSet) {
			const searchableEventTypes = await api.loadSearchableEventTypes();
			commit("setSearchableEventTypes", searchableEventTypes);
		}
		// else we already loaded the event types from the api so there's nothing to do
	},

	async loadDeviceTypes({ commit }) {
		const deviceTypes: ServerType[] = await devicesApi.getDeviceTypes();
		commit("setDeviceTypes", deviceTypes);
	},

	async loadOperators({ commit }) {
		const operators = await api.loadAvailableEventOperators();
		commit("setOperators", operators);
	},

	async loadEventCategories({ commit }) {
		const categories = await api.loadAvailableEventCategories();
		commit("setCategories", categories);
	},

	async loadAlarmTypes({ commit }, serverTypeID: number) {
		const alarmTypes = await api.loadAlarmTypes(serverTypeID);
		commit("setAlarmTypes", alarmTypes);
	}
};
