import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { AlarmMaskingState } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<AlarmMaskingState, RootState> = {
	async loadGroup({ commit }, groupId: number ) {
		let groupResponses = await api.responsesByArea(groupId);
		commit("setGroupId", groupId);
		commit("setResponses", groupResponses);
	},

	setMaxAlarmsToShow({ commit }, maxCount : number) {
		commit("setMaxAlarmsToShow", maxCount);
	},

};
