import { GetterTree } from "vuex";
import {
	CSVFormHandler,
	ModalItemCSV,
} from "./types";

function formattedByTitle(modalItems) {
	return modalItems.reduce(
		(mandatory, { title }) => ({
			...mandatory,
			[title]: true
		}),
		{}
	)
}

function formattedByTitleValue(modalItems) {
	return modalItems.reduce(
		(mandatory, value) => ({
			...mandatory,
			[value.title]: value
		}),
		{}
	)
}

function formattingValue({ elementForm, key, modalItem }) {
	const value = elementForm[key]

	if (modalItem.csvComponent === "checkbox") {
		return `${value}`.toLowerCase() === "true"
	}

	return value
}

function createFormData(csvData, validModalItems = []) {
	if(!csvData.length) return []
	const validModalByTitle = Object.entries(formattedByTitleValue(validModalItems))

	return csvData.map((elementForm, rowId) =>
		validModalByTitle
			.map(([key, value]: [string, ModalItemCSV & ModalItemCSV], indexCell) => ({
				...value,
				csvValue: formattingValue({ elementForm, key, modalItem: value }),
				rowId,
				uniqId: `Row=${rowId}indexCell=${indexCell}`,
				indexCell
			}))
	)
}

export const getters: GetterTree<CSVFormHandler, any> = {
	isValidForm: state => name => Object.keys(state.errorsForm[name] || {}).length === 0,

	formattedErrorsByRowId: state => name => Object.values(state.errorsForm[name] || {})
		.reduce(<T extends {[key: string]: any}>(acc, cur: T) => {
			if(acc[cur.rowId] !== undefined) acc[cur.rowId].push(cur)
			else acc[cur.rowId] = [cur]

			return acc
		}, {}),

	errorsColumn: state => name => {
		return Object.values(state.errorsForm[name] || {})
			.reduce(<T extends {[key: string]: any}>(acc: T, { indexCell }) => ({
				...acc,
				[indexCell]: true
			}), {})
	},

	errorsRow: (state, getters) => name => {
		return Object.values(getters.formattedErrorsByRowId(name))
			.reduce(<T extends {[key: string]: any}>(acc: T, v:any[]) => {
				const { rowId } = v[0]
				return {
					...acc,
					[rowId]: true
				}
			}, {})
	},

	errorList: (state, getters) => name => {
		return Object.values(getters.formattedErrorsByRowId(name)).map((row:any[]) => {
			const { rowId } = row[0]
			return `Row ${Number(rowId) + 1}, ${row.map(({ message, title }) => message ? message : title).join(", ")} not valid`
		})
	},

	headersForm: (state, getters) => name =>
		getters.validModalItems(name).map(({ title }) => title),

	validHeaders: (state, getters): any => name =>
		getters.validModalItems(name).reduce(
			(validHeaders, { title }) => ({
				...validHeaders,
				[title]: true
			}),
			{}
		),

	formattedByUnitedKey: (state, getters) => name => getters.validModalItems(name)
		.reduce((acc, { csvUnitedKey }) =>
			csvUnitedKey
				? { ...acc, [csvUnitedKey]: csvUnitedKey }
				: acc, {}),

	unionData: (state, getters) => ({ name, isOrigin }) => {
		return getters.formattedFormData({ name, isOrigin }).flat().reduce((acc, cur) => {
			const unionTitle = getters.formattedByUnitedKey(name)[cur.title]
			const { csvValue } = cur

			if(!unionTitle) return acc

			if(acc[unionTitle]) {
				acc[unionTitle].push(csvValue)
			} else {
				acc[unionTitle] = [csvValue]
			}

			return acc
		}, {})

	},

	formattedFormData: (state, getters) => ({ name, isOrigin = false }) => {
		const data = isOrigin ? state.originalCsvData[name] : state.csvData[name]
		return data ? createFormData(data, getters.validModalItems(name)) : []
	},

	formData: (state, getters) => ({ name, isOrigin }) =>
		getters.formattedFormData({ name, isOrigin })
			.map( v => v
				.map(el => {
					if (el.csvUnitedKey) {
						el.unionData = el.csvData.concat(getters.unionData({ name, isOrigin })[el.csvUnitedKey].filter(v => v) || [])
					}
					return el
				})),

	errorsCSV: (state, getters, rootState, rootGetters) => name =>
		rootGetters["csvParser/errorsCSV"](name),

	mandatoryFields: (state, getters) => name => {
		const validModalItems = getters.validModalItems(name) || []
		const requiredFields = validModalItems.filter(({ required }) => required)

		return formattedByTitle(requiredFields)
	},

	validModalItems: state => (name): ModalItemCSV[] => {
		const modalItems = (state.modalItemsCsv[name]) || []

		return modalItems.length ? modalItems.filter(({ csvExclude }) => !csvExclude) : []
	}

};
