
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { FeaturesList } from "@/store/types";
import { get } from 'lodash';
import { FilteredEvent } from "@/store/eventqueue/types";
import EventDetails from "@/components/EventDetails.vue";

const AreaNotes = namespace("areaNotes");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		"event-details": EventDetails
	}
})
export default class EventQueueAreaInfoIcon extends Vue {
	@Prop()
	public event: FilteredEvent;

	@Prop({ type: Number })
	public groupId: Number;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@AreaNotes.Mutation setAreaNoteGroupDetails: any;
	@SiteMonitor.Mutation setEventDetailsShown: any;
	@SiteMonitor.Mutation setAreaDetailsTabIndex: any;

	private isIconActive: boolean = false;

	public get siteTitle(): string {
		return this.event ? this.event.groupTitle : "";
	}

	public get getGroupId(): Number {
		return this.groupId ? this.groupId : null;
	}

	private get isEnabled(): boolean {
		return (get(this.featuresList, ["AreaNotes"]) &&
		get(this.featuresList, ["AreaNotes", "Icons"]));
	}

	private launchModal(): void {
		this.setEventDetailsShown(true);
		this.setAreaDetailsTabIndex(2);
		this.setAreaNoteGroupDetails({ groupId: this.getGroupId, groupTitle: this.siteTitle });
	}
}
