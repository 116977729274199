
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace, Getter, Action } from "vuex-class";
import api from "@/services/api.service";
import { PasswordModel, PasswordChangeResult } from "@/store/my-account/types.ts";
import { FeaturesList } from "@/store/types";
import { get, isEqual } from "lodash";
import { validationMixin } from "vuelidate";
import { maxLength } from "vuelidate/lib/validators";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { passwordValidationMixins } from "@/mixins";
import UserDTO from "@/types/sv-data/users/UserDTO";
import ClearMFA from "./clear-mfa.vue";
import AddMFA from "@/views/MFA.vue";

const { getPasswordStrength } = passwordValidationMixins.methods;

const UserManagement = namespace("userManagement");

@Component({
	mixins: [validationMixin],
	validations: {
		userInformation: {
			email: {
				maxLength: maxLength(150)
			},
			address: {
				maxLength: maxLength(200)
			},
			telephone: {
				maxLength: maxLength(50)
			},
			mobile: {
				maxLength: maxLength(50)
			},
			verifyChange: function verifyChange() {
				return !isEqual(this.userInformation, this.userInformationTemplate);
			}
		}
	},
	components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"clear-mfa": ClearMFA,
		"add-mfa": AddMFA,
	}
})
export default class AccountDetails extends Vue {
	@Getter("getUserId") currentUserId: number;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Action getTimeZones: any;
	@Action logout: () => void;

	private showMfaClearForm: boolean = false;
	private showMfaAddForm: boolean = false;
	private BrowserTimeZone: string = null;
	private showPasswordForm: boolean = false;
	private errorMessage = "";
	
	private passwordRequest: PasswordModel = {
		OldPassword: "",
		NewPassword: "",
		ConfirmPassword: ""
	};

	private result: PasswordChangeResult = {
		errorMessage: null,
		successful: false
	};

	private newPasswordValid: boolean = false;

	public get isEditPersonalEnabled() {
		if (get(this.featuresList, ["AccountDetails", "EditPersonalInformation"]) == null || this.isSuiteUser) {
			return false;
		} else {		
			return get(this.featuresList, ["AccountDetails", "EditPersonalInformation"]);
		}
	}

	private get isSuiteEnabled(): boolean {
		return get(this.featuresList, ["Suite"]);
	}

	private get isSuiteUser(): boolean {
		return this.isSuiteEnabled && this.userInformation.isSuiteUser;
	}

	private async changePassword() {
		this.errorMessage = null;
		this.result = await api.changePassword(this.passwordRequest);

		if (this.result && this.result.successful) {
			this.showPasswordForm = false;
		} else {
			this.errorMessage = this.result.errorMessage;
		}
	}

	@Watch("passwordRequest.NewPassword")
	private onNewPasswordChanged(newValue) {
		if (getPasswordStrength(newValue) === "Weak") {
			this.errorMessage = "A stronger password is required";
			this.newPasswordValid = false;
		} else {
			this.errorMessage = "";
			this.newPasswordValid = true;
		}
	}

	private saveIsEnabled() {
		return (
			this.passwordRequest.OldPassword != null &&
			this.passwordRequest.OldPassword != "" &&
			this.passwordRequest.NewPassword != null &&
			this.passwordRequest.NewPassword != "" &&
			this.passwordRequest.ConfirmPassword != null &&
			this.passwordRequest.ConfirmPassword != "" &&
			this.newPasswordValid
		);
	}

	private closePasswordForm() {
		this.showPasswordForm = !this.showPasswordForm;

		this.passwordRequest = {
			OldPassword: "",
			NewPassword: "",
			ConfirmPassword: ""
		};
	}

	private userInformationTemplate: any = null;

	private userInformation: UserDTO = {
		userID: 0,
		mobileStatusID: null,
		username: "",
		fullName: "",
		email: "",
		address: "",
		telephone: "",
		mobile: "",
		groupID: 0,
		isContact: false,
		permissionGroupIds: [],
		comment: "",
		userGroupMembershipTitles: [],
		timeZoneID: 0,
		registrationStatus: "",
		userMFASet: undefined,
		isSuiteUser: false,
	};

	async mounted() {
		this.userInformation = await api.getUserById(this.currentUserId);
		this.userInformationTemplate = Object.assign({}, this.userInformation);
		this.BrowserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	private async saveUserDetails() {
		const { fullName, telephone, mobile, address } = this.userInformation;
		await api.updatePersonalInfo({ fullName, telephone, mobile, address });
		this.userInformationTemplate = Object.assign({}, this.userInformation);
	}

	private resetUser() {
		this.userInformation = Object.assign({}, this.userInformationTemplate);
	}

	public mfaClearSubmit() {
		this.showMfaClearForm = false;
		this.logout();
	}

	public mfaAddSubmit() {
		this.showMfaAddForm = false;
		this.userInformation.userMFASet = true;
	}

	public showMfaForm() {
		if (this.userInformation.userMFASet) {
			this.showMfaClearForm = true;
		} else {
			this.showMfaAddForm = true;
		} 
	}
}
