
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace, Getter, Action, Mutation } from "vuex-class";

import Sessions from "@/components/Sessions.vue";
import Cameras from "@/components/Cameras.vue";
import LinkingCamera from "@/components/LinkingCamera.vue";
import ManualRaise from "@/components/ManualRaise.vue";
import { FeaturesList } from "@/store/types";
import { SessionResource } from '@/store/sessions/types';
import { EventDetails } from "@/store/site-monitor/types";
import { get } from "lodash";

const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");

@Component({
	components: {
		cameras: Cameras,
		session: Sessions,
		"link-camera": LinkingCamera,
		"manual-raise": ManualRaise,
	}
})
export default class SiteMonitorCameras extends Vue {
	@Getter isLoggedIn: any;
	@Getter getActiveRegion;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Action updateActiveRegion: any;

	@SMCameras.Mutation setMediaMatrixEventId: any; // should be proxied through action
	@SiteMonitor.Mutation private setActivity: () => void;
	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;

	private auditServiceSessionResourceId = SessionResource.AuditServiceSession;
	private deviceServiceSessionResourceId = SessionResource.DeviceServiceSession;

	@Watch("isLoggedIn")
	onIsLoggedInChanged(value: boolean, oldValue: boolean) {
		if (!value) {
			window.close();
		}
	}

	get eventId() {
		try {
			return this.eventDetails == null ? 0 : this.eventDetails.eventID;
		} catch (err) {
			return 0;
		}
	}

	private mounted() {
		this.$el.addEventListener("mousedown", (e: MouseEvent) => { this.setActivity(); })
	}

	public beforeDestroy() {
		this.$el.removeEventListener("mousedown", (e: MouseEvent) => { this.setActivity(); })
	}

	private created() {
		//As this window is in a seperate window we need to retrieve the active region from the shared store
		//and call the update ActiveRegion action which will update the api's base url to point at the correct region
		this.updateActiveRegion(this.getActiveRegion);
		window.zE('webWidget', 'hide');
	}

	public get mediaMatrixEnabled() {
		return get(this.featuresList, ["Alarms", "MediaMatrix"]);
	}

	public get linkedCamerasEnabled() {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "LinkedCameras"]);
	}

	public get isCloseOnEventEndEnabled() {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "CloseOnEnd"]);
	}

	@Watch("eventDetails")
	private onEventDetailsChanged(newValue) {
		if (this.isCloseOnEventEndEnabled) {
			if (!newValue || newValue.eventID === 0) {
				window.close();
			}
		}
	}
}
