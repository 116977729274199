import { PaginatedSearchQueryParams, PaginatedSearchQueryParamsCustomField } from "@/store/types";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { Vue, Component } from "vue-property-decorator";

export interface PaginatedSearchRequest {
	params: PaginatedSearchQueryParams;
	cancelToken: CancelToken;
}

export interface PaginatedSearchRequestCustomField {
	params: PaginatedSearchQueryParamsCustomField;
	cancelToken: CancelToken;
}

@Component({})
export default class PaginatedSearch extends Vue {
	private activePaginatedSearchCancelTokenSource: CancelTokenSource = null;

	private defaultPaginatedSearchParams: PaginatedSearchQueryParams = {
		page: 1,
		pageSize: 25
	};

	public mostRecentSearchParams: PaginatedSearchQueryParams;

	public generateNewPaginatedSearchRequest(params?: PaginatedSearchQueryParams): PaginatedSearchRequest {
		if (!params) {
			params = this.defaultPaginatedSearchParams;
		}

		this.mostRecentSearchParams = params;

		if (this.activePaginatedSearchCancelTokenSource) {
			this.activePaginatedSearchCancelTokenSource.cancel();
		}
		this.activePaginatedSearchCancelTokenSource = axios.CancelToken.source();
		return {
			params,
			cancelToken: this.activePaginatedSearchCancelTokenSource.token
		}
	}
}
