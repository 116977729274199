
	import { Component, Watch, Vue } from "vue-property-decorator";
	import { Getter, Action, namespace } from "vuex-class";
	// UI third-party widgets
	import VuePerfectScrollbar from "vue-perfect-scrollbar";
	import draggable from "vuedraggable"; // @ref https://github.com/SortableJS/Sortable#options
	import vSelect from "vue-select"; // @ref https://sagalbot.github.io/vue-select/
	// models
	import NewTask from "@/models/new-task.model";
	import TaskCategory from "@/models/task-category.model";

	// UI components
	import AddNewTask from "@/components/tasks/AddNewTask.vue";
	import EditActionPlan from "@/components/tasks/EditActionPlan.vue";
	import AddNewActionCategory from "@/components/tasks/AddNewActionCategory.vue";
	import EditLibraryTask from "@/components/tasks/EditLibraryTask.vue";
	import NavHeader from "@/components/NavHeader.vue";
	import TaskTriggerDialog from "@/components/tasks/TaskTriggerDialog.vue";

	import { parseTaskText } from "@/filters";
	import api from "@/services/api.service";
	import { ActionPlanTask, ScriptCategoryLink, CategoryReorderRequest } from "@/store/tasks/types";

	import { orderBy } from "lodash";
	import TaskTypeIds from '@/types/sv-data/enums/TaskTypeIds';

	// @note
	// Entity called "Action" used to be called "Task", and is still
	// Called "Task" in API and UI internals. For display purposes
	// "tasks" are "actions", please use the new terminology
	// whenver doing new work, sorry for the confusion.

	// === We need to start addressing the below, as the whole tasks/actions system is woefully poor === //
	// @techdebt @refactor break up into sub-components
	// @techdebt @techdebt @techdebt - we *really* need to look into the action page as a whole and re-organise it,
	// 1,400 lines is far too long for an individual component at this point
	// @techdebt merge AddNewTask and EditLibraryTask to a single component
	// @techdebt we need more types for the action plan edit page

	const Tasks = namespace("tasks");

	@Component({
		components: {
			draggable,
			"vue-select": vSelect,
			"vue-perfect-scrollbar": VuePerfectScrollbar,
			"add-new-task": AddNewTask,
			"edit-library-task": EditLibraryTask,
			"edit-action-plan": EditActionPlan,
			"add-new-action-category": AddNewActionCategory,
			"task-trigger-dialog": TaskTriggerDialog,
			"nav-header": NavHeader
		},
		filters: {
			parseTaskText
		}
	})
	export default class ConfigureTasks extends Vue {
		$refs: {
			confirmTaskDeletionModal: any;
			"task-library-container": HTMLElement;
		};

		// === Vuex Bindings === //
		@Getter getUserTenantGroupId: number;
		@Getter getPermissions: any;
		@Getter getFeature: (featureList: string[]) => boolean;
		@Action logout: () => void;

		@Tasks.Getter("getTaskLibrary") taskLibrary: any;
		@Tasks.Getter("getTaskTypes") taskTypes: any; // fetched automatically by initApp action
		@Tasks.Getter("getTaskCategories") taskCategories: any;
		@Tasks.Getter("getTaskLibraryByCategoryID") taskLibraryByCategoryID: {
			[categoryId: number]: any[];
		};
		@Tasks.Getter("getActionPlans") actionPlans: any;

		@Tasks.Getter("getEditTriggerAction") triggerAction: any;

		@Tasks.Getter("getScriptCategoryLinks") scriptCategoryLinks: ScriptCategoryLink[];
		@Tasks.Mutation setEditTriggerAction: any;
		@Tasks.Action loadEditTriggerAction: any;

		@Tasks.Action fetchActionPlans: any;
		@Tasks.Action createActionPlan: any;
		@Tasks.Action updateActionPlan: any;
		@Tasks.Action deleteActionPlan: any;

		@Tasks.Action fetchActionLibrary: any;
		@Tasks.Action addTaskToLibrary: any;
		@Tasks.Action updateTask: any;
		@Tasks.Action deleteTaskFromLibrary: any;

		@Tasks.Action fetchTaskTypes: any;
		@Tasks.Action fetchTaskCategories: any;
		@Tasks.Action createTaskCategory: any;
		@Tasks.Action updateTaskCategory: any;
		@Tasks.Action deleteTaskCategory: any;

		@Tasks.Action addActionToPlan: any;
		@Tasks.Action bulkAddActionsToPlan: any;
		@Tasks.Action removeActionFromPlan: any;
		@Tasks.Action updateActionForPlan: any;

		@Tasks.Action updateActionOrderInPlan: (payload: any) => Promise<void>;

		@Tasks.Action getScriptCategoryLinks: () => Promise<void>;
		@Tasks.Action updateCategoryOrderInScript: (payload: CategoryReorderRequest) => Promise<void>;

		// === Properties === //
		public editingActionPlan: boolean = false;
		public actionPlanCollectionTypeahead: any = "";
		public editedActionCategoryID: number | boolean = false;
		public editedActionCategoryTitle = "";
		public isValidEditedActionCategoryTitle = true;
		public showEditedActionCategoryTitleErrorAnimation = false;

		// Adding new Action to Action library
		public addingNewTaskStates: { [taskCategoryId: number]: boolean } = {};
		public editingTaskStates: { [taskId: number]: boolean } = {};
		public reorderTaskStates: { [scriptId: number]: boolean } = {};

		public deleteLibraryActionID = null;
		private confirmTaskDeletionModalDetails: any = {
			taskText: "",
			taskCategoryTitle: "",
			taskData: {}
		};

		public deleteActionCategoryID = null;

		public actionPlanActions: any[] = [];
		public actionsInActionPlansByCategory: any = {};
		public actionCategoriesExpandedState: any = {
			"col-l": {},
			"col-r": {}
		};
		// Adding new Action Category
		public addingNewActionCategory: boolean = false;
		public newActionCategory = { Title: "" };

		public unEditedActionPlan: any = {};
		public deletingActionPlan = false;

		public reorderingItem = false;
		private taskTypeIds = TaskTypeIds;

		public scriptCategoryLinksBeingUpdated: boolean = false;

		// Used to allow the scriptCategoryLinks api call to be made and state to update before reordering is allowed
		private readonly scriptCategoryLinksUpdateTimeout: number = 5000;

		// Keep track of how many timeouts we have, to only set the value if we have no active timeouts
		private activeScriptCategoryLinksUpdateTimeouts: number = 0;

		// Used to set the draggable animation settings for tasks and categories
		private readonly draggableAnimation: number = 200;

		// === Computed Properties === //

		// @refactor add store getter to get task types in the form of
		// typeID => typeTitle as well as existing getter - both are used throught
		public get taskTypesByTitle() {
			let taskTypes: any = {};
			this.taskTypes.forEach(type => {
				taskTypes[type.taskTypeID] = type.title;
			});
			return taskTypes;
		}

		// Provides a representation of action categories by ID for easy access,
		// decorates each category with total number of actions beloning to that category,
		// used when grouping actions by category within action plans (right-hand-column),
		public get decoratedActionCategoriesInCurrentScript() {
			// find all action categories that should be shown for current action plan
			let relevantCategoryIDs = [];
			if (this.actionPlanCollectionTypeahead.scriptID) {
				relevantCategoryIDs = this.taskLibrary
					.filter(t => this.actionPlanCollectionTypeahead.scriptID in t.scripts)
					.map(t => t.taskCategoryID)
					.filter((value, index, self) => self.indexOf(value) === index);
			}

			const _: any = {};
			this.taskCategories.forEach(cat => {
				if (relevantCategoryIDs.includes(cat.taskCategoryID)) {
					_[cat.taskCategoryID] = {
						title: cat.title,
						totalNumActions: this.taskLibrary.filter(t => t.taskCategoryID === cat.taskCategoryID).length,
						numActionsAddedToScript: this.taskLibrary.filter(t => {
							return (
								t.taskCategoryID === cat.taskCategoryID &&
								this.actionPlanCollectionTypeahead.scriptID in t.scripts
							);
						}).length
					};
				}
			});

			return _;
		}

		/**
		 * Returns a method which can be used to check if a given task is the one being edited
		 */
		public get isEditedTask() {
			return task => this.editingTaskStates[task.taskID];
		}

		/**
		 * Sets the options to be used when dragging re-orerable tasks around in the
		 * Action Plan interface.
		 */
		public get actionPlanDragOptions() {
			return {
				animation: this.draggableAnimation,
				disabled: false,
				ghostClass: "action-plan-item-ghost"
			};
		}

		/**
		 * Sets the options to be used when dragging re-orerable categories around in the
		 * Action Plan interface.
		 */
		public get actionPlanCategoryDragOptions() {
			return {
				animation: this.draggableAnimation,
				ghostClass: "action-plan-category-item-ghost"
			};
		}

		// === Watchers === //
		@Watch("actionPlans", { immediate: true, deep: true })
		public onTaskCollectionsChanged(value: any, oldValue: any) {
			this.initActionsInActionPlansByCategoryPlaceholder();
			this.initActionCategoriesExpandedState();
		}

		@Watch("taskLibrary", { immediate: true, deep: true })
		public async onTaskLibraryChanged(value: any, oldValue: any) {
			this.initActionsInActionPlansByCategoryPlaceholder();
			this.initActionCategoriesExpandedState();

			// If the ReOrderCategories feature is enabled, update the ScriptCategoryLinks with every task library change
			if (this.isReorderCategoriesEnabled){
				await this.waitForScriptCategoryLinks();
			}
		}

		@Watch("actionPlanCollectionTypeahead")
		public onActionPlanSelected(value: any, oldValue: any) {
			this.editingActionPlan = false;
		}

		public async waitForScriptCategoryLinks(): Promise<void>{
			await this.getScriptCategoryLinks();

			this.activeScriptCategoryLinksUpdateTimeouts++;

			// Wait for the ScriptCategoryLink state to be ready to allow updating
			setTimeout(()=> {
				this.activeScriptCategoryLinksUpdateTimeouts--;

				// Only update the scriptCategoryLinksBeingUpdated if we have no other active timeouts
				if (this.activeScriptCategoryLinksUpdateTimeouts === 0){
					this.scriptCategoryLinksBeingUpdated = false;
				}
			}, this.scriptCategoryLinksUpdateTimeout);
		}

		// === Methods === //
		public async created() {
			await this.fetchTaskCategories();
			await this.fetchActionPlans();
			await this.fetchActionLibrary();

			if (this.actionPlans.length > 0) {
				this.actionPlanCollectionTypeahead = this.actionPlans[0];
			}

			if (this.isReorderCategoriesEnabled){
				await this.getScriptCategoryLinks();
			}
		}

		public async mounted() {
			await this.fetchTaskTypes();
			this.initActionsInActionPlansByCategoryPlaceholder();
			this.setEditTriggerAction(null);
		}

		public logoutUser() {
			api.logout().then(() => {
				this.logout();
			});
		}

		private initActionsInActionPlansByCategoryPlaceholder() {
			this.actionsInActionPlansByCategory = {};
			// 1st level nesting by action plan ID
			this.actionPlans.forEach(p => {
				// 2nd level nesting by action category id
				this.taskLibrary
					.filter(task => p.scriptID in task.scripts)
					.map(task => task.taskCategoryID)
					.filter((value, index, self) => self.indexOf(value) === index)
					.forEach(categoryID => {
						if (!this.actionsInActionPlansByCategory[p.scriptID]) {
							this.actionsInActionPlansByCategory[p.scriptID] = {};
						}
						this.actionsInActionPlansByCategory[p.scriptID][categoryID] = this.taskLibrary.filter(
							task => p.scriptID in task.scripts && task.taskCategoryID === categoryID
						);
					});
			});

			// hacky, forcing component refresh because draggable task collection area fails to
			// re-render tasks in collection on drag end automatically.
			// console.log('this.actionsInActionPlansByCategory: ', this.actionsInActionPlansByCategory);
			this.$forceUpdate();
		}

		private get shouldCollapseByDefault(): boolean {
			return this.getFeature(["Actions", "CollapseOnSetup"]);
		}

		private get isReorderCategoriesEnabled(): boolean {
			return this.getFeature(["Actions", "ReorderCategories"]);
		}

		private get areScriptCategoryLinksLoaded(): boolean {
			if (this.isReorderCategoriesEnabled === false) {
				return false;
			}

			return this.isReorderCategoriesEnabled && this.scriptCategoryLinks && this.scriptCategoryLinks.length > 0;
		}

		// @todo make it reactive based on this.taskCategories, this.actionPlans and this.taskLibrary
		private initActionCategoriesExpandedState() {
			// left column
			this.taskCategories.forEach(taskCat => {
				// preserve previous state if present
				if (this.actionCategoriesExpandedState["col-l"][taskCat.taskCategoryID] === undefined) {
					// set expanded by default
					Vue.set(this.actionCategoriesExpandedState["col-l"], taskCat.taskCategoryID.toString(), !this.shouldCollapseByDefault);
				}
			});

			// right column: for each script, than for each category in that script
			this.actionPlans.forEach(actionPlan => {
				if (!this.actionCategoriesExpandedState["col-r"][actionPlan.scriptID]) {
					this.actionCategoriesExpandedState["col-r"][actionPlan.scriptID] = {};
				}

				// check if this action plan contains any action categories
				if (this.actionsInActionPlansByCategory[actionPlan.scriptID]) {
					Object.entries(this.actionsInActionPlansByCategory[actionPlan.scriptID]).forEach(
						([categoryID, value]) => {
							// preserve previous state if present
							if (
								!this.actionCategoriesExpandedState["col-r"][actionPlan.scriptID][categoryID] != undefined
							) {
								// set expanded by default
								this.actionCategoriesExpandedState["col-r"][actionPlan.scriptID][categoryID] = true;
							}
						}
					);
				}
			});
		}

		// === Adding/Editing Tasks ===
		public onAddNewTaskToLibrary(taskCategoryID: number) {
			Vue.set(this.addingNewTaskStates, taskCategoryID, true);
		}

		public onSaveNewTask(newTask: NewTask) {
			Vue.set(this.addingNewTaskStates, newTask.taskCategoryID, false);
			this.addTaskToLibrary(newTask);
		}

		public onCancelNewTask(taskCategoryId: number) {
			Vue.set(this.addingNewTaskStates, taskCategoryId, false);
		}

		public onTaskEdit(taskToEdit: NewTask) {
			Vue.set(this.editingTaskStates, taskToEdit.taskID, true);
		}

		public onUpdateLibraryTask(updatedTask: any) {
			Vue.set(this.editingTaskStates, updatedTask.taskID, false);
			this.updateTask(updatedTask);
		}

		public onCancelEditingLibraryTask(taskId: number) {
			Vue.set(this.editingTaskStates, taskId, false);
		}

		// === Deleting Actions === //
		public onDeleteActionFromLibrary(task: any, taskCategoryTitle: string) {
			this.confirmTaskDeletionModalDetails = {
				...task,
				taskCategoryTitle
			};

			this.deleteLibraryActionID = task.taskID;
			this.$refs.confirmTaskDeletionModal.show();
		}

		public cancelDeletingActionFromLibrary() {
			this.deleteLibraryActionID = null;
		}

		public doDeleteActionFromLibrary() {
			if (this.isReorderCategoriesEnabled === true){
				this.scriptCategoryLinksBeingUpdated = true;
			}

			this.deleteTaskFromLibrary(this.deleteLibraryActionID);
			this.deleteLibraryActionID = null;
		}

		// === Adding/Editing Action Plans === //
		public onAddNewActionPlan() {
			this.actionPlanCollectionTypeahead = {
				isNew: true,
				edit: true
			};
		}

		public async onSaveActionPlan() {
			if(this.actionPlanCollectionTypeahead) {
				this.actionPlanCollectionTypeahead.groupID = this.getUserTenantGroupId
			}
			if (this.actionPlanCollectionTypeahead.isNew) {
				let actionPlanID = await this.createActionPlan(this.actionPlanCollectionTypeahead);

				this.actionPlanCollectionTypeahead.scriptID = actionPlanID;
				this.actionPlanCollectionTypeahead.isNew = false;
			} else {
				this.updateActionPlan(this.actionPlanCollectionTypeahead);
			}
			this.actionPlanCollectionTypeahead.edit = false;
		}

		public onEditActionPlan() {
			this.unEditedActionPlan = {
				...this.actionPlanCollectionTypeahead
			};

			Vue.set(this.actionPlanCollectionTypeahead, "edit", true);
		}

		public onCancelEditingActionPlan() {
			if (this.actionPlanCollectionTypeahead) {
				if (this.actionPlanCollectionTypeahead.isNew) {
					this.actionPlanCollectionTypeahead = null;
				} else {
					this.actionPlanCollectionTypeahead = this.unEditedActionPlan;
				}
			}
		}

		// === Deleting Action Plans === //
		public async doDeleteActionPlan() {
			await this.deleteActionPlan(this.actionPlanCollectionTypeahead.scriptID);

			this.deletingActionPlan = false;
			if (this.actionPlans.length > 1) {
				this.actionPlanCollectionTypeahead = this.actionPlans[1];
			} else {
				this.actionPlanCollectionTypeahead = null;
			}
		}

		// === Adding/Editing Action Categories === //
		public onAddNewActionCategory() {
			this.addingNewActionCategory = true;
		}

		public onCancelAddingNewActionCategory() {
			this.addingNewActionCategory = false;
		}

		public async onSaveActionCategory() {
			this.addingNewActionCategory = false;
			await this.createTaskCategory(this.newActionCategory);
			this.newActionCategory = { Title: "" };

			this.initActionCategoriesExpandedState();
		}

		public onActionCategoryEdit(taskCategory: TaskCategory) {
			this.editedActionCategoryID = taskCategory["taskCategoryID"];
			this.editedActionCategoryTitle = taskCategory["title"];
		}

		public onCancelEditingActionCategory() {
			this.editedActionCategoryID = false;
			this.editedActionCategoryTitle = "";
		}

		public onUpdateActionCategory(taskCategory) {
			// validation
			if (this.editedActionCategoryTitle.length === 0) {
				this.isValidEditedActionCategoryTitle = false;
				this.showEditedActionCategoryTitleErrorAnimation = true;
				setTimeout(() => {
					this.showEditedActionCategoryTitleErrorAnimation = false;
				}, this.$config.ANIMATION_DURATION);
				return;
			} else {
				this.isValidEditedActionCategoryTitle = true;
			}

			const payload = {
				...taskCategory,
				title: this.editedActionCategoryTitle
			};
			this.updateTaskCategory(payload);
			this.editedActionCategoryID = false;
			this.editedActionCategoryTitle = "";
		}

		// === Deleting Action Categories === //
		public onDeleteActionCategory(category: any) {
			this.deleteActionCategoryID = category.taskCategoryID;
		}

		public cancelDeletingActionCategory() {
			this.deleteActionCategoryID = null;
		}

		public doDeleteActionCategory() {
			if (this.isReorderCategoriesEnabled === true){
				this.scriptCategoryLinksBeingUpdated = true;
			}

			this.deleteTaskCategory(this.deleteActionCategoryID);
			this.deleteActionCategoryID = null;
		}

		public onRemoveTaskFromCollection(task: any) {
			if (this.isReorderCategoriesEnabled === true){
				this.scriptCategoryLinksBeingUpdated = true;
			}

			const payload = {
				ScriptID: this.actionPlanCollectionTypeahead.scriptID,
				TaskID: task.taskID,
				Required: true // @tbc what's this?
			};
			this.removeActionFromPlan(payload);
		}

		/**
		 * Toggles a task's required status.
		 */
		public toggleRequiredTask(evt: any, action: ActionPlanTask, scriptId: number) {
			const payload = {
				scriptID: scriptId,
				taskID: action.taskID,
				order: action.scripts[scriptId].order,
				required: !action.scripts[scriptId].required
			};

			this.updateActionForPlan(payload);
		}

		// === Expanding/Collapsing Task Categories === //
		public collapseCategory(actionCategoryID, col: string) {
			if (col === "col-l") {
				this.actionCategoriesExpandedState[col][actionCategoryID] = false;
			}
			if (col === "col-r") {
				this.actionCategoriesExpandedState[col][this.actionPlanCollectionTypeahead.scriptID][
					actionCategoryID
				] = false;
			}
			// @ref https://stackoverflow.com/questions/32106155/can-you-force-vue-js-to-reload-re-render
			this.$forceUpdate();
		}

		public expandCategory(actionCategoryID, col: string) {
			if (col === "col-l") {
				this.actionCategoriesExpandedState[col][actionCategoryID] = true;
			} else if (col === "col-r") {
				this.actionCategoriesExpandedState[col][this.actionPlanCollectionTypeahead.scriptID][
					actionCategoryID
				] = true;
			}

			// @ref https://stackoverflow.com/questions/32106155/can-you-force-vue-js-to-reload-re-render
			this.$forceUpdate();
		}

		// === Trigger Action Handlers === //
		public async setTriggerAction(action: any) {
			this.loadEditTriggerAction(action);
		}

		// @techdebt - this method is unused, but looks like something that was forgotten about?
		public clearTriggerAction() {
			this.setEditTriggerAction(null);
		}

		// === Action Plan Editing Helpers === //
		public inActionPlan(action: any) {
			if (this.triggerAction) {
				return this.triggerAction.trigger.some(trigger => trigger.triggerTaskID == action.taskID);
			} else {
				return action.scripts[this.actionPlanCollectionTypeahead.scriptID] != null;
			}
		}

		public categoryInActionPlan(taskCategory: any) {
			if (!this.taskLibraryByCategoryID[taskCategory.taskCategoryID]) return false;

			if (this.triggerAction) {
				return this.taskLibraryByCategoryID[taskCategory.taskCategoryID].every(action => {
					return (
						this.triggerAction.taskID == action.taskID ||
						this.triggerAction.trigger.some(trigger => trigger.triggerTaskID == action.taskID)
					);
				});
			} else {
				return this.taskLibraryByCategoryID[taskCategory.taskCategoryID].every(action => {
					return action.scripts[this.actionPlanCollectionTypeahead.scriptID] != null;
				});
			}
		}

		public async toggleInActionPlan(action: any): Promise<void> {
			if (this.isReorderCategoriesEnabled === true){
				this.scriptCategoryLinksBeingUpdated = true;
			}

			if (this.triggerAction && action.taskID != this.triggerAction.taskID) {
				if (this.inActionPlan(action)) {
					let triggerIndex = this.triggerAction.trigger.findIndex(
						trigger => trigger.triggerTaskID == action.taskID
					);
					if (triggerIndex > -1) this.triggerAction.trigger.splice(triggerIndex, 1);
				} else {
					this.triggerAction.trigger.push({
						taskID: this.triggerAction.taskID,
						trigger: action,
						resultCondition: null,
						required: false,
						triggerTaskID: action.taskID
					});
				}
			} else if (this.actionPlanCollectionTypeahead) {
				if (this.inActionPlan(action)) {
					//onRemoveTaskFromCollection(action)
					let planCategory = this.actionsInActionPlansByCategory[this.actionPlanCollectionTypeahead.scriptID][
						action.taskCategoryID
					];
					let removePlanActions = planCategory.filter(planAction => planAction.taskID == action.taskID);

					removePlanActions.forEach(removeAction => this.onRemoveTaskFromCollection(removeAction));
				} else {
					const payload = {
						ScriptID: this.actionPlanCollectionTypeahead.scriptID,
						TaskID: action.taskID,
						Required: true
					};
					await this.addActionToPlan(payload);
				}
			}
		}

		public async toggleCategoryInActionPlan(taskCategory): Promise<void> {
			if (this.isReorderCategoriesEnabled === true){
				this.scriptCategoryLinksBeingUpdated = true;
			}

			if (!this.taskLibraryByCategoryID[taskCategory.taskCategoryID]) return;

			if (this.triggerAction) {
				if (this.categoryInActionPlan(taskCategory)) {
					this.taskLibraryByCategoryID[taskCategory.taskCategoryID].forEach(action => {
						let triggerIndex = this.triggerAction.trigger.findIndex(
							trigger => trigger.triggerTaskID == action.taskID
						);
						if (triggerIndex > -1) this.triggerAction.trigger.splice(triggerIndex, 1);
					});
				} else {
					this.taskLibraryByCategoryID[taskCategory.taskCategoryID].forEach(action => {
						if (!this.inActionPlan(action) && action.taskID != this.triggerAction.taskID) {
							this.triggerAction.trigger.push({
								taskID: this.triggerAction.taskID,
								trigger: action,
								resultCondition: null,
								required: false,
								triggerTaskID: action.taskID
							});
						}
					});
				}
			} else if (this.actionPlanCollectionTypeahead) {
				if (this.categoryInActionPlan(taskCategory)) {
					this.taskLibraryByCategoryID[taskCategory.taskCategoryID].forEach(action => {
						let planCategory = this.actionsInActionPlansByCategory[this.actionPlanCollectionTypeahead.scriptID][
							action.taskCategoryID
						];
						let removePlanActions = planCategory.filter(planAction => planAction.taskID == action.taskID);

						removePlanActions.forEach(removeAction => this.onRemoveTaskFromCollection(removeAction));
					});
				} else {
					for (const action of this.taskLibraryByCategoryID[taskCategory.taskCategoryID]){
						const payload = {
							ScriptID: this.actionPlanCollectionTypeahead.scriptID,
							TaskID: action.taskID,
							Required: true
						};
						this.addActionToPlan(payload);
					}
				}
			}
		}

		// === Task Library Re-Ordering Handlers === //

		/**
		 * Returns the tasks for a given action plan (script) and category,
		 * ordered by their order in the ScriptTasks table.
		 */
		public orderedActionPlanByCategory(scriptId: number, categoryId: number) {
			let unorderedActions = this.actionsInActionPlansByCategory[scriptId][categoryId] as ActionPlanTask[];

			let orderedActions = orderBy(unorderedActions, action => action.scripts[scriptId].order, ["asc"]);

			return orderedActions;
		}

		/**
		 * Handler for when the action plan ordering is changed.
		 * Computes the new order for the given script ID and pushes that to the server.
		 */
		public async onActionPlanOrderChanged(
			reorderEvent: {
				moved: {
					element: ActionPlanTask;
					newIndex: number;
					oldIndex: number;
				};
			},
			scriptId: number,
			taskCategoryId: string
		) {
			// Build a payload from the information we've been passed by Vue-Draggable
			var reOrderPayload = {
				scriptId,
				taskCategoryId: parseInt(taskCategoryId),
				taskId: reorderEvent.moved.element.taskID,
				newOrder: reorderEvent.moved.newIndex + 1,
				previousOrder: reorderEvent.moved.oldIndex + 1
			};

			try {
				await this.updateActionOrderInPlan(reOrderPayload);
			} catch (err) {
				this.$notify({
					type: "error",
					title: "Action Setup",
					text:
						"Unable to update the order of this task - please try again later, or contact support if the problem persists."
				});
			}
		}

		/**
		 * Helper method to accomodate changes to list elements when an item is removed.
		 * Due to the heavily nested markup, when an element leaves the action plan list it needs
		 * it's position to be set to absolute. The below code sets the elements position to what it
		 * was prior to it leaving the list, so we can achieve a smooth exit animation instead of having
		 * the element jump to the top-left of the parent container (as pos: aboslute does).
		 */
		public beforeActionPlanListItemLeave(el: HTMLElement) {
			const { marginLeft, marginTop, width, height } = window.getComputedStyle(el);
			el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`;
			el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`;
			el.style.width = width;
			el.style.height = height;
		}

		public getScriptCategoryLinkById(scriptId: number, taskCategoryId: number): ScriptCategoryLink {
			const scriptCategoryLinks: ScriptCategoryLink[] = this.scriptCategoryLinks;

			let scriptCategoryLink = null;

			if (scriptCategoryLinks){
				scriptCategoryLink = scriptCategoryLinks.find(x=>x.scriptId === scriptId && x.taskCategoryId === taskCategoryId);
			}

			return scriptCategoryLink;
		}

		public actionPlanCategories(scriptId: number): ScriptCategoryLink[]{
			let actionPlanTasksInScript = this.actionsInActionPlansByCategory[scriptId];

			let unorderedActionPlanTasks = orderBy(actionPlanTasksInScript, category=>category.taskCategoryID, ["asc"])

			let unorderedCategories : ActionPlanTask[] = [].concat.apply([], unorderedActionPlanTasks);

			let orderedCategories : ScriptCategoryLink[] = [];

			if (!this.isReorderCategoriesEnabled) {
				let unorderedCategoryLinks : ScriptCategoryLink[] = [];

				unorderedCategories.forEach((actionPlanTask :ActionPlanTask) => {
					if (!unorderedCategoryLinks.some(x=> x.taskCategoryId === actionPlanTask.taskCategoryID)){
						let unorderedCategoryToAdd: ScriptCategoryLink = {
							scriptId: scriptId,
							taskCategoryId: actionPlanTask.taskCategoryID,
							order: null
						};

						unorderedCategoryLinks.push(unorderedCategoryToAdd)
					}
				})

				return unorderedCategoryLinks;
			}
			else {
				unorderedCategories.forEach((actionPlanTask :ActionPlanTask) => {
					// Default ScriptCategoryLinkOrder to use if the ScriptCategoryLink cannot be obtained
					const scriptCategoryLinkOrderDefault = 100;

					let scriptCategoryLink = this.getScriptCategoryLinkById(scriptId, actionPlanTask.taskCategoryID);
					let scriptCategoryLinkOrder: number = scriptCategoryLinkOrderDefault;

					if (scriptCategoryLink != null){
						scriptCategoryLinkOrder = scriptCategoryLink.order
					}

					actionPlanTask.scriptCategoryLink = scriptCategoryLink;

					if (!orderedCategories.some(x=> x.taskCategoryId === actionPlanTask.taskCategoryID)){
						let orderedCategoryToAdd: ScriptCategoryLink = {
							scriptId: scriptId,
							taskCategoryId: actionPlanTask.taskCategoryID,
							order: scriptCategoryLinkOrder
						};

						orderedCategories.push(orderedCategoryToAdd)
					}
				})
			}

			return orderBy(orderedCategories, category=>category.order, ["asc"]);
		}

		public async onActionPlanCategoryOrderChanged(
			reorderEvent: {
                moved: {
                    element: any,
                    newIndex: number;
                    oldIndex: number;
                };
            },
            scriptId: number,
        ): Promise<void> {
			// If the ScriptCategoryLinks are being updated, don't send request
			if (this.scriptCategoryLinksBeingUpdated === true){
				return;
			}

            // Build a payload from the information we've been passed by Vue-Draggable
            var reOrderPayload: CategoryReorderRequest = {
                scriptId,
                taskCategoryId: reorderEvent.moved.element.taskCategoryId,
                newOrder: reorderEvent.moved.newIndex + 1,
                previousOrder: reorderEvent.moved.oldIndex + 1
            };

			try {
				this.scriptCategoryLinksBeingUpdated = true;

				// Get the script category links to make sure they are updated before making the update request
				await this.getScriptCategoryLinks();

                await this.updateCategoryOrderInScript(reOrderPayload);
            } catch (err) {
                this.$notify({
                    type: "error",
                    title: "Action Setup",
                    text:
                        "Unable to update the order of this category - please try again later, or contact support if the problem persists."
                });
            }
			this.scriptCategoryLinksBeingUpdated = false;
        }
	}
