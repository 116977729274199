import { MutationTree } from "vuex";
import { UserManagementState, UserRole, Permission, UserGroup } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<UserManagementState> = {
	setPermissions: (state, permissions: Permission[]) => (state.Permissions = permissions),
	setUserRoles: (state, userRoles: UserRole[]) => (state.UserRoles = userRoles),
	setUserGroups: (state, userGroups: UserGroup[]) => state.userGroups = userGroups,
	setUserGroupDictionary: (state, userGroupDictionary) => state.userGroupDictionary = userGroupDictionary,
	setUserRoleDictionary: (state, userRoleDictionary) => state.userRoleDictionary = userRoleDictionary,
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
