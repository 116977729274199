import { Module } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "../types";
import { AlarmPointState } from "./types";

export function getDefaultState(): AlarmPointState {
	return {
		displayResponseId: null,
		responses: [],
		alarmPointManagerOpen: false,
		cancelTokenSource: null,
		selectedResponse: null,
		selectedArea: null,
		disableAutomaticLoad: false,
	};
}

export const state: AlarmPointState = getDefaultState();

const namespaced: boolean = true;

export const alarmPoints: Module<AlarmPointState, RootState> = {
	namespaced,
	state,
	mutations,
	actions
};