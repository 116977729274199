
import { Component, Vue, Prop, Watch, Emit, Mixins } from "vue-property-decorator";
import { namespace, Getter, Action } from "vuex-class";

import { validationMixin } from "vuelidate";
import { required, requiredIf, minValue, maxValue, numeric, minLength } from "vuelidate/lib/validators";

import moment from "moment";
import DatePicker from "@sureview/vuejs-datepicker";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import { EventRecord, MaskReason, EventDetails } from "@/store/site-monitor/types";

import api from "@/services/api.service";

import AccessControlHistory from "@/components/access-control/History.vue";
import AlarmPointHistory from "./AlarmPointHistory.vue";
import MaskingForm from "@/components/alarm-masking/MaskingForm.vue";
import PutOnTest from "./PutOnTest.vue";
import { UserPermissions, FeaturesList } from "@/store/types";
import { get } from "lodash";
import maskingApi from '@/services/api.masking.service';

const AlarmPoints = namespace("alarmPoints");
const SiteMonitor = namespace("siteMonitor");

class ResponseOnTestRequest {
	responseID: number;
	hours: number | null;
	minutes: number | null;
	date: string;
	onTestUntilDate: Date;
	time: string;
	scheduleType: number;
	auditMode: boolean;
	onTestNote: string;
}

@Component({
	mixins: [validationMixin],
	validations: {
		onTestRequest: {
			hours: {
				numeric,
				required: requiredIf(function() {
					return this.onTestRequest.scheduleType == this.ScheduleType.ForPeriod;
				}),
				minValue: minValue(0),
				validateHourPermission: function(hours) {
					return hours <= this.getMaskMaxHours;
				},
				validateMaxTime: function(hours) {
					return this.getMaskMaxHours == hours && this.onTestRequest.minutes > 0 ? false : true;
				}
			},
			minutes: {
				numeric,
				required: requiredIf(function() {
					return this.onTestRequest.scheduleType == this.ScheduleType.ForPeriod;
				}),
				minValue: minValue(0),
				maxValue: maxValue(59)
			},
			onTestUntilDate: {
				required: requiredIf(function() {
					return this.onTestRequest.scheduleType == this.ScheduleType.UntilDate;
				}),
				minValue: minValue(new Date().setHours(0, 0, 0, 0))
			},
			time: {
				pattern: function(value) {
					if (typeof value == "undefined" || value == null || value == "") return true;
					return /^[0-9]{2}:[0-9]{2}$/.test(value);
				},
				minTime: function(value) {
					/**
					 * Validator to ensure that the time entered by the user is in the future
					 */
					if (
						typeof value == "undefined" ||
						value == null ||
						value == "" ||
						typeof this.onTestRequest.onTestUntilDate == "undefined" ||
						this.onTestRequest.onTestUntilDate == null ||
						this.onTestRequest.scheduleType !== this.ScheduleType.UntilDate
					)
						return true;

					const now = new Date().setHours(0, 0, 0, 0);
					const currentRequestDate = new Date(this.onTestRequest.onTestUntilDate).setHours(0, 0, 0, 0);

					if (now == currentRequestDate) {
						// If the user has chosen today as the re-arm date, we need to make sure that the time they've
						// entered is greater than the current time.
						var momentTime = moment(value, "HH:mm", true).toDate();
						return momentTime > new Date();
					} else {
						// If the user hasn't chosen today, just make sure that the date they've entered is in the future.
						return currentRequestDate > now;
					}
				},
				required: requiredIf(function() {
					return this.onTestRequest.scheduleType == this.ScheduleType.UntilDate;
				})
			},
			onTestNote: {
				required,
				minLength: minLength(1)
			}
		}
	},
	components: {
		VuePerfectScrollbar,
		"access-history": AccessControlHistory,
		"masking-form": MaskingForm,
		"alarm-point-history": AlarmPointHistory,
		"date-picker": DatePicker,
		"put-on-test": PutOnTest
	},
	filters: {
		displayDate: function(value: Date) {
			return moment(value).format("LL LT");
		}
	}
})
export default class AlarmPoint extends Vue {
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getPermissions") permissions: UserPermissions;

	@AlarmPoints.State("selectedResponse") response;
	@AlarmPoints.State("disableAutomaticLoad") disableAutomaticLoad;
	@AlarmPoints.Action fetchDisableAutomaticLoad: () => Promise<any>;
	@AlarmPoints.State("displayResponseId") displayResponseId;
	@AlarmPoints.Mutation setDisplayResponseId: any;
	@AlarmPoints.Mutation setSelectedResponse: any;

	@SiteMonitor.Getter getEventDetails: EventDetails;
	@SiteMonitor.State("MaskReasons") maskReasons!: MaskReason[];
	@SiteMonitor.Getter getGroupMaskReasons: any;
	@SiteMonitor.Action loadMaskReasonsForAlarm: any;
	@SiteMonitor.Action loadMaskReasonsForGroup: any;

	@Getter getMaskMaxHours: number;

	public responseDetails: any = null;
	public responseOnTestEvent: any = null;

	public testAlarmsLoaded: boolean = false;
	public testAlarms: any[] = [];
	public maxEventRecordID: number = 0;

	public showOnTest: boolean = false;
	public showMask: boolean = false;
	public showHistory: boolean = false;

	public async mounted() {
		if (this.response) {
			this.loadResponse();
		}
		await this.fetchDisableAutomaticLoad();
	}

	@Watch("response")
	public onResponseChanged(value: any) {
		if (value) this.loadResponse();
	}

	public async loadResponse() {
		if (this.response) {
			await this.loadMaskReasonsForAlarm(this.response.groupID);
			await this.loadMaskReasonsForGroup(this.response.groupID);

			this.responseDetails = await api.responseGet(this.response.responseID);
			this.responseOnTestEvent = await api.loadResponseOnTestEvent(this.response.responseID);
		} else this.responseOnTestEvent = null;
	}

	// == Consts == //
	public Today = new Date();

	public calendarDisabledDates = { to: new Date(this.Today.setDate(this.Today.getDate() - 1)) };
	/**
	 * May not be entirely required - this is just used to set the min property for the HTML5 time input,
	 * but we don't actually use HTML5 validation.
	 */
	public get minTimeForSelectedDate() {
		const today = new Date().setHours(0, 0, 0, 0);
		const defaultMinTime = "00:00";

		if (typeof this.onTestRequest.onTestUntilDate == "undefined" || this.onTestRequest.onTestUntilDate == null)
			return defaultMinTime;
		var selectedDate = this.onTestRequest.onTestUntilDate.setHours(0, 0, 0, 0);

		if (selectedDate == today) {
			return moment().format("HH:mm");
		} else {
			return defaultMinTime;
		}
	}

	public get maskingEnabled() {
		return this.getFeature(["Alarms", "Masking"]) && (!!this.permissions.canDisarmSites || !!this.permissions.canDisarmSitesExtended);
	}

	public get onTestEnabled() {
		return this.getFeature(["Alarms", "AlarmPoints", "OnTest"]) && !!this.permissions.canPutAlarmPointsOnTest;
	}

	public calendarDateFormatter(date: string) {
		return moment(date).format("L");
	}

	public ScheduleType = {
		ForPeriod: 1,
		UntilDate: 2
	};

	public onTestRequest = {
		responseID: null,
		hours: null,
		minutes: null,
		onTestUntilDate: new Date(),
		date: null,
		time: moment()
			.add({ hours: 1 })
			.format("HH:mm"),
		scheduleType: this.ScheduleType.ForPeriod,
		onTestNote: ""
	} as ResponseOnTestRequest;

	@Emit("onMaskedAlarm")
	public onControlMaskedAlarm(result) {
		this.showMask = false;

		this.response.armed = false;
		this.response.toggleArmAt = result.result.rearmAt
			? result.result.rearmAt.substring(0, result.result.rearmAt.length - 1)
			: result.result.rearmAt; //Remove trailing Z

		api.responseGet(this.response.responseID).then(details => {
			this.responseDetails = details;
		});

		return result;
	}

	@Emit("onUnmaskedAlarm")
	public onControlUnmaskedAlarm(result) {
		this.showMask = false;
		this.response.armed = true;

		api.responseGet(this.response.responseID).then(details => {
			this.responseDetails = details;
		});

		return result;
	}

	public get responseMaskedReason() {
		if (this.responseDetails.disarmReasonID && this.maskReasons) {
			let disarmReasons = this.maskReasons.filter(
				reason => reason.disarmReasonID == this.responseDetails.disarmReasonID
			);
			if (disarmReasons.length > 0) return disarmReasons[0].title;
			else return "Unknown reason";
		}

		return "";
	}
	
	public get groupMaskedReason() {
		if (this.responseDetails.groupDisarmReasonID && this.getGroupMaskReasons) {
			let disarmReasons = this.getGroupMaskReasons.filter(
				reason => reason.disarmReasonID == this.responseDetails.groupDisarmReasonID
			);
			if (disarmReasons.length > 0) return disarmReasons[0].title;
			else return "Unknown reason";
		}

		return "";
	}

	public async startTest() {
		this.$v.$reset();

		this.$nextTick(async () => {
			// force re-validation
			this.$v.$touch();

			// if there are validation errors, stop the submission
			if (this.$v.$error) {
				return;
			}

			this.onTestRequest.responseID = this.response.responseID;

			var testRequest = this.onTestRequest;
			if (this.onTestRequest.scheduleType == this.ScheduleType.UntilDate) {
				// Get the hours and minutes out of the time value
				var momentTime = moment(this.onTestRequest.time, "HH:mm", true);
				var hours = momentTime.hours();
				var minutes = momentTime.minutes();

				// Calculate the datetime when this alarm is to be un-masked, and convert it to UTC
				var formattedDate = moment(this.onTestRequest.onTestUntilDate)
					.set({ hours, minutes, seconds: 0 })
					.utc()
					.format();

				// Update the date value within our mask request, set hours and mins to 0
				// (nulls don't work - unsure why, probably because we're posting the form and not a JSON object)
				testRequest = { ...this.onTestRequest, date: formattedDate, hours: 0, minutes: 0 };

				delete testRequest.hours;
				delete testRequest.minutes;
			} else {
				delete testRequest.date;
			}

			this.responseOnTestEvent = await api.startTest(testRequest);
		});
	}

	public async endTest() {
		if (this.responseOnTestEvent) {
			await api.endTest(this.responseOnTestEvent.eventID);
			this.responseOnTestEvent = null;

			this.testAlarms = [];
			this.testAlarmsLoaded = false;
		}
	}

	public async loadOnTestRecords() {
		if (this.responseOnTestEvent) {
			var onTestRecords = await api.loadGroupOnTestRecords(this.responseOnTestEvent.eventID, 0);

			this.maxEventRecordID = onTestRecords.maxEventRecordID;
			this.testAlarms = onTestRecords.records;
			this.testAlarmsLoaded = true;
		}
	}

	public async unMaskAlarm() {
		await maskingApi.unmaskAlarm(this.response.eventID, this.response.responseID, this.response.groupID);
		this.onControlUnmaskedAlarm({ response: this.response });

		this.response.armed = true;
		this.responseDetails.armed = true;
		this.responseDetails.toggleArmAt = null;
		this.responseDetails.disarmReasonID = null;
	}

	public async unMaskArea() {
		await maskingApi.unmaskArea(this.responseDetails.groupID);

		this.response.groupArmed = true;
		this.responseDetails.groupArmed = true;
		this.responseDetails.groupToggleArmAt = null;
		this.responseDetails.groupDisarmReasonID = null;
	}

	private async onAlarmMasked(): Promise<void> {
		var updatedResponse = await api.responseGet(this.response.responseID);
		this.setSelectedResponse(updatedResponse);
	}
}
