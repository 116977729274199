
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import { namespace } from "vuex-class";
    import TextHighlight from "vue-text-highlight";
    import VuePerfectScrollbar from "vue-perfect-scrollbar";
    import { debounce } from "lodash";
    import api from "@/services/api.service";
    import AreaTreeSelect from './AreaTreeSelect.vue';
    import { CameraType, SearchedCamera } from '@/store/site-monitor-cameras/types';

    const Areas = namespace("areas");
    const SMCameras = namespace("siteMonitorCameras");
    
    const PAGE_SIZE: number = 20; 
	const DEBOUNCE_WAIT_TIME: number = 1000;

	@Component({
		components: {
            VuePerfectScrollbar: VuePerfectScrollbar,
            "text-highlight": TextHighlight,
            "area-tree-select": AreaTreeSelect,
		}
	})
	export default class CameraSelect extends Vue {
		$refs!: {
			areaTreeScrollBar: any;
			toggleBox: HTMLDivElement;
			searchBox: HTMLInputElement;
		};

        @Areas.Getter getAreaTitle: (id: number) => string;

        @SMCameras.Action fetchAreaCameras: (params: { groupID: number, pageNumber: number, paginated: boolean }) => Promise<void>;
        @SMCameras.Action searchForCameras: (params: { query: string, groupID: number, page: number, reset: boolean }) => Promise<void>;

        @SMCameras.Getter("getAreaCameras") groupCameras: CameraType[];
        @SMCameras.Getter searchedCameras: SearchedCamera[];

        @SMCameras.Mutation setAreaCameras: any;
	    @SMCameras.Mutation setAreaCameraCount: any;
	    @SMCameras.Mutation setSearchedGroupCameraCount: any;

        @SMCameras.State("areaCameraCount") public areaCameraCount: number;
	    @SMCameras.State("searchedGroupCameraCount") public searchedGroupCameraCount: number;

        @Prop({ type: Boolean, default: false }) 
		public multiple: boolean;

        @Prop()
        public value: any;

		public pageNumber: number = 1;
		public selectedGroup: number = null;
        public searchPageSize: number = PAGE_SIZE;

        private isInputFocused: boolean = false;
        private selectedCameras: any[] = [];
        private searchQuery: string = "";
        private isLoading: boolean = false;

        private menuStyle: any = {
            top: "0px",
            left: "0px",
            width: "auto"
        }

        private async mounted(): Promise<void> {
            if (this.value) {
                await this.updateFromValue();
            }

            this.setAreaCameras([]);
			this.setAreaCameraCount(0);
			this.setSearchedGroupCameraCount(0);
			this.selectedGroup = null;
			this.searchQuery = null;
        }

        @Watch("value")
        private async updateFromValue(): Promise<void> {
            if (!this.selectedCameras) {
                this.selectedCameras = [];
            }

            if (this.value) {
                if (this.multiple) {
                    // Value set, and we are using an array of cameras

                    // Remove any from selected cameras list that don't appear in the model
                    this.selectedCameras = this.selectedCameras.filter(camera => !this.value.some(cameraDeviceID => cameraDeviceID == camera.deviceID));

                    // Find an add any cameras that are in the model but not the selected cameras list
                    // noinspection ES6MissingAwait
                    this.value.forEach(async cameraDevice => {
                        if (this.selectedCameras.some(camera => camera.deviceID == cameraDevice.deviceID)) {
                            return;
                        }

                        let camera =  await api.loadDeviceById(cameraDevice.deviceID, null);
                        if (camera) {
                            this.selectedCameras.push(camera);
                        }
                    });
                } else {
                    // Already current value - do nothing
                    if (this.selectedCameras.some(camera => camera.deviceID == this.value.deviceID)) {
                        return;
                    }

                    // Set selected groups list to new value
                    this.selectedCameras = [];
                    let cameraDevice = await api.loadDeviceById(this.value.deviceID, null);
                    if (cameraDevice) {
                        this.selectedCameras.push(cameraDevice);
                    }
                }
            } else {
                // Value is null, so reset list
                this.selectedCameras = [];
            }
        }

        private selectCamera(camera): void {
            if (!camera) {
                return;
            }

            camera.deviceID = camera.objectID;

            if (!this.multiple && this.selectedCameras.length > 0) {
                this.selectedCameras = [];
            } else {
                if (this.selectedCameras.some(alreadySelected => alreadySelected.deviceID === camera.deviceID)) {
                    return;
                }
            }

            this.selectedCameras.push(camera);
            this.searchQuery = "";

            if (this.multiple) {
                this.$emit("input", this.selectedCameras);
            } else {
                this.$emit("input", camera);
                this.$refs.searchBox.blur();
            }

            this.onBlur();
        }

        private deselectCamera(camera): void {
            const cameraIndex = this.selectedCameras.findIndex(alreadySelected => alreadySelected.deviceID === camera.deviceID);
            if (cameraIndex > -1) {
                this.selectedCameras.splice(cameraIndex, 1);
            }

            if (this.multiple) {
                this.$emit("input", this.selectedCameras);
            }
        }

        private async displayDropdown(): Promise<void> {
            const left = (this.$refs.toggleBox.offsetParent as HTMLElement).offsetLeft +
                this.$refs.toggleBox.offsetLeft;

            const top = this.$refs.toggleBox.getBoundingClientRect().top;
            this.menuStyle.top = top + "px";
            this.menuStyle.left = left + "px";
            this.menuStyle.width = (this.$refs.toggleBox.offsetWidth + 2) + "px";

            const maxHeight = document.documentElement.clientHeight - top - 40;
            this.menuStyle.height = Math.min(250, maxHeight) + "px";

            this.isInputFocused = true;
        }

        private onBlur(): void {
            setTimeout(() => {
                this.isInputFocused = false;
                this.searchQuery = "";
            } , 200);
        }

        private setSearchFocus (): void {
            if (!this.isInputFocused) {
                this.$refs.searchBox.focus();
            }
        }

        public get areaCameras(): any[] {
		    if (!this.filterActive)
		    {
			    return this.groupCameras;
		    }

		    return this.searchedCameras.filter(
			    camera => camera.areaTitle == this.getAreaTitle(this.selectedGroup)
		    ).slice(0, this.searchPageSize);
	    }

        private get filterActive(): boolean {
		    return !!this.searchQuery;
	    }

        @Watch("selectedGroup")
	    public async querySearch(newValue: number, oldValue: number): Promise<void> {
		    try
		    {
			    this.isLoading = true;

			    if(newValue != oldValue){
				    this.setAreaCameras([]);
			    }

			    await this.fetchAreaCameras({ groupID: this.selectedGroup, pageNumber: this.pageNumber, paginated: true } );
		    }
		    catch(err)
		    {
			    console.error("Error Loading Area Cameras", err);
			    this.setAreaCameras([]);
		    }
		    finally
		    {
			    this.isLoading = false;
		    }
	    }

        @Watch("searchQuery")
	    public debouncedFilterSearch: (() => Promise<void>)  = debounce(async () => {
		    await this.searchForDebounce();
	    }, DEBOUNCE_WAIT_TIME);

	    private async searchForDebounce(): Promise<void> {
		    try
		    {
			    this.isLoading = true;
			    await this.searchForCameras({ query: this.searchQuery, groupID: this.selectedGroup, page: this.pageNumber, reset: true });
		    }
		    catch(err)
		    {
			    console.error("Error searching for cameras", err);
		    }
		    finally
		    {
			    this.isLoading = false;
		    }
	    }
	}
