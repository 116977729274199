
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { Rule, CardComponent, EditingRule, RuleScope, CardValidation, Grouping } from "@/store/rules-engine/types";
import RulesCard from "@/components/rules-engine-setup/RulesCard.vue";
import RulesText from '@/components/rules-engine-setup/RulesText.vue';
import RulesLogicStep from '@/components/rules-engine-setup/RulesLogicStep.vue';
import { Getter, namespace } from "vuex-class";
import { Cancelable, cloneDeep, debounce, isEqual } from "lodash";
import vSelect from "vue-select";
import { SessionResource } from "@/store/sessions/types";

const RuleStore = namespace("rulesEngine");
const SessionStore = namespace("sessions");

@Component({
	components:
        {
			"vue-select": vSelect,
            "rules-card": RulesCard,
            "rules-text": RulesText,
            "rules-logic-step": RulesLogicStep
        }
})
export default class RulesEngineSetupModal extends Vue {
	@RuleStore.Getter private getTitleComponentConfig: CardComponent;
	@RuleStore.Getter private getScopeBuilderConfig: CardComponent;
	@RuleStore.Getter private getTriggerBuilderConfig: CardComponent;
	@RuleStore.Getter private getActionBuilderConfig: CardComponent;
	@RuleStore.Getter private getGrouping: Grouping[];
	@RuleStore.Action private saveRule: (rule: EditingRule) => Promise<boolean>;
	@RuleStore.Mutation private updateEditingRule: (rule: Rule) => void;
	@RuleStore.State private editingRule: EditingRule;
	@Getter getUserTenantGroupId: number;
	@SessionStore.Action updateSession: ({ resourceId: SessionResource }) => Promise<void>;

	@Prop({ type: Boolean, default: false })
	private readOnly: boolean;

	@Prop({ required: true, default: false})
	private showModal: boolean = false;

	private titleIsValid: boolean = false;
	private scopeIsValid: boolean = false;
	private triggersIsValid: boolean = false;
	private actionsIsValid: boolean = false;

	// Default grouping area which has an Id of 2
	private currentRule: EditingRule = {
		ruleId: 0,
		title: "",
		tenantId: null,
		grouping: 2,
		ruleTriggers: [],
		ruleActions: [],
		responseId: null,
		serverId: null,
		serverTypeId: null,
		serverTypeEventNum: null,
		input1: null,
		input2: null
	};

	// Default grouping area which has an Id of 2
	private grouping: Grouping = {
		id: 2,
		title: null
	};

	private ruleScope: RuleScope = {
		responseId: null,
		serverId: null,
		serverTypeId: null,
		eventNum: null,
		input1: null,
		input2: null
	};

	private mounted(): void {
		this.currentRule.tenantId = this.getUserTenantGroupId;
		this.updateGrouping();
	}

	@Watch("editingRule")
	private onEditingRuleChanged(): void {
		this.configureCurrentRule();
	}

	private configureCurrentRule(): void {
		if(this.existingRule){
			this.currentRule = cloneDeep(this.editingRule);
			this.updateRuleScope();
		}
		this.updateGrouping();
	}

	private updateRuleScope(): void {
		this.ruleScope = {
			responseId: this.currentRule.responseId,
			serverId: this.currentRule.serverId,
			serverTypeId: this.currentRule.serverTypeId,
			eventNum: this.currentRule.serverTypeEventNum,
			input1: this.currentRule.input1,
			input2: this.currentRule.input2
		};
	}

	private updateGrouping(): void {
		this.grouping = this.getGrouping.firstOrDefault(g => g.id === this.currentRule.grouping);
	}

	private get existingRule(): boolean {
		return !!this.editingRule?.ruleId;
	}

	private get ruleIsInvalid(): boolean {
		if(!this.existingRule){
			// Check cards are valid
			return !(this.titleIsValid && this.scopeIsValid && this.triggersIsValid && this.actionsIsValid);
		} else {
			// Check if any changes have been made and all cards are valid
			const hasChanged = !isEqual(this.editingRule, this.currentRule);
			return !(this.titleIsValid && this.scopeIsValid && this.triggersIsValid && this.actionsIsValid) || !hasChanged;
		}
	}

	@Emit("closeSetupModal")
	private closeRulesEngineSetupModal(): void {
		this.setDataForCloseModal();
	}

	private async saveCurrentRule(): Promise<void> {
		// Only close modal if actions.saveRule is successful
		const result = await this.saveRule(this.currentRule);
    	if (result) {
			this.closeRulesEngineSetupModal();
			this.$emit("triggerReload");
		}
	}

	private setDataForCloseModal(): void {
		this.updateEditingRule(null);

		// Default grouping area which has an Id of 2
		this.currentRule = {
			ruleId: 0,
			title: "",
			tenantId: this.getUserTenantGroupId,
			grouping: 2,
			ruleTriggers: [],
			ruleActions: [],
			responseId: null,
			serverId: null,
			serverTypeId: null,
			serverTypeEventNum: null,
			input1: null,
			input2: null
		};
		this.ruleScope = {
			responseId: null,
			serverId: null,
			serverTypeId: null,
			eventNum: null,
			input1: null,
			input2: null
		};
		this.grouping = {
			id: 2,
			title: null
		};
	}

	private titleValid(value: CardValidation): void {
		this.titleIsValid = value.isValid;
	}

	private scopeValid(value: CardValidation): void {
		this.scopeIsValid = value.isValid;
	}

	private scopeUpdated(value: RuleScope): void {
		this.currentRule.responseId = value.responseId;
		this.currentRule.serverId = value.serverId;
		this.currentRule.serverTypeId = value.serverTypeId;
		this.currentRule.serverTypeEventNum = value.eventNum;
		this.currentRule.input1 = value.input1;
		this.currentRule.input2 = value.input2;
	}

	private triggersValid(value: CardValidation): void {
		this.triggersIsValid = value.isValid;
	}

	private actionsValid(value: CardValidation): void {
		this.actionsIsValid = value.isValid;
	}

	private groupingUpdated(value: Grouping): void {
		if(value) {
			this.currentRule.grouping = value.id;
		}
	}

	@Watch("ruleScope", { deep: true })
	@Watch("currentRule", { deep: true })
	private async reloadSession(): Promise<void> {
		await this.reloadSessionDebounced();
	}

	private get reloadSessionDebounced(): (() => Promise<void>) & Cancelable {
		return debounce(async () => this.updateSession({ resourceId: SessionResource.StandardLoginSession }),
			1000,
			{ leading: true, trailing: false}
		);
	}
}
