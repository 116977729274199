
// Vue
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

// Types
import { UserPermissions, FeaturesList, VueBootstrapField } from "@/store/types";
import { ExternalMapLayerParameter } from "@/store/external-map-layers/types";

// Third Party
import { get } from "lodash";

// Sv Child components
import SureViewIcon from "@/components/SureViewIcon.vue";

@Component({
	components: {
		"sureview-icon": SureViewIcon,
	}
})
export default class ExternalMapLayerPropertiesSetup extends Vue {
	@Prop({ type: Array, default: () => [] }) private layerParameters: ExternalMapLayerParameter[];
	@Prop({ type: Boolean, default: true }) private isLoading: boolean;
	@Prop({ type: Boolean, default: true }) private isReadOnly: boolean;
	@Prop({ type: Boolean, default: false }) private isSetup: boolean;

	@Getter getPermissions: UserPermissions;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	// If feature is on / off
	private get isExternalMapLayerPropertiesEnabled(): boolean {
		return get(this.featuresList, ["ExternalMapLayers", "AutoSyncAttributes"]);
	}

	private get canEditProperties(): boolean {
		return (
			!this.isReadOnly &&
			this.isExternalMapLayerPropertiesEnabled &&
			this.getPermissions && this.getPermissions.canEditExternalMapLayers
		);
	}

	private get canViewProperties(): boolean {
		return this.getPermissions.canViewExternalMapLayers && this.isExternalMapLayerPropertiesEnabled;
	}

	private getTableFields(): VueBootstrapField[] {
		// Gets the appropriate fields
		if (this.layerParameters && this.layerParameters.length > 0) {
			let fields: VueBootstrapField[] = [{ key: "title", label: "Title", sortable: true }];
			if (this.isSetup == true) {
				fields.push({ key: "type", label: "Type", sortable: true });
				fields.push({ key: "isVisible", label: "IsVisible", sortable: true });
			}
			fields.push({ key: "defaultValue", label: this.isSetup == true ? "Default Value" : "Value" });

			return fields;
		}
	}

	private getTableItems(): ExternalMapLayerParameter[] {
		// Filterer out table items that arent set to be visible outside of setup
		if (!this.isSetup) {
			return this.layerParameters.filter(f => f.isVisible);
		}
		return this.layerParameters;
	}
}
