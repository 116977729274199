import { SubscriptionDto } from "@/store/subscription/types";
import { CancelTokenSource } from "axios";

export interface RootState {
	[x: string]: any; // hacky,
	// solves problem described here
	// @ref https://forum.vuejs.org/t/vuex-submodules-with-typescript/40903/4
	appInitialised: boolean;
	dataError: boolean;
	User: User;
	UserGroups: UserGroup[];
	systemPrefs: SystemPrefs;
	mobilePrefs: MobilePrefs;
	keyboardShortcuts: KeyboardShortcut[];
	timeZoneAbbr: string;
	windowId: string;
	activeRegion: ActiveRegion;
	localUserData: User;
	featuresList: FeaturesList;
	timeZones: TimeZone[];
	isSessionHeartBeatActive: boolean;
	isSystemAdminAccessTimeModalHidden: boolean;
	elevationLabels: ElevationLabelType[];
	appOptions: AppOptions | null;
	requiresConfiguration: boolean | null;
	tenantSystemPrefs: TenantSystemPrefs;
	reloadEventQueue: boolean;
}

export interface User {
	loggedIn: boolean;
	fullName: string;
	userId: number;
	username: string;
	userGroupId: number;
	error: string;
	isFullUser: boolean;
	userPermissions: UserPermissions;
	userPrefs: UserPrefs;
	isFirstLogin: boolean;
	tenantGroupId: number;
	dynamicLinkConfig?: DynamicLinkConfig;
	mobileAppEnabled?: boolean;
	suiteUserId?: string;
	suiteResponseLicenced?: boolean;
	suiteCasesLicenced?: boolean;
	suiteTenantId?: string;
}

export interface FeatureTreeNode {
	children?: FeatureTreeNode[];
	description?: string | null;
	enabled: boolean;
	isDefault?: boolean;
	isRelease?: string;
	name: string;
	supportLink?: string;
	isLicenseable?: boolean;
	isEnabledInCurrentLicense?: boolean;
	expanded?: boolean;
}

export interface UserPermissions {
	canManageContactRoles: boolean;
	canEditSettings: boolean;
	canHandleAlarms: boolean;
	canViewReports: boolean;
	canAddIndividualActions: boolean;
	canEditUserPermissions: boolean;
	canViewSiteSetup: boolean;
	canEditSiteSetup: boolean;
	isSystemAdmin: boolean;
	canRaiseActivityLog: boolean;
	canToggleLogVisibility: boolean;
	canEditOwnActivityLog: boolean;
	canEditOtherActivityLogs: boolean;
	canDisarmSites: boolean;
	canDisarmSitesExtended: boolean;
	canSetResponseToMasked: boolean;
	canSetSiteToOnTest: boolean;
	canSetSiteToAuditMode: boolean;
	canViewEvents: boolean;
	canAccessQuickControl: boolean;
	canViewMediaMatrixControls: boolean;
	canAccessManualRaise: boolean;
	canAccessManualTour: boolean;
	canViewSitRep: boolean;
    canUseMobile: boolean;
	canViewMobileRaise: boolean;
	canEditMobileRaise: boolean;
	canDownloadEventSearchSitRep: boolean;
	canDownloadEventSearchMedia: boolean;
	canViewSubscriptionDetails: boolean;
	canProcessActiveAlarms: boolean;
	canDownloadSingleClip: boolean;
	isAccountAdmin: boolean;
    canViewExternalMapLayers: boolean;
	canEditExternalMapLayers: boolean;
	canEditInsightsTitle: boolean;
	canExtendAlerts: boolean;
	canEditMaps: boolean;
	canEditSystemSettings: boolean;
	canEditEventCategorySetup: boolean;
	canEditGuardTours: boolean;
	canAssignGuardTour: boolean;
	canConductGuardTour: boolean;
	canClaimFromMobileRaise: boolean;
	canPreserveEvents: boolean;
	canRequestSupport: boolean;
	canEditAssetSetup: boolean;
	canViewFieldOps: boolean;
	canEditFieldOps: boolean;
	canOverrideGroupSync: boolean;
	canUpdateEventLocation: boolean;
	canUpdateEndedEventLocation: boolean;
	canEditAutomations: boolean;
	canViewAutomations: boolean;
	canViewSystemView: boolean;
	canViewSituationalAwarenessEvents: boolean;
	canPromoteSituationalAwarenessEvent: boolean;
	canDismissSituationalAwarenessEvent: boolean;
	canViewRulesEngine: boolean;
	canEditRulesEngine: boolean;
	canUpdateEventCategory: boolean;
	canViewSchedules: boolean;
	canEditSchedules: boolean;
	canEndMultipleEvents: boolean;
	canEditMaskReasons: boolean;
	canViewMaskReasons: boolean;
	canEditPostEvent: boolean;
	canMaskUntilDateTime: boolean;
	canChangeMaskAction: boolean;
	canViewEventHistory: boolean;
	canPutAlarmPointsOnTest: boolean;
	canUseVirtualAssistSummary: boolean;
	canViewNotificationTemplates: boolean;
	canEditNotificationTemplates: boolean;
	canConfigureSuiteSync: boolean;
	canConfigureDeviceSync: boolean;
	canEditInvolvedParties: boolean;
	canUseVirtualOperators: boolean;
	canViewCustomFields: boolean;
	canEditCustomFields: boolean;
}

export interface UserGroup {
	groupID: number;
	parentGroupID: number;
	groupTypeID: number;
	title: string;
	armed: boolean;
	disabledAt: string;
	mobileAppEnabled: boolean;
	licenseTypeID: number;
}

export interface KeyboardShortcut {
	keys: string;
	action: string;
	featureFlag?: string;
}

export interface LegendKey {
	icon: string;
	details: string;
}

export interface RoutingGroup {
	routingGroupID: number;
	groupID: number;
	title: string;
	isDefault: boolean;
	isActive: boolean;
	unattendedEscalateTo: number;
	canEscalateTo: string;
	colourCode: string;
}

export interface ActiveRegion {
	regionId: number;
	regionUrl: string;
}

export interface EventSearchFeatures {
	PreserveEvents: boolean | undefined | null;
	SupplementalNotes: boolean | undefined | null;
	AlarmTag: boolean | undefined | null;
	DownloadEvent: boolean | undefined | null;
	DownloadSingleClip: boolean | undefined | null;
}

export interface MediaMatrixFeatures {
	AreaTree: boolean | undefined | null;
	RelayControl: boolean | undefined | null;
	Tours: boolean | undefined | null;
	DeviceAudit: boolean | undefined | null;
	LinkedCameras: boolean | undefined | null;
	Clear: boolean | undefined | null;
	HideMenu: boolean | undefined | null;
}

export interface SiteMonitorFeatures {
	Map: boolean | undefined | null;
	EventSharing: boolean | undefined | null;
	SitRep: boolean | undefined | null;
	Raise: boolean | undefined | null;
	SiteDetails: boolean | undefined | null;
	NoteAttachments: boolean | undefined | null;
	Park: boolean | undefined | null;
	LinkedCameras: boolean | undefined | null;
	EventFlagging: boolean | undefined | null;
	AcknowledgeAll: boolean | undefined | null;
	QuickEnd: boolean | undefined | null;
}

export interface EventQueueFeatures {
	Map: boolean | undefined | null;
	ManualRaise: ManualRaiseFeatures | undefined | null;
	ManualTour: boolean | undefined | null;
	InProcessing: boolean | undefined | null;
	Parked: boolean | undefined | null;
	Patrol: boolean | undefined | null;
	DisableProcessingActiveAlarms: | boolean | undefined | null;
}

export interface ManualRaiseFeatures {
	RequireNote: boolean | undefined | null;
}

export interface ActionPlanFeatures {
	AssignActions: boolean | undefined | null;
}

export interface AlarmPointsFeatures {
	OnTest: boolean | undefined | null;
	Masking: boolean | undefined | null;
	AuditMode: boolean | undefined | null;
	AlarmHistory: boolean | undefined | null;
}

export interface AlarmFeatures {
	MediaMatrix: MediaMatrixFeatures | undefined | null;
	EventSearch: EventSearchFeatures | undefined | null;
	SiteMonitor: SiteMonitorFeatures | undefined | null;
	EventQueue: EventQueueFeatures | undefined | null;
	QuickControl: boolean | undefined | null;
	AlarmQueueFilters: boolean | undefined | null;
	ActionPlans: ActionPlanFeatures | undefined | null;
	AlarmPoints: AlarmPointsFeatures | undefined | null;
	AutoPassAlarmOnLeave: boolean | undefined | null;
	Maps: MapsFeatures | undefined | null;
	SessionExpiryAudioSound: boolean | undefined | null;
}

export interface MapsFeatures {
	ToggleSiteMonitor: boolean | undefined | null;
	ToggleEventQueue: boolean | undefined | null;
}

export interface ActionsFeatures {
	Choices: boolean | undefined | null;
	CallContact: boolean | undefined | null;
	MassNotification: boolean | undefined | null;
	SitRep: boolean | undefined | null;
	CCure: boolean | undefined | null;
	ViewAudit: boolean | undefined | null;
	DynamicDataList: boolean | undefined | null;
	CollapseCompletedActions: boolean | undefined | null;
}

export interface CCureUIFeatures {
	LogALL: boolean | undefined | null;
	LogSingle: boolean | undefined | null;
}

export interface CCureFeatures {
	UI: CCureUIFeatures | undefined | null;
}

export interface IntegrationFeatures {
	Lenel: boolean | undefined | null;
	CCure: CCureFeatures | undefined | null;
	Genetec: boolean | undefined | null;
}

export interface MobileFeatures {
	ManualRaise: boolean | undefined | null;
	Tracking: boolean | undefined | null;
    Views: boolean | undefined | null;
    ManualLocation: boolean | undefined | null;
    FieldOps: FieldOpsFeatures | undefined | null;
}

export interface FieldOpsFeatures {
	EventShare: FieldOpsEventShareFeatures | undefined | null;
}

export interface FieldOpsEventShareFeatures {
	FileUpload: boolean | undefined | null;
}

export interface UsersFeatures {}

export interface EventTypeSetupFeatures {
	Geofence?: boolean | undefined | null;
}

export interface TenantFeatures {
	DefaultMasking: boolean | undefined | null;
	DefaultParking: boolean | undefined | null;
}

export interface ValidatePinCodeRequest {
	UserId: number;
	EventId: number;
	PinCode: string;
}

export interface FeaturesList {
	Alarms: AlarmFeatures | undefined | null;
	Actions: ActionsFeatures | undefined | null;
	Integration: IntegrationFeatures | undefined | null;
	ActivityLog: boolean | undefined | null;
	Insights: boolean | undefined | null;
	Dispatch: DispatchFeatures | undefined | null;
	Mobile: MobileFeatures | undefined | null;
	View: boolean | undefined | null;
	Users: boolean | undefined | null;
	EventTypeSetup: EventTypeSetupFeatures | undefined | null;
    EventCategorySetup: boolean | undefined | null;
    CaseManagement: CaseManagementFeatures | undefined | null;
    MobileUsers: boolean | undefined | null;
	SystemView: boolean | undefined | null;
	SituationalAwareness: SituationalAwarenessFeatures | undefined | null;
	TenantSettings: TenantFeatures | undefined | null;
}

export interface SituationalAwarenessFeatures {
	ExternalAlerts: boolean | undefined | null;
}

export interface DispatchFeatures {
	Filters: boolean | undefined | null;
}

export interface TimeZone {
	timeZoneID: number;
	id: string; // this is the name of the timezone
	displayName: string;
	iana?: string; // Used for TimeZone conversion
}

export interface DynamicLinkConfig {
	testPattern: string;
	linkFormatter: string;
}

export interface CaseManagementFeatures {
    RaiseToCaseManagement: boolean | undefined | null;
}

export interface LoginRequest {
	username: string,
	password: string,
	authCode?: string,
	token?: string
}

export interface LoginResult {
	authenticationStatus: AuthenticationStatus;
	userId: number;
	username: string;
	groupId: number;
	tenantGroupId: number;
	fullName: string;
	permissions: UserPermissions;
	features: FeaturesList;
	subscription: SubscriptionDto
	userPrefs: UserPrefs;
	systemPrefs: SystemPrefs;
	redirectRoute: string;
	isFirstLogin: boolean;
	mfaToken: string;
	mobileAppEnabled: boolean;
	suiteUserId: string;
	suiteResponseLicenced: boolean;
	suiteCasesLicenced: boolean;
	suiteTenantId?: string;
	elevationLabels: ElevationLabelType;
	tenantSystemPrefs: TenantSystemPrefs;
}

export interface LoginProviderResult {
	isExternal: boolean;
	authorizationEndpoint: string;
}

export enum AuthenticationStatus {
	None = -1,
	Success,
	LockedOut,
	DisabledUser,
	PasswordExpired,
	ConfigureMfa
}

export interface UserPrefs {
	useImperialMeasurements: boolean;
}

export interface MobilePrefs {
	mapStyle?: string;
}

export interface SystemPrefs {
	mapType: string;
	hideLabels: boolean;
	requireMapPin: boolean;
	canUsersAddIndividualActions: boolean;
	alarmScreenTimeoutSeconds: number;
	alarmAgeWarningSeconds: number;
}

export interface PaginatedQueryParams {
	page: number;
	pageSize: number;
	sortBy?: string;
	sortDesc?: boolean;
}

export interface PaginatedSearchQueryParams extends PaginatedQueryParams {
	searchTerm?: string;
	serverId?: number;
	isEmailAlarms?: boolean;
	isResponseId?: boolean;
}

export interface MaskingModalPaginatedSearchQueryParams extends PaginatedSearchQueryParams {
	filterMaskingType?: MaskingTypeEnum;
}

export enum MaskingTypeEnum {
	Unarmed = 0,
	Armed = 1,
	All = 2
}

export interface PaginatedSearchQueryParamsCustomField extends PaginatedQueryParams {
	customFieldTableType?: number;
	live?: boolean;
}

export interface VueBootstrapField {
	key: string;
	label: string;
	sortable?: boolean;
	sortDirection?: string;
	formatter?: any;
	class?: string;
	isActive?: boolean;
	filterByFormatted?: boolean;
	sortByFormatted?: boolean;
	headerTitle?: string;
	visible?: boolean;
	searchable?: boolean;
	dataType?: string;
	thStyle?: Object;
	thClass?: string;
	editable?: boolean;
}

export interface VueBootstrapFieldTableSortEvent {
	apiUrl: string;
	currentPage: number;
	perPage: number;
	filter: string;
	sortBy: string;
	sortDesc: boolean;
}

export interface VueBootstrapFieldEx extends VueBootstrapField {
	keySort?: string;
}

export interface CancellableQuery<T> {
	params: T;
	cancelTokenSource: CancelTokenSource;
}

export interface LogoutPayload {
	hasSessionExpired: boolean,
	playAudioAlert: boolean,
	errorMessage?: string,
}

export interface ElevationLabelType {
	mapElevationId?: number;
	tenantId?: number;
	title: string;
	elevation: number;
}

export interface AppOptions {
	version: string;
	deployed?: string;
}

export interface TenantSystemPrefs {
	defaultMaskValue: number;
	defaultParkValue: number;
	alarmAgeWarningSeconds: number;
	defaultMaskAction: boolean;
}

export interface UserInfo {
	userId: number;
	fullName: string;
	notes: string;
	email: string;
}

export enum PlatformTypeEnum {
	Response = 1,
}
