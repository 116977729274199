
import { Component, Mixins } from "vue-property-decorator";
import GenericTable, { TableHeader } from "../../table/generic-table.vue";
import { ModalItem } from "../../table/generic-update-modal.vue";
import api from "@/services/api.schedules.service";
import DatePicker from "@sureview/vuejs-datepicker";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import PaginatedSearch from "@/mixins/PaginatedSearch";
import { PaginatedSearchQueryParams } from "@/store/types";
import { PublicHolidayApiContract, PublicHolidayDto } from "@/store/schedules/types";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import moment from "moment";
import { cloneDeep } from "lodash";
import { Datetime } from "vue-datetime";

@Component({
	components: {
		"generic-table": GenericTable,
	},
})
export default class PublicHolidaySetup extends Mixins(PaginatedSearch) {
	private columns: TableHeader[] = [
		{
			title: "Title",
			key: "title",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The public holiday title",
			searchable: true,
			visible: true,
			dataType: "title",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
		},
		{
			title: "Area",
			key: "groupTitle",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The public holiday area",
			searchable: true,
			visible: true,
			dataType: "string",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
		},
		{
			title: "Day",
			key: "day",
			order: 3,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The public holiday day",
			searchable: true,
			visible: true,
			dataType: "number",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
		},
		{
			title: "Month",
			key: "month",
			order: 4,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The public holiday month",
			searchable: true,
			visible: true,
			dataType: "number",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
		},
		{
			title: "Year",
			key: "year",
			order: 5,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The public holiday year",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
			useCustomCell: true,
		},
	];

	public get modalItems (): ModalItem[] {
		return [
			{
				title: "Public Holiday",
				key: "title",
				dataType: "text",
				maxLength: 250,
				required: true,
				placeholder: "Enter a public holiday",
			},
			{
				title: "Area",
				key: "groupId",
				dataType: "component",
				data: AreaTreeSelect,
				maxLength: 250,
				required: true,
				placeholder: "Enter an Area",
				props: {
					reduce: (area: AreaNode) => area.id,
				}
			},
			{
				title: "Inherited",
				key: "inherited",
				dataType: "checkbox",
				readOnly: false,
				description: "Public Holiday will apply to all child areas",
			},
  			{
				title: "Date",
				key: "publicHolidayDate",
				dataType: "component",
				data: Datetime,
				defaultValue: new Date().toISOString(),
				required: true,
				readOnly: false,
				csvComponent:'input',
				props: {
					type: "date",
					inputClass: "public-holiday-calendar-input"
				}
			},
			{
				title: "Repeat Yearly",
				key: "repeatYearly",
				dataType: "checkbox",
				readOnly: false,
				description: "Is the public holiday repeated yearly"
			},
		]
	}

	private publicHolidays: PublicHolidayDto[] = [];
	private totalRecords: number = 0;
	private isLoading: boolean = false;

	private async mounted(): Promise<void> {
		await this.updateData();
	}

	private async putPublicHoliday(publicHoliday: PublicHolidayDto): Promise<void> {
		await api.createOrUpdatePublicHoliday(this.mapDtoToApiContract(publicHoliday));
		await this.updateData(this.mostRecentSearchParams);
	}

	private async deletePublicHoliday(request: PublicHolidayDto): Promise<void> {
		await api.deletePublicHoliday(request.publicHolidayId);
		await this.updateData(this.mostRecentSearchParams);
	}

	private async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		try {
			this.isLoading = true;

			const request = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);

			let publicHolidaysWithPagination = await api.getPublicHolidays(request)

			this.totalRecords = publicHolidaysWithPagination.totalRecords;

			this.publicHolidays = publicHolidaysWithPagination.data.map(x => this.apiContractToPublicHolidayDto(x));
		}
		catch (ex)
		{
			console.error("Unexpected error fetching public holidays: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	private apiContractToPublicHolidayDto(value: PublicHolidayDto | null) : PublicHolidayDto {
		if(value != null)
		{
			let mappedHoliday: PublicHolidayDto = cloneDeep(value);

			// As publicHolidayDate is only for display purposes we need to ensure the date aligns with
			// Datetime format so the GenericModal does not register a change
			mappedHoliday.publicHolidayDate = new Date(mappedHoliday.year == 0 ? new Date().getFullYear() : mappedHoliday.year,
				mappedHoliday.month - 1,
				mappedHoliday.day).toISOString().replace(/T.*/, 'T00:00:00.000+00:00');

			mappedHoliday.repeatYearly = mappedHoliday.year == 0;

			return mappedHoliday
		}

		return null;
	}

	private mapDtoToApiContract(data: PublicHolidayDto): PublicHolidayApiContract {
		let date = moment(data.publicHolidayDate);
		return {
			title: data.title,
			publicHolidayId: data.publicHolidayId,
			groupId: data.groupId,
			groupTitle: null,
			day: date.date(),
			month: date.month() + 1,
			year: data.repeatYearly ? 0 : date.year(),
			inherited: data.inherited ?? false,
		} as PublicHolidayApiContract
	}
}
