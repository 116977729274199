import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { CSVParser } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: CSVParser = getDefaultState();
const namespaced: boolean = true;

export const csvParser: Module<CSVParser, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};
