
import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import { namespace, Getter, Action } from "vuex-class";
import UploadFileButton from "@/components/mobile/core/UploadFileButton.vue";
import { MessageWithAttachment } from "@/store/guard-chat/types";
import MessagesList from "./MessagesList.vue";
import * as chatApi from "@/services/api.guardChat.service";

const GuardChat = namespace("guardChat");
const Mobile = namespace("mobile");

@Component({
	components: {
		UploadFileButton,
		MessagesList
		}
})
export default class Chat extends Vue {
	@GuardChat.Mutation resetState: () => void;
	@Getter getFeature: any;

	@Prop() eventShareId: number | null;

	@Ref() readonly chatContainer: HTMLDivElement;
	@Ref() readonly generalMessagesList: MessagesList;
	@Ref() readonly eventMessagesList: MessagesList;

	@GuardChat.State("numberOfUnread") private eventShareNumberOfUnread: number;
	@GuardChat.Getter("getMessages") private eventShareMessages: MessageWithAttachment[];
	@GuardChat.Mutation private removeMessages: () => void;
	@GuardChat.Mutation("setNumberOfUnread") private setNumberOfUnread: (newValue: number) => void;
	@GuardChat.Action loadMessages: (eventId: number) => Promise<void>;
	@GuardChat.Getter("getTwoWayMessages") twoWayMessages: MessageWithAttachment[];
	@GuardChat.Action loadTwoWayMessages: (eventId: number) => Promise<void>;
	@Getter("getUserId") loggedInUserId: number;
	@Mobile.State private isRightSideBarOpen: boolean;
	@Mobile.Mutation("setChatTab") private setChatTab: (tab: string) => void;
	@Mobile.Mutation("setChatOpen") private setChatOpen: (open: boolean) => void;
	@Mobile.State("ChatOpen") private chatExpanded: boolean;
	@Mobile.State("ChatTab") private chatTab: string;

	//Extra flag to indicate if the chat was open when the rightside bar was toggle so we can re-open the chat
	private chatWasOpen: boolean = false;

	//will control visibility of chat content
	//will be set to true when chat container fully expanded (on transition end)
	//to prevent content reflow and scrolls during expand
	private chatContentVisible = false;

	private message: string = "";
	private isUploading: boolean = false;
	private cancelUpload: boolean = false;
	private isFirstExpand: boolean = true;
	private chatExpandTimeoutId: number | undefined = undefined;
	private chatCollapseTimeoutId: number | undefined = undefined;
	private twoWayNumberOfUnread: number = 0;
	private isDestroying: boolean = false;
	private pollUnreadsTimer: any = null;
	private isSending: boolean = false;

	private async created() {
		this.startPollUnreads();
	}

	private onIsUploading({ state }: { state: boolean }) {
		this.isUploading = state;
	}

	private onUploadSuccess() {
		this.isUploading = false;
	}

	//Ensures we minimize the chat when the right side bar is open
	@Watch("isRightSideBarOpen")
	private rightSideWatcher(){
		if(this.isRightSideBarOpen){
			this.chatExpanded ? this.chatWasOpen = true : this.chatWasOpen = false;
			this.setChatOpen(false);
		} else if (this.chatWasOpen) {
			this.setChatOpen(true);
		}
	}

	@Watch("chatExpanded")
	private chatOpenWatch(): void{
		if(this.chatExpanded){
			this.onChatExpand();
		}
	}

	private onChatExpand() {
		this.chatContentVisible = true;
		// handler, bound via @transitionend at template fires bunch of times during transition.
		// the one, assigned directly to element and removed after have been fired, executed as expected (only once)
		// https://medium.com/better-programming/detecting-the-end-of-css-transition-events-in-javascript-8653ae230dc7
		const onChatExpandTransitionEnd = (() => {
			this.chatContainer.removeEventListener("transitionend", onChatExpandTransitionEnd);
			this.chatContentVisible = true;
			//adjust scrolling only after browser renders content visible
			this.chatExpandTimeoutId = setTimeout(() => {
				if(this.generalMessagesList && this.chatTab === "General"){
					this.generalMessagesList.restoreScrolling();
					if(this.isFirstExpand){
						this.generalMessagesList.scrollToLastMessage();
						this.isFirstExpand = false;
					}
				}

				if(this.eventMessagesList && this.chatTab === "EventShare"){
					this.eventMessagesList.restoreScrolling();
					if(this.isFirstExpand){
						this.eventMessagesList.scrollToLastMessage();
						this.isFirstExpand = false;
					}
				}
			});
		}).bind(this);

		this.chatContainer.addEventListener("transitionend", onChatExpandTransitionEnd);
	}

	private onChatCollapse() {
		if(this.generalMessagesList) this.generalMessagesList.storeScrolling();
		if(this.eventMessagesList) this.eventMessagesList.storeScrolling();

		this.chatCollapseTimeoutId = setTimeout(() => {
			this.chatContentVisible = false;
			this.setChatOpen(false);
		});
	}

	private isAttachmentsFeatureEnabled() {
		// Commented out until we re-introduce file attachements
		// this.getFeature(["Alarms", "SiteMonitor", "Messages", "Attachments"]);
		return false;
	}

	private get isSendMessageVisible() {
		if (this.isAttachmentsFeatureEnabled()) {
			return this.message.length > 0 && !this.isSending;
		}
        return true;
    }

	private get eventChatFileUploadEnabled(): Boolean
	{
		return this.getFeature(["Mobile", "FieldOps", "EventShare", "FileUpload"]);
	}

	private get isSendMessageDisabled() {
		return !this.message || !this.message.trim().length;
	}

	private updateModel($event) {
		if($event)
			this.message = $event;
	}

	private loadMessagesAction(twoWay: boolean = false) {
		if (twoWay)
			return () => this.loadTwoWayMessages(this.loggedInUserId);
		return () => this.loadMessages(this.eventShareId);
	}

	private async onScrollToLastMessage(twoWay: boolean = false) {
		if (twoWay)
			await chatApi.acknowledgeTwoWayOwn();
	 	else
			this.setNumberOfUnread(0);
	}

	private async sendMessage(twoWay: boolean) {
		if(this.isSending) {
			return;
		}

		this.isSending = true;

		try {
			if (this.chatTab === "General") {
				await chatApi.sendTwoWayMessage(this.loggedInUserId, {
					messageType: "mobileUser",
					message: this.message
				});
			} else {
				await chatApi.sendMessage({
					eventId: this.eventShareId,
					messageType: "guard",
					message: this.message
				});
				this.setNumberOfUnread(this.eventShareNumberOfUnread - 1);
			}
		} catch(error) {
			console.error(error)
		} finally {
			this.isSending = false;
			this.message = "";
		}
	}

	private async startPollUnreads() {
		this.pollUnreadsTimer = setInterval(async () => {
			if (!this.isDestroying) {
				this.twoWayNumberOfUnread = await chatApi.getTwoWayUnackedCount();
			}
		}, 5000);
	}

	private get numberOfUnread(): number {
		return (this.eventShareNumberOfUnread ? this.eventShareNumberOfUnread : 0)
			+ (this.twoWayNumberOfUnread ? this.twoWayNumberOfUnread : 0);
	}

	@Watch("chatTab")
	private chatTabWatch(tab: string){
		if (!this.chatExpanded)
			return;

		if (tab === "General" && this.generalMessagesList) {
			setTimeout(() => {
				this.generalMessagesList.scrollToLastMessage();
			}, 50);
		} else if (this.eventMessagesList) {
			setTimeout(() => {
				this.eventMessagesList.scrollToLastMessage();
				this.setNumberOfUnread(0);
			}, 50);
		}
	}

	@Watch("eventShareId")
	private shareIDWatch(newValue: number | null, oldValue: number | null){
		if (oldValue != newValue) {
			this.removeMessages();
		}
		//If we pick up a new event, set the chat tab to be the event share
		if (!oldValue && newValue) {
			this.setChatTab("EventShare");
			return;
		}

		this.setChatTab("General");
	}

	private beforeDestroy() {
		this.isDestroying = true;
		clearInterval(this.pollUnreadsTimer);
	}
}
