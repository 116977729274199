enum ObjectTypeId {
	Camera = 1,
	Door = 2,
	Relay = 3,
	Audio = 4,
	Appliance = 5,
	SyncSystem = 6,
	GroupSync = 7,
	Server = 8,
	Alarm = 9
}
export default ObjectTypeId;
