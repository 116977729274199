
// Vue
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";

// Third Party
import { validationMixin } from "vuelidate";
import { required, requiredIf, minLength, maxLength, minValue, not } from "vuelidate/lib/validators";
import { isEqual, debounce, get } from "lodash";

// Components
import vSelect from "vselect3";
import { ExternalMapLayer, ExternalMapLayerParameter, ExternalMapLayerType, ExternalMapLayerTypeID } from "@/store/external-map-layers/types";
import ExternalMapLayerPropertiesSetup from "@/components/external-map-layer/ExternalMapLayerPropertiesSetup.vue";
import DeleteExternalMapLayerModal from "@/components/external-map-layer/DeleteExternalMapLayerModal.vue";
import { FeaturesList, UserPermissions } from "@/store/types";
import { getDebouncePeriod } from "@/utils/debounce-helper";

// Namespaces
const ExternalMapLayerStore = namespace("externalMapLayers");
const SettingsStore = namespace("settings");

@Component({
	mixins: [validationMixin],
	validations: {
		currentExternalMapLayer: {
			title: {
				required,
				minLength: minLength(1),
				maxLength: maxLength(50),
				doesntExist: not(function(value) {
					return this.layerTitles.includes(this.currentExternalMapLayer.title) && this.isNew;
				})
			},
			externalMapLayerTypeID: {
				required,
				minLength: minValue(1) // Unselected is 0
			}
		},

		hasDataChanged: {
			required: requiredIf(function() {
				!isEqual(this.isNew ? this.emlTemplate : this.selectedMapLayer, this.currentExternalMapLayer);
			})
		}
	},
	components: {
		"vue-select": vSelect,
		"external-properties": ExternalMapLayerPropertiesSetup,
		"delete-external-map-layer-modal": DeleteExternalMapLayerModal
	}
})
export default class ExternalMapLayerModal extends Vue {
	@Prop({ default: null, type: Object }) selectedMapLayer: ExternalMapLayer;
	@Prop({ default: false, type: Boolean }) visible: boolean;
	@Prop({ default: true, type: Boolean }) readonly: boolean;
	@Prop({ default: [], type: Array }) layerTitles: string[];
	@Prop({ default: null }) enhancedMap: any;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getPermissions: UserPermissions;

	@ExternalMapLayerStore.State externalMapLayerTypes!: ExternalMapLayerType[];
	@ExternalMapLayerStore.Action createOrUpdateExternalMapLayer: (externalMapLayer: ExternalMapLayer) => Promise<void>;

	private deleteLayerModal: boolean = false;
	private isNew: boolean = true;
	private isLoading: boolean = false;
	private isReadOnly: boolean = false;
	private isSecure: boolean = false;

	// A list of functions to be called by the click handler when the items displayed by the layer is selected and the name of the attribute to be passed into the function
	private clickHandlerList = [
		{ label: "View Cameras", functionName: "viewSelectedCamera" },
		{ label: "FirstTwo", functionName: "firstTwoIntegration" }
	];

	private selectedClickHandler = null;

	private readonly emlTemplate: ExternalMapLayer = {
		title: "",
		url: "",
		dataObjectURL: "",
		parameters: [],
		customClickHandler: null,
		customClickHandlerAttribute: null,
		externalMapLayerTypeID: 0,
		externalMapLayerID: 0,
		defaultVisible: false,
		groupID: 0,
		displaying: false,
		username: null,
		password: null,
		tokenServiceUrl: null,
		refreshInterval: null
	};

	private currentExternalMapLayer: ExternalMapLayer = { ...this.emlTemplate };

	@Emit("toggle-modal")
	@Emit("external-map-layer")
	private closeModal(): void {
		this.currentExternalMapLayer = { ...this.emlTemplate };
		this.selectedClickHandler = null;
	}

	@Watch("visible")
	private modalToggle(toggle: boolean) {
		this.isSecure = false;
		this.isNew = !this.selectedMapLayer;
		if (toggle) {
			this.currentExternalMapLayer = this.isNew ? { ...this.emlTemplate } : { ...this.selectedMapLayer };

			this.isSecure =
				this.selectedMapLayer &&
				this.selectedMapLayer.username !== undefined &&
				this.selectedMapLayer.username !== null
					? true
					: false;
			if (this.selectedMapLayer) {
				this.selectedClickHandler = this.clickHandlerList.find(
					f => f.functionName == this.selectedMapLayer.customClickHandler
				);
			}
			this.urlChange();
		} else {
			this.currentExternalMapLayer = { ...this.emlTemplate };
		}
	}

	private async saveExternalMapLayer(): Promise<void> {
		if (this.selectedClickHandler) {
			this.currentExternalMapLayer.customClickHandler = this.selectedClickHandler.functionName;
		}
		//sudo call action to save
		await this.createOrUpdateExternalMapLayer(this.currentExternalMapLayer);
		//sudo call close modal method
		this.closeModal();
	}

	private async urlChange(): Promise<void> {
		if (this.currentExternalMapLayer.url) {
			this.currentExternalMapLayer.url = this.currentExternalMapLayer.url.trim();
		}

		let tokenServiceUrl = this.currentExternalMapLayer.tokenServiceUrl;

		if (tokenServiceUrl) {
			tokenServiceUrl = tokenServiceUrl.trim();
			this.currentExternalMapLayer.tokenServiceUrl = tokenServiceUrl;
		}
		// We only need to query for properties if we are an ArcGis layer and properties are enabled
		if (
			this.isExternalMapLayerPropertiesEnabled &&
			this.currentExternalMapLayer.externalMapLayerTypeID === ExternalMapLayerTypeID.ArcGIS &&
			this.currentExternalMapLayer.url
		) {
			if (!this.isSecure) {
				await this.setArcGisParametersOnLayer();
			}
			// ensure we have all credentials before attempting to query if this layer is secure
			else if (
				this.currentExternalMapLayer.username &&
				this.currentExternalMapLayer.password &&
				this.currentExternalMapLayer.tokenServiceUrl
			) {
				await this.setArcGisParametersOnLayer();
			}
		}
	}

	private urlChangeDebounce = debounce(async () => await this.urlChange(), getDebouncePeriod());

	private typeChange(): void {
		// FirstTwo Layer should be secure
		if (this.currentExternalMapLayer.externalMapLayerTypeID == ExternalMapLayerTypeID.FirstTwo) {
			this.isSecure = true;
		} else {
			this.isSecure = false;
		}

		// If we change the type and were not ArgGis layer, empty parameters otherwise get the parameters
		if (this.currentExternalMapLayer.externalMapLayerTypeID != ExternalMapLayerTypeID.ArcGIS) {
			this.currentExternalMapLayer.parameters = [];
		} else {
			this.urlChange();
		}
	}

	private async secureChange(): Promise<void> {
		// If we toggle the secure layer off clear the relevant fields
		if (!this.isSecure) {
			this.currentExternalMapLayer.username = null;
			this.currentExternalMapLayer.password = null;
			this.currentExternalMapLayer.tokenServiceUrl = null;

			// Requery to update parameters
			this.currentExternalMapLayer.parameters = [];
			await this.setArcGisParametersOnLayer();
		}
	}

	private async getArcGisParameters(requestedLayer: ExternalMapLayer): Promise<Object> {
		// ToDo: Will this impact being able to display ArcGIS layers?
		/* const prefRequest: PrefTypeRequest = {
				prefTypeID: PrefTypesEnum.ESRIAuthenticationPostRestType,
				groupID: null
			}

		const prefEnabled = this.getPrefBoolValue(prefRequest);
		const data = await this.enhancedMap.getArcGisParameters(requestedLayer, false);
		return data;*/
		return null;
	}

	private async setArcGisParametersOnLayer(): Promise<void> {
		this.isLoading = true;
		let parameters: any = await this.getArcGisParameters(this.currentExternalMapLayer);
		try {
			if (parameters && parameters.fields) {
				// Clear existing parameters if new to prevent keeping outdated params when querying an existing url
				if (this.isNew) {
					this.currentExternalMapLayer.parameters = [];
				}
				// For every parameter received from the api create a new Parameter object for the layer
				parameters.fields.forEach(parameter => {
					if (!this.currentExternalMapLayer.parameters.find(emlp => emlp.title == parameter.name)) {
						let newParam = new ExternalMapLayerParameter(
							parameter,
							this.currentExternalMapLayer.externalMapLayerID
						);
						this.currentExternalMapLayer.parameters.push(newParam);
					}
				});
				// Delete any parameters that already exist that we don't get back from the api
				this.currentExternalMapLayer.parameters.forEach(localParam => {
					if (parameters.fields.find(apiParam => apiParam.name == localParam.title) == null) {
						this.currentExternalMapLayer.parameters = this.currentExternalMapLayer.parameters.filter(
							filterParam => filterParam !== localParam
						);
					}
				});
			}
		} catch(err) {
			console.log("Error setting ArcGIS Parameter on layer: " + err)
		}
		this.isLoading = false;
	}

	private get isExternalMapLayersEnabled(): boolean {
		return (
			get(this.featuresList, ["ExternalMapLayers"]) &&
			(this.getPermissions.canViewExternalMapLayers || this.getPermissions.canEditExternalMapLayers)
		);
	}

	private get isExternalMapLayerPropertiesEnabled(): boolean {
		return (
			get(this.featuresList, ["ExternalMapLayers"]) &&
			this.getPermissions.canEditExternalMapLayers
		);
	}

	private get titleAlreadyExists(): boolean {
		return (
			!!this.currentExternalMapLayer &&
			!this.$v.currentExternalMapLayer.title.doesntExist &&
			!!this.currentExternalMapLayer.title &&
			this.currentExternalMapLayer.title.length > 0 &&
			this.isNew
		);
	}

	private refreshIntervalFormatter(value): number {
		if (value == null || value < 1 ){
			return null;
		}
		return Math.floor(value);
	}
}
