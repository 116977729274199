import { MutationTree } from "vuex";
import {
	EventSearchState,
	Event,
	EventType,
	DeviceType,
	Category,
	AlarmType,
	Operator,
	EventRecordHasClipStatus
} from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<EventSearchState> = {
	setEvents(state, data: Event[]) {
		state.Events = data;
	},
	setSearchableEventTypes(state, data: EventType[]) {
		state.SearchableEventTypes = data;
	},
	setDeviceTypes(state, data: DeviceType[]) {
		state.DeviceTypes = data;
	},
	setCategories(state, data: Category[]) {
		state.Categories = data;
	},
	setAlarmTypes(state, data: AlarmType[]) {
		state.AlarmTypes = data;
	},
	setOperators(state, data: Operator[]) {
		state.Operators = data;
	},
	setSearching(state, searching: boolean) {
		state.searching = searching;
	},
	setEventFlagged(state, { eventId, flagged }) {
		const event = state.Events.filter(e => e.eventID === eventId);
		if (event[0]) {
			event[0].flaggedForReview = flagged;
		}
	},
	setEventSearchIncidentReportShown(state, shown: boolean) {
		state.IncidentReportOpen = shown;
	},
	setEventSearchIncidentReportDownloaded(state, downloaded: boolean) {
		state.IncidentReportDownloaded = downloaded;
	},
	removeEventFromSearchResults(state, eventId: number) {
		const indexOfEvent = state.Events.findIndex(e => e.eventID === eventId);
		if (indexOfEvent !== -1) {
			state.Events.splice(indexOfEvent, 1);
		}
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	setEventRecordsWithClips(state, data: EventRecordHasClipStatus[]) {
		state.EventRecordsWithClips = data;
	}
};
