
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { QuickEndSettingsDto } from '@/types/organization-settings/QuickEndSettings';
import { Getter } from 'vuex-class';
import { truncateString } from "@/filters";

@Component({
    filters: {
		truncateString
	}
})
export default class QuickEndSettingsTooltipContent extends Vue {
    @Prop({type: Boolean}) readonly quickEndSettingsExist: boolean;
    @Prop({type: Boolean}) readonly quickEndSettingsValid: boolean;
    @Prop({type: Object}) readonly quickEndDto: QuickEndSettingsDto;

    @Getter("getFeature") getFeature: (featureName: string[]) => boolean;
    private get isQuickEndTooltipEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "QuickEnd", "Confirm"]);
	}

    @Emit("submit")
    private submitQuickEnd(): void {
        return;
    }

    @Emit("close-tooltip")
    private closeTooltip(): void {
        return;
    }

}
