export interface PollingStatus {
	isPolling: boolean,
	intervalHandlerId: number
}

export const defaultPollingStatus = (): PollingStatus => {
	return {
		isPolling: false,
		intervalHandlerId: 0
	}
}
