import { Module } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "../types";
import { getDefaultState } from "./state";
import { ResponseActionsState } from "./types";

export const state: ResponseActionsState = getDefaultState();
const namespaced: boolean = true;

export const responseActions: Module<ResponseActionsState, RootState> = {
	namespaced,
	state,
	actions,
	mutations
};
