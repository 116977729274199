
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Multiselect from "vue-multiselect";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const Eventqueue = namespace("eventqueue");
const SMCameras = namespace('siteMonitorCameras');

@Component({
  components: {
    multiselect: Multiselect,
    VuePerfectScrollbar: VuePerfectScrollbar
  }
})
export default class AreaCameras extends Vue {
  @Eventqueue.Action searchGroups: any;
  @SMCameras.Action fetchAreaCameras: any;
  @SMCameras.Getter areaCameras: any[];

  public settings: any = {};
  public selected: any[] = [];
  public searchOptions: any[] = [];
  public isLoading: boolean = false;

  public areaSelected(selectedOption: any, id: string) {
    this.fetchAreaCameras(selectedOption.groupID);
  }

  @Emit("deviceSelected")
  public cameraSelect(camera: any) {
    return camera;
  }

  async asyncFind(query: string) {
    this.isLoading = true;
    this.searchOptions = await this.searchGroups({
      SearchTerm: query
    });
    this.isLoading = false;
  }
}
