
	import { Component, Prop, Watch, Vue, Emit } from "vue-property-decorator";
	import { namespace } from "vuex-class";
	import vSelect from "vue-select";
	import { ActionPlanTask } from "@/store/tasks/types";
	import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";
    import { DeviceDetails } from "@sureview/camera";
    import Multiselect from "vue-multiselect";

    import { get } from "lodash";

    const Devices = namespace("devices");

	@Component({
		components: {
            "vue-select": vSelect,
            multiselect: Multiselect
		},
		mixins: [validationMixin],
		validations: {
            controlTaskSetup: {
			    device: {
				    deviceId: {
					    required
				    }
			    }
            },
            controlType: {
                required
            }
		}
	})
	export default class ControlSetup extends Vue {

   		@Prop()
		public value!: ActionPlanTask;

        @Devices.State("cancelTokenSource") source: any;
        @Devices.State doors: DeviceDetails[];
        @Devices.Action deviceSearch: (query: string) => Promise<void>;

        /**
         * Holds the request data for the quick control action
         */
        public controlTaskSetup = {
            device: null
        };
        public controlActionTypes = [
            {
                title: "On"
            },
            {
                title: "Off"
            },
            {
                title: "Pulse"
            }];

        // Indicates that we're loading the search results
	    public doorSearchLoading = false;

        public controlType: any = null;

		public mounted() {
			this.$v.$reset();

            this.loadFromTask();

			// Trigger initial validation
			this.onValidationChange(this.$v.$invalid);
		}

		@Watch("controlType", { deep: true, immediate: true })
		public controlTypeChange(newValue) {
			this.$v.$touch();

			if (!this.$v.$error) {
				this.onInput();
			}
        }

        @Watch("controlTaskSetup", { deep: true, immediate: true })
		public controlTaskSetupChange(newValue) {
			this.$v.$touch();

			if (!this.$v.$error) {
				this.onInput();
			}
		}

		/**
		 * Fires an input event containing the new task data.
		 */
		@Emit("input")
		private onInput() {
			return {
				...this.value,
				taskText: `Triggering "${this.controlType.title}" action on "${this.controlTaskSetup.device.title}"`,
				taskData: {
                    control: {
                        deviceId: this.controlTaskSetup.device.deviceId,
                        title: this.controlTaskSetup.device.title
                    },
                    controlAction: this.controlType
				}
			};
		}

		/**
		 * Watcher/emitter for the validation state of this component,
		 * bubbles up vuelidate's validation state when it changes.
		 */
		@Watch("$v.$invalid")
		private onValidationChange($invalid: boolean) {
			this.$emit("validate-task", !$invalid);
        }

        private loadFromTask() {
            if(get(this, ["value", "taskData", "control", "deviceId"], {}) > 0){
                this.controlTaskSetup.device = get(this, ["value", "taskData", "control"], {});
                this.controlType = get(this, ["value", "taskData", "controlAction"], {});
            }
		}

        /**
         * Async handler for the vue-multiselect. Handles the real time search.
         */
        public async asyncFind(query: string) {
            if (this.source) {
                this.source.cancel("Operation canceled by the user.");
            }

            this.doorSearchLoading = true;
            await this.deviceSearch(query);
            this.doorSearchLoading = false;
        }

	}
