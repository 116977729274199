import { ICamera } from "@/store/views/types";
import { AlarmQueueFilterID, AlarmQueueFilter } from "@/store/eventqueue/types";

import {
	ILatLng
} from "@sureview/v2-mapping-saas";
import { AssetMapLayerItem } from "@/components/mobile-assets/types";
import AssetType from "@/types/sv-data/AssetType";
import MapLabel from "@/scripts/mapping/mapLabel";

export interface Mobile {
	isRightSideBarOpen: boolean;
	isLeftSideBarOpen: boolean;
	showDirections: boolean;
	DeviceLocation: any;
	IsWatchingLocation: boolean;
	AuditEndpoint: string;
	MobileFeatures: MobileFeatures;
	UnavailableStatuses: UnavailableStatus[];
	LiveDevice: ICamera;
	MobileMode: string;
	MapEnabled: boolean;
	StarredDevices: StarredDevice[];
	UserPermissions: { [permission: string]: number };
	NotificationText: string;
	ManageDataLoading: boolean;
	ManageAlarmQueueFilters: AlarmQueueFilter[];
	ManageCurrentAlarmQueueFilters: AlarmQueueFilter[];
	MapGoto: (latLng: ILatLng) => void;
	QuickCloseList: QuickClose[];
	UserMapOnlineStatus: boolean,
	UserAssetMapItem: AssetMapLayerItem,
	UserAssetType: AssetType,
	EventShareItems: MobileEventShareInfo[],
	ActiveEventShareItem: MobileEventShareInfo,
	FocusedEventID: number,
	NavigationMode: string,
	NavigateToActiveAlarm: boolean,
	NavigationLocation: ILatLng,
	ChatOpen: boolean,
	ChatTab: string,
	Regions: Map<number, {poly: google.maps.Polygon, label: MapLabel }>
}

export interface MobileEventShareInfo {
	eventID: number;
    assetId: number;
    eventAreaTitle: string,
    areaGroupID: number,
    eventDescription: string,
    eventLocation: string,
	latLng?: ILatLng,
	eventAddress: string,
    created: Date,
    lastUpdated: Date,
    state: number,
	priority?: number
}

export interface UserAssetStatus {
	assetId: number,
	status: string,
	statusNote: string
}

export interface MobileFeatures {
	isManualRaiseEnabled: boolean;
	isSOSEnabled: boolean;
	isLostModeEnabled: boolean;
	isBreakCoverEnabled: boolean;
	isClaimedEnabled: boolean;
	isFileUploadEnabled: boolean;
}

export interface UnavailableStatus {
	statusID: number;
	name: string;
}

export interface StarredDevice {
	deviceID: number;
	title: string;
	area: string;
}

export interface SVLocation {
	lat: number;
	lng: number;
}

export interface QuickClose {
	eventOutcomeID: number;
	title: string;
	isMobileClose: boolean;
	groupID: number;
}

export enum DeviceType {
	Unknown = 0,
	LegacyiOS = 1,
	LegacyAndroid = 2,
	CCiOS = 3,
	CCAndroid = 4,
}
export interface TrackingToggle {
	toggle: string;
}
export interface ScanType {
	type: string;
}
export interface WebAppInterface {
	// IMPORTANT: Android WebAppInterfaces can only take primitive types as a parameter
	trackingRequest(trackingToggle: string) : any;
	scanRequest(type: string) : any;
}
