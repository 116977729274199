
import PerfectScrollbar from "perfect-scrollbar";
import { Component, Prop, Watch, Vue, Emit } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import { TaskType, ActionPlanTask } from "@/store/tasks/types";
import vSelect from "vue-select";

import { validationMixin } from "vuelidate";
import { required, integer, minValue } from "vuelidate/lib/validators";

import { get } from "lodash";

const Tasks = namespace("tasks");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		"vue-select": vSelect
	},
	mixins: [validationMixin],
	validations: {
		notificationData: {
			serverId: {
				required,
				integer,
				minValue: minValue(1)
			},
			templateId: {
				required,
				integer,
				minValue: minValue(1)
			},
			title: {
				required
			}
		}
	}
})
export default class NotificationSetup extends Vue {
	@Tasks.Action fetchNotificationServers: any;
	@Tasks.Action fetchNotificationTemplates: any;
	@Tasks.Getter("getNotificationServers") servers: any;
	@Tasks.Getter("getNotificationTemplates") templates: any;

	@Prop()
	public value!: ActionPlanTask;

	public showErrorAnimation = false;
	public isNameEmpty: boolean = false;
	public notificationData: any = {
		title: "",
		serverId: null,
		templateId: null
	};

	public async mounted() {
		this.$v.$reset();

		await this.fetchNotificationServers();
		this.loadFromTask();

		// Trigger initial validation
		this.onValidationChange(this.$v.$invalid);
	}

	/**
	 * Triggers validation and a v-model update when the user changes a data point.
	 */
	@Watch("notificationData", { deep: true, immediate: true })
	private async onNotificationDataChange(newValue) {
		this.$v.$touch();

		if (!this.$v.$error) {
			this.onInput();
		}
	}

	/**
	 * Fires an input event containing the new task.
	 */
	@Emit("input")
	private onInput() {
		return {
			...this.value,
			taskText: this.notificationData.title,
			taskData: {
				...this.notificationData
			}
		};
	}

	/**
	 * Watcher/emitter for the validation state of this component,
	 * bubbles up vuelidate's validation state when it changes.
	 */
	@Watch("$v.$invalid")
	private onValidationChange($invalid: boolean) {
		this.$emit("validate-task", !$invalid);
	}

	/**
	 * Loads notification templates for the currently selected server.
	 */
	private async loadTemplatesForServer() {
		if (this.notificationData.serverId > 0) {
			await this.fetchNotificationTemplates(
				this.notificationData.serverId
			);
		}
	}

	/**
	 * Loads the notificationData from the taskData.
	 */
	private loadFromTask() {
		if (get(this, ["value", "taskData"], false)) {
			this.notificationData = {
				...this.value.taskData
			};
		}

		this.loadTemplatesForServer();
	}
}
