
import { Prop, Mixins, Component, Watch } from "vue-property-decorator";
import api from "@/services/api.service";
import SystemViewTreeNode from '@/types/sv-data/system-view/SystemViewTreeNode';
import { SetupSummaryResponse, CameraSummary } from '@/types/sv-data/system-view/SetupSummary';
import { Getter } from 'vuex-class';
import { UserPermissions, FeaturesList } from '@/store/types';
import { get } from 'lodash';
import SystemOverview from "./SystemOverview.vue";
import SystemViewMap from "./SystemViewMap.vue";
import SystemViewAlarmSetup from "./SystemViewAlarmSetup.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ObjectTypeId from "@/types/sv-data/enums/ObjectTypeIds";
import { MapLayerItem } from "@/types/sv-data/system-view/MapLayerItem";
import CameraPreview from '../device-setup/CameraPreview.vue';
import HideMapMixin from "@/mixins/HideMapMixin";

@Component({
	components:{
		SystemOverview,
		SystemViewMap,
		SystemViewAlarmSetup,
		VuePerfectScrollbar,
		CameraPreview
	}
})
export default class SetupOverviewCamera extends Mixins(HideMapMixin) {
	@Prop({ required: true, default: null }) private selectedNode: SystemViewTreeNode;

	@Getter getPermissions: UserPermissions;
	@Getter getFeaturesList: FeaturesList;

	private setupResponse: SetupSummaryResponse =  null;
	private cameraSummary: CameraSummary = null;
	private objectTypeId: ObjectTypeId = ObjectTypeId.Camera;
	private locationDetails: MapLayerItem[] = [];
	private showPreviewModal: boolean = false;

	private async mounted(): Promise<void> {
		await this.retrieveCameraDetails();
	}

	@Watch("selectedNode", { deep: true })
	private async retrieveCameraDetails(): Promise<void> {
		if(this.selectedNode && this.canUseSystemView)
		{
			this.cameraSummary = null; // Reset previous display data

			this.setupResponse = await api.getSetupSummary(this.selectedNode.objectTypeId, this.selectedNode.objectId)

			if(this.setupResponse && !this.setupResponse?.error ) {
				try {
					this.cameraSummary =  JSON.parse(this.setupResponse.summary) as CameraSummary;
				}
				catch (e) {
					console.error("Failed to Parse Camera summary: " + e);
					this.setupResponse.error = "Failed to Parse Camera summary";
				} finally {
					this.updateMapData();
				}
			}
		}
	}

	private get canUseSystemView(): boolean {
		return  get(this.getFeaturesList, ["SystemView"]) && (this.getPermissions.canViewSystemView || this.getPermissions.isSystemAdmin);
	}

	private get getSystemOverviewCols(): string{
		return this.isHideMapsEnabled ? '12' : '6';
	}

	private updateMapData(): void {
		if (!!this.cameraSummary && !!this.cameraSummary.mapLayerItems) {
			this.locationDetails = this.cameraSummary.mapLayerItems;
		} else {
			this.locationDetails = [];
		}
	}

	private toggleCameraPreviewModal(value: boolean): void
	{
		this.showPreviewModal = value;
	}

}
