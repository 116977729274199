
import { Component, Prop, Watch, Vue, Emit } from "vue-property-decorator";
import vSelect from "vue-select";
@Component({
	components: {
		"vue-select": vSelect
	}
})
export default class MultipleAssignToAction extends Vue {
	$refs!: {
		multipleAssignControl: any;
	};

	@Prop(Boolean)
	isControlVisible: boolean;

	@Prop(String)
	groupTitle: string;

	@Prop({
		default: () => []
	})
	usersToAssign: any[];

	@Watch("isControlVisible")
	onEventDetailsShownChanged(value: boolean) {
		if (value) {
			this.$refs.multipleAssignControl.show();
		} else {
			this.$refs.multipleAssignControl.hide();
		}
	}

	public assignedUser = null;

	@Emit("selectUser")
	public onAssign() {
		this.hideModal();
		return {
			...this.assignedUser,
			groupTitle: this.groupTitle
		};
	}

	public hideModal() {
		this.$refs.multipleAssignControl.hide();
	}

	@Emit("hide")
	public onCloseEventHandler() {}
}
