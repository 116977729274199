
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SessionResource } from "@/store/sessions/types";
import { AuditService } from "@/services/auditService";
import { namespace } from "vuex-class";
import EmailHolder from "@/components/emails/email-holder.vue";

const SessionStore = namespace("sessions");
const SiteMonitor = namespace("siteMonitor");
const Emails = namespace("emails");
@Component({
	components: {
		EmailHolder
	}
})
export default class EmailRawDataModal extends Vue {
	@SessionStore.Getter getSession: (resourceId: number) => string;
	@SiteMonitor.Getter("getAuditService") auditService: AuditService;
	@SiteMonitor.Mutation("setAuditService") private setAuditService: AuditService;
	@Emails.Action loadEmailData: ({ auth, recordId }) => Promise<void>;
	@Emails.State eventRecordIdToShowEmailFor: number;
	@Emails.Mutation("setEventRecordIdToShowEmailFor") private setEventRecordIdToShowEmailFor: (number) => void;

	@Watch("eventRecordIdToShowEmailFor")
	private getEventRecordIdToShowEmailFor(newVal: number, oldVal:number): void {
		if (!newVal || newVal < 0) {
			return;
		}

        this.eventRecordId = newVal;
	    this.downloadRawData();
	}

	private eventRecordIdAsString:string = "";
	private eventRecordId: number;
	/**
	 * Download the raw data and put it into rawDataHtml to be leverage in modal
	 * @param eventRecordId
	 */
	private async downloadRawData(): Promise<void> {
		this.eventRecordIdAsString = `${this.eventRecordId}`
		const auth = this.getSession(SessionResource.AuditServiceSession);

		await this.loadEmailData({ auth, recordId: this.eventRecordIdAsString })
		this.$bvModal.show("rawDataModal");
	}

	private async downloadEmail(): Promise<void> {
		await this.auditService
			.getAllFiles(this.getSession(SessionResource.AuditServiceSession), this.eventRecordId)
			.then(async (response: any) => {
				const fileName = response.headers["x-suggested-filename"];
				let link = document.createElement("a");
				link.href = window.URL.createObjectURL(response.data);
				link.download = fileName;
				link.click();
			})
			.catch(err => {
				console.error(`Download failed: ${err.response.data}`);
			});
	}

	private resetEventRecordId(): void {
		this.setEventRecordIdToShowEmailFor(-1);
	}
}
