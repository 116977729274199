
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getAssetIcon, getTrackedAssetIcon } from '@/assets/svgIcons'
import { MapLayerItemType, MapLayerItemTypeIds,  } from "@/store/map-layers/types";
import { MarkerIcons } from "@sureview/v2-mapping-saas";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const MapLayers = namespace("mapLayers");

@Component({
	components: {
		"scrollbar": VuePerfectScrollbar,
    }
})
export default class EnhancedSingleMapControls extends Vue {
    @Prop({type: Number, default: 0}) elevation: number;
    @Prop({ default: "", type: String }) displayElevation: string;

    @Prop({ type: Array, default: () => [] }) elevationList: any[];
    @Prop({ type: Array, default: () => [] }) filterOptions: MapLayerItemType[];

    @Prop({ default: false, type: Boolean }) showGoToEventButton: boolean;
    @Prop({ default: false, type: Boolean }) showHideMapButton: boolean;
    @Prop({ default: false, type: Boolean }) showFilterSelector: boolean;
    @Prop({ default: false, type: Boolean }) isSearchActive: boolean;
    @Prop({ default: false, type: Boolean }) openSearch: boolean;
    @Prop({ default: false, type: Boolean }) isRegionsFilterActive: boolean;
    @Prop({ default: false, type: Boolean }) showRegionsFilterOption: boolean

    @MapLayers.State("mapItemIcons") private mapIcons!: MarkerIcons;

    /// Sets the default Icons for User and Asset types as these can be altered at asset type level and asset level
    private mapIconForType(mapItemType: MapLayerItemType): string {
		if (mapItemType.title === "Asset") {
			return getAssetIcon("black", true, false, false);
		}

		if (mapItemType.title === "TrackedAsset") {
			return getTrackedAssetIcon();
		}

		if (mapItemType.mapLayerItemTypeId === MapLayerItemTypeIds.User) {
			return getAssetIcon("black", false, false, false);
		}

		return this.mapIcons[mapItemType.mapLayerItemTypeId];
	}

    private get canGoUp(): boolean {
		return this.elevationList.some(elevation => elevation.elevationValue > this.elevation);
	}

    private get canGoDown(): boolean {
        return this.elevationList.some(elevation => elevation.elevationValue < this.elevation);
	}
}

