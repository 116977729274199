
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { EventDetails } from "@/store/site-monitor/types";
import { DeviceControllerOutput } from "@/store/site-monitor-cameras/types";
import { renderDistance } from "@/filters";

const SiteMonitor = namespace("siteMonitor");

@Component({
	filters: {
		renderDistance
	}
})
export default class ControlDevicesList extends Vue {

	@Prop() outputs!: DeviceControllerOutput;
	@Prop() title!: string;

	public openOutputs: boolean = true;

	@Emit("doPulseOutput")
	private async emitPulse(output: DeviceControllerOutput): Promise<DeviceControllerOutput> {
		return output;
	}

	private setOutput(output: DeviceControllerOutput, on: boolean): void  {
		this.$emit("setOutput", output, on);
	}

	private pulseOutput(output: DeviceControllerOutput): void  {
		output.controlRequest = true;
	}

	private cancelPulse(output: DeviceControllerOutput): void  {
		output.controlRequest = false;
		output.controlReason = "";
	}
}

