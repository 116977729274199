
import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import AreaEventHistory from './AreaEventHistory.vue';
import Vue from 'vue';
import Draggable from "../utilities/draggable.vue";
import { UserPermissions } from '@/store/types';

const AreaEventHistoryStore = namespace("areaEventHistory");

@Component({
    components: {
        "area-event-history": AreaEventHistory,
        "draggable": Draggable,
    }
})
export default class AreaEventHistoryPopout extends Vue {
    @Prop({ type: Number, required: false }) groupId: number;
    @Prop({ type: Number, required: false }) timeZoneId: number;

    @AreaEventHistoryStore.Mutation setIsPopoutModeEnabled: (isEnabled: boolean) => void;

    @AreaEventHistoryStore.State isPopoutModeEnabled: boolean;
    @AreaEventHistoryStore.State storedTimeZoneId: number;

    @Getter getFeature: (featureName: string[]) => boolean;
    @Getter getPermissions: UserPermissions;

    private created(): void {
        if (this.storedTimeZoneId != null) {
            this.timeZoneId = this.storedTimeZoneId;
        }
    }

    private get isEventHistoryEnabled(): boolean {
		return this.getFeature(["Areas", "AreaEventHistory"]) && this.getPermissions.canViewEventHistory;
	}

    private disablePopoutMode(): void {
        this.setIsPopoutModeEnabled(false);
    }
}
