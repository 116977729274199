
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { isEqual } from "lodash";
import GoogleMap from '@/scripts/mapping/map';
import { getMapStyle } from '@/scripts/mapping/mapStyle';
import getScript from '@/services/insertScript.service';
import { Getter } from 'vuex-class';
import VSwatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.css"
import MapAreaRegion from '@/types/sv-data/maps/MapAreaRegion';
import ILatLng from "@sureview/v2-mapping-saas";
import { mapRegionMixins } from '@/mixins'
import PlaceResult = google.maps.places.PlaceResult;
import Marker = google.maps.Marker;
import GPSService, { GeoCodeLocation } from "@/services/gps.service";
import { faMapMarker } from "@fortawesome/pro-solid-svg-icons";
import { convertStringToLatLng } from "@sureview/v2-mapping-saas";
import HideMapMixin from "@/mixins/HideMapMixin";

export interface AreaLocation {
	address: string,
	mapAreaRegion?: MapAreaRegion,
	latLong?: string
}

const {
    getPolygonOptionsForRegion,
    defaultPolygonOptions
} = mapRegionMixins.methods;

const geoCoder = new GPSService();

const defaultMapAreaRegion = {
	regionId: -1,
	groupId: -1,
	tenantId: -1,
	path: null,
	borderColor: null,
	fillColor: null,
};

@Component({
    components: {
        "color-swatch" : VSwatches
    }
})
export default class AreaLocationSetup extends Mixins(HideMapMixin)  {
    @Getter getMapType: any;
    @Getter getHideLabels: any;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

    @Prop()
    private value: AreaLocation;

    @Prop({default: false, type:Boolean})
    private readonly: Boolean;

    public $refs!: {
        mappingContainer: HTMLElement;
    }

    private areaLocation: AreaLocation = {
    	mapAreaRegion: defaultMapAreaRegion,
		latLong: "",
		address: ""
	}

    private drawRegion: boolean = false;
    private map: GoogleMap | null = null;
    private drawingManager: google.maps.drawing.DrawingManager | null = null;
    private regionPolygon: google.maps.Polygon | null = null;
    private mapLightMode: boolean = false;

    private isSettingLocationForFirstTime: boolean = false;
	private autocomplete: google.maps.places.Autocomplete | null = null;
    private latLongOfAddress: string = "";
    private locationMarker: Marker;
	private isProvidingLocation: boolean = false;
	private isLatLongValid: boolean = true;

    @Watch('drawRegion')
    private drawRegionWatch(): void {
        this.drawingManager!.setOptions({
            drawingControl: this.drawRegion,
            drawingMode: this.drawRegion ?
                google.maps.drawing.OverlayType.POLYGON :
                null
        });
    }

    private getPath(polygon: google.maps.Polygon): ILatLng[] {
        return polygon.getPath()
            .getArray()
            .map(point => {
                return { lat: point.lat(), lng: point.lng() };
            });
    }

    private renderRegion(): void {
        const polygonOptions = getPolygonOptionsForRegion(this.areaLocation.mapAreaRegion);
        this.regionPolygon = new google.maps.Polygon({
            map: this.map!.map,
            paths: JSON.parse(this.areaLocation.mapAreaRegion.path),
            ...polygonOptions
        });

         //Add listener when a new point is added
        google.maps.event.addListener(this.regionPolygon.getPath(), 'insert_at', () => {
            this.areaLocation.mapAreaRegion.path = JSON.stringify(this.getPath(this.regionPolygon));
        });

        //Add listener when a point is moved
        google.maps.event.addListener(this.regionPolygon.getPath(), 'set_at', () => {
            this.areaLocation.mapAreaRegion.path = JSON.stringify(this.getPath(this.regionPolygon));
        });

        let bounds = new google.maps.LatLngBounds();

        this.regionPolygon.getPaths().forEach(function(path) {
            path.forEach(function(latlng) {
                bounds.extend(latlng);
            });
        });

        this.map.map.fitBounds(bounds);
    }

    public async initMap() {
		if (this.isHideMapsEnabled) {
            return;
        }
		
        await getScript(
            `https://maps.googleapis.com/maps/api/js?key=`
            + `${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
            + `&libraries=drawing,places,geometry`
        ).then(() => {
            this.map = new GoogleMap(
                this.$refs.mappingContainer,
               { lat: 0, lng: 0 }, // start position
                5, // Zoom level
                false,
                this.getMapType,
                getMapStyle(this.getMapType, this.getHideLabels, this.mapLightMode ? 'light' : 'dark'),
            );

            //Display the google maps api zoom control as it doesn't like modals, instead we've got our own
            this.map.map.setOptions({ scrollwheel: false, zoomControl: false });

            this.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: null,
                drawingControl: this.drawRegion,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ['polygon']
                },
                polygonOptions: defaultPolygonOptions
            } as any);

            this.drawingManager.setMap(this.map!.map);

            // on zone add
            google.maps.event.addListener(
                this.drawingManager,
                'polygoncomplete',
                (polygon: google.maps.Polygon) => {
                    this.drawRegion = false;
                    this.regionPolygon = polygon;
                    this.areaLocation.mapAreaRegion.path = JSON.stringify(this.getPath(polygon));

                    //Add listener when a new point is added
                    google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
                        this.areaLocation.mapAreaRegion.path = JSON.stringify(this.getPath(polygon));
                    });

                    //Add listener when a point is moved
                    google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
                        this.areaLocation.mapAreaRegion.path = JSON.stringify(this.getPath(polygon));
                    });
                }
            );
        });
    }

    private setPolygonEditState(editable: boolean) : void {
        if (!this.regionPolygon)
            return;

        this.regionPolygon.setEditable(editable);
    }

    private async mounted(): Promise<void> {
        await this.initMap();
        if (this.value) {
			this.areaLocation = {
				address: this.value.address,
				latLong: this.value.latLong,
				mapAreaRegion: {...this.value.mapAreaRegion}
			}
		}

        if (this.isAreaRegionsEnabled && this.areaLocation.mapAreaRegion) {
			if (!this.areaLocation.mapAreaRegion.borderColor) {
				this.areaLocation.mapAreaRegion.borderColor = "#2196f3";
			}

			if (!this.areaLocation.mapAreaRegion.fillColor) {
				this.areaLocation.mapAreaRegion.fillColor = "#2196f3";
			}

			if (this.areaLocation.mapAreaRegion.path) {
				this.renderRegion();
			}
		}

        this.mapLightMode = true;
        this.isSettingLocationForFirstTime = this.value?.latLong === null || this.value?.latLong === "";
		this.initializeAutocomplete();

		if (this.areaLocation.latLong && this.areaLocation.latLong !== "") {
			const areaLocationLatLng = convertStringToLatLng(this.areaLocation.latLong);
			this.setAreaLocationPosition(areaLocationLatLng);
			this.map.map.setCenter(areaLocationLatLng);
		}
    }

	private get isAreaRegionsEnabled(): boolean {
		return this.getFeature(["Areas", "Regions"]);
	}

    @Watch("areaLocation", { deep: true })
    private onAreaLocationUpdated(): void {
        if (this.hasChanged) {
        	this.$emit("input", this.areaLocation);
		}
    }

    private get isValid(): boolean {
    	return this.areaLocation && this.areaLocation.address && this.areaLocation.latLong && this.isLatLongValid;
	}

    private get hasChanged(): boolean {
    	if (this.areaLocation.latLong !== this.value?.latLong) {
    		return true;
		}

		if (this.areaLocation.address !== this.value?.address) {
			return true;
		}

		if (!isEqual(this.areaLocation.mapAreaRegion, this.value?.mapAreaRegion) &&
			(!isEqual(this.areaLocation.mapAreaRegion, defaultMapAreaRegion) && this.areaLocation.mapAreaRegion.path)) {
			return true;
		}

    	return false;
	}

    @Watch("areaLocation.mapAreaRegion", { deep: true })
    private regionAreaUpdated(newRegion: MapAreaRegion | null, oldRegion: MapAreaRegion | null): void{
    	if (!newRegion) {
    		return;
		}

        if(!oldRegion || !this.drawingManager)
            return;

        let polygonOptions = getPolygonOptionsForRegion(newRegion)

        this.drawingManager.setOptions({
            polygonOptions: polygonOptions
        });

        if(!this.regionPolygon)
            return;

        this.regionPolygon.setOptions({...polygonOptions});
		this.areaLocation.mapAreaRegion = newRegion;
    }

    private removeRegion(): void {
        if(this.regionPolygon){
            this.regionPolygon.setMap(null);
            this.regionPolygon = null;
            this.areaLocation.mapAreaRegion.path = null;
        }
    }

    @Watch("mapLightMode")
    private mapModeWatch(): void {
        if(!this.map || !this.map.map)
            return;

        const mapStyles = getMapStyle(this.getMapType, this.getHideLabels, this.mapLightMode ? "light" : "dark");

        this.map.map.setOptions({
            styles: mapStyles,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });
    }

    private zoom(value: number): void {
        if(!this.map || !this.map.map)
            return;

        let zoom = this.map.map.getZoom() + value;
        //Google maps api min zoom = 0, maxZoom = 22
        if(zoom < 0) zoom = 0;
        if(zoom > 22) zoom = 22;
        this.map.map.setZoom(zoom);
    }

	private initializeAutocomplete(): void {
		setTimeout(() => {
			const targetElement = document.getElementById('address-input') as HTMLInputElement;
			this.autocomplete = new google.maps.places.Autocomplete(targetElement);
			(this.autocomplete as any).setFields(['address_components', 'geometry', 'name']);
			this.autocomplete!.addListener('place_changed', () => {
				let place: PlaceResult = this.autocomplete!.getPlace();
				this.areaLocation.address = targetElement.value;
				if (place.geometry && place.geometry.location) {
					this.latLongOfAddress = `${place.geometry.location.lat()} ${place.geometry.location.lng()}`;
					this.areaLocation.latLong = this.latLongOfAddress;
					this.isLatLongValid = true;
					this.map.map.setCenter(place.geometry.location);
					this.map.map.setZoom(11);
					this.createAreaLocationMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
				}
			})
		}, 0.5 * this.$config.ANIMATION_DURATION);
	}

    public async setLatLongBasedOnAddress(retry: boolean = false): Promise<void> {
		if (this.isHideMapsEnabled) {
            return;
        }

		if (retry && (!this.autocomplete || !this.map.map || !this.autocomplete.getPlace)) {
			setTimeout(() => { this.setLatLongBasedOnAddress(retry) }, 200);
			return;
		}

		let place: PlaceResult = this.autocomplete!.getPlace();
		if (place?.geometry?.location) {
			this.latLongOfAddress = `${place.geometry.location.lat()} ${place.geometry.location.lng()}`;
			const latLng = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
			this.setAreaLocationPosition(latLng);
			this.map.map.setCenter(latLng);
			this.map.map.setZoom(11);
		} else {
			let response = await geoCoder.GeoCodeLookup(this.areaLocation.address, process.env.VUE_APP_GOOGLE_MAPS_API_KEY);
			if (response?.results) {
				let groupLocationDetails = response.results[0];
				if (groupLocationDetails?.geometry?.location) {
					this.latLongOfAddress = `${groupLocationDetails.geometry.location.lat} ${groupLocationDetails.geometry.location.lng}`;
					const latLng = { lat: groupLocationDetails.geometry.location.lat, lng: groupLocationDetails.geometry.location.lng };
					this.setAreaLocationPosition(latLng);
					this.map.map.setCenter(latLng);
					this.map.map.setZoom(11);
				}
				else {
					this.$notify({
						title: "Address location error",
						text: "Could not determine location based on provided address",
						type: "error"
					})
				}
			}
		}
	}

	private setAreaLocationPosition(latLong: GeoCodeLocation) {
		if (!this.locationMarker) {
			this.createAreaLocationMarker(latLong);
		}
		this.map.map.setOptions({ draggableCursor: "" });
		this.locationMarker.setPosition(latLong);
		this.areaLocation.latLong = `${latLong.lat} ${latLong.lng}`;
	}

	private async updateAreaLocation(latLong: string): Promise<void> {
		if (this.isHideMapsEnabled) {
            return;
        }		

		this.latLongOfAddress = latLong;
    	let latLng;
    	try {
			latLng = geoCoder.tryConvertStringToGeoCodeLocation(latLong);
			this.isLatLongValid = true;
		} catch {
    		this.isLatLongValid = false;
    		return;
		}

		this.setAreaLocationPosition(latLng);
		this.map.map.setCenter(latLng);
		this.areaLocation.latLong = `${latLng.lat} ${latLng.lng}`;

		// wait for the b-input value to update.
		await this.$nextTick();
		(document.getElementById('latLong-input') as HTMLInputElement).value = this.latLongOfAddress;
	}


	@Watch("isProvidingLocation")
	private onIsProvidingLocationChanged() {
    	if (!this.isProvidingLocation) {
    		if (this.isLatLongValid) {
				this.areaLocation.latLong = this.latLongOfAddress;
			} else {
    			this.latLongOfAddress = this.areaLocation.latLong;
			}
		}
	}

	@Watch("areaLocation.latLong")
	private onLatLongUpdated() {
    	this.latLongOfAddress = this.areaLocation.latLong;
	}

	private createAreaLocationMarker(position: GeoCodeLocation): void {
		this.locationMarker = new google.maps.Marker({
			position: position,
			map: this.map.map,
			title: 'Area location',
			icon: {
				path: faMapMarker.icon[4] as string,
				fillColor: '#2196F3',
				fillOpacity: 1.0,
				strokeColor: '#000000',
				strokeWeight: 1,
				scale: 0.08,
				labelOrigin: new google.maps.Point(190, 180),
				anchor: new google.maps.Point(200, 500)
			},			
			draggable: !this.readonly,
			label: {
				fontFamily: "'Font Awesome 5 Pro'",
				text: "\uf015",
				fontWeight: '900',
				fontSize: "15pt",
				color: "#ffffff"
			}
		});
		this.locationMarker.addListener("dragend", (event: google.maps.MouseEvent) => {
			this.areaLocation.latLong = `${event.latLng.lat()} ${event.latLng.lng()}`
		});
	}
}
