
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import vSelect from "vue-select";
import { isEmpty } from "lodash";

import NavHeader from "@/components/NavHeader.vue";
import UserRoleSetup from "@/components/setup/users/UserRoleSetup.vue";
import UserGroupSetup from "@/components/setup/users/UserGroupSetup.vue";

const UserManagement = namespace("userManagement");
const AreaStore = namespace("areas");

@Component({
	components: {
		"nav-header": NavHeader,
		"user-role-setup": UserRoleSetup,
		"user-group-setup": UserGroupSetup,
		"vue-select": vSelect
	}
})
export default class PermissionSetup extends Vue {
	$refs!: {
		userGroupSetup: UserGroupSetup;
	}
	@Getter("getPermissions") permissions: any;
	@AreaStore.Action fetchAreaDictionary: () => Promise<void>;
	@AreaStore.State areaDictionary: Map<number, string>;
	@UserManagement.Action fetchPermissionsState: () => Promise<void>;

	public async mounted() {
		// Check permissions and go back if user has no access.
		if (!this.permissions.isSystemAdmin && !this.permissions.canEditUserGroups) {
			this.$router.go(-1);
			return;
		}

		// Fetch dependant state information for child components.
		await this.fetchPermissionsState();
		if (isEmpty(this.areaDictionary)) {
			this.fetchAreaDictionary();
		}
	}

	private triggerGroupSetupRefresh(): void {
		this.$refs.userGroupSetup.loadState();
	}
}
