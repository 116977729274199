enum EventRecordTypes {
	Alarm=1,
	PatrolRaised=2,
	ProcessingEvent=3,
	CameraEvent=4,
	AutoPatrolEvent=5,
	MultiViewEvent=6,
	RelayEvent=7,
	AudioEvent=8,
	MultiViewStopped=9,
	Relay=10,
	UserEvent=11,
	CompletionEvent=12,
	IncidentText=13,
	DataChanged=14,
	ArmDisarmEvent=15,
	ProcessingStartedEvent=16,
	SupplementaryData=17,
	DispatchEvent=18,
	UserLogin=19,
	UserLogout=20,
	AlarmHandlingEvent=21,
	ScriptEvent=22,
	AttachedFile=23,
	Outcome=24,
	EdgeDeviceLaunched=25,
	ServerArmDisarm=26,
	InputArmDisarmEvent=27,
	EventTimeOut=28,
	Bookmark=29,
	AlarmRestored=30,
	EventPreserved=31,
	EventHidden=32,
	MapViewed=33,
	RaisedToRoutingGroup=34,
	SitePasswordChecked=35,
	ProblemEvent=36,
	NoteEvent=37,
	PlaybackReviewEvent=38,
	RemoteDataSync=39,
	SetupEvent=40,
	NotificationEvent=41,
	FailsafeThresholdEvent=42,
	AlarmVideoViewed=43,
	AlarmMapViewed=44,
	UserUpdate=45,
	ReportGenerated=46,
	ClickToDialEvent=47,
	InvestigatorDispatched=48,
	InvestigatorArrived=49,
	InvestigatorLeaving=50,
	RequestControl=51,
	ParkEvent=52,
	CCureLogMessage=53,
	CCureResetAction=54,
	CCureResetAllAction=55,
	TaskAdded=100,
	TaskCompleted=101,
	TaskAssigned=102,
	OperatorNote=103,
	EventShare=104,
	ForceRestore=105,
	AcknowledgeAll=106,
	ActivityLog=107,
	ReviewedFlaggedEvent=108,
	FlaggingEventforReview=109,
	Choices=110,
	Callcontact=111,
	GuardNote=112,
	InvestigatorEnRoute=113,
	ViewAdded=114,
	ViewUpdated=115,
	ViewDeleted=116,
	ViewCellAdded=117,
	ViewCellUpdated=118,
	ViewCellDeleted=119,
	ViewPageAdded=120,
	ViewPageUpdated=121,
	ViewPageDeleted=122,
	ViewOpened=123,
	ViewDuplicated=124,
	Tours=125,
	ViewSession=126,
	VideoReport=127,
	VideoReportCenterDevice=128,
	VideoReportCameraStateSuccess=129,
	VideoReportCameraStatePartial=130,
	VideoReportCameraStateNoClip=131,
	VideoReportCameraStateError=132,
	DownloadedMedia=133,
	HighPriorityAlarmSwitch=134,
	HighPriorityAlarmIgnore=135,
	ThirdPartyTaskLinked=136,
	ThirdPartyTaskUnlinked=137,
	SetUserGroupsToView=138,
	GuardTourWaypoint=139,
	CaseCreated=140,
	OperatorMessage=141,
	GuardMessage=142,
	ChatMobileUserMessage = 143,
	ChatOperatorMessage = 144,
	PeekStarted = 156,
	PeekEnded = 157
}

export default EventRecordTypes;
