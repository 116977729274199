
import { Component, Vue } from "vue-property-decorator";
import ReportBuilder from "../components/reports/ReportBuilder.vue";
import { UserPermissions, FeaturesList } from '@/store/types';
import { Getter } from 'vuex-class';
import NavHeader from '@/components/NavHeader.vue';
import { get } from "lodash";

@Component({
  components: {
    ReportBuilder,
    'nav-header': NavHeader,
  }
})
export default class Reports extends Vue {
  @Getter getPermissions: UserPermissions;
  @Getter("getFeaturesList") featuresList: FeaturesList;

  public get insightsEnabled(){
    return get(this.featuresList, ["Insights"]);
  }
}
