
import { Component, Vue, Watch } from "vue-property-decorator";
import api from "@/services/api.service";
import { passwordValidationMixins } from "@/mixins";

const { getPasswordStrength } = passwordValidationMixins.methods;

@Component
export default class ResetPassword extends Vue {
	private isLoading: boolean = false;
	private wasSuccessful: boolean = false;
	private email: string = "";
	private isEmailValid: boolean = false;

 	private async submit() {
 		try {
			this.isLoading = true;
			await api.resetPassword(this.email);
			this.wasSuccessful = true;
			this.isLoading = false;
		}
		catch {
			this.isLoading = false;
		}
	}

	@Watch("email")
	private validateEmail() {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.isEmailValid = re.test(String(this.email).toLowerCase());
	}
}
