
import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { get, invert } from "lodash";

import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { FeaturesList, PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
import api from "@/services/api.service";
import CameraSelect from "@/components/form/CameraSelect.vue";
import { truncateString } from "@/filters";

const Areas = namespace("areas");
const CustomFields = namespace("customFields");

const { mapArrayFieldsToObject, mapObjectFieldsToArray } = customFieldMixins.methods;

import { ModalItemCSV } from '@/store/csv-form-handler/types'
import csvUploaderWrapper from "@/components/csv-uploader/csv-uploader-wrapper.vue";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import _ from 'lodash';
import PaginatedSearch from "@/mixins/PaginatedSearch";
import ResponsesPagedResponse from "@/types/sv-data/responses/ResponsesPagedResponse";
import { Response, AlarmGrouping, YesNoOption, Script } from "@/store/responses/types";
import ResponseActionSetup from "./ResponseActionSetup.vue";
import { ResponseAction } from "@/store/response-actions/types";
import AutomationMixin from "@/mixins/AutomationMixin";
import CustomFieldTableView from "@/components/custom-fields/CustomFieldModalTableView.vue";
import { CustomFieldDto, CustomFieldTableTypes } from "@/store/custom-fields/types";
import { MapCustomFieldToModalItem } from "@/utils/CustomFieldLogic";
import { customFieldMixins } from "@/mixins/customFieldMixins";

interface ResponseRow extends Response {
	readOnly?: boolean;
	readOnlyMessage?: string;
	automations?: ResponseAction[];
}

@Component({
    components: {
		"generic-table": GenericTable,
		"csv-uploader-wrapper": csvUploaderWrapper,
		"custom-field-modal-table-view": CustomFieldTableView
	},
	filters: {
        truncateString
    }
})
export default class AlarmSetupTable extends Mixins(PaginatedSearch, AutomationMixin) {
	$refs!: {
		customFieldTableView: CustomFieldTableView;
	}

	@Prop({ required: false, default: null }) private selectedServerId: number;
	@Prop({ required: false, default: null }) private isEmailAlarms: boolean;
	@Prop({ required: false, default: false }) private readonly: boolean;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Areas.Action fetchAreaDictionary: () => Promise<void>;
	@Areas.Getter getAreaTitle: (id: number) => string;
	@Areas.State areaDictionary: Map<number, string> [];

	@CustomFields.State private alarmCustomFields: CustomFieldDto[];
	@CustomFields.Action retrieveCustomFields: ({ tableType, live }) => Promise<void>;

	@Getter("getPermissions") private permissions: UserPermissions;

	private get hasAlarmSetupEditAreaFlag() {
		return get(this.featuresList, ["AlarmSetup", "EditArea"])
	}

	private get hasMaskDurationLimit(): boolean {
		return get(this.featuresList, ["Alarms", "Masking", "MaxMaskDuration"], false);
	}

	public get hasCanViewCustomFieldsPermission(): boolean {
		return this.permissions.canViewCustomFields;
	}

	private totalRecords: number = 0;
	private isLoading: boolean = false;

	private responses: any[] = [];
	private responseScripts: Script[] = [];
	private progressBulk = {}
	private alarmDecoderServers: any [] = [];
	private selectedResponse: any = {};
	private tooltipToShow: string = "";

	private readonly customFieldTableType: number = CustomFieldTableTypes.Alarm;

	/**
	*	@returns Table columns
	*/
	public get columns (): TableHeader[] {
		const columns: TableHeader[] = [
			{
				title: "Alarm Name",
				key: "title",
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Alarm name",
				searchable: true,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
            {
                title: "Area",
                key: "groupTitle",
                order: 2,
                sortOrder: 1,
                sortOrderReversed: false,
                description: "The area the alarm belongs to",
                searchable: true,
                visible: true,
                dataType: "input",
				sortable: true,
				sortKey: "Area",
            },
            {
                title :"Action Plan",
                key:"scriptTitle",
                order: 3,
                sortOrder: 3,
                sortOrderReversed: false,
                visible: true,
                searchable: true,
				sortable: true,
				sortKey: "ActionPlan"
            },
            {
                title : this.isEmailAlarms ? "Email Address" : "ExtraValue",
                key:"extraValue",
                order: 4,
                sortOrder: 4,
                sortOrderReversed: false,
                visible: true,
                searchable: true,
                addQuickCopy: this.isEmailAlarms ? true : false,
				useCustomCell: this.isEmailAlarms ? true : false,
				sortable: true,
            },
            {
                title: "Response Code",
                key: "responseCode",
                order: 5,
                sortOrder: 5,
                sortOrderReversed: false,
                description: "The Response code of the alarm",
                searchable: true,
                visible: false,
                dataType: "input",
				sortable: true,
            },
			{
				title: "Priority",
				key: "priority",
				order: 6,
				sortOrder: 6,
				sortOrderReversed: false,
				description: "priority of the alarm",
				searchable: true,
				visible: true,
				dataType: "input",
				sortable: true,
			},
            {
                title :"Grouping",
                key:"grouping",
                order: 7,
                sortOrder: 7,
                sortOrderReversed: false,
                visible: true,
                searchable: true,
                useCustomCell: true,
				sortable: true,
            }
		];

		if(!get(this.featuresList, ["Alarms", "CameraAutomation"])){
			columns.push(
				{
					title: "Linked Cameras",
					key: "linkedCameras",
					order: 8,
					sortOrder: 8,
					sortOrderReversed: false,
					dataType: "list",
					searchable: true,
					useCustomCell: true,
					visible: !get(this.featuresList, ["Alarms", "CameraAutomation"]),
					disable: !get(this.featuresList, ["Devices"]),
				}
			);
		}

		if(this.showAutomations){
			columns.push(
				{
                title: "Automations",
                key: "automations",
                order: 9,
                sortOrder: 9,
                sortOrderReversed: false,
                dataType: "list",
                searchable: true,
                useCustomCell: true,
				visible: this.showAutomations,
				disable: !get(this.featuresList, ["Alarms", "CameraAutomation"]),
            });
		}

		if(this.hasMaskDurationLimit){
			columns.push(
				{
					title: "Max Mask Duration (minutes)",
					key: "maskDurationLimit",
					order: 10,
					sortOrder: 10,
					sortOrderReversed: false,
					searchable: false,
					visible: this.hasMaskDurationLimit,
					dataType: "number",
					sortable: false
				});
		}

		return columns;
	}

	/**
	*	@returns Model items list
	*/
	public get modalItems (): ModalItemCSV[] {
		var items: ModalItemCSV[] = [
			{
				title: "Alarm name",
				key: "title",
				dataType: "text",
				maxLength: 250,
				required: true,
                placeholder: "Enter an alarm name",
				csvComponent: 'input',
				csvValidation: {
					validationCell({ currentElement }) {
						return { isValid: !!currentElement.csvValue }
					}
				},
				order: 1
            },
            {
                title: "Area",
                key: "groupID",
                dataType: "component",
                newOnly: !this.hasAlarmSetupEditAreaFlag,
                required: true,
                data: AreaTreeSelect,
				props: {
                	reduce: (a) => a.id,
					clearable: true
				},
				csvComponent: 'select',
				csvData: Object.keys(this.areasByTitle),
				csvValidation: {
					validationCell: ({ currentElement }) => {
						return {
							isValid: !!this.areasByTitle[currentElement.csvValue]
						}
					},
				},
				order: 2
            },
            {
                title: "Linked cameras",
                key: "linkedCameras",
                dataType: "component",
                required: false,
                visible: this.showLinkedCamerasInModal,
                data: CameraSelect,
                props: {
                    multiple: true
                },
				csvExclude: true,
				order: 3
            },
            {
                title: "Action Plan",
                key: "script",
                dataType: "vselect3",
                data: this.responseScripts,
                required: false,
                placeholder: "Select an action plan",
				csvComponent: 'select',
				csvData: this.responseScripts.map(v => v.title),
				csvValidation: {
					validationCell: ({ currentElement }) => {
						if (this.responseScripts.length === 0 || !currentElement.csvValue) {
							return { isValid: !currentElement.csvValue }
						}

						return { isValid: this.responseScripts.some(({ title }) => title === currentElement.csvValue) }
					}
				},
				order: 4
            },
			{
				title: "Email Address",
				key: "extraValue",
				dataType: "text",
				readOnly: true,
				updateOnly: true,
				csvExclude: true,
				order: 5
            },
			{
                title: "Alarm Decoder",
                key: "server",
                dataType: "vselect3",
                data: this.alarmDecoderServers,
				readOnlyIfEdit: true,
				required: false,
                placeholder: "Select an alarm decoder",
				csvData: this.alarmDecoderServers.map(x => x.title),
                csvComponent: 'select',
				csvValidation: {
					validationCell: ({ currentElement }) => {
						if (!currentElement.csvValue) {
							return { isValid: true }
						}

						if(this.alarmDecoderServers.length === 0) {
							return { isValid: !currentElement.csvValue }
						}

						return { isValid: this.alarmDecoderServers.some(({ title }) => title === currentElement.csvValue) }
					}
				},
				order: 6
            },
			{
                title: "Response Code",
                key: "responseCode",
                dataType: "text",
				maxLength: 100,
				visibleMethod: (value) => { return value?.server == null ? false : true },
				requiredMethod: (value) => { return value?.server == null ? false : true },
                placeholder: "Enter a response code",
				csvComponent: 'input',
				order: 7
            },
            {
                title: "Priority",
                key: "priority",
                dataType: "number",
                defaultValue: null,
                min: 0,
                max: 2147483647,
                required: false,
                placeholder: "Enter the alarm priority",
				csvComponent: 'number',
				csvValidation: {
					validationCell({ currentElement }) {
						return {
							isValid: currentElement.csvValue
								? Number(currentElement.csvValue) <= 2147483647 && Number(currentElement.csvValue) >= 0
								: true
						}
					}
				},
				order: 8
            },
            {
                title: "Grouping",
                key: "grouping",
                dataType: "vselect3",
                data: this.alarmGroupingOptions,
                required: true,
                placeholder: "Select an alarm grouping",
				csvData: this.alarmGroupingOptions.map(v => v.title),
				csvComponent: 'select',
				csvValidation: {
					validationCell: ({ currentElement }) => {
						return { isValid: this.alarmGroupingOptions.some(({ title }) => title === currentElement.csvValue) }
					}
				},
				order: 9
            },
			{
                title: "Auto Handle",
                key: "autoHandle",
                dataType: "vselect3",
                data: this.yesNoNotSet,
                required: true,
                placeholder: "Select an auto handle setting",
				props:
				{
					reduce: (ah: YesNoOption) => ah.value
				},
                csvData: this.yesNoNotSet.map(v => v.title),
                csvComponent: 'select',
                csvValidation: {
                    validationCell: ({ currentElement }) => {
                        return { isValid: this.yesNoNotSet.some(({ title }) => title === currentElement.csvValue) }
                    }
                },
				order: 10
            },
		] as ModalItemCSV[];

		if(this.showAutomations){
			items.push(
			{
				title: "Automations",
				key: "automations",
				dataType: "component",
				required: false,
				visible: this.showAutomations,
				data: ResponseActionSetup,
				csvExclude: true,
				updateOnly: true,
				props:
				{
					groupID: this.selectedResponse.groupID
				},
				order: items.length + 1,
			});
		}

		if(this.hasMaskDurationLimit)
		{
			items.push({
				title: "Max Mask Duration (minutes)",
				key: "maskDurationLimit",
				dataType: "number",
				maxLength: 10,
				min: 0,
				csvComponent: 'input',
				visible: this.hasMaskDurationLimit,
				order: items.length + 1,
			});
		}

		if (this.isCustomFieldAlarmsEnabled && this.alarmCustomFields != null){
			var shellModalItem: ModalItemCSV = {
				title: "",
				key: "",
				dataType: "",
				readOnly: false,
				order: items.length + 1,
				csvComponent: null
			};

			for (let i = 0; i < this.alarmCustomFields.length; i++) {
				let alarmCustomFieldModalItem = MapCustomFieldToModalItem(this.alarmCustomFields[i], shellModalItem);
				items.push(alarmCustomFieldModalItem as ModalItemCSV);
			}
		}

		return items;
	}

    private alarmGroupingOptions: AlarmGrouping[] = [
        {
            title: "By Area",
            isRaiseIndividual: false,
            isRaiseGrouped: false
        },
        {
            title: "By Alarm",
            isRaiseIndividual: false,
            isRaiseGrouped: true
        },
        {
            title: "Not Grouped",
            isRaiseIndividual: true,
            isRaiseGrouped: false
        },
        {
            title: "Not Set",
            isRaiseIndividual: null,
            isRaiseGrouped: null
        }
    ];

	private yesNoNotSet: YesNoOption[] = [
        {
            title: "Yes",
            value: 1,
			actualValue: true
        },
        {
            title: "No",
            value: 2,
			actualValue: false
        },
        {
            title: "Not set",
            value: 3,
			actualValue: null
        }
    ];

	private get isBulkAddEnabled(): boolean {
		return get(this.featuresList, ["AlarmSetup", "BulkAdd"]) && !this.readonly;
	}

	private get showAutomations(): boolean {
		return !!get(this.featuresList, ["Alarms", "CameraAutomation"]) &&
		(!!this.permissions.canViewAutomations || !!this.permissions.canEditAutomations);
	}

	private get showLinkedCamerasInModal(): boolean {
		return !!get(this.featuresList, ["Devices"]) && !get(this.featuresList, ["Alarms", "CameraAutomation"]);
	}

	private get isCustomFieldAlarmsEnabled(): boolean {
		return get(this.featuresList, ["CustomFields", "Alarms"]);
	}

	private async retrieveAlarmDecoderServers(): Promise<void> {
		var alarmDecoderServers = await api.getAlarmDecoderServerList();
		this.alarmDecoderServers = [];

		if (!alarmDecoderServers || alarmDecoderServers.length == 0)
		{
			return;
		}

		for (const [key, value] of Object.entries(alarmDecoderServers)) {
			this.alarmDecoderServers.push({
				title: value,
				serverID: key
			})
		}
	}

	private async mounted(): Promise<void> {
		this.responseScripts = await api.getResponseScripts();

		if (this.isCustomFieldAlarmsEnabled) {
			await this.retrieveCustomFields({ tableType: CustomFieldTableTypes.Alarm, live: true });
		}

		await this.updateResponses();
		await this.fetchAreaDictionary();
	}

    private async putAlarm(response: any) {
        if (response.script) {
            response.scriptID = response.script.scriptID;
            response.scriptTitle = response.script.title;
        } else {
            response.scriptID = null;
            response.scriptTitle = null;
        }

		//Converts bulk upload autohandle setting to actual setting
		if (typeof(response.autoHandle) === "string")
		{
			response.autoHandle = this.yesNoNotSet.find(yn => yn.title == response.autoHandle)?.value
		}

		//Converts the autohandle to its actual value
		if (typeof(response.autoHandle) === "number")
		{
			response.autoHandle = this.yesNoNotSet.find(yn => yn.value == response.autoHandle)?.actualValue
		}

        response.isRaiseIndividual = response.grouping.isRaiseIndividual;
        response.isRaiseGrouped = response.grouping.isRaiseGrouped;

		if (response.server)
		{
			var serverId = response.server?.serverID;
			if (typeof response.server === 'string' || response.server instanceof String)
			{
				serverId = this.alarmDecoderServers.firstOrDefault(a => a.title == response.server)?.serverID;
			}
			response.serverID = serverId;
			// if its a new alarm and the serverID isn't set clear out the response code
			if (!response.serverID && !response.responseID)
			{
				response.responseCode = null;
			}
		}

		if (this.isCustomFieldAlarmsEnabled) {
			response.customFields = mapObjectFieldsToArray(response, "id", "value", "cf");
			console.log("Custom Field ", response);
		} else {
			response.customFields = null;
		}

        if (!response.responseID) {
            try {

                api.createNewResponse(response);
            }
            catch (error) {
                this.$notify({
                    type: "error",
                    title: "Failed to create alarm",
                    text: error.response.data
                });
            }
        } else {
            await api.updateResponse(response);
			await this.updateAutomations(response.responseID, response.automations ?? [], this.selectedResponse.automations ?? []);

        }
        await this.updateResponses(this.mostRecentSearchParams);
		this.$emit("triggerUpdate");
	}

	private async updateAutomations(responseId: number, newAutomations: ResponseAction[], oldAutomations:  ResponseAction[]): Promise<void> {
		// Cant use foreach loop as it does not play nicely with async calls
		// Create/Update responseActions

		if(newAutomations !== null && typeof(newAutomations) !== "string")
		{
			for(let i = 0; i < newAutomations.length; i++ ) {
				try
				{
					var newAutomation = newAutomations[i];
                    newAutomation.description = null;

					// If we have no response its a new automation
					if(!newAutomation.responseActionID)
					{
						newAutomation.responseID = responseId;

						await api.createAlarmAutomation(responseId, newAutomation);
					}
					else{

						// Check to see if changees have been made to an item before we try and hit the backend
						// Shallow compare
						var oldAutomation = oldAutomations.find(r => r.responseActionID == newAutomations[i].responseActionID)

						var updated = newAutomation.objectID != oldAutomation.objectID
									|| newAutomation.responseActionTypeID != oldAutomation.responseActionTypeID
									|| newAutomation.settingsMeta != oldAutomation.settingsMeta
						if(updated)
						{
							await api.updateAlarmAutomation(responseId, newAutomation);
						}
					}


				}
				catch (error) {
					this.$notify({
					type: "error",
					title: "Failed to create/update automation",
					text: error.response.data
					});
				}
			}
		}
		// Delete responseActions that no longer exist
		if(oldAutomations  && oldAutomations.length > 0)
		{
			for(let i = 0; i < oldAutomations.length ; i++)
			{
				if(!newAutomations.find(r => r.responseActionID == oldAutomations[i].responseActionID))
				{
					try {
                        await api.deleteAlarmAutomation(this.selectedResponse.responseID, oldAutomations[i].responseActionID)
                    }
                    catch (error) {
                        this.$notify({
                            type: "error",
                            title: "Failed to delete automation",
                            text: error.response.data
					    });
				    }
				}
			}
		}
	}

	private onModalOpen(response): void {
	    if (response) {
            response.script = this.responseScripts.find(rs => rs.scriptID === response.scriptID);
			this.selectedResponse = response;
        }

		if (this.isCustomFieldAlarmsEnabled) {
			mapArrayFieldsToObject(this.selectedResponse, this.selectedResponse.customFields, this.alarmCustomFields,"id", "value", "cf");
		}
    }

    private async deleteAlarm(response: any): Promise<void> {
        if (response && response.responseID) {
            await api.deleteResponse(response.responseID);
            await this.updateResponses(this.mostRecentSearchParams);
			this.$emit("triggerUpdate");
        }
	}

	public async updateResponses(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		try
		{
			this.isLoading = true;
			this.mostRecentSearchParams = paginatedSearchQueryParams;
			await this.retrieveAlarmDecoderServers();
			const cancellableQuery = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);

			if (this.selectedServerId) {
				cancellableQuery.params.serverId = this.selectedServerId;
			}
			cancellableQuery.params.isEmailAlarms = this.isEmailAlarms;

			let result: ResponsesPagedResponse = await api.getResponses(cancellableQuery);
			var responseData: ResponseRow[] = result.data;

			if (responseData) {
				responseData = responseData.map(response => {
					var isReadOnly = !!response.groupSyncId && !this.permissions.canOverrideGroupSync && !this.permissions.isSystemAdmin;
					response.readOnly = isReadOnly;
					response.readOnlyMessage = isReadOnly ? "You do not have permission to edit Sync Alarms": null;
					return response;
				})
			}

			this.responses = responseData;
            if (this.responses)
            {
                for(var i = 0; i < this.responses.length; i++)
				{
					var response = this.responses[i];
					response.script = this.responseScripts.find(rs => rs.scriptID === response.scriptID);
					response.autoHandle = this.yesNoNotSet.find(yn => yn.actualValue == response.autoHandle)?.value
					response.grouping = this.alarmGroupingOptions.find(ag =>
						ag.isRaiseGrouped == response.isRaiseGrouped &&
						ag.isRaiseIndividual == response.isRaiseIndividual);

					const server = this.alarmDecoderServers ? this.alarmDecoderServers.find(ad => ad.serverID == response.serverID) : [];
					if(server)
					{
						response.server = {
							title: server ? server.title : null,
							serverID: response.serverID
						}
					} else {
						response.server = null;
					}
					response.showOriginalEmail = false;
					Vue.set(response, "server", response.server);

					if (this.showAutomations) {
						response.automations = await api.getAlarmAutomations(response.responseID);
					}
				}
			}
			this.totalRecords = result.totalRecords;
		}
		catch (ex) {
			console.log("Unexpected error searching alarms: " + ex);
		} finally {
			this.isLoading = false;
		}
    }

	private convertDataToRest(formData) {
		return formData.map(value => {
			return {
				...value,
				groupID: this.areasByTitle[`${value.groupID}`],
				priority: Number(value.priority),
				grouping: this.alarmGroupingOptions.find(({ title }) => title === value.grouping),
				script: this.responseScripts.find(({ title }) => title === value.script)
			}
		})
	}

	private async bulkOnSubmit({ formData }): Promise<void> {
		const max = formData.length;
		let counterSucceed = 0;
		let progress: any = {
			max,
			value: 0,
			status: 'init',
			successfulMessage: `Successfully added ${max} Alarms`,
		};

		this.progressBulk = {
			...progress
		};

		const convertedData = this.convertDataToRest(formData);
		for (var i = 0; i < convertedData.length; i++)
		{
			const alarm = convertedData[i];
			const id = alarm.rowId;
			try
			{
				await this.putAlarm(alarm);
				progress.rowIndex = id;
				counterSucceed += 1;
				progress.value = progress.value + 1;

				this.progressBulk = {
					...progress,
					status: counterSucceed === max ? 'succeed' : 'progress',
				};
			}
			catch(err)
			{
				const failedContact = convertedData.find(({ rowId }) => rowId === id);
				this.progressBulk = {
					...progress,
					rowIndex: id,
					status: 'error',
					error: {
						name: failedContact.title,
						message: `Bulk upload has failed to add alarm ${failedContact.title}`
					}
				};
			}
		}
	}

	private onCloseBulk(): void {
		this.resetProgress()
	}

	private resetProgress(): void {
		this.progressBulk = {}
	}

	private get defaultFile(): { name: string, data: string } {
		const modalItems = this.modalItems
		const headers = modalItems.filter(({ csvExclude }) => !csvExclude).map(({ title }) => title)
		const body = headers.map( v => '')
		return {
			name: 'AlarmTemplate.csv',
			data: `${headers.join()}\r\n${body.join()}`
		}
	}

	private get areasByTitle() {
		return invert(this.areaDictionary);
	}

	private copyAlarmEmail(responseID: number): void{
		var idToCopy = `copy-to-clipboard-target-${responseID}`;
		let node = document.getElementById(idToCopy);
        let range = document.createRange();
        range.selectNodeContents(node);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");

		this.tooltipToShow =`copy-to-clipboard-tooltip-${responseID}`;
        setTimeout(() => {
            this.tooltipToShow = "";
        } , 2000);
	}

	private async openCustomFieldModal(): Promise<void> {
		this.$refs.customFieldTableView.showCustomFieldModal();
	}
}
