class MarkerTypeLine {
	public color: string = "";
	public opacity: number = 1;
	public weight: number = 1;
}

class MarkerTypeFill {
	public color: string = "";
	public opacity: number = 1;
}

class MarkerState {
	public imageUrl: string = "";
	public line: MarkerTypeLine | null = null;
	public fill: MarkerTypeFill | null = null;
	public hidden: boolean = false;
	public opacity: number = 1;
}

class MarkerStateMap {
	[s: string]: MarkerState;
}

class MarkerType {
	public defaultState: MarkerState;
	public states: MarkerStateMap | null;
	constructor(defaultState: MarkerState, states: MarkerStateMap | null) {
		this.defaultState = defaultState;
		this.states = states;
	}

	public stateGet(state: string): MarkerState {
		if (this.states == null || this.states[state] == null) {
			return this.defaultState;
		}

		return this.states[state];
	}
}

class CachedMarkerIcon{
	isAsset: boolean | false;
	isSelect: boolean | false;
	iconColor: string | null;
	hasActiveShare: boolean | false;
	iconUrl: string | null;
}

export {
	MarkerState,
	MarkerStateMap,
	MarkerTypeLine,
	MarkerTypeFill,
	MarkerType,
	CachedMarkerIcon
};
