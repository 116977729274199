
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import RegionalHandlerMixin from "../mixins/RegionalHandlerMixin";
import { FilteredEvent } from "@/store/eventqueue/types";
import EventQueueAreaInfoIcon from "@/components/event-queue/EventQueueAreaInfoIcon.vue";
import { get } from "lodash";

const Eventqueue = namespace("eventqueue");
const Subscription = namespace("subscription");

@Component({
	components: {
		"event-queue-area-info-icon": EventQueueAreaInfoIcon
	}
})
export default class ProcessingQueue extends Mixins(RegionalHandlerMixin) {

	@Eventqueue.Getter getActiveEvents!: FilteredEvent[];
	@Eventqueue.Action loadEventHandleAllowInProcessing: any;
	@Eventqueue.Mutation setCountByType: any;
	@Eventqueue.Mutation setHoverEvent: any;
	@Eventqueue.Mutation setPeekEventId: (eventId: number | null) => void;
	@Subscription.Getter isSubscriptionActive: boolean;

	private peekEvent(eventId: number): void {
		this.setPeekEventId(eventId);
	}

	public get events(): FilteredEvent[] {
		if (this.getActiveEvents == null) {
			return null;
		}

		let filteredEvents = this.getActiveEvents.filter((q: any) => q.eventTypeID == 1 && q.inProcessing == 1);
		this.setCountByType({
			countType: "inProcess",
			count: filteredEvents.length
		});

		return filteredEvents;
	}

	public hoverEvent(event: FilteredEvent) {
		this.setHoverEvent(event);
	}

	public unhoverEvent(event: FilteredEvent) {
		this.setHoverEvent({});
	}

	public get noEvents() {
		return typeof this.events == "undefined" || this.events == null || this.events.length == 0;
	}

	private get isEventPeekEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventQueue", "Peek"], false);
	}
}
