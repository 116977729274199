
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";

import BufferSeek from "./media/BufferSeek.vue";
import AuditVideo from "./AuditVideo.vue";
import { MediaFile, AuditTimeStamp, AuditService } from "@/services/auditService";

const SessionStore = namespace("sessions");

@Component({
	components: {
		"audit-video": AuditVideo,
		"buffer-seek": BufferSeek
	}
})
export default class AuditControl extends Vue {
	$refs!: {
		video: AuditVideo;
	};

	@SessionStore.Getter private getSession: (resourceId: number) => string;
	@Getter private getFeature: (featureName: string[]) => boolean;

	public currentFile: MediaFile | null = null;

	private auditService: AuditService | null = null;
	private eventRecordID: number | null = null;
	private eventID: number | null = null;

	private playing: boolean = false;
	private ended: boolean = false;
	private playBackSpeed: number = 1.0;

	private duration: AuditTimeStamp = {
		time: 0,
		minutes: 0,
		seconds: 0,
		totalSeconds: 0
	};

	private timestamp: AuditTimeStamp = {
		time: 0,
		minutes: 0,
		seconds: 0,
		totalSeconds: 0
	};

	private dataSize: number = 0;
	private appendLock: boolean = false;
	private appendTimeout: NodeJS.Timeout = null;
	private moreDataAvailable: boolean = false;
	private latestClipLength: number | null = null;

	private isMuted: boolean = true;
	private mediaHasAudio: boolean = false;
	private mediaHasVideo: boolean = false;

	/**
	 * Stops current playback, stops fetching data, resets the control and begins playing a new clip.
	 */
	public async playClip(auditService: AuditService, eventRecordID: number, clip: any, eventID: number = -1) {
		this.stop();

		this.auditService = auditService;
		this.eventRecordID = eventRecordID;
		this.eventID = eventID;
		this.currentFile = clip;
		this.ended = false;
		this.playBackSpeed = 1.0;

		await this.$nextTick();

		this.playing = true;
		this.$refs.video.open();
		this.appendVideo();
	}

	/**
	 * Clears our append timeout, and resets the state of the video control.
	 */
	public stop() {
		clearTimeout(this.appendTimeout);

		if (this.$refs.video) {
			this.$refs.video.close();
		}

		this.moreDataAvailable = true;
		this.dataSize = 0;
		this.appendLock = false;
		this.latestClipLength = null;
		this.ended = true;
		this.playing = false;

		this.$set(this, "duration", {
			time: 0,
			minutes: 0,
			seconds: 0,
			totalSeconds: 0
		});

		this.$set(this, "timestamp", {
			time: 0,
			minutes: 0,
			seconds: 0,
			totalSeconds: 0
		});
	}

	/**
	 * Attempts to fetch new video data from the audit service, and if any is returned, streams it to our
	 * video player.
	 */
	private async appendVideo() {
		if (this.ended || this.appendLock || !this.auditService) {
			return;
		}

		this.appendLock = true;

		// Stop any further video data requests until this one is finished.
		clearTimeout(this.appendTimeout);

		// Get our audit service url.
		const auth = this.getSession(2);
		const mediaUrl = this.auditService.mediaUrl(
			auth,
			this.eventRecordID,
			this.currentFile!.FileIdentifier,
		);

		try {
			// Fetch the next packet of video data from the audit service.
			const { headers, data } = await this.auditService.auditDataGet(mediaUrl, this.dataSize);
			const contentRange: string = headers["content-range"];

			if (contentRange) {
				// If audit returned a content range, extract stream sizes.
				const [rangeStr, maxSizeStr] = contentRange.split("/"); // x-y/z -> [x-y, z]
				const [rangeMinStr, rangeMaxStr] = rangeStr.split("-"); // x-y -> [x, y]

				// size = the end offset of this data packet range.
				const size = parseInt(rangeMaxStr);

				// maxSize = the maximum size of the video stream.
				const maxSize = parseInt(maxSizeStr);

				// Update our latest clip length for the seek bar.
				this.latestClipLength = maxSize;

				if (data && data.byteLength > 0) {
					// If we've got data, add it to the video.
					this.dataSize = size;
					this.$refs.video.addVideoData(data);
					this.moreDataAvailable = this.latestClipLength > size;
				} else {
					this.$refs.video.processRemainingData();
					this.moreDataAvailable = false;
				}
			}
		} catch (err) {
			console.log(`AuditControl - Error fetching audit data: ${err}`);
			this.moreDataAvailable = false;
		}

		// Setup next timeout to fetch video data.
		this.appendTimeout = setTimeout(
			() => {
				this.appendLock = false;
				this.appendVideo();
			},
			this.moreDataAvailable ? 100 : 2000
		);
	}

	/**
	 * Handler for when the video player raises an ended event.
	 */
	private onVideoEnded() {
		try {
			if (this.playing) {
				if (this.moreDataAvailable) {
					// Wait - we don't have the full clip yet ...
				} else {
					this.$refs.video.seek(0);
					this.$refs.video.play();
				}
			}
		} catch (err) {
			console.log(`AuditControl - Error: ${err}`);
		}
	}

	/**
	 * Handler for when the audit video component throws an error. If required, we'll
	 * attempt to restart the stream.
	 */
	private onVideoError({ restart }: { restart: boolean }) {
		clearTimeout(this.appendTimeout);

		if (restart) {
			setTimeout(() => {
				this.stop();
				this.playClip(this.auditService, this.eventRecordID, this.currentFile, this.eventID);
			}, 5000);
		}
	}

	/**
	 * Handler for when the current clip duration is updated.
	 */
	private onClipDurationChanged(duration: AuditTimeStamp) {
		this.$set(this, "duration", duration);
	}

	/**
	 * Handler for when the current clip time is updated.
	 */
	private onClipTimeChanged(timestamp: AuditTimeStamp) {
		this.$set(this, "timestamp", timestamp);
	}

	/**
	 * Handler for when the user updates the video seek bar.
	 */
	private onSliderSeek(seekTo: number) {
		this.$refs.video.seek(seekTo);
	}

	/**
	 * Toggles playback of the video control.
	 */
	private togglePlaying() {
		if (this.playing) {
			this.playing = false;
			this.$refs.video.pause();
		} else {
			this.playing = true;
			this.$refs.video.play();
		}
	}

	/**
	 * Increases playback speed of the video control.
	 */
	private speedUp() {
		if (this.playBackSpeed + 0.5 <= 5.0) {
			this.playBackSpeed += 0.5;
			this.$refs.video.setPlaybackSpeed(this.playBackSpeed);
		}
	}

	/**
	 * Decreases playback speed of the video control.
	 */
	private slowDown() {
		if (this.playBackSpeed - 0.5 >= 0.5) {
			this.playBackSpeed -= 0.5;
			this.$refs.video.setPlaybackSpeed(this.playBackSpeed);
		}
	}

	private get canUnmute():boolean{
		return this.getFeature(["Alarms", "MediaMatrix", "UnmuteAudio"]);
	}

	muteUnMute(): void {
		this.isMuted = !this.isMuted;
		this.$refs.video.muteUnMute(this.isMuted);
	}

	private onMediaHasAudio(mediaHasAudio): void {
		this.mediaHasAudio = mediaHasAudio;
	}
	private onMediaHasVideo(mediaHasVideo): void {
		this.mediaHasVideo = mediaHasVideo;
	}
}
