import { MutationTree } from "vuex";
import getDefaultState from "./state";
import { GuardChat, Message } from "./types";

export const mutations: MutationTree<GuardChat> = {
	addMessages(state, messages: Message[]) {
		state.messages.push(...messages);
	},
	removeMessages(state) {
		state.messages = [];
	},
	addTwoWayMessages(state, messages: Message[]) {
		state.twoWayMessages.push(...messages);
	},
	setNumberOfUnread(state, newValue: number) {
		state.numberOfUnread = newValue;
	},
	setTwoWayNumberOfUnread(state, newValue: number) {
		state.twoWayNumberOfUnread = newValue;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	resetTwoWayState(state) {
		state.twoWayMessages = [];
		state.twoWayNumberOfUnread = 0;
	}
};
