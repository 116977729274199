import api from "@/services/api.service";
import EventRecordTypes from "@/types/sv-data/enums/EventRecordTypes";
import { Component, Vue } from "vue-property-decorator";

export interface SvAudioContext {
    id?: string,
    url: string,
    dataType?: BinaryType,
    mimeType?: string,
    playingState: boolean
}

/**
 * Mixin which adds helper and default methods to audio components.
 */
@Component({})
export default class AudioMixin extends Vue {
    private wsAudio: WebSocket = null;
    private audioElement: HTMLAudioElement  = null;
    private stopped: boolean = false;
    private queue: Uint8Array[] = [];
    private buffer: SourceBuffer = null;
    private source: MediaSource = null;
    private playing: boolean = false;
    private invalidAuth: boolean = false;

    public eventRecordId: number = null;

    public async generateEventRecord(eventId: number, deviceId: number, message: string): Promise<number> {
        if (!eventId) {
            return;
        }

        // Create an EventRecord for the device
        var auditRequest = {
            eventId: eventId,
            eventRecordTypeId: EventRecordTypes.AudioEvent,
            details: message,
            objectId: deviceId,
        };

        var eventRecord = (await api.postAudit(auditRequest)).data;
        return eventRecord.eventRecordID
    }

    private audioContextTemplate: SvAudioContext = {
        url: "",
        dataType: "arraybuffer",
        mimeType: "audio/mp4;codecs=\"mp4a.40.2\"",
        id: "1",
        playingState: false
    };

    private audioContext: SvAudioContext = { ...this.audioContextTemplate };

    /**
     * @summary Method which setups the websocket, audio context and any identional stuff for audio!
     * @param context our audio context
     */
    public async setupAudio(context: SvAudioContext): Promise<void> {
        this.invalidAuth = false;
        await this.listenAudio(false);

        // Setup our audio context.
        if (!context || !this.setupAudioContext(context)) {
            // Failed to setup desired data
            this.audioContext = { ...this.audioContextTemplate };
            return;
        }

        // make an audio element.
        this.audioElement = document.createElement("audio");
        this.audioElement.autoplay = true;
        this.audioElement.preload = "none";
        this.audioElement.id = this.audioContext.id;

        // create a media source
        this.source = new window.MediaSource();

        // on source open + adding source to src.
        this.source.onsourceopen = () => this.onSourceOpen();
        this.audioElement.src = URL.createObjectURL(this.source);
    }

    /**
     * @summary beforeDestory hook to close down ws and audio
     * @summary Vue Lifecycle hook
     */
    public beforeDestroy(): void {
        this.stopAudio();
    }

    /**
     * @summary method to setup audio Context data.
     * @param context Current audio context
     */
    private setupAudioContext(context: SvAudioContext): boolean {
        if (!context || !context.url) {
            this.audioErrorHandler(
                {
                    message: "url was not provided when trying to connect to audio device",
                    type: "error",
                    title: "Missing ws url"
                }
            );
            return false;
        }
        this.audioContext.url = context.url;
        this.audioContext.dataType = context.dataType ?? this.audioContext.dataType;
        this.audioContext.id = context.id ?? this.audioContext.id;
        this.audioContext.mimeType = context.mimeType ?? this.audioContext.mimeType;
        this.audioContext.playingState = context.playingState ?? this.audioContext.playingState;
        return true;
    }

    private audioErrorHandler({ message, type, title }: { message: string, type: string, title: string }) {
        console.error(`error occurred with audio: ${title}. ${message}`);
        this.$notify({
            type: type,
            title: title,
            text: message
        });
    }

    /**
     * @summary callback which is called when the media source is ready
     */
    private onSourceOpen() {
        this.buffer = this.source.addSourceBuffer(this.audioContext.mimeType);

        this.buffer.onupdateend = () => this.bufferAddNext();

        this.wsAudio = new WebSocket(this.audioContext.url);

        // Setup the data type
        this.wsAudio.binaryType = this.audioContext.dataType;
        this.wsAudio.onmessage = (evt: MessageEvent) => this.onMessage(evt);

        this.listenAudio(this.audioContext.playingState);

        // Kick it all off.
        this.bufferAddNext();
    }

    /**
     * @summary method which is called when an event is received from our websocket.
     * @param evt our current event.
     */
    private onMessage(evt: MessageEvent): void {
        try {
            if (window.ArrayBuffer.prototype.isPrototypeOf(evt.data)) {
                // only process the data if the tab is not hidden the player will
                // be stopped and so won't be throwing away data from the SourceBuffer
                // so we'll cause it to get full and error out if we keep adding to it)

                // check the queue size - if it's very large then we are receiving
                // more data than we can process and need to dump the old data
                // (then the jumping further down will bring us up to real time)
                if (this.queue.length > 1000) {
                    this.queue.length = 0;
                }

                // get the bytes from the array and add them to the queue
                const data = new window.Uint8Array(evt.data);
                this.queue.push(data);
                // restart adding if needed
                if (this.stopped) this.bufferAddNext();

                // start the element playing if it is not yet
                if (this.playing) this.audioElement.play();

                // check the current play position against what we have buffered ahead -
                // if there is too much of a gap jump to the end so we're not lagging behind
                // NOTE: cannot make it too small or it'll be constantly jumping, appearing
                // to the user as choppy. Also note Edge constantly
                // sits about 3.5s behind which must be its buffer (Chrome and Firefox are usually a
                // fraction of a second behind so their buffers must be smaller)
                const ranges = this.audioElement.buffered.length;
                if (ranges >= 1) {
                    const bufferEnd = this.audioElement.buffered.end(ranges - 1);
                    if (bufferEnd - this.audioElement.currentTime > 5) {
                        this.audioElement.currentTime = bufferEnd;
                    }
                }
            } else if (typeof evt.data === "string") {
                if (!evt.data.includes("PID=") && (evt.data.includes("40") || evt.data.includes("500"))) {
                    console.warn(`error occurred while fetching data: ${evt.data}`);
                    this.invalidAuth = true;
                    this.stopAudio();
                } else {
                    // message is text data
                    console.log(evt.data);
                }
            }
        } catch (ex){
            this.stopAudio();
            console.error(ex);
        }
    }

    /**
     * @summary when a new buffer is ready to be added
     */
    private bufferAddNext(): void {
        // start off assuming we are in the normal endless loop of being fired each time a buffer
        // update finishes then adding the next chunk of data (and being fired again when that ends and so on)
        this.stopped = false;

        // if we have not ended and the buffer is not currently updating,
        // then see if there is data in the queue to add
        if (this.source && !this.buffer.updating) {
            if (this.queue.length == 0) {
                // no more data to add - the loop is going to end so we will have to be restarted manually
                this.stopped = true;
                return;
            }

            try {
                this.buffer.appendBuffer(this.queue.shift());
            }
            catch (e) {
                // adding failed - the loop is going to end so we will have to be restarted manually
                this.stopped = true;
                if (e.name === "QuotaExceededError") {
                    // buffer is full - we must be way behind so clear the queue and buffer of old data.
                    // Then when the new data is added the jumping will move the player up to current time
                    this.queue.length = 0;
                    const ranges = this.audioElement.buffered.length;
                    if (ranges >= 1) this.buffer.remove(0, this.audioElement.buffered.end(ranges - 1));
                }
                else {
                    this.stopAudio();
                }
            }
        }
    }

    /**
     * @summary method to handle shutting down audio and websocket
     */
    public async stopAudio(): Promise<void> {
        try
        {
            await this.listenAudio(false);
        } catch {
            console.log("Audio already stopped")
        }

        if (this.wsAudio)
            this.wsAudio.close();

        if (this.source && this.source.sourceBuffers[0]) {
            this.source.removeSourceBuffer(this.source.sourceBuffers[0]);
        }

        this.queue = [];
        this.buffer = null;
        this.source = null;
        this.wsAudio = null;
        this.audioElement = null;
        this.audioContext = { ...this.audioContextTemplate };
        this.stopped = false;
        this.playing = false;
    }

    /**
     * @summary This method allows extended components to enable or disable the audio
     * playing through the browser.
     * @param state the audio state be playing or not
     */
    public async listenAudio(state: boolean): Promise<void> {
        // Validate we have audio setup.
        if (!this.audioContext || !this.audioElement) {
            return;
        }
        state ? await this.audioElement.play(): this.audioElement.pause();
        this.playing = state;
    }

    public get audioReady(): boolean {
        return (!!this.audioContext && !!this.wsAudio && !!this.audioElement);
    }

    public get getAuthState(): boolean {
        return !this.invalidAuth;
    }

    public get audioState(): string {
        if (this.audioReady)
            return "";

        if (this.invalidAuth)
            return "Authentication failed";

        return "Audio starting...";
    }
}