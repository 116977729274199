import { MutationTree } from "vuex";
import { SystemHealthState, SystemViewHealthStatusCount } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<SystemHealthState> = {
	setHealthStatus(state, healthStatus: SystemViewHealthStatusCount) {
		state.status = healthStatus;
	},
	setStatusFilter(state, selectedFilter: string) {
		state.selectedFilter = selectedFilter;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
