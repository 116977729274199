
import { ModalItemCSV } from "@/store/csv-form-handler/types";
import { ContactFor, ContactForEx } from "@/store/user-management/types";
import { Prop, Component, Vue } from "vue-property-decorator";
import GenericUpdateModal from "../table/generic-update-modal.vue";
import AreaTreeSelect from "./AreaTreeSelect.vue";
import { Getter } from 'vuex-class';

@Component({
	components: {
		"generic-update-modal": GenericUpdateModal
	}
})
export default class ContactsFor extends Vue {
	@Getter getFeature: (featuresList: string[]) => boolean;

	$refs!: {
		genericUpdateModal: GenericUpdateModal
	};

	@Prop({default: []})
	private value: ContactFor[];

	@Prop({})
	private allRoles: { key: string, title: string }[];

	@Prop({})
	private areaTitles: Map<number, string>;

	private contactList: ContactForEx[] = this.format() || [];
	private selectedContact: ContactForEx = null;
	private columns = [
		{
			label: "Area",
			key: "title",
			sortable: true,
			thStyle: {width: '20%'}
		},
		{
			label: "Roles",
			key: "roleIds",
			sortable: true,
			thStyle: { width: '55%' }
		},
		{
			label: "Inherited",
			key: "isInherited",
			sortable: true,
			visible: true,
			thStyle: {width: '100px'}
		},
		{
			label: "Actions",
			key: "edit",
			thStyle: { width: '60px'}
		}
	];

	private get modalItems() : ModalItemCSV[]
	{
		return [
			{
				title: "ID",
				key: "id",
				visible: false,
				dataType: "input"
			},
			{
				title: "Area",
				key: "groupId",
				dataType: "component",
				data: AreaTreeSelect,
				props: {
					reduce: (a) => a.id,
					clearable: false,
					appendToBody: true
				},
				order: 0,
				csvData: Object.keys(this.areaTitles),
				csvComponent: 'select',
				csvValidation: {
					validationCell: ({ currentElement }) => {
						return {
							isValid: this.areaTitles[currentElement.csvValue] !== undefined
						}
					},
				},
				required: true
			},
			{
				title: "Roles",
				key: "roleIds",
				placeholder: "Select a Role",
				useCustomCell: true,
				data: this.allRoles,
				dataType: "vselect3",
				props: {
					multiple: true,
					closeOnSelect: false,
					getOptionLabel: (option) => this.getRoleTitle(option),
					reduce: (role) => role.key 
				},
				required: true,
				csvComponent: 'select-multiple',
				readOnly: this.isSuiteEnabled
			},
			{
				title: "Inherited",
				key: "isInherited",
				dataType: "checkbox"
			}
		] as ModalItemCSV[]
	}

	private onAddNew(item: ContactForEx) : void
	{
		const title: string = this.areaTitles[item.groupId];
		const contactFor: ContactForEx = {
			id: item.id ?? this.contactList.length,
			title: title,
			roleIds: item.roleIds,
			isInherited: item.isInherited ?? false,
			groupId: item.groupId
		};

		this.contactList.push(contactFor);
		this.$emit("input", this.contactList);
	}

	private onSave(item: ContactForEx) : void
	{
		var updateIndex = this.contactList.findIndex(cl => cl.id == item.id);

		if(updateIndex === -1)
		{
			this.onAddNew(item);
			return;
		}

		this.contactList[updateIndex].isInherited = item.isInherited;
		this.contactList[updateIndex].groupId = item.groupId;
		this.contactList[updateIndex].title = this.areaTitles[item.groupId];
		this.contactList[updateIndex].roleIds = item.roleIds;

		this.$emit("input", this.contactList);

		this.selectedContact = null;
	}

	private onDelete(item: ContactForEx): void
	{
		this.contactList = this.contactList.filter(cl => item.id != cl.id);
		this.$emit("input", this.contactList);
	}

	private format() : ContactForEx[]
	{
		if(!this.value)
		{
			return [];
		}

		return this.value.map((v, i) => {
			return {
				id: i,
				title: this.areaTitles[v.groupId],
				groupId: v.groupId,
				roleIds: v.roleIds,
				isInherited: v.isInherited
			} as ContactForEx
		});
	}

	private getRoleTitle(role: { key: number, title: string } | number): string
	{
		if (typeof role === "object")
		{
			return role.title;
		}

		const currentRole = this.allRoles.find(r => r.key.toString() == role.toString());

		if (!currentRole)
		{
			return "Role not found.";
		}

		return currentRole.title;
	}

	private onEditBtnClick(item: ContactForEx, isReadonly: boolean): void
	{
		this.$refs.genericUpdateModal.showUpdateDialog(item, isReadonly, false);
	}

	private onAddBtnClick(): void
	{
		let contact: ContactForEx = {
			title: "",
			id: -1,
			roleIds: [],
			isInherited: true,
			groupId: 0
		};

		this.$refs.genericUpdateModal.showUpdateDialog(contact, false, true);
	}

	public validate(): boolean {
        return this.isValid;
    }

    private get isValid(): boolean {
        if(!this.contactList || !this.contactList.length) {
			return false;
        }
        return true;
    }

	private get isSuiteEnabled(): boolean {
		return this.getFeature(["Suite"]);
	}
}
