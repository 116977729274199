import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { ReportState } from "./types";
import { RootState } from "../types";
import { LinkParser } from "@sureview/v2-linkify";

export const actions: ActionTree<ReportState, RootState> = {
	async loadAvailableReports({ commit, getters }) {
		const { data } = await api.loadAvailableReports(getters.getReportApiEndpoint);
		commit("setAvailableReports", data);
	},

	async runReport({ commit, getters, rootGetters }, payload: any) {
		commit("setReportLoadingStatus", true);
		commit("setReportErrorStatus", false);

		try {
			const { data } = await api.runReport(getters.getReportApiEndpoint, getters.getCurrentReportUrl, payload);

			// If we're fetching the event list report, we need to linkify any dynamic
			// links that may have been put inside an events outcome note.
			if (getters.getCurrentReportUrl.startsWith("/AlarmEventList")) {
				// Check if the dynamic link integration is enabled
				const dynamicLinksEnabled = rootGetters.getFeature(["Integration", "DynamicLink"]);
				const dynamicLinkConfig = rootGetters.getDynamicLinkConfig;

				if (dynamicLinksEnabled && dynamicLinkConfig) {
					let linkParser = new LinkParser(dynamicLinkConfig);
					for (let i = 0; i < data.data.length; i++) {
						const element = data.data[i];
						const outcomeNote = element.outcomeNote;
						const parsed = linkParser.parse(outcomeNote);
						if (parsed) {
							element.outcomeNote = parsed.reduce((prev, curr) => prev + curr.toString(), "");
						}
					}
				}
			}

			commit("setReport", data);
		} catch (err) {
			commit("setReportLoadingStatus", false);
			commit("setReportErrorStatus", true);
			throw err;
		}

		commit("setReportLoadingStatus", false);
	},
	async fetchReport({ getters }, payload: any) {
		try {
			const { data } = await api.runReport(getters.getReportApiEndpoint, payload.reportUrl, payload.payload);
			return data;
		} catch (ex) {
			// in the event of an error (404,401,500) return null
			// a check is done in the returning function for a valid result based on the value not being null
			return null;
		}
	},
	async downloadReport({ commit, getters }, payload: any) {
		commit("setReportLoadingStatus", true);
		commit("setReportErrorStatus", false);

		try {
			let reportUrl = getters.getCurrentReportUrl;
			// extract the report type from the URL
			var myRegexp = /^\/([a-z]+)\??/gim;
			var match = myRegexp.exec(reportUrl);
			let reportType = "/" + match[1];

			// request the export
			let result = await api.DownloadReport(getters.getReportApiEndpoint, reportType, payload);

			// get the filename that has been generated by the Dataservice
			var disposition = result.headers["content-disposition"];
			var matches = /"([^"]*)"/.exec(disposition);
			var filename = matches != null && matches[1] ? matches[1] : "ReportExport.csv";

			// create download file from the AJAX response
			var blob = new Blob([result.data], { type: "text/csv" });

			// create a temporary link that will be clicked to initate the download then removed
			var link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			link.download = filename;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			commit("setReportLoadingStatus", false);
			commit("setReportErrorStatus", true);
		}

		commit("setReportLoadingStatus", false);
	},

	async fetchReportServiceEndpoint({ commit }) {
		const { data } = await api.getReportServiceEndpoint();
		commit("setReportApiEndpoint", data);
	},

	async fetchReportTypes({ commit, getters }) {
		const { data } = await api.loadReportTypes(getters.getReportApiEndpoint);
		commit("setReportTypes", data);
	},

	async heartbeatToReportServer({ getters }) {
		return await api.heartbeatToServer(getters.getReportApiEndpoint);
	},

	async heartbeatToCurrentServer({ getters }) {
		return await api.heartbeatToCurrentServer();
	},

	getReportUrl({ getters }, { templateId, grouping, selectedFilters }) {
		const report = getters.getReport;

		let reportUrl = report.reportEndpoint;
		if (templateId) {
			reportUrl += "/" + templateId;
		}

		let params = [];
		report.filterOptions.forEach(filter => {
			const filterSelectedValue = selectedFilters[filter.filterName];
			if (Array.isArray(filterSelectedValue)) {
				if (filterSelectedValue.length > 0) {
					params.push({
						key: filter.filterName,
						value: filterSelectedValue.map(item => item.id).join(",")
					});
				}
			} else if (filterSelectedValue) {
				params.push({
					key: filter.filterName,
					value: filterSelectedValue
				});
			}
		});

		const paramString = params.map(param => param.key + "=" + param.value).join("&");

		if (grouping) {
			reportUrl = reportUrl + "?groupingTypeEnum=" + grouping + "&" + paramString;
		} else {
			reportUrl = reportUrl + "?" + paramString;
		}

		return reportUrl;
	}
};
