import { getDefaultState } from "./state";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Module } from "vuex";
import { RootState } from "../types";

export const state: any = getDefaultState();

const namespaced: boolean = true;

export const caseManagement: Module<any, RootState> = {
    namespaced,
    state,
    actions,
    mutations
};