import { Mobile } from "./types";
import { ICamera } from "@/store/views/types";
import MapLabel from "@/scripts/mapping/mapLabel";

export function getDefaultState(): Mobile {
    return {
    	isRightSideBarOpen: false,
		isLeftSideBarOpen: false,
		showDirections: true,
		DeviceLocation: "",
		IsWatchingLocation: false,
        AuditEndpoint: "",
        MobileFeatures: {
            isSOSEnabled: false,
            isBreakCoverEnabled: true,
            isLostModeEnabled: false,
            isManualRaiseEnabled: false,
            isClaimedEnabled: false,
            isFileUploadEnabled: true
        },
        UnavailableStatuses: [],
        LiveDevice: { title: "Live Cameras" } as ICamera,
        MobileMode: "",
        MapEnabled: false,
        StarredDevices: [],
        UserPermissions: {},
		NotificationText: "",
		ManageDataLoading: true,
		ManageAlarmQueueFilters: [],
		ManageCurrentAlarmQueueFilters: [],
		MapGoto: null,
        QuickCloseList: [],
        UserMapOnlineStatus: true, // ensure online is defaulted to true
        UserAssetMapItem: null,
        UserAssetType: null,
        EventShareItems: [],
        ActiveEventShareItem: null,
        FocusedEventID: null,
        NavigationMode: "WALKING",
        NavigateToActiveAlarm: false,
        NavigationLocation : null,
        ChatOpen: false,
        ChatTab: "General",
        Regions: new Map<number, {poly: google.maps.Polygon, label: MapLabel }>()
    }
}
