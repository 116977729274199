
	import { Component, Prop, Vue } from "vue-property-decorator";
	@Component({
			components: {}
		})
	export default class ZendeskHelp extends Vue {
		@Prop({ type: Boolean, default: false }) public iconOnly: boolean;

		private toggleHelp():void {
			if(document.querySelector("#webWidget")){
				// hide the widget
				window.zE('webWidget', 'close');	
			}
			else{
				window.zE('webWidget', 'helpCenter:setSuggestions', {
					url: true
				});
				window.zE("webWidget", "show");
				window.zE("webWidget", "open");
			}
		}
    }
