import { Observable, timer, of, BehaviorSubject } from "rxjs";
import { switchMap, concatMap, map, tap, take } from "rxjs/operators";
import { ActionTree } from "vuex";
import { TasksState } from "./types";
import { RootState } from "@/store/types";
import { CompleteTaskPayload } from "@/services/api.service";
import { TasksService } from "@/services/tasks.service";
import TaskCategory from "@/models/task-category.model";
import { CategoryReorderRequest } from "@/store/tasks/types";

import api from "@/services/api.service";
import Vue from "vue";

const tasksService = new TasksService();

export const actions: ActionTree<TasksState, RootState> = {
	// Fetch all actions for an event
	async fetchEventTasks({ dispatch, commit, rootGetters }, payload: any) {
		const allTasks = await tasksService.loadTasksForEvent(payload.eventId);
		try {
			allTasks.forEach(element => {
				element.taskData = JSON.parse(element.taskData);
			});
		} catch (Ex) {
			console.log(
				"Error occured fetching event" + payload.eventId,
				+"Is the response taskData an valid object?" + Ex
			);
		}
		commit("setOutstandingRequiredTasks", allTasks.filter((task: any) => task.required && !task.completed));

		// @techdebt move to getter
		const userShares = rootGetters["siteMonitor/userShares"];
		if (userShares != null) {
			allTasks.forEach((task: any) => {
				if (task.assignedToEventUserID != null && userShares[task.assignedToEventUserID] !== undefined) {
					task.assignedToEventUser = userShares[task.assignedToEventUserID];
				}
			});
		}

		// commit('setTasks', tasksService.responseToModel(allTasks));
		commit("setTasks", allTasks);
	},

	async assignTask({ dispatch, commit, state, rootState }, payload: any) {
		await tasksService.assignTaskToUser(payload);

		// @techdebt trigger `fetchEventTasks` action to load new data,
		// and remove line loading new data inside component method
	},

	async assignAllTasks(context, payload: any) {
		await tasksService.assignAllTasks(payload);
	},

	// all Actions (Action Library, used to configure Action Plans)
	// @note `Action` entity exists as `Task` in API
	async fetchActionLibrary({ commit }) {
		const allTasks = await tasksService.loadTasks();
		try {
			allTasks.forEach((action: any) => {
				action.selected = false;
				action.taskData = JSON.parse(action.taskData);
			});
		} catch (Ex) {
			console.log("Error occured fetching action Library Is the response taskData an valid object?" + Ex);
		}

		commit("setTaskLibrary", allTasks);
	},

	async fetchTaskTypes({ commit }) {
		const taskTypes = await tasksService.loadTaskTypes();
		commit("setTaskTypes", taskTypes);
	},

	async fetchTaskCategories({ commit, getters }) {
		const taskCategories = await tasksService.loadTaskCategories();

		// @todo refactor this entire getter
		const tasksByCategoryID: { [index: string]: any[] } = {};
		let categoryKey = null;
		getters.getTaskLibrary.forEach((t: any) => {
			if (t.taskCategoryID === 0 || t.taskCategoryID === undefined) {
				categoryKey = 0;
			} else {
				categoryKey = t.taskCategoryID;
			}
			if (!tasksByCategoryID[categoryKey]) {
				tasksByCategoryID[categoryKey] = [];
			}
			tasksByCategoryID[categoryKey].push(t);
		});

		taskCategories.forEach((category: any) => {
			category.selected = false;
			if (tasksByCategoryID[category.taskCategoryID]) {
				category.tasks = tasksByCategoryID[category.taskCategoryID];
			} else {
				category.tasks = [];
			}
		});

		commit("setTaskCategories", taskCategories);
		// @todo and load all associate tasks, too
	},

	async fetchActionPlans({ commit }) {
		const actionPlans = await tasksService.loadActionPlans();
		actionPlans.sort((a, b) => a.title.localeCompare(b.title));

		commit("setActionPlans", actionPlans);
	},

	async createActionPlan({ dispatch }, payload: any) {
		const response = await tasksService.createActionPlan(payload);
		if (response.status === 200) {
			dispatch("fetchActionPlans");
		}

		return response.data;
	},

	async updateActionPlan({ dispatch }, payload: any) {
		const response = await tasksService.updateActionPlan(payload);
		if (response.status === 200) {
			dispatch("fetchActionPlans");
		} else {
			// @todo error handling
		}
	},

	async deleteActionPlan({ dispatch }, payload: any) {
		const response = await tasksService.deleteActionPlan({
			scriptId: payload
		});
		if (response.status === 200) {
			dispatch("fetchActionPlans");
			dispatch("fetchActionLibrary");
		} else {
			// @todo error handling
		}
	},

	async bulkAddActionsToPlan({ dispatch }, payload: any) {
		await Promise.all([
			payload.actionIDs.map(actionID => {
				return tasksService.addActionToPlan({
					ScriptID: payload.ScriptID,
					Required: true,
					TaskID: actionID
				});
			})
		]);
		dispatch("fetchActionLibrary");
	},

	async addActionToPlan({ dispatch }, payload: any) {
		const response = await tasksService.addActionToPlan(payload);
		if (response.status === 200) {
			dispatch("fetchActionLibrary");
		} else {
			// @todo error handling
		}
	},

	async updateActionForPlan({ dispatch }, payload: any) {
		const response = await tasksService.updateActionForPlan(payload);
		if (response.status === 200) {
			dispatch("fetchActionLibrary");
		} else {
			// @todo error handling
		}
	},

	async removeActionFromPlan({ dispatch }, payload: any) {
		const response = await tasksService.removeActionFromPlan(payload);
		if (response.status === 200) {
			dispatch("fetchActionLibrary");
		} else {
			// @todo error handling
		}
	},

	async addTaskToEvent({ dispatch }, payload: any) {
		// @todo type
		const response = await tasksService.saveEventTask(payload);
		if (response.status === 200) {
			dispatch("fetchEventTasks", { eventId: payload.eventId });
		} else {
			// @todo error handling
		}
	},

	async addTaskToLibrary({ dispatch }, newTask: any) {
		const response = await tasksService.createTask(newTask);
		if (response.status === 200) {
			dispatch("fetchActionLibrary");
		} else {
			// @todo error handling
		}
	},

	async deleteTaskFromLibrary({ dispatch }, taskID: number) {
		const response = await tasksService.deleteTask({
			taskId: taskID
		});
		if (response.status === 200) {
			dispatch("fetchActionLibrary");
		} else {
			// @todo error handling
		}
	},

	async updateTask({ dispatch }, updatedTask: any) {
		// Stringify any task data we have
		if (updatedTask.taskData) {
			updatedTask.taskData = JSON.stringify(updatedTask.taskData);
		}

		const response = await tasksService.updateTask(updatedTask);
		if (response.status === 200) {
			dispatch("fetchActionLibrary");
		} else {
			// @todo error handling
		}
	},

	async completeTask({ dispatch }, payload: CompleteTaskPayload) {
		const response = await tasksService.completeTask(payload);
		if (response.status === 200) {
			dispatch("fetchEventTasks", { eventId: payload.EventID });
		} else {
			// @todo error handling
		}
	},

	async createTaskCategory({ dispatch }, payload: TaskCategory) {
		const response = await tasksService.saveTaskCategory(payload);
		if (response.status === 200) {
			await dispatch("fetchTaskCategories");
		} else {
			// @todo error handling
		}
	},

	async updateTaskCategory({ dispatch }, payload: TaskCategory) {
		const response = await tasksService.updateTaskCategory(payload);
		if (response.status === 200) {
			dispatch("fetchTaskCategories");
		} else {
			// @todo error handling
		}
	},

	async deleteTaskCategory({ dispatch }, categoryID: number) {
		const response = await tasksService.deleteTaskCategory({
			taskCategoryId: categoryID
		});
		if (response.status === 200) {
			dispatch("fetchTaskCategories");
			dispatch("fetchActionLibrary");
		} else {
			// @todo error handling
		}
	},

	async assignUserToTaskCategory({ dispatch }, payload: any) {
		const { status } = await tasksService.assignUserToTaskCategory(payload);
		if (status === 200) {
			dispatch("fetchEventTasks", { eventId: payload.EventID });
		} else {
			// @todo error handling
		}
	},

	async fetchNotificationServers({ commit }) {
		const { data } = await tasksService.fetchNotificationServers();
		commit("setNotificationServers", data);
	},

	async fetchNotificationTemplates({ commit }, serverId: number) {
		const { data } = await tasksService.fetchNotificationTemplates(serverId);
		commit("setNotificationTemplates", data);
	},

	async fetchTemplateDetails({ commit }, templateId: number) {
		const { data } = await tasksService.fetchTemplateDetails(templateId);
		return data;
	},

	async loadEditTriggerAction({ commit }, action) {
		Vue.set(action, "trigger", await api.triggeredTasks(action.taskID));
		commit("setEditTriggerAction", action);
	},

	/**
	 * Updates the order of a Task Library item within an Action Plan (script).
	 * @param param0 vuex methods.
	 * @param payload the payload containing our task, script, category and new ordering.
	 */
	async updateActionOrderInPlan({ dispatch, commit }, payload: any) {
		// Perform the re-order in the front end so the animation is nice & smooth
		commit("setActionOrder", payload);

		try {
			// Update the ordering in the back-end
			const { data } = await api.updateActionOrderInPlan(payload);

			// The data returned will be the set of tasks that we need to update in our store
			commit("setUpdatedTasks", data);
		} catch (err) {
			// If an error occurs when we're re-ordering the action plan,
			// we need to revert our changes, so re-set our task library
			dispatch("fetchActionLibrary");

			// Raise the error so we can give some feedback
			console.error(`Unable to re-order action plan: ${err}`);
			throw err;
		}
	},
	async fetchCallContacts({ commit }, payload: any) {
		let callContacts;

		if (payload.CallType === "Contact Role") {
			if(!payload?.GroupId)
			{
				callContacts = [];
			}
			else
			{
				callContacts = await tasksService.getContactsForRole(payload.value.contactRoleId, payload.GroupId);
			}
		}
		else {
			callContacts = (await tasksService.fetchCallContacts(payload)).data;
		}

		commit("setCallContacts", callContacts);
	},
	async fetchDynamicDatalist({ commit }) {
		const { data } = await api.fetchDynamicDataList();
		commit("setDynamicDataList", data);
	},
	async fetchDynamicData({ commit }, payload: any) {
		const { data } = await api.fetchDynamicData(payload);
		commit("setDynamicData", data);
	},
	async getScriptCategoryLinks({ commit }) {
		const data = await tasksService.getScriptCategoryLinks();
		commit("setScriptCategories", data)
	},
	async updateCategoryOrderInScript({ dispatch, commit }, payload: CategoryReorderRequest){
		try {
			const data = await tasksService.updateCategoryOrderInScript(payload);

			commit("setUpdatedTaskCategories", data);
		} catch (ex) {
			console.error("Unable to re-order action plan: ${ex}");

			dispatch("getScriptCategoryLinks");

			throw ex;
		}
	}
};
