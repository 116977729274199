
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { get } from "lodash";

import { UserPermissions, FeaturesList } from "@/store/types";
import { UserGroup } from "@/store/user-management/types";
import { SubscriptionDto } from "@/store/subscription/types";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { ModalItem } from "@/components/table/generic-update-modal.vue";
import UserGroupRoles from "./UserGroupRoles.vue";
import vselect3 from "vselect3";

const UserManagement = namespace("userManagement");
const Subscription = namespace("subscription");
const Areas = namespace("areas");

import api from "@/services/api.service";

export interface UserGroupEx extends UserGroup {
	externalIdsArray?: string[]
}

@Component({
	components: {
		"generic-table": GenericTable
	}
})
export default class UserGroupSetup extends Vue {
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getPermissions: UserPermissions;
	@Getter getUserTenantGroupId: number;

	@UserManagement.Getter getUserGroupTitle: (id) => string;
	@UserManagement.Getter getUserRoleTitle: (id) => string;
	@UserManagement.Action fetchPermissionsState: () => Promise<void>;

	@Subscription.Action fetchSubscription: (id: number) => Promise<void>;
	@Subscription.State subscription: SubscriptionDto;
	@Areas.Getter getAreaTitle: (id) => string;

	private userGroupsList: UserGroupEx[] = [];

	private groupColumns: TableHeader[] = [
		{
			title: "Group Name",
			key: "title",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The user group name",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true
		},
		{
			title: "Roles",
			key: "roles",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "Areas roles",
			searchable: false,
			visible: true,
			dataType: "list",
			useCustomCell: true
		}
	];

	private groupModalItems() {
		return [
			{
				title: "Group Names",
				key: "title",
				dataType: "text",
				required: true,
				readOnly: this.isSuiteEnabled,
			} as ModalItem,
			{
				title: "External Ids",
				key: "externalIdsArray",
				dataType: "component",
				data: vselect3,
				props: {
					"create-option": id => id,
					placeholder: "External Ids",
					taggable: true,
					appendToBody: true,
					multiple: true,
				},
				required: false,
				description: "External ids to match during syncing",
				visible: this.syncSetupEnabled,
				readOnly: this.isSuiteEnabled
			},
			{
				title: "Roles",
				key: "roles",
				dataType: "component",
				data: UserGroupRoles,
				defaultValue: [],
				props: {
					multiple: true,
					requiredFields: [{
						key: "userRoleId", isSet: (value) => {
							return value && value !== 0;
						}
					}]
				},
				required: true
			}
		];
	}

	private isLoading: boolean = false;

	public async loadState(): Promise<void>
	{
		try
		{
			this.isLoading = true;
			await this.retrieveUserGroups();
			await this.fetchSubscription(this.getUserTenantGroupId);
		} catch (ex) {
			console.error("Unexpected error loading data: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private async mounted() {
		await this.loadState();
	}

	private get syncSetupEnabled(): boolean {
		return get(this.featuresList, ["Devices", "AdvancedSetup", "SyncSystem"]);
	}

	private async updateUserGroup(userGroup: UserGroupEx) {
		if (userGroup) {
			try {
				this.isLoading = true;
				if (userGroup.externalIdsArray && userGroup.externalIdsArray.length > 0){
					try
					{
						userGroup.externalIds = JSON.stringify(userGroup.externalIdsArray);
					}
					catch(er)
					{
						console.error(er)
					}
				}
				else
				{
					userGroup.externalIds = "[]";
				}
				await api.saveUserGroup(userGroup);
				await this.updateState();
				await this.fetchSubscription(this.getUserTenantGroupId);
			} catch (ex) {
				if(ex.response && ex.response.status === 566){
					Vue.prototype.$notify({
						type: "error",
						title: "User group not updated. ",
						text: ex.response.data
					});
				} else {
					console.log("Unexpected error updating User Group: " + ex);
					throw ex;
				}
				console.log("Unexpected error updating User Group: " + ex);
			} finally {
				this.isLoading = false;
			}
		}
	}

	private async deleteUserGroup(deleteGroup: UserGroup) {
		try {
			this.isLoading = true;
			await api.deleteUserGroup(deleteGroup.userGroupId);
			await this.updateState();
		} catch (ex) {
			console.log("Unexpected error deleting User Group: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private async saveActionMessages(userGroupToSave) {
		if (!userGroupToSave || !userGroupToSave.userGroupId || this.userGroupsList.length == 0) {
			return;
		}
		let currentUserGroup = this.userGroupsList.find(r => r.userGroupId == userGroupToSave.userGroupId);

		let groupsCurrentUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserGroup(currentUserGroup);
		let updatedGroupsUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserGroup(userGroupToSave);

		let usageDifference = {
			operators: updatedGroupsUserSubscriptionUsageCount.operators - groupsCurrentUserSubscriptionUsageCount.operators,
			mobileOfficers: updatedGroupsUserSubscriptionUsageCount.mobileOfficers - groupsCurrentUserSubscriptionUsageCount.mobileOfficers,
		}

		let totalNumberOfUsers = await api.getUserCountForUserGroup(userGroupToSave.userGroupId);
		if (totalNumberOfUsers > 0) {
			let result = [];
			if (totalNumberOfUsers == 1) {
				result.push(`Update the permissions for ${totalNumberOfUsers} user.`);
			} else {
				result.push(`Update the permissions for ${totalNumberOfUsers} users.`);
			}
			if (usageDifference.operators !== 0) {
				result.push(`Change the number of Operators used by your subscription from
					${this.subscription.limitedSubscription ? this.subscription.operators + "/" + this.subscription.operatorsLimit : this.subscription.operators}
					to
					${this.subscription.operators + usageDifference.operators + (this.subscription.limitedSubscription ? "/" + this.subscription.operatorsLimit: "")}.`
				);
			}
			if (usageDifference.mobileOfficers !== 0) {
				result.push(`Change the number of Mobile Officers used by your subscription from
					${this.subscription.limitedSubscription ? this.subscription.mobileOfficers + "/" + this.subscription.mobileOfficersLimit : this.subscription.mobileOfficers}
					to
					${this.subscription.mobileOfficers + usageDifference.mobileOfficers + (this.subscription.limitedSubscription ? "/" + this.subscription.operatorsLimit: "")}.`
				);
			}
			return result;
		}
		else {
			return [];
		}
	}

	private async saveActionErrors(userGroupToSave): Promise<string[]> {
		if (!userGroupToSave || !userGroupToSave.userGroupId || this.userGroupsList.length == 0 || !this.subscription.limitedSubscription) {
			return [];
		}

		let currentUserGroup = this.userGroupsList.find(r => r.userGroupId == userGroupToSave.userGroupId);
		let groupsCurrentUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserGroup(currentUserGroup);
		let updatedGroupsUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserGroup(userGroupToSave);
		let usageDifference = {
			operators: updatedGroupsUserSubscriptionUsageCount.operators - groupsCurrentUserSubscriptionUsageCount.operators,
			mobileOfficers: updatedGroupsUserSubscriptionUsageCount.mobileOfficers - groupsCurrentUserSubscriptionUsageCount.mobileOfficers,
		}

		let totalNumberOfUsers = await api.getUserCountForUserGroup(userGroupToSave.userGroupId);
		if (totalNumberOfUsers > 0) {
			let result = [];
			if (this.subscription.operators + usageDifference.operators > this.subscription.operatorsLimit) {
				result.push(`Operator usage limit of ${this.subscription.operatorsLimit} will be exceeded`)
			}
			if (this.subscription.mobileOfficers + usageDifference.mobileOfficers > this.subscription.mobileOfficersLimit) {
				result.push(`Mobile Officer usage limit of ${this.subscription.mobileOfficersLimit} will be exceeded`)
			}
			return result;
		}
		else {
			return [];
		}
	}

	private async deleteActionMessages(userGroupToDelete) {
		if (!userGroupToDelete || !userGroupToDelete.userGroupId || this.userGroupsList.length == 0) {
			return;
		}
		let updatedGroupsUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserGroup(userGroupToDelete);
		let totalNumberOfUsers = await api.getUserCountForUserGroup(userGroupToDelete.userGroupId);
		if (totalNumberOfUsers > 0) {
			let result = [];
			if (totalNumberOfUsers == 1) {
				result.push(`Update the permissions for ${totalNumberOfUsers} user.`);
			} else {
				result.push(`Update the permissions for ${totalNumberOfUsers} users.`);
			}
			if (updatedGroupsUserSubscriptionUsageCount.operators > 0) {
				result.push(`Reduce the number of Operators used by your subscription from
					${this.subscription.limitedSubscription ? this.subscription.operators + "/" + this.subscription.operatorsLimit : this.subscription.operators}
					to
					${this.subscription.operators - updatedGroupsUserSubscriptionUsageCount.operators + (this.subscription.limitedSubscription ? "/" + this.subscription.operatorsLimit: "")}.`
				);
			}
			if (updatedGroupsUserSubscriptionUsageCount.mobileOfficers > 0) {
				result.push(`Reduce the number of Mobile Officers used by your subscription from
					${this.subscription.limitedSubscription ? this.subscription.mobileOfficers + "/" + this.subscription.mobileOfficersLimit : this.subscription.mobileOfficers}
					to
					${this.subscription.mobileOfficers - updatedGroupsUserSubscriptionUsageCount.mobileOfficers + (this.subscription.limitedSubscription ? "/" + this.subscription.mobileOfficersLimit: "")}.`
				);
			}
			return result;
		}
		else {
			return [];
		}
	}

	private get isBillingEnabled() {
		return get(this.featuresList, ["Billing"]);
	}

	private get isSuiteEnabled(): boolean {
		return get(this.featuresList, ["Suite"]);
	}

	private async retrieveUserGroups(): Promise<void>
	{
		var userGroups =  await api.getUserGroups();
		this.userGroupsList = userGroups.map(userGroup => {
			var externalIdList = [];
			if (userGroup.externalIds && userGroup.externalIds.length > 0)
			{
				try
				{
					externalIdList = JSON.parse(userGroup.externalIds)
				}
				catch(er)
				{
					console.error(er)
				}
			}

			return {
				...userGroup,
				externalIdsArray: externalIdList
			} as UserGroupEx;
		});
	}

	private async updateState() {
		try {
			this.isLoading = true;
			await this.retrieveUserGroups();
			await this.fetchSubscription(this.getUserTenantGroupId);
			await this.fetchPermissionsState();
		} catch (ex) {
			console.log("Unexpected exception updating component state: " + ex);
		} finally {
			this.isLoading = false;
		}
	}
}
