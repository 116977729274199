
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import { AreaNote } from "@/store/area-notes/types";
import { UserPermissions } from "@/store/types";

const AreaNotes = namespace("areaNotes");

@Component
export default class AreaNotesTableItem extends Vue {
	@Prop({ default: {
		entry: "",
		groupId: 0,
		userName: "",
		created: "",
		requiresAcknowledgement: false,
		startFrom: "",
		endAt: ""
	}})
	public areaNote: AreaNote;

	@Getter getPermissions: UserPermissions;
	@AreaNotes.Action deleteAreaNote: any;
	@AreaNotes.Action startEditingNote: any;
	@AreaNotes.Getter getAreaNoteToEdit: AreaNote;

	private areaNoteIdToDelete: number | null = null;

	private deleteNote() {
		this.deleteAreaNote(this.areaNoteIdToDelete);
		this.areaNoteIdToDelete = null;
	}

	private editNote(note: AreaNote) {
		this.startEditingNote(note);
	}

	private hasPermissionsToEditNotes() {
		return this.getPermissions.canViewSiteSetup;
	}
}
