
import { Component, Mixins } from "vue-property-decorator";
import NavHeader from "@/components/NavHeader.vue";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { namespace, Getter } from "vuex-class";
import { RulesWithPagination, Rule, EditingRule, RulesWithTableActions, Grouping } from "@/store/rules-engine/types";
import { PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
import PaginatedSearch, { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";
import RulesEngineSetupModal from "@/components/rules-engine-setup/RulesEngineSetupModal.vue";
import RulesEngineDeleteModal from "@/components/rules-engine-setup/RulesEngineDeleteModal.vue";

const RuleStore = namespace("rulesEngine");

@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable,
		"rules-engine-setup-modal": RulesEngineSetupModal,
		"rules-engine-delete-modal": RulesEngineDeleteModal
	}
})
export default class RulesEngineSetup extends Mixins(PaginatedSearch) {
	@RuleStore.Action private retrieveRules: (paginatedSearchRequest: PaginatedSearchRequest) => Promise<RulesWithPagination>;
	@RuleStore.Action private configureRuleForUI: (rule: Rule) => Promise<void>;
	@RuleStore.Action private retrieveRuleServerTypes: () => Promise<void>;
	@RuleStore.Action private retrieveRuleActionPlans: () => Promise<void>;
	@RuleStore.State private rules: RulesWithPagination;
	@RuleStore.State private editingRule: EditingRule;
	@RuleStore.State private setupModalVisible: boolean;
	@Getter getPermissions: UserPermissions;
	@RuleStore.Getter private getGrouping: Grouping[];

	private isLoading: boolean = false;
	private totalRecords: number = 0;
	private isSetupModalShown: boolean = false;
	private isDeleteModalShown: boolean = false;
	private selectedDeleteRule: Rule = null;

	private get userHasViewPermissions(): boolean {
		return this.getPermissions.canViewRulesEngine;
	}

	private get userHasEditPermissions(): boolean {
		return this.getPermissions.canEditRulesEngine;
	}

	private async mounted(): Promise<void> {
		await Promise.all([this.updateData(), this.retrieveRuleServerTypes(), this.retrieveRuleActionPlans()]);
    }

	private columns: TableHeader[] = [
		{
			title: "Title",
			key: "title",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The Server Title",
			searchable: false,
			sortable: true,
			sortKey: "serverTitle",
			isTermLabel: true,
			visible: true
		},
		{
			title: "Grouping",
			key: "grouping",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The Rule Grouping",
			searchable: false,
			sortable: true,
			isSortedByDefault: true,
			visible: true,
			useCustomCell: true
		},
		{
			title: "Device",
			key: "ServerTitle",
			order: 3,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The Server Title",
			searchable: false,
		},
		{
			title: "Alarm",
			key: "responseTitle",
			order: 4,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The Response Title",
			searchable: false,
		},
		{
			title: "Event Type",
			key: "eventNum",
			order: 5,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The Event Type",
			searchable: false,
		},
		{
			title: "Device Type",
			key: "serverTypeTitle",
			order: 6,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The Device Type",
			searchable: false,
		}
	];

	private async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		try {
			this.isLoading = true;

			const request = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);
			await this.retrieveRules(request);

			let rulesWithPagination = this.rules;
			this.totalRecords = rulesWithPagination.totalRecords;
		}
		catch (ex) {
			console.log("Unexpected error fetching rules: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	private handleEditClick(rule: Rule, index: number): void {
		this.configureRuleForUI(rule);
		this.isSetupModalShown = true;
	}

	private handleAddClick(): void {
		// Open RuleEngineSetupModal with a blank rule if userHasEditPermissions
		this.isSetupModalShown = true;
	}

	private get dataWithActions(): RulesWithTableActions[] {
		return this.rules.data.map(ruleData => {
			return {...ruleData, controls: true}
			});
	}

	private handleDeleteClick(rule: Rule): void {
		this.selectedDeleteRule = rule;
		this.isDeleteModalShown = true;
	}

	private closeDeleteModal(): void {
		this.selectedDeleteRule = null;
		this.isDeleteModalShown = false;
	}

	private closeSetupModal(): void {
		this.isSetupModalShown = false;
	}

	private getGroupingTitle(id: number): string {
		if (id) {
			return this.getGrouping.firstOrDefault(g => g.id === id)?.title;
		}
		return "";
	}
}
