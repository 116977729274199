















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

enum ProgressStatuses {
	init = 'init',
	progress = 'progress',
	error = 'error',
	succeed = 'succeed'
}
interface Progress {
	status: keyof typeof ProgressStatuses;
	max: number;
	rowIndex: string | number;
	error?: {
		name: string,
		message: string;
	};
	successfulMessage: string;
	percentage?: boolean;
	bulkErrors?: string[];
}

@Component
export default class CsvProgress extends Vue {
	@Prop({
		default: () => ({})
	}) progress:Progress
	@Prop({
		default: false
	}) isMultipleError:boolean

	progressList: Progress[] = []


	@Watch('progress', {deep: true, immediate: true})
	setProgressList() {
		if(this.progress.status) {
			this.progressList.push({...this.progress})
		}
	}

	private get variantSingleProgress() {
		if(!this.progress.status) return ''
		if(this.isMultipleError) return 'success'

		return this.errorList.length ? 'danger': 'success'
	}

	public get isSucceed() {
		return this.progress.status === 'succeed'
	}

	public get progressProcessValue() {
		return this.progressList.reduce((acc, cur) => {
			if(cur.status === 'init') return acc
			if(!this.isMultipleError && cur.status === 'error') return cur.percentage ? cur.rowIndex : acc + 1
			if(cur.status === 'progress' || cur.status === 'succeed') return cur.percentage ? cur.rowIndex : acc + 1
			return acc
		}, 0)
	}

	public get errors() {
		return this.progressList
			.filter(({ status }) => status === 'error')
	}


	public get errorList() {
		return this.errors
			.map(({ error, rowIndex }) => {
				if(error && error.message) return error.message
				return `Row ${rowIndex} is failed`
			})
	}
}
