
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import Multiselect from "vue-multiselect";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { UserContactDetails, CallContactPayload } from '@/store/tasks/types';

const Tasks = namespace("tasks");
const SiteMonitor = namespace("siteMonitor");


@Component({
	components: {
		multiselect: Multiselect,
		VuePerfectScrollbar: VuePerfectScrollbar
	}
})
export default class CallContactTask extends Vue {
	@Getter getUserName: string;
	@SiteMonitor.Action createAuditRecord: any;
	@SiteMonitor.Getter("getEventDetails") eventDetails: any;
	@Tasks.Action fetchCallContacts: (payload: CallContactPayload) => Promise<void>;
	@Tasks.Getter("getCallContacts") getCallContacts: UserContactDetails[];

	@Prop() public task!: any;
	@Prop(String) public result!: string;
	@Prop(String) public eventTaskID!: string;

	private renderType: any = { name: "", value: "" };

	private callName: string = "";
	private callList: UserContactDetails[] = [];
	private callListIndex: number = 0;

	private contactRoleRenderType: string = "Contact Role";

	/** submit data to complete Task */
	private submitOutcome(outcome: string): void 
	{
		this.$emit("result-changed", outcome);
		this.$emit("complete");
	}

	private get isContactRoleRenderType(): boolean 
	{
		return this.renderType.name === this.contactRoleRenderType;
	}

	private get currentCaller(): UserContactDetails 
	{
		return this.callList[this.callListIndex];
	}

	private callResultYes(): void 
	{
		const mobile = this.callList[this.callListIndex]["mobile"];
		const telephone = this.callList[this.callListIndex]["telephone"];
		const bothNumbers = mobile && telephone ? true : false;

		if (this.renderType.value === "CallList") {
			// Set the currently selected users fullname to callName
			this.callName = this.callList[this.callListIndex]["fullname"];

			//Complete task with this user called.
			this.submitOutcome(
				`Call ${this.callName} (${mobile} ${
					bothNumbers ? "/" : ""
				} ${telephone}) :  Call Made`
			);
		}
		// If rendertype is call contact role
		else if (this.isContactRoleRenderType) {
			// Set the currently selected users fullname to callName
			this.callName = this.callList[this.callListIndex]["fullname"];

			//Complete task with this user called.
			this.submitOutcome(`Contacts for ${this.renderType.value.role} were displayed and have been closed.`);
		}
		// If rendertype is either FireFighters or Police
		else if (
			this.renderType.value === "Fire Dept" ||
			this.renderType.value === "Police"
		) {
			this.submitOutcome(
				` Call ${this.renderType.name} "${telephone}" ${this.getUserName} entry: "Call Made"`
			);
		}
	}

	private async callResultNo(): Promise<void> 
	{
		const mobile = this.callList[this.callListIndex]["mobile"];
		const telephone = this.callList[this.callListIndex]["telephone"];
		const bothNumbers = mobile && telephone ? true : false;

		if (this.renderType.value == "CallList") {
			// Set the currently selected users fullname to callName
			this.callName = this.callList[this.callListIndex]["fullname"];

			//Check if we have ran out of users within the call list.
			if (this.callListIndex >= this.callList.length - 1) {
				//If no users are left, set complete task to be no users picked up.
				this.submitOutcome("No users picked up");

				// If it's not the last user and they didn't pick up the phone
			} else {
				// Increment to the next user
				this.callListIndex++;
			}

			// Create audit saying that the user didn't pick up the phone.
			await this.createAuditRecord({
				eventId: this.eventDetails.eventID,
				eventRecordTypeId: 111,
				details: `Call ${this.callName} (${mobile} ${
					bothNumbers ? "/" : ""
				} ${telephone}) : No Answer`
			});
		}
		// If rendertype is either FireFighters or Police
		else if (
			this.renderType.value == "Fire Dept" ||
			this.renderType.value == "Police"
		) {
			// Complete Task with msg below
			this.submitOutcome(
				` Call ${this.renderType.name} "${telephone}" ${this.getUserName} entry: "No Answer"`
			);
		}
	}

	private callResultExit(): void 
	{
		const outcome = `The ${this.renderType.name} has not been setup or configured correctly.`;
		this.submitOutcome(outcome);
	}

	private async mounted(): Promise<void> 
	{
		await this.configureCallContacts();
	}

	@Watch("eventDetails.groupID")
	private async configureCallContacts(): Promise<void>
	{
		// Get taskText object thats created in setup.
		const settings = this.task.taskData;

		// Check if settings is not undefined and contains the desired key.
		if (settings !== null && settings !== undefined) {
			// Insert settings Name value in this.renderType
			this.renderType = settings;

			const payload = {
				GroupId: this.eventDetails.groupID,
				All: false,
				Parent: true,
				CallType: settings.value,
				EventTaskId: this.eventTaskID,
				value: undefined,
			};

			if (this.isContactRoleRenderType) {
				payload.CallType = this.renderType.name;
				payload.value = this.renderType.value;
			}
			
			await this.fetchCallContacts(payload);

			this.getCallContacts.forEach(element => {
				if (this.eventTaskID == element.eventTaskID || this.isContactRoleRenderType && !this.callList.find(contact => contact.userID == element.userID)) {
					this.callList.push(element);
				}
			});
		}
	}
}
