
    import Vue from 'vue';
    import { Component } from 'vue-property-decorator';
    import api from "@/services/api.service";
    import SamlSettings from '@/types/sv-data/SamlSettings';
    import { Getter } from 'vuex-class';

    @Component({
    })
    export default class SingleSignOnSettings extends Vue {
		@Getter getUserTenantGroupId: number;

        private readonly currentOrigin: string = window.location.origin;

        private isLoading: boolean = false;
        private isSaving: boolean = false;
        private form: SamlSettings = {
            isEnabled: false,
            issuer: '',
            metadataUrl: '',
            loginUrl: '',
            redirectUrl: '',
            certificate: ''
        };

        private get ssoUrl(): string {
            return api.getOriginalApiUrl + '/login/saml?groupId=' + this.getUserTenantGroupId;
        }

        private async created() {
            this.isLoading = true;
            try {
				this.form = await api.getSamlSettings();
			} catch (ex) {
				this.isLoading = false;
				throw ex;
			}

            this.isLoading = false;
        }

        private async onSubmit($event: Event) {
            this.isSaving = true;

            // we save front end base url to know where to redirect user after sso authentication
            this.form.redirectUrl = this.currentOrigin;

			try {
				await api.setSamlSettings(this.form);
			} catch (ex) {
				this.isSaving = false;
				throw ex;
			}

            this.isSaving = false;
        }

        private copySSOUrl() {
            const textArea = document.createElement("textarea");
            textArea.value = this.ssoUrl;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);

            textArea.focus();
            textArea.select();

            document.execCommand("copy");

            document.body.removeChild(textArea);
        }
    }
