
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import { namespace } from "vuex-class";
    import TextHighlight from "vue-text-highlight";
    import VuePerfectScrollbar from "vue-perfect-scrollbar";
    import { debounce } from "lodash";
    import AreaTreeSelect from '@/components/form/AreaTreeSelect.vue';
	import { Response } from '@/store/responses/types';
	import api from "@/services/api.service";

	const AlarmPoints = namespace('alarmPoints');

    const PAGE_SIZE: number = 100;
	const WAIT_DEBOUNCE_INTERVAL: number = 1000;
	const TIMEOUT_INTERVAL: number = 200;

	@Component({
		components: {
            VuePerfectScrollbar: VuePerfectScrollbar,
            "text-highlight": TextHighlight,
            "area-tree-select": AreaTreeSelect,
		}
	})
	export default class AlarmPointSelect extends Vue {
		$refs!: {
			areaTreeScrollBar: any;
			toggleBox: HTMLDivElement;
			searchBox: HTMLInputElement;
		};

		@AlarmPoints.State("responses") responses: any[];

		@AlarmPoints.Mutation setResponses: (responses: any[]) => void;

		@AlarmPoints.Action responseSearch: ({ groupId, query }) => void;


        @Prop({ type: Boolean, default: false })
		public multiple: boolean;

        @Prop()
		// v-model
		// when multiple is true, then expected type Response[]
		// when multiple is false, then expected type Response
        public value: any;

		public pageNumber: number = 1;
		public selectedGroup: number = null;
        public searchPageSize: number = PAGE_SIZE;

        private isInputFocused: boolean = false;
		private selectedResponses: Response[] = [];
        private searchQuery: string = null;
        private isLoading: boolean = false;

        private menuStyle: any = {
            top: "0px",
            left: "0px",
            width: "auto"
        }

        // noinspection JSUnusedLocalSymbols
        private async mounted(): Promise<void> {
            if (this.value) {
                await this.updateFromValue();
            }

            this.setResponses([]);
			this.selectedGroup = null;
			this.searchQuery = null;
        }

        @Watch("value")
        private async updateFromValue(): Promise<void> {
            if (!this.selectedResponses) {
                this.selectedResponses = [];
            }

            if (this.value) {
                if (this.multiple) {
                    // Value set, and we are using an array of alarm points

                    // Remove any from selected responses list that don't appear in the model
                    this.selectedResponses = this.selectedResponses.filter(response => !this.value.some(responseId => responseId == response.responseID));

					// Find and add any responses that are in the model but not the selected responses list
					for (let selectedResponse of this.value)
					{
						// if value is updated from ReportBuilder filter, it has 'id' field.
						// and it is possible that the responseID is not
						// defined in the object.
						if(!!selectedResponse.id && !selectedResponse.responseID) {
							// value set from ReportBuilder filter may not have the responseID field
							selectedResponse.responseID = selectedResponse.id;
						}

                        if (this.selectedResponses.some(response => response.responseID == selectedResponse.responseID)) {
                            continue;
                        }

						await this.updateSelectedResponses(selectedResponse.responseID);
					}
                } else {
					// if value is updated from ReportBuilder filter, it has 'id' field.
					// and it is possible that the responseID is not
					// defined in the object.
					if(!!this.value.id && !this.value.responseID) {
						// value set from ReportBuilder filter may not have the responseID field
						this.value.responseID = this.value.id;
					}

                    // Already current value - do nothing
                    if (this.selectedResponses.some(response => response.responseID == this.value.responseID)) {
                        return;
                    }
					this.selectedResponses = [];
					await this.updateSelectedResponses(this.value.responseID);
                }

            } else {
                // Value is null, so reset list
                this.selectedResponses = [];
            }
        }

		private async updateSelectedResponses(responseId: number): Promise<void> {
			let response = await api.responseGet(responseId);
			if (response) {
				// used in ReportBuilder filter
				// add the id field.
				response.id= response.responseID;
				this.selectedResponses.push(response);
			}
		}

        private selectResponse(response): void {
            if (!response) {
                return;
            }

			// get the Report filters to work when
			// user clicks on Run Report.
			response.id = response.responseID;

			if (!this.multiple && this.selectedResponses.length > 0) {
                this.selectedResponses = [];
            } else {
                if (this.selectedResponses.some(alreadySelected => alreadySelected.responseID === response.responseID)) {
                    return;
                }
            }

            this.selectedResponses.push(response);
            this.searchQuery = "";

            if (this.multiple) {
                this.$emit("input", this.selectedResponses);
            } else {
                this.$emit("input", response);
                this.$refs.searchBox.blur();
            }

            this.onBlur();
        }

        private deselectResponse(response): void {
            const responseIndex = this.selectedResponses.findIndex(alreadySelected => alreadySelected.responseID === response.responseID);
            if (responseIndex > -1) {
                this.selectedResponses.splice(responseIndex, 1);
            }

            if (this.multiple) {
                this.$emit("input", this.selectedResponses);
            }
        }

        private async displayDropdown(): Promise<void> {
            const left = (this.$refs.toggleBox.offsetParent as HTMLElement).offsetLeft +
                this.$refs.toggleBox.offsetLeft;

            const top = this.$refs.toggleBox.getBoundingClientRect().top;
            this.menuStyle.top = top + "px";
            this.menuStyle.left = left + "px";
            this.menuStyle.width = (this.$refs.toggleBox.offsetWidth + 2) + "px";

            const maxHeight = document.documentElement.clientHeight - top - 40;
            this.menuStyle.height = Math.min(250, maxHeight) + "px";

            this.isInputFocused = true;
        }

        private onBlur(): void {
            setTimeout(() => {
                this.isInputFocused = false;
                this.searchQuery = "";
            } , TIMEOUT_INTERVAL);
        }

        private setSearchFocus(): void {
            if (!this.isInputFocused) {
                this.$refs.searchBox.focus();
            }
        }

        public get areaResponses(): Response[] {
		    if (!this.filterActive)
		    {
			    return this.responses;
		    }

			return this.responses.filter(
			    response => response.groupID == this.selectedGroup
		    ).slice(0, this.searchPageSize);
	    }

        private get filterActive(): boolean {
		    return !!this.searchQuery;
	    }

        @Watch("selectedGroup")
	    public async querySearch(newValue: number, oldValue: number): Promise<void> {
		    try
		    {
			    this.isLoading = true;

			    if(newValue != oldValue){
				    this.setResponses([]);
			    }

			    await this.responseSearch({
					groupId: this.selectedGroup,
					query: null
				});

		    }
		    catch(err)
		    {
			    console.error("Error Loading Area Alarm Points", err);
			    this.setResponses([]);
		    }
		    finally
		    {
			    this.isLoading = false;
		    }
	    }

        @Watch('searchQuery')
	    public debouncedFilterSearch: (() => Promise<void>)  = debounce(async () => {
		    await this.searchForDebounce();
	    }, WAIT_DEBOUNCE_INTERVAL);

	    private async searchForDebounce(): Promise<void> {
		    try
		    {
			    this.isLoading = true;
			    await this.responseSearch({ groupId: this.selectedGroup, query: this.searchQuery });
		    }
		    catch(err)
		    {
			    console.error("Error searching for alarm points", err);
		    }
		    finally
		    {
			    this.isLoading = false;
		    }
	    }
	}
