import { MutationTree } from "vuex";
import Vue from "vue";
import { TourState, Tour, PredefinedTour, cameraStreaming, isActiveCell } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<TourState> = {

	/** Tour setup */
	setAllTours(state, tours: any) {
		state.Tour = tours;
	},
	setAllDevices(state, devices: any) {
		state.DeviceList = devices;
	},
	unsetTour(state, tourId: number) {
		const tourIndex = state.Tour.findIndex(x => x.tourId === tourId);
		state.Tour.splice(tourIndex, 1);
	},
	addDevice(state, data: any) {
		const tourIndex = state.Tour.findIndex(x => x.tourId === data.tourId);
		state.Tour[tourIndex].tourSteps.push(data.device);
	},
	addSearchDevices(state, data: any) {
		state.searchedDeviceList = data;
	},

	/** Predefined Tours */
	setPredefinedTour(state, data: PredefinedTour) {
		state.PredefinedTour = data;
	},
	setPredefinedTourDurtationType(state, isDurationBased: boolean) {
		state.PredefinedTour.durationBasedCamera = isDurationBased;
	},
	/** Remove X time from current time */
	updatePredefinedTourDuration(state, time: number) {
		state.PredefinedTour.predefiendStep.durationTimer -= time;
	},
	/** Set current time to new time */
	setPredefinedTourDurationTimer(state, time: number) {
		state.PredefinedTour.predefiendStep.durationTimer = time;
	},
	/** Remove X time from current time */
	updatePredefinedTourTimeout(state, time: number) {
		state.PredefinedTour.tourTimeout -= time;
	},
	/** Set current time to new time */
	addPredefinedTourTimeout(state, time: number) {
		state.PredefinedTour.tourTimeout = time;
	},
	setPredefinedTourCamera(state, device: any) {
		state.PredefinedTour.currentCamera = device;
	},
	updatePredefinedTourPausedState(state, isPaused: boolean) {
		state.PredefinedTour.isPausedTour = isPaused;
	},
	addPredefinedTourIteration(state, itergation: number) {
		state.PredefinedTour.currentIteration += itergation;
	},
	addPredefinedTourCameraIndex(state, cameraIndex: number) {
		state.PredefinedTour.currentCameraIndex = cameraIndex;
	},
	setTriggeredActiveCell(state, confirmed: boolean) {
		state.PredefinedTour.cellTrigger = confirmed;
	},
	activeCellDisable(state) {
		state.PredefinedTour.isActive = null;
	},
	activeCellEnable(state, object: isActiveCell) {
		state.PredefinedTour.isActive = object;
	},
	setTriggeredIsStreaming(state, object: cameraStreaming) {
		var index = state.PredefinedTour.camerasStreaming.findIndex(x => x.index == object.index)
		if(index == -1) {
			state.PredefinedTour.camerasStreaming.push(object);
		} else {
			state.PredefinedTour.camerasStreaming[index].isStreaming = object.isStreaming;
		}
	},
	setIsInTourState(state, isInTour: boolean) {
		state.isInTour = isInTour;
	},
	clearPredefinedStreamingCameras(state) {
		state.PredefinedTour.camerasStreaming = [];
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
}