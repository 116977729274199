import { ReportState } from "./types";

export function getDefaultState(): ReportState {
    return {
        AvailableReports: [],
        Report: {},
        RelativeHoursToReport : 168,
        CurrentReportUrl : "",
        ResultCount : 10,
        ReportLoadingStatus : false,
        ReportErrorStatus : false,
        ReportApiEndpoint : "",
        ReportTypes: [],
        isDateRangeRelative: true
    }
}
