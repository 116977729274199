
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";

// tinymce imports
import tinymce, { Editor, RawEditorOptions } from "tinymce";
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/plugins/advlist';
import "tinymce/plugins/autolink";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import 'tinymce/plugins/link';
import "tinymce/plugins/preview";
import "tinymce/plugins/searchreplace";
import 'tinymce/plugins/table';
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/anchor";
import "tinymce/models/dom";
import "tinymce/plugins/visualblocks";

import VuePerfectScrollbar from "vue-perfect-scrollbar"
import SearchDropDown from "@/components/form/SearchDropDown.vue"
import { TenantFeatures } from "@/store/types";
import { FormatMargin, FormatStyle } from "@/types/format"
import RichTextEditorPlaceholder from "@/types/RichTextEditorPlaceholder";
import { debounce } from "lodash";
import { SessionResource } from "@/store/sessions/types";

const SessionStore = namespace("sessions");

@Component({
	components: {
		"scrollbar": VuePerfectScrollbar,
		"search-dropdown": SearchDropDown,
	}
})

export default class RichTextEditor extends Vue {
	@SessionStore.Action updateSession: ({resourceId: SessionResource}) => Promise<void>;

	@Ref() editorContainer!: HTMLTextAreaElement;

	// Store
	@Getter isFeatureEnabled!: (featureKey: TenantFeatures) => boolean;

	// Props
	@Prop({ type: String, default: "" })
	public value!: string;

	@Prop({ type: Boolean, default: false })
	public readonly!: boolean;

	@Prop({ default: null })
	public minHeight!: string | null;

	@Prop({ type: Boolean, default: false })
	public userHistoryOnly!: boolean;

	@Prop({ type: Boolean, default: false })
	public applyMaxHeight!: boolean;

	@Prop({ type: Array, default: () => null })
	public placeholders!: RichTextEditorPlaceholder[] | null;

	@Prop({ type: Array, default: () => null })
	public manualFields!: RichTextEditorPlaceholder[] | null;

	@Prop({ type: Boolean, default: false})
	public isPrintTemplate!: boolean;

	@Prop({ default: null })
	public formatMargin!: FormatMargin | null;

	@Prop({ default: null })
	public formatStyles!: FormatStyle | null;

	@Watch("value")
	private debounceOnValueChanged = debounce(async () => await this.onValueChanged(), 30000);

	private async onValueChanged() {
		await this.updateSession({
			resourceId: SessionResource.StandardLoginSession
		});
	}

	// Data
	private editor: Editor | null = null;
	private focusWhenReady: boolean = false;
	private marginModalOpen: boolean = false;
	// Source of truth for the current margins
	private currentMargin: FormatMargin = {
		top: 5,
		right: 5,
		bottom: 5,
		left: 5
	};
	// Margins to use when editing to ensure they don't overwrite existing margins until saved
	private editingMargin: FormatMargin = {
		top: 25,
		right: 25,
		bottom: 25,
		left: 25
	};
	// Editor font formats
	private currentFormatStyles: FormatStyle = {
		fontSize: "10pt",
		fontName: "Helvetica,Arial,sans-serif"
	}

	// Editor Options
	// TinyMCE plugins
	private plugins: string[] =
		[
			"advlist", "autolink", "fullscreen", "image", "lists", "link", "searchreplace", "table", "pagebreak", "anchor", "visualblocks"
		];

	// Specific styles to be passed into the TinyMCE editor.
	private get editorStyles(): string {
		return `
			body
			{
				font-family:${this.currentFormatStyles.fontName};
				font-size: ${this.currentFormatStyles.fontSize};
				margin-top: ${this.currentMargin.top}mm;
				margin-left: ${this.currentMargin.left}mm;
				margin-bottom: ${this.currentMargin.bottom}mm;
				margin-right: ${this.currentMargin.right}mm;
				background-color: white !important;
			}
			.mce-offscreen-selection
			{
				display: none;
			}
			.mce-pagebreak {
				width: 100%;
				height: 1px;
				display: block;
				border-top: 1px dashed darkgray;
			}
			.placeholder {
				display: inline-block;
				padding: .25em .4em;
				font-size: 75%;
				font-weight: 700;
				line-height: 1;
				text-align: center;
				white-space: nowrap;
				vertical-align: baseline;
				border-radius: .25rem;
				color: #fff;
				background-color: #2196f3;
			}
			.manualField {
				display: inline-block;
				padding: .25em .4em;
				font-size: 75%;
				font-weight: 700;
				line-height: 1;
				text-align: center;
				white-space: nowrap;
				vertical-align: baseline;
				border-radius: .25rem;
				color: #fff;
				background-color: #E86908;
			}`
	}

	// Getters
	private get marginsValid(): boolean {
		// Must check if the type is a number as inputting a minus symbol "-" is a string and will pass validation without type check
		return (typeof this.editingMargin.top === "number" && this.editingMargin.top >= 0 && this.editingMargin.top <= 100) &&
			(typeof this.editingMargin.left === "number" && this.editingMargin.left >= 0 && this.editingMargin.left <= 100) &&
			(typeof this.editingMargin.right === "number" && this.editingMargin.right >= 0 && this.editingMargin.right <= 100) &&
			(typeof this.editingMargin.bottom === "number" && this.editingMargin.bottom >= 0 && this.editingMargin.bottom <= 100);
	}

	private handleFormatUpdate(event: any): void {
		let selectedContent = this.editor?.selection.getContent();
		if (event && !selectedContent) {
			switch (event.format) {
				case "fontsize":
					this.currentFormatStyles.fontSize = event.vars.value;
					break;
				case "fontname":
					this.currentFormatStyles.fontName = event.vars.value;
					break;
			}

			this.updateEditorStyle();
			this.$emit("setCssOverrides", this.currentFormatStyles);
		}
	}

	// TinyMCE setup function to be run when the editor is first initialised.
	private onEditorSetup (editor: Editor): void {
		editor.on("input", () => this.$emit("input", editor.getContent()));

		// when a user uses a button emit the content up.
		editor.on("ExecCommand", () => this.$emit("input", editor.getContent()));

		// when a user applies a format, update if necessary
		editor.on("FormatApply", (e) => this.handleFormatUpdate(e));

		// this resolves an issue where you cannot interact with the TinyMCE dialogues within BModal.
		document.addEventListener("focusin", function (e) {
			let target = e.target as Element;
			let closest = target.closest(".tox-tinymce-aux, .tox-dialog, .moxman-window, .tam-assetmanager-root");
			if (closest !== null && closest !== undefined) {
				e.stopImmediatePropagation();
			}
		});

		// add the custom buttons
		if (this.isPrintTemplate) {
			editor.ui.registry.addButton("includeMargins", {
				text: "Margin",
				onAction: this.openMarginSetup
			});
		}
	}

	// Initialise TinyMCE editor on Mounted.
	private async initialiseTinyMCE(): Promise<void> {
		const tinyMCEConfig: RawEditorOptions =
			{
				target: this.editorContainer,
				height: this.minHeight || 550,
				resize: false,
				plugins: this.plugins,
				toolbar: "undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify pagebreak | bullist numlist | link image table | includeHeader usePageNumbers includeMargins",
				content_style: this.editorStyles,
				promotion: false,
				visualblocks_default_state: true,
				pagebreak_separator: `<div class="page-break" style="page-break-before: always; clear:both"/></div>`,
				noneditable_class: "ql-placeholder-content",
				menubar: "edit insert view format table",
				setup: this.onEditorSetup
			};

		this.editor = (await tinymce.init(tinyMCEConfig))[0];
	}

	// Methods
	focusOut(event: FocusEvent): void {
		// Ensure that this event isn't triggered when changing between children, e.g. clicking a button while focused on text
		if (!event.relatedTarget || !this.$el.contains(event.relatedTarget as Element)) {
			this.$emit("blur")
		}
	}

	clickAndFocus(): void {
		if (this.readonly) {
			this.focusWhenReady = true
			this.$emit("focus")
		}
	}

	private closeMarginModal(): void {
		this.marginModalOpen = false;
	}

	private setupStyles(): void {
		if (this.formatMargin != null) {
			this.currentMargin = { ...this.formatMargin };
		}

		if (this.formatStyles != null) {
			this.currentFormatStyles = { ...this.formatStyles };
		}
	}

	private openMarginSetup(): void {
		if (this.formatMargin != null) {
			this.editingMargin = { ...this.currentMargin };
		}

		this.marginModalOpen = true;
	}

	private onMarginInput(): void {
		this.marginModalOpen = !this.marginModalOpen;

		this.currentMargin = { ...this.editingMargin };
		this.$emit("setFormatMargin", this.currentMargin);

		// Update the editor margins when margin modal closes
		this.updateEditorStyle();
	}

	private updateEditorStyle(): void {
		if (this.editor) {
			this.editor.iframeElement!.contentDocument!.getElementsByTagName("style")[0].innerHTML = this.editorStyles;
		}
	}

	private addPlaceholder (placeholder: RichTextEditorPlaceholder) : void {
		let content: string = `<span contenteditable="false" class="placeholder" data-id="${placeholder.id}" data-label="${placeholder.label}"><span contenteditable="false">${placeholder.label}</span></span>`;
		this.editor!.insertContent(content);
		this.$emit("input", this.editor?.getContent());
	}

	private addManualField (manualField: RichTextEditorPlaceholder) : void {
		let content: string = `<span contenteditable="false" class="manualField" data-id="manual-fields.${manualField.id}" data-label="${manualField.label}"><span contenteditable="false">${manualField.label}</span></span>`;
		this.editor!.insertContent(content);
		if(this.manualFields.filter((element) => element.id == manualField.id).length === 0) {
			this.manualFields.push(manualField);
		}
		this.$emit("input", this.editor?.getContent());
	}

	private addCustomManualField(label: string) {
		let key = label.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
			return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
		}).replace(/\s+/g, '');
		this.addManualField({
			id: key,
			label: label
		});
	}

	// Watchers
	@Watch("readonly")
	private onReadonlyChanged(readonly: boolean): void {
		if (this.editor) {
			return;
		}

		this.editor!.readonly = !readonly;

		if (this.focusWhenReady && !readonly) {
			this.focusWhenReady = false
			this.editor!.focus()
		}
	}

	// Vue lifecycle methods
	private async mounted(): Promise<void> {
		this.setupStyles();
		await this.initialiseTinyMCE();
	}

	private beforeDestroy(): void {
		if (!this.editor) {
			return;
		}

		this.currentMargin = {
			top: 25,
			right: 25,
			bottom: 25,
			left: 25
		};

		this.editor = null;
		tinymce.remove();
	}
}
