



























































import { Component, Watch, Prop, Mixins} from "vue-property-decorator";
import getScript from "@/services/insertScript.service";
import GoogleMap from "@/scripts/mapping/map";
import { Getter, Mutation, namespace} from "vuex-class";
import { getMapStyle } from "@/scripts/mapping/mapStyle";
import { SVLocation, UserAssetStatus, MobileEventShareInfo } from "@/store/mobile/types";
import {
	getEnhancedMapping,
	IEnhancedMapping,
	IMarker,
	ILatLng
} from "@sureview/v2-mapping-saas";

import FieldOpsMixin from "@/mixins/FieldOpsMixin";
import { AssetMapLayerItem } from '@/components/mobile-assets/types';
import MapLabel from '@/scripts/mapping/mapLabel';
import api from '@/services/api.service';

import { mapRegionMixins } from '@/mixins'
import mobileApi from "@/services/api.mobile.service";

const {
	getRegionBounds,
	getPolygonOptionsForRegion,
} = mapRegionMixins.methods;

class LocationBounds {
	location: google.maps.LatLng;
	viewport: {
		northeast: google.maps.LatLng;
		southwest: google.maps.LatLng;
	};
}

class NavInfo {
	distance: {
		text: string,
		value: number // will be meters
	};
	duration: {
		text: string,
		value: number // will be in seconds
	};
}

// The interval delay for browser location updating on first page load.
const mountingLocationInterval = 250
// The default interval delay for browser location updating.
const defaultLocationInterval = 10000

const Mobile = namespace("mobile");
const FieldOpsStore = namespace("fieldOps");

declare global {
	interface Window {
		newrelic: any;
		infoWindow: any;
		claimUnclaimHandler: any;
		activateInactivateHandler: any;
		markAsArrived: any;
		markerClickHandler: any;
	}
}

@Component
export default class MobileMap extends Mixins(FieldOpsMixin) {
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
    @Getter("getMapKey") public mapKey: any;

	// Map type
	@Getter getMapType: any;
	@Getter getHideLabels: any;
	@Mutation setMapType: any;
	@Mutation setMapStyle: (style: string) => void;
	@Getter("getMapStyle") mapStyle: string

	// Device location
	@Mobile.State("UserMapOnlineStatus") userMapOnlineStatus: boolean;
	@Mobile.State("DeviceLocation") deviceLocation: any;
	@Mobile.Mutation setDeviceLocation: any;
	@Mobile.Mutation setIsWatchingLocation: (value: boolean) => void;

	//Field Ops
	@FieldOpsStore.Action("loadOnlineAssets") private loadOnlineAssets: () => void;
	@FieldOpsStore.Action("loadAssetTypes") private loadAssetTypes: () => void;
	@FieldOpsStore.Mutation("setTimer") private clearTimer: (timer: null) => void;
	@FieldOpsStore.Mutation("setViewingAssetId") private setViewingAsset: (assetId: number) => void;
	@FieldOpsStore.State("viewingAssetId") private viewingAssetId: number;
    @Mobile.State("UserAssetMapItem") private userMapStatus: UserAssetStatus;

	// Event Share
	@Mobile.State("EventShareItems") private eventItems: MobileEventShareInfo[];
	@Mobile.State("ActiveEventShareItem") private activeEventShareItem: MobileEventShareInfo;
    @Mobile.State("NavigationMode") private navigationMode: string;
    @Mobile.State("NavigateToActiveAlarm") private navigateToActiveAlarm: boolean;
    @Mobile.State("NavigationLocation") private navigationLocation: ILatLng;
    @Mobile.State("FocusedEventID") private focusedEventID: number;
    @Mobile.Mutation("setNavigationLocation") private setNavigationLocation: (location: ILatLng) => void;
    @Mobile.Mutation("setFocusedEventID") private setFocusedEvent: (eventID: number) => void;
	@Mobile.Mutation("toggleRightSideBarVisibility") private toggleRightSideBar: () => void;

	//Regions
	@Mobile.State("Regions") private regions: Map<number, {poly: google.maps.Polygon, label: MapLabel }>;
	@Mobile.Mutation("setRegions") private setRegions: (regions: Map<number, {poly: google.maps.Polygon, label: MapLabel }>) => void;

	// Local vars
	public directionsService: google.maps.DirectionsService;
	public directionsDisplay: google.maps.DirectionsRenderer;
	public mappingMarkers: IEnhancedMapping | null = null;
	public enhancedMapping: GoogleMap | null = null;
	public mapType: string = "";
	public minPatrolZones: number = 1;
	private mapInitialized: boolean = false;
	private userPreviousLocation: google.maps.LatLng;
	private userPreviousDestination: google.maps.LatLng;
	private deviceMarker: google.maps.Marker;
	private lastUpdatedGuardLocation = Date.now();
	private geolocationWatchNumber: number;
	public userMarker: IMarker | null = null;
	private updateLocationTimeout: number | null;
	private fieldOpsPoll = null;
	private browserLocationUpdater = null;
	private currentLocationInterval = mountingLocationInterval;
	private selectedAsset: AssetMapLayerItem | null = null;
	private eventItemMarkers: Map<number, IMarker> = new Map<number, IMarker>();
	private navLocInfo: NavInfo = {
		distance: null,
		duration: null
	};
	private MapLabel: any = null;

	private activeCircle: google.maps.Circle = null;

	@Prop(Number)
	public zoomLevel = 17;

	@Prop(Boolean)
	public prototype: boolean;

	@Prop({type:Boolean, default: false})
	private inApp: boolean;

	@Prop({type: Boolean, default: true})
	public liveGpsTrack: boolean;

	private get isAreaRegionsEnabled(): boolean {
		return this.getFeature(["Areas", "Regions"]);
	}

	private get isMobileUsersEnabled() {
		return this.getFeature(["MobileUsers"]);
	}

	private get isMobileFieldOpsEnabled() {
		return this.getFeature(["Mobile", "FieldOps"]);
	}

	private get selectedItemImageUrl(){
		const icon = this.getIconForAssetType(this.selectedAsset, true);
		return icon.url
	}

	private panToAsset(){
		if(!this.selectedAsset)
			return;

		this.enhancedMapping.map.panTo(this.selectedAsset.latLng);
	}

	private get userAssetId() : number | null {
        return this.userMapStatus ? this.userMapStatus.assetId : null;
    }

	private get iconClassForNavMode() : string {
		switch(this.navigationMode){
			case "WALKING":
				return "fa-walking"
			case "DRIVING":
				return "fa-car";
			case "BICYCLING":
				return "fa-bicycle";
			default:
				return "";
		}
	}


	@Watch("activeEventShareItem")
	private activeEventShareItemWatch(){
		if(!this.activeEventShareItem && this.activeCircle){
			this.activeCircle.setMap(null);
			this.activeCircle = null;
		}
	}

	@Watch("focusedEventID")
	private focusedEventIdWatch(){
		if(this.focusedEventID){
			const event = this.eventItems.find(x => x.eventID == this.focusedEventID);
			if(event){
				this.panToCenter(event.latLng);
				this.enhancedMapping.map.setZoom(17);
			}
			this.setFocusedEvent(null);
		}
	}

	@Watch("navigationLocation")
	private async toggleNavigationToAlarm() {
		if (this.deviceLocation && this.navigationLocation) {
			this.showDirections(this.deviceLocation, this.navigationLocation);

			//Pan the map to the end location then focused back to the user location
			this.panToCenter(this.navigationLocation);
			if(this.navigationMode != "OFF")
				setTimeout(() => this.panToCenter(this.deviceLocation), 2500);
			return;
		}
		this.hideDirections();
	}

	@Watch("navigationMode")
	private async changeNavigationMode() {
		if (this.navigationMode === "OFF" || !this.navigationMode) {
			this.hideDirections();
		} else {
			this.showDirections(this.deviceLocation, this.navigationLocation);
		}
	}

	@Watch("assetAndUsersItems", { deep: true })
	@Watch("eventItems", { deep: true })
	private async displayFieldOpsItems() {
		if(!this.enhancedMapping){
			setTimeout(() => this.displayFieldOpsItems(), 50);
			return;
		}
		const assetMarkersToRemove = new Map(this.assetItemMarkers);
		const trackedMarkersToRemove = new Map(this.trackedAssetItemMarkers);
		const eventMarkersToRemove = new Map(this.eventItemMarkers);

		if(this.assetAndUsersItems){
			this.assetAndUsersItems.forEach(item => {
				try 
				{
					//Clear the selected asset if it is no hidden otherwise reset to get any status changes
					if(this.selectedAsset && item.assetId === this.selectedAsset.assetId){
						if(item.hideOnMap){
							this.selectedAsset = null;
						} else {
							this.selectedAsset = {...item};
						}
					}

					// If the marker does not have a location, do not try to draw it, or if the marker is the current mobile user
					if (!item.latLng || !item.latLng.lat || !item.latLng.lng || (this.userAssetId && item.assetId === this.userAssetId)) {
						return;
					}

					if(item.isTrackedAsset)
					{
						if (this.trackedAssetItemMarkers.has(item.trackedAssetId)) {
							trackedMarkersToRemove.delete(item.trackedAssetId);
							const marker = this.trackedAssetItemMarkers.get(item.trackedAssetId);
							marker.moveTo(item.latLng);
						} else {
							const marker = this.createMarkerForAsset(item, true);
							this.trackedAssetItemMarkers.set(item.trackedAssetId, marker);
						}
					}
					else
					{
						if (this.assetItemMarkers.has(item.assetId) ) {
							const marker = this.assetItemMarkers.get(item.assetId);
							marker.visible = !item.hideOnMap
							marker.moveTo(item.latLng);
							assetMarkersToRemove.delete(item.assetId);
						} else {
							const marker = this.createMarkerForAsset(item, !item.hideOnMap);
							this.assetItemMarkers.set(item.assetId, marker);
						}
					}
				}
				catch(e){
					console.error("Asset " + item.title +" could not be added: " + e);
				}
			});
		}
		else
		{
			this.clearAssetMarkers();
		}

		if(this.eventItems){
			this.eventItems.forEach(item => {
				try {
					// If eventItem does not have a lat long we cannot plot it.
					if (!item.latLng || !item.latLng.lat || !item.latLng.lng) { 
						return
					}
					var marker: any;
					if(this.eventItemMarkers.has(item.eventID)){
						marker = this.eventItemMarkers.get(item.eventID);
						marker.state = item.state === 2 ? "processing" : "active";
						marker.moveTo(item.latLng);
						eventMarkersToRemove.delete(item.eventID);
					} else {
						marker = this.mappingMarkers.createMarker(
							this.enhancedMapping,
							item.eventID,
							null, // eventRecordID
							4, // mapLayerItemTypeId: 1 for alarm
							item.eventID, // object id
							item.eventAreaTitle + " - " +  item.eventDescription,
							item.latLng,
							null, // region
							null, // minElevation
							null, // maxElevation
							true // visible: boolean
						);
						marker.state = item.state === 2 ? "processing" : "active";
						marker.isClickable = true;
						marker.click = () => {};

						this.eventItemMarkers.set(item.eventID, marker);
					}
					if(item.state === 2){
						if(this.activeCircle){
							this.activeCircle.setMap(null);
							this.activeCircle = null;
						}
						this.activeCircle = new google.maps.Circle({
								strokeColor: "#0d87e9",
								strokeOpacity: 0.8,
								strokeWeight: 2,
								fillColor: "#0d87e9",
								fillOpacity: 0.05,
								map: this.enhancedMapping.map,
								center: item.latLng,
								radius: 50,
								zIndex: 50
							});
					}
				}			
				catch (e) {
					console.error("Event item " + item.eventID + " could not be added: " + e);
				}
			});
		}

		//Clear out any markers that don't exist anymore
		eventMarkersToRemove.forEach(m => {
			try {
				m.remove();
				this.eventItemMarkers.delete(m.eventID)
			}
			catch (e) {
				console.error("Failed to remove event marker: " + e )
			}
		});

		// Clear out markers that are offline/hidden
		assetMarkersToRemove.forEach(m => {
			try {
				if(this.selectedAsset && this.selectedAsset.assetId == m.assetId){
					this.selectedAsset = null;
				}
				m.remove();
				this.assetItemMarkers.delete(m.assetId);
			}
			catch (e) {
				console.error("Failed to remove asset marker: " + e)
			}
		});

		trackedMarkersToRemove.forEach(m => {
			try 
			{
				m.remove();
				this.trackedAssetItemMarkers.delete(m.assetId);
			}
			catch (e) 
			{
				console.error("Failed to remove tracked asset marker: " + e)
			}
		});
	}

	private createMarkerForAsset(asset: AssetMapLayerItem, visible: boolean) {
		if(!asset)
			return;

		const marker = this.mappingMarkers.createMarker(
			this.enhancedMapping,
			asset.isTrackedAsset ? asset.trackedAssetId : asset.assetId,
			null, // eventRecordID
			this.getMarkerType(asset), // mapLayerItemTypeId: number | string | MarkerType
			asset.isTrackedAsset ? asset.trackedAssetId : asset.assetId, // object id
			asset.title,
			asset.latLng,
			null, // region
			null, // minElevation
			null, // maxElevation
			visible // visible: boolean
		);

		if(!asset.isTrackedAsset)
		{
			marker.isClickable = true;

			marker.click = () => {
				this.setSelectedItem(asset);
			};
		}

		return marker;
	}

	private async setSelectedItem(value: AssetMapLayerItem | null) {
		if(this.selectedAsset == value)
			return;

		// clear previously selected
		if (this.selectedAsset && this.assetItemMarkers.has(this.selectedAsset.assetId)) {
			this.setMarkerIconForItem(this.selectedAsset.assetId, false);
		}

		if (value && value.assetId){
			this.setMarkerIconForItem(value.assetId, true);
		}else {
			this.selectedAsset = null;
			this.setViewingAsset(null)
			return;
		}

		const assetIndex = this.assetAndUsersItems.findIndex(ast => ast.assetId === value.assetId);
		if(assetIndex >= 0) {
			this.selectedAsset = {...this.assetAndUsersItems[assetIndex]};
		}

		this.setViewingAsset(value ? value.assetId : null);
		this.panToAsset();
	}

	@Watch("viewingAssetId")
	private async viewingAssetChanged() {
		if(!this.viewingAssetId && this.selectedAsset){
			this.setSelectedItem(null);
		} else if (!this.selectedAsset || (this.selectedAsset && this.selectedAsset.assetId !== this.viewingAssetId)) {
			const assetIndex = this.assetAndUsersItems.findIndex(ast => ast.assetId === this.viewingAssetId);
			if(assetIndex >= 0){
				this.setSelectedItem(this.assetAndUsersItems[assetIndex]);
			}
		}
	}

	async mounted() {
		this.init();
		this.setMapStyle("dark");
		this.setMapType("roadmap");

		if ((this.prototype || !this.inApp) && !this.browserLocationUpdater) {
			this.currentLocationInterval = mountingLocationInterval;
			this.triggerGetCurrentPositionRefresh();
		}
		if(this.deviceLocation && this.navigationLocation && this.navigationMode != "OFF"){
			this.showDirections(this.deviceLocation, this.navigationLocation)
		}
	}

	async beforeDispose(){
		this.clearTimeout();
	}

	async beforeDestroy() {
		this.clearTimeout();

		// Clear the GPS watch so we're no longer doing it when the map is cleared or the page changes
		navigator.geolocation.clearWatch(this.geolocationWatchNumber);
		clearTimeout(this.updateLocationTimeout);
		this.setIsWatchingLocation(false);
		clearInterval(this.fieldOpsPoll);
		this.fieldOpsPoll = null;
		this.clearTimer(null);
		if(this.regions){
			this.regions.forEach(m => {
				m.poly.setMap(null);
				if(m.label)
					m.label.onRemove();
			})
			this.setRegions(null);
		}
	}

	@Watch("mapStyle")
	private mapStyleUpdated(){
		if(this.enhancedMapping && this.enhancedMapping.map){
			this.enhancedMapping.map.setOptions({
				styles: this.mobileMapStyle
			});
		}
	}

	@Watch("liveGpsTrack")
	private triggerGpsTracking(){
		if(this.liveGpsTrack) {
			if (navigator.geolocation && !this.geolocationWatchNumber) {
				this.geolocationWatchNumber = navigator.geolocation.watchPosition(this.foundLocation, this.locationError, {
					enableHighAccuracy: true
				});
				this.setIsWatchingLocation(true);
			}

			if(this.userMarker) {
				this.userMarker.remove();
			}

			if(this.enhancedMapping.map && this.deviceLocation){
				this.enhancedMapping.map.panTo(this.deviceLocation);
			}
		} else {
			this.setIsWatchingLocation(false);
			navigator.geolocation.clearWatch(this.geolocationWatchNumber);
			this.geolocationWatchNumber = null;
		}
	}

	private get mobileMapStyle(): any[] {
		// Remove all transit stations from the map (too much for mobile interface)
		var mapStyles = getMapStyle(this.getMapType, this.getHideLabels, this.mapStyle);
		mapStyles.push({
			featureType: "transit.station",
			stylers: [{ visibility: "off" }]
		});

		return mapStyles;
	}

	private async init() {
		await getScript(
			`https://maps.googleapis.com/maps/api/js?key=` +
				`${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}` +
				`&libraries=drawing,places,geometry`
		);
		this.MapLabel = require('@/scripts/mapping/mapLabel').default;
		// Create the directions service
		this.directionsService = new google.maps.DirectionsService();
		this.directionsDisplay = new google.maps.DirectionsRenderer({
			suppressMarkers: true,
			preserveViewport: true,
			polylineOptions: {
				strokeColor: "#117C8F",
				strokeWeight: 5
			}
		});

		// Default position of the map is 0, 0
		let pos: LocationBounds = {
			location: new google.maps.LatLng(0, 0),
			viewport: null
		};

		//If we're not in prototype mode, use the device's location (GPS)
		if (!this.prototype) {
			if (navigator.geolocation) {
				this.geolocationWatchNumber = navigator.geolocation.watchPosition(this.foundLocation, this.locationError, {
					enableHighAccuracy: true
				});
				this.setIsWatchingLocation(true);
			}
		}

		if(!this.liveGpsTrack){
			this.triggerGpsTracking()
		}



		// Create the Google Map
		this.enhancedMapping = new GoogleMap(
			this.$refs.mappingContainer as HTMLElement,
			pos.location,
			this.zoomLevel,
			false,
			this.getMapType,
			this.mobileMapStyle,
			true
		);

		// Fit the bounds of the map
		if (pos.viewport) {
			this.enhancedMapping.map.fitBounds(new google.maps.LatLngBounds(pos.viewport.southwest, pos.viewport.northeast));
		}

		this.enhancedMapping!.registerEvent("onClick", (latLong: google.maps.LatLng) => {
			this.setUserLocation(latLong);
		});

		if(!this.mappingMarkers){
			this.mappingMarkers = await getEnhancedMapping("google", this.mapKey);
		}

		if(this.isMobileFieldOpsEnabled) {
			this.loadAssetTypes();
			this.loadOnlineAssets();
			this.clearTimer(null); //clear out
			if(this.fieldOpsPoll){
				clearInterval(this.fieldOpsPoll);
				this.fieldOpsPoll = null;
			}
			this.fieldOpsPoll = setInterval(async () => {
				this.loadOnlineAssets();
			}, 5000);
		}

		if(this.isAreaRegionsEnabled){
			this.loadRegions();
		}
	}

	private async loadRegions() {
		var regions = await api.getAllRegions();
		var regionsToUpdate = new Map<number, {poly: google.maps.Polygon, label: MapLabel }>();
		//Generate the polygons for every region that has a path
		regions.filter(x => x.path).forEach(region => {
        	const polygonOptions = getPolygonOptionsForRegion(region);
			const regionPoly = new google.maps.Polygon({
				map: this.enhancedMapping!.map,
				paths: JSON.parse(region.path),
				...polygonOptions
        	});

			google.maps.event.addListener(regionPoly, 'click', (event) => {
				this.setUserLocation(event.latLng);
			});


			const position = getRegionBounds(regionPoly.getPath()).getCenter();
			const label = new this.MapLabel(
			{
				text: region.areaDetails,
				position: position,
				fontSize: 12,
				fontColor: "black",
				labelInBackground: true,
				strokeColor: region.borderColor,
				map: this.enhancedMapping!.map
			});
			label.set('position', position);
			regionsToUpdate.set(region.groupId, { poly: regionPoly, label: label });
		});
		this.setRegions(regionsToUpdate);
	}

	private async setUserLocation(latLong: google.maps.LatLng){
		if(this.liveGpsTrack)
			return;

		if (this.userMarker != null) {
			this.userMarker.remove();
			this.userMarker = null;
		}

		if(!this.mappingMarkers){
			this.mappingMarkers = await getEnhancedMapping("google", this.mapKey);
		}

		this.userMarker = this.mappingMarkers.createMarker(
				this.enhancedMapping!,
				null,
				null,
				7,
				null,
				"Location",
				latLong,
				null,
				null,
				null,
				true
			);


		this.userMarker.dragend = (latLong: google.maps.LatLng) => {
			this.manualSetLocationData(latLong);
		};

		this.manualSetLocationData(latLong)
	}

	private manualSetLocationData(latLong: google.maps.LatLng){
		this.setDeviceLocation(latLong);
		this.panToCenter(latLong);
		this.foundLocation(latLong, true)
	}

	/*** MAP Functions ***/
	private showDirections(origin: google.maps.LatLng, destination: google.maps.LatLng) {
		this.userPreviousLocation = origin;
		if (!origin || !destination || !this.navigationMode || this.navigationMode === "OFF") {
			return;
		}

		var request: google.maps.DirectionsRequest = {
			origin: origin,
			destination: destination,
			travelMode: google.maps.TravelMode[this.navigationMode]
		};

		this.directionsService.route(
			request,
			function(result, status) {
				if (status == google.maps.DirectionsStatus.OK) {
					this.directionsDisplay.setMap(this.enhancedMapping.map);
					this.directionsDisplay.setDirections(result);
					this.navLocInfo.distance = result.routes[0].legs[0].distance;
					this.navLocInfo.duration = result.routes[0].legs[0].duration;
				}
			}.bind(this)
		);
	}

	private hideDirections() {
		if (this.directionsDisplay && this.directionsDisplay.getMap()) {
			this.directionsDisplay.set("directions", null);
			this.directionsDisplay.setMap(null);
		}

		this.navLocInfo = {
			duration: null,
			distance: null
		};

		this.userPreviousDestination = new google.maps.LatLng(0, 0);
	}

	private panToCenter(centerPosition: google.maps.LatLng) {
		this.enhancedMapping.map.panTo(centerPosition);
	}

	private parseLatLngToLocation(location: google.maps.LatLng): string {
		if (!location) {
			return null;
		}

		let locationJson: SVLocation = {
			lat: location.lat(),
			lng: location.lng()
		};

		return JSON.stringify(locationJson);
	}

	private async updateLocationHistory(deviceLocation: google.maps.LatLng) {
		const updateInterval = 5000;

		// On first run (usually) this.lastUpdatedGuardLocation won't have been set, so set it, and ensure it triggers straight away
		if (this.lastUpdatedGuardLocation === null || typeof this.lastUpdatedGuardLocation === 'undefined') {
			this.lastUpdatedGuardLocation = Date.now() - (updateInterval * 2);
		}

		clearTimeout(this.updateLocationTimeout);

		// Update the backend with the device location (every x seconds, so less regularly than the Vuex store)
		// If mobile users is enabled only update the location if the user is online
		let triggerRecall: boolean = false;
		if (Date.now() - this.lastUpdatedGuardLocation > updateInterval && (this.isMobileUsersEnabled ? this.userMapOnlineStatus : true)) {
			if (deviceLocation) {
				const deviceLocationString = this.parseLatLngToLocation(deviceLocation);
				await mobileApi.setLocationForGuard(deviceLocationString);
				this.lastUpdatedGuardLocation = Date.now();
			}
			if (!this.inApp) {
				triggerRecall = true;
			}
		} else {
			triggerRecall = true;
		}

		if (triggerRecall) {
			this.updateLocationTimeout = window.setTimeout(() => this.updateLocationHistory(deviceLocation), updateInterval);
		}
	}

	/*** GPS Functions ***/
	public foundLocation(position:any, positionIsLatLng: boolean = false) {
		let deviceLocation: google.maps.LatLng = null;
		if (this.prototype) {
			deviceLocation = new google.maps.LatLng(51.650662, -3.915031); // Hard coded location (Swansea Office) to use for debugging
		} else {
			if (positionIsLatLng) {
				deviceLocation = position;
			} else {
				if (!position || !position.coords) {
					return;
				}
				deviceLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
			}
		}

		// Update vuex with the current device location
		this.setDeviceLocation(deviceLocation);

		if (this.enhancedMapping) {
			if (!this.mapInitialized) {
				this.enhancedMapping.map.setCenter(deviceLocation);
				var markerIcon = {
					url: "images/google-maps-location-32x32.png",
					scaledSize: new google.maps.Size(32, 32),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(16, 16)
				};
				this.deviceMarker = new google.maps.Marker({
					position: deviceLocation,
					map: this.enhancedMapping.map,
					icon: markerIcon
				});
				this.mapInitialized = true;
			} else {
				this.deviceMarker.setPosition(deviceLocation);

				if(this.navigationLocation && this.userPreviousLocation && this.deviceLocation
					&& google.maps.geometry.spherical.computeDistanceBetween(this.userPreviousLocation, this.deviceLocation) > 20){
							this.showDirections(this.deviceLocation, this.navigationLocation);
				}
			}
			//if we now have a location and the map is set to 0,0, update the map to focus on the deviceLocation
			if(this.enhancedMapping.map && !this.enhancedMapping.map.getCenter().lat() && !this.enhancedMapping.map.getCenter().lng()){
				this.enhancedMapping.map.setCenter(deviceLocation);
			}
		}

		if (this.isMobileUsersEnabled) {
			this.updateLocationHistory(deviceLocation);
		}
	}

	public locationError(error) {
		if (navigator.geolocation) {
			this.geolocationWatchNumber = navigator.geolocation.watchPosition(this.foundLocation, this.locationError, {
				enableHighAccuracy: true
			});
		}
	}

	@Watch("browserLocationUpdater")
	private setCurrentLocationInterval(){
		if (this.browserLocationUpdater){
			this.currentLocationInterval = defaultLocationInterval
		}
	}

	private triggerGetCurrentPositionRefresh() : void {
		let timeoutFunction = async function() {
			try {
				navigator.geolocation.getCurrentPosition(pos => {
					this.foundLocation(pos);
				}, err => {
					this.foundLocation(null);
				})
			} catch(e) {
				console.error(e);
			}

			this.triggerGetCurrentPositionRefresh();
		}.bind(this);

		this.clearTimeout();
		this.browserLocationUpdater = setTimeout(timeoutFunction, this.currentLocationInterval);
	}

	private clearTimeout(): void {
		if (this.browserLocationUpdater) {
			clearTimeout(this.browserLocationUpdater);
			this.browserLocationUpdater = null;
		}
	}
}
