var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 form-group p-0 m-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{attrs:{"id":"ChoiceDescriptionGroup"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.choicesData.choiceDescription),expression:"choicesData.choiceDescription"}],class:[
						'form-control',
						{
							invalid:
								_vm.$v.choicesData.choiceDescription.$invalid,
							'error-animated':
								_vm.$v.choicesData.choiceDescription.$error
						}
					],attrs:{"id":"questionInput","type":"text","placeholder":"Enter the task name"},domProps:{"value":(_vm.choicesData.choiceDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.choicesData, "choiceDescription", $event.target.value)}}}),(_vm.$v.choicesData.choiceDescription.$invalid)?_c('span',{staticClass:"small text-danger py-1"},[_vm._v("Task name required")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},_vm._l((_vm.choicesData.choiceOptions.length),function(index,i){return _c('div',{key:i,attrs:{"id":("questionOption" + i)}},[_c('div',{attrs:{"id":"ChoiceDescriptionGroup"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.choicesData.choiceOptions[i]),expression:"choicesData.choiceOptions[i]"}],class:[
									'form-control',
									{
										invalid:
											_vm.$v.choicesData.choiceOptions
												.$each[i] &&
											_vm.$v.choicesData.choiceOptions
												.$each[i].$invalid,
										'error-animated':
											_vm.$v.choicesData.choiceOptions
												.$each[i] &&
											_vm.$v.choicesData.choiceOptions
												.$each[i].$error
									}
								],attrs:{"type":"text","placeholder":("Enter option " + index)},domProps:{"value":(_vm.choicesData.choiceOptions[i])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.choicesData.choiceOptions, i, $event.target.value)}}}),(
									_vm.$v.choicesData.choiceOptions.$each[i] &&
										_vm.$v.choicesData.choiceOptions.$each[
											i
										].$invalid
								)?_c('small',{staticClass:"text-danger small form-text"},[_vm._v("One or more characters are required.")]):_vm._e()]),_c('div',{staticClass:"col-auto px-1 py-2"},[(i >= 2)?_c('button',{staticClass:"btn btn-secondary",attrs:{"id":("removeOption" + index)},on:{"click":function($event){return _vm.choicesData.choiceOptions.splice(i, 1)}}},[_c('i',{staticClass:"fas fa-minus fa-1x text-white"})]):_vm._e()])])])])}),0)]),_c('div',{staticClass:"p-2"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"data-cy":"addChoiceOption"},on:{"click":function($event){return _vm.choicesData.choiceOptions.push('')}}},[_c('i',{staticClass:"fas fa-plus fa-1x text-white"})]),_c('b-dropdown',{staticClass:"mx-2",attrs:{"id":"ddlChoices","boundary":"viewport","text":(" " + (_vm.choicesData.selectedChoice))}},_vm._l((this.choiceListTypes),function(choice){return _c('b-dropdown-item',{key:choice,attrs:{"id":("dropdownOptions" + choice)},on:{"click":function($event){_vm.choicesData.selectedChoice = choice}}},[_vm._v(_vm._s(choice))])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }