import { Module } from "vuex";
import { getters } from "./getters"
import { mutations } from "./mutations";
import { actions } from "./actions";
import { SystemHealthState } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: SystemHealthState = getDefaultState();

export const systemHealth: Module<SystemHealthState, RootState> = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
