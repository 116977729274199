import Vue from "vue";
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const opts = {
  theme: {
    options: {
      customProperties: true,
    },
    theme: { disable: true },
  },
  icons: {
    iconfont: "fa",
  },
}

export default new Vuetify(opts);
