
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace, Action, Getter } from "vuex-class";
import { FeaturesList } from "@/store/types";
import vSelect from "vue-select";
import { Datetime } from "vue-datetime";
import { ActivityLogDTO } from "../../store/activity-log/types";
import { formatDateMixin } from "@/mixins";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const ActivityLog = namespace("activityLog");
import { get, keyBy } from "lodash";
import AreaTreeSelect from '../form/AreaTreeSelect.vue';

Vue.mixin(formatDateMixin);

const { formatDateStr } = formatDateMixin.methods;
const Reports = namespace("reports");

@Component({
	components: {
		"vue-select": vSelect,
		Datetime,
		VuePerfectScrollbar: VuePerfectScrollbar,
		"area-tree-select": AreaTreeSelect,
	}
})
export default class ActivityLogTable extends Vue {
	@Action fetchUserGroups: any;
	@ActivityLog.Action getActivityLog: any;
	@ActivityLog.Action getActivityLogEntry: any;
	@ActivityLog.Action getActivityLogOutcomes: any;
	@ActivityLog.Action addActivityLog: any;
	@ActivityLog.Action editActivityLog: any;
	@ActivityLog.Action hideActivityLog: any;
	@ActivityLog.Action unHideActivityLog: any;
	@Reports.Action fetchReportServiceEndpoint: any;
	@Reports.Action getAvailableReportTemplatesForArea: any;

	@Getter("getUserGroups") userGroups: any;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getPermissions: any;
	@Getter getUserId: number;
	@Getter getUserGroupId: number;

	@Prop({default: true}) liveData: boolean;
	@Prop({default: null}) fromDate: null | Date;
	@Prop({default: null}) toDate: null | Date;
	@Prop({default: false}) triggerUpdate: boolean;
	@Prop({default: []}) selectedEventTypes: any[];

	@Watch("triggerUpdate")
	private onTriggerUpdateChanged(): void {
		if (this.triggerUpdate == true) {
			this.fromDateValue = this.fromDate;
			this.toDateValue = this.toDate;
			this.getActivityLogList();
			this.$emit("Updated", true);
		}
	}

	@Watch("liveData")
	private onLiveDataChanged(): void {
		if (this.liveData == true) {
			this.fromDateValue = null;
			this.toDateValue = null;
			this.pageNumber = 1;
			this.getActivityLogListLive();
		}
	}

	private fromDateValue: Date = null;

	private toDateValue: Date = null;

	// if we truncate the outcome note or not
	private truncateLogs: boolean = true;

	// number of characters to truncate after
	private stringTruncateLength = 130;

	private loading: boolean = false;

	// time period to check for new activity logs
	private updateInterval: number = 1500;

	// variable to hold SetTimeout object
	private timeOut: any = null;

	// variable to hold the list of activity logs
	private tableData: ActivityLogDTO[] = [];

	// list of the available categories (event outcomes)
	private categoryData: any[] = [];

	// object used to hold data while adding or editing an activity log
	private editRowData: ActivityLogDTO = {
		activityLogID: 0,
		eventTypeID: null,
		logged: new Date(),
		start: new Date(),
		end: null,
		categoryID: null,
		categoryTitle: null,
		areaID: null,
		areaTitle: null,
		viewedUserID: null,
		outcomeNote: null,
		userTitle: null,
		hiddenFromUsers: false
	};

	// status of editing or adding a record, used to display the edit interface
	private editingRecord: boolean = false;

	// variable to hold the id of the record being edited
	private currentActivityBeingEdited: number = 0;

	// variable to hold the HTML element for the editor row
	private editorRow: HTMLElement;

	// variable to hold users timezone
	private timeZone: string = null;

	private canRaiseActivityLog = false;
	private canToggleLogVisibility = false;
	private canEditOwnActivityLog = false;
	private canEditOtherActivityLogs = false;

	private pageNumber: number = 1;
	private totalPages: number = 1;

	// default css class to set the font size on the activity log table
	private fontSizeClass: string = "font-large";

	// flag used to determine if we are hiding or showing all data columns in the table
	private showDetailedScreen: boolean = false;

	// variable used to hold the API endpoint for the reports server
	private reportEndPoint: string = null;

	// bool used for allowing downloads of report
	private get reportDownloadAllowed(): boolean {
		return this.getPermissions.canViewActivityLogManagement && this.getPermissions.canViewReports;
	}

	// variable to hold the list of available templates for downloading a report
	private reportTemplates: any[] = [];

	// variable to hold the selected template object
	private selectedReportTemplate: any = null;

	private async created(): Promise<void> {
		await this.run();
	}

	private get isActivityLogActionsEnabled(): boolean {
		return get(this.featuresList, ["ActivityLog", "ActivityLogActions"]);
	}

	private get eventUrl() {
		return window.location.pathname + "#/eventsearch/";
	}

	// starts off the live activity log
	private async run(): Promise<void> {
		// set up permissions
		this.canRaiseActivityLog = this.getPermissions.canRaiseActivityLog;
		this.canToggleLogVisibility = this.getPermissions.canToggleLogVisibility;
		this.canEditOwnActivityLog = this.getPermissions.canEditOwnActivityLog;
		this.canEditOtherActivityLogs = this.getPermissions.canEditOtherActivityLogs;

		// set up report download options (if allowed)
		if (this.reportDownloadAllowed) {
			let endpoint = await this.fetchReportServiceEndpoint();

			if (endpoint && endpoint.data) {
				this.reportEndPoint = endpoint.data;

				let templates = await this.getAvailableReportTemplatesForArea(this.getUserGroupId);

				// default the selected report if there is only 1 available
				if (templates.data.length == 1) {
					this.selectedReportTemplate = templates.data[0];
				}

				// store the return templates
				this.reportTemplates = templates.data;
			}
		}

		// set timezone based on user browser
		this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		// grab the HTML element for the editor row
		this.editorRow = document.getElementById("edit-row");

		// get the activity log and start the timeout to refresh the data
		this.getActivityLogListLive();
	}

	private setLoadingStatus(status: boolean): void {
		this.loading = status;
	}

	private async getActivityLogList(): Promise<void> {
		if(this.loading)
		{
			return;
		}

		try
		{
			this.setLoadingStatus(true);

			let queryStr = `?page=${this.pageNumber}`;

			// add date filters if we are using the date search and not live data
			if (!this.liveData && this.fromDateValue && this.toDateValue) {
				let fromDateValueUTCString = new Date(new Date(this.fromDateValue).toUTCString()).toISOString();
				let toDateValueUTCString = new Date(new Date(this.toDateValue).toUTCString()).toISOString();

				queryStr += `&fromDate=${fromDateValueUTCString}&toDate=${toDateValueUTCString}`;
			}

			if(this.selectedEventTypes.length > 0) {
				this.selectedEventTypes.forEach((et) => queryStr += `&eventTypeIds=${et.eventTypeID}`);
			}

			// request the data
			let result = await this.getActivityLog(queryStr);

			// results and paging data returned from API
			this.tableData = result.data.activityLogs as ActivityLogDTO[];
			this.totalPages = result.data.totalPages;
			this.pageNumber = result.data.currentPage;

			// create a list of the dates from the Log, used to display the date range we are displaying
			let dates = this.tableData.map(function(item) {
				return new Date(item.logged);
			});

			// get the min and max date for the data we have (displayed in the UI)
			if (dates && dates.length > 0) {
				let minDate = Math.min.apply(null, dates);
				let maxDate = Math.max.apply(null, dates);

				// send the data to the parent component (ActivityLogManagment)
				this.$emit("DateRange", {
					minDate: new Date(minDate) + "Z",
					maxDate: new Date(maxDate) + "Z"
				});
			}
		}
		catch(ex)
		{
			console.error(ex);
		}
		finally
		{
			this.setLoadingStatus(false);
		}
	}

	private async getActivityLogListLive(): Promise<void>
	{
		if (this.$route && this.$route.name == "activity-log") {
			// clear any current timeouts that are running
			if (this.timeOut != null) {
				clearTimeout(this.timeOut);
			}

			// only refresh the data if we not adding or editing a row, or not set to view live data
			if (!this.editingRecord && this.liveData) {
				await this.getActivityLogList();

				// create the timeout to refresh the data
				this.timeOut = setTimeout(() => {
					this.getActivityLogListLive();
				}, this.updateInterval);
			}
		}
	}

	// resets our edit object to default values ready to be reused
	private resetEditRowObject(): void {
		this.editRowData.activityLogID = 0;
		this.editRowData.eventTypeID = null;
		this.editRowData.logged = new Date().toISOString();
		this.editRowData.start = new Date().toISOString();
		this.editRowData.end = null;
		this.editRowData.categoryID = null;
		this.editRowData.categoryTitle = null;
		this.editRowData.areaID = null;
		this.editRowData.areaTitle = null;
		this.editRowData.viewedUserID = null;
		this.editRowData.outcomeNote = null;
		this.editRowData.hiddenFromUsers = false;

		// reset category data as this is based on the selected area
		this.categoryData = [];
	}

	// takes the data from the matching log Id and makes it editable
	private async showActivityLogEditor(logID: number): Promise<void> {
		this.resetEditRowObject();

		this.currentActivityBeingEdited = logID;

		// get the HTML element of the row we want to edit
		let targetRow = document.getElementById("table-row-" + logID);

		// request a copy of the log from the API so were sure we have the latest version
		let record = this.getActivityLogEntry(logID).then(result => {
			this.editRowData = result.data;

			this.setCategoryOptions(this.editRowData.areaID);

			this.editRowData.start = this.checkDateStringContainsZ(this.editRowData.start);
			this.editRowData.end = this.checkDateStringContainsZ(this.editRowData.end);

			// display the edit row interface after the selected row in the DOM
			// the selected row will be hidden
			targetRow.after(this.editorRow);

			this.editingRecord = true;
		});
	}

	// copy the updated data from our edit interface
	private async updateActivityLog(rowId: number): Promise<void> {
		let newobj: ActivityLogDTO = {
			activityLogID: this.editRowData.activityLogID,
			start: this.editRowData.start,
			end: this.editRowData.end,
			categoryID: this.editRowData.categoryID,
			areaID: this.editRowData.areaID,
			outcomeNote: this.editRowData.outcomeNote,
			eventTypeID: 11,
			logged: new Date(),
			hiddenFromUsers: false,
			categoryTitle: this.editRowData.categoryTitle,
			areaTitle: this.editRowData.areaTitle,
			viewedUserID: 0,
			userTitle: this.editRowData.userTitle
		};

		// hack to prevent issues with Axios not liking empty properties and messing update
		Object.keys(newobj).forEach(function(key, index) {
			if (newobj[key] == null) {
				delete newobj[key];
			}
		});

		if (!newobj.areaID) {
			alert("Please select an area for this activity log entry");
		} else if (!newobj.outcomeNote || newobj.outcomeNote == "") {
			alert("Please enter a note for this Activity Log entry");
		} else {
			if (rowId == 0) {
				await this.addActivityLog(newobj);
			} else {
				await this.editActivityLog(newobj);
			}

			this.editingRecord = false;

			this.currentActivityBeingEdited = 0;

			this.resetEditRowObject();

			// refresh list after updating
			await this.getActivityLogList();
		}
	}

	private cancelLogUpdate(): void {
		this.currentActivityBeingEdited = 0;
		this.editingRecord = false;
		this.resetEditRowObject();
		this.getActivityLogListLive();
	}

	private logNewActivity(): void {
		this.resetEditRowObject();

		// find the HTML element for the table
		let table = document.getElementById("activity-log-table");
		this.editingRecord = true;
		this.currentActivityBeingEdited = 0;

		// show the editor row at the top of the table
		table.prepend(this.editorRow);

		// automatically focus on the outcome note column
		this.$nextTick(() => {
			document.getElementById("outcome-note").focus();
		});
	}

	private async toggleHideFromUserStatus(id: number, status: boolean): Promise<void> {
		// make API to show or hide the activity log
		status ? await this.hideActivityLog(id) : await this.unHideActivityLog(id);

		// refresh data
		await this.getActivityLogList();
	}

	private setSelectedArea(area: any): void {
		this.editRowData.categoryID = null;
		this.editRowData.categoryTitle = null;

		if (!area) {
			this.editRowData.areaID = null;
			this.editRowData.areaTitle = null;
			return;
		}

		this.editRowData.areaID = area.groupID;
		this.editRowData.areaTitle = area.title;

		this.setCategoryOptions(area.groupID);
	}

	private setSelectedCategory(category: any): void {
		if (!category) {
			this.editRowData.categoryID = null;
			this.editRowData.categoryTitle = null;
			return;
		}
		this.editRowData.categoryID = category.eventOutcomeID;
		this.editRowData.categoryTitle = category.title;
	}

	private setSelectedReportTemplate(template: any): void {
		this.selectedReportTemplate = template;
	}

	// get the time value from date time object for the editor
	private getTime(field: any): string {
		let dateTime = this.editRowData[field];

		let time = formatDateStr(dateTime, true, true, false);

		return time;
	}

	// create a date object from the time in the editor
	private setTime(event: any, field:any): void {
		let time = event.target.value;
		let value = null;
		if (time) {
			let dateTime = new Date();

			let hoursMins = time.split(":");

			dateTime.setHours(hoursMins[0]);
			dateTime.setMinutes(hoursMins[1]);

			value = dateTime.toISOString();
		}

		this.editRowData[field] = value;
	}

	private async setCategoryOptions(areaId: number): Promise<void> {
		if (areaId) {
			this.categoryData = [];

			// get category options for Area from API
			await this.getActivityLogOutcomes(areaId).then(result => {
				this.categoryData = result.data;
			});
		}
	}

	// check that our time string contains Z for UTC
	private checkDateStringContainsZ(date: string | Date): string | Date {
		if (date && !date.toString().includes("Z")) {
			return date.toString() + "Z";
		}

		return date;
	}

	// set the page number and request records for that page
	private async paginate(pageNumber: number): Promise<void> {
		this.editingRecord = false;
		this.$emit("LiveStatusChanged", false);
		this.pageNumber = pageNumber;
		await this.getActivityLogList();
	}

	// set the starting date range to use when downloading a report
	private get reportDownloadStartDate(): Date | string {
		if (!this.liveData) {
			return this.fromDate;
		}

		let start = new Date();
		start.setHours(0, 0, 0, 0);

		return start.toUTCString();
	}

	// set the ending date range to use when downloading a report
	private get reportDownloadFromDate(): Date | string {
		if (!this.liveData) {
			return this.toDate;
		}

		let end = new Date();
		end.setHours(23, 59, 59, 999);

		return end.toUTCString();
	}

	// generate url to request the report PDF
	private get downloadActivityLogReportURL(): string {
		if (this.selectedReportTemplate != null) {
			return `${this.reportEndPoint}/Reports/DownloadReportPDF/ShiftReport/${this.selectedReportTemplate.reportTemplateID}?shifts=1&startDate=${this.reportDownloadStartDate}&endDate=${this.reportDownloadFromDate}&timeZone=${this.timeZone}&fileName=ActivityLog-${this.reportDownloadFilename}`;
		}

		return "";
	}

	// generate filename to used for the report download
	private get reportDownloadFilename(): string {
		return this.liveData ? new Date().toISOString().split("T")[0] : this.fromDate.toString().split("T")[0];
	}

	private get formattedSelectedEventTypesById(): any {
		return keyBy(this.selectedEventTypes, 'eventTypeID')
	}

	private get filteredTableData(): ActivityLogDTO[] {
		return !this.selectedEventTypes.length
			? this.tableData
			: this.tableData.filter(({ eventTypeID }) => this.formattedSelectedEventTypesById[eventTypeID])
	}

	private toggleNotes(id:string): void{
		document.getElementById(id).classList.toggle('show');
	}
}
