
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import Draggable from "./utilities/draggable.vue";
import { EventRecord, EventRecordDetails } from "@/store/site-monitor/types";
import { truncateString } from '@/filters';
import { Getter, namespace } from 'vuex-class';
import { CustomFieldDto, CustomFieldTableTypes, CustomFieldValues } from "@/store/custom-fields/types";
import customFieldsApi from "@/services/api.customField.service";
import CustomDefinedFieldsDisplay from "./custom-fields/CustomDefinedFieldsDisplay.vue";
import { FeaturesList } from "@/store/types";

const CustomFields = namespace("customFields");

@Component({
	components: {
		"draggable": Draggable,
		"custom-defined-fields-display": CustomDefinedFieldsDisplay
	},
	filters: { truncateString }
})
export default class EventRecordDetailsPopout extends Vue {
	@Prop({ type: Boolean, default: false }) isModalShown: Boolean;
	@Prop({ type: Object }) eventRecord: EventRecord;
	@Prop({ type: Number, default: 75 }) maxTitleCharacters: Number;
	@Prop({ type: Number, default: 30 }) maxValueCharacters: Number;

	@CustomFields.Action retrieveCustomFields: ({ tableType, live }: { tableType: number, live: boolean; }) => Promise<void>;
	@CustomFields.State alarmCustomFields: CustomFieldDto[];

	private customFieldAlarmTableType = CustomFieldTableTypes.Alarm;
	private customFieldObjectValues: CustomFieldValues[] = [];
	private isLoading: boolean = false;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Watch("eventRecord.EventRecordDetails.objectId")
	private async retrieveCustomFieldValues(): Promise<void> {
		if (this.eventRecord?.EventRecordDetails?.objectId) {
			try {
				this.isLoading = true;
				this.customFieldObjectValues = await customFieldsApi.retrieveCustomFieldsForObject(this.eventRecord.EventRecordDetails.objectId, CustomFieldTableTypes.Alarm);
			}
			catch {
				console.log("Failed to get Custom Fields for object with objectId: " + this.eventRecord?.EventRecordDetails?.objectId);
				this.isLoading = false;
				this.customFieldObjectValues = [];
			}
		}
		else {
			this.customFieldObjectValues = [];
		}

		this.isLoading = false;
	}

	@Emit("disableDetailsPopout")
	private disableDetailsPopout(): boolean {
		return false;
	}

	private get customFieldsEmpty(): string {
		return (!this.customFieldObjectValues || this.customFieldObjectValues.length == 0) ? "custom-fields-empty" : "";
	}

	private get getEventRecordDetails(): EventRecordDetails {
		if (this.eventRecord?.EventRecordDetails) {
			return this.eventRecord.EventRecordDetails;
		}
		else {
			return null;
		}
	}
}
