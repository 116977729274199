import api from "../../services/api.service";
import { ActionTree } from "vuex";
import {
	ExternalMapLayerState,
	ExternalMapLayer,
	ExternalMapLayerUserLink
} from "./types";
import { RootState } from "../types";

export const actions: ActionTree<ExternalMapLayerState, RootState> = {
	async loadExternalMapLayers({ commit }) {
		const externalMapLayers = await api.getExternalMapLayers();
		let data = externalMapLayers.data;

		commit("setExternalMapLayers", data);
	},
	async loadExternalMapLayerTypes({ commit }) {
		const externalMapLayerTypes = await api.getExternalMapLayerTypes();
		commit("setExternalMapLayerTypes", externalMapLayerTypes.data);
	},
	async createOrUpdateExternalMapLayer({ commit, dispatch }, externalMapLayer: ExternalMapLayer) {
		const { data } = await api.createOrUpdateExternalMapLayer(externalMapLayer);
		const updatedExternalMapLayer: ExternalMapLayer = data;

		let action = externalMapLayer.externalMapLayerID ? "updateExternalMapLayer" : "addNewExternalMapLayer";

		commit(action, updatedExternalMapLayer);
		dispatch("loadExternalMapLayers");
	},
	async deleteExternalMapLayer({ commit }, externalMapLayerID: number) {
		await api.deleteExternalMapLayer(externalMapLayerID);
		commit("removeExternalMapLayer", externalMapLayerID);
	},
	async updateExternalMapLayerVisibility({ commit, dispatch }, externalMapLayer: ExternalMapLayer) {
		// create an externalMapLayerLink object to send to API
		let externalMapLayerUserLink: ExternalMapLayerUserLink = {
			externalMapLayerID: externalMapLayer.externalMapLayerID,
			display: !externalMapLayer.displaying,
			userId: -1
		};
		let data = await api.updateExternalMapLayerUserLink(externalMapLayerUserLink);

		// trigger pulling down the list of externalMapLayers again as the state will have changed.
		dispatch("loadExternalMapLayers");
		return data;
	},
	async updateExternalMapLayerVisibilityForDelete({ dispatch }, externalMapLayerId: number) {
		// create an externalMapLayerLink object to send to API
		let externalMapLayerUserLink: ExternalMapLayerUserLink = {
			externalMapLayerID: externalMapLayerId,
			display: false,
			userId: -1
		};
		await api.updateExternalMapLayerUserLink(externalMapLayerUserLink);

		// trigger pulling down the list of externalMapLayers again as the state will have changed.
		dispatch("loadExternalMapLayers");
	}
};
