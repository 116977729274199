
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter, Action, Mutation } from "vuex-class";
import { FeaturesList, UserPermissions } from "@/store/types";
import ShortcutHelp from "./ShortcutHelp.vue";
import PutOnTest from "@/components/alarm-points/PutOnTest.vue";
import { EventDetails } from "@/store/site-monitor/types";
import { get } from "lodash";
import api from "@/services/api.service";
import organizationApi from "@/services/api.organizationSettings";
import EventDetailsModal from "@/components/EventDetails.vue";
import { CaseData, CaseType } from "@/store/case-management/types.ts";
import vSelect3 from "vselect3";
import { stringMixin } from "@/mixins";
import MaskingModalLauncher from "@/components/alarm-masking/MaskingModalLauncher.vue";
import router from '@/router';
import ZendeskHelp from "./ZendeskHelp.vue";
import MobileEventSharingStatus from '@/types/sv-data/MobileEventSharingStatus';
import MobileEventShareStates from '@/types/sv-data/enums/MobileEventShareStates';
import NotificationModal from "@/components/site-monitor/NotificationModal.vue";
import RaiseCaseModal from "./RaiseCaseModal.vue";
import { QuickEndSettingsDto } from "@/types/organization-settings/QuickEndSettings";
import { BTooltip } from "bootstrap-vue";
import { truncateString } from "@/filters";
import QuickEndSettingsTooltipContent from "./site-monitor/QuickEndSettingsTooltipContent.vue";
import { AreaDetailsIndexValues } from "@/types/Constants"
const { isNullOrWhitespace, ellipseAfterX } = stringMixin.methods;

const SiteMonitor = namespace("siteMonitor");
const SiteMonitorCameras = namespace("siteMonitorCameras");
const Tasks = namespace("tasks");
const AreaNotes = namespace("areaNotes");
const CaseManagement = namespace("caseManagement");

@Component({
	components: {
		MaskingModalLauncher,
		"shortcut-help": ShortcutHelp,
		"put-on-test": PutOnTest,
		"event-details": EventDetailsModal,
        "v-select-3": vSelect3,
		"zendeskHelp" : ZendeskHelp,
		"notification-modal": NotificationModal,
		"raise-case-modal": RaiseCaseModal,
		"quick-end-tooltip-content": QuickEndSettingsTooltipContent
	},
	filters: {
		truncateString
	}
})
export default class SiteMonitorHeader extends Vue {
	$refs: {
		extendButton: HTMLAnchorElement;
		tooltip: BTooltip
	};

	public hide: boolean = false;
	public isDestroyed: boolean = false;
	public updateInterval: NodeJS.Timeout = null;

    // Data to send to case management.
    public caseData: CaseData = {
        EventId: null,
        TemplateId: null,
        Summary: null,
    };
    // Case type selected by user.
    public selectedCaseType: CaseType = null;
    // Flag to show loading icon.
    public raisingToCases: boolean = false;
    public isModalShown: boolean = false;

	public flagEventModalState = {
		open: false,
		processing: false
	};

	private isNotificationModalVisible = false;

	private readonly defaultRaiseEventButtonText: string = "Raise Case";
	private readonly defaultRaisedEventButtonText: string = "Case Raised";

	private raiseEventButtonText: string = this.defaultRaiseEventButtonText;
	private isRaiseCaseButtonEnabled: boolean = true;
	private raisedEventHoverButtonText: string = "Raise Case";
	private isRaiseCaseModalShown: boolean = false;
	private casesWindow: Window = null;
	private originalEventId: number;

	private hasUnacknowledgedAlarms: boolean = true;
	private quickEndDto: QuickEndSettingsDto = null;

	private quickEndSettingsExist: boolean = true;
	private quickEndSettingsValid: boolean = true;

	@Prop(Number) eventid?: number;
	@Prop({ type: Boolean, default: false })
	private isEventEdit: boolean;

	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	@SiteMonitor.Getter areaNotesQuantity!: number;
	@Getter getPermissions: UserPermissions;
	@Getter getUserId: number

	@SiteMonitor.Getter getEventRecords: any[];
	@SiteMonitor.Getter getEventShare!: any[];
	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;
	@SiteMonitor.Getter getSelectedEventRecord: any;
	@SiteMonitor.Getter("getIsController") isController!: boolean;
	@SiteMonitor.Getter getEventRecordsAwaitingRestoreCount: any;
	@SiteMonitor.Getter getEventRecordsAwaitingAckCount: any;
	@SiteMonitor.Getter getMapCircleCenter: any;

	@SiteMonitor.Action setEventDetails: any;
	@Action openMediaMatrix: () => Promise<void>;

	@SiteMonitor.Mutation setSelectedEventRecord: any;
	@SiteMonitor.Mutation setEventShareShown: any;
	@SiteMonitor.Mutation setEventLeaveShown: any;
	@SiteMonitor.Mutation setEventRaiseShown: any;
	@SiteMonitor.Mutation setEventCloseShown: any;
	@SiteMonitor.Mutation setEventDetailsShown: any;
	@SiteMonitor.Mutation setIncidentReportShown: any;
	@SiteMonitor.Mutation setInvolvedPartiesShown: (shown: boolean) => void;
	@SiteMonitor.Mutation setMapCircleCenter;
	@SiteMonitor.Mutation setPutEventOnTestShown: (shown: boolean) => void;
	@SiteMonitor.Mutation setActivity;
	@SiteMonitor.Mutation setEventCaseId: (id: string) => void;
	@SiteMonitor.Mutation setAreaDetailsTabIndex: any;
	@SiteMonitor.Mutation resetEventRecords: () => void;

	@SiteMonitor.Action parkEvent: any;
	@SiteMonitor.Action private eventRecordAllAcknowledgement: (eventId:number) => Promise<void>;
	@SiteMonitor.Action("leaveEvent") leaveEventRequest: any;
	@SiteMonitor.Action fetchEventDetails: any;
	@SiteMonitor.Action setAreaNotesVisible: any;
	@SiteMonitor.Action fetchAreaNotes: any;
	@SiteMonitor.Action("updateSharing") updateSharingAction: any;
	@SiteMonitor.Action flagEventForReview: ({ eventId, flagged }) => Promise<boolean>;
	@SiteMonitor.Action createReviewRecord: ({ eventId, eventRecordTypeId, details }) => Promise<any>;
	@SiteMonitor.Action eventPassControl: any;
	@SiteMonitor.Action("closeEvent") quickCloseEvent: (outcome: any) => Promise<void>;
	@SiteMonitor.State("MobileEventShares") mobileShares: MobileEventSharingStatus[];
	@SiteMonitor.State eventCaseId: string;

	@SiteMonitorCameras.Getter("getRequiresEventDetails")
	requiresEventDetails: any;
	@SiteMonitorCameras.Mutation setRequiresEventDetails: any;
	@SiteMonitor.Mutation setIsСontroller: (boolean) => void;

	@Tasks.Getter getOutstandingRequiredTaskCount: number;

	@AreaNotes.Getter getAreaNotesCount!: number;
	@AreaNotes.Action public fetchAreaNotesForEvent: any;
	@AreaNotes.Mutation setAreaNoteGroupDetails: any;

    @CaseManagement.State((state) => state.caseTypes) caseTypes: CaseType[];
    @CaseManagement.Action fetchCaseTypes: (eventId: number) => void;
	@CaseManagement.Action casesLogin: (suiteSession: boolean) => Promise<{ url: string, token: string }>;

	@Mutation setReloadEventQueue: (boolean) => void;

	@Watch("eventid")
	private async eventIDRouteChanged(eventId: number | null): Promise<void> {
		const caseNumber = await api.loadRaisedCaseNumber(eventId)
		this.setEventCaseId(caseNumber)
	}

	@Watch("eventDetails")
	private async eventIdWatcher(newValue: EventDetails | null): Promise<void>
	{
		if (this.isSimultaneousProcessingEnabled){
			return;
		}

		if(newValue && newValue.eventID !== this.originalEventId) {
		    this.setEventCaseId(null)
			var passedEventControl = false;
			var isCurrentController = this.checkCurrentUserEventController();

			if (isCurrentController) {
				if (this.eventSharingEnabled) {
					passedEventControl = await this.passEventToNextOperator();
				}

				if (!passedEventControl) {
					await this.parkEvent({
						eventId: this.originalEventId,
						hours: 0,
						minutes: 0,
						seconds: 0,
						parkNote: "Event returned to queue - user picked up other event",
						parkedByMap: false
					});
				}
			}
			router.push({ path: `/event-queue` });
		}
	}

	private async passEventToNextOperator(): Promise<boolean> {
		var passedEvent = false;

		const sharedOperators = this.getEventShare.filter((user: any) =>
		user.userID != null && user.userID != this.getUserId && !user.removed && user.isActive);

		if (sharedOperators != null && sharedOperators.length > 0) {
			// Only one other operator so pass control to them
			if (sharedOperators.length === 1) {
				await this.eventPassControl({eventID: this.eventid, userID: sharedOperators[0].userID});
			} else {
				// Get the userIds and their last active time
				const operators = sharedOperators.map(op => {
					return { userID: op.userID, lastActiveDate: new Date(op.lastActive)}
				});

				// Order the operators by their last active time (latest first)
				const orderedOperators = operators.sort((opA, opB) => opB.lastActiveDate.getTime() - opA.lastActiveDate.getTime());

				// Pass control to the last active operator
				await this.eventPassControl({eventID: this.eventid, userID: orderedOperators[0].userID});
			}

			passedEvent = true;
		}
		return passedEvent;
	}

	private checkCurrentUserEventController(): boolean {
		// Check if the current user is the controller of the event
		if (this.eventSharingEnabled) {
			const shareUser = this.getEventShare.find(user => user.userID == this.getUserId);

			if (shareUser) {
				return this.getEventShare.find(user => user.userID == this.getUserId).isController;
			}
		}

		return this.isController;
	}

	@Getter("getFeaturesList") featuresList: FeaturesList;
	public get sharedWith(): any[] {
		return this.getEventShare.filter(share => !share.removed);
	}

	@Watch("requiresEventDetails")
	public onRequiresEventDetailsChanged(value: boolean, oldValue: boolean) {
		if (value) {
			this.setEventDetails(this.eventDetails);
			this.setSelectedEventRecord(this.getSelectedEventRecord);
			this.setIsСontroller(this.isController);
			this.setMapCircleCenter(this.getMapCircleCenter);
			this.setRequiresEventDetails(false);
		}
	}

	public async created() {
		//await this.fetchEventDetails(this.eventid);
		this.originalEventId = this.eventid;
		this.fetchAreaNotesForEvent(this.eventid);
	}

	public async mounted() {
		this.updateSharing();
		if (this.raiseToCaseManagementEnabled) {
			await this.fetchCaseTypes(this.eventid);
		}
	}

	@Watch("eventDetails")
	public async getQuickEnd(): Promise<void>
	{
		if(!this.eventDetails.groupID)
		{
			return;
		}
		this.quickEndDto = await organizationApi.retrieveQuickEndSettings(this.eventDetails.groupID, false);
		this.quickEndSettingsExist = this.quickEndDto?.eventOutcomeTitle != null;
	}

	public updateSharing() {
		if (!this.isDestroyed) {
			this.updateSharingAction({ eventID: this.eventid }).then(() => {
				if (!this.isDestroyed) {
					this.updateInterval = setTimeout(this.updateSharing, 5000);
				}
			});
		}
	}

	public openSharing() {
		if (this.isController) {
			this.setEventShareShown(true);
			this.setActivity();
		}
	}

	public openInvolvedParties(): void {
		this.setInvolvedPartiesShown(true);
	}

	public beforeDestroy() {
		this.isDestroyed = true;
		if (this.updateInterval != null) {
			clearTimeout(this.updateInterval);
		}

		this.setEventCaseId(null);
		if (this.casesWindow) {
			this.casesWindow.close();
		}
	}

	async leaveEvent() {
		await this.reloadEventQueue();

		if (this.eventDetails.eventTypeID == 6 || this.eventDetails.eventTypeID == 10) {
			this.$router.push({ path: "/event-queue" });
		} else {
			if (this.isController) {
				if (this.eventDetails.eventTypeID == 1) {
					if (this.autoPassAlarmEnabled()) {
						let sharedOperators = this.getEventShare.filter((user: any) => user.userID != null && user.userID != this.getUserId && !user.removed && user.isActive);
						if (sharedOperators != null && sharedOperators.length == 1){
							await this.eventPassControl({eventID: this.eventid, userID: sharedOperators[0].userID});
							this.$router.push({ path: "/event-queue" });
						}
						else {
							this.setEventLeaveShown(true);
						}
					}
					else {
						this.setEventLeaveShown(true);
					}
				} else if(this.eventid) {
					await this.parkEvent({
						eventId: this.eventid,
						hours: 0,
						minutes: 0,
						seconds: 0,
						parkNote: null,
						parkedByMap: false
					}
				);
					this.$router.push({ path: "/event-queue" });
				}
			} else {
				await this.leaveEventRequest(this.eventid);
				this.$router.push({ path: "/event-queue" });
			}
		}
	}

	public get canClose(): boolean {
		if (this.isEventEdit) {
			return true;
		}

		if (this.getEventRecordsAwaitingRestoreCount > 0) {
			return false;
		}

		if (this.getEventRecordsAwaitingAckCount > 0) {
			return false;
		}

		if (!this.isController) {
			return false;
		}

		if (this.getOutstandingRequiredTaskCount > 0) {
			return false;
		}

		return true;
	}

	public get canQuickClose(): boolean {
		if (this.isEventEdit) {
			return true;
		}

		if (this.getEventRecordsAwaitingRestoreCount > 0) {
			return false;
		}

		if (!this.isController) {
			return false;
		}

		if (this.getOutstandingRequiredTaskCount > 0) {
			return false;
		}

		return true;
	}

	public closeTitle(isQuickEnd: boolean = false) {
		if (this.isEventEdit) {
			return "Update Event Outcome";
		}

		let restoreCount = this.getEventRecordsAwaitingRestoreCount;
		if (restoreCount > 0) {
			return restoreCount + " alarms awaiting restore.";
		}

		if (!isQuickEnd)
		{
			let ackCount = this.getEventRecordsAwaitingAckCount;
			if (ackCount > 0) {
				return ackCount + " alarms awaiting acknowledgement.";
			}
		}

		if (!this.isController) {
			return "You do not have control of this event.";
		}

		if (this.getOutstandingRequiredTaskCount > 0) {
			return "There are still required actions left to complete.";
		}

		return "End Event";
	}

    public get raiseToCaseManagementEnabled() {
        return get(this.featuresList, ["CaseManagement", "RaiseToCaseManagement"]);
    }

	public get eventFlaggingEnabled() {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "EventFlagging"]);
	}

	public get eventSharingEnabled() {
		return !this.isEventEdit && get(this.featuresList, ["Alarms", "SiteMonitor", "EventSharing"]);
	}

	public get sitRepEnabled() {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "SitRep"]) && this.getPermissions.canViewSitRep;
	}

	public get raiseEventEnabled() {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "Raise"]);
	}

	public get newAreaNotesEnabled() {
		return get(this.featuresList, ["AreaNotes"]) && get(this.featuresList, ["AreaNotes", "Icons"]);
	}

	public get areaNotesEnabled() {
		return get(this.featuresList, ["AreaNotes"]);
	}

	public get areaEventHistoryEnabled() {
		return get(this.featuresList, ["Areas", "AreaEventHistory"]);
	}

	public get onTestEnabled() {
		return get(this.featuresList, "Alarms.AlarmPoints.OnTest");
	}

	public autoPassAlarmEnabled() {
		return get(this.featuresList, "Alarms.AutoPassAlarmOnLeave");
	}

	private get isSchedulesEnabled(): boolean {
		return this.getFeature(["Schedules"]) && !!(this.getPermissions.canViewSchedules || this.getPermissions.canEditSchedules);
	}

	private get isAdditionalInformationEnabled(): boolean {
		return get(this.featuresList, ["CaseManagement", "RaiseToCases", "AdditionalInformation"]);
	}

	private get isSimultaneousProcessingEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "SimultaneousProcessing"], false);
	}

	private get isInvolvedPartiesEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "InvolvedParties"])
	}

	public raiseEvent() {
		if (this.isController) {
			this.setEventRaiseShown(true);
		}
	}

	public closeEvent() {
		if (this.isEventEdit || (this.canClose && this.isController)) {
			if (this.eventDetails.eventTypeID == 3) {
				//TODO: End test
			} else if (this.eventDetails.eventTypeID == 6 || this.eventDetails.eventTypeID == 10) {
				api.endTest(this.eventDetails.eventID).then(result => {
					this.$router.push({ path: "/event-queue" });
				});
			} else {
				this.setEventCloseShown(true);
			}
		}
	}

	public async quickEndEventViaButton(){
		if(this.isQuickEndTooltipEnabled){
			return;
		}
		await this.quickEndEvent();
	}

	public async quickEndEvent(): Promise<void> {
		if(this.quickEndDto && this.isQuickEndButtonEnabled && this.canQuickClose)
		{
			await api.forceRestoreAllAlarms(this.eventDetails.eventID, "Force restored from Quick End");
			await this.eventRecordAllAcknowledgement(this.eventDetails.eventID);
			const payload = {
				eventId: this.eventDetails.eventID,
				eventOutcomeId: this.quickEndDto.value.eventOutcomeId,
				outcomeNote: this.quickEndDto.value.note,
				clearGuards: true
			};
			await this.quickCloseEvent(payload).then(async (result: any) => {
				if (result.success === true) {
					await this.reloadEventQueue();
					this.setEventDetails(null);
					this.resetEventRecords();
					this.$router.push({ path: "/event-queue" });
				} else {
					this.quickEndSettingsValid = false;
					this.$notify({
						type: "error",
						title: "Quick End Event Category is misconfigured.",
						text: "A sub-category may have been created since its selection. Please contact your System Administrator."
					});
				}
			});
		}
	}

	/**
	 * Flags the event we're currently processing for review at a later stage.
	 */
	public async flagEvent() {
		if (!this.eventFlaggingEnabled) {
			return;
		}

		this.setActivity();

		let successful = false;

		// Show our loading spinner
		this.flagEventModalState.processing = true;

		// If we're in control of the event, and it hasn't already been flagged
		if (this.isController && !this.eventDetails.flaggedForReview) {
			try {
				successful = await this.flagEventForReview({
					eventId: this.eventDetails.eventID,
					flagged: true
				});

				if (successful) {
					// If we flagged the event successfully, create an event record for audit
					await this.createReviewRecord({
						eventId: this.eventDetails.eventID,
						eventRecordTypeId: 109,
						details: "Flagged event for review"
					});
				}
			} catch (err) {
				console.error(err);
				successful = false;
			}
		}

		// Inform the user if we weren't able to flag the event for whatever reason
		if (!successful) {
			this.$notify({
				type: "error",
				title: "Flag for Review",
				text:
					"Unable to flag the current event for review - please try again later, or contact support if the problem persists."
			});
		}

		// Hide our spinner
		this.flagEventModalState.processing = false;
		this.flagEventModalState.open = false;
	}

	public showEventDetails() {
		this.setEventDetailsShown(true);
		this.setAdjustedTabIndex(AreaDetailsIndexValues.CONTACTS)
		this.setAreaNoteGroupDetails({
			groupId: this.groupId,
			groupTitle: this.siteTitle
		});
	}

	public incidentReport() {
		this.setIncidentReportShown(true);
		this.setActivity();
	}

	public get siteTitle() {
		return this.eventDetails ? this.eventDetails.siteTitle : "";
	}

	public get groupId() {
		return this.eventDetails ? this.eventDetails.groupID : 0;
	}

	/**
	 * Shows the flag event modal.
	 */
	public showFlagEventModal() {
		// Check to ensure event flagging is turned on- if it isn't, cancel
		if (!this.eventFlaggingEnabled) {
			return;
		}

		this.setActivity();

		if (this.eventDetails && !this.eventDetails.flaggedForReview) {
			this.flagEventModalState.open = true;
		}
	}

	public unParkAtChanged(unParkAt: string) {
		if (unParkAt.endsWith("Z")) unParkAt = unParkAt.slice(0, -1);
		this.eventDetails.unParkAt = new Date(unParkAt);
		this.$refs.extendButton.click();
	}

	private showAreaNotesModal() {
		if (this.areaNotesEnabled) {
			this.setEventDetailsShown(true);
			this.setAdjustedTabIndex(AreaDetailsIndexValues.NOTES);
			this.setAreaNoteGroupDetails({
				groupId: this.groupId,
				groupTitle: this.siteTitle
			});
		}
		this.setActivity();
	}

	private showEventHistory(): void {
		if (this.areaEventHistoryEnabled && this.getPermissions.canViewEventHistory){
			this.setEventDetailsShown(true);
			this.setAdjustedTabIndex(AreaDetailsIndexValues.HISTORY);
			this.setAreaNoteGroupDetails({
				groupId: this.groupId,
				groupTitle: this.siteTitle
			})
		}
	}
	private closeFlaggedEventModal() {
		this.setActivity();
		this.flagEventModalState.open = false;
	}

	public processRaiseCaseRequest(): void {
		if(this.eventCaseId) {
			this.addAdditionalInformation(true);
		} else {
			this.showCaseManagementModal();
		}
	}

    /**
     * Open modal and reset fields.
     */
    private showCaseManagementModal(): void {
        this.selectedCaseType = null;
        this.caseData.Summary = null;
        this.isModalShown = true;
    }

    /**
     * Close modal.
     */
    public closeCaseManagementModal() {
        this.isModalShown = false;
    }

    /**
     * Method to raise event to case management.
     */
    public async raiseToCaseManagement() {
        // When case type or summary are not defined, do not raise event to case management.
        if (this.selectedCaseType == null || isNullOrWhitespace(this.caseData.Summary)) {
            return;
        }
        // Set true to show loading icon.
        this.raisingToCases = true;
        // Update case data.
        this.caseData.EventId = this.eventid;
        this.caseData.TemplateId = this.selectedCaseType.id;

        // Raise event to cases.
        try {
            await api.raiseToCases(this.caseData);
			this.isRaiseCaseModalShown = this.isAdditionalInformationEnabled;
        } catch (ex) {
            this.$notify({
                type: "error",
                title: "Error",
                text: ex.response.data,
            });
        } finally {
            // In all cases, stop showing loading icon and close modal.
            this.raisingToCases = false;
            this.closeCaseManagementModal();
        }
    }

	private get canMobileEventShare() : boolean {
		return this.getFeature(["Mobile", "FieldOps", "EventShare"]);
	}

	private get eventUserShareWithMobile() {
		if(!this.canMobileEventShare || !this.mobileShares || this.mobileShares.length === 0)
			return this.sharedWith;

		return this.sharedWith.map(eventUser => {
			let mobileShare = this.mobileShares.find(ms =>
				ms.userID == eventUser.userID
				&& ms.userID != 0
				&& (ms.state == 1 || ms.state == 2)
			);
			return {
				...eventUser,
				mobileShare: mobileShare
			};
		});
	}

	private get mobileEventShareAssets() {
		if(!this.canMobileEventShare)
			return [];

		if(this.sharedWith && this.sharedWith.length > 0){
			return this.mobileShares.filter(x => !this.sharedWith.map(y => y.userID).includes(x.userID));
		}
		return this.mobileShares;
	}

	private get canOpenMediaMatrixWithButton() {
		return !this.isEventEdit && this.getFeature(["Alarms","MediaMatrix", "OpenButton"]);
	}

	private getMobileShareNotificationText(share: MobileEventSharingStatus): string {
		const context = share.userID > 0 ? "Mobile user " : "Mobile asset "
		switch (share.state) {
			case MobileEventShareStates.Sent:
				return `Mobile event share request sent to ${share.assetTitle}.`;
			case MobileEventShareStates.Accepted:
				return `${context} ${share.assetTitle} accepted event share.`;
			case MobileEventShareStates.Rejected:
				return `${context} ${share.assetTitle} rejected event share.`;
			case MobileEventShareStates.Left:
				return `${context} ${share.assetTitle} left event.`;
			case MobileEventShareStates.Removed:
				return `${context} ${share.assetTitle} was removed from event share.`;
			default:
				return `${context} ${share.assetTitle} updated.`;
		}
	}

	@Watch("mobileShares", { deep: true })
	private mobileEventShareWatch(newMobileShares: MobileEventSharingStatus[], oldEventShares: MobileEventSharingStatus[]): void{
		if(!oldEventShares || oldEventShares.length <= 0)
			return;

		const updatedEventShares = newMobileShares.filter((share) => {
				const result = oldEventShares.find(x => x.assetID === share.assetID
                    && x.state === share.state);
				return !result;
			});

		updatedEventShares.forEach((share: MobileEventSharingStatus) =>
		{
			this.$notify(
			{
				group: "siteMonitorNotification",
				title: "Mobile Event Share:",
				text : this.getMobileShareNotificationText(share),
				duration: 5000
			});
		});

	}

	private getMobileShareBackground(share: any): string {
		return `background-color:${share.iconColor ? share.iconColor : 'grey' };`
	}

	private showNotificationModal() {
		this.isNotificationModalVisible = true;
	}

	private get isAlarmsSiteMonitorEmailEnabled() {
		return this.getFeature(["Alarms", "SiteMonitor", "Email"]) && this.groupId !== 0;
	}

	private setAdjustedTabIndex(index: number): void {
		let adjustedIndex = index;
		// adjust for Schedules tab
		if (index > AreaDetailsIndexValues.SCHEDULES && !this.isSchedulesEnabled) {
			adjustedIndex--;
		}
		// adjust for Area Notes tab
		if (index > AreaDetailsIndexValues.NOTES && !this.areaNotesEnabled) {
			adjustedIndex--;
		}
		this.setAreaDetailsTabIndex(adjustedIndex);
	}

	private get siteTitleEllipse(): string {
		return ellipseAfterX(this.siteTitle);
	}

	private get isQuickEndTooltipEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "QuickEnd", "Confirm"]);
	}

	private get isQuickEndButtonEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "QuickEnd"]);
	}

	private get isSuiteEnabled(): boolean {
		return this.getFeature(["Suite"]);
	}

	@Watch("eventCaseId")
	private setRaiseCasesButton(): void {
		// If case is raised
		if (this.eventCaseId) {
			if (this.isAdditionalInformationEnabled) {
				this.raiseEventButtonText = this.defaultRaisedEventButtonText;
				this.isRaiseCaseButtonEnabled = true;
				this.raisedEventHoverButtonText = "Add more info to case";
			} else {
				this.raiseEventButtonText = this.defaultRaisedEventButtonText;
				this.isRaiseCaseButtonEnabled = false;
				this.raisedEventHoverButtonText = "Case has already been raised";
			}
		} else {
			this.raiseEventButtonText = this.defaultRaiseEventButtonText;
			this.isRaiseCaseButtonEnabled = true;
			this.raisedEventHoverButtonText = this.defaultRaiseEventButtonText;
		}
	}

	private async addAdditionalInformation(add: boolean): Promise<void> {
		this.isRaiseCaseModalShown = false;

		if (!add) {
			return;
		}

		try {
			const loginResult = await this.casesLogin(this.isSuiteEnabled);
			const encodedCaseNumber = encodeURIComponent(this.eventCaseId);
			const casesUrl = loginResult.url + (loginResult.url.endsWith("/") ? "" : "/");
			const url = `${casesUrl}login/withTokenForCaseRestricted/${loginResult.token}/${encodedCaseNumber}`;
			this.casesWindow = window.open(url, "Case-Edit", "location=yes, scrollbars=yes, status=yes");
		} catch (ex) {
			console.error("Unexpected error signing into Cases: ", ex);
		}
	}

	private onTooltipClose(){
		this.$refs.tooltip.$emit('close');
	}

	private get isAlarmMaskingEnabled(): boolean {
		return this.getFeature(["Alarms", "Masking"]) &&
			(this.getPermissions.canDisarmSites || this.getPermissions.canDisarmSitesExtended);
	}

	private get isReloadEventQueueEnabled(): boolean {
		return this.getFeature(["Temp_EventReload"]);
	}

	private async reloadEventQueue(): Promise<void> {
		if (this.isReloadEventQueueEnabled && this.eventDetails?.groupID && await api.getReloadEventQueuePref(this.eventDetails?.groupID))
		{
			this.setReloadEventQueue(true);
		}
	}
}
