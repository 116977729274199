
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { clone, isEqual } from 'lodash';
import { CardComponent, CardValidation } from '@/store/rules-engine/types';


@Component({})
export default class RulesCard extends Vue {
    @Prop({type: String, default: ""})
    private cardTitle: string;

    @Prop()
    private cardContent: CardComponent

    @Prop()
    private value: any;

    @Prop({type: Boolean, default: false})
    private hasValidation: boolean;

	@Prop({ type: Boolean, default: false })
	private readOnly: boolean;

    private componentValidation: CardValidation = { isValid:false, message: null };
    private inputValue: any = null;

    //Getters
    private get validationClass(): string
    {
        if(!this.hasValidation)
        {
            return "border-left-blue"
        }

        return this.componentValidation.isValid ? "border-left-green" : "border-left-red";
    }

    private get isValid(): boolean
    {
        return this.componentValidation ? this.componentValidation.isValid : true;
    }

    private get validationMessage(): string
    {
        return this.componentValidation ? this.componentValidation.message : null;
    }

    // Methods
    private mounted(): void
    {
        this.valueChanged();
    }

    @Watch("value")
    private valueChanged(): void
    {
        if(!this.checkHasChanged)
        {
            return;
        }

        this.inputValue = (this.value == null || this.value == undefined) ? null : clone(this.value);
    }

	@Emit("isValid")
    private validationUpdated(isValid: CardValidation): CardValidation
    {
    	this.componentValidation = isValid;
		return isValid;
    }

    @Emit("input")
    private valueUpdated(value: any): any
    {
        this.inputValue = value;
        if(!this.checkHasChanged)
        {
            return
        }

        return value;
    }

    private checkHasChanged(): boolean
    {
        let valueA = this.value;
        let valueB = this.inputValue;

        if (valueA != null && valueB != null)
        {
            if (typeof valueA === "object" && !isEqual(valueA, valueB)) {
                return true;
            }
            else if (valueA.toString() != valueB.toString())
            {
                return true;
            }
        }
        else if (valueA != valueB)
        {
            return true;
        }
    }
}
