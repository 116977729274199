import api from "../../services/api.service";
import { ActionTree } from "vuex";
import { MapLayerItemType, MapLayersState, MapBounds } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<MapLayersState, RootState> = {
	async loadMapDataByBounds({ commit }, { mapBounds, cancelToken }): Promise<void> {
		const mapData = await api.getMapDataInBounds(mapBounds, cancelToken);
		commit("setMapData", mapData);
	},

	async loadMapLayersItemTypes({ commit }) {
		let mapItemTypes: MapLayerItemType[] = await api.getMapItemTypes();

		if (mapItemTypes) {
			mapItemTypes.forEach(mapItemType => {
				mapItemType.visible = !mapItemType.hideByDefault;
			});

			const assetIndex = mapItemTypes.findIndex(x => x.title === "Asset");
			if (assetIndex > -1)
			{
				let assetType = mapItemTypes[assetIndex];
				assetType.mapLayerItemTypeId = -1;
				mapItemTypes[assetIndex] = assetType;
			}

			commit("setMapLayersItemTypes", mapItemTypes);
		}
	},
};
