import { GetterTree } from "vuex";
import { EventCountsByType, EventsQueueState, SiteTreeNode } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<EventsQueueState, RootState> = {
	getActiveEvents: state => state.FilteredEvents,
	getFilteredEvents: state => state.FilteredEvents,
	getFilteredEventIds: state => state.FilteredEventIds,
	getFilteredEventById: state => (id: number) => state.FilteredEvents.filter(event => event.eventID === id)[0],
	getActiveFilterID: state => state.AlarmActiveFilter,
	getCountByType: state => (countType: keyof EventCountsByType) => state.eventCountsByType[countType],
	getAlarmQueueFilterIDs: state => state.AlarmQueueFilterIDs,
	getLastUpdatedEvents: state => state.EventsLastUpdated,
	getAlarmQueueFilters: state => state.AlarmQueueFilters,
	getHoverEvent: state => state.HoverEvent,
	DisplayedEvent: state => state.DisplayedEvent,
	getManualPatrolShown: state => state.ManualPatrolShown,
	isNewFilterModalVisible: state => state.NewFilterModalShown,
	getActivityLogShown: state => state.ActivityLogShown,
	getManualActionShown: state => state.ManualActionShown,
	getSiteTree: state => state.SiteTree,
	getSiteTreeForSelectedTenant: state => (selectedTenant: number) => {
		return state.SiteTree.filter(node =>  node.group.groupID === selectedTenant);
	},
	getSiteTreeCount: state => {
		let total = 0;
		const recursiveCount = (tree: SiteTreeNode) => {
			total++;
			if (tree.subGroups && tree.subGroups.length > 0) {
				tree.subGroups.forEach((node: SiteTreeNode) => {
					recursiveCount(node);
				});
			}
		};
		state.SiteTree.forEach((node: SiteTreeNode) => {
			recursiveCount(node);
		});
		return total;
	},
	getSelectedGroups: state => state.selectedGroups,
	getTreeSelectedGroup: state => state.treeSelectedGroup,
	getSearchSelectedGroup: state => state.searchSelectedGroup,
	getQueueMapBounds: state => state.queueMapBounds,
	getCurrentSort: state => state.CurrentSort,
	getProcessingError: state => state.processingError,
	routingGroups: state => state.RoutingGroups,
	alarmTags: state => state.AlarmTags,
	getLastEventHandled: state => state.lastEventHandled,
	getUserZone: state => state.userZone,
	serverEventTypes: state => state.ServerEventTypes,
	getHandlingEvent: state => state.HandlingEvent,
	getJoiningEventID: state => state.JoiningEventID,
	getTourNoteRequired: state => state.TourNoteRequired,
	getHideMapFlag: state => state.hideMapFlag,
	getMaxEventsToShow: state => state.maxEventsToShow,
	getBulkHandleShown: state => state.bulkHandleShown,
	getVirtualOperatorControlPanelShown: state => state.virtualOperatorControlPanelShown,
	getAllAlarmsSelected: state => state.allAlarmsSelected,
	getSelectedAlarms: state => state.selectedAlarms,
	getRefreshQueue: state => state.refreshQueue,
	getClosingAction: state => state.closingAction,
};
