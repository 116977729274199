
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter, Mutation, Action } from "vuex-class";
@Component({})
export default class SureViewIcon extends Vue {
    @Prop({type: String, default: ""}) text;
    @Prop({type: Number, default: 40}) width;
    @Prop({type: Number, default: 40}) height;
    @Prop({type: String, default: ""}) svgClass;
}
