var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-treeselect',{key:_vm.rerenderKey,ref:"treeSelect",attrs:{"placeholder":_vm.placeholder,"append-to-body":_vm.appendToBody,"options":_vm.areaTree,"searchable":true,"multiple":_vm.multiple,"disabled":_vm.readonly,"clearable":_vm.clearable,"disableFuzzyMatching":true,"auto-load-root-options":false,"load-options":_vm.loadOptions,"always-open":false,"show-count":true,"async":_vm.isAsyncSearchEnabled,"cacheOptions":true,"flat":_vm.fullPathValue ? false : _vm.multiple,"noOptionsText":_vm.isLoadingAreas ? 'Loading...' : 'No areas available',"value":_vm.value,"valueFormat":_vm.valueFormat,"value-consists-of":_vm.fullPathValue ? _vm.fullPath : _vm.groupOnly,"clear-on-select":true,"noChildrenText":"No subareas found."},on:{"close":_vm.close,"open":_vm.open,"search-change":_vm.searchValueChanged,"select":_vm.selected,"input":_vm.onInputChanged,"deselect":_vm.deselect},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('label',{staticStyle:{"margin":"0"}},[(node.label.includes('unknown'))?_c('span',{domProps:{"innerHTML":_vm._s(_vm.areaDictionary[node.id])}}):_c('span',[(_vm.showState)?_c('i',{class:[
					{'fas fa-shield unmasked-color': node.raw.state === 'Unmasked'},
					{'fal fa-shield-alt partially-masked-color': node.raw.state === 'Partially Masked'},
					{'fal fa-shield masked-color': node.raw.state === 'Masked'},
					{'fas fa-shield': !node.raw.state} ]}):_vm._e(),_vm._v(" "+_vm._s(node.label)+" ")])])}},{key:"option-label",fn:function(ref){
				var node = ref.node;
				var shouldShowCount = ref.shouldShowCount;
				var count = ref.count;
				var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName},[(_vm.showState)?_c('i',{class:[
				{'fas fa-shield unmasked-color': node.raw.state === 'Unmasked'},
				{'fal fa-shield-alt partially-masked-color': node.raw.state === 'Partially Masked'},
				{'fal fa-shield masked-color': node.raw.state === 'Masked'} ]}):_vm._e(),_vm._v(" "+_vm._s(node.label)+" "),(count > 0)?_c('span',[_vm._v("("+_vm._s(count)+")")]):_vm._e()])}}])},[(_vm.isSearching && _vm.isSearchComplete)?_c('div',{staticClass:"p-1",attrs:{"slot":"before-list"},slot:"before-list"},[_vm._v(" Showing a maximum of 500 areas, if you cannot find the area you are looking for - try refining your search. ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }