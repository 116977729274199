
import axios, { CancelTokenSource } from "axios";
import { Component, Watch, Prop, Mixins } from "vue-property-decorator";
import { Getter, Mutation, namespace } from "vuex-class";
import { axiosInstance } from "@/axios.instance";
import { FeaturesList, UserPermissions } from "@/store/types";
import {
	getEnhancedMapping,
	IEnhancedMapping,
	IMap,
	IMarker,
	ICircle,
	ILatLng,
	ILatLngBounds,
	convertStringToLatLng,
	stringToBounds,
	union,
	extend,
	MarkerIcons
} from "@sureview/v2-mapping-saas";
import { get, keyBy, differenceBy, cloneDeep } from "lodash";
import { darkMapStyle, getMapStyle } from "@/scripts/mapping/mapStyle";
import { EventDetails } from "@/store/site-monitor/types";

import GPSService from "@/services/gps.service";
import { AssetMapLayerItem } from './mobile-assets/types';
import { FilteredEvent, AlarmQueueFilterID } from '@/store/eventqueue/types';
import { LocationBounds, LayerMap, ItemMap, ItemTypeObjectMap, LayerTypeMap, ItemObjectMap, MarkerArrayMap, MapCameraDevice, SubscriberExpiry, EnhancedMapMode } from '@/types/EnhancedSingleMapTypes';
import Multiselect from "vue-multiselect";
import { MapBounds, MapData, MapLayerItemType, MapLayer, MapLayerItemTypeIds, MapLayerItem, AreaMapDetails, AdvancedFilters, AdvancedFilterOption, DefaultViewTriggers } from "@/store/map-layers/types";
import RegionalHandlerMixin from '@/mixins/RegionalHandlerMixin';
import router from '@/router';
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { AreaNode } from '@/types/sv-data/groups/AreaNode';
import CameraModal from './media/CameraModal.vue';
import FieldOpsMixin from "@/mixins/FieldOpsMixin";
import EventTypes from '@/types/sv-data/enums/EventTypes';
import  {NotificationOptions } from "vue-notification"
import api from '@/services/api.service';
import MapLabel from '@/scripts/mapping/mapLabel';
import { mapRegionMixins, stringMixin, formatDateMixin } from '@/mixins'
import { SituationalAwarenessEventQueue } from '@/store/situational-awareness/types';
import { ExternalMapLayer } from "@/store/external-map-layers/types";
import AreaDetails from "@/components/AreaDetails.vue";
import EnhancedSingleMapControls from "./mapping/EnhancedSingleMapControls.vue";
import AreaDateTimeDisplay from "./AreaDateTimeDisplay.vue";

const MapLayers = namespace("mapLayers");
const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Eventqueue = namespace("eventqueue");
const ManualRaiseStore = namespace("manualRaise");
const FieldOpsStore = namespace("fieldOps");
const ExternalMapLayerStore = namespace("externalMapLayers");
const SchedulesStore = namespace("schedules");

const geoCoder = new GPSService();
const { ellipseAfterX } = stringMixin.methods;
const formatDateMethod = formatDateMixin.methods;
const fromNow = formatDateMethod.fromNow.bind(formatDateMethod)

const {
	getRegionBounds,
	getPolygonOptionsForRegion,
} = mapRegionMixins.methods;

@Component({
	components: {
		"multiselect": Multiselect,
		"area-tree-select": AreaTreeSelect,
		"camera-modal" : CameraModal,
		"area-details": AreaDetails,
		"enhanced-single-map-controls": EnhancedSingleMapControls,
		"area-date-time-display": AreaDateTimeDisplay
	}
})
export default class EnhancedSingleMap extends Mixins(RegionalHandlerMixin, FieldOpsMixin) {
	// ----------------------------------------------------- Global Logic  ---------------------------------------------------------------------------------
	@Prop({ default: () => 19 })
	public zoomLevel: number;

	@Prop({ default: () => false })
	private showMapToggle: boolean; //Feature flag or just remove

	@Prop()
	private mapStyle!: any;

	@Prop({type:Number, default: EnhancedMapMode.SiteMonitor})
	private enhancedMapMode: EnhancedMapMode;

	@Prop({type: Boolean, default: false})
	private addingAssetLocation: boolean;

	@Prop({type: Boolean, default: false})
	private useAdvancedFilters: boolean;

	public $refs: any = {
		mappingContainer: HTMLElement
	};

	public elevation: number = 0;
	public displayElevation: string = "";

	public layersAdded: LayerMap = new LayerMap();
	public itemsAdded: ItemMap = new ItemMap();
	public markersByTypeAndObject: ItemTypeObjectMap = new ItemTypeObjectMap();
	public mapCenter: ILatLng | null = null;
	public enhancedMapping: IEnhancedMapping | null = null;
	public map: IMap | null = null;
	public layersVisible = false;
	public elevationList: any[] = [];
	private noMapData: boolean = false;
	private mapType: string = "";
	private eventQueueTimer = null;
	private openAlarmOverlay: boolean = false;
	private openFieldOpsOverlay: boolean = false;
	private overlayAsset: any = null;
	private alarmToOpen: any = null;
	private viewingCameras: MapCameraDevice[] = [];
	private openSearch: boolean = false;
	private alarmStyle: HTMLStyleElement;
	private areaMarkers: IMarker[] = [];
	private selectedArea: IMarker = null;
	private loadMapDataToken: CancelTokenSource | null = null;

	// uninitialized intentionally to make it non-reactive
	private previousEvents: any[];

	@MapLayers.State private mapData: MapData;
	@MapLayers.State("advancedFilters") private advancedFilters: AdvancedFilters;
	@MapLayers.Action private loadMapDataByBounds: ({ mapBounds, cancelToken }) => Promise<void>;
	@MapLayers.Action private loadMapLayersItemTypes: () => Promise<void>;
	@MapLayers.Getter("getMapLayerItemTypes") private mapItemTypes: MapLayerItemType[];
	@MapLayers.State("mapItemIcons") private mapIcons!: MarkerIcons;
	@MapLayers.State("filterChanged") private filterChanged: number;
	@MapLayers.Mutation private setMapLayersItemType: any;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getPermissions") private permissions: UserPermissions;
	@Eventqueue.Getter("getActiveEvents") events!: any[];
	@Eventqueue.Action("fetchEventQueue") fetchEventQueue: any;

	@Getter public getUseImperialMeasurements: any;
	@Getter public getMapType: any;
	@Getter public getHideLabels: any;
	@Getter("getIsFieldOpsLicensed") private isFieldOpsLicenced: boolean;
	@Getter("getIsResponseLicensed") private isResponseLicenced: boolean;
	@Mutation public setMapType: any;
	@Getter("getMapKey") public mapKey: any;

	@FieldOpsStore.Action("loadOnlineAssets") private loadOnlineAssets: () => Promise<void>;
	@FieldOpsStore.Action("loadAssetTypes") private loadAssetTypes: () => Promise<void>;
	@FieldOpsStore.Mutation("setViewingAssetId") private setViewingAsset: (assetId: number) => void;
	@FieldOpsStore.State("activeSubscribers") private activeSubscribers: SubscriberExpiry[];

	@SiteMonitor.Action parkEvent: any;

	@SiteMonitor.Action private pollApplianceServerAndDeviceServerDetails: () => Promise<void>;
	@SiteMonitor.Action private stopPollingApplianceServerAndDeviceServerDetails: () => void;
	@SiteMonitor.Action fetchDeviceServerDetails: (deviceIds: number[]) => Promise<void>;
	@SiteMonitor.Action updateCurrentEventLocation: (latLng : string) => Promise<void>;
	@SiteMonitor.Getter("getIsCameraEnabled") private isCameraEnabled: (objectId: number) => boolean;
	@SiteMonitor.Getter("getApplianceOfflineNotification") private applianceOfflineNotification: (objectId: number) => string;
	@ExternalMapLayerStore.Getter("getExternalMapLayers") externalLayersList!: ExternalMapLayer[];
	@ExternalMapLayerStore.Action loadExternalMapLayers: () => Promise<void>;
	@ExternalMapLayerStore.Action updateExternalMapLayerVisibility: (externalMapLayer: ExternalMapLayer) => Promise<void>;
	@SchedulesStore.State("areaTimeZoneId") private areaTimeZoneId: number | null;

	@Watch("advancedFilters", { deep: true })
	private advancedFiltersUpdated(): void {
		if (this.filterChanged != null){
			if (this.filterChanged == -2){
				this.toggleRegions();
				return;
			}
			let filterChanged = this.enabledMapItemTypes.filter(t => t.mapLayerItemTypeId == this.filterChanged);

			if (filterChanged) {
				this.toggleVisibility(filterChanged[0]);
			}
		}
	}

	private get isMobileEventShareEnabled(): boolean {
		return this.getFeature(["Mobile", "FieldOps", "EventShare"]);
	}

	private get isAreaLocationEnabled(): boolean {
		return this.getFeature(["Areas", "ShowOnMap"]);
	}

	private get isTrackedAssetsEnabled(): boolean {
		return this.getFeature(["Mobile", "FieldOps", "TrackedAssets"]);
	}

	private closeCamera(cameraId: number){
		const camera = this.viewingCameras.find(x => x.id === cameraId);
		if(cameraId && camera)
			this.viewingCameras.remove(camera);
	}

	private get isSiteMonitorMode() :boolean{
		return this.enhancedMapMode === EnhancedMapMode.SiteMonitor;
	}

	private get isFieldOpsMode() :boolean {
		return this.enhancedMapMode === EnhancedMapMode.FieldOps;
	}

	private get isEventQueueMode() :boolean{
		return this.enhancedMapMode === EnhancedMapMode.EventQueue;
	}

	private get isEventSearchMode(): boolean {
		return this.enhancedMapMode === EnhancedMapMode.EventSearch;
	}

	private get isSiteMonitorOrEventSearchMode(): boolean {
		return this.isEventSearchMode || this.isSiteMonitorMode;
	}

	private async upLevel() {
		let higherFloors = this.elevationList.filter(elevation => elevation.elevationValue > this.elevation);
		if (higherFloors.length > 0) {
			this.elevation = higherFloors[0].elevationValue;
			await this.updateElevation();
		}
	}

	private async downLevel() {
		let lowerFloors = this.elevationList.filter(elevation => elevation.elevationValue < this.elevation);
		if (lowerFloors.length > 0) {
			this.elevation = lowerFloors[lowerFloors.length - 1].elevationValue;
			await this.updateElevation();
		}
	}

	private async gotoElevation(elevation: any) {
		this.elevation = elevation.elevationValue;
		this.displayElevation = elevation.name;
		await this.updateElevation();
	}

	private async updateElevation() {
		this.clearMap(false);
		await this.loadInBounds(this.map!.mapBounds, (this.map!.currentViewState === "narrow" || (this.isFieldOpsMode && this.map!.map.zoom >= 11)));
		this.getItemsInCircle();
	}

	private showOverlayAlarm(alarm: any){
		this.openAlarmOverlay = true;
		this.alarmToOpen = alarm;
	}

	private get overlayText(): string{
		return this.alarmToOpen ?
		`(${this.alarmToOpen.groupTitle} :
			${this.alarmToOpen.displayTitle ? this.alarmToOpen.displayTitle : this.alarmToOpen.eventTitle})` : ""
	}

	private async openAlarmInSiteMonitor(){
		var previousEvent = {...this.EventDetails}

		//If in eventQueue redirect, if fieldOps new window
		this.handleEventQueueItem(
			this.alarmToOpen,
			false,
			this.isEventQueueMode ? false: this.isFieldOpsMode,
			"Picked up from Map")

		//If we had an active site monitor with an event which isn't the selected one park it
		if(previousEvent && previousEvent.eventID && previousEvent.eventID !== this.alarmToOpen.eventID){
			await this.parkEvent({
					eventId: previousEvent.eventID,
					hours: 0,
					minutes: 0,
					seconds: 0,
					parkNote: 'Switched alarm from map - placing back in queue',
					parkedByMap: true
				});
		}
		this.openAlarmOverlay = false;
	}

	private created() {
		this.setManualRaiseShown(false);
	}

	private destroy() {
		this.clearMap();
	}

	private beforeDestroy() {
		if(this.alarmStyle) {
			document.body.removeChild(this.alarmStyle);
		}

		if(this.map) {
			this.map.reset();
		}

		if (this.enhancedMapping) {
			this.enhancedMapping.dispose();
		}

		clearTimeout(this.eventQueueTimer);
		this.clearMap();
		if(this.eventMarker){
			this.eventMarker.remove();
		}

		this.removeAdhocAlarmMarkers();

		if (this.clickCircle != null) {
			this.clickCircle.remove();
			this.clickCircle = null;
		}

		this.clearAssetMarkers();

		if (this.areaMarkers) {
			this.clearAreaMarkers(this.areaMarkers);
		}

		if(this.alertMarkers) {
			this.clearAlertMarkers(this.alertMarkers);
		}

		if(this.regions){
			this.regions.forEach(m => {
				m.poly.setMap(null);
				if(m.label)
					m.label.onRemove();
				m = null;
			})
		}

		this.stopPollingApplianceServerAndDeviceServerDetails();
	}

	private filterCondition(filter: AdvancedFilterOption, marker?: IMarker): boolean {
		//Assigns the relevant filter condition based on the filter type
		if(filter.filterType == "Basic"){
			return filter.filterCondition as boolean;
		} else if (filter.filterType == "Advanced"){
			return (filter.filterCondition as Function)(marker);
		}
	}

	private filterConditionWithAssetType(showAsset: boolean, asset: AssetMapLayerItem): boolean {
		//Checks the assetTypes array in the store to determine if marker should be visible
		if(!this.useAdvancedFilters){
			return showAsset;
		} else {
			let assetVisible = this.advancedFilters.assetTypes.find(ast => ast == asset.assetTypeId);

			if (assetVisible){
				return showAsset;
			} else {
				return false;
			}
		}
	}

	private filterConditionWithTrackedAsset(showAsset: boolean): boolean {
		if(!this.useAdvancedFilters){
			return showAsset;
		}

		if(!this.isTrackedAssetsEnabled){
			return false;
		} else {
			return showAsset;
		}
	}

	private toggleBasicVisibility(mapItemType: MapLayerItemType): void {
		//Basic filters' visibility need to be toggled here first before passing it on
		//Advanced filters' visibility is controlled beforehand so they go directly to the toggleVisibility method from the watcher
		var newMapItem = {
			...mapItemType,
			visible: !mapItemType.visible
		};

		this.toggleVisibility(newMapItem);
	}

	private toggleVisibility(mapItemType: MapLayerItemType): void {
		this.setMapLayersItemType({ id: mapItemType.mapLayerItemTypeId, data: mapItemType });

		if(mapItemType.mapLayerItemTypeId === MapLayerItemTypeIds.User || mapItemType.mapLayerItemTypeId === -1 || mapItemType.mapLayerItemTypeId === MapLayerItemTypeIds.TrackedAsset)
		{
			this.displayAssetItems();
		}

		if(mapItemType.mapLayerItemTypeId === MapLayerItemTypeIds.Alarm ){
			for (let eventRecordID in this.eventQueueMarkers) {
				this.eventQueueMarkers[eventRecordID].visible = this.useAdvancedFilters ?
					this.filterCondition(this.advancedFilters.alarm, this.eventQueueMarkers[eventRecordID]) :
					mapItemType.visible;
			}
		}

		if (this.markersByTypeAndObject[mapItemType.mapLayerItemTypeId] != null) {
			var ByObject = this.markersByTypeAndObject[mapItemType.mapLayerItemTypeId];

			for (var objectId in ByObject) {
				ByObject[objectId].forEach(marker => (marker.markerTypeVisible = mapItemType.visible));
			}
		}

		if (mapItemType.mapLayerItemTypeId == MapLayerItemTypeIds.Alarm) {
			for (let eventRecordID in this.eventAdHocAlarmMarkers) {
				this.eventAdHocAlarmMarkers[eventRecordID].visible = this.useAdvancedFilters ?
					this.filterCondition(this.advancedFilters.alarm, this.eventQueueMarkers[eventRecordID]) :
					mapItemType.visible;
			}

			for (var eventID in this.eventIcons) {
				this.eventIcons[eventID].forEach(eventMarker => {
					this.eventQueueMarkers[eventMarker.eventRecordID!].visible = this.useAdvancedFilters ?
						this.filterCondition(this.advancedFilters.alarm, this.eventQueueMarkers[eventMarker.eventRecordID!]) :
						mapItemType.visible;
				});
			}
		}

		if(mapItemType.mapLayerItemTypeId == MapLayerItemTypeIds.Area && this.areaMarkers){
			this.areaMarkers.forEach(m => {
				m.visible = mapItemType.visible;
			});
		}
	}

	private clearMap(clearEventMarkers: boolean = true) {
		for (var layerId in this.layersAdded) {
			let layer = this.layersAdded[layerId];

			layer.remove();
			delete this.layersAdded[layerId];
		}

		for (var itemId in this.itemsAdded) {
			let item = this.itemsAdded[itemId];

			item.state = "";
			if (this.markersByTypeAndObject[item.mapLayerItemTypeId]) {
				if (this.markersByTypeAndObject[item.mapLayerItemTypeId][item.objectId!]) {
					delete this.markersByTypeAndObject[item.mapLayerItemTypeId][item.objectId!];
				}
			}
			item.visible = false;
			item.remove();
			delete this.itemsAdded[itemId];
		}

		if (clearEventMarkers) {
			for (var adHocItemId in this.eventAdHocAlarmMarkers) {
				let item = this.eventAdHocAlarmMarkers[adHocItemId];

				item.state = "";
				if (this.eventAdHocAlarmMarkers[item.mapLayerItemTypeId]) {
					if (this.eventAdHocAlarmMarkers[item.mapLayerItemTypeId][item.objectId!]) {
						delete this.eventAdHocAlarmMarkers[item.mapLayerItemTypeId][item.objectId!];
					}
				}
				item.visible = false;
				item.remove();
				delete this.eventAdHocAlarmMarkers[itemId];
			}

			for (var eventID in this.eventIcons) {
				this.eventIcons[eventID].forEach(eventMarker => {
					delete this.eventQueueMarkers[eventMarker.eventRecordID!];
					eventMarker.visible = false;
					eventMarker.remove();
				});

				delete this.eventIcons[eventID];
			}

			if (this.eventMarker) {
				this.eventMarker.visible = false;
				this.eventMarker.remove();
			}

			if (this.manualRaiseAlarmMarker) {
				this.manualRaiseAlarmMarker.visible = false;
				this.manualRaiseAlarmMarker.remove();
			}
		}
	}

	@Watch("elevationList", { deep: true })
	private updateDisplayElevation() {
		if(this.displayElevation === "" && this.elevationList.length > 0) {
			this.displayElevation = this.elevationList[0].name;
		}
	}

	private async mounted() {
		await this.loadMapLayersItemTypes();
		await this.init();
		await this.pollApplianceServerAndDeviceServerDetails();
	}

	private MapLabel: any = null;

	private get isFieldOpsEnabled(): boolean {
		return this.getFeature(["Mobile", "FieldOps"]);
	}

	private async performLoadMapDataByBounds(bounds: MapBounds): Promise<boolean> {

		if (this.loadMapDataToken) {
			this.loadMapDataToken.cancel("Cancel load Map data");
		}

		this.loadMapDataToken = axios.CancelToken.source();

		try {
				await this.loadMapDataByBounds({ mapBounds: bounds, cancelToken: this.loadMapDataToken.token });
			} catch (e) {
				return false; //Return when past requests are cancelled
			}

		return true;
	}

	private async init() {
		this.enhancedMapping = await getEnhancedMapping("google", this.mapKey);
		this.MapLabel = require('@/scripts/mapping/mapLabel').default;
		if (this.isFieldOpsEnabled) {
			await this.loadAssetTypes();
			await this.loadOnlineAssets();
		}


		let pos: LocationBounds = {
			location: { lat: 0, lng: 0 },
			viewport: null
		};

		if (this.eventLocation && this.isSiteMonitorOrEventSearchMode) {
			pos = await geoCoder.decodeLocation(this.eventLocation, this.mapKey);

			let elevationBounds = this.eventElevations;

			if (this.EventDetails.alarmElevation) {
				this.elevation = this.EventDetails.alarmElevation;
			} else if (elevationBounds.minElevation !== null && this.elevation <= elevationBounds.minElevation) {
				this.elevation = elevationBounds.minElevation;
			} else if (elevationBounds.maxElevation !== null && this.elevation <= elevationBounds.maxElevation) {
				this.elevation = elevationBounds.maxElevation;
			} else {
				this.elevation = elevationBounds.minElevation;
			}

			if (pos) {
				this.noMapData = false;
			} else {
				this.noMapData = true;
				pos = {
					location: { lat: 0, lng: 0 },
					viewport: null
				};
			}
		} else {
			this.noMapData = true;
		}


		this.mapCenter = pos.location;
		this.map = await this.enhancedMapping.createMap(
			this.$refs.mappingContainer,
			pos.location,
			1,
			false,
			this.isFieldOpsMode ? "roadmap" : this.getMapType,
			this.isFieldOpsMode ? darkMapStyle : getMapStyle(this.getMapType, this.getHideLabels)
		);

		this.map.map.setOptions({
			zoomControl: true,
			mapTypeControl: true
		});

		this.displayAssetItems();

		this.map!.registerEvent("onClick", (latLong: ILatLng) => {
			if(this.isFieldOpsMode) {
				this.$emit("setClickLocation", latLong.lat + " " + latLong.lng);
			}
			else if (this.addingAlarm && this.isEventQueueMode) {
				this.setManualRaiseAlarmMarkerPosition(latLong);
			}
		});

		if (pos.viewport) {
			this.setBounds({
				north: pos.viewport.northeast.lat,
				east: pos.viewport.northeast.lng,
				south: pos.viewport.southwest.lat,
				west: pos.viewport.southwest.lng
			});
		}

		this.map!.staticMapIcon = this.staticEventMapIcon;
		this.mapType = this.getMapType;

		this.map!.registerEvent("onMapTypeChanged", (mapType: string) => {
			if(this.isSiteMonitorOrEventSearchMode)
				this.setActivity();

			this.mapType = mapType;
			this.setMapType(mapType);

			this.map!.map.setOptions({
				styles: getMapStyle(this.getMapType, this.getHideLabels)
			});
		});

		this.map!.registerEvent("onViewportChangedDelayed", () => {
			if(this.isSiteMonitorOrEventSearchMode)
				this.setActivity();

			if (this.map!.currentViewState === "narrow" || !this.isSiteMonitorOrEventSearchMode) {
				if (this.map!.map.zoom >= 11){
					this.loadInBounds(this.map!.mapBounds, true);
				} else {
					if(this.isAreaLocationEnabled){
						this.getAreaMarkers();
					}

					if(this.isSituationalAwarenessExternalAlertEnabled){
						this.displayAlertsWithLocation();
					}
				}
			} else if (this.isSiteMonitorMode && this.isAreaLocationEnabled) {
				this.getAreaMarkers();
			}
		});

		this.map!.registerEvent("onDisplayTypeChanged", (data: any) => {
			if(this.isSiteMonitorOrEventSearchMode)
				this.setActivity();

			var layersVisible = this.isSiteMonitorOrEventSearchMode ? this.map!.currentViewState === "narrow" : this.map!.map.zoom >= 11;

			for (var layerId in this.layersAdded) {
				this.layersAdded[layerId].visible = layersVisible;
			}

			for (var layerItemId in this.itemsAdded) {
				this.itemsAdded[layerItemId].visible = layersVisible;
			}
		});

		this.AdhocLocationEventRecords.forEach((record: any) => {
			this.createAdHocMarker(record);
		});

		//Load the event queue if we aren't on site monitor mode -- for now
		await this.fetchEventQueue();
		await this.updateEventMarkers();

		//If we aren't using the eventQueue trigger a reload of the eventQueue markers every 5 seconds
		if(!this.isEventQueueMode){
			const poll = () => {
				this.eventQueueTimer = setTimeout(async () => {
					try {
						this.updateEventMarkers();
						poll();
					} catch {
						clearTimeout(this.eventQueueTimer);
						setTimeout(poll, 5000);
					}
				}, 5000);
			};
			poll();
		}

		await this.$nextTick();
		if (this.isSiteMonitorOrEventSearchMode && this.EventDetails) {
			this.onEventLocationChanged();
		}

		if(this.isAreaRegionsEnabled)
			this.loadRegions()
	}

	public async centerOnPosition(position: any, zoom:number = 19, keepZoom: boolean = false){
		if(!this.map){
			setTimeout(() => this.centerOnPosition(position, zoom, keepZoom), 50);
			return;
		}

		if(this.map && position){
			this.map.map.panTo(position);
			if (this.map.map.getZoom() < zoom && !keepZoom) {
				this.map.map.setZoom(zoom);
			}
		}
	}

	// ----------------------------------------------------- Global Logic  ---------------------------------------------------------------------------------

	// ----------------------------------------------------- Site Monitor Logic  ----------------------------------------------------------------------------

	@Prop({ default: () => true })
	private showCircle: boolean;

	private clickCircleEventID: number = 0;
	private clickCircle: ICircle | null = null;
	private eventActiveItems = new Array<IMarker>();
	private eventActiveItemIDs = new Set<number>();
	private eventAdHocAlarmMarkers = new ItemMap();
	private eventMarker: any = null

	@SiteMonitor.Getter("getEventDetails") private EventDetails: EventDetails;
	@SiteMonitor.Getter("getActiveMapItemsRequired") private activeMapItemsRequired: any;
	@SiteMonitor.Getter("getActiveMapItems") private activeMapItems: any;
	@SiteMonitor.State private AdhocLocationEventRecords!: any[];
	@SiteMonitor.Getter("getEventLocationLastSet") private eventLocationLastSet: number;
	@SiteMonitor.Getter("getActiveResponseIDs") private activeResponseIDs: any;
	@SiteMonitor.Mutation private setActiveMapItems: any;
	@SiteMonitor.Mutation private setActiveMapItemsRequired: any;
	@SiteMonitor.Mutation private setMapCircleCenter: any;
	@SiteMonitor.Mutation private setActivity;
	@SiteMonitor.Mutation private updateFirstEventRecordLocation: (location: string) => void;
	@SMCameras.Mutation private setAwaitingCamera: any;
	@SMCameras.Mutation private clearDeviceControllerCameras: any;
	@SiteMonitor.State private goToLocation: string;

	@Watch("goToLocation")
	private async updateLocation(): Promise<void> {
		if(!this.goToLocation){
			return;
		}
		let gpsLocation = await geoCoder.decodeLocation(this.goToLocation, this.mapKey);

		if (gpsLocation) {
			this.mapCenter = gpsLocation.location;

			if (this.showCircle)
			{
				this.loadRadius(this.mapCenter);
			}

			if(gpsLocation.viewport){
				this.setBounds({
					north: gpsLocation.viewport.northeast.lat,
					east: gpsLocation.viewport.northeast.lng,
					south: gpsLocation.viewport.southwest.lat,
					west: gpsLocation.viewport.southwest.lng
				});
			}
			this.map.center = this.mapCenter;
			this.map.zoom = this.zoomLevel;
			this.noMapData = false;
		}
	}

	private setElevationToDefault(): void
	{
		let currentElevation = this.elevationList ? this.elevationList[0]: null;
		if (currentElevation)
		{
			this.elevation = currentElevation.elevationValue;
			this.displayElevation = currentElevation.name;
		}
		else
		{
			this.elevation = null;
			this.displayElevation = null;
		}
	}

	//Make this generic
	private async loadInBounds(mapBounds: any, layersVisible: boolean) {
		if (this.layersVisible != layersVisible) {
			for (var eventrecordId in this.eventAdHocAlarmMarkers) {
				this.eventAdHocAlarmMarkers[eventrecordId].visible = layersVisible;
			}
		}
		this.layersVisible = layersVisible;

		let bounds = {
			north: mapBounds.north,
			south: mapBounds.south,
			east: mapBounds.east,
			west: mapBounds.west
		} as MapBounds;

		bounds.elevation = this.elevation ? this.elevation : 0;

		const loadSucceeded =  await this.performLoadMapDataByBounds(bounds);

		// mapData is not updated, no need for further processing.
		if(!loadSucceeded) {
			return;
		}

		const elevations = this.mapData.elevations.slice();
		this.elevationList = elevations.sort(function(a, b) {
			return a.elevationValue - b.elevationValue;
		});

		if (this.elevation != null && this.elevation != undefined) {
			let currentElevation = elevations.find(
				(elevationFloor: any) => elevationFloor.elevationValue == this.elevation
			);
			if (currentElevation)
			{
				this.displayElevation = currentElevation.name;
			}
			else
			{
				this.setElevationToDefault();
			}
		} else if (elevations.length > 0) {
			this.setElevationToDefault()
		}
		this.displayLayers(this.mapData.layers as MapLayer[]);

		if(this.isSiteMonitorMode || this.isEventSearchMode) {
			await this.setMarkerByEventDetails();
		}

		this.displayMapItems(this.mapData.items as MapLayerItem[]);

		if (this.showCircle && this.isSiteMonitorMode) {
			if (this.clickCircle === null || this.clickCircleEventID !== this.EventDetails.eventID) {
				this.clickCircleEventID = this.EventDetails.eventID;
				this.loadRadius(this.mapCenter);
			}
		}

		if (this.isAreaLocationEnabled) {
			this.displayAreaMarkers(this.mapData.areas as AreaMapDetails[]);
		}
	}

	private async goToEvent(){
		var pos = await geoCoder.decodeLocation(this.eventLocation, this.mapKey);
		if(!pos)
			return;

		this.centerOnPosition(pos.location);
		if(this.showCircle){
			this.loadRadius(pos.location);
		}
	}

	private loadRadius(position: any) {
		if(!this.isSiteMonitorOrEventSearchMode)
			return;

		if (this.clickCircle != null) {
			this.clickCircle.remove();
			this.clickCircle = null;
		}

		this.clickCircle = this.enhancedMapping.createCircle(
			this.map!,
			"#0d87e9",
			0.8,
			2,
			"#0d87e9",
			0.05,
			position,
			this.nearbyEventCameraRadius,
			this.allowSpotlightScaling
		);

		this.clickCircle.editted = latLng => this.getItemsInCircle();
		this.getItemsInCircle();
	}

	private get formattedEventsByEventId() {
		return this.events && keyBy(this.events, 'eventID')
	}

	private get eventLocation() {
		if (!this.EventDetails)
			return "";

		return this.EventDetails.latLong;
	}

	private get allowSpotlightScaling() {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "Map", "SpotlightScaling"]);
	}

	// Feature flag checker for toggle maps feature.
	private get isToggleMapEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Maps", "ToggleEventQueue"]) && this.showMapToggle;
	}

	private get nearbyEventCameraRadius() {
		if (!this.EventDetails ||
			!this.EventDetails.cameraNearbyRadius ||
			this.EventDetails.cameraNearbyRadius < 1)  {
			return 30;
		}
		return this.EventDetails.cameraNearbyRadius;
	}

	private get staticEventMapIcon(): boolean {
		if (!this.EventDetails ||
			!this.EventDetails.staticMapIcon)  {
			return false;
		}
		return this.EventDetails.staticMapIcon;
	}

	private get eventElevations() {
		var details = this.EventDetails;

		if (!details) {
			return {
				minElevation: null,
				maxElevation: null
			};
		}

		return {
			minElevation: details.minElevation,
			maxElevation: details.maxElevation
		};
	}

	@Watch("eventLocationLastSet")
	private async onEventLocationChanged() {
		if(!this.isSiteMonitorOrEventSearchMode)
			return;


		if (this.map && this.EventDetails) {

			let elevationBounds = this.eventElevations;

			if (this.EventDetails.alarmElevation) {
				this.elevation = this.EventDetails.alarmElevation;
			} else if (!elevationBounds.minElevation && this.elevation <= elevationBounds.minElevation) {
				this.elevation = elevationBounds.minElevation;
			} else if (!elevationBounds.maxElevation && this.elevation <= elevationBounds.maxElevation) {
				this.elevation = elevationBounds.maxElevation;
			} else {
				this.elevation = elevationBounds.minElevation;
			}

			if (!this.eventLocation) {
				this.noMapData = true;
			} else {
				let gpsLocation = await geoCoder.decodeLocation(this.eventLocation, this.mapKey);

				if (gpsLocation) {
					this.mapCenter = gpsLocation.location;

					if (this.showCircle) {
						this.loadRadius(this.mapCenter);
					}

					if(gpsLocation.viewport){
						this.setBounds({
							north: gpsLocation.viewport.northeast.lat,
							east: gpsLocation.viewport.northeast.lng,
							south: gpsLocation.viewport.southwest.lat,
							west: gpsLocation.viewport.southwest.lng
						});
					}
					this.map.center = this.mapCenter;
					this.map.zoom = this.zoomLevel;
					this.noMapData = false;
				} else {
					this.noMapData = true;
				}
			}
		}
	}

	private async getItemsInCircle(): Promise<void> {
		if(!this.isSiteMonitorMode)
			return;

		this.clearDeviceControllerCameras();
		const bounds = this.clickCircle.squareBounds;

		let center = this.clickCircle!.center;
		this.setMapCircleCenter(center.lat + " " + center.lng);

		if (this.clickCircle && this.allowSpotlightScaling) {
			this.clickCircle!.editable = true;
		}

		let mapBounds = {
			north: bounds.north,
			south: bounds.south,
			east: bounds.east,
			west: bounds.west
		} as MapBounds;

		// If we don't have any elevation at this point,
		// set elevation to be default: 0
		if (!this.elevation) {
			this.elevation = 0;
		}

		mapBounds.elevation = this.elevation;

		const loadSucceeded =  await this.performLoadMapDataByBounds(mapBounds);

		// mapData is not updated, no need for further processing.
		if(!loadSucceeded) {
			return;
		}

		let items = this.mapData.items as Array<MapLayerItem>;

		let newActiveItems = new Array<IMarker>();
		this.eventActiveItemIDs = new Set<number>();

		let centerPosition = { lat: center.lat, lng: center.lng };
		let radius = this.clickCircle!.radius;
		let itemDistances = new Array<any>();

		let activeMapLayerItems = new Set();

		items.forEach(item => {
			if (item.mapLayerItemTypeId != MapLayerItemTypeIds.Alarm) {
				let marker = this.itemsAdded[item.mapLayerItemId];
				let distance;

				if (marker) {
					distance = geoCoder.getDistance(centerPosition, {
						lat: marker.position.lat,
						lng: marker.position.lng
					});

					if (distance <= radius) {
						marker.state = "active";

						itemDistances.push({
							distance: distance,
							itemType: item.mapLayerItemTypeId,
							title: item.title,
							objectId: marker.objectId
						});

						newActiveItems.push(marker);
						activeMapLayerItems.add(item.mapLayerItemId);
						this.eventActiveItemIDs.add(item.mapLayerItemId);
					}
				} else if (item!.latLong) {
					const position = convertStringToLatLng(item!.latLong!);
					distance = geoCoder.getDistance(centerPosition, {
						lat: position.lat,
						lng: position.lng
					});

					if (distance <= radius && item.minElevation === this.elevation) {
						this.eventActiveItemIDs.add(item.mapLayerItemId);
						activeMapLayerItems.add(item.mapLayerItemId);
						itemDistances.push({
							distance: distance,
							itemType: item.mapLayerItemTypeId,
							title: item.title,
							objectId: item.objectId,
							imperial: false
						});
					} else {
						return;
					}
				}
			}
		});

		if (this.eventActiveItems) {
			this.eventActiveItems.forEach(marker => {
				if (!activeMapLayerItems.has(marker.mapLayerItemId)) {
					if (marker.mapLayerItemTypeId != MapLayerItemTypeIds.Alarm) marker.state = "";
				}
			});
		}
		this.eventActiveItems = newActiveItems;

		itemDistances = itemDistances.sort((a, b) => {
			return a.distance - b.distance;
		});

		var uniqueDistances = [];
		itemDistances.forEach(distance => {
			if (
				!uniqueDistances.find(
					item => item.itemType == distance.itemType && item.objectId == distance.objectId
				)
			) {
				uniqueDistances.push(distance);
			}
		});
		itemDistances = uniqueDistances;

		if (this.getUseImperialMeasurements) {
			itemDistances.forEach(distance => {
				distance.distance = geoCoder.metersToFeet(distance.distance);
				distance.imperial = true;
			});
		}

		this.setActiveMapItems(itemDistances);
		this.setActiveMapItemsRequired(false);
	}

	@Watch("adhocLocationEventRecords")
	private onAdhocLocationEventRecordsChange(records: any[]) {
		if(this.isFieldOpsMode || this.isEventQueueMode)
			return;

		var recordsAdded = [];
		if (this.map) {
			records.forEach(record => {
				if (!this.eventAdHocAlarmMarkers[record.eventRecordID]) {
					this.createAdHocMarker(record);
					recordsAdded.push(record.eventRecordID);
				}
			});
		}

		this.removeAdhocAlarmMarkers(recordsAdded);
	}

	private removeAdhocAlarmMarkers(doNotRemove: string[] = null) {
		for (let eventRecordId in this.eventAdHocAlarmMarkers) {
			if(!doNotRemove || !doNotRemove.includes(eventRecordId))
			{
				// if this object isn't null and it has the remove function
				if (this.eventAdHocAlarmMarkers[eventRecordId] !== null
					&& typeof this.eventAdHocAlarmMarkers[eventRecordId].remove === "function") {
					this.eventAdHocAlarmMarkers[eventRecordId].remove();
				}
				this.eventAdHocAlarmMarkers[eventRecordId] = null;
			}

		}
	}

	private createAdHocMarker(record: any) {
		if (this.isEventQueueMode && this.isFieldOpsMode) {
			return;
		}

		if (this.eventAdHocAlarmMarkers[record.eventRecordID] && typeof this.eventAdHocAlarmMarkers[record.eventRecordID].remove === "function") {
			this.eventAdHocAlarmMarkers[record.eventRecordID].remove();
		}

		var location = record.location.split(",");
		this.eventAdHocAlarmMarkers[record.eventRecordID] = this.enhancedMapping.createMarker(
			this.map!,
			null,
			record.eventRecordID.toString(),
			4,
			record.objectId,
			record.details,
			convertStringToLatLng(location[0]),
			null,
			null,
			null,
			true
		);

		this.eventAdHocAlarmMarkers[record.eventRecordID].state = record.awaitingAcknowledgement ? "active" : "processing";
		this.eventAdHocAlarmMarkers[record.eventRecordID].visible = this.useAdvancedFilters ?
			this.filterCondition(this.advancedFilters.alarm, this.eventAdHocAlarmMarkers[record.eventRecordID]) :
			true;
	}

	private getEventMarkerProcessingState(): string {
		const inProcessing = get(this.formattedEventsByEventId, `[${this.EventDetails.eventID}].inProcessing`);
		const isProcessing = inProcessing || this.EventDetails.unParkAt !== null;

		return isProcessing ? "processing" : "active";
	}

	private async setMarkerByEventDetails() {
		const markerLocation = await geoCoder.decodeLocation(this.eventLocation, this.mapKey);
		if (this.eventMarker) {
			return;
		}
		const marker = this.enhancedMapping.createMarker(
			this.map!,
			null,
			null,
			4,
			this.EventDetails.eventID,
			this.EventDetails.eventTitle,
			markerLocation.location,
			null,
			null,
			null,
			true
		);

		marker.state = this.getEventMarkerProcessingState();
		if(this.canMoveEventLocation){
			marker.isDraggable = true;
			marker.dragend = (latLong: ILatLng) => {
				marker.state = "pendingupdate";
				this.pendingEventMarkerPos = latLong;
			};

		}
		this.originalEventLocation = markerLocation.location;
		this.eventMarker = marker;
	}

	private pendingEventMarkerPos: ILatLng = null;
	private originalEventLocation: ILatLng = null;

	//Displays the alarms with locations from the eventqueue
	private async displayMapItems(mapItems: MapLayerItem[]): Promise<void> {
		let mapItemIDs: string[] = [];
		let markerTypeVisibilityByID = new LayerTypeMap();

		this.mapItemTypes.forEach(markerType => {
			markerTypeVisibilityByID[markerType.mapLayerItemTypeId] = markerType.visible;
		});

		mapItems = mapItems.filter(mt => mt.mapLayerItemTypeId != MapLayerItemTypeIds.Asset && mt.mapLayerItemTypeId != MapLayerItemTypeIds.User);

		let deviceIdsForIsEnabled: number[] =
			mapItems.filter(item => !!item.objectId && item.mapLayerItemTypeId === MapLayerItemTypeIds.Camera)
					.map(item => {
						return item.objectId;
					});
		await this.fetchDeviceServerDetails(deviceIdsForIsEnabled);

		mapItems.forEach(mapItem => {
			let mapLayerItemId = mapItem.mapLayerItemId;

			if ((mapItem.minElevation == null || mapItem.minElevation! <= this.elevation) &&
				(mapItem.maxElevation == null || mapItem.maxElevation! >= this.elevation)
			) {
				mapItemIDs.push(mapItem.mapLayerItemId.toString());

				// we don't want to display the map layer item associated with an alarm if there is an alarm already active for that map layer item,
				// as this will prevent the alarm icon from being interactive.
				let isActiveAlarmMapLayerItem = this.isEventQueueMode && this.events.some(e => e.location === mapItem.latLong);

				if (this.itemsAdded[mapLayerItemId] == undefined && !isActiveAlarmMapLayerItem) {
					let mapMarkerItem = this.enhancedMapping.createMarker(
						this.map!,
						mapItem.mapLayerItemId,
						null,
						mapItem.mapLayerItemTypeId == MapLayerItemTypeIds.TrackedAsset ? this.getTrackedAssetMarkerType() : mapItem.mapLayerItemTypeId,
						mapItem.objectId,
						this.getMapItemTitle(mapItem),
						convertStringToLatLng(mapItem.latLong!),
						mapItem.regionPath,
						mapItem.minElevation,
						mapItem.maxElevation,
						this.layersVisible
					);

					this.itemsAdded[mapLayerItemId] = mapMarkerItem;

					if (markerTypeVisibilityByID[mapItem.mapLayerItemTypeId] != null) {
						let selectedItemType = MapLayerItemTypeIds[mapItem.mapLayerItemTypeId].toString().toLowerCase();

						this.itemsAdded[mapLayerItemId].markerTypeVisible =
							this.useAdvancedFilters ? this.advancedFilters[selectedItemType].filterCondition :
							markerTypeVisibilityByID[mapItem.mapLayerItemTypeId];
					}

					if (mapItem.objectId != null) {
						if (this.markersByTypeAndObject[mapItem.mapLayerItemTypeId] == null) {
							this.markersByTypeAndObject[mapItem.mapLayerItemTypeId] = new ItemObjectMap();
						}

						if (!this.markersByTypeAndObject[mapItem.mapLayerItemTypeId][mapItem.objectId!]) {
							this.markersByTypeAndObject[mapItem.mapLayerItemTypeId][mapItem.objectId!] = [];
						}

						this.markersByTypeAndObject[mapItem.mapLayerItemTypeId][mapItem.objectId!].push(mapMarkerItem);

						if (mapItem.mapLayerItemTypeId === MapLayerItemTypeIds.Camera) {
							if (this.isCameraEnabled(mapMarkerItem.objectId)) {
								mapMarkerItem.isClickable = true;

								mapMarkerItem.click = async () => {
								if (this.isCameraEnabled(mapMarkerItem.objectId)) {
										this.$emit("onCameraIconClick");
										if(this.isSiteMonitorMode){
											this.setAwaitingCamera({
												objectId: mapItem.objectId,
												title: mapItem.title
											});
										} else if (!this.isEventSearchMode) {
											const camera: MapCameraDevice = {
												id: mapItem.objectId,
												title: mapItem.title
											}

											if(!this.viewingCameras.find(x => x === camera))
												this.viewingCameras.push(camera);
										}

									} else {
										mapMarkerItem.isClickable = false;
										mapMarkerItem.state = "broken";
									}
								};

								if (this.eventActiveItemIDs.has(mapLayerItemId)) {
									mapMarkerItem.state = "active";
								}
							} else {
								mapMarkerItem.isClickable = false;
								mapMarkerItem.state = "broken";
							}
						}
					}
				}
			}
		});

		var assetIds = this.assetAndUsersItems ? this.assetAndUsersItems.map(au => au.mapLayerItemId) : [];

		for (var itemId in this.itemsAdded) {
			if (mapItemIDs.indexOf(itemId) === -1) {
				var item = this.itemsAdded[itemId];

				//Don't delete any asset items as this will be handled seperately by the cleanup of old assets in the displayAssetItems() method
				if(assetIds && assetIds.length > 0 && assetIds.includes(parseInt(itemId))){
					continue;
				}

				item.state = "";

				if (this.markersByTypeAndObject[item.mapLayerItemTypeId]) {
					if (this.markersByTypeAndObject[item.mapLayerItemTypeId][item.objectId!]) {
						delete this.markersByTypeAndObject[item.mapLayerItemTypeId][item.objectId!];
					}
				}

				item.remove();
				delete this.itemsAdded[itemId];
			}
		}
	}

	private displayLayers(layers: MapLayer[]) {
		const itemLayerIDs: string[] = [];

		layers.forEach(layer => {
			const elevation = this.elevation;

			let isElevated =
				(layer.minElevation === null || layer.minElevation! <= elevation) &&
				(layer.maxElevation === null || layer.maxElevation! >= elevation);


			if (isElevated) {
				itemLayerIDs.push(layer.mapLayerId.toString());
				if (this.layersAdded[layer.mapLayerId] == undefined) {
					this.layersAdded[layer.mapLayerId] = this.enhancedMapping.createOverlay(
						this.map!,
						layer.mapLayerId,
						layer.title,
						stringToBounds(layer.ne, layer.sw), // boundaries
						layer.rotation, // rotation
						axiosInstance.defaults.baseURL + "/MapLayers/Image/" + layer.mapLayerId.toString(), //imageUrl
						layer.minElevation, // minElevation,
						layer.maxElevation, // maxElevation
						this.layersVisible // , visible
					);
				}
			}
		});

		for (var layerId in this.layersAdded) {
			if (itemLayerIDs.indexOf(layerId) === -1) {
				let layer = this.layersAdded[layerId];

				layer.remove();
				delete this.layersAdded[layerId];
			}
		}
	}

	private async displayAreaMarkers(mapDetails: AreaMapDetails[]): Promise<void> {
		let areaMarkersToRemove = [...this.areaMarkers];

		// get location of event if in site monitor
		let pos;
		if (this.isSiteMonitorMode){
			pos = await geoCoder.decodeLocation(this.eventLocation, this.mapKey);
		}

		try {
			mapDetails.forEach(details => {
				if (!details.markerLocation) {
					return;
				}

				// check for event markers (alarms etc) - don't create area marker if event marker exists with the same lat lng
				for(const itemId in this.eventQueueMarkers){
					let item = this.eventQueueMarkers[itemId];
					if (item && item.position.lat == details.markerLocation.split(" ")[0] && item.position.lng == details.markerLocation.split(" ")[1]){
						return;
					}
				}

				// if we're in site monitor, don't create an area marker if it has the same lat lng as the map centre (selected event location)
				if (this.isSiteMonitorMode && (pos?.location?.lat == details.markerLocation.split(" ")[0] && pos?.location?.lng == details.markerLocation.split(" ")[1])){
					return;
				}

				const areaMapType = this.mapItemTypes.find(t => t.mapLayerItemTypeId === MapLayerItemTypeIds.Area);
				const showAreaAssets = areaMapType ? areaMapType.visible : true;

				//check if areaMarker already exists
				const areaMarkerExists = this.areaMarkers.find(m => m.groupId == details.groupId);

				// Item latLng is formatted here to be used to extend bounds
				let latLng = convertStringToLatLng(details.markerLocation!);
				if(this.isFieldOpsMode && this.computeDefaultView.areas == false){
					this.extendDefaultViewBounds(latLng);
				}

				if(areaMarkerExists){
					const marker = areaMarkerExists;
					marker.visible = showAreaAssets;
					marker.marker.setTitle(details.title);
					marker.moveTo(latLng);
					areaMarkersToRemove = areaMarkersToRemove.filter(m => m.groupId != details.groupId);
				} else {
					let areaMarkerItem = this.enhancedMapping.createMarker(
							this.map!,
							null,
							null,
							MapLayerItemTypeIds.Area,
							null,
							details.title,
							latLng,
							null,
							null,
							null,
							showAreaAssets
					);
					areaMarkerItem.groupId = details.groupId;
					areaMarkerItem.click = () => this.areaMarkerOnClick(areaMarkerItem);
					this.areaMarkers.push(areaMarkerItem);
				}
			});
		} catch (e) {
			console.error("Failed to create an area marker", e);
		}

		let groupsToRemove = areaMarkersToRemove.map(m => m.groupId);
		this.areaMarkers = this.areaMarkers.filter(a => !groupsToRemove.includes(a.groupId));

		this.clearAreaMarkers(areaMarkersToRemove);

		// Mark the areas complete so the view can be calculated
		this.setAreaComputeDefaultView(true);
	}

	private clearAreaMarkers(markers: IMarker[]): void {
		markers.forEach(m => {
			try
			{
				m.remove();
			}
			catch(e)
			{
				console.error("Failed to delete area marker", e);
			}
		});
	}

	private areaMarkerOnClick(selectedArea: IMarker) : void
	{
		this.selectedArea = selectedArea;

		this.$nextTick(() => {
			this.$bvModal.show("modal-area-details");
		});
	}

	private get enabledMapItemTypes(): MapLayerItemType[] {
		if(!this.mapItemTypes || this.mapItemTypes.length < 1){
			return []
		}

		let itemList = [...this.mapItemTypes];

		if(!this.isAreaLocationEnabled){
			itemList = itemList.filter(t => t.mapLayerItemTypeId != MapLayerItemTypeIds.Area);
		}

		if(!this.isTrackedAssetsEnabled){
			itemList = itemList.filter(t => t.mapLayerItemTypeId != MapLayerItemTypeIds.TrackedAsset);
		}

		return itemList;
	}

	private getMapItemTitle(mapItem: MapLayerItem): string {
		if(mapItem.mapLayerItemTypeId === MapLayerItemTypeIds.Camera) {
			if (this.isCameraEnabled(mapItem.objectId)) {
				return mapItem.title;
			} else {
				return this.applianceOfflineNotification(mapItem.objectId);
			}
		} else {
			return mapItem.title;
		}
	}

	private async saveEventMarkerLocation(): Promise<void> {
		const location = this.pendingEventMarkerPos.lat + " " + this.pendingEventMarkerPos.lng;
		await this.updateCurrentEventLocation(location);
		this.updateFirstEventRecordLocation(location);
		this.eventMarker.state = this.getEventMarkerProcessingState();
		this.originalEventLocation =  this.pendingEventMarkerPos;
		this.pendingEventMarkerPos = null;
	}

	private resetEventMarkerLocation() {
		this.eventMarker.state = this.getEventMarkerProcessingState();
		this.eventMarker.moveTo(this.originalEventLocation);
		this.pendingEventMarkerPos = null;
	}

	private get canMoveEventLocation(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "UpdateEventLocation"]) && (this.permissions.canUpdateEventLocation || this.permissions.isSystemAdmin);
	}

	// ----------------------------------------------------- Site Monitor Logic  ----------------------------------------------------------------------------

	// ----------------------------------------------------- Field Ops Logic  -------------------------------------------------------------------------------
	@Prop()
	private selectedAssetItemId: number | null;

	@FieldOpsStore.Action private updateAssetLocation: (asset: AssetMapLayerItem) => void;
	@FieldOpsStore.State private situationalAwarenessEventQueue: SituationalAwarenessEventQueue[];
	@FieldOpsStore.State private selectedSituationalAwarenessEvent: SituationalAwarenessEventQueue;

	@MapLayers.Getter private triggerDefaultView: boolean;
	@MapLayers.State private defaultViewForUser: google.maps.LatLngBounds;
	@MapLayers.State private computeDefaultView: DefaultViewTriggers;
	@MapLayers.Mutation private setAreaComputeDefaultView: (compute: boolean) => void;
	@MapLayers.Mutation private setAssetComputeDefaultView: (compute: boolean) => void;
	@MapLayers.Mutation private extendDefaultViewBounds: (latLng: google.maps.LatLngLiteral) => void;

	@Getter getPermissions: UserPermissions;

	private alertMarkers: IMarker[] = [];

	private currentSelectedAssetItemId: number | null;

	private get isSituationalAwarenessExternalAlertEnabled() : boolean {
		let isFeatureEnabled: boolean = this.getFeature(["SituationalAwareness","ExternalAlerts"]);
		let userHasPermission: boolean = (this.getPermissions.canViewSituationalAwarenessEvents || this.getPermissions.canDismissSituationalAwarenessEvent || this.getPermissions.canPromoteSituationalAwarenessEvent) && this.getPermissions.isSystemAdmin;

		return isFeatureEnabled && userHasPermission;
	}

	@Watch("triggerDefaultView")
	private setDefaultBounds(): void {
		if(this.isFieldOpsMode && this.defaultViewForUser != null && this.triggerDefaultView){
			this.map!.map.fitBounds(this.defaultViewForUser);
		}
	}

	@Watch("assetAndUsersItems", { deep: true })
	private async displayAssetItems() {
		if (this.isEventSearchMode) {
			return;
		}

		const assetMarkersToRemove = new Map(this.assetItemMarkers);
		const trackedMarkersToRemove = new Map(this.trackedAssetItemMarkers);

		const userType = this.mapItemTypes.find(x => x.mapLayerItemTypeId === MapLayerItemTypeIds.User)
		const assetType = this.mapItemTypes.find(x => x.title === 'Asset') ;
		const trackedAssetType = this.mapItemTypes.find(x => x.mapLayerItemTypeId === MapLayerItemTypeIds.TrackedAsset);
		const showUsers = (userType && this.useAdvancedFilters) ? this.filterCondition(this.advancedFilters.user) :
							userType ? userType.visible : true;
		const showAssets = (assetType && this.useAdvancedFilters) ? this.filterCondition(this.advancedFilters.asset) :
							assetType ? assetType.visible : true;

		let showTrackedAssets: boolean;
		if (this.isTrackedAssetsEnabled){
			showTrackedAssets = (trackedAssetType && this.useAdvancedFilters) ? this.filterCondition(this.advancedFilters.trackedAsset) :
								trackedAssetType ? trackedAssetType.visible : true;
		} else {
			showTrackedAssets = false;
		}

		if(!this.assetAndUsersItems)
		{
			return;
		}

		if(!this.map)
		{
			setTimeout(() => this.displayAssetItems(), 50);
			return;
		}

		this.assetAndUsersItems.forEach(item => {
			// If the marker does not have a location, do not try to draw it
			if (!item.latLng) {
				return;
			}

			if(item.isTrackedAsset)
			{
				if (this.trackedAssetItemMarkers.has(item.trackedAssetId))
				{
					const marker = this.trackedAssetItemMarkers.get(item.trackedAssetId);
					marker.visible = this.filterConditionWithTrackedAsset(showTrackedAssets);
					marker.moveTo(item.latLng);
					trackedMarkersToRemove.delete(item.trackedAssetId);
					marker.marker.setTitle(this.getMarkerTitleForAsset(item));
				}
				else
				{
					const marker = this.createMarkerForAsset(item, this.filterConditionWithTrackedAsset(showTrackedAssets));
					this.trackedAssetItemMarkers.set(item.trackedAssetId, marker);
				}
			}
			else
			{
				const canShowMarker :boolean = (item.isAsset ? showAssets : showUsers) && !item.hideOnMap;
				if (this.assetItemMarkers.has(item.assetId))
				{
					const marker = this.assetItemMarkers.get(item.assetId);
					marker.visible = this.filterConditionWithAssetType(canShowMarker, item);
					marker.moveTo(item.latLng);
					assetMarkersToRemove.delete(item.assetId);
					const icon = this.getIconForAssetType(item, this.selectedAssetItemId === item.assetId);
					marker.marker.setTitle(this.getMarkerTitleForAsset(item));
					marker.marker.setIcon(icon);
				}
				else
				{
					const marker = this.createMarkerForAsset(item, this.filterConditionWithAssetType(canShowMarker, item));
					this.assetItemMarkers.set(item.assetId, marker);
				}
			}

			// Item latLng is already formatted correctly for extending bounds
			if(this.isFieldOpsMode && this.computeDefaultView.assets == false){
				this.extendDefaultViewBounds(item.latLng!);
			}
		});

		assetMarkersToRemove.forEach(m => {
			try
			{
				m.remove();
				this.assetItemMarkers.delete(m.assetId);
			}
			catch(e)
			{
				console.error("Failed to delete asset marker", e)
			}
		});

		trackedMarkersToRemove.forEach(m => {
			try
			{
				m.remove();
				this.trackedAssetItemMarkers.delete(m.trackedAssetId);
			}
			catch(e)
			{
				console.error("Failed to delete tracked asset marker", e)
			}
		});

		this.setSelectedItem();

		if(this.isMobileEventShareEnabled){
			// Set up the alarm pulsing
			if(this.alarmStyle) {
				document.body.removeChild(this.alarmStyle);
				this.alarmStyle = null;
			}

			const activeEventShareAssets = this.assetAndUsersItems.filter(x => x.activeEventShare).map(x => `[title="${this.getMarkerTitleForAsset(x)}"]`);
			if(activeEventShareAssets && activeEventShareAssets.length > 0){
				if(!this.alarmStyle)
				{
					this.alarmStyle = document.createElement('style')
				}

				this.alarmStyle.innerHTML = `${activeEventShareAssets.toString()} {
						border-radius: 50%;
						box-shadow: 0 0 0 0 rgba(226, 19, 19, 1);
						transform: scale(1);
						animation: pulseOrange 2s infinite;
					}
					`
				document.body.appendChild(this.alarmStyle);
			}
		}

		// Mark the assets complete so the view can be calculated
		this.setAssetComputeDefaultView(true);
	}

	@Watch("situationalAwarenessEventQueue", { deep: true })
	private displayAlertsWithLocation(): void {
		if(this.situationalAwarenessEventQueue == null || this.situationalAwarenessEventQueue.length < 1){
			return;
		}

		let alertMarkersToRemove = (this.alertMarkers && this.alertMarkers.length < 1) ? []: [...this.alertMarkers];

		try {
			this.situationalAwarenessEventQueue.forEach(event => {
				if (!event.location) {
					return;
				}

				//check if alertMarker already exists
				const alertMarkerExists = this.alertMarkers.find(m => m.situationalAwarenessEventQueueId == event.situationalAwarenessEventQueueId);

					if(alertMarkerExists){
					const marker = alertMarkerExists;
					marker.marker.setTitle(event.description);
					marker.moveTo(convertStringToLatLng(event.location!));
					alertMarkersToRemove = alertMarkersToRemove.filter(m => m.situationalAwarenessEventQueueId != event.situationalAwarenessEventQueueId);
					} else {
						let alertMarkerItem = this.enhancedMapping.createMarker(
							this.map!,
							null,
							null,
							MapLayerItemTypeIds.Alarm,
							null,
							event.description,
						    convertStringToLatLng(event.location!),
							null,
							null,
							null,
							true
						);
						alertMarkerItem.situationalAwarenessEventQueueId = event.situationalAwarenessEventQueueId;
						this.alertMarkers.push(alertMarkerItem);
				}
			});
		} catch (e) {
			console.error("Failed to create an alert marker", e);
		}

		let alertsToRemove = alertMarkersToRemove.map(m => m.situationalAwarenessEventQueueId);
		this.alertMarkers = this.alertMarkers.filter(a => !alertsToRemove.includes(a.situationalAwarenessEventQueueId));

		this.clearAlertMarkers(alertMarkersToRemove);
	}

	@Watch("selectedSituationalAwarenessEvent")
	private goToAlertLocation(): void {
		if(this.selectedSituationalAwarenessEvent == null){
			return;
		}

		const alertMarker = this.alertMarkers.find(am => am.situationalAwarenessEventQueueId == this.selectedSituationalAwarenessEvent.situationalAwarenessEventQueueId);

		if(alertMarker){
			this.centerOnMarker(alertMarker);
		}
	}

	private clearAlertMarkers(markers: IMarker[]): void {
		markers.forEach(m => {
			try
			{
				m.remove();
			}
			catch(e)
			{
				console.error("Failed to delete alert marker", e);
			}
		});
	}

	public centerOnAssetItem(assetId: number) {
		const marker = this.assetItemMarkers.get(assetId);
		if (marker) {
			this.centerOnMarker(marker);
		}
	}

	public centerOnEventQueueItem(eventRecordId: number) {
		const marker = this.eventQueueMarkers[eventRecordId];
		if (marker) {
			this.centerOnMarker(marker);
		}
	}

	private centerOnMarker(marker: any) {
		if (this.map) {
			// center map on marker's position and set it to the default 'selected' zoom level
			this.map.map.panTo(marker.marker.position);
			if (this.map.map.getZoom() < 17) {
				this.map.map.setZoom(17);
			}
		}
	}

	@Watch("selectedAssetItemId")
	private async setSelectedItem() {
		if (this.selectedAssetItemId === this.currentSelectedAssetItemId) {
			return;
		}

		if (this.selectedAssetItemId) {
			this.setMarkerIconForItem(this.selectedAssetItemId, true);
			this.centerOnAssetItem(this.selectedAssetItemId);

		}

		// clear previously selected
		if (this.currentSelectedAssetItemId && this.assetItemMarkers.has(this.currentSelectedAssetItemId)) {
			this.setMarkerIconForItem(this.currentSelectedAssetItemId, false);
		}

		this.currentSelectedAssetItemId = this.selectedAssetItemId;
	}

	private getMarkerTitleForAsset(asset: AssetMapLayerItem): string {
		let title =  asset.activeEventShare && !asset.isTrackedAsset ? `${asset.title} | Attending : ${asset.eventDetails}` : asset.title;

		if (asset.userId || asset.isTrackedAsset) {
			const lastUpdatedInMinutes = fromNow(asset.lastLocationUpdate);
			title = `${title} | Last updated : ${lastUpdatedInMinutes ? lastUpdatedInMinutes : "Unknown"}`;
		}

		if (asset.status) {
			title = `${title} | Status : ${asset.status}`;
		}

		return title;
	}

	private createMarkerForAsset(assetMapLayerItem: AssetMapLayerItem, visible: boolean) {
		if(!assetMapLayerItem) {
			return;
		}

		const marker = this.enhancedMapping.createMarker(
			this.map,
			assetMapLayerItem.isTrackedAsset ? assetMapLayerItem.trackedAssetId : assetMapLayerItem.assetId, // mapLayerItemId
			null, // eventRecordId
			this.getMarkerType(assetMapLayerItem), // mapLayerItemTypeId: number | string | MarkerType
			assetMapLayerItem.assetTypeId, // asset type
			this.getMarkerTitleForAsset(assetMapLayerItem),
			assetMapLayerItem.latLng,
			null, // region
			null, // minElevation
			null, // maxElevation
			visible // visible: boolean
		);

		// If this item is an asset (not a mobile user) - allow operators to move them on the map
		if(assetMapLayerItem.isAsset && !assetMapLayerItem.isTrackedAsset){
			marker.isDraggable = true;
			marker.dragend = (latLong: ILatLng) => {
				var assetClone = {...assetMapLayerItem,}
				assetClone.latLng = latLong;
				assetClone.latLong = latLong.lat + " " + latLong.lng;
				marker.moveTo(latLong);
				this.updateAssetLocation(assetClone);
			};
		}

		marker.isClickable = !assetMapLayerItem.isTrackedAsset;

		marker.click = async() => {
			try
			{
				if(assetMapLayerItem.isTrackedAsset)
				{
					return;
				}

				if (!this.isFieldOpsMode && this.isFieldOpsLicenced)
				{
					this.setViewingAsset(null);
					await this.$nextTick();
					this.setViewingAsset(assetMapLayerItem.assetId);
					var activeFieldOpsPage = this.activeSubscribers.find(x => x.subscriber === "FieldOps")
					if(this.isSiteMonitorOrEventSearchMode) {
						this.overlayAsset = {...this.assetAndUsersItems.find(x => x.assetId === assetMapLayerItem.assetId)};
						this.openFieldOpsOverlay = true;
					} else {
						if(!activeFieldOpsPage || activeFieldOpsPage.expiry <= Date.now())
							this.openFieldOps();
					}
				}
			} catch(ex){
				throw ex;
			}


			this.$emit("markerClicked", assetMapLayerItem);
			this.centerOnPosition({...marker.position})
		};

		this.itemsAdded[assetMapLayerItem.mapLayerItemId] = marker;

		return marker;
	}

	private openFieldOps(){
		this.openFieldOpsOverlay = false;
		this.overlayAsset = null;
		const routeData = router.resolve({
			path: "/field-ops"
		});
		window.open(
			routeData.href,
			"field-ops"
		).focus();
	}
	// ----------------------------------------------------- Field Ops Logic  -------------------------------------------------------------------------------

	// ----------------------------------------------------- Event Queue Logic  -----------------------------------------------------------------------------
	@Eventqueue.Getter("getLastUpdatedEvents") eventsLastUpdated!: number;
	@Eventqueue.Getter("getHoverEvent") hoverEvent: any;
	@Eventqueue.Getter DisplayedEvent: FilteredEvent;
	@Eventqueue.Getter getAlarmQueueFilterIDs: AlarmQueueFilterID[];
	@Eventqueue.Getter("getActiveFilterID") currentFilterID!: AlarmQueueFilterID;

	// Getter and action for boolean flag which determines whether the map is shown.
	@Eventqueue.Action setHideMapFlag : any;
	@Eventqueue.Getter("getHideMapFlag") hideMapFlag: boolean;

	@ManualRaiseStore.State selectedMapLayer: any;
	@ManualRaiseStore.State manualRaiseShown: boolean;
	@ManualRaiseStore.State("areaMapBounds") manualRaiseAreaMapBounds: any;
	@ManualRaiseStore.Mutation setAlarmMarkerPosition: any;
	@ManualRaiseStore.Mutation setSelectedMapElevation: any;
	@ManualRaiseStore.Mutation setManualRaiseShown: (visible: boolean) => void;
	@ManualRaiseStore.Mutation("setSelectedGroup") setManualRaiseSelectedGroup: (areaNode: AreaNode) => void;

	private eventQueueMarkers: ItemMap = new ItemMap();
	private manualRaiseAlarmMarker: IMarker | null = null;
	private groups: any[] = [];
	private groupSearchLoading = false;
	private selectedGroup: any = null;
	private eventIcons: MarkerArrayMap = new MarkerArrayMap();
	private createdSearchAutocomplete: boolean = false;
	private searchType: string = "area";
	private filterBoundsChanged: boolean = false;
	private filterBounds: ILatLngBounds | null = null;
	private searchAutoComplete: google.maps.places.Autocomplete | null = null;

	private setSearchAutocomplete() {
		setTimeout(() => {
			this.searchAutoComplete = new google.maps.places.Autocomplete(
				document.getElementById("tbLocation") as HTMLInputElement
			);

			this.searchAutoComplete!.bindTo("bounds", this.map!.map);
			(this.searchAutoComplete! as any).setFields(["address_components", "geometry", "name"]);

			this.searchAutoComplete!.addListener("place_changed", () => {
				let place = this.searchAutoComplete!.getPlace();

				if (place.geometry) {
					if (place.geometry.viewport) {
						this.map!.map.fitBounds(place.geometry.viewport);
					} else if (place.geometry.location) {
						this.map!.map.setCenter(place.geometry.location);
						this.map!.map.setZoom(11);
					}

					if (place.geometry.location && this.addingAlarm) {
						this.setManualRaiseAlarmMarkerPosition(place.geometry.location);
					}
				}
			});
		}, 0.5 * this.$config.ANIMATION_DURATION);
	}

	@Watch("selectedGroup", {deep:true})
	private selectedGroupWatch(){
		this.searchAreaSelected(this.selectedGroup)
	}

	@Watch("searchType")
	private onSearchTypeChanged(value: string) {
		if (value === "location") {
			if (!this.createdSearchAutocomplete) {
				this.createdSearchAutocomplete = true;
				this.setSearchAutocomplete();
			}
		}
	}

	private get addingAlarm() {
		return this.isEventQueueMode ? this.manualRaiseShown : false;
	}

	@Watch("currentFilterID")
	private onCurrentFilterChange(filter: any) {
		if (this.map != null) {
			this.setFilterBounds(filter.filterId, filter.regionId);
		}
	}

	@Watch("eventsLastUpdated")
	public onEventsChanged() {
		this.updateEventMarkers();
	}

	@Watch("hoverEvent")
	private onhoverEventChanged(event: any, oldEvent: any) {
		if (oldEvent && this.eventIcons[oldEvent.eventID]) {
			this.eventIcons[oldEvent.eventID].forEach(marker => {
				marker.state = marker.state.replace("highlight", "");
			});
		}

		if (event && this.eventIcons[event.eventID]) {
			this.eventIcons[event.eventID].forEach(marker => {
				marker.revertState = marker.state;
				if (marker.state == "active") {
					marker.state = "highlightactive";
				} else if (marker.state == "processing") {
					marker.state = "highlightprocessing";
				}
			});
		}
	}

	private setFilterBounds(filterID: number, regionID: number) {
		if (filterID === -1) {
			this.filterBounds = null;
			this.filterBoundsChanged = true;
		} else if (filterID === 0) {
			let openFilters = this.getAlarmQueueFilterIDs;
			if (openFilters.length > 0) {
				let requestPromises = [];

				openFilters.forEach(filter => {
					requestPromises.push(
						axiosInstance.get(
							"/AlarmQueueFilters/Bounds/" +
								filter.filterId +
								(filter.regionId ? "?regionId=" + filter.regionId : "")
						)
					);
				});

				Promise.all(requestPromises).then(values => {
					let allBounds: ILatLngBounds = null;

					values.forEach(response => {
						if (response.data && response.data.anyBounds) {
							let filterBounds = response.data as ILatLngBounds;

							if (!allBounds) {
								allBounds = {
									north: filterBounds.north,
									east: filterBounds.east,
									south: filterBounds.south,
									west: filterBounds.west
								};
							} else {
								allBounds = union(allBounds, filterBounds);
							}
						}
					});

					this.filterBounds = allBounds;
					if (allBounds) {
						this.setBounds(this.filterBounds);
					}
					this.filterBoundsChanged = true;
				});
			}
		} else {
			axiosInstance
				.get("/AlarmQueueFilters/Bounds/" + filterID + (regionID ? "?regionId=" + regionID : ""))
				.then(response => {
					let filterBounds = response.data;

					if (filterBounds && filterBounds.anyBounds) {
						this.filterBounds = {
							north: filterBounds.north,
							east: filterBounds.east,
							south: filterBounds.south,
							west: filterBounds.west
						};
						this.setBounds(this.filterBounds);
					} else {
						this.filterBounds = null;
					}

					this.filterBoundsChanged = true;
				});
		}
	}

	@Watch("DisplayedEvent")
	private onDisplayedEventChanged(event) {
		if(!this.isEventQueueMode)
			return;

		if (event && event.location) {
			const latLngString = event.location;
			this.centerOnPosition(convertStringToLatLng(latLngString), 16);
		}
	}

	@Watch("manualRaiseAreaMapBounds")
	private onManualRaiseAreaMapBoundsChanged(value: any, oldValue: any) {
		if (this.map) {
			if (value) {
				this.setBounds(value);
			} else if (oldValue) {
				this.setFilterBounds(this.currentFilterID.filterId, this.currentFilterID.regionId);
			}
		}
	}

	@Watch("selectedMapLayer")
	private async onManualRaiseSelectedLayerChanged(value: any, oldValue: any) {
		if (get(value, ["minElevation"]) && get(value, ["minElevation"]) !== get(oldValue, ["minElevation"])) {
			await this.gotoElevation({ elevationValue: value.minElevation });
		}
	}

	@Watch("addingAlarm")
	private async onAddAlarmChanged(value: boolean) {
		if(!this.isEventQueueMode) //Only allow add alarms on the event queue mode (for now)
			return;

		if (this.map) {
			if (value) {
				this.map!.setCursor("crosshair");
				if (this.map!.currentViewState == "narrow") {
					await this.loadInBounds(this.map!.mapBounds, true);
				}

				for (let eventRecordID in this.eventQueueMarkers) {
					this.eventQueueMarkers[eventRecordID].state = "muted" + this.eventQueueMarkers[eventRecordID].state;
				}
			} else {
				this.map!.setCursor("grab");
				if (this.manualRaiseAlarmMarker != null) {
					this.manualRaiseAlarmMarker.remove();
					this.manualRaiseAlarmMarker = null;
				}

				this.selectedGroup = null;
				this.setAlarmMarkerPosition("");

				for (let eventRecordID in this.eventQueueMarkers) {
					this.eventQueueMarkers[eventRecordID].state = this.eventQueueMarkers[eventRecordID].state.replace("muted", "");
				}
			}
		}
	}

	private setManualRaiseAlarmMarkerPosition(latLng: any) {
		if (this.manualRaiseAlarmMarker) {
			this.manualRaiseAlarmMarker.remove();
			this.manualRaiseAlarmMarker = null;
		}

		this.manualRaiseAlarmMarker = this.enhancedMapping.createMarker(
			this.map!,
			null,
			null,
			4,
			null,
			"Alarm",
			latLng,
			null,
			null,
			null,
			true
		);
		this.manualRaiseAlarmMarker.state = "active";
		this.manualRaiseAlarmMarker.isDraggable = true;
		this.manualRaiseAlarmMarker.isClickable = false;

		this.manualRaiseAlarmMarker.dragend = (latLong: ILatLng) => {
			this.setAlarmMarkerPosition(latLong.lat + " " + latLong.lng);
		};

		if (latLng instanceof google.maps.LatLng) {
			this.setAlarmMarkerPosition(latLng.lat() + " " + latLng.lng());
		} else {
			this.setAlarmMarkerPosition(latLng.lat + " " + latLng.lng);
		}

		this.map!.center = latLng;
	}

	private async getAreaMarkers(): Promise<void> {
		if(this.map){
			let bounds = this.map!.mapBounds

			var mapDataResponse = await axiosInstance.post("MapLayers/AreaData", bounds);
			const mapData = mapDataResponse.data;

			if (!mapData){
				return;
			}

			this.displayAreaMarkers(mapData.areas);
		} else {
			console.log("Map is null")
		}
	}

	private async updateEventMarkers() {
		if ((this.manualRaiseShown && this.isEventQueueMode) || this.isEventSearchMode) {
			return;
		}

		if (this.map) {
			let events;
			//If in eventQueue only show the events in the event queue
			if(this.isEventQueueMode) {
				events = this.events;
			} else  {
				// Otherwise only show the events which we can plot (i.e. with locations)
				// create payload to post to url defined above
				const requestData = {
					filters: [],
					sortBy: 1,
					sortDesc: true,
					includeAll: true,
					onlyWithLocation: false,
					eventTypes: [EventTypes.Alarm]
				};

				// post as JSON, using the in-built transformRequest (outputs a JSON string)
				var filteredEventsResponse = await axiosInstance.post("/eventqueue", requestData, {
					headers: {
						"Content-Type": "application/json"
					},
					transformRequest: axios.defaults.transformRequest
				});
				const { eventQueue: eventsList } = filteredEventsResponse.data;

				events = eventsList
			}

			if (events == null)
				return;

			var bounds = {} as ILatLngBounds;
			var boundsSet = false;

			let eventIDs = new Set<number>();

			let eventsWithLocations = [];
			if(this.isSiteMonitorOrEventSearchMode && this.EventDetails)
			{
				events = events.filter(e => e.eventID != this.EventDetails.eventID)
				if(this.eventQueueMarkers[this.EventDetails.eventID]){
					this.eventQueueMarkers[this.EventDetails.eventID].visible = false;
					this.eventQueueMarkers[this.EventDetails.eventID].remove();
				}
			}

			var eventsWithNewLocation: number[] = [];

			// Only try and plot the events that have locations (might need to change up the event queue retrieve as it only retrieves the last 200 events)
			events.filter(e => e.location).forEach((event: any) => {
				eventsWithLocations.push(event);

				if (this.eventQueueMarkers[event.eventID] != null && this.isEventQueueMode) {
					let newLatLng: {lat:number, lng: number} = convertStringToLatLng(event.location);
					let existingLatLng = this.eventQueueMarkers[event.eventID]?.position;
					if((newLatLng && existingLatLng) && (newLatLng.lat != existingLatLng.lat || newLatLng.lng != existingLatLng.lng))
					{
						eventsWithNewLocation.push(event.eventID)
					}
				}
			});

			eventsWithLocations.forEach((event: any) => {

				let latLong = event.location;
				let position = convertStringToLatLng(latLong);

				// if it is a valid location add/update markers
				if(position) {
					eventIDs.add(event.eventID);

					if (this.eventQueueMarkers[event.eventID] == null) {

						let newMarker = this.enhancedMapping.createMarker(
							this.map!,
							null,
							null,
							4,
							event.eventID,
							event.eventTitle,
							position,
							null,
							null,
							null,
							true
						);

						newMarker.visible = this.useAdvancedFilters ? this.advancedFilters.alarm.filterCondition : true

						newMarker.isClickable = true;
						newMarker.click = () => {
							if(!this.isSiteMonitorOrEventSearchMode && this.isResponseLicenced){
								this.showOverlayAlarm(event);
							}
							this.centerOnPosition({...newMarker.position});
						};
						bounds = extend(bounds, position);
						boundsSet = true;

						this.eventQueueMarkers[event.eventID] = newMarker;

						if (this.eventIcons[event.eventID] == null)
							this.eventIcons[event.eventID] = new Array<IMarker>();

						this.eventIcons[event.eventID].push(newMarker);

					}
					else if (this.filterBoundsChanged || eventsWithNewLocation.includes(event.eventID)) {
						bounds = extend(bounds, position);
						boundsSet = true;
					}

					if (this.isSiteMonitorMode)
					{
						this.eventQueueMarkers[event.eventID].state =
							event.inProcessing === 1 || event.unParkAt !== null ? "backgroundprocessing" : "backgroundactive";
					}
					else if (this.addingAlarm)
					{
						this.eventQueueMarkers[event.eventID].state =
							event.inProcessing === 1 || event.unParkAt !== null ? "mutedprocessing" : "mutedactive";
					} else {
						//highlight

						let marker = this.eventQueueMarkers[event.eventID];

						if (marker.state && marker.state.indexOf("highlight") > -1) {
							marker.state =
								event.inProcessing == 1 || event.unParkAt != null
									? "highlightprocessing"
									: "highlightactive";
						} else {
							marker.state =
								event.inProcessing == 1 || event.unParkAt != null ? "processing" : "active";
						}
					}
				}
			});

			// Scenario:
			// given an event had an eventQueueMarker
			// but the current location is not a valid location,
			// the loop below will remove the existing marker added for the
			// last valid location.
			for (var eventID in this.eventIcons) {
				if (!eventIDs.has(parseInt(eventID))) {
					this.eventIcons[eventID].forEach(eventMarker => {
						if (eventMarker.eventRecordID)
							delete this.eventQueueMarkers[eventMarker.eventRecordID!];

						eventMarker.remove();
					});

					delete this.eventIcons[eventID];
				}
			}

			if (boundsSet && this.isEventQueueMode) {
				if (this.filterBounds) {
					bounds = union(bounds, this.filterBounds!);
				}

				if (!this.manualRaiseAreaMapBounds)
					this.setBounds(bounds);
			}

			this.filterBoundsChanged = false;

			if (this.isFieldOpsMode && events.length != 0) {
				if (this.previousEvents) {
					// get difference and display notifications
					const newEvents = differenceBy(events as any[], this.previousEvents, "eventID");
					newEvents.forEach(e => {
						const msg = `${e.groupTitle} [${e.eventTitle}]`;
						let notificatinOptions: NotificationOptions = {
							group: "fieldOpsNotification",
							type: "warn",
							title: "New event:",
							text: msg,
							duration: 20000
						}

						this.$notify(notificatinOptions);
					});
				}
				this.previousEvents = events;
			}
		} else {
			console.log("Map is null");
		}
	}

	private async searchAreaSelected(area: AreaNode | null) {
		//If we have a region for the area, use the region first
		let areaRegion = this.regions ? this.regions.get(area.id) : null;
		if (areaRegion) {
			const regionBounds = getRegionBounds(areaRegion.poly.getPath());
			this.map!.map.fitBounds(regionBounds);
			return;
		}

		let areaLayerBounds = await api.getBoundsForArea(area.id);
		if (areaLayerBounds && (areaLayerBounds.east !== -180 && areaLayerBounds.west !== 180)) {
			this.setBounds(areaLayerBounds);
		} else if (area.latLong) {
			this.centerOnPosition(convertStringToLatLng(area.latLong));
		} else {
			let geoCoderResponse = await geoCoder.decodeLocation(area.address, this.mapKey);
			if (geoCoderResponse) {
				this.centerOnPosition(geoCoderResponse.location, 17);
			}
		}
	}

	private setBounds(bounds: ILatLngBounds) {
		if (!bounds)
			return;

		bounds.north = bounds.north || 400;
		bounds.east = bounds.east || 400;
		bounds.west = bounds.west || 400;
		bounds.south = bounds.south || 400;

		this.map!.mapBounds = bounds;
	}
	// ----------------------------------------------------- Event Queue Logic  -----------------------------------------------------------------------------
	private get canEventShareAsset(){
		if (this.overlayAsset
			&& this.overlayAsset.shareable
			&& (!this.overlayAsset.isAsset || (this.overlayAsset.isAsset && !this.overlayAsset.activeEventShare))
			&& this.isMobileEventShareEnabled)
		{
			return true;
		}
		return false;
	}

	private async shareAssetToEvent(){
		if(this.EventDetails && this.overlayAsset){
			await api.shareEventWithAsset(this.EventDetails.eventID, this.overlayAsset.assetId);
		}
		this.overlayAsset = null;
		this.openFieldOpsOverlay = false;
	}

	// ----------------------------------------------------- Regions  -----------------------------------------------------------------------------
	private regions: Map<number, {poly: google.maps.Polygon, label: MapLabel }> = new Map<number, {poly: google.maps.Polygon, label: MapLabel }>();
	private showRegions: boolean = true;

	private get isAreaRegionsEnabled(): boolean {
		return this.getFeature(["Areas", "Regions"]);
	}

	private async loadRegions(): Promise<void> {
		var regions = await api.getAllRegions();
		//Generate the polygons for every region that has a path
		regions.filter(x => x.path).forEach(region => {
			const polygonOptions = getPolygonOptionsForRegion(region);
			const regionPoly = new google.maps.Polygon({
				map: this.map!.map,
				paths: JSON.parse(region.path),
				...polygonOptions
			});
			const position = getRegionBounds(regionPoly.getPath()).getCenter();

			google.maps.event.addListener(regionPoly, 'click', (event) => {
				if(this.addingAssetLocation) {
					this.$emit("setClickLocation", event.latLng.lat() + " " + event.latLng.lng());
				}

				if(!this.addingAlarm)
					return;

				this.setManualRaiseAlarmMarkerPosition(event.latLng);
				this.setManualRaiseSelectedGroup({
					id: region.groupId,
					label: region.areaDetails
				});
			});

			const label = new this.MapLabel(
				{
					text: ellipseAfterX(region.areaDetails),
					position: position,
					fontSize: 12,
					fontColor: "black",
					labelInBackground: true,
					strokeColor: region.borderColor,
					map: this.map!.map
				});
			label.set('position', position);
			this.regions.set(region.groupId, { poly: regionPoly, label: label });
		});
	}

	private toggleRegions(): void {
		this.showRegions = this.useAdvancedFilters ? this.filterCondition(this.advancedFilters.regions) : !this.showRegions;
		if(this.showRegions && this.regions){
			this.regions.forEach(m => {
				m.poly.setMap(this.map!.map);
				if(m.label)
					m.label.setMap(this.map!.map);
			})
		} else if (this.regions) {
			this.regions.forEach(m => {
				m.poly.setMap(null);
				if(m.label)
					m.label.setMap(null);
			})
		}
	}
	private async setExternalMapLayersList(): Promise<void> {
		if (this.externalLayersList && this.externalLayersList.length > 0) {
			await this.onExternalMapLayersListChanged(this.externalLayersList);
		}
	}

	@Watch("externalLayersList")
	public async onExternalMapLayersListChanged(list: ExternalMapLayer[]) {
		// send list of layers to mapping library, all logic and functions is handled in there
		if (this.enhancedMapping) {

			if (!this.map || !this.map.map)
			{
				// Need to wait until the map is ready
				setTimeout(function() { this.onExternalMapLayersListChanged(list); }.bind(this), 1000);
				return;
			}
			await this.enhancedMapping.loadExternalMapLayers(list, this.map.map);
		} else {
			setTimeout(function() { this.onExternalMapLayersListChanged(list); }.bind(this), 1000);
		}
	}

	public async toggleExternalMapLayerVisibility(mapLayerItem: ExternalMapLayer) {
		let updatedMapLayer = cloneDeep(mapLayerItem);
		updatedMapLayer.displaying = !mapLayerItem.displaying;

		await this.updateExternalMapLayerVisibility(updatedMapLayer);
	}
	// ----------------------------------------------------- Regions  -----------------------------------------------------------------------------
}
