import { DistanceConfigurationState, maxRadiusAsMetersInMiles } from "./types";

export function getDefaultState(): DistanceConfigurationState {
    return {
		distanceConfigurations: {
			pageNumber: 0,
			pageSize: 0,
			totalRecords: 0,
			data: [],
		},
		maxRadius: maxRadiusAsMetersInMiles
	}
}