import { GetterTree } from "vuex";
import { SystemHealthState } from "./types";
import { RootState } from "@/store/types";

export const getters: GetterTree<SystemHealthState, RootState> = {
	getHealthStatus: state => {
		return state.status;
	},
	getStatusFilter: state => {
		return state.selectedFilter;
	},
};
