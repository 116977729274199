import { ActionTree } from "vuex";
import { EmailData } from "./types";
import { RootState } from "@/store/types";
import { AuditService } from "@/services/auditService";
import { getFormattedEmlData } from "@/utils/formatterEmailAttachments";

export const actions: ActionTree<EmailData, RootState> = {
    async loadEmailData({ commit, getters, rootGetters }, { auth, recordId }) {

		let eventDetails = rootGetters["siteMonitor/getEventDetails"];
		let auditService: AuditService;

        if(getters.getEmail(recordId)) return;

		if(eventDetails.auditEndpoint) {
			auditService = new AuditService(null, eventDetails.auditEndpoint);
		}
		else {
			auditService = rootGetters["siteMonitor/getAuditService"];
		}

        const { data } = await auditService.downloadRawData(auth, recordId);
        const formattedData = await getFormattedEmlData(data);

        commit("putEmail", { formattedData, name: recordId });
    }
}
