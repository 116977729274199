
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Mutation, Action, Getter } from "vuex-class";
import vSelect from "vue-select";
import { Datetime } from "vue-datetime";

import { formatDateMixin } from "@/mixins";
import { ActivityLogDTO } from "../../store/activity-log/types";
import AreaTreeSelect from '../form/AreaTreeSelect.vue';
import { AreaNode } from '@/types/sv-data/groups/AreaNode';
import { UserGroup } from '@/store/types';

const ActivityLog = namespace("activityLog");
const Eventqueue = namespace("eventqueue");

Vue.mixin(formatDateMixin);

@Component({
	components: {
		"vue-select": vSelect,
		"area-tree-select": AreaTreeSelect,
		Datetime
	}
})
export default class ActivityLogPopup extends Vue {
	@Getter("getUserGroups") groups: UserGroup[];
	@Action fetchUserGroups: () => Promise<void>;

	@Eventqueue.Mutation setActivityLogShown: (shown: boolean) => void;
	@Eventqueue.Getter("getActivityLogShown") activityLogShown!: boolean;

	@ActivityLog.Action addActivityLog: (log: ActivityLogDTO) => Promise<any>;
	@ActivityLog.Action getActivityLogOutcomes: (areaId: number) => Promise<any>;

	private newActivityLog: ActivityLogDTO = {
		activityLogID: 0,
		eventTypeID: null,
		logged: new Date().toISOString(),
		start: new Date().toISOString(),
		end: new Date().toISOString(),
		categoryID: null,
		categoryTitle: "",
		areaID: 0,
		areaTitle: "",
		viewedUserID: null,
		outcomeNote: "",
		userTitle: "",
		hiddenFromUsers: false
	};

	private categoryData = [];

	private setSelectedCategory(category: any): void {
		this.newActivityLog.categoryID = category.eventOutcomeID;
		this.newActivityLog.categoryTitle = category.title;
	}

	@Watch("newActivityLog.areaID")
	private async onActivityLogAreaIDChanged(newAreaId: number): Promise<void> {
		await this.getActivityLogOutcomes(newAreaId).then(result => {
			this.categoryData = result.data;
		});
	}

	private get activityLogIsValid(): boolean {
		return !!(
			this.newActivityLog &&
			this.newActivityLog.areaID &&
			this.newActivityLog.outcomeNote
		);
	}

	private async createActivityLog(activityLog: ActivityLogDTO): Promise<void> {
		await this.addActivityLog(this.newActivityLog).then(() => {
			this.reset();
			this.setActivityLogShown(false);
		});
	}

	private reset(): void {
		this.newActivityLog.activityLogID = 0;
		this.newActivityLog.eventTypeID = 0;
		this.newActivityLog.logged = new Date().toISOString();
		this.newActivityLog.start = new Date().toISOString();
		this.newActivityLog.end = new Date().toISOString();
		this.newActivityLog.categoryID = null;
		this.newActivityLog.categoryTitle = "";
		this.newActivityLog.areaID = 0;
		this.newActivityLog.areaTitle = "";
		this.newActivityLog.viewedUserID = null;
		this.newActivityLog.outcomeNote = "";
		this.newActivityLog.hiddenFromUsers = false;

		this.categoryData = [];
	}

	private cancel(): void {
		this.reset();
		this.setActivityLogShown(false);
	}

	private get getTimeNow(): string {
		return new Date().toISOString();
	}

	private setTime(event: any, field:any): void {
		let time = event.target.value;
		let dateTime = new Date();

		let hoursMins = time.split(":");

		dateTime.setHours(hoursMins[0]);
		dateTime.setMinutes(hoursMins[1]);

		let startTime = dateTime.toISOString();

		this.newActivityLog[field] = startTime;
	}

	@Watch("activityLogShown")
	private async onActivityLogShownChanged(newValue: boolean): Promise<void> {
		if (newValue) {
			await this.fetchUserGroups();
		}
	}

	private areaSelected(area: AreaNode): void {
		if (!this.newActivityLog) {
			return;
		}

		if (!area) {
			this.newActivityLog.areaID = null;
			this.newActivityLog.areaTitle = null;
			return;
		}

		this.newActivityLog.areaID = area.id;
		this.newActivityLog.areaTitle = area.label;
	}
}
