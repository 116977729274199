import { axiosInstance, axiosJsonInstance } from "@/axios.instance";
import { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";
import { MaskingReason } from "@/types/sv-data/masking/MaskingReason";
import MaskingReasonsPagedResponse from "@/types/sv-data/masking/MaskingReasonsPagedResponse";
import MaskingRequest from "@/types/sv-data/masking/MaskingRequest";
import { AxiosInstance, } from "axios";

class MaskingApi {
	private axios: AxiosInstance;
	private axiosJson: any;

	constructor() {
		this.axios = axiosInstance;
		this.axiosJson = axiosJsonInstance;
	}

	public async getMaskingReasons(
        isResponse: Boolean)
        : Promise<MaskingReason[]> {
            const { data } = await this.axiosJson.get(`/Masking/GetMaskingReasons?isResponse=${isResponse}`);
            return data;
    }

    public async getPagedMaskingReasons(
        request: PaginatedSearchRequest)
        : Promise<MaskingReasonsPagedResponse> {
            const { data } = await this.axiosJson.get("/Masking/GetPagedMaskingReasons", request);
            return data;
    }

    public async createMaskingReason(
        maskingReason: MaskingReason)
        : Promise<number> {
            const { data } = await this.axios.post("/Masking/CreateMaskingReason", maskingReason);
            return data;
    }

    public async updateMaskingReason(
        maskingReason: MaskingReason)
        : Promise<boolean> {
            const { data } = await this.axios.put("/Masking/UpdateMaskingReason", maskingReason);
            return data;
    }

    public async deleteMaskingReason(
        maskingReasonId: number)
        : Promise<boolean> {
            const { data } = await this.axios.delete(`/Masking/DeleteMaskingReason/${maskingReasonId}`);
            return data;
    }

    public async maskArea(
		groupId: number,
		duration: number,
		disarmReasonId?: number,
		disarmNote?: string,
		maskIsAutoHandle? : boolean): Promise<any> {
		return await this.axios.post(`/Masking/MaskArea`,
		{
			groupId: groupId,
			durationInMinutes: duration,
			disarmReasonId: disarmReasonId,
			disarmNote: disarmNote,
			maskIsAutoHandle: maskIsAutoHandle
		});
	}

	public async maskAllAlarmsInArea(
		groupId: number,
		duration: number,
		disarmReasonId?: number,
		disarmNote?: string,
		maskIsAutoHandle? : boolean): Promise<any> {
		return await this.axios.post(`/Masking/MaskAllAlarmsInArea`,
		{
			groupId: groupId,
			durationInMinutes: duration,
			disarmReasonId: disarmReasonId,
			disarmNote: disarmNote,
			maskIsAutoHandle: maskIsAutoHandle
		});
	}

	public async unmaskAllAlarmsInArea(groupId): Promise<any> {
		return await this.axios.post(`/Masking/UnmaskAllAlarmsInArea`, { groupId: groupId });
	}

	public async unmaskArea(groupId): Promise<any> {
		return await this.axios.get(`/Masking/UnmaskArea/${groupId}`);
	}

	public async loadMaskReasonsForAlarm(groupID: number): Promise<any> {
		return (await this.axios.get(`/Masking/Reasons/Alarm/${groupID}`)).data;
	}

	public async loadMaskReasonsForGroup(groupID: number): Promise<any> {
		return (await this.axios.get(`/Masking/Reasons/Group/${groupID}`)).data;
	}

	public async maskAlarm(maskRequest: MaskingRequest): Promise<any> {
		return (await this.axios.post("/Masking/MaskAlarm", maskRequest)).data;
	}

	public async unmaskAlarm(eventID: number, responseID: number, groupID: number): Promise<any> {
		await this.axios.post("/Masking/UnmaskAlarm", { eventID, responseID, groupID });
	}

	public async loadMaskedAlarmsForGroup(groupID: number): Promise<any> {
		return await this.axios.get(`/Masking/Masked/Alarms/${groupID}`);
	}
}

const maskingApi = new MaskingApi();
export default maskingApi;
