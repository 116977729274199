export {};

declare global {
	interface String {
		trimEndOf(remove: string[]): string;
	}
}

if (!String.prototype.trimEndOf) {
	String.prototype.trimEndOf = function trimEndOf<T> (this: string, remove: string[]): string {
		let newLength = this.length;
		for (let i = this.length; i--; i > -1) {
			if (remove.indexOf(this[i]) > -1) {
				newLength--;
			} else {
				break;
			}
		}
		return this.substring(0, newLength);
	}
}