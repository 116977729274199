import Vue from "vue";
import { MutationTree } from "vuex";
import { Mobile, MobileEventShareInfo } from "./types";
import { AlarmQueueFilter } from "@/store/eventqueue/types";

import { getDefaultState } from "./state";

import {
	ILatLng
} from "@sureview/v2-mapping-saas";

import "@/scripts/dictionary";
import { AssetMapLayerItem } from "@/components/mobile-assets/types";
import AssetType from "@/types/sv-data/AssetType";
import MapLabel from "@/scripts/mapping/mapLabel";

export const mutations: MutationTree<Mobile> = {
	// Called by async actions
	setAuditEndpoint(state, auditEndpoint) {
		state.AuditEndpoint = auditEndpoint;
	},
	setDeviceLocation(state, deviceLocation) {
		state.DeviceLocation = deviceLocation;
	},
	setIsWatchingLocation(state, value: boolean) {
		state.IsWatchingLocation = value;
	},
	toggleRightSideBarVisibility(state) {
		state.isRightSideBarOpen = !state.isRightSideBarOpen;
	},
	toggleLeftSideBarVisibility(state) {
		state.isLeftSideBarOpen = !state.isLeftSideBarOpen;
	},
	setShowDirections(state, showDirections) {
		state.showDirections = showDirections;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	setLiveDevice(state, device) {
		state.LiveDevice = device;
	},
	setMobileMode(state, mode: string) {
		if (state.MobileMode !== mode) {
			state.MobileMode = mode;
		}
	},
	setMapEnabled(state, enabled: boolean) {
		state.MapEnabled = enabled;
	},
	setStarredDevices(state, devices) {
		state.StarredDevices = devices;
	},
	setUserPermissions(state, permissions) {
		state.UserPermissions = permissions;
	},
	setNotificationText(state, notificationText) {
		state.NotificationText = notificationText;
	},
	setManageDataLoading: (state, loading: boolean) => state.ManageDataLoading = loading,
	setManageFilters: (state, filters: AlarmQueueFilter[]) => state.ManageAlarmQueueFilters = filters,
	setManageCurrentFilters: (state, filters: AlarmQueueFilter[]) => state.ManageCurrentAlarmQueueFilters = filters,
	setMapGoto: (state, gotoFunction: (latLng: ILatLng) => void) => state.MapGoto = gotoFunction,
	setQuickCloseList(state, quickCloseList) {
		state.QuickCloseList = quickCloseList;
	},
	setUserMapOnlineStatus: (state, onlineStatus: boolean) => state.UserMapOnlineStatus = onlineStatus,
	setUserAssetMapItem(state, mapItem: AssetMapLayerItem){
		state.UserAssetMapItem = mapItem ? { ...mapItem } : null;
	},
	setUserAssetType(state, assetType: AssetType){
		state.UserAssetType = assetType;
	},
	setEventShareItems(state, items: MobileEventShareInfo[]){
        state.EventShareItems = items;
    },
    setActiveEventShareItem(state, item: MobileEventShareInfo){
		if(!item){
			if(state.NavigateToActiveAlarm)
				state.NavigationLocation = null;
			state.NavigateToActiveAlarm = false;
		}

		// if the navigation is active, reset the nav location when changing alarm
		if(item && state.NavigateToActiveAlarm){
			state.NavigationLocation = item.latLng;
		}
        state.ActiveEventShareItem = item;

    },
	setNavigationMode(state, mode: string){
		state.NavigationMode = mode;
	},
	setNavigateToActiveAlarm(state, navigateTo: boolean) {
		if (navigateTo) {
			state.isRightSideBarOpen = false;
		}

		state.NavigateToActiveAlarm = navigateTo;
		state.NavigationLocation = null;
		state.NavigationLocation = state.ActiveEventShareItem ? state.ActiveEventShareItem.latLng : null;
	},
	setNavigationLocation(state, location: boolean){
		state.NavigationLocation = location;
	},
	setFocusedEventID(state, eventID: number | null){
		state.FocusedEventID = eventID;
	},
	setChatOpen(state, open: boolean){
		state.ChatOpen = open;
	},
	setChatTab(state, tab: string){
		state.ChatTab = tab;
	},
	setRegions(state, regions: Map<number, {poly: google.maps.Polygon, label: MapLabel }>){
		state.Regions = regions;
	}
};
