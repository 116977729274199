
import _Vue from "vue";
import { Component, Vue, Watch, Emit, Prop } from "vue-property-decorator";

export const enum SideBarState {
	Closed  = 0,
	Narrow = 1,
	Wide = 2,
}

@Component({
	components: {}
})
export default class Sidebar extends Vue {
	@Prop({type: Boolean, default: false}) 
	private allowWide: boolean;

	@Prop({type: Number, default: 1})
	private startState: number;

	private state: SideBarState = 1;

	private mounted(){
		this.state = this.startState
	}

	private toggleMovement(grow: boolean){
		if(grow){
			if(this.state >= SideBarState.Closed && this.state != SideBarState.Wide){
				this.state++;
			}
		} else {
			if(this.state <= SideBarState.Wide && this.state != SideBarState.Closed){
				this.state--;
			}
		}
	}

	private get isClosed() : boolean {
		return this.state === SideBarState.Closed;
	}

	private get isWide(): boolean {
		return this.state === SideBarState.Wide;
	}

	private get isNarrow(): boolean {
		return this.state === SideBarState.Narrow;
	}
}

