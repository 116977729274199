import { FlatAreaListRequest } from "./types";
import { defaultPollingStatus, PollingStatus } from "@/types/PollingStatus";
import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { AreaState, GroupWithPermissions } from "@/store/areas/types";

export const actions: ActionTree<AreaState, RootState> = {
	// deprecated
	async getAreaList({ commit }) {
		const data = (await api.getAreaList()) as GroupWithPermissions[];
		commit("setAreaList", data as GroupWithPermissions[]);
	},

	async getTenants({ commit }) {
		const { data } = await api.getTenants();

		// set the tenant id based on the isUsersTenant value
		data.forEach(tenant => {
			if (tenant.isUsersTenant) {
				commit("setCurrentTenantId", tenant.groupID)
			}
		});

		return data;
	},

	async fetchAreaDictionary({ commit }) {
		const areaDictionary = await api.getAreaDictionary();
		commit("setAreaDictionary", areaDictionary);
	},

	async pollAreaMaskingCount({ state, dispatch, commit }) {
		await dispatch("fetchAreaMaskingCount");

		if (!state.areaMaskingCountPollingStatus) {
			state.areaMaskingCountPollingStatus = defaultPollingStatus();
		}

		if (!state.areaMaskingCountPollingStatus.isPolling) {
			let fetchAreaMaskingCountPoll = async () => {

				try {
					await dispatch("fetchAreaMaskingCount");
				} catch (err) {
					console.error(err);
				} finally {
					clearTimeout(state.areaMaskingCountPollingStatus.intervalHandlerId);

					let intervalHandlerId = window.setTimeout(fetchAreaMaskingCountPoll, 30000);

					let pollingStatus: PollingStatus = {
						isPolling: true,
						intervalHandlerId: intervalHandlerId
					};

					commit("setAreaMaskingCountPollingStatus", pollingStatus);
				}
			};
			await fetchAreaMaskingCountPoll();
		}
	},

	stopPollingAreaMaskingCount({ commit, state }): void {
		if (state.areaMaskingCountPollingStatus && state.areaMaskingCountPollingStatus.isPolling) {
			clearTimeout(state.areaMaskingCountPollingStatus.intervalHandlerId);

			let pollingStatus: PollingStatus = {
				isPolling: false,
				intervalHandlerId: 0
			};
			commit("setAreaMaskingCountPollingStatus", pollingStatus);
		}
	},

	async fetchAreaMaskingCount({ commit }) {
		const areaMaskingCount = await api.getAreaMaskingCount();
		commit("setAreaMaskingCount", areaMaskingCount);
	},

	async fetchFlatListWithState({ commit }, request: FlatAreaListRequest) {
		const areasWithState = await api.getFlatAreaList(request);
		commit("setFlatAreaList", areasWithState);
	}
};
