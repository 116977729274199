import { MutationTree } from "vuex";
import { CustomFieldsState } from "./types";
import { getDefaultState } from "./state";
import PagedResponse from "@/types/sv-data/PagedResponse";

export const mutations: MutationTree<CustomFieldsState> = {
	setAreaCustomFields(state: CustomFieldsState, fields: PagedResponse) {
		state.areaCustomFields = fields.data;
		state.totalCustomFields = fields.totalRecords;
	},
	setContactCustomFields(state: CustomFieldsState, fields: PagedResponse) {
		state.contactCustomFields = fields.data;
		state.totalCustomFields = fields.totalRecords;
	},
	setAlarmCustomFields(state: CustomFieldsState, fields: PagedResponse) {
		state.alarmCustomFields = fields.data;
		state.totalCustomFields = fields.totalRecords;
	},
	resetState(state: CustomFieldsState): void {
		Object.assign(state, getDefaultState());
	},
};
