
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { required, integer, minValue, maxValue } from "vuelidate/lib/validators";
import { HousekeepingSettings, OrganizationSettings } from "@/types/sv-data/OrganizationSettings";
import api from "@/services/api.service";
import { validationMixin } from "vuelidate";
import { Getter } from "vuex-class";
import { UserPermissions } from "@/store/types";

@Component({
	mixins: [validationMixin],
	validations: {
		newDaysToKeepEvents: {
			required,
			integer,
			minValue: minValue(1),
			maxValue: maxValue(365)
		},
		newDaysToKeepEventFiles: {
			required,
			integer,
			maxValue: maxValue(365),
			validateMin: function(val) {
				return val <= this.newDaysToKeepEvents;
			},
		}
	}
})
export default class HousekeepingSettingsForm extends Vue {
	@Getter getPermissions: UserPermissions;

	private organizationSettings: OrganizationSettings = {
		housekeepingSettings: {
			daysToKeepEventFiles: 60,
			daysToKeepEvents: 365
		},
		apiKeySettings: null
	};

	private newDaysToKeepEventFiles: number = 60;
	private newDaysToKeepEvents: number = 365;
	private isConfirmationModalShown: boolean = false;

	private async mounted() {
		this.organizationSettings = await api.getOrganizationSettings();
		this.newDaysToKeepEventFiles = this.organizationSettings.housekeepingSettings.daysToKeepEventFiles;
		this.newDaysToKeepEvents = this.organizationSettings.housekeepingSettings.daysToKeepEvents;
	}

	private async confirmUpdateHousekeepingSettings(): Promise<void> {
		let newHousekeepingSettings: HousekeepingSettings = {
			daysToKeepEvents: this.newDaysToKeepEvents,
			daysToKeepEventFiles: this.newDaysToKeepEventFiles
		};
		await api.setHousekeepingSettings(newHousekeepingSettings);
		this.organizationSettings.housekeepingSettings = newHousekeepingSettings;
		this.$notify({
			type: "success",
			title: "Retention settings updated"
		});
	}

	private get updateRequiresConfirmation(): boolean {
		return this.organizationSettings.housekeepingSettings.daysToKeepEvents > this.newDaysToKeepEvents ||
			this.organizationSettings.housekeepingSettings.daysToKeepEventFiles > this.newDaysToKeepEventFiles;
	}

	private async resetChanges(): Promise<void> {
		this.newDaysToKeepEvents = this.organizationSettings.housekeepingSettings.daysToKeepEvents;
		this.newDaysToKeepEventFiles = this.organizationSettings.housekeepingSettings.daysToKeepEventFiles;
		this.$v.$reset();
	}

	private get haveSettingsChanged(): boolean {
		return this.organizationSettings.housekeepingSettings.daysToKeepEvents !== this.newDaysToKeepEvents ||
			this.organizationSettings.housekeepingSettings.daysToKeepEventFiles !== this.newDaysToKeepEventFiles;
	}
}
