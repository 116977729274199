import { sortBy } from "lodash";
import { UserGroup } from "../user-management/types";
import {
	MobileRaiseTemplate,
	RaiseId,
	TemplateDetailsModel,
	TemplateGridRowModel,
	MobileRaise,
	MobileRaiseLinkEditModel
} from "./types";

export const raiseIdsEqual = (id1: RaiseId | null, id2: RaiseId | null): boolean => {
	// both is null
	if (id1 === null && id2 === null) {
		return true;
	}
	// only one is null
	if (id1 === null || id2 === null) {
		return false;
	}
	return id1.typeID === id2.typeID && id1.manualRaiseType === id2.manualRaiseType;
};

export const newTemplateDetailsModel = (): TemplateDetailsModel => ({
	title: "",
	userGroups: [],
	mobileRaiseLinks: [],
	quickRaiseIds: []
});

export type UserGroupGetter = (id: number) => UserGroup | undefined;
export type RaiseGetter = (id: RaiseId) => MobileRaise | undefined;

export const createMapTemplateToRowMapper = (userGroupGetter: UserGroupGetter) => (
	t: MobileRaiseTemplate
): TemplateGridRowModel => {
	const quickSelectRaises = (t.mobileRaiseLinks
		? t.mobileRaiseLinks.filter(l => l.gridSetSlot !== null).length
		: 0
	).toString();

	return {
		mobileRaiseTemplateID: t.mobileRaiseTemplateID,
		title: t.title,
		userGroups: t.userGroupIDs.map(id => {
			const userGroup = userGroupGetter(id);
			return userGroup ? userGroup.title : "";
		}),
		quickSelectRaises
	};
};

export const mapTemplateToEditModel = (
	t: MobileRaiseTemplate,
	userGroupGetter: UserGroupGetter,
	raiseGetter: RaiseGetter
): TemplateDetailsModel => {
	const mobileRaiseLinks: MobileRaiseLinkEditModel[] = t.mobileRaiseLinks.map(l => {
		const raise = raiseGetter(l);
		return {
			...l,
			titleList: raise ? raise.titleList : []
		};
	});

	const quickRaiseIds = sortBy(
		t.mobileRaiseLinks.filter(l => l.gridSetSlot !== null),
		l => l.gridSetSlot
	).map(l => ({ typeID: l.typeID, manualRaiseType: l.manualRaiseType }));

	const model: TemplateDetailsModel = {
		mobileRaiseTemplateID: t.mobileRaiseTemplateID,
		title: t.title,
		userGroups: t.userGroupIDs.map(id => userGroupGetter(id)),
		mobileRaiseLinks,
		quickRaiseIds
	};

	return model;
};

export const mapEditModelToTemplate = (model: TemplateDetailsModel): MobileRaiseTemplate => {
	const mobileRaiseLinks = model.mobileRaiseLinks.map(l => {
		const quickRaiseIdIndex = model.quickRaiseIds.findIndex(id => raiseIdsEqual(id, l));
		const gridSetSlot = quickRaiseIdIndex === -1 ? null : quickRaiseIdIndex;
		return {
			typeID: l.typeID,
			manualRaiseType: l.manualRaiseType,
			iconSetID: l.iconSetID,
			gridSetSlot,
			autoShare: l.autoShare,
		};
	});

	const template: MobileRaiseTemplate = {
		title: model.title,
		mobileRaiseLinks,
		userGroupIDs: model.userGroups.map(ug => ug.userGroupId),
		gridSet: model.quickRaiseIds.length,
		mobileRaiseTemplateID: model.mobileRaiseTemplateID ?? 0
	};

	return template;
};
