import { GetterTree } from "vuex";
import { RootState } from "../types";
import { MobileRaiseState, RaiseId } from "./types";
import {
	raiseIdsEqual,
	UserGroupGetter,
	createMapTemplateToRowMapper,
	mapTemplateToEditModel,
	RaiseGetter
} from "./typesFunctions";

export const getters: GetterTree<MobileRaiseState, RootState> = {
	getTemplateGridRows: (state, getters, rootState, rootGetters) => {
		const userGroupGetter = rootGetters["userManagement/getUserGroupById"] as UserGroupGetter;
		const mapper = createMapTemplateToRowMapper(userGroupGetter);
		return state.mobileRaiseTemplates.map(mapper);
	},

	getTemplateModelById: (state, getters, rootState, rootGetters) => (templateId: number) => {
		const template = state.mobileRaiseTemplates.find(t => t.mobileRaiseTemplateID === templateId);
		const userGroupGetter = rootGetters["userManagement/getUserGroupById"] as UserGroupGetter;
		const raiseGetter = getters["getMobileRaiseByRaiseId"] as RaiseGetter;
		return mapTemplateToEditModel(template, userGroupGetter, raiseGetter);
	},

	getMobileRaiseByRaiseId: state => (id: RaiseId) => state.mobileRaises.find(r => raiseIdsEqual(r, id)),

	getIconSetById: state => (id: number) => state.iconSets.find(i => i.iconSetID === id),
	getIconSetByRaiseId: state => (raiseId: RaiseId) => state.iconSets.find(i => raiseIdsEqual(i, raiseId)),
	searchIconSets: state => (searchTerm: string) => {
		if (searchTerm) {
			const term = searchTerm.toLowerCase();
			return state.iconSets.filter(
				i => i.title.toLowerCase().includes(term) || i.iconData.toLowerCase().includes(term)
			);
		}
		return state.iconSets;
	}
};
