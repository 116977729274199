import { Vue } from "vue-property-decorator";

export const customFieldMixins = {
	methods: {
		mapArrayFieldsToObject(object: any, array: any[], allCustomFields: any[], idProp: string, valueProp: string, tag: string): any {
			allCustomFields.forEach((element: any): void => {
				const key: string = tag + element[idProp];
				Vue.set(object, key, null);
			})

			if (!Array.isArray(array)) {
				return object;
			}

			array.forEach((element: any): void => {
				const key: string = tag + element[idProp];
				Vue.set(object, key, element[valueProp]);
			})

			return object;
		},

		mapObjectFieldsToArray(object: any, idProp: string, valueProp: string, tag: string, maintainType: boolean = false): any[] {
			let result: any[] = [];
			let keys: string[] = Object.keys(object);

			keys.filter((key: string): boolean => key.startsWith(tag)).forEach((key: string): void => {
				let newEntry: {} = {};
				newEntry[idProp] = key.slice(tag.length);
				if (maintainType) {
					newEntry[valueProp] = object[key];
				} else {
					if (typeof object[key] === "string" || object[key] instanceof String) {
						newEntry[valueProp] = object[key];
					} else {
						newEntry[valueProp] = JSON.stringify(object[key]);
					}
				}
				result.push(newEntry);
			});

			return result
		}
	}
}
