import { AreaEventHistoryState } from "./types";

export function getDefaultState(): AreaEventHistoryState {
	return {
		isPopoutModeEnabled: false,
		areaEventHistory: [],
		filter: null,
		storedTimeZoneId: null,
		totalRecords: null,
	};
}
