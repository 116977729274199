export const Constants = {
	FIELD_OPS_NO_LICENCE_ERROR: "User does not have a licence for Field Ops",
	NO_PERMISSION_TO_PAGE: "You do not have permission to this page",
	MAX_MASKING_DURATION: 2147483647
} as const;

export const AreaDetailsIndexValues = {
	CONTACTS: 0,
	SCHEDULES: 1,
	NOTES: 2,
	HISTORY: 3,
} as const;