import { CSVFormHandler, ModalItemCSV } from "./types";

export function getDefaultState(): CSVFormHandler {
    return {
		modalItemsCsv: {},
		csvData: {},
		errorsForm: {},
		originalCsvData: {}
	}
}
