
import EnhancedSingleMap from "@/components/EnhancedSingleMap.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import GoogleMap from "@/scripts/mapping/map";
import getScript from "@/services/insertScript.service";
import { getMapStyle } from "@/scripts/mapping/mapStyle";
import { Getter } from "vuex-class";
import { faMapMarker } from "@fortawesome/pro-solid-svg-icons";
import ILatLng from "@sureview/v2-mapping-saas";
import { maxRadiusAsMetersInMiles, metersAsMilesDecimalPlaces } from "@/store/distance-configuration/types";
import { convertMetersToMiles } from "@/utils/conversion-utils";

@Component({
    components: {
        "enhanced-single-map": EnhancedSingleMap
    }
})
export default class MapRadius extends Vue {
    @Getter getMapType: string;
    @Getter getHideLabels: boolean;

    @Prop({type: Number, required: false})
    private value: number;

    @Prop({type: Object, required: true})
    private location: ILatLng;

    @Prop({type: Number, default: maxRadiusAsMetersInMiles })
    private maxRadius: number;

    @Prop({ required: false, default: false }) 
    private readonly: boolean;
    
    public $refs!: {
        mappingContainer: HTMLElement;
    }
    
    private map: GoogleMap | null = null;
    private mapLightMode: boolean = false;
    private marker: google.maps.Marker | null = null;
    private circle: google.maps.Circle | null = null;

    private currentRadius: number = null;

    private defaultRadius: number = 1000;
    
    private async mounted(): Promise<void> {
        await this.initMap();
        this.mapLightMode = true;
        this.currentRadius = this.value ? this.value : this.defaultRadius;
    }

    private get setupRadius(): number {
        if (this.value) {
            return this.value;
        }

        return this.value ? this.value : this.defaultRadius;
    }

    private get setupZoom(): number {
        var currentRadius = this.setupRadius;
        
        // Returns google map zoom levels based on the size of the radius
        // 0 is the whole map and 21+ is max zoom
        switch (true) {
            case !currentRadius:
                return 0;
            case currentRadius <= 1000:
                return 13;
            case currentRadius <= 20000:
                return 11;
            case currentRadius <= 30000:
                return 9;
            case currentRadius <= 50000:
                return 8;
            default:
                return 7;
        }
    }

    private get asMiles(): string {
        return convertMetersToMiles(this.currentRadius, metersAsMilesDecimalPlaces);
    }

    @Watch("Value")
    private onValueChanged(): void {
        if (!this.value) {
            this.currentRadius = null;
        } else {
            this.currentRadius = this.value;
        }
    }

    @Watch("location")
    private onLocationChanged(): void {        
        var latLng = { lat: 0, lng: 0 };
        if (this.location) {
            latLng = {
                lat: this.location.lat,
                lng: this.location.lng
            };
        }

        if (!!this.map && !!this.map.map) {
            this.clearMap();
            const newLatLng = new google.maps.LatLng(latLng.lat, latLng.lng);
            this.map.map.setCenter(newLatLng);
            this.setMapArtifacts(latLng);
        }  
    }

    private setMapArtifacts(latLng: ILatLng): void {
        // Draw the marker for the area
        this.marker = new google.maps.Marker({
            position: latLng,
            map: this.map.map,
            draggable: false,
            title: 'Area location',
            icon: {
                path: faMapMarker.icon[4] as string,
                fillColor: '#2196F3',
                fillOpacity: 1.0,
                strokeColor: '#000000',
                strokeWeight: 1,
                scale: 0.08,
                labelOrigin: new google.maps.Point(190, 180),
                anchor: new google.maps.Point(200, 500)
            },			
            label: {
                fontFamily: "'Font Awesome 5 Pro'",
                text: "\uf015",
                fontWeight: '900',
                fontSize: "15pt",
                color: "#ffffff"
            }	
        });

        // create a new circle around the marker
        this.circle = new google.maps.Circle({
            strokeColor: '#2196F3',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '',
            fillOpacity: 0,
            map: this.map.map,
            center: latLng,
            radius: this.setupRadius,
            draggable: false,
            editable: !this.readonly,
        });

        // attach a radius changed event listener to the circle
        google.maps.event.addListener(this.circle, 'radius_changed', () => {

            // get radius of the circle in meters
            var radius = this.circle.getRadius();

            // Radius must not exceed the maxRadius
            if (radius > this.maxRadius) {
                this.circle.setRadius(this.maxRadius);
                radius = this.maxRadius;
            }

            // Ensure radius is rounded to nearest whole number 
            this.currentRadius = Math.round(radius);
        });
    }

    private clearMap(): void {
        if (this.marker) {
            this.marker.setMap(null);
        }

        if (this.circle) {
            this.circle.setMap(null);
        }
    }
    
    public async initMap(): Promise<void> {
        await getScript(
            `https://maps.googleapis.com/maps/api/js?key=`
            + `${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
            + `&libraries=drawing,places,geometry`
        ).then(() => {
            var latLng = { lat: 0, lng: 0 };
            if (this.location) {
                latLng = {
                    lat: this.location.lat,
                    lng: this.location.lng
                };
            }

            this.map = new GoogleMap(
                this.$refs.mappingContainer,
                latLng,
                this.setupZoom,
                false,
                this.getMapType,
                getMapStyle(this.getMapType, this.getHideLabels, this.mapLightMode ? 'light' : 'dark'),
            );

            this.map.map.setOptions({ scrollwheel: true, zoomControl: true });

            this.setMapArtifacts(latLng);        
        });
    }
    
    @Watch("mapLightMode")
    private mapModeWatch(): void {
        if(!this.map || !this.map.map) {
            return;
        }
        
        const mapStyles = getMapStyle(this.getMapType, this.getHideLabels, this.mapLightMode ? "light" : "dark");

        this.map.map.setOptions({
            styles: mapStyles,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });
    }

    @Watch("currentRadius")
    @Emit("input")
    private radiusChanged(): number {
        // When we update the radius we need to ensure the circle is also updated
        if (this.circle) {
            this.circle.setRadius(this.currentRadius ? this.currentRadius : null)
        }

        return this.currentRadius ? this.currentRadius : null;
    }

    private wholeNumberFormatter(value: number): number {
        if (!value) {
            return value;
        }

        if (value > this.maxRadius) {
            return this.maxRadius;
        }

        return Math.round(value);
    }
}
