import store from "@/store";
import getDevicePosition from "./device.geolocation.service";

const getWatchedDeviceLocation = () => store.getters["mobile/getWatchedDeviceLocation"] as (
	timeoutMs: number
) => Promise<google.maps.LatLng>;

const getIsWatchingLocation = () => store.state.mobile.IsWatchingLocation as boolean;

/**
 * function, returning GPS coords either from store, updated by GPS watcher or directly from geolocation API
 * @param timeoutMs - operation timeout in milliseconds
 */
// reason for adding such function: because in Chrome (84.0.4147.135) with GPS watcher registered, call to
// navigator.geolocation.getCurrentPosition() just do nothing. Even error callback not executed after timeout.
const getPosition = async (timeoutMs: number) => {
	if (getIsWatchingLocation()) {
		// If we can't get watched position retrieve the device position
		try {
			return await getWatchedPosition(timeoutMs/2);
		} catch {
			return await getUnwatchedDevicePosition(timeoutMs/2);
		}
	} else {
		return await getUnwatchedDevicePosition(timeoutMs);
	}
};

const getWatchedPosition = async (timeoutMs: number) => {
	const loc = await getWatchedDeviceLocation()(timeoutMs);
		return {
			lat: loc.lat(),
			lng: loc.lng()
		};
}

const getUnwatchedDevicePosition = async (timeoutMs: number) => {
	const posOptions: PositionOptions = {
		timeout: timeoutMs,
		enableHighAccuracy: true
	};
	const loc = await getDevicePosition(posOptions);
	return {
		lat: loc.coords.latitude,
		lng: loc.coords.longitude
	};
}
export default getPosition;
