import { axiosJsonInstance } from "@/axios.instance";
import { AxiosInstance } from "axios";
import { UpdatedViewCellData } from "@/store/views/types";

class ViewsApi {
	private axios: AxiosInstance;

	constructor() {
		this.axios = axiosJsonInstance;
	}

	public async getViewsList(): Promise<any> {
		const { data } = await this.axios.get("/Views/List");
		return data;
	}

	public async getViewDetails(viewId: number, userId: number): Promise<any> {
		const { data } = await this.axios.get(`/Views/GetDetails/${viewId}?userId=${userId}`);
		return data;
	}

	public async getUserViews(): Promise<any> {
		const { data } = await this.axios.get(`/UserViews`);
		return data;
	}

	public async getSharedAdhocView(sessionId: string): Promise<any> {
		const { data } = await this.axios.get(`/UserViews/AdhocUserView/${sessionId}`);
		return data;
	}

	public async addUserView(viewId: number): Promise<any> {
		const { data } = await this.axios.post(`/UserViews/Add`, viewId);
		return data;
	}

	public async removeUserView(viewId: number): Promise<any> {
		return await this.axios.delete(`/UserViews/Remove?viewId=${viewId}`);
	}

	public async getViewPlugins(): Promise<any> {
		const { data } = await this.axios.get("/Plugins");
		return data;
	}

	public async saveView(view: any): Promise<any> {
		const { data } = await this.axios.post("/Views/Save", view);
		return data;
	}

	public async deleteView(viewId: number): Promise<any> {
		return await this.axios.delete(`/Views/Delete/${viewId}`);
	}

	public async fetchViewUpdateTime(viewId: number): Promise<any> {
		const { data } = await this.axios.get(`/Views/LastUpdated/${viewId}`);
		return data;
	}

	public async saveViewCellContent(
		viewId: number,
		sessionId: string,
		viewCellId: number,
		content: any
	): Promise<any> {
		await this.axios.post(`/ViewCells/SetContents`, {
			viewId,
			viewCellId,
			sessionId,
			content
		});
	}

	public async shareView(viewId: number, userIds: number[]): Promise<any> {
		const { data } = await this.axios.post("/Views/Share/WithUsers", { viewId, userIds });
		return data;
	}

	public async shareViewWithAdhoc(viewId: number, fullName: string, email: string): Promise<any> {
		const { data } = await this.axios.post("/Views/Share/WithAdhoc", { viewId, fullName, email });
		return data;
	}

	public async getViewSharesForView(viewId: number): Promise<any> {
		const { data } = await this.axios.get(`/Views/Share/Details/${viewId}`);
		return data;
	}

	public async removeUserShare(viewId: number, userId: number) {
		var { status } = await this.axios.delete(`/Views/Share/RemoveUser/${viewId}/${userId}`);
		if (status != 200) {
			return false;
		} else {
			return true;
		}
	}

	public async removeAdhocUserShare(viewId: number, name: string) {
		var { status } = await this.axios.delete(`/Views/Share/RemoveAdhocUser/${viewId}/${name}`);
		if (status != 200) {
			return false;
		} else {
			return true;
		}
	}

	public async fetchViewContentUpdateTime(viewId: number) {
		const { data } = await this.axios.get(`/ViewCells/LastUpdated/${viewId}`);
		return data;
	}

	public async fetchUpdatedCellContent(viewId: number, sessionId: string): Promise<UpdatedViewCellData[]> {
		const { data } = await this.axios.post("/ViewCells/GetContents", {
			viewId,
			sessionId
		});

		return data;
	}

	public async toggleLockedView(viewId: number){
		var { status } = await this.axios.get(`/Views/ToggleLock/${viewId}`);
		if (status != 200) {
			return false;
		} else {
			return true;
		}
	}

	public async getUserGroupsFromView(viewId: number): Promise<any> {
		const { data } = await this.axios.get(`/Views/UserGroups/${viewId}`);
		return data;
	}

	public async setUserGroupsToView(viewId: number, userGroups: {}): Promise<any> {
		return await this.axios.post(`/Views/UserGroups/${viewId}`, userGroups);
	}
}

const viewsApi = new ViewsApi();
export default viewsApi;
