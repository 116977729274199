import api from "@/services/api.service";
import Vue from "vue";
import router from "../../router";
import { ActionTree } from "vuex";
import { SiteMonitorState, EventShareUser, EventDetails, AuditReviewResponse } from "./types";
import { RootState } from "../types";
import { PollingStatus } from "@/types/PollingStatus";
import { EventOutcomeResponse } from "@/types/EventOutcome";
import { difference } from "lodash";
import CloseMultipleEventsDTO from "@/types/sv-data/events/CloseMultipleEventsDTO";
import CloseEventResultDTO from "@/types/sv-data/events/CloseEventResultDTO";
import maskingApi from "@/services/api.masking.service";

interface UnlinkCameraDTO {
	objectId: number;
	deviceId: number;
}

interface ParkEventDTO {
	eventId: number;
	hours: number;
	minutes: number;
	seconds: number;
	parkNote: string;
	parkedByMap: boolean;
}

interface CloseEventDTO {
	eventId: number;
	eventOutcomeId: number;
	outcomeNote: string;
	clearGuards: boolean;
}
interface AuditRecordDTO {
	eventId: number;
	eventRecordTypeId: number;
	details: string;
	objectId: number | null | undefined;
}

interface RoutingGroupDTO {
	eventId: number;
	RoutingGroupId: number;
}

export const actions: ActionTree<SiteMonitorState, RootState> = {
	async fetchEventRecords({ dispatch, commit, getters, state }, { eventID, reviewMode }) {
		const requestingEventRecords = getters.getRequestingEventRecords;
		if (requestingEventRecords) {
			return null;
		}
		commit("setRequestingEventRecords", true);

		let fromRecord = getters.getLatestEventRecord;

		return new Promise(function (resolve, reject) {
			api.loadEventRecords(eventID, fromRecord).then(
				async events => {
					const eventDetails = state.EventDetails;

					const initialEventRecordId =
						eventDetails && eventDetails.initialEventRecordId ? eventDetails.initialEventRecordId : null;
					let initialRecord = null;

					if (!initialEventRecordId) {
						console.log(
							"initialEventRecordId not set for event ID " +
							eventID
						);
						console.log(`Event Details: ${eventDetails}`);
					}

					if (events.data == null) {
						resolve(0);
					} else {
						events.data.forEach((eventRecord: any) => {
							if (fromRecord < eventRecord.eventRecordID) {
								fromRecord = eventRecord.eventRecordID;
							}

							if (eventRecord.eventUserID != null) {
								const userShare = getters.userShares[eventRecord.eventUserID];

								if (userShare != null) {
									eventRecord.colorIndex = userShare.colorIndex;
									eventRecord.initials = userShare.initials;
									eventRecord.userName = userShare.name;
									eventRecord.userId = userShare.userID;
								} else {
									Vue.set(eventRecord, "colorIndex", null);
									Vue.set(eventRecord, "initials", null);
									Vue.set(eventRecord, "userName", null);
									Vue.set(eventRecord, "userId", null);

									commit("setEventSharesSet", false);
								}
							}

							if (initialEventRecordId != null) {
								if (eventRecord.eventRecordID === initialEventRecordId) {
									console.log("Set initial event record from event record refresh");
									initialRecord = eventRecord;
								}
							}
						});

						commit("setLatestEventRecordID", fromRecord);
						commit("setEventRecords", events.data);

						commit("setDataError", false, { root: true });

						if (initialRecord) {
							commit("setSelectedEventRecord", initialRecord);
						}
						commit("setRequestingEventRecords", false);

						resolve(events.data.length);
					}
				},
				errorResponse => {
					if (errorResponse.response != null && errorResponse.response.status === 401) {
						router.push({ path: "/event-queue" });
					} else if (errorResponse.response != null && errorResponse.response.status === 403) {
						commit("setEventNoPermission", true);
					} else {
						commit("setDataError", true, { root: true });
						resolve(0);
					}
					commit("setRequestingEventRecords", false);
				}
			);
		});
	},
	async ackEventRecord({ commit }, eventRecord) {
		if (eventRecord && eventRecord.awaitingAcknowledgement) {
			await api.eventRecordAcknowledgement(eventRecord.eventRecordID);
			commit("ackEventRecord", eventRecord.eventRecordID);
		}
	},
	async updateSharing({ commit, getters }, { eventID }) {
		const data = await api.loadShareForEvent(eventID);

		const myUserID: number = data.myUserID;
		const controllerUserID: number = data.controllerUserID;
		const shareUsers: EventShareUser[] = data.users;

		shareUsers.forEach(share => {
			share.isController = share.userID === controllerUserID;
		});

		commit("setIsСontroller", controllerUserID === myUserID);
		commit("setUserID", myUserID);
		commit("setСontrollerUserID", controllerUserID);
		commit("setEventShare", shareUsers);
		commit("setMobileEventShares", data.mobileShares);

		if (!getters.getEventShareSet) {
			const userShares = getters.userShares;

			commit("setEventRecordShares", userShares);
			commit("tasks/updateTaskAssignedToEventUser", userShares, {
				root: true
			});
			commit("setEventSharesSet", true);
		}

		//  const pollEventShares$ = timer(0, 5000).pipe(
		//  switchMap(_ => api.loadShareForEvent(eventID)),
		//  map((data: any) => {
		//    const myUserID: number = data.myUserID;
		//    const controllerUserID: number = data.controllerUserID;
		//    const shareUsers: EventShareUser[] = data.users;

		//      shareUsers.forEach((share) => {
		//          share.isController = share.userID === controllerUserID;
		//      });

		//      commit('setIsСontroller', controllerUserID === myUserID);
		//    commit('setUserID', myUserID);
		//      commit('setСontrollerUserID', controllerUserID);
		//      commit('setEventShare', shareUsers);

		//    if (!getters.getEventShareSet) {
		//        const userShares = getters.userShares;

		//      commit('setEventRecordShares', userShares);
		//      commit('tasks/updateTaskAssignedToEventUser', userShares, { root: true });
		//      commit('setEventSharesSet', true);
		//    }
		//  })
		//  ).subscribe();

		//  (this as any)._vm.subscriptions$.set('pollEventShares$', pollEventShares$);
	},
	async removeShare({ commit }, { eventID, uniqueID, isMobile }) {
		await api.eventShareRemove(eventID, uniqueID, isMobile);

		const shareUsers = await api.loadShareForEvent(eventID);
		commit("setMobileEventShares", shareUsers.mobileShares);
		commit("setEventShare", shareUsers.users);
	},
	async fetchEventDetails({ commit, state, rootGetters }, eventId: number) {
		const details = await api.loadEventDetails(eventId);

		if (details.data.initialEventRecordId && !state.SelectedEventRecord) {
			console.log("Setting SelectedEventRecord from event details");

			const initialRecord = state.EventRecords.find(
				(record: any) => record.eventRecordID === details.data.initialEventRecordId
			);

			if (initialRecord) {
				commit("setSelectedEventRecord", initialRecord);
			}
		}

		if (rootGetters["siteMonitorCameras/getRequiresEventDetails"]) {
			commit("siteMonitorCameras/setRequiresEventDetails", false, {
				root: true
			});
		}
		commit("setEventDetails", details.data);
		commit("siteMonitorCameras/setRequiresEventDetails", false, {
			root: true
		});
		commit("setEventCaseId", details.data.eventCaseId)

		return details.data;
	},

	async setEventDetails({ commit }, data: EventDetails | null) {
		commit("setEventDetails", data);
		commit("siteMonitorCameras/setRequiresEventDetails", false, {
			root: true
		});
	},

	/**
	 * Gets the details of an event to be shown in an expanded event record.
	 * @param context Vuex context.
	 * @param eventRecordID The id of the event record that will be expanded.
	 */
	async getEventRecordDetails(context, { eventRecordId }) {
		const result = await api.loadEventRecordDetails(eventRecordId);
		return result;
	},

	async setEventRecordFilter({ commit }, id: number) {
		commit("setEventRecordFilter", id);
	},

	async eventRecordAcknowledgement(context, eventRecordID: number) {
		const response = await api.eventRecordAcknowledgement(eventRecordID);
		return response.data;
	},

	/**
	 * Creates an event record attached to an event. The current user must be a part
	 * of the event, otherwise an error will be returned.
	 * @param context Vuex context.
	 * @param data The data to include with the new event record.
	 */
	async createAuditRecord(context, payload: AuditRecordDTO) {
		const { data } = await api.postAudit(payload);
		return data;
	},

	/**
	 * Like createAuditRecord, creates an event record with details attached to an event,
	 * but does not require that the current user already be a part of the event.
	 * @param context Vuex context.
	 * @param param1 The eventId, eventRecordTypeId, and any details (string) to include with the new event record.
	 */
	async createReviewRecord(
		context,
		payload: AuditRecordDTO
	): Promise<AuditReviewResponse> {
		const response = await api.auditReviewAction(payload);
		return response.data;
	},

	async loadRoutingGroupsByEvent(context, eventID: number) {
		const response = await api.loadRoutingGroupsByEvent(eventID);
		return response.data;
	},

	async loadOutcomesForEvent({ commit }, eventID: number) : Promise<EventOutcomeResponse> {
		const response = await api.loadOutcomesForEvent(eventID);
		commit("setEventOutcomes", response.data)
		return response.data;
	},

	async loadAllOutcomesForAlarms({ commit }) : Promise<EventOutcomeResponse> {
		const response = await api.loadAllOutcomesForAlarms();
		commit("setEventOutcomes", response.data)
		return response.data;
	},

	async changeEventRoutingGroup(context, data: RoutingGroupDTO) {
		const response = await api.changeEventRoutingGroup(data);
		return response.data;
	},

	async loadMaskReasonsForAlarm({ commit }, groupID: number) {
		const responseData = await maskingApi.loadMaskReasonsForAlarm(groupID);
		commit("setMaskReasons", responseData);
	},

	async loadMaskReasonsForGroup({ commit }, groupID: number) {
		const responseData = await maskingApi.loadMaskReasonsForGroup(groupID);
		commit("setGroupMaskReasons", responseData);
	},

	async maskAlarm(context, maskRequest: any) {
		return await maskingApi.maskAlarm(maskRequest);
	},

	async loadMaskedAlarms({ commit }, groupID: number) {
		const response = await maskingApi.loadMaskedAlarmsForGroup(groupID);
		commit("setMaskedAlarms", response.data);
	},

	async closeEvent(context, data: CloseEventDTO): Promise<CloseEventResultDTO> {
		return await api.closeEvent(data);
	},

	async closeMultipleEvents(context, data: CloseMultipleEventsDTO): Promise<CloseEventResultDTO> {
        return await api.closeMultipleEvents(data);
    },

	async loadEventGroup(context, groupID: number) {
		return await api.loadGroup(groupID);
	},

	async loadExternalUsersAllowed({ commit }, eventId: number) {
		const allow = await api.loadExternalUserShareFlag(eventId);
		commit("setExternalUsersAllowed", allow);
	},

	async searchGroup(context, SearchTerm: string) {
		return await api.searchGroup(SearchTerm);
	},

	setAreaNotesVisible({ commit }, isVisible: boolean) {
		commit("setAreaNotesVisible", isVisible);
	},

	async loadEventOperators({ commit }, eventID: number) {
		const shareUsers = await api.loadShareForEvent(eventID);
		commit("setEventShare", shareUsers.users);
		commit("setMobileEventShares", shareUsers.mobileShares);
	},

	async loadHistoricalEventOperators({ commit }, eventID: number) {
		const shareUsers = await api.loadHistoricalShareForEvent(eventID);
		commit("setEventShare", shareUsers.users);
		commit("setMobileEventShares", shareUsers.mobileShares);
	},

	async eventShareSearch({ commit }, query: string) {
		const { data } = await api.eventShareSearch(query);
		commit("setShareWithPersons", data);
	},

	async loadNotificationTemplate(context, payload: any) {
		const { data } = await api.loadNotificationTemplate(payload);
		return data;
	},

	async addAdhocEventShare({ commit }, payload: any) {
		const { data } = await api.addAdhocEventShare(payload);
		const shareLink = window.location.toString().replace(window.location.hash, "#/Access/" + data.shareID);

		commit("setAdHocShareDetails", {
			eventUserID: data.shareID,
			accessCode: data.accessCode,
			shareLink
		});
		data.shareLink = shareLink;

		return data;
	},

	async shareEventWithUser(context, payload: any) {
		return await api.shareEventWithUser(payload);
	},

	async shareEventWithGroup(context, payload: any) {
		return await api.shareEventWithGroup(payload);
	},

	async eventPassControl(context, { eventID, userID }) {
		return await api.EventPassControl(eventID, userID);
	},

	async parkEvent(context, payload: ParkEventDTO) {
		return await api.EventPark(payload);
	},

	async leaveEvent(context, eventID: number) {
		return await api.leaveEvent(eventID);
	},

	async eventRecordAllAcknowledgement({ commit }, eventId: number) {
		await api.eventRecordAllAcknowledgement(eventId);
		commit("setEventAckCount")
	},

	async unlinkCamera(context, data: UnlinkCameraDTO) {
		return await api.unlinkCamera(data);
	},

	async createResponseActionForEventRecord({ commit }, { eventRecordId, title, priority, groupId }) {
		const responseID = await api.createResponseActionForEventRecord({
			eventRecordId,
			title,
			priority,
			groupId
		});

		commit("setEventRecordResponse", {
			eventRecordID: eventRecordId,
			title,
			priority,
			responseID
		});
	},

	/**
	 * Flags the current event for review and returns true/false
	 * depending on the success of the operation.
	 * @param eventId The ID of the event currently being processed.
	 */
	async flagEventForReview({ commit }, { eventId, flagged }) {
		const result = await api.flagEventForReview(eventId, flagged);

		if (result) {
			commit("setFlaggedForReview", flagged);
		}

		return result;
	},

	/**
	 * preserves the current event and returns true/false
	 * depending on the success of the operation.
	 * @param eventId The ID of the event currently being processed.
	 */
	async preserveEvent({ commit }, { eventId, preserved }) {
		const result = await api.preserveEvent(eventId, preserved);
		return result;
	},

	/**
	 * Puts an existing event into on test mode.
	 * @param context Vuex context
	 * @param param1 The necessary info to create the payload
	 */
	async putEventOnTest(context, { eventId, groupId, hours, minutes, date, scheduleType, auditMode, onTestNote }) {
		const result = await api.putEventOnTest({
			eventId,
			groupId,
			hours,
			minutes,
			date,
			scheduleType,
			auditMode,
			onTestNote
		});
	},

	async fetchEventRecordDetails({ commit }, { eventRecordID }) {
		const result = await api.loadEventRecordDetails(eventRecordID);
		return result;
	},

	async removeAdhocUsersFromEvent({ commit, state }) {
		let adhocUsers = state.EventShares.filter(
			(user: any) => user.userID == null && !user.removed && user.isActive
		);

		adhocUsers.forEach(adhocUser => {
			api.eventShareRemove(adhocUser.eventID, adhocUser.eventUserID);
		});
	},

	setHideMapFlag({ commit }, hideMapFlag: boolean) {
		commit("setHideMapFlag", hideMapFlag);
	},

	async pollApplianceServerAndDeviceServerDetails({ dispatch, commit, state }) {

		if (!state.applianceServerAndDeviceServerPollingStatus.isPolling) {
			let fetchApplianceServerAndDeviceServer = async () => {
				try {
					await dispatch("fetchApplianceServerDetails");
				} catch (err) {
					console.error(err)
				}

				try {
					await dispatch("refreshDeviceServerDetails");
				} catch (err) {
					console.error(err)
				}
			}

			await fetchApplianceServerAndDeviceServer();

			clearInterval(state.applianceServerAndDeviceServerPollingStatus.intervalHandlerId);

			let intervalHandlerId = window.setInterval(fetchApplianceServerAndDeviceServer, 60000);

			let pollingStatus: PollingStatus = {
				isPolling: true,
				intervalHandlerId: intervalHandlerId
			}

			commit("setApplianceServerAndDeviceServerPollingStatus", pollingStatus);
		}
	},

	stopPollingApplianceServerAndDeviceServerDetails({ commit, state }): void {

		if (state.applianceServerAndDeviceServerPollingStatus.isPolling) {
			clearInterval(state.applianceServerAndDeviceServerPollingStatus.intervalHandlerId);

			let pollingStatus: PollingStatus = {
				isPolling: false,
				intervalHandlerId: 0
			};
			commit("setApplianceServerAndDeviceServerPollingStatus", pollingStatus);
		}
	},

	async fetchApplianceServerDetails({ commit, state }): Promise<void> {
		const applianceServerDetails = await api.getApplianceServerDetails();
		commit("setApplianceServerDetails", applianceServerDetails);
	},

	async fetchDeviceServerDetails({ commit, state }, deviceIds: number[]): Promise<void> {
		if (!deviceIds || deviceIds.length == 0) {
			commit("setDeviceServerDetails", []);
			return;
		}

		const existingDeviceIds = state.deviceServerDetails && state.deviceServerDetails.length ? state.deviceServerDetails.map(details => {
			return details.deviceId
		}) : [];

		// remove the existingIds from deviceIds
		deviceIds = difference(deviceIds, existingDeviceIds);

		if(deviceIds.length > 0) {
			const result = await api.getDeviceServerDetails(deviceIds);
			commit("setDeviceServerDetails", result);
		}
	},

	async refreshDeviceServerDetails({ state, dispatch }): Promise<void> {
		const deviceIds = state.deviceServerDetails.map(details => {
			return details.deviceId
		});

		if (deviceIds && deviceIds.length > 0) {
			await dispatch("fetchDeviceServerDetails", deviceIds);
		}
	},

	/**
	 * Updates the Location of the current event
	 * @param latLong The ID of the event currently being processed.
	 */
	async updateCurrentEventLocation({ state, commit },  latLng :string): Promise<void> {
		await api.updateInitialAlarmLocation(state.EventDetails.eventID, latLng);
		commit("setLocation", {
			latLong: latLng,
			minElevation: state.EventDetails.minElevation,
			maxElevation: state.EventDetails.maxElevation
		})

	},

	async loadAllOutcomes({ commit }): Promise<EventOutcomeResponse> {
		const response = await api.loadAllOutcomes();
		if (response.status != 200){
			return null;
		}

		commit("setEventOutcomes", response.data)
		return response.data;
	}
};
