import { MutationTree } from "vuex";
import { AreaNotesState, AreaNote } from "./types";
import { getDefaultState } from "./state";
import Vue from "vue";

export const mutations: MutationTree<AreaNotesState> = {
	setAreaNotes(state, notes) {
		state.areaNotes = notes;
	},
	addAreaNote(state, note) {
		if (note) {
			state.areaNotes.push(note);
		}
	},
	deleteAreaNote(state, noteId) {
		Vue.delete(state.areaNotes, state.areaNotes.findIndex(x => x.noteId === noteId));
	},
	updateNote(state, note: AreaNote) {
		let indexToUpdate = state.areaNotes.findIndex(n => n.noteId === note.noteId)
		Vue.set(state.areaNotes, indexToUpdate, note);
	},
	setEditingNote(state, note: AreaNote) {
		state.areaNoteToEdit = note;
	},
	setIsFormShown(state, isFormShown: boolean) {
		state.isFormShown = isFormShown;
	},
	setIsEditing(state, isEditing) {
		state.isEditing = isEditing;
	},
	// @techdebt
	// This is accessed from outsite of this component in Event Queue and Site Monitor.
	// As a result, this mutation and its related state data should ideally live in a different module
	setAreaNoteGroupDetails(state, { groupId, groupTitle }) {
		state.currentGroupId = groupId;
		state.currentGroupTitle = groupTitle;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
