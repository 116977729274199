import { Module } from "vuex";
import { areaGetters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { AreaState } from "./types";
import { RootState } from "@/store/types";
import { getDefaultState } from "./state";

export const state: AreaState = getDefaultState();

const namespaced: boolean = true;

export const areas: Module<AreaState, RootState> = {
	namespaced,
	state,
	getters: areaGetters,
	actions,
	mutations
};
