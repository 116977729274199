
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class SiteStatus extends Vue {
  public armedSitesCount: number = 0;
  public disarmedSitesCount: number = 0;
  public hide: boolean = false;

  created () {
    this.init();
    this.update();
  }

  init () {
    // @refactor interval
    // setTimeout(function() { this.update() }.bind(this), 10);
    // this.updateInterval = setInterval(function() {
    //     this.update()
    // }.bind(this), 20000);
  }
  
  destroy () {
    // clearInterval(this.updateInterval);
  } 
  
  update () {
    // DataService.getData("EventQueue/GroupSummary").done(function(status) {
    //     this.armedSitesCount = status.armed;
    //     this.disarmedSitesCount = status.disarmed;

    //     this.hide = false;
    // }.bind(this)).fail(function() {
    //     this.hide = true;
    // }.bind(this));
  }
}
