
import { Prop, Mixins, Component, Watch } from "vue-property-decorator";
import api from '@/services/api.service';
import SystemViewTreeNode from '@/types/sv-data/system-view/SystemViewTreeNode';
import { SetupSummaryResponse, DeviceSummary } from '@/types/sv-data/system-view/SetupSummary';
import { Getter } from 'vuex-class';
import { UserPermissions, FeaturesList } from '@/store/types';
import { get } from 'lodash';
import SystemOverview from "./SystemOverview.vue";
import SystemViewMap from "./SystemViewMap.vue";
import SetupOverviewAlarmSetup from "./SystemViewAlarmSetup.vue";
import { MapLayerItem } from "@/types/sv-data/system-view/MapLayerItem";
import HideMapMixin from "@/mixins/HideMapMixin";

@Component({
	components:{
		SystemOverview,
		SystemViewMap,
		SetupOverviewAlarmSetup,
	}
})
export default class SetupOverviewDevice extends Mixins(HideMapMixin) {
	@Prop({ required: true, default: null }) private selectedNode: SystemViewTreeNode;

	@Getter getPermissions: UserPermissions;
	@Getter getFeaturesList: FeaturesList;

	private setupResponse: SetupSummaryResponse =  null;
	private deviceSummary: DeviceSummary = null;
	private locationDetails: MapLayerItem[] = [];

	private async mounted(): Promise<void> {
		await this.retrieveDeviceDetails();
	}

	@Watch("selectedNode", { deep: true })
	private async retrieveDeviceDetails(): Promise<void> {
		if(this.selectedNode && this.canUseSystemView)
		{
			this.deviceSummary = null; // Reset previous display data

			this.setupResponse = await api.getSetupSummary(this.selectedNode.objectTypeId, this.selectedNode.objectId);

			if(this.setupResponse && !this.setupResponse?.error ) {
				try {
					this.deviceSummary =  JSON.parse(this.setupResponse.summary) as DeviceSummary;
				}
				catch (e) {
					console.error("Failed to Parse Device summary: " + e);
					this.setupResponse.error = "Failed to Parse Device summary";
				} finally {
					this.updateMapData();
				}
			}
		}
	}

	private get canUseSystemView(): boolean {
		return  get(this.getFeaturesList, ["SystemView"]) && (this.getPermissions.canViewSystemView || this.getPermissions.isSystemAdmin);
	}

	private get getSystemOverviewCols(): string{
		return this.isHideMapsEnabled ? '12' : '6';
	}

	private updateMapData(): void {
		if (!!this.deviceSummary && !!this.deviceSummary.mapLayerItems) {
			this.locationDetails = this.deviceSummary.mapLayerItems;
		} else {
			this.locationDetails = [];
		}
	}
}
