
import { Component, Vue, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { UserPermissions } from "../store/types";
import { AreaNode } from '@/types/sv-data/groups/AreaNode';
import AreaTreeSelect from './form/AreaTreeSelect.vue';

const Eventqueue = namespace("eventqueue");

@Component({
	components: {
		multiselect: Multiselect,
		"area-tree-select": AreaTreeSelect
	},
	// Validation for the patrol Note.
	mixins: [validationMixin],
	validations: {
		patrolNote: {
			required: requiredIf(function() {
				return this.tourNoteRequired;
			})
		}
	}
})
export default class ManualPatrol extends Vue {
	private handleError: any = null;

	@Eventqueue.Getter("getManualPatrolShown") manualPatrolShown!: boolean;
	@Eventqueue.Getter("getTourNoteRequired") tourNoteRequired: boolean; // returned value from action 'tourNotesRequired', true or false.
	@Eventqueue.Mutation setManualPatrolShown: any;
	@Eventqueue.Mutation setLastEventHandled: () => void;
	@Eventqueue.Action eventControl: any;
	@Eventqueue.Action tourNotesRequired: any; // Action to call API to get required true or false.

	@Getter("getPermissions") permissions: UserPermissions;

	private isStartingTour: boolean = false;
	private patrolNote: string = "";
	private selectedArea: AreaNode = null

	private created(): void {
		this.setManualPatrolShown(false);
		// call action to check if the required for note is true or false.
		this.tourNotesRequired();
	}

	//// Validate the form, check if the 'group and note' is required and if so, validate correctly.
	private get validForm(): boolean {
		// check if the note is required and groupID is undefined.
		if (!this.$v.patrolNote.required && !this.selectedArea) {
			// return false if either patrol is required (and note is not filled) or GroupID is undefineid... or both.
			return false;
		} else if (this.$v.patrolNote.required && this.selectedArea && this.selectedArea.id) {
			// return true if BOTH GroupID is not undefined and required is true or false, but there is something in the field if set to true.
			return true;
		} else {
			return false;
		}
	}

	private setNoteRequired(value: string): void {
		this.patrolNote = value;
		this.$v.patrolNote.$touch();
	}

	//// On submit of the model 'Ok' button.
	@Emit("submit")
	private onSubmit(): void {
		if (!this.validForm) {
			return;
		}
		// to update the validation to check, sets all it's children to true for validation.
		this.$v.$touch();
		if (!this.$v.patrolNote.$error) {
			this.$v.$reset();
			// Start manual Patrol.
			this.startManualPatrolForGroup(this.selectedArea);
		}
	}

	private async startManualPatrolForGroup(patrolGroup: AreaNode): Promise<void> {
		this.handleError = null;

		// If we are already processing a tour request, don't retrigger
		if (this.isStartingTour) {
			return;
		}

		// We are processing a tour request
		this.isStartingTour = true;

		// Payload object, to send via the action to the API.
		const eventObject = {
			groupId: patrolGroup.id,
			patrolNote: this.patrolNote
		};

		// Call action with payload, gets a returned value.
		const patrolResult = await this.eventControl(eventObject);

		if (patrolResult && patrolResult.eventID != null) {
			this.setLastEventHandled();
			this.setManualPatrolShown(false);
			this.$router.push({
				path: `/site-monitor/${patrolResult.eventID}`
			});
		} else {
			let eventType = "";
			switch (patrolResult.existingEventTypeID) {
				case 1:
					eventType = "alarm";
					break;
				case 2:
					eventType = "tour";
					break;
				case 3:
					eventType = "site check";
					break;
				case 6:
					eventType = "test";
					break;
				default:
					break;
			}

			this.handleError = {
				groupTitle: patrolGroup.label,
				eventType: eventType
			};
		}

		// We have finished the request
		this.isStartingTour = false;
	}

	public closeManualPatrol() {
		this.setManualPatrolShown(false);
		// Clear tree and note on close.
		this.patrolNote = "";
		this.selectedArea = null;
	}
}
