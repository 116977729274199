
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class RaiseTitle extends Vue {
	@Prop() titleList: string[];

	private buildTitleString(title: string, idx: number) {
		if (idx === 0) {
			return title;
		}
		return `${"\xa0\xa0".repeat(idx - 1)}└─${title}`;
	}


}
