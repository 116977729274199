// API definition is available at https://dev.sureviewops.com/api/swagger/index.html

import {
	axiosInstance,
	axiosJsonInstance,
	axiosJsonDirectInstance,
	axiosNoContentTypeInstance
} from "@/axios.instance";
import { AxiosRequestConfig, CancelToken, AxiosResponse } from "axios";
import { AlarmQueueFilterID } from "@/store/eventqueue/types";
import axios from "axios";
import {
	EventRecordDetails,
	ApplianceServerDetails,
	DeviceServerDetails,
	AddInvolvedPartyRequest,
	RemoveInvolvedPartyRequest,
	InvolvedParty
} from "@/store/site-monitor/types";
import { ExternalMapLayer } from "@/store/external-map-layers/types";
import { ServerTypeEvent, Tag } from "@/types";
import { CaseData } from "@/store/case-management/types";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import { AssetAuditHistory, AssetStatusInfo, AuditRequest, AssetMapLayerItem } from "@/components/mobile-assets/types";
import { apiFormattingMixins } from "@/mixins";
const { arrayToQueryString } = apiFormattingMixins.methods
import UsersPagedResponse from "@/types/sv-data/users/UsersPagedResponse";
import UserDTO from "@/types/sv-data/users/UserDTO";
import GroupRowPagedResponse from "@/types/sv-data/groups/GrowRowPagedResponse";
import Script from "@/types/sv-data/events/Script";
import { ContactRole, UserContactDetails, UserGroup, UserRole } from "@/store/user-management/types";
import ResponseAlarmType from "@/types/sv-data/events/ResponseAlarmType";
import EventCategory from "@/types/sv-data/events/EventCategory";
import { DeviceDetails, ServerDetails, ServerType, ServerLineProfile, ServersWithEnabledDeviceTypesParams, SyncSystem, DeviceConfigurationPresetPayload, DeviceConfigurationPreset } from "@/store/devices/types";
import PersonalInfo from "@/types/sv-data/users/PersonalInfo";
import { AppOptions, LoginRequest, LoginResult, UserInfo, ValidatePinCodeRequest } from "@/store/types";
import { MobileEventShareInfo } from "@/store/mobile/types";
import { ScriptCategoryLink, CategoryReorderRequest } from "@/store/tasks/types";
import ShareAsset from "@/types/sv-data/ShareAsset";
import AssetType from "@/types/sv-data/AssetType";
import EventUser from "@/types/sv-data/events/EventUser";
import MapAreaRegion from "@/types/sv-data/maps/MapAreaRegion";
import MapAreaRegionDetails from "@/types/sv-data/maps/MapAreaRegionDetails";
import Asset from "@/types/sv-data/Asset";
import AssetsWithPagination from "@/types/sv-data/AssetsWithPagination";
import AssetTypesWithPagination from "@/types/sv-data/AssetTypesWithPagination";
import AlarmDecoder, { AlarmDecoderExtended } from "@/types/sv-data/alarms/AlarmDecoder";
import IdValue from "@/types/sv-data/IdValue";
import SamlSettings from "@/types/sv-data/SamlSettings";
import {
	MapBounds,
	MapData,
	MapLayerItemType,
	MapLayer,
	MapLayerItem
} from "@/store/map-layers/types";
import ShortContact from "@/types/sv-data/users/ShortContact";
import EmailNotificationRequest from "@/types/sv-data/events/EmailNotificationRequest";
import { HousekeepingSettings, OrganizationSettings } from "@/types/sv-data/OrganizationSettings";
import { Response } from "@/store/responses/types";
import { AudioDevice } from "@/types/audio/audio";
import ResponsesPagedResponse from "@/types/sv-data/responses/ResponsesPagedResponse";
import ResponseDtoPagedResponse from "@/types/sv-data/responses/ResponseDtoPagedResponse";
import ServerDetailsPagedResponse from "@/types/sv-data/devices/ServerDetailsPagedResponse";
import { ILatLng } from "@sureview/v2-mapping-saas";
import { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";
import ContactsPagedResponse from "@/types/sv-data/users/ContactsPagedResponse";
import AlarmDecoderPagedResponse from "@/types/sv-data/alarms/AlarmDecoderPagedResponse";
import { GetChildGroupsListRequest } from "@/types/sv-data/groups/GetChildGroupsListRequest";
import ServerTypeEventsPagedResponse from "@/types/sv-data/events/ServerTypeEventPagedResponse";
import { ServerTypeEventsPagedQueryParams } from "@/types/sv-data/events/ServerTypeEventsRequest";
import Vue from "vue";
import { AreaMaskingCount, FlatAreaList, FlatAreaListRequest } from "@/store/areas/types";
import { ManualRaiseResult } from "@/store/manual-raise/types";
import { GroupSyncAudit, GroupSyncModel, GroupSyncQueue, SyncStateResponse } from "@/types/sv-data/devices/SyncSystem";
import Matrix from "@/types/sv-data/media-matrix/Matrix";
import { ResponseAction, ResponseActionType } from "@/store/response-actions/types";
import { SetupSummaryResponse } from "@/types/sv-data/system-view/SetupSummary";
import HealthCheckRequest from "@/types/system-health/HealthCheckRequest";
import SystemViewTreeNode from "@/types/sv-data/system-view/SystemViewTreeNode";
import SystemViewRequestTreeParams from "@/types/sv-data/system-view/SystemViewRequestTreeParams";
import ObjectTypeId from "@/types/sv-data/enums/ObjectTypeIds";
import { HealthHistoryResponse } from "@/types/sv-data/system-view/HealthHistory";
import SystemViewHealthStatusCount from "@/types/sv-data/system-view/SystemViewHealthStatusCount";
import { ServerTypeOption } from "@/types/sv-data/events/ServerTypeOption";
import {
	SituationalAwarenessEventQueue,
	SituationalAwarenessEventQueueActionRequest,
	SituationalAwarenessEventQueueActionResult,
} from "@/store/situational-awareness/types";
import { RulesWithPagination, RuleServer, RuleActionPlan, RuleServerTypeEvent, RuleServerType, RuleAlarm, Rule } from "@/store/rules-engine/types";
import { EventOutcomeResponse } from "@/types/EventOutcome";
import {
	DistanceConfiguration,
	DistanceConfigurationAlarmDecoder,
	DistanceConfigurationPagedResponse
} from "@/store/distance-configuration/types";
import UpdateEventOutcomeRequest from "@/types/sv-data/requests/UpdateEventOutcome";
import CloseMultipleEventsDTO from "@/types/sv-data/events/CloseMultipleEventsDTO";
import CloseEventResultDTO from "@/types/sv-data/events/CloseEventResultDTO";
import SyncSystemsPagedResponse from "@/types/sv-data/devices/SyncSystemsPagedResponse";
import { AreaEventHistoryPaginatedSearchParams, AreaEventHistoryWithPagination } from "@/types/sv-data/AreaEventHistory";

interface ScreenshotInfo {
	DeviceID: number;
	Image: string;
}
interface InterDataServiceLoginTokenContainer {
	Key: string;
	RegionalUserID: number;
}
interface UserLoginData {
	sessionID: string;
	accessCode: string;
}

export interface CompleteTaskPayload {
	EventID: number;
	EventTaskID: string;
	TaskComplete: boolean;
	Result?: string;
	taskData?: any;
}

// @contributing
// awoid naming methods prefixed `get`, e.g. no getEvents,
// using `load` hints at presence of an async operation

class ImmixCloudV2ApiHttp {
	public static readonly apiRoot = "";
	// true for formdata payloads, false for application/json, applies to POST and PUT
	private readonly STRINGIFY_PAYLOADS: boolean = true;

	private axios: any;
	private axiosJson: any;
	private axiosPost: any;
	private axiosJsonDirectInstance: any;
	private axiosNoContentTypeInstance: any;

	constructor() {
		this.axios = axiosInstance;
		this.axiosJson = axiosJsonInstance;

		// Used to skip the interceptor if we want to handle our exceptions
		// within our eco-system.
		this.axiosJsonDirectInstance = axiosJsonDirectInstance;
		this.axiosNoContentTypeInstance = axiosNoContentTypeInstance;
	}

	public async getAppOptions(): Promise<AppOptions>
	{
		const manifestRequest = axios.create({
			headers: {
				Accept: "application/json, text/javascript, */*; q=0.01",
				"Content-Type": "application/json"
			}
		});

		try
		{
			const { data } = await manifestRequest.get("./manifest.json");
			return data;
		}
		catch (err) {
			console.error(err);
			return null;
		}
	}

	public get getOriginalApiUrl() {
		return axiosInstance.prototype._originalBaseUrl;
	}

	public setBaseApiUrl(apiUrl: string) {
		this.axios.prototype.setApiBaseUrl(apiUrl);
	}

	public startHeartbeat() {
		this.axios.prototype.startHeartbeatToOriginalServer();
	}

	public stopHeartbeat() {
		this.axios.prototype.stopHeartbeatToOriginalServer();
	}

	public async saveCameraScreenshot(data: ScreenshotInfo) {
		this.axios.post("/Devices/SaveCameraSnapshot", data);
	}

	public async login(credentials: LoginRequest): Promise<LoginResult> {
		const { data } = await this.axios.post("/login", credentials);
		return data;
	}

	public async logout(): Promise<any> {
		const response = await this.axios.get("/Login/Logout");
		return response;
	}

	public async singleEventLogin(credentials: UserLoginData): Promise<any> {
		const response = await this.axios.post("/login/SingleEvent", credentials);
		return response;
	}

	public async loginToRegion(token: InterDataServiceLoginTokenContainer): Promise<any> {
		const response = await this.axios.post("/login/InterDataServiceLogin", token);
		return response;
	}

	public async getInterDataServiceLoginToken(regionId: number): Promise<any> {
		return (await this.axios.get(`/Login/GenerateInterDataServiceLoginToken/${regionId}`)).data;
	}

	public async getLoginSession(): Promise<LoginResult> {
		const { data } = await this.axios.get("/login/session");
		return data;
	}

	public async getMapDataInBounds(mapViewport: MapBounds, cancelToken: CancelToken = null): Promise<MapData> {
		return (await this.axios.post("/MapLayers", mapViewport, { cancelToken: cancelToken })).data;
	}

	public async getDefaultMapBounds(): Promise<MapBounds> {
		return (await this.axios.get("/MapLayers/Bounds")).data;
	}

	public async getBoundsForArea(areaId: number): Promise<MapBounds> {
		return (await this.axios.get(`/MapLayers/Bounds/${areaId}`)).data;
	}

	public async searchAssets(mapQuery: any): Promise<any> {
		const response = await this.axios.get("/MapLayers/SearchAssets", {
			params: mapQuery
		});
		return response.data;
	}

	public async getMapLayerAssets(noUserAsset: boolean = false): Promise<any> {
		const response = await this.axios.get(`/MapLayers/Assets/${noUserAsset}`)
		return response.data
	}

	public async updateAssetMapLayerItem(mapLayerItem: AssetMapLayerItem): Promise<void>{
		await this.axiosJson.put("/MapLayers/MapItems", mapLayerItem);
	}

	public async getMapLayer(mapLayerId: number): Promise<MapLayer> {
		return (await this.axios.get(`/MapLayers/Image/${mapLayerId}`)).data;
	}

	public async getAssetMapItemForUser(): Promise<AssetMapLayerItem> {
		const { data } = await this.axios.get(`/MapLayers/UserAssetMapItem/`);
		return data;
	}

	public async putMapLayerItem(mapLayerItem: MapLayerItem) {
		return await this.axiosJsonDirectInstance.put("/MapLayers/MapItems", mapLayerItem);
	}

	public async deleteMapLayer(mapLayerId: number): Promise<any> {
		return await this.axiosJson.delete(`/MapLayers/${mapLayerId}`);
	}

	public async updateAreaLatLong(groupId: number, latLong: ILatLng): Promise<any> {
		await this.axiosJsonDirectInstance.post(`/groups/UpdateLatLong/${groupId}`, latLong);
	}

	public async putMapLayer(mapLayer: FormData) {
		return (await this.axiosNoContentTypeInstance.put("/MapLayers", mapLayer, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})).data;
	}

	public async deleteMapLayerItem(mapLayerItemId: number): Promise<any> {
		return await this.axiosJsonDirectInstance.delete(`/MapLayers/MapItems/${mapLayerItemId}`);
	}

	public async getAssetTypeByID(assetTypeId: number): Promise<AssetType> {
		const { data } = await this.axiosJson.get(`/Assets/AssetTypes/${assetTypeId}`);
		return data;
	}

	public async getAssetTypes(paginatedSearchRequest: PaginatedSearchRequest): Promise<AssetTypesWithPagination> {
		const { data } = await this.axios.get(`/Assets/AssetTypes`, paginatedSearchRequest);
		return data;
	}

	public async searchAssetTypes(query: string, pageNumber: number, pageSize: number): Promise<AssetTypesWithPagination> {
		const { data } = await this.axios.get(`/Assets/AssetTypes/Search?query=${query}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
		return data;
	}

	public async getCachedAssetTypes(): Promise<AssetType[]> {
		const { data } = await this.axiosJson.get("/Assets/AssetTypes/Cache");
		return data;
	}

	public async createOrUpdateAssetType(assetType: AssetType): Promise<any> {
		const { data } = await this.axios.put("/Assets/AssetType", assetType);
		return data;
	}

	public async deleteAssetType(assetTypeId: number): Promise<any> {
		const { data } = await this.axiosJson.delete(`/Assets/AssetType/${assetTypeId}`);
		return data;
	}

	public async getPagedStandaloneAssets(paginatedSearchRequest: PaginatedSearchRequest): Promise<AssetsWithPagination> {
		const { data } = await this.axios.get(`/Assets/AssetOnly`, paginatedSearchRequest);
		return data;
	}

	public async createOrUpdateAsset(asset: Asset): Promise<any> {
		const { data } = await this.axios.put("/Assets", asset);
		return data;
	}

	public async disableAsset(assetId: number): Promise<any> {
		await this.axios.post(`/Assets/Disable/${assetId}`);
	}

	public async setAssetStatus(assetStatus: AssetStatusInfo): Promise<void>{
		await this.axios.post("/Assets/SetStatusInfo", assetStatus);
	}

	public async setMultiMapLayerAssetStatuses(status: string, note:string, assetIds: number[]): Promise<void>{
		const assetsUpdate = {
			status: status,
			statusNote: note,
			assetIds: assetIds
		}
		await this.axiosJson.post("/Assets/SetStatusInfo/Multiple", assetsUpdate);
	}

	public async getAssetAuditHistory(auditRequest: AuditRequest): Promise<AssetAuditHistory[]>{
		const response =  await this.axios.get(`/Assets/AuditHistory`,{
			params:{
				assetId : auditRequest.assetId,
				beforeEventRecordId : auditRequest.beforeEventRecordId,
				maxRows : auditRequest.maxRows,
			}
		});
		return response.data;
	}

	public async getAssetsStatusHistory(maxRows: number, beforeEventRecordId: number): Promise<AssetAuditHistory[]>{
		const response =  await this.axios.get(`/Assets/StatusHistory`,{
			params:{
				beforeEventRecordId,
				maxRows
			}
		});
		return response.data;
	}

	public async getRecentStatuses(mapLayerItemId: number): Promise<string[]>{
		const response = await this.axios.get(`/Assets/RecentStatuses/${mapLayerItemId}`)
		return response.data;
	}

	public async getRecentStatusesMultiAssets(assetIds: number[]): Promise<string[]>{
		const response = await this.axios
			.get(`/Assets/RecentStatuses?${arrayToQueryString("assetIds", assetIds)}`);
		return response.data;
	}

	public async getShareableAssets(): Promise<ShareAsset[]>{
		const response = await this.axios.get(`/EventShare/ShareableAssets/`)
		return response.data;
	}

	public async getMapLayerAssetStatus(statusId: any): Promise<any>{
		const response =  await this.axios.get(`/MapLayers/Assets/${statusId}`);
		return response.data;
	}

	public async closeEvent(outcome: any): Promise<CloseEventResultDTO> {
		const response = await this.axios.post("/Event/Close", outcome);
		return response.data;
	}

	public async closeMultipleEvents(data: CloseMultipleEventsDTO): Promise<CloseEventResultDTO> {
		const response = await this.axios.post("/Event/CloseMultiple", data);
		return response.data;
	}

	public async flagEventForReview(eventId: number, flagged: boolean): Promise<boolean> {
		const { data } = await this.axios.post("/Event/Flag", {
			eventId,
			flagged
		});

		return data;
	}

	public async preserveEvent(eventId: number, preserved: boolean): Promise<boolean> {
		const { data } = await this.axios.post("/Event/Preserve", {
			eventId,
			preserved
		});

		return data;
	}

	public async groupsForFilter(filterId: any): Promise<any[]> {
		return (
			await this.axios.get("/AlarmQueueFilters/GroupsForFilter", {
				params: {
					AlarmQueueFilterID: filterId
				}
			})
		).data;
	}
	public async loadAlarmQueueFiltersForGuards(): Promise<any[]>{
		const { data } =
			await this.axios.get("/DispatchSetup/AlarmFilters");
		var mappedFilters = data.map(item =>
			{
				const container = { title:"",alarmQueueFilterID:null };
				container.title = item.title;
				container.alarmQueueFilterID = item.alarmQueueFilterID;

				return container;
			})
		return mappedFilters;
	}
	public async loadEventQueueFiltered(filters: any): Promise<any> {
		const response = await this.axios.post("/eventqueue", filters);
		return response;
	}

	public async eventHandleTop(filter: AlarmQueueFilterID): Promise<any> {
		const regionId = filter.regionId > 0 ? filter.regionId : null;
		const { data } = await this.axios.get(`/EventQueue/HandleTop/${filter.filterId}`, {
			params: { regionId }
		});

		return data;
	}

	public async postAudit(data: any): Promise<any> {
		const response = await this.axios.post("/EventRecords", data);
		return response;
	}

	public async auditReviewAction(data: any) {
		const response = await this.axios.post("/EventRecords/AuditReview", data);
		return response;
	}

	public async forceRestore({ eventRecordID, restoralReason }): Promise<any> {
		return await this.axios.post("/EventRecords/ForceRestore", {
			Id: eventRecordID,
			Reason: restoralReason
		});
	}

	public async forceRestoreAllAlarms(eventId: number, reason: string ): Promise<any> {
		return await this.axios.post("/EventRecords/ForceRestoreAllAlarms", {
			Id: eventId,
			Reason: reason
		});
	}

	public devicesByGroup(groupID: number, params: any): Promise<any> {
		return axiosInstance.get("/devices/byGroup/" + groupID, {
			params
		});
	}

	public async allDevicesByGroup(groupID: number, params: any) {
		params.page = 1;
		var loadMore = true;
		var allDevices = [];

		while (loadMore) {
			var sample = await axiosInstance.get("/devices/byGroup/" + groupID, {
				params
			});

			sample.data.devices.forEach(record => {
				allDevices.push(record);
			});

			if (sample.data.devices.length < 100) {
				loadMore = false;
			}

			params.page = params.page + 1;
		}

		return allDevices;
	}

	public async devicesSearchNear(params: any) {
		return await axiosInstance.get("/Devices/SearchNear", {
			params
		});
	}

	public async deviceAction(deviceID: number, payload: any): Promise<DeviceDetails> {
		const { data } = await axiosInstance.post("/devices/" + deviceID, payload);
		return data;
	}

	public async isUrlReachable(baseUrl: string, route: string): Promise<Boolean> {
		try {
			var externalAxios = axios.create({
				baseURL: baseUrl,
				withCredentials: true,
				headers: {
					Accept: "application/json, text/javascript, */*; q=0.01",
					"Content-Type": "application/json",
				}
			});

			var response = await externalAxios.get(route);
			if (response && response.status === 200){
				return true;
			}
		} catch (ex) {
			console.error("isUrlReachable Exception: ", ex)
		}

		return false;
	}

	public async loadDeviceById(deviceId: number, params: any): Promise<any> {
		// Avoid axios interceptor to we can handle when devices arent found
		var response = await this.axiosJsonDirectInstance.get(`/devices/${deviceId}`, {
			params
		});

		if (!response) {
			throw("No Response received")
		}

		if (response.status != 200) {
			throw (response)
		}

		var { data } = response;

		if(!data.deviceServerEndpoint){
			data.deviceServerEndpoint = api.getDeviceServiceAddress();
		}

		if(!data.auditServiceEndpoint){
			data.auditServiceEndpoint = api.getAuditServiceAddress();
		}

		return data;
	}

	public async getDevices(serverId: number): Promise<any> {
		const { data } = await this.axios.get(`/devices/GetByServerId?serverId=${serverId}`);
		return data;
	}

	public async deviceQuickControl(deviceID: number, Reason: string): Promise<DeviceDetails> {
		const { data } = await this.axios.post("/devices/QuickControl", {
			deviceID,
			Reason
		});
		return data;
	}

	public async deviceSearch(deviceTypeId: number, query: string, cancelToken: any): Promise<any> {
		return this.axios.get(`/devices/search/${deviceTypeId}`, {
			params: {
				search: query
			},
			cancelToken
		});
	}

	public async deviceSearchNear(params): Promise<any> {
		return this.axios.get("/Devices/SearchNear", {
			params
		});
	}

	public async getDevicesByIds(deviceIds: number[]): Promise<any> {
		const { data } = await this.axiosJson.post(`/Devices/GetDeviceInfoFromIds`, deviceIds);
		return data;
	}

	public async unlinkCamera({ objectId, deviceId }): Promise<any> {
		return await this.axios.get(`/Responses/Unlink/${objectId}/${deviceId}`);
	}

	public async createResponseActionForEventRecord({ eventRecordId, title, priority, groupId }): Promise<any> {
		return await this.axios.post(`/Responses/Link/${eventRecordId}/${title}/${priority}/${groupId}`);
	}

	public async EventPark(payload): Promise<any> {
		return this.axios.post("/Event/Park", payload);
	}

	public async raiseManualAlarm(payload): Promise<ManualRaiseResult> {
		return (await this.axiosJson.post("/Event", payload)).data;
	}

	public async raiseAndJoinManualAlarm(payload, templateId): Promise<ManualRaiseResult> {
		return (await this.axiosJson.post("/Event/ManualRaiseAndJoin", { ... payload, templateID: templateId })).data;
	}

	public async eventControl(eventObject): Promise<any> {
		// eventObject is the payload.
		// Post using axiosJsonInstance, with path/route and payload.
		return await this.axiosJson.post("/Event/Patrol", eventObject);
		// ^^ return data.
	}

	public async tourNotesRequired(): Promise<any> {
		// Calls Backend to see if the notes are required or not.
		return await this.axios.get(`/Event/Patrol/NoteRequired`);
	}

	public async disableAutomaticLoad(): Promise<any> {
		return await this.axios.get(`/Event/AlarmPoint/DisableAutomaticLoad`);
	}

	public async EventPassControl(eventid: number | string, userId: number): Promise<any> {
		return this.axios.get("/EventShare/PassControl/" + eventid, {
			params: {
				userId
			}
		});
	}

	public async leaveEvent(EventID: number): Promise<any> {
		return await this.axios.post(`/EventShare/LeaveEvent?eventId=${EventID}`);
	}

	public async changeEventRoutingGroup(data: any): Promise<any> {
		const response = await this.axios.post("/RoutingGroups/Raise", data);
		return response;
	}

	public async loadEventHandle(EventID: number): Promise<any> {
		const response = await this.axios.get("EventQueue/Handle", { EventID });
		return response;
	}
	public async loadEventHandleAllowInProcessing(eventId: number, allowInProcessing?: any): Promise<any> {
		const response = await this.axios.get(`EventQueue/Handle/${eventId}?AllowInProcessing=${allowInProcessing}`);
		return response;
	}

	public async loadResponseStates(): Promise<any> {
		const { data } = await this.axios.get("eventqueue/ResponseState");
		return data;
	}

	public async loadAlarmQueueFilters(): Promise<any> {
		return await this.axios.get("/AlarmQueueFilters");
	}

	public async addAlarmQueueFilter(alarmQueueFilterID: AlarmQueueFilterID): Promise<any> {
		const endpoint = `/AlarmQueueFilters/AddFilter/${alarmQueueFilterID.filterId}`;
		const regionId = alarmQueueFilterID.regionId > 0 ? alarmQueueFilterID.regionId : null;

		return await this.axios.get(endpoint, {
			params: { regionId }
		});
	}

	public async removeAlarmQueueFilter(alarmQueueFilterID: AlarmQueueFilterID): Promise<any> {
		const endpoint = `/AlarmQueueFilters/RemoveFilter/${alarmQueueFilterID.filterId}`;
		const regionId = alarmQueueFilterID.regionId > 0 ? alarmQueueFilterID.regionId : null;

		return await this.axios.get(endpoint, {
			params: { regionId }
		});
	}

	public async loadAvailableAlarmQueueFilters(): Promise<any> {
		return await this.axios.get("/AlarmQueueFilters/Available");
	}

	public async loadAlarmQueueFilterBounds(filterId: number): Promise<any> {
		return await this.axios.get(`/AlarmQueueFilters/Bounds/${filterId}`);
	}

	public async loadOutcomesForEvent(eventId: number): Promise<any> {
		const response = await this.axios.get(`/Outcomes/ForEvent/${eventId}`);
		return response;
	}

	public async loadAllOutcomes(): Promise<AxiosResponse<EventOutcomeResponse>> {
		return await this.axios.get(`/Outcomes/AllOutcomes/`);
	}

	public async loadAllOutcomesForAlarms(): Promise<AxiosResponse<EventOutcomeResponse>> {
		return await this.axios.get(`/Outcomes/ForAnyAlarm/`);
	}

	public async loadEventDetails(eventId: number): Promise<any> {
		const response = await this.axios.get(`/Event/Details/${eventId}`);
		return response;
	}

	public async loadRaisedCaseNumber(eventId: number): Promise<string> {
        return (await this.axios.get(`/Event/raisedCaseNumber/${eventId}`)).data;
    }

	public async retrieveAwaitingAckCountForEvent(eventId: number): Promise<number>
	{
		return (await this.axios.get(`/Event/AwaitingAckCount/${eventId}`)).data;
	}

	public async loadEventDetailsDirect(eventId: number): Promise<any> {
		try {
			const response = await this.axiosJsonDirectInstance.get(`/Event/Details/${eventId}`);
			return response;
		} catch(e) {
			console.error(e);
			if(e.response)
			{
				Vue.prototype.$notify({
					type: e.response.status == 404 ? "neutralError" : "error",
					title: "Error",
					text: e.response.data,
					ignoreDuplicates: true
				});
			}
			else {
				Vue.prototype.$notify({
					type: "error",
					title: "Error",
					text: "No response received",
					ignoreDuplicates: true
				});
			}
		}
	}

	public async loadEventRecordDetails(eventRecordId: number): Promise<EventRecordDetails> {
		const response = await this.axios.get(`/Event/EventRecordDetails/${eventRecordId}`);
		return response.data;
	}

	public async loadRoutingGroups(): Promise<any> {
		const response = await this.axios.get("/RoutingGroups");
		return response;
	}

	public async loadRoutingGroupsByEvent(eventId: number): Promise<any> {
		const response = await this.axios.get("/RoutingGroups/ByEvent/" + eventId);
		return response;
	}

	public async loadEventRecords(EventID: number, fromRecord: number): Promise<any> {
		const response = await this.axios.get("/EventRecords/" + EventID, {
			params: { fromRecord }
		});
		return response;
	}

	public async loadEventRecordsForMobile(EventID: number): Promise<any> {
		const response = await this.axios.get("/EventRecords/ForMobile/" + EventID);
		return response.data;
	}

	public async eventRecordAcknowledgement(EventRecordID: number): Promise<any> {
		const response = await this.axios.get(`/EventRecords/Acknowledge/${EventRecordID}`);
		return response;
	}

	public async eventRecordAllAcknowledgement(EventID: number): Promise<any> {
		const response = await this.axios.get(`/EventRecords/AcknowledgeAll/${EventID}`);
		return response;
	}

	public async eventShareRemove(eventID: number, uniqueId: any, isMobile: boolean = false) {
		return await this.axios.get(`/EventShare/RemoveShare/${eventID}`, {
			params: {
				uniqueId,
				isMobile
			}
		});
	}

	public async eventShareSearch(SearchTerm: string): Promise<any> {
		const response = await this.axios.get("/EventShare/Search", {
			params: {
				SearchTerm
			}
		});
		return response;
	}

	public async loadShareForEvent(eventId: number): Promise<any> {
		const { data } = await this.axios.get(`/EventShare/${eventId}`);
		return data;
	}

	public async loadHistoricalShareForEvent(eventId: number): Promise<any> {
		const { data } = await this.axios.get(`/EventShare/Historical/${eventId}`);
		return data;
	}

	public async loadExternalUserShareFlag(eventId: number): Promise<any> {
		const { data } = await this.axios.get(`/EventShare/ExternalShareAllowed/${eventId}`);
		return data;
	}

	public async shareEventWithAsset(eventId: number, assetId: number): Promise<void> {
		await this.axios.post(`/EventShare/Mobile/ShareEventToAsset/${eventId}/${assetId}`)
	}

	public async loadGroupSummary(): Promise<any> {
		const response = await this.axios.get("/eventqueue/GroupSummary");
		return response;
	}

	public async loadGroupPermissions(groupID: number): Promise<any> {
		const { data } = await this.axios.get("/groups/Permissions/" + groupID);
		return data;
	}

	public async loadGroupOnTestEvent(groupID: number): Promise<any> {
		const response = await this.axios.get("/OnTest/TestEvent/" + groupID);
		return response.status === 204 ? null : response.data;
	}

	public async loadResponseOnTestEvent(responseID: number): Promise<any> {
		const response = await this.axios.get("/OnTest/ResponseTestEvent/" + responseID);
		return response.status === 204 ? null : response.data;
	}

	public async loadGroupOnTestRecords(eventID: number, fromRecord: number): Promise<any> {
		const { data } = await this.axios.get("/OnTest/TestEventRecords/" + eventID, {
			params: {
				fromRecord
			}
		});
		return data;
	}

	public async startTest(payload: any): Promise<any> {
		const { data } = await this.axios.post("/OnTest/StartTest", payload);
		return data;
	}

	public async extendTest(payload: any): Promise<any> {
		const { data } = await this.axios.post("/OnTest/ExtendTest", payload);
		return data;
	}

	public async endTest(eventId: number): Promise<any> {
		await this.axios.get("/OnTest/EndTest/" + eventId);
	}

	// TASKS
	//
	public async loadTasks(): Promise<any> {
		return await this.axios.get("/All");
	}

	public async triggeredTasks(taskID: number): Promise<any> {
		return (await this.axios.get(`/Tasks/Triggers/${taskID}`)).data;
	}

	public async triggeredTasksSave(taskID: number, tasks: any[]): Promise<any> {
		await this.axiosJson.post(`/Tasks/Triggers/${taskID}`, tasks);
	}

	public async loadTaskTypes(): Promise<any> {
		return await this.axios.get("/Tasks/TaskTypes");
	}

	public async loadTasksForEvent(eventId: number): Promise<any> {
		return await this.axios.get(`/Tasks/${eventId}`);
	}

	public async fetchCallContacts(payload: any): Promise<any> {
		return await this.axios.post("/Tasks/CallList", payload);
	}

	public async searchTasks(searchPhrase: string): Promise<any> {
		return await this.axios.get(`/TaskSearch?search=${searchPhrase}`);
	}

	// save a task to task library - to a general "library" of tasks
	public async createTask(taskData: any): Promise<any> {
		const payload = {
			TaskTypeID: taskData.taskTypeID,
			TaskCategoryID: taskData.taskCategoryID,
			TaskData: JSON.stringify(taskData.taskData),
			TaskText: taskData.taskText
		};
		if (taskData.title) {
			payload["TaskText"] = taskData.title;
		}
		return await this.axios.post("/CreateTask", payload);
	}

	public async deleteTask(taskData: any) {
		return await this.axios.post("/DeleteTask", taskData);
	}

	// save a task to task library - to a general "library" of tasks
	public async updateTask(taskData: any): Promise<any> {
		const payload = {
			taskID: taskData.taskID,
			taskTypeID: taskData.taskTypeID,
			taskCategoryID: taskData.taskCategoryID,
			taskText: taskData.taskText,
			taskData: taskData.taskData,
			groupID: taskData.groupID
		};

		return await this.axiosJson.post("/UpdateTask", payload);
	}

	// save an event-specific task
	public async saveEventTask(task: any): Promise<any> {
		return await this.axiosJson.post("/Tasks", task);
	}

	public async completeTask(payload: CompleteTaskPayload): Promise<any> {
		return await this.axios.post("/Tasks/Complete", payload);
	}

	public async assignTask(payload: any): Promise<any> {
		return await this.axios.post("/Tasks/Assign", payload);
	}

	public async assignAllTask(payload: any): Promise<any> {
		return await this.axios.post("/Tasks/AssignAll", payload);
	}

	public async assignAllTasks(payload: any): Promise<any> {
		return await this.axios.post("/Tasks/AssignAll", payload);
	}

	public async createTaskCategory(payload: any): Promise<any> {
		return await this.axios.post("/CreateTaskCategory", payload);
	}

	public async updateTaskCategory(payload: any): Promise<any> {
		return await this.axios.post("/UpdateTaskCategory", payload);
	}

	public async deleteTaskCategory(payload: any): Promise<any> {
		return await this.axios.post("/DeleteTaskCategory", payload);
	}

	// "Action Plans" (i.e. "Scripts")
	//
	public async loadActionPlans(): Promise<Script[]> {
		const { data } = await this.axios.get("/Scripts");
		return data;
	}

	public async createActionPlan(payload: any): Promise<any> {
		return await this.axios.post("/CreateScript", payload);
	}

	public async deleteActionPlan(payload: any): Promise<any> {
		return await this.axios.post("/DeleteScript", payload);
	}

	public async updateActionPlan(payload: any): Promise<any> {
		return await this.axios.post("/UpdateScript", payload);
	}

	public async updateActionOrderInPlan(payload: any): Promise<any> {
		return await this.axios.post("/UpdateTaskOrderInScript", payload);
	}

	public async getScriptCategoryLinks(): Promise<ScriptCategoryLink[]> {
		const { data } = await this.axios.get("/Tasks/GetScriptCategoryLinks");
		return data;
	}

	public async updateCategoryOrderInScript(categoryReorderRequest: CategoryReorderRequest): Promise<ScriptCategoryLink[]> {
		const { data } = await this.axiosJson.post("/Tasks/UpdateCategoryOrderInScript", categoryReorderRequest)
		return data
	}

	// load Task Library
	public async loadActionPlanActions(scriptId: number): Promise<any> {
		return await this.axios.get(`/ScriptTasks/${scriptId}`);
	}

	public async addActionToPlan(payload: any): Promise<any> {
		return await this.axios.post("/AddTaskToScript", payload);
	}

	public async updateActionForPlan(payload: any): Promise<any> {
		return await this.axios.post("/UpdateTaskForScript", payload);
	}

	public async removeActionFromPlan(payload: any): Promise<any> {
		return await this.axios.post("/RemoveTaskFromScript", payload);
	}

	public async loadTaskCategories(): Promise<any> {
		return await this.axios.get("/TaskCategories");
	}
	//
	// END "Action Plans" (i.e. "Scripts")

	public async loadTasksByCategory(taskCategoryID: number): Promise<any> {
		return await this.axios.get(`/TasksByCategory/${taskCategoryID}`);
	}

	// DispatchSetup
	public async loadGuards(): Promise<any> {
		const { data } = await this.axios.get("/DispatchSetup/AllGuards");
		return data;
	}

	// Used in dispatch - loads all the guard types from the database
	public async loadGuardTypes(): Promise<any> {
		const { data } = await this.axios.get("/DispatchSetup/AllGuardTypes");
		return data;
	}

	public async loadGuardsForFilter(alarmQueueFilterID: number): Promise<any> {
		const { data } = this.axios.get(`/DispatchSetup/GuardsForFilter/${alarmQueueFilterID}`);
		return data;
	}

	public async addNewGuard(guard: any): Promise<any> {
		const { data } = await this.axios.post("/DispatchSetup/AddGuard", guard);
		return data;
	}

	public async assignGuardToZone(data: any): Promise<any> {
		return await this.axios.post("/DispatchSetup/PatrolZones/AssignGuard", data);
	}

	public async removeGuardFromZone(data: any): Promise<any> {
		return await this.axios.post("/DispatchSetup/PatrolZones/RemoveGuard", data);
	}

	public async updateGuard(data: any): Promise<any> {
		return await this.axios.post("/DispatchSetup/UpdateGuard", data);
	}

	public async deleteGuard(dispatchGuardID: number): Promise<any> {
		return await this.axios.get(`/DispatchSetup/DeleteGuard/${dispatchGuardID}`);
	}

	// Dispatch
	public async loadGuardsForEvent(eventId: number, location: string): Promise<any> {
		const { data } = await this.axios.get(`/Dispatch/ForLocation/${eventId}`, {
			params: {
				location
			}
		});
		return data;
	}

	public async loadDispatchedGuardsForEvent(eventId: number): Promise<any> {
		return await this.axios.get(`/Dispatch/DispatchStatus/${eventId}`);
	}

	public async dispatchGuard(dispatchGuardRequest: { guardID; eventID; status; location; }): Promise<any> {
		return await this.axios.post("/Dispatch", dispatchGuardRequest);
	}

	// Gets the list of groups the current user can access
	public async loadUserGroups(): Promise<any> {
		const response = await this.axios.get("/Groups");
		return response.data;
	}

	// Gets a group by ID
	public async loadGroup(groupID: number): Promise<any> {
		const response = await this.axios.get(`/Groups/${groupID}`);
		return response.data;
	}

	public async loadUserZone(): Promise<any> {
		const response = await this.axios.get("/Groups/UsersGroup");
		return response.data;
	}

	// Gets the tree of groups a user can access
	public async loadGroupsTree(multiTenant?: boolean): Promise<any> {
		if (multiTenant) {
			const response = await this.axios.get("/Groups/Tree?multiTenant=true");
			return response.data;
		}
		const response = await this.axios.get("/Groups/Tree");
		return response.data;
	}

	public async getRootGroups(requiredPermissions: string[], pageNumber: number, pageSize: number): Promise<GroupRowPagedResponse> {
		let permissionsAsQueryString = arrayToQueryString("requiredPermissions", requiredPermissions);
		const response = await this.axios.get(`groups/rootGroups?${permissionsAsQueryString}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
		return response.data;
	}

	public async getChildGroups(paginatedSearchRequest: GetChildGroupsListRequest): Promise<GroupRowPagedResponse> {
		let permissionsAsQueryString = arrayToQueryString("requiredPermissions", paginatedSearchRequest.requiredPermissions);
		const response = await this.axios.get(`groups/childGroups?groupId=${paginatedSearchRequest.groupId}&`
			+ `${permissionsAsQueryString}` +
			`&page=${paginatedSearchRequest.page}` +
			`&pageSize=${paginatedSearchRequest.pageSize}` +
			`&searchTerm=${paginatedSearchRequest.searchTerm}` +
			`&sortBy=${paginatedSearchRequest.sortBy}` +
			`&sortDesc=${paginatedSearchRequest.sortDesc}`);
		return response.data;
	}

	public async getGroupTreeNode(groupId: number, requiredPermissions: string[]): Promise<AreaNode> {
		const response = await this.axios.get(`/groups/treeNode/${groupId}?requiredPermissions=${requiredPermissions}`);
		return response.data;
	}

	public async getGroupTreeNodes(groupIds: number[]): Promise<AreaNode []> {
		let nodesAsQueryString = arrayToQueryString("groupIds", groupIds);
		const response = await this.axios.get(`/groups/treeNodes?${nodesAsQueryString}`);
		return response.data;
	}

	public async searchGroupTree(query: string, requiredPermissions: string[]): Promise<AreaNode> {
		const response = await this.axios.post("/groups/tree/search", { query, requiredPermissions });
		return response.data;
	}

	public async getFullGroupsTree(requiredPermissions: string[]): Promise<AreaNode[]> {
		const response = await this.axios.get(`/groups/fullGroupTree?requiredPermissions=${requiredPermissions}`);
		return response.data;
	}

	public async loadGroupsList(): Promise<any> {
		const response = await this.axios.get("/Groups");
		return response.data;
	}

	public async subgroups(parentGroupId: number): Promise<number[]> {
		const response = await this.axiosJson.get("/groups/subgroups", {
			params: {
				parentGroupId
			}
		});
		return response.data;
	}

	public async groupsToRoot(groupId: number): Promise<any> {
		const response = await this.axiosJson.get("/groups/toroot", {
			params: {
				groupId
			}
		});
		return response.data;
	}

	public async searchGroup(SearchTerm: string): Promise<any> {
		const response = await this.axios.get("/Groups/Search", {
			params: {
				SearchTerm
			}
		});
		return response.data;
	}

	public async searchGroupFull(cancelToken: any, SearchTerm: string): Promise<any> {
		const response = await this.axios.get("/Groups/SearchFull", {
			params: {
				SearchTerm
			},
			cancelToken
		});
		return response.data;
	}

	public async fetchAreaEventHistory(requestParams: AreaEventHistoryPaginatedSearchParams): Promise<AreaEventHistoryWithPagination> {// Promise<AreaEventHistory[]>  {
		let eventTypeIdsAsQueryString = arrayToQueryString("eventHistoryTypeIds", requestParams.eventHistoryTypeIds);
		let responseIdsAsQueryString = arrayToQueryString("responseIds", requestParams.responseIds);
		const { data } = await this.axios.get("/EventHistory?" +
			`fromDate=${requestParams.fromDate.toISOString()}&
			toDate=${requestParams.toDate.toISOString()}&
			groupId=${requestParams.groupId}&
			${eventTypeIdsAsQueryString}&
			includeAutohandled=${requestParams.includeAutohandled}&
			${responseIdsAsQueryString}&
			page=${requestParams.page}&
			pageSize=${requestParams.pageSize}&
			sortBy=${requestParams.sortBy}&
			sortDesc=${requestParams.sortDesc ?? true}`
		);

		return data;
	}

	public async loadPatrolZones(): Promise<any> {
		const { data } = await this.axios.get("/DispatchSetup/PatrolZones");
		return data;
	}

	public async addPatrolZone(zone: any): Promise<any> {
		const { data } = await this.axios.post("/DispatchSetup/PatrolZones/Add", zone);
		return data;
	}

	public async updatePatrolZone(zone: any): Promise<any> {
		const { data } = await this.axios.post("/DispatchSetup/PatrolZones/Update", zone);
		return data;
	}

	public async deletePatrolZone(PatrolZoneID: number): Promise<any> {
		await this.axios.get(`/DispatchSetup/PatrolZones/Delete/${PatrolZoneID}`);
	}

	// Mobile Dispatch Control in Site Monitor
	public async loadMobileGuardsForEvent(eventId: number, location: string): Promise<any> {
		const response = await this.axiosJson.get(`/Dispatch/MobileGuardsForEvent`, {
			params: {
				eventId,
				location
			}
		});
		return response && response.data ? response.data : null;
	}

	// Mobile Dispatch
	public async extendGuardSession(): Promise<any> {
		await this.axiosJson.post("/DispatchGuard/Session/Extend");
	}

	public async registerUserDevice(deviceID: string, deviceTypeID: number): Promise<any> {
		const userDevicePayload = {
			DeviceID: deviceID,
			DeviceType: deviceTypeID
		};

		await this.axiosJson.post("/DispatchGuard/Device/Register", userDevicePayload);
	}

	public async mobileLogout(guardID: number, deviceID: string, deviceTypeID: number): Promise<any> {
		const logoutPayload = {
			GuardID: guardID,
			UserDevice: {
				DeviceID: deviceID,
				DeviceType: deviceTypeID
			}
		};

		await this.axiosJson.post("/DispatchGuard/Session/Logout", logoutPayload);
	}

	public async fetchAuditEndpoint(): Promise<any> {
		const response = await this.axiosJson.get(`/DispatchGuard/FetchAuditEndpoint`);
		return response && response.data ? response.data : null;
	}

	public async addEventRecordForGuard(
		eventId: number,
		details: string,
		objectId: number,
		eventRecordTypeId: number
	): Promise<any> {
		const eventRecordPayload = {
			EventID: eventId,
			Details: details,
			ObjectID: objectId,
			EventRecordTypeID: eventRecordTypeId
		};

		const response = await this.axiosJson.post(`/DispatchGuard/EventRecord/Add`, eventRecordPayload);
		return response && response.data ? response.data : null;
	}

	public async loadCallSigns(): Promise<any> {
		const response = await this.axios.get("/DispatchGuard/CallSign/LoadAll");
		return response && response.data ? response.data : null;
	}

	public async setGuardForUser(guardId: number): Promise<any> {
		const response = await this.axiosJson.post(`/DispatchGuard/Session/SetGuardForUser`, guardId);
		return response && response.data ? response.data : null;
	}

	public async loadGuardForUser(): Promise<any> {
		const response = await this.axiosJson.get(`/DispatchGuard/Session/GetGuardForUser`);
		return response && response.data ? response.data : null;
	}

	public async loadGuardStatus(guardId: number): Promise<any> {
		const response = await this.axiosJson.get(`/DispatchGuard/Status/`, {
			params: {
				guardId
			}
		});
		return response && response.data ? response.data : null;
	}

	public async loadUnavailableStatuses(): Promise<any> {
		const response = await this.axiosJson.get(`/DispatchGuard/UnavailableStatus/LoadAll`);
		return response && response.data ? response.data : null;
	}

	public async setUnavailableStatus(
		guardId: number,
		unavailableStatusId: number,
		unavailableReason: string
	): Promise<any> {
		const unavailableStatusPayload = {
			GuardId: guardId,
			UnavailableStatusId: unavailableStatusId,
			UnavailableReason: unavailableReason
		};

		await this.axiosJson.post(`/DispatchGuard/UnavailableStatus/Set`, unavailableStatusPayload);
	}

	public async loadDispatchRequestsForGuard(GuardID: number): Promise<any> {
		const response = await this.axiosJson.get(`/DispatchGuard/DispatchRequest/LoadAll`, {
			params: {
				GuardID
			}
		});
		return response && response.data ? response.data : null;
	}

	public async claimDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
		const claimPayload = {
			DispatchQueueId: dispatchQueueId,
			GuardId: guardId
		};

		await this.axiosJson.post(`/DispatchGuard/DispatchRequest/Claim`, claimPayload);
	}

	public async unclaimDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
		const unclaimPayload = {
			DispatchQueueId: dispatchQueueId,
			GuardId: guardId
		};

		await this.axiosJson.post(`/DispatchGuard/DispatchRequest/Unclaim`, unclaimPayload);
	}

	public async activateDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
		const activatePayload = {
			DispatchQueueId: dispatchQueueId,
			GuardId: guardId
		};

		await this.axiosJson.post(`/DispatchGuard/DispatchRequest/Activate`, activatePayload);
	}

	public async inactivateDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
		const inactivatePayload = {
			DispatchQueueId: dispatchQueueId,
			GuardId: guardId
		};

		await this.axiosJson.post(`/DispatchGuard/DispatchRequest/Inactivate`, inactivatePayload);
	}

	public async guardArrived(dispatchQueueId: number, guardId: number): Promise<any> {
		const statusPayload = {
			DispatchQueueId: dispatchQueueId,
			GuardId: guardId
		};

		await this.axiosJson.post(`/DispatchGuard/Arrived`, statusPayload);
	}

	public async guardAvailable(guardId: number): Promise<any> {
		await this.axiosJson.post(`/DispatchGuard/Available`, guardId);
	}

	public async addDispatchRequest(
		preferredGuardId: number,
		patrolZoneId: number,
		eventId: number,
		requester: string,
		requesterDescription: string,
		location: string,
		siteTitle: string
	): Promise<any> {
		const dispatchRequestPayload = {
			PreferredGuardID: preferredGuardId,
			PatrolZoneID: patrolZoneId,
			EventID: eventId,
			Requester: requester,
			RequesterDescription: requesterDescription,
			Location: location,
			SiteTitle: siteTitle
		};

		return await this.axiosJson.post("/DispatchGuard/DispatchRequest/Add", dispatchRequestPayload);
	}

	public async finishDispatchRequest(dispatchQueueId: number, guardId: number): Promise<any> {
		const finishPayload = {
			DispatchQueueId: dispatchQueueId,
			GuardId: guardId
		};
		await this.axiosJson.post(`/DispatchGuard/DispatchRequest/Finish`, finishPayload);
	}

	public async loadNotesForEvent(eventId: number): Promise<any> {
		const response = await this.axiosJson.get(`/DispatchGuard/Notes/ForEvent`, {
			params: {
				eventId
			}
		});
		return response && response.data ? response.data : null;
	}

	/* public async eventSearch(filters: any): Promise<any> {
	const { data } = await this.axios.post('/eventSearch', filters);
	return data;
  } */

	public async eventSearch(filters: any): Promise<any> {
		const { data } = await this.axiosJson.post("/EventReports/Search", filters);
		return data;
	}

	public async eventTags(): Promise<Tag[]> {
		return (await this.axios.get("/Event/Tags")).data;
	}

	public async loadEventNotes(eventId: number): Promise<any> {
		const { data } = await this.axios.get(`/Notes/ForEvent/${eventId}`);
		return data;
	}

	public async matrixGet(eventId): Promise<Matrix> {
		return (await this.axios.get("/Matrix/" + eventId)).data;
	}

	public async matrixSave(payload: any): Promise<any> {
		return this.axios.post("/Matrix", payload);
	}

	public async loadSearchableEventTypes(): Promise<any> {
		const { data } = await this.axios.get("/EventReports/SearchableEventTypes");
		return data;
	}

	public async loadAvailableEventCategories(): Promise<any> {
		const { data } = await this.axios.get("/EventReports/Categories");
		return data;
	}

	public async loadAvailableEventOperators(): Promise<any> {
		const { data } = await this.axios.get("/EventReports/Operators");
		return data;
	}

	public async loadAlarmTypes(serverTypeID: number): Promise<any> {
		const { data } = await this.axios.get(`/EventReports/EventTypes/${serverTypeID}`);
		return data;
	}

	public async loadAreaNotesForEvent(eventId: number): Promise<any> {
		const { data } = await this.axios.get(`/notes/ForEvent/${eventId}`);
		return data;
	}

	public async loadAreaNotesForGroup(groupId: number): Promise<any> {
		const { data } = await this.axios.get(`/Notes/ForGroup/${groupId}`);
		return data;
	}

	public async addAreaNote(note: any): Promise<any> {
		return await this.axios.post("/notes/Add", note);
	}

	public async editAreaNote(note: any): Promise<any> {
		return await this.axios.post("/notes/Edit", note);
	}

	public async deleteAreaNote(noteId: number): Promise<any> {
		const { data } = await this.axios.delete("/notes/Delete", {
			params: {
				noteId
			}
		});
		return data;
	}

	public async updateMatrixCell(data: any): Promise<any> {
		return await this.axios.post("/Matrix/Cell", data);
	}

	public async sessionGet(resourceId: number, eventId: number, baseUrl: string) {
		const sessionRequestConfig: AxiosRequestConfig = {
			params: {
				eventId
			}
		};

		if (baseUrl !== null && baseUrl !== undefined) {
			sessionRequestConfig.baseURL = baseUrl;
		}

		return await axiosInstance.get("/session/" + resourceId, sessionRequestConfig);
	}

	public async saveMatrix(data: any): Promise<any> {
		return await this.axios.post("/Matrix", data);
	}

	public async fetchMatrixForEvent(eventID: number): Promise<any> {
		return await this.axios.get(`/Matrix/${eventID}`);
	}

	public async serverLogout(): Promise<any> {
		return await this.axios.get("/Login/Logout");
	}

	public async loadAlarmTags(): Promise<any> {
		return await this.axios.get("/AlarmQueueFilters/AlarmTags");
	}

	public async createAlarmQueueFilter(data: any): Promise<any> {
		return await this.axiosJson.post("/AlarmQueueFilters/CreateFilter", data);
	}

	public async editAlarmQueueFilter(data: any): Promise<any> {
		return await this.axiosJson.post("/AlarmQueueFilters/EditFilter", data);
	}

	public async loadNotificationTemplate(payload: any): Promise<any> {
		return await this.axios.post("/Notification/Template", payload);
	}

	public async loadTemplateDetails(templateId: number): Promise<any> {
		return await this.axios.get(`/Notification/Template/Details/${templateId}`);
	}

	public async loadEventShareInvites(): Promise<any> {
		return await this.axios.get("EventShare/Invites");
	}

	public async acceptEventShareInvite(eventUserID: number): Promise<any> {
		return await this.axios.get(`EventShare/Accept/${eventUserID}`);
	}

	public async ignoreEventShareInvite(eventUserID: number): Promise<any> {
		return await this.axios.get(`EventShare/Ignore/${eventUserID}`);
	}

	public async addAdhocEventShare(payload: any): Promise<any> {
		return await this.axios.post("/EventShare/InviteAdhoc", payload);
	}

	public async shareEventWithUser(payload: any): Promise<any> {
		return await this.axios.post("/EventShare/InviteUser", payload);
	}

	public async shareEventWithGroup(payload: any): Promise<any> {
		return await this.axios.post("/EventShare/InviteGroup", payload);
	}

	public async deleteAlarmQueueFilter(filterId: number): Promise<any> {
		return await this.axios.get(`/AlarmQueueFilters/DeleteFilter/${filterId}`);
	}

	public async loadServerEventTypes(
		serverType: number,
		serverTypeGroupID: number | null,
		pageSize?: number | null,
		filter?: string | null
	): Promise<any> {
		return await this.axios.get(`/servers/EventTypes/${serverType}`, {
			params: {
				groupID: serverTypeGroupID,
				pageSize,
				filter,
			}
		});
	}

	public async searchGroups(params): Promise<any> {
		return await this.axios.get("/groups/search", {
			params
		});
	}

	public async loadMapLayersByGroupId(groupId: number): Promise<MapLayer[]> {
		return (await this.axios.get(`/MapLayers/ByGroup/${groupId}`)).data;
	}

	public async loadOrCreateMapLayersByGroupID(groupId: number, location: string = ""): Promise<any> {
		return await this.axios.get(`/MapLayers/ByGroup/GetOrCreate`,{
			params:{
				GroupID : groupId,
				Location: location
			}
		});
	}

	public async getMapLayerById(mapLayerId: number): Promise<MapLayer> {
		return (await this.axios.get(`/MapLayers/${mapLayerId}`)).data;
	}

	public async loadMapLayersItemsByMapLayerId(layerId: number): Promise<MapLayerItem[]> {
		return (await this.axios.get(`/MapLayers/MapItems/ByLayer/${layerId}`)).data;
	}

	public async getMapLayerItemsByBounds(bounds: MapBounds): Promise<MapLayerItem> {
		return (await this.axios.post("/MapLayers/MapItems", bounds)).data;
	}

	public async responseGet(responseId: number): Promise<any> {
		return (await this.axios.get(`/Responses/${responseId}`)).data;
	}

	public async responsesByArea(groupId: number): Promise<any> {
		return (await this.axios.get("/Responses/ByArea/" + groupId)).data;
	}

	public async getPagedResponsesByArea(groupId: number, paginatedSearchRequest?: PaginatedSearchRequest): Promise<ResponseDtoPagedResponse> {
		return (await this.axiosJson.get(`/Responses/PagedByArea/${groupId}`, paginatedSearchRequest)).data;
	}

	public async responsesDisarmed(): Promise<any> {
		return (await this.axios.get("/Responses/Disarmed/")).data;
	}

	public async responseSearch(cancelToken: any, searchTerm: string): Promise<any> {
		return (
			await this.axios.get("/Responses/Search", {
				params: {
					search: searchTerm
				},
				cancelToken
			})
		).data;
	}

	public async fetchResponses(
		page: number,
		pageSize: number,
		serverId: number | null,
		searchTerm: string,
		cancelToken?: CancelToken
	): Promise<any> {
		return await this.axiosJsonDirectInstance.get(`/Responses/GetResponses/${page}/${pageSize}`, {
			params: {
				serverId,
				searchTerm
			},
			cancelToken
		});
	}

	public async responseHistory(responseId: number): Promise<any> {
		return (await this.axios.get("/EventRecords/AlarmPointHistory/" + responseId)).data;
	}

	public async accessControlHistory(responseId: number): Promise<any> {
		return (
			await this.axiosJsonDirectInstance.get("/AccessControl/History/Latest", {
				params: {
					responseId
				}
			})
		).data;
	}

	public async fetchServers(groupId: number, pageSize?: number, filter?: string): Promise<any> {
		return await this.axios.get(`/servers?groupId=${groupId}`, {
			params: {
				pageSize,
				filter
			}
		});
	}

	public async getServers(paginatedSearchRequest?: PaginatedSearchRequest): Promise<ServerDetailsPagedResponse> {
		const { data } = await this.axios.get(`/servers/GetServerList`, paginatedSearchRequest);
		return data;
	}

	public async getServersWithEnabledDeviceTypes(
		params: ServersWithEnabledDeviceTypesParams
	): Promise<AxiosResponse<ServerDetails[]>> {
		return await this.axios.get(`/servers/ByDeviceTypesEnabled`, { params });
	}

	public async getContactsForRole(contactRoleId: number, groupId: number): Promise<UserContactDetails[]> {
		const { data } = await this.axios.get(`/users/ContactsForRole/${contactRoleId}/${groupId}`);
		return data;
	}

	public async getContactRoles(paginatedSearchRequest: PaginatedSearchRequest): Promise<AxiosResponse<ContactRole[]>> {
        return await this.axios.get(`/users/ContactRolesPaginated`, paginatedSearchRequest);
    }

	public async getServer(serverId: number): Promise<AxiosResponse<ServerDetails>> {
		return await this.axios.get(`/servers/${serverId}`);
	}

	public async fetchServersByGroup(payload: any): Promise<any> {
		return await this.axios.get(`/servers/ServersByGroup/${payload.groupId}/${payload.page}/${payload.pageSize}`);
	}

	public async fetchServerLineProfiles(): Promise<AxiosResponse<ServerLineProfile[]>> {
		return await this.axios.get("/servers/LineProfiles");
	}

	public async fetchNotificationServers(): Promise<any> {
		return await this.axios.get("/servers/Notification");
	}

	public async fetchNotificationTemplates(serverId: number): Promise<any> {
		return await this.axios.get(`/Notification/Template/${serverId}`);
	}

	public async getMatrixDeviceDetails(deviceIds: number[], eventId: number, latLong: string) {
		const { data } = await this.axios.post("/DeviceDetails", {
			deviceIds,
			eventId,
			latLong
		});

		return data;
	}

	public async loadAvailableReports(apiUrl: string): Promise<any> {
		return await this.axios.get("/Reports/AvailableReports", {
			baseURL: apiUrl
		});
	}

	public async runReport(apiUrl: string, url: string, payload: any): Promise<any> {
		return await this.axiosJsonDirectInstance.post(`/Reports/GetReport${url}`, payload, {
			baseURL: apiUrl
		});
	}

	public async DownloadReport(apiUrl: string, url: string, payload: any): Promise<any> {
		return await this.axiosJson.post(`/Reports/DownloadReport${url}`, payload, {
			baseURL: apiUrl
		});
	}

	public async getReportServiceEndpoint(): Promise<any> {
		return await this.axios.get("/Reports/GetReportsDataServiceEndpoint");
	}

	public async loadReportTypes(url: string): Promise<any> {
		return await this.axios.get("/Reports/GetReportTypes", {
			baseURL: url
		});
	}

	public async heartbeatToCurrentServer(): Promise<any> {
		return await this.axios.get("/session/HeartbeatLoginSession");
	}

	public async heartbeatToServer(apiUrl: string): Promise<any> {
		return await this.axios.get("/session/HeartbeatLoginSession", {
			baseURL: apiUrl
		});
	}

	public async getUsers(paginatedSearchRequest: PaginatedSearchRequest): Promise<UsersPagedResponse> {
		const { data } = await this.axios.get(`/users/`, paginatedSearchRequest);
		return data;
	}

	public async getContacts(paginatedSearchRequest: PaginatedSearchRequest): Promise<ContactsPagedResponse> {
		const { data } = await this.axios.get(`/users/Contacts`, paginatedSearchRequest);
		return data;
	}

	public async getContactsForGroup(groupId: number): Promise<ShortContact[]> {
		const { data } = await this.axios.get(`/users/Contacts/ForGroup/${groupId}`);
		return data;
	}

	public async setMfa(credentials: any): Promise<any> {
		if (credentials.username && credentials.password) {
			return await this.axios.post("/users/SetupMFA", credentials);
		}

		return await this.axios.post("/users/SetMFA", {
			AuthCode: credentials.authCode,
			Token: credentials.token
		});
	}

	public async clearMfa(authCode: string): Promise<void>{
		const { data } = await this.axios.post(`/users/ClearMFA`, {
			authCode
		});
		return data;
	}

	public async getUserById(userId: number): Promise<any> {
		const { data } = await this.axios.get(`/users/GetUser/${userId}`);
		return data;
	}

	public async getContactRole(userId: number): Promise<any> {
		const { data } = await this.axios.get(`/users/${userId}/ContactRole`);
		return data;
	}

	public async getCurrentUserMobileStateId(userId: number): Promise<number> {
		const { data } = await this.axios.get(`/users/GetUser/${userId}`);
		return data.mobileStatusID;
	}

	public async getAvailableStatuses(): Promise<any> {
		const { data } = await this.axios.get(`/users/MobileStatuses`);
		return data;
	}

	public async setMobileUserState(mobileStatusID: string): Promise<any> {
		const { data } = await this.axiosJson.put(`/users/SetMobileStatus`, {
			mobileStatusID
		});
		return data;
	}

	public async toggleCurrentMobileUserState(toggle: boolean): Promise<void> {
		await this.axios.put(`/users/ToggleMobileUserState/${toggle}`);
	}

	public async setUserMapDisplayName(title: string): Promise<any> {
		const { data } = await this.axios.post(`Assets/MyAsset/UpdateDisplayName/${title}`);
		return data;
	}

	public async getContactRoleGroups(userId: number): Promise<any> {
		const { data } = await this.axios.get(`/users/ContactRoleGroups/${userId}`);
		return data;
	}

	public async updateContactRole(updatedContactRole: any): Promise<any> {
		const { data } = await this.axios.put("/users/ContactRole", updatedContactRole);
		return data;
	}

	public async deleteContactRole(contactRoleId: any): Promise<any> {
		return await this.axios.delete(`/users/ContactRole/${contactRoleId}`);
	}

	public async createContactRole(newContactRole: any): Promise<any> {
		const { data } = await this.axios.post("/users/ContactRole", newContactRole);
		return data;
	}

	public async loadContactRoleList(): Promise<any> {
		const { data } = await this.axios.get("/users/ContactRoles");
		return data;
	}

	public async updateUser(user: UserDTO): Promise<any> {
		return await this.axiosJson.post("/users/UpdateUser", user);
	}

	public async updatePersonalInfo(personalInfo: PersonalInfo): Promise<void> {
		return await this.axiosJson.put("/users/UpdatePersonalInfo", personalInfo);
	}

	public async updateRole(params): Promise<any> {
		return await this.axiosJson.post("/users/UpdateRole", params);
	}

	public async deleteUser(userId: number): Promise<any> {
		return await this.axios.delete(`/users/DeleteUser/${userId}`);
	}

	public async createUser(newUser: any): Promise<any> {
		return await this.axios.post("/users/CreateUser", newUser);
	}

	public async createOrUpdateContactUser(newUser: UserContactDetails): Promise<any> {
		return await this.axiosJson.post("/users/CreateOrUpdateContactUser", {
			userID: newUser.userID,
			FullName: newUser.fullName,
			GroupID: newUser.groupID,
			Email: newUser.email,
			Telephone: newUser.telephone,
			Mobile: newUser.mobile
		});
	}

	public async getUserRoles(): Promise<UserRole[]> {
		return (await this.axios.get(`/UserSetup/UserRoles`)).data;
	}

	public async saveUserRole(userRole: UserRole): Promise<number> {
		return (await this.axiosJson.put(`/UserSetup/UserRole`, userRole)).data;
	}

	public async deleteUserRole(userRoleId: number): Promise<void> {
		await this.axiosJson.delete(`/UserSetup/UserRole/Delete/${userRoleId}`);
	}

	public async getUserGroups(): Promise<UserGroup[]> {
		return (await this.axios.get(`/UserSetup/UserGroups`)).data;
	}

	public async saveUserGroup(userGroup: UserGroup): Promise<number> {
		return await this.axiosJson.put(`/UserSetup/UserGroup`, userGroup);
	}

	public async deleteUserGroup(userGroupId: number): Promise<void> {
		await this.axiosJson.delete(`/UserSetup/UserGroup/Delete/${userGroupId}`);
	}

	public async getUserGroup(): Promise<any> {
		const { data } = await this.axios.get("/users/GetUserGroup");
		return data;
	}

	public async getUserGroupDictionary(): Promise<Map<number, string>> {
		const { data } = await this.axios.get("/UserSetup/UserGroupDictionary");
		return data;
	}

	public async getUserRoleDictionary(): Promise<Map<number, string>> {
		const { data } = await this.axios.get("/UserSetup/UserRoleDictionary");
		return data;
	}

	public async permissionsGet(): Promise<any> {
		return (await this.axios.get("/UserSetup/Permissions")).data;
	}

	public async getPermissionsForUserGroups(userGroupIds: number[]): Promise<any[]> {
		const { data } = await this.axiosJson.post("/UserSetup/PermissionsForUserGroups", userGroupIds);
		return data;
	}

	public async getMapItemTypes(): Promise<MapLayerItemType[]> {
        const { data } = await this.axiosJson.get("/MapLayers/MapItemTypes");
        return data;
	}

	public async loadAllFeatures(tenantId: number): Promise<any> {
		const { data } = await this.axios.get("/Features?featureGroup=" + tenantId);
		return data;
	}

	public async toggleFeatures(features: any[], tenantId: number): Promise<any> {
		const { data } = await this.axiosJson.put("/Features?featureGroup=" + tenantId, features);
		return data;
	}

	public async loadJsonConfig(): Promise<any> {
		const { data } = this.axios.get("/Features/Config");
		return data;
	}

	public async refreshGroupCache(): Promise<void> {
		await this.axios.get("/groups/UpdateBulkAreaUploadState");
	}

	public async bulkAddAreas(areas: any): Promise<any> {
		const { data } = await this.axiosJson.post("/groups/BulkAddGroups", areas);
		return data;
	}

	public async addArea(area: any): Promise<any> {
		const { data } = await this.axiosJson.post("/groups/AddGroup", area);
		return data;
	}

	public async updateArea(area: any): Promise<any> {
		return await this.axiosJson.post("/groups/UpdateGroup", area);
	}

	public async getArea(areaId: number, liveData: boolean = false): Promise<any> {
		const { data } = await this.axios.get(`/groups/${areaId}?liveData=${liveData}`);
		return data;
	}

	public async getAreaList(): Promise<any> {
		const { data } = await this.axios.get(`/groups/GetGroups`);
		return data;
	}

	public async getFlatAreaList(params: FlatAreaListRequest): Promise<FlatAreaList[]> {
		const { data } = await this.axios.get("/groups/FlatGroupsList", { params });
		return data;
	}

	public async getAreaDictionary(): Promise<Map<number, string>> {
		const { data } = await this.axios.get(`/groups/dictionary`);
		return data;
	}

	public async deleteArea(areaId: number): Promise<any> {
		return await this.axios.get(`/groups/DeleteGroup/${areaId}`);
	}

	public async getReportTemplates(reportKey: string): Promise<any> {
		return await this.axios.get("/Reports/Templates/" + reportKey);
	}

	public async getAvailableReportTemplatesForArea(areaId: number): Promise<any> {
		return await this.axios.get(`/Reports/AvailableReportTemplatesForArea?AreaId=${areaId}`);
	}

	public async getActivityLog(queryStr): Promise<any> {
		let url = "/ActivityLog/GetList";
		if (queryStr && queryStr.length > 0) {
			url += queryStr;
		}
		return await this.axios.get(url);
	}

	public async addActivityLog(payload: any): Promise<any> {
		return await this.axios.post(`/ActivityLog/Add`, payload);
	}

	public async editActivityLog(payload: any): Promise<any> {
		return await this.axios.post(`/ActivityLog/Edit`, payload);
	}

	public async hideActivityLog(activityLogId: number): Promise<any> {
		return await this.axios.post(`/ActivityLog/Hide/${activityLogId}`);
	}

	public async unHideActivityLog(activityLogId: number): Promise<any> {
		return await this.axios.post(`/ActivityLog/Unhide/${activityLogId}`);
	}

	public async getActivityLogOutcomes(areaId: null | boolean): Promise<any> {
		let url = "/Outcomes/GetActivityLogOutcomes";

		if (areaId) {
			url += "?areaID=" + areaId;
		}

		return await this.axios.get(url);
	}

	public async getActivityLogEntry(activityLogId: number): Promise<any> {
		return await this.axios.get(`/ActivityLog/${activityLogId}`);
	}

	public async enableArea(areaId: number): Promise<any> {
		return await this.axios.get(`/groups/enableGroup/${areaId}`);
	}

	public async disableArea(areaId: number): Promise<any> {
		return await this.axios.get(`/groups/DisableGroup/${areaId}`);
	}

	public async fetchFeaturesList(): Promise<any> {
		return await this.axios.get("/Features/getFeaturesList");
	}

	public async getTimeZones(): Promise<any> {
		const { data } = await this.axios.get("/groups/TimeZones");
		return data;
	}

	public async fetchDynamicDataList(): Promise<any> {
		return await this.axios.get("/Tasks/GetDynamicDataList");
	}

	public async fetchDynamicData(payload: any): Promise<any> {
		return await this.axios.post(`/Tasks/GetDynamicData`, payload);
	}

	public async putEventOnTest(payload: any): Promise<any> {
		return await this.axiosJson.post("OnTest/PutEventOnTest", payload);
	}

	public async changePassword(payload: any): Promise<any> {
		const { data } = await this.axiosJson.post(`/users/ChangePassword`, payload);
		return data;
	}

	public async register(payload: any): Promise<any> {
		const { data } = await this.axiosJson.post(`/register`, payload);
		return data;
	}

	public async serverTypeEventUpdate(serverTypeEvent: ServerTypeEvent): Promise<number> {
		return (await this.axiosJson.post("/servertypeevents", serverTypeEvent)).data;
	}

	public async serverTypeEventDelete(serverTypeEvent: ServerTypeEvent): Promise<void> {
		await this.axiosJson.delete("/servertypeevents", {
			params: {
				serverTypeId: serverTypeEvent.serverTypeID,
				eventNum: serverTypeEvent.eventNum
			}
		});
	}

	public async responseAlarmTypes(groupId?: number): Promise<ResponseAlarmType[]> {
		return (await this.axiosJson.get("/Responses/ResponseAlarmTypes", {
			params: {
				groupId
			}
		})).data;
	}

	public async retrieveServer(serverId: number): Promise<ServerDetails> {
		const { data } = await this.axios.get(`/servers/Server/${serverId}`);
		return data;
	}

	public async addServer(newServer: ServerDetails): Promise<number> {
		const { data } = await this.axios.post("/servers/AddServer", newServer);
		return data;
	}

	public async updateServer(updatedServer: ServerDetails): Promise<void> {
		return await this.axiosJsonDirectInstance.post("/servers/UpdateServer", updatedServer);
	}

	public async deleteServer(serverId: number): Promise<void> {
		return await this.axios.delete(`/servers/DeleteServer/${serverId}`);
	}

	public async addDevice(newDevice: any): Promise<any> {
		return await this.axios.post("/devices/AddDevice", newDevice);
	}

	public async updateDevice(updatedDevice: any): Promise<any> {
		return await this.axios.post("/devices/UpdateDevice", updatedDevice);
	}

	public async deleteDevice(deviceId: number): Promise<void> {
		return await this.axios.delete(`/devices/DeleteDevice/${deviceId}`);
	}

	public async getServerTypes(): Promise<ServerType[]> {
		const { data } = await this.axios.get(`/servers/IntegrationServerTypes`);
		return data;
	}

	public async getApplianceList(): Promise<any> {
		const { data } = await this.axios.get(`/servers/GetApplianceList`);
		return data;
	}

	public async getApplianceServerDetails(): Promise<ApplianceServerDetails[]> {
		const { data } = await this.axios.get(`/servers/GetApplianceServers`);
		return data;
	}

	public async getIsFeaturesConfigControlledByFile(): Promise<any> {
		const { data } = await this.axios.get("/Features/GetFunctionalityExists");
		return data;
	}

	public async fetchAllTours(): Promise<any> {
		return await this.axios.get("/Tours/GetAllTours");
	}

	public async fetchDeviceList(groupID: number): Promise<any> {
		return await this.axios.get(`/Tours/GetAllDevices/${groupID}`);
	}

	public async searchDeviceList(groupID: number, searchText: string): Promise<any> {
		return await this.axios.get(`/Tours/SearchDeviceList/${groupID}/${searchText}`);
	}

	public async addTour(tour: any): Promise<any> {
		return await this.axiosJson.post("/Tours/InsertTour", tour);
	}

	public async updateTour(tour: any): Promise<any> {
		return await this.axiosJson.post("/Tours/UpdateTour", tour);
	}

	public async deleteTour(tourId: number): Promise<any> {
		return await this.axios.delete(`/Tours/DeleteTour/${tourId}`);
	}

	public async fetchGuardTours(groupID: number): Promise<any> {
		return await this.axios.get(`/GuardTours/GuardToursByGroup/${groupID}`);
	}

	public async fetchGuardTour(guardTourId: number): Promise<any> {
		return await this.axios.get(`/GuardTours/GuardTour/${guardTourId}`);
	}

	public async addGuardTour(guardTour: any): Promise<any> {
		return await this.axiosJson.post("/GuardTours/CreateGuardTour", guardTour);
	}

	public async deleteGuardTour(guardTourId: number): Promise<any> {
		return await this.axios.delete(`/GuardTours/DeleteGuardTour/${guardTourId}`);
	}

	public async updateGuardTour(tour: any): Promise<any> {
		return await this.axiosJson.post("/GuardTours/UpdateGuardTour", tour);
	}

	public async getTenants(): Promise<any> {
		return await this.axios.get("/Groups/Tenants");
	}

	public async getWeeklyDisarmSchedule(groupID: number): Promise<any> {
		const { data } = await this.axios.get(`/Groups/WeeklyDisarmSchedule/${groupID}`);
		return data;
	}

	public async getResponses(paginatedSearchRequest?: PaginatedSearchRequest): Promise<ResponsesPagedResponse> {
		return (await this.axiosJson.get(`/Responses/GetResponses`, paginatedSearchRequest)).data;
	}

	public async getHttpResponses(paginatedSearchRequest?: PaginatedSearchRequest): Promise<ResponsesPagedResponse> {
		return (await this.axiosJson.get(`/Responses/GetHttpResponses`, paginatedSearchRequest)).data;
	}

	public async getAllResponsesByArea(groupId?: number, filter?: string): Promise<Response[]> {
		return (await this.axios.get(`/Responses/GetAllResponsesByArea`,
		{
			params: {
				groupId: groupId,
				filter: filter
			}
		})).data;
	}

	public async createNewResponse(response: Response): Promise<AxiosResponse> {
		return await this.axios.post("/Responses/CreateEmailResponse", response);
	}

	public async createDeviceResponse(response: Response): Promise<AxiosResponse> {
		return await this.axios.post("/Responses/CreateDeviceResponse", response);
	}

	public async updateResponse(response: Response): Promise<AxiosResponse> {
		return await this.axios.put("/Responses/UpdateResponse", response);
	}

	public async deleteResponse(responseId: number): Promise<AxiosResponse> {
		return await this.axiosJson.delete(`/Responses/DeleteResponse/${responseId}`);
	}

	public async createNewHttpResponse(response: Response): Promise<AxiosResponse> {
		return await this.axios.post("/Responses/CreateHttpResponse", response);
	}

	public async getResponseScripts() {
        return (await this.axios.get(`/Responses/Scripts`)).data;
    }

	public async getDefaultResponseGrouping(cancelToken?: CancelToken): Promise<any> {
		return await this.axios.get("/Responses/GetDefaultResponseGrouping", {
			cancelToken
		});
	}

	public async getSubscription(tenantGroupId?: number): Promise<any> {
		if (tenantGroupId) {
			const { data } = await this.axios.get(`/Subscription?tenantGroupId=${tenantGroupId}`);
			return data;
		} else {
			const { data } = await this.axios.get(`/Subscription/`);
			return data;
		}
	}

	public async reloadSubscription(): Promise<any> {
		const { data } = await this.axios.get(`/Subscription/Reload`);
		return data;
	}

	public async getExternalMapLayerTypes(): Promise<any> {
		return await this.axios.get("/ExternalMapLayers/GetTypes");
	}

	public async getExternalMapLayers(): Promise<any> {
		return await this.axios.get("/ExternalMapLayers");
	}

	public async createOrUpdateExternalMapLayer(externalMapLayer: ExternalMapLayer): Promise<any> {
		return await this.axiosJson.put("/ExternalMapLayers", externalMapLayer);
	}

	public async deleteExternalMapLayer(externalMapLayerID: number): Promise<any> {
		return await this.axiosJson.delete(`/ExternalMapLayers/${externalMapLayerID}`);
	}

	public getOnlineUserLocations() {
		return axiosInstance.get("/MapLayers/MapItems/OnlineUserLocations")
	}

	public async updateExternalMapLayerUserLink(externalMapLayerUserLink: any) {
		return await this.axiosJson.put("/ExternalMapLayerUserLink", externalMapLayerUserLink);
	}

	public async getBilledUserPermissions() {
		return (await this.axios.get("/Subscription/BilledUserSets")).data;
	}

	public async getSubscriptionUsageForUserRole(userRole: any) {
		return (await this.axios.post("/Subscription/SubscriptionUsageForUserRole", userRole)).data;
	}

	public async getSubscriptionUsageForUserGroup(userGroup: any) {
		return (await this.axios.post("/Subscription/SubscriptionUsageForUserGroup", userGroup)).data;
	}

	public async getUserCountForUserRole(userRoleId: number) {
		return (await this.axios.post(`/UserSetup/UserCountForUserRole?userRoleId=${userRoleId}`)).data;
	}

	public async getUserCountForUserGroup(userGroupId: number) {
		return (await this.axios.post(`/UserSetup/UserCountForUserGroup?userGroupId=${userGroupId}`)).data;
	}

	public async updateSubscription(subscriptionUpdateRequest): Promise<any> {
		return (await this.axios.post("/Subscription/Update", { subscriptionUpdateRequest })).data;
	}

	public async getSamlSettings(): Promise<SamlSettings> {
		return (await this.axios.get("/settings/saml")).data;
	}

	public async setSamlSettings(settings: SamlSettings): Promise<void> {
		await this.axiosJson.put("/settings/saml", settings);
	}

	public async isCreditCardRequired() {
	    return (await this.axios.get("/Register/IsCreditCardRequired")).data;
    }

	public async putCustomer(customer: any) {
	    return (await this.axiosJson.put("/Customer", customer)).data;
    }

    public async createPaymentProfileForCustomer(paymentProfile: any) {
        return (await this.axiosJson.post("/Customer/PaymentProfile", paymentProfile)).data;
    }

    public async validateContactInfo(contactInfo: any) {
        return (await this.axiosJson.post("/Register/CheckContactInfo", contactInfo)).data;
    }

	public async updateEventOutcome(eventOutcome: EventCategory): Promise<void> {
		return await this.axiosJsonDirectInstance.post("/Outcomes/UpdateOutcome", eventOutcome);
	}

	public async deleteEventOutcome(eventOutcome: EventCategory): Promise<void> {
		return await this.axiosJson.post("/Outcomes/DeleteOutcome", eventOutcome);
	}

	public async createEventOutcome(eventOutcome: EventCategory): Promise<number> {
		const { data } =  await this.axiosJson.post("/Outcomes/CreateOutcome", eventOutcome);
		return data;
	}

    public async fetchEventOutcomesForUser(): Promise<EventCategory[]> {
		const { data } =  await this.axios.get("/Outcomes/GetEventOutcomes");
		return data;
	}

	public async fetchEventOutcomeTree(): Promise<EventCategory[]> {
		const { data } =  await this.axios.get("/Outcomes/GetEventOutcomeTree");
		return data;
	}

    // Case management
    public async getTemplates(eventId: number) {
        return await this.axios.get(`/cases/templates/${eventId}`);
    }

    public async getTemplatesForUser() {
        return await this.axios.get("/cases/templates");
    }

    public async raiseToCases(caseData: CaseData) {
        return await this.axiosJsonDirectInstance.post("/cases/raiseToCases", caseData);
    }

    public async casesLogin(suiteSession: boolean = false): Promise<{ url: string; token: string }> {
        return (await this.axiosJson.post(`/cases/casesLogin/${suiteSession}`)).data;
    }

    public async inviteUser(userId: number) {
        return await this.axios.post(`/users/InviteUser/${userId}`);
    }

    public async setPassword(token: string, password: string): Promise<void> {
			const request = {
				token: token,
				password: password
			}

			await this.axiosJson.post(`/users/SetPassword`, request);
    }

    public async resetPassword(email: string) {
        return (await this.axios.post("/users/ResetPassword?emailAddress=" + encodeURIComponent(email))).data;
    }

	public async validatePinCode(validatePinCodeRequest: ValidatePinCodeRequest): Promise<boolean> {
		const { data } = await this.axiosJson.post(`/users/ValidatePinCode`, validatePinCodeRequest);
		return data;
	}

    public getDeviceServiceAddress(): string {
		return location.origin;
	}

	public getAuditServiceAddress(): string {
		return location.origin;
	}

	public async setTenant(groupId: number) {
		return await this.axios.post(`/users/SetTenant/${groupId}`);
	}

	public async resetTenant(): Promise<any> {
		return await this.axios.post("/users/resetTenant");
	}

	public async getTenantOverview(): Promise<any> {
		const response = await this.axios.get("/UserContext/TenantOverview");
		return response.data;
	}

	public async groupsWithPermission(permissions: string []): Promise<any> {
		let permissionsAsQueryString = arrayToQueryString("permissions", permissions);
		const response = await this.axios.get(`/UserContext/GroupsWithPermission?${permissionsAsQueryString}`);
		return response.data;
	}

	public async getMobileEventShareQueueForUser() : Promise<MobileEventShareInfo[]>{
		const response = await this.axios.get(`/EventShare/Mobile/ForUser`);
		return response.data;
	}

	public async getEventUsersForEvent(eventID: number) : Promise<EventUser[]>{
		const response = await this.axios.get(`/Event/EventUsers/${eventID}`);
		return response.data;
	}

	public async setMobileEventShareItem(eventShareInfo: MobileEventShareInfo) {
        return (await this.axios.post("/EventShare/Mobile/Update", eventShareInfo)).data;
    }

	public async getEventQueueState(): Promise<any> {
		const { data } = await this.axios.get("/eventqueue/GetCurrentSystemState");
		return data;
	}

	public async getRegionForGroup(groupID: number): Promise<MapAreaRegion> {
		const { data } = await this.axios.get(`/MapRegion/ForGroup/${groupID}`);
		return data;
	}

	public async getAllRegions(): Promise<MapAreaRegionDetails[]> {
		const { data } = await this.axios.get(`/MapRegion/AreaRegionTree`);
		return data;
	}

	public async createOrUpdateRegion(region: MapAreaRegion): Promise<void> {
		const { data } = await this.axiosJson.put("/MapRegion/", region);
		return data;
	}

	public async getDevicesByType(groupId: number, deviceTypeId: number, filter: string | null | undefined): Promise<any> {
		let uri = `/Devices/GetDevicesByType/${groupId}/${deviceTypeId}`
		if (filter && filter.length > 0) {
			uri += `?filter=${filter}`
		}
		const { data } = await this.axiosJson.get(uri);

		return data;
	}

	public async getExtendedDevicesByType(groupId: number, deviceTypeId: number, filter: string | null | undefined): Promise<any> {
		let uri = `/Devices/GetExtendedDevicesByType/${groupId}/${deviceTypeId}`
		if (filter && filter.length > 0) {
			uri += `?filter=${filter}`
		}
		const { data } = await this.axiosJson.get(uri);

		return data;
	}

	public async getAllDevicesByType(deviceTypeId: number, filter: string | null | undefined): Promise<any> {
		let uri = `/Devices/GetDevicesByType/${deviceTypeId}`
		if (filter && filter.length > 0) {
			uri += `?filter=${filter}`
		}
		const { data } = await this.axiosJson.get(uri);

		return data;
	}

	public async getDeviceServerDetails(deviceIds: number[]): Promise<DeviceServerDetails[]> {
		const { data } = await this.axiosJson.post("/Devices/ServerDetails", deviceIds);
		return data;
	}

	public async getAlarmDecoders(paginatedSearchRequest: PaginatedSearchRequest): Promise<AlarmDecoderPagedResponse> {
		const { data } = await this.axios.get("/servers/AlarmDecoders", paginatedSearchRequest);
		return data;
	}

	public async getAlarmDecoder(serverTypeId: number): Promise<AlarmDecoderExtended> {
		const { data } = await this.axios.get(`/servers/AlarmDecoder/${serverTypeId}`);
		return data;
	}

	public async deleteAlarmDecoder(serverTypeId: number): Promise<any> {
		const { data } = await this.axios.delete(`/servers/AlarmDecoder/${serverTypeId}`);
		return data;
	}

	public async createOrUpdateAlarmDecoder(alarmDecoder: AlarmDecoder): Promise<any> {
		return await this.axios.put("/servers/AlarmDecoder", alarmDecoder);
	}

	public async getAlarmDecoderServerList(): Promise<IdValue[]> {
		const { data } = await this.axios.get(`/servers/AlarmDecoders/Servers`);
		return data;
	}

	public async auditEventPickUp(eventID: number, pickupMethod: string){
		await this.axios.post(`/Event/AuditEventPickup/${eventID}/${pickupMethod}`)
	}

	public async sendEventNotification(eventId: number, payload: EmailNotificationRequest): Promise<void> {
		await this.axiosJson.post(`/Event/${eventId}/Notification`, payload);
	}

	public async getOrganizationSettings(): Promise<OrganizationSettings> {
		return (await this.axios.get("/OrganizationSettings")).data;
	}

	public async setHousekeepingSettings(housekeepingSettings: HousekeepingSettings): Promise<void> {
		await this.axios.post("/OrganizationSettings/HousekeepingSettings", housekeepingSettings);
	}

	public async createHttpAlarmReceiverApiKey(): Promise<string> {
		return (await this.axios.post("/OrganizationSettings/GenerateHttpAlarmReceiverApiKey")).data;
	}

	public async getDevicesByMapLayerItemType(
		groupId: number,
		deviceTypeId: number,
		filter: string | null | undefined
	): Promise<any> {

		let uri = `/AdvancedDevices/GetDevicesByMapLayerItemType/${groupId}/${deviceTypeId}`;
		if (filter && filter.length > 0) {
			uri += `?filter=${filter}`;
		}
		const { data } = await this.axiosJson.get(uri);

		return data;
	}

	public async fetchAudioDevicesByIds(deviceIds: number[]): Promise<AudioDevice[]> {
		let idsAsQueryString = arrayToQueryString("deviceIds", deviceIds);
		const response = await this.axios.get(`/AdvancedDevices/AudioDeviceInformation?${idsAsQueryString}`);
		return response.data;
	}

	public async fetchAudioDevicesByGroupId(groupId: number): Promise<AudioDevice[]> {
		const response = await this.axios.get(`/AdvancedDevices/AudioDeviceInformationForGroup/${groupId}`);
		return response.data;
	}

	public async pagedServerTypeEvents(paginatedSearchRequest: ServerTypeEventsPagedQueryParams): Promise<ServerTypeEventsPagedResponse> {
		let serverTypesAsQueryString = arrayToQueryString("serverTypeIds",paginatedSearchRequest.serverTypeIds)
		const { data } = await this.axios.get(`/servertypeevents?`+
												`${serverTypesAsQueryString}` +
												`&page=${paginatedSearchRequest.page}` +
												`&pageSize=${paginatedSearchRequest.pageSize}` +
												`&searchTerm=${paginatedSearchRequest.searchTerm}` +
												`&sortBy=${paginatedSearchRequest.sortBy}` +
												`&sortDesc=${paginatedSearchRequest.sortDesc}`
												);

		return data;
	}

	public async getAreaMaskingCount(): Promise<AreaMaskingCount> {
		const { data } = await this.axios.get("/groups/MaskingStatesCount");
		return data;
	}

	public async retrieveSyncSystems(pageRequest?: PaginatedSearchRequest): Promise<SyncSystemsPagedResponse> {
		const { data } = await this.axios.get(`/Sync/SyncSystems`, pageRequest);
		return data;
	}

	public async retrieveSyncSystemsCount(): Promise<number> {
		const { data } = await this.axios.get(`/Sync/SyncSystems/Count`);
		return data;
	}

	public async addOrUpdateSyncSystem(syncSystem: SyncSystem): Promise<number> {
		var response = await this.axios.put("/Sync/SyncSystem", syncSystem)
		if (response) {
			if (response.status == 200 && syncSystem.syncSystemId > 0)
			{
				return syncSystem.syncSystemId
			}
			else if (response.status == 201)
			{
				return parseInt(response.data.replace(/\D/g,""));
			}
		}
	}

	public async deleteSyncSystem(syncSystemId: number): Promise<void> {
		await this.axios.delete(`/Sync/SyncSystem/${syncSystemId}`);
	}

	public async groupSyncsForSyncSystem(syncSystemId: number): Promise<GroupSyncModel[]> {
		const { data } = await this.axios.get(`/Sync/GroupSyncsForSync/${syncSystemId}`)
		return data;
	}

	public async RetrieveLatestQueueStateForGroupSync(groupSyncId: number): Promise<SyncStateResponse> {
		const response = await this.axios.get(`/Sync/LatestGroupSyncQueue/${groupSyncId}`)
		return response.data;
	}

	public async addOrUpdateGroupSync(groupSync: GroupSyncModel): Promise<void> {
		await this.axiosJson.put(`/Sync/GroupSync/`, groupSync)
	}

	public async deleteGroupSync(groupSyncId: number): Promise<void> {
		await this.axiosJsonDirectInstance.delete(`/Sync/GroupSync/${groupSyncId}`)
	}

	public async queueGroupSync(groupSyncId: number): Promise<void> {
		await this.axios.post(`/Sync/QueueGroupSync/${groupSyncId}`)
	}

	public async retrieveLastSyncQueueStates(syncSystemId: number): Promise<GroupSyncQueue[]> {
		const { data } = await this.axios.get(`Sync/LatestQueue/${syncSystemId}`);
		return data;
	}

	public async syncQueueForGroupSync(groupSyncId: number): Promise<GroupSyncQueue[]> {
		const { data } = await this.axios.get(`Sync/SyncQueueForGroupSync/${groupSyncId}`);
		return data;
	}

	public async auditTrailForGroupSyncQueue(syncQueueId: number): Promise<GroupSyncAudit[]> {
		const { data } = await this.axios.get(`Sync/GroupSyncQueueAudit/${syncQueueId}`);
		return data;
	}

	public async updateInitialAlarmLocation(eventId : number , latlng: string) : Promise<void> {
		return await this.axios.post(`Event/UpdateInitialAlarmLocation`, { eventId: eventId , latLngString: latlng });
	}

	public async suiteSamlGenerate(user: string, isMobile: boolean = false): Promise<any> {
		return await this.axios.get(`login/SuiteSaml?user=${encodeURIComponent(user)}&isFieldOpsMobile=${isMobile}`);
	}

	public async mfaRedirectUrl(): Promise<string> {
		const { data } = await this.axios.get(`login/MFARedirectUrl`);
		return data;
	}

	public async getAlarmAutomations(alarmId: number): Promise<ResponseAction[]>{
		const { data } = await this.axiosJson.get(`Alarms/${alarmId}/Actions`);
		return data;
	}

	public async updateAlarmAutomation(alarmId: number, responseAction: ResponseAction): Promise<ResponseAction> {
		const { data } = await this.axiosJson.put(`Alarms/${alarmId}/Actions`, responseAction);
		return data;
	}

	public async createAlarmAutomation(alarmId: number, responseAction: ResponseAction): Promise<ResponseAction> {
		const { data } = await this.axiosJson.post(`Alarms/${alarmId}/Actions`, responseAction);
		return data;
	}

	public async getAlarmAutomation(alarmId: number, actionId: number): Promise<ResponseAction>{
		const { data } = await this.axiosJson.get(`Alarms/${alarmId}/Actions/${actionId}`);
		return data;
	}

	public async deleteAlarmAutomation(alarmId: number, actionId: number): Promise<boolean> {
		const { data } = await this.axiosJson.delete(`Alarms/${alarmId}/Actions/${actionId}`);
		return data;
	}

	public async getSupportedActionTypes(): Promise<ResponseActionType> {
		const { data } = await this.axiosJson.get(`alarms/SupportedActionTypes`);
		return data;
	}

	public async getAutomationLimit(groupID: number): Promise<number> {
		const { data } = await this.axiosJson.get(`alarms/AutomationLimit/${groupID}`);
		return data;
	}

	public async getElevationLabels(): Promise<any> {
		const { data } =  await this.axios.get("MapLayers/MapItems/GetElevationLabelsByTenantId");
		return data;
	}

	public async requiresConfiguration(): Promise<boolean>
	{
		try
		{
			const { data } = await this.axios.get("Login/RequiresConfiguration");
			return data;
		}
		catch (err)
		{
			console.error(err);
			return false;
		}
	}

	public async raiseSystemLog(healthCheckRequest : HealthCheckRequest) {
		const { data } = await this.axiosJson.post(`systemView/addHealthEntry`, healthCheckRequest);
		return data;
	}

	public async getSystemViewTree(groupID: number, params: SystemViewRequestTreeParams): Promise<SystemViewTreeNode[]> {
		const { data } = await this.axiosJson.get(`SystemView/SetupTree/${groupID}`, {
			params
		});
		return data;
	}

	public async getSetupSummary(objectTypeId: number, objectId: number): Promise<SetupSummaryResponse>  {
		const { data } = await this.axiosJson.get(`SystemView/SetupSummary?objectTypeId=${objectTypeId}&objectId=${objectId}`);
		return data
	}

	public async getSystemViewSetupEntities(
		objectTypeId: ObjectTypeId,
		objectId: number,
		devicesToSkip:number,
		responsesToSkip:number,
		params: SystemViewRequestTreeParams,
		count: number = 200
	): Promise<SystemViewTreeNode> {
		const { data } = await this.axiosJson.get(`SystemView/SetupEntities/${objectTypeId}/${objectId}/${devicesToSkip}/${responsesToSkip}/${count}`, { params });
		return data;
	}

	public async getSystemLogs(objectTypeId: ObjectTypeId, objectId: number, count: number = 10, maxSystemLogId: number = null): Promise<HealthHistoryResponse>
	{
		const { data } = await this.axiosJson.get(`SystemLog/GetSystemLogByObjectTypeIdAndObjectId?objectTypeId=${objectTypeId}&objectId=${objectId}&count=${count}&maxSystemLogId=${maxSystemLogId}`)
		return data;
	}

	public async getSystemHealthStatus(refresh: boolean = false): Promise<SystemViewHealthStatusCount>
	{
		const { data } = await this.axiosJson.get(`SystemView/GetSystemHealthStatus?refreshCache=${refresh}`);
		return data;
	}

	public async getSituationalAwarenessQueue() : Promise<SituationalAwarenessEventQueue[]> {
		const { data } = await this.axios.get("/SituationalAwarenessEventQueue");

		return data;
	}

	public async promoteSituationalAwarenessQueueEntry(promoteRequest: SituationalAwarenessEventQueueActionRequest) : Promise<SituationalAwarenessEventQueueActionResult>
	{
		const { data } = await this.axios.post("/SituationalAwarenessEventQueue/Promote", promoteRequest);
		return data;
	}

	public async dismissSituationalAwarenessQueueEntry(dismissRequest: SituationalAwarenessEventQueueActionRequest) : Promise<SituationalAwarenessEventQueueActionResult>
	{
		const { data } = await this.axios.post("/SituationalAwarenessEventQueue/Dismiss", dismissRequest);
		return data;
	}

	public async getServerTypesForTenant(): Promise<ServerTypeOption[]>
	{
		const { data } = await this.axiosJson.get("Servers/ServerTypesForTenant");
		return data?.map(type => { return { serverTypeId: type.serverTypeID, title: type.title }});
	}

	public async retrieveRules(paginatedSearchRequest: PaginatedSearchRequest): Promise<RulesWithPagination>
	{
		const { data } = await this.axios.get("/Rules", paginatedSearchRequest);
		return data;
	}

	public async createOrUpdateRule(rule: Rule): Promise<boolean> {
		try {
			await this.axiosJsonDirectInstance.put("/Rules", rule);
			return true;
		} catch(e) {
			if(e.response)
			{
				Vue.prototype.$notify({
					type: e.response.status == 404 ? "neutralError" : "error",
					title: "Error",
					text: e.response.data,
					ignoreDuplicates: true
				});
			}
			else {
				Vue.prototype.$notify({
					type: "error",
					title: "Error",
					text: "Unknown issue occurred",
					ignoreDuplicates: true
				});
			}

			return false;
		}
	}

	public async DeleteRule(ruleId: number): Promise<AxiosResponse> {
		return await this.axios.delete(`/rules/${ruleId}`);
	}

	public async retrieveRuleServerTypes(): Promise<RuleServerType[]>
	{
		const { data } = await this.axios.get("/Rules/ServerTypes");
		return data;
	}

	public async retrieveRuleServersForServerType(serverTypeId: number): Promise<RuleServer[]>
	{
		const { data } = await this.axios.get(`/Rules/Servers/${serverTypeId}`);
		return data;
	}

	public async retrieveRuleServerTypeEventsForServerType(serverTypeId: number): Promise<RuleServerTypeEvent[]>
	{
		const { data } = await this.axios.get(`/Rules/ServerTypeEvents/${serverTypeId}`);
		return data;
	}

	public async retrieveRuleActionPlans(): Promise<RuleActionPlan[]>
	{
		const { data } = await this.axios.get("/Rules/ActionPlans");
		return data;
	}

	public async retrieveRuleAlarmsForGroupId(groupId: number): Promise<RuleAlarm[]>
	{
		const { data } = await this.axios.get(`/Rules/Alarms/${groupId}`);
		return data;
	}

	public async getCameraPresets(device: DeviceDetails) : Promise<DeviceConfigurationPreset[]> {
		const { data } = await this.axiosJson.get(`/Presets/GetPresets`, {
			params: {
				deviceId: device.deviceID
			},
		});
		return data;
	}

	public async createNewCameraPreset(presetConfigPayload : DeviceConfigurationPresetPayload) : Promise<boolean> {
		const { data } = await this.axiosJson.post(`/Presets/CreatePreset`, presetConfigPayload);
		return data;
	}

	public async updateCameraPreset(presetConfigPayload : DeviceConfigurationPresetPayload) : Promise<boolean> {
		const { data } = await this.axiosJson.put(`/Presets/UpdatePreset`, presetConfigPayload);
		return data;
	}

	public async deleteCameraPreset(presetConfigPayload : DeviceConfigurationPresetPayload) : Promise<boolean> {
		const { data } = await this.axiosJson.delete(`/Presets/DeletePreset`, {
			params: {
				deviceId: presetConfigPayload.deviceId,
				groupId: presetConfigPayload.groupId,
				name: presetConfigPayload.name,
				position: presetConfigPayload.position
			},
		});
		return data;
	}

	public async createOrUpdateCameraPresets(presetPayloads: DeviceConfigurationPresetPayload[]): Promise<void>
	{
		const { data } = await this.axiosJson.put(`/Presets/CreateOrUpdatePresets`, presetPayloads);
		return data;
	}

	public async retrieveDistanceConfigurations(paginatedSearchRequest?: PaginatedSearchRequest): Promise<DistanceConfigurationPagedResponse> {
		const { data } = await this.axios.get("/DistanceConfiguration", paginatedSearchRequest);
		return data;
	}

	public async createOrUpdateDistanceConfiguration(distanceConfiguration: DistanceConfiguration): Promise<void> {
		await this.axiosJson.put("/DistanceConfiguration", distanceConfiguration);
	}

	public async deleteDistanceConfiguration(distanceConfigurationId: number): Promise<void> {
		await this.axios.delete(`/DistanceConfiguration/${distanceConfigurationId}`);
	}

	public async retrieveDistanceConfigurationMaxRadius(): Promise<number> {
		const { data } = await this.axios.get("/DistanceConfiguration/maxRadius");
		return data;
	}

	public async retrieveDistanceConfigurationAlarmDecoders(): Promise<DistanceConfigurationAlarmDecoder[]> {
		const { data } = await this.axios.get("/DistanceConfiguration/ServerTypes");
		return data;
	}

	public async updateClosedEventOutcome(request: UpdateEventOutcomeRequest): Promise<void> {
		await this.axiosJson.post(`/Outcomes/UpdateEventOutcome`, request);
	}

	public async updateEventOutcomeByNotification(request: UpdateEventOutcomeRequest): Promise<void> {
		await this.axiosJson.post(`/Outcomes/UpdateEventOutcomeByNotification`, request);
	}

	public async joinClosedEvent(eventId: number) : Promise<void> {
		await this.axios.get(`/EventShare/JoinClosedEvent/${eventId}`);
	}

	public async getUsersInfoBySearchTerm(searchQuery: string, pageSize: number): Promise<UserInfo[]> {
		const { data } = await this.axios.get("/users/GetUsersAndContactsBySearch", {
			params: {
				searchQuery: searchQuery,
				pageSize: pageSize
			}
		});
		return data;
	}

	public async addInvolvedParty(request: AddInvolvedPartyRequest): Promise<void> {
		await this.axiosJson.put("/InvolvedParty/AddInvolvedParty", request);
	}

	public async removeInvolvedParty(request: RemoveInvolvedPartyRequest): Promise<void> {
		await this.axiosJson.put("/InvolvedParty/RemoveInvolvedParty", request);
	}

	public async getInvolvedPartiesForEvent(eventId: number): Promise<InvolvedParty[]> {
		const { data } = await this.axios.get(`/InvolvedParty/InvolvedPartiesForEvent/${eventId}`);
		return data;
	}

	public async getReloadEventQueuePref(groupId: number): Promise<boolean> {
		const { data } = await this.axios.get(`/groups/ReloadEventQueue/${groupId}`);
		return data;
	}
}
const api = new ImmixCloudV2ApiHttp();
export default api;
