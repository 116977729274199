import { Vue, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class HideMapMixin extends Vue {
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

    public get isHideMapsEnabled(): boolean {
        return this.getFeature(["HideMaps"]);
    }
}
