import { GetterTree } from "vuex";
import { UserContextState } from "./types";
import { RootState } from "@/store/types";

export const getters: GetterTree<UserContextState, RootState> = {
	getTenantSize: state => {
		if (!state.isSet || !state.tenantOverview || !state.tenantOverview.areaCount || state.tenantOverview.areaCount < 1) {
			// user context not set, assume the worse case scenario
			return "large";
		}

		if (state.tenantOverview.areaCount < 100) {
			return "small";
		} else if (state.tenantOverview.areaCount < 500) {
			return "medium";
		} else {
			return "large";
		}
	}
};
