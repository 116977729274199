/**
 * async wrapper of geolocation getCurrentPosition, also encapsulating feature check
 * @param posOptions - PositionOptions passed to geolocation.getCurrentPosition
 */
const getDevicePosition = (posOptions: PositionOptions) => {
	if ("geolocation" in navigator) {
		return new Promise((resolve: PositionCallback, reject: PositionErrorCallback) => {
			navigator.geolocation.getCurrentPosition(resolve, reject, posOptions);
		});
	} else {
		// https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
		const err: PositionError = {
			code: 2,
			message: "GEOLOCATION API IS NOT AVAILABLE",
			PERMISSION_DENIED: 1,
			POSITION_UNAVAILABLE: 2,
			TIMEOUT: 3
		};
		return Promise.reject(err);
	}
};

export default getDevicePosition;
