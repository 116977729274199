
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CustomFieldDto, CustomFieldValues, CustomFieldValueAndSettings, CustomFieldTableTypes } from "@/store/custom-fields/types";
import CustomDefinedField from "./CustomDefinedField.vue";
import { namespace } from "vuex-class";
import { orderBy } from "lodash";

const CustomFields = namespace("customFields");

@Component({
	components: {
		"custom-field": CustomDefinedField
	}
})
export default class CustomDefinedFieldsDisplay extends Vue {
	@Prop({ type: Number, required: true }) public customFieldTableType: number; // links to an enum
	@Prop({ type: Array }) public values: CustomFieldValues[];

	@Prop({ type: Boolean, default: false }) public allowHorizontal: boolean;
	@Prop({ type: Number, default: 22 }) public maxWidth: number;
	@Prop({ type: Number, default: 250 }) public maxCharacters: number;

	@CustomFields.Action retrieveCustomFields: ({ tableType, live }: { tableType: number, live: boolean; }) => Promise<void>;
	@CustomFields.State areaCustomFields: CustomFieldDto[];
	@CustomFields.State contactCustomFields: CustomFieldDto[];
	@CustomFields.State alarmCustomFields: CustomFieldDto[];

	@Watch("customFieldTableType")
	private async mounted(): Promise<void> {
		await this.retrieveCustomFields({ tableType: this.customFieldTableType, live: true });
	}

	private get customFieldSettings(): CustomFieldDto[] {
		switch (this.customFieldTableType) {
			case CustomFieldTableTypes.Area:
				return this.areaCustomFields;
			case CustomFieldTableTypes.Contact:
				return this.contactCustomFields;
			case CustomFieldTableTypes.Alarm:
				return this.alarmCustomFields;
			default:
				return [];
		}
	}

	private get customFieldValueAndSettings(): CustomFieldValueAndSettings[] {
		if (!this.values || this.values.length == 0) {
			return [];
		}

		let unorderedFields = this.values.map(v => { return { value: v, setting: this.retrieveCustomFieldSettingForValue(v) }; });
		unorderedFields = unorderedFields.filter(f => f.setting != null);

		let orderedFields = orderBy(unorderedFields, field => field.setting.order, ["asc"]);

		return orderedFields;
	}

	private retrieveCustomFieldSettingForValue(customFieldValue: CustomFieldValues): CustomFieldDto {
		return this.customFieldSettings.firstOrDefault(cf => cf.id == customFieldValue.id);
	}

	private get allowHorizontalClass(): string {
		return this.allowHorizontal ? "allow-horizontal" : "disable-horizontal";
	}

	private get containerOrientation(): string {
		return this.allowHorizontal ? "custom-field-display" : "custom-field-vertical-display";
	}

	private customFieldStyling(): { "min-width": string, "max-width": string; } {
		return {
			"min-width": this.maxWidth + "rem",
			"max-width": this.maxWidth + "rem",
		};
	}
}
