import { ActionTree } from "vuex";
import { DevicesState, DeviceSearchQuery, DeviceTypeCounts,
	     ServerDetails, ServerType,
		 GetDevicesByTypeParams, DeviceDetails,
		 DeviceTypeIdentifier, ServersWithEnabledDeviceTypesParams,
		 FetchServerByGroupParams, SearchServerByGroupParams, DeviceSearchSwipeAndShowQuery,
		 GetDeviceForGroupByTypeParams, GetDeviceParams, ServerConfiguration,
		 ServerConfigurationResponse, DeviceConfigurationRelay, FetchConfigForServerParams,
		 SyncSystem, DeviceConfigurationPreset, DeviceType, DeviceConfigurationPresetPayload, DevicePreset } from "./types";
import { RootState, PaginatedSearchQueryParams, CancellableQuery } from "@/store/types";
import api from "@/services/api.service";
import axios from "axios";
import { ICamera } from "../views/types";
import { devicesApi } from "@/services/api.devices.service";
import QuickControlResponse from "@/types/QuickControlResponse";
import ServerDetailsPagedResponse from "@/types/sv-data/devices/ServerDetailsPagedResponse";
import DeviceDetailsPagedResponse from "@/types/sv-data/devices/DeviceDetailsPagedResponse";
import objectToCamelCase from "@/utils/objectToCamelCase";

export const actions: ActionTree<DevicesState, RootState> = {
	async deviceSearch({ commit }, query: string) {
		const cancelTokenSource = axios.CancelToken.source();
		commit("setCancelTokenSource", cancelTokenSource);
		const { data } = await api.deviceSearch(3, query, cancelTokenSource.token);
		commit("setDoors", data);
	},

	async deviceSearchSwipeAndShow( { commit }, { query, searchTermsInAnyOrder }: DeviceSearchSwipeAndShowQuery ): Promise<void> {
		const cancelTokenSource = axios.CancelToken.source();
		commit("setCancelTokenSource", cancelTokenSource);
		const { data } = await devicesApi.deviceSearch(3, query, cancelTokenSource.token, searchTermsInAnyOrder);
		commit("setDoors", data);
	},

	async deviceSearchAndAppendToGroupTree({ commit }, { query, deviceTypeId }) {
		const cancelTokenSource = axios.CancelToken.source();
		commit("setCancelTokenSource", cancelTokenSource);
		const { data } = await api.deviceSearch(deviceTypeId, query, cancelTokenSource.token);
		commit("eventqueue/setDevicesForGroupInSiteTree", data, { root: true });
	},

	async deviceSearchAndAppendToGroupTreeWithQuery({ commit }, { query, deviceTypeId }: DeviceSearchQuery) {
		const cancelTokenSource = axios.CancelToken.source();
		commit("setCancelTokenSource", cancelTokenSource);

		const { data } = await api.deviceSearch(deviceTypeId, query, cancelTokenSource.token);

		// remapping object as the property casing does not match what the camer plugin and other code is expecting
		let devices = data.map(device => {
			return {
				deviceID: device.deviceId,
				deviceTypeID: device.deviceTypeId,
				title: device.title,
				groupId: device.groupId,
				settingsMeta: device.settingsMeta
			} as ICamera;
		});

		commit("eventqueue/setDevicesAndGroupsInSiteTree", { devices, query }, { root: true });
	},

	async deviceQuickControl(
		context,
		{
			deviceId,
			reason,
			eventId
		}: { deviceId: number; reason: string; eventId: number; regionUrl?: string }
	): Promise<QuickControlResponse> {
		const data = await api.deviceQuickControl(deviceId, reason);
		return { device: data, eventId: eventId };
	},

	async getServers({ commit }): Promise<void> {
		const response: ServerDetailsPagedResponse = await api.getServers();
		commit("setServersList", response.data);
		commit("setTotalServers", response.totalRecords);
	},

	async getFilteredServersPaginated(
		{ commit, state },
		{ params, cancelTokenSource }: CancellableQuery<PaginatedSearchQueryParams>
	): Promise<void> {
		const response: ServerDetailsPagedResponse = await api.getServers({ params, cancelToken: cancelTokenSource.token });
		commit("setServersList", response.data);
		commit("setTotalServers", response.totalRecords);
	},

	async getServersWithEnabledDeviceTypes({ commit }, params: ServersWithEnabledDeviceTypesParams): Promise<void> {
		const { data }: { data: ServerDetails[] } = await api.getServersWithEnabledDeviceTypes(params);
		commit("addServersNotAlreadyInStore", data);
	},

	async getServer({ commit }, serverId: number): Promise<void> {
		const { data }: { data: ServerDetails } = await api.getServer(serverId);
		commit("addServersNotAlreadyInStore", [data]);
	},

	async getDeviceTypeCounts({ commit }, serverId: number | null): Promise<void> {
		const { data }: { data: DeviceTypeCounts } = await devicesApi.getDeviceTypeCounts(serverId);

		if (serverId) {
			commit("addDeviceTypeCountsToCurrentServer", data);
			return;
		}

		commit("setDeviceTypeCounts", data);
	},

	async fetchServerConfig({ commit }, { server, serverType, auth }: FetchConfigForServerParams): Promise<void> {
		commit("setGettingConfig", true);
		let data = null;
		try {
			data = await devicesApi.getConfigBase(server.serverID, server.extraValue, auth, "json")
		} catch (err) {
			commit("setGettingConfig", false);
		}

		if (data == null) {
			return;
		}

		var camelizedData = objectToCamelCase(data);

		if (camelizedData != null && camelizedData.length > 0 && camelizedData[0] != null) {
			// Sort arrays by name or title
			camelizedData[0].cameras =
				camelizedData[0].cameras != null && camelizedData[0].cameras.length > 0
					? camelizedData[0].cameras.sort((a, b) => (!a.name ? 1 : !b.name ? -1 : a.name.localeCompare(b.name)))
					: [];
			camelizedData[0].alarms =
				camelizedData[0].alarms != null && camelizedData[0].alarms.length > 0
					? camelizedData[0].alarms.sort((a, b) => (!a.title ? 1 : !b.title ? -1 : a.title.localeCompare(b.title)))
					: [];
			camelizedData[0].relays =
				camelizedData[0].relays != null && camelizedData[0].relays.length > 0
					? camelizedData[0].relays.sort((a, b) => (!a.name ? 1 : !b.name ? -1 : a.name.localeCompare(b.name)))
					: [];
			camelizedData[0].audios =
				camelizedData[0].audios != null && camelizedData[0].audios.length > 0
					? camelizedData[0].audios.sort((a, b) => (!a.name ? 1 : !b.name ? -1 : a.name.localeCompare(b.name)))
					: [];
		}

		commit("setServerConfig", camelizedData);
		commit("setGettingConfig", false);
	},

	async fetchServersByGroupId({ commit }, { groupId, pageSize }: FetchServerByGroupParams): Promise<void> {
		const { data } = await api.fetchServers(groupId, pageSize);
		commit("setServersList", data);
	},

	async searchServersByGroupId({ commit }, { groupId, pageSize, filter }: SearchServerByGroupParams): Promise<void> {
		const { data } = await api.fetchServers(groupId, pageSize, filter);
		commit("setFilteredServersList", data);
	},

	async addServer({ commit }, newServer: ServerDetails): Promise<void> {
		const data = await api.addServer(newServer);

		newServer.serverID = data;

		const newServerForStore: ServerDetails = { ...newServer };
		commit("addServer", newServerForStore);
		commit("setCurrentServer", newServerForStore);
		commit("setNotificationOptions", {
			type: "success",
			title: "Device Created",
			text: `"${newServer.title}" was created.`
		});
	},

	async updateServer({ commit, state }, updatedServer: ServerDetails): Promise<void> {
		try {
			await api.updateServer(updatedServer);
		}
		catch (ex) {
			var errorMessage = ex.response?.data ? ex.response.data : "An error occurred when updating the device.";
			commit("setNotificationOptions", {
				type: "error",
				title: "Error",
				text: errorMessage
			});
			return;
		}

		const serverForStore = { ...updatedServer, deviceTypeCounts: state.currentServer.deviceTypeCounts };
		commit("setCurrentServer", serverForStore);
		commit("updateServer", serverForStore);

		commit("setNotificationOptions", {
			type: "success",
			title: "Device Updated",
			text: `"${updatedServer.title}" was updated.`
		});
	},

	async deleteServer({ commit }, serverId: number): Promise<void> {
		await api.deleteServer(serverId);

		commit("deleteServer", serverId);
		commit("setNotificationOptions", { type: "success", title: "Device Deleted" });
	},

	async addOrUpdateSyncSystem({ commit, dispatch, state }, syncSystem: SyncSystem): Promise<void> {
		try
		{
			let id = await api.addOrUpdateSyncSystem(syncSystem);
			if (!id) {
				return;
			}

			let existing = state.syncSystems.find(s => s.syncSystemId == id);
			if(!existing) {
				commit("setNotificationOptions", {
					type: "success",
					title: "Sync System Created",
					text: `"${syncSystem.title}" was created.`
				});

				await dispatch("fetchAllSyncSystems");
			}
			else {
				commit("setNotificationOptions", {
					type: "success",
					title: "Sync System Updated",
					text: `"${syncSystem.title}" was updated.`
				});

				commit("updateSyncSystem", syncSystem);
			}

			await dispatch("getServers");
			await dispatch("setServerFromSyncId", id);
		}
		catch (ex) {
			commit("setNotificationOptions", {
				type: "error",
				title: "Sync " + (syncSystem.syncSystemId > 0 ? "Update " :  "Creation ") + "Failed",
				text: ex.message
			});
		}
	},

	reloadCurrentServer({ commit, dispatch, state }): void {
		var server = state.serversList.find(s => s.serverID == state.currentServer.serverID)
		if (server) {
			commit("setCurrentServer", { ...server })
			dispatch("getDeviceTypeCounts", server.serverID);
		}
	},

	setServerFromSyncId({ commit, dispatch, state }, syncSystemId: number): void {
		var server = state.serversList.find(s => s.syncSystemId == syncSystemId)
		if (server) {
			commit("setCurrentServer", { ...server })
			dispatch("getDeviceTypeCounts", server.serverID);
		}
	},

	async addDevicesForServer({ commit, state }, serverConfig: ServerConfiguration): Promise<void> {
		let serverConfigResponse : ServerConfigurationResponse = null;

		try {
			serverConfigResponse = await devicesApi.createConfig(serverConfig);
		} catch (err) {
			commit("setSettingConfig", false);
		}

		if (serverConfigResponse == null) {
			return;
		}

		let serverConfigs = [];
		serverConfigs.push(serverConfig.deviceConfiguration);

		commit("setServerConfig", serverConfigs);

		const deviceTypeCounts: DeviceTypeCounts = { ...state.deviceTypeCounts };

		const newDeviceTypeCountsForCurrentServer = (await devicesApi.getDeviceTypeCounts(state.currentServer.serverID)).data;

		const diffDeviceTypeCountsForCurrentServer: DeviceTypeCounts = state.currentServer && {
			devices: newDeviceTypeCountsForCurrentServer.devices - state.currentServer.deviceTypeCounts.devices,
			cameras: newDeviceTypeCountsForCurrentServer.cameras - state.currentServer.deviceTypeCounts.cameras,
			audios: newDeviceTypeCountsForCurrentServer.audios - state.currentServer.deviceTypeCounts.audios,
			doors: newDeviceTypeCountsForCurrentServer.doors - state.currentServer.deviceTypeCounts.doors,
			outputs: newDeviceTypeCountsForCurrentServer.outputs - state.currentServer.deviceTypeCounts.outputs,
			alarms: newDeviceTypeCountsForCurrentServer.alarms - state.currentServer.deviceTypeCounts.alarms
		};

		// establish outputs and doors from relays - N.B. Assumption that this is dependent on the type property in the relay object
		let outputs : DeviceConfigurationRelay[] = [];
		let doors : DeviceConfigurationRelay[] = [];
		if (serverConfig.deviceConfiguration.relays != null) {
			outputs = serverConfig.deviceConfiguration.relays.filter(function (obj) {
				return obj.type == 0;
			});
			doors = serverConfig.deviceConfiguration.relays.filter(function (obj) {
				return obj.type != 0;
			});
		}

		// set device counts by taking away current device counts for server and then adding new device counts for server
		deviceTypeCounts.audios = (deviceTypeCounts.audios + diffDeviceTypeCountsForCurrentServer.audios);
		deviceTypeCounts.cameras = (deviceTypeCounts.cameras + diffDeviceTypeCountsForCurrentServer.cameras);
		deviceTypeCounts.doors = (deviceTypeCounts.doors + diffDeviceTypeCountsForCurrentServer.doors);
		deviceTypeCounts.outputs = (deviceTypeCounts.outputs + diffDeviceTypeCountsForCurrentServer.outputs);
		deviceTypeCounts.alarms = (deviceTypeCounts.alarms + diffDeviceTypeCountsForCurrentServer.alarms);

		commit("setDeviceTypeCounts", deviceTypeCounts);
		commit("setAlarmCounts", deviceTypeCounts.alarms);

		if (state.currentServer) {
			commit("addDeviceTypeCountsToCurrentServer", newDeviceTypeCountsForCurrentServer);
			commit("addAlarmCountsToCurrentServer", newDeviceTypeCountsForCurrentServer.alarms);
		}

		commit("setNotificationOptions", {
			type: "success",
			title: "Device Config Created",
			text: `Config was created for "${serverConfig.deviceConfiguration.name}".`
		});
		commit("setSettingConfig", false);

		commit("setDeviceConfigErrors", serverConfigResponse.errors);
	},

	async addDevice({ commit, state }, newDevice: DeviceDetails): Promise<void> {
		const { data }: { data: DeviceDetails } = await devicesApi.addDevice(newDevice);
		commit("addDevice", data);

		if(state.device.presets != null && state.device.presets.length > 0)
		{
			let presetPayload = state.device.presets.map(preset => {
				return { name: preset.title, groupId: state.device.groupID, deviceId: data.deviceID, position: preset.presetNumber } as DeviceConfigurationPresetPayload
			});

			await api.createOrUpdateCameraPresets(presetPayload);
		}

		const deviceTypeCounts: DeviceTypeCounts = { ...state.deviceTypeCounts };
		const deviceTypeCountsForCurrentServer: DeviceTypeCounts = state.currentServer && {
			...state.currentServer.deviceTypeCounts
		};

		const notificationTitle = `${DeviceTypeIdentifier[state.currentDeviceTypeIdentifier]} Created`;

		switch (state.currentDeviceTypeIdentifier) {
			case DeviceTypeIdentifier.Camera: {
				deviceTypeCounts.cameras++;

				if (state.currentServer) {
					deviceTypeCountsForCurrentServer.cameras++;
				}

				break;
			}
			case DeviceTypeIdentifier.Audio: {
				deviceTypeCounts.audios++;

				if (state.currentServer) {
					deviceTypeCountsForCurrentServer.audios++;
				}

				break;
			}
			case DeviceTypeIdentifier.Output: {
				deviceTypeCounts.outputs++;

				if (state.currentServer) {
					deviceTypeCountsForCurrentServer.outputs++;
				}

				break;
			}
			case DeviceTypeIdentifier.Door: {
				deviceTypeCounts.doors++;

				if (state.currentServer) {
					deviceTypeCountsForCurrentServer.doors++;
				}

				break;
			}
		}

		commit("setDeviceTypeCounts", deviceTypeCounts);

		if (state.currentServer) {
			commit("addDeviceTypeCountsToCurrentServer", deviceTypeCountsForCurrentServer);
		}

		commit("setNotificationOptions", {
			type: "success",
			title: notificationTitle,
			text: `"${newDevice.title}" was created.`
		});
	},

	async updateDevice({ commit, state }, updatedDevice: DeviceDetails): Promise<void> {
		const { data }: { data: DeviceDetails } = await devicesApi.updateDevice(updatedDevice);

		if (state.device.presets && state.device.presets.length > 0)
		{
			let presetPayload = state.device.presets.map(preset => {
				return { name: preset.title, groupId: state.device.groupID, deviceId: data.deviceID, position: preset.presetNumber } as DeviceConfigurationPresetPayload
			});

			await api.createOrUpdateCameraPresets(presetPayload);
		}

		if (state.currentServer && state.currentServer.serverID !== updatedDevice.serverID) {
			const deviceTypeCountsForCurrentServer: DeviceTypeCounts = {
				...state.currentServer.deviceTypeCounts
			};

			switch (state.currentDeviceTypeIdentifier) {
				case DeviceTypeIdentifier.Camera: {
					deviceTypeCountsForCurrentServer.cameras--;
					break;
				}
				case DeviceTypeIdentifier.Audio: {
					deviceTypeCountsForCurrentServer.audios--;
					break;
				}
				case DeviceTypeIdentifier.Output: {
					deviceTypeCountsForCurrentServer.outputs--;
					break;
				}
				case DeviceTypeIdentifier.Door: {
					deviceTypeCountsForCurrentServer.doors--;
					break;
				}
			}

			commit("addDeviceTypeCountsToCurrentServer", deviceTypeCountsForCurrentServer);
		}

		commit("updateDevice", data);

		const notificationTitle = `${DeviceTypeIdentifier[state.currentDeviceTypeIdentifier]} Updated`;

		commit("setNotificationOptions", {
			type: "success",
			title: notificationTitle,
			text: `"${updatedDevice.title}" was updated.`
		});
	},

	async deleteDevice({ commit, state }, device: DeviceDetails): Promise<void> {
		await devicesApi.deleteDevice(device.deviceID);
		commit("deleteDevice", device.deviceID);

		const deviceTypeCounts: DeviceTypeCounts = { ...state.deviceTypeCounts };
		const deviceTypeCountsForCurrentServer: DeviceTypeCounts = state.currentServer && {
			...state.currentServer.deviceTypeCounts
		};

		const notificationTitle = `${DeviceTypeIdentifier[state.currentDeviceTypeIdentifier]} Deleted`;

		switch (state.currentDeviceTypeIdentifier) {
			case DeviceTypeIdentifier.Camera: {
				deviceTypeCounts.cameras--;

				if (state.currentServer) {
					deviceTypeCountsForCurrentServer.cameras--;
				}

				break;
			}
			case DeviceTypeIdentifier.Audio: {
				deviceTypeCounts.audios--;

				if (state.currentServer) {
					deviceTypeCountsForCurrentServer.audios--;
				}

				break;
			}
			case DeviceTypeIdentifier.Output: {
				deviceTypeCounts.outputs--;

				if (state.currentServer) {
					deviceTypeCountsForCurrentServer.outputs--;
				}

				break;
			}
			case DeviceTypeIdentifier.Door: {
				deviceTypeCounts.doors--;

				if (state.currentServer) {
					deviceTypeCountsForCurrentServer.doors--;
				}

				break;
			}
		}

		commit("setDeviceTypeCounts", deviceTypeCounts);

		if (state.currentServer) {
			commit("addDeviceTypeCountsToCurrentServer", deviceTypeCountsForCurrentServer);
		}

		commit("setNotificationOptions", { type: "success", title: notificationTitle });
	},

	async getDeviceTypes({ commit }): Promise<void> {
		const deviceTypes: ServerType[] = await devicesApi.getDeviceTypes();
		commit("setServerTypes", deviceTypes);
	},

	async fetchDevicesForAllGroupsByType(
		{ commit },
		{ params, cancelTokenSource }: CancellableQuery<GetDevicesByTypeParams>
	): Promise<void> {
		const { data }: { data: DeviceDetailsPagedResponse } = await devicesApi.getDevicesForAllGroupsByType(params, cancelTokenSource);
		commit("setDevices", data.data);
		commit("setTotalDevices", data.totalRecords);
	},

	async fetchAllSyncSystems({ commit }): Promise<void> {
		const response = await api.retrieveSyncSystems();
		commit("setSyncSystems", response.data);
		commit("setTotalSyncSystems", response.totalRecords);
	},

	async fetchSyncSystems(
		{ commit, state },
		{ params, cancelTokenSource }: CancellableQuery<PaginatedSearchQueryParams>
	): Promise<void> {
		const response = await api.retrieveSyncSystems({ params, cancelToken: cancelTokenSource.token });
		commit("setSyncSystems", response.data);
		commit("setTotalSyncSystems", response.totalRecords);
	},

	async fetchSyncSystemCount({ commit }): Promise<void> {
		const response = await api.retrieveSyncSystemsCount();
		commit("setTotalSyncSystems", response);
	},

	async fetchDevicesByType(
		{ commit },
		{ groupId, deviceTypeId, filter }: GetDeviceForGroupByTypeParams
	): Promise<void> {
		const data: DeviceDetails[] = await api.getDevicesByType(groupId, deviceTypeId, filter);
		commit("setDevices", data);
	},

	async fetchDevicesByTypeAndAppend(
		{ commit },
		{ groupId, deviceTypeId, filter }: GetDeviceForGroupByTypeParams
	): Promise<void> {
		const data: DeviceDetails[] = await api.getDevicesByType(groupId, deviceTypeId, filter);
		commit("appendDevices", data);
	},

	async fetchDevicesByTypeAndAppendNoFilter(
		{ commit }, { groupId, params }
	): Promise<void> {
		const { data }: { data: DeviceDetails[] } = await api.devicesByGroup(groupId, params);
		commit("appendDevices", data);
	},

	async fetchDeviceById(
		{ commit },
		{ deviceId, params }: { deviceId: number; params: GetDeviceParams }
	): Promise<void> {
		commit("setDevice", await api.loadDeviceById(deviceId, params));
	},

	async getServerLineProfiles({ commit }): Promise<void> {
		const { data } = await api.fetchServerLineProfiles();
		commit("setServerLineProfiles", data);
	},

	async getDevicesByTitle({ commit }, deviceTitles: string[]): Promise<void> {
		const { data } = await devicesApi.getDevicesByTitle(deviceTitles);
		commit("setDevices", data);
	},

	async getCameraPresets({ commit }, device: DeviceDetails): Promise<DeviceConfigurationPreset[]> {
		if (device.deviceTypeID !== DeviceType.Camera) {
			return [];
		}

		const presets = await api.getCameraPresets(device);
		return presets;
	},

	async createOrUpdateCameraPreset({ commit, state }, presetConfigPayload: DeviceConfigurationPresetPayload): Promise<boolean> {
		let presets = !state.device.presets ? [] : [...state.device.presets];

		let existingPreset = presets.find(p => p.presetNumber === presetConfigPayload.position) as DevicePreset;

		if (existingPreset)
		{
			existingPreset.title = presetConfigPayload.name;
		}
		else
		{
			presets.push({ title: presetConfigPayload.name, presetNumber: presetConfigPayload.position } as DevicePreset );
		}

		commit("setDevicePresets", presets);
		return true;
	},

	async deleteCameraPreset({ commit, state }, presetConfigPayload: DeviceConfigurationPresetPayload): Promise<boolean> {
		// initially try and delete it from the record.
		let result: boolean;

		// only try and delete the preset if the device has an ID - if it doesn't the Camera does not exist yet.
		if (state.device.deviceID){
			result = await api.deleteCameraPreset(presetConfigPayload);
		}

		let presets = !state.device.presets ? [] : [...state.device.presets];
		presets.removeWhere(p => p.presetNumber === presetConfigPayload.position);

		commit("setDevicePresets", presets);
		return result === true;
	},
};
