import { GetterTree } from "vuex";
import { SubscriptionState } from "./types";
import { RootState } from "@/store/types";

export const getters: GetterTree<SubscriptionState, RootState> = {
	getSubscriptionType: state => {
		switch (state.subscription.type) {
			case 0:
				return "Standard";
			case 1:
				return "Stubbed";
			case 2:
				return "System Admin";
			default:
				return "Custom";
		}
	},
	isSubscriptionActive: state => {
		if(state.isLoaded && !state.subscription.isActiveState){
			return false;
		}
		return true;
	}
};
