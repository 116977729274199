
import { Component, Mixins } from "vue-property-decorator";
import GenericTable, { TableHeader } from '../table/generic-table.vue';
import GenericUpdateModal, { ModalItem } from '../table/generic-update-modal.vue';
import AssetType from '@/types/sv-data/AssetType';
import api from '@/services/api.service';
import VSwatches from 'vue-swatches'
import PaginatedSearch from "@/mixins/PaginatedSearch";
import { PaginatedSearchQueryParams, FeaturesList } from "@/store/types";
import { Getter } from "vuex-class";
import { get } from "lodash";
import vselect3 from "vselect3";

export interface AssetTypeEx extends AssetType {
	externalIdsArray?: string[]
}

@Component({
	components: {
        "generic-table": GenericTable,
		"generic-update-modal": GenericUpdateModal,
        "color-swatch" : VSwatches,
    }
})
export default class AssetTypeSetup extends Mixins(PaginatedSearch) {
	@Getter("getFeaturesList") private featuresList: FeaturesList;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

    private get columns (): TableHeader[] {
		const columns : TableHeader [] = [
			{
				title: "Asset Type Title",
				key: "title",
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The asset type title",
				searchable: true,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
			{
				title: "IconColor",
				key: "iconColor",
				order: 2,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Icon color of the asset type while on the map",
				searchable: false,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				useCustomCell: true
			},
			{
				title: "Mobile User",
				key: "canBeUser",
				order: 3,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "If the asset type is for mobile users only.",
				searchable: false,
				visible: true,
				dataType: "checkbox",
				isTermLabel: true,
				sortable: true,
				sortKey: "IsMobileUser",
			},
			{
				title: "Visible on Map",
				key: "shownOnMap",
				order: 4,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Flag indicating if an asset can be viewed on a map",
				searchable: false,
				visible: true,
				dataType: "checkbox",
				isTermLabel: true,
				sortable: true,
				sortKey: "IsVisible",
			},
			{
				title: "Event Shareable",
				key: "shareable",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Flag indicating if an asset type can be shared to an event",
				searchable: false,
				visible: true,
				dataType: "checkbox",
				isTermLabel: true,
				sortable: true,
				sortKey: "IsShareable",
			},
			{
				title: "External Ids",
				key: "externalIdsArray",
				order: 6,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "External ids to match during syncing",
				dataType: "list",
				useCustomCell: true,
				visible: true,
				disable: !this.syncAssetFeatureEnabled,
			}
    	];

		return columns;
	}

    public get modalItems (): ModalItem[] {
		return [
			{
				title: "Title",
				key: "title",
				dataType: "text",
				maxLength: 250,
				required: true,
                placeholder: "Enter an asset title",
				readOnlyMethod: (item) => item.suiteControlled,
            },
            {
				title: "Icon Color",
				key: "iconColor",
				dataType: "component",
				data: VSwatches,
				readOnly: false,
				props: {
					"swatch-size": 20,
					"trigger-style":{ width: '30px', height: '30px', borderRadius: '5px', border : '2px solid white', cursor: 'context-menu' },
					"show-fallback": true,
					"fallback-input-type":"color",
				}
			},
            {
				title: "Mobile User",
				key: "canBeUser",
				dataType: "checkbox",
				readOnly: this.selectedAssetType ? this.selectedAssetType.hasLinkedAssets : false,
				description:  (this.selectedAssetType ? this.selectedAssetType.hasLinkedAssets : false) ?
				"Mobile user flag can not be changed as this asset type has linked users, if checked asset type can only be assigned to mobile users."
				: "If checked asset type can only be assigned to mobile users",
				readOnlyMethod: (item) => item.suiteControlled,
			},
            {
				title: "Visible on Map",
				key: "shownOnMap",
				dataType: "checkbox",
				readOnly: false,
				csvComponent:'input',
			},
            {
				title: "Event Shareable",
				key: "shareable",
				dataType: "checkbox",
				readOnly: false,
				description: "Can asset type be shared to an event"
			},
			{
				title: "External Ids",
				key: "externalIdsArray",
				dataType: "component",
				data: vselect3,
				props: {
					"create-option": id => id,
					placeholder: "External Ids",
					taggable: true,
					appendToBody: true,
					multiple: true,
				},
				required: false,
				description: "External ids to match during syncing",
				visible: this.syncAssetEnabled,
			},
        ]
    }

    private assetTypes: AssetType[] = [];
    private selectedAssetType: AssetType = null;
	private totalRecords: number = 0;
	private isLoading: boolean = false;

	private get isSuiteEnabled(): boolean {
        return get(this.featuresList, ["Suite"]);
    }

    private async putAssetType(assetType: AssetTypeEx): Promise<void> {
		assetType.live = true;
		let externalIdsString : string = "";

		try {
			externalIdsString = JSON.stringify(assetType.externalIdsArray);
		}
		catch (ex){
			console.log("Unexpected error processing External Ids: " + ex);
		}

		assetType.externalIds = externalIdsString;
		await api.createOrUpdateAssetType(assetType);
		this.updateData(this.mostRecentSearchParams);
    }

    private async deleteAssetType(assetType: AssetType): Promise<void> {
		await api.deleteAssetType(assetType.assetTypeId);
		this.updateData(this.mostRecentSearchParams);
    }

    private async onModalOpen(value: AssetType | null): Promise<void> {
		this.selectedAssetType = value;
    }

	private async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		try {
			this.isLoading = true;

			const request = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);
			let assetTypesWithPagination = await api.getAssetTypes(request);
			this.totalRecords = assetTypesWithPagination.totalRecords;
			this.assetTypes = this.mapAssetTypes(assetTypesWithPagination.data)
		}
		catch (ex) {
			console.log("Unexpected error fetching asset types: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	private mapAssetTypes(assetTypes: AssetType[]): AssetTypeEx[] {
		var externalIdsList = [];
		return assetTypes.map(at => {
				at.iconColor = at.iconColor ? at.iconColor: "black";
				at.hideDelete = at.suiteControlled && this.isSuiteEnabled;
				externalIdsList = this.parseExternalIds(at.externalIds);
				return {
					...at,
					externalIdsArray: externalIdsList
				} as AssetTypeEx
			});
	}

	private parseExternalIds(externalId : string): string[]  {
		var externalIds: string[] = [];

		try
		{
			externalIds = JSON.parse(externalId);
		}
		catch(ex)
		{
			console.log("Unexpected error processing External Ids: " + ex);
		}

		return externalIds;
	}

	private get syncAssetEnabled(): boolean {
		return this.getFeature(["Mobile", "FieldOps", "SyncedAssets"]);
	}

	private get syncAssetFeatureEnabled(): boolean {
		return get(this.featuresList, ["Mobile", "FieldOps", "SyncedAssets"]);
	}

	private async mounted(): Promise<void> {
		await this.updateData();
    }
}
