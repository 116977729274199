
import { Component, Prop, Watch, Vue, Emit } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import vSelect from "vue-select";
import { ActionPlanTask } from "@/store/tasks/types";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { get, debounce } from "lodash";
import { ContactRole } from '@/store/user-management/types';
import { TasksService } from '@/services/tasks.service';
import { PaginatedSearchRequest } from '@/mixins/PaginatedSearch';

const tasksService = new TasksService();

@Component({
	components: {
		"vue-select": vSelect
	},
	mixins: [validationMixin],
	validations: {
		selectedCaller: {
			value: {
				required
			},
			name: {
				required
			},
			required
		}
	}
})
export default class CallContactSetup extends Vue {
	@Prop()
	public value!: ActionPlanTask;

	public callList: any[] = [
		{ value:  undefined, name: "Contact Role" },
		/*{ value: "CallList", name: "Call List" },
		{ value: "Fire Dept", name: "Fire Dept" },
		{ value: "Police", name: "Police" }*/
	];

	private selectedCaller: any = {};

	private filteredContactRoles: ContactRole[] = [];

	private contactRoleSearchTimer: NodeJS.Timeout = null;

	public async mounted() {
		this.$v.$reset();

		this.loadFromTask();

		// Trigger initial validation
		this.onValidationChange(this.$v.$invalid);

		await this.searchContactRoles();
	}

	private debouncedContactRolesSearch(value?: any): void {
		clearTimeout(this.contactRoleSearchTimer);

		this.contactRoleSearchTimer = setTimeout(async () =>
		{
			await this.searchContactRoles(value);
		}, 250);
	}

	private async searchContactRoles(value?: any) {
		let paramsToUse:PaginatedSearchRequest["params"] = {
			page: 1,
			pageSize: 50,
		};

		if (value && typeof value === 'string' && value.length > 0)
		{
			paramsToUse.searchTerm = value
		}

		try
		{
			this.filteredContactRoles = await tasksService.getContactRoles({
				params: {...paramsToUse}
			} as PaginatedSearchRequest);
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Error",
				text: `Unable to load contact roles`
			});
			this.filteredContactRoles = [];
		}
	}

	@Watch("selectedCaller", { deep: true, immediate: true })
	public callContactSettingsChange(newValue) {
		this.$v.$touch();

		if (!this.$v.$error) {
			this.onInput();
		}
	}

	/**
	 * Fires an input event containing the new task data.
	 */
	@Emit("input")
	private onInput() {
		return {
			...this.value,
			taskText: `Call ${this.selectedCaller.name}${this.selectedCaller.value.role ? ` : ${this.selectedCaller.value.role}` : ""}`,
			taskData: {
				...this.selectedCaller
			}
		};
	}

	/**
	 * Watcher/emitter for the validation state of this component,
	 * bubbles up vuelidate's validation state when it changes.
	 */
	@Watch("$v.$invalid")
	private onValidationChange($invalid: boolean) {
		this.$emit("validate-task", !$invalid);
	}

	private loadFromTask() {
		this.selectedCaller = get(this, ["value", "taskData"], {});
	}
}
