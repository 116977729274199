
import { Component, Vue, Prop } from "vue-property-decorator";
import AdvancedMapFilters from "./AdvancedMapFilters.vue";
import ExternalMapLayerSelector from "@/components/external-map-layer/ExternalMapLayerSelector.vue";
import { Getter } from "vuex-class";
import { FeaturesList, UserPermissions } from "@/store/types";
import { get } from "lodash";

@Component({
	components: {
		"advanced-filter": AdvancedMapFilters,
		"external-map-layer-selector" : ExternalMapLayerSelector
	}
})
export default class MapDataSources extends Vue {

	@Getter("getPermissions") permissions: UserPermissions;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	private get isExternalMapLayersEnabled(): boolean {
		return (
			get(this.featuresList, ["ExternalMapLayers"]) &&
			(this.permissions.canViewExternalMapLayers || this.permissions.canEditExternalMapLayers)
		);
	}
}
