import { CancelTokenSource } from "axios";

export interface AlarmPointState {
	selectedArea: any,
	displayResponseId: number | null,
	responses: any[],
	alarmPointManagerOpen: boolean,
	cancelTokenSource: CancelTokenSource | null,
	selectedResponse: any,
	disableAutomaticLoad: boolean
}

export enum OnTestScheduleType {
	ForPeriod = 1,
	UntilDate = 2
}

export class OnTestRequest {
	groupId?: number;
	responseId?: number;

	hours: number | null;
	minutes: number | null;
	date: Date | null;
	time?: string;
	scheduleType: number;
	auditMode: boolean;
	onTestNote: string;
}