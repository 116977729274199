
import { Component, Vue } from "vue-property-decorator";
import NavHeader from "@/components/NavHeader.vue";
import AreaTable from "@/views/AreaSetup/AreaTable.vue";

@Component({
	components: {
		"nav-header": NavHeader,
		"area-table" : AreaTable,
	}
})
export default class AreaSetupPage extends Vue {
}
