
	import { Component, Vue, Prop, Watch, Emit, Mixins } from "vue-property-decorator";
	import moment from "moment";
	import { namespace, Getter, Action } from "vuex-class";
	import { get } from "lodash";
	import { Group } from "@/store/eventqueue/types";
	import { Response } from "@/store/alarm-masking/types";
	import api from "@/services/api.service";
	import { FeaturesList, UserPermissions, VueBootstrapField, VueBootstrapFieldTableSortEvent, VueBootstrapFieldEx, MaskingModalPaginatedSearchQueryParams, MaskingTypeEnum } from "@/store/types";
	import AlarmMaskDurationInput from "@/components/alarm-masking/AlarmMaskDurationInput.vue";
	import { debounce } from "lodash";
    import { truncateString } from '@/filters';
	import MaskingModalGroupBadge from '@/components/alarm-masking/MaskingModalGroupBadge.vue';
	import AreaTreeSelect from '@/components/form/AreaTreeSelect.vue';
	import { AreaNode } from "@/types/sv-data/groups/AreaNode";
	import { AreaMaskingCount } from "@/store/areas/types";
	import MaskingModalFilteredAreas from "./MaskingModalFilteredAreas.vue";
	import ConfirmationModal from "@/components/mobile-raise-templates/ConfirmationModal.vue";
	import maskingApi from "@/services/api.masking.service";
	import MaskType from "@/types/sv-data/enums/MaskType";
	import MaskingRequest from "@/types/sv-data/masking/MaskingRequest";
	import { MaskOption } from '@/store/alarm-masking/types';
	import vSelect3 from "vselect3";

	import { Constants } from "@/types/Constants";
	import PaginatedSearch from "@/mixins/PaginatedSearch";
	import ResponseDtoPagedResponse from "@/types/sv-data/responses/ResponseDtoPagedResponse";
	import { getDebouncePeriod } from '@/utils/debounce-helper';

	const Eventqueue = namespace("eventqueue");
	const Areas = namespace("areas");



	@Component({
		filters:  { truncateString },
		components: {
			"area-tree-select": AreaTreeSelect,
			"alarm-mask-duration-input": AlarmMaskDurationInput,
			"masking-modal-group-badge": MaskingModalGroupBadge,
			"masking-modal-filtered-areas": MaskingModalFilteredAreas,
			"confirmation-modal": ConfirmationModal,
			"v-select-3": vSelect3
		}
	})
	export default class MaskingModal extends Mixins(PaginatedSearch) {
		@Prop({default: false, type: Boolean}) public readonly value;
		@Prop() public preSelectedAlarmFilter: string;
		@Prop() public groupId: number;
		@Prop() public eventId: number;
		@Prop() public responseId: number;
		@Prop({ default: () => { return ["Any"]}  }) private requiredPermissions: string [];

		@Eventqueue.Action getSelectedGroupByIDSetState: ({groupID, state}: {groupID: number, state: string}) => Promise<void>;
		@Eventqueue.State("selectedGroup") selectedArea: Group;
		@Eventqueue.Action updateSelectedGroup: (selectedGroup: Group) => void;

		@Getter getPermissions: UserPermissions;
		@Getter getDefaultMaskValue: number;
		@Getter("getFeaturesList") featuresList: FeaturesList;
		@Getter getDefaultMaskAction: boolean;

		@Action getTenantSystemPrefs: (live: boolean) => Promise<void>;

		@Areas.Action fetchAreaMaskingCount: () => Promise<void>;
		@Areas.State areaMaskingCount: AreaMaskingCount;

		// Private Fields
		private isModalShown = false;
		private selectedAlarmFilter: string = "All";
		private selectedAreaAlarms: Response[] = [];
		private groupFilter: string = "";
		private alarmFilter: string = "";
		private alarmFilterInput: string = "";
		private areaMaskDuration: number = 10;
		private maskAllDuration: number = 10;
		private isLoadingAreaAlarms: boolean = false;
		private isUpdatingArea: boolean = false;
		private isMaskingAlarm: boolean = false;
		private isLoadingAreas: boolean = false;
		private selectedAreaNode: AreaNode = null;
		private isMaskDurationConfirmationVisible: boolean = false;
		private responsesWithInvalidMasking: Response[] = [];
		private maskOptions: MaskOption[] = [{ title: `Ignore`, value: false }, { title: `AutoHandle`, value: true }];
		private selectedMaskAction: boolean = false;
		private maxMaskingDurationLimit: number = Constants.MAX_MASKING_DURATION

		private totalRecords: number = 0;
		private totalArmed: number = 0;
		private totalUnarmed: number = 0;
		private recordsPage: number = 1;
		private recordsPageSize: number = 25;
		private recordsPageSizeMax: string = "200";
		private recordsPageSizeUpdated: string = "25";
		private sortBy: string = "title";
		private sortDesc: boolean = false;
		private isSearchTermResponseId :boolean = false;

		private async mounted(): Promise<void> {
			await this.getTenantSystemPrefs(true);
			this.areaMaskDuration = this.getDefaultMaskValue;
			this.selectedMaskAction = this.getDefaultMaskAction;
			await this.onVModelUpdated(this.value);
		}

		private destroyed() {
			this.isModalShown = false;
		}

		// Public Methods
		public clearFilter(): void {
			this.groupFilter = "";
		}

		public async forceUpdateAreaAlarms(): Promise<void> {
			await this.updateSelectedAreaAlarms();
			await this.updateSelectedAreaState();
		}

		private async toggleAreaMasking(): Promise<void> {
			this.isUpdatingArea = true;

			try {
				if (this.selectedArea.armed) {
                    await maskingApi.maskArea(this.selectedArea.groupID, this.areaMaskDuration, 0, "", this.selectedMaskAction);
				} else {
					await maskingApi.unmaskArea(this.selectedArea.groupID);
				}
			} catch {
				this.isUpdatingArea = false;
				return;
			}

			this.isUpdatingArea = false;
		}

		private async toggleAlarmMasking(alarm: Response) {
			this.isMaskingAlarm = true;
			try {
				if (alarm.armed) {
					var alarmMaskDurationInput = parseInt(alarm.maskDurationInput);
					var maxAlarmMaskDuration = this.getPermissions.canDisarmSitesExtended ? this.maxMaskingDurationLimit : alarm.maskDurationLimit;
					var alarmMaskDuration = alarmMaskDurationInput > maxAlarmMaskDuration ? maxAlarmMaskDuration : alarmMaskDurationInput;

					await maskingApi.maskAlarm({
						responseID: alarm.responseID,
						groupID: alarm.groupID,
						eventID: alarm.eventID,
						disarmReasonID: null,
						disarmNote: "",
						maskType: MaskType.duration,
						hours: 0,
						minutes: alarmMaskDuration,
						maskIsAutoHandle: this.selectedMaskAction
					} as MaskingRequest);
				}
				else {
					await maskingApi.unmaskAlarm(alarm.eventID, alarm.responseID, alarm.groupID);
				}
			}
			catch {
				this.isMaskingAlarm = false;
				return;
			}

			const responses = await api.responsesByArea(this.selectedArea.groupID);
			const totalAlarmMaskCount = responses.filter(r => !r.armed).length;

			let indexOfAlarmToUpdate = this.selectedAreaAlarms.findIndex(a => a.responseID === alarm.responseID);
			if (indexOfAlarmToUpdate != -1) {
				alarm.armed = !alarm.armed;
				if (!alarm.armed) {
					alarm.toggleArmAt = moment.utc().add(alarm.maskDurationInput, 'm').toDate();
				} else {
					alarm.toggleArmAt = null;
				}

				Vue.set(this.selectedAreaAlarms, indexOfAlarmToUpdate, alarm);
			}
			else {
				this.$notify({
					type: "error",
					title: "Failed to toggle alarm masking",
					text: "Could not find alarm to mask, please try again or contact support for assistance"
				});
				return;
			}

			const isLastAlarmToBeUnmasked = totalAlarmMaskCount === 1 && alarm.armed;
			const isLastAlarmToBeMasked = totalAlarmMaskCount === responses.length - 1 && !alarm.armed;
			const isFirstAlarmToBeUnmasked = totalAlarmMaskCount === responses.length && alarm.armed;
			const isFirstAlarmToBeMasked = totalAlarmMaskCount === 0;

			if (isLastAlarmToBeUnmasked || isFirstAlarmToBeMasked || isFirstAlarmToBeUnmasked || isLastAlarmToBeMasked) {

				if (isFirstAlarmToBeMasked || isFirstAlarmToBeUnmasked) {
					this.updateSelectedGroup({...this.selectedArea, state: "Partially Masked"});
				}

				if (isLastAlarmToBeUnmasked) {
					this.updateSelectedGroup({...this.selectedArea, state: "Unmasked"});
				}

				if (isLastAlarmToBeMasked) {
					this.updateSelectedGroup({...this.selectedArea, state: "Masked"});
				}
			}

			if (this.selectedArea.groupID == this.groupId) {
				await this.updateResponses(this.groupId);
			}

			this.isMaskingAlarm = false;
		}

		private async unmaskAllAlarmsInArea() {
			this.isMaskingAlarm = true;

			try {
				await maskingApi.unmaskAllAlarmsInArea(this.selectedArea.groupID);
				await this.updateSelectedAreaAlarms();
				this.updateSelectedGroup({...this.selectedArea, state: "Unmasked"});
			}
			catch {
				this.isMaskingAlarm = false;
				return;
			}

			this.isMaskingAlarm = false;
		}

		private async maskAllAlarmsInArea() {
			const responses = await api.responsesByArea(this.selectedArea.groupID);

			this.responsesWithInvalidMasking = responses.filter(response => this.maskAllDuration > response.maskDurationLimit)
			if (this.responsesWithInvalidMasking.length > 0 && !this.getPermissions.canDisarmSitesExtended)
			{
				this.isMaskDurationConfirmationVisible = true;
				return;
			}

			await this.applyMaskingToAllAlarmsInArea();
		}

		private async applyMaskingToAllAlarmsInArea() {
			this.isMaskingAlarm = true;
			try {
				await maskingApi.maskAllAlarmsInArea(this.selectedArea.groupID, this.maskAllDuration, 0, "", this.selectedMaskAction);
				await this.updateSelectedAreaAlarms();
				this.updateSelectedGroup({...this.selectedArea, state: "Masked"});
			}
			catch {
				this.isMaskingAlarm = false;
				return;
			}

			this.isMaskingAlarm = false;
		}

		private close() {
			this.$emit("input", false);
			this.$emit("closed");
			this.isModalShown = false;
			this.updateSelectedGroup(null);
			this.selectedAreaAlarms = [];
		}

		private getAlarmsToShow():  Response[] {
			if(this.filteredAreaAlarms === null) {
				return new Response[0];
			}

			return this.filteredAreaAlarms.slice(0, this.recordsPageSize);
		}

		private calculateTableHeight() {
			let result = document.body.clientHeight / 100 * 35;
			return `${result}px`;
		}

		private dateAsLocalTime(date: Date): string {
			let result;
			let type = typeof(date);
			if (type === "object") {
				result = moment(date).local().format("L LT");
			}
			else {
				result = moment(date + "Z").local().format("L LT");
			}
			return result;
		}

		private dateAsUtc(date: Date): string {
			let result;
			let type = typeof(date);
			if (type === "object") {
				result = moment.utc(date).format("L LT");
			}
			else {
				result = moment.utc(date + "Z").format("L LT");
			}
			return result;
		}

		private setAlarmFilter() {
			if (this.responseId) {
				this.isSearchTermResponseId = true;
				this.alarmFilterInput = this.responseId.toString();
				this.alarmFilter = this.responseId.toString();
				this.focusOnAlarmSearch();
			}
		}

		private focusOnAlarmSearch() {
			this.$nextTick(() => {
				(this.$refs.alarmFilterSearch as HTMLElement).focus();
			});
		}

		private async updateSelectedAreaAlarms(): Promise<void> {
			if(!this.isAlarmMaskingEnabled){
				return;
			}

			this.isLoadingAreaAlarms = true;

			try {
				await this.updateResponses(this.selectedArea.groupID);
			}
			catch {
				this.isLoadingAreaAlarms = false;
			}

			this.isLoadingAreaAlarms = false;
		}

		private async groupSelected(selectedGroup: AreaNode): Promise<void> {
			if (selectedGroup && selectedGroup.id) {
				await this.getSelectedGroupByIDSetState({groupID: selectedGroup.id, state: selectedGroup.state });
				if (this.selectedArea) {
					await this.updateSelectedAreaAlarms();
					this.$set(this, "selectedAreaNode", selectedGroup);
				}
			} else {
				this.updateSelectedGroup(null);
			}
		}

		private confirmAreaMaskingChange(newAreaMaskingCount: AreaMaskingCount, oldAreaMaskingCount: AreaMaskingCount): boolean {
			if (!newAreaMaskingCount || !oldAreaMaskingCount) {
				return true;
			}

			return (newAreaMaskingCount.totalGroups !== oldAreaMaskingCount.totalGroups) ||
			    	(newAreaMaskingCount.masked !== oldAreaMaskingCount.masked) ||
			    	(newAreaMaskingCount.partiallyMasked !== oldAreaMaskingCount.partiallyMasked) ||
			    	(newAreaMaskingCount.unmasked !== oldAreaMaskingCount.unmasked);
		}

		private async getLatestSelectedNode(): Promise<void> {
			if (this.selectedArea && this.selectedArea.groupID) {
				const node = await api.getGroupTreeNode(this.selectedArea.groupID, this.requiredPermissions);
				await this.groupSelected(node);
			}
		}

		private async confirmMasking(): Promise<void> {
			this.isMaskDurationConfirmationVisible = false;
			this.responsesWithInvalidMasking = [];
			await this.applyMaskingToAllAlarmsInArea();
		}

		private cancelMasking(): void {
			this.isMaskDurationConfirmationVisible = false;
			this.responsesWithInvalidMasking = [];
		}

		private async unmaskAdvancedAlarms(): Promise<void> {

			if (this.selectedResponses.length > 0){
				for (var response of this.selectedResponses) {
					if (!response.armed){
    					await this.toggleAlarmMasking(response);
					}
				}
			}
			else
			{
				await this.unmaskAllAlarmsInArea();
			}
		}

		private async updateSelectedAreaState(): Promise<void> {
			const responses = await api.responsesByArea(this.selectedArea.groupID);
			const totalAlarmMaskCount = responses.filter(r => !r.armed).length;

			const isArmed = totalAlarmMaskCount === 0;
			const isMasked = totalAlarmMaskCount === responses.length;

			if (isMasked) {
				this.updateSelectedGroup({...this.selectedArea, state: "Masked"});
				return;
			}

			if (isArmed) {
				this.updateSelectedGroup({...this.selectedArea, state: "Unmasked"});
				return;
			}

			this.updateSelectedGroup({...this.selectedArea, state: "Partially Masked"});
		}

		// Getter Methods
		public get isAlarmMaskingEnabled(): boolean {
			return get(this.featuresList, ["Alarms", "Masking"], false) &&
				(this.getPermissions.canDisarmSites || this.getPermissions.canDisarmSitesExtended);
		}

		public get isMaskAreaEnabled(): boolean {
			return get(this.featuresList, ["Alarms", "Masking", "Areas"], false);
		}

		private get isMaskingAdvancedEnabled(): boolean {
			return get(this.featuresList, ["Alarms", "Masking", "Advanced"], false);
		}

		private get isMaskingFilterEnabled(): boolean {
			return get(this.featuresList, ["Alarms", "Masking", "Filter"], false);
		}

		private get isUpdatingAlarmsInModal(): boolean {
			return this.isLoadingAreaAlarms|| this.isMaskingAlarm || this.isUpdatingArea;
		}

		private get selectedResponses(): Response[] {
			return this.filteredAreaAlarms.filter(response => response.isAlarmSelected);
		}

		private get filteredAreaAlarms() {
			let result = [];

			if (!this.isModalShown) {
				this.isLoadingAreaAlarms = false;
				return result;
			}

			result = [...this.selectedAreaAlarms];

			result.forEach(a => {
				a.maskDurationInput = this.getDefaultMaskValue.toString();
				a.isMasked = !a.armed;
			});

			return result;
		}

		@Watch("selectedAlarmFilter")
		public async onSelectedAlarmFilterChanged(): Promise<void> {
			this.recordsPage = 1;
			this.updateResponses(this.selectedArea.groupID);
		}

		private get maxAreaMaskDuration(): number {
			return this.getPermissions.canDisarmSitesExtended ? this.maxMaskingDurationLimit : this.selectedArea.maskDurationLimit;
		}

		private get isAreaAlarmsDisplayed(): boolean {
			if (!this.selectedAreaAlarms) {
				return false;
			}

			if (!this.isMaskAreaEnabled) {
				return true;
			}

			if (this.isMaskAreaEnabled && !this.selectedArea.armed) {
				return false;
			}

			return true;
		}

		private get isAreaMaskDurationDisplayed(): boolean {
			return this.isMaskAreaEnabled && this.selectedArea.state === "Masked" && !this.selectedArea.armed
		}

		private get alarmMaskingTableColumns(): VueBootstrapFieldEx[] {
			return [
			{
				label: 'Selected',
				key: 'isAlarmSelected',
				visible: this.isMaskingAdvancedEnabled
			},
			{
				key: 'title',
				label: 'Name',
				sortable: true,
				visible: true,
				keySort: "title",
			},
			{
				key: 'toggleArmAt',
				label: 'State',
				sortable: this.selectedAlarmFilter !== 'Unmasked',
				visible: true,
				keySort: "toggleArmAt",
			},
			{
				label: 'Action',
				key: 'action',
				visible: !this.isMaskingAdvancedEnabled
			}
			]
		}

		private get visibleFields(): VueBootstrapField[] {
			return this.alarmMaskingTableColumns.filter(c => c.visible);
		}

		private get isChangeMaskActionEnabled(): boolean {
			return get(this.featuresList, ["TenantSettings", "ChangeMaskAction"], false);
		}

		private get canChangeMaskAction(): boolean {
			return this.getPermissions.canChangeMaskAction;
		}

		private async updateResponses(groupId: number): Promise<void> {
			if (this.isLoadingAreaAlarms) {
				return;
			}
			var responseData: Response[] = null;
			try
			{
				const isResponseId = this.isSearchTermResponseId;
				this.isLoadingAreaAlarms = true;

				const sortBy = this.alarmMaskingTableColumns.find(f => f.key == this.sortBy)?.keySort;

				const queryParams: MaskingModalPaginatedSearchQueryParams = {
					page: this.recordsPage,
					pageSize: this.recordsPageSize,
					sortBy: sortBy,
					sortDesc: this.sortDesc,
					searchTerm: this.alarmFilter,
					filterMaskingType: this.getFilterMaskingType()
				};

				const cancellableQuery = this.generateNewPaginatedSearchRequest(queryParams);

				if(this.isSearchTermResponseId) {
					cancellableQuery.params.isResponseId = true;
				}

				const result: ResponseDtoPagedResponse = await api.getPagedResponsesByArea(groupId, cancellableQuery);
				const responseData = result.data as Response[];

				this.selectedAreaAlarms = responseData;

				this.totalRecords = result.totalRecords;
				this.totalArmed = result.totalArmed;
				this.totalUnarmed = result.totalUnarmed;


				if(isResponseId && this.totalRecords == 1) {
					this.alarmFilterInput = responseData[0].title;
					this.alarmFilter = responseData[0].title;
					await this.$nextTick();
				}
			}
			catch (ex) {
				console.error("Unexpected error searching alarms: " + ex);
			}
			finally {
				this.isLoadingAreaAlarms = false;
				this.isSearchTermResponseId = false;
			}
    	}

		private getFilterMaskingType(): MaskingTypeEnum {
			switch(this.selectedAlarmFilter) {
				case "Unmasked":
					return MaskingTypeEnum.Armed
				case "Masked":
					return MaskingTypeEnum.Unarmed
				default:
					return MaskingTypeEnum.All
			}
		}

		private handleSearchInput = debounce(async (searchTerm: string): Promise<void> => {
			await this.performSearch(searchTerm);
		}, getDebouncePeriod());

		private async performSearch(searchTerm: string): Promise<void> {
			this.alarmFilter = searchTerm;
			this.recordsPage = 1;
			await this.updateResponses(this.selectedArea.groupID);
			this.focusOnAlarmSearch();
		}

		private removeNonDigitsFromString(value: string): string {
			if (!value)
				return "";

			const digitFind = value.match(/\d+/g);
			if (!digitFind)
				return "";

			if (parseInt(digitFind.join("")) > parseInt(this.recordsPageSizeMax)) {
				this.recordsPageSizeUpdated = this.recordsPageSizeMax;
			}

			return digitFind.join("");
		}

		private async onRecordsPerPageClick(): Promise<void> {
			if (parseInt(this.recordsPageSizeUpdated) !== this.recordsPageSize) {
				this.recordsPage = 1;
			}

			this.recordsPageSize = parseInt(this.recordsPageSizeUpdated);
			this.onPageClick(this.recordsPage);
		}

		private async onPageClick(page: number): Promise<void> {
			if (this.isLoadingAreaAlarms)
			{
				return;
			}

			this.recordsPage = page || this.recordsPage;

			await this.updateResponses(this.selectedArea.groupID);
		}

		private async onSortChange(event: VueBootstrapFieldTableSortEvent): Promise<void> {
			if (this.isLoadingAreaAlarms) {
				return;
			}

			this.sortBy = event.sortBy || this.sortBy;
			this.sortDesc = event.sortDesc;

			await this.updateResponses(this.selectedArea.groupID);
		}

		// Watchers
		@Watch("value")
		private async onVModelUpdated(newValue: boolean): Promise<void> {
			this.isModalShown = newValue;

			if (this.isModalShown) {
				if (this.groupId) {
					this.setAlarmFilter();
				}
			}
		}

		@Watch("preSelectedAlarmFilter")
		private preSelectedAlarmChanged(): void {
			this.selectedAlarmFilter = this.preSelectedAlarmFilter;
		}

		@Watch("selectedArea")
		private async selectedAreaChanged(newArea: Group): Promise<void> {
			if (!this.isModalShown) {
				return;
			}

			if (!newArea) {
				this.selectedAreaAlarms = [];
				return;
			}

			await this.updateResponses(this.selectedArea.groupID);
		}

		@Watch("groupId")
		private async onGroupIdChanged(): Promise<void> {
			if (this.groupId) {
				const node = await api.getGroupTreeNode(this.groupId, this.requiredPermissions);
				await this.groupSelected(node);
			}
		}

		@Watch("areaMaskDuration")
		private onAreaMaskDurationChanged(newValue): void {
			if (newValue > this.maxAreaMaskDuration) {
				this.$nextTick(() => {
					this.areaMaskDuration = this.maxAreaMaskDuration;
				});
			}

			if (newValue < 1) {
				this.$nextTick(() => {
					this.areaMaskDuration = 1;
				});
			}
		}

		@Watch("isModalShown")
		private async onModalShownChanged(): Promise<void> {
			if (!this.isModalShown) {
				this.updateSelectedGroup(null);
			}

			if (this.isModalShown && this.groupId) {
				this.isLoadingAreas = true;
				const node = await api.getGroupTreeNode(this.groupId, this.requiredPermissions);
				this.selectedAreaNode = node;
				this.isLoadingAreas = false;
				await this.groupSelected(this.selectedAreaNode);
			}
		}

		@Watch("isMaskingAlarm")
		private async onIsMaskingAlarmChanged(): Promise<void> {
			if (this.isMaskingAlarm || !this.isAlarmMaskingEnabled) {
				return;
			}

			await this.getLatestSelectedNode();
			await this.fetchAreaMaskingCount();
		}

		@Watch("isUpdatingArea")
		private async onIsUpdatingAreaChanged(): Promise<void> {
			if (this.isUpdatingArea || !this.isAlarmMaskingEnabled) {
				return;
			}

			await this.getLatestSelectedNode();
			await this.fetchAreaMaskingCount();
		}

		@Watch("areaMaskingCount")
		private async onAreaMaskingCountChanged(newAreaMaskingCount: AreaMaskingCount, oldAreaMaskingCount: AreaMaskingCount): Promise<void> {
			if (this.confirmAreaMaskingChange(newAreaMaskingCount, oldAreaMaskingCount)) {
				await this.getLatestSelectedNode();
			}
    	}

		// Emits
		@Emit("openFilterModal")
		private groupBadgeSelected(result: string): string {
			this.groupFilter = result;
			return result;
		}

		@Emit("openAdvancedMaskingModal")
		private openAdvancedMaskModal(): Response[] {
			return this.selectedResponses;
		}

		private get paginatedRowsCount(): number {
			switch(this.selectedAlarmFilter) {
				case "Unmasked":
					return this.totalArmed;
				case "Masked":
					return this.totalUnarmed;
				default:
					return this.totalRecords;
			}
		}

		private get filterBadgesLabel(): string {
			return `Filter${this.alarmFilterInput ? " based on search" : ""}`
		}
	}
