import { MutationTree } from "vuex";
import {
	ReportState
} from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<ReportState> = {
	setAvailableReports(state, reports: any[]) {
		state.AvailableReports = reports;
	},

	setReport(state, report: any) {
		state.Report = report;
	},

	setRelativeHoursToReport(state, hours: number) {
		state.RelativeHoursToReport = hours;
		state.isDateRangeRelative = true;
	},

	setCurrentReportUrl(state, url: string) {
		state.CurrentReportUrl = url;
	},

	setResultCountNumber(state, count: number) {
		state.ResultCount = count;
	},

	setReportLoadingStatus(state, status: boolean) {
		state.ReportLoadingStatus = status;
	},

	setReportErrorStatus(state, status: boolean) {
		state.ReportErrorStatus = status;
	},

	setReportApiEndpoint(state, endpoint: string) {
		state.ReportApiEndpoint = endpoint;
	},

	setReportTypes(state, types: []) {
		state.ReportTypes = types;
	},

	setIsDateRangeRelative(state, isRelative: boolean) {
		state.isDateRangeRelative = isRelative;
	},

	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
