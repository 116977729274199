
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import AreaTreeSelect from '@/components/form/AreaTreeSelect.vue';
import { FeaturesList } from "@/store/types";
import { get } from 'lodash';
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import EventDetails from "@/components/EventDetails.vue";

const AreaNotes = namespace("areaNotes");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		"area-tree-select": AreaTreeSelect,
		"event-details": EventDetails
	}
})
export default class AreaNotesFinder extends Vue {
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@AreaNotes.Mutation setAreaNoteGroupDetails: any;
	@SiteMonitor.Mutation setEventDetailsShown: any;
	@SiteMonitor.Mutation setAreaDetailsTabIndex: any;
	@AreaNotes.State currentGroupId: number;
	@AreaNotes.State currentGroupTitle: string;

	private isModalVisible: boolean = false;
	private selectedGroup: AreaNode = null;

	public async show(): Promise<void> {
		this.isModalVisible = true;
	}

	private get isEnabled(): boolean {
		return (get(this.featuresList, ["AreaNotes"])
			&& get(this.featuresList, ["AreaNotes", "Icons"]));
	}

	public get siteTitle(): string {
		return this.selectedGroup ? this.selectedGroup.label : "";
	}

	public get getGroupId(): Number {
		return this.selectedGroup ? this.selectedGroup.id : null;
	}

	private groupSelected(selectedGroup: AreaNode): void {
		this.selectedGroup = selectedGroup;
	}

	private showNotesForSelectedGroup(modalEvent): void {
		// Prevent the default modal OK button event handling
		// stops the modal being hidden when 'Show Notes' (OK) button is clicked
		modalEvent.preventDefault();

		this.setEventDetailsShown(true);
		this.setAreaDetailsTabIndex(0);
		this.setAreaNoteGroupDetails({ groupId: this.getGroupId, groupTitle: this.siteTitle });
	}
}
