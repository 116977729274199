
import { Component, Vue, Prop } from "vue-property-decorator";
import { axiosInstance } from "@/axios.instance";

class ReportData {
	public constructor(eventID: number, summary: string) {
		this.EventID = eventID;
		this.Summary = summary;
	}

	EventID: number | null = null;
	Summary: string = "";
	IncludeMap: boolean;
	IncludeAuditTrail: boolean;
	IncludeClips: boolean;
}

type IncidentReportSelections = {
	IncludeMap: IncidentReportSelection,
	IncludeAuditTrail: IncidentReportSelection,
	IncludeClips: IncidentReportSelection,
}

type IncidentReportSelection = {
	title: string,
	selected: boolean
};

@Component({})
export default class IncidentReportDownload extends Vue {
	@Prop({ type: Number, required: true })
	private eventId: number;

	private selections: IncidentReportSelections = {
		IncludeMap: {
			title: "Map",
			selected: false
		},
		IncludeAuditTrail: {
			title: "Current Audit Trail",
			selected: true //Include Audit Trail by Default
		},
		IncludeClips: {
			title: "Event Thumbnail",
			selected: false
		}
	};

	private async downloadIncidentReport(): Promise<void> {
		try {
			await this.generateReport(this.eventId);
		}
		catch (error) {
			this.$notify({
				type: "error",
				title: "Situation Report",
				text: "Unable to generate the report - please try again later, or contact support if the problem persists"
			})
		}
	}

	public async generateReport(eventId: number, eventSummary: string = ""): Promise<void> {
		let reportData = new ReportData(eventId, eventSummary);

		reportData.IncludeAuditTrail = this.selections.IncludeAuditTrail.selected;
		reportData.IncludeClips = this.selections.IncludeClips.selected;
		reportData.IncludeMap = this.selections.IncludeMap.selected;

		let response = await axiosInstance.post("Report/Incident", reportData);

		let incidentReportID = response.data.reportID;
		let downloadUrl = `${axiosInstance.defaults.baseURL}/Report/Incident/${incidentReportID}`;

		let link = document.createElement("a");
		link.href = downloadUrl;
		link.download = downloadUrl;

		link.click();
	}
}
