import { DeviceDetails } from "@/store/devices/types";

export interface AudioDevice extends DeviceSpec {
    eventRecordId?: number | null;
    canReceive: boolean;
    canTransmit: boolean;
    canTwoway: boolean;
}

export interface DeviceSpec {
    deviceId?: number;
    title?: string;
    dllFile?: string;
    dllDeviceType?: string;
    urlTemplate?: string;
    host?: string;
    port?: string | number;
    user?: string;
    password?: string;
    extra?: string;
    input1?: string | number;
    input2?: string | number;
    deviceExtra?: string;
}

export enum AudioTypes {
    receive,
    transmit,
}

export enum AudioX {
    transmit = "audioTx",
    receive = "audioRx"
}