
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class AddNewActionCategory extends Vue {
	public hasValidationErrors: boolean = false;
	public showErrorAnimation: boolean = false;

	@Prop({
		default: () => ({
			Title: {
				type: String,
				required: true
			}
		})
	})
	newPlanCategory!: any; // @todo type

	@Watch("newPlan.Title")
	onTitleChanged(title: string) {
		this.hasValidationErrors = !title.length;
	}

	public checkActionCategory() {
		return !!this.newPlanCategory.Title.length;
	}

	public onSave() {
		if (this.checkActionCategory()) {
			this.hasValidationErrors = false;
			this.$emit("save", this.newPlanCategory);
		} else {
			this.hasValidationErrors = true;
			this.showErrorAnimation = true;

			setTimeout(() => {
				this.showErrorAnimation = false;
			}, this.$config.ANIMATION_DURATION);
		}
	}

	public onCancel() {
		this.$emit("cancel");
	}
}
