import PagedResponse from "@/types/sv-data/PagedResponse";

export interface PublicHolidayApiContract {
	publicHolidayId: number;
	title: string;
	groupTitle: string;
	day: number;
	month: number;
	year: number;
	groupId: number;
	inherited: boolean;
}

export interface PublicHolidayDto extends PublicHolidayApiContract{
	publicHolidayDate: string;
	repeatYearly: boolean;
}

export interface Schedule {
	scheduleId: string;
	scheduleTypeId: number;
	groupId?: number;
	responseId?: number;
	startDateTime: string;
	endDateTime?: string;
	title: string;
	intervalValue: number;
	intervalTypeId: number;
	maxOccurrences: number;
	nextOccurrence: string;
	expiresOn: string;
	publicHolidayTypeId: number;
	daysToRun: string;
	disabled: boolean | null;
	timeZoneId?: number;
	responseMaskingMeta?: ResponseMaskingMeta;
	serverTypeEvent?: number;
}

export interface ScheduleTableDto extends Schedule{
	areaTitle: string;
	nextOccurrenceAreaTime: string;
}

export interface ResponseMaskingMeta {
	responseIds: number[];
	allAlarms: boolean;
	disarmReason?: string;
	disarmUntil?: string;
}

export interface ScheduleType {
	scheduleTypeId: number;
	title: string;
}

export interface ScheduleState {
	schedules: Schedule[];
	tempSchedule: Schedule;
	scheduleTypes: ScheduleType[];
	isCreateModalVisible: boolean;
	isDeleteConfirmationVisible: boolean;
	isReloadRequired: boolean;
	selectedScheduleId: string;
	selectedScheduleStart: Date;
	searchString: string;
	selectedGroup: number;
	selectedScheduleTypes: number[];
	isLoading: boolean;
	enabledScheduleFilter: boolean | null;
	areaTimeZoneId: number | null;
}

export interface SchedulesPagedResponse extends PagedResponse {
	data: ScheduleTableDto[];
}

export interface ScheduleTableRow {
	scheduleId: string;
	title: string;
	nextRunLocalTime: string;
	nextRunAreaTime: string;
	scheduleType: string;
	areaTitle: string;
	disabled: boolean;
	parentSchedule: Schedule;
}

export enum IntervalType {
	Day = 1,
	Week = 2,
	Month = 3,
	Year = 4
}

export const IntervalTypeOptions = [
	{
		id: IntervalType.Day,
		title: "Day",
	},
	{
		id: IntervalType.Week,
		title: "Week",
	},
	{
		id: IntervalType.Month,
		title: "Month",
	},
	{
		id: IntervalType.Year,
		title: "Year"
	}
];

export enum PublicHolidaySetting {
	RunOnPublicHolidays = 1,
	SkipPublicHolidays = 2,
	AlwaysRun = 3
}

export const PublicHolidaySettingOptions = [
	{
		id: PublicHolidaySetting.RunOnPublicHolidays,
		title: "Run on Public Holidays"
	},
	{
		id: PublicHolidaySetting.SkipPublicHolidays,
		title: "Skip Public Holidays"
	},
	{
		id: PublicHolidaySetting.AlwaysRun,
		title: "Always Run"
	}
];

export enum DayOfWeek {
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
	Sunday = 7
}

export const CalendarRangeDay: string = "day";
export const CalendarRangeWeek: string = "week";
export const CalendarRangeMonth: string = "month";

export const CalendarRangeTypeOptions: string[] = [CalendarRangeDay, CalendarRangeWeek, CalendarRangeMonth];

export type CalendarRangeType = typeof CalendarRangeDay | typeof CalendarRangeWeek | typeof CalendarRangeMonth;
