
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { MobileRaiseTemplateProjection, MobileRaiseProjection } from "@/store/mobile-raise/types";
import QuickSelect from "./QuickSelect.vue";
import SearchSelect from "./SearchSelect.vue";

const MobileRaiseTemplates = namespace("mobileRaise");

@Component({
	components: {
		"quick-select": QuickSelect,
		"search-select": SearchSelect
	}
})
export default class RaiseSelect extends Vue {
	@MobileRaiseTemplates.State(state => state.templateProjections) templates: MobileRaiseTemplateProjection[];
	@MobileRaiseTemplates.State(state => state.currentTemplateProjectionIdx) templateIdx: number | null;
	@MobileRaiseTemplates.State isProjectionsLoaded: boolean;
	@MobileRaiseTemplates.Mutation("nextTemplateProjection") nextTemplate: () => void;
	@MobileRaiseTemplates.Mutation("previousTemplateProjection") previousTemplate: () => void;
	@MobileRaiseTemplates.Action loadTemplateProjections: () => Promise<void>;

	private searchTerm: string = "";
	private selectMode = "quick";

	created(): void {
		this.loadTemplateProjections();
	}

	onLoadAll(): void {
		this.selectMode = "search";
	}

	onBack(): void {
		this.selectMode = "quick";
		this.searchTerm = "";
	}

	onSearchUpdate(newVal: string): void {
		if (this.selectMode === "quick") {
			this.selectMode = "search";
		}
		this.searchTerm = newVal;
	}

	onPrevious(): void {
		this.searchTerm = "";
		this.previousTemplate();
	}

	onNext(): void {
		this.searchTerm = "";
		this.nextTemplate();
	}

	private get quickRaises(): MobileRaiseProjection[] {
		return this.templates[this.templateIdx].raises.filter(l => l.gridSetSlot !== null);
	}

	private get hasQuickRaises(): boolean {
		return this.quickRaises.length !== 0;
	}
}
