
import { Component, Vue } from "vue-property-decorator";
import NavHeader from "@/components/NavHeader.vue";
import { LogoutPayload, UserPermissions } from '@/store/types';
import { Action, Getter } from 'vuex-class';
import { Constants } from "@/types/Constants";
import ScheduleSetup from "@/components/Schedules/ScheduleSetup.vue";
import PublicHolidaySetup from "@/components/Schedules/public-holidays/PublicHolidaySetup.vue";

@Component({
	components: {
		"public-holiday-setup": PublicHolidaySetup,
		"nav-header": NavHeader,
		"schedule-setup": ScheduleSetup,
	}
})
export default class Schedules extends Vue {
	@Getter("getPermissions") permissions: UserPermissions;
	@Getter getIsFieldOpsLicensed: boolean;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	// logout
	@Action logout: (payload?: LogoutPayload) => void;
	@Action serverLogout: () => Promise<void>;

	private created(): void {
		if(!this.isSchedulesEnabled || (!this.canAccessSiteSetup && !this.canAccessSchedules)) {
			this.logoutUser(Constants.NO_PERMISSION_TO_PAGE);
		}
	}

	private async logoutUser(errorMessage: string = null): Promise<void> {
		await this.serverLogout();
		if(errorMessage) {
			const logoutPayload: LogoutPayload = {
				hasSessionExpired: false,
				playAudioAlert: false,
				errorMessage: errorMessage
			}
			await this.logout(logoutPayload);
		} else {
			await this.logout();
		}
	}

	private get canAccessSchedules() : boolean {
		return !!(this.permissions.canViewSchedules || this.permissions.canEditSchedules);
	}

	private get isSchedulesEnabled(): boolean {
		return this.getFeature(["Schedules"]);
	}

	private get canAccessSiteSetup(): boolean {
		return !!(this.permissions.canEditSiteSetup || this.permissions.canViewSiteSetup);
	}
}
