import { axiosInstance } from "@/axios.instance";
import { apiFormattingMixins } from "@/mixins";
import { AxiosInstance } from "axios";
const { arrayToQueryString } = apiFormattingMixins.methods;

class UserApi {
	private axios: AxiosInstance;

	constructor() {
		this.axios = axiosInstance;
	}

	public async checkUserHasPermissionToGroup(groupId: string, permissions: string[] = []): Promise<boolean> {
		const permissionsAsQueryString = arrayToQueryString("permissions", permissions);
		const { data } = await this.axios.get(`users/HasPermissions?groupId=${groupId}&${permissionsAsQueryString}`);
		return data;
	}
}

const userApi = new UserApi();
export default userApi;
