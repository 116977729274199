export default function getScript(source: any) {
	// TODO: guarantee once loaded

	return new Promise((resolve, reject) => {
		// if (document.querySelector('script[src="' + source + '"]')) {
		if (document.querySelector('script[src*="' + source.split("/")[2] + '"]')) {
			// Already have the script on the page.
			resolve();
			return;
		}

		const script = document.createElement("script");
		const prior = document.getElementsByTagName("script")[0];

		script.async = true;
		script.defer = true;
		script.src = source;

		if (document.documentElement != null) {
			document.documentElement.appendChild(script);
		}

		script.onload = () => {
			resolve("Resolved!");
		};

		script.onerror = () => {
			reject();
		};
	});
}
