
import { Component, Prop, Vue, Watch, Mixins } from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import api from '@/services/api.service';
import { formatDateMixin } from "@/mixins";
import { AssetAuditHistory } from './types';
import RegionalHandlerMixin from '@/mixins/RegionalHandlerMixin';
const { displayDateFromISOString, fromNow } = formatDateMixin.methods;

const maxRows = 50;
@Component({
	components: { scrollbar: VuePerfectScrollbar }
})
export default class MobileAssetHistory extends Vue {
    @Prop()
    private selectedAssetID: number | null;

    @Prop({default: false, type:Boolean})
    private showExactTime: boolean; 

    private endOfHistory: boolean = false;
    private loadingHistory: boolean = false;
    private fields: any[] =
    [
        {
            key: "created",
            label : "Time",
            formatter: created => {  
                    if(this.showExactTime)
                         return displayDateFromISOString(created);
                    return fromNow(created).replace('hours', 'hrs').replace('minutes','min');
                },
            thStyle: { width: '20%'}
        },
        {
            key: "assetTitle",
            label : "Asset",
            thStyle: { width: '20%'},
            tdClass: this.selectedAssetID ? "d-none" : "",
            thClass: this.selectedAssetID ? "d-none" : "",
        },
        {
            key: "name",
            label : "Name",
            thStyle: { width: '20%'},
            tdClass: !this.selectedAssetID ? "d-none" : "",
            thClass: !this.selectedAssetID ? "d-none" : "",
        },
        {
            key: "details",
            label : "Details"
        }
    ];

    private assetHistory: any[] = [];
    private currentRows: number = maxRows;

    private async mounted() {
        if(this.selectedAssetID){
            this.loadAssetHistory(null, this.selectedAssetID);
        } else {
            this.assetHistory = await this.getHistory(maxRows);
        }
    }

    private async reachedEndOfHistory(){
        if(this.endOfHistory || this.loadingHistory)
            return;


        let nextHistory = await this.getHistory(maxRows)
        nextHistory = nextHistory.filter(x => !this.assetHistory.map(x => { return x.eventRecordID }).includes(x.eventRecordID));

        if (nextHistory && nextHistory.length > 0){
            this.assetHistory = this.assetHistory.concat(nextHistory);
            this.currentRows += maxRows; //increment the current row count
        } else {
            this.endOfHistory = true;
        }

        //set a short delay in between loading to ensure multiple calls aren't made
        setTimeout(() => { this.loadingHistory = false }, 150);
    }

    private get lastEventRecordId() : number | null {
        if(!this.assetHistory || this.assetHistory.length === 0){
            return 0;
        } else {
            return this.assetHistory[this.assetHistory.length - 1].eventRecordID;
        }
    }

    public async reloadHistory(){
        this.assetHistory = await this.getHistory(this.currentRows, true)
    }

    @Watch("selectedAssetID")
    private async loadAssetHistory(oldId:number | null, newId: number | null){
        if(oldId === newId){
            if(oldId && newId){
                this.reloadHistory();
            }
            return;
        }

        if(this.selectedAssetID){
            //reset state
            this.assetHistory = [];
            this.endOfHistory = false;
            this.currentRows = maxRows;
            this.assetHistory = await this.getHistory(maxRows);
        }
    }

    private async getHistory(rowCount:number, fromStart: boolean = false): Promise<AssetAuditHistory[]>{
        this.loadingHistory = true;
        try 
        {
            if(this.selectedAssetID){
                return await api.getAssetAuditHistory({
                        assetId: this.selectedAssetID,
                        maxRows: rowCount,
                        beforeEventRecordId:  fromStart ? 0 : this.lastEventRecordId
                    });
            } else {
                return await api.getAssetsStatusHistory(
                        rowCount,
                        fromStart ? 0 : this.lastEventRecordId
                    );
            }
        } finally {
            this.loadingHistory = false;
        }
    }

}
