import { defaultPollingStatus } from "@/types/PollingStatus";
import { AreaState, GroupWithPermissions, defaultFlatAreaList } from "./types";

export function getDefaultState(): AreaState {
	return {
		AreaList: Array<GroupWithPermissions>(),
		areaDictionary: new Map<number, string>(),
		areaMaskingCount: {
			totalGroups: 0,
			masked: 0,
			partiallyMasked: 0,
			unmasked: 0
		},
		areaMaskingCountPollingStatus: defaultPollingStatus(),
		flatAreaList: defaultFlatAreaList()
	}
}
