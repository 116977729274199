
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Datetime } from "vue-datetime";
import vSelect from "vue-select";
import { UserGroup } from "@/store/types";
import { MobileRaiseProjection } from "@/store/mobile-raise/types";
import Title from "./Title.vue";
import api from "@/services/api.service";

@Component({
	components: {
		datetime: Datetime,
		"raise-title": Title,
		"vue-select": vSelect
	}
})
export default class RaiseActivityLog extends Vue {
	@Getter getUserGroups: UserGroup[];
	@Action fetchUserGroups: () => Promise<void>;
	@Prop() raise: MobileRaiseProjection;

	private startTime: string = "";
	private endTime: string = "";
	private area: UserGroup | null = null;
	private notes: string = "";
	private dataLoaded: boolean = false;

	async created() {
		await this.fetchUserGroups();
		this.dataLoaded = true;
		this.startTime = new Date().toISOString();
	}

	get isAreaValid() {
		return !!this.area;
	}
	get isNotesValid() {
		return !!this.notes;
	}
	get isStartTimeValid() {
		return !!this.startTime;
	}
	get isFormValid() {
		return this.isAreaValid && this.isNotesValid && this.isStartTimeValid;
	}

	async submit() {
		const newLog = {
			start: this.startTime,
			end: this.endTime,
			areaID: this.area.groupID,
			categoryID: this.raise.typeID,
			outcomeNote: this.notes
		};
		await api.addActivityLog(newLog);
		this.$emit("submit");
	}
}
