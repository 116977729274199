import api from "@/services/api.service";
import ccureapi from "@/services/api.ccure.service";
import { axiosInstance } from "@/axios.instance";
import axios from "axios";
import router from "@/router";
import { ActionTree } from "vuex";
import {
	EventsQueueState,
	AlarmQueueFilter,
	AlarmQueueFilterEditModel,
	SiteTreeNode,
	AlarmQueueFilterID,
	EventQueueInfo,
	SearchServerEventTypesParams,
	Group
} from "./types";
import { RootState, UserGroup } from "../types";
import Vue from "vue"; // Need to refactor this out to use store
import { get } from "lodash";
import EventTypes from "@/types/sv-data/enums/EventTypes";

export const actions: ActionTree<EventsQueueState, RootState> = {
	async fetchEventQueue({ dispatch, commit, state, getters, rootGetters }, data: any) {
		if (rootGetters.getPermissions.canProcessAlarms
			|| rootGetters.getPermissions.canHandleAlarms
			|| rootGetters.getPermissions.isSystemAdmin) {

			if (data == null) {
				data = await getters.getAlarmQueueFilterIDs;
			}

			try {
				const currentSort = state.CurrentSort;
				let filteredEventsResponse: any = null;

				// define the url that will be hit to get the event queue
				let eventQueueRequestUrl = "/eventqueue";

				// if another url is defined, use that.
				if (state.eventQueueGetOverride) {
					eventQueueRequestUrl = state.eventQueueGetOverride;
				}

				const eventTypes = [EventTypes.Alarm, EventTypes.Patrol, EventTypes.SiteCheck];

				let features = await rootGetters.getFeaturesList;
				let alarmOnTestFeatureEnabled = get(features, ["Alarms", "AlarmPoints", "OnTest"]);

				if (alarmOnTestFeatureEnabled)
				{
					eventTypes.push(EventTypes.OnTest, EventTypes.AuditTest);
				}

				// create payload to post to url defined above
				const requestData = {
					filters: data,
					sortBy: currentSort.sort,
					sortDesc: currentSort.desc,
					includeAll: true,
					onlyWithLocation: false,
					eventTypes: eventTypes
				};

				// post as JSON, using the in-built transformRequest (outputs a JSON string)
				filteredEventsResponse = await axiosInstance.post(eventQueueRequestUrl, requestData, {
					headers: {
						"Content-Type": "application/json"
					},
					transformRequest: axios.defaults.transformRequest
				});
				const alarmQueueFilters = state.AlarmQueueFilters;
				const filterObj: any = {};

				alarmQueueFilters.forEach((filter: any) => {
					let name = filter.alarmQueueFilterID.toString();

					if (filter.regionID) {
						name = filter.alarmQueueFilterID.toString() + "_" + filter.regionID.toString();
					}

					filterObj[name] = {
						activeAlarmCount: 0
					};
				});

				if (!filteredEventsResponse) {
					return;
				}

				commit("setActiveAlarmCount", filteredEventsResponse.data.alarmCount ? filteredEventsResponse.data.alarmCount : 0);
				const currentFilter = state.AlarmActiveFilter;
				const { eventQueue: eventsList } = filteredEventsResponse.data;

				const filteredEvents: any[] = [];
				let includeCurrentFilter: boolean;

				eventsList.forEach(eventQueue => {
					includeCurrentFilter =
						currentFilter.filterId === -1
							? true
							: currentFilter.filterId === 0
							? eventQueue.inFilters != null
							: eventQueue.inFilters != null &&
							eventQueue.inFilters.some(el => {
									return (
										// We do an extra check here as region ID of 0 is 'local' for
										// event queue items.
										(el.regionID === 0 && currentFilter.regionId === null) ||
										(el.regionID === currentFilter.regionId && el.filterID === currentFilter.filterId)
									);
							});

					if (includeCurrentFilter) {
						if (eventQueue.eventTitle === "") {
							eventQueue.displayTitle = eventQueue.responseTitle;
						} else if (eventQueue.responseTitle === "") {
							eventQueue.displayTitle = eventQueue.eventTitle;
						} else {
							eventQueue.displayTitle = `${eventQueue.eventTitle} - ${eventQueue.responseTitle}`;
						}

						filteredEvents.push(eventQueue);
					}

					if (eventQueue.eventTypeID === 2) {
						eventQueue.eventTitle = "Tour";
					}

					if (!eventQueue.inProcessing && eventQueue.eventTypeID === 1 && eventQueue.unParkAt == null) {
						if (filterObj["-1"] != null) {
							filterObj["-1"].activeAlarmCount++;
						}

						if (eventQueue.inFilters != null) {
							filterObj["0"].activeAlarmCount++;

							eventQueue.inFilters.forEach((filter: any) => {
								let name = filter.filterID.toString();

								if (filter.regionID) {
									name = filter.filterID.toString() + "_" + filter.regionID.toString();
								}

								if (filterObj[name]) {
									filterObj[name].activeAlarmCount++;
								}
							});
						}
					}

					if (eventQueue.regionID === 0 && eventQueue.regionTitle === null) {
						eventQueue.regionTitle = "Local";
					}
				});

				commit("setDataError", false, { root: true });

				// @techdebt all this conditions should be removed after migrating to websockets
				alarmQueueFilters.forEach((filter: any) => {
					let name = filter.alarmQueueFilterID.toString();

					if (filter.regionID) {
						name = filter.alarmQueueFilterID.toString() + "_" + filter.regionID.toString();
					}

					const alarmCount = filterObj[name].activeAlarmCount;

					if (filter.activeAlarmCount !== alarmCount) {
						commit("setAlarmQueueFilterAlarmCount", {
							filter,
							alarmCount
						});
					}
				});

				commit("setFilteredEvents", filteredEvents.slice(0, state.maxEventsToShow));

				commit("setFilteredEventIds", filteredEvents
					.filter(q => q.eventTypeID == 1 && q.inProcessing == false && q.unParkAt == null)
					.map(s => s.eventID)
				);

				if(state.debugMode){
					try {
						// previous event Queue Information
						const listOfAlarms = eventsList.filter(evt => evt.eventTypeID == 1).map(evt => evt.eventID);
						const listOfParked = eventsList.filter(evt => evt.eventTypeID == 1 && evt.inProcessing == 0 && evt.unParkAt != null).map(evt => evt.eventID);
						const listOfPatrols = eventsList.filter(evt => evt.eventTypeID == 2).map(evt => evt.eventID);
						const eventQueueInfo: EventQueueInfo = {
							CurrentUTC: new Date().toISOString(),
							EventsLastUpdate: new Date(state.EventsLastUpdated).toISOString(),
							AlarmList: listOfAlarms.toString(),
							ParkedList: listOfParked.toString(),
							TourList: listOfPatrols.toString()
						};
						commit("setEventQueueUpdateInfoHistory", eventQueueInfo);
					} catch (err) {
						throw "Error sorting previous event Queue info" + err.message;
					}
				} else {
					commit("clearEventQueueUpdateInfoHistory");
				}

			} catch (err) {
				console.log(err);
				if (err.response != null && err.response.status === 401) {
					dispatch("logout");
				} else if(!err.message.includes("sorting previous")) {
					commit("setLostConnectionCount", state.lostConnectionCount+1);
					if(state.lostConnectionCount > 2) {
						commit("setLostConnectionCount", 0);
						commit("setDataError", true, { root: true });
					}
				}
			}
		}
	},

	setMaxEventsToShow({ state, commit }, maxCount : number){
		commit("setMaxEventsToShow", maxCount);
	},

	addFilteredEvents({ getters, commit }, data: any) {
		data.forEach(event => {
			const storedEvent = getters.getFilteredEventById(event.eventID);

			commit("addFilteredEvents", { storedEvent, event });
		});
	},

	removeFilteredEvents({ getters, commit, state }, data: any) {
		data.forEach(event => {
			const storedEvent = getters.getFilteredEventById(event.eventID);
			const index = storedEvent ? state.FilteredEvents.indexOf(storedEvent) : null;
			commit("removeFilteredEvents", index);
		});
	},

	async fetchAlarmQueueFilters({ dispatch, commit, state }) {
		const response = await api.loadAlarmQueueFilters();

		const filters: AlarmQueueFilter[] = response.data;
		const filtersIds: AlarmQueueFilterID[] = [];
		const activeFilter = state.AlarmActiveFilter;

		const allItem: AlarmQueueFilter = {
			alarmQueueFilterID: -1,
			title: "All",
			isActive: activeFilter.filterId === -1,
			activeAlarmCount: 0,
			hide: false,
			regionID: 0,
			regionalFilterID: "0_-1", // <RegionalID>_<FilterID>,
			isLocal: true
		};

		const allFiltersItem: AlarmQueueFilter = {
			alarmQueueFilterID: 0,
			title: "All Open Filters",
			isActive: activeFilter.filterId === 0 && filters.length > 0,
			activeAlarmCount: 0,
			hide: filters.length === 0,
			regionID: 0,
			regionalFilterID: "0_0",
			isLocal: true
		};

		filters.forEach(filter => {
			filter.isActive =
				activeFilter.filterId === filter.alarmQueueFilterID && activeFilter.regionId === filter.regionID;
			filter.activeAlarmCount = 0;
			filtersIds.push({
				filterId: filter.alarmQueueFilterID,
				regionId: filter.regionID
			});

			filter.isLocal = (filter.regionID || 0) === 0;

			if (!filter.addedRegionTitle && !filter.isLocal) {
				filter.title = `${filter.title} (${filter.regionTitle})`;
			}
		});

		let anyActive = allFiltersItem.isActive || allItem.isActive;
		if (!anyActive) {
			filters.forEach(filter => {
				if (filter.isActive) {
					anyActive = true;
				}
			});
		}

		if (!anyActive) {
			allItem.isActive = true;
			dispatch("setActiveFilter", { filterId: -1, regionId: null });
		}

		filters.unshift(allFiltersItem);
		filters.unshift(allItem);

		commit("setAlarmQueueFilters", filters);
		commit("setAlarmQueueFilterIDs", filtersIds);

		await dispatch("updateFilteredEvents", filtersIds);
		return filtersIds;
	},

	updateFilteredEvents({ dispatch, commit, state }, data: any) {
		const activeId = data || [{ filterId: 0, regionId: null }];
		dispatch("fetchEventQueue", activeId);
	},

	async handleEvent({ dispatch, commit, state, getters, rootGetters }, { eventID, checkInProcessing, openInNewWindow, pickupMethod }: any) {
		if (getters.getHandlingEvent) {
			return;
		}
		commit("setHandlingEvent", true);
		commit("setJoiningEventID", eventID);
		console.log("EventQueueHandleEvent", pickupMethod)
		try {
			const eventHandledResult = await api.loadEventHandleAllowInProcessing(eventID, checkInProcessing);

			if (eventHandledResult.data.canProcess) {
				commit("setLastEventHandled");

				// check if HandleInNewTab feature is enabled
				let features = await rootGetters.getFeaturesList;
				let openInNewTabFeatureEnabled = get(features, ["Alarms", "HandleInNewTab"]);
				await api.auditEventPickUp(eventID, pickupMethod);
				// only apply this logic if we are using the AlarmQueue in views
				if ((router.currentRoute && router.currentRoute.name == "views" && openInNewTabFeatureEnabled) || openInNewWindow) {
					// get the route data for the site monitor
					const routeData = router.resolve({
						path: "/site-monitor"
					});

					// open the site monitor in a new window
					window.open(routeData.href + `/${eventID}`, "site-monitor");
				} else {
					router.push({ path: `/site-monitor/${eventID}` }).catch(err => {});
				}
			} else {
				if (eventHandledResult.data.removedFromEvent) {
					commit("setProcessingError", "Unable to process event - you have been removed from this event.");
				} else if (!checkInProcessing && eventHandledResult.data.inProcessing) {
					commit("setProcessingError", "Unable to process event - event is already being processed.");
				} else {
					commit("setProcessingError", "Unable to process event");
				}

				// throw an error here so the caller knows to reset our region and base URL
				throw new Error("Unable to process event");
			}
		} finally {
			commit("setHandlingEvent", false);
		}
	},

	async sitesTreeGet({ dispatch, commit, state, getters }, multiTenant?: boolean) {
		const data = await api.loadGroupsTree(multiTenant);
		// @todo @refactor move store modifications to mutations
		const setSelected = (node: SiteTreeNode) => {
			node.group.selected = false;

			if (node.subGroups != null && node.subGroups.length > 0) {
				node.subGroups = node.subGroups.map(setSelected);
			}

			return node;
		};

		if (!data) {
			commit("setSiteTree", []);
			return;
		}

		const tree = data.map(setSelected);
		const setLocalProperties = (node: SiteTreeNode) => {
			Vue.set(node, "show", true);
			Vue.set(node, "contents", null);
			Vue.set(node, "loadingContents", false);
			Vue.set(node, "contentsOpen", false);

			if (node.subGroups && node.subGroups.length > 0) {
				node.subGroups.forEach(setLocalProperties);
			} else {
				// if there are no subGroups there may be contents of the child most group which should be closed initially
				node.isOpen = false;
			}
		};

		tree.forEach(setLocalProperties);

		commit("setSiteTree", tree);
		commit("updateSiteTreeSelected");
	},

	findGroupInTreeById({ getters }, groupId: number) {
		const recursiveFind = (tree: SiteTreeNode, groupId: number) => {
			if (tree.group.groupID === groupId) {
				return tree.group;
			}

			if (tree.subGroups && tree.subGroups.length > 0) {
				for (var i = 0; i < tree.subGroups.length; i++) {
					const searchResult = recursiveFind(tree.subGroups[i], groupId);
					if (searchResult) return searchResult;
				}
			}

			return null;
		};

		for (var i = 0; i < getters.getSiteTree.length; i++) {
			const searchResult = recursiveFind(getters.getSiteTree[i], groupId);
			if (searchResult) return searchResult;
		}
		return null;
	},
	async loadAvailableAlarmQueueFilters() {
		const { data } = await api.loadAvailableAlarmQueueFilters();

		data.forEach(filter => {
			filter.isLocal = (filter.regionID || 0) === 0;
		});

		return data;
	},

	async addAlarmQueueFilter({ dispatch }, id: AlarmQueueFilterID) {
		await api.addAlarmQueueFilter(id);
		return await dispatch("fetchAlarmQueueFilters");
	},

	async removeAlarmQueueFilter({ dispatch }, id: AlarmQueueFilterID) {
		await api.removeAlarmQueueFilter(id);
		return await dispatch("fetchAlarmQueueFilters");
	},

	async eventHandleTop({ dispatch }, id: AlarmQueueFilterID) {
		return await api.eventHandleTop(id);
	},

	async createAlarmQueueFilter({ dispatch }, data: AlarmQueueFilterEditModel) {
		Object.keys(data).forEach(key => {
			if (key === "groups") {
				return;
			}

			if (data[key] === null) {
				delete data[key];
				return;
			}

			if (/Operator$/.test(key)) {
				data[key] = data[key] && data[key].toLowerCase();
			}
			if (/Value$/.test(key)) {
				// Convert strings to numbers in "*Value" props
				data[key] = data[key] && parseInt(data[key], 10);
			}
		});

		const alarmQueueFilterID = await api.createAlarmQueueFilter(data);
		await dispatch("fetchAlarmQueueFilters");

		return alarmQueueFilterID.data;
	},

	async editAlarmQueueFilter({ dispatch }, data: AlarmQueueFilterEditModel) {
		Object.keys(data).forEach(key => {
			if (data[key] === null) {
				delete data[key];
			}
		});

		await api.editAlarmQueueFilter(data);
		await dispatch("fetchAlarmQueueFilters");

		return data.alarmQueueFilterID;
	},

	async deleteAlarmQueueFilter({ dispatch }, filterId: number) {
		await api.deleteAlarmQueueFilter(filterId);
		await dispatch("fetchAlarmQueueFilters");
	},

	collapseSiteTree({ dispatch, commit }, { groups, lvl, collapsedLevel }) {
		const nextLvl = lvl + 1;
		groups.forEach(group => {
			if (lvl === collapsedLevel) {
				commit("collapseSiteTreeNode", group);
			}

			if (lvl < collapsedLevel && group.subGroups.length) {
				commit("expandSiteTreeNode", group);
				dispatch("collapseSiteTree", {
					groups: group.subGroups,
					nextLvl,
					collapsedLevel
				});
			}
		});
	},

	async loadRoutingGroups({ commit }) {
		const { data } = await api.loadRoutingGroups();
		commit("setRoutingGroups", data);
	},

	async loadAlarmTags({ commit }) {
		const { data } = await api.loadAlarmTags();
		commit("setAlarmTags", data);
	},

	async fetchUserZone({ commit }: any) {
		const userZone = await api.loadUserZone();
		commit("setUserZone", userZone);
	},

	setDisplayedEvent({ commit }, event) {
		commit("setDisplayedEvent", event);
	},

	async eventControl(context, payload) {
		const { data } = await api.eventControl(payload);
		return data;
	},

	async tourNotesRequired({ commit }) {
		// execute api tourNotesRequired Method.
		const { data } = await api.tourNotesRequired();
		// Commit the returned data.
		commit("setTourNotesRequired", data);
	},

	async loadServerEventTypes({ commit }, { serverType, groupID }: { serverType: number; groupID: number | null }) {
		// passing anything other than on object with 'serverType' as a property will cause this to be undefined
		if (serverType === undefined) {
			throw new Error("Action:loadServerEventTypes - serverType parameter is invalid");
		}

		const { data } = await api.loadServerEventTypes(serverType, groupID);
		commit("setServerEventTypes", data);
	},

	async searchServerEventTypes({ commit }, { serverType, groupID, filter, pageSize }: SearchServerEventTypesParams) {
		const { data } = await api.loadServerEventTypes(serverType, groupID, pageSize, filter);
		commit("setFilteredServerEventTypes", data);
	},

	async searchGroups(context, params) {
		const { data } = await api.searchGroups(params);
		return data as UserGroup[];
	},

	async loadGroupsList({ commit }) {
		return await api.loadGroupsList();
	},

	async loadEventHandleAllowInProcessing({ commit }, id: number) {
		const { data } = await api.loadEventHandleAllowInProcessing(id);

		if (!data.canProcess) {
			if (data.removedFromEvent) {
				commit("setProcessingError", "Unable to process event - you have been removed from this event.");
			} else {
				commit("setProcessingError", "Unable to process event");
			}
		}

		return data;
	},

	setActiveFilter({ commit }, alarmQueueFilterID: AlarmQueueFilterID) {
		commit("setActiveFilter", alarmQueueFilterID);
	},

	async loadEventShareInvites() {
		const { data } = await api.loadEventShareInvites();
		return data;
	},

	async acceptEventShareInvite(context, eventUserID: number) {
		const { data } = await api.acceptEventShareInvite(eventUserID);
		return data;
	},

	async ignoreEventShareInvite(context, eventUserID: number) {
		const { data } = await api.ignoreEventShareInvite(eventUserID);
		return data;
	},

	async fetchGroupsForFilter(context, filterID: number) {
		return await api.groupsForFilter(filterID);
	},

	async resetAllActions(context, eventId: number) {
		console.log(eventId);
		return await ccureapi.resetAllActions(eventId);
	},

	async resetAllActionsWithRegion(context, data: any) {
		return await ccureapi.resetAllActionsWithRegion(data.eventId, data.regionId);
	},

	setHideMapFlag({ commit }, hideMapFlag: boolean) {
		commit("setHideMapFlag", hideMapFlag);
	},

	async getSelectedGroupByIDSetState({ commit }, { groupID, state }: { groupID: number, state: string }): Promise<void> {
		if (groupID) {
			let data = await api.loadGroup(groupID);
			if (data) {
				data = { ...data, state };
			}
			commit("setSelectedGroup", data);
		}
	},
	updateSelectedGroup({ commit }, group: Group): void {
		commit("setSelectedGroup", group);
	}
};
