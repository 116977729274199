
import Vue from 'vue';
import organizationApi from "@/services/api.organizationSettings.ts";
import { Getter } from "vuex-class";
import { UserPermissions } from "@/store/types";
import { QuickEndSettingsDto } from "@/types/organization-settings/QuickEndSettings.ts";
import Component from "vue-class-component";
import EventOutcomeSelect from "@/components/form/EventOutcomeSelect.vue";

@Component({
	components:{
		"event-outcome-select": EventOutcomeSelect
	}
})

export default class QuickEndSettingsForm extends Vue {
	@Getter getPermissions: UserPermissions;
	@Getter getUserTenantGroupId: number;

	private quickEndSettings: QuickEndSettingsDto = {
		groupId: this.getUserTenantGroupId,
		eventOutcomeTitle: "",
		value: {
			enabled: false,
			note: "",
			eventOutcomeId: null
		}};

	private newEventOutcomeId: number = null;
	private hasChildren: boolean = false;
	private newNote: string = "";
	private newEnable: boolean = false;

	private async beforeMount()
	{
		try {
			this.quickEndSettings = await organizationApi.retrieveQuickEndSettings(null, true);
		} catch {
			console.log("Failed to get quick end settings.");
			this.quickEndSettings = null;
		}

		if(!this.quickEndSettings) {
			this.newEventOutcomeId = null;
			this.newNote = "";
			return;
		}

		this.newEventOutcomeId = this.quickEndSettings?.value?.eventOutcomeId;
		this.newNote = this.quickEndSettings?.value?.note;
		this.newEnable = this.quickEndSettings?.value?.enabled;
	}

	private async resetChanges(): Promise<void> {

		try {
			await organizationApi.deleteQuickEndSettings();
			this.newNote = "";
			this.newEventOutcomeId = null;
			this.newEnable = false;

			this.$notify({
				type: "success",
				title: "Success",
				text: "Quick End settings reset"
			});
		} catch {
			this.$notify({
				type: "error",
				title: "Error",
				text: "Failed to reset Quick End settings"
			});
		}
	}

	private async confirmUpdateQuickEndSettings(): Promise<void> {
		let newQuickEndSettings: QuickEndSettingsDto = {
			groupId: this.getUserTenantGroupId,
			value: {
				enabled: this.newEnable,
				note: this.newNote,
				eventOutcomeId: this.newEventOutcomeId
			},
			eventOutcomeTitle: this.quickEndSettings?.eventOutcomeTitle
		};

		try {
			this.quickEndSettings = await organizationApi.createOrUpdateQuickEndSettings(newQuickEndSettings);

			this.$notify({
				type: "success",
				title: "Success",
				text: "Quick End settings updated"
			});
		} catch {
			this.$notify({
				type: "error",
				title: "Error",
				text: "Failed to update Quick End settings"
			});
		}
	}

	private get haveSettingsChangedOrHasChildren(): boolean {
		if(this.hasChildren)
		{
			return false;
		}

		if(this.newEventOutcomeId == null) {
			return false;
		}

		return this.quickEndSettings?.value?.note !== this.newNote ||
			this.quickEndSettings?.value?.eventOutcomeId !== this.newEventOutcomeId ||
			this.quickEndSettings?.value?.enabled !== this.newEnable;
	}

	private onOutcomeChanged(hasChildren: boolean) {
		this.hasChildren = hasChildren;
	}

	private get noteState(){
		if(!this.newNote)
		{
			return true;
		}

		return this.newNote.length < 5000;
	}

	private isNoteInvalid(): boolean
	{
		if(!this.newNote){
			return false;
		}

		return this.newNote.length > 5000;
	}
}
