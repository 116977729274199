import axios from "axios";
import { stringify } from "qs";
// import store from '@/store';

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_HTTP_API_URI,
	withCredentials: true,
	headers: {
		Accept: "application/json, text/javascript, */*; q=0.01",
		"Content-Type": "application/x-www-form-urlencoded"
	}
});

const axiosJsonInstance = axios.create({
	baseURL: process.env.VUE_APP_HTTP_API_URI,
	withCredentials: true,
	headers: {
		Accept: "application/json, text/javascript, */*; q=0.01",
		"Content-Type": "application/json"
	}
});

const axiosJsonDirectInstance = axios.create({
	baseURL: process.env.VUE_APP_HTTP_API_URI,
	withCredentials: true,
	headers: {
		Accept: "application/json, text/javascript, */*; q=0.01",
		"Content-Type": "application/json",
	}
});

// When uploading files we must create an axios instance with no content type set,
// axios will then correctly set the appropraite headers when uploading files.
const axiosNoContentTypeInstance = axios.create({
	baseURL: process.env.VUE_APP_HTTP_API_URI,
	withCredentials: true,
	headers: {
		Accept: "application/json, text/javascript, */*; q=0.01"
	}
});

axiosInstance.defaults.transformRequest = function(data, headers) {
	for (let prop in data) {
		if (data[prop] == null) {
			delete data[prop];
		}
	}
	return stringify(data);
};

axiosInstance.defaults.headers.post["Content-Type"] =
	"application/x-www-form-urlencoded";

axiosInstance.prototype.setApiBaseUrl = function(url: string) {
	axiosInstance.defaults.baseURL = url;
	axiosJsonInstance.defaults.baseURL = url;
	axiosJsonDirectInstance.defaults.baseURL = url

	// if the current url doesn't end with /api/ and isnt our local host... add api to the end.
	if (
		!axiosInstance.defaults.baseURL.toLowerCase().endsWith("/api/") &&
		!axiosInstance.defaults.baseURL.toLowerCase().endsWith("/api") &&
		!axiosInstance.defaults.baseURL.startsWith(
			"http://local.sureviewops.com"
		)
	) {
		axiosInstance.defaults.baseURL =
			axiosInstance.defaults.baseURL + "/api/";
		axiosJsonInstance.defaults.baseURL =
			axiosJsonInstance.defaults.baseURL + "/api/";
		axiosJsonDirectInstance.defaults.baseURL =
			axiosJsonDirectInstance.defaults.baseURL + "/api/";
	}

	if (
		axiosInstance.defaults.baseURL !==
		axiosInstance.prototype._originalBaseUrl
	) {
		axiosInstance.prototype.startHeartbeatToOriginalServer();
	}
};

axiosInstance.prototype._originalBaseUrl = axiosInstance.defaults.baseURL;

axiosInstance.prototype.resetBackToOriginalBaseUrl = function() {
	axiosJsonInstance.defaults.baseURL =
		axiosInstance.prototype._originalBaseUrl;
	axiosInstance.defaults.baseURL = axiosInstance.prototype._originalBaseUrl;
	axiosJsonDirectInstance.defaults.baseURL = axiosInstance.prototype._originalBaseUrl;

	axiosInstance.prototype.stopHeartbeatToOriginalServer();
};

axiosInstance.prototype.heartbeatIntervalHolder = null;

axiosInstance.prototype.startHeartbeatToOriginalServer = function() {
	/// <summary>begin heart beating the original data service to keep login session alive</summary>

	// if there is already a timeout running, clear it.
	axiosInstance.prototype.stopHeartbeatToOriginalServer();

	// set new timeout to hit session handler.
	axiosInstance.prototype.heartbeatIntervalHolder = setInterval(() => {
		// hit heartbeat action in session handler
		axiosInstance
			.get("/session/HeartbeatLoginSession", {
				// set the base url to be the original one, don't change what we're currently looking at.
				baseURL: axiosInstance.prototype._originalBaseUrl
			})
			.then(result => {
				// if the result is not a success code
				if (result.status !== 200) {
					// somethings gone wrong so alert (don't know if there's a better dialog to use) TODO - Revisit.
					alert("Login session in local region has expired");

					// stop the interval as this will keep happening.
					axiosInstance.prototype.stopHeartbeatToOriginalServer();
					return;
				}
			});
	}, 5000);
};

axiosInstance.prototype.stopHeartbeatToOriginalServer = function() {
	if (axiosInstance.prototype.heartbeatIntervalHolder != null) {
		clearInterval(axiosInstance.prototype.heartbeatIntervalHolder);
	}
};

export { axiosInstance, axiosJsonInstance, axiosJsonDirectInstance, axiosNoContentTypeInstance };
