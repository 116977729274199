import { GetterTree } from "vuex";
import { RootState } from "../types";
import { Grouping, RuleActionPlan, RuleDropdownOptions, RulesEngineState, RuleServerType } from "./types";
import RulesText from "@/components/rules-engine-setup/RulesText.vue";
import RulesLogicBuilder from "@/components/rules-engine-setup/RulesLogicBuilder.vue";
import RulesScope from "@/components/rules-engine-setup/RulesScope.vue";
import RulesNumberInput from "@/components/rules-engine-setup/data-components/RulesNumberInput.vue";
import RulesAlarmDropdown from "@/components/rules-engine-setup/data-components/RulesAlarmDropdown.vue";
import { ComponentToValue, CardComponent } from "@/store/rules-engine/types";
import RuleGenericDropdown from "@/components/rules-engine-setup/data-components/RuleGenericDropdown.vue";
import { get } from "lodash";
import { isRegexValid } from "@/utils/string-utils";
import { TextValidationResult } from "@/types/text-validation-result";

export const getters: GetterTree<RulesEngineState, RootState> = {
	getTriggerType(): any[] {
        return [
            {
                id: 1,
                title: "Device Type"
            },
            {
                id: 2,
                title: "Alarm Type"
            },
            {
                id: 3,
                title: "Received After X Seconds"
            }
        ];
    },
	getActionType(): any[] {
        return [
            {
                id: 1,
                title: "Raise Priority"
            },
            {
                id: 2,
                title: "Action Plan"
            },
            {
                id: 3,
                title: "Combine Alarms"
            }
        ];
    },
	getScopeCriteria(): any[] {
        return [
            {
                id: 1,
                title: "Device Type"
            },
            {
                id: 2,
                title: "Alarm Type"
            }
        ];
    },
	getOperators(): string[] {
		return ["AND", "OR"];
	},
	getComponentLinks: (state, rootState, rootGetters) =>  {
		const componentLinks = getDefaultComponentLinks(state);

		// Add ActionTypeRegex action
		addActionTypeRegexActionToComponentLinks(componentLinks, rootGetters);

		return componentLinks;
	},
	getTitleComponentConfig(): CardComponent
    {
        return {
            component: RulesText,
            props: {
                fieldName: "Title"
            }
        }
    },
    getActionBuilderConfig(): CardComponent
    {
        return {
            component: RulesLogicBuilder,
            props: {
                section: "Action",
                rootText: "Action",
                allowedLogic: {
                    allowAnd: true,
                    allowOr: false,
                    subRules:
                    {
                        allowAnd: false,
                        allowOr: false
                    }
                },
            }
        }
    },
    getScopeBuilderConfig(): CardComponent
    {
        return {
            component: RulesScope,
            props: {
                section: "Scope",
                rootText: "Scope",
                allowedLogic: {
                    allowAnd: true,
                    allowOr: false,
                    subRules:
                    {
                        allowAnd: false,
                        allowOr: false
                    }
                },
            }
        }
    },
    getTriggerBuilderConfig(): CardComponent
    {
        return {
            component: RulesLogicBuilder,
            props: {
                section: "Trigger",
                rootText: "Trigger",
                allowedLogic: {
                    allowAnd: true,
                    allowOr: true,
                    subRules:
                    {
                        allowAnd: true,
                        allowOr: true
                    }
                },
            }
        }
    },
	getGrouping(): Grouping[] {
		return [
			{
				id: 1,
				title: "By Alarm"
			}, {
				id: 2,
				title: "By Area"
			}
		];
	}
}

const getDefaultComponentLinks = (state: RulesEngineState) => {
	return [
		{
			component: RulesText,
			componentFor: ["Scope","Trigger","Action"],
			valueComponents:[
				{
					section: "Scope",
					valueComponentLinks:
					[
						{
							value: 1, // Device Type
							component: RuleGenericDropdown,
							props: {
								options: state.serverTypes.map((s: RuleServerType) => {
									return { id: s.serverTypeId, title: s.title }
								}),
								allowNull: false,
								placeholderText: "Select a Device Type"
							}
						} as ComponentToValue,
						{
							value: 2, // Alarm Type
							component: RulesAlarmDropdown,
							props: {
								allowNulls: false
							}
						} as ComponentToValue,
					]
				},
				{
					section: "Action",
					valueComponentLinks:
					[
						{
							value: 1, // Raise Priority
							component: RulesNumberInput,
							props: {
								allowNulls: false,
								minValue: 1,
								maxValue: Number.MAX_VALUE,
								placeholderText: "Enter a Priority"
							}
						} as ComponentToValue,
						{
							value: 2, // Action Plan Dropdown
							component: RuleGenericDropdown,
							props: {
								options: state.actionsPlans.map((s: RuleActionPlan) => {
									return { id: s.actionPlanId, title: s.title }
								}),
								allowNull: false,
								placeholderText: "Select an Action Plan"
							}
						} as ComponentToValue,
						{
							value: 3, // Combine Alarms
							component: null
						} as ComponentToValue,
						{
							value: 4, // Close Event
							component: null
						} as ComponentToValue
					]
				},
				{
					section: "Trigger",
					valueComponentLinks:
					[
						{
							value: 1, // Device Type
							component: RuleGenericDropdown,
							props: {
								options: state.serverTypes.map((s: RuleServerType) => {
									return { id: s.serverTypeId, title: s.title }
								}),
								allowNull: false,
								placeholderText: "Select a Device Type"
							}
						} as ComponentToValue,
						{
							value: 2, // Alarm Type
							component: RulesAlarmDropdown,
							props: {
								allowNulls: false
							}
						} as ComponentToValue,
						{
							value: 3, // Received After
							component: RulesNumberInput,
							props: {
								allowNulls: false,
								minValue: 1,
								maxValue: 30,
								placeholderText: "Enter a value between 1 and 30"
							}
						} as ComponentToValue,
						{
							value: 4,
							component: RuleGenericDropdown,
							props: {
								options: null,
								ruleDropdownOption: RuleDropdownOptions.server,
								allowNull: false,
								placeholderText: "Select a Device"
							}
						} as ComponentToValue,
						{
							value: 5,
							component: RuleGenericDropdown,
							props: {
								options: null,
								ruleDropdownOption: RuleDropdownOptions.serverTypeEvent,
								allowNull: false,
								placeholderText: "Select an Event Type"
							}
						} as ComponentToValue,
						{
							value: 6,
							component: RulesNumberInput,
							props: {
								allowNulls: false,
								minValue: -1,
								placeholderText: "Input 1"
							}
						} as ComponentToValue,
						{
							value: 7,
							component: RulesNumberInput,
							props: {
								allowNulls: false,
								minValue: -1,
								placeholderText: "Input 2"
							}
						} as ComponentToValue,
						{
							value: 8,
							component: RulesNumberInput,
							props: {
								allowNulls: false,
								minValue: 1,
								maxValue: 30,
								placeholderText: "Enter a value between 1 and 30"
							}
						} as ComponentToValue
					]
				}
			]
		}
	];
}

const addActionTypeRegexActionToComponentLinks = (componentLinks, rootGetters) => {

	const actionPlanRegexFeatureEnabled = get(rootGetters.featuresList, ["RulesEngine", "ActionPlanRegex"]);

	if (actionPlanRegexFeatureEnabled) {
		componentLinks.forEach(c => c.valueComponents.forEach(vc => {

		if (vc.section === "Action") {
			const section = vc.valueComponentLinks;
			section.push({
				value: 5,
				component: RulesText,
				props: {
					validation: (input: string): TextValidationResult => isRegexValid(input)
				}
			} as ComponentToValue);
		}
		}));
	}
}
