import { Module } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { AreaEventHistoryState } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: AreaEventHistoryState = getDefaultState();
const namespaced: boolean = true;

export const areaEventHistory: Module<AreaEventHistoryState, RootState> = {
	namespaced,
	state,
	actions,
	mutations
};
