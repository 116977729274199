
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import TextHighlight from "vue-text-highlight";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import GenericUpdateModal, { ModalItem } from "./generic-update-modal.vue";
import { debounce } from "lodash";
import { PaginatedSearchQueryParams } from "@/store/types";

export interface TableHeader {
	title: string;
	key: string;
	order: number;
	sortOrder: number;
	sortOrderReversed: boolean;
	description?: string;
	searchable?: boolean;
	visible?: boolean;
	dataType?: string;
	useCustomCell?: boolean;
	width?: string;
	isTermLabel?: boolean;
	addQuickCopy?: boolean;
	style?: string;
	hideFilterOption?: boolean;
	sortable?: boolean;
	sortKey?: string;
	isSortedByDefault?: boolean;
	disable?: boolean;
}

@Component({
	components: {
		"vue-select": vSelect,
		"text-highlight": TextHighlight,
		"generic-update-modal": GenericUpdateModal,
		scrollbar: VuePerfectScrollbar
	}
})
export default class GenericTable extends Vue {
	$refs!: {
		genericTable: any;
		GenericUpdateModal: GenericUpdateModal;
	};

	@Prop() public columns!: TableHeader[];
	@Prop() public modalItems!: ModalItem[];

	@Prop() public editPermissions!: any[];
	@Prop() public deletePermissions!: any[];
	@Prop() public addPermissions!: any[];
	@Prop({ type: Boolean, default: true }) isShowSearch: boolean;
	@Prop({ type: Boolean, default: true }) isShowAction: boolean;
	@Prop({ type: Boolean, default: false }) isValidateTable: boolean;

	@Prop() public csvOption?: any;

	// When unset(undefined) - generic-table will proceed with existing checks
	// for add/edit controls.
	// When set, visibility of those controls will solely depends on readonly prop value
	@Prop() public readonly: boolean | undefined;

	@Prop({ type: String }) public canEditProperty;
	@Prop({ default: [] }) public dataList: any[];
	@Prop({ default: "" }) public term!: string;
	@Prop() public modalTitle!: string;
	@Prop({ type: Boolean, default: true }) useModal: boolean;
	@Prop({ type: Boolean, default: false }) useSlotActions: boolean;
	@Prop({ type: Boolean, default: false }) isSafeDeleteEnabled: boolean;
	@Prop() deleteActionMessages: any;
	@Prop({ type: String, default: "" }) deleteMessageAlternativeText!: string;
	@Prop({ type: Boolean, default: false }) isSaveConfirmationEnabled: boolean;
	@Prop() saveActionMessages: any;
	@Prop() saveActionErrors: any;

	@Prop({ type: Object, default: () => ({}) }) progress: any;
	@Prop() isProgress: boolean;

	@Prop() public onSave;
	@Prop() public onDelete;
	@Prop() public onAddNew;
	@Prop() public onModalOpen;

	@Prop({ type: Boolean, default: false}) public refreshAllowed: boolean;
	@Prop({ type: Boolean, default: false}) public clearOnClose: boolean;

	@Prop({ default: "" }) public idColumn!: string;
	@Prop({ type: Number, default: 0 }) totalAvailableRows: number;
	@Prop({ type: Boolean, default: false }) isLoading: boolean = false;
	@Prop({ type: Boolean, default: true }) closeOnSave: boolean = true;
	@Prop({ type: Boolean, default: false }) resetPaginationOnRowCountChange: boolean;
	@Prop({ type: Boolean, default: true }) showPaginationControls: boolean;
	@Prop({ type: String, default: "card card-secondary table-container overflow-hidden" })
	private tableClass: string;

	@Prop({ type: String, default: "card-body d-flex flex-column overflow-hidden" })
	private tableBodyClass: string;

	@Prop({ type: String, default: "" })
	private scrollbarClass: string;

	@Getter getPermissions: any;

	@Prop({ type: Boolean, default: false }) private isManagedExternally: boolean;
	@Prop({ type: Boolean, default: false }) private hideAdd: boolean;

	private renderData: any[] = null;
	private showFilter: boolean = false;
	private tableSearch: string = "";
	private safeDeleteInput = "";
	private deleteActionMessageResult = [];

	@Watch("totalAvailableRows")
	private resetPagination(): void {
		if (!this.resetPaginationOnRowCountChange) {
			return;
		}
		this.resultsPerPage = "25";
		this.resultsPerPageUpdated = "25";
		this.selectedPage = 1;
		this.tableSearch = "";
		this.updateSearchFilter();
	}

	private mounted() {
		this.updateRenderData(this.dataList);

		const defaultSortColumn = this.columns.find(c => c.isSortedByDefault);
		if (defaultSortColumn) {
			this.sortByColumn = defaultSortColumn.sortKey ? defaultSortColumn.sortKey : defaultSortColumn.key;
			this.sortDirection = "ascending";
		}

		this.debounceUpdateSearchFilter = debounce(() => {
			this.updateSearchFilter();
		}, 250);
		this.safeDeleteInput = "";
	}

	private defaultRenderCount: number = 50;
	private renderCount: number = this.defaultRenderCount;

	private isRenderUpdating: boolean = false;

	private rowToDelete: any = {};
	private isDeleteConfirmDialogShown: boolean = false;

	private resultsPerPage: string = "25";
	private resultsPerPageUpdated: string = "25";
	private selectedPage: number = 1;
	private debounceUpdateSearchFilter;

	private sortByColumn?: string = null;
	private sortDirection?: string = null;

	// Filter the data by the columns that are requested
	private filterByColumn(row: any[]) {
		let renderList = [];
		for (let key in row) {
			let filteredColumns = this.columns.filter(function(el) {
				return el.key == key && el.visible && !el.disable;
			});
			if (filteredColumns.length > 0) {
				const width = filteredColumns[0].width;
				renderList.push({
					value: row[key],
					key: key,
					type: filteredColumns[0].dataType,
					useCustomCell: filteredColumns[0].useCustomCell,
					sortOrder: filteredColumns[0].order,
					width: width ? `width:${width};` : "",
					style: filteredColumns[0].style || ""
				});
			}
		}
		return renderList.sort((a, b) => a.sortOrder - b.sortOrder);
	}

	private updateSearchFilter() {
		if (this.resultsPerPageUpdated !== this.resultsPerPage) {
			this.selectedPage = 1;
		}
		this.resultsPerPage = this.resultsPerPageUpdated;
		const searchFilterEventPayload: PaginatedSearchQueryParams = {
			searchTerm: this.tableSearch,
			page: this.selectedPage,
			pageSize: parseInt(this.resultsPerPage),
			sortBy: this.sortByColumn,
			sortDesc: this.sortDirection === "descending"
		};
		this.$emit("search-filter-updated", searchFilterEventPayload);
	}

	private checkPermissions(row) {
		if (this.readonly) {
			return !this.readonly;
		}

		if (row.readOnly) {
			return false;
		}

		if (this.canEditProperty) {
			return row[this.canEditProperty];
		}

		// if required permission is blank, we dont need a specific permission
		if (this.editPermissions == null) {
			return true;
		}

		let userHasPermission;

		// if there is a permissions requirement against the row itself
		if (row.permissions) {
			// use the current rows permissions
			userHasPermission = this.editPermissions.some(r => Object.keys(row.permissions).includes(r));
		} else {
			// use the users permissions to see if we have one that matches
			userHasPermission = this.editPermissions.some(r => Object.keys(this.getPermissions).includes(r));
		}

		return userHasPermission;
	}

	// Return the list of columns that are tagged as visible
	private visibleColumns() {
		return this.columns
			.filter(function(el) {
				return el.visible && !el.disable;
			})
			.sort(function(a, b) {
				return a.order - b.order;
			});
	}

	@Watch("dataList", { deep: true })
	private updateRenderData(value: any) {
		this.searchTable(this.tableSearch, undefined);
	}

	private async openModel(row, readOnly?, addNew?) {
		if (this.onModalOpen) {
			let returnValue = await this.onModalOpen(row);
			if (returnValue != null) {
				row = returnValue;
			}
		}
		this.$refs.GenericUpdateModal.showUpdateDialog(row, readOnly, addNew);
	}

	private formatList(data) {
		let output = "";
		for (let value in data) {
			if (output != "") {
				output += ", ";
			}
			output += data[value];
		}
		return output;
	}

	private get getRenderData() {
		if (this.renderData == null) {
			return this.renderData;
		}

		return this.renderData.slice(0, this.renderCount);
	}

	@Watch("tableSearch")
	// Filter the searchable columns by search string
	private async searchTable(searchString, previousSearchString) {
		this.renderCount = this.defaultRenderCount;

		if (previousSearchString !== undefined && searchString !== previousSearchString) {
			this.selectedPage = 1;
			await this.debounceUpdateSearchFilter();
		}

		const isAsyncSearching = this.totalAvailableRows !== 0;
		if (searchString === "" || isAsyncSearching) {
			// Nothing to search. Show the whole results
			this.renderData = this.dataList.filter(function(el) {
				if (el.hidden == true) {
					return false;
				}
				return true;
			});
		} else {
			// Get all the columns that are searchable
			let searchableColumns = this.columns.filter(function(columnEl) {
				return columnEl.searchable == true && columnEl.visible && !columnEl.disable;
			});
			// Search the data that is searchable
			this.renderData = this.dataList.filter(function(dataEl) {
				for (let index in searchableColumns) {
					let text = typeof searchString === "string" ? searchString : searchString.target.value; // keyboard event

					if (
						searchString &&
						dataEl[searchableColumns[index].key] &&
						dataEl[searchableColumns[index].key]
							.toString()
							.toLowerCase()
							.includes(text.toLowerCase())
					) {
						return true;
					}
				}
				return false;
			});
		}
	}

	private onScrollBottom() {
		if (!this.isRenderUpdating && this.renderData != null && this.renderCount < this.renderData.length) {
			this.isRenderUpdating = true;
			setTimeout(
				function() {
					this.renderCount += 25;
					if (this.renderCount > this.renderData.length) {
						this.renderCount = this.renderData.length;
					}
					this.isRenderUpdating = false;
				}.bind(this),
				500
			);
		}
	}

	private get isAddVisible() {
		if (this.hideAdd || this.isManagedExternally) {
			return false;
		}

		if (this.readonly !== undefined) {
			return !this.readonly;
		}
		return this.getPermissions.canEditSiteSetup && this.useModal;
	}

	private async onDeleteRow(row) {
		if (this.deleteActionMessages) {
			this.deleteActionMessageResult = typeof this.deleteActionMessages === "function" ? await this.deleteActionMessages(row) : this.deleteActionMessages;
		}
		this.isDeleteConfirmDialogShown = true;
		this.rowToDelete = row;
	}

	private confirmDelete(row) {
		this.isDeleteConfirmDialogShown = false;
		this.onDelete(row);
	}

	private get getKeyColumn() {
		const keyColumn = this.columns.find(c => c.isTermLabel == true);
		if (!keyColumn) {
			console.error("Error no table column term label set!");
			return "";
		} else {
			return keyColumn.key;
		}
	}

	private cancelDelete() {
		this.isDeleteConfirmDialogShown = false;
		this.safeDeleteInput = "";
	}

	private toolTipIdToShow = "";

	private copyRowValueToClipboard(idToCopy: string) {
		let node = document.getElementById(idToCopy);
		let range = document.createRange();
		range.selectNodeContents(node);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);
		document.execCommand("copy");
		this.toolTipIdToShow = idToCopy;

		setTimeout(() => {
			this.toolTipIdToShow = "";
		}, 2000);
	}

	private closeDeleteModal() {
		this.safeDeleteInput = "";
	}

	private get filteredColumns() {
		return this.columns.filter(({ hideFilterOption, disable }) => !hideFilterOption && !disable);
	}

	private removeNonDigitsFromString(value: string): string {
		if (!value)
			return "";

		const digitFind = value.match(/\d+/g);
		if (!digitFind)
			return "";

		if (parseInt(digitFind.join("")) > 200) {
			this.resultsPerPageUpdated = "200";
		}

		return digitFind.join("");
	}

	// 1 column sorting
	private updateSorting(column: TableHeader): void {
		if (!column.sortable) {
			return;
		}
		const sortByColumn = column.sortKey ? column.sortKey : column.key;
		if (!this.sortByColumn) {
			this.sortByColumn = sortByColumn;
			this.sortDirection = "ascending";
			this.updateSearchFilter();
			return;
		}

		if (this.sortByColumn === sortByColumn) {
			this.sortDirection = this.sortDirection === "ascending" ? "descending" : "ascending";
		} else {
			this.sortDirection = this.sortDirection = "ascending";
		}
		this.sortByColumn = sortByColumn;

		this.updateSearchFilter();
	}

	public updateNewEntry(entry: any, keys: string[]): void {
		this.$refs.GenericUpdateModal.updateNewEntry(entry, keys);
	}

	public modalClosed(): void {
		this.$emit("genericUpdateModalClosed");
	}
}
