import { GetterTree } from "vuex";
import { ViewsState } from "./types";
import { RootState } from "../types";
import { axiosInstance } from "@/axios.instance";

export const getters: GetterTree<ViewsState, RootState> = {
	getViews: state => state.views,
	getUserViews: state => state.userViews,
	getSelectedView: state => state.selectedView,
	getPluginTypes: state => state.pluginTypes,
	getViewMode: state => state.viewMode,
	getSelectedTileType: state => state.selectedTileType,
	getSideBarExtended: state => state.sideBarExtended,
	getSideBarPinned: state => state.sideBarPinned,
	getEvent: state => state.latestEvent,
	getDataServiceLocation: state => axiosInstance.defaults.baseURL,
	getContents: state => (viewCellId: number) => {
		if (viewCellId) {
			const contents = state.cellContents[viewCellId.toString()];
			return contents;
		}
		return null;
	},
	getViewSessionId: state => state.viewSessionId
};
