import { GetterTree } from "vuex";
import { RootState } from "../types";
import { GuardChat, Message, MessageWithAttachment } from "./types";
import { filter, map, some } from "lodash";
import EventRecordTypes from "@/types/sv-data/enums/EventRecordTypes";

const getLastMessageId = (messages: Message[]): number | undefined => {
	if (messages && messages.length > 0) {
		return messages[messages.length - 1].eventRecordId;
	}
}

export const getters: GetterTree<GuardChat, RootState> = {
	getMessages: (state): MessageWithAttachment[] => {
		const messages = filter(
			state.messages,
			m =>
				m.eventRecordTypeId === EventRecordTypes.OperatorMessage ||
				m.eventRecordTypeId === EventRecordTypes.GuardMessage
		);
		const result = map(messages, m => ({
			eventId: m.eventId,
			eventRecordId: m.eventRecordId,
			details: m.details,
			messageType: m.eventRecordTypeId === EventRecordTypes.OperatorMessage ? "operator" : "guard",
			createdAtUtc: m.createdAtUtc,
			author: m.author,
			hasFiles: some(
				state.messages,
				sm => sm.eventRecordTypeId === EventRecordTypes.AttachedFile && sm.objectId === m.eventRecordId
			)
		}));
		return result;
	},

	getTwoWayMessages: (state): MessageWithAttachment[] => {
		const messages = filter(
			state.twoWayMessages,
			m =>
				m.eventRecordTypeId === EventRecordTypes.ChatMobileUserMessage ||
				m.eventRecordTypeId === EventRecordTypes.ChatOperatorMessage
		);
		const result = map(messages, m => ({
			eventId: m.eventId,
			eventRecordId: m.eventRecordId,
			details: m.details,
			messageType: m.eventRecordTypeId === EventRecordTypes.ChatOperatorMessage ? "operator" : "mobileUser",
			createdAtUtc: m.createdAtUtc,
			author: m.author,
			hasFiles: some(
				state.messages,
				sm => sm.eventRecordTypeId === EventRecordTypes.AttachedFile && sm.objectId === m.eventRecordId
			)
		}));
		return result;
	},

	getLastMessageId: (state): number | undefined => getLastMessageId(state.messages),

	getTwoWayLastMessageId: (state): number | undefined => getLastMessageId(state.twoWayMessages),
};
