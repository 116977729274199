enum EventTypes {
    Alarm = 1,
    Patrol = 2,
    SiteCheck = 3,
    SetupSession = 4,
    WorkstationSession = 5,
    OnTest = 6,
    ArmDisarm = 7,
    QuickControl = 9,
    AuditTest = 10,
    ActivityLog = 11,
    ViewSession = 12,
    VideoReport = 13,
    HighPriorityAudit = 14,
    GuardTour = 15,
    SystemLog = 16,
    EventChat = 17,
    AssetHistory = 18,
}

export default EventTypes;