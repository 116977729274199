import { axiosJsonInstance, axiosInstance } from "@/axios.instance";
import { DeviceNode } from "@/types/sv-data/devices/DeviceNode";
import { AxiosInstance, AxiosResponse, CancelTokenSource } from "axios";
import { GetDevicesByTypeParams, DeviceTypeCounts, DeviceDetails, ServerConfiguration, ServerConfigurationResponse } from "@/store/devices/types";

class DevicesApi {
	private axios: AxiosInstance;
	private axiosJson: any;

	constructor() {
		this.axios = axiosInstance;
		this.axiosJson = axiosJsonInstance;
	}

	public async deviceSearch(
		deviceTypeId: number,
		query: string,
		cancelToken: any,
		searchTermsInAnyOrder: boolean = false
	): Promise<any> {
		return this.axios.get(`/AdvancedDevices/search/${deviceTypeId}`, {
			params: {
				search: query,
				searchTermsInAnyOrder: searchTermsInAnyOrder
			},
			cancelToken
		});
	}

	public async getDevicesByTitle(deviceTitles: string[]): Promise<AxiosResponse<DeviceDetails[]>> {
		return await this.axiosJson.post("/AdvancedDevices/GetDevicesByTitle", deviceTitles);
	}

	public async getDeviceTypeCounts(serverId: number): Promise<AxiosResponse<DeviceTypeCounts>> {
		let endpoint = "/AdvancedDevices/TypeCounts";

		if (serverId) {
			endpoint += `?serverId=${serverId}`;
		}

		return await this.axiosJson.get(endpoint);
	}

	public async getDevicesForAllGroupsByType(
		params: GetDevicesByTypeParams,
		cancelTokenSource: CancelTokenSource = null
	) {
		const { deviceTypeId, serverId, page, pageSize, sortBy, sortDesc, searchTerm, selectOutputs, includeReferenceShots } = params;
		let endpoint = `/AdvancedDevices/ByType/${deviceTypeId}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}`;

		if (serverId) {
			endpoint += `&serverId=${serverId}`;
		}

		if (searchTerm) {
			endpoint += `&searchTerm=${searchTerm}`;
		}

		if (selectOutputs === true || selectOutputs === false) {
			endpoint += `&selectOutputs=${selectOutputs}`;
		}

		if (includeReferenceShots) {
			endpoint += `&includeReferenceShots=true`;
		}

		return await this.axiosJson.get(endpoint, { cancelToken: cancelTokenSource?.token });
	}

	public async getDeviceTypes(): Promise<any> {
		const { data } = await this.axios.get("/servers/IntegrationServerTypes");
		return data;
	}

	/**
	 * Gets the list of device nodes for a specified group in the group tree.
	 * @param groupId The ID of the group.
	 * @param deviceTypeId The type ID of the devices to get.
	 * @returns An array of device nodes for the specified group.
	 */
	public async getDevicesForGroupTree(
		groupId: number,
		deviceTypeId: number
	): Promise<DeviceNode[]> {
		const { data } = await this.axios.get(`/AdvancedDevices/Tree/${groupId}/${deviceTypeId}`);
		return data;
	}

	/**
	 * Searches for devices of a specified device type with a query string.
	 * @param query The query string to search devices for.
	 * @param deviceTypeId The type ID's of the devices to include in the search.
	 * @param searchTermsInAnyOrder Whether or not to take the order of search terms (separated by spaces) into account.
	 * @returns An object containing device nodes.
	 */
	public async searchDevicesForGroupTree(
		query: string,
		deviceTypeIds: number[],
		searchTermsInAnyOrder: boolean
	): Promise<DeviceNode[]> {
		const { data } = await this.axios.post(
			"/AdvancedDevices/Tree/Search",
			{
				query,
				deviceTypeIds,
				searchTermsInAnyOrder
			}
		);
		return data;
	}

	public async createConfig(
		serverConfig: ServerConfiguration
	): Promise<ServerConfigurationResponse> {
		const { data } = await this.axiosJson.post(
			"DeviceConfig/Add",
			serverConfig
		);
		return data;
	}

	public async getConfig(
		file: string,
		type: string,
		urlTemplate: string,
		prefValue: string,
		host: string,
		port: number,
		user: string,
		password: string,
		extra: string,
		input1: string,
		input2: string,
		deviceExtra: string,
		site: string,
		format: string,
		auth: string
		): Promise<any> {
		let urlBase = window.location.origin;
		const { data } = await this.axios.get(
			`${urlBase}/devices/config?file=${file}&type=${type}&urlTemplate=${urlTemplate}&prefValue=${prefValue}&host=${host}&port=${port}&user=${user}&password=${password}&extra=${extra}&input1=${input1}&input2=${input2}&deviceExtra=${deviceExtra}&site=${site}&format=${format}&auth=${auth}`);
		return data;
	}

	public async getConfigBase(
		serverId: number,
		extraValue: string,
		auth: string,
		format: string,
		): Promise<any> {
		let urlBase = window.location.origin;
		const { data } = await this.axios.get(
			`${urlBase}/devices/config?auth=${auth}&serverId=${serverId}&extra=${extraValue}&format=${format}`);
		return data;
	}

	public async addDevice(newDevice: any): Promise<any> {
		return await this.axios.post("/AdvancedDevices/AddDevice", newDevice);
	}

	public async updateDevice(updatedDevice: any): Promise<any> {
		return await this.axios.post("/AdvancedDevices/UpdateDevice", updatedDevice);
	}

	public async deleteDevice(deviceId: number): Promise<any> {
		return await this.axios.delete(`/AdvancedDevices/DeleteDevice/${deviceId}`);
	}
}

const devicesApi = new DevicesApi();
export { devicesApi };
