import { PaginatedSearchQueryParams } from "../types";

export interface DevicesState {
	cancelTokenSource: any;
	doors: DeviceDetails[];
	serversList: ServerDetails[];
	syncSystems: SyncSystem[];
	totalSyncSystems: number;
	filteredServersList: ServerDetails[];
	totalServers: number;
	currentServer: ServerDetails;
	devices: DeviceDetails[];
	totalDevices: number
	device: DeviceDetails;
	currentDeviceTypeIdentifier: DeviceTypeIdentifier;
	serverTypes: ServerType[];
	deviceConfigurationErrors: DeviceConfigurationErrors;
	deviceResponseRecordsRetrievalStatus: DeviceResponseRecordsRetrievalStatus;
	deviceTypeCounts?: DeviceTypeCounts;
	alarms?: number;
	deleteChildDeviceModalVisible: boolean;
	editChildDeviceModalVisible: boolean;
	cameraPreviewModalVisible: boolean;
	notificationOptions: NotificationOptions | null;
	serverLineProfiles: ServerLineProfile[];
	serverConfig: DeviceConfiguration[];
	gettingConfig: boolean;
	settingConfig: boolean;
	serverModalOpened: boolean;
	serverModalStartTab: ServerModalStartTab
}

export interface SyncSystem {
	syncSystemId: number;
	serverTypeId: number;
	serverId?: number;
	applianceId?: number;
	title: string;
	host: string;
	port: number;
	username: string;
	password: string;
	extraValue: string;
	tenantId: number;
	isSuiteSync: boolean;
	isDeviceSync: boolean;
	linkedSyncSystemId?: number;
	linkedSyncSystemTitle?: string;
}

export interface DeviceDetails {
	deviceID: number;
	serverID: number;
	deviceTypeID: number;
	title: string;
	quality: string;
	speed: string;
	hasPtz: boolean;
	auditServiceEndpoint: string;
	deviceServerEndpoint: string;
	eventRecordId: number | null;
	timeZone?: string | null;
	presets: DevicePreset[];
	input1: number;
	input2: number;
	serverTitle?: string | null | undefined;
	extraValue?: string;
	settingsMeta: string | null;
	audioSettings?: AudioSettingsMeta;
	cameraSettings?: CameraSettingsMeta;
	relaySettings?: RelaySettingsMeta;
	defaultReferenceShot?: string;
	groupID?: number;
	deviceServiceSession: string;
	comment: string;
	orderNum: number;
	relayType: string;
	eventRecordID: number | null;
	liveVideoDelay?: number | null;
	supportsAudio: boolean;
	receivesAudio: boolean;
	transmitsAudio: boolean;
	groupSyncId?: string;
	readonly?: string;
	readonlyMessage?: string;
	transcodingAllowed?: boolean;
	canTimeTravel?: boolean;
}

export interface DevicePreset {
	presetNumber: number;
	title: string;
}

export interface ServerDetails {
	serverID: number;
	serverTypeID: number;
	title: string;
	groupID: number;
	host: string;
	port: number;
	username: string;
	password: string;
	extraValue: string;
	groupName?: string;
	extraValueName?: string;
	devices?: any[];
	isRaiseIndividual?: boolean;
	isRaiseGrouped?: boolean;
	deviceTypeTitle?: string;
	applianceId?: number;
	logDisarmedAlarms?: boolean;
	showEdge?: boolean;
	edgePath?: string;
	edgeUser?: string;
	edgePassword?: string;
	deviceTypeCounts?: DeviceTypeCounts;
	alarms?: number;
	accountNumber?: string;
	serverLineProfileId?: number;
	syncSystemId?: number;
	readonly?: boolean;
	readonlyMessage?: string;
	deviceServiceEndpoint?: string;
}

export interface AlarmDecoderServer {
	serverID: number;
	title: string;
}

export interface ServerConfiguration {
    serverId: number;
	extraValue: string;
	deviceConfiguration: DeviceConfiguration;
}

export interface ServerConfigurationResponse {
    serverConfiguration: ServerConfiguration,
	errors: DeviceConfigurationErrors;
}

export interface DeviceConfigurationErrors {
	alarms: DeviceConfigurationAlarm[];
	cameras: DeviceConfigurationCamera[];
	outputs: DeviceConfigurationRelay[];
	doors: DeviceConfigurationRelay[];
	audios: DeviceConfigurationAudio[];
}

export interface DeviceConfiguration {
	name: string;
	extraValue: string;
	host: string;
	port: number;
	username: string;
	password: string;
	syncIdentifier: string;
	alarms: DeviceConfigurationAlarm[];
	cameras: DeviceConfigurationCamera[];
	relays: DeviceConfigurationRelay[];
	audios: DeviceConfigurationAudio[];
	users: DeviceConfigurationUser[];
}

export interface DeviceConfigurationSelections {
	name: string;
	extraValue: string;
	host: string;
	port: number;
	username: string;
	password: string;
	syncIdentifier: string;
	alarms: DeviceConfigurationAlarmSelection[];
	cameras: DeviceConfigurationCameraSelection[];
	doors: DeviceConfigurationRelaySelection[];
	outputs: DeviceConfigurationRelaySelection[];
	audios: DeviceConfigurationAudioSelection[];
	users: DeviceConfigurationUser[];
}

export interface DeviceConfigurationAlarmSelection extends DeviceConfigurationAlarm {
	selected: boolean
}
export interface DeviceConfigurationAlarm {
	title: string;
	input1: number;
	input2: number;
	eventType: number;
	syncIdentifier: string;
	extraValue: string;
	linkedCameraInput: number;
	highPriority: boolean;
}

export interface DeviceConfigurationCameraSelection extends DeviceConfigurationCamera {
	selected: boolean
}

export interface DeviceConfigurationCamera {
	name: string;
    input1: number;
    hasPtz: boolean;
    presets: DeviceConfigurationPreset[];
    referenceImage: number[];
    syncIdentifier: string;
	extraValue: string;
    features: DeviceFeature[];
	latLong: string;
}

export interface DeviceConfigurationRelaySelection extends DeviceConfigurationRelay {
	selected: boolean
}

export interface DeviceConfigurationRelay {
	name: string;
    input1: number;
	input2: number;
    type: number;
    syncIdentifier: string;
	extraValue: string;
    features: DeviceFeature[];
	latLong: string;
}

export interface DeviceConfigurationAudioSelection extends DeviceConfigurationAudio {
	selected: boolean
}

export interface DeviceConfigurationAudio {
	name: string;
    input1: number;
    type: number;
    syncIdentifier: string;
	extraValue: string;
    features: DeviceFeature[];
	latLong: string;
}

export interface DeviceConfigurationUser {
	name: string;
	address: string;
	email: string;
	phone: string;
	extraValue: string;
	externalID: string;
	serverID: number;
}

export interface DeviceFeature {
	name: string;
	value: string;
}

export interface DeviceConfigurationPreset {
	name: string;
	position: number;
}

export interface DeviceConfigurationPresetTableItems extends DeviceConfigurationPreset {
	editing: boolean;
	creating: boolean;
}

export interface DeviceConfigurationPresetPayload extends DeviceConfigurationPreset {
	deviceId: number;
	groupId: number;
}

export interface DeviceSearchResult {
	deviceId: number;
	deviceTypeId: number;
	groupId: number;
	settingsMeta: string;
	title: string;
	serverTypeId?: number;
}

export interface DeviceSearchQuery {
	query: string;
	deviceTypeId: number;
	searchTermsInAnyOrder?: boolean;
}

export interface DeviceSearchSwipeAndShowQuery {
	query: string;
	searchTermsInAnyOrder?: boolean;
	regionUrl?: string;
}

export interface FetchServerByGroupParams {
	groupId: number;
	pageSize?: number;
}

export interface FetchConfigForServerParams {
	server: ServerDetails;
	serverType: ServerType;
	auth: string;
}

export interface SearchServerByGroupParams extends FetchServerByGroupParams {
	filter: string;
}

export interface GetDevicesByTypeParams {
	deviceTypeId: number;
	serverId?: number | null | undefined;
	page: number;
	pageSize: number;
	sortBy?: string;
	sortDesc?: boolean;
	searchTerm?: string | null | undefined;
	selectOutputs?: boolean | null | undefined;
	includeReferenceShots?: boolean | null | undefined;
}

export interface GetDeviceForGroupByTypeParams {
	groupId: number;
	deviceTypeId: number;
	filter: string | null | undefined;
}

export interface GetDeviceParams {
	eventId: number;
	deviceAction: string;
	noRecordingCamera: boolean;
}

export enum DeviceType {
	Camera = 1,
	Audio = 2,
	Relay = 3,
	SplitCamera = 4,
	Output = 5,
}

export enum DeviceTypeIdentifier {
	Camera = 1,
	Audio = 2,
	Output = 3,
	Door = 4
}

export enum ServerModalStartTab {
	Server = 1,
	Sync = 2,
}

export interface ServerType {
	serverTypeID: number;
	title: string;
	dll: string;
	dllDeviceType: string;
	numAudioInputs: number;
	numAudioOutputs: number;
	canSendMessage: boolean;
	canNotifyRadio: boolean;
	extraValueName: string;
	usesExtraCameraIDs: string;
	usesDeviceExtraValue: boolean;
	usesExtraCameraIds: boolean;
	usesExtraRelayIds: boolean;
	usesExtraAudioIds: boolean;
	urlTemplate: string;
	numCameras: number;
	numRelays: number;
	numAlarmInputs: number;
	hasMatrixBuiltIn: boolean;
	hidden: boolean;
	defaultPort: number;
	serverParams: string;
	alarmResponseCodeRegex: string;
	alarmEventCodeRegex: string;
	canGetConfig: boolean;
	isAlarmPanel: boolean;
}

export interface DeviceSearchResult {
	deviceId: number;
	deviceTypeId: number;
	groupId: number;
	settingsMeta: string;
	title: string;
}

export interface DeviceSearchQuery {
	query: string;
	deviceTypeId: number;
}

export interface DevicePreset {
	presetNumber: number;
	title: string;
}

export interface DeviceTypeCounts {
	devices: number;
	cameras: number;
	audios: number;
	doors: number;
	outputs: number;
	alarms: number;
}

export interface DeviceResponseRecordsRetrievalStatus extends DeviceRecordsRetrievalStatus {
	allAlarmsRetrieved: boolean;
}

export interface DeviceRecordsRetrievalStatus {
	allServersRetrieved: boolean;
	allFilteredServersRetrieved: boolean;
	allCamerasRetrieved: boolean;
	allFilteredCamerasRetrieved: boolean;
	allAudiosRetrieved: boolean;
	allFilteredAudiosRetrieved: boolean;
	allDoorsRetrieved: boolean;
	allFilteredDoorsRetrieved: boolean;
	allOutputsRetrieved: boolean;
	allFilteredOutputsRetrieved: boolean;
}

export interface ServersWithEnabledDeviceTypesParams extends PaginatedSearchQueryParams {
	audiosEnabled?: boolean;
	camerasEnabled?: boolean;
	relaysEnabled?: boolean;
}

export interface AudioSettingsMeta {
	transmitEnabled: boolean;
	receiveEnabled: boolean;
}

export interface CameraSettingsMeta {
	qual: string;
	telemServerId: number;
	telemSpeed: string;
}

export interface RelaySettingsMeta {
	relayType?: string;
	onOffEnabled: boolean;
	pulseEnabled: boolean;
	stateSwitchEnabled: boolean;
	stateSwitchDefaultState: boolean;
}

export interface ServerLineProfile {
	serverLineProfileId: number;
	title: string;
	groupId?: number;
}

export interface CameraPreviewDeviceDetails {
	title: string;
	deviceId: number;
}
