import { SiteMonitorState } from "./types";

export function getDefaultState(): SiteMonitorState {
    return {
        LastActivity: null,
        AreaNotesVisible: false,
        GroupID: null,
        EventDetails: null,
        EventRecords: [],
        UserID: 0,
        ControllerUserID: 0,
        IsController: false,
        requestingEventRecords: false,
        AdhocLocationEventRecords: [],
        ActiveResponseIDs: {},
        EventRecordsAwaitingAck: {},
        EventRecordsAwaitingAckCount: 0,
        EventRecordsAwaitingRestore: {},
        EventRecordsAwaitingRestoreCount: 0,
        EventRecordsWithFiles: [],
        SelectedEventRecord: null,
        LatestEventRecordID: 0,
        EventRecordsNoFilterRenderIndex: 0,
        EventRecordFilters: [
            {
                filterIndex: 0,
                filterTitle: "Alarms",
                filterRecordIds: [1],
                showNew: true,
                newCount: 0,
                hasPriorityColumn: true,
                hasLinkedCameraColumn: true,
                filterRecords: [],
                renderIndex: 0,
                enabled: true,
				hasVideoAvailableColumn: true,
				showMatrixGridLocation: true
            },
            {
                filterIndex: 1,
                filterTitle: "Operator",
                filterRecordIds: [13, 16, 21, 104, 105, 106],
                filterRecords: [],
                renderIndex: 0,
                enabled: true
            },
            {
                filterIndex: 2,
                filterTitle: "Dispatch",
                filterRecordIds: [48, 49, 50, 103, 112, 113],
                filterRecords: [],
                renderIndex: 0,
                enabled: false
            },
            {
                filterIndex: 3,
                filterTitle: "Devices",
                filterRecordIds: [4, 7, 8],
                filterRecords: [],
                renderIndex: 0,
                enabled: false,
				hasVideoAvailableColumn: true
            },
            {
                filterIndex: 4,
                filterTitle: "Actions",
                filterRecordIds: [100, 101, 102],
                filterRecords: [],
                renderIndex: 0,
                enabled: true
            },
            {
                filterIndex: 5,
                filterTitle: "Notes",
                filterRecordIds: [17, 103],
                showNew: true,
                newCount: 0,
                clearNewOnView: true,
                filterRecords: [],
                renderIndex: 0,
                enabled: true
            },
            {
                filterIndex: 6,
                filterTitle: "Messages",
                filterRecordIds: [141, 142],
                showNew: true,
                newCount: 0,
                clearNewOnView: true,
                filterRecords: [],
                renderIndex: 0,
                enabled: true
            }
        ],
        EventRecordsFilterActiveIndex: -1,
        EventDetailsOpen: false,
        EventRaiseOpen: false,
        EventSharingOpen: false,
        EventCloseOpen: false,
        EventLeaveOpen: false,
        PutEventOnTestOpen: false,
        InvolvedPartyOpen: false,
        ForceRestoreEventRecord: null,
        EventNoPermission: false,
        IncidentReportOpen: false,
        IncidentReportDownloaded: false,
        EventShares: [],
        MobileEventShares: [],
        ShareWithPersons: [],
        AdhocShareDetails: {},
        EventSharesSet: false,
        userShares: {},
        ExternalUsersAllowed: false,
        activeMapItems: null,
        mapCircleCenter: null,
        eventLocationLastSet: 0,
        auditService: null,
        MaskReasons: [],
        GroupMaskReasons: [],
        MaskedAlarms: [],
        Events: [],
        ActiveMapItemsRequired: true,
        IsUploadingFile: false,
        hideMapFlag: false,
		deviceServerDetails: [],
		applianceServerDetails: [],
		applianceServerAndDeviceServerPollingStatus: {
			isPolling: false,
			intervalHandlerId: 0
		},
		eventCaseId: null,
        goToLocation: null,
        eventOutcomes: {
            eventOutcomeTree: [],
            eventOutcomeFlatList: []
        },
        areaDetailsTabIndex: 0,
    }
}
