import { FieldOps } from "./types";
export function getDefaultState(): FieldOps {
    return {
        assetTypes: new Map<string, any>(),
        onlineAssets: [],
        manuallyChangedAssets: [],
        timer: null,
        lastPollCall: null,
        activeSubscribers: [],
        viewingAssetId: 0,
		situationalAwarenessEventQueue: [],
		selectedSituationalAwarenessEvent: null
    }
}
