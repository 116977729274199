
import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";

@Component({})
export default class ScheduleMaskDuration extends Vue {
	@Prop({ type: String, required: true }) private startMask: string;
	@Prop({ type: String, required: true }) private endMask: string;

	private oneMinuteInMilliseconds: number = 60000;

	private get maskedStartTime(): string {
		const startDate = new Date(this.startMask);
		return this.formattedTime(startDate);
	}

	private get maskedEndTime(): string {
		const endDate = new Date(this.endMask);
		return this.formattedTime(endDate);
	}

	private get unMaskedStartTime(): string {
		const startDate = new Date(this.startMask);
		startDate.setMinutes(startDate.getMinutes() - 1);
		return this.formattedTime(startDate);
	}

	private get unMaskedEndTime(): string {
		const endDate = new Date(this.endMask);
		endDate.setMinutes(endDate.getMinutes() + 1);
		return this.formattedTime(endDate);
	}

	private get maskedPeriod(): string {
		if (this.maskOver24Hours) {
			return `${this.formattedMaskedOver24Hours(this.startMask)} : ${this.formattedMaskedOver24Hours(this.endMask)}`;
		}

		return `${this.maskedStartTime} : ${this.maskedEndTime}`;
	}

	private get maskDurationInHours(): number {
		const duration = moment.duration(moment(this.endMask).diff(moment(this.startMask)));
		return duration.asHours();
	}

	private get maskOver24Hours(): boolean {
		return this.maskDurationInHours > 24;
	}

	private formattedTime(dateTime: Date): string {
		const dateTimeWithoutOffset = new Date(dateTime.getTime() - (dateTime.getTimezoneOffset() * this.oneMinuteInMilliseconds));
		const formattedDateTime = dateTimeWithoutOffset.toISOString().replace('Z', '').replace('T', ' ');
		const time = new Date(formattedDateTime).toTimeString().split(' ')[0].split(':');

		return `${time[0]}:${time[1]}`;
	}

	private formattedMaskedOver24Hours(dateTimeAsString: string): string {
		const dateTime = new Date(dateTimeAsString);
		const dateTimeWithoutOffset = new Date(dateTime.getTime() - (dateTime.getTimezoneOffset() * this.oneMinuteInMilliseconds));
		return dateTimeWithoutOffset.toISOString().replace('Z', '').replace('T', ' ').replace('.000', '');
	}
}
