
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";
import { ActionPlanTask } from "@/store/tasks/types";
import { stringMixin } from "@/mixins";

const { isNullOrWhitespace } = stringMixin.methods;

@Component({
	components: {}
})
export default class BasicTask extends Vue {
	@Prop(Boolean) public editing: Boolean;

	@Prop()
	public task!: ActionPlanTask;

	public hasValidationErrors: boolean = false;
	public showErrorAnimation: boolean = false;
	private updating: boolean = false;
	public get basicTaskModel() {
		return this.task.completed;
	}

	public set basicTaskModel(checked: boolean) {
		this.updateTask(checked);
	}

	public mounted() {
		this.$nextTick(() => {
			if (this.editing) {
				this.onValidateTask();
			}
		});
	}

	// Ensure the task was setup correctly.
	@Emit("validate-task")
	public onValidateTask() {
		let isValid = !isNullOrWhitespace(this.task.taskText);

		if (!isValid) {
			this.hasValidationErrors = true;
			this.showErrorAnimation = true;

			setTimeout(() => {
				this.showErrorAnimation = false;
			}, this.$config.ANIMATION_DURATION);
		} else {
			this.hasValidationErrors = false;
			this.showErrorAnimation = false;
		}

		return isValid;
	}

	private isFocused: boolean = false;

	private updateTask(checked: boolean){
		if(this.updating)
			return;

		this.updating = true;
		try{
			this.$emit("complete-task", {
				...this.task,
				completed: checked
			});
		} finally {
			this.updating = false;
		}
	}
}
