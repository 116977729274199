import { axiosInstance, axiosJsonInstance } from "@/axios.instance";
import { ResponseMaskingMeta, Schedule, ScheduleType, SchedulesPagedResponse } from "@/store/schedules/types";
import { PublicHolidayApiContract, PublicHolidayDto } from "@/store/schedules/types";
import { AxiosInstance, CancelTokenSource } from "axios";
import { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";
import PublicHolidaysWithPagination from "@/types/sv-data/schedule/PublicHolidaysWithPagination";
import { apiFormattingMixins } from "@/mixins";
const { arrayToQueryString } = apiFormattingMixins.methods

interface FilterSchedulesApiRequest {
	filterString: string;
	groupId: number,
	scheduleTypes: number[],
	startTime: Date,
	endTime: Date,
	enabledSchedules: boolean | null,
}

interface ScheduleApiRequest {
	scheduleId: string;
	scheduleTypeId: number;
	groupId: number;
	startDate: string;
	endDate?: string;
	title: string;
	intervalValue: number;
	intervalTypeId: number;
	expiresOn?: string;
	maxOccurences?: number;
	publicHolidayTypeId: number;
	responseId?: string;
	disabled?: boolean;
	daysToRun?: string;
	responseMaskingMeta?: ResponseMaskingMeta;
	serverTypeEvent?: string;
}

class SchedulesApi {
	private axios: AxiosInstance;
	private axiosJson: any;
	private dateTimeDstOffsetStart: number = 0;
	private dateTimeDstOffsetEnd: number = 19;

	constructor() {
		this.axios = axiosInstance;
		this.axiosJson = axiosJsonInstance;
	}

	public async getPublicHolidays(paginatedSearchRequest: PaginatedSearchRequest) : Promise<PublicHolidaysWithPagination> {
		const { data } = await this.axios.get("/PublicHoliday/GetPublicHolidays", paginatedSearchRequest);
		return data;
	}

	public async getPublicHolidaysBetweenDates(startDate: string, endDate: string, groupId: number) : Promise<PublicHolidayDto[]> {
		const { data } = await this.axios.get(`/PublicHoliday/GetHolidayBetweenDates?startDate=${startDate}&endDate=${endDate}&groupId=${groupId}`);
		return data;
	}

	public async createOrUpdatePublicHoliday(publicHolidayDto: PublicHolidayApiContract) : Promise<PublicHolidayDto> {
		const { data } = await this.axiosJson.put("/PublicHoliday/CreateOrUpdatePublicHoliday", publicHolidayDto);
		return data;
	}

	public async deletePublicHoliday(request: number) : Promise<void> {
		await this.axios.delete(`/PublicHoliday/DeletePublicHoliday?publicHolidayId=${request}`);
	}

	public async filterSchedules(
		filteringSearch: string,
		selectedGroup: number,
		selectedScheduleTypes: number[],
		startDate: Date,
		endDate: Date,
		enabled: boolean | null,
		cancelToken?: CancelTokenSource)
		: Promise<Schedule[]> {
		var filteringModel = {
			filterString: filteringSearch,
			groupId: selectedGroup,
			scheduleTypes: selectedScheduleTypes,
			startTime: startDate,
			endTime: endDate,
			enabledSchedules: enabled,
		} as FilterSchedulesApiRequest
		let { data } = await this.axiosJson.post("/Schedules/GetFilteredSchedules",
			filteringModel,
			{ cancelToken: cancelToken?.token });
		return data;
	}

	public async getAllScheduleTypes(): Promise<ScheduleType[]> {
		const { data } = await this.axios.get("/Schedules/GetScheduleTypes");
		return data;
	}

	public async getPaginatedSchedules(
		page: number,
		pageSize: number,
		sortBy: string,
		sortDesc: boolean,
		groupId: number | null,
		searchTerm: string,
		scheduleType: number[],
		enabledSchedules: boolean | null)
		: Promise<SchedulesPagedResponse> {

		let query = `/Schedules/GetPaginatedSchedules?` +
		    `${arrayToQueryString("scheduleTypes", scheduleType)}&` +
			`groupId=${groupId}&`+
			`page=${page}&`+
			`pageSize=${pageSize}&`+
			`sortBy=${sortBy}&`+
			`sortDesc=${sortDesc}&`+
			`searchTerm=${searchTerm}&` +
			`enabledSchedules=${enabledSchedules}`;

		const { data } = await this.axiosJson.get(query);
		return data;
	}

	public async createOrUpdateSchedule(schedule: Schedule): Promise<Schedule> {
		let scheduleRequest = {
			scheduleId: schedule.scheduleId,
			scheduleTypeId: schedule.scheduleTypeId,
			groupId: schedule.groupId,

			// Due to moment being deprecated, to remove the DST offset given when converting
			// from DateTime to a string in ISO format, we must truncate the DST offset appended during the conversion.
			startDate: schedule.startDateTime.slice(this.dateTimeDstOffsetStart, this.dateTimeDstOffsetEnd),
			endDate: schedule.endDateTime.slice(this.dateTimeDstOffsetStart, this.dateTimeDstOffsetEnd),
			title: schedule.title,
			intervalValue: schedule.intervalValue,
			intervalTypeId: schedule.intervalTypeId,
			expiresOn: schedule.expiresOn,
			maxOccurrences: schedule.maxOccurrences,
			publicHolidayTypeId: schedule.publicHolidayTypeId,
			daysToRun: schedule.daysToRun,
			disabled: schedule.disabled,
			responseId: schedule.responseId?.toString(),
			serverTypeEvent: schedule.serverTypeEvent?.toString(),
			responseMaskingMeta: schedule.responseMaskingMeta ?? null
		} as ScheduleApiRequest;

		const { data } = await this.axiosJson.put("/Schedules/CreateOrUpdateSchedule", scheduleRequest);
		return data;
	}

	public async deleteSchedule(scheduleTaskId: number): Promise<void> {
		const { data } = await this.axiosJson.delete(`/Schedules/DeleteSchedule?scheduleId=${scheduleTaskId}`);
		return data;
	}
}

const schedulesApi = new SchedulesApi();
export default schedulesApi;
