
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import VSwatches from 'vue-swatches'

@Component({
	components: {
        "color-swatch" : VSwatches
	}
})
export default class AssetColorPicker extends Vue {
    @Prop({type: Boolean, default: false})
    private readonly: boolean;

    @Prop()
    private value: string | null;
    
    private colour: string | null = null;
    private initialColour: string | null = null;

    private mounted() {
        const startColour = this.value;
        this.colour = startColour;
        this.initialColour = startColour;
    }

    @Watch("colour")
    @Emit("input")
    private colourWatch(){
        return this.colour;
    }

    private clear() {
        this.colour = null;
    }

    private cancel() {
        this.colour = this.initialColour;
    }
}
