import { ActionTree } from "vuex";
import {
	DistanceConfigurationPagedResponse,
	DistanceConfigurationState
} from "./types";
import { RootState } from "@/store/types";
import { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";
import api from "@/services/api.service";

export const actions: ActionTree<DistanceConfigurationState, RootState> = {
	async retrieveDistanceConfigurations({ commit }, request: PaginatedSearchRequest) {
		const data: DistanceConfigurationPagedResponse = await api.retrieveDistanceConfigurations(request);
		commit("setDistanceConfigurations", data);
	},
	async retrieveMaxRadius({ commit }) {
		const data: number = await api.retrieveDistanceConfigurationMaxRadius();
		commit("setMaxRadius", data);
	}
}