import Vue from "vue";
import BootstrapVue from "bootstrap-vue";

// uncomment below for a styling easter egg
// import 'bootstrap/dist/css/bootstrap.css';
import "vselect3/dist/vue-select.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-datetime/dist/vue-datetime.css";
import "animate.css/animate.css";
import "@sureview/camera/dist/camera.css";

import ImmixCloudAppV2 from "./App.vue";
import router from "./router";
import store from "./store/";
import { sync } from "vuex-router-sync";
import shortkey from "vue-shortkey";
import { formatDateMixin } from "./mixins";
import Notifications from "vue-notification";
import VueSubscriptionsStore from "@/services/subscriptions.plugin";
import { applyAxiosInterceptors } from "@/services/axios.interceptors";
import VueAppConfig from "@/services/appConfig.plugin";
import Router from "vue-router";
import { Linkify, LazyLinkifyOptions } from "@sureview/v2-linkify";
import Camera from "@sureview/camera";
import vuetify from "@/plugins/vuetify";

Vue.use(Router);

// On route change:
// 1. guard condition preventing unauthenticated users accessing
// routes that require login.
// Such routes are marked with `meta: { auth: true }` in router definition
// 2. cancel any long-polling subscriptions.
router.beforeEach((to, from, next) => {
	(router.app as any).subscriptions$.unsubscribeAll();
	const authRequired = to.matched.some(route => route.meta.auth);
	const authed = store.getters.isLoggedIn;
	const closeOnLogout = to.matched.some((route: any) => route.meta.closeOnLogout);
	const redirectOnLogin = to.matched.some((route: any) => route.meta.redirectOnLogin);
	const subscriptionState = store.state.subscription;

	// For suite tenancies, if field ops only users attempts to access to any of the non-licenced routes return them to field ops
	if (!store.getters.getIsResponseLicensed
		&& store.getters.getIsFieldOpsLicensed
		&& !store.getters.getFieldOpsLicencedRoutes.includes(to.name)) {
		if(authed)
		{
			next("/field-ops");
		} else {
			next("/login")
		}
		return;
	}

	// If we have a subscription for the tenancy and its not active stop a user under that tenancy from accessing anything
	// apart from the event queue, organization settings or login page, redirect them
	if (subscriptionState.isLoaded
		&& !subscriptionState.subscription.isActiveState
		&& !["event-queue","organization", "login"].includes(to.name)) {
			if(authed){
				next("/event-queue");
			} else {
				next("/login")
			}
		return;
	}

	if (authRequired && !authed) {
		if (closeOnLogout) {
			window.close();
		}
		if (redirectOnLogin) {
			next("/login?redirect=" + to.fullPath);
		} else if (to.path.indexOf("login") > 0) {
			next();
		} else {
			next("/login");
		}
	} else {
		next();
	}
});

// @ref https://github.com/vuejs/vuex-router-sync
const unsyncRouterStore = sync(store, router); // @techdebt on teardown

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(shortkey, {
	prevent:
	[
		".prevent-shortcut", "input[type=text]",
		"input[type=date]","input[type=email]",
		"input[type=password]","input[type=number]",
		"input[type=search]","input[type=tel]",
		"input[type=url]", "textarea"
	]
});
Vue.mixin(formatDateMixin);
Vue.use(VueAppConfig);
Vue.use(VueSubscriptionsStore);
Vue.use(
	Linkify,
	new LazyLinkifyOptions($store => {
		// Check the dynamic link feature flag is enabled
		if ($store.getters["getFeature"](["Integration", "DynamicLink"])) {
			return $store.getters["getDynamicLinkConfig"];
		} else {
			return null;
		}
	})
);
Vue.use(Camera);
Vue.config.errorHandler = async function (err, vm, info) {
	if (typeof window.newrelic !== "undefined" && typeof window.newrelic.noticeError === "function") {
		window.newrelic.noticeError(err);
	}
	// eslint-disable-next-line no-unsafe-finally
	throw err;
};

applyAxiosInterceptors(Vue);

new Vue({
	router,
	store,
	vuetify,
	render: h => h(ImmixCloudAppV2),
	mounted() {
		this._escListener = function(e) {
			const validTagNames = ["INPUT", "TEXTAREA"]
			if (e.key === "Escape" && e.srcElement && validTagNames.includes(e.srcElement.tagName)) {
				e.target.blur();
                e.preventDefault();
            }
        };

        document.addEventListener("keydown", this._escListener.bind(this));
	},
	beforeDestroy(){
		document.removeEventListener("keydown", this._escListener);
	}
}).$mount("#app");
