import { ILatLng } from "@sureview/v2-mapping-saas";

export interface AssetMapLayerItem {
	mapLayerItemId: number;
	title: string;
	extraValue: string;
	latLng: ILatLng;
	latLong: string;
	objectId: number;
	regionPath: string;
	mapLayerItemTypeId: number;
	mapLayerIds: number[];
	status: string;
	statusNote: string;
	isAsset: boolean;
	hideOnMap: boolean;
	unackedMessageCount?: number
	assetId: number;
	userId?: number;
	shareable: boolean;
	activeEventShare: boolean;
	eventDetails: string;
	assetTypeId: number;
	iconColor: string;
	lastLocationUpdate: Date;
	isTrackedAsset?: boolean;
	trackedAssetId?: number;
}

export interface MapLayerItemWithExpiry{
	mapLayerItem: AssetMapLayerItem;
	checksRemaining: number;
}

export interface AssetStatusInfo {
    assetId: number,
    status: string,
    statusNote:string,
    title?: string
}

export interface AssetAuditHistory {
	eventRecordID: number,
	assetTitle: string,
	created: Date,
	name: string,
	details : string
}

export interface ActionRequest {
	asset: AssetStatusInfo,
	action: ActionType,
	item: AssetMapLayerItem
}

export interface MappedAsset {
	Id: number,
	label: string,
	knownLoc: string,
	item: AssetMapLayerItem
}

export const enum ActionType {
	Status  = 0,
	Chat = 1,
	History = 2,
	Move = 3,
	SetLocation = 4,
	ShowLocation = 5,
	HideLocation = 6,
	CancelSetLocation = 7
}
export interface AuditRequest {
	assetId: number,
	beforeEventRecordId: number,
	maxRows: number
}
