
import { Component, Mixins } from "vue-property-decorator";
import NavHeader from "@/components/NavHeader.vue";
import PaginatedSearch from "@/mixins/PaginatedSearch";
import NotificationTemplate from "@/types/sv-data/notification-templates/NotificationTemplate";
import NotificationTemplatePagedRequest from "@/types/sv-data/notification-templates/NotificationTemplatePagedRequest";
import NotificationTemplateApi from "@/services/api.notificationTemplate.service";
import { PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
import { Getter } from "vuex-class";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { ModalItem } from "@/components/table/generic-update-modal.vue";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import RichTextEditor from "@/components/form/RichTextEditor.vue";
import RichTextEditorPlaceholder from "@/types/RichTextEditorPlaceholder";
import NotificationTemplateTypeId from "@/types/sv-data/enums/NotificationTemplateTypeIds";
import NotificationTemplatePreviewRequest
	from "@/types/sv-data/notification-templates/NotificationTemplatePreviewRequest";
import NotificationDocumentTypeId from "@/types/sv-data/enums/NotificationDocumentTypeIds";

@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable
	}
})
export default class NotificationTemplateSetup extends Mixins(PaginatedSearch) {
	@Getter("getPermissions") private permissions: UserPermissions;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	private notificationTemplates: NotificationTemplate[] = [];
	private manualFields: RichTextEditorPlaceholder[] = [];
	private selectedNotificationTemplate: NotificationTemplate = null;
	private isLoading: boolean = false;
	private totalRecords: number = 0;
	private previewEventId: number = null;

	private async mounted(): Promise<void> {
		await this.getPaginatedNotificationTemplates();
	}

	private get isNotificationTemplatesEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "Email", "NotificationTemplates"]);
	}

	private get isInvolvedPartiesEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "InvolvedParties"])
	}

	private get hasNotificationTemplatePermissions(): boolean {
		return this.permissions.canViewNotificationTemplates || this.permissions.canEditNotificationTemplates;
	}

	private async createNotificationTemplate(notificationTemplate: NotificationTemplate): Promise<void> {
		await NotificationTemplateApi.createNotificationTemplate(notificationTemplate);
		await this.getPaginatedNotificationTemplates(this.mostRecentSearchParams);
	}

	private async getPaginatedNotificationTemplates(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {

		this.isLoading = true;
		this.notificationTemplates = [];

		if (!paginatedSearchQueryParams) {
			paginatedSearchQueryParams = {
				page: 1,
				pageSize: 25,
				sortDesc: false,
				sortBy: "Title",
				searchTerm: ""
			};
		}

		const searchParams = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);

		let request = { ...searchParams, ...paginatedSearchQueryParams } as NotificationTemplatePagedRequest;

		// Hard coded as no other types exist
		request.notificationTemplateTypeId = NotificationTemplateTypeId.Event;

		this.mostRecentSearchParams = request;

		let results = await NotificationTemplateApi.getNotificationTemplates(request, request.groupId);
		this.totalRecords = results.totalRecords;
		this.notificationTemplates = results.data;
		this.isLoading = false;
	}

	private async updateNotificationTemplate(notificationTemplate: NotificationTemplate) {
		await NotificationTemplateApi.updateNotificationTemplate(notificationTemplate);
		await this.getPaginatedNotificationTemplates(this.mostRecentSearchParams);
	}

	private async deleteNotificationTemplate(notificationTemplate: NotificationTemplate) {
		await NotificationTemplateApi.deleteNotificationTemplate(notificationTemplate.notificationTemplateId);
		await this.getPaginatedNotificationTemplates(this.mostRecentSearchParams);
	}

	private async requestNotificationPreview(): Promise<void> {
		if(this.selectedNotificationTemplate == null || this.previewEventId < 1) {
			return;
		}
		this.previewEventId = Number(this.previewEventId);

		let request: NotificationTemplatePreviewRequest = {
			notificationTemplate: this.selectedNotificationTemplate,
			notificationDocumentTypeId: NotificationDocumentTypeId.Pdf,
			manualInputFields: {},
		}

		await NotificationTemplateApi.previewNotificationTemplate(this.previewEventId, request);
	}

	private async onModalOpen(value: NotificationTemplate): Promise<void> {
		if (value) {
			this.selectedNotificationTemplate = value;
			this.manualFields = this.selectedNotificationTemplate.manualFields;
		} else {
			this.selectedNotificationTemplate = null;
			this.manualFields = [];
		}
	}

	private get columns(): TableHeader[] {
		return [
			{
				title: "Title",
				key: "title",
				order: 1,
				dataType: "input",
				description: "Notification Template Title",
				sortable: true,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true,
				isTermLabel: true
			},
			{
				title: "Area",
				key: "groupTitle",
				order: 2,
				dataType: "input",
				description: "Area Title",
				sortable: false,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true
			},
			{
				title: "Inherited",
				key: "inherited",
				order: 3,
				dataType: "checkbox",
				sortable: true,
				sortOrder: 0,
				sortOrderReversed: false,
				visible: true
			}
		];
	}

	private get placeholders(): RichTextEditorPlaceholder[] {
		let placeholderItems: RichTextEditorPlaceholder[] = [
			{
				id: "areaName",
				label: "Affected Area",
			},
			{
				id: "areaAddress",
				label: "Area Address",
			},
			{
				id: "areaReferenceId",
				label: "Area Unique Identifier",
			},
			{
				id: "eventCreatedDate",
				label: "Event Started",
			},
			{
				id: "alarms",
				label: "Alarms",
			},
			{
				id: "eventOutcome",
				label: "Event Outcome"
			},
			{
				id: "eventOutcomeNote",
				label: "Event Outcome Note"
			},
			{
				id: "note",
				label: "Note"
			},
			{
				id: "senderName",
				label: "Sender Name"
			},
			{
				id: "eventUsers",
				label: "Event Users"
			}
		];

		if (this.isInvolvedPartiesEnabled) {
			let involvedPartyPlaceholder: RichTextEditorPlaceholder = {
				id: "involvedParties",
				label: "Involved Parties"
			}

			placeholderItems.push(involvedPartyPlaceholder as RichTextEditorPlaceholder);
		}

		return placeholderItems;
	}

	private get modalItems(): ModalItem[] {
		return [
			{
				title: "Title",
				key: "title",
				dataType: "text",
				required: true,
				maxLength: 50,
				description: "Enter a notification template title",
				placeholder: "Notification template title"
			},
			{
				title: "Inherited",
				key: "inherited",
				required: false,
				dataType: "checkbox"
			},
			{
				title: "Area",
				key: "groupId",
				dataType: "component",
				data: AreaTreeSelect,
				required: true,
				props: {
					reduce: (area: AreaNode) => area.id,
					clearable: false
				}
			},
			{
				title: "Body",
				key: "body",
				dataType: "component",
				data: RichTextEditor,
				props: {
					placeholders: this.placeholders,
					manualFields: this.manualFields,
					minHeight: "350px"
				}
			}
		];
	}
}
