import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { SignUpState } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: SignUpState = getDefaultState();

export const signup: Module<SignUpState, RootState> = {
	namespaced: true,
    getters,
	state,
	actions,
	mutations
};
