
    import { Response } from "@/store/alarm-masking/types";
    import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
    import MaskingForm from "@/components/alarm-masking/MaskingForm.vue";

	@Component({
        components: {
            "masking-form": MaskingForm
        }
	})

    export default class AdvancedMaskModal extends Vue {
        public $refs!: {
            maskingForm: MaskingForm
        }

        // Props
        @Prop({ type: Array, default(){ return [] }})
        public selectedResponses: Response[];

        @Prop({default: false, type: Boolean})
        public readonly value: boolean;

        // Private Vars
        private isModalShown: boolean = false;
        private isInvalid: boolean = true;

        // Getter Methods
        private get maskingTitle(): string {
			return this.hasResponses ? "Mask Alarms" : "Mask Area Alarms";
		}

        private get hasResponses(): boolean {
            return this.selectedResponses != null && this.selectedResponses.length != 0;
        }

        // Watchers
        @Watch("value")
		private async onVModelUpdated(newValue: boolean): Promise<void> {
			this.isModalShown = newValue;
		}

        // Private Methods
        private async maskAlarms(): Promise<void> {
            this.$refs.maskingForm.maskAlarms();
        }

        private validateMaskingForm(value: boolean): void {
            this.isInvalid = value;
        }

        // Emits
        @Emit("modalClosed")
        private close(): void {
            this.isInvalid = true;
        }
    }
