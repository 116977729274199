
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import NavHeader from "@/components/NavHeader.vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import vselect3 from "vselect3";
import { required, helpers } from 'vuelidate/lib/validators'
import { cloneDeep } from "lodash";
import { validationMixin } from 'vuelidate';
import { Tour } from "@/store/tours/types"
import AreaTreeSelect from './form/AreaTreeSelect.vue';
import api from '@/services/api.service';

const MIN_DURATION = 5;
const MAX_DURATION = 32767; //Max number of the database field

const Areas = namespace("areas");
const Tours = namespace("tours");

const requiredValidator = helpers.withParams(
	{ type: "required" },
	function(val) {
		return this.tourUsingDuration ? !!val : true;
	}
)

const minValidator = (minVal: number) => helpers.withParams(
	{ type: "min", value: minVal },
	function(val) {
		return this.tourUsingDuration && val ? val >= minVal : true;
	}
)

const maxValidator = (maxVal: number) => helpers.withParams(
	{ type: "max", value: maxVal },
	function(val) {
		return this.tourUsingDuration && val ? val <= maxVal : true;
	}
)

const defaultMatrixOption: TourSetupMatrixOptions = {
	title: "3 x 3",
    matrixColumnSize: 3,
    matrixRowSize: 3
}

interface TourWithMatrixOptions extends Tour {
	matrixOptions: {
		matrixColumnSize: number,
		matrixRowSize: number
	}
}

interface TourSetupMatrixOptions {
    title: string,
    matrixColumnSize: number,
    matrixRowSize: number
}

@Component({
	components: {
		draggable,
		"vue-select": vSelect,
		"nav-header": NavHeader,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
        "area-tree-select": AreaTreeSelect,
		vselect3
	},
	mixins: [validationMixin],
	validations: {
		currentTour: {
			title: {
				required,
			},
			tourSteps: {
				required,
				$each: {
					duration: {
						required: requiredValidator,
						min: minValidator(MIN_DURATION),
						max: maxValidator(MAX_DURATION)
					}
				}
			}
		}
	}
})
export default class TourSetupModal extends Vue {
	/** Store Getter/Setter */
	@Getter getUserTenantGroupId: number;

	@Tours.Action fetchAllTours: any;
	@Tours.Action fetchDeviceList: any;
	@Tours.Action removeDevice: any;
	@Tours.Action searchDeviceList: any;
	@Tours.Getter getTour: any;
	@Tours.Getter getDeviceList: any;
	@Tours.Getter getSearchDevices: any;
	@Tours.Mutation addSearchDevices: any;

	@Areas.Action getAreaList: any;
	@Areas.Getter("getAreaList") areaList!: any[];

	@Prop({default: 0, type: Number})
	private tourId: number;

	private tourUsingDuration: boolean = true;
	private deletingIndex:number = -1;
	private tourGroupId: number = 0;
	private searchableGroupId: number = 0;

	// Api search calls
	private deviceSearchTimer: any = null;
	private deviceSearchValue: string = "";

	private currentTour: TourWithMatrixOptions = {
		groupId: 0,
		title: "",
		tourId: 0,
		tourOrder: 0,
		tourSteps: [],
		functionality: null,
		tourStepsCount: 0,
		matrixRowSize: 3,
		matrixColumnSize: 3,
		matrixOptions: {
			matrixColumnSize: 3,
			matrixRowSize: 3
		}
    }

	private matrixOptions: TourSetupMatrixOptions[] = [
		{
			title: "1 x 1",
			matrixColumnSize: 1,
			matrixRowSize: 1
		},
		{
			title: "2 x 2",
			matrixColumnSize: 2,
			matrixRowSize: 2
		},
		{
			title: "2 x 3",
			matrixColumnSize: 2,
			matrixRowSize: 3
		},
		{
			title: "3 x 3",
			matrixColumnSize: 3,
			matrixRowSize: 3
		},
		{
			title: "4 x 3",
			matrixColumnSize: 4,
			matrixRowSize: 3
		},
		{
			title: "4 x 4",
			matrixColumnSize: 4,
			matrixRowSize: 4
		},
	];

	/** Filter through our current tour steps and if contains the same device, remove from the device list. */
	private get filteredDevices(): any[] {
		if (this.getSearchDevices.length > 0 && this.deviceSearchValue !== "") {
			return cloneDeep(this.getSearchDevices.filter(devices => {
				// Filter through the devices and only return the ones that hasn't been added already.
				return (this.currentTour && this.currentTour.tourSteps.find(tourStep => tourStep.objectID == devices.objectID)) ?  false : true;
				// Map the device object with the title containing the area e.g. Area - CameraName
			})).map(device =>  this.getDeviceArea(device));
		} else {
			return cloneDeep(this.getDeviceList.filter(devices => {
				// Filter through the devices and only return the ones that hasn't been added already.
				return  (this.currentTour && this.currentTour.tourSteps.find(tourStep => tourStep.objectID == devices.objectID)) ?  false : true;
				// Map the device object with the title containing the area e.g. Area - CameraName
				})).map(device => {return this.getDeviceArea(device);
			});
		}
	}

	/** Method to fetch devices depending on the group you are currently on. */
	@Watch("searchableGroupId")
	private async updateDevicesList() : Promise<void> {
		await this.fetchDeviceList(this.searchableGroupId ? this.searchableGroupId : this.getUserTenantGroupId);
	}

    private async mounted() {
		this.searchableGroupId = this.getUserTenantGroupId;
		this.tourGroupId = this.getUserTenantGroupId;
		await this.fetchDeviceList(this.getUserTenantGroupId);
		if (this.tourId > 0) {
			this.editTourDetails(this.tourId);
		} else {
			this.tourUsingDuration = true;
			this.currentTour = this.cloneTourTemplate();
		}
	}

	private getDeviceArea(device: any): any {
		let title = this.areaList.find(area => area.groupID == device.groupId)
		return {title: `${ title ? title.title : "" } - ${ device.title }`, groupId: device.groupId, objectID: device.objectID}
	}

	private cloneTourTemplate(): TourWithMatrixOptions {
		const tourTemplate: any = {
			groupId: this.getUserTenantGroupId,
			title: "",
			tourId: 0,
			tourOrder: 0,
			tourSteps: [],
			functionality: null,
			tourStepsCount: 0,
			matrixColumnSize: 3,
			matrixRowSize: 3,
			matrixOptions: defaultMatrixOption
		};
		// Deep cloned our template due to wanting it to be passed by VALUE not reference.
		return cloneDeep(tourTemplate);
	}

	private removeDeviceFromTour(): void {
		this.currentTour.tourSteps.splice(this.deletingIndex, 1);
		this.deletingIndex = -1;
	}

	/** Loads the desired tour into the edit modal */
	@Watch("tourId")
	public editTourDetails(tourId: number): void {
		this.currentTour = this.getTour(tourId);
		if(!this.currentTour){
			this.currentTour = this.cloneTourTemplate();
		}

		var matrixOptions = this.matrixOptions
			.find(x => x.matrixColumnSize == this.currentTour.matrixColumnSize
					&& x.matrixRowSize == this.currentTour.matrixRowSize
			);

		this.currentTour.matrixOptions = matrixOptions ? matrixOptions :  defaultMatrixOption;
		this.tourGroupId = this.currentTour.groupId ? this.currentTour.groupId : this.getUserTenantGroupId;

		// steps based on confirmation is based on null, else duration is a number
		if (this.currentTour.tourSteps.length > 0)
		{
			this.currentTour.tourSteps[0].duration == 0 ? this.tourUsingDuration = false : this.tourUsingDuration = true;
		}
    }

	/** Save new or update tour */
	public async save() {
		this.currentTour.tourStepsCount = this.currentTour.tourSteps.length;
		this.currentTour.groupId = this.tourGroupId > 0 ? this.tourGroupId : this.getUserTenantGroupId;
		//If the tourUsingDuration is set to false i.e. confirmation reset the step duration to 3
		if(this.currentTour && this.currentTour.tourSteps && this.currentTour.tourSteps.length > 0 && !this.tourUsingDuration) {
			this.currentTour.tourSteps.forEach(step => {
				step.duration = 0;
			});
		}

		// new tour.
		if (this.currentTour.tourId == 0) {
			await api.addTour(this.currentTour);
		} else {
			this.currentTour.tourSteps.forEach(step => {
				step.tourId = this.currentTour.tourId;
			});
			await api.updateTour(this.currentTour);
		}

		await this.fetchAllTours();
		this.tourUsingDuration = true;
		this.currentTour = this.cloneTourTemplate();
	}

    @Watch("$v.$invalid")
    private evaluateSetupValid(){
        this.$emit('setup-valid', this.$v.$invalid);
	}

	private addDeviceToTour(device: any): void {
		// Check if the user is searching or not then use the correct array.
		if (this.deviceSearchValue) {
			const dev = this.getSearchDevices.find(x => x.objectID == device.objectID);
			this.currentTour.tourSteps.push({
				...dev,
				duration: this.getLastStepDuration()
			});
		} else {
			const dev = this.getDeviceList.find(x => x.objectID == device.objectID);
			this.currentTour.tourSteps.push({
				...dev,
				duration: this.getLastStepDuration()
			});
		}

		this.addSearchDevices([]);
	}

	private getLastStepDuration() : number {
		if (this.currentTour.tourSteps.length > 0) {
			let lastDevice = this.currentTour.tourSteps[this.currentTour.tourSteps.length - 1];
			return lastDevice.duration;
		}
		return MIN_DURATION;
	}

	private addAllDevices(): void {
		this.getDeviceList.forEach((element) => {
			const found = this.currentTour.tourSteps.findIndex(x => x.objectID == element.objectID);
			if (found == -1) {
				this.currentTour.tourSteps.push({
					...element,
					duration: this.getLastStepDuration()
				});
			}
		});
	}

	private searchDevice(value: any): void {
		if (value == "") {
			this.addSearchDevices([]);
			return;
		}

		this.deviceSearchValue = value;
		clearTimeout(this.deviceSearchTimer);
		this.deviceSearchTimer = setTimeout(() => {
			this.searchDeviceList({ groupID: this.searchableGroupId, searchText: value });
		}, 2000);
	}

	private onMatrixInput(value: TourSetupMatrixOptions): void {
		this.currentTour.matrixColumnSize = value.matrixColumnSize;
		this.currentTour.matrixRowSize = value.matrixRowSize;
		this.currentTour.matrixOptions = value;
	}

	private updateDeleteIndex(index: number | null): void {
		this.deletingIndex = index;
	}
}
