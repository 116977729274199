import { ActionTree } from "vuex";
import { RootState } from "../types";
import { MobileRaiseState, TemplateDetailsModel } from "./types";
import * as api from "@/services/api.mobileRaiseTemplates.service";
import { mapEditModelToTemplate } from "./typesFunctions";

export const actions: ActionTree<MobileRaiseState, RootState> = {
	async loadTemplates({ commit }) {
		const templates = await api.loadTemplates();
		commit("setMobileRaiseTemplates", templates);
	},

	async loadMobileRaises({ commit }) {
		const raises = await api.loadMobileRaises();
		commit("setMobileRaises", raises);
	},

	async loadIconSets({ commit }) {
		const iconSets = await api.loadIconSets();
		commit("setIconSets", iconSets);
	},

	async addMobileRaiseTemplate({ commit }, model: TemplateDetailsModel) {
		const template = mapEditModelToTemplate(model);

		const newTemplate = await api.addTemplate(template);
		commit("addMobileRaiseTemplate", newTemplate);
	},

	async updateMobileRaiseTemplate({ commit }, model: TemplateDetailsModel) {
		const template = mapEditModelToTemplate(model);

		await api.saveTemplate(template);
		commit("updateMobileRaiseTemplate", template);
	},

	async deleteMobileRaiseTemplate({ commit }, templateId: number) {
		await api.deleteTemplate(templateId);
		commit("deleteMobileRaiseTemplate", templateId);
	}
};
