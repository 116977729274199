














































































import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import PatrolQueue from "@/components/event-queue/PatrolQueue.vue";
import ProcessingQueue from "@/components/ProcessingQueue.vue";
import ParkedQueue from "@/components/ParkedQueue.vue";
import OnTestQueue from "@/components/event-queue/OnTestQueue.vue";
import { UserPermissions } from "@/store/types";
import { EventCountsByType } from "@/store/eventqueue/types";

const Eventqueue = namespace("eventqueue");

@Component({
	components: {
		"PatrolQueue": PatrolQueue,
		"ProcessingQueue": ProcessingQueue,
		"ParkedQueue": ParkedQueue,
		"ontest-queue": OnTestQueue
	}
})
export default class AlarmQueueFooter extends Vue {
	@Eventqueue.Getter getCountByType: (countType: keyof EventCountsByType) => number;
	@Getter getPermissions: UserPermissions;
	@Getter getFeature: (featuresList: string[]) => boolean;

	private isQueueDetailShown: boolean = true;

	private get patrolEventCount(): number {
		return this.getCountByType("patrols");
	}

	private get parkedEventCount(): number {
		return this.getCountByType("parked");
	}

	private get processingEventCount(): number {
		return this.getCountByType("inProcess");
	}

	private get onTestEventCount(): number {
		return this.getCountByType("onTest");
	}

	private get isProcessingTabEnabled(): boolean {
		const disableProcessingActiveAlarms = this.getFeature(["Alarms", "EventQueue", "DisableProcessingActiveAlarms"]);
		return disableProcessingActiveAlarms ? this.getPermissions.canProcessActiveAlarms : true;
	}

	private get isTourTabEnabled(): boolean {
		return this.getFeature(["Alarms", "EventQueue", "ManualTour"]);
	}

	private get isOnTestEnabled() {
		return this.getFeature(["Alarms", "AlarmPoints", "OnTest"]) && !!this.getPermissions.canPutAlarmPointsOnTest;
	}
}
