const darkMapStyle = [
	{
		elementType: "geometry",
		stylers: [
			{
				color: "#242f3e"
			}
		]
	},
	{
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#746855"
			},
			{
				visibility: "off"
			}
		]
	},
	{
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#9ca5b3"
			}
		]
	},
	{
		featureType: "administrative.locality",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#d59563"
			}
		]
	},
	{
		featureType: "landscape.man_made",
		elementType: "geometry",
		stylers: [
			{
				color: "#1c1c31"
			},
			{
				saturation: -60
			}
		]
	},
	{
		featureType: "poi",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#d59563"
			}
		]
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				color: "#263c3f"
			}
		]
	},
	{
		featureType: "poi.park",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#6b9a76"
			}
		]
	},
	{
		featureType: "road",
		elementType: "geometry",
		stylers: [
			{
				color: "#38414e"
			}
		]
	},
	{
		featureType: "road",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#212a37"
			}
		]
	},
	{
		featureType: "road",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#9ca5b3"
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "geometry",
		stylers: [
			{
				color: "#746855"
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#1f2835"
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#f3d19c"
			}
		]
	},
	{
		featureType: "transit",
		elementType: "geometry",
		stylers: [
			{
				color: "#2f3948"
			}
		]
	},
	{
		featureType: "transit.station",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#d59563"
			}
		]
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				color: "#17263c"
			}
		]
	},
	{
		featureType: "water",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#515c6d"
			}
		]
	},
	{
		featureType: "water",
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#17263c"
			}
		]
	},
	{
		featureType: "poi",
		stylers: [{ visibility: "off" }]
	}
];

const lightMapStyle = [
    {
        elementType: "geometry",
    },
    {
        elementType: "labels.text.fill",
    },
    {
        elementType: "labels.text.stroke",
    },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry",
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
    },
    {
        featureType: "road",
        elementType: "geometry",
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
    },
    {
        featureType: "transit",
        elementType: "geometry",
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
    },
    {
        featureType: "water",
        elementType: "geometry",
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
    },
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }]
    }
];

const satelliteMapStyle = [
	{
		featureType: "poi",
		elementType: "labels",
		stylers: [{ visibility: "off" }]
	}
];

const getMapStyle = (mapType: string, hideLabels: boolean, mapStyle: string = "") => {
	let styles: any[] = [];

	if (mapType === "roadmap" || mapType === "terrain") {
		styles = darkMapStyle;
	} else {
		styles = satelliteMapStyle;
	}

	// if a map style has been provided ignore default
	switch(mapStyle){
		case "light":
			styles = lightMapStyle;
			break;
		case "dark" :
			styles = darkMapStyle;
			break;
		case "satellite":
			styles = satelliteMapStyle;
			break;
		default:
			break;
	}

	if (!hideLabels) {
		const labels = styles.filter(style => style.elementType === "labels");
		labels.forEach(element => {
			(element.stylers[0] as any).visibility = "on";
		});
	}

	return styles;
};

export { darkMapStyle, satelliteMapStyle, lightMapStyle, getMapStyle };
