
	import { Component, Vue, Prop, Watch } from "vue-property-decorator";
	import { namespace, Getter } from "vuex-class";
	import { get } from "lodash";
	import MaskingModal from "@/components/alarm-masking/MaskingModal.vue";
	import { FeaturesList, UserPermissions } from "@/store/types";
	import { TenantOverview } from "@/store/user-context/types";
	import MaskingModalGroupBadge from "@/components/alarm-masking/MaskingModalGroupBadge.vue";
	import MaskingModalFilteredAreas from "./MaskingModalFilteredAreas.vue";
	import AdvancedMaskModal from "./AdvancedMaskModal.vue";

	const Eventqueue = namespace("eventqueue");
	const AlarmMasking = namespace("alarmMasking");
	const UserContext = namespace("userContext");
	const Areas = namespace("areas");

	interface Response {
		responseID: number,
		groupID: number,
		eventID: number,
		title: string,
		armed: boolean,
		maskDuration: number
	}

	@Component({
		components: {
			"masking-modal": MaskingModal,
			"masking-modal-group-badge": MaskingModalGroupBadge,
			"masking-modal-filtered-areas": MaskingModalFilteredAreas,
			"advanced-mask-modal": AdvancedMaskModal
		}
	})
	export default class MaskingModalLauncher extends Vue {

		$refs: any = {
            displayModal: MaskingModal
        };

		@Prop({default: "event-queue"}) public variant: string;
		@Prop() public eventId: number;
		@Prop() public groupId: number;

		@Eventqueue.Mutation setTreeGroupSelectedByGroupId: (groupId: number) => void;

		@AlarmMasking.Getter getMaskedAlarmCount: number;
		@AlarmMasking.Getter getUnmaskedAlarmCount: number;
		@AlarmMasking.Action loadGroup: (groupId: number) => Promise<void>;

		@UserContext.State tenantOverview: TenantOverview;

		@Getter getPermissions: UserPermissions;
		@Getter("getFeaturesList") featuresList: FeaturesList;

		@Areas.Action pollAreaMaskingCount: () => Promise<void>;
		@Areas.Action stopPollingAreaMaskingCount: () => void;

		private modalGroupFilter: string = "";
		private modalAlarmFilter: string = "";

		private isModalShown: boolean = false;
		private isFilterModalShown: boolean = false;
		private isAdvancedModalShown: boolean = false;
		private selectedFilter: string = "all";
		private selectedGroupId: number = null;
		private selectedResponses: Response[] = [];

		private async mounted() {
			if (this.groupId) {
				this.selectedGroupId = this.groupId;
				await this.onGroupIdChanged();
			}

			await this.pollMaskingCount();
		}

		private beforeDestroy() {
			this.stopPollingAreaMaskingCount();
		}

		@Watch("groupId")
		private async onGroupIdChanged() {
			this.selectedGroupId = this.groupId;

			if(this.isAlarmMaskingEnabled){
				await this.loadGroup(this.groupId);
			}
		}

		private onModalClose() {
			this.isModalShown = false;
			this.modalGroupFilter = "";
			this.modalAlarmFilter = "";
		}

		private onFilterModalClose(): void {
			this.isFilterModalShown = false;
		}

		private async pollMaskingCount(): Promise<void> {
			if(this.isAlarmMaskingEnabled){
				await this.pollAreaMaskingCount();
			}
		}

		@Watch("modalGroupFilter")
		private onGroupFilterChanged() {
			if (this.modalGroupFilter !== "") {
				this.isModalShown = true;
			}
		}

		@Watch("modalAlarmFilter")
		private onAlarmFilterChanged() {
			if (this.modalAlarmFilter !== "") {
				this.isModalShown = true;
			}
		}

		public get isAlarmMaskingEnabled() {
			return get(this.featuresList, ["Alarms", "Masking"], false) &&
				(this.getPermissions.canDisarmSites || this.getPermissions.canDisarmSitesExtended);
		}

		private groupBadgeSelected(result: string): void {
			this.modalGroupFilter = result;
		}

		private get openMaskingModal(): boolean {
			return this.isModalShown && !this.isFilterModalShown && !this.isAdvancedModalShown;
        }

		private get openFilterModal(): boolean {
			return this.isModalShown && this.isFilterModalShown;
		}

		private requestFilterModal(filter: string): void {
			if (filter) {
				this.selectedFilter = filter;
				this.isFilterModalShown = true;
			}
		}

		private requestAdvancedModal(responses: Response[]): void {
			this.selectedResponses = responses;
			this.isAdvancedModalShown = true;
		}

		private selectedAreaChosen(groupId: number): void {
			if (groupId) {
				this.selectedGroupId = groupId;
			}

			if(this.$refs.displayModal)
			{
				this.$refs.displayModal.clearFilter();
			}
			this.onFilterModalClose();
		}

		private async advancedMaskModalClosed(): Promise<void> {
			this.isAdvancedModalShown = false;
			this.selectedResponses = [];

			await this.$nextTick();

			if(this.$refs.displayModal)
			{
				this.$refs.displayModal.clearFilter();
				await this.$refs.displayModal.forceUpdateAreaAlarms();
			}
		}
	}
