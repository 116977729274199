
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace, Getter, Mutation, Action } from "vuex-class";

import NavMenu from "@/components/NavMenu.vue";

@Component({
    components: {
        "nav-menu": NavMenu
    }
})
export default class NavHeader extends Vue {
    @Prop(String)
    title!: string;
}
