
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";

const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {}
})
export default class RaiseCaseModal extends Vue {
	@Prop({default: false, type: Boolean})
	private readonly value;
	
	@SiteMonitor.State eventCaseId: string;

	private isModalShown: boolean = false;

	private destroyed() {
		this.isModalShown = false;
		this.addAdditionalInformation(false);
	}

	@Watch("value")
	private async onValueUpdated(newValue): Promise<void> {
		this.isModalShown = newValue;
	}

	@Emit("addAdditionalInformation")
	private addAdditionalInformation(add: boolean): boolean {
		return add;
	}
}
