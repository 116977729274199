
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";

import { Datetime } from "vue-datetime";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import DatePicker from "./form/DatePicker.vue";

import { EventDetails } from "@/store/site-monitor/types";
import { AreaNote } from "@/store/area-notes/types";

const SiteMonitor = namespace("siteMonitor");
const AreaNotesModule = namespace("areaNotes");

@Component({
	components: {
		VuePerfectScrollbar: VuePerfectScrollbar,
		datetime: Datetime,
		"date-picker": DatePicker
	}
})
export default class AreaNotes extends Vue {
	@Prop(Number) public eventid?: number;

	// VueX

	@Getter("getUserName") public currentUserName!: string;

	@SiteMonitor.Getter public areaNotesVisible!: boolean;
	@SiteMonitor.Getter public groupID!: number;
	@SiteMonitor.Getter public getEventDetails: EventDetails;
	@SiteMonitor.Action public setAreaNotesVisible: any;
	@AreaNotesModule.Getter("getAreaNotes") public areaNotes!: AreaNote[];
	@AreaNotesModule.Action public addAreaNote: any;
	@AreaNotesModule.Action public deleteAreaNote: any;
	@AreaNotesModule.Action public fetchAreaNotesForEvent: any;

	public $refs!: {
		notesModal: any;
		newNoteText: any;
		confirmDeleteModal: any;
	};

	public showAddNewNoteForm: boolean = false;
	public isNoteEditing: boolean = false;
	public newNote: AreaNote = null;

	// Not currently supported
	public noteTypes = [
		{ text: "Normal", value: "normal" },
		{ text: "Popup notification", value: "popup" }
	];

	public selectedNote: any;

	public get eventDetails() {
		return this.getEventDetails || {};
	}

	// For delete confirmation
	private deletingNoteId: number | null = null;

	// When area notes visible set to true, show this component
	@Watch("areaNotesVisible")
	public onEventDetailsShownChanged(value: boolean, oldValue: boolean) {
		if (value) {
			this.show();
		} else {
			this.$refs.notesModal.hide();
		}
	}

	/*
				On show, load area notes
			*/
	public async show() {
		this.fetchAreaNotesForEvent(this.eventid);
		this.$refs.notesModal.show();
	}

	public editNoteText(noteId: number) {
		// @todo editing will be implemented later
		// this.isNoteEditing = false;
		// this.updateNote()
	}

	/*
				When mounted, initialize the new note and make this component hidden
			*/
	public mounted() {
		this.$set(this, "newNote", this.getInitialNewNote());
		this.setAreaNotesVisible(false);
	}

	/*
				When submit button is clicked, save the newly created note, then reset the adding form.
			*/
	public async onSubmit() {
		this.newNote.created = new Date().toISOString();
		this.newNote.userName = this.currentUserName;
		this.newNote.groupId = this.groupID;
		this.newNote.eventId = this.eventid;

		await this.addAreaNote(this.newNote);

		this.$set(this, "newNote", this.getInitialNewNote());
		this.showAddNewNoteForm = false;
	}

	/*
				Note default values
			*/
	private getInitialNewNote(): AreaNote {
		return {
			groupId: 0,
			entry: "",
			requiresAcknowledgement: true,
			noteType: "normal",
			startFrom: "",
			endAt: "",
			created: "",
			userName: ""
		};
	}

	/*
				Show the delete confirm controls

				@param note - Note to delete
			*/
	private startDeleteAreaNote(note: AreaNote) {
		this.deletingNoteId = note.noteId;
	}
}
