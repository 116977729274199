import { MutationTree } from "vuex";
import { ViewsState, Dimensions, ViewMode, ViewCellObject, ViewObject, UpdatedViewCellData } from "./types";
import Vue from "vue";
import { getDefaultState } from "./state";
import { isEqual } from "lodash";

export const mutations: MutationTree<ViewsState> = {
	setViews(state, views) {
		state.views = views;
	},

	setUserViews(state, userViews) {
		state.userViews = userViews;
	},

	setPluginTypes(state, pluginTypes) {
		state.pluginTypes = pluginTypes;
	},

	setViewMode(state, viewMode: ViewMode) {
		state.viewMode = viewMode;
	},

	setSelectedView(state, view) {
		state.selectedView = view;
	},

	setSelectedViewCells(state, viewCells: ViewCellObject[]) {
		if (state.selectedView) {
			state.selectedView.cells = viewCells;

			// Update the cell contents store
			viewCells.forEach(cell => {
				Vue.set(state.cellContents, cell.viewCellId, cell.contents);
			});
		}
	},

	setSelectedViewPublic(state, value: boolean) {
		if (state.selectedView) {
			state.selectedView.public = value;
		}
	},

	setViewDimensions(state, dimensions: Dimensions) {
		if (state.selectedView) {
			// Filter cells outside grid size
			let newCells = state.selectedView.cells.filter(cell => cell.x < dimensions.x && cell.y < dimensions.y);
			// let yIncrease = 0;

			if (state.selectedView.width > dimensions.x) {
				// Resize cells which stretch outside grid width
				newCells
					.filter(cell => cell.x + cell.width > dimensions.x)
					.forEach(foundCell => (foundCell.width -= foundCell.width + foundCell.x - dimensions.x));
			}

			if (state.selectedView.height > dimensions.y) {
				// Resize cells which stretch outside grid height
				newCells
					.filter(cell => cell.y + cell.height > dimensions.y)
					.forEach(foundCell => (foundCell.height -= foundCell.height + foundCell.y - dimensions.y));
			}

			state.selectedView.cells = newCells;
			state.selectedView.width = dimensions.x;
			state.selectedView.height = dimensions.y;
		}
	},

	setSelectedViewTitle(state, title) {
		if (state.selectedView) {
			state.selectedView.title = title;
		}
	},

	setSelectedTileType(state, tileType) {
		state.selectedTileType = tileType;
	},

	setSideBarExtended(state, extended) {
		state.sideBarExtended = extended;
	},

	setSideBarPinned(state, pinned) {
		state.sideBarPinned = pinned;
	},

	setViewSessionId(state, sessionId) {
		state.viewSessionId = sessionId;
	},

	setContents(state, { viewCellId, contents }) {
		if (viewCellId) {
			state.cellContents[viewCellId.toString()] = contents;
		}
	},

	deleteCell(state, { viewCellId }) {
		let cellIndex = state.selectedView.cells.findIndex(cell => cell.viewCellId === viewCellId);

		if (cellIndex < 0) {
			return;
		}

		Vue.delete(state.selectedView.cells, cellIndex);
	},

	setUpdatedCellContents(state, viewCellData: UpdatedViewCellData[]) {
		if (!viewCellData) {
			return;
		}

		viewCellData.forEach(cellData => {
			let currentContents = state.cellContents[cellData.viewCellId];
			let newContents = cellData.contents || cellData.defaultContents;

			// Diff the cell contents - we only want to do an update here if we have actually made changes
			if (!isEqual(newContents, currentContents)) {
				Vue.set(state.cellContents, cellData.viewCellId, newContents);
			}
		});
	},

	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};
