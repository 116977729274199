
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import vSelect3 from "vselect3";
import { UserPermissions } from '@/store/types';
import SchedulesCalendar from './Schedules/SchedulesCalendar.vue';
import ScheduleModal from "./Schedules/ScheduleModal.vue";
import { ScheduleType } from '@/store/schedules/types';
import { ScheduleTypes } from "@/components/Schedules/ScheduleEnums";
import ValidatePinCodeModal from "@/components/ValidatePinCodeModal.vue";
import AreaNotesForm from '@/components/area-notes/AreaNotesForm.vue';
import AreaNotesTable from '@/components/area-notes/AreaNotesTable.vue';
import { EventDetails } from "@/store/site-monitor/types";
import AreaEventHistory from "./area-event-history/AreaEventHistory.vue";
import CustomDefinedFieldsDisplay from "@/components/custom-fields/CustomDefinedFieldsDisplay.vue";
import { CustomFieldDto, CustomFieldTableTypes, CustomFieldToggleCollapseState } from "@/store/custom-fields/types";

const SiteMonitor = namespace("siteMonitor");
const Schedules = namespace("schedules");
const CustomFields = namespace("customFields");

@Component({
	components: {
		"v-select-3": vSelect3,
		"schedules-calendar": SchedulesCalendar,
		"schedule-modal": ScheduleModal,
		"area-notes-form": AreaNotesForm,
		"area-notes-table": AreaNotesTable,
		"area-event-history-tab": AreaEventHistory,
		"validate-pin-code-modal": ValidatePinCodeModal,
		"custom-fields-display": CustomDefinedFieldsDisplay,
	}
})
export default class AreaDetails extends Vue {
	@SiteMonitor.Mutation setEventDetailsShown: any;
	@SiteMonitor.Action loadEventGroup: any;
	@SiteMonitor.State areaDetailsTabIndex: number;
	@SiteMonitor.Getter("getEventDetails") private eventDetails: EventDetails;

	@Schedules.State selectedGroup: number | null;
	@Schedules.State scheduleTypes: ScheduleType[];
	@Schedules.State selectedScheduleTypes: number[];
	@Schedules.Action loadScheduleTypes: () => Promise<void>;

	@Schedules.Mutation setSelectedGroup: (selectedGroup: number) => void;
	@Schedules.Mutation setEnabledScheduleFilter: (disabled: boolean | null) => void;
	@Schedules.Mutation updateSearchString: (searchString: string) => void;
	@Schedules.Mutation addSelectedScheduleType: (scheduleTypeId: number) => void;
	@Schedules.Mutation removeSelectedScheduleType: (scheduleTypeId: number) => void;
	@Schedules.Mutation setIsReloadRequired: (isReloadRequired: boolean) => void;
	@Schedules.Mutation resetSelectedGroup: () => void;

	@Schedules.Getter isScheduleTypeSelected: (scheduleTypeId: number) => boolean;

	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getPermissions") permissions: UserPermissions;

	public eventAreaDetails: any = {};
	public eventUniqueRoles: string[] = ["All Roles"];
	public eventActiveRole: string = "All Roles";

	private enabledScheduleFilter: boolean = true;
	private scheduleSearchFilter: string = "";
	private scheduleCollapseOpen: boolean = false;
	private customFieldsCollapseOpen: CustomFieldToggleCollapseState[] = [];
	private selectedScheduleTypeOptions: number[] = [];
	private readonly areaCustomFieldType = CustomFieldTableTypes.Area;
	private readonly contactCustomFieldType = CustomFieldTableTypes.Contact;

	@Prop({ type: Boolean, default: false }) showTitle: boolean;
	@Prop({ type: Number, default: false }) groupId: number;

	@CustomFields.Action retrieveCustomFields: ({ tableType, live }) => Promise<void>;
	@CustomFields.State areaCustomFields: CustomFieldDto[];
	@CustomFields.State contactCustomFields: CustomFieldDto[];

	private async mounted(): Promise<void> {
		if (this.isCustomFieldsEnabled) {
			await this.retrieveCustomFields({ tableType: CustomFieldTableTypes.Area, live: true });
			await this.retrieveCustomFields({ tableType: CustomFieldTableTypes.Contact, live: true });
		}

		await this.loadEventAreaDetails();
		this.selectedScheduleTypeOptions = this.selectedScheduleTypes;

		if (this.eventAreaDetails?.keyContacts != null) {
			this.customFieldsCollapseOpen = this.eventAreaDetails.keyContacts.map(item => ({
				id: item.userId,
				isOpen: false
			}));
		}

		if (!this.isMaskingEnabled && this.selectedScheduleTypeOptions.includes(ScheduleTypes.Masking)) {
			this.selectedScheduleTypeOptions.removeWhere(s => s == ScheduleTypes.Masking);
		}

		if (!this.isEventRaiseEnabled && this.selectedScheduleTypeOptions.includes(ScheduleTypes.EventRaise)) {
			this.selectedScheduleTypeOptions.removeWhere(s => s == ScheduleTypes.EventRaise);
		}

		if (this.showSchedules) {
			await this.loadSchedules();
		}
	}

	beforeDestroy(): void {
		this.resetSelectedGroup();
	}

	private async loadEventAreaDetails(): Promise<void> {
		this.eventAreaDetails = await this.loadEventGroup(this.groupId);
		// emit the area details back up to the parent component in Views (saving multiple requests for the same data)
		this.$emit("areaDetails", this.eventAreaDetails);
		this.mapEventUniqueRoles();
	}

	private mapEventUniqueRoles(): void {
		let roles: string[] = [];
		this.eventAreaDetails.keyContacts.forEach((keyContact: { roles: ConcatArray<string>; }) => roles = roles.concat(keyContact.roles));
		const eventUniqueRoles = new Set(roles);

		const uniqueRoles = Array.from(eventUniqueRoles);
		this.eventUniqueRoles = ["All Roles", ...uniqueRoles];
	}

	private async loadSchedules(): Promise<void> {
		if (this.selectedGroup != this.groupId) {
			this.setSelectedGroup(this.groupId);
		}

		this.setEnabledScheduleFilter(true);

		await this.loadScheduleTypes();
	}

	private toggleCustomFieldCollapse(id: number): void {
		this.customFieldsCollapseOpen.forEach(element => {
			if (element.id === id) {
				element.isOpen = !element.isOpen;
			}
		});
	}

	private getCustomFieldCollapseState(id: number): boolean {
		let _isOpen: boolean = false;

		this.customFieldsCollapseOpen.forEach(element => {
			if (element.id === id) {
				_isOpen = element.isOpen;
			}
		});

		return _isOpen;
	}

	@Watch("eventActiveRole")
	@Watch("eventUniqueRole")
	private resetCustomFieldCollapseState(): void {
		this.customFieldsCollapseOpen.forEach(element => {
			element.isOpen = false;
		});
	}

	private get showSchedules(): boolean {
		return this.getFeature(["Schedules"]) && !!(this.permissions.canViewSchedules || this.permissions.canEditSchedules);
	}

	private get showContacts(): boolean {
		return this.eventUniqueRoles && this.eventUniqueRoles.length > 1;
	}

	private get isMaskingEnabled(): boolean {
		return this.getFeature(["Schedules", "Masking"]);
	}

	private get isEventRaiseEnabled(): boolean {
		return this.getFeature(["Schedules", "EventRaise"]);
	}

	private get isEventHistoryEnabled(): boolean {
		return this.getFeature(["Areas", "AreaEventHistory"]) && this.permissions.canViewEventHistory;
	}

	private get isCustomFieldsEnabled(): boolean {
		return this.getFeature(["CustomFields"]);
	}

	public get showAreaNotes() {
		return this.getFeature(["AreaNotes"]);
	}

	private get filteredScheduleTypes(): ScheduleType[] {
		let schedulesTypeList = this.scheduleTypes;

		if (!this.isMaskingEnabled) {
			schedulesTypeList = schedulesTypeList.filter(st => st.scheduleTypeId != ScheduleTypes.Masking);
		}

		if (!this.isEventRaiseEnabled) {
			schedulesTypeList = schedulesTypeList.filter(st => st.scheduleTypeId != ScheduleTypes.EventRaise);
		}

		return schedulesTypeList;
	}

	@Watch("enabledScheduleFilter")
	private onEnabledFilterChanged(disabled: boolean | null): void {
		this.setEnabledScheduleFilter(disabled);
		this.setIsReloadRequired(true);
	}

	@Watch("scheduleSearchFilter")
	private onScheduleSearch(filter: string): void {
		this.updateSearchString(filter);
		this.setIsReloadRequired(true);
	}

	private onScheduleTypeChanged(newScheduleTypes: number[]): void {
		this.selectedScheduleTypes.forEach(s => {
			this.removeSelectedScheduleType(s);
		});

		newScheduleTypes.forEach(s => {
			this.addSelectedScheduleType(s);
		});

		this.setIsReloadRequired(true);
	}

	// Gets the Event Id from Event Details if there are any
	private get eventId(): number {
		return this.eventDetails ? this.eventDetails.eventID : null;
	}
}
