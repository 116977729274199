import { axiosJsonInstance as axios } from "@/axios.instance";
import { Message } from "@/store/guard-chat/types";

const MESSAGES_URL = "/Messages";
const TWO_WAY_MESSAGES_URL = "/Messages/TwoWay";
const TWO_WAY_UNACKED_URL = `${TWO_WAY_MESSAGES_URL}/UnackedCount`;
const TWO_WAY_ACKNOWLEDGE_URL = `${TWO_WAY_MESSAGES_URL}/Acknowledge`;

interface CreateMessageCommand {
	message: string;
	messageType: "operator" | "guard";
	eventId: number;
}

interface CreateTwoWayMessageCommand {
	message: string;
	messageType: "operator" | "mobileUser";
}

const buildUrl = (eventId: number, afterId?: number) => {
	if (afterId) {
		return `${MESSAGES_URL}?eventId=${eventId}&afterId=${afterId}`;
	} else {
		return `${MESSAGES_URL}?eventId=${eventId}`;
	}
};

const buildTwoWayUrl = (chatUserId: number, afterId?: number) => {
	if (afterId) {
		return `${TWO_WAY_MESSAGES_URL}?chatUserId=${chatUserId}&afterId=${afterId}`;
	} else {
		return `${TWO_WAY_MESSAGES_URL}?chatUserId=${chatUserId}`;
	}
};

export const loadMessages = async (eventId: number, afterId?: number): Promise<Message[]> => {
	const url = buildUrl(eventId, afterId);
	const { data } = await axios.get(url);
	return data;
};

export const sendMessage = async (cmd: CreateMessageCommand): Promise<number> => {
	const { data } = await axios.post(MESSAGES_URL, cmd);
	return data;
};

export const loadTwoWayMessages = async (chatUserId: number, afterId?: number): Promise<Message[]> => {
	const url = buildTwoWayUrl(chatUserId, afterId);
	const { data } = await axios.get(url);
	return data;
};

export const sendTwoWayMessage = async (chatUserId: number, cmd: CreateTwoWayMessageCommand): Promise<number> => {
	const url = buildTwoWayUrl(chatUserId);
	const { data } = await axios.post(url, cmd);
	return data;
};

export const getTwoWayUnackedCount = async (): Promise<number> => {
	const { data } = await axios.get(TWO_WAY_UNACKED_URL);
	return data;
}

export const acknowledgeTwoWayOwn = async (): Promise<void> => {
	await axios.post(TWO_WAY_ACKNOWLEDGE_URL);
}

export const acknowledgeTwoWayForUser = async (chatUserId: number): Promise<void> => {
	const url = `${TWO_WAY_ACKNOWLEDGE_URL}?chatUserId=${chatUserId}`;
	await axios.post(url);
}
