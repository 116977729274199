
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import AreaNotesForm from "@/components/area-notes/AreaNotesForm.vue";
import AreaNotesTable from "@/components/area-notes/AreaNotesTable.vue";

const AreaNotes = namespace("areaNotes");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		"area-notes-form": AreaNotesForm,
		"area-notes-table": AreaNotesTable
	}
})
export default class AreaNotesModal extends Vue {
	@SiteMonitor.Getter("areaNotesVisible") isShown: boolean;
	@AreaNotes.Getter getCurrentGroupTitle: string;
	@AreaNotes.Action closeModal: any;
}
