
import { Component, Prop, Vue } from "vue-property-decorator";
import vSelect from "vue-select";

@Component({
	components: {
		"vue-select": vSelect
	}
})
export default class UrlInputLine extends Vue {
	@Prop()
	private value!: string;

	public get url () {
		return this.value
	}

	public set url (value: string) {
		this.$emit("validate", true);
		this.$emit("input", value);
	}
}
