
import { Component, Vue, Prop } from "vue-property-decorator";
import TextHighlight from "vue-text-highlight";

const slashRegexp = /\//gi;

const buildTitle = (titles: string[]) => {
	const processedTitles = titles.map(t => t.replace(slashRegexp, " / "));
	return processedTitles.join(" | ");
};

@Component({
	components: {
		"text-highlight": TextHighlight
	}
})
export default class Title extends Vue {
	@Prop({ default: () => [] }) titleList: string[];
	@Prop({ default: "" }) highlightText: string;

	get formattedTitle() {
		return buildTitle(this.titleList);
	}
}
