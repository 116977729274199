
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { namespace, Getter } from 'vuex-class';
import vSelect3 from "vselect3";
import axios, { CancelTokenSource } from "axios";
import { debounce, isEqual, unionWith, differenceBy } from "lodash";
import api from '@/services/api.service';
import { UserInfo, UserPermissions } from '@/store/types';
import { AddInvolvedPartyRequest, InvolvedParty, RemoveInvolvedPartyRequest } from '@/store/site-monitor/types';
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const SiteMonitor = namespace("siteMonitor");

@Component({
    components: {
		VuePerfectScrollbar: VuePerfectScrollbar,
        "v-select-3": vSelect3
    }
})
export default class InvolvedParties extends Vue {
    @Prop(Number) 
    eventId?: number;

	$refs!: {
		involvedPartiesModal: any;
	};

	private readonly debouncePeriod: number = process.env.NODE_ENV === "test" ? 1 : 500;
	private readonly maxPageSize: number = 100;

    private selectedParties: UserInfo[] = [];
	private involvedPartySearchToken: CancelTokenSource;
	private users: UserInfo[] = [];
	private involvementType: string | null = null;
	private involvedParties: InvolvedParty[] = [];

	@Getter("getPermissions") permissions: UserPermissions;

    @SiteMonitor.Getter("getInvolvedPartiesShown") involvedPartiesShown!: boolean;

    @SiteMonitor.Mutation setInvolvedPartiesShown: (shown: boolean) => void;

    @Watch("involvedPartiesShown")
    private async onInvolvedPartiesShownChanged(value: boolean): Promise<void> {
        if (value) {
			await this.updateInvolvedParties();
			this.clearInputs();
            this.$refs.involvedPartiesModal.show();
        } else {
            this.$refs.involvedPartiesModal.hide();
        }
    }

	private get canEditInvolvedParties(): boolean {
		return !!this.permissions.canEditInvolvedParties;
	}

	private get isValid(): boolean {
		if (this.selectedParties.length == 0)
		{
			return false;
		}

		if (!this.involvementType || this.involvementType.trim() === "")
		{
			return false;
		}

		return true;
	}

    public created(): void {
        this.setInvolvedPartiesShown(false);
    }

    public closeInvolvedParties(): void {
        this.setInvolvedPartiesShown(false);
    }

	private async handleInvolvedPartySearchInput(searchTerm: string): Promise<void> {
		await this.performInvolvedPartySearch(searchTerm);
	}

	private performInvolvedPartySearch: (searchTeam: string) => Promise<void> = debounce(
		async function (searchTerm: string) {
			if (this.involvedPartySearchToken) {
				this.involvedPartySearchToken.cancel("Cancel Search");
			}

			this.involvedPartySearchToken = axios.CancelToken.source();

			try {
				let usersInfo = await api.getUsersInfoBySearchTerm(searchTerm, this.maxPageSize);
				this.users = unionWith(usersInfo, this.selectedParties, isEqual);
				let existingInvolvedParties = this.involvedParties.filter(ip => ip.added);
				this.users = differenceBy(this.users, existingInvolvedParties, 'userId');
			} catch (e) {
				return; //Return when past requests are cancelled
			}
		},
		this.debouncePeriod,
		{ leading: false, trailing: true }
	);

	private async addInvolvedParty(): Promise<void> {
		let involvedPartiesToAdd: AddInvolvedPartyRequest[] = [];

		this.selectedParties.forEach(involvedParty => {
			involvedPartiesToAdd.push({ 
				eventId: this.eventId, 
				userId: involvedParty.userId, 
				involvementType: this.involvementType 
				} as AddInvolvedPartyRequest)
		});

		for (var i = 0; i < involvedPartiesToAdd.length; i++) {
			await api.addInvolvedParty(involvedPartiesToAdd[i]);
		}

		this.selectedParties = [];
		this.users = [];
		this.involvementType = null;

		await this.updateInvolvedParties();
	}

	private async updateInvolvedParties(): Promise<void> {
		this.involvedParties = await api.getInvolvedPartiesForEvent(this.eventId);
	}

	private clearInputs(): void {
		this.selectedParties = [];
		this.involvementType = null;
	}

	private async removeInvolvedParty(involvedParty: InvolvedParty): Promise<void> {
		await api.removeInvolvedParty({ eventId: this.eventId, userId: involvedParty.userId } as RemoveInvolvedPartyRequest);
		await this.updateInvolvedParties();
	}

	private getUserTitle(user: UserInfo): string {
		var userDisplay = user.fullName;

		if (user.notes != "")
		{
			userDisplay += ` [${user.notes}]`;
		}

		userDisplay += ` (${user.email})`;

		return userDisplay;
	}
}
