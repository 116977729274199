var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 form-group"},[(!_vm.showAdvanced)?_c('div',[_c('li',{class:[
				'list-group-item',
				'list-group-item-action',
				'd-flex',
				'justify-content-between',
				'align-items-center',
				{
					disabled: _vm.isSending
				}
			],attrs:{"id":"sendNotification"},on:{"click":function($event){return _vm.sendNotification()}}},[_c('div',{staticClass:"flex-grow-1 text-left"},[_c('span',[_vm._v("Send notification using \""+_vm._s(_vm.template.title)+"\" template")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSending),expression:"isSending"}],staticClass:"fas fa-spinner fa-spin m-l-10"})])]),_c('li',{staticClass:"list-group-item list-group-item-action d-flex justify-content-between align-items-center",attrs:{"id":"advancedOptionsOpen"},on:{"click":function($event){_vm.showAdvanced = true}}},[_vm._m(0)])]):_c('div',[_c('div',{staticClass:"card card-secondary row p-5"},[_c('div',{staticClass:"card-header"},[_vm._v(" Recipients "),_c('span',{class:[
						'span-link',
						'badge',
						'badge-pill',
						'dark-background',
						'float-right',
						{
							'badge-success': !_vm.showUserSearch,
							'badge-danger': _vm.showUserSearch
						}
					],attrs:{"id":"addUser","title":_vm.showUserSearch ? 'Cancel adding new recipient' : 'Add new recipient'},on:{"click":function($event){_vm.showUserSearch = !_vm.showUserSearch}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showUserSearch),expression:"!showUserSearch"}],staticClass:"fas fa-plus fa-sm"}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUserSearch),expression:"showUserSearch"}],staticClass:"fas fa-ban fa-sm"})])]),_c('div',{staticClass:"card-body no-padding"},[_c('VuePerfectScrollbar',{staticClass:"shared-list"},[_c('div',{staticClass:"list-group"},_vm._l((_vm.totalUsers),function(user,i){return _c('div',{key:i,staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(user.name + (user.default ? " (From Template)" : ""))+" ")]),_c('div',[_c('span',{staticClass:"span-link badge badge-pill dark-background badge-danger",attrs:{"title":"Remove","id":"removeShare"},on:{"click":function($event){return _vm.removeUser(user.userID)}}},[_c('span',{staticClass:"fas fa-times fa-sm"})])])])])}),0)])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUserSearch),expression:"showUserSearch"}],staticClass:"row p-5"},[_c('multiselect',{attrs:{"id":"sharePersonSelect","track-by":"userID","label":"name","placeholder":"Type to search","openDirection":"below","options":_vm.searchedUsers,"searchable":true,"allow-empty":true,"loading":_vm.isSearching,"internal-search":false,"multiple":true,"hideSelected":true,"showNoResults":false,"clear-on-select":true,"selectLabel":"","deselectLabel":"","selectedLabel":""},on:{"search-change":_vm.eventShareFind},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
					var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.name))])]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}},[_c('template',{slot:"noOptions"},[_c('span',[_vm._v("Search for a person")])])],2)],1),_c('div',{staticClass:"row p-5 form-group p-t-25"},[_c('label',{attrs:{"for":"inputSubject"}},[_vm._v("Subject")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.template.subject),expression:"template.subject"}],staticClass:"form-control",attrs:{"type":"text","id":"inputSubject"},domProps:{"value":(_vm.template.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.template, "subject", $event.target.value)}}})]),_c('div',{staticClass:"row p-5 form-group"},[_c('label',{attrs:{"for":"inputMessage"}},[_vm._v("Message")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.template.message),expression:"template.message"}],staticClass:"form-control",attrs:{"type":"text","id":"inputMessage","rows":"5"},domProps:{"value":(_vm.template.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.template, "message", $event.target.value)}}})]),_c('div',{staticClass:"row p-5 form-group"},[_c('a',{class:[
					'btn',
					'btn-primary',
					'm-r-20',
					{
						disabled: _vm.isSending
					}
				],attrs:{"id":"advancedSendNotification","disabled":_vm.isSending},on:{"click":function($event){return _vm.sendNotification()}}},[_vm._v(" Send "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSending),expression:"isSending"}],staticClass:"fas fa-spinner fa-spin m-l-10",attrs:{"id":"advancedSendingSpinner"}})]),_c('a',{staticClass:"btn btn-secondary",attrs:{"id":"advancedHide"},on:{"click":function($event){_vm.showAdvanced = false}}},[_vm._v("Hide")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$v.template.subject.$error),expression:"$v.template.subject.$error"}],staticClass:"row fadeInDown animated"},[_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$v.template.message.$error),expression:"$v.template.message.$error"}],staticClass:"row fadeInDown animated"},[_vm._m(2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$v.totalUsers.$error),expression:"$v.totalUsers.$error"}],staticClass:"row fadeInDown animated"},[_vm._m(3)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 text-left"},[_c('span',[_vm._v("Show advanced options")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('sub',{staticClass:"small text-danger"},[_vm._v("Subject Required")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('sub',{staticClass:"small text-danger"},[_vm._v("Message Body Required")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('sub',{staticClass:"small text-danger"},[_vm._v("You must have at least 1 recipient")])])}]

export { render, staticRenderFns }