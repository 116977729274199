
import { Component, Mixins, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, State } from "vuex-class";
import { AuditService } from "@/services/auditService";
import { Event, EventRecordHasClipStatus } from "@/store/event-search/types";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { get } from "lodash";
import EventRecords from "@/components/EventRecords.vue";
import AuditControl from "@/components/AuditControl.vue";
import vSelect from "vue-select";
import { setTimeout } from "timers";
import { FeaturesList, UserPermissions, User } from "@/store/types";
import { EventDetails } from "@/store/site-monitor/types";
import { SessionResource } from "@/store/sessions/types";
import EnhancedSingleMap from './EnhancedSingleMap.vue';
import api from "@/services/api.service";
import PreserveEventControl from "@/components/event-review/PreserveEventControl.vue";
import { formatDateMixin } from "@/mixins"
import { EnhancedMapMode } from '@/types/EnhancedSingleMapTypes';
import EventClose from "./EventClose.vue";
import HideMapMixin from "@/mixins/HideMapMixin";

const { convertUtcDateTimeToLocalDateTimeString } = formatDateMixin.methods;
const SessionStore = namespace("sessions");
const SiteMonitor = namespace("siteMonitor");
const EventSearchStore = namespace("eventSearch");

@Component({
	components: {
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"event-records": EventRecords,
		"audit-control": AuditControl,
		"enhanced-single-map": EnhancedSingleMap,
		"vue-select": vSelect,
		"preserve-event-control": PreserveEventControl,
		"event-close": EventClose,
	}
})
export default class EventReview extends Mixins(HideMapMixin) {
	$refs!: {
		player: AuditControl;
	};

	constructor() {
		super();
	}

	@Prop(Event) public event: Event;

	@State User: User;

	@Getter getFeature: (featuresList: string[]) => boolean;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getPermissions: UserPermissions;

	@SessionStore.Getter getSession: any;
	@SessionStore.Action updateSession: any;

	@EventSearchStore.Mutation setEventFlagged: any;
	@EventSearchStore.Mutation setEventSearchIncidentReportShown: any;
	@EventSearchStore.Mutation setEventRecordsWithClips: any;

	@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;
	@SiteMonitor.Getter("getEventOperators") eventOperators: any;
	@SiteMonitor.Mutation setEventDetails: any;
	@SiteMonitor.Action fetchEventDetails: any;
	@SiteMonitor.Action loadEventGroup: any;
	@SiteMonitor.Action loadHistoricalEventOperators: any;
	@SiteMonitor.Action updateSharing: any;
	@SiteMonitor.Action createReviewRecord: any;
	@SiteMonitor.Action flagEventForReview: ({ eventId, flagged }) => Promise<boolean>;
	@SiteMonitor.Mutation setEventCloseShown: (shown: boolean) => void;

	public eventArea = {};
	public eventRecordID: number = 0;
	public selectedClip: any = {};
	public clips: any[] = [];
	public attachments = null;
	public auditService: AuditService | null = null;
	public eventDetailsAvailable: boolean = false;
	public archiveProgressCheckTimeout: any = null;
	public archiveProgressCheckWaitTime: number = 1000;
	public downloadingMedia: boolean = false;
	public downloadMediaButtonText: string = "Download Media";
	public archiveProgressPercentage: number = 0;
	public showDownloadClipModalFlag: boolean = false;
	public downloadingMediaClipSystemName: boolean = false;
	public downloadingMediaClipFriendlyName: boolean = false;
	private eventHasMedia: Boolean = false;

	private enhancedMapMode = EnhancedMapMode;
	private readonly defaultDownloadButtonText: string = "Download Media";
	private readonly archivingMediaText: string = "Preparing Files...";
	private readonly downloadingMediaText: string = "Downloading..."
	private downloadingMediaProgress: string = "";
	private downloadPercentage: string = "";
	private downloadMediaKey: number = 0;

	private auditServiceSessionResourceId = SessionResource.AuditServiceSession;

	public reviewModalState = {
		open: false,
		processing: false,
		note: ""
	};

	private get currentEventId(): number {
		return this.event?.eventID ?? 0;
	}

	public get isSitrepEnabled() {
		return (
			get(this.featuresList, ["Alarms", "EventReview", "SitRep"]) &&
			this.getPermissions.canDownloadEventSearchSitRep
		);
	}

	public get isEventOutcomeUpdateEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventSearch", "UpdateOutcome"]) && this.getPermissions.canUpdateEventCategory && !this.canEditPostEvent;
	}

	private get isDownloadEventMediaEnabled(): boolean {
		return (
			get(this.featuresList, ["Alarms", "EventReview", "DownloadMedia"])
			&& this.getPermissions.canDownloadEventSearchMedia
		);
	}

	private get downloadMediaDisabledText(): string {
		if (!this.eventHasMedia) {
			return "Event has no media";
		} else if (this.isPreserveEventsEnabled && this.eventDetails && new Date(this.eventDetails.filesPreservedUntil) <= new Date() && !this.eventDetails.preserve ) {
			return "Media is no longer available as of " + convertUtcDateTimeToLocalDateTimeString(this.eventDetails.filesPreservedUntil);
		}

		return "";
	}

	/**
	 * Check if feature flag is enabled and user has permissions for downloading a single clip in Event Search.
	 */
	public get isDownloadEventMediaSingleClipEnabled() {
		return (
			get(this.featuresList, ["Alarms", "EventSearch", "DownloadSingleClip"]) &&
			this.getPermissions.canDownloadSingleClip
		);
	}

	public get isEventFlaggingEnabled() {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "EventFlagging"], false);
	}

	public get isPreserveEventsEnabled() {
		return get(this.featuresList, ["Alarms", "EventSearch", "PreserveEvents"], false);
	}

	private get isClipPlaybackEnabled(): boolean {
		return get(this.featuresList, ["VideoAuditing"], false) || get(this.featuresList, ["Alarms", "MediaMatrix", "ClipsList"]);
	}

	@Watch("eventDetails")
	public async onEventDataReceive(data: EventDetails): Promise<void> {
		const eventArea = await this.loadEventGroup(data.groupID);
		if (eventArea) {
			this.$set(this, "eventArea", eventArea);
		}

		this.auditService = new AuditService(api.getAuditServiceAddress(), this.eventDetails.auditEndpoint);

		if (data && data.eventID) {
			this.checkEventHasMedia(data.eventID);
		}
	}

	@Watch("event", { deep:true })
	public onEventIDChanged(event: Event): void {
		this.downloadMediaButtonText = this.defaultDownloadButtonText;
		this.downloadingMedia = false;

		this.eventDetailsAvailable = false;
		if (!event || event.eventID === 0) {
			this.setEventDetails(null);
			this.eventHasMedia = false;
		} else {
			this.loadEventData(event.eventID);
		}
	}

	private async mounted() {
		if (this.event) {
			this.loadEventData(this.event.eventID);
		}
	}

	public loadEventData(eventID: number) {
		if (this.isClipPlaybackEnabled && this.$refs.player) {
			if (this.$refs.player.currentFile) {
				this.$refs.player.stop();
			}
		}

		this.selectedClip = null;
		this.clips = [];
		this.loadHistoricalEventOperators(eventID);
		this.fetchEventDetails(eventID);
		this.eventDetailsAvailable = true;
	}

	public async eventRecordSelected(eventRecord: any): Promise<void> {
		if (!eventRecord) {
			return;
		}

		this.eventRecordID = eventRecord.eventRecordID;

		if (this.clips.length > 0 && !eventRecord.hasClip) {
			return;
		}

		var eventRecordClip = this.clips.firstOrDefault(c => c.EventRecordId === this.eventRecordID);
		//If video audit is enabled and either we don't have the eventRecordClip or the clips list is empty
		if (this.isClipPlaybackEnabled && (!eventRecordClip || this.clips.length === 0)) {
			this.$refs.player.stop();

			this.clips = [];

			await this.auditService
				.mediaList(this.getSession(SessionResource.AuditServiceSession), eventRecord.eventRecordID, true)
				.then(
					response => {
						if (response.data) {
							let rawClips = response.data;
							let clips = rawClips.filter(c => (c.MediaType !== 'RAW DATA' && c.FileSize > 1000) || (c.MediaType === "Unknown" && c.FileSize == 0));

							this.clips = clips;

							const eventRecordsWithClips: EventRecordHasClipStatus[] = clips.map(clip => {
								return {
									eventRecordId: clip.EventRecordId,
									hasClip: true
								};
							});
							this.setEventRecordsWithClips(eventRecordsWithClips);
							eventRecordClip = this.clips.firstOrDefault(c => c.EventRecordId === this.eventRecordID);
							if (!eventRecordClip) {
								eventRecordClip = this.clips.firstOrDefault(c => c);
							}
						}
					},
					error => {
						this.clips = [];
					}
				);
		}

		if (eventRecordClip && (!this.selectedClip || this.selectedClip.FileIdentifier !== eventRecordClip.FileIdentifier)) {
			this.selectedClip = eventRecordClip;
			this.clipSelected(eventRecordClip);
		}
	}

	public clipSelected(clip: any): void {
		this.$refs.player.playClip(
			this.auditService as AuditService,
			clip.EventRecordId,
			clip
		);
	}

	/**
	 * Marks the event we're reviewing as reviewed, creates an audit event record with a note,
	 * and un-flags the event in the event search and site monitor stores.
	 */
	public async markEventReviewed() {
		this.reviewModalState.processing = true;

		try {
			// First thing - try and actually un-flag the event in the back-end
			let flagRemoved = await this.flagEventForReview({
				eventId: this.event.eventID,
				flagged: false
			});

			// If we were successful, then we can create our event record and update the UI
			if (flagRemoved) {
				// Create an event record with any note that the user put into the text field
				let record = await this.createReviewRecord({
					eventId: this.event.eventID,
					eventRecordTypeId: 108,
					details: `Reviewed flagged event : ${this.reviewModalState.note}`
				});

				// If the user has had to be added to the event, then update the users
				// so we can get the correct initials/colour for their badge within the
				// event records table.
				if (record.userAdded) {
					await this.updateSharing({ eventID: this.event.eventID });
				}

				// Set flagged to false in the event search store
				this.setEventFlagged({
					eventId: this.event.eventID,
					flagged: false
				});
			}
		} catch (err) {
			this.$notify({
				type: "error",
				title: "Reviewing event",
				text:
					"Unable to mark the event as reviewed, please try again later. If the problem persists, contact your support provider."
			});
		}

		// Reset our modals state
		this.reviewModalState.processing = false;
		this.reviewModalState.open = false;
		this.reviewModalState.note = "";
	}

	private async renewAuditSession() {
		await this.updateSession({
			resourceId: SessionResource.AuditServiceSession,
			eventId: this.event.eventID
		});
	}

	private async checkEventHasMedia(eventId: number): Promise<void> {
		try
		{
			await this.auditService
				.doesEventHaveMedia(this.getSession(SessionResource.AuditServiceSession), eventId)
				.then(async (response: any) => {
					if (response && response.status === 200) {
						this.eventHasMedia = response.data;
					} else {
						this.eventHasMedia = true;
					}
				})
				.catch(err => {
					throw err
				});
		}
		catch (ex)
		{
			this.eventHasMedia = true;
			console.error("checkEventHasMedia", ex);
		}
	}
	/**
	 * Start the process of downloading the events archived media
	 */
	public async startMediaDownload(eventId: number) {
		if (!this.downloadingMedia) {
			await this.renewAuditSession();

			await this.auditService
				.archiveEvent(this.getSession(SessionResource.AuditServiceSession), eventId)
				.then(async (response: any) => {
					this.downloadingMedia = true;
					this.downloadMediaButtonText = this.archivingMediaText;
					this.checkArchiveProgress(eventId);
				})
				.catch(err => {
					this.downloadingMedia = false;
					this.downloadMediaButtonText = this.defaultDownloadButtonText;
					this.archiveProgressPercentage = 0;
					// Inform the user if we weren't able to archive the events media
					this.$notify({
						type: "error",
						title: this.defaultDownloadButtonText,
						text: "Unable to start archiving media - please try again later, or contact support if the problem persists."
					});
				});
			this.downloadMediaKey++;
		}
	}

	/**
	 * Continuously requests the current progress percentage of the archive
	 */
	public async checkArchiveProgress(eventId: number) {
		if (this.downloadingMedia) {
			this.auditService
				.archiveIsReady(this.getSession(SessionResource.AuditServiceSession), eventId)
				.then(async (response: any) => {
					this.archiveProgressPercentage = response.data.complete;

					// If the media is still archiving
					if (!response.data.isReady || response.data.complete < 100) {
						//Wait archiveProgressCheckWaitTime seconds before requesting the progress again
						this.archiveProgressCheckTimeout = setTimeout(() => {
							this.checkArchiveProgress(eventId);
						}, this.archiveProgressCheckWaitTime);
					} else if (response.data.isReady && response.data.complete == 100) {
						//The archive is ready so download it
						this.downloadEventMediaArchive(eventId);
					}
				})
				.catch(err => {
					this.downloadingMedia = false;
					this.downloadMediaButtonText = this.defaultDownloadButtonText;
					this.archiveProgressPercentage = 0;
					// Inform the user if we weren't able to download the events media
					this.$notify({
						type: "error",
						title: "Preserve",
						text:
							"Unable to check the status of the archive process - please try again later, or contact support if the problem persists."
					});
				});
		} else {
			clearTimeout(this.archiveProgressCheckTimeout);
		}
	}

	/**
	 * Downloads the events archived media
	 */
	public async downloadEventMediaArchive(eventId: number): Promise<void> {

		this.downloadMediaButtonText = this.downloadingMediaText;
		this.archiveProgressPercentage = 0;

		await this.auditService
			.getArchive(this.getSession(SessionResource.AuditServiceSession), eventId)
			.then(async (response: any) => {
				await this.createReviewRecord({
					eventId: this.event.eventID,
					eventRecordTypeId: 133,
					details: `Media files downloaded by user: ${this.User.fullName}`,
					objectId: this.event.eventID
				});

				this.downloadingMedia = false;
				this.downloadMediaButtonText = this.defaultDownloadButtonText;
			})
			.catch(err => {
				this.downloadingMedia = false;
				this.downloadMediaButtonText = this.defaultDownloadButtonText;
				// Inform the user if we weren't able to download the events media
				this.$notify({
					type: "error",
					title: this.defaultDownloadButtonText,
					text:
						"Unable to Download archived media - please try again later, or contact support if the problem persists."
				});
			});
	}

	public uploadAttachments() {}

	public incidentReport() {
		this.setEventSearchIncidentReportShown(true);
	}

	/**
	 * Method for toggling the display of the modal for downloading a clip.
	 */
	public showDownloadClipModal(showModal: boolean) {
		// Case where no clips exist on event.
		if (this.selectedClip == null && this.showDownloadClipModalFlag == false) {
			// Notify user that no clip exists or may still be downloading.
			this.$notify({
				type: "error",
				title: "Download Media Clip",
				text: "Unable to Download media clip - no alarm clips exist for this event or may still be loading."
			});
			return;
		}
		this.showDownloadClipModalFlag = showModal;
	}

	/**
	 * Downloads a specific media clip from an event.
	 */
	public async downloadEventClipArchive(useFriendlyName: boolean) {
		// Use the device title as the filename for the media clip if flag is true.
		let fileName = null;
		if (useFriendlyName) {
			fileName = this.selectedClip.DeviceTitle;
			// Ensure loading icon shows in friendly name button.
			this.downloadingMediaClipFriendlyName = true;
		} else {
			// Ensure loading icon shows in system name button.
			this.downloadingMediaClipSystemName = true;
		}

		// Generate url for getting media clip data to append to endpoint.
		let mediaUrl = this.auditService.mediaUrl(
			this.getSession(SessionResource.AuditServiceSession),
			this.eventRecordID,
			this.selectedClip.FileIdentifier
		);

		// Get the media clip.
		this.auditService
			.auditDataGetFile(mediaUrl, fileName)
			.then(async (response: any) => {
				// Download the clip.
				const fileName = response.headers["x-suggested-filename"];
				let link = document.createElement("a");
				link.href = window.URL.createObjectURL(response.data);
				link.download = fileName;
				link.click();
				// Close the modal.
				this.showDownloadClipModalFlag = false;
				// Unlock all buttons on success and remove loading icon.
				this.downloadingMediaClipFriendlyName = false;
				this.downloadingMediaClipSystemName = false;

				// Add event record to audit the event of downloading a media clip.
				await this.createReviewRecord({
					eventId: this.event.eventID,
					eventRecordTypeId: 133,
					details: `Media clip file ${fileName} for device ${this.selectedClip.DeviceTitle} downloaded by user: ${this.User.fullName}`,
					objectId: this.event.eventID
				});
			})
			.catch(ex => {
				// Close modal
				this.showDownloadClipModalFlag = false;
				// Notify user that no clip was selected.
				this.$notify({
					type: "error",
					title: "Download Media Clip",
					text: `Error: ${ex.name} - Unable to Download media clip, please try again later or contact support if the problem persists.`
				});
				// On failure, unlock all buttons and remove loading icon.
				this.downloadingMediaClipFriendlyName = false;
				this.downloadingMediaClipSystemName = false;
				return;
			});
	}

	private eventPreservationUpdated(isPreserved: boolean) : void {
		this.eventDetails.preserve = isPreserved;
		let updatedEventDetails = {... this.eventDetails };
		updatedEventDetails.preserve = isPreserved;
		this.setEventDetails(updatedEventDetails);
		this.$emit("event-preserve-updated", isPreserved);
		if (!isPreserved && new Date(this.eventDetails.preservedUntil) < new Date()) {
			this.$emit("event-removed", this.eventDetails.eventID);
		}
	}

	private get downloadMediaHoverText(): string {
		if (this.downloadingMedia) {
			if (this.downloadMediaButtonText === this.downloadingMediaText) {
				return this.downloadingMediaProgress;
			} else {
				return this.archiveProgressPercentage + '%';
			}
		} else {
			return '';
		}
	}

	private get canEditPostEvent(): boolean {
		return !!this.getFeature(["Alarms", "EventReview", "EditPostEvent"]) && !!this.getPermissions.canEditPostEvent;
	}

	private openUpdateEventOutcomeModal(): void {
		this.setEventCloseShown(true);
	}

	private openEventEditInNewTab(): void {
		const route = this.$router.resolve({ name: `eventEdit`, params: { eventId: this.currentEventId?.toString() } });
		window.open(route.href, "_blank");
	}

	@Emit("goToEventPage")
	private onUpdatedEventOutcome(): number {
		return this.event.eventID;
	}
}
