
import SystemViewHealthStatusCount from '@/types/sv-data/system-view/SystemViewHealthStatusCount';
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SystemViewHealthRadioFilter from './SystemViewHealthRadioFilter.vue';

const SystemHealth = namespace("systemHealth");

@Component({
	components: {
		"health-radio-filter": SystemViewHealthRadioFilter
	}
})
export default class SystemViewHealthFilter extends Vue {
	@Prop({type: Boolean, default: false}) private bannerMode: Boolean;
	@SystemHealth.Getter('getHealthStatus') private healthStatusCount: SystemViewHealthStatusCount;
	@SystemHealth.Getter('getStatusFilter') private statusFilter: string;
	@SystemHealth.Mutation setHealthStatus: (status: SystemViewHealthStatusCount) => void;
	@SystemHealth.Mutation setStatusFilter: (filter: string) => void;
	@SystemHealth.Action retrieveHealthStatuses: () => Promise<void>;

	private selectedFilter: string = null;
	private updateTimer: NodeJS.Timeout = null;

	private onSelectedFilterChanged(filter: string): void {
		this.setStatusFilter(filter);

		if (this.bannerMode) {
			window.open(this.$router.resolve('system-view').href, '_blank', "location=yes, scrollbars=yes, status=yes");
		}
	}

	private bannerClick(): void
	{
		this.onSelectedFilterChanged(this.healthStatusCount?.unhealthy ? "Unhealthy": "Healthy")
	}

	private async mounted(): Promise<void> {
		await this.retrieveHealthStatuses();

		if (this.bannerMode) {
			this.setStatusFilter(null);
			this.selectedFilter = null;
			this.updateTimer = setInterval(() => this.retrieveHealthStatuses(), 60000);
		} else {
			this.setupFilter();
		}
	}

	private setupFilter(): void {
		if (this.statusFilter == null) {
			this.setStatusFilter("All");
		}
		this.selectedFilter = this.statusFilter;
	}

	public beforeDestroy() {
		if (this.updateTimer != null) {
			clearTimeout(this.updateTimer);
			this.updateTimer = null;
		}

		if (!this.bannerMode) {
			this.setStatusFilter("All");
		}
	}
}
