import { MutationTree } from "vuex";
import { AreaEventHistoryState } from "./types";
import { getDefaultState } from "./state";
import { AreaEventHistory, AreaEventHistoryPaginatedSearchParams } from "@/types/sv-data/AreaEventHistory";

export const mutations: MutationTree<AreaEventHistoryState> = {
	setIsPopoutModeEnabled(state: AreaEventHistoryState, isEnabled: boolean) {
		state.isPopoutModeEnabled = isEnabled;
	},
	setAreaEventHistory(state: AreaEventHistoryState, areaEventHistory: AreaEventHistory[]) {
		state.areaEventHistory = areaEventHistory;
	},
	setFilter(state: AreaEventHistoryState, filter: AreaEventHistoryPaginatedSearchParams){
		state.filter = filter;
	},
	setTimeZone(state: AreaEventHistoryState, timeZoneId: number){
		state.storedTimeZoneId = timeZoneId;
	},
	setTotalRecords(state: AreaEventHistoryState, totalRecords: number){
		state.totalRecords = totalRecords;
	},
	resetState(state: AreaEventHistoryState): void {
		Object.assign(state, getDefaultState());
	},
};
