
import {
	Component,
	Vue,
	Prop,
	Watch,
	Emit,
	Mixins
} from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { validationMixin } from "vuelidate";
import {
	required,
	requiredIf,
	minValue,
	maxValue,
	numeric
} from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import moment from "moment";
import { EventDetails } from "@/store/site-monitor/types";
import api from "../../services/api.service";

import AlarmPoint from "./AlarmPoint.vue";

const AlarmPoints = namespace("alarmPoints");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		VuePerfectScrollbar,
		"alarm-point": AlarmPoint
	}
})
export default class AlarmPointDialog extends Vue {
	@AlarmPoints.State("displayResponseId") displayResponseId;

	@AlarmPoints.Mutation setDisplayResponseId: any;
	@AlarmPoints.Mutation setSelectedResponse: any;

	@SiteMonitor.Getter getEventDetails: EventDetails;

	private response: any = null;
	private fetchingHistory: boolean = false;
	private alarmPointDialogVisible: boolean = false;

	public mounted(): void {
		this.setDisplayResponseId(null);
		this.setSelectedResponse(null);
	}

	@Watch("displayResponseId")
	public async onDisplayResponseIdChanged(
		value: number | null,
		oldValue: number | null
	): Promise<void> {
		if (value) {
			this.response = await api.responseGet(value);
			this.setSelectedResponse(this.response);
			this.alarmPointDialogVisible = true;
		} else {
			this.alarmPointDialogVisible = false;
			this.setSelectedResponse(null);
		}
	}

	public cancel(): void {
		this.alarmPointDialogVisible = false;

		this.setDisplayResponseId(null);
		this.setSelectedResponse(null);
	}
}
