
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";

import GenericTable, {	TableHeader } from "@/components/table/generic-table.vue";
import { ModalItem } from "@/components/table/generic-update-modal.vue";
import UserRolePermissions from "./UserRolePermissions.vue";
import { FeaturesList } from "@/store/types";
import { UserRole } from "@/store/user-management/types";

const UserManagement = namespace("userManagement");
const Subscription = namespace("subscription");

import api from "@/services/api.service";

import "@/scripts/array-operations";
import { get } from "lodash";
import { SubscriptionDto } from "@/store/subscription/types";

@Component({
	components: {
		"generic-table": GenericTable
	}
})
export default class UserRoleSetup extends Vue {
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@UserManagement.Action public getUserRoles: () => UserRole[];
	@UserManagement.Action public loadPermissions: () => void;
	@UserManagement.Action fetchPermissionsState: () => Promise<void>;
	@Subscription.Action fetchSubscription: any;
	@Subscription.State subscription: SubscriptionDto;
	@Getter getUserTenantGroupId: number;

	public rolesList: UserRole[] = [];
	public roleColumns: TableHeader[] = [
		{
			title: "Role Name",
			key: "title",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The role name",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true
		},
	];

	public roleModalItems: ModalItem[] = [
		{
			title: "Role Name",
			key: "title",
			dataType: "text",
			required: true
		} as ModalItem,
		{
			title: "Permissions",
			key: "permissions",
			dataType: "component",
			data: UserRolePermissions,
			defaultValue: []
		}
	];

	private isLoading: boolean = false;

	public async mounted () {
		try {
			this.isLoading = true;
			this.rolesList = await this.getUserRoles();
			await this.fetchSubscription(this.getUserTenantGroupId);
		} catch (ex) {
			console.log("Unexpected error loading initial state: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	public async updateUserRole (userRole) {
		if (userRole) {
			try {
				this.isLoading = true;
				await api.saveUserRole(userRole);
				await this.updateState();
			} catch (ex) {
				if(ex.response && ex.response.status === 566){
					Vue.prototype.$notify({
						type: "error",
						title: "User role not updated",
						text: ex.response.data
					});
				} else {
					console.log("Unexpected error updating user role: " + ex);
					throw ex;
				}
			}
			finally {
				this.isLoading = false;
			}
		}
	}

	public async deleteUserRole (deleteRole: UserRole) {
		try {
			this.isLoading = true;
			await api.deleteUserRole(deleteRole.userRoleId);
			await this.updateState();
		} catch (ex) {
			console.log("Unexpected error deleting user role: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private async saveActionMessages(userRoleToSave) {
		if (!userRoleToSave || !userRoleToSave.userRoleId || this.rolesList.length == 0) {
			return;
		}
		let currentUserRole = this.rolesList.find(r => r.userRoleId == userRoleToSave.userRoleId);
		let rolesCurrentUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserRole(currentUserRole);
		let updatedRolesUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserRole(userRoleToSave);

		let usageDifference = {
			operators: updatedRolesUserSubscriptionUsageCount.operators - rolesCurrentUserSubscriptionUsageCount.operators,
			mobileOfficers: updatedRolesUserSubscriptionUsageCount.mobileOfficers - rolesCurrentUserSubscriptionUsageCount.mobileOfficers,
		}

		let totalNumberOfUsers = await api.getUserCountForUserRole(userRoleToSave.userRoleId);
		if (totalNumberOfUsers > 0) {
			let result = [];
			if (totalNumberOfUsers == 1) {
				result.push(`Update the permissions for ${totalNumberOfUsers} user.`);
			} else {
				result.push(`Update the permissions for ${totalNumberOfUsers} users.`);
			}
			if (usageDifference.operators !== 0) {
				result.push(`Change the number of Operators used by your subscription from
					${this.subscription.limitedSubscription ? this.subscription.operators + "/" + this.subscription.operatorsLimit : this.subscription.operators} 
					to 
					${this.subscription.operators + usageDifference.operators + (this.subscription.limitedSubscription ? "/" + this.subscription.operatorsLimit: "")}.`
				);
			}
			if (usageDifference.mobileOfficers !== 0) {
				result.push(`Change the number of Mobile Officers used by your subscription from 
					${this.subscription.limitedSubscription ? this.subscription.mobileOfficers + "/" + this.subscription.mobileOfficersLimit : this.subscription.mobileOfficers} 
					to 
					${this.subscription.mobileOfficers + usageDifference.mobileOfficers + (this.subscription.limitedSubscription ? "/" + this.subscription.mobileOfficersLimit: "")}.`
				);
			}
			return result;
		}
		else {
			return [];
		}
	}

	private async saveActionErrors(userRoleToSave): Promise<string[]> {
		if (!userRoleToSave || !userRoleToSave.userRoleId || this.rolesList.length == 0 || !this.subscription.limitedSubscription) {
			return [];
		}
		let currentUserRole = this.rolesList.find(r => r.userRoleId == userRoleToSave.userRoleId);
		let rolesCurrentUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserRole(currentUserRole);
		let updatedRolesUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserRole(userRoleToSave);

		let usageDifference = {
			operators: updatedRolesUserSubscriptionUsageCount.operators - rolesCurrentUserSubscriptionUsageCount.operators,
			mobileOfficers: updatedRolesUserSubscriptionUsageCount.mobileOfficers - rolesCurrentUserSubscriptionUsageCount.mobileOfficers,
		}

		let totalNumberOfUsers = await api.getUserCountForUserRole(userRoleToSave.userRoleId);
		if (totalNumberOfUsers > 0) {
			let result = [];
			if (this.subscription.operators + usageDifference.operators > this.subscription.operatorsLimit) {
				result.push(`Operator usage limit of ${this.subscription.operatorsLimit} will be exceeded`)
			}
			if (this.subscription.mobileOfficers + usageDifference.mobileOfficers > this.subscription.mobileOfficersLimit) {
				result.push(`Mobile Officer usage limit of ${this.subscription.mobileOfficers} will be exceeded`)
			}
			return result;
		}
		else {
			return [];
		}
	}

	private async deleteActionMessages(userRoleToDelete) {
		if (!userRoleToDelete || !userRoleToDelete.userRoleId || this.rolesList.length == 0) {
			return;
		}
		let updatedRolesUserSubscriptionUsageCount = await api.getSubscriptionUsageForUserRole(userRoleToDelete);
		let totalNumberOfUsers = await api.getUserCountForUserRole(userRoleToDelete.userRoleId);
		if (totalNumberOfUsers > 0) {
			let result = [];
			if (totalNumberOfUsers == 1) {
				result.push(`Update the permissions for ${totalNumberOfUsers} user.`);
			} else {
				result.push(`Update the permissions for ${totalNumberOfUsers} users.`);
			}
			if (updatedRolesUserSubscriptionUsageCount.operators > 0) {
				result.push(`Reduce the number of Operators used by your subscription from 
					${this.subscription.limitedSubscription ? this.subscription.operators + "/" + this.subscription.operatorsLimit : this.subscription.operators} 
					to 
					${this.subscription.operators - updatedRolesUserSubscriptionUsageCount.operators + (this.subscription.limitedSubscription ? "/" + this.subscription.operatorsLimit: "")}.`
				);
			}
			if (updatedRolesUserSubscriptionUsageCount.mobileOfficers > 0) {
				result.push(`Reduce the number of Mobile Officers used by your subscription from 
					${this.subscription.limitedSubscription ? this.subscription.mobileOfficers + "/" + this.subscription.mobileOfficersLimit : this.subscription.mobileOfficers} 
					to 
					${this.subscription.mobileOfficers - updatedRolesUserSubscriptionUsageCount.mobileOfficers + (this.subscription.limitedSubscription ? "/" + this.subscription.mobileOfficersLimit: "")}.`
				);
			}
			return result;
		}
		else {
			return [];
		}
	}

	private get isBillingEnabled(): boolean {
		return get(this.featuresList, ["Billing"]);
	}

	private async updateState() {
		try {
			this.isLoading = true;
			this.$emit("triggerUpdate");
			this.rolesList = await this.getUserRoles();
			await this.fetchSubscription(this.getUserTenantGroupId);
			await this.fetchPermissionsState();
		} catch (ex) {
			console.log("Unexpected exception updating component state: " + ex);
		} finally {
			this.isLoading = false;
		}
	}
}
