
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import EventShareItem from './EventShareItem.vue';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import MobileEventRecords from './MobileEventRecords.vue';
import { MobileEventShareInfo } from '@/store/mobile/types';
import api from '@/services/api.service';
import MobileEventShareStates from "@/types/sv-data/enums/MobileEventShareStates";

const Mobile = namespace("mobile");

@Component({
    components:
    {
        "event-share-item": EventShareItem,
        "mobile-event-records" : MobileEventRecords,
        "scrollbar" : VuePerfectScrollbar
    }
})

export default class MobileEventShare extends Vue {
	@Mobile.State("DeviceLocation") private userLocation: any;
	@Mobile.Getter("getEventShareQueue") private eventShareItems: MobileEventShareInfo[];
	@Mobile.State("ActiveEventShareItem") private activeEvent: MobileEventShareInfo;
	@Mobile.Action("setActiveEventShareItem") private setActiveEvent: (event: MobileEventShareInfo) => void;
	@Mobile.Mutation("setEventShareItems") private setEventShareItems: (events: MobileEventShareInfo[]) => void;
    @Mobile.State("NavigateToActiveAlarm") private navigateToAlarm: boolean;
    @Mobile.Mutation("setNavigationLocation") private setNavigationLocation: (location) => void;

    private eventShareQueueVisible: boolean = true;
    private activeEventVisible: boolean = true;
    private showOverlay: boolean = false;
    private setActiveOrLeaving: boolean = true; // true is active, false is leaving
    private pendingEvent: MobileEventShareInfo = null;
    private unreadRecordCount: number = 0;

    @Watch("eventShareItems")
    private eventShareItemsWatch(): void {
        //If the pending item no longer exists clear it
        if(!this.eventShareItems
            || this.eventShareItems.length == 0
            || (this.pendingEvent && !this.eventShareItems.find(x => x.eventID == this.pendingEvent.eventID)))
        {
            this.pendingEvent = null;
        }
    }

    private get eventQueueSize(): string {
        if(this.activeEventVisible){
            return (this.activeEvent) ? "queueSmall" : "queueMedium";
        } else {
            return "queueLrg"
        }
    }

    private toggleEventQueue(): void {
        //the event queue can't be collapse when there is no active event
        if (!this.activeEvent){
            this.eventShareQueueVisible = true;
            return;
        }

        this.eventShareQueueVisible = !this.eventShareQueueVisible;
    }

    private async trySetActiveEvent(confirmed: boolean = false): Promise<void>{
        this.setActiveOrLeaving = true;
        let event = this.pendingEvent;
        if(!event)
            throw "no event provided.";

        if(!this.activeEvent || confirmed){
            if(this.activeEvent){
                this.removeEventShare(this.activeEvent.eventID);
            }
            event.state = MobileEventShareStates.Accepted;
            await api.setMobileEventShareItem(event);
            this.setActiveEvent(event);
            this.showOverlay = false;
            this.pendingEvent = null;
            return;
        }

        if(this.activeEvent && this.activeEvent.eventID != event.eventID){
            this.pendingEvent = event;
            this.showOverlay = true;
        }
    }

    private async updateEvent(event: MobileEventShareInfo): Promise<void> {
        this.pendingEvent = event;
        if(event.state === MobileEventShareStates.Accepted){
            this.trySetActiveEvent(false);
            return;
        }

        if(event.state === MobileEventShareStates.Rejected || event.state === MobileEventShareStates.Left){
            //If we have left the active event, clear the navigation
            if(this.activeEvent && this.activeEvent.eventID === event.eventID){
                this.showOverlay = true;
                this.setActiveOrLeaving = false;
            } else {
                await api.setMobileEventShareItem(event);
                this.removeEventShare(event.eventID);
                this.pendingEvent = null;
            }
        }
    }

    private removeEventShare(eventID: number): void{
        const filteredItems = this.eventShareItems.filter(x => x.eventID != eventID);
        this.setEventShareItems(filteredItems);

        if ((!filteredItems || filteredItems.length === 0) && this.activeEvent){
             this.eventShareQueueVisible = false;
        }
    }

    private async leaveActiveEvent(): Promise<void> {
        const activeEvent = {...this.activeEvent}
        activeEvent.state = MobileEventShareStates.Left;
        await api.setMobileEventShareItem(activeEvent);
        this.removeEventShare(activeEvent.eventID);
        this.eventShareQueueVisible = true;
        this.pendingEvent = null;
        this.showOverlay = false;
        this.setActiveEvent(null);
        if(this.navigateToAlarm){
            this.setNavigationLocation(null);
        }
    }

    @Watch("activeEvent")
    private activeEventWatch(): void {
        if(!this.activeEvent)
            this.eventShareQueueVisible = true;
    }

    private cancelPendingStateChange(): void {
        this.showOverlay = false;
        this.pendingEvent = null;
    }

    public newRecords(value: number): void {
        var mobileEventRecord = this.$el.querySelector("#mobileEventRecords");
        if(mobileEventRecord && mobileEventRecord.scrollTop != 0){
            this.unreadRecordCount += value;
            return;
        }

        this.unreadRecordCount = 0;
    }

    private scrollRecords(): void {
        var mobileEventRecord = this.$el.querySelector("#mobileEventRecords");
        mobileEventRecord.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
        });

        this.unreadRecordCount = 0;
    }

    private get pendingActive(): boolean {
        if(this.pendingEvent && this.activeEvent && this.activeEvent.eventID === this.pendingEvent.eventID){
            return true;
        }
        return false;
    }
}
