
    import { Component, Mixins, Prop } from "vue-property-decorator";
    import PaginatedSearch, { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";
    import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
    import { Getter, namespace } from "vuex-class";
    import { 
        DistanceConfiguration, 
        DistanceConfigurationDto, 
        DistanceConfigurationPagedResponse,
        DistanceConfigurationWithTableActions,
        defaultDistanceConfiguration,
        metersAsMilesDecimalPlaces
    } from "@/store/distance-configuration/types";
    import { PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
    import DistanceConfigurationDeleteModal from "./DistanceConfigurationDeleteModal.vue";
    import DistanceConfigurationEditModal from "./DistanceConfigurationEditModal.vue";
    import { convertMetersToMiles } from "@/utils/conversion-utils";

    const DistanceConfigurationStore = namespace("distanceConfiguration");

    @Component({
        components: {
            "generic-table": GenericTable,
            "distance-configuration-delete-modal" : DistanceConfigurationDeleteModal,
            "distance-configuration-edit-modal" : DistanceConfigurationEditModal
        }
    })
    export default class DistanceConfigurationTable extends Mixins(PaginatedSearch) {
        
	    @Prop({ required: false, default: false }) 
        private readonly: boolean;

        @DistanceConfigurationStore.Action private retrieveDistanceConfigurations: (paginatedSearchRequest: PaginatedSearchRequest) => Promise<void>;
        @DistanceConfigurationStore.State private distanceConfigurations: DistanceConfigurationPagedResponse;
        @Getter getPermissions: UserPermissions;

        private isLoading: boolean = false;
        private isDeleteModalShown: boolean = false;
        private isEditModalShown: boolean = false;

        private selectedDistanceConfiguration: DistanceConfiguration = {...defaultDistanceConfiguration};

        private async mounted(): Promise<void> {
            await this.updateData();
        }

        private get columns (): TableHeader[] {
            return [
                {
                    title: "Area",
                    key: "areaTitle",
                    order: 1,
                    sortOrder: 0,
                    sortOrderReversed: false,
                    description: "The Area Title",
                    searchable: true,
                    visible: true,
                    dataType: "input",
                    isTermLabel: true,
                    sortable: true,
                    isSortedByDefault: true,
                },
                {
                    title: "Alarm Decoder",
                    key: "serverTypeTitle",
                    order: 2,
                    sortOrder: 1,
                    sortOrderReversed: false,
                    description: "The Alarm Decoder",
                    searchable: true,
                    visible: true,
                    dataType: "input",
                    sortable: true,
                    sortKey: "AlarmDecoder",
                },
                {
                    title: "Event Type",
                    key: "serverTypeEventTitle",
                    order: 3,
                    sortOrder: 3,
                    sortOrderReversed: false,
                    visible: true,
                    searchable: true,
                    sortable: true,
                    sortKey: "EventType"
                },
                {
                    title: "Radius (m/mi)",
                    key: "radius",
                    order: 4,
                    sortOrder: 4,
                    sortOrderReversed: false,
                    visible: true,
                    searchable: true,
                    addQuickCopy: true,
                    useCustomCell: true,
                    sortable: true,
                },
            ];
        }

        private get userHasViewPermissions(): boolean {
            return this.getPermissions.canViewSiteSetup;
        }

        private get userHasEditPermissions(): boolean {
            return this.getPermissions.canEditSiteSetup;
        }

        private get totalRecords(): number {
            return this.distanceConfigurations ? this.distanceConfigurations.totalRecords : 0;
        }

        public async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
            try {
                this.isLoading = true;
                const request = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);
                await this.retrieveDistanceConfigurations(request);
            }
            catch (ex) {
                console.error("Unexpected error fetching Distance Filters", ex);
            }
            finally {
                this.isLoading = false;
            }
        }

        private get dataWithActions(): DistanceConfigurationWithTableActions[] {
            return this.distanceConfigurations?.data ? this.distanceConfigurations.data.map(distanceConfiguration => {
                return {...distanceConfiguration, controls: true}
            }) : [];
        }
        
        private handleDeleteClick(distanceConfiguration: DistanceConfigurationDto): void {
            this.selectedDistanceConfiguration = distanceConfiguration;
            this.isDeleteModalShown = true;
        }

        private closeDeleteModal(): void {
            this.isDeleteModalShown = false;
            this.selectedDistanceConfiguration = {...defaultDistanceConfiguration};
        }

        private handleAddClick(): void {
            this.selectedDistanceConfiguration = {...defaultDistanceConfiguration};
            this.isEditModalShown = true;
        }

        private handleEditClick(distanceConfiguration: DistanceConfigurationDto): void {
            this.selectedDistanceConfiguration = {...distanceConfiguration};
            this.isEditModalShown = true;
        }

        private closeEditModal(): void {
            this.isEditModalShown = false;
            this.selectedDistanceConfiguration = {...defaultDistanceConfiguration};
        }
        
        private reloadDistanceConfigurations(): void {
            this.updateData();
        }

        private getMetersAsMiles(meters: number): string {
            return convertMetersToMiles(meters, metersAsMilesDecimalPlaces)
        }
    }
