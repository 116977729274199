import { GetterTree } from "vuex";
import { AlarmMaskingState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<AlarmMaskingState, RootState> = {
	getMaskedAlarmCount: state => {
		return state.responses.filter(r => !r.armed).length;
	},
	getUnmaskedAlarmCount: state => {
		return state.responses.filter(r => r.armed).length;
	},
};
