
import { Component, Mixins } from "vue-property-decorator";
import api from '@/services/api.service';
import GenericTable, { TableHeader } from '@/components/table/generic-table.vue';
import { ModalItem } from '@/components/table/generic-update-modal.vue';
import AlarmDecoder, { AlarmDecoderExtended } from '@/types/sv-data/alarms/AlarmDecoder';
import AlarmDecoderEventSetup from './AlarmDecoderEventSetup.vue';
import { helpers } from 'vuelidate/lib/validators';
import Script from '@/types/sv-data/events/Script';
import { namespace, Getter } from 'vuex-class';
import AreaTreeSelect from '@/components/form/AreaTreeSelect.vue';
import { PaginatedSearchQueryParams } from "@/store/types";
import PaginatedSearch from "@/mixins/PaginatedSearch";
import AlarmDecoderPagedResponse from "@/types/sv-data/alarms/AlarmDecoderPagedResponse";

@Component({
    components: {
        "generic-table": GenericTable,
    }
})
export default class AlarmDecoderTable extends Mixins(PaginatedSearch) {
	@Getter getFeature: (featuresList: string[]) => boolean;

    private get hasAlarmSetupEditAreaFlag() {
        return this.getFeature(["AlarmSetup", "EditArea"]);
    }

    private get hasAlarmSetupLocationRegexFlag(): boolean {
        return this.getFeature(["AlarmSetup", "LocationRegex"]);
    }

	private get hasAlarmSetupExtraTextRegexFlag(): boolean {
		return this.getFeature(["AlarmSetup", "ExtraTextRegex"]);
	}

    private get canDistanceFilterFlag(): boolean {
        return this.getFeature(["AlarmSetup", "DistanceFiltering"]);
    }

    private get hasAlarmSetupLatLngRegexFlag(): boolean {
        return this.getFeature(["AlarmSetup", "LocationRegex", "LatLngRegexes"]);
    }

    private get hasAlarmSetupURLRegexFlag(): boolean {
        return this.getFeature(["AlarmSetup", "URLRegex"]);
    }

    private responseScripts: Script[] = [];

    public get columns (): TableHeader[] {
        const columns : TableHeader[] = [
            {
                title: "Title",
                key: "title",
                order: 1,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "The alarm decoder title",
                searchable: true,
                visible: true,
                dataType: "input",
                isTermLabel: true,
                sortable: true,
                isSortedByDefault: true
            },
            {
                title: "System Identifier",
                key: "systemIdentifier",
                order: 2,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "System Identifier used when triggering an alarm",
                visible: true,
                isTermLabel: true,
                useCustomCell: false,
                searchable: true,
                addQuickCopy: true,
                sortable: true,
            },
            {
                title: "Response Regex",
                key: "responseRegex",
                order: 3,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "The response regex",
                searchable: true,
                visible: true,
                dataType: "input",
                isTermLabel: true,
                sortable: true,
            },
            {
                title: "Event Regex",
                key: "eventRegex",
                order: 4,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "The event regex",
                searchable: true,
                visible: true,
                dataType: "input",
                isTermLabel: true,
                sortable: true,
            }
        ];

        if(this.hasAlarmSetupLocationRegexFlag)
        {
            columns.push({
                title: "Location Regex",
                key: "locationRegex",
                order: 5,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "The location regex",
                searchable: true,
                visible: true,
                dataType: "input",
                isTermLabel: true,
                sortable: true
            });
        }

        if(this.hasAlarmSetupLatLngRegexFlag)
        {
            columns.push({
                title: "Latitude Regex",
                key: "latRegex",
                order: 6,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "The latitude regex",
                searchable: true,
                visible: false,
                dataType: "input",
                isTermLabel: true,
                sortable: true
            },
            {
                title: "Longitude Regex",
                key: "lngRegex",
                order: 7,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "The longitude regex",
                searchable: true,
                visible: false,
                dataType: "input",
                isTermLabel: true,
                sortable: true
            });
        }
        
        if(this.hasAlarmSetupExtraTextRegexFlag)
		{
			columns.push({
				title: "Extra Text Regex",
				key: "extraTextRegex",
				order: 8,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The extra text regex",
				searchable: true,
				visible: false,
				dataType: "input",
				isTermLabel: true,
				sortable: true
			});
		}

        if(this.canDistanceFilterFlag)
        {
            columns.push({
                title: "Distance Filter",
                key: "canDistanceFilter",
                order: 9,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "Shows whether an alarm decoder supports distance filtering",
                searchable: false,
                visible: false,
                dataType: "input",
                isTermLabel: true,
                sortable: true,
                useCustomCell: true
            });
        }

        if(this.hasAlarmSetupURLRegexFlag)
        {
            columns.push({
                title: "URL Regex",
                key: "urlRegex",
                order: 10,
                sortOrder: 0,
                sortOrderReversed: false,
                description: "The URL regex",
                searchable: true,
                visible: false,
                dataType: "input",
                isTermLabel: true,
                sortable: true
            });
        }

        return columns;
    }

    public get modalItems (): ModalItem[] {
        return [
            {
                title: "Title",
                key: "title",
                dataType: "text",
                maxLength: 250,
                required: true,
                placeholder: "Enter the alarm decoder title",
            },
            {
                title: "Response Regex",
                key: "responseRegex",
                dataType: "text",
                maxLength: 200,
                required: true,
                placeholder: "Enter the response regex",
                validations: {
                    isRegexValid: helpers.withParams(
                        { type: "isRegexValid", errorMessage: "Response Regex must contains ?<rc>" },
                        value => {
                            return !helpers.req(value) || value.includes("?<rc>")
                        }
                    )
                },
            },
            {
                title: "Event Regex",
                key: "eventRegex",
                dataType: "text",
                maxLength: 200,
                required: true,
                placeholder: "Enter the event regex",
                validations: {
                    isRegexValid: helpers.withParams(
                        { type: "isRegexValid", errorMessage: "Event Regex must contains ?<ec>" },
                        value => {
                            return !helpers.req(value) || value.includes("?<ec>")
                        }
                    )
                },
            },
            {
                title: "Location Regex",
                key: "locationRegex",
                dataType: "text",
                maxLength: 200,
                required: false,
                placeholder: "Enter the location regex",
                validations: {
                    isRegexValid: helpers.withParams(
                        { type: "isRegexValid", errorMessage: "Location Regex must contain ?<lc>" },
                        value => {
                            return !helpers.req(value) || value.includes("?<lc>")
                        }
                    )
                },
                visible: this.hasAlarmSetupLocationRegexFlag,
                requiredMethod: (value) => { 
                    return (value?.latRegex != null && value?.latRegex.trim()) || (value?.lngRegex != null && value?.lngRegex.trim())
                },  
            },
            {
                title: "Latitude Regex",
                key: "latRegex",
                dataType: "text",
                maxLength: 200,
                required: false,
                placeholder: "Enter the latitude regex",
                validations: {
                    isRegexValid: helpers.withParams(
                        { type: "isRegexValid", errorMessage: "Latitude Regex must contain ?<lat>" },
                        value => {
                            return !helpers.req(value) || value.includes("?<lat>")
                        }
                    )
                },
                description: "This Regex will apply to the result of the Location Regex",
                visible: this.hasAlarmSetupLatLngRegexFlag,
                requiredMethod: (value) => { return value?.lngRegex != null && value?.lngRegex.trim() },  
            },
            {
                title: "Longitude Regex",
                key: "lngRegex",
                dataType: "text",
                maxLength: 200,
                placeholder: "Enter the longitude regex",
                validations: {
                    isRegexValid: helpers.withParams(
                        { type: "isRegexValid", errorMessage: "Longitude Regex must contain ?<lng>" },
                        value => {
                            return !helpers.req(value) || value.includes("?<lng>")
                        }
                    )
                },
                description: "This Regex will apply to the result of the Location Regex",
                visible: this.hasAlarmSetupLatLngRegexFlag,
                requiredMethod: (value) => { return value?.latRegex != null && value?.latRegex.trim() },                
            },
			{
				title: "Extra Text Regex",
				key: "extraTextRegex",
				dataType: "text",
				maxLength: 200,
				required: false,
				placeholder: "Enter the extra text regex",
				validations: {
					isRegexValid: helpers.withParams(
						{ type: "isRegexValid", errorMessage: "Extra Text Regex must contain ?<et>" },
						value => {
							return !helpers.req(value) || value.includes("?<et>")
						}
					)
				},
				visible: this.hasAlarmSetupExtraTextRegexFlag
			},
            {
                title: "Distance Filter",
                key: "canDistanceFilter",
                dataType: "checkbox",
                visible: this.canDistanceFilterFlag,
                description: "Enables distance configuration filters to be added to alarms that come in through the decoder"
            },
            {
                title: "URL Regex",
                key: "urlRegex",
                dataType: "text",
                maxLength: 200,
                placeholder: "Enter the URL regex",
                validations: {
                    isRegexValid: helpers.withParams(
                        { type: "isRegexValid", errorMessage: "URL Regex must contain ?<url>" },
                        value => {
                            return !helpers.req(value) || value.includes("?<url>")
                        }
                    )
                },
                description: "This Regex will apply to the result of the URL Regex",
                visible: this.hasAlarmSetupURLRegexFlag,
                requiredMethod: (value) => { return value?.urlRegex != null && value?.urlRegex.trim() },                
            },
            {
                title: "Default Area",
                key: "defaultGroupId",
                dataType: "component",
                readOnlyIfEdit: !this.hasAlarmSetupEditAreaFlag,
                required: true,
                data: AreaTreeSelect,
                props: {
                    reduce: (a) => a.id,
                    clearable: false
                },
            },
            {
                title: "Default Action Plan",
                key: "defaultScriptId",
                dataType: "vselect3",
                data: this.responseScripts,
                props: {
                    reduce: (s) => s.scriptID,
                },
                required: false,
                placeholder: "Select an action plan",
            },
            {
                title: "Default Grouping",
                key: "defaultGrouping",
                dataType: "vselect3",
                data: this.groupingOptions,
                props: {
                    reduce: (s) => s.value,
                },
                required: true,
                placeholder: "Select an alarm grouping",
            },
            {
                title: "Default Priority",
                key: "defaultPriority",
                dataType: "number",
                required: true
            },
            {
                title: "Events",
                key: "serverTypeEvents",
                dataType: "component",
                data: AlarmDecoderEventSetup,
            },
        ]
    }

    private alarmDecoders: AlarmDecoder[] = [];
    private selectedDecoder: AlarmDecoderExtended = null;
    private totalRecords: number = 0;
    private isLoading: boolean = false;

    private async putAlarmDecoder(decoder: AlarmDecoderExtended): Promise<void> {
        if(this.selectedDecoder)
        {
            decoder.rootServerId = this.selectedDecoder.rootServerId;
            decoder.rootResponseId = this.selectedDecoder.rootResponseId;
        }
        await api.createOrUpdateAlarmDecoder(decoder);
        await this.loadDecoders(this.mostRecentSearchParams);
        this.$emit("triggerUpdate");
    }

    private async deleteAlarmDecoder(decoder: AlarmDecoderExtended): Promise<void> {
        await api.deleteAlarmDecoder(decoder.serverTypeId);
        await this.loadDecoders(this.mostRecentSearchParams);
        this.$emit("triggerUpdate");
    }

    private async onModalOpen(decoder: AlarmDecoder): Promise<void> {
        if(!decoder)
            this.selectedDecoder = null;
        else
            this.selectedDecoder = await api.getAlarmDecoder(decoder.serverTypeId);

        if(decoder && !decoder.serverTypeEvents)
            decoder.serverTypeEvents = [];
    }

    public async loadDecoders(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
        try {
            this.isLoading = true;
            this.selectedDecoder = null;
            const cancellableQuery = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);
            const getAlarmDecodersPagedResponse: AlarmDecoderPagedResponse = await api.getAlarmDecoders(cancellableQuery);
            this.totalRecords = getAlarmDecodersPagedResponse.totalRecords;
            this.alarmDecoders = getAlarmDecodersPagedResponse.data;
        }
        catch (ex) {
            console.log("Unexpected error fetching alarm decoders: " + ex);
        }
        finally {
            this.isLoading = false;
        }
    }

    private async mounted(): Promise<void> {
        this.responseScripts = await api.getResponseScripts();
        await this.loadDecoders();
    }

    private deleteAlarmDecoderMessage(alarmDecoder: AlarmDecoderExtended): string[]{
        return [`Remove ${alarmDecoder.linkedAlarmCount} configured alarm${(alarmDecoder.linkedAlarmCount > 1 ? 's':'')} which use this decoder.`];
    }

    private groupingOptions: any[] = [
        {
            title: "Not Set",
            value: 4,
        },
        {
            title: "By Area",
            value: 3,
        },
        {
            title: "By Alarm",
            value: 2,
        },
        {
            title: "Not Grouped",
            value: 1,
        }
    ];
}
