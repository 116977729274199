
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import { State, Getter, Action, Mutation, namespace } from "vuex-class";
	import vSelect from "vue-select"; // @ref https://sagalbot.github.io/vue-select/
	import NotificationSetup from "@/components/tasks/notification/NotificationSetup.vue";
	import ChoicesTaskSetup from "@/components/tasks/choices/ChoicesTaskSetup.vue";
	import CallContactSetup from "@/components/tasks/call-contact/CallContactSetup.vue";
	import NewTask from "@/models/new-task.model";
	import { TaskType } from "@/store/tasks/types";
	import BasicTask from "@/components/tasks/task-types/BasicTask.vue";
    import RaiseToCasesTask from "@/components/tasks/task-types/RaiseToCasesTask.vue";
	import InputTask from "@/components/tasks/task-types/InputTask.vue";
	import YesNoTask from "@/components/tasks/task-types/YesNoTask.vue";
	import UrlTask from "@/components/tasks/task-types/UrlTask.vue";
	import SitRepTask from "@/components/tasks/task-types/SitRepTask.vue";
	import CCureTask from "@/components/tasks/task-types/CCureTask.vue";
	import AuditTask from "@/components/tasks/task-types/AuditTask.vue";
	import DynamicDataList from "@/components/tasks/task-types/DynamicDataList.vue";
	import ControlTask from "@/components/tasks/control/ControlSetup.vue";
	import EventOutcomeTask from "@/components/tasks/task-types/EventOutcomeTask.vue";
	import { stringMixin } from "@/mixins";
	import TaskTypeIds from "@/types/sv-data/enums/TaskTypeIds";

	import { get } from "lodash";

	const { isNullOrWhitespace } = stringMixin.methods;

	// @techdebt @refactor break up into sub-components
	const Tasks = namespace("tasks");

	@Component({
		components: {
			"vue-select": vSelect,
			"notification-setup": NotificationSetup,
			"basic-task": BasicTask,
			"input-task": InputTask,
			"yes-no-task": YesNoTask,
			"url-task": UrlTask,
			"sitrep-task": SitRepTask,
			"ccure-task": CCureTask,
			"audit-task": AuditTask,
			"choices-task-setup": ChoicesTaskSetup,
			"call-contact-setup": CallContactSetup,
			"dynamic-data-list": DynamicDataList,
			"control-task": ControlTask,
            "raise-to-cases-task": RaiseToCasesTask,
			"event-outcome-task": EventOutcomeTask
		}
	})
	export default class AddNewTask extends Vue {
		@Tasks.Getter("getEnabledActionTypes") enabledActionTypes: TaskType[];
		@Getter getFeature: (featuresList: string[]) => boolean;

		public hasValidationErrors: boolean = false;
		public showErrorAnimation: boolean = false;
		public taskTypeSelectPlaceholder: any = null;

		private TaskTypeIdEnum: typeof TaskTypeIds = TaskTypeIds;

		public placeholderText = {
			1: "Action text",
			2: "Specify question that will require user input to complete action",
			3: "Specify question that will require user to choose Yes/No to complete action"
		};

		@Prop(Number)
		public taskCategoryId!: number;

		@Prop(Array)
		public taskTypes!: TaskType[];

		public newTask: {
			taskText: string;
			taskCategoryID: number;
			taskTypeID: number;
			taskData: any;
		} = { taskText: "", taskCategoryID: 0, taskTypeID: 1, taskData: null };

		public taskToRender: string = "basic-task";
		public isTaskValid: boolean = false;

		public mounted() {
			this.loadCorrectComponent();
			this.taskTypeSelectPlaceholder = this.enabledActionTypes.find(
				tt => tt.title === "Basic"
			);
		}

		public loadCorrectComponent() {
			let task = this.enabledActionTypes.find(
				(taskType: any) =>
					taskType.taskTypeID === this.newTask.taskTypeID &&
					taskType.componentFile !== null
			);

			// Avoid property undefined errors if a task was not returned.
			if (task !== undefined) {
				this.taskToRender = task.componentFile;
			}
		}

		@Watch("taskTypeSelectPlaceholder", { immediate: true, deep: true })
		public onTaskTypeChanged(taskType: any) {
			this.newTask.taskText = "";
			if (taskType === null || taskType === undefined) return;
			this.newTask.taskTypeID = taskType.taskTypeID || 1;

			this.loadCorrectComponent();
		}

		public get isValid() {
			let isValid = this.isTaskValid;

			// taskType defaults to basic
			if (!this.newTask.taskTypeID) {
				this.newTask.taskTypeID = 1;
			}

			return isValid;
		}

		// Called when a task component verifies that it is valid.
		public taskValidated(valid: boolean) {
			this.isTaskValid = valid;
		}

		private reset() {
			this.taskTypeSelectPlaceholder = null;
			this.newTask = {
				taskText: "",
				taskCategoryID: 0,
				taskTypeID: 1,
				taskData: null
			};
		}

		public onSave() {
			if (this.isValid) {
				this.newTask.taskCategoryID = this.taskCategoryId;

				this.confirmUrlTaskHasTitle();

				this.$emit("save", this.newTask);
				this.reset();
			}
		}

		public onCancel() {
			this.$emit("cancel", this.taskCategoryId);
			this.reset();
		}

		private confirmUrlTaskHasTitle() {
			if (this.newTask.taskTypeID === TaskTypeIds.URL) {
				if (this.newTask.taskText === "") {
					this.newTask.taskText = this.newTask.taskData;
					this.newTask.taskData = null;
				}
			}
		}

		private get massNotificationEnabled(): boolean {
			return this.getFeature(["Actions", "MassNotification"]);
		}

		private get choicesEnabled(): boolean {
			return this.getFeature(["Actions", "Choices"]);
		}

		private get callContactsEnabled(): boolean {
			return this.getFeature(["Actions", "CallContacts"]);
		}

		private get controlsEnabled(): boolean {
			return this.getFeature(["Actions", "Control"]);
		}

		private get eventOutcomeEnabled(): boolean {
			return this.getFeature(["Actions", "EventOutcome"]);
		}
	}
