
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import UserDTO from "@/types/sv-data/users/UserDTO";
import ConfirmationModal from "@/components/mobile-raise-templates/ConfirmationModal.vue";

const GenericTableStore = namespace("GenericTable");

@Component({
	components: {
		"confirmation-modal": ConfirmationModal
	}
})
export default class ClearMFA extends Vue {
	@Prop({ type: Boolean, default: false })
	public value: boolean;

	@GenericTableStore.Getter("getModalRow") row: UserDTO;

	private displayUndoButton = false;
	private isClearConfirmationShown = false;

	@Watch("value")
	private onValueChanged(newValue: boolean) {
		// if newValue is flase (changed to cleared) - display undo button
		this.displayUndoButton = !newValue;
	}

	private onClearClick() {
		this.isClearConfirmationShown = true;
	}
	private onUndoClick() {
		this.$emit("input", true);
	}
	private onClearConfirm() {
		this.isClearConfirmationShown = false;
		this.$emit("input", false);
	}
	private onClearCancel() {
		this.isClearConfirmationShown = false;
	}

	private get valueText() {
		if (this.value) {
			return "Two-Factor Authentication set";
		} else if (this.displayUndoButton) {
			return "Two-Factor Authentication cleared";
		} else {
			return "Two-Factor Authentication not set";
		}
	}

	private get valueStyle() {
		return this.value ? "fixed-width-span text-success" : "fixed-width-span text-danger";
	}
}
