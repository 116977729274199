
	import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
    import { State, Mutation } from "vuex-class";

    @Component
    export default class GettingStartedModal extends Vue {
        @Prop({default: false, type: Boolean}) public readonly value;

        @State User;
        @Mutation setisFirstLogin: any;

        private isModalShown = false;

        @Emit("input")
        public onModalChange() {
            return this.isModalShown;
        }

        @Watch("value")
        public onValueChanged(newValue) {
            this.isModalShown = newValue;

            // If this is a new user and the modal is being opened
            if (this.User.isFirstLogin && newValue) {
                // The User is no longer considered a new user as they have viewed the Getting Stated Guide
                this.setisFirstLogin(false);
            }
        }
    }
