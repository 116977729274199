
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import Multiselect from "vue-multiselect";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { DeviceService } from "@sureview/camera";
import { ShareWithPerson, EventDetails } from "@/store/site-monitor/types";
import { SessionResource } from "@/store/sessions/types";
import api from '@/services/api.service';
import vselect3 from "vselect3";
import ShareAsset from '@/types/sv-data/ShareAsset';
import EventSharingAsset from './EventSharingAsset.vue';
import MobileEventSharingStatus from '@/types/sv-data/MobileEventSharingStatus';

const SessionStore = namespace("sessions");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		multiselect: Multiselect,
		VuePerfectScrollbar: VuePerfectScrollbar,
		"v-select3" : vselect3,
		"event-share-asset" : EventSharingAsset
	}
})
export default class EventSharing extends Vue {
	@Prop(Number) eventid?: number;

	$refs!: {
		sharingModal: any;
		assetSelect: any;
	};

	public settings: any = {};
	public selected: any[] = [];
	public isLoading: boolean = false;

	public adhocName: string = "";
	public adhocEmail: string = "";

	private mobileAssetIDs: number[] = [];

	@SessionStore.Getter getSession: any;
	@SessionStore.Action updateSession: any;

	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getIsFieldOpsLicensed") private fieldOpsLicenced: boolean;
	@SiteMonitor.Action loadExternalUsersAllowed: any;
	@SiteMonitor.Action removeShare: any;
	@SiteMonitor.Action updateSharing: any;
	@SiteMonitor.Action eventShareSearch: any;
	@SiteMonitor.Action eventPassControl: any;
	@SiteMonitor.Action loadNotificationTemplate: any;
	@SiteMonitor.Action shareEventWithUser: any;
	@SiteMonitor.Action shareEventWithGroup: any;
	@SiteMonitor.Action addAdhocEventShare: any;

	@SiteMonitor.Getter("getExternalUsersAllowed") externalEventShare!: boolean;
	@SiteMonitor.Getter("getEventShareShown") eventShareShown!: boolean;
	@SiteMonitor.Getter("getEventShare") sharedWith!: any[];
	@SiteMonitor.Getter shareWithPersons: ShareWithPerson[];
	@SiteMonitor.Getter("getAdhocShareDetails") adhocShareDetails: any;
	@SiteMonitor.Getter getEventDetails: EventDetails;
	@SiteMonitor.State("MobileEventShares") mobileEventShares: MobileEventSharingStatus[];

	@SiteMonitor.Mutation setEventShareShown: any;
	@SiteMonitor.Mutation setAdHocShareDetails: any;
	@SiteMonitor.Mutation setActivity: any;

	private assets: ShareAsset[] = [];

	private get canMobileEventShare() : boolean {
		return this.getFeature(["Mobile", "FieldOps", "EventShare"]) && this.fieldOpsLicenced;
	}

	private async loadAssets() : Promise<void> {
		const assets = await api.getShareableAssets();
		//An asset can only be actively attached to one event at a time, filter out any assets that have an active event
		this.assets = assets.filter(ast => ast.isUser || (!ast.isUser && !ast.hasActiveShare));
	}

	private get mobileEventShareAssets() {
		if(this.sharedWith && this.sharedWith.length > 0 && this.mobileEventShares){
			return this.mobileEventShares.filter(x => !this.sharedWith.map(y => y.userID).includes(x.userID));
		}
		return this.mobileEventShares;
	}

	private getMobileEventShareForUser(userID: number){
		if(!this.mobileEventShares || this.mobileEventShares.length == 0)
			return null;

		const mobileShare = this.mobileEventShares.find(x => x.userID === userID);
		return mobileShare ? mobileShare : null;
	}

	private async mobileShare(): Promise<void> {
		const mobileAssetIDsCopy = [...this.mobileAssetIDs];
		for (const assetID of mobileAssetIDsCopy) {
			await api.shareEventWithAsset(this.eventid, assetID);
		}
		this.updateSharing({ eventID: this.eventid });

		this.$refs.assetSelect.clearSelection();
		this.mobileAssetIDs = [];
	}

	private getMobileShareBackground(share: any): string {
		return `background-color:${share.iconColor ? share.iconColor : 'grey' };`
	}

	@Watch("eventShareShown")
	onEventCloseShownChanged(value: boolean) {
		if (value) {
			this.loadAssets(); //reload the assets every time open the share
			this.$refs.sharingModal.show();
		} else {
			this.$refs.sharingModal.hide();
		}
	}

	created() {
		this.setEventShareShown(false);
		this.updateSession({ resourceId: SessionResource.DeviceServiceSession }); // eventId?
		this.loadExternalUsersAllowed(this.eventid);
		this.loadAssets();
	}

	async eventShareFind(query: any) {
		this.isLoading = true;
		try {
			await this.eventShareSearch(query);
			this.isLoading = false;
		} catch (e) {
			this.isLoading = false;
		}
			this.setActivity();
	}

	async addImmixShare() {
			this.setActivity();
		let sharePromises: Promise<any>[] = [];
		this.selected.forEach(share => {
			if (share.userID != null) {
				sharePromises.push(
					this.shareEventWithUser({
						UserID: share.userID,
						EventID: this.eventid,
						PassControl: false
					})
				);
			} else if (share.routingGroupID != null) {
				sharePromises.push(
					this.shareEventWithGroup({
						RoutingGroupID: share.routingGroupID,
						EventID: this.eventid,
						PassControl: false
					})
				);
			}
		});

		this.selected = [];

		let shareResults = [];

		for (var i = 0; i < sharePromises.length; i++) {
			shareResults.push(await sharePromises[i]);
		}

		let notification: any = {};
		let anyNotification = false;

		shareResults.forEach(function(shareResult: any) {
			if (shareResult.notification != null && shareResult.notification.notificationServerID != null) {
				var notificationServerID = shareResult.notification.notificationServerID.toString();
				if (notification[notificationServerID] == null) notification[notificationServerID] = [];

				notification[notificationServerID].push(shareResult);
				anyNotification = true;
			}
		});

		if (anyNotification) {
			this.sendNotifications(notification);
		}

		this.updateSharing({ eventID: this.eventid });
	}

	async addAdhocShare() {
		const shareResult = await this.addAdhocEventShare({
			EventID: this.eventid,
			FullName: this.adhocName,
			Email: this.adhocEmail
		});

		this.adhocName = "";
		this.adhocEmail = "";

		if (shareResult.notification.notificationServerID != null) {
			const notification: any = {};
			notification[shareResult.notification.notificationServerID] = [shareResult];

			await this.updateSession({ resourceId: SessionResource.DeviceServiceSession, eventId: this.eventid });
			this.sendNotifications(notification);
		}
	}

	sendNotifications(notification: any) {
		let deviceService = new DeviceService(api.getDeviceServiceAddress(), this.getEventDetails?.devicesEndpoint);
		let authSessionID = this.getSession(SessionResource.DeviceServiceSession);

		for (var notificationServerID in notification) {
			notification[notificationServerID].forEach(async (shareResult: any) => {
				let values = [
					{ Key: "FullName", Value: shareResult.fullName },
					{ Key: "EventID", Value: this.eventid },
					{ Key: "Email", Value: shareResult.notification.email },
					{ Key: "SharedBy", Value: shareResult.sharedBy },
					{ Key: "AccessCode", Value: shareResult.accessCode },
					{ Key: "ShareID", Value: shareResult.shareID },
					{ Key: "ShareLink", Value: shareResult.shareLink }
				];

				const template = await this.loadNotificationTemplate({
					TemplateName: "Event Sharing Template",
					ServerID: notificationServerID,
					Values: JSON.stringify(values)
				});

				const recipient = {
					Fullname: shareResult.fullName,
					ExternalID: shareResult.notification.externalID,
					Email: shareResult.notification.email,
					Telephone: shareResult.notification.telephone
				};

				const message = {
					Content: template.content,
					Subject: template.subject,
					Parameters: []
				};

				deviceService.sendNotification(authSessionID, parseInt(notificationServerID), [recipient], message);
			});
		}
	}

	closeSharing() {
			this.setActivity();
		this.setEventShareShown(false);
	}

	async passControl(share: any) {
		await this.eventPassControl({
			eventID: this.eventid,
			userID: share.userID
		});
		this.closeSharing();
	}

	public removeEventShare(share: any) {
		this.setActivity();
		if(share.assetID)
			this.removeShare({ eventID: this.eventid, uniqueID: share.assetID, isMobile: true });
		else
			this.removeShare({ eventID: this.eventid, uniqueID: share.eventUserID, isMobile: false });

	}
}
