import moment from "moment";
import MapAreaRegion from "./types/sv-data/maps/MapAreaRegion";

export const formatDateMixin = {
	methods: {
		dateStringWithZeroHourOffset(value: Date) {
			return value ? moment(value + "Z") : "";
		},
		convertDateToLocalDateTimeString(value: Date | string) {
			if(!value) return ""

			const isUTCFormat = value.toString().includes("Z")

			return isUTCFormat
				? this.displayDateFromISOString(value)
				: this.convertUtcDateTimeToLocalDateTimeString(value)
		},
		displayDateFromISOString(value: Date | string) {
			return value ? moment(value).format("L LTS") : "";
		},
		convertUtcDateTimeToLocalDateTimeString(value: Date | string) {
			return value ? moment(value + "Z").format("L LTS") : "";
		},
		convertUtcDateTimeToLocalTimeString(value: Date) {
			return value ? moment(value + "Z").format("LTS") : "";
		},
		fromNow(value: Date) {
			return value ? moment(value + "Z").fromNow() : "";
		},
		displayUnParkAt(value?: Date) {
			return value ? moment(value + "Z").format("L LTS") : null;
		},
		currentDateFormatted() {
			return moment().format();
		},
		endOFDayFormatted() {
			return moment()
				.endOf("day")
				.format();
		},
		weekAgoDateFormatted() {
			return moment()
				.subtract(7, "days")
				.startOf("day")
				.format();
		},
		toStartOfDay(value: string) {
			return moment(value)
				.startOf("day")
				.format();
		},
		toEndOfDay(value: string) {
			return moment(value)
				.endOf("day")
				.format();
		},
		relativeDaysDateFormatted(days: number) {
			days = days === undefined ? 7 : days;
			return moment()
				.subtract(days, "days")
				.format("YYYY-MM-DDTHH:mm:ss.000");
		},
		relativeHoursDateFormatted(hours: number) {
			hours = hours === undefined ? 24 : hours;
			return moment()
				.subtract(hours, "hours")
				.format("YYYY-MM-DDTHH:mm:ss.000");
		},
		formatDateStr(dateStr, timeOnly = false, convertFromUTC = false, includePeriod = true, dateOnly = false) {
			if (dateStr) {
				if (convertFromUTC && !dateStr.includes("Z")) {
					dateStr += "Z";
				}

				const date = new Date(dateStr);

				const monthNames = [
					"Jan",
					"Feb",
					"March",
					"April",
					"May",
					"June",
					"July",
					"Aug",
					"Sept",
					"Oct",
					"Nov",
					"Dec"
				];

				const day = date.getDate();
				const monthIndex = date.getMonth();
				const year = date.getFullYear();
				const hours = date.getHours();
				const mins = date.getMinutes();

				let formattedDate = "";

				if (!timeOnly) {
					formattedDate += day + " " + monthNames[monthIndex] + " " + year;
				}

				if (!dateOnly) {
					formattedDate +=
						" " +
						(hours < 10 ? "0" : "") +
						hours +
						":" +
						(mins < 10 ? "0" : "") +
						mins +
						" " +
						(includePeriod ? (hours < 12 ? "am" : "pm") : "");
				}
				return formattedDate.trim();
			}
		}
	}
};

export const stringMixin = {
	methods: {
		isNullOrWhitespace(input: string) {
			return !input || !input.trim();
		},
		formatString(str: string, ...args: any[]) {
			return str.replace(/{(\d+)}/g, function(match, num) {
				return typeof args[num] != "undefined" ? args[num] : match;
			});
		},
		ellipseAfterX(stringToEllipse: string, ellipseAfter: number = 50): string {
			if(!stringToEllipse || stringToEllipse.length <= ellipseAfter){
				return stringToEllipse;
			}
			return stringToEllipse.substring(0,ellipseAfter) + "...";
		}
	}
};

export const subscriptionMixins = {
	methods: {
		isSubscriptionUnlimited(limit: number) {
			const maximumUsageUpperLimit = 2000000000;
			return limit > maximumUsageUpperLimit;
		}
	}
}

export const passwordValidationMixins = {
	methods: {
		getPasswordStrength(password: string): string {
			var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
			var mediumRegex = new RegExp(
				"^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
			);

			if (strongRegex.test(password)) {
				return "Strong";
			} else if (mediumRegex.test(password)) {
				return "Medium";
			} else {
				return "Weak";
			}
		}
	}
}

export const apiFormattingMixins = {
	methods : {
		arrayToQueryString(parameterName: string, array: any[]): string {
			if(!array || (array && array.length == 0)){
				return "";
			}

			let result = "";

			array.forEach(item => {
				result = result.concat(`${parameterName}=${item}&`);
			});

			return result.slice(0, -1);
		}
	}
}

export const mapRegionMixins = {
	methods: {
		getRegionBounds(coords: google.maps.MVCArray<google.maps.LatLng> | google.maps.LatLng[]) {
			const bounds = new google.maps.LatLngBounds();

			(coords as google.maps.LatLng[]).forEach((path: google.maps.LatLng) => {
				bounds.extend(path);
			});
			return bounds;
		},
		getPolygonOptionsForRegion(region: MapAreaRegion): google.maps.PolygonOptions {
			let options = {
				strokeColor: "#2196f3",
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: "#2196f3",
				fillOpacity: 0.2
			};

			if(region){
				if(region.borderColor)
					options.strokeColor = region.borderColor;
				if(region.fillColor)
					options.fillColor = region.fillColor
			}

			return options;
		},
		get defaultPolygonOptions(): google.maps.PolygonOptions {
			return {
				strokeColor: "#2196f3",
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: "#2196f3",
				fillOpacity: 0.2
			};
		}
	}
}

export const numberFormattingMixins = {
	methods: {
		enforceWholeNumber(value:number): number {
			try {
				if (!value) {
					return null;
				}
				return Math.floor(value);
			}
			catch {
				return null;
			}
		}
	}
}
