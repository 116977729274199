
import { CameraType } from "@/store/site-monitor-cameras/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");

@Component({})
export default class SystemViewCameraIcon extends Vue {
	@Prop({required: true}) private deviceId: number;

	@SMCameras.Getter areaCameras: CameraType[];
	@SiteMonitor.Getter("getSystemViewApplianceNotification") private systemViewApplianceNotification: (deviceId: number) => string;

	private lastInteractionHealthy: boolean = null;
	private lastError: string = null;

	private mounted(): void {
		this.setCamera();
	}

	private setCamera(): void {
		var notification = this.systemViewApplianceNotification(this.deviceId)

		if (notification) {
			this.lastInteractionHealthy = false;
			this.lastError = notification;
			this
		} else {
			var camera = this.areaCameras.firstOrDefault(c => {
				return c.objectID === this.deviceId
			});

			if (camera) {
				this.lastInteractionHealthy = camera.lastInteractionHealthy;
				this.lastError = this.getLastError(camera);
			}
		}
	}

	private getLastError(camera: CameraType): string {
		if (camera.lastInteractionHealthy) {
			return null;
		}

		return camera.lastError ? camera.lastError : "*No information available";
	}

	@Watch("deviceId")
	private onDeviceIdChanged(): void {
		this.setCamera();
	}

	@Watch("areaCameras")
	private onAreaCamerasUpdated(): void {
		this.setCamera();
	}

	private get iconColor(): string {
		if (this.lastInteractionHealthy !== null) {
			return this.lastInteractionHealthy ? "healthy-item" : "unhealthy-item";
		}
		return "";
	}
}
