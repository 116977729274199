
import PerfectScrollbar from "perfect-scrollbar";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import { TaskType } from "@/store/tasks/types";
import vSelect from "vue-select";
import NotificationTask from "@/components/tasks/notification/NotificationTask.vue";
import { eventSearch } from "@/store/event-search";
import BasicTask from "@/components/tasks/task-types/BasicTask.vue";
import RaiseToCasesTask from "@/components/tasks/task-types/RaiseToCasesTask.vue";
import InputTask from "@/components/tasks/task-types/InputTask.vue";
import YesNoTask from "@/components/tasks/task-types/YesNoTask.vue";
import UrlTask from "@/components/tasks/task-types/UrlTask.vue";
import SitRepTask from "@/components/tasks/task-types/SitRepTask.vue";
import CCureTask from "@/components/tasks/task-types/CCureTask.vue";
import AuditTask from "@/components/tasks/task-types/AuditTask.vue";
import ChoicesTask from "@/components/tasks/choices/ChoicesTask.vue";
import CallContactTask from "@/components/tasks/call-contact/CallContactTask.vue";
import DynamicDataList from "@/components/tasks/task-types/DynamicDataList.vue";
import ControlTask from "@/components/tasks/control/ControlTask.vue";
import EventOutcomeTask from "@/components/tasks/task-types/EventOutcomeTask.vue";
import { stringMixin } from "@/mixins";
import { FeaturesList } from "@/store/types";
import SVButton from "@/components/form/SVButton.vue";

import { get } from "lodash";

const Tasks = namespace("tasks");
const SiteMonitor = namespace("siteMonitor");

const { isNullOrWhitespace } = stringMixin.methods;

@Component({
	components: {
		"vue-select": vSelect,
		"notification-task": NotificationTask,
		"basic-task": BasicTask,
		"input-task": InputTask,
		"yes-no-task": YesNoTask,
		"url-task": UrlTask,
		"sitrep-task": SitRepTask,
		"ccure-task": CCureTask,
		"audit-task": AuditTask,
		"choice-task": ChoicesTask,
		"call-contact-task": CallContactTask,
		"dynamic-data-list": DynamicDataList,
		"control-task": ControlTask,
		SVButton: SVButton,
        "raise-to-cases-task": RaiseToCasesTask,
		"event-outcome-task": EventOutcomeTask
	}
	// emits events:
	// - @assign-task-to-user
	// - @complete-task
})
export default class CompleteTaskLine extends Vue {
	@Tasks.Getter("getEnabledActionTypes") enabledActionTypes: TaskType[];
	@SiteMonitor.Getter("getIsController") isController: boolean;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Prop() public availableGuards!: any[];

	@Prop({
		// @todo type
		default: () => []
	})
	public eventShares!: any[];

	@Prop({
		default: () => ({
			title: {
				type: String,
				required: true
			},
			groupTitle: {
				type: String,
				required: true
			},
			result: {
				type: String,
				required: true
			},
			taskTypeID: {
				type: Number,
				required: false
			},
			taskData: {
				type: Object,
				required: false
			}
		})
	})
	public task!: any;

	public taskToRender: string = "basic-task";
	public taskResult: any = "";

	public get unremovedEventShares() {
		return this.eventShares.filter(eventShare => !eventShare.removed);
	}

	public get eventGuards() {
		return this.availableGuards;
	}

	public get taskHasComponent() {
		return this.enabledActionTypes.some(
			tt => tt.taskTypeID === this.task.taskTypeID && !isNullOrWhitespace(tt.componentFile)
		);
	}

	@Watch("enabledActionTypes", { deep: true })
	private actionTypeWatcher(){
		this.loadTaskType();
	}

	private loadTaskType(){
		let task = this.enabledActionTypes.find(
			(taskType: any) => taskType.taskTypeID === this.task.taskTypeID && taskType.componentFile !== null
		);

		// Avoid property undefined errors if a task was not returned.
		if (task !== undefined) {
			this.taskToRender = task.componentFile;
		}
	}

	public mounted() {
		this.loadTaskType();
	}

	public onAssignTaskToUser(eventTaskID: number, eventUserID: number | null) {
		this.$emit("assign-task-to-user", eventTaskID, eventUserID);
	}

	public onCompleteTask($event: any) {
		const completedTask = {
			...this.task,
			completed: true
		};
		switch (this.task.taskTypeID) {
			case 6: //Notification type
				this.$emit("complete-task", completedTask);
				break;
			case 7: //Choices
				this.$emit("complete-task", completedTask);
				break;
			case 8: //Call Contact
				this.$emit("complete-task", completedTask);
				break;
			case 13: //Control
				this.$emit("complete-task", completedTask);
				break;
			default:
				// safeguard against unknown task types
				break;
		}
	}

	// Pass any emited events back up the chain.
	public passTask(completedTask: object) {
		this.$emit("complete-task", completedTask);
	}

	public onReOpenTask() {
		// send "/Complete" request with {complete: false}
		this.$emit("complete-task", {
			...this.task,
			completed: false
		});
	}

	private get massNotificationEnabled() {
		return get(this.featuresList, ["Actions", "MassNotification"], false);
	}

	private get choicesEnabled() {
		return get(this.featuresList, ["Actions", "Choices"], false);
	}

	private get callContactsEnabled() {
		return get(this.featuresList, ["Actions", "CallContacts"], false);
	}

	private get controlsEnabled() {
		return get(this.featuresList, ["Actions", "Control"], false);
	}

	// Pass any emitted "completed-task-now-close-event" events back up the chain.
	public passTaskEventClosure(completedTask: object) {
		this.$emit("completed-task-now-close-event", completedTask);
	}
}
