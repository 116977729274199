import { MutationTree } from "vuex";
import { EditingRule, RulesEngineState, RulesWithPagination, RuleActionPlan, RuleServerType, RuleServer, ServersForServerType, ServerTypeEventsForServerType, RuleGenericDropdownItem } from "./types";
import { getDefaultState } from "./state";
import Vue from "vue";

export const mutations: MutationTree<RulesEngineState> = {
	setRules(state: RulesEngineState, data: RulesWithPagination) {
		const defaultData: RulesWithPagination = {
			pageNumber: 0,
			pageSize: 0,
			totalRecords: 0,
			data: []
		}

		state.rules = data ? data : defaultData;
	},
	updateEditingRule(state: RulesEngineState, editingRule: EditingRule) {
		state.editingRule = editingRule;
	},
	removeRule(state: RulesEngineState, ruleId: number) {
		state.rules.data = state.rules.data.filter(rule => rule.ruleId !== ruleId);
	},
	setRuleAlarmsForGroupId(state: RulesEngineState, payload: any) {
		let dropdownOptions: RuleGenericDropdownItem[] = [];
		if (payload.ruleAlarms) {
			for (let i = 0; i < payload.ruleAlarms.length; i++){
				let alarmItem: RuleGenericDropdownItem = {
					id: payload.ruleAlarms[i].responseId,
					title: payload.ruleAlarms[i].title
				}

				dropdownOptions.push(alarmItem);
			}
		}
		state.alarmsForGroupId.set(payload.groupId, dropdownOptions);
	},
	setRuleServerTypes(state: RulesEngineState, ruleServerTypes: RuleServerType[]) {
		state.serverTypes = ruleServerTypes ? ruleServerTypes : [];
	},
	setRuleServersForServerType(state: RulesEngineState, payload: ServersForServerType) {
		const index = state.serversForServerType.findIndex((s: ServersForServerType) => s.serverTypeId === payload.serverTypeId);

		if (index !== -1) {
			// Update if it exists.
			Vue.set(state.serversForServerType, index, { serverTypeId: payload.serverTypeId, servers: payload.servers })
		} else {
			// Add if it does not
			state.serversForServerType.push({ serverTypeId: payload.serverTypeId, servers: payload.servers });
		}
	},
	setRuleServerTypeEventsForServerType(state: RulesEngineState, payload: ServerTypeEventsForServerType) {
		const index = state.serverTypeEventsForServerType.findIndex((s: ServerTypeEventsForServerType) => s.serverTypeId === payload.serverTypeId);

		if (index !== -1) {
			// Update if it exists.
			Vue.set(state.serverTypeEventsForServerType, index, { serverTypeId: payload.serverTypeId, serverTypeEvents: payload.serverTypeEvents })
		} else {
			// Add if it does not
			state.serverTypeEventsForServerType.push({ serverTypeId: payload.serverTypeId, serverTypeEvents: payload.serverTypeEvents });
		}
	},
	setRuleActionPlans(state:RulesEngineState, ruleActionPlans: RuleActionPlan[]) {
		state.actionsPlans = ruleActionPlans ? ruleActionPlans : [];
	},
	resetState(state: RulesEngineState) {
		Object.assign(state, getDefaultState());
	}
}
