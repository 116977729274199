
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AudioDevice } from "@/types/audio/audio";

// Audio Components
import Receive from "./Receive.vue";
import Transmit from "./Transmit.vue";
import { EventDetails } from '@/store/site-monitor/types';
import { SessionResource } from '@/store/sessions/types';

// constants
import AudioLists from './AudioList.vue';
import api from '@/services/api.service';

const deviceServiceRegex = /(^\w+:|^)\/\//;

// vuex modules
const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Session = namespace("sessions");

interface AudioDeviceNearby extends AudioDevice {
    nearby: boolean;
}

@Component({
    components: {
        "receive": Receive,
        "transmit": Transmit,
        "audio-list": AudioLists
    }
})
export default class AudioControl extends Vue {
    @Session.Getter 
    private getSession: (sessionId: number | string) => string;

    @Session.Action 
    private updateSession: ({ eventId, resourceId }: { eventId?: number, resourceId: number }) => Promise<void>;

    @SMCameras.State("deviceControllerAudioDevices") 
    private nearbyAudioDevices!: any[];

    @SiteMonitor.Getter("getEventDetails") 
    private eventDetails: EventDetails;


    private selectedAudioDevice: AudioDevice = null;
    private audioDevices: AudioDeviceNearby[] = [];
    private filter: string = "";
    private auth: string = "";

    private async mounted() {
        this.audioDevices = [];
        await this.updateSession({ resourceId: SessionResource.DeviceServiceSession });
        this.auth = this.getSession(SessionResource.DeviceServiceSession);
        await this.fetchNearbyAudio();
        await this.fetchGroupAudio(this.groupId, null);
    }

    /**
     * @summary method which will return the DeviceServiceEndpoint
     * this method also stricts out the extra bits which could exist like http/s etc.
     */
    private get getDeviceServiceEndpoint(): string {
		if(this.eventDetails.devicesEndpoint){
			return this.eventDetails.devicesEndpoint.replace(deviceServiceRegex, '');
		}
        return api.getDeviceServiceAddress().replace(deviceServiceRegex, '');
    }

    /**
     * @summary computed property which will return if at least 1 of the audio types are chosen
     */
    private get loadAudioControls() {
        return (this.selectedAudioDevice) ? true : false;
    }

    /**
     * @summary depending on the components rendered, it will change the column size.
     */
    private get displayControlsWidth(): number {
        return (this.selectedAudioDevice && this.selectedAudioDevice.canReceive && this.selectedAudioDevice.canTransmit) ? 6 : 12;
    }

    // ---------------------------------------------------------------------------
    private get groupId(): number {
        return (this.eventDetails && this.eventDetails.groupID) ? this.eventDetails.groupID : null;
    }
    
    private get eventId(): number {
        return this.eventDetails ? this.eventDetails.eventID : null;
    }

    @Watch("nearbyAudioDevices", { deep: true })
    private async fetchNearbyAudio(): Promise<void> {
        //filter out any nearby existing audio devices
        this.audioDevices = this.audioDevices.filter(ad => !ad.nearby);
        var deviceIds = this.nearbyAudioDevices ? this.nearbyAudioDevices.map(ad => ad.objectId) : [];
        if (!deviceIds.length) 
            return;

        const audioDeviceInfo  = await api.fetchAudioDevicesByIds(deviceIds);

        if (!audioDeviceInfo) 
            return;

        this.audioDevices = this.audioDevices.filter(ad => !ad.nearby);
        this.audioDevices = this.audioDevices.concat(audioDeviceInfo.map(d => {
            return { ...d, nearby: true, }
        }));
    }

    @Watch("groupId", { deep: true })
    private async fetchGroupAudio(newGroupId: number | null, oldGroupId: number | null): Promise<void> {
        if (newGroupId == oldGroupId)
            return;

        //filter out any existing group audio devices
        this.audioDevices = this.audioDevices.filter(ad => ad.nearby);

        if(!newGroupId)
            return;

        let audioDeviceInfo = await api.fetchAudioDevicesByGroupId(newGroupId);

        if (!audioDeviceInfo) 
            return;

        //filter out any devices that already exist in the nearby
        this.audioDevices = this.audioDevices.concat(audioDeviceInfo.map(d => {
            return { ...d, nearby: false, }
        }));

    }

    private get filteredDevices(): AudioDeviceNearby[] {
        if (this.filter) {
            return this.audioDevices.filter(ad => ad.title.toLowerCase().includes(this.filter.toLowerCase()));
        } else {
            return this.audioDevices;
        }
    }
    
    private get nearbyDevices(): AudioDevice[] {
        return this.filteredDevices.filter(ad => ad.nearby);
    }

    private get groupDevices(): AudioDevice[] {
        return this.filteredDevices.filter(ad => !ad.nearby && !this.nearbyDevices.map(nd => nd.deviceId).includes(ad.deviceId));
    }

    private get canReceiveAudio(): boolean {
        return this.loadAudioControls && this.selectedAudioDevice.canReceive;
    }

    private get canTransmitAudio(): boolean {
        return this.loadAudioControls && this.selectedAudioDevice.canTransmit;
    }
}
