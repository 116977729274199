import { MutationTree } from "vuex";
import { CaseManagementState, CaseType } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<CaseManagementState> = {
	setCaseTypes(state, caseTypes: CaseType[]) {
		state.caseTypes = caseTypes;
	},
	setCaseTypesForUser(state, caseTypes: CaseType[]) {
		state.caseTypesForUser = caseTypes;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
}