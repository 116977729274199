
import { Component, Vue } from "vue-property-decorator";
import NavHeader from "@/components/NavHeader.vue";
import { namespace, Getter } from "vuex-class";
import { UserPermissions } from "@/store/types";
import { TemplateGridRowModel, TemplateDetailsModel } from "@/store/mobile-raise-templates/types";
import TemplateModal from "@/components/mobile-raise-templates/TemplateModal.vue";
import { TemplateModalMode } from "@/components/mobile-raise-templates/TemplateModalMode";

const UserManagement = namespace("userManagement");
const MobileRaiseTemplates = namespace("mobileRaiseTemplates");

const titleListFormatter = (titleList: string[]) => titleList.join();

const fields = [
	{ label: "Template Name", key: "title", sortable: true },
	{ label: "Quick Select Raises", key: "quickSelectRaises", sortable: true },
	{ label: "User Groups", key: "userGroups", formatter: titleListFormatter },
	{ key: "actions", label: "Actions", sortable: false, class: ["text-right", "pr-4"] }
];

@Component({
	components: {
		"nav-header": NavHeader,
		TemplateModal
	}
})
export default class MobileRaiseSetup extends Vue {
	@Getter getPermissions: UserPermissions;
	@MobileRaiseTemplates.Getter getTemplateGridRows: TemplateGridRowModel[];
	@MobileRaiseTemplates.Action loadTemplates: () => Promise<void>;
	@MobileRaiseTemplates.Action loadMobileRaises: () => Promise<void>;
	@MobileRaiseTemplates.Action loadIconSets: () => Promise<void>;
	@MobileRaiseTemplates.Action addMobileRaiseTemplate: (model: TemplateDetailsModel) => Promise<void>;
	@MobileRaiseTemplates.Action updateMobileRaiseTemplate: (model: TemplateDetailsModel) => Promise<void>;
	@MobileRaiseTemplates.Action deleteMobileRaiseTemplate: (templateId: number) => Promise<void>;
	@UserManagement.Action loadUserGroups: () => Promise<void>;

	private searchTerm: string = "";
	private isTemplateModalVisible: boolean = false;
	private templateModalMode: TemplateModalMode = TemplateModalMode.view;
	private templateId: number | null = null;
	private sortBy: string = "title";
	private sortDesc: boolean = false;

	created() {
		this.loadTemplates();
		this.loadMobileRaises();
		this.loadIconSets();
		this.loadUserGroups();
	}

	private get isReadlonly() {
		return !this.getPermissions.canEditMobileRaise;
	}

	private get fields() {
		return fields;
	}

	private openViewModal(row: any) {
		this.templateModalMode = TemplateModalMode.view;
		this.templateId = row.item.mobileRaiseTemplateID;
		this.isTemplateModalVisible = true;
	}

	private openEditModal(row: any) {
		this.templateModalMode = TemplateModalMode.edit;
		this.templateId = row.item.mobileRaiseTemplateID;
		this.isTemplateModalVisible = true;
	}

	private openNewModal() {
		this.templateModalMode = TemplateModalMode.add;
		this.templateId = null;
		this.isTemplateModalVisible = true;
	}

	private onTemplateModalClose() {
		this.isTemplateModalVisible = false;
	}

	private onTemplateModalAdd(model: TemplateDetailsModel) {
		this.isTemplateModalVisible = false;
		this.addMobileRaiseTemplate(model);
	}

	private onTemplateModalSave(model: TemplateDetailsModel) {
		this.isTemplateModalVisible = false;
		this.updateMobileRaiseTemplate(model);
	}

	private onTemplateModalDelete(templateId: number) {
		this.isTemplateModalVisible = false;
		this.deleteMobileRaiseTemplate(templateId);
	}
}
