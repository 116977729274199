import { MutationTree } from "vuex";
import { ManualRaiseState } from "./types";
import { getDefaultState } from "./index";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";

export const mutations: MutationTree<ManualRaiseState> = {
	setManualRaiseShown: (state, visible) => {
		state.manualRaiseShown = visible;
	},
	setOnCompleteHandler: (state, handler) => {
		state.onCompleteHandler = handler;
	},
	setAreaMapBounds(state, bounds: any) {
		state.areaMapBounds = bounds;
	},
	setAlarmMarkerPosition(state, position) {
		state.alarmMarkerPosition = position;
	},
	setSelectedGroup(state, areaNode: AreaNode) {
		state.selectedGroup = areaNode;
	},
	resetState: state => {
		Object.assign(state, getDefaultState());
	}
};
