
	import { Component, Vue, Watch, Prop } from "vue-property-decorator";
	import { namespace, Getter, Mutation } from "vuex-class";
	import vSelect3 from "vselect3";
	import { EventDetails } from '@/store/site-monitor/types';
	import { EventOutcome, EventOutcomeResponse } from '@/types/EventOutcome';
	import api from "@/services/api.service";
	import apiKeyApi from "@/services/api.apiKey.service";
    import settingsApi from "@/services/api.settings.service";
	import CloseEventResultDTO from "@/types/sv-data/events/CloseEventResultDTO";
	import CloseMultipleEventsDTO from "@/types/sv-data/events/CloseMultipleEventsDTO";
	import ActionState from "@/types/sv-data/events/ActionState";
	import { UserPermissions } from "@/store/types";

	import NestedSelect from "@/components/utilities/NestedSelect.vue"

	const SiteMonitor = namespace("siteMonitor");
	const Eventqueue = namespace("eventqueue");
	const Tasks = namespace("tasks");

	@Component({
		components: {
			"v-select-3": vSelect3,
			"nested-select": NestedSelect
		}
	})
	export default class EventClose extends Vue {
		$refs!: {
			eventCloseModal: any;
		};

		@SiteMonitor.Getter getEventShare!: any[];
		@SiteMonitor.Getter("getEventCloseShown") eventCloseShown!: boolean;
		@SiteMonitor.Getter getIsUploadingFile: boolean;
		@SiteMonitor.Getter getEventDetails: EventDetails;
		@SiteMonitor.Getter("eventOutcomesFlatList") private outcomesFlatList: EventOutcome[];

		@SiteMonitor.Mutation setEventCloseShown: any;
		@SiteMonitor.Mutation setEventDetails: any;
		@SiteMonitor.Mutation resetEventRecords: () => void;

		@SiteMonitor.Action("loadOutcomesForEvent") loadOutcomes: (eventId: number) => Promise<EventOutcomeResponse>;
		@SiteMonitor.Action loadAllOutcomesForAlarms: () => Promise<EventOutcomeResponse>;
		@SiteMonitor.Action("closeEvent") closeEvent: (data: any) => Promise<CloseEventResultDTO>
		@SiteMonitor.Action("closeMultipleEvents") closeEvents: (data: CloseMultipleEventsDTO) => Promise<CloseEventResultDTO>;
		@SiteMonitor.Action private fetchEventDetails: (eventId: number) => Promise<EventDetails>;

		@Getter getFeature: (featuresList: string[]) => boolean;
		@Getter getPermissions: UserPermissions;

		@Tasks.Getter hasEventOutcomeTask: boolean;

		@Eventqueue.Getter("getSelectedAlarms") selectedAlarms: number[];
		@Eventqueue.Getter("getClosingAction") closingAction: ActionState;
		@Eventqueue.Mutation removeSelectedAlarm: (data: number) => void;
		@Eventqueue.Mutation setRefreshQueue: (refresh:boolean) => void;
		@Eventqueue.Mutation setClosingAction: (action:ActionState) => void;

		@Getter getUserTenantGroupId: number;
		@Getter getUserSuiteTenantId: string;

		@Mutation setReloadEventQueue: (boolean) => void;

		@Prop(Number) eventid?: number;
		@Prop({default: true}) singleEvent: boolean;

		@Prop({type: Boolean, required: false, default: false})
		private updateEventOutcome: boolean;

		public eventRecords: any[] = [];
		public errors: any = {
			http: {
				getOutcomes: null,
				closeEvent: null
			},
			form: {
				isValid: null,
				outcomeChoice: {
					message: null,
					isValid: null
				},
				outcomeNote: {
					message: null,
					isValid: null
				}
			}
		};

		public lastRecord: number = 0;

		// tree of all possible outcomes, of unpredictable depth, as received by API
		public outcomeTree: any[] = [];
		// to allow for infinite depth in outcomes tree above, this array maintains currrent
		// breadcrumbs path of selections made by the user, and is used as a support datastructre
		public outcomeSelectionPath: EventOutcome[] = [];
		// is iterated by template to display
		public outcomeSelectionControls: any[] = [];

		public clearGuards: boolean = false;
		public outcomeNote: string = "";
		public eventCloseError: string | null = null;

		public get otherUserCount() {
			const SharedUsers = this.getEventShare.filter((user: any) => user.isActive)
				.length;
			return SharedUsers - 1;
		}

		public get currentSelectedOutcome() {
			const lastOutcome = this.outcomeSelectionPath[
				this.outcomeSelectionPath.length - 1
			];
			if (lastOutcome == null) return "";

			return lastOutcome.title;
		}

		public get isInvalidCategoryWarning() : boolean {
			return this.eventOutcome == null && this.updateEventOutcome;
		}

		/*
		* Getter to validate if the current event outcome note meets the required criteria.
		*/
		private get isOutcomeNoteValid(): boolean {
			return !this.getEventDetails?.eventNoteRequired ||
				(this.getEventDetails?.eventNoteRequired && this.outcomeNote.length > 0) ||
				(!!this.updateEventOutcome && this.outcomeNote.length > 0);
		}

		private get eventOutcome (): EventOutcome | null {
			return this.outcomeSelectionPath.length === 0
				? null
				: this.outcomeSelectionPath[this.outcomeSelectionPath.length - 1]
		}

		public created(): void {
			this.setEventCloseShown(false);
		}

		public resetData(): void {
			this.eventCloseError = null;
			this.outcomeNote = "";
			this.setClosingAction(ActionState.Undefined);
		}

		@Watch("eventCloseShown")
		public async onEventCloseShownChanged(value: boolean, oldValue: boolean): Promise<void> {
			if (value) {
				// Event Close modal load
				if (this.eventid) {
					await this.fetchEventDetails(this.eventid);
				}
				await this.loadOutcomesForEvent();

				this.resetData();

				if (this.useEventOutcomeTask || this.getEventDetails?.eventOutcomeNote) {
					this.outcomeNote = this.getEventDetails?.eventOutcomeNote ?? "";
				}

				this.$refs.eventCloseModal.show();

				// Wait for the next tick - the modal should be visible then
				await this.$nextTick();

				if(!this.updateEventOutcome) {
					const outcomeSelect0 = (this.$refs["outcomeSelect0"] as any)?.[0];
					setTimeout(() => {
						outcomeSelect0?.$el.querySelector("input").focus();
					}, 0.5 * this.$config.ANIMATION_DURATION);
				}
				else
				{
					// Sets the outcome not when updateEventOutcome feature is enabled
					this.outcomeNote = this.getEventDetails?.eventOutcomeNote ?? "";
				}

				if (this.isVirtualAssistSummaryEnabled){
					// VO widget load
					const aiDataApiKey = await apiKeyApi.getApiKey("VirtualOperator");
					const aiDataUrl = await settingsApi.getAiDataUrl();
					const tenantId = this.getUserTenantGroupId;

					window.executivesummarywidget.mount(
						"#executiveSummaryWidget",
						aiDataApiKey,
						tenantId,
						this.eventid,
						aiDataUrl,
						this.onSummaryGenerated);
				}
			} else {
				this.$refs.eventCloseModal.hide();
			}
		}

		private onSummaryGenerated(summary: string): void
		{
			this.outcomeNote = summary;
		}

		public mounted(): void {}

		public async loadOutcomesForEvent(): Promise<void> {
			let response: EventOutcomeResponse = null;
			if (this.singleEvent) {
				response = await this.loadOutcomes(this.eventid);
			}
			else {
				response = await this.loadAllOutcomesForAlarms();
			}

			this.outcomeTree = response.eventOutcomeTree

			if (this.updateEventOutcome || this.getEventDetails?.eventOutcomeID > 0) {
				this.outcomeSelectionPath = [];
				this.buildOutcomePath();
			}
		}

		public async onConfirmClicked(): Promise<void> {
			if (!this.validate()) {
				return;
			}

			var triggerReload: boolean = false;
			if (this.errors.form.isValid) {
				this.eventCloseError = null;
				let closureResult: CloseEventResultDTO = null;
				this.setClosingAction(ActionState.InProcess);

				if (this.updateEventOutcome) {
					await api.updateClosedEventOutcome({
						eventId: this.eventid,
						eventOutcomeId: this.eventOutcome?.eventOutcomeID,
						note: this.outcomeNote
					});
					this.$emit("updatedEventOutcome");
					this.outcomeNote = "";
					this.setEventCloseShown(false);
				} else if (this.singleEvent) {
					closureResult = await this.closeSingleEvent();

					if (this.isReloadEventQueueEnabled && this.getEventDetails?.groupID && await api.getReloadEventQueuePref(this.getEventDetails?.groupID))
					{
						triggerReload = true;
					}
				} else {
					closureResult = await this.closeMultipleEvents();
				}

				// if this is a bulk close
				// set the flag in the state to trigger the event queue refresh
				if (!this.singleEvent) {
					this.setRefreshQueue(true);
				}

				if(closureResult != null){
					if (closureResult.success === true) {
						this.setClosingAction(ActionState.Success);

						this.setEventDetails(null);
						this.resetEventRecords();

						this.setEventCloseShown(false);

						if (this.singleEvent) {
							this.setReloadEventQueue(triggerReload);
							this.$router.push({ path: "/event-queue" });
						}
					} else {
						this.eventCloseError = closureResult.error;

						this.setClosingAction(ActionState.Undefined);
					}
				}
			}
		}

    	private async closeMultipleEvents(): Promise<CloseEventResultDTO> {
			const payload: CloseMultipleEventsDTO = {
				eventIds: this.selectedAlarms,
				eventOutcomeId: this.eventOutcome!.eventOutcomeID,
				outcomeNote: this.outcomeNote
			};

			return this.closeEvents(payload);
    	}

		private async closeSingleEvent(): Promise<CloseEventResultDTO> {
			const payload = {
						eventId: this.eventid,
						eventOutcomeId: this.eventOutcome!.eventOutcomeID,
						outcomeNote: this.outcomeNote,
						clearGuards: true
					};

			return this.closeEvent(payload);
    	}

		public cancelCloseEvent(): void {
			this.setEventCloseShown(false);
		}

		// @todo - ensure that in future we use a validation library (vuelidate), makes doing this stuff
		// a thousand times simpler!!
		private validate() {
			this.errors.form.isValid = true;

			if (!this.eventOutcome)
			{
				return false;
			}

			// selected outcome is leaf node of possible outcomes tree
			if (this.eventOutcome.childOutcomes && this.eventOutcome.childOutcomes.length > 0) {
				this.errors.form["outcomeChoice"].message =
					"Please select a specific outcome";
				this.errors.form["outcomeChoice"].isValid = false;
				this.errors.form.isValid = false;
			} else {
				this.errors.form["outcomeChoice"].message = null;
				this.errors.form["outcomeChoice"].isValid = null;
			}

			// outcome note less than 5000 symbols, optional
			if (this.outcomeNote.length > 5000) {
				this.errors.form["outcomeNote"].message =
					"Note too long - max length of 5000 chars allowed";
				this.errors.form["outcomeNote"].isValid = false;
				this.errors.form.isValid = false;
			} else if ((this.updateEventOutcome || !this.singleEvent) && this.outcomeNote.length == 0) {
				this.errors.form["outcomeNote"].message =
					"Note is required";
				this.errors.form["outcomeNote"].isValid = false;
				this.errors.form.isValid = false;
			}
			else {
				this.errors.form["outcomeNote"].message = null;
				this.errors.form["outcomeNote"].isValid = null;
			}

			return this.errors.form.isValid;
		}

		private isLeafNode(choice: EventOutcome) {
			return choice.childOutcomes !== undefined &&
				choice.childOutcomes.length === 0
				? true
				: false;
		}

		private getParent(nodeOutcome: any, findOutcome: any) {
			if (nodeOutcome.childOutcomes != null) {
				let foundOutcome = null;
				nodeOutcome.childOutcomes.forEach((outcome: any) => {
					if (outcome.eventOutcomeID == findOutcome.eventOutcomeID) {
						foundOutcome = nodeOutcome;
					}
				});

				if (foundOutcome != null) {
					return foundOutcome;
				}

				nodeOutcome.childOutcomes.forEach((outcome: any) => {
					let recurseOutcome = this.getParent(outcome, findOutcome);
					if (recurseOutcome != null) {
						foundOutcome = recurseOutcome;
					}
				});

				if (foundOutcome != null) {
					return foundOutcome;
				}
			}

			return null;
		}

		private buildOutcomePath(): void {
			if (this.getEventDetails.eventOutcomeID) {
				var IdToFind = this.getEventDetails.eventOutcomeID;
				for(var outcome of this.outcomeTree) {
					// If we have the path setup
					if (this.checkOutcomeInPath(outcome, IdToFind)) {
						this.outcomeSelectionPath = [outcome, ...this.outcomeSelectionPath];
						// Finish
						return;
					}
				}
			}

			this.outcomeSelectionPath = [];
		}

		private checkOutcomeInPath(outcome: any, idToFind: number): boolean {
			if (outcome.eventOutcomeID == idToFind) {
				return true;
			}

			if (!!outcome.childOutcomes && outcome.childOutcomes.length > 0) {
				for(var child of outcome.childOutcomes) {
					if (this.checkOutcomeInPath(child, idToFind)) {
						this.outcomeSelectionPath = [child, ...this.outcomeSelectionPath];
						return true;
					}
				}
			}

			return false;
		}

		private get useEventOutcomeTask(): boolean {
			return !this.updateEventOutcome && this.getFeature(["Actions", "EventOutcome"]) && this.hasEventOutcomeTask;
		}

		private get modalTitle(): string {
			var closeText = this.singleEvent ? "Close" : "End Events";
			return this.updateEventOutcome ? "Change Category" : closeText;
		}

		private get confirmBtnText(): string {
			return this.singleEvent ? "OK" : "Confirm";
		}

		private get isEventOutcomeValid(): boolean {
			return this.validate() && this.isOutcomeNoteValid;
		}

		private get isClosingInProcess(): boolean {
			return this.closingAction == ActionState.InProcess;
		}

		private get isClosingSuccess(): boolean {
			return this.closingAction == ActionState.Success;
		}

		private get isClosingFail(): boolean {
			return this.closingAction == ActionState.Fail;
		}

		private get isVirtualAssistSummaryEnabled(): boolean {
			return this.getFeature(["AI", "VirtualAssist"]) && this.getPermissions.canUseVirtualAssistSummary && this.singleEvent;
		}

		private get enforceModalButtonDismissal(): boolean {
			return this.getFeature(["TenantSettings", "EnforceDismissingModalByButtons"]);
		}

		private get isReloadEventQueueEnabled(): boolean {
			return this.getFeature(["Temp_EventReload"]);
		}
	}
