import { render, staticRenderFns } from "./ColorModalItem.vue?vue&type=template&id=3e214b1b&scoped=true"
import script from "./ColorModalItem.vue?vue&type=script&lang=ts"
export * from "./ColorModalItem.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e214b1b",
  null
  
)

export default component.exports