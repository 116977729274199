
// Third party libs
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { namespace, Getter, Mutation, State, Action } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect3 from "vselect3";
import { get } from "lodash";
import ElevationSelect from "@/components/form/ElevationSelect.vue"

// Map Utils
import {
	getEnhancedMapping,
	IEnhancedMapping,
	IMap,
	ILayer,
	ILatLng,
	ILatLngBounds,
	convertStringToLatLng,
	stringToBounds,
	IMarker,
	MarkerIcons
} from "@sureview/v2-mapping-saas";
import GPSService, { GeoCodeLocation } from "@/services/gps.service";
import { getMapStyle } from "@/scripts/mapping/mapStyle";

// Components
import EnhancedMap from "@/components/EnhancedMap.vue";
import MapLayerSetup from "@/components/map-setup/MapLayerSetup.vue";
import MapLayerItemSetup from "@/components/map-setup/MapItemSetup.vue";
import NavHeader from "@/components/NavHeader.vue";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";

// Types
import { FeaturesList, UserPermissions } from "@/store/types";
import {
	MapLayer,
	MapLayerItem,
	MapLayerItemType,
	MapData,
	MapBounds,
	MapLayerItemTypeIds
} from "@/store/map-layers/types";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import { Group } from "@/store/areas/types";

import api from "@/services/api.service";

// Vuex Store Modules.
const MapLayers = namespace("mapLayers");

// Constants
const geoCoder = new GPSService();
const addressNotFoundErrorDuration: number = 6000;
const defaultLocation = { lat: 0, lng: 0 };
const roofTopZoomLevel: number = 20;
const areaZoomLevel: number = 18;
const rectangleOptions: google.maps.RectangleOptions = {
	editable: true,
	draggable: true,
	strokeColor: "#2196F3",
	fillOpacity: 0
};
@Component({
	components: {
		"area-tree-select": AreaTreeSelect,
		"map-layer-setup": MapLayerSetup,
		"map-setup": EnhancedMap,
		"map-setup-popup": MapLayerItemSetup,
		"nav-header": NavHeader,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"v-select-3": vSelect3,
		"elevation-select": ElevationSelect
	}
})
export default class MapSetup extends Vue {
	@Ref() mappingContainer: HTMLElement;

	@Action startApiHeartbeat: () => void;
	@State private featuresList: FeaturesList;
	@Getter private getHideLabels: boolean;
	@Getter private getMapType: string;
	@Getter("getMapKey") private mapKey: string;
	@Getter private getPermissions: UserPermissions;
	@Getter private getUserGroupId: number;
	@Mutation private setMapType: (mapType: string) => void;

	@MapLayers.State("mapItemIcons") private mapIcons!: MarkerIcons;
	@MapLayers.Getter("getMapLayerItemTypes") private mapLayerItemTypes: MapLayerItemType[];
	@MapLayers.Action private loadMapLayersItemTypes: () => Promise<void>;

	private enhancedMapping: IEnhancedMapping | null = null;
	private drawingManager: google.maps.drawing.DrawingManager = null;

	// Setup Context
	private mapDataInBounds: MapData = {
		items: [],
		layers: [],
		elevations: [],
		areas: []
	};
	private selectedArea: AreaNode = null;
	private selectedAreaDetails: Group = null;
	private previouslySelectedAreas: Group[] = [];
	private selectedItemType: MapLayerItemType = null;
	private selectedLayer: MapLayer = null;
	private selectedLayerFilters: MapLayer[] = [];
	private isAddingLayer: boolean = false;
	private isEditingLayer: boolean = false;
	private showMapLayerInfoBox: boolean = false;
	private isPlotting: boolean = false;
	private relocatedMapLayerItems: MapLayerItem[] = [];
	private isLoading: boolean = true;
	private isLoadingArea: boolean = true;
	private isSavingMapLayerItems: boolean = false;
	private isNoAddressErrorShown: boolean = false;
	private selectedMapLayerDimensions = null;
	private selectedFloor: number = 0;
	private groupGeoCodeLocations: Map<number, GeoCodeLocation> = new Map<number, GeoCodeLocation>();
	private selectedMapLayerItemTypes: MapLayerItemType[] = [];
	private hiddenMapLayerIds: number[] = [];
	private showFilters: boolean = false;
	private viewingGroupId: number = null;

	// Map Context
	private mapInstance: IMap | null = null;
	private mapOverlays: Map<number, ILayer> = new Map<number, ILayer>();
	private mapMarkers: Map<number, IMarker> = new Map<number, IMarker>();
	private divOverlayContainer: HTMLElement = null;
	private zoomLevel: number = 3;
	private infoWindow: google.maps.InfoWindow = null;
	private newMarker: IMarker = null;
	private previewLayerOverlay: ILayer = null;
	private rotationContainer: HTMLElement = null;
	private rotationContainerCenter: any = null;
	private rotationHandle: HTMLElement = null;
	private mapLayerPlaceholderRectangle: google.maps.Rectangle = null;

	private areaTreeSelectIsOpen: boolean = false;

	public async mounted() {
		await this.onMount();
	}

	private async onMount() {
		if (!this.getPermissions.canEditSiteSetup) {
			return;
		}

		await this.loadMapLayersItemTypes();
		this.startApiHeartbeat();
		await this.mountMap();

		this.viewingGroupId = this.getUserGroupId;

		let latLongForSelectedArea = await this.getLatLongForGroup(this.viewingGroupId);
		if (latLongForSelectedArea) {
			this.goToLocation(roofTopZoomLevel, latLongForSelectedArea);
		} else {
			await this.refreshMapData();
		}
	}

	private async mountMap() {
		this.enhancedMapping = await getEnhancedMapping("google", this.mapKey);
		this.mapInstance = this.enhancedMapping.createMap(
			this.mappingContainer,
			defaultLocation,
			this.zoomLevel,
			false,
			this.getMapType,
			getMapStyle(this.getMapType, this.getHideLabels)
		);

		// setup click handle to run when the map type changes
		this.mapInstance!.registerEvent("onMapTypeChanged", (mapType: string) => {
			this.setMapType(mapType);

			this.mapInstance!.map.setOptions({
				styles: getMapStyle(this.getMapType, this.getHideLabels)
			});
		});

		this.mapInstance!.registerEvent("onViewportChangedDelayed", this.mapViewportUpdated);
		this.mapInstance!.registerEvent("onClick", this.mapClicked);

		this.drawingManager = new google.maps.drawing.DrawingManager({
			drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
			drawingControl: false,
			rectangleOptions: rectangleOptions
		});

		this.drawingManager.setMap(this.mapInstance.map);

		google.maps.event.addListener(this.drawingManager, "rectanglecomplete", this.onRectangleComplete);
		google.maps.event.addListener(this.mapInstance.map, "dragstart", this.hideRotationElements);
		google.maps.event.addListener(this.mapInstance.map, "dragend", this.setRotationElementDimensions);
		google.maps.event.addListener(this.mapInstance.map, "zoom_changed", this.hideRotationElements);
	}

	/**
	 * On Viewport Changed Event Handler
	 */
	private async mapViewportUpdated(): Promise<void> {
		if (!this.isLoadingArea) {
			this.viewingGroupId = null;
		}

		this.setRotationElementDimensions();

		if (this.isEditingLayer) {
			return;
		}

		await this.refreshMapData();
	}

	@Watch("selectedFloor")
	private async refreshMapData(): Promise<void> {
		let bounds = {
			north: this.mapInstance!.mapBounds.north,
			south: this.mapInstance!.mapBounds.south,
			east: this.mapInstance!.mapBounds.east,
			west: this.mapInstance!.mapBounds.west,
			elevation: this.selectedFloor
		} as MapBounds;

		this.mapDataInBounds = await api.getMapDataInBounds(bounds);
	}

	private get filteredMapData(): MapData {
		let result: MapData = {
			items: [],
			layers: [],
			elevations: [],
			areas: []
		};

		if (this.selectedArea) {
			result.items = this.mapDataInBounds.items.filter(i => i.groupId === this.selectedArea.id);
			result.layers = this.mapDataInBounds.layers.filter(l => l.groupId === this.selectedArea.id);
		} else {
			result.items = this.mapDataInBounds.items;
			result.layers = this.mapDataInBounds.layers;
		}

		if (this.selectedMapLayerItemTypes.length > 0) {
			result.items = result.items.filter(i =>
				this.selectedMapLayerItemTypes.map(t => t.mapLayerItemTypeId).includes(i.mapLayerItemTypeId)
			);
		}

		if (this.selectedLayerFilters.length > 0) {
			const selectedMapLayerFilterIds = this.selectedLayerFilters.map(l => l.mapLayerId);
			result.items = result.items.filter(i =>
				i.mapLayerIds.some(itemLayer => selectedMapLayerFilterIds.includes(itemLayer))
			);
			result.layers = result.layers.filter(l => selectedMapLayerFilterIds.includes(l.mapLayerId));
		}

		result.elevations = this.mapDataInBounds.elevations;
		return result;
	}

	private mapClicked(latLong: GeoCodeLocation) {
		if (this.isPlotting && this.selectedItemType) {
			this.onMapLayerItemPlot(latLong);
		}
	}

	/**
	 * Event handler to run on plot of a new map layer item.
	 *
	 * @param {any} latLong - Coordinates of the map layer item.
	 */
	private onMapLayerItemPlot(latLong: GeoCodeLocation) {
		let newMapLayerItemTemplate: MapLayerItem = {
			mapLayerItemTypeId: this.selectedItemType.mapLayerItemTypeId,
			title: "",
			latLong: `${latLong.lat} ${latLong.lng}`,
			mapLayerItemId: 0,
			minElevation: 0,
			maxElevation: 0,
			objectId: 0,
			extraValue: null,
			regionPath: null,
			mapLayerIds: [],
			hideOnMap: false,
			groupId: null
		};

		this.newMarker = this.enhancedMapping.createMarker(
			this.mapInstance!, // Enhanced Mapping
			newMapLayerItemTemplate.mapLayerItemId, // MapLayer Item Id
			null, // Event Record Id
			newMapLayerItemTemplate.mapLayerItemTypeId, // Map Item Type Id
			newMapLayerItemTemplate.objectId, // Object Id
			newMapLayerItemTemplate.title, // Title
			convertStringToLatLng(newMapLayerItemTemplate.latLong!), // Position
			newMapLayerItemTemplate.regionPath, // Region Path
			newMapLayerItemTemplate.minElevation, // Min Elevation
			newMapLayerItemTemplate.maxElevation, // Max Elevation
			true // Visible
		);

		this.mapInstance!.setCursor("grab");

		this.openMapLayerItemSetupModal(newMapLayerItemTemplate);

		this.isPlotting = false;
	}

	/**
	 * On change of selectedArea (triggered by <area-select>), get the address for the area, and reposition the map
	 *
	 * @param {AreaNode} areaNode - The area node that has been selected
	 */
	@Watch("selectedArea")
	private async onSelectedAreaUpdated(areaNode: AreaNode) {
		if (!areaNode || areaNode.id < 0) {
			return;
		}

		await this.closeLayerSetup();
		this.isLoading = true;

		let previouslySelectedGroup = this.previouslySelectedAreas.find((g: Group) => g.groupID === areaNode.id);
		if (previouslySelectedGroup) {
			this.selectedAreaDetails = previouslySelectedGroup;
			return;
		}

		this.selectedAreaDetails = await api.getArea(areaNode.id);
		let latLongForSelectedArea = await this.getLatLongForGroup(areaNode.id);
		if (latLongForSelectedArea) {
			this.selectedAreaDetails.latLong = `${latLongForSelectedArea.lat} ${latLongForSelectedArea.lng}`;
		}

		this.previouslySelectedAreas.push(this.selectedAreaDetails);
	}

	@Watch("viewingGroupId")
	private async onViewingAreaUpdated() {
		if (this.viewingGroupId === null) {
			return;
		}

		await this.closeLayerSetup();
		this.isLoading = true;
		this.isLoadingArea = true;
		let latLongForSelectedArea = await this.getLatLongForGroup(this.viewingGroupId);
		if (latLongForSelectedArea) {
			this.goToLocation(areaZoomLevel, latLongForSelectedArea);
		} else {
			this.isLoading = false;
			this.isLoadingArea = false;
		}
	}

	private async getLatLongForGroup(groupId: number): Promise<GeoCodeLocation> {
		if (this.groupGeoCodeLocations.has(groupId)) {
			return this.groupGeoCodeLocations.get(groupId);
		}

		let result: GeoCodeLocation = null;

		let group = await api.getArea(groupId);

		// if the group already has a lat long, use that
		if (group.latLong && group.latLong.length > 0) {
			result = convertStringToLatLng(group.latLong);
		}
		// otherwise if we have an address, do a GeoLookup to get its coordinates
		else if (group && group.address && group.address.length > 0) {
			let response = await geoCoder.GeoCodeLookup(group.address, this.mapKey);
			if (response && response.results) {
				let groupLocationDetails = response.results[0];
				if (groupLocationDetails && groupLocationDetails.geometry && groupLocationDetails.geometry.location) {
					result = groupLocationDetails.geometry.location;
					await api.updateAreaLatLong(groupId, `${result.lat} ${result.lng}`);
				}
			}
			if (response && response.status == "ZERO_RESULTS") {
				this.showNoAddressError();
			}
		}

		this.groupGeoCodeLocations.set(groupId, result);

		return result;
	}

	private async goToMapLayer(mapLayer: MapLayer): Promise<void> {
		await this.closeLayerSetup();
		await this.$nextTick();
		let nwLatLng = convertStringToLatLng(mapLayer.nw);
		let seLatLng = convertStringToLatLng(mapLayer.se);

		let latCenter = (nwLatLng.lat - seLatLng.lat) / 2 + seLatLng.lat;
		let lngCenter = (seLatLng.lng - nwLatLng.lng) / 2 + nwLatLng.lng;

		this.selectedLayer = { ...mapLayer };
		this.goToLocation(roofTopZoomLevel, { lat: latCenter, lng: lngCenter });
	}

	/**
	 * Loads all available map layers and their items in the current viewport.
	 */
	@Watch("filteredMapData")
	private async displayLayersAndItems(): Promise<void> {
		if (!this.isEditingLayer) {
			this.clearMap();
			await this.displayLayers();
		}

		await this.displayItems();

		this.isLoading = false;
	}

	/**
	 * Create google map objects for each layer and adds them to the given group object
	 *
	 * @param {MapLayer[]} layers MapLayer object array
	 */
	private async displayLayers(): Promise<void> {
		for (const layer of this.filteredMapData.layers) {
			if (!this.mapOverlays.has(layer.mapLayerId) && !this.hiddenMapLayerIds.includes(layer.mapLayerId)) {
				await this.createOverlayForMapLayer(layer);
			}
		}
	}

	/**
	 * Create google map objects for each map item and adds them to the given layer object
	 */
	private displayItems(): void {
		for (const item of this.filteredMapData.items) {
			this.createMarker(item);
		}
	}

	private createMarker(mapLayerItem: MapLayerItem): IMarker {
		if (this.mapMarkers.has(mapLayerItem.mapLayerItemId)) {
			let marker = this.mapMarkers.get(mapLayerItem.mapLayerItemId);
			marker.visible = true;
			this.mapMarkers.set(mapLayerItem.mapLayerItemId, marker);
			return marker;
		}

		let mapMarkerItem: IMarker = this.enhancedMapping.createMarker(
			this.mapInstance!,
			mapLayerItem.mapLayerItemId,
			null,
			mapLayerItem.mapLayerItemTypeId,
			mapLayerItem.objectId,
			mapLayerItem.title,
			convertStringToLatLng(mapLayerItem.latLong!),
			mapLayerItem.regionPath,
			mapLayerItem.minElevation,
			mapLayerItem.maxElevation,
			true
		);

		mapMarkerItem.isDraggable = true;

		let vueThis = this;
		if (mapMarkerItem && mapMarkerItem.marker) {
			// cant use arrow function here () => as I need the scope of `this` to be from within the function
			mapMarkerItem.marker.addListener("dragend", function(event: google.maps.MouseEvent) {
				vueThis.updateMarkerItemPosition(event.latLng, mapLayerItem.mapLayerItemId); // this = the marker clicked on not the vue instance
			});
		}

		// setup click handler for the markers, this will be display the item setup window
		if (mapLayerItem.objectId) {
			mapMarkerItem.state = "active";
			mapMarkerItem.isClickable = true;
			mapMarkerItem.click = () => {
				this.openMapLayerItemSetupModal(mapLayerItem);
			};
		}

		this.mapMarkers.set(mapLayerItem.mapLayerItemId, mapMarkerItem);
		return mapMarkerItem;
	}

	/**
	 * move to the map to the given position and zoom level
	 *
	 * @param {number} zoom - zoom level to set the map to
	 * @param {GeoCodeLocation} location - LatLong coordinates to center the map on
	 */
	private goToLocation(zoom: number = 10, location: GeoCodeLocation): void {
		if (this.mapInstance) {
			this.mapInstance.map.zoom = zoom;
			this.mapInstance.map.setCenter(location);
		}
	}

	/**
	 * Show Address not found error
	 */
	private showNoAddressError(): void {
		this.isNoAddressErrorShown = true;
		setTimeout(() => {
			this.isNoAddressErrorShown = false;
		}, addressNotFoundErrorDuration);
	}

	/**
	 * Gets the icon for the map item type
	 *
	 * @param {MapLayerItemType} mapItemType MapItemType Object
	 * @returns {string} - Url for the image
	 */
	private getIcon(mapItemType: MapLayerItemType): string {
		// if the icon property is set, use that (custom map items)
		if (mapItemType.icon && mapItemType.icon.length > 0) {
			return `data:image/png;base64, ${mapItemType.icon}`;
		}

		if (mapItemType.mapLayerItemTypeId === MapLayerItemTypeIds.Asset) {
			return;
		}
		// otherwise return the url for the icon
		return this.mapIcons[mapItemType.mapLayerItemTypeId];
	}

	/**
	 * Event handler for updating a marker position when it is moved
	 *
	 * @param {ILatLng} latLong
	 * @param {number} mapLayerItemId
	 */
	private updateMarkerItemPosition(latLong: ILatLng, mapLayerItemId: number) {
		let mapLayerItem = this.mapDataInBounds.items.find(i => i.mapLayerItemId === mapLayerItemId);
		if (mapLayerItem) {
			let mapLayerItemCopy = { ...mapLayerItem };
			mapLayerItemCopy.latLong = `${latLong.lat()} ${latLong.lng()}`;

			const mapLayerItemIndex = this.relocatedMapLayerItems.findIndex(i => i.mapLayerItemId === mapLayerItemId);
			if (mapLayerItemIndex === -1) {
				this.relocatedMapLayerItems.push(mapLayerItemCopy);
			} else {
				this.relocatedMapLayerItems.splice(mapLayerItemIndex, 1, mapLayerItemCopy);
			}
		}
	}

	/**
	 * Creates a new EnhancedMap overlay
	 *
	 * @param {MapLayer} layer
	 * @param {ILatLngBounds} bounds
	 * @param {string} image
	 */
	private async createOverlayForMapLayer(
		layer: MapLayer,
		bounds: ILatLngBounds = null,
		image: string = null
	): Promise<ILayer> {
		if (this.mapOverlays.has(layer.mapLayerId)) {
			let overlay = this.mapOverlays.get(layer.mapLayerId);
			overlay.visible = true;
			this.mapOverlays.set(layer.mapLayerId, overlay);
			return overlay;
		}

		let imageSrc = image == null ? layer.image : image;
		if (imageSrc && !imageSrc.includes("data:image")) {
			imageSrc = "data:image/png;base64, " + imageSrc;
		}

		const overlay = await this.enhancedMapping.createOverlay(
			this.mapInstance!,
			layer.mapLayerId,
			layer.title,
			bounds ?? stringToBounds(layer.ne, layer.sw),
			layer.rotation,
			imageSrc,
			layer.minElevation,
			layer.maxElevation,
			true
		);

		this.mapOverlays.set(layer.mapLayerId, overlay);

		return overlay;
	}

	/**
	 * Returns the map icon type for the given id
	 *
	 * @param {number} mapItemTypeId
	 */
	private getMapItemIcon(mapItemTypeId: number): string {
		return this.mapIcons[mapItemTypeId];
	}

	private async startAddingNewLayer(): Promise<void> {
		await this.closeLayerSetup();
		this.selectedLayer = {
			mapLayerId: 0,
			groupId: this.selectedArea ? this.selectedArea.id : null,
			image: "",
			title: "",
			sw: "",
			se: "",
			ne: "",
			nw: "",
			rotation: 0,
			minElevation: 0,
			maxElevation: 0,
			calculatedSw: "",
			calculatedSe: "",
			calculatedNe: "",
			calculatedNw: "",
		};

		// Hide other map layers as we can only get a handle on 1 at a time.
		this.hideMapLayers();
		await this.$nextTick();

		// enables drawing mode to plotting a new layer
		this.drawingManager.setMap(this.mapInstance.map);

		this.isAddingLayer = true;
		this.isEditingLayer = true;
		this.showMapLayerInfoBox = true;
	}

	/**
	 * Setups up data and sets UI to edit mode for editing the selected layer
	 *
	 * @param {MapLayer} mapLayer
	 */
	private async startEditingMapLayer(mapLayer: MapLayer): Promise<void> {
		if (this.selectedLayer && this.selectedLayer.mapLayerId !== mapLayer.mapLayerId) {
			await this.closeLayerSetup();
			await this.goToMapLayer(mapLayer);
		}

		this.selectedLayer = mapLayer;

		// Hide other map layers as we can only get a handle on 1 at a time.
		this.hideMapLayers();

		this.isEditingLayer = true;
		this.showMapLayerInfoBox = true;

		// We must wait until state is updated otherwise the mapLayerPlaceholderRectangle wont be shown.
		await this.$nextTick();
		await this.createPreviewOverlay(this.selectedLayer);
	}

	private async onMapLayerSaved(): Promise<void> {
		await this.closeLayerSetup();
		await this.refreshMapData();
	}

	private async onMapLayerEditToggle(): Promise<void> {
		this.isEditingLayer = !this.isEditingLayer;
		if (this.isEditingLayer) {
			await this.startEditingMapLayer(this.selectedLayer);
		} else {
			await this.closeLayerSetup();
		}
	}

	/**
	 * Event handler that runs on emit from Layer Setup child component, on the upload of a layer image
	 * @param {MapLayer} mapLayer
	 */
	private async createPreviewOverlay(mapLayer: MapLayer): Promise<void> {
		if (!this.isEditingLayer && !this.isAddingLayer) {
			return;
		}

		if (!mapLayer.ne || mapLayer.ne.length <= 0) return;

		if (this.previewLayerOverlay) {
			this.previewLayerOverlay.remove();
		}

		if (this.mapLayerPlaceholderRectangle) {
			this.mapLayerPlaceholderRectangle.setMap(null);
		}

		this.previewLayerOverlay = await this.createOverlayForMapLayer(mapLayer);
		this.mapLayerPlaceholderRectangle = this.getNewGoogleMapsRectangle();
		this.mapLayerPlaceholderRectangle.setOptions(rectangleOptions);
		this.mapLayerPlaceholderRectangle.addListener("bounds_changed", this.onRectangleBoundsChanged);
		this.mapLayerPlaceholderRectangle.setBounds(this.previewLayerOverlay.bounds);
		this.mapLayerPlaceholderRectangle.setVisible(true);

		if (this.selectedMapLayerDimensions.width !== "NaN" && this.selectedMapLayerDimensions.height !== "NaN") {
			setTimeout(() => {
				this.loadRotationElement();
			}, 100);
		}
	}

	private getNewGoogleMapsRectangle(): google.maps.Rectangle {
		return new google.maps.Rectangle({ map: this.mapInstance.map });
	}

	private async updatePreviewOverlay(mapLayer: MapLayer): Promise<void> {
		if (this.mapOverlays.has(mapLayer.mapLayerId)) {
			const overlay = this.mapOverlays.get(mapLayer.mapLayerId);
			overlay.remove();
			this.mapOverlays.delete(mapLayer.mapLayerId);
		}

		this.previewLayerOverlay.remove();
		await this.createPreviewOverlay(mapLayer);
	}

	private async onLayerImageChanged(mapLayer: MapLayer) {
		this.selectedLayer = mapLayer;
		if (this.previewLayerOverlay) {
			await this.updatePreviewOverlay(mapLayer);
		} else {
			await this.createPreviewOverlay(mapLayer);
		}
	}

	private onLayerInfoChanged(mapLayer: MapLayer) {
		this.selectedLayer = mapLayer;
	}

	private async onMapLayerDeleted(mapLayerId: number): Promise<void> {
		await this.closeLayerSetup();
		if (this.mapOverlays.has(mapLayerId)) {
			const overlay = this.mapOverlays.get(mapLayerId);
			overlay.remove();
			this.mapOverlays.delete(mapLayerId);
		}
		await this.refreshMapData();
	}

	/**
	 * Clear map of all layers and items
	 */
	private clearMap(): void {
		if (this.mapInstance) {
			this.mapInstance!.setCursor("grab");
		}

		this.hideRotationElements();

		if (this.newMarker) {
			this.newMarker.remove();
		}

		if (this.drawingManager) {
			this.drawingManager.setMap(null);
		}

		if (this.mapLayerPlaceholderRectangle) {
			this.mapLayerPlaceholderRectangle.setMap(null);
		}

		if (this.previewLayerOverlay) {
			this.previewLayerOverlay.remove();
		}

		this.mapOverlays.forEach(mapOverlay => {
			mapOverlay.visible = false;
			mapOverlay.remove();
		});

		this.mapMarkers.forEach(mapMarker => {
			mapMarker.visible = false;
			mapMarker.remove();
		});

		this.mapOverlays.clear();
		this.mapMarkers.clear();

		this.relocatedMapLayerItems = [];
	}

	/**
	 * Hides the rotation UI
	 */
	private hideRotationElements() {
		if (this.rotationContainer) {
			this.rotationContainer.style.visibility = "hidden";
		}
	}

	/**
	 * Sets the dimensions and size of the rotation element for rotating a map layer image.
	 */
	private setRotationElementDimensions() {
		// find over lay div
		this.divOverlayContainer = document.getElementById("divOverlayContainer");

		if (this.divOverlayContainer && (this.isEditingLayer || this.isAddingLayer)) {
			// find rotation UI elements
			this.rotationContainer = document.getElementById("rotation-container");
			this.rotationHandle = document.getElementById("rotation-handle");
			const rect = this.divOverlayContainer.getBoundingClientRect();

			// workout top / left pixels for the rotation container
			this.rotationContainerCenter = {
				x: rect.left + rect.width / 2,
				y: rect.top + rect.height / 2
			};

			// set dimensions based on the parent attributes
			this.rotationContainer.style.top = this.rotationContainerCenter.y + "px";
			this.rotationContainer.style.left = this.rotationContainerCenter.x + "px";
			this.rotationContainer.style.width = parseInt(this.divOverlayContainer.style.width) + "px";
			this.rotationContainer.style.visibility = "visible";
		}
	}

	/**
	 * Hooks up the relevant event handlers for rotating the map layer image
	 */
	private loadRotationElement() {
		this.setRotationElementDimensions();
		// workout with transform method can be used based on what is available to the browser
		let transform = (() => {
			let prefs = ["t", "WebkitT", "MozT", "msT", "OT"],
				style = document.documentElement.style,
				p;
			for (let i = 0, len = prefs.length; i < len; i++) {
				if ((p = prefs[i] + "ransform") in style) return p;
			}

			this.$notify({
				type: "error",
				title: "Map Layer Setup issue",
				text: "This browser does not support the ability to rotate map layers"
			});
		})();

		// calculates rotation angle for the image based off where the mouse is
		let rotate = (x, y) => {
			let deltaX = x - this.rotationContainerCenter.x;
			let deltaY = y - this.rotationContainerCenter.y;

			let angle = +((Math.atan2(deltaY, deltaX) * 180) / Math.PI).toFixed();

			// store the value for our layer
			this.selectedLayer.rotation = angle;

			return angle;
		};

		// DRAGSTART
		let mousedown = event => {
			event.preventDefault();
			document.body.style.cursor = "move";
			mousemove(event);
			document.addEventListener("mousemove", mousemove);
			document.addEventListener("mouseup", mouseup);
		};

		// DRAG
		let mousemove = event => {
			this.mapLayerPlaceholderRectangle.setVisible(false);
			this.rotationContainer.style[transform] = "rotate(" + rotate(event.pageX, event.pageY) + "deg)";
			this.divOverlayContainer.style[transform] = "rotate(" + rotate(event.pageX, event.pageY) + "deg)";
		};

		// DRAGEND
		let mouseup = () => {
			this.mapLayerPlaceholderRectangle.setVisible(true);
			this.onRectangleBoundsChanged();
			document.body.style.cursor = null;
			document.removeEventListener("mouseup", mouseup);
			document.removeEventListener("mousemove", mousemove);
		};

		// DRAG START
		if (this.rotationHandle) {
			this.rotationHandle.addEventListener("mousedown", mousedown);
		}
	}

	private hideMapLayers(): void {
		if (this.mapLayerPlaceholderRectangle) {
			this.mapLayerPlaceholderRectangle.setMap(null);
		}

		if (this.previewLayerOverlay) {
			this.previewLayerOverlay.remove();
		}

		this.mapOverlays.forEach(mapOverlay => {
			mapOverlay.visible = false;
			mapOverlay.remove();
		});

		this.mapOverlays.clear();
	}

	private closeMapLayerItemWindow(): void {
		this.isPlotting = false;

		if (this.infoWindow && typeof this.infoWindow.close == "function") {
			this.infoWindow.close();
		}

		if (google.maps && google.maps.event) {
			google.maps.event.trigger(this.infoWindow, "closeclick");
		}
	}

	private async mapLayerItemDeleted(mapLayerItemId: number) {
		this.closeMapLayerItemWindow();
		if (this.mapMarkers.has(mapLayerItemId)) {
			let marker = this.mapMarkers.get(mapLayerItemId);
			marker.visible = false;
			marker.remove();
			this.mapMarkers.set(mapLayerItemId, marker);
		}
	}

	private async mapLayerItemSaved() {
		this.closeMapLayerItemWindow();

		let bounds = {
			north: this.mapInstance!.mapBounds.north,
			south: this.mapInstance!.mapBounds.south,
			east: this.mapInstance!.mapBounds.east,
			west: this.mapInstance!.mapBounds.west,
			elevation: this.selectedFloor
		} as MapBounds;

		this.mapDataInBounds = await api.getMapDataInBounds(bounds);
	}

	/**
	 * Opens a new instance of the Map Item Setup window
	 *
	 * @param {MapLayerItem} mapLayerItem
	 */
	private openMapLayerItemSetupModal(mapLayerItem: MapLayerItem): void {
		if (this.mapLayerPlaceholderRectangle) {
			this.mapLayerPlaceholderRectangle.setVisible(false);
		}

		this.closeMapLayerItemWindow();

		let latLong = convertStringToLatLng(mapLayerItem.latLong);
		let mapLayerItemSetupModal = Vue.extend(MapLayerItemSetup);
		const mapLayerSetupModalInstance = new mapLayerItemSetupModal({
			store: this.$store,
			propsData: {
				mapLayerItem: mapLayerItem,
				mapLayersInBounds: this.mapDataInBounds.layers,
				groupId: this.viewingGroupId,
				currentFloor: this.selectedFloor
			}
		}).$mount();

		// hook up event handlers for emits
		mapLayerSetupModalInstance.$on("mapLayerItemSaved", () => this.mapLayerItemSaved());
		mapLayerSetupModalInstance.$on("mapLayerItemWindowClosed", () => this.closeMapLayerItemWindow());
		mapLayerSetupModalInstance.$on("mapLayerItemDeleted", (eventData: any) =>
			this.mapLayerItemDeleted(eventData.mapLayerItemId)
		);

		this.infoWindow = new google.maps.InfoWindow({
			content: mapLayerSetupModalInstance.$el,
			position: latLong,
			pixelOffset: new google.maps.Size(0, -48)
		});

		// event handler for clicking the X in the popup window
		this.infoWindow.addListener("closeclick", () => {
			if (this.mapMarkers.has(mapLayerItem.mapLayerItemId)) {
				let marker = this.mapMarkers.get(mapLayerItem.mapLayerItemId);
				marker.isDraggable = true;
				this.mapMarkers.set(mapLayerItem.mapLayerItemId, marker);
			}
			if (this.newMarker) {
				this.newMarker.remove();
			}
			if (this.mapLayerPlaceholderRectangle) {
				this.mapLayerPlaceholderRectangle.setVisible(true);
			}

			this.mapInstance.map.setOptions({gestureHandling: "greedy"});

			this.setRotationElementDimensions();
		});

		// lock the markers location while the popup window is open.
		if (this.mapMarkers.has(mapLayerItem.mapLayerItemId)) {
			let marker = this.mapMarkers.get(mapLayerItem.mapLayerItemId);
			marker.isDraggable = false;
			marker.marker.setZIndex(9999999);
			this.mapMarkers.set(mapLayerItem.mapLayerItemId, marker);
		}

		this.infoWindow.open(this.mapInstance.map);

		this.mapInstance.map.setCenter(latLong);
		this.mapInstance.map.panBy(0,-90);
		this.mapInstance.map.setOptions({gestureHandling: "none"});

		// reset the cursor for the map (changed when plotting a new item)
		this.mapInstance!.setCursor("grab");
	}

	/**
	 * Set the map to allow plotting of an item, which will trigger the "onClick" event for the map.
	 *
	 * @param {MapLayerItemType} itemType
	 */
	private beginPlottingItem(itemType: MapLayerItemType): void {
		// only allow items to be plotted if we are not currently editing or creating a layer
		if (!this.isAddingLayer) {
			this.isPlotting = true;
			this.selectedItemType = itemType;

			this.mapInstance!.setCursor("crosshair");

			if (this.mapLayerPlaceholderRectangle) {
				this.mapLayerPlaceholderRectangle.setVisible(false);
			}

			this.hideRotationElements();
		}
	}

	/**
	 * Event handler for drawing a new rectangle on the map.
	 *
	 * @param {google.maps.Rectangle} rectangle
	 */
	private onRectangleComplete(rectangle: google.maps.Rectangle): void {
		// turn off drawing mode
		this.drawingManager.setMap(null);

		// updates our rectangle object with the properties on the newly create one
		this.mapLayerPlaceholderRectangle = rectangle;

		// update the width and high display, based on the size of the rectangle
		this.updateDimensions(rectangle);

		// add an event listener to run if the size, shape or position of the rectangle is changed
		this.mapLayerPlaceholderRectangle.addListener("bounds_changed", this.onRectangleBoundsChanged);

		// create an event handler for clicking on the rectangle, this will trigger a rotation of the map layer
		google.maps.event.addListener(this.mapLayerPlaceholderRectangle, "click", () => {
			// if we have a preview layer, ie an image has been uploaded
			if (this.previewLayerOverlay) {
				// on each click, increase the rotation by 10
				this.previewLayerOverlay.rotation = this.previewLayerOverlay.rotation + 10;
				this.previewLayerOverlay.rotationValue = this.previewLayerOverlay.rotationValue + 10;
				this.selectedLayer.rotation = this.previewLayerOverlay.rotationValue;
			}
		});

		// update the bounds of the selected layer
		this.setSelectedLayerBounds();
		this.createPreviewOverlay(this.selectedLayer);
	}

	/**
	 * Helper method to update the bounds of the selected layer
	 *
	 * @param {ILatLngBounds} bounds - bounds object for coordinates
	 */
	private setSelectedLayerBounds(bounds: ILatLngBounds = null): void {
		if (!bounds) {
			bounds = this.mapLayerPlaceholderRectangle.getBounds().toJSON();
		}

		this.selectedLayer.ne = `${bounds.north} ${bounds.east}`;
		this.selectedLayer.nw = `${bounds.north} ${bounds.west}`;
		this.selectedLayer.se = `${bounds.south} ${bounds.east}`;
		this.selectedLayer.sw = `${bounds.south} ${bounds.west}`;
	}

	/**
	 * Click handler that runs when the size, shape or position of the rectangle is changed
	 */
	private onRectangleBoundsChanged(): void {
		if (!this.isAddingLayer && !this.isEditingLayer) {
			return;
		}

		this.updateDimensions(this.mapLayerPlaceholderRectangle);
		this.setSelectedLayerBounds();

		if (this.previewLayerOverlay) {
			// update the position of the preview layer to match the rectangle
			this.previewLayerOverlay.bounds = this.mapLayerPlaceholderRectangle.getBounds();
			this.previewLayerOverlay.rotation = this.selectedLayer.rotation;
			this.previewLayerOverlay.rotationValue = this.selectedLayer.rotation;
			this.setRotationElementDimensions();
		}
	}

	/**
	 * Update the calculated height and width of the rectangle
	 *
	 * @param {google.maps.Rectangle} rectangle
	 */
	private updateDimensions(rectangle: google.maps.Rectangle): void {
		let bounds = this.mapLayerPlaceholderRectangle.getBounds().toJSON();

		let height = google.maps.geometry.spherical.computeDistanceBetween(
			new google.maps.LatLng(bounds.north, bounds.east),
			new google.maps.LatLng(bounds.south, bounds.east)
		);

		let width = google.maps.geometry.spherical.computeDistanceBetween(
			new google.maps.LatLng(bounds.north, bounds.east),
			new google.maps.LatLng(bounds.north, bounds.west)
		);

		this.selectedMapLayerDimensions = {
			height: height.toFixed(2),
			width: width.toFixed(2)
		};
	}

	/**
	 * Closes the Layer Setup interface and resets objects and data
	 */
	private async closeLayerSetup(): Promise<void> {
		this.isEditingLayer = false;
		this.isAddingLayer = false;

		// remove the rectangle from the map
		if (this.mapLayerPlaceholderRectangle) {
			this.mapLayerPlaceholderRectangle.setMap(null);
		}

		// remove the preview layer from the map
		if (this.previewLayerOverlay) {
			this.previewLayerOverlay.remove();
			this.previewLayerOverlay = null;
		}

		// remove the drawing manager from the map
		if (this.drawingManager) {
			this.drawingManager.setMap(null);
		}

		if (this.rotationContainer) {
			this.rotationContainer.style.visibility = "hidden";
		}

		this.selectedLayer = null;
		this.showMapLayerInfoBox = false;
	}

	/**
	 * Updates any changes map item positions
	 */
	private saveChangesToMapLayerItems(): void {
		this.isSavingMapLayerItems = true;
		this.relocatedMapLayerItems.forEach(async mapLayerItem => {
			await api.putMapLayerItem(mapLayerItem);
			let itemToUpdate = this.mapDataInBounds.items.find(i => i.mapLayerItemId === mapLayerItem.mapLayerItemId);
			itemToUpdate.latLong = mapLayerItem.latLong;
		});

		this.relocatedMapLayerItems = [];
		setTimeout(() => {
			this.isSavingMapLayerItems = false;
		}, 200);
	}

	private cancelChangesToMapLayerItems(): void {
		this.relocatedMapLayerItems.forEach(async mapLayerItem => {
			let originalMapLayerItem = this.mapDataInBounds.items.find(i => i.mapLayerItemId === mapLayerItem.mapLayerItemId);
			if (originalMapLayerItem && this.mapMarkers.has(originalMapLayerItem.mapLayerItemId)) {
				this.mapMarkers
					.get(originalMapLayerItem.mapLayerItemId)
					.moveTo(convertStringToLatLng(originalMapLayerItem.latLong));
			}
		});
		this.relocatedMapLayerItems = [];
	}

	/**
	 * Toggle visibility of a map layer image
	 *
	 * @param {number} mapLayerId
	 * @param {boolean | null} visibility
	 */
	private async toggleMapLayerVisibility(mapLayerId: number, visibility: boolean = null): Promise<void> {
		if (!visibility) {
			if (this.mapOverlays.has(mapLayerId)) {
				visibility = !this.mapOverlays.get(mapLayerId).visible;
			} else {
				visibility = false;
			}
		}

		if (this.mapOverlays.has(mapLayerId)) {
			let overlay = this.mapOverlays.get(mapLayerId);
			overlay.visible = visibility;
			this.mapOverlays.set(mapLayerId, overlay);
			if (!visibility) {
				this.hiddenMapLayerIds.push(mapLayerId);
			}
		} else {
			const mapLayerToShow = this.mapDataInBounds.layers.find(l => l.mapLayerId === mapLayerId);
			await this.createOverlayForMapLayer(mapLayerToShow);
		}

		if (!visibility) {
			return;
		}
		const indexToRemove = this.hiddenMapLayerIds.indexOf(mapLayerId);
		if (indexToRemove === -1) {
			return;
		}
		this.hiddenMapLayerIds.splice(indexToRemove, 1);
	}

	/**
	 * Get text for Title tag referencing the image layer display state
	 *
	 * @param {number} mapLayerId
	 * @returns {string}
	 */
	private mapLayerImageStatusTitle(mapLayerId: number): string {
		if (!this.selectedLayer || this.selectedLayer.mapLayerId === mapLayerId) {
			return "";
		}
		return (
			(this.mapOverlays.has(mapLayerId) && this.mapOverlays.get(mapLayerId).visible ? "hide" : "show") +
			" map layer image"
		);
	}

	private getShortKey(mapItemType: MapLayerItemType): string {
		// camera
		if (mapItemType.mapLayerItemTypeId == 1) {
			return "q";
		}
		// Audio
		if (mapItemType.mapLayerItemTypeId == 2) {
			return "w";
		}
		// Door
		if (mapItemType.mapLayerItemTypeId == 3) {
			return "e";
		}
		// Alarm
		if (mapItemType.mapLayerItemTypeId == 4) {
			return "r";
		}
		// Output
		if (mapItemType.mapLayerItemTypeId == 5) {
			return "y";
		}
	}

	private get isMapSetupUseElevationLabels(): boolean {
		return get(this.featuresList, ["MapSetup", "ElevationLabels"]);
	}

	private get elevationValuesElevationsArray(): number[] {
		return this.mapDataInBounds.elevations.map( item => item.elevationValue );
	}

	private get selectedFloorForLayer() : number {
		return this.isMapSetupUseElevationLabels ? this.selectedFloor : this.selectedFloor + 1;
	}

	private destroyed(): void {
		this.closeMapLayerItemWindow();
		this.closeLayerSetup();
		this.clearMap();
	}
}
