import { ActionTree } from "vuex";
import { RootState } from "../types";
import { GuardChat } from "./types";
import * as api from "@/services/api.guardChat.service";
import EventRecordTypes from "@/types/sv-data/enums/EventRecordTypes";

export const actions: ActionTree<GuardChat, RootState> = {
	async loadMessages({ commit, state, getters }, eventId: number) {
		const afterId = getters.getLastMessageId as number | undefined;
		let messages = await api.loadMessages(eventId, afterId);
		if (messages) {
			const currentMessageIds = state.messages.map(x => { return x.eventRecordId});
			messages = messages.filter(x => !currentMessageIds.includes(x.eventRecordId));
			if (messages.length > 0) {
				commit("addMessages", messages);
				var newMessageCount = messages.filter(x => x.eventRecordTypeId != EventRecordTypes.AttachedFile).length;
				commit("setNumberOfUnread", state.numberOfUnread + newMessageCount);
			}
		}
	},

	async loadTwoWayMessages({ commit, state, getters }, chatUserId: number) {
		const afterId = getters.getTwoWayLastMessageId as number | undefined;
		let messages = await api.loadTwoWayMessages(chatUserId, afterId);
		const currentMessageIds = state.messages.map(x => { return x.eventRecordId});
		messages = messages.filter(x => !currentMessageIds.includes(x.eventRecordId));
		if (messages && messages.length > 0) {
			commit("addTwoWayMessages", messages);
			var newMessageCount = messages.filter(x => x.eventRecordTypeId != EventRecordTypes.AttachedFile).length;
			commit("setTwoWayNumberOfUnread", state.twoWayNumberOfUnread + newMessageCount);
		}
	}
};
