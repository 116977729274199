import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { SetupState } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<SetupState, RootState> = {
	async loadAllFeatures({ commit }, tenantId: number): Promise<void> {
		const { data } = await api.loadAllFeatures(tenantId);
		commit("setAllFeatures", data);
	},

	async toggleFeatures({ commit }, { features, tenantId }): Promise<boolean> {
		const { data } = await api.toggleFeatures(features, tenantId);
		return data ;
	},

	async fetchJsonConfig({ commit }) {
		const { data } = await api.loadJsonConfig();
		return data;
	},

	async loadActionPlans({ commit }) {
		const data = await api.loadActionPlans();
		commit("setActionPlans", data);

		return data;
	},

	async loadResponseAlarmTypes({ commit }) {
		const data = await api.responseAlarmTypes();
		commit("setResponseAlarmTypes", data);

		return data;
	},

	/**
	 * Sets the current areas to be the parents of the passed in group id.
	 * If null is passed in sets it to null
	 * @param commit
	 * @param groupId The group to set the parents for
	 */
	async groupIdSelected({ commit }, groupId: number | null) {
		if (groupId) {
			const groupsToRoot = await api.groupsToRoot(groupId);
			commit("setCurrentAreas", new Set(groupsToRoot));
		} else {
			commit("setCurrentAreas", null);
		}
	},

	/**
	 * Sets the current areas to be the children of the passed in group id.
	 * If null is passed in sets it to null
	 * @param commit
	 * @param groupId The group to set the children for
	 */
	async groupIdSelectedChildren({ commit }, groupId: number | null) {
		if (groupId) {
			const subgroups = await api.subgroups(groupId);
			commit("setCurrentAreas", new Set(subgroups));
		} else {
			commit("setCurrentAreas", null);
		}
	},

	async getIsFeaturesConfigControlledByFile(): Promise<boolean> {
		const { data } = await api.getIsFeaturesConfigControlledByFile();
		return data;
	}
};
