import { GetterTree } from "vuex";
import { SignUpState } from "./types";
import { RootState } from "@/store/types";
import router from "@/router"

export const getters: GetterTree<SignUpState, RootState> = {
	currentStep: state => () => {
        let currentStep = state.steps.find(s => router.currentRoute.path.includes(s.route));
        if (!currentStep) {
            return state.steps[0];
        }
        return currentStep;
	},
    steps: state => {
	    if (!state.isCreditCardRequired) {
            return state.steps.filter(s => s.number != 4);
        } else {
            return state.steps;
        }
    }
};
