import { AreaNotesState } from "./types";

export function getDefaultState(): AreaNotesState {
	return {
		areaNotes: [],
		areaNoteToEdit: null,
		currentGroupId: null,
		currentGroupTitle: "",
		isFormShown: false,
		isEditing: false
	}
}