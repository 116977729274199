import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import RegionalHandlerMixin from "./RegionalHandlerMixin";
import HideMapMixin from "./HideMapMixin";
import { get } from "lodash";
import { Getter, Mutation, namespace } from "vuex-class";
import { EventDetails } from "@/store/site-monitor/types";
import { EnhancedMapMode, SubscriberExpiry } from "@/types/EnhancedSingleMapTypes";
import { ActiveRegion, KeyboardShortcut, LegendKey } from "@/store/types";

const SiteMonitorState = namespace("siteMonitor");
const UserContext = namespace("userContext");
const FieldOpsStore = namespace("fieldOps");
const Tasks = namespace("tasks");

const expirationDateOffset: number = 5100;
@Component
export default class SiteMonitorMixin extends Mixins(RegionalHandlerMixin, HideMapMixin) {
	@Getter private getActiveRegion: ActiveRegion;
	@Mutation private setLegendKeys: (legends: LegendKey[]) => void;
	@Mutation private setRoutingGroupsLegend: (show: boolean) => void;
	@Mutation private setShortcuts: (shortCuts: KeyboardShortcut[]) => void;

	@SiteMonitorState.Getter("getEventDetails") public eventDetails: EventDetails;
	@SiteMonitorState.Mutation public setEventDetails: any;
	@SiteMonitorState.Mutation public setActivity;

	@UserContext.Action("startPolling") private startPollingUserContext: () => Promise<void>;
	@UserContext.Action("stopPolling") private stopPollingUserContext: () => Promise<void>;

	@FieldOpsStore.Action("addOrUpdateActiveSubscriber") private subscribeToPoll: (subscriberExpiry: SubscriberExpiry) => void;

	@Tasks.Getter("getEventTasks") private eventTasks: any; // @techdebt: no defined return value.
	@Tasks.Action private fetchEventTasks: (payload: { eventId: number }) => Promise<void>;

	// Data variables
	public originalEventId: number;
	public showActions: boolean = true;
	public subscriberTimer: NodeJS.Timeout = null;
	public enhancedMapMode = EnhancedMapMode;

	// Getter and action for the flag for toggling the map.
	public mapScale: number = 0.5; // 0, 0.5, 0.75

	// Lifecycle methods
	public onCreate(): void {
		// Refresh proofing for region based requests
		// Our api.service can have its baseUrl updated to point at different servers
		// however when the api is initially loaded the baseUrl will be set based
		// on the server the user logged in with. Since we store the active region in the
		// vuex store, which is refresh proof, we update the active region with the value
		// from the store, which will update the api base url.
		this.updateActiveRegion(this.getActiveRegion);

		// Set our shortcuts for the site monitor
		this.setShortcuts([
			{ keys: "Shift+E", action: "End event" },
			{ keys: "Shift+L", action: "Leave event" },
			{
				keys: "Shift+N",
				action: "Incident Report",
				featureFlag: "Alarms.SiteMonitor.SitRep"
			},
			{
				keys: "Shift+D",
				action: "Dispatch",
				featureFlag: "Alarms.Dispatch"
			},
			{
				keys: "Shift+R",
				action: "Raise",
				featureFlag: "Alarms.SiteMonitor.Raise"
			},
			{
				keys: "Shift+S",
				action: "Share Event",
				featureFlag: "Alarms.SiteMonitor.EventSharing"
			},
			{
				keys: "Shift+I",
				action: "Area information"
			},
			{
				keys: "Shift+M",
				action: "Open Media Matrix",
				featureFlag: "Alarms.MediaMatrix"
			},
			{
				keys: "Shift+T",
				action: "Email Notification",
				featureFlag: "Alarms.SiteMonitor.Email"
			},
			{
				keys: "Shift+G",
				action: "Flag event for review",
				featureFlag: "Alarms.SiteMonitor.EventFlagging"
			},
			{
				keys: "Shift+C",
				action: "Acknowledge all events",
			},
			{
				keys: "Shift+A",
				action: "Open action tab and focus on uncompleted action task",
			}
		]);

		this.setLegendKeys([{ icon: "", details: "None to show" }]);

		this.setRoutingGroupsLegend(false);

		this.originalEventId = this.eventId;
	}

	public async mounted(): Promise<void> {
		// Get our EventTasks (actions)
		await this.fetchEventTasks({ eventId: this.eventId });

		// If we want to hide the actions pane if there are no actions
		// And there are no actions
		if(this.actionPlansCollapseIfEmpty &&
			(!this.eventTasks || this.eventTasks.length === 0)){
			this.showActions = false;
			return;
		}

		// If we are here, then we want to show the actions pane
		this.showActions = true;

		await this.startPollingUserContext();

		if(this.mapEnabled){
			this.subscriberTimer = setInterval(async () => {
				this.subscribeToPoll({ subscriber: "SiteMonitor", expiry: Date.now() + expirationDateOffset });
			}, 5000);
		}

		this.$el.addEventListener("mousedown", (_e: MouseEvent) => { this.setActivity(); })
		this.$el.addEventListener("keydown", (e:KeyboardEvent) => {
			if(e.key === "Tab"){
				this.setActivity();
			}
		});
	}

	public beforeDestroy(): void {
		this.$el.removeEventListener("mousedown", (_e: MouseEvent) => { this.setActivity(); })
		this.$el.removeEventListener("keydown", (e:KeyboardEvent) => {
			if(e.key === "Tab"){
				this.setActivity();
			}
		});
		this.startPollingUserContext();
		if(this.subscriberTimer){
			clearInterval(this.subscriberTimer);
		}
	}

	public destroyed(): void {
		this.$store.dispatch("closeEvent");
		// If we have changed to a different event don't clear the details.
		if (!!this.eventDetails && this.eventDetails.eventID === this.originalEventId) {
			this.setEventDetails(null);
		}

		this.resetRegion();
	}

	// Custom Methods
	public async focusActions(): Promise<void> {
		if(this.showActions)
			return;

		this.showActions = true;
		await this.$nextTick();
		if (this.$refs.taskContainer) {
			(this.$refs.taskContainer as any).focus();
		}
	}

	public scaleMapComponent(scaleUp: boolean): void {
		if(scaleUp && this.mapScale < 0.75){
			this.mapScale += 0.25;
		} else if(!scaleUp && this.mapScale > 0){
			this.mapScale -= 0.25;
		}
	}

	// Getters
	/**
	 * Returns the event ID passed in through our route as a number
	 */
	public get eventId(): number | null {
		return parseInt(this.$route.params.eventId) || null;
	}

	public get eventRecordType(): string {
		return get(this.featuresList, ["Integration", "CCure", "UI"]) ? "event-records-ccure" : "event-records";
	}

	public get mapEnabled(): boolean {
		var siteMonitorMap = get(this.featuresList, ["Alarms", "SiteMonitor", "Map"]);
		var hideMaps = !this.isHideMapsEnabled
		return siteMonitorMap && hideMaps;
	}

	public get newAreaNotesEnabled(): boolean {
		return get(this.featuresList, ["AreaNotes"]) && get(this.featuresList, ["AreaNotes", "Icons"]);
	}

	public get areaNotesEnabled(): boolean {
		return get(this.featuresList, ["AreaNotes"]) && !get(this.featuresList, ["AreaNotes", "Icons"]);
	}

	public get actionPlansEnabled(): boolean {
		return !!get(this.featuresList, ["Alarms", "ActionPlans"]);
	}

	public get actionPlansCollapseIfEmpty(): boolean {
		return get(this.featuresList, ["Actions", "OnEmptyStartCollapsed"]);
	}

	// Feature flag check for toggle maps.
	public get isToggleMapEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Maps", "ToggleSiteMonitor"]);
	}

	public get isStatusInfoBarEnabled(): boolean {
		return this.getFeature(["SystemInfoBar"]);
	}

	public get isSituationalAwarenessEnabled(): boolean {
		return this.getFeature(["SituationalAwareness"]);
	}

	public get eventRecordComponentScale(): string {
		if(!this.mapEnabled)
			return "event-records-container-full-height";

		switch(this.mapScale) {
			case 0:
				return "event-records-container-full-height";
			case 0.25:
				return "event-records-container-medium-height";
			case 0.5:
				return "event-records-container";
			case 0.75:
				return "event-records-container-small-height";
			default:
				return "event-records-container";
		}
	}

	public get mapComponentScale(): string {
		if (!this.mapEnabled) {
			return "enhanced-map-container-zero-height";
		}

		switch(this.mapScale) {
			case 0:
				return "enhanced-map-container-zero-height";
			case 0.25:
				return "enhanced-map-container-small-height";
			case 0.5:
				return "enhanced-map-container";
			case 0.75:
				return "enhanced-map-container-tall-height";
			default:
				return "enhanced-map-container";
		}
	}
}
