import { AxiosInstance } from "axios";
import { axiosInstance, axiosJsonInstance } from "@/axios.instance";
import NotificationTemplatePagedResponse from "@/types/sv-data/notification-templates/NotificationTemplatePagedResponse";
import NotificationTemplatePagedRequest from "@/types/sv-data/notification-templates/NotificationTemplatePagedRequest";
import NotificationTemplate from "@/types/sv-data/notification-templates/NotificationTemplate";
import NotificationTemplatePreviewRequest from "@/types/sv-data/notification-templates/NotificationTemplatePreviewRequest";
import NotificationDocumentTypeId from "@/types/sv-data/enums/NotificationDocumentTypeIds";

class NotificationTemplateApi {
	private axios: AxiosInstance;
	private axiosJson: any;

	constructor() {
		this.axios = axiosInstance;
		this.axiosJson = axiosJsonInstance;
	}

	public async getNotificationTemplates(request: NotificationTemplatePagedRequest, groupId: number | null)
		: Promise<NotificationTemplatePagedResponse> {

		let query = `/NotificationTemplates?` +
			`page=${request.page}` +
			`&pageSize=${request.pageSize}` +
			`&searchTerm=${request.searchTerm}` +
			`&sortBy=${request.sortBy}` +
			`&sortDesc=${request.sortDesc}` +
			`&notificationTemplateTypeId=${request.notificationTemplateTypeId}`;

		if (groupId != null) {
			query = query + `&groupId=${groupId}`;
		}

		const { data } = await this.axiosJson.get(query);

		return data;
	}

	public async getAllNotificationTemplates(notificationTemplateType: number, groupId: number | null): Promise<NotificationTemplate[]> {
		const pageSizeLimit: number = 200;
		let data = [] as NotificationTemplate[];
		let request: NotificationTemplatePagedRequest = {
			page: 1,
			pageSize: pageSizeLimit,
			searchTerm: "",
			notificationTemplateTypeId: notificationTemplateType
		};

		let results: NotificationTemplatePagedResponse = await this.getNotificationTemplates(request, groupId);
		data.push(...results.data);

		while(results.totalRecords > data.length) {
			request.page += 1;
			results = await this.getNotificationTemplates(request, groupId);
			data.push(...results.data);
		}

		return data;
	}

	public async createNotificationTemplate(notificationTemplate: NotificationTemplate)
		: Promise<void>
	{
		notificationTemplate.body = this.escapeSymbols(notificationTemplate.body);

		if (notificationTemplate.inherited == null) {
			notificationTemplate.inherited = false;
		}

		await this.axiosJson.post("/NotificationTemplates", notificationTemplate);
	}

	public async updateNotificationTemplate(notificationTemplate: NotificationTemplate)
		: Promise<void>
	{
		notificationTemplate.body = this.escapeSymbols(notificationTemplate.body);

		if (notificationTemplate.inherited == null) {
			notificationTemplate.inherited = false;
		}

		await this.axiosJson.put("/NotificationTemplates", notificationTemplate);
	}

	public async deleteNotificationTemplate(notificationTemplateId: number)
		: Promise<void>
	{
		await this.axios.delete(`/NotificationTemplates/${notificationTemplateId}`);
	}

	public async previewNotificationTemplate(eventId: number, request: NotificationTemplatePreviewRequest){
		// Clone template so we can make changes without affecting the original instance
		let template = { ...request.notificationTemplate };

		template.body = this.escapeSymbols(template.body);
		request.notificationTemplate = template;

		let result = await this.axiosJson.post(`/NotificationTemplates/Preview/${eventId}`, request, {
			responseType: "blob"
		});

		let title = `Event ${eventId} Template Preview.pdf`;
		if(request.notificationDocumentTypeId === NotificationDocumentTypeId.Png){
			title = `Event ${eventId} Template Preview.html`;
		}

		const urlObject = window.URL;
		const url = urlObject.createObjectURL(result.data)
		const el = document.createElement("a")

		el.setAttribute("href", url)
		el.setAttribute("download", title)
		el.click()
		urlObject.revokeObjectURL(url)
	}

	private escapeSymbols(body: string): string {
		return body
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;")
			.replace(/"/g, "&quot;")
			.replace(/'/g, "&#039;");
	}
}

const notificationTemplatesApi = new NotificationTemplateApi();
export default notificationTemplatesApi;
