
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { sortBy } from "lodash";
import { TaskGroup, EventTask, ActionPlanTask, Task } from "@/store/tasks/types";
import { namespace, Getter } from "vuex-class";
import { UserPermissions } from "@/store/types";
import { EventShareUser } from "@/store/site-monitor/types";
import { CompleteTaskPayload } from "@/services/api.service";

import CompleteTaskLine from "@/components/tasks/CompleteTaskLine.vue";
import AddNewEventTask from "@/components/tasks/AddNewEventTask.vue";

const SiteMonitor = namespace("siteMonitor");
const Tasks = namespace("tasks");

@Component({
	components: {
		"complete-task-line": CompleteTaskLine,
		"add-new-event-task": AddNewEventTask
	}
})
export default class EventTaskGroup extends Vue {
	@Prop() public taskGroup: TaskGroup;
	@Prop() public eventId: number;

	@Getter("getPermissions") permissions: UserPermissions;
	@Getter("getCanAddIndividualActionsOverride") canAddIndividualActionsOverride: boolean;
	@Getter getFeature: (featureNames: string[]) => boolean;

	@Tasks.Action completeTask: (payload: CompleteTaskPayload) => Promise<void>;
	@Tasks.Action fetchEventTasks: ({ eventId }) => Promise<void>;
	@Tasks.Action assignTask: (payload) => Promise<void>;
	@Tasks.Action assignAllTasks: (payload) => Promise<void>;

	@Tasks.Getter getOutstandingRequiredTaskCount: number;

	@SiteMonitor.Mutation setActivity: () => void;
	@SiteMonitor.Getter("getEventShare") eventShares: any[];
	@SiteMonitor.Mutation private setEventCloseShown: (visible: boolean) => void;

	private showCompletedTasks: boolean = false;
	private addingNewTask: boolean = false;

	/** Getters */

	/**
	 * Returns an array of tasks marked as complete.
	 */
	public get completedTasks() {
		return this.taskGroup.tasks.filter(task => task.completed);
	}

	/**
	 * Returns an array of tasks, and depending on if we're hiding completed tasks, filters those out.
	 */
	public get visibleTasks() {
		const sortedTasks = sortBy(this.taskGroup.tasks, ["order"]);
		if (this.canCollapseCompletedTasks) {
			return sortedTasks.filter(task => (this.showCompletedTasks ? true : !task.completed));
		} else {
			return sortedTasks;
		}
	}

	/**
	 * Returns true if this task group has any incomplete tasks.
	 */
	public get hasActiveTasks() {
		return this.taskGroup.tasks.filter(task => !task.completed).length > 0;
	}

	/**
	 * Checks if the user has permission to add individual actions, or just action groups.
	 */
	public get canAddIndividualActions() {
		return this.canAddIndividualActionsOverride ? true : this.permissions.canAddIndividualActions;
	}

	/**
	 * Returns true if the user has the 'collapse completed actions' feature enabled.
	 */
	public get canCollapseCompletedTasks() {
		return this.getFeature(["Actions", "CollapseCompletedActions"]);
	}

	/** Events */

	@Emit("add-task")
	public onAddTask(task: EventTask) {
		this.onCancelAddingTask();
	}

	/** Methods */

	/**
	 * Handler for when the user cancels adding a new task.
	 */
	public onCancelAddingTask() {
		this.addingNewTask = false;
	}

	/**
	 * Handler for when the user completes (or resets) a task.
	 */
	public async onCompleteTask(completedTaskLine: any) {
		this.focusTask(completedTaskLine.eventTaskID);

		const completeTaskPayload: CompleteTaskPayload = {
			EventID: completedTaskLine.eventID,
			EventTaskID: completedTaskLine.eventTaskID,
			TaskComplete: completedTaskLine.completed,
			Result: completedTaskLine.result || "",
			taskData: JSON.stringify(completedTaskLine.taskData)
		};
		await this.completeTask(completeTaskPayload);
		await this.setActivity();
		if(completedTaskLine.completed){
			setTimeout(() => {
				this.$emit("completed-task")
			}, 200);
		}
	}

	/**
	 * Handler for when the user assigns a task to another user within this event.
	 */
	public async onAssignTaskToUser(eventTaskID: string, eventUserID: string) {
		await this.assignTask({
			eventID: this.eventId,
			eventTaskID: eventTaskID,
			eventUserID: eventUserID
		});

		await this.fetchEventTasks({ eventId: this.eventId });
		this.setActivity();
	}

	/**
	 * Handler for when the user assigns all the tasks within a group to another user within this event.
	 */
	public async onAssignAllTasks(categoryTitle: string, eventUserID: string) {
		await this.assignAllTasks({
			eventID: this.eventId,
			taskCategoryTitle: categoryTitle,
			eventUserID: eventUserID
		});

		await this.fetchEventTasks({ eventId: this.eventId });
		this.setActivity();
	}

	public async onTaskCloseEvent(taskCompleted: ActionPlanTask): Promise<void> {
		await this.fetchEventTasks({ eventId: this.eventId });

		// Open close modal if no outstanding required tasks.
		if (this.getOutstandingRequiredTaskCount === 0) {
			this.setEventCloseShown(true);
		}
	}

	private focusTask(task: string){
		this.$emit("focusTask", task)
	}
}
