
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import NavHeader from "@/components/NavHeader.vue";
import FeatureTree from "@/components/setup/FeatureTree.vue";
import { UserPermissions, FeatureTreeNode } from "@/store/types";
import { get } from "lodash";
import vSelect from "vue-select";
import { Tenant } from "../store/areas/types";
import { cloneDeep } from "lodash";
import api from "@/services/api.service";
import { stringMixin } from "@/mixins";

const { isNullOrWhitespace } = stringMixin.methods;

@Component({
	components: {
		"nav-header": NavHeader,
		"feature-tree": FeatureTree,
		VuePerfectScrollbar,
		"vue-select": vSelect
	}
})
export default class FeatureSetup extends Vue {
	@Getter getPermissions: UserPermissions;
	@Action loadFeaturesList: any;

	private allFeatures: any[] = [];
	private changedFeaturesList: any[] = [];
	private showConfirmation: boolean = false;
	private configDownload: any;
	private isFeaturesConfigControlledByFile: boolean = false;
	private tenantsList: any[] = [];
	private tenant: Tenant = null;
	private saveButtonDisabled: boolean = false;
	private searchTerm: string = "";

	@Watch("tenant")
	private async onTenantChange(value: Tenant) {
		this.allFeatures = await api.loadAllFeatures(get(value, "groupID", -1));
		this.searchTerm = "";
	}

	async mounted() {
		this.changedFeaturesList = [];
		this.searchTerm = "";
		await this.setup();
	}

	public async loadAllTenants() {
		this.tenantsList = (await api.getTenants()).data;

		// set the tenant id based on the isUsersTenant value
		this.tenantsList.forEach(tenant => {
			if (tenant.isUsersTenant) {
				this.tenant = tenant;
			}
		});
	}

	/** Allows unit test to function properly */
	private async setup() {
		this.isFeaturesConfigControlledByFile = await api.getIsFeaturesConfigControlledByFile();
		await this.loadAllTenants();
	}

	private async featureChanged(feature: any) {
		// Called when a feature is changed at any level. Changed feature name is parsed and added to a changelist.
		if (feature.enabled) {
			// Logic to handle when feature is being turned on
			let featuresToEnable = [];
			let fullFeatureName = feature.name;
			featuresToEnable.push(feature.name);

			// Split the feature name out to check for parent features to enable
			while (fullFeatureName.includes("/")) {
				let lastSplitIndex = fullFeatureName.lastIndexOf("/");
				fullFeatureName = fullFeatureName.substr(0, lastSplitIndex);
				featuresToEnable.push(fullFeatureName);
			}

			// Check if feature being changed already exists in changelist
			featuresToEnable.forEach(name => {
				const changedFeature = this.changedFeaturesList.find(changedFeature => changedFeature.name == name);

				// If feature exists in changelist, then turn it on, else push it to the list
				if (changedFeature) {
					changedFeature.enabled = true;
				} else {
					this.changedFeaturesList.push({
						name: name,
						enabled: true
					});
				}
			});
		} else {
			// Logic to handle turning off features
			const firstFeature = this.changedFeaturesList.find(changedFeature => changedFeature.name == feature.name);

			// If feature exists in changelist, then turn it off, else push it to the list
			if (firstFeature) {
				firstFeature.enabled = false;
			} else {
				this.changedFeaturesList.push({
					name: feature.name,
					enabled: false
				});
			}

			// Find all children of feature being turned off
			const childFeatures = this.changedFeaturesList.filter(changedFeature =>
				changedFeature.name.startsWith(feature.name)
			);

			// Set the children features to off
			childFeatures.forEach(ele => {
				ele.enabled = false;
			});
		}
	}

	private async save() {
		this.saveButtonDisabled = true;
		try {
			await api.toggleFeatures(this.changedFeaturesList, this.tenant.groupID);
			this.showConfirmation = false;
			// Reload the feature list to ensure components that use it are updated.
			await this.loadFeaturesList();
			this.allFeatures = await api.loadAllFeatures(get(this.tenant, "groupID", -1));
			this.changedFeaturesList = [];
		} finally {
			this.saveButtonDisabled = false;
		}
	}

	/**
	 * Updates the current featureList with the changed values from the UI.
	 */
	private updateFeaturesLocally(updatedFeatures: any) {
		if (updatedFeatures.length < 1) return;

		updatedFeatures.forEach(element => {
			let featureTree: any = element.name.split("/");
			let FeatureList = this.configDownload;
			let toggleResult = element.enabled;

			featureTree.forEach(element => {
				if (featureTree[featureTree.length - 1] == element) {
					FeatureList = FeatureList.find(x => x.name == element);
					FeatureList.enabled = toggleResult;
					return;
				} else {
					FeatureList = FeatureList.find(x => x.name == element).children;
				}
			});
		});
	}

	/**
	 * Method that maps over all the parents children to check if its enabled.
	 */
	private mapEnabledChildFeatures(features: any) {
		let mappedFeatures = features
			.map(feature => {
				if (!feature.enabled) return null;

				if (feature.children.length > 0) {
					feature.children = this.mapEnabledChildFeatures(feature.children);
				}

				return feature;
			})
			.filter(x => {
				return x != null;
			})
			.map(feature => {
				return { Feature: feature.name, Children: feature.children, GroupID: 1 };
			});

		return mappedFeatures;
	}

	/*
	* Getter for the list that is used in the FeatureTree
	* If there is a search term this is the filtered list
	* If not then all features from the store
	*/
	private get getFeatures(): FeatureTreeNode[] {
		if (!isNullOrWhitespace(this.searchTerm)) {
			return this.filterFeatures;
		}
		else {
			return this.allFeatures;
		}
	}

	/*
	*	Driving method for the search
	*/
	private get filterFeatures(): FeatureTreeNode[] {
		let matchingFeatures: FeatureTreeNode[] = [];

		// Loop over all top level elements
		this.allFeatures.forEach(feature => {
			let matchingFeature: FeatureTreeNode = this.findFeatures(feature);

			// If the feature (including any of its children) match then add to the list
			// of matching features
			if (matchingFeature) {
				matchingFeature.expanded = true;
				matchingFeatures.push(matchingFeature);
			}
		});

		return matchingFeatures;
	}

	/*
	* Recursive function to search the feature tree for features whose description or name contains
	* the search term
	*/
	private findFeatures(feature: FeatureTreeNode): FeatureTreeNode {
		let matchedFeature: FeatureTreeNode = null;
		let matchingChildFeature: FeatureTreeNode = null;
		let matchFound: boolean = false;

		// Check if the current nodes description matches the search term
		let currentFeatureMatches = this.currentFeatureMatches(feature);

		if (currentFeatureMatches) {
			matchFound = true;
		}

		// Get the feature as even if it doesn't match
		// it would be needed to attach any matching children to
		matchedFeature = cloneDeep(feature);
		matchedFeature.children = [];

		// if there are children
		if (feature.children && feature.children.length > 0) {
			// check if there is a match in any of the children
			feature.children.forEach(childFeature => {
				matchingChildFeature = this.findFeatures(childFeature);

				// if there is a match then add it to the parent
				if (matchingChildFeature) {
					matchFound = true;
					matchingChildFeature.expanded = true;
					matchedFeature.children.push(matchingChildFeature);
				}
			});
		}

		// if a match has been found return the matching object
		if (matchFound) {
			return matchedFeature;
		} else {
			return null;
		}
	}

	/*
	* Helper method used to check a features description or name contains the search term
	*/
	private currentFeatureMatches(feature: FeatureTreeNode): boolean {
		const lowerCaseSearchTerm: string = this.searchTerm.toLowerCase();

		return (feature.description &&
			(feature.description.toLowerCase().includes(lowerCaseSearchTerm) ||
			feature.name.toLowerCase().includes(lowerCaseSearchTerm)));
	}

	/**
	 * Method to get the desired format and download json.
	 */
	private downloadJson() {
		this.configDownload = JSON.parse(JSON.stringify(this.allFeatures));

		// Iterate through changed features and map the changes to the main list.
		this.updateFeaturesLocally(this.changedFeaturesList);

		let config = this.mapEnabledChildFeatures(this.configDownload);

		let element = document.createElement("a");
		element.setAttribute("href", "data:text/plain;charset=utf-8," + JSON.stringify(config));

		element.setAttribute("download", "Functionality.json");
		element.style.display = "none";
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	private expandAllFeatures(): void {
		this.allFeatures.push();
		this.allFeatures.forEach(feature => {
			feature.expanded = true;
		});
	}
}
