import { Module } from "vuex";
import { RootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import getDefaultState from "./state";
import { MobileRaiseState } from "./types";

const state: MobileRaiseState = getDefaultState();

const namespaced: boolean = true;

const mobile: Module<MobileRaiseState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};

export default mobile;
