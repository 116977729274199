
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import {
	MobileRaise,
	MobileRaiseLinkEditModel,
	IconSet
} from "@/store/mobile-raise-templates/types";
import { raiseIdsEqual } from "@/store/mobile-raise-templates/typesFunctions";
import IconsSelector from "./IconsSelector.vue";
import RaiseTitle from "./RaiseTitle.vue";
import { VueBootstrapField } from '@/store/types';

const titleListFormatter = (titleList: string[]) => titleList.join();

const fields = [
	{
		label: "Raise Title",
		key: "titleList",
		formatter: titleListFormatter
	},
	{ label: "Type", key: "displayType" },
	{ label: "Icon", key: "iconSetTitle" },
	{ label: "Add To Template", key: "addedToTemplate", sortable: false },
];

interface LinksSelectorRow extends MobileRaiseLinkEditModel
{
	displayType: string;
	iconSetTitle: string;
	addedToTemplate: boolean;
}

const MobileRaiseTemplates = namespace("mobileRaiseTemplates");

@Component(
{
	components: {
		"icons-selector": IconsSelector,
		"raise-title": RaiseTitle
	}
})
export default class LinksSelector extends Vue
{
	@MobileRaiseTemplates.State mobileRaises: MobileRaise[];
	@MobileRaiseTemplates.Getter getIconSetById: (id: number) => IconSet;
	@Prop() linkedRaises: MobileRaiseLinkEditModel[];
	@Prop() isVisible: boolean;

	@Getter("getFeature") private getFeature: (featureName: string[]) => boolean;

	private rows: LinksSelectorRow[] = [];
	private iconForRow: LinksSelectorRow = null;
	private isIconsSelectorVisible = false;
	private searchTerm: string = "";
	private autoShareField: VueBootstrapField = { label: "Auto Share User", key: "autoShare", sortable: false };

	private get fields(): VueBootstrapField[] {
		if (this.autoShareEnabled)
		{
			return [...fields, this.autoShareField];
		}
		else
		{
			return fields;
		}
	}

	@Watch("isVisible", { immediate: true })
	onIsVisibleChanged(val: boolean, oldVal: boolean): void {
		if (val) {
			this.rows = this.mobileRaises.map(r => {
				const templateLink = this.linkedRaises.find(lr => raiseIdsEqual(r, lr));
				const addedToTemplate = !!templateLink;
				const iconSetID = addedToTemplate ? templateLink.iconSetID : null;
				const autoShare = addedToTemplate ? templateLink.autoShare : false;
				return {
					...r,
					displayType: r.manualRaiseType ? "Manual Raise" : "Activity Log Category",
					addedToTemplate,
					iconSetID,
					iconSetTitle: this.getIconTitle(iconSetID),
					autoShare,
				};
			});
		}
	}

	private get autoShareEnabled(): boolean {
		return this.getFeature(["Mobile", "MobileRaise", "AutoShare"]);
	}

	private handleAddToTemplateToggle(row: LinksSelectorRow, checked: boolean): void {
		row.addedToTemplate = checked;

		// Reset auto share flag when removed from template.
		if (!checked)
		{
			row.autoShare = checked;
		}
	}

	private handleAutoShareToggle(row: LinksSelectorRow, checked: boolean): void {
		row.autoShare = checked;
	}

	public onApplyClick(): void {
		const linkedModels: MobileRaiseLinkEditModel[] = this.rows
			.filter(r => r.addedToTemplate);
		this.$emit("apply", linkedModels);
	}

	private onHide(): void {
		this.$emit("hide");
	}

	private getIconTitle(id: number | null): string {
		if (id === null)
		{
			return "Use default";
		}
		const iconSet = this.getIconSetById(id);
		return iconSet.title;
	}

	private onIconsButtonClick(row: LinksSelectorRow): void {
		this.iconForRow = row;
		this.isIconsSelectorVisible = true;
	}

	private onIconSelected(iconSetID: number | null): void {
		this.iconForRow.iconSetID = iconSetID;
		this.iconForRow.iconSetTitle = this.getIconTitle(iconSetID);
	}
}
