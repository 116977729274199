
import { Component, Vue, Prop } from "vue-property-decorator";
import { UserGroup } from "@/store/user-management/types";
import { uniqueId } from "lodash";
import vSelect from "vue-select";

@Component({
	components: {
		"vue-select": vSelect
	}
})
export default class UserGroupsControl extends Vue {
	@Prop() disabled: boolean;
	@Prop() options: UserGroup[];
	@Prop() value: UserGroup[];

	private get getId() {
		return uniqueId("template-usergroups-input-");
	}

	private onInput(values: UserGroup[]) {
		this.$emit("input", values);
	}
}
