import { getDefaultState } from "./state";
import { MutationTree } from "vuex";
import { ResponseActionsState, ResponseActionType } from "./types";

export const mutations: MutationTree<ResponseActionsState> = {
	setSupportedActionTypes(state: ResponseActionsState, data: ResponseActionType[]) {
		state.supportedActionTypes = data;
	},
	clearState(state: ResponseActionsState) {
		state = getDefaultState();
	},
};
