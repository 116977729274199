import { ActionTree } from "vuex";
import { FieldOps } from "./types";
import { RootState } from "../types";
import api from "@/services/api.service";
import {
	convertStringToLatLng,
} from "@sureview/v2-mapping-saas";
import { AssetMapLayerItem } from "@/components/mobile-assets/types";
import { SubscriberExpiry } from "@/types/EnhancedSingleMapTypes";

export const actions: ActionTree<FieldOps, RootState> = {
    async loadOnlineAssets({ commit, state }){
		const assets = await api.getMapLayerAssets();
		var assetAndUsers = assets.map(i => ({
				...i,
				latLng: i.latLong ? convertStringToLatLng(i.latLong) : i.latLong,
			}));

		// For every asset we have manually changed since the last poll
		state.manuallyChangedAssets.forEach(ignoreAsset => {
			// if the asset exists in our update list, then set it to be our current state
			var index = assetAndUsers.findIndex(i => i.assetId === ignoreAsset.mapLayerItem.assetId);
			if(index > -1) {
				// only map the location changes to ensure we don't suppress any other changes
				assetAndUsers[index] =
				{
					...assetAndUsers[index],
					latLong :ignoreAsset.mapLayerItem.latLong,
					latLng :ignoreAsset.mapLayerItem.latLng,
				};
			}
			// If the item does not exist inside the array then the backend cache hasn't caught up. Add it
			else {
				assetAndUsers.push(ignoreAsset.mapLayerItem);
			}

			// Reduce our item 'lifespan' (we dont want to keep using the cached version forever)
			commit("addOrUpdateManuallyChangedAsset",
			{
				...ignoreAsset,
				checksRemaining: ignoreAsset.checksRemaining -1
			});

			// Clear out the asset if it has expired (will be updated on next poll)
			if(ignoreAsset.checksRemaining < 1){
				commit("removeManuallyChangedAsset", ignoreAsset);
			}
		});

        commit("setOnlineAssets", assetAndUsers)
    },
	async initAssetPoll({ commit }){
		commit("setTimer", null);
	},
    async startAssetPoll({ commit, state, dispatch }){
		if(state.timer) {
			// If we get calls to start a new asset poll, check if the active timer
			// has got stuck, if it has spin clear it out and spin up a new one
			const timeNow: any = Date.now() - 6000;
			if(state.lastPollCall && state.lastPollCall < timeNow){
				commit("setTimer", null);
				commit("setLastPollCall", null)
			} else {
				return;
			}
		}

        const poll = () => {
			var timer =  setTimeout(async () => {
				try {
					commit("setLastPollCall", Date.now())
					if(!state.activeSubscribers || state.activeSubscribers.length === 0){
						throw "no active subscribers"
					}
					await dispatch("loadOnlineAssets");
					await dispatch("endTimer");
					poll();
				} catch(ex) {
					commit("setTimer", null);
					if(ex.message == "no active subscribers")
						console.log(ex)
					else
						throw ex;
				}
			}, 5000)
			commit("setTimer", timer)
		};
		poll();
    },
	async addOrUpdateActiveSubscriber({ commit, dispatch }, subscriberExpiry: SubscriberExpiry){
		commit("addOrUpdateActiveSubscriber", subscriberExpiry);
		dispatch("startAssetPoll");
	},
	async endTimer({ commit, state }){
		commit("cleanUpSubscribers");
		if(!state.activeSubscribers || state.activeSubscribers.length === 0){
			commit("setTimer", null);
		}
	},
    async loadAssetTypes({ commit }){
        const assetTypesData = await api.getCachedAssetTypes();
        var assetTypes = new Map<string, any>();
		assetTypesData.forEach(ut => {
			assetTypes.set(ut.assetTypeId.toString(), ut);
		});
        commit("setAssetTypes", assetTypes);
    },
	async updateAssetLocation({ commit, state }, asset: AssetMapLayerItem){
        // Create a clone to update so we can push all the UI changes in one call
		var index = state.onlineAssets.findIndex(i => i.assetId === asset.assetId);

        if(index === -1){
            return;
		}

		var cloneAsset = { ...state.onlineAssets.find(i => i.assetId === asset.assetId) };

		// Set location fields
		cloneAsset.latLng = asset.latLng;
		cloneAsset.latLong = asset.latLong;
		cloneAsset.hideOnMap = asset.hideOnMap;

		commit("addOrUpdateManuallyChangedAsset",{
			mapLayerItem: asset,
			checksRemaining: 5
		});

		await api.updateAssetMapLayerItem(cloneAsset);

		commit("updateAsset", cloneAsset);
	},
	async loadSituationalAwarenessEventQueue({ commit }){
		try{
			const situationalEventQueueData = await api.getSituationalAwarenessQueue();
			commit("setSituationalAwarenessEventQueue", situationalEventQueueData);
		} catch (e) {
			console.log("Failed to get Situational Awareness Event Queue");
		}
	}
};
