
import { EventSearchState } from "./types";

export function getDefaultState(): EventSearchState {
    return {
        Events: [],
        SearchableEventTypes: [],
        AlarmTypes: [],
        Categories: [],
        DeviceTypes: [],
        Operators: [],
        EventTags: [],
        searching: false,
        IncidentReportOpen: false,
        IncidentReportDownloaded: false,
		EventRecordsWithClips: [],
    }
}
