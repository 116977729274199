import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { ResponseActionsState } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<ResponseActionsState, RootState> = {
	async fetchSupportActionTypes({ commit }) {
		const data = await api.getSupportedActionTypes();
		commit("setSupportedActionTypes", data);
	},
};
