import ActionState from "@/types/sv-data/events/ActionState";
import { EventsQueueState } from "./types";

export function getDefaultState(): EventsQueueState {
	return {
		AlarmQueueFilters: [],
		AlarmQueueFilterIDs: [],
		FilteredEvents: [],
		FilteredEventIds: [],
		CurrentSort: {
			sort: 0,
			desc: false,
			changed: false,
			reversed: false
		},
		AlarmActiveFilter: {
			filterId: -1,
			regionId: null
		},
		maxEventsToShow: 200,
		EventsLastUpdated: 0,
		HoverEvent: null,
		DisplayedEvent: null,
		NewFilterModalShown: false,
		ManualPatrolShown: false,
		ManualActionShown: false,
		ActivityLogShown: false,
		SiteTree: [],
		ChangedTreeNodes: [],
		selectedGroups: [],
		treeSelectedGroup: {},
		searchSelectedGroup: {},
		queueMapBounds: null,
		activeAlarmCount: 0,
		eventCountsByType: {
			parked: 0,
			patrols: 0,
			inProcess: 0,
			onTest: 0
		},
		eventQueueGetOverride: null,
		processingError: null,
		userZone: {
			groupID: 0,
			parentGroupID: 0,
			title: "",
			timeZoneID: 0,
			selected: false
		},
		lastEventHandled: null,
		RoutingGroups: [],
		AlarmTags: [],
		ServerEventTypes: [],
		filteredServerEventTypes: null,
		HandlingEvent: false,
		advancedTreeSearchCancelToken: null,
		JoiningEventID: null,
		TourNoteRequired: false,
		hideMapFlag: false,
		lostConnectionCount: 0,
		eventQueueUpdateInfoHistory: [],
		debugMode: false,
		pendingEventPickup: null,
		selectedGroup: null,
		peekEventId: null,
		bulkHandleShown: false,
		virtualOperatorControlPanelShown: false,
		allAlarmsSelected: false,
		selectedAlarms: [],
		refreshQueue: false,
		closingAction: ActionState.Undefined,
		eventQueuePriorityColors: []
	};
}
