import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "./vuex-shared-mutations";
import { getDefaultState } from "./state";
import { getters as generalGetters } from "./getters";
import { actions as generalActions } from "./actions";
import { mutations as generalMutations } from "./mutations";

import { emails } from "./emails/index"
import { csvParser } from "./csv-parser";
import { eventqueue } from "./eventqueue/index";
import { siteMonitor } from "./site-monitor/index";
import { siteMonitorCameras } from "./site-monitor-cameras/index";
import { tasks } from "./tasks/index";
import { sessions } from "./sessions/index";
import { postLoginAppBootstrap } from "./plugins";
import { mapLayers } from "./map-layers/index";
import { userManagement } from "./user-management/index";
import { devices } from "./devices/index";
import { responses } from "./responses/index";
import { eventSearch } from "./event-search/index";
import { mobile } from "./mobile/index";
import { views } from "./views/index";
import { reports } from "./reports/index";
import { areaNotes } from "./area-notes/index";
import { activityLog } from "./activity-log/index";
import { areas } from "./areas/index";
import { tours } from "./tours/index";
import { GenericTable } from "./generic-table/index";
import { manualRaise } from "./manual-raise/index";
import mobileRaiseTemplates from "./mobile-raise-templates";
import mobileRaise from "./mobile-raise";
import { subscription } from "./subscription/index";
import { signup } from "@/store/signup";
import { externalMapLayers } from "./external-map-layers/index";
import { caseManagement } from "./case-management/index"
import { csvFormHandler } from "./csv-form-handler"
import { alarmMasking } from "./alarm-masking/index";
import { userContext } from "./user-context/index";
import { guardChat } from "./guard-chat";
import { fieldOps } from "./field-ops/index";
import { setup } from "./setup/index";
import { responseActions } from "./response-actions/index";
import { customFields } from "./custom-fields/index";
import { systemHealth } from "./system-health";
import { rulesEngine } from "./rules-engine";
import { distanceConfiguration } from "./distance-configuration";
import { schedules } from "./schedules";
import { alarmPoints } from "./alarm-points";
import { areaEventHistory } from "./area-event-history";
const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

// @todo
// 2. thinking we could have a section of state called `UI` that would represent the state of various UI elements
// (e.g. look at all the state properties that end with `Open`)
// 3. thinking about creating CCure, Groups, Event, EventShare modules

export default new Vuex.Store({
	state: getDefaultState(),
	getters: {
		...generalGetters
	},
	mutations: {
		...generalMutations
	},
	actions: {
		...generalActions
	},
	modules: {
		fieldOps,
		eventqueue,
		siteMonitor,
		tasks,
		userManagement,
		siteMonitorCameras,
		sessions,
		mapLayers,
		eventSearch,
		devices,
		responses,
		mobile,
		views,
		reports,
		activityLog,
		areas,
		tours,
		areaNotes,
		GenericTable,
		manualRaise,
		mobileRaiseTemplates,
		mobileRaise,
		subscription,
		signup,
		emails,
        externalMapLayers,
        caseManagement,
		csvParser,
		csvFormHandler,
		alarmMasking,
		guardChat,
		userContext,
		setup,
		responseActions,
		systemHealth,
		rulesEngine,
		distanceConfiguration,
		schedules,
		alarmPoints,
		customFields,
		areaEventHistory,
	},
	plugins: [
		// @ref https://github.com/robinvdvleuten/vuex-persistedstate
		createPersistedState({
			paths: [
				"User",
				"featuresList",
				"timeZones",
				"fieldOps",
				"eventqueue.alarmQueueFooter",
				"eventqueue.hideMapFlag",
				"siteMonitor.hideMapFlag",
				"activeRegion",
				"siteMonitor.EventDetails",
				"signup",
				"mobile",
				"siteMonitor.deviceServerDetails",
				"siteMonitor.applianceServerDetails",
				"siteMonitor.eventOutcomes",
				"siteMonitorCameras.deviceControllerClips",
				"siteMonitorCameras.layoutIndex",
				"elevationLabels",
				"systemHealth.selectedFilter",
				"mapLayers.advancedFilters",
				"tenantSystemPrefs",
				"customFields",
				"areaEventHistory",
			]
		}),
		postLoginAppBootstrap,
		createMutationsSharer({
			predicate: [
				"logout",
				"setFeaturesList",
				"setTenantSystemPrefs",
				"SetTimeZones",
				"setActiveRegion",
				"siteMonitor/setIsСontroller",
				"siteMonitor/setActiveMapItems",
				"siteMonitor/setActiveMapItemsRequired",
				"siteMonitor/setMapCircleCenter",
				"siteMonitor/setEventDetails",
				"siteMonitor/setSelectedEventRecord",
				"siteMonitor/setActivity",
				"siteMonitor/setEventOutcomes",
				"siteMonitorCameras/setRequiresEventDetails",
				"siteMonitorCameras/setAwaitingCamera",
				"siteMonitorCameras/setLinkRecord",
				"siteMonitorCameras/setCameraLink",
				"siteMonitorCameras/setPushContents",
				"siteMonitorCameras/setShownAuditClips",
				"siteMonitorCameras/setDeviceControllerClips",
				"siteMonitorCameras/setLayoutIndex",
				"tours/setAllTours",
				"tours/unsetTour",
				"fieldOps/setViewingAssetId",
				"fieldOps/addOrUpdateActiveSubscriber",
				"fieldOps/cleanUpSubscribers",
				"fieldOps/setTimer",
				"fieldOps/setLastPollCall",
				"mobile/setUserAssetStatus",
				"mobile/setUserAssetMapItem",
				"mobile/setDeviceLocation",
				"mobile/setEventShareItems",
				"mobile/setActiveEventShareItem",
				"mobile/setNavigationMode",
				"mobile/setNavigateToActiveAlarm",
				"mobile/setNavigationLocation",
				"mobile/setFocusedEventID",
				"mobile/setChatTab",
				"mobile/setChatOpen",
				"setElevationLabels",
				"systemHealth/setStatusFilter",
				"mapLayers/setAdvancedFilters",
				"customFields/setAreaCustomFields",
				"customFields/setContactCustomFields",
				"customFields/setAlarmCustomFields",
				"areaEventHistory/setIsPopoutModeEnabled",
				"areaEventHistory/setAreaEventHistory",
				"areaEventHistory/setFilter",
				"areaEventHistory/setTimeZone",
				"areaEventHistory/setTotalRecords",
			],
			sharingKey: "vuex-mutations-sharer",
			storageKey: "vuex-mutation-sharer-storage",
			excludedPages: [
				"eventsearch"
			]
		})
	],
	strict: debug
});
