import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { UserManagementState } from "./types";
import { RootState } from "@/store/types";

export const actions: ActionTree<UserManagementState, RootState> = {

	async getUserRoles({ commit }) {
		const userRoles = await api.getUserRoles();
		commit("setUserRoles", userRoles);
		return userRoles;
	},

	async loadPermissions({ commit }) {
		commit("setPermissions", await api.permissionsGet());
	},

	async loadUserGroups({ commit }) {
		const userGroups = await api.getUserGroup();
		commit("setUserGroups", userGroups);
	},

	async fetchUserGroupDictionary({ commit }) {
		const userGroupDictionary = await api.getUserGroupDictionary();
		commit("setUserGroupDictionary", userGroupDictionary);
	},

	async fetchUserRoleDictionary({ commit }) {
		const userRoleDictionary = await api.getUserRoleDictionary();
		commit("setUserRoleDictionary", userRoleDictionary);
	},

	async fetchPermissionsState({ dispatch }) {
		dispatch("loadPermissions");
		dispatch("loadUserGroups");
		dispatch("fetchUserGroupDictionary");
		dispatch("fetchUserRoleDictionary");
	}

};
