
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { ClipType } from "@/store/site-monitor-cameras/types";
import { EventDetails } from "@/store/site-monitor/types";
import { SessionResource } from "@/store/sessions/types";
import { FeaturesList} from "@/store/types";
import { get } from "lodash";
import { stringMixin, formatDateMixin } from '@/mixins';
import moment from "moment";
import { truncateString } from '@/filters';

const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Session = namespace("sessions");
const { ellipseAfterX } = stringMixin.methods;
const { convertUtcDateTimeToLocalDateTimeString } = formatDateMixin.methods;

@Component({
	filters: { truncateString },
	components: {}
})
export default class ClipsList extends Vue {
	@SiteMonitor.Getter("getEventDetails") getEventDetails: EventDetails;
	@SiteMonitor.Getter("getAuditService") auditService: any;
	@SiteMonitor.Getter("getSelectedEventRecord") selectedEventRecord: any;
	@SiteMonitor.Getter("getIsController") isController: any;

	@SMCameras.Getter("getLayoutIndex") layoutIndex!: number;
	@SMCameras.Getter("getLayouts") layouts!: number[][];

	@SMCameras.Getter("getAllMatrixContents") getAllMatrixContents: any;
	@SMCameras.Getter("getMediaMatrixIsNew") mediaMatrixIsNew: any;

	@SMCameras.Getter("getDeviceControllerClips") clips!: ClipType[];

	@SMCameras.Mutation setDeviceControllerClips: any;

	@SMCameras.Mutation setAwaitingClip: any;
	@SMCameras.Mutation highlightCell: any;
	@SMCameras.Mutation unhighlightCell: any;

	@SMCameras.Action updateMediaMatrixContents: any;

	@Session.Getter getSession: any;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	private _getClipsTimeout: NodeJS.Timeout | null;
	private _updateMediaListTimeout: NodeJS.Timeout | null;

	private clipsFailed: boolean = false;
	private clipsFailedStatus: string = "";
	private noClipsAvailable: boolean = false;
	private previousClips: string[] = [];

	private filter: string = "";

	open: boolean = true;

	public get eventId() {
		return this.getEventDetails ? this.getEventDetails.eventID : 0;
	}

	@Watch("eventId")
	public onEventChanged(eventId: number, oldEventId: number) {
		// stop loop for getting clips list
		this.clearDownUpdateMediaListAndGetClipsTimeouts();

		// reset media information
		this.mediaEventID = 0;
		this.currentEventRecordID = 0;
	}

	@Watch("selectedEventRecord")
	public onSelectedEventRecordChanged(value: any, oldValue: any) {
		this.clipsFailed = false;
		this.clipsFailedStatus = "";
		this.noClipsAvailable = false;
		this.previousClips = [];

		this.clearDownUpdateMediaListAndGetClipsTimeouts();

		if (value) {
			// set media id to -1 to get clips from other event records
			this.mediaEventID = -1;
			this.getMediaList(value.eventRecordID, value.attachedCameras);
		}
	}

	@Watch("mediaMatrixIsNew")
	public onMediaMatrixIsNewChanged(value: any, oldValue: any) {
		if (value && !oldValue) {
			if (this.selectedEventRecord) {
				this.getMediaList(this.selectedEventRecord.eventRecordID, this.selectedEventRecord.attachedCameras);
			}
		}
	}

	mounted() {
		this.gettingClips = false;
		if (this.selectedEventRecord != null) {
			this.getMediaList(this.selectedEventRecord.eventRecordID, this.selectedEventRecord.attachedCameras);
		}
	}

	public beforeDestroy() {
		this.clearDownUpdateMediaListAndGetClipsTimeouts();
	}

	public get layoutHorizontal() {
		return this.layouts[this.layoutIndex][0];
	}

	public get layoutVertical() {
		return this.layouts[this.layoutIndex][1];
	}

	public get filteredClips() {
		if (this.filter == null || this.filter == "") {
			return this.clips;
		}

		const filter = this.filter.toLowerCase();
		return this.clips.filter((c: any) => c.DeviceTitle.toLowerCase().includes(filter));
	}

	// Gets the class for the clip list item
	public getClipListItemStyle(clip: any) {
		if (clip.FileSizeError) return "redClip";

		if (clip.Error != null) return "orangeClip";

		if (this.isSelectedClip(clip)) return "selectedCamera";

		return "";
	}

	// Returns true if there is any errors in the clip or if it's below the minimum filesize
	public isClipError(clip: any) {
		if (clip.Error != null || clip.FileSizeError) return true;
		return false;
	}

	public isSelectedClip(clip: any) {
		return !!clip && clip.index !== null && !isNaN(clip.index);
	}

	private get isAutoPopulateClipsEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "AutoPopulate"]);
	}

	private get isPerformAutomations(): boolean {
		return get(this.featuresList, ["Alarms", "CameraAutomation"]);
	}

	public cameraIconColor(camera: any, i: number, j: number) {
		if ((i - 1) * this.layoutHorizontal + j === camera.index) return "redCameraIndicator";

		if (camera.FileSizeError) return "redClip";

		if (camera.Error != null) return "orangeClip";

		return "greyCameraIndicator";
	}

	public clipSelect(clip: any) {
		if (this.isController) {
			this.setAwaitingClip({
				eventRecordID: clip.EventRecordId,
				clip: clip
			});
		}
	}

	private mediaEventID: number = 0;
	private currentEventRecordID: number = 0;
	public getMediaList(eventRecordID: number, cameras: any[], skipRecordChecks: boolean = false) {
		if (this.eventId == 0) {
			return;
		}
		if (!skipRecordChecks) {
			if (eventRecordID == this.currentEventRecordID) {
				console.log(
					"Already fetching for record " + eventRecordID + " (Skip checks: " + skipRecordChecks + ")"
				);
				return;
			}

			console.log("Set event record to " + eventRecordID + " (EventID: " + this.eventId + ")");
			this.currentEventRecordID = eventRecordID;
		}

		this.clearDownUpdateMediaListAndGetClipsTimeouts();
		let auth = this.getSession(SessionResource.AuditServiceSession);
		if (auth) {
			this.mediaEventID = this.eventId;
			this.getClips(eventRecordID, cameras, true);
		} else {
			console.log("Auth not available");
			this.currentEventRecordID = 0;
			//Auth not ready yet - wait.
			this._updateMediaListTimeout = setTimeout(() => {
				console.log("Retrying with auth ... ");
				this.getMediaList(eventRecordID, cameras);
			}, 400);
		}
	}

	private gettingClips: boolean = false;
	public getClips(eventRecordID: number, cameras: any[], addUpdatedClips: boolean) {
		var auth = this.getSession(SessionResource.DeviceServiceSession);

		if (!this.auditService) {
			console.log("No audit service available.");
			return;
		}

		if (this.selectedEventRecord == null || eventRecordID != this.selectedEventRecord.eventRecordID) {
			if (this.selectedEventRecord == null) console.log("No selected event record.");
			else
				console.log(
					"Incorrect record request - Selected " +
						this.selectedEventRecord.eventRecordID +
						", but requested " +
						eventRecordID
				);

			return;
		}

		// if we're already getting clips
		if (this.gettingClips) {
			console.log("Already fetching clips.");
			return;
		}

		// mark that we're getting clips; prevent duplicate operations.
		this.gettingClips = true;

		// do request to audit service to get media list.
		this.auditService.mediaList(auth, eventRecordID, this.isAutoPopulateClipsEnabled, !this.isPerformAutomations).then(
			(response: any) => {
				let checkForClipsInSeconds = 5;
				try {
					// get the raw clips
					var rawClips = response.data;

					if (rawClips) {
						this.clipsFailed = false;
						this.clipsFailedStatus = "";
						this.noClipsAvailable = rawClips.length == 0;
					} else {
						rawClips = [];
						this.noClipsAvailable = true;
						this.clipsFailed = false;
						this.clipsFailedStatus = "";
					}

					var filteredClips = [];
					// iterate over the raw clips to filter out anything that doesnt have the info we need.
					for (var i = 0; i < rawClips.length; i++) {
						var clip = rawClips[i];
						// filter out any clips that dont have deviceid OR Device Title OR StartTime OR MediaType
						if (
							clip.MediaType != "RAW DATA" &&
							clip.FileIdentifier &&
							(clip.DeviceID || clip.DeviceTitle || clip.StartTime)
						) {
							// add clip to filtered list
							filteredClips.push(clip);
						}
					}
					this.noClipsAvailable = filteredClips.length == 0;

					// Create the uniqueFileIdentifiers and sort by EventRecordId
					let clips: ClipType[] = filteredClips.map(clip =>{ return {...clip, UniqueFileIdentifier: clip.FileIdentifier + clip.EventRecordId}})
						.sort((firstRecord, secondRecord) => (firstRecord.EventRecordId > secondRecord.EventRecordId) ? 1 : (firstRecord.EventRecordId < secondRecord.EventRecordId) ? -1 : 0);


					// Configure the DateTime and the clip count
					clips.forEach((clip) => {
						let clipsPerRecord = clips.filter(c => c.EventRecordId == clip.EventRecordId);
						clip.Subtitle = `(${clipsPerRecord.map(c => c.UniqueFileIdentifier).indexOf(clip.UniqueFileIdentifier) + 1}/${clipsPerRecord.length})`;
					});

					// setup matrix
					this.transformDeviceControllerClips(clips);

					// filter out any clips already on the matrix
					var newClips = this.removeAuditClipsAlreadyInMatrix(clips);

					// populate matrix with any new clips or cameras
					this.$emit("clipsRetrieved", { eventRecordID, cameras, newClips });

					// if nothing returned, check again in 2s else check in 5
					// this has changed to account for threaded flv conversion
					if (clips.length == 0) {
						checkForClipsInSeconds = 2;
					}
				} catch (err) {
					console.error(err);
				}

				this.gettingClips = false;

				this.clearDownGetClipsListTimeouts();
				this._getClipsTimeout = setTimeout(() => {
					this.getClips(eventRecordID, cameras, addUpdatedClips);
				}, checkForClipsInSeconds * 1000);
			},
			(errorResponse: any) => {
				let checkForClipsInSeconds = 5;

				if (
					errorResponse != null &&
					errorResponse.response &&
					errorResponse.response.status == 500 &&
					errorResponse.response.data.toLowerCase().indexOf("system.io.directorynotfoundexception") > -1
				) {
					this.noClipsAvailable = true;
					this.clipsFailed = false;
					this.clipsFailedStatus = "";

					checkForClipsInSeconds = 2; // If the call succeeded, but got an error with no clips, then re-request sooner
				} else {
					this.noClipsAvailable = false;
					this.clipsFailed = true;

					if (errorResponse && errorResponse.response && errorResponse.response.statusText) {
						this.clipsFailedStatus = errorResponse.response.statusText;
					} else {
						var auditProtocol = this.auditService.protocol;
						var currentProtocol = location.protocol;

						if (currentProtocol.toLowerCase() == "https:" && auditProtocol.toLowerCase() == "http:") {
							this.clipsFailedStatus = "Unable to access clips due to security restrictions";
						} else {
							this.clipsFailedStatus = "Failed to retrieve clips";
						}
					}
				}

				// on error
				try {
					// blank down matrix
					this.transformDeviceControllerClips([]);
					this.$emit("clipsRetrieved", {
						eventRecordID,
						cameras,
						newClips: []
					});

					this.gettingClips = false;

					this.clearDownGetClipsListTimeouts();
					this._getClipsTimeout = setTimeout(() => {
						this.getClips(eventRecordID, cameras, addUpdatedClips);
					}, checkForClipsInSeconds * 1000);
				} catch (err) {
					console.log(err);
				}
			}
		);
	}

	private clearDownUpdateMediaListAndGetClipsTimeouts() {
		/// <summary>Clear any timeouts set for update media list or get clips</summary>
		this.clearDownUpdateMediaList();
		this.clearDownGetClipsListTimeouts();
	}

	private clearDownUpdateMediaList() {
		/// <summary>Clear any timeouts set for update media list</summary>
		if (this._updateMediaListTimeout) {
			clearTimeout(this._updateMediaListTimeout);
			this._updateMediaListTimeout = null;
		}
	}

	private clearDownGetClipsListTimeouts() {
		/// <summary>Clear any timeouts set for get clips</summary>
		if (this._getClipsTimeout) {
			clearTimeout(this._getClipsTimeout);
			this._getClipsTimeout = null;
		}
	}

	public get matrixClips(): { [clipIdentifier: string]: number } {
		let matrixClips: { [clipIdentifier: string]: number } = {};

		let matrixContents = this.getAllMatrixContents;
		// iterate over current cells in the matrix
		for (var matrixIndex in matrixContents) {
			if (matrixContents[matrixIndex].clip !== undefined) {
				// get the cells clip
				let clip = matrixContents[matrixIndex].clip.clip;
				if (clip != null) {
					// add index value to the array under the clips unique file identifier
					let clipIdentifier = clip.UniqueFileIdentifier;
					matrixClips[clipIdentifier] = parseInt(matrixIndex);
					this.previousClips.push(clipIdentifier);
				}
			}
		}

		return matrixClips;
	}

	private removeAuditClipsAlreadyInMatrix(clips: any) {
		/// <summary>Looks at the unique file identifiers and removes any that are already in the matrixes content</summary>
		/// <param name="clips">array of new clips to parse</param>

		let clipsToReturn = [];
		if (clips) {
			let matrixClips: { [clipIdentifier: string]: number } = this.matrixClips;

			// iterate over each clip
			for (var i = 0; i < clips.length; i++) {
				let item = clips[i];
				let clipIdentifier = item.UniqueFileIdentifier;

				// if we cant find a cell with this unique file identifier
				if (matrixClips[clipIdentifier] == undefined && !this.previousClips.includes(clipIdentifier)) {
					// add this clip to the clips to return.
					clipsToReturn.push(item);

					this.previousClips.push(clipIdentifier);
				}
			}
		}

		return clipsToReturn;
	}

	public transformDeviceControllerClips(clips: ClipType[]) {
		if (clips) {
			let matrixClips: { [fileIdentifier: string]: number } = {};
			let matrixContents = this.getAllMatrixContents;

			for (var matrixIndex in matrixContents) {
				if (matrixContents[matrixIndex].clip !== undefined) {
					let clip = matrixContents[matrixIndex].clip.clip;
					let clipIdentifier = clip.UniqueFileIdentifier;
					if (clip != null) {
						matrixClips[clipIdentifier] = parseInt(matrixIndex);
					}
				}
			}

			clips.forEach((item: any) => {
				try {
					let clipIdentifier = item.UniqueFileIdentifier;
					if (matrixClips[clipIdentifier]) {
						Vue.set(item, "index", matrixClips[clipIdentifier]);
					} else {
						// Checks if there is a placeholder view that the video can replace.
						// If not, it will be pushed to the end. (This should only happen on first load)

						for (let i = 0; i < clips.length; i++) {
							if (matrixClips["Placeholder" + i]) {
								Vue.set(item, "index", i + 1);
								delete matrixClips["Placeholder" + i];

								this.updateMediaMatrixContents({
									index: i + 1,
									contents: {
										clip: {
											eventRecordID: item.EventRecordId,
											clip: item
										}
									},
									isController: true,
									eventID: this.eventId
								});

								break;
							} else {
								Vue.set(item, "index", null);
							}
						}
					}
				} catch (err) {
					console.error(err);
				}
			});
		}
		this.setDeviceControllerClips(clips);
	}

	public clipTitle(title: string): string {
		return ellipseAfterX(title, 30);
	}

	public clipLocalDate(date: string): string {
		let momentDate = moment(new Date(date));
		return momentDate.format("DD MMMM YYYY hh:mm:ss A");
	}
}
