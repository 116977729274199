import { ActionTree } from "vuex";
import { ManualRaiseResult, ManualRaiseState } from "./types";
import { RootState } from "@/store/types";
import api from "@/services/api.service";

export const actions: ActionTree<ManualRaiseState, RootState> = {
	clearSettings({ commit }) {
		commit("setOnCompleteHandler", null);
	},

	async raiseManualAlarm({ commit, dispatch, state }, { payload, autoHandle, raiseAndJoin = false, templateId = null })
	{
		try
		{
			let result: ManualRaiseResult;

			if (raiseAndJoin)
			{
				result = await api.raiseAndJoinManualAlarm(payload, templateId);
			}
			else
			{
				result = await api.raiseManualAlarm(payload);
			}

			let errorMessage = "";

			// Set error message for multiple variants
			if (result == null)
			{
				errorMessage = "Unable to raise alarm.";
			}
			else if (result.eventID == null)
			{
				if (!result.groupArmed)
				{
					errorMessage = "Alarm not raised - area is masked.";
				}
				else if (!result.responseArmed)
				{
					errorMessage = "Alarm not raised - alarm point is masked.";
				}
				else
				{
					errorMessage = "Alarm not raised.";
				}
			} else if (result.responseAutohandle)
			{
				errorMessage = "Alarm raised, but marked as log-only.";
			}

			if (errorMessage.length)
			{
				// Register error & stop processing
				commit("eventqueue/setProcessingError", errorMessage, { root: true });
				return;
			}

			// If we've got a hook to execute on complete...
			if (state.onCompleteHandler && typeof state.onCompleteHandler == "function")
			{
				// Run the hook and clear out the handler and any default values
				state.onCompleteHandler(result);
				dispatch("clearSettings");
			}

			if (autoHandle)
			{
				dispatch(
					"eventqueue/handleEvent",
					{
						eventID: result.eventID,
						checkInProcessing: false,
						pickupMethod: "Picked up from Manual Raise"
					},
					{ root: true }
				);
			}
			else
			{
				commit("setManualRaiseShown", false);
				commit("setAreaMapBounds", null);
			}
		} catch (error)
		{
			commit("setProcessingError", "Error - Event not raised");
		}
	}
};
