import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { TourState, Tour } from "./types";
import { RootState } from "@/store/types";

export const actions: ActionTree<TourState, RootState> = {
	/** Tour Setup */
	async fetchAllTours({ commit }) {
		let { data } = await api.fetchAllTours();

		// Clear out
		commit("setAllTours", []);

		data = data.map(obj => ({ ...obj, functionality: null }));

		if (data.length > 0) {
			commit("setAllTours", data);
		}
	},
	async fetchDeviceList({ commit }, groupID: number) {
		const { data } = await api.fetchDeviceList(groupID);
		commit("setAllDevices", data);
	},
	async searchDeviceList({ commit }, query: any) {
		const { data } = await api.searchDeviceList(query.groupID, query.searchText);
		commit("addSearchDevices", data);
	},
	async removeTour({ commit }, tourId: number) {
		const { data } = await api.deleteTour(tourId);
		if (data) {
			commit("unsetTour", tourId);
		}
	},
	triggerActiveCell({ commit }, confirmed: boolean) {
		commit("setTriggeredActiveCell", confirmed);
	},
	triggerStreamingData({ commit }, object: object) {
		commit("setTriggeredIsStreaming", object);
	}
};
