
// Vue
import { TimeZone } from "@/store/types";
import { Schedule, ScheduleType, ScheduleTableRow } from "@/store/schedules/types";
import { PaginatedSearchQueryParams, } from "@/store/types";
import { Component, Watch, Mixins } from "vue-property-decorator";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { namespace, State, Getter } from "vuex-class";
import { debounce } from "lodash";
import axios, { CancelTokenSource } from "axios";
import SureViewIcon from "../SureViewIcon.vue";
import PaginatedSearch from "@/mixins/PaginatedSearch";
import api from "@/services/api.schedules.service";

// Namespaces
const SchedulesStore = namespace("schedules");

const debouncePeriod: number = process.env.NODE_ENV === "test" ? 1 : 500;

@Component({
	components: {
		"generic-table": GenericTable,
		"sureview-icon": SureViewIcon,
	},
})

export default class SchedulesTable extends Mixins(PaginatedSearch) {
	// State
	@State private timeZones: TimeZone[];
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;

	@SchedulesStore.State("selectedGroup") private selectedGroup: number;
	@SchedulesStore.State("searchString") private searchString: string;
	@SchedulesStore.State("selectedScheduleTypes") private selectedScheduleTypes: number[];
	@SchedulesStore.State scheduleTypes: ScheduleType[];
	@SchedulesStore.State("isReloadRequired") private isReloadRequired: boolean;
	@SchedulesStore.State("enabledScheduleFilter") private enabledScheduleFilter: boolean | null;

	// Mutations
	@SchedulesStore.Mutation setIsReloadRequired: (isReloadRequired: boolean) => void;
	@SchedulesStore.Mutation setIsScheduleModalVisible: (isVisible: boolean) => void;
	@SchedulesStore.Mutation setTempSchedule: (tempSchedule: Schedule) => void;
	@SchedulesStore.Mutation setIsDeleteConfirmationVisible: (isVisible: boolean) => void;

	/**
	*	@returns Table columns
	*/
	public get columns (): TableHeader[] {
		return [
			{
				title: "Title",
				key: "title",
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Schedule title",
				searchable: false,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
			{
				title: "Area",
				key: "areaTitle",
				order: 2,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Schedule area",
				searchable: false,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
			{
				title: "Next Run Date (My Time)",
				key: "nextRunLocalTime",
				order: 3,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Next Run Date in local time",
				searchable: false,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
			{
				title: "Next Run Date (Area Time)",
				key: "nextRunAreaTime",
				order: 4,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Next Run Date in area time",
				searchable: false,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
			{
				title: "Schedule Type",
				key: "scheduleType",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Type of schedule",
				searchable: false,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
			{
				title: "Disabled",
				key: "disabled",
				order: 6,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Is the schedule going to be run",
				searchable: false,
				visible: true,
				dataType: "checkbox",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			}
		];
	}

	private schedules: ScheduleTableRow[] = [];
	private totalRecords: number = 0;
	private isLoading: boolean = false;
	private updateToken: CancelTokenSource | null = null;

	// Getter
	private get isDisabled(): boolean {
		return this.selectedGroup <= 0;
	}

	private get locale(): string {
		const { locale } = Intl.DateTimeFormat().resolvedOptions();
		return locale;
	}

	private get isMaskingEnabled(): boolean {
		return this.getFeature(["Schedules", "Masking"]);
	}

	private get isEventRaiseEnabled(): boolean {
		return this.getFeature(["Schedules", "EventRaise"]);
	}

	// Public
	public async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<void> {
		try {
			this.isLoading = true;
			this.schedules = [];

			if (!this.selectedGroup){
				return;
			}

			this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams)

			let schedulesWithPagination = await api.getPaginatedSchedules(
				this.mostRecentSearchParams.page,
				this.mostRecentSearchParams.pageSize,
				this.mostRecentSearchParams.sortBy,
				this.mostRecentSearchParams.sortDesc,
				this.selectedGroup,
				this.searchString,
				this.selectedScheduleTypes,
				this.enabledScheduleFilter);

			// if nothing returned exit early
			if (!schedulesWithPagination){
				return;
			}

			this.totalRecords = schedulesWithPagination.totalRecords;

			this.schedules = schedulesWithPagination.data.map(s => {

				let nextOccurrenceDisplay = "";
				let nextOccurrenceAreaTimeDisplay = "";

				if (s.nextOccurrence != null) {
					const nextOccurrence = new Date(`${s.nextOccurrence}Z`);
					nextOccurrenceDisplay = nextOccurrence.toLocaleString(this.locale);
				}

				if (s.nextOccurrenceAreaTime != null) {
					const nextOccurrenceAreaTime = new Date(s.nextOccurrenceAreaTime);
					nextOccurrenceAreaTimeDisplay =  nextOccurrenceAreaTime.toLocaleString(this.locale)
				}

				return {
					title: s.title,
					nextRunLocalTime: nextOccurrenceDisplay,
					nextRunAreaTime: nextOccurrenceAreaTimeDisplay,
					scheduleId: s.scheduleId,
					scheduleType: this.scheduleTypes[s.scheduleTypeId - 1].title,
					areaTitle: s.areaTitle,
					disabled: s.disabled,
					parentSchedule: s as Schedule
				} as ScheduleTableRow;
			});
		}
		catch (ex) {
			console.error("Unexpected error fetching schedules: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	// Private
	private async triggerGetEvents(): Promise<void> {
		await this.performGetSchedules();
	}

	private performGetSchedules: () => Promise<void> = debounce(
		async function () {
			if (this.updateToken) {
				this.updateToken.cancel("Cancel Search");
			}

			this.updateToken = axios.CancelToken.source();
			try {
				await this.updateData(this.mostRecentSearchParams);
			} catch (e) {
				return; //Return when past requests are cancelled
			}
		},
		debouncePeriod,
		{ leading: false, trailing: true }
	);

	private getTimeZone(timeZoneId: number): TimeZone {
		return this.timeZones.firstOrDefault(tz => tz.timeZoneID == timeZoneId);
	}

	private onEditClick(row: ScheduleTableRow): void {
		this.setTempSchedule(row.parentSchedule);
		this.setIsScheduleModalVisible(true);
	}

	private onDeleteClick(row: ScheduleTableRow): void {
		this.setTempSchedule(row.parentSchedule);
		this.setIsDeleteConfirmationVisible(true);
	}

	private canDelete(row: ScheduleTableRow): boolean {
		if(!this.isMaskingEnabled && row.scheduleType == "Masking"){
			return false;
		}

		if(!this.isEventRaiseEnabled && row.scheduleType == "Event Raise"){
			return false;
		}

		return true;
	}

	private async mounted() : Promise<void> {
		await this.updateData();
	}

	// Watchers
	@Watch("isReloadRequired")
	private async onReloadRequiredChanged(changeRequired: boolean): Promise<void> {
		if (changeRequired) {
			await this.triggerGetEvents();
			this.setIsReloadRequired(false);
		}
	}
}
