
	import { Prop, Component, Vue } from "vue-property-decorator";
	import GenericTable, { TableHeader } from "./../table/generic-table.vue";
	import { namespace, Getter } from "vuex-class";
	import vSelect from "vue-select";
	import { PaginatedSearchQueryParams,  UserPermissions} from "@/store/types";
	import { CustomFieldDto, CustomFieldTableTypes, CustomFieldForDisplay } from "@/store/custom-fields/types";
	import { CustomFieldTypesForDisplay } from "@/types/sv-data/custom-fields/Constants";
	import CustomFieldUpdateView from "@/components/custom-fields/CustomFieldModalUpdateView.vue";
	import CustomFieldDeleteView from "@/components/custom-fields/CustomFieldModalDeleteView.vue";
	import { cloneDeep } from "lodash";

	const Subscription = namespace("subscription");
	const CustomFields = namespace("customFields");

	@Component({
		components: {
			"generic-table" : GenericTable,
			"v-select": vSelect,
			"custom-field-modal-update-view": CustomFieldUpdateView,
			"custom-field-modal-delete-view": CustomFieldDeleteView,
		}
	})

	export default class CustomFieldTableView extends Vue {
		$refs!: {
			customFieldUpdateView: CustomFieldUpdateView;
			customFieldDeleteView: CustomFieldDeleteView;
		}

		@Prop({ required: true }) public customFieldTableTypeId: CustomFieldTableTypes;
		@Subscription.Getter private isSubscriptionActive: boolean;

		@Getter("getPermissions")
    	private permissions: UserPermissions;

		@CustomFields.Action private retrieveCustomFields: ({ tableType: number, live: boolean }) => Promise<void>;
		@CustomFields.State private areaCustomFields: CustomFieldDto[];
		@CustomFields.State private contactCustomFields: CustomFieldDto[];
		@CustomFields.State private alarmCustomFields: CustomFieldDto[];
		@CustomFields.State private totalCustomFields: number;

		private isLoading: boolean = false;
		private customFieldList: CustomFieldDto[] = [];
		private customFieldListForDisplay: CustomFieldForDisplay[] = [];
		private customFieldListPage: CustomFieldDto[] = [];
		private selectedCustomField: CustomFieldDto = this.createEmptyCustomField();
		private customFieldToUpdate: CustomFieldDto = this.createEmptyCustomField();
		private actionTitle: string = "";
		private isTableViewShown: boolean = false;
		private isUpdateViewShown: boolean = false;
		private isDeleteViewShown: boolean = false;
		private totalRecords: number = 0;
		private pageNumber = 1;
		private pageSize = 25;
		private searchTerm = "";
		private sortBy: "id" | "customFieldTableTypeId" | "title" | "customFieldType" | "required" | "placeholder" | "order" = "order";
		private sortDesc: boolean = true;

		private readonly addActionTitle = "Add";
		private readonly editActionTitle = "Edit";

		private async showTableView(isDelete: boolean): Promise<void> {
			this.isUpdateViewShown = false;
			this.isDeleteViewShown = false;
			this.isTableViewShown = true;
			await this.retrieveCustomField();
			if (isDelete) {
				this.$emit("update-data");
			}
		}

		public async showCustomFieldModal(): Promise<void> {
			this.isTableViewShown = true;
			try
			{
				await this.retrieveCustomField();
			} catch (e) {
				console.error("Failed to get custom fields");
			}
		}

		public hideCustomFieldModal(): void {
			this.isTableViewShown = false;
			this.$emit("onModalClose");
		}

		public get hasCanEditCustomFieldsPermission(): boolean {
			return this.permissions.canViewCustomFields;
		}

		private mapCustomFieldsForTable(): void {
			this.customFieldListForDisplay = [];
			this.customFieldList.forEach(field => {
				var customFieldForDisplay: CustomFieldForDisplay = {
					...field,
					customFieldType: CustomFieldTypesForDisplay.find(cf => cf.customFieldTypeId == field.customFieldTypeId)?.typeName
				}
				this.customFieldListForDisplay.push(customFieldForDisplay);
			})
		}

		private get customFieldPage(): CustomFieldForDisplay[] {
			var customFieldListPage = this.customFieldListForDisplay;
			if (this.searchTerm != "") {
				customFieldListPage = customFieldListPage.filter(e => e.title.toLowerCase().includes(this.searchTerm) ||
					e.customFieldType.toLowerCase().includes(this.searchTerm) ||
					e.placeholder.toLowerCase().includes(this.searchTerm));
					this.totalRecords = customFieldListPage.length;
			}

			if (customFieldListPage.length > 1) {
				customFieldListPage.sortBy(this.sortBy, this.sortDesc);
			}

			return customFieldListPage.slice(((this.pageNumber - 1) * this.pageSize), (this.pageNumber * this.pageSize));
		}

		private updatePage(paginatedSearchQueryParams?: PaginatedSearchQueryParams): void {
			this.pageNumber = paginatedSearchQueryParams.page;
			this.pageSize = paginatedSearchQueryParams.pageSize;
			this.searchTerm = paginatedSearchQueryParams.searchTerm.toLowerCase();
			this.sortDesc = paginatedSearchQueryParams.sortDesc;

			if (paginatedSearchQueryParams.sortBy == "id" ||
				paginatedSearchQueryParams.sortBy == "title" ||
				paginatedSearchQueryParams.sortBy == "customFieldType" ||
				paginatedSearchQueryParams.sortBy == "required" ||
				paginatedSearchQueryParams.sortBy == "placeholder" ||
				paginatedSearchQueryParams.sortBy == "order") {
				this.sortBy = paginatedSearchQueryParams.sortBy;
			}
		}

		private async retrieveCustomField(): Promise<void> {
			try {
				this.isLoading = true;
				await this.retrieveCustomFields({ tableType: CustomFieldTableTypes.Alarm, live: true });

				switch (this.customFieldTableTypeId) {
				case CustomFieldTableTypes.Area:
					this.customFieldList = this.areaCustomFields;
					break;
				case CustomFieldTableTypes.Contact:
					this.customFieldList = this.contactCustomFields;
					break;
				case CustomFieldTableTypes.Alarm:
					this.customFieldList = this.alarmCustomFields;
					break;
				}

				this.totalRecords = this.totalCustomFields;
				this.mapCustomFieldsForTable();

			} catch (ex) {
				console.error("Unexpected error fetching custom fields: " + ex);
			} finally {
				this.isLoading = false;
			}
		}

		private createEmptyCustomField(): CustomFieldDto {
			return {
				id: null,
				customFieldTableTypeId: 0,
				title: "",
				customFieldTypeId: null,
				required: false,
				placeholder: "",
				order: 0
			}
		}

		private clearSelectedCustomField(): void {
			this.selectedCustomField = this.createEmptyCustomField();
		}

		private showDeleteView(customField: CustomFieldDto): void {
			this.selectedCustomField = customField;
			this.isTableViewShown = false;
			this.isDeleteViewShown = true;
		}

		private showAddView(): void {
			this.actionTitle = this.addActionTitle;
			this.selectedCustomField = this.createEmptyCustomField();
			this.selectedCustomField.order = 0;
			this.isTableViewShown = false;
			this.isUpdateViewShown = true;
		}

		private showUpdateView(customField: CustomFieldDto): void {
			this.actionTitle = this.editActionTitle;
			this.selectedCustomField = this.getExistingCustomFieldValues(customField);
			this.customFieldToUpdate = customField;
			this.isTableViewShown = false;
			this.isUpdateViewShown = true;
		}

		private getExistingCustomFieldValues(customField: CustomFieldDto): CustomFieldDto {
			this.selectedCustomField = cloneDeep(customField);

			return this.selectedCustomField;
		}

		private columns: TableHeader[] = [
			{
				title: "Title",
				key: "title",
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Title",
				isTermLabel: true,
				dataType: "input",
				visible: true,
				sortable: true,
				searchable: true
			},
			{
				title: "Type",
				key: "customFieldType",
				order: 2,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Type",
				isTermLabel: true,
				dataType: "input",
				visible: true,
				sortable: true,
				searchable: true
			},
			{
				title: "Required",
				key: "required",
				order: 3,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Required",
				isTermLabel: true,
				dataType: "checkbox",
				visible: true,
				sortable: true
			},
			{
				title: "Placeholder",
				key: "placeholder",
				order: 4,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "placeholder",
				isTermLabel: true,
				dataType: "input",
				visible: true,
				searchable: true,
			},
			{
				title: "Order",
				key: "order",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Order",
				isTermLabel: true,
				dataType: "input",
				visible: true,
				sortable: true
			}
		];
	}
