
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import Sessions from "@/components/Sessions.vue";
import EventSearch from "@/components/EventSearch.vue";
import EventReview from "@/components/EventReview.vue";
import { Event } from "@/store/event-search/types";

import NavHeader from "@/components/NavHeader.vue";
import api from "@/services/api.service";

import { FeaturesList } from "@/store/types";
import { get } from "lodash";
import { SessionResource } from "@/store/sessions/types";

const EventSearchStore = namespace("eventSearch");
const SiteMonitorStore = namespace("siteMonitor");

@Component({
	components: {
		"event-search": EventSearch,
		"event-review": EventReview,
		session: Sessions,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"nav-header": NavHeader
	}
})
export default class EventSearchPage extends Vue {
	@Getter("getFeaturesList") featuresList: FeaturesList;


	@EventSearchStore.Getter events: Event[];
	@EventSearchStore.Action loadEvents: (searchFilter: any) => Promise<void>;
	@EventSearchStore.Getter searching: boolean;
	@EventSearchStore.Mutation setEvents: any;
	@EventSearchStore.Mutation removeEventFromSearchResults: (eventId: number) => Promise<void>;

	@SiteMonitorStore.Mutation resetEventRecords: any;

	public selectedEvent = null;
	public makeSearchRequest = false;
	public eventGoto = "";
	private searchPending: boolean = false;

	private auditServiceSessionResourceId = SessionResource.AuditServiceSession;

	public get selectedEventId() {
		return this.selectedEvent && this.selectedEvent.eventID ? this.selectedEvent.eventID : 0;
	}

	public get isEmptySearchResults() {
		return this.makeSearchRequest && this.events.length === 0;
	}

	public get isSearchCollapsed() {
		return this.makeSearchRequest && this.events.length > 0;
	}

	public get isEventFlaggingEnabled() {
		return get(this.featuresList, ["Alarms", "SiteMonitor", "EventFlagging"], false);
	}

	public async mounted() {
		if (this.$route.params.eventId) {
			var eventId = parseInt(this.$route.params.eventId);
			if (!isNaN(eventId)) this.selectedEvent = (await api.loadEventDetails(eventId)).data;
		}
	}

	public toSearch() {
		this.$router.push({ path: `/eventsearch` });
	}

	public async gotoEvent() {
		if (this.eventGoto) {
			var eventId = parseInt(this.eventGoto);
			if (!isNaN(eventId) && eventId < Number.MAX_SAFE_INTEGER) {
				var eventDetails = (await api.loadEventDetailsDirect(eventId)).data;
				if (eventDetails && eventDetails.closed) {
					this.makeSearchRequest = false;
					this.resetEventRecords();
					this.setEvents([]);

					this.selectedEvent = eventDetails;
					this.$router.push({
						path: `/eventsearch/${this.eventGoto}`
					});
					return;
				}
			}

			if (!eventDetails.closed) {
				this.$notify({
					type: "error",
					title: "Error",
					text: "This event is still being processed"
				});
			} else {
				this.$notify({
					type: "error",
					title: "Error",
					text: "This is not a valid ID"
				});
			}
		}
	}

	public beforeDestroy() {
		this.resetEventRecords();
		this.setEvents([]);
	}

	public eventSelect(event: any) {
		this.resetEventRecords();

		this.$set(this, "selectedEvent", event);
	}

	public async onSearch(searchFilter: any): Promise<void> {
		this.searchPending = true;
		try
		{
			await this.loadEvents(searchFilter);
			this.makeSearchRequest = true;
			this.selectedEvent = null;
		}
		catch(ex)
		{
			console.error(ex)
		}
		this.searchPending = false;
	}

	private eventPreserveUpdated(isPreserved: boolean): void {
		this.selectedEvent.preserve = isPreserved;
	}

	private clearEventSelectAndRemoveFromResults(eventId: number): void {
		this.selectedEvent = null;
		this.removeEventFromSearchResults(eventId);
	}

	private get showSearchButton() {
		return this.$route?.params?.eventId ?? false;
	}

	private onGoToEventPage(eventId: number): void {
		this.eventGoto = `${eventId}`;
		this.gotoEvent();
	}
}
