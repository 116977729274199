
import { ResponseAction, ResponseActionIndexed, ResponseActionType, ResponseActionTypeId } from "@/store/response-actions/types";
import vselect3 from "vselect3";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import api from "@/services/api.service";
import { DeviceDetails, DeviceType } from "@/store/devices/types";
import { FeaturesList, UserPermissions } from "@/store/types";
import { get } from "lodash";
import ResponseExtended from "@/types/sv-data/alarms/ResponseExtended";
import ResponseActionTable from "./ResponseActionTable.vue";

const responseActions = namespace("responseActions");
interface settingsMetaJson
{
	isJson?: boolean;
	numberOfSeconds: number;
	recordPreAlarmFootage: boolean;
	recordQuality: string;
	secondsToGoBack: number;
}
@Component({
    components: {
		"v-select-3": vselect3,
		ResponseActionTable
	},
})
export default class ResponseActionSetup extends Vue {
	@Prop({required: true}) value: ResponseAction[];
	@Prop({required: true}) groupID: any;

	@Getter("getPermissions")
    private permissions: UserPermissions;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@responseActions.Action('fetchSupportActionTypes') fetchSupportActionTypes: () => void;
	@responseActions.State('supportedActionTypes') supportedActionTypes: ResponseActionType[];
	@responseActions.Mutation('clearState') clearState: () => void;

	private displayAddAutomationForm: boolean = false;
	private availableCameras: DeviceDetails[] = [];
	private recordQuality: string[] = [ "Lowest", "Low", "Default", "Medium", "High", "Highest" ];
	private isResponseActionEdit: boolean = false;
	private canPreAlarm: boolean = false;

	// Automation type
	private type: ResponseActionType = null;

	// Automation Camera device
	private selectedCamera: number | null = null;

	// Settings Meta
	private numberOfSeconds: number | null = null;
	private selectedRecordQuality: string = "Default";
	private isPreAlarm: boolean = false;
	private secondsToGoBack: number | null = null;

	// Automation type for tracking edit
	private originalType: ResponseActionType = null;

	// Automation Camera device for tracking edit
	private originalSelectedCamera: number = null;

	// Settings Meta for tracking edit
	private originalNumberOfSeconds: number | null = null;
	private originalRecordQuality: string = "Default";
	private originalSecondsToGoBack: number | null = null;

	// Current Response
	private response: ResponseExtended = null;

	// ResponseActions for current response
	private responseActions: ResponseAction[] = [];

	// ResponseAction being edited
	private responseActionToEdit: ResponseAction = null;
	private responseActionEditIndex: number = null;

    // The maximum automations allowed for this Response
	private automationLimit: number = 0;

	private async created(): Promise<void> {
		if (this.value) {
			this.init();
		}
	}

	private beforeDestroy(): void {
		this.clearState();
	}

	private async init(): Promise<void> {
		this.getAlarmActions();
		this.fetchSupportActionTypes();
		await this.getCameras();
		await this.getAutomationLimit();
	}

	private showTable(): boolean {
		return !!get(this.featuresList, ["Alarms", "CameraAutomation"]) &&
			(!!this.permissions.canViewAutomations || !!this.permissions.canEditAutomations);
	}

	@Watch("groupID")
	private async getAutomationLimit(): Promise<void> {
		if (this.groupID) {
			this.automationLimit = await api.getAutomationLimit(this.groupID);
		}
	}

	private get hasEditAutomations(): boolean {
		return !!get(this.featuresList, ["Alarms", "CameraAutomation"]) && !!this.permissions.canEditAutomations;
	}

	private get maxAutomationsReached(): boolean {
		return this.responseActions ? this.responseActions.length >= this.automationLimit : false;
	}

	private async getAlarmActions(): Promise<void> {
		this.responseActions = this.value ?? [];
	}

	private async getCameras(): Promise<void> {
		this.availableCameras = await api.getExtendedDevicesByType(this.groupID, DeviceType.Camera, null);
	}

	public get addButtonValid(): boolean {
		return this.displayAddAutomationForm &&
		!!this.type &&
		!!this.type.responseActionTypeId &&
		!!this.selectedCamera &&
		!!this.selectedRecordQuality &&
		!!this.numberOfSeconds &&
		(this.isPreAlarm ? !!this.secondsToGoBack : true);
	}

	public get editButtonValid(): boolean {
		return this.addButtonValid && (
			this.type !== this.originalType ||
			this.selectedRecordQuality.toLowerCase() !== this.originalRecordQuality.toLowerCase() ||
			this.selectedCamera !== this.originalSelectedCamera ||
			this.numberOfSeconds !== this.originalNumberOfSeconds ||
			(this.isPreAlarm ? this.secondsToGoBack !== this.originalSecondsToGoBack : true
		));
	}

	public async submitResponseActon(): Promise<void> {
		this.isResponseActionEdit ? await this.editResponseAction() : await this.addResponse();
	}

	private generateActionDescription(): string
	{
		const recordPrefix = this.isPreAlarm ? "Pre" : "Post";
		const deviceTitle = this.selectedCamera ? this.availableCameras.find(ac => ac.deviceID == this.selectedCamera)?.title : "";
		return `<***>Unsaved</***>:  ${recordPrefix}-record camera <***>${deviceTitle}</***>, capturing <***>${this.numberOfSeconds} seconds </***> of footage`;
	}

	public async addResponse(): Promise<void> {
		var newResponseAction: ResponseAction = {
			armed: false,
			description: this.generateActionDescription(),
			deviceTitle: this.selectedCamera ? this.availableCameras.find(ac => ac.deviceID == this.selectedCamera)?.title : "",
			objectID: this.selectedCamera,
			orderNum : 0,
			responseID: null,
			responseActionID: 0, 	// This might be null if we edit a newly created action that has not yet been saved
			responseActionTypeID: this.type.responseActionTypeId,
			settingsMeta: this.getSettingsMeta()
		};

		this.responseActions.push(newResponseAction);
		this.updatedResponseAction(newResponseAction);
	}

	public async editResponseAction(): Promise<void> {
		var updatedResponseAction: ResponseAction = {
			armed: this.responseActionToEdit.armed,
			description: this.generateActionDescription(),
			deviceTitle: this.selectedCamera ? this.availableCameras.find(ac => ac.deviceID == this.selectedCamera)?.title : "",
			objectID: this.selectedCamera,
			//orderNum: not mapped
			responseID: this.responseActionToEdit.responseID,
			responseActionID: this.responseActionToEdit.responseActionID ?? null, 	// This might be null if we edit a newly created action that has not yet been saved
			responseActionTypeID: this.type.responseActionTypeId,
			settingsMeta: this.getSettingsMeta()
		};
		this.updatedResponseAction(updatedResponseAction);
	}

	private getSettingsMeta(): string {
		return JSON.stringify({
			isJson: true,
			numberOfSeconds: this.numberOfSeconds,
			recordPreAlarmFootage: this.isPreAlarm,
			recordQuality: this.selectedRecordQuality,
			secondsToGoBack: this.secondsToGoBack
		});
	}

	private async triggerEditResponseAction(responseActionIndexed: ResponseActionIndexed): Promise<void> {
		this.displayAddAutomationForm = true;
		this.isResponseActionEdit = true;
		const responseActionToEdit = this.responseActions[responseActionIndexed.index];
		this.responseActionEditIndex = responseActionIndexed?.index ?? null;

		if (responseActionToEdit) {
			this.type = this.supportedActionTypes.firstOrDefault((sat: ResponseActionType) => sat.responseActionTypeId === responseActionToEdit.responseActionTypeID);
			this.selectedCamera = responseActionToEdit.objectID;
			this.setSettingsMetaFromResponseAction(responseActionToEdit.settingsMeta);

			// Ensure original values are available for tracking changes
			this.responseActionToEdit = responseActionToEdit;
			this.originalType = this.type;
			this.originalSelectedCamera = this.selectedCamera;
			this.originalNumberOfSeconds = this.numberOfSeconds;
			this.originalRecordQuality = this.selectedRecordQuality;
			this.originalSecondsToGoBack = this.secondsToGoBack;
		}
	}

	private setSettingsMetaFromXML(settingsMeta: string): void
	{
		// Record Quality
		const recordQuality = settingsMeta.match(new RegExp("<Quality>(.*)</Quality>"))[1];

		if (!recordQuality) {
			this.selectedRecordQuality = "default";
		} else {
			this.selectedRecordQuality = recordQuality;
		}

		// Number of Seconds
		const numOfSeconds = settingsMeta.match(new RegExp("<NumImages>(.*)</NumImages>"))[1];
		if (!numOfSeconds || isNaN(+numOfSeconds)) {
			this.numberOfSeconds = null;
		} else {
			this.numberOfSeconds = +numOfSeconds;
		}

		// Pre-Alarm
		const preAlarm = settingsMeta.match(new RegExp("<RecordPrealarm>(.*)</RecordPrealarm>"))[1];
		if (!preAlarm) {
			this.isPreAlarm = false;
		} else {
			this.isPreAlarm = preAlarm.toLocaleLowerCase() === "true" ? true : false;
		}

		// Seconds to go back
		if (this.isPreAlarm) {
			const secondsToGoBack = settingsMeta.match(new RegExp("<SecondsToGoBack>(.*)</SecondsToGoBack>"))[1];
			if (!secondsToGoBack || isNaN(+secondsToGoBack)) {
				this.secondsToGoBack = null;
			} else {
				this.secondsToGoBack = +secondsToGoBack;
			}
		} else {
			this.secondsToGoBack = null;
		}
	}

	private setSettingsMetaFromObject(settingsMeta: string): void
	{
		var settingsMetaObject: settingsMetaJson = JSON.parse(settingsMeta);

		// Record Quality
		const recordQuality = settingsMetaObject.recordQuality;

		if (!recordQuality) {
			this.selectedRecordQuality = "default";
		} else {
			this.selectedRecordQuality = recordQuality;
		}

		// Number of Seconds
		const numOfSeconds = settingsMetaObject.numberOfSeconds;
		if (!numOfSeconds || isNaN(+numOfSeconds)) {
			this.numberOfSeconds = null;
		} else {
			this.numberOfSeconds = +numOfSeconds;
		}

		// Pre-Alarm
		this.isPreAlarm = settingsMetaObject.recordPreAlarmFootage;

		// Seconds to go back
		if (this.isPreAlarm) {
			const secondsToGoBack = settingsMetaObject.secondsToGoBack;
			if (!secondsToGoBack || isNaN(+secondsToGoBack)) {
				this.secondsToGoBack = null;
			} else {
				this.secondsToGoBack = +secondsToGoBack;
			}
		} else {
			this.secondsToGoBack = null;
		}
	}

	private setSettingsMetaFromResponseAction(settingsMeta: string | any): void {
		if(settingsMeta.includes("isJson"))
		{
			this.setSettingsMetaFromObject(settingsMeta);
		}
		else
		{
			this.setSettingsMetaFromXML(settingsMeta);
		}
	}


	@Emit("input")
	private deleteResponseAction(responseAction: ResponseAction): ResponseAction[] {
		// If its new item we are removing simply do an object compare to filer
		if(!responseAction.responseActionID)
		{
			if(this.responseActions && this.responseActions.length == 1)
			{
				this.responseActions = [];
				return this.responseActions;
			}

			const raIndex = this.responseActions.findIndex((r: ResponseAction) => r.description === responseAction.description);
			if (raIndex > -1)
			{
				this.responseActions.splice(raIndex, 1)
			}
		}
		else
		{
			const ra = this.responseActions.firstOrDefault((r: ResponseAction) => r.responseActionID === responseAction.responseActionID);
			if (ra)
			{
				if(responseAction.responseActionID != null){
					this.responseActions = this.responseActions.filter((ra: ResponseAction) => {
						return ra.responseActionID !== responseAction.responseActionID;
					});
				}
			}
		}
		return this.responseActions;
	}

	@Watch("supportedActionTypes")
	public updatedSupportedActionTypes(newValue: ResponseActionType[]): void {
		if (!this.type && !!newValue) {
			this.type = newValue.find((t:ResponseActionType) => t.responseActionTypeId === ResponseActionTypeId.RecordCamera);
		}
	}

	@Watch("value")
	public async onValueChanged(): Promise<void> {
		if(this.value) {
			await this.init();
		}
	}
	@Watch("displayAddAutomationForm")
	public onDisplayAddAutomationFormChanged(): void {
		if (!this.displayAddAutomationForm) {
			this.clearOriginals();
			this.clearForm();
		}
	}

	@Emit("input")
	public updatedResponseAction(responseAction: ResponseAction): ResponseAction[] {
		// Update the action if its preexisting

		if(this.responseActionEditIndex !== null)
		{
			var ra = this.responseActions[this.responseActionEditIndex]

			if (ra) {
				ra.responseActionTypeID = responseAction.responseActionTypeID;
				ra.objectID = responseAction.objectID;
				ra.settingsMeta = responseAction.settingsMeta
				ra.deviceTitle = responseAction.deviceTitle;
				ra.description = responseAction.description;
			}
			this.responseActionEditIndex = null;
		}
		this.closeForm();
		return this.responseActions;
	}

	public closeForm() {
		this.displayAddAutomationForm = false;
		this.isResponseActionEdit = false;
		this.clearOriginals();
	}

	private clearOriginals(): void {
		this.responseActionToEdit = null;
		this.originalType = null;
		this.originalSelectedCamera = null;
		this.originalNumberOfSeconds = null;
		this.originalRecordQuality = null;
		this.originalSecondsToGoBack = null;
	}

	private clearForm(): void {
		this.type = this.supportedActionTypes.find((t:ResponseActionType) => t.responseActionTypeId === ResponseActionTypeId.RecordCamera);
		this.selectedCamera = null;
		this.numberOfSeconds = null;
		this.selectedRecordQuality = "Default";
		this.isPreAlarm = false;
		this.secondsToGoBack = null;
	}

	private addAutomationButtonText(): string {
		if (this.maxAutomationsReached) {
			return "You have reached the limit for adding automations";
		}
	}

	private getAutomationLimitLabel(): string {
		return `${(this.value ? this.value.length : 0)}/${this.automationLimit}`
	}

	@Watch("selectedCamera")
	private cameraUpdated(): void
	{
		if (!this.selectedCamera || !this.availableCameras || !this.availableCameras.length )
		{
			this.canPreAlarm = this.isPreAlarm;
		}

		var cameraDetails = this.availableCameras.find(c => c.deviceID == this.selectedCamera);
		if (cameraDetails)
		{
			if (cameraDetails.canTimeTravel)
			{
				this.canPreAlarm = true;
			}
			else
			{
				this.canPreAlarm = false;
				this.secondsToGoBack = 0;
				this.isPreAlarm = false;
			}
		}
		else if(this.isPreAlarm)
		{
			this.canPreAlarm = true;
		}
	}
}
