
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({})
export default class SearchDropDown extends Vue {
	@Prop({ type: Array, default: () => [] })
	options!: any[]

	@Prop({ type: String, default: "label" })
	label!: string

	@Prop({ type: String, default: "" })
	text!: string

	@Prop({ type: String, default: "md" })
	size!: "sm" | "md" | "lg"

	@Prop({ type: Boolean, default: false })
	right!: boolean

	@Prop({ type: Boolean, default: false })
	canCreateOption!: boolean;

	@Prop({ type: String, default: "option" })
	term!: string;

	private searchTerm: string = ""

	get filteredItems(): any[] {
		const lowerSearch = this.searchTerm.toLowerCase()
		return this.options.filter(o => (o[this.label] as string).toLowerCase().includes(lowerSearch))
	}

	private onAddNewItem(): void {
		this.$emit("addNewItem", this.searchTerm);
		this.searchTerm = "";
	}
}
