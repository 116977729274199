import axios from "axios";
import { clone } from "lodash";

class MediaFile {
	public DeviceId: number | null = null;
	public DeviceTitle: string | null = null;
	public FileIdentifier: string = "";
	public FriendlyStartTime: string = "";
	public MediaType: string = "";
	public StartTime: string = "";
	public EventRecordId: number | null = null;
}

class AuditClip {
	public _data: ArrayBuffer[] = [];
	private dataQueue: ArrayBuffer[] = [];
	private media: MediaSource;
	private sourceBuffer: SourceBuffer;
	private sourceBufferUpdating: boolean = false;

	public constructor() {
		this.media = new MediaSource();
	}

	public addData(data: ArrayBuffer) {
		this._data.push(data);
		this.dataQueue.push(data);
		if (this.sourceBuffer) {
			this.sourceBuffer.appendBuffer(data);
		}
	}

	// Convert the video data to a Blob
	public toBlob(): Blob {
		return new Blob(this._data, { type: "video/mp4" });
	}

	public get mediaSource(): MediaSource {
		return this.media;
	}

	public setSourceOpenHandler(): void {
		this.media.onsourceopen = ev => {
			this.sourceBuffer = this.media.addSourceBuffer('video/mp4;codecs="avc1.640032"');

			for (let i = 0; i < this._data.length; i++) {
				this.sourceBuffer.appendBuffer(this._data[i]);
			}
		};
	}

	public hasDataToProcess(): boolean {
		return this.dataQueue.length > 0;
	}

	public clearQueue(): void {
		this.updateBufferFromQueue();
	}

	public endClip() {
		if (this.media.readyState === "open") {
			this.media.endOfStream();
		}
	}

	private updateBufferFromQueue(): void {
		if (!this.dataQueue || this.dataQueue.length === 0) {
			return;
		}

		this.sourceBufferUpdating = true;

		const dataQueueToProcess = clone(this.dataQueue);
		const dataQueueLength = dataQueueToProcess.length;
		const buffersLengths = dataQueueToProcess.map((buffer: ArrayBuffer) => buffer.byteLength);
		const totalBufferlength = buffersLengths.reduce((b1: number, b2: number) => b1 + b2, 0);

		let buffer = new Uint8Array(totalBufferlength);
		let offset = 0;

		for (let i = 0; i < dataQueueLength; ++i)
		{
			const poppedData = this.dataQueue.shift();
			buffer.set(new Uint8Array(poppedData), offset);
			offset += poppedData.byteLength;
		}

		this.sourceBuffer.appendBuffer(buffer.buffer);
		this.sourceBufferUpdating = false;
	}
}

class AuditTimeStamp {
	public time: number = 0;
	public minutes: number = 0;
	public seconds: number = 0;
	public totalSeconds: number = 0;
}

class AuditService {
	public endpoint: string;

	constructor(defaultEndpointAddress: string, containerisedEndpointAddress: string = null) {
		if (containerisedEndpointAddress)
		{
			this.endpoint = containerisedEndpointAddress  + "/audit";
			return;
		}

		// If the endpoint address is null, ensure that we dont insert null/audit
		// 	as the endpoint (NULL objects get converted to the word null when cast to string)
		if(!defaultEndpointAddress){
			this.endpoint = "/audit";
		}
		else{
			this.endpoint = defaultEndpointAddress + "/audit";
		}
	}

	public mediaList(auth: string, eventRecordId: number, allRecords: boolean = false, excludeAuditClips: boolean = false): Promise<any> {
		const url = this.endpoint +
			"/getmedialist?ias=" + auth +
			"&eventrecord=" + eventRecordId +
			"&allrecords=" + allRecords +
			"&excludeauditclips=" + excludeAuditClips;
		return axios.get(url);
	}

	public get protocol(): string {
		const url = new URL(this.endpoint);
		return url.protocol;
	}

	public mediaUrl(auth: string, eventRecordId: number, fileId: string): string {
		return (
			this.endpoint +
			"/getdata?auth=" +
			auth +
			"&eventrecord=" +
			eventRecordId +
			"&fileid=" +
			encodeURIComponent(fileId)
		);
	}

	public auditDataGet(mediaUrl: string, start: number): Promise<any> {
		const end = start + 262144; // 512Kb
		// const end = start + 131072; // 128Kb
		// const end = start + 65536; // 64Kb
		// const end = start + 16384; // 16Kb

		return axios.get(mediaUrl, {
			headers: {
				Range: "bytes=" + /* 0-'*/ start + "-" + end
			},
			responseType: "arraybuffer"
		});
	}

	/**
	 * Download whole media clip as file from audit service.
	 */
	public auditDataGetFile(mediaUrl: string, customFileName: string = null): Promise<any> {
		// By default, customFileName is not defined and audit service will use filename used in filestore.
		// When customFileName is defined, it will be passed to the request so file is downloaded with that name.
		return axios.get((customFileName == null ? mediaUrl : mediaUrl + "&customname=" + customFileName), {
			headers: {
				// range works by specifying bytes=<start>-<stop>
				// specifying start to be 0 and NO stop value ensures
				// whole file is downloaded.
				Range: "bytes=0-"
			},
			responseType: "blob"
		});
	}

	// Upload a file using the audit service - it will create an event record for you.
	public upload(eventId: number, formData: FormData, auth: string, config: any) {
		const uploadEndpoint = `${this.endpoint}/upload?auth=${auth}&event=${eventId}`;

		return axios.post(uploadEndpoint, formData, config);
	}

	public async uploadFileForEventRecord(
		fieldName: string,
		file: File,
		auth: string,
		eventId: number,
		eventRecordId: number,
		timeout: number = 60000,
		config: any = {}
	): Promise<any> {
		const formData = new FormData();
		formData.append(fieldName, file, file.name);

		config.timeout = timeout;

		return await axios.post(
			`${this.endpoint}/upload?auth=${auth}&event=${eventId}&eventrecord=${eventRecordId}`,
			formData,
			config
		);
	}

	public async getAllFiles(auth: string, eventRecordId: number): Promise<any> {
		return await axios.get(`${this.endpoint}/getallfiles?auth=${auth}&eventrecord=${eventRecordId}`, {
			responseType: "blob"
		});
	}

	public async doesEventHaveMedia(
		auth: string,
		eventId: number
	): Promise<any> {
		return await axios.get(`${this.endpoint}/doesEventHaveMedia?auth=${auth}&eventId=${eventId}`);
	}

	public async archiveEvent(
		auth: string,
		eventId: number
	): Promise<any> {
		return await axios.get(
			`${this.endpoint}/createarchive?auth=${auth}&event=${eventId}`,
			{ responseType: "blob" }
		);
	}

	public async getArchive(
		auth: string,
		eventId: number,
	): Promise<any> {
		window.open(`${this.endpoint}/getarchive?auth=${auth}&event=${eventId}`, "_self");
		return true;
	}

	public async archiveIsReady(
		auth: string,
		eventId: number
	): Promise<any> {
		return await axios.get(`${this.endpoint}/archiveisready?auth=${auth}&event=${eventId}`);
	}

	public async downloadRawData(auth:string, eventRecordId: number): Promise<any> {
		return await axios.get(`${this.endpoint}/rawData?auth=${auth}&eventrecord=${eventRecordId}`);
	}
}

export { MediaFile, AuditClip, AuditTimeStamp, AuditService };
