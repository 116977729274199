
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";
import { ActionPlanTask } from "@/store/tasks/types";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";

const SiteMonitor = namespace("siteMonitor");
const EventSearch = namespace("eventSearch");

@Component({})
export default class SitRepTask extends Vue {
	@SiteMonitor.Getter("getIncidentReportShown") incidentReportShown: any;
	@SiteMonitor.Getter("getIncidentReportDownloaded") incidentReportDownloaded: any;
	@EventSearch.State IncidentReportOpen: boolean;
	@EventSearch.State IncidentReportDownloaded: boolean;
	@SiteMonitor.Mutation setIncidentReportShown: any;
	@Getter("getFeaturesList") featuresList: FeaturesList;

	@Prop(Boolean)
	public editing: Boolean;

	@Prop()
	public task!: ActionPlanTask;

	private clicked = false;

	public mounted() {
		if (this.editing) {
			this.task.taskText = "Download SitRep";
			// No validation needed so call valid task by default.
			this.onValidateTask();
		}
	}

	@Emit("validate-task")
	public onValidateTask() {
		return true;
	}

	@Emit("complete-task")
	public onCompleteTask() {
		return {
			...this.task,
			completed: true
		};
	}

	public incidentReport() {
		this.setIncidentReportShown(true);
	}

	@Watch("IncidentReportDownloaded")
	public onEventSearchIncidentReportDownloadedChanged(value: boolean, oldValue: boolean) {
		if (value && !oldValue) {
			this.onCompleteTask();
		}
	}

	@Watch("incidentReportDownloaded")
	public onIncidentReportDownloadedChanged(value: boolean, oldValue: boolean) {
		if (value && !oldValue) {
			this.onCompleteTask();
		}
	}

	private get featureEnabled() {
		return get(this.featuresList, ["Actions", "SitRep"], false)
	}
}
