import { RootState } from "@/store/types";
import { GetterTree } from "vuex";
import { SiteMonitorState, EventShareUser } from "./types";

export const getters: GetterTree<SiteMonitorState, RootState> = {
	getLastActivity: state => state.LastActivity,
	getEventDetails: state => state.EventDetails,
	getEventLocationLastSet: state => state.eventLocationLastSet,
	getRequestingEventRecords: state => state.requestingEventRecords,
	getEventRecords: state => state.EventRecords,
	getEventRecordsAwaitingAckCount: state => state.EventRecordsAwaitingAckCount,
	getEventRecordsAwaitingRestoreCount: state => state.EventRecordsAwaitingRestoreCount,
	getEventRecordsWithFiles: state => state.EventRecordsWithFiles,
	getActiveResponseIDs: state => state.ActiveResponseIDs,
	getEventRecordFilters: state => state.EventRecordFilters,
	getCurrentRecordFilter: state => {
		if (state.EventRecordsFilterActiveIndex === -1) {
			return null;
		}

		return state.EventRecordFilters[state.EventRecordsFilterActiveIndex];
	},
	getEventRecordsNoFilterRenderIndex: state => state.EventRecordsNoFilterRenderIndex,
	getFilteredRecords: state => {
		if (state.EventRecordsFilterActiveIndex === -1) {
			return state.EventRecords;
		}

		const activeFilter = state.EventRecordFilters[state.EventRecordsFilterActiveIndex];
		return state.EventRecords.filter(
			(record: any) => activeFilter.filterRecordIds.indexOf(record.eventRecordTypeID) > -1
		);
	},
	getLatestEventRecord: state => state.LatestEventRecordID,
	getEventCloseShown: state => state.EventCloseOpen,
	getEventDetailsShown: state => state.EventDetailsOpen,
	getEventRaiseShown: state => state.EventRaiseOpen,
	getIncidentReportShown: state => state.IncidentReportOpen,
	getIncidentReportDownloaded: state => state.IncidentReportDownloaded,
	getEventShareShown: state => state.EventSharingOpen,
	getEventLeaveShown: state => state.EventLeaveOpen,
	getPutEventOnTestShown: state => state.PutEventOnTestOpen,
	getInvolvedPartiesShown: state => state.InvolvedPartyOpen,
	getEventShare: state => state.EventShares,
	getEventShareSet: state => state.EventSharesSet,
	getEventNoPermission: state => state.EventNoPermission,
	userShares: (state, storeGetters) => {
		const shares: { [key: string]: EventShareUser } = {};
		storeGetters.getEventShare.forEach((user: EventShareUser) => {
			shares[user.eventUserID] = {
				isController: user.userID === state.ControllerUserID,
				...user
			};
		});

		return shares;
	},
	shareWithPersons: state => state.ShareWithPersons,
	getExternalUsersAllowed: state => state.ExternalUsersAllowed,
	getAdhocShareDetails: state => (eventUserId: string) => state.AdhocShareDetails[eventUserId],
	groupID: state => state.GroupID,
	getSelectedEventRecord: state => state.SelectedEventRecord,
	getActiveMapItems: state => state.activeMapItems,
	getMapCircleCenter: state => state.mapCircleCenter,
	getIsController: state => state.IsController,
	getUserID: state => state.UserID,
	filterIndex: state => state.EventRecordsFilterActiveIndex,
	getAuditService: state => state.auditService,
	getMaskReasons: state => state.MaskReasons,
	getEvents: state => state.Events,
	getGroupMaskReasons: state => state.GroupMaskReasons,
	maskedAlarms: state => state.MaskedAlarms,
	getEventOperators: state => state.EventShares,
	areaNotesVisible: state => state.AreaNotesVisible,
	getForceRestoreEventRecord: state => state.ForceRestoreEventRecord,
	getActiveMapItemsRequired: state => state.ActiveMapItemsRequired,
	getIsUploadingFile: state => state.IsUploadingFile,
	getHideMapFlag: state => state.hideMapFlag,
	getIsCameraEnabled: (state, getters, storeGetters) => (deviceId: number): boolean => {
		if (!!storeGetters && !!storeGetters.featuresList && !!storeGetters.featuresList.SystemView) {
			return true;
		}

		let isEnabled = true;

		if (!deviceId) {
			return isEnabled;
		}

		const serverId = state.deviceServerDetails.firstOrDefault(details => details.deviceId === deviceId)?.serverId;
		if (serverId) {
			const applianceServer = state.applianceServerDetails.firstOrDefault(appServer => appServer.serverId === serverId);
			if (!!applianceServer && !!applianceServer.applianceId) {
				isEnabled = applianceServer.isOnline;
			}
		}

		return isEnabled;
	},
	getApplianceOfflineNotification: state => (deviceId: number): string => {
		if (!deviceId) {
			return "";
		}

		let notification = "The Ops Link for this camera is currently offline.";

		const serverId = state.deviceServerDetails.firstOrDefault(details => details.deviceId === deviceId)?.serverId;
		if (serverId) {
			const applianceServer = state.applianceServerDetails.firstOrDefault(appServer => appServer.serverId === serverId);
			if (!!applianceServer && !!applianceServer.applianceTitle) {
				notification = `The Ops Link, '${applianceServer.applianceTitle}', for this camera is currently offline.`;
			}
		}

		return notification;
	},
	getSystemViewApplianceNotification: (state, getters: any) => (deviceId: number): string => {
		const serverId = state.deviceServerDetails.firstOrDefault(details => details.deviceId === deviceId)?.serverId;
		if (serverId) {
			const applianceServer = state.applianceServerDetails.firstOrDefault(appServer => {
			    return appServer.serverId === serverId
			});

			if (!applianceServer || applianceServer.applianceId == null || applianceServer.isOnline) {
				return null;
			}

			return `The Ops Link, '${applianceServer.applianceTitle}', for this camera is currently offline.`;
		}
		return null;
	},
	eventOutcomesTree: state => state.eventOutcomes.eventOutcomeTree,
	eventOutcomesFlatList: state => state.eventOutcomes.eventOutcomeFlatList
};
