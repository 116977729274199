import { ActionTree } from "vuex";
import { RootState } from "../types";
import {
	EditingRule,
	Rule,
	RuleTrigger,
	RuleAction,
	RulesEngineState,
	RulesWithPagination,
	RuleStep,
	ServersForServerType,
	RuleDropdownOptions,
	RuleGenericDropdownItem,
	RuleServer,
	RuleServerTypeEvent,
	ServerTypeEventsForServerType
} from "./types";
import api from "@/services/api.service";
import { PaginatedSearchRequest } from "@/mixins/PaginatedSearch";
import { cloneDeep } from "lodash";

export const actions: ActionTree<RulesEngineState, RootState> = {
	async retrieveRules({ commit }, request: PaginatedSearchRequest) {
		const data: RulesWithPagination = await api.retrieveRules(request);
		commit("setRules", data);
	},
	retrieveComponentForTypeId({ getters }, { section, typeId }): any {
		let selectorComponentLinks = getters.getComponentLinks;
        var possibleComponentLinks = selectorComponentLinks.filter(link => link.componentFor.includes(section))
        if(possibleComponentLinks.length == 0){
            return null;
        }
        if(possibleComponentLinks.length > 1){
            console.error("duplicated links created");
            return null;
        }

        var valueComponentLinksForSection = possibleComponentLinks[0].valueComponents.filter(vs => vs.section == section);
        if(valueComponentLinksForSection.length == 0){
            return null;
        }
        if(valueComponentLinksForSection.length > 1){
            console.error("duplicate value components set");
            return null;
        }

        var valueToComponent = valueComponentLinksForSection.map(vs => vs.valueComponentLinks)[0];

        var componentSelector = valueToComponent.filter(ts => ts.value == typeId)
        return componentSelector[0];
	},
	async saveRule({ dispatch }, rule: EditingRule): Promise<Boolean> {
		// Trigger and Actions back to their base type
		let convertedTriggers: RuleTrigger[] = await dispatch("configureRuleForApi", { triggers: rule.ruleTriggers, ruleType: "triggers" });
		let convertedActions: RuleAction[] = await dispatch("configureRuleForApi", { actions: rule.ruleActions, ruleType: "actions" });

		let convertedRule: Rule = {
			ruleId: rule.ruleId,
			title: rule.title,
			tenantId: rule.tenantId,
			serverTypeId: rule.serverTypeId,
			grouping: rule.grouping,
			responseId: rule.responseId,
			serverId: rule.serverId,
			serverTypeEventNum: rule.serverTypeEventNum,
			input1: rule.input1,
			input2: rule.input2,
			triggers: convertedTriggers,
			actions: convertedActions
		}

		return await api.createOrUpdateRule(convertedRule);
	},
	async configureRuleForApi({ dispatch }, payload: any): Promise<RuleTrigger[] | RuleAction[]> {
		if(payload.ruleType == "triggers" && payload?.triggers){
			let configuredRuleTriggers: RuleTrigger[] = [];
			for(var i = 0; i < payload.triggers.length; i++)
			{
				var currentTrigger = cloneDeep(payload.triggers[i]);
				let ruleTrigger: RuleTrigger = {
					id: currentTrigger.id,
					operator: currentTrigger.operator,
					condition: {
						triggerTypeId: currentTrigger.condition.typeId,
						value: currentTrigger.condition.value,
					},
					subTriggers: await dispatch("configureRuleForApi", { triggers: currentTrigger.subRules, ruleType: "triggers" })
				}
				configuredRuleTriggers.push(ruleTrigger);
			}

			return configuredRuleTriggers;
		} else if(payload?.actions) {
			let configuredRuleActions: RuleAction[] = [];

			for(var x = 0; x < payload.actions.length; x++)
			{
				var action = cloneDeep(payload.actions[x]);
				let ruleAction: RuleAction = {
						id: action.id,
						actionTypeId: action.condition.typeId,
						value: action.condition.value
					}
				configuredRuleActions.push(ruleAction);
			}

			return configuredRuleActions;
		}
		return [];
	},
	async configureRuleForUI({ dispatch, commit }, rule: Rule): Promise<void> {
		// First, we need to convert the RuleTriggers and RuleActions to objects.
		const ruleTriggers: RuleTrigger[] = rule.triggers;
		const ruleActions: RuleAction[] = rule.actions;

		// Next, we must convert the RuleTriggers and RuleActions to RuleSteps
		let convertedTriggers: RuleStep[] = await dispatch("configureTriggerRuleForUI", ruleTriggers);
		let convertedActions: RuleStep[] = await dispatch("configureActionRuleForUI", ruleActions);

		// Then, we need to create the EditingRule object
		let convertedRule: EditingRule = {
			ruleId: rule.ruleId,
			title: rule.title,
			tenantId: rule.tenantId,
			serverTypeId: rule.serverTypeId,
			grouping: rule.grouping,
			responseId: rule.responseId,
			serverId: rule.serverId,
			serverTypeEventNum: rule.serverTypeEventNum,
			input1: rule.input1,
			input2: rule.input2,
			ruleTriggers: convertedTriggers,
			ruleActions: convertedActions,
		}

		// finally, we need to set the new EditingRule
		commit("updateEditingRule", convertedRule);
	},
	async configureTriggerRuleForUI({ dispatch }, ruleTrigger: RuleTrigger[]) {
		let configuredRuleStep: RuleStep[] = [];

		if (!!ruleTrigger && ruleTrigger.length > 0) {

			for (var i = 0; i < ruleTrigger.length; i++) {
				const trigger = ruleTrigger[i];
				var currentTrigger = cloneDeep(trigger);
				let ruleStep: RuleStep = {
					id: currentTrigger.id,
					operator: currentTrigger.operator,
					condition: {
						typeId: trigger.condition?.triggerTypeId ?? null,
						value: trigger.condition?.value ?? null
					},
					subRules: await dispatch("configureTriggerRuleForUI", currentTrigger.subTriggers)
				}
				configuredRuleStep.push(ruleStep);
			}
		}

		return configuredRuleStep;
	},
	async configureActionRuleForUI({ dispatch }, ruleAction: RuleAction[]) {
		let configuredRuleSetup: RuleStep[] = [];

		if (!!ruleAction && ruleAction.length > 0) {

			for (var i = 0; i < ruleAction.length; i++) {

				const action = ruleAction[i];
				var currentAction = cloneDeep(action);
				let ruleStep: RuleStep = {
					id: currentAction.id,
					operator: "AND", /* Always 'AND' */
					condition: {
						typeId: currentAction.actionTypeId,
						value: currentAction.value
					},
					subRules: []
				}

				configuredRuleSetup.push(ruleStep);
			}
		}

		return configuredRuleSetup;
	},
	async deleteRule({ commit }, ruleId: number) {
		await api.DeleteRule(ruleId).then(
			result => {
				if (!!result && result.status === 200) {
					commit("removeRule", ruleId);
				}
			}
		);
	},
	async retrieveRuleAlarmsForGroupId({ commit, state }, groupId: number) {
		if (!state.alarmsForGroupId.has(groupId)) {
			const ruleAlarms = await api.retrieveRuleAlarmsForGroupId(groupId);
			const payload = {
				ruleAlarms,
				groupId
			};
			commit("setRuleAlarmsForGroupId", payload);
		}
	},
	async retrieveRuleServerTypes({ commit }) {
		const ruleServerTypes = await api.retrieveRuleServerTypes();
		commit("setRuleServerTypes", ruleServerTypes);
	},
	async retrieveRuleServersForServerType({ commit, state }, serverTypeId: number) {
		// If we already have the servers then return them
		if (state.serversForServerType) {
			const servers = state.serversForServerType.firstOrDefault((s: ServersForServerType) => s.serverTypeId === serverTypeId)?.servers;
			if (servers) {
				return servers;
			}
		}

		// Otherwise, we need to get them
		const ruleServers = await api.retrieveRuleServersForServerType(serverTypeId);
		const payload: ServersForServerType = {
			serverTypeId,
			servers: ruleServers
		};
		commit("setRuleServersForServerType", payload);

		return ruleServers;
	},
	async retrieveRuleServerTypeEventsForServerType({ commit, state }, serverTypeId: number) {
		// If we already have the serverTypeEvents then return them
		if (state.serversForServerType) {
			const events = state.serverTypeEventsForServerType.firstOrDefault((s: ServersForServerType) => s.serverTypeId === serverTypeId)?.serverTypeEvents;
			if (events) {
				return events;
			}
		}

		const ruleServerTypeEvents = await api.retrieveRuleServerTypeEventsForServerType(serverTypeId);
		const payload: ServerTypeEventsForServerType = {
			serverTypeId,
			serverTypeEvents: ruleServerTypeEvents
		};
		commit("setRuleServerTypeEventsForServerType", payload);

		return ruleServerTypeEvents;
	},
	async retrieveRuleActionPlans({ commit }) {
		const ruleActionPlans = await api.retrieveRuleActionPlans();
		commit("setRuleActionPlans", ruleActionPlans);
	},
	async retrieveOptionsForRuleDropdownOptions( { dispatch }, { optionType, id }): Promise<RuleGenericDropdownItem[]>{
		switch(optionType as RuleDropdownOptions) {
			case RuleDropdownOptions.server: {
				const servers = await dispatch("retrieveRuleServersForServerType", id);
				return servers.map((s: RuleServer) => { return { id: s.serverId, title: s.title }});
			}
			case RuleDropdownOptions.serverTypeEvent: {
				const events = await dispatch("retrieveRuleServerTypeEventsForServerType", id);
				return events.map((s: RuleServerTypeEvent) => {return { id: s.eventNum, title: s.title }});
			}
		}
		return [];
	}
}
