
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter, namespace, State } from "vuex-class";
import vselect3 from "vselect3";

import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { UserGroupRole, UserRole } from "@/store/user-management/types";

const UserManagement = namespace("userManagement");
const Areas = namespace("areas");

@Component({
	components: {
		"v-select3": vselect3,
		"area-tree-select": AreaTreeSelect
	}
})
export default class UserGroupRoles extends Vue {
	@Prop({ type: Array, default: [] })
	public value: UserGroupRole[];

	@Prop({type: Boolean, default: false})
	private readonly: Boolean;

	@UserManagement.Getter("getUserRoles") public userRoles: UserRole[];
	@Areas.Action fetchAreaDictionary: () => Promise<void>;
	@Getter getUserTenantGroupId: number;

	private userGroupRoles: any = [];

	private mounted() {
		this.userGroupRoles = this.value.map(i => {
			return {
				userGroupId: i.userGroupId,
				groupId: i.groupId,
				userRoleId: i.userRoleId,
				inherit: i.inherit
			}
		});

		if (this.userGroupRoles.length === 0) {
			this.userGroupRoles.push({
				userGroupId: 0,
				groupId: this.getUserTenantGroupId,
				userRoleId: 0,
				inherit: true
			})
		}
	}

	private addNew() {
		const newUserGroup = {
			userGroupId: 0,
			groupId: this.getUserTenantGroupId,
			userRoleId: 0,
			inherit: true,
		};

		this.userGroupRoles.push(newUserGroup);
	}

	@Watch("userGroupRoles", { deep: true })
	private userGroupRolesUpdated() {
		this.$emit("input", this.userGroupRoles);
	}
}
