
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import AreaTreeSelect from "../form/AreaTreeSelect.vue";
import TreePicker from '@/components/form/TreePicker.vue';
import SystemViewHealthFilter from "./SystemViewHealthFilter.vue";
import AreaTreePath from "@/views/AreaSetup/AreaTreePath.vue";
import GroupRow from "@/types/sv-data/groups/GroupRow";
import api from "@/services/api.service";
import SystemViewTreeNode from '@/types/sv-data/system-view/SystemViewTreeNode';
import SystemViewHealthStatusCount from '@/types/sv-data/system-view/SystemViewHealthStatusCount';
import SystemViewRequestTreeParams from '@/types/sv-data/system-view/SystemViewRequestTreeParams';
import ObjectTypeId from '@/types/sv-data/enums/ObjectTypeIds';
import { Getter, namespace } from "vuex-class";

const SystemHealth = namespace("systemHealth");

@Component({
	components: {
		TreePicker,
		SystemViewHealthFilter,
		AreaTreePath
	}
})
export default class SystemViewTree extends Vue {

	@Getter getUserGroupId: number;
	@SystemHealth.Getter('getHealthStatus') private healthStatusCount: SystemViewHealthStatusCount;
	@SystemHealth.Getter('getStatusFilter') private statusFilter: string;
	@SystemHealth.Mutation setHealthStatus: (status: SystemViewHealthStatusCount) => void;
	@SystemHealth.Mutation setStatusFilter: (filter: string) => void;

	private viewPermissions: string [] = ['CanViewSystemView'];
	private selectedPage: number = 1;
	private resultsPerPage: string = "25";
	private systemViewTree: SystemViewTreeNode[] = [];

	private selectedArea: GroupRow[] = [];
	private rootArea: GroupRow[] = [];
	private titleFilter: string = null;
	private isLoading: boolean = false;
	private isLoadingAreas: boolean = false;

	private selectedGroupId: number = null;

	private requestParams: SystemViewRequestTreeParams = {
		filter: null,
		excludeHealthyItems: false,
		excludeUnhealthyItems: false,
		excludeUntestedItems: false,
	};

	public loadMoreLabel: string = "Load more...";

	private async mounted(): Promise<void> {
		this.onStatusFilterChange();
		await this.setupAreas();
	}

	private async setupAreas(): Promise<void> {
		// Setup the Area Select
		try {
			this.isLoadingAreas = true;
			const rootGroupsPagedResponse = await api.getRootGroups(this.viewPermissions, this.selectedPage, parseInt(this.resultsPerPage));

			if (!!rootGroupsPagedResponse &&
				!!rootGroupsPagedResponse.data &&
				rootGroupsPagedResponse.data.length === 1) {
				this.selectedArea = rootGroupsPagedResponse.data;
				this.selectedGroupId = this.selectedArea[0].groupID;
				this.rootArea = rootGroupsPagedResponse.data;
			} else {
				this.selectedGroupId = this.getUserGroupId;
			}
		}
		catch (ex) {
			console.error("Unexpected error occurred while filtering areas on System View: " + ex);
		}

		this.isLoadingAreas = false;
	}

	@Watch("selectedGroupId")
	private async onSelectedGroupIdChanged(): Promise<void> {
		await this.getTree();
	}

	@Watch("healthStatusCount")
	private async onHealthStatusCountChanged(): Promise<void> {
	}

	@Watch("titleFilter")
	private async onTitleFilterChanged(): Promise<void> {
		this.requestParams.filter = this.titleFilter;
		await this.getTree();
	}

	@Watch("requestParams")
	private async updateTree(): Promise<void> {
		await this.getTree();
	}

	private async getTree(): Promise<void> {
		if (this.selectedGroupId) {
			this.isLoading = true;
			try {
				var tree = await api.getSystemViewTree(this.selectedGroupId, this.requestParams);
				if (tree) {
					tree = this.setLeafNodes(tree);
					this.systemViewTree = tree;
				}
			} catch (ex) {
				console.error("Unexpected error loading SystemView Item tree: " + ex);
			}
			this.isLoading = false;
		}
	}

	private setLeafNodes(tree: SystemViewTreeNode[]): SystemViewTreeNode[] {
		if(!tree) {
			return null;
		}

		tree.forEach(node => {
			node.label = node.title;

			// For Vue-TreeSelect each node needs a unique id and as we cross tables objectId alone cannot be used
			node.id = `${node.objectTypeId}-${node.objectId}-${node.title}`;
			if (node.children && node.children.length > 0) {
				node.children = this.setLeafNodes(node.children);
				node.isDefaultExpanded = true;

				if ((node.objectTypeId === ObjectTypeId.GroupSync ||
					(node.objectTypeId === ObjectTypeId.Server && node.title.toLowerCase() === "not synced")) &&
					node.children.length < node.totalChildItems) {
					this.addLoadMoreNode(node);
				}
			} else {
				node.isDefaultExpanded = false;
				if (node.totalChildItems > 0 && (node.objectTypeId === ObjectTypeId.GroupSync ||
					(node.objectTypeId === ObjectTypeId.Server && node.title.toLowerCase() === "not synced"))) {
					// Set to null so we can trigger a load for the children when opened
					node.children = null;
				} else {
					// This is to remove the expand/collapse icon
					node.children = undefined;
				}
			}
		});

		return tree;
	}

	private addLoadMoreNode(parentNode: SystemViewTreeNode): void {
		// This is a placeholder node to trigger an add more children if the parentNode has more
		var loadMoreNode: SystemViewTreeNode = {
			title: this.loadMoreLabel,
			label: this.loadMoreLabel,
			id: `${parentNode.objectTypeId}-${parentNode.objectId}-${this.loadMoreLabel}`,
			objectId: parentNode.objectId,
			objectTypeId: parentNode.objectTypeId,
			isExpandable: false,
			shouldDisplayData: true,
			children: undefined,
			totalChildItems: 0,
		};

		parentNode.children.push(loadMoreNode);
	}

	@Watch("statusFilter")
	private async onStatusFilterChange(): Promise<void> {
		var statusFilter = this.statusFilter ? this.statusFilter.toLowerCase() : "";
		switch(statusFilter){
			case "healthy":
				this.requestParams.excludeHealthyItems = false;
				this.requestParams.excludeUnhealthyItems = true;
				this.requestParams.excludeUntestedItems = true;
				break;
			case "unhealthy":
				this.requestParams.excludeHealthyItems = true;
				this.requestParams.excludeUnhealthyItems = false;
				this.requestParams.excludeUntestedItems = true;
				break;
			default:
				this.requestParams.excludeHealthyItems = false;
				this.requestParams.excludeUnhealthyItems = false;
				this.requestParams.excludeUntestedItems = false;
		}
		this.getTree();
	}

	private updateSelectedArea(): void {
		this.selectedGroupId = this.selectedArea[this.selectedArea.length-1].groupID;
	}

	@Emit("itemSelected")
	private async onItemSelected(node: SystemViewTreeNode): Promise<SystemViewTreeNode> {
		return node;
	}

	private isLeafNode(item: SystemViewTreeNode): boolean {
		return item.objectTypeId === ObjectTypeId.Camera ||
		item.objectTypeId === ObjectTypeId.Door ||
		item.objectTypeId === ObjectTypeId.Relay ||
		item.objectTypeId === ObjectTypeId.Audio ||
		item.objectTypeId === ObjectTypeId.Alarm;
	}

	private loadHome(): void {
		try {
			this.isLoadingAreas = true;
			this.selectedArea = this.rootArea;
			this.updateSelectedArea();
		} catch (ex) {
			console.error("Unexpected error loading SystemView home area filter: " + ex);
		}

		this.isLoadingAreas = false;
	}

	@Watch("systemViewTree")
	private onTreeUpdated(updatedTree: SystemViewTreeNode[]): void {
		this.systemViewTree = updatedTree;
	}
}
