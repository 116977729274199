
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { EventDetails, EventShareUser } from "@/store/site-monitor/types";
import EventTypes from '@/types/sv-data/enums/EventTypes';

const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {}
})
export default class InactivityWarning extends Vue {
	private hide: boolean = true;
	private closingEvent: boolean = false;
	public checkTimer: NodeJS.Timeout = null;
	public inactivityWarningSeconds: number = 0;

	@SiteMonitor.Getter getEventDetails: EventDetails;
	@SiteMonitor.Getter("getLastActivity") lastActivity: number | null;
	@SiteMonitor.Mutation setActivity: () => void;
	@SiteMonitor.Action eventPassControl: any;
	@SiteMonitor.Action parkEvent: any;
	@SiteMonitor.Action leaveEvent: any;
	@SiteMonitor.Getter getEventShare!: EventShareUser[];
	@SiteMonitor.Getter getUserID!: number;
	@SiteMonitor.Getter("getIsController") isController!: boolean;
	@Getter("getFeature") private getFeature: (featureName: string[]) => boolean;
	@Getter getUserTenantGroupId: number;

	private get sharedOperators(): EventShareUser[] {
		return this.getEventShare.filter(
			(user: EventShareUser) =>
				user.userID != null &&
				user.userID != this.getUserID &&
				!user.removed &&
				user.isActive
		);
	}

	private get isMediaWallEvent(): boolean {
		if(!this.getEventDetails || !this.getUserTenantGroupId)
		{
			return false;
		}

		return (this.getUserTenantGroupId === this.getEventDetails.groupID && this.getEventDetails.eventTypeID === EventTypes.Patrol)
	}

	private get isInactivitySoundEnabled(): boolean {
		return this.getFeature(["Alarms", "SiteMonitor", "InactivityWarningSound"]);
	}

	private get isTemporaryMediaWallEnabled(): boolean {
		return this.getFeature(["MediaWall_Temp"]);
	}

	private get otherUserCount(): number {
		return this.sharedOperators == null ? 0 : this.sharedOperators.length;
	}

	private mounted(): void {
		this.checkTimer = setInterval(() => {
			if (this.closingEvent) return;

			if (!this.getEventDetails || !this.getEventDetails.inactivityTimeout)
				return;

			if (this.isTemporaryMediaWallEnabled && this.isMediaWallEvent) {
				return;
			}

			let lastActivity = this.lastActivity;
			if (lastActivity) {
				let inactiveTime = new Date().valueOf() - lastActivity;
				if (inactiveTime > this.getEventDetails.inactivityTimeout * 1000 * 60) {
					if (this.hide) {
						this.hide = false;
						this.inactivityWarningSeconds = this.getEventDetails.inactivityWarning;
					}

					try 
					{
						if (this.isInactivitySoundEnabled){
							const audio = document.getElementById("alarmSound");
							(audio as HTMLAudioElement).play();
						}
					} catch {
						throw "Error playing inactivity warning";
					}

					if (this.inactivityWarningSeconds > 0)
						this.inactivityWarningSeconds--;
					else {
						this.closingEvent = true;

						if (this.isController) {
							if (this.otherUserCount > 0) {
								let otherOperators = this.sharedOperators;
								let sortedOperators = otherOperators.sort((a, b) => {
									return (
										new Date(b.joinedEvent).valueOf() -
										new Date(a.joinedEvent).valueOf()
									);
								});

								this.eventPassControl({
									eventID: this.getEventDetails.eventID,
									userID: sortedOperators[0].userID
								}).then(() => {
									this.$router.push({ path: "/event-queue" });
								});
							} else if(this.getEventDetails && this.getEventDetails.eventID) {
								this.parkEvent({
									eventId: this.getEventDetails.eventID,
									hours: 0,
									minutes: 0,
									seconds: 0,
									parkNote: "Event returned to queue due to inactivity",
									parkedByMap: false
								}).then(() => {
									this.$router.push({ path: "/event-queue" });
								});
							}
						} else {
							this.leaveEvent(this.getEventDetails.eventID).then(() => {
								this.$router.push({ path: "/event-queue" });
							});
						}
					}
				} else {
					if (!this.hide) {
						this.hide = true;
					}
				}
			}
		}, 1000);
	}

	private beforeDestroy(): void {
		clearInterval(this.checkTimer);
		this.checkTimer = null;
	}

	private cancelCountdown(): void {
		this.setActivity();
	}
}
