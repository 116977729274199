
import { FeaturesList, VueBootstrapField } from "@/store/types";
import { get } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ObjectTypeId from "@/types/sv-data/enums/ObjectTypeIds";
import SystemViewAlarmAutomations from './SystemViewAlarmAutomations.vue';
import { AlarmAutomation } from '@/types/sv-data/system-view/SetupSummary';

@Component({
	components: {
		VuePerfectScrollbar,
		"system-view-alarm-automations": SystemViewAlarmAutomations
	}
})
export default class SystemViewAlarmSetup extends Vue {

	@Prop({required: true}) private objectId: number;
	@Prop({required: true}) private objectTypeId: ObjectTypeId;
	@Prop({required: true}) private alarmInformation: AlarmAutomation[];

	@Getter getFeaturesList: FeaturesList;

	private get alarmSetupFields(): VueBootstrapField[] {
	return [
		{
			key: "alarmTitle",
			label: "Alarm",
			visible: true,
			sortable: true
		},
		{
			key: "serverTitle",
			label: "Server",
			visible: true,
			sortable: true
		},
		{
			key: "serverTypeTitle",
			label: "Integration",
			visible: true,
			sortable: true
		},
		{
			key: "actionPlanTitle",
			label: "Action Plan",
			visible: true,
			sortable: true
		},
		{
			key: "priority",
			label: "Priority",
			visible: true,
			sortable: true
		},
		{
			key: "autoHandled",
			label: "Auto Handled",
			visible: true,
			sortable: true
		},
		{
			key: "ignore",
			label: "Ignore",
			visible: true,
			sortable: true
		},
		{
			key: "lastOccurred",
			label: "Last Occurrence",
			visible: true,
			sortable: true
		},
		{
			key: "showAutomations",
			label: "Alarm Actions",
			visible: this.showAutomations,
			sortable: false
		},
	];
	}

	private get visibleAlarmSetupFields(): VueBootstrapField[] {
		return this.alarmSetupFields.filter(field => !!field.visible);
	}

	private get showAutomations(): boolean {
		return !!get(this.getFeaturesList, ["Alarms", "CameraAutomation"]);
	}
}
