
import { Component, Vue, Watch } from "vue-property-decorator";
import { ContactInfoModel, PaymentInfoModel } from "@/store/signup/types";
import { namespace } from "vuex-class";
import VueSelect from "vue-select";

const SignUp = namespace("signup");

@Component({
    components: {
        "vue-select" : VueSelect
    }
})
export default class PaymentInfo extends Vue {
    @SignUp.State countryOptions: [];
    @SignUp.State contactInfo: ContactInfoModel;
    @SignUp.Mutation setRegistrationComplete;

    private paymentInfoModel: PaymentInfoModel = {
        billingAddress: {
            addressLine1: "",
            addressLine2: "",
            country: "",
            state: "",
            city: "",
            zipCode: ""
        },
        cardInfo: {
            firstName: "",
            lastName: "",
            cardNumber: {
                part1: "",
                part2: "",
                part3: "",
                part4: ""
            },
            expirationMonth: undefined,
            expirationYear: undefined,
            cvc: ""
        }
    };

    private stateOptions = [];

    private isBillingAddressSameAsContactAddress: boolean = false;

    private mounted() {
        this.setRegistrationComplete(false);
    }

    @Watch("paymentInfoModel.cardInfo.cardNumber.part1")
    private cardNumberPart1Changed(newVal, oldVal) {
        if (oldVal.length !== 4 && newVal.length === 4) {
            (this.$refs.ccNumP2 as HTMLElement).focus();
        }
    }

    @Watch("paymentInfoModel.cardInfo.cardNumber.part2")
    private cardNumberPart2Changed(newVal, oldVal) {
        if (oldVal.length !== 4 && newVal.length === 4) {
            (this.$refs.ccNumP3 as HTMLElement).focus();
        }
    }

    @Watch("paymentInfoModel.cardInfo.cardNumber.part3")
    private cardNumberPart3Changed(newVal, oldVal) {
        if (oldVal.length !== 4 && newVal.length === 4) {
            (this.$refs.ccNumP4 as HTMLElement).focus();
        }
    }
    private get yearOptions() {
        let yearOptions = [];
        let currentYear = new Date().getFullYear();

        yearOptions.push(currentYear);

        for (let i = currentYear + 1; i < currentYear + 20; i++) {
            yearOptions.push(i);
        }

        return yearOptions;
    }

    private isCardExpiryInTheFuture() {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth() + 1;
        if (this.paymentInfoModel.cardInfo.expirationYear > currentYear) {
            return true;
        }

        if (this.paymentInfoModel.cardInfo.expirationYear == currentYear && this.paymentInfoModel.cardInfo.expirationMonth >= currentMonth) {
            return true;
        }

        return false;
    }

    @Watch("paymentInfoModel", { deep: true })
    private onPaymentInfoChanged() {
        let isCardNumberComplete = this.paymentInfoModel.cardInfo.cardNumber.part1.length == 4 &&
            this.paymentInfoModel.cardInfo.cardNumber.part2.length == 4 &&
            this.paymentInfoModel.cardInfo.cardNumber.part3.length == 4 &&
            this.paymentInfoModel.cardInfo.cardNumber.part4.length >= 3

        let isCvcComplete = this.paymentInfoModel.cardInfo.cvc.length >= 3;
        let isFirstNameComplete = this.paymentInfoModel.cardInfo.firstName.length > 0;
        let isLastNameComplete = this.paymentInfoModel.cardInfo.lastName.length > 0;

        let isCardValid = isCardNumberComplete && isFirstNameComplete && isLastNameComplete && this.isCardExpiryInTheFuture() && isCvcComplete;

        let isAddressLine1Complete = this.paymentInfoModel.billingAddress.addressLine1.length > 0;
        let isCountryComplete = this.paymentInfoModel.billingAddress.country.length > 0;
        let isCityComplete = this.paymentInfoModel.billingAddress.city.length > 0;
        let isZipCodeComplete = this.paymentInfoModel.billingAddress.zipCode.length > 0;

        let isBillingAddressValid = isAddressLine1Complete && isCountryComplete && isCityComplete && isZipCodeComplete;

        let isValid = isCardValid && isBillingAddressValid;

        if (isValid) {
            this.$emit('payment-step-complete', this.paymentInfoModel);
        }

        this.setRegistrationComplete(isValid);
    }

    private get mappedCountryOptions() {
        return this.countryOptions.map(co => { return { title: co[0], code: co[1] } });
    }

    @Watch("paymentInfoModel.billingAddress.country")
    private async countryStateOptions(newVal, oldValue) {
        if (newVal)
        {
            if (oldValue !== "") {
                this.paymentInfoModel.billingAddress.state = "";
            }
            let countryStateFile = await fetch(`/resources/country_states/${newVal}.json`);
            let countryStateFileContents = await countryStateFile.json();
            this.stateOptions = countryStateFileContents[newVal].map(co => { return { title: co[0], code: co[1] } });
        }
    }

    @Watch("isBillingAddressSameAsContactAddress")
    private async billingAddressSameAsContactAddressToggled(newVal) {
        if (newVal) {
            this.paymentInfoModel.billingAddress.addressLine1 = this.contactInfo.addressLine1;
            this.paymentInfoModel.billingAddress.addressLine2 = this.contactInfo.addressLine2;
            this.paymentInfoModel.billingAddress.country = this.contactInfo.country;
            this.paymentInfoModel.billingAddress.state = this.contactInfo.state;
            this.paymentInfoModel.billingAddress.city = this.contactInfo.city;
            this.paymentInfoModel.billingAddress.zipCode = this.contactInfo.zipCode;
        } else {
            this.paymentInfoModel.billingAddress.addressLine1 = "";
            this.paymentInfoModel.billingAddress.addressLine2 = "";
            this.paymentInfoModel.billingAddress.country = "";
            this.paymentInfoModel.billingAddress.state = "";
            this.paymentInfoModel.billingAddress.city = "";
            this.paymentInfoModel.billingAddress.zipCode = "";
        }
    }

}
