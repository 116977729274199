
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class RoundIconButton extends Vue {
	@Prop() iconClass: string;

	private onBtnClick() {
		this.$emit("click");
	}
}
