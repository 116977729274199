
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import LiveCameras from "@/components/mobile/live/LiveCameras.vue";
import AssetControl from '../assets/AssetControl.vue';
import { UserPermissions } from '@/store/types';

const Mobile = namespace("mobile");

@Component({
	components: {
		"live-cameras": LiveCameras,
		"asset-control" : AssetControl
	}
})
export default class RightSideBar extends Vue {
	@Getter getPermissions: UserPermissions;
	@Mobile.State isRightSideBarOpen: boolean;
	@Mobile.State isLeftSideBarOpen: boolean;
	@Mobile.Mutation toggleRightSideBarVisibility: () => void;
	@Mobile.State(state => state.MobileMode) mobileMode: string;

	private sideBarComponent: string = "";

	public async mounted() {
		this.setDynamicComponent();
	}

	@Watch("mobileMode")
	onMobileModeChanged(value: string, oldValue: string) {
		this.setDynamicComponent();
	}

	setDynamicComponent() {
		switch (this.mobileMode) {
			case "live":
				this.sideBarComponent = "live-cameras";
				break;
			case "fieldOps":
				if (this.getPermissions.canViewFieldOps || this.getPermissions.canEditFieldOps) {
					this.sideBarComponent = "asset-control";
				} else {
					this.sideBarComponent = "none";
				}
				break;
			default:
				this.sideBarComponent = "none";
		}
	}

	private get showRightSidebar(): boolean {
		return this.isRightSideBarOpen && !this.isLeftSideBarOpen;
	}
}
