
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { CameraType } from "@/store/site-monitor-cameras/types";
import { EventDetails } from "@/store/site-monitor/types";
import vSelect from "vue-select";
import { FeaturesList } from "@/store/types";
import { MatrixContents } from "@/store/site-monitor-cameras/types";
import { PredefinedTour, PredefinedTourStep, isActiveCell } from "@/store/tours/types";
import { get, clone } from "lodash";
import { validationMixin } from 'vuelidate';
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'

const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Tours = namespace("tours");

enum TourOptionList {
	AreaCameras = 1,
	NearbyCameras = 2,
	PredefinedCameras = 3
}

@Component({
	components: {
		"vue-select": vSelect,
	},
	mixins: [validationMixin],
	validations: {
		selectedPredefinedTour: {
			required: requiredIf(function(){
				if (this.selectedTourOption == TourOptionList.PredefinedCameras)
				{
					if (!this.selectedPredefinedTour) {
						return true;
					}
					return false;
				}
				return false;
			})
		},
		failedCamera: {
			failedNote: {
				required,
				minLength: minLength(2)
			}
		}

	}
})
export default class Tour extends Vue {
	/** Vuex Methods */
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getUserName!: string;

	@SiteMonitor.Getter("groupID") groupId!: number | null;
	@SiteMonitor.Getter getEventDetails: EventDetails;
	@SiteMonitor.Action createAuditRecord: any;

	@SMCameras.Action saveMatrix: (matrixData: any) => Promise<any>;
	@SMCameras.Action fetchAreaCameras: any;
	@SMCameras.Getter("getLayoutIndex") layoutIndex!: number;
	@SMCameras.Getter("getLayouts") layouts!: number[][];
	@SMCameras.Getter("getAreaCameras") groupCameras: CameraType[];
	@SMCameras.Getter("getDeviceControllerCameras") nearbyCameras: CameraType[];
	@SMCameras.Getter getAllMatrixContents: MatrixContents;
	@SMCameras.Getter getMatrixPushContents: any;
	@SMCameras.State("areaCameraCount") public areaCameraCount: number;

	@SMCameras.Mutation setPushContents: any;
	@SMCameras.Mutation setLayoutIndex: any;

	@Tours.Action fetchAllTours: any;

	@Tours.Getter getAllTours: any;
	@Tours.Getter getPredefinedTour: PredefinedTour;
	@Tours.Getter tourInProgress: any;

	@Tours.Mutation setIsInTourState: any;
	@Tours.Mutation clearPredefinedStreamingCameras: any;
	@Tours.Mutation activeCellDisable: any;
	@Tours.Mutation activeCellEnable: any;
	@Tours.Mutation addPredefinedTourCameraIndex: any;
	@Tours.Mutation addPredefinedTourIteration: any;
	@Tours.Mutation updatePredefinedTourPausedState: any;
	@Tours.Mutation setPredefinedTourCamera: any;
	@Tours.Mutation addPredefinedTourTimeout: any;
	@Tours.Mutation updatePredefinedTourTimeout: any;
	@Tours.Mutation updatePredefinedTourDuration: any;
	@Tours.Mutation setPredefinedTourDurationTimer: any;
	@Tours.Mutation setPredefinedTour: any;

	@Prop(Array) cameras: CameraType[];

	private loopCameras: boolean = false;
	private useAreaCameras: boolean = true;
	private showCameraToggle: boolean = false;
	private showNearbyCameraChangeModal: boolean = false;
	private acceptNearbyCameras: boolean = false;
	private firstCellIndex: number = 0;
	private pageNumber: number = 1;

	private initialMatrix: any = [];
	private initialLayoutIndex: number = 0;

	private TourOptionList: any = TourOptionList;
	private selectedTourOption: number = 1;
	private selectedPredefinedTour: any = null;
	private tourOptions: any = [
		{ text: "Area Cameras", value: TourOptionList.AreaCameras },
		{ text: "Nearby Cameras", value: TourOptionList.NearbyCameras },
		{ text: "Predefined Cameras", value: TourOptionList.PredefinedCameras }
	];

	private nearbyCamerasBeforeChange: any = [];

	private timePassed: number = 0;
	private tourDefaultTimeout: number = 10;

	private failedCamera: any = { camera: null, failedNote: "" };
	private isFailedCamera: boolean = false;
	private wasTimeout: boolean = false;

	private get eventId() {
		return this.getEventDetails ? this.getEventDetails.eventID : 0;
	}

	private get getAllToursByGroup() {
		return this.getAllTours.filter((x: any) => x.groupId == this.groupId);
	}

	private get camerasLeft() {
		if (this.selectedTourOption == this.TourOptionList.PredefinedCameras) {
			return this.camerasList.length - this.getPredefinedTour.currentIteration - 1;
		} else {
			return this.camerasList.length - (this.firstCellIndex + this.totalCells);
		}
	}

	private get atEnd() {
		return this.camerasLeft + this.totalCells <= this.totalCells ? true : false;
	}

	private get atStart() {
		return this.camerasLeft + this.totalCells == this.camerasList.length ? true : false;
	}

	private get isAdvancedTourEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "VideoTours", "PredefiendTour"]);
	}

	private get areaToursOnly(): boolean {
		return get(this.featuresList, ["Alarms", "MediaMatrix", "VideoTours", "AreaToursOnly"]);
	}

	private get cameraCount() {
		if (this.selectedTourOption == this.TourOptionList.PredefinedCameras && this.getPredefinedTour) {
			return `${ this.getPredefinedTour.currentIteration+1 } / ${ this.camerasList.length }`;
		} else {
			return `${ this.firstCellIndex + this.totalCells - this.emptyCells.length } / ${ this.selectedTourOption == TourOptionList.NearbyCameras ? this.camerasList.length : this.areaCameraCount }`;
		}
	}

	private get isCellStreaming() {
		const cameraStream = this.getPredefinedTour.camerasStreaming.find(x => x.index == this.getPredefinedTour.currentCameraIndex);
		if (cameraStream == null) {
			return false;
		}
		return cameraStream.isStreaming;
	}

	public get camerasList() {
		switch(this.selectedTourOption) {
			case TourOptionList.NearbyCameras: {
				return this.filteredNearbyCameras;
			}
			case TourOptionList.PredefinedCameras: {
				let PredefinedTour = clone(this.selectedPredefinedTour);
				if (!PredefinedTour || !PredefinedTour.tourSteps) {
					return [];
				}

				let predefinedCameras = PredefinedTour.tourSteps;

				predefinedCameras.sort(function(x, y){
					return x.stepOrder - y.stepOrder;
				});

				return predefinedCameras;
			}
			default: {
				return this.groupCameras;
			}
		}
	}

	public get loadedCameras() {
		return Object.values(this.getAllMatrixContents)
			.filter(m => m && m.camera)
			.map(c => c.camera)
			.filter(g => g.objectID)
			.map(c => c.objectID);
	}

	/** Checks if each cell has contents and if not, return the index of that cell. */
	public get emptyCells() {
		return Array.from(Array(this.totalCells).keys()).map(
			x => ++x
		).filter(
			x => this.cellEmpty(this.getAllMatrixContents[x]) ? true : !Object.keys(this.getAllMatrixContents).includes(x.toString())
		);
	}

	public get tourStepComment() {
		if (!this.getPredefinedTour) {
			return "";
		}
		return this.camerasList[this.getPredefinedTour.currentIteration] !== undefined ? this.camerasList[this.getPredefinedTour.currentIteration].comment : "";
	}

	public get totalCells() {
		return (
			this.layouts[this.layoutIndex][0] * this.layouts[this.layoutIndex][1]
		);
	}

	private get filteredNearbyCameras() {
		if (!this.tourInProgress) {
			return this.nearbyCameras;
		}

		if (this.acceptNearbyCameras) {
			this.acceptNearbyCameras = false;
			this.nearbyCamerasBeforeChange = this.nearbyCameras;
			return this.nearbyCameras;
		}
		return this.nearbyCamerasBeforeChange;
	}

	@Watch("layoutIndex")
	private onLayoutUpdate() {
		if (this.tourInProgress) {
			this.loadPage(this.firstCellIndex, this.totalCells);
		}
	}

	/** Used to evalulate data if other components pushed contents to it.
	 * Checking if the user clicked the active cell etc.
	 */
	@Watch("getPredefinedTour", { deep:true })
	private async validateCameraData() {
		if (!this.getPredefinedTour && !this.getPredefinedTour.cellTrigger && !this.getPredefinedTour.durationBasedCamera) {
			await this.loadNextCell(this.getPredefinedTour.cellTrigger);
			this.getPredefinedTour.cellTrigger = null;
		}
	}

	@Watch("nearbyCameras")
	private nearbyCamerasOnChange() {
		if (this.tourInProgress && this.selectedTourOption == TourOptionList.NearbyCameras) {
			this.showNearbyCameraChangeModal = true;
			this.auditTour(`Nearby cameras have been changed`);
		}
	}

	@Watch("eventId")
	public async onEventChanged(eventId: number, oldEventId: number) {
		if (this.getEventDetails) {
			this.tourInProgress = false;
			this.setIsInTourState(false);
			await this.resetTourDetails();
		}
	}

	async mounted() {
		await this.fetchAllTours();
		if (this.getEventDetails) {
			await this.fetchAreaCameras({ groupID: this.getEventDetails.groupID, pageNumber: this.pageNumber, paginated: true });

			if (this.getEventDetails.useAreaCameras && this.getEventDetails.useNearbyCameras) {
				this.showCameraToggle = true;
			} else {
				this.useAreaCameras = this.getEventDetails.useAreaCameras;
			}
		}
	}

	private updateNearbyCameras() {
		if (this.tourInProgress && this.selectedTourOption == TourOptionList.NearbyCameras) {
			this.showNearbyCameraChangeModal = false;
			this.auditTour(`User: ${ this.getUserName } accepted Nearby Cameras updates`);
			this.populateMediaMatrix(this.nearbyCameras);
			this.firstCellIndex = 0;
			this.acceptNearbyCameras = true;
		}
	}

	private async resetTourDetails() {
		this.$emit('tourName', "");
		this.$emit('tourStopped');
		this.pageNumber = 1;
		await this.fetchAreaCameras({ groupID: this.getEventDetails.groupID, pageNumber: this.pageNumber, paginated: true });
		this.firstCellIndex = 0;
		this.selectedTourOption = TourOptionList.AreaCameras;
		if (this.selectedTourOption == this.TourOptionList.PredefinedCameras) {
			this.selectedPredefinedTour = null;
			this.activeCellDisable();
			this.setPredefinedTour(null);
			this.setPredefinedTourCamera({});
		}else if (this.selectedTourOption == this.TourOptionList.AreaCameras) {
			this.acceptNearbyCameras = false;
			this.showNearbyCameraChangeModal = false;
		}
	}

	private async loadNextCell(confirmed: boolean, timeout: boolean = false) {
		this.addPredefinedTourIteration(1);
		this.wasTimeout = timeout;

		//Check we are at the end of our tour.
		if (this.camerasLeft == -1) {
			this.confirmCamera(confirmed);
			return;
		}

		const nextCamera = this.camerasList.find(x => x.stepOrder == this.getPredefinedTour.currentIteration + 1);
		this.addPredefinedTourTimeout(this.tourDefaultTimeout);

		if (this.getPredefinedTour.isPausedTour) {
			this.updatePredefinedTourPausedState(false);
		}

		if (this.getPredefinedTour.currentCameraIndex == this.totalCells && this.getPredefinedTour.currentCameraIndex !== this.camerasList.length) {
			this.addPredefinedTourCameraIndex(1);
			await this.camerasNext();
			this.clearPredefinedStreamingCameras();
			this.setPredefinedTourCamera(nextCamera);
		} else {
			this.addPredefinedTourCameraIndex(this.getPredefinedTour.currentCameraIndex + 1);
		}

		await this.confirmCamera(confirmed, nextCamera);

	}

	private async confirmCamera(confirmed: boolean, nextCamera: any = null) {
		if (confirmed) {
			if (this.getPredefinedTour.durationBasedCamera) {
				this.auditTour(`Camera ${ this.getPredefinedTour.currentCamera.title } has been auto-approved`,
				this.getPredefinedTour.currentCamera.objectID);
			} else {
				this.auditTour(`Camera: ${ this.getPredefinedTour.currentCamera.title } has been approved by User: ${ this.getUserName }. time taken: ${ this.timePassed }seconds.`,
				this.getPredefinedTour.currentCamera.objectID);
				this.timePassed = 0;
			}

			if (this.camerasLeft == -1) {
				await this.stopTour();
			} else {
				this.setPredefinedTourCamera(nextCamera);
				this.activeCellEnable({ isActive: true, index: this.getPredefinedTour.currentCameraIndex });
			}
		} else {

			this.isFailedCamera = true;
			this.failedCamera = { camera: this.getPredefinedTour.currentCamera, failedNote: "" };
			this.updatePredefinedTourPausedState(true);

			if (this.wasTimeout) {
				await this.confirmFailedCamera();
			}
		}

	}

	private cellEmpty(cellContents: any) {
		let empty = true;

		if (cellContents !== undefined &&
				cellContents.camera !== undefined &&
				cellContents.camera.objectID !== undefined) {
					empty = false;
		}
		return empty;
	}

	private loadPage(startIndex: number, pageSize: number) {
		for (let i = 0; i < pageSize; i++) {
			const cameraIdx = startIndex + i;
			const newContents = this.getCamContents(cameraIdx);
			const pushContents = {
				index: i+1,
				newContents
			};
			this.setPushContents(pushContents);
		}
	}

	private getCamContents(cameraIdx: number) {
		if (cameraIdx < this.camerasList.length) {
			return {
				camera: {
					index: cameraIdx,
					objectID: this.camerasList[cameraIdx].objectID,
					title: this.camerasList[cameraIdx].title,
				}
			};
		}
		return {};
	}

	private loadNextPage() {
		if (this.firstCellIndex >= this.camerasList.length - this.totalCells) {
			return;
		}

		this.firstCellIndex += this.totalCells;

		this.loadPage(this.firstCellIndex, this.totalCells);
	}

	private loadPreviousPage() {
		if (this.firstCellIndex === 0) {
			return;
		}

		this.firstCellIndex = this.firstCellIndex <= this.totalCells ? 0 : this.firstCellIndex - this.totalCells;

		this.loadPage(this.firstCellIndex, this.totalCells);
	}

	private async camerasNext() {
		if (!this.tourInProgress) {
			return;
		}

		let nextPageTotal = this.firstCellIndex + (this.totalCells * 2);
		if (nextPageTotal >= this.groupCameras.length && this.groupCameras.length < this.areaCameraCount) {
			this.pageNumber++;
			await this.fetchAreaCameras({ groupID: this.getEventDetails.groupID, pageNumber: this.pageNumber, paginated: true });
		}

		this.loadNextPage();
		this.auditTour( `Video Tour: ${ this.tourType } has loaded next ${ this.cameraCount } cameras`);
	}

	private get tourType() {
		switch(this.selectedTourOption) {
			case TourOptionList.NearbyCameras: {
				return "Nearby Cameras"
			}
			case TourOptionList.PredefinedCameras: {
				return `${ this.selectedPredefinedTour.title }`
			}
			default: {
				return "Area Cameras"
			}
		}
	}

	private camerasPrevious() {
		if (this.atStart || !this.tourInProgress) return;
		this.loadPreviousPage();
		this.auditTour( `Video Tour: ${ this.tourType } has loaded the previous ${ this.cameraCount } cameras`);
	}

	private async startTour() {
		if (this.tourInProgress) {
			return;
		}

		if (this.selectedTourOption === this.TourOptionList.PredefinedCameras) {
			var layoutIndex = this.layouts.findIndex(layout =>
					layout[0] == this.selectedPredefinedTour.matrixColumnSize
					&& layout[1] == this.selectedPredefinedTour.matrixRowSize
			);

			if (layoutIndex > -1) {
				await this.setLayoutIndex(layoutIndex)
				let [matrixColumns, matrixRows] = this.layouts[layoutIndex];
				await this.saveMatrix({
					eventID: this.eventId,
					matrixColumns,
					matrixRows
				});
			}
		}

		await this.$nextTick();

		this.initialLayoutIndex = this.layoutIndex;
		this.initialMatrix = Object.values(
			JSON.parse(JSON.stringify(this.getAllMatrixContents))
		).map(x => x[Object.keys(x)[0]]);

		if (this.selectedTourOption == this.TourOptionList.PredefinedCameras) {
			if (this.camerasList.length < 1) {
				await this.stopTour();
				return;
			}

			let predefinedTourStep: PredefinedTourStep = {
				durationTimer: this.camerasList[0].durationTimer
			};
			const isActiveCell: isActiveCell = { isActive: true, index: 1 } // DEFAULT FIRST CELL.

			let predefinedTour: PredefinedTour  = {
				predefiendStep: predefinedTourStep,
				camerasStreaming: [],
				selectedTourOption: 3,
				cellTrigger: null,
				selectedPredefinedTour: this.selectedPredefinedTour,
				isPausedTour: false,
				tourTimeout: this.tourDefaultTimeout,
				confirmationComment: "",
				durationBasedCamera: false,
				currentCamera: this.camerasList[0],
				currentCameraIndex: 1,
				currentIteration: 0,
				isActive: { isActive: true, index: 1 } // DEFAULT FIRST CELL.
			};

			this.$emit('tourName', `Video Tour: ${ predefinedTour.selectedPredefinedTour.title }`);

			if (predefinedTour.currentCamera.duration > 0 ) {
				predefinedTour.durationBasedCamera = true;
				predefinedTour.predefiendStep.durationTimer = this.camerasList[0].duration;
			} else {
				predefinedTour.durationBasedCamera = false;
			}

			this.auditTour(`User: ${ this.getUserName } started Video Tour: ${ this.selectedPredefinedTour.title }`);
			this.setPredefinedTour(predefinedTour);
			this.loadPage(this.firstCellIndex, this.totalCells);
			this.setIsInTourState(true);


			if (this.getPredefinedTour.durationBasedCamera) {
				await this.countDownTimer();
			} else {
				await this.timePassedLastConfirmed();
			}

			return;

		} else if (this.selectedTourOption == TourOptionList.NearbyCameras)  {
			this.$emit('tourName', "Video Tour: Nearby Cameras");
			this.auditTour(`User: ${ this.getUserName } started Video Tour: Nearby Cameras`);
			this.auditTour(`Video Tour: ${ this.tourType } has loaded first ${ this.cameraCount } cameras`);
			this.nearbyCamerasBeforeChange = this.nearbyCameras;
		}
		else {
			this.$emit('tourName', "Video Tour: Area Cameras");
			this.auditTour(`User: ${ this.getUserName } started Video Tour: Area Cameras`);
			this.auditTour(`Video Tour: ${ this.tourType } has loaded first ${ this.cameraCount } cameras`);
		}

		this.loadPage(this.firstCellIndex, this.totalCells);
		this.setIsInTourState(true);
	}

	private async stopTour() {
		this.auditTour(`User: ${ this.getUserName }, completed Video Tour: ${ this.tourType }`);
		await this.resetTourDetails();

		await this.MatrixsOriginalState();
	}

	private pauseTourToggle(): void {
		this.updatePredefinedTourPausedState(!this.getPredefinedTour.isPausedTour);
		this.auditTour(`User: ${ this.getUserName } ${this.getPredefinedTour.isPausedTour ? `Paused` : `Resumed` } Video Tour: ${ this.selectedPredefinedTour.title } at Camera: ${ this.getPredefinedTour.currentCamera.title }`);
	}

	/** Helper method to populate the Media Matrix */
	private populateMediaMatrix(objectlist: any) {
		for (let index = 0; index < this.totalCells; index++) {
			let newContents = {};
			if (index < objectlist.length) {
					newContents = {
						camera: {
							objectID: objectlist[index].objectID,
							title: objectlist[index].title,
							index: index + 1
						}
					};
			}

			this.setPushContents({
				index: index+1,
				newContents: newContents
			});
		}
	}

	private async confirmFailedCamera() {
		if (!this.isFailedCamera) {
			return;
		}
		if (this.wasTimeout) {
			this.auditTour(
				`Camera ${ this.failedCamera.camera.title } failed to load while User: ${ this.getUserName } was conducting a Video Tour.`,
				this.getPredefinedTour.currentCamera.objectID);
		} else {
			this.auditTour(
				`Camera ${ this.getPredefinedTour.currentCamera.title } has been unconfirmed by User: ${ this.getUserName }. Note: ${ this.failedCamera.failedNote }`,
				this.getPredefinedTour.currentCamera.objectID);
		}


		const nextCamera = this.camerasList.find(x => x.stepOrder == this.getPredefinedTour.currentIteration + 1);
		this.setPredefinedTourCamera(nextCamera);
		if (this.getPredefinedTour.durationBasedCamera) {
			this.setPredefinedTourDurationTimer(this.failedCamera.camera.duration);
		}

		this.activeCellEnable({ isActive: true, index: this.getPredefinedTour.currentCameraIndex });
		this.updatePredefinedTourPausedState(false);
		this.failedCamera = { camera: null, failedNote: "" };
		this.isFailedCamera = false;

		if (this.camerasLeft == -1) {
			await this.stopTour();
		}
	}

	/** Return matrix to its origional State */
	private MatrixsOriginalState() {
		if (!this.tourInProgress) {
			return;
		}
		this.tourInProgress = false;
		this.setIsInTourState(false);
		this.setLayoutIndex(this.initialLayoutIndex);
		this.populateMediaMatrix(this.initialMatrix);
	}

	/** Timer for duration based defined cameras */
	private async countDownTimer() {
		if (this.getPredefinedTour.predefiendStep.durationTimer > 0 && this.tourInProgress && this.selectedTourOption == TourOptionList.PredefinedCameras) {
			setTimeout(async () => {
				// If paused, repear after timeout.
				if (this.getPredefinedTour.isPausedTour)
				{
					await this.countDownTimer();
					return;
				}
				if (this.isCellStreaming) {
					this.updatePredefinedTourDuration(1);
					await this.countDownTimer();
					return;
				}
				else if (!this.isCellStreaming) {
					if (this.getPredefinedTour.tourTimeout <= 1 &&  this.camerasLeft != -1) {
						await this.loadNextCell(false, true);
						this.addPredefinedTourTimeout(this.tourDefaultTimeout);
						const thisCamera = this.camerasList.find(x => x.stepOrder == this.getPredefinedTour.currentCameraIndex);
						this.setPredefinedTourDurationTimer(thisCamera.duration);
						await this.countDownTimer();
						return;
					} else if (this.camerasLeft == -1) {
						await this.stopTour();
						return;
					} else {
						this.updatePredefinedTourTimeout(1);
						await this.countDownTimer();
						return;
					}
				}
			}, 1000);
		} else {
			// Check to see if there is another camera within the list.
			if (this.tourInProgress && this.camerasLeft != -1) {
				// Load next cell.
				await this.loadNextCell(true);
				const thisCamera = this.camerasList.find(x => x.stepOrder == this.getPredefinedTour.currentCameraIndex);
				this.setPredefinedTourDurationTimer(thisCamera.duration);

				if (this.getPredefinedTour.predefiendStep.durationTimer > 0) {
					await this.countDownTimer();
					return;
				}
			} else {
				await this.stopTour();
			}
		}
	}

	/** Timer for duration based defined cameras */
	private async timePassedLastConfirmed() {
		if (this.tourInProgress && this.selectedTourOption == TourOptionList.PredefinedCameras && !this.getPredefinedTour.durationBasedCamera) {
			setTimeout(async () => {
				this.timePassed++;
				await this.timePassedLastConfirmed();
			}, 1000);
		}
	}

	/** Audit based logic */
	private async auditTour(details: any, objectId: number = null) {
		// Create audit saying that the user didn't pick up the phone.
		await this.createAuditRecord({
			eventId: this.getEventDetails.eventID,
			eventRecordTypeId: 125,
			details: details,
			objectID: objectId > 0 ? objectId : null
		});
	}
}
