
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { AudioDevice, AudioX } from "@/types/audio/audio";
import AudioMixin, { SvAudioContext } from "@/mixins/audio/AudioMixin";
import AudioAddressMixin from "@/mixins/audio/AudioAddressMixin";

@Component({})
export default class Receive extends Mixins(AudioMixin, AudioAddressMixin) {
    @Prop({ default: null, type: Object })
    private audioReceive: AudioDevice;

    @Prop({default: "", type: String})
    private deviceAuth: string; 

    @Prop() 
    private eventId: number;
    
    @Prop({default: "", type: String})
    private deviceServiceEndpoint: string;

    private active: boolean = false;
    private address: string = "";

    private async mounted() {
        this.auth = this.deviceAuth;
        await this.updateAudioUrl();
        window.addEventListener('beforeunload', this.unloadHook)
    }

    private unloadHook(): void {
        if (this.audioReceive) {
            this.auditAudioStop(this.audioReceive.deviceId, this.audioReceive.title)
        }
    }

    @Watch("audioReceive")
    private async audioDeviceChanged(newDevice: AudioDevice | null, oldDevice: AudioDevice | null): Promise<void> {
        if(!newDevice || (newDevice && oldDevice && newDevice.deviceId != oldDevice.deviceId)){
            await this.auditAudioStop(newDevice.deviceId, newDevice.title);
        }
        this.updateAudioUrl();
    }

    @Watch("eventId")
    private async updateAudioUrl(): Promise<void> {
        this.active = false;
        await this.listenAudio(false);
        await this.stopAudio();

        this.eventRecordId = null;
        if (!this.eventId || !this.audioReceive) 
            return;

        this.eventRecordId = await this.generateEventRecord(this.eventId, this.audioReceive.deviceId, `Audio receive opened for device: ${this.audioReceive.title}`);
        var audioDevice = {...this.audioReceive};
        audioDevice.eventRecordId = this.eventRecordId;
        this.address = this.constructAddress(this.deviceServiceEndpoint, AudioX.receive, audioDevice);
        this.setAudio();
    }

    /**
     * @summary computed property which will return the varient state of the button
     * @returns { string } 'primary' or 'secondary'
     */
    private get currentVariant(): string {
        if (!this.getAuthState) 
            return "warning";
        return this.active ? "primary" : "secondary";
    }

    /**
     * @summary method to toggle the active state for receive.
     */
    private async toggleActiveState(): Promise<void> {
        if (!this.audioReady) {
            return;
        }

        this.active = !this.active;
        if (!this.active) {
            if (this.eventId && this.audioReceive && this.audioReceive.deviceId) {
                await this.auditAudioStop(this.audioReceive.deviceId, this.audioReceive.title);
            }
            await this.updateAudioUrl();
        } else {
            await this.listenAudio(true);
        }
    }

    public async auditAudioStop(deviceId: number, deviceTitle: string): Promise<void> {
        await this.generateEventRecord(this.eventId, deviceId, `Audio receive closed for device: ${deviceTitle}`);
    }

    /**
     * @summary method which will setup the audio instance
     */
    private setAudio(): void {
        if (!this.address) 
            return;

        const audioContext: SvAudioContext = {
            url: this.address,
            playingState: this.active
        }
        this.setupAudio(audioContext);
    }

    private async destroyed() {
        if (this.eventId && this.audioReceive) {
            await this.auditAudioStop(this.audioReceive.deviceId, this.audioReceive.title)
        }
        window.removeEventListener("beforeunload", this.unloadHook);
    }
}
