// `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places`

import axios from "axios";
import { ILatLng } from "@sureview/v2-mapping-saas";
import { SVLocation } from "@/store/mobile/types";
import { convertStringToLatLng } from "@sureview/v2-mapping-saas";

export interface GeoCodeAddressComponent {
	long_name: string;
	short_name: string;
	types: string[];
}

export interface GeoCodeLocation {
	lat: number;
	lng: number;
}

export interface GeoCodeViewport {
	northeast: GeoCodeLocation;
	southwest: GeoCodeLocation;
}

export interface GeoCodeGeometry {
	location: GeoCodeLocation;
	location_type: string;
	viewport: GeoCodeViewport;
}

export interface GeoCodeResult {
	address_components: GeoCodeAddressComponent[];
	formatted_address: string;
	geometry: GeoCodeGeometry;
	place_id: string;
	types: string[];
}

export interface GeoCodeResponse {
	results: GeoCodeResult[];
	status: string;
}

export class LocationBounds {
	public location: ILatLng;
	public viewport: {
		northeast: ILatLng;
		southwest: ILatLng;
	};
}
export default class GPSService {
	private axios: any;

	constructor() {
		this.axios = axios.create({
			baseURL: "https://maps.googleapis.com/maps/api/geocode/json"
		});
	}

	public async GeoCodeLookup(
		address: string,
		key: string
	): Promise<GeoCodeResponse> {
		return (await this.axios.get("", {
			params: {
				address,
				key
			}
		})).data;
	}

	public tryConvertStringToGeoCodeLocation(latLong: string): GeoCodeLocation {
    	const error = "Invalid Lat Long";
		const latLongParts = latLong.split(" ");
		if (latLongParts.length !== 2) {
			throw error;
		}

		const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
		let isLatLongTwoFloats = floatRegex.test(latLongParts[0]) && floatRegex.test(latLongParts[1]);
		if (!isLatLongTwoFloats) {
			throw error;
		}

		const isLatLongComplete = latLongParts[0].slice(latLongParts[0].length - 1) !== "." && latLongParts[1].slice(latLongParts[1].length - 1) !== ".";
		if (!isLatLongComplete) {
			throw error;
		}

		const latLng = convertStringToLatLng(latLong);
		const isLatAndLngNotSet = isNaN(latLng.lng) || isNaN(latLng.lat);
		if (isLatAndLngNotSet) {
			throw error;
		}

		const isLatValueValid = latLng.lat >= -85 && latLng.lat <= 85;
		const isLngValueValid = latLng.lng >= -180 && latLng.lng <= 180;
		const isLatLongValid = isLatValueValid && isLngValueValid;

		if (!isLatLongValid) {
			throw error;
		}
		return latLng;
	}

	public async decodeLocation(location: string, mapKey: string): Promise<LocationBounds> {
		try {
			if (/^-?[\d.]+[NS]?,? -?[\d.]+[WE]?($|,)/.test(location)) {
				return {
					location: convertStringToLatLng(location),
					viewport: null
				};
			} else {
				let response = await this.GeoCodeLookup(location, mapKey);

				if (response.results.length == 0) {
					return {
						location: {
							lat: null,
							lng: null
						},
						viewport: null
					};
				}

				let addressGeometry = response.results[0].geometry;

				if (addressGeometry.viewport) {
					return {
						location: {
							lat: addressGeometry.location.lat,
							lng: addressGeometry.location.lng
						},
						viewport: {
							northeast: addressGeometry.viewport.northeast,
							southwest: addressGeometry.viewport.southwest
						}
					};
				} else {
					return {
						location: {
							lat: addressGeometry.location.lat,
							lng: addressGeometry.location.lng
						},
						viewport: null
					};
				}
			}
		} catch {
			console.log("geoCoder failed to decodeLocation");
			return {
				location: {
					lat: null,
					lng: null
				},
				viewport: null
			};
		}

	}

	public parseILatLngToLocation(location: ILatLng): string {
		if (!location) {
			return null;
		}
		return location.lat + " " + location.lng;
	}

	public parseGoogleLatLngToLocation(location: google.maps.LatLng): string {
		if (!location) {
			return null;
		}

		let locationJson: SVLocation = {
			lat: location.lat(),
			lng: location.lng()
		};

		return JSON.stringify(locationJson);
	}

	public rad(x: number) {
		return (x * Math.PI) / 180;
	}

	public metersToKm(meters: number) {
		return meters * 0.001;
	}

	public metersToMiles(meters: number){
		return meters * 0.00062137119224;
	}

	public metersToFeet(meters) {
		if (meters < 0) return 0;
		else return Math.round(meters / 0.3048);
	}

	public getDistance(p1: any, p2: any): number {
		let R = 6378137; // Earth's mean radius in meters

		let dLat = this.rad(p2.lat - p1.lat);
		let dLong = this.rad(p2.lng - p1.lng);
		let a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = R * c;

		return Math.round(d);
	}
}
