
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import TreeSelectNode from "@/types/sv-data/groups/TreeSelectNode";
import { Group } from "@/types";

@Component({
	components: {
		"area-tree-select": AreaTreeSelect
	}
})
export default class AreaTreePath extends Vue {
	@Prop()
	public value;

	@Prop()
	public disabled: boolean;

	private localValue: Group [] = [];

	private selectedArea: TreeSelectNode [] = [];

	private onAreaClicked(area: Group) {
		if (this.disabled) {
			return;
		}
		const indexClicked = this.value.findIndex(a => a.groupID === area.groupID);
		if (indexClicked === 0) {
			this.localValue = [ this.value[indexClicked] ];
		} else {
			this.localValue = this.value.slice(0, indexClicked + 1);
		}

		this.$emit("input", this.localValue);
		this.$emit("onAreaSelected", area.groupID);
	}

	@Watch("selectedArea")
	private selectedAreaChanged() {
		if (this.selectedArea && this.selectedArea.length > 0 && this.selectedArea[0]) {
			this.$emit("input", this.selectedArea.map(i => { return { ...i, groupID: i.id, title: i.label }}));
			this.$emit("onAreaSelected", this.selectedArea[this.selectedArea.length - 1].id);
			this.selectedArea = [];
		}
	}

	private get areasInPath() {
		if (!this.value || this.value.length === 0) {
			return "";
		}
		return this.value.map(x => x.title);
	}
}
