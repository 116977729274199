
import { Component, Watch, Mixins } from "vue-property-decorator";
import { namespace, Getter, Action } from "vuex-class";
import Multiselect from "vue-multiselect";
import ShortcutHelp from "./ShortcutHelp.vue";
import AlarmQueueFilterEdit from "@/components/AlarmQueueFilterEdit.vue";
import NavMenu from "@/components/NavMenu.vue";
import NavHeader from "@/components/NavHeader.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { UserPermissions, FeaturesList } from "@/store/types";
import RegionalHandlerMixin from "@/mixins/RegionalHandlerMixin";
import { get } from "lodash";
import {
	AlarmQueueFilterID,
	FilteredEvent,
	AlarmQueueFilter,
	AlarmQueueFilterEditModel,
	EventQueueInfo,
} from "@/store/eventqueue/types";
import AreaNotesFinder from "@/components/area-notes/AreaNotesFinder.vue";
import MaskingModalLauncher from "@/components/alarm-masking/MaskingModalLauncher.vue";
import SystemViewHealthFilter from '@/components/system-view/SystemViewHealthFilter.vue';
import userApi from "@/services/api.user.service";

const Eventqueue = namespace("eventqueue");
const ManualRaiseStore = namespace("manualRaise");
const Subscription = namespace("subscription");
const AlarmPoints = namespace("alarmPoints");

@Component({
	components: {
		multiselect: Multiselect,
		"shortcut-help": ShortcutHelp,
		VuePerfectScrollbar: VuePerfectScrollbar,
		"alarm-queue-filter-edit": AlarmQueueFilterEdit,
		"nav-menu": NavMenu,
		"nav-header": NavHeader,
		"area-notes-finder": AreaNotesFinder,
		"masking-modal-launcher": MaskingModalLauncher,
		"system-view-health-filter": SystemViewHealthFilter,
	}
})
export default class AlarmQueueHeader extends Mixins(RegionalHandlerMixin) {

	$refs!: {
		deleteFilterConfirmation: any;
		areaNotesFinder: AreaNotesFinder;
	};

	@Getter getFeature: (featuresList: string[]) => boolean;

	@Eventqueue.Getter("getActiveEvents") events!: FilteredEvent[];
	@Eventqueue.Getter("getActiveFilterID") currentFilterID!: AlarmQueueFilterID;
	@Eventqueue.Getter("getAlarmQueueFilters") activeFilters!: AlarmQueueFilter[];
	@Eventqueue.Getter("getLastUpdatedEvents") eventsLastUpdated!: number;
	@Eventqueue.Getter("getUserZone") userZone!: any;
	@Eventqueue.Getter getHandlingEvent: boolean;
	@Eventqueue.Getter("getBulkHandleShown") bulkHandleShown!: boolean;

	@Eventqueue.State("activeAlarmCount") private activeAlarmCount: number;
	@Eventqueue.State("eventQueueUpdateInfoHistory") private eventQueueInfo: EventQueueInfo[];

	@Eventqueue.Mutation setActivityLogShown: any;
	@Eventqueue.Mutation setManualPatrolShown: any;
	@Eventqueue.Mutation setManualActionShown: any;
	@Eventqueue.Mutation setNewFilterModalShown: any;
	@Eventqueue.Mutation setBulkHandleShown: (shown: boolean) => void;
	@Eventqueue.Mutation setSelectedAlarms: (data: number[]) => void;
	@Eventqueue.Mutation setAllAlarmsSelected: (allSelected: boolean) => void;
	@Eventqueue.Mutation setVirtualOperatorControlPanelShown: (open: boolean) => void;

	@Eventqueue.Action setActiveFilter: any;
	@Eventqueue.Action fetchAlarmQueueFilters: any;
	@Eventqueue.Action fetchEventQueue: any;
	@Eventqueue.Action loadAvailableAlarmQueueFilters: any;
	@Eventqueue.Action addAlarmQueueFilter: any;
	@Eventqueue.Action removeAlarmQueueFilter: any;
	@Eventqueue.Action eventHandleTop: any;
	@Eventqueue.Action deleteAlarmQueueFilter: any;

	// Getter and action for toggling the map' display.
	@Eventqueue.Action setHideMapFlag: any;
	@Eventqueue.Getter("getHideMapFlag") hideMapFlag: boolean;

	@ManualRaiseStore.Mutation setManualRaiseShown: (visible: boolean) => void;

	@AlarmPoints.Mutation setAlarmPointManagerOpen: (open: boolean) => void;

	@Getter getPermissions: UserPermissions;
	@Getter("getActiveRegion") activeRegion: any;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter getUserTenantGroupId: number;

	@Action serverLogout: any;
	@Action logout: () => void;

	@Subscription.Getter isSubscriptionActive: boolean;
	@Subscription.Action fetchSubscription: (id: number) => Promise<void>;

	public updateInterval: any;
	public stopped = false;
	public allAvailableFilters: AlarmQueueFilter[] = [];
	private isUpdateInProgress = false;

	public filterSearch: string = "";

	public modalShow = false;

	public addingFilter = false;
	public filterToDelete = {};
	public editedFilter: AlarmQueueFilterEditModel = null;

	private isAreaNotesMousedOver = false;
	private lastUpdatedCheckTimer: any = null;
	private hasAlarmSoundBeenTriggered: boolean = false;
	private isAlarmSoundQueued: boolean = false;

	private groupId: number | null = null;

	private get isQuickControlEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "QuickControl"]) && this.getPermissions.canAccessQuickControl;
	}

	private get isSystemViewHealthEnabled(): boolean {
		return get(this.featuresList, ["SystemView","EventQueueFilter"]) && (this.getPermissions.canViewSystemView || this.getPermissions.isSystemAdmin);
	}

	public get debugMode() {
		return get(this.featuresList, ["DebugMode"]);
	}

	get activeEventsCount(): number {
		return this.activeFilters.length ? this.activeFilters[0].activeAlarmCount : 0;
	}

	public get visibleFilters() {
		return this.activeFilters.filter(filter => !filter.hide);
	}

	public get availableFilters() {
		return this.allAvailableFilters.filter(queueFilter => {
			return (
				this.activeFilters.findIndex(
					activeFilter =>
						activeFilter.alarmQueueFilterID === queueFilter.alarmQueueFilterID &&
						activeFilter.regionID === queueFilter.regionID
				) == -1
			);
		});
	}

	// Gets the filters and sorts then alphabetically
	public get filteredFilters() {
		const titleWithRegion = (filter: AlarmQueueFilter) =>
			filter.regionID > 0 ? `${filter.title} (${filter.regionTitle})` : filter.title;
		let search = this.filterSearch.toLowerCase();
		return this.availableFilters
			.filter(
				filter =>
					titleWithRegion(filter)
						.toLowerCase()
						.indexOf(search) > -1
			)
			.sort(function(a, b) {
				var first = a.title.toUpperCase();
				var second = b.title.toUpperCase();
				return first < second ? -1 : first > second ? 1 : 0;
			});
	}

	public get virtualOperatorsEnabled() {
		return get(this.featuresList, ["AI", "VirtualOperators"]) && this.getPermissions.canUseVirtualOperators;
	}

	public get siteMonitorEnabled() {
		return get(this.featuresList, ["Alarms", "SiteMonitor"]);
	}

	public get isActivityLogEnabled() {
		return get(this.featuresList, ["ActivityLog"]);
	}

	public get isManualTourEnabled() {
		return get(this.featuresList, ["Alarms", "EventQueue", "ManualTour"]);
	}

	private get isAreaNotesEnabled() {
		return get(this.featuresList, ["AreaNotes"]) && get(this.featuresList, ["AreaNotes", "Icons"]);
	}

	// Feature flag for map display toggling functionality.
	private get isToggleMapEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Maps", "ToggleEventQueue"]);
	}

	public get bulkHandleEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventQueue", "EndMultipleEvents"]);
	}

	public get bulkHandlePermission(): boolean {
		return this.getPermissions.canEndMultipleEvents;
	}

	public get eventBulkHandleAllowed(): boolean {
		return this.bulkHandleEnabled && this.bulkHandlePermission;
	}

	@Watch("eventsLastUpdated")
	public onEventsChanged() {
		if (this.events == null) {
			return;
		}

		if (this.events.filter(q => q.eventTypeID == 1 && !q.inProcessing && q.unParkAt == null).length > 0) {
			this.makeAlarm();
		}
		else {
			this.hasAlarmSoundBeenTriggered = false;
			this.isAlarmSoundQueued = false;
		}
	}

	@Watch("activeRegion", { deep: true })
	public onActiveRegionChanged(newRegion: any, oldRegion: any) {
		// If we're switching to a non-local region (regionID not 0)
		if (newRegion.regionId !== 0) {
			// Stop polling, as we don't have perms to fetch the event queue
			this.stopped = true;
		} else {
			this.stopped = false;
			this.updateInterval = setTimeout(this.update, 2000);
		}
	}

	public async mounted(): Promise<void> {
		this.hideConfirmationModal();
		this.stopped = false;

		this.fetchAlarmQueueFilters();
		this.setAllAvailableFilters();

		// @refactor messy as hell, investigate a single call to `this.update` and
		// reduce number of mutaitons, re-work state to be less "flat",
		// complicated selections can be done with getters.
		this.updateInterval = setTimeout(this.update, 2000);
		this.lastUpdatedCheckTimer = setInterval(() => this.checkLastUpdated(), 5000);

		await this.fetchSubscription(this.getUserTenantGroupId);
		await this.onGroupIdChanged();
	}

	private checkLastUpdated(): void {
		//Throw if the events haven't been updated in the last 5 seconds
		const timeToCheck = Date.now() - 5000;
		if(this.eventsLastUpdated && (this.eventsLastUpdated < timeToCheck))
			throw "EventQueueDelay [" + new Date().toUTCString() + "] " + this.lastUpdatedString;
	}

	private get lastUpdatedString(): string {
		return new Date(this.eventsLastUpdated).toLocaleDateString() + " " + new Date(this.eventsLastUpdated).toLocaleTimeString()
	}

	private beforeDestroy(): void {
		clearInterval(this.lastUpdatedCheckTimer);
		this.lastUpdatedCheckTimer = null;
	}

	private async setAllAvailableFilters(): Promise<void> {
		const filters = await this.loadAvailableAlarmQueueFilters();
		this.$set(this, "allAvailableFilters", filters);
	}

	public async update(): Promise<void> {
		if (!this.stopped && !this.isUpdateInProgress) {
			const before = performance.now();
			try {
				this.isUpdateInProgress = true;
				await this.fetchEventQueue();
				this.isUpdateInProgress = false;
			} catch (ex) {
				console.error(ex);
			}

			const after = performance.now();
			const timeTaken = after - before;

			this.updateInterval = setTimeout(this.update, Math.max(10, 2000 - timeTaken));
		} else {
			if (this.updateInterval != null) {
				clearTimeout(this.updateInterval);
				this.updateInterval = null;
			}
		}
	}

	public makeAlarm(): void  {
		if (process.env.VUE_APP_ALARM_BLINKING_ENABLED) {
			document.body.classList.add("alarm-body");

			setTimeout(() => {
				if (document.body.classList.contains("alarm-body")) {
					document.body.classList.remove("alarm-body");
				}
			}, 1000);
		}

		if (process.env.VUE_APP_ALARM_AUDIO_ENABLED && this.activeEventsCount) {
			try {
				const audio = document.getElementById("alarmSound");
				if (this.hasAlarmSoundBeenTriggered) {
					audio && (audio as HTMLAudioElement).play()
				}
				if (!this.hasAlarmSoundBeenTriggered && !this.isAlarmSoundQueued) {
					this.isAlarmSoundQueued = true;
					setTimeout(() => {
						if (this.isAlarmSoundQueued) {
							audio && (audio as HTMLAudioElement).play();
							this.hasAlarmSoundBeenTriggered = true;
						}
					}, 5000);
				}
			} catch {
				console.log("Error playing alarm sound");
			}
		}
	}

	public destroyed(): void  {
		this.stopped = true;
		this.isAlarmSoundQueued = false;
		if (this.updateInterval != null) {
			clearInterval(this.updateInterval);
			this.updateInterval = null;
		}
	}

	public async logoutUser(): Promise<void> {
		await this.serverLogout();
		this.logout();
	}

	// Clears the selectedAlarms
	public clearSelectedAlarms(): void  {
		this.setSelectedAlarms([]);
		// not totally needed but ensure no lag in the UI updating the SelectAll checkbox
		this.setAllAlarmsSelected(false);
	}

	// Called when the BulkHandle button is clicked.
	// calls the store action, inverting the value currently stored in the store state
	public toggleBulkHandle(): void  {
		this.setBulkHandleShown(!this.bulkHandleShown);

		// when either showing or hiding the bulk realted controls
		// clear out any selected rows
		this.clearSelectedAlarms();
	}

	public selectFilter(filter: AlarmQueueFilter): void {
		// when changing filters clear out any selected rows
		this.clearSelectedAlarms();

		// Update the store with the details of the filter
		const data = {
			filterId: filter.alarmQueueFilterID,
			regionId: filter.regionID
		};
		this.setActiveFilter(data);
	}

	public editFilter(filter: AlarmQueueFilter): void {
		// @techdebt @todo delete after serverside implemetation
		const editedFilter: AlarmQueueFilterEditModel = {
			alarmQueueFilterID: filter.alarmQueueFilterID,
			title: filter.title,
			priorityValue: filter.priorityHigherThan,
			priorityOperator: this.capitalizeFirstLetter(filter.priorityHigherThanDDL),
			numberOfEventsValue: filter.numberOfEvents,
			numberOfEventsOperator: this.capitalizeFirstLetter(filter.numberOfEventsDDL),
			alarmAgeValue: filter.olderThan,
			alarmAgeOperator: this.capitalizeFirstLetter(filter.olderThanDDL),
			alarmTagID: filter.responseAlarmTypeID,
			groupID: filter.groups.length ? filter.groups[0].groupID : null,
			shared: filter.shared,
			created: filter.created,
			combinedFilters: filter.combinedFilters,
			groups: filter.groups
		};

		this.$set(this, "editedFilter", editedFilter);

		this.setNewFilterModalShown(true);
	}

	public manualPatrolOpen(): void {
		this.setManualPatrolShown(true);
	}

	public virtualOperatorControlPanelOpen() {
		this.setVirtualOperatorControlPanelShown(true);
	}

	public manualRaiseOpen() {
		// Show map if hidden.
		if(this.isToggleMapEnabled) {
		    this.setHideMapFlag(false);
		}
		this.setManualRaiseShown(true);
	}

	public activityLogOpen() {
		this.setActivityLogShown(true);
	}

	public manualActionOpen() {
		this.setManualActionShown(true);
	}

	public async newFilterSelected(filter: AlarmQueueFilter) {
		this.addingFilter = true;

		await this.addAlarmQueueFilter({
			filterId: filter.alarmQueueFilterID,
			regionId: filter.regionID || null
		});

		this.addingFilter = false;

		this.activeFilters.forEach(activeFilter => {
			if (
				activeFilter.alarmQueueFilterID == filter.alarmQueueFilterID &&
				activeFilter.regionID == filter.regionID
			) {
				this.selectFilter(activeFilter);
			}
		});
	}

	public async removeFilter(filter: AlarmQueueFilter) {
		let filterId = filter.alarmQueueFilterID;
		let regionId = filter.regionID || 0;

		await this.removeAlarmQueueFilter({ filterId, regionId });
	}

	public confirmDeleteFilter(filter: AlarmQueueFilter) {
		this.filterToDelete = filter;
		this.modalShow = true;
	}

	public async deleteFilter(id: number) {
		await this.deleteAlarmQueueFilter(id);
		this.hideConfirmationModal();
		this.setAllAvailableFilters();
	}

	public hideConfirmationModal() {
		this.modalShow = false;
	}

	public onCancelFilterEditing() {
		this.editedFilter = null;
	}

	private capitalizeFirstLetter(str: string) {
		return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
	}

	public resetFilterSearch() {
		this.filterSearch = "";
	}

	private moveFilter(forward: boolean){
		if (!this.activeFilters || !this.activeFilters.length || this.activeFilters.length == 1)
			return;

		const indexOfCurrent = this.visibleFilters.findIndex(x => x.isActive);
		const filterLength = this.visibleFilters.length - 1;
		let newIndex = indexOfCurrent;
		newIndex = forward ? (indexOfCurrent + 1) : (indexOfCurrent - 1);
		newIndex = newIndex < 0 ? (0) : (newIndex > filterLength ? filterLength : newIndex);
		this.selectFilter(this.activeFilters[newIndex]);
	}

	private logEventQueueHistory(): void {
		if(!this.debugMode)
			return;
		throw "EventQueueHistory " + JSON.stringify(this.eventQueueInfo);
	}

	private alarmPointsOpen(): void {
		this.setAlarmPointManagerOpen(true);
	}

	private get isOnTestEnabled(): boolean {
		return this.getFeature(["Alarms", "AlarmPoints", "OnTest"]) && !!this.getPermissions.canPutAlarmPointsOnTest;
	}

	private get isAlarmMaskingEnabled(): boolean {
		return this.getFeature(["Alarms", "Masking"]) &&
			(this.getPermissions.canDisarmSites || this.getPermissions.canDisarmSitesExtended);
	}

	@Watch("userZone.GroupID")
	private async onGroupIdChanged() : Promise<void> {
		if (!this.userZone || !this.userZone.groupID) {
			this.groupId = null;
		}

		const userHasPermission = await userApi.checkUserHasPermissionToGroup(this.userZone.groupID);
		this.groupId = userHasPermission ? this.userZone.groupID : null;
	}
}
