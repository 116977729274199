
import { Component, Mixins } from "vue-property-decorator";
import GenericTable, { TableHeader } from '../table/generic-table.vue';
import GenericUpdateModal, { ModalItem } from '../table/generic-update-modal.vue';
import api from '@/services/api.service';
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { AreaNode } from '@/types/sv-data/groups/AreaNode';
import AssetTypeSelect from '@/views/UserSetup/AssetTypeSelect.vue';
import AssetColorPicker from './AssetColorPicker.vue';
import Asset from '@/types/sv-data/Asset';
import PaginatedSearch from "@/mixins/PaginatedSearch";
import { FeaturesList, PaginatedSearchQueryParams, UserPermissions } from "@/store/types";
import { Getter } from "vuex-class";
import { get } from "lodash";

@Component({
	components: {
		"generic-table": GenericTable,
		"generic-update-modal": GenericUpdateModal,
        "color-swatch" : AssetColorPicker
	}
})
export default class AssetOnlySetup extends Mixins(PaginatedSearch) {
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter("getPermissions") permissions: UserPermissions;

	private get columns(): TableHeader[] {
		const columns : TableHeader[] = [
			{
				title: "Asset Title",
				key: "title",
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "The asset title",
				searchable: true,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				isSortedByDefault: true,
			},
			{
				title: "Asset Type",
				key: "assetTypeTitle",
				order: 2,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Asset Type of the Asset",
				searchable: true,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
				sortKey: "Type"
			},
			{
				title: "Area",
				key: "groupTitle",
				order: 3,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Area the asset belongs under",
				searchable: true,
				visible: true,
				dataType: "select",
				isTermLabel: true,
				sortable: true,
				sortKey: "Area"
			},
			{
				title: "Icon Color Override",
				key: "iconColor",
				order: 4,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Icon color of the asset type while on the map",
				searchable: false,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				useCustomCell: true
			},
			{
				title: "Area Sync",
				key: "groupSyncTitle",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Area Sync for syncing",
				searchable: false,
				dataType: "input",
				isTermLabel: true,
				visible: true,
				disable: !this.syncAssetFeatureEnabled
			},
			{
				title: "Device",
				key: "serverTitle",
				order: 6,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Device for syncing",
				searchable: false,
				dataType: "input",
				isTermLabel: true,
				visible: true,
				disable: !this.syncAssetFeatureEnabled
			}
		];

		return columns;
	}

	public get modalItems (): ModalItem[] {
		return [
			{
				title: "Title",
				key: "title",
				dataType: "text",
				maxLength: 250,
				required: true,
                placeholder: "Enter an asset title",
				readOnlyMethod: (item: ModalItem, row: any) => { return (!this.canOverrideGroupSync && row?.groupSyncTitle != null) },
            },
			{
				title: "Asset Type",
				key: "assetTypeId",
				dataType: "component",
				required: true,
				data: AssetTypeSelect,
				props: {
					assetOnlyTypes: true,
					clearable: false
				},
				readOnlyMethod: (item: ModalItem, row: any) => { return (!this.canOverrideGroupSync && row?.groupSyncTitle != null) },
			},
            {
				title: "Area",
				key: "groupId",
				dataType: "component",
				required: true,
				data: AreaTreeSelect,
				props: {
					reduce: (area: AreaNode) => area.id,
					clearable: false
				},
				readOnlyMethod: (item: ModalItem, row: any) => { return (!this.canOverrideGroupSync && row?.groupSyncTitle != null) },
			},
            {
				title: "Icon Color",
				key: "iconColor",
				dataType: "component",
				data: AssetColorPicker,
				readOnly: false,
				description: "Setting an icon color will override the asset type icon color for this asset"
			},
			{
				title: "Area Sync",
				key: "groupSyncTitle",
				dataType: "text",
				visible: this.syncAssetEnabled && this.selectedAsset != null,
				readOnlyIfEdit: true,
			},
			{
				title: "Device",
				key: "serverTitle",
				dataType: "text",
				visible: this.syncAssetEnabled && this.selectedAsset != null,
				readOnlyIfEdit: true,
			},
        ]
    }

	private assets: Asset[] = [];
	private selectedAsset: Asset = null;

	private async mounted() {
		await this.updateData();
	}

	private async onModalOpen(value: any): Promise<void> {
		if (value){
			this.selectedAsset = value;
		}
		else {
			this.selectedAsset = null;
		}
    }

	private async putAsset(asset: Asset): Promise<void> {
		asset.live = true;
		await api.createOrUpdateAsset(asset);
		this.updateData(this.mostRecentSearchParams);
    }

    private async disableAsset(asset: Asset): Promise<void> {
		await api.disableAsset(asset.assetId);
		this.updateData(this.mostRecentSearchParams);
    }

	private totalRecords: number = 0;
	private isLoading: boolean = false;

	public async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams) {
		try {
			this.isLoading = true;
			this.assets = [];
			const request = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);
			let assetsWithPagination = await api.getPagedStandaloneAssets(request);
			this.totalRecords = assetsWithPagination.totalRecords;
			this.assets = assetsWithPagination.data;
		}
		catch (ex) {
			console.log("Unexpected error fetching assets: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	private get syncAssetEnabled(): boolean {
		return this.getFeature(["Mobile", "FieldOps", "SyncedAssets"]);
	}

	private get syncAssetFeatureEnabled(): boolean {
		return get(this.featuresList, ["Mobile", "FieldOps", "SyncedAssets"]);
	}

	private get canOverrideGroupSync(): boolean {
		return this.permissions.canOverrideGroupSync;
	}
}
