
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace, State, Getter } from "vuex-class";
import { debounce, get } from "lodash";

import { validationMixin } from "vuelidate";
import { maxLength, integer, required } from "vuelidate/lib/validators";
import { MarkerIcons } from "@sureview/v2-mapping-saas"
import vselect3 from "vselect3"
import api from "@/services/api.service";
import { MapLayerItemType, MapLayerItem, MapLayer, MapLayerItemTypeIds } from "@/store/map-layers/types";
import { FeaturesList, ElevationLabelType } from "@/store/types";
import { Response } from "@/store/alarm-masking/types";
import ElevationSelect from "@/components/form/ElevationSelect.vue"

const MapLayers = namespace("mapLayers");

interface IMapLayerDisplay {
	title: string;
	mapLayerId: number;
}

class MapLayerItemDeviceOption {
	constructor(mapItemObject = null, typeId = null) {
		if (mapItemObject) {
			(this.title = mapItemObject.title),
				(this.typeId = typeId),
				(this.objectId = this.getObjectId(mapItemObject));
		}
	}
	public typeId: number = 0;
	public title: string = "";
	public objectId: number = 0;
	private getObjectId(mapItemObject) {
		let objectId = -1;
		// CHANGE TO SWITCH LATER
		if (this.typeId == 0 && mapItemObject.hasOwnProperty("objectId")) {
			// Map Item
			objectId = mapItemObject.objectId;
		}
		if (this.typeId == MapLayerItemTypeIds.Camera && mapItemObject.hasOwnProperty("deviceID")) {
			objectId = mapItemObject.deviceID;
		}
		if (this.typeId == MapLayerItemTypeIds.Audio && mapItemObject.hasOwnProperty("deviceID")) {
			objectId = mapItemObject.deviceID;
		}
		if (this.typeId == MapLayerItemTypeIds.Relay && mapItemObject.hasOwnProperty("deviceID")) {
			objectId = mapItemObject.deviceID;
		}
		if (this.typeId == MapLayerItemTypeIds.Alarm && mapItemObject.hasOwnProperty("responseID")) {
			objectId = mapItemObject.responseID;
		}
		if (this.typeId == MapLayerItemTypeIds.Output && mapItemObject.hasOwnProperty("deviceID")) {
			objectId = mapItemObject.deviceID;
		}
		if (this.typeId == MapLayerItemTypeIds.Geofence && mapItemObject.hasOwnProperty("?")) {
			objectId = mapItemObject.responseID;
		}

		return objectId;
	}
}

@Component({
	components: {
		vselect3,
		"elevation-select": ElevationSelect
	},
	mixins: [validationMixin],
	validations: {
		updatedMapLayerItem: {
			title: {
				required,
				maxLength: maxLength(250)
			},
			minElevation: {
				integer
			},
			maxElevation: {
				integer
			}
		}
	}
})

export default class MapLayerItemSetup extends Vue {
	@Prop({ default: null }) public mapLayerItem: MapLayerItem; // The Item we are Creating / Editing
	@Prop({ default: () => [] }) public mapLayersInBounds: MapLayer[]; // The Item we are Creating / Editing
	@Prop(Number) public groupId: number;
	@Prop(Number) public currentFloor: number;

	@State private featuresList: FeaturesList;

	@MapLayers.State("mapItemIcons") private mapIcons!: MarkerIcons;
	@MapLayers.Getter("getMapLayerItemTypes") public mapItemTypes: MapLayerItemType[];

	private isEditMode: boolean = false;
	private isDeleteConfirmationVisible: boolean = false;

	private mapLayerDisplay: IMapLayerDisplay = { title: "", mapLayerId: 0 };
	private selectedLayers: MapLayer[] = null;

	private selectedDevice: MapLayerItemDeviceOption = null;
	private availableDevices: MapLayerItemDeviceOption[] = [];

	private mapItemTemplate: MapLayerItem; // The thing we validate against and reset to
	private updatedMapLayerItem: MapLayerItem = {
		mapLayerItemId: 0,
		mapLayerItemTypeId: 0,
		objectId: 0,
		extraValue: "",
		title: "",
		latLong: "",
		minElevation: 0,
		maxElevation: 0,
		regionPath: "",
		mapLayerIds: [],
		hideOnMap: false,
		groupId: null
	};

	// For translating the mapLayerItemTypeId into a user friendly title
	private typeDictionary: Map<number, string> = new Map([
		[1, "Camera"],
		[2, "Audio"],
		[3, "Door"],
		[4, "Alarm"],
		[5, "Output"],
		[6, "Geofence"]
	]);

	private selectedFloor: number = 1;

	public async mounted() {

		// create our local mapItem object,
		if (this.mapLayerItem) {
			this.updatedMapLayerItem = { ...this.mapLayerItem };

			this.selectedFloor = this.isMapSetupUseElevationLabels ?
				this.updatedMapLayerItem.minElevation :
				this.updatedMapLayerItem.minElevation + 1;

			//Ensure mapLayerIds is initialized
			if (!this.updatedMapLayerItem.mapLayerIds) {
				this.updatedMapLayerItem.mapLayerIds = [];
			}

			if (this.updatedMapLayerItem.mapLayerItemId == 0) {
				// Create default values for the values we dont receive if we are creating an object
				this.updatedMapLayerItem.mapLayerItemId = 0;
				this.updatedMapLayerItem.title = "";
				this.updatedMapLayerItem.extraValue = "";
				this.updatedMapLayerItem.regionPath = "";
				this.updatedMapLayerItem.objectId = 0;
				this.updatedMapLayerItem.mapLayerIds = [];
				this.toggleEdit();
			}
		}

		await this.loadDevices();

		// Initialize the template the fields will reset to on reset
		this.mapItemTemplate = Object.assign({}, this.updatedMapLayerItem);

		// setup for select dropdowns
		this.setDefaultDevice();
		this.setDefaultLayer();

		// call layer selected to set layer title for display
		this.setLayerDisplay();
	}

	@Watch("selectedFloor")
    private onSelectedFloorUpdated() {
        if (this.isMapSetupUseElevationLabels) {
            if(this.isMapSetupElevationsEnabled)
            {
                this.mapLayerItem.minElevation = this.updatedMapLayerItem.minElevation;
                this.mapLayerItem.maxElevation = this.updatedMapLayerItem.maxElevation;
            }
            else
            {
                this.mapLayerItem.minElevation = this.selectedFloor;
                this.mapLayerItem.maxElevation = this.selectedFloor;
				this.updatedMapLayerItem.minElevation = this.selectedFloor;
            	this.updatedMapLayerItem.maxElevation = this.selectedFloor;
            }
        }
        else
        {
            this.mapLayerItem.minElevation = this.selectedFloor - 1;
            this.mapLayerItem.maxElevation = this.selectedFloor - 1;
			this.updatedMapLayerItem.minElevation = this.selectedFloor - 1;
            this.updatedMapLayerItem.maxElevation = this.selectedFloor - 1;
        }
    }

	private get isMapSetupElevationsEnabled(): boolean {
		return get(this.featuresList, ["MapSetup", "Elevations"]);
	}

	private get isMapSetupUseElevationLabels(): boolean {
		return get(this.featuresList, ["MapSetup", "ElevationLabels"]);
	}

	private get getDefaultValueForElevationOptions(): number {
		if (!this.isEditMode) {
			return null;
		} else{
			return this.currentFloor;
		}
	}

	/**
	 * load the appropriate devices depending on the item type
	 */
	private async loadDevices(filter: string = null): Promise<void> {
		if (this.updatedMapLayerItem.mapLayerItemTypeId == MapLayerItemTypeIds.Alarm) {
			let responses: Response[] = (await api.getAllResponsesByArea(this.groupId, filter));

			// Convert to SelectionObject
			responses.forEach(response => {
				const availableDevicesContainsResponse = this.availableDevices.find(
					(d: MapLayerItemDeviceOption) => d.title == response.title && d.objectId == response.responseID);
				if (!availableDevicesContainsResponse) {
					this.availableDevices.push(new MapLayerItemDeviceOption(response, this.updatedMapLayerItem.mapLayerItemTypeId));
				}
			});

		} else {
			const groupId = this.groupId ? this.groupId : -1;
			let devices = await api.getDevicesByMapLayerItemType(
				groupId,
				this.mapLayerItem.mapLayerItemTypeId,
				filter);
			// Convert to SelectionObject
			devices.forEach(device => {
				const availableDevicesContainsDevice = this.availableDevices.find(
					(d: MapLayerItemDeviceOption) => d.title == device.title && d.objectId == device.deviceID);
				if (!availableDevicesContainsDevice) {
					this.availableDevices.push(new MapLayerItemDeviceOption(device, this.updatedMapLayerItem.mapLayerItemTypeId));
				}
			});
		}
	}

	private setDefaultDevice() {
		if (this.updatedMapLayerItem.mapLayerItemId === 0) {
			return;
		}
		this.selectedDevice = new MapLayerItemDeviceOption(this.updatedMapLayerItem, 0);
	}

	/**
	 * Sets the initial selected layer
	 */
	private setDefaultLayer() {
		if (this.updatedMapLayerItem.mapLayerIds.length === 0) {
			return;
		}
		this.selectedLayers = this.mapLayersInBounds.filter((layer: MapLayer) => this.updatedMapLayerItem.mapLayerIds.includes(layer.mapLayerId));
	}

	/**
	 * Sets the layer display object for the UI
	 */
	private setLayerDisplay() {
		// If there are no layers display placeholder
		if (this.selectedLayers === null || this.selectedLayers.length === 0) {
			this.mapLayerDisplay = { title: "No Layer Linked", mapLayerId: 0 };
		} else {
			// Set the displayLayer to be the first selection from the selected layers
			this.mapLayerDisplay.title = this.selectedLayers[0].title;
			this.mapLayerDisplay.mapLayerId = this.selectedLayers[0].mapLayerId;
		}
	}

	/**
	 * Sets the selected device to the local map object
	 */
	private deviceSelected() {
		this.updatedMapLayerItem.title = this.selectedDevice?.title || "untitled";
		this.updatedMapLayerItem.objectId = this.selectedDevice?.objectId || 0;
	}

	/**
	 * returns popup edit mode state
	 */
	private get isEditModeEnabled() {
		return this.isEditMode;
	}

	/**
	 * Open the delete confimation dialog
	 */
	private deleteMapLayerItemDialog() {
		this.isDeleteConfirmationVisible = true;
	}

	/**
	 * Toggles the popup edit mode
	 */
	private toggleEdit() {
		this.isEditMode = !this.isEditMode;
	}

	private async saveMapItemDetails() {
		if (this.selectedLayers) {
			this.updatedMapLayerItem.mapLayerIds = this.selectedLayers.map(l => l.mapLayerId);
		}
		try {
			await api.putMapLayerItem(this.updatedMapLayerItem);
			this.mapItemTemplate = Object.assign({}, this.updatedMapLayerItem);
			this.$emit("mapLayerItemSaved");
		} catch (e) {
			const errorTitle = "Error Saving Map Marker";
			if (e.response && e.response.data) {
				switch (e.response.status) {
					case 403:
						this.$notify({
							type: "error",
							title: errorTitle,
							text: `You do not have permissions to save this map marker`
						});
						break;
					case 500:
						this.$notify({
							type: "error",
							title: errorTitle,
							text: "Unexpected error saving map marker. Please contact support if this problem persists."
						});
						break;
				}
			}
			console.error(e);
		}
	}

	/**
	 * Reset the UI to its default state
	 */
	private resetMapItemData() {
		// If we are creating an item close the popup on reset
		if (this.updatedMapLayerItem.mapLayerItemId == 0) {
			this.$emit("mapLayerItemWindowClosed");
		}
		this.updatedMapLayerItem = Object.assign({}, this.mapItemTemplate);

		this.setDefaultDevice();
		this.setDefaultLayer();
		this.setLayerDisplay();
		this.toggleEdit();
	}

	/**
	 * Delete the local item from the db then close the delete dialog and the popup
	 */
	private async deleteMapLayerItemDelegate() {
		this.isDeleteConfirmationVisible = false;
		try {
			await api.deleteMapLayerItem(this.updatedMapLayerItem.mapLayerItemId);
			this.$emit("mapLayerItemDeleted", { mapLayerItemId: this.updatedMapLayerItem.mapLayerItemId });
		} catch (e) {
			const errorTitle = "Error Deleting Map Marker";
			if (e.response &&
				e.response.data) {
				switch(e.response.status) {
					case 403:
						this.$notify({
							type: "error",
							title: errorTitle,
							text: `You do not have permissions to delete this map marker`
						});
						break;
					case 500:
						this.$notify({
							type: "error",
							title: errorTitle,
							text: "Unexpected error deleting map marker. Please contact support if this problem persists."
						});
						break;
				}
			}
			console.error(e);
		}
	}

	private get getDeviceTypeArticle(): string {
		switch(this.updatedMapLayerItem.mapLayerItemTypeId) {
			case 2:
			case 4:
			case 5:
				return "an";
			default:
				return "a"
		}
	}

	private get getDeviceSelectPlaceholder(): string {
		return `Please select ${this.getDeviceTypeArticle} ${this.typeDictionary.get(this.updatedMapLayerItem.mapLayerItemTypeId)} device`;
	}

	/**
	 * debounce method to handle our waiting time when user is inputting data
	 * @param query Data that the user has inserted
	 * @param loading callback method to enable / disable loading icon
	 * @param filterName filter name to find the endpoint, this is unique.
	 */
	private debounceFilterQuery = debounce(async (query: string) => await this.loadDevices(query), 500);
}
