import { MutationTree } from "vuex";
import { AlarmPointState } from "./types";
import { getDefaultState } from "./index";
import { CancelTokenSource } from "axios";

export const mutations: MutationTree<AlarmPointState> = {
    setDisplayResponseId: (apState: AlarmPointState, responseId: number) => {
        apState.displayResponseId = responseId;
    },
    setResponses: (apState: AlarmPointState, responses: any[]) => (apState.responses = responses),
    setAlarmPointManagerOpen: (apState: AlarmPointState, open: boolean) => {
        apState.alarmPointManagerOpen = open;
    },
    setCancelTokenSource: (apState: AlarmPointState, cancelToken: CancelTokenSource) => (apState.cancelTokenSource = cancelToken),
    setSelectedResponse: (apState: AlarmPointState, response: any) => (apState.selectedResponse = response),
    setSelectedArea: (apState: AlarmPointState, area: any) => (apState.selectedArea = area),
    armSelectedArea: apState => {
        apState.selectedArea.armed = true;
        apState.selectedArea.toggleArmAt = null;
        apState.selectedArea.disarmReasonID = null;
    },
    setDisableAutomaticLoad: (apState: AlarmPointState, data: boolean) => {
        apState.disableAutomaticLoad = data;
    },
    resetState: apState => {
        Object.assign(apState, getDefaultState());
    }
}