
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { ActionPlanTask, Task } from "@/store/tasks/types";
import EventOutcomeSelector from "@/components/EventOutcomeSelector.vue";
import { Getter, namespace } from "vuex-class";
import { requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { EventOutcome, EventOutcomeResponse } from "@/types/EventOutcome";
import { EventOutcome as EventOutcomeFilter } from "@/types/reports/Filters";
import { EventDetails } from "@/store/site-monitor/types";
import EventTypes from "@/types/sv-data/enums/EventTypes";
import { cloneDeep } from "lodash";
import isEqual from 'lodash.isequal';
import TaskTypeIds from "@/types/sv-data/enums/TaskTypeIds";
import api from '@/services/api.service';

const SiteMonitor = namespace("siteMonitor");
const Tasks = namespace("tasks");

interface eventOutcomeTaskData
{
	eventOutcomeId: number,
	ackAllAlarms: boolean,
	forceResAllAlarms: boolean,
	skipReqActions: boolean,
	skipCloseDialog: boolean,
	setCatNote: boolean,
	catNote: string,
}

@Component({
	components: {
		"event-outcome-select": EventOutcomeSelector
	},
	mixins: [validationMixin],
	validations: {
		eventOutcomeResult: {
			required: requiredIf(function () {
				return this.task?.required;
			}),
		}
	}
})
export default class EventOutcomeTask extends Vue {
	@SiteMonitor.Getter("eventOutcomesFlatList") private outcomesFlatList: EventOutcome[];
	@SiteMonitor.Getter("getEventDetails") private eventDetails: EventDetails;
	@SiteMonitor.Getter("getEventRecordsAwaitingAckCount") private awaitingAckCount: number;
	@SiteMonitor.Getter("getEventRecordsAwaitingRestoreCount") private awaitingRestoreCount: number;
	@Tasks.Getter private getOutstandingRequiredTasks: Task[];
	@Getter getFeature: (featureNames: string[]) => boolean;

	@SiteMonitor.Mutation setEventDetails: (event: null) => void;
	@SiteMonitor.Mutation resetEventRecords: () => void;

	@SiteMonitor.Action private loadOutcomesForEvent: (eventId: number) => Promise<EventOutcomeResponse>;
	@SiteMonitor.Action private loadAllOutcomes: () => Promise<EventOutcomeResponse>;
	@SiteMonitor.Action("closeEvent") private closeEvent: (outcome: any) => Promise<void>;
	@SiteMonitor.Action private eventRecordAllAcknowledgement: (eventId:number) => Promise<void>;

	@Prop()
	private task!: ActionPlanTask;

	@Prop({ required: true, type: Boolean, default: false})
	private editing!: boolean;

	private initialEventOutcomeId: number = 0;
	private initialEventOutcomeNote: string = "";
	private eventOutcomeId: number = 0;
	private eventOutcomePath: string = "";
	private disabledText: string = "";
	private dataLoaded: boolean = false;
	private pendingComplete: boolean = false;
	private outcomeSelectKey: number = 0;

	public taskData: eventOutcomeTaskData = {
		eventOutcomeId: null,
		ackAllAlarms: false,
		forceResAllAlarms: false,
		skipReqActions: false,
		skipCloseDialog: false,
		setCatNote: false,
		catNote: "",
	};

	private get eventId(): number {
		return this.eventDetails ? this.eventDetails.eventID : 0;
	}

	private get eventOutcomeToCloseEvent(): boolean {
		return this.getFeature(["Actions", "EventOutcome", "EventOutcomeToCloseEvent"]) && !this.eventCloseEnabled;
	}

	private get eventOutcomePresetsEnabled(): boolean {
		return this.getFeature(["Actions", "EventOutcome", "EventOutcomePresets"]);
	}

	private get eventCloseEnabled(): boolean {
		return this.getFeature(["Actions", "EventOutcome", "EventOutcomePresets", "AdvancedOptions"]);
	}

	private get isEventOnAudit(): boolean {
		return this.eventDetails?.eventTypeID === EventTypes.AuditTest;
	}

	private get eventCategoriesMap(): EventOutcomeFilter[] {
		if (!this.outcomesFlatList) {
			return [];
		}

		return this.outcomesFlatList.map((category) => {
			return {
				id: category.eventOutcomeID,
				parentId: category.parentID,
				title: category.title,
			};
		});
	}

	private get eventCategoryInitialValueFromTaskData(): EventOutcomeFilter[] {
		if (!this.outcomesFlatList || !this.eventOutcomePresetsEnabled) {
			return null;
		}

		if (this.task.taskData && !isNaN(this.task.taskData.eventOutcomeId as number) && this.outcomesFlatList && this.outcomesFlatList.length > 0) {
			let initialValue: EventOutcome = this.outcomesFlatList.find((category) => category.eventOutcomeID === this.task.taskData.eventOutcomeId);

			// if the initial value has been changed, don't update it
			if(this.eventOutcomeId > 0 && this.initialEventOutcomeId != this.eventOutcomeId)
			{
				return null;
			}

			this.eventOutcomeId =  initialValue?.eventOutcomeID;

			if (initialValue) {
				return [{
					id: initialValue.eventOutcomeID,
					parentId: initialValue.parentID,
					title: initialValue.title,
				}];
			}
		}

		return null;
	}

	private get computedTaskText(): string {
		if (!this.eventOutcomePresetsEnabled || this.eventOutcomePresetsEnabled && !this.task.taskData)
		{
			return "Event Outcome";
		}

		return this.task.taskText;
	}

	private get isOutcomeNoteValid(): boolean {
		return !this.eventDetails?.eventNoteRequired
		|| (this.eventDetails?.eventNoteRequired && this.taskData?.catNote?.length > 0);
	}

	/**
	 * If the selected event outcome is a leaf node (i.e. it has no children)
	 * @private
	 */
	private get isLeafNode(): boolean {
		return this.eventOutcomeId != 0 && this.eventCategoriesMap.filter(outcome => outcome.parentId === this.eventOutcomeId).length == 0
	}

	private get anyEventCloseOptionsEnabled(): boolean
	{
		return !this.task
		|| !this.task.taskData
		|| this.task.taskData.ackAllAlarms
		|| this.task.taskData.forceResAllAlarms
		|| this.task.taskData.skipReqActions
		|| this.task.taskData.skipCloseDialog
		|| this.task.taskData.setCatNote;
	}

	private get completeDisabled(): boolean
	{
		if(this.pendingComplete)
		{
			this.disabledText = "The task is being completed.";
			return true;
		}
		if(!this.eventOutcomeId || this.eventOutcomeId == 0)
		{
			this.disabledText = "No outcome has been selected.";
			return true;
		}

		if(!this.isLeafNode)
		{
			this.disabledText = "A specific outcome must be selected";
			return true;
		}

		var outstandingTaskCount: number = this.getOutstandingRequiredTasks ? this.getOutstandingRequiredTasks.filter(gs => gs.taskTypeID != TaskTypeIds.EventOutcome).length : 0;

		if(!this.eventCloseEnabled && outstandingTaskCount > 1)
		{
			this.disabledText = "There are outstanding required tasks."
			return true;
		}

		if(this.eventCloseEnabled)
		{
			var eventCloseErrors: string = "";
			if(this.taskData.setCatNote && !this.isOutcomeNoteValid)
			{
				eventCloseErrors = "An event outcome note is required."
			}

			if(this.taskData.skipCloseDialog)
			{
				if(!this.taskData.ackAllAlarms && this.awaitingAckCount > 0)
				{
					eventCloseErrors = "\n There are alarms awaiting acknowledgement."
				}

				if(!this.taskData.forceResAllAlarms && this.awaitingRestoreCount > 0)
				{
					eventCloseErrors = "\n There are alarms awaiting restore."
				}

				if(!this.taskData.skipReqActions && outstandingTaskCount > 1)
				{
					eventCloseErrors += "\n Required Tasks must be completed."
				}
			}

			if(eventCloseErrors.length > 0)
			{
				this.disabledText = eventCloseErrors;
				return true;
			}
		}

		if(this.isEventOnAudit)
		{
			this.disabledText = "Event is on Audit."
			return true;
		}

		this.disabledText = "";
		return false;
	}

	private async mounted(): Promise<void> {
		if (this.editing) {
			await this.loadAllOutcomes();
		}
		else if (this.eventId) {
			await this.loadOutcomesForEvent(this.eventId);
		}

		if(this.task?.taskData)
		{
			this.initialEventOutcomeId = this.task.taskData.eventOutcomeId;
			this.initialEventOutcomeNote = this.task.taskData.catNote;
		}

		this.dataLoaded = true;
	}

	private formatCompleteTask(): ActionPlanTask {
		if(this.taskData)
		{
			this.taskData.eventOutcomeId = this.eventOutcomeId;
		}

		return {
			...this.task,
			taskData: this.taskData || null,
			result: "Path Set to: " + this.eventOutcomePath,
			completed: true
		}
	}

	@Emit("validate-task")
	private validateTask(): boolean {
		if(this.editing)
		{
			return this.taskData && (this.eventOutcomePresetsEnabled ? this.isLeafNode : true);
		}
		return this.isLeafNode;
	}

	@Emit("complete-task")
	public emitTaskCompletion(): ActionPlanTask {
		return this.formatCompleteTask();
	}

	@Emit("completed-task-now-close-event")
	public emitCloseEvent(): ActionPlanTask {
		return this.formatCompleteTask();
	}

	// Watch task and validate on change
	@Watch("task", { immediate: true })
	private taskUpdated(newValue: any, oldValue: any): void {
		if(!this.task.taskData)
		{
			this.taskData = {
				eventOutcomeId: null,
				ackAllAlarms: false,
				forceResAllAlarms: false,
				skipReqActions: false,
				skipCloseDialog: false,
				setCatNote: false,
				catNote: "",
			};
		}
		else if(!isEqual(newValue, oldValue))
		{
			this.taskData = cloneDeep(this.task.taskData);
		}

		this.validateTask();
	}

	private outcomeChanged(value: number): void {
		this.eventOutcomeId = value;
		if (!this.editing || !this.eventOutcomePresetsEnabled) {
			return;
		}

		this.taskData.eventOutcomeId = value;
	}

	private outcomePathChanged(value: string): void {
		this.eventOutcomePath = value;
	}

	private reset(): void {
		this.taskData.eventOutcomeId = this.initialEventOutcomeId;
		this.eventOutcomeId = this.initialEventOutcomeId;
		this.taskData.catNote = this.initialEventOutcomeNote;
		this.outcomeSelectKey = this.outcomeSelectKey + 1;
	}

	@Watch("task.completed")
	private onTaskReOpened(value: boolean, oldValue: boolean){
		if (!value && oldValue) {
			this.eventOutcomeId = 0;
		}
	}

	@Watch("taskData", { deep: true })
	private taskDataUpdated(): void
	{
		if(!this.editing)
		{
			return;
		}

		if(!this.validateTask())
		{
			return;
		}

		this.$emit("input", {
			...this.task,
			taskData: this.taskData || null,
			taskText: (this.eventOutcomePresetsEnabled && this.taskData.eventOutcomeId)
				? `Event Outcome - Preset : ${this.eventOutcomePath}` : "Event Outcome"
		});
	}

	private maxOutcomeNote(note: string): string
	{
		if(!note)
		{
			return note;
		}

		return note.substring(0,5000);
	}

	public async onCompleteTask(): Promise<void> {
		if(this.completeDisabled || this.pendingComplete)
		{
			return;
		}
		
		this.pendingComplete = true;
		try
		{
			if(this.eventCloseEnabled)
			{
				if(this.taskData.ackAllAlarms)
				{
					await this.eventRecordAllAcknowledgement(this.eventId);
				}

				if(this.taskData.forceResAllAlarms)
				{
					await api.forceRestoreAllAlarms(this.eventDetails.eventID, "Force restored from action plan");
				}

				if(this.taskData.skipCloseDialog)
				{
					setTimeout(() => { this.endEvent(); }, 150);
				}
			}

			var result =this.emitTaskCompletion();

			if (this.eventOutcomeToCloseEvent)
			{
				setTimeout(() => { this.emitCloseEvent(); }, 250);
			}
		}
		catch(ex)
		{
			console.error(ex);
		}
		this.pendingComplete = false;
	}

	private async endEvent(): Promise<void>
	{
		const payload = {
					eventId: this.eventId,
					eventOutcomeId: this.eventOutcomeId,
					outcomeNote: this.taskData.catNote,
					clearGuards: true
				};

		this.closeEvent(payload).then((result: any) => {
			if (result.success === true) {
				this.setEventDetails(null);
				this.resetEventRecords();
				this.$router.push({ path: "/event-queue" });
			}
		});
	}
}
