import { DevicesState, DeviceTypeIdentifier, ServerModalStartTab } from "./types";
import { getDeviceResponseRecordsRetrievalStatusDefaults } from "./templates";

export function getDefaultState(): DevicesState {
    return {
        cancelTokenSource: null,
        doors: [],
        serversList: [],
        totalServers: 0,
        filteredServersList: null,
        currentServer: null,
        devices: [],
        syncSystems: [],
		totalSyncSystems: 0,
        totalDevices: 0,
        device: null,
        currentDeviceTypeIdentifier: DeviceTypeIdentifier.Camera,
        serverTypes: [],
		deviceConfigurationErrors: null,
		deviceResponseRecordsRetrievalStatus: getDeviceResponseRecordsRetrievalStatusDefaults(),
        deviceTypeCounts: null,
        deleteChildDeviceModalVisible: false,
        editChildDeviceModalVisible: false,
        cameraPreviewModalVisible: false,
        notificationOptions: null,
        serverLineProfiles: [],
		serverConfig: null,
		gettingConfig: false,
		settingConfig: false,
        serverModalOpened: false,
        serverModalStartTab: ServerModalStartTab.Server
    }
}
