export interface ResponseActionsState {
	responseActions: ResponseAction[];
	supportedActionTypes: ResponseActionType[]
}

export interface ResponseAction {
	responseID?: number,
	responseActionID?: number
	responseActionTypeID: ResponseActionTypeId,
	objectID: number,
	settingsMeta: string,
	armed?: boolean,
	orderNum?: number,
	deviceTitle?: string,
	description?: string
}

export interface ResponseActionIndexed {
	responseAction: ResponseAction,
	index: number
}

export interface ResponseActionType {
	responseActionTypeId: number,
	title: string
}

export enum ResponseActionTypeId {
	RecordCamera = 1
}