import api from "@/services/api.service";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../types";
import { AreaEventHistoryState } from "./types";

export const actions: ActionTree<AreaEventHistoryState, RootState> = {
	async fetchAreaEventHistory({ commit, state }: ActionContext<AreaEventHistoryState, RootState>) : Promise<void> {
		const areaEventHistory = await api.fetchAreaEventHistory(state.filter);
		commit("setAreaEventHistory", (areaEventHistory).data);
	},
};
