





























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
const CSVParser = namespace("csvParser");
const CSVFormHandler = namespace("csvFormHandler");
import { namespace } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ModalItemCSV } from '@/store/csv-form-handler/types'
import { get, isArray } from 'lodash'
import vSelect from "vue-select";
import csvForm from './csv-form.vue'

import csvProgress from './csv-progress.vue'

interface FileCsv {
	stringCsv: string,
	fileName: string
}

@Component({
	components: {
		scrollbar: VuePerfectScrollbar,
		"vue-select": vSelect,
		'csv-form': csvForm,
		'csv-progress': csvProgress
	}
})
export default class CsvUploader extends Vue {
	@Prop({ type: String, required: true}) name
	@Prop({ type: Boolean, default: false}) isMultipleError
	@Prop({ type: Array, default: () => []}) modalItemsCsv: ModalItemCSV[];
	@Prop({ type: Object, required: true }) defaultFile
	@Prop({ type: Object }) progressBulk
	@Prop({ type: Object, default: () => ({
			stringCsv: '',
			fileName: 'Template.csv'
		}) }) fileCsv: FileCsv;
	@CSVParser.Action loadSCVData: any;
	@CSVParser.Action linkDownloadCSV: any;
	@CSVParser.Action setValidHeadersName: any;
	@CSVParser.Action setMandatoryFields: any;
	@CSVParser.Getter isDataEmpty:any;

	@CSVParser.Getter headers:any;
	@CSVParser.Getter body:any;
	@CSVParser.Getter getCsvLinkDownloadByString:any;
	@CSVParser.Getter errorsCSV:any;

	//Remove or Not ??
	@CSVFormHandler.Action addNewRowCsv: any;
	@CSVFormHandler.Action setModalItemsCsv: any;

	@CSVFormHandler.Getter mandatoryFields: any
	@CSVFormHandler.Getter validHeaders: any
	@CSVFormHandler.State csvData: any;

	fileName: string = 'fileCSV.csv'

	private formData = []
	private isLoading = false

	beforeDestroy() {
		this.loadSCVData({ name: this.name, csvText: '' });
		this.setModalItemsCsv({ modalItemsCsv: {}, name: this.name });
		this.setValidHeadersName({ validHeadersName: {}, name: this.name });
		this.setMandatoryFields({ mandatoryFields: {}, name: this.name });
	}

	get isProgressError() {
		return this.progressBulk.status === 'error'
	}

	get disabledGridButton() {
		return !!this.localIsDataEmpty || !!this.localErrorsCSV.error || !!this.localErrorsCSV.info
	}

	get localErrorsCSV() {
		return this.errorsCSV(this.name)
	}

	get localBody() {
		return this.body(this.name)
	}

	get localIsDataEmpty() {
		return this.isDataEmpty(this.name)
	}

	get validModalItems() {
		return this.modalItemsCsv.filter(({ csvExclude }) => !csvExclude)
	}

	get isUploading() {
		return !!Object.keys(this.progressBulk).length
	}

	get batchUploadErrors() {
		return !!this.progressBulk?.percentage && this.progressBulk?.rowIndex == 100 && !!this.progressBulk?.bulkErrors
	}

	get batchUploadErrorRowsForDownload(): any[] {
		if (!this.batchUploadErrors) {
			return [];
		}

		// Get the failed rows to download
		let erroredFormData = [];
		for (let i = 0; i < this.progressBulk.bulkErrors.length; i++) {
			erroredFormData.push(this.formData[this.progressBulk.bulkErrors[i].rowId]);
		}

		return erroredFormData;
	}

	downloadTemplate() {
		const { name, data } = this.defaultFile
		const url = this.getCsvLinkDownloadByString(data)
		this.download({ fileName: name, url })
	}

	setCurrentData(data) {
		this.formData = data
		this.$emit('get-current-data', data)
	}

	private formattedDataToDownload(csvValue: Array<string> | string): string {
		return isArray(csvValue) ? `"${csvValue.join()}"` : csvValue
	}

	private async downloadCSV() {
		let formDataToDownload = [...this.formData];

		// If the upload has been batched and has errors, only download the failed rows
		if (this.batchUploadErrors) {
			formDataToDownload = this.batchUploadErrorRowsForDownload;
		}

		const body = formDataToDownload.map( row => row.reduce((acc, { title, csvValue }) => ({
		...acc,
		[title]: this.formattedDataToDownload(csvValue)
		}) ,{}))

		const option = {
			body,
			headers: this.headers(this.name)
		}

		const url = await this.linkDownloadCSV(option)
		this.download({ fileName: this.fileName, url })
	}

	private download({ fileName, url }) {
		const a = document.createElement("a");

		document.body.appendChild(a);
		a.setAttribute("style", "display: none");
		a.href = url;
		a.download = fileName;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	private openFilesTree() {
		const input = this.$refs.uploadCompleted as HTMLInputElement
		input.value = ''
		input.click()
	}

	private async uploadCompleted(file) {
		const fileExtension = `${get(file, 'name')}`.match(/\.csv$/i)

		if(fileExtension === null) return

		this.fileName = file.name

		const reader = new FileReader()
		reader.onload = async () => {
			const result = reader.result as string
			this.isLoading = true
			await this.loadSCVData({ name: this.name, csvText: '' });
			this.$emit('reset-progress')
			this.$nextTick(async () => {
				await this.loadSCVData({ name: this.name, csvText: result });
				await this.initDataForm()

				this.isLoading = false
			})
		}
		reader.readAsText(file);
	}

	async initDataForm() {
		await this.setModalItemsCsv({ modalItemsCsv: this.modalItemsCsv, name: this.name });
		await this.setValidHeadersName({ validHeadersName: this.validHeaders(this.name), name: this.name });
		await this.setMandatoryFields({ mandatoryFields: this.mandatoryFields(this.name), name: this.name });
	}
}
