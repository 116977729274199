
import { Component, Vue } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {}
})
export default class ShortcutHelp extends Vue {
	$refs: any = {
		displayModal: HTMLElement
	};

	@Getter("getShortcuts") shortCuts!: any[];
	@Getter("getLegendKeys") legendKeys!: any[];
	@Getter("getShowRoutingGroupsLegend") showRoutingGroupsLegend!: boolean;
	@Getter("getRoutingGroups") routingGroups!: any;

	@SiteMonitor.Mutation setActivity;

	public open(): void {
		this.$refs.displayModal.show();
		this.setActivity();
	}
}
