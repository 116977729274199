
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import TextHighlight from "vue-text-highlight";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { debounce } from "lodash";
// API
// Components
import Tree from "@/components/Tree.vue";
// Types
import { ICamera } from "@/store/views/types";
import { CameraType, SearchedCamera } from "@/store/site-monitor-cameras/types";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { FeaturesList } from "@/store/types";
import { tryCatch } from "rxjs/internal-compatibility";
import api from '@/services/api.service';

const SMCameras = namespace("siteMonitorCameras");
const Areas = namespace("areas");
const Mobile = namespace("mobile");

@Component({
	components: {
		"area-tree-select": AreaTreeSelect,
		tree: Tree,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
		"text-highlight": TextHighlight
	}
})
export default class MobileCameraSelect extends Vue {
	@SMCameras.Action fetchAreaCameras: (params: { groupID: number, pageNumber: number, paginated: boolean }) => Promise<void>;
	@SMCameras.Getter("getAreaCameras") groupCameras: CameraType[];
	@SMCameras.Getter searchedCameras: SearchedCamera[];
	@SMCameras.State("areaCameraCount") public areaCameraCount: number;
	@SMCameras.State("searchedGroupCameraCount") public searchedGroupCameraCount: number;
	@SMCameras.Action searchForCameras: (params: { query: string, groupID: number, page: number, reset: boolean }) => Promise<void>;

	@SMCameras.Mutation setAreaCameras: any;
	@SMCameras.Mutation setAreaCameraCount: any;
	@SMCameras.Mutation setSearchedGroupCameraCount: any;

	@Areas.Getter getAreaTitle: (id: number) => string;

	@Mobile.Mutation setLiveDevice: (camera: ICamera) => void;
	@Mobile.Getter("getLiveDeviceId") liveDeviceId: any;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	$refs!: {
		scrollBar: any;
	};

	public pageNumber: number = 1;
	public selectedGroup: number = null;
	public searchPageSize: number = 20;
	private searchQuery: string = "";
	private isLoading: boolean = false;

	public mounted(): void {
			this.setAreaCameras([]);
			this.setAreaCameraCount(0);
			this.setSearchedGroupCameraCount(0);
			this.selectedGroup = null;
			this.searchQuery = null;
		}

	private open: boolean = false;

	@Watch("selectedGroup")
	public async querySearch(newValue: number, oldValue: number): Promise<void> {
		try
		{
			this.isLoading = true;

			if(newValue != oldValue){
				this.pageNumber = 1;
				this.setAreaCameras([]);
			}

			await this.fetchAreaCameras({ groupID: this.selectedGroup, pageNumber: this.pageNumber, paginated: true } );
		}
		catch(err)
		{
			console.error("Error Loading Area Cameras", err);
			this.pageNumber = 1;
			this.setAreaCameras([]);
		}
		finally
		{
			this.isLoading = false;
		}
	}

	public get areaCameras(): any[] {
		if (!this.filterActive)
		{
			return this.groupCameras;
		}

		return this.searchedCameras.filter(
			camera => camera.areaTitle == this.getAreaTitle(this.selectedGroup)
		).slice(0, this.searchPageSize);
	}

	public showMoreCameras(): void {
		if (!this.filterActive) {
			this.pageNumber++;
		}
		else
		{
			this.pageNumber++;
			this.searchPageSize += 20;
		}
	}

	// Watch the pageNumber and reuse the same search query to update list
	@Watch("pageNumber")
	public async onPageNumberChanged(): Promise<void> {
		if (this.searchQuery) {
			await this.searchForCameras({ query: this.searchQuery, groupID: this.selectedGroup, page: this.pageNumber, reset: false });
			} else {
			await this.fetchAreaCameras({ groupID: this.selectedGroup, pageNumber: this.pageNumber, paginated: true });
		}
	}

	@Watch("searchQuery")
	public debouncedFilterSearch: (() => Promise<void>)  = debounce(async () => {
		await this.searchForDebounce();
	}, 1000);

	private async searchForDebounce(): Promise<void> {
		try
		{
			this.isLoading = true;
			this.pageNumber = 1;
			await this.searchForCameras({ query: this.searchQuery, groupID: this.selectedGroup, page: this.pageNumber, reset: true });
		}
		catch(err)
		{
			console.error("Error searching for cameras", err);
		}
		finally
		{
			this.isLoading = false;
		}
	}

	private get filterActive(): boolean {
		return !!this.searchQuery;
	}

	@Watch("filterActive")
	private resetPageSize(): void {
		this.pageNumber = 1;
		this.searchPageSize = 20;
	}

	private get showLoadMoreButton(): boolean {
		if (this.filterActive) {
			return this.areaCameras.length < this.searchedGroupCameraCount;
		} else {
			return this.groupCameras.length < this.areaCameraCount;
		}
	}

	// Raise event to show camera
	private async showAreaCamera(camera) : Promise<void> {
		if(!camera)
		{
			return;
		}
		
		let cameraDetails =  await api.loadDeviceById(camera.objectID, null);
		this.setLiveDevice(cameraDetails);
		this.$emit("deviceSelected")
	}
}

