import { SubscriptionState } from "./types";

export function getDefaultState(): SubscriptionState {
	return {
		subscription: {
		    id: 0,
			expirationDate: new Date(),
			updatedAt: new Date(),
			state: "",
			limitedSubscription: false,
			isActiveState: true,
			cameras: 0,
			camerasPricePerUnit: "",
			camerasLimit: 0,
			mobileOfficers: 0,
			mobileOfficersPricePerUnit: "",
			mobileOfficersLimit: 0,
			operators: 0,
			operatorsPricePerUnit: "",
			operatorsLimit: 0,
			customer: {
				id: 0,
				email: "",
				firstName: "",
				lastName: "",
				billingPortalLink: {
					url: "",
					expiresAt: new Date()
				},
				hasSubscription: false,
				companyName: "",
				telephone: "",
				address1: "",
				address2: "",
				city: "",
				country: "",
				state: "",
				zipCode: ""
			},
			type: 0,
		},
		billedUserPermissions: [],
		isLoaded: false,
	}
}