
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { get } from "lodash";
import { namespace, Getter } from "vuex-class";
import vSelect from "vue-select";
import { isEqual } from "lodash";
import { SubscriptionBilledPermission, SubscriptionDto } from "@/store/subscription/types";
import api from "@/services/api.service";
import { FeaturesList } from "@/store/types";

const Subscription = namespace("subscription");

@Component({
    components: {
        "vue-select": vSelect,
    }
})
export default class UserGroupInput extends Vue {
    @Prop({ type: Boolean, default: false }) readonly: boolean;

    @Prop({ type: Boolean, default: false }) disabled: boolean;

    @Prop({ type: Boolean, default: false }) required: boolean;

    @Prop({ type: String, default: "User Group" }) parentTitle: String;
    @Prop({ type: Object, default: () => ({}) }) model: any;

    @Prop()
    public value: any[];

    @Getter getPermissions: any;
    @Getter("getFeaturesList") featuresList: FeaturesList;

    @Subscription.State subscription: SubscriptionDto;
    @Subscription.State billedUserPermissions: SubscriptionBilledPermission[];
    @Subscription.Getter('getSubscriptionType') subscriptionType: string;
    @Subscription.Action fetchBilledUserPermissions: any;

    private userGroups: any[] = [];
    private selectedUserGroups: any[] = [];

    private initialSelectedUserGroupIds: any[] = [];
    private permissionIdsForInitialUserGroups: number[] = [];

    private operatorUsage = 0;
    private mobileOfficerUsage = 0;

    public async validate(): Promise<boolean> {
        await this.onSelectUserGroup(this.selectedUserGroups);
        return this.isValid
    }

    private get isValid(): boolean {
        if (this.isSubscriptionLimited) {
            if (this.operatorUsage > this.subscription.operatorsLimit) {
                return false;
            }
            if (this.mobileOfficerUsage > this.subscription.mobileOfficersLimit){
                return false;
            }
        }
        return true;
    }

    private get isSubscriptionLimited(): boolean {
        return this.subscription ? this.subscription.limitedSubscription : false;
    }

    private async mounted() {
        let getUserGroups = await api.getUserGroup();
        this.userGroups = getUserGroups.map(ug => {
            let result = {}
            result['key'] = ug.userGroupId;
            result['title'] = ug.title;
            return result;
        })

        if (this.value) {
            this.initialSelectedUserGroupIds = this.value.map(x => x.key);
            let permissionsForInitialUserGroups = await api.getPermissionsForUserGroups(this.initialSelectedUserGroupIds);
            this.permissionIdsForInitialUserGroups = permissionsForInitialUserGroups.map(p => p.permissionId);
            this.selectedUserGroups = this.value;
        }
        this.fetchBilledUserPermissions();

        this.operatorUsage = this.subscription.operators;
        this.mobileOfficerUsage = this.subscription.mobileOfficers;
    }

    @Watch("value")
    valueChanged(value: any[]) {
        this.selectedUserGroups = this.value;
    }

    @Watch("selectedUserGroups")
    public updateUserGroup(userGroupIds): void {
        this.$emit("input", userGroupIds);
    }

    @Watch("model", { deep: true })
    public userGroupsGroupById(): void {
		this.selectedUserGroups = this.model.permissionGroupIds;
    }

    private async onSelectUserGroup(userGroups: any[]) {
        if (this.isBillingEnabled) {
            if (!userGroups || userGroups.length === 0)
            {
                this.updateOperatorCount([]);
                this.updateMobileOfficerCount([]);
                return;
            }

            let userGroupIds = userGroups.map(x => x.key);
            let isUserGroupsUnchanged = isEqual(userGroupIds, this.initialSelectedUserGroupIds);
            if (isUserGroupsUnchanged) {
                this.mobileOfficerUsage = this.subscription.mobileOfficers;
                this.operatorUsage = this.subscription.operators;
                return;
            }

            let permissionsForUserGroups = await api.getPermissionsForUserGroups(userGroupIds);
            let selectedUserGroupPermissionIds = permissionsForUserGroups.map(permission => permission.permissionId);

            this.updateOperatorCount(selectedUserGroupPermissionIds);
            this.updateMobileOfficerCount(selectedUserGroupPermissionIds);
        }
    }

	private updateOperatorCount(selectedUserGroupPermissionIds) {
        const operatorPermissionId = this.billedUserPermissions.find(up => up.title === "Operators").permissionID;
		let initialUserGroupsIncludesOperatorPermissions = this.permissionIdsForInitialUserGroups.includes(operatorPermissionId);
		let selectedUserGroupIncludesOperatorPermissions = selectedUserGroupPermissionIds.includes(operatorPermissionId);

		if (!initialUserGroupsIncludesOperatorPermissions && selectedUserGroupIncludesOperatorPermissions && this.subscription.operators + 1 > this.operatorUsage) {
			this.operatorUsage++;
		} else if (initialUserGroupsIncludesOperatorPermissions &&
			!selectedUserGroupIncludesOperatorPermissions &&
			this.subscription.operators === this.operatorUsage) {
			this.operatorUsage--;
		} else if (initialUserGroupsIncludesOperatorPermissions == selectedUserGroupIncludesOperatorPermissions) {
			this.operatorUsage = this.subscription.operators;
		}
	}

	private updateMobileOfficerCount(selectedUserGroupPermissionIds) {
        const guardPermissionId = this.billedUserPermissions.find(up => up.title === "Mobile Officers").permissionID;
		let initialUserGroupsIncludesGuardPermissions = this.permissionIdsForInitialUserGroups.includes(guardPermissionId);
		let selectedUserGroupIncludesGuardPermissions = selectedUserGroupPermissionIds.includes(guardPermissionId);

		if (!initialUserGroupsIncludesGuardPermissions && selectedUserGroupIncludesGuardPermissions && this.subscription.mobileOfficers + 1 > this.mobileOfficerUsage) {
			this.mobileOfficerUsage++;
		} else if (initialUserGroupsIncludesGuardPermissions &&
				!selectedUserGroupIncludesGuardPermissions &&
			this.subscription.mobileOfficers === this.mobileOfficerUsage) {
			this.mobileOfficerUsage--;
		} else if (initialUserGroupsIncludesGuardPermissions == selectedUserGroupIncludesGuardPermissions) {
			this.mobileOfficerUsage = this.subscription.mobileOfficers;
		}
	}

    private get isBillingEnabled() {
        return get(this.featuresList, ["Billing"]);
    }

	private get isSuiteEnabled() {
		return get(this.featuresList, ["Suite"]);
	}

}
