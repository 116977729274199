import { axiosInstance, axiosJsonInstance } from "@/axios.instance";
import store from "@/store";
import { LogoutPayload } from "@/store/types";

export const applyAxiosInterceptors = Vue => {
	axiosInstance.interceptors.response.use(undefined, defaultInterceptor);
	axiosJsonInstance.interceptors.response.use(undefined, defaultInterceptor);

	function defaultInterceptor(error: any) {
		if (error.response) {
			let errorMessage =
				error.response.data && error.response.data.length > 2
					? error.response.data
					: "";

			switch (error.response.status) {
				case 401:
					if (error.response.request.responseURL.endsWith("/login")) {
						throw error;
					}
					console.log("intercepted a logged out user, dispatching a logout action");
					store.dispatch("logout", <LogoutPayload> {
						hasSessionExpired: true,
						playAudioAlert: store.getters.isSessionExpiryAudioSoundEnabled
					});
					errorMessage = null;
					break;

				case 403:
					if (errorMessage === "You are not included in this event") {
						store.commit("siteMonitor/setEventNoPermission", true);
						errorMessage = null;
					} else {
						errorMessage =
							errorMessage ||
							"You do not have permissions for this operation";
					}
					break;

				case 418:
					// Handled by ErrorWarning.vue, no need for additional notifications
					break;

				case 500:
					errorMessage =
						errorMessage ||
						"Something went wrong. Please try again later or contact support";
					break;

                case 566:
                    throw error;

				default:
					// @nuke When we are sure that we not in this case or handle
					console.error(error);
					break;
			}

			if (errorMessage) {
				Vue.prototype.$notify({
					type: "error",
					title: "Error",
					text: errorMessage,
					ignoreDuplicates: true
				});
			}
		} else {
			// @nuke When we are sure that we not in this case or handle
			throw error;
		}
	}
};
