import { GetterTree } from "vuex";
import { Mobile } from "./types";
import { RootState } from "../types";
import wait from "@/utils/wait";
import MobileEventShareStates from "@/types/sv-data/enums/MobileEventShareStates";

export const getters: GetterTree<Mobile, RootState> = {
	getLiveDeviceId: state => state.LiveDevice.deviceID,
	getWatchedDeviceLocation: state => async (timeOut: number) => {
		let elapsedTime = 0;
		const delayMs = 10;

		if(state.DeviceLocation) {
			return state.DeviceLocation
		}

		while (elapsedTime < timeOut) {
			await wait(delayMs);
			if(state.DeviceLocation) {
				return state.DeviceLocation
			}
			elapsedTime += delayMs;
		}

		throw new Error("getWatchedDeviceLocation timed out");
	},
	getUserAssetMapDisplayName: state => state.UserAssetMapItem ? state.UserAssetMapItem.title : null,
	getEventShareQueue: state => {
		const visibleStates = [MobileEventShareStates.Sent,MobileEventShareStates.Accepted];
		return state.EventShareItems
			.filter(x => x.eventID != (state.ActiveEventShareItem ? state.ActiveEventShareItem.eventID : 0)
					&& visibleStates.includes(x.state)
			)
	},
	getCanUserEventShare: state => state.UserAssetType ? state.UserAssetType.shareable : false,
	getCurrentLocationRegionGroupID: state => {
		if(state.Regions && state.Regions.size && state.DeviceLocation){
			// The regions will be retrieved in the order of the group tree structure,
			// we need to work backwards to find the lowest level region the user is located within and set that to the group id
			let regionArray = Array.from(state.Regions, ([name]) => (name));
			let groupId = 0;
			regionArray.reverse().some(groupID => {
				const region = state.Regions.get(groupID);
				if(google.maps.geometry.poly.containsLocation(
					state.DeviceLocation,
					region.poly
					)
				){
					groupId = groupID;
					return true;
				}
			});
			return groupId;
		}
	}
};
