import { Module } from "vuex";
import { RootState } from "../types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import getDefaultState from "./state";
import { MobileRaiseState } from "./types";

const state: MobileRaiseState = getDefaultState();

const namespaced: boolean = true;

const module: Module<MobileRaiseState, RootState> = {
	namespaced,
	state,
	actions,
	mutations
};

export default module;
