import { Vue, Component } from "vue-property-decorator";
import { namespace, Mutation, Action, Getter } from "vuex-class";
import { get } from "lodash";
import { FeaturesList } from "@/store/types";
import { FilteredEvent, PendingEventPickup } from "@/store/eventqueue/types";
import api from "@/services/api.service";

const EventQueue = namespace("eventqueue");

@Component
export default class RegionalHandlerMixin extends Vue {
	@Mutation public restoreLocalLogin: any;
	@Action public loginToRegion: any;
	@Action public getInterDataServiceLoginToken: any;
	@Action public updateActiveRegion: any;
	@EventQueue.Action public handleEvent: any;
	@EventQueue.Action("eventHandleTop") public eventHandleTop: (id: number) => Promise<void>;

	@EventQueue.Mutation("setPendingEventPickup") public setPendingEventPickup: (pendingEvent: PendingEventPickup | null) => void;
	@EventQueue.Mutation public setHandlingEvent: (handling: boolean) => void;

	@EventQueue.Getter public getHandlingEvent: boolean;
	@Getter("getFeaturesList") public featuresList: FeaturesList;

	/**
	 * Attempts to login to a remote region, firstly generating a login token, and then switching our Axios instance
	 * over to point at the DataService on the remote server.
	 * @param regionId The ID of the region to login to.
	 * @param regionUrl The URL of the region to login to.
	 */
	public async regionalLogin(regionId: number, regionUrl: string) {
		// Preliminary check - if the region ID or URL are invalid we can't do anything
		if (
			regionId === undefined ||
			regionId == null ||
			regionId <= 0 ||
			(regionUrl === undefined ||
				regionUrl == null ||
				regionUrl.trim() === "")
		) {
			return;
		}

		try {
			// Get a login token for the region using our local data service
			const loginToken = await this.getInterDataServiceLoginToken(regionId);

			// Notify the app, globally, that we're switching regions
			this.updateActiveRegion({ regionId, regionUrl });

			// Wait until the next DOM update - give time for any @Watch's observing the active region to fire
			await this.$nextTick();

			// Login to the remote region using the loginToken
			const loginResult = await this.loginToRegion(loginToken);

			if (typeof loginResult !== "undefined" && loginResult != null) {
				// If we've successfully logged in, we can return true and carry on with whatever we're doing
				return true;
			} else {
				throw new ReferenceError("Login Result was undefined or null");
			}
		} catch (err) {
			console.error(
				`Could not login to remote region (ID: ${regionId}, URL: ${regionUrl}): ${err}`
			);

			// reset our region back to the local one
			this.resetRegion();
		}

		return false;

	}

	private get isProcessAlarmConfirmationEnabled(): boolean {
		return get(this.featuresList,["Alarms", "ProcessConfirmation"]);
	}

	/**
	 * Handles an event queue item, taking into account handling regional login etc.
	 * @param eventQueueItem The item from the Event Queue to be handled.
	 */
	public async handleEventQueueItem(
		eventQueueItem: FilteredEvent,
		checkInProcessing: boolean = false,
		openInNewWindow: boolean = false,
		pickupMethod: string = "Unknown",
		bypassConfirmation: boolean = false
	) {
		if (!get(this.featuresList, ["Alarms", "SiteMonitor"])) {
			return;
		}
		if(this.isProcessAlarmConfirmationEnabled && pickupMethod != "Picked up from Map" && !bypassConfirmation ) {
			this.setPendingEventPickup({
				eventQueueItem: eventQueueItem,
				checkInProcessing: checkInProcessing,
				openInNewWindow: openInNewWindow,
				pickupMethod: pickupMethod
			});
			return;
		}

		if (
			typeof eventQueueItem.regionURL === "undefined" ||
			eventQueueItem.regionURL == null ||
			eventQueueItem.regionURL.trim() === "" ||
			eventQueueItem.regionID <= 0
		) {
			// No region shenanigans here, so just process the event
			try {
				if(this.isProcessAlarmConfirmationEnabled){
					this.setPendingEventPickup(null);
				}
				await this.handleEvent({
					eventID: eventQueueItem.eventID,
					checkInProcessing,
					openInNewWindow,
					pickupMethod
				});
			} catch (err) {
				// Swallow err
			}
		} else {
			// Attempt to login to the remote region
			const loginSuccessful = await this.regionalLogin(
				eventQueueItem.regionID,
				eventQueueItem.regionURL
			);

			if (loginSuccessful) {
				try {
					if(this.isProcessAlarmConfirmationEnabled){
						this.setPendingEventPickup(null);
					}
					await this.handleEvent({
						eventID: eventQueueItem.eventID,
						checkInProcessing,
						openInNewWindow,
						pickupMethod
					});
				} catch (err) {
					// Couldn't handle the event, so we need to reset our region
					this.resetRegion();
				}
			} else {
				this.$notify({
					type: "error",
					title: "Unsuccessful Remote Login",
					text:
						"Unable to login to the remote region - please try again later, or contact support if the problem persists."
				});
			}
		}
	}

	protected resetRegion() {
		// Reset our login, and the active region property so we restart polling
		this.updateActiveRegion({
			regionId: 0,
			regionUrl: api.getOriginalApiUrl
		});
	}

	public async processTopAlarm(currentFilterID, action:string = "Unknown", confirmed: boolean = false) {
		var handleResult;

		if(this.isProcessAlarmConfirmationEnabled && !confirmed){
			this.setPendingEventPickup({
				highestPriorityAlarm: true,
				pickupMethod: action,
				currentFilterID: currentFilterID,
				checkInProcessing: false,
				openInNewWindow: false
			})
			return;
		}

		if (this.getHandlingEvent) {
			return;
		}

		this.setHandlingEvent(true);

		try {
			handleResult = await this.eventHandleTop(currentFilterID);

			var pickupMethod = "Unknown";
			if(action === "shortkey"){
				pickupMethod = "Shortcut from event queue";
			} else if (action === "clicked") {
				pickupMethod = "Process Top Priority Button";
			}

			if (handleResult.eventID != null && handleResult.canProcess) {
				await api.auditEventPickUp(handleResult.eventID, pickupMethod);

				if (
					typeof handleResult.regionID == "undefined" ||
					handleResult.regionID == null ||
					handleResult.regionID <= 0
				) {
					// Handle the event locally
					this.$router.push({
						path: `/site-monitor/${handleResult.eventID}`
					});
				} else {
					// Handle the event remotely
					var loginSuccessful = await this.regionalLogin(handleResult.regionID, handleResult.regionURL);

					if (loginSuccessful) {
						this.$router.push({
							path: `/site-monitor/${handleResult.eventID}`
						});
					} else {
						this.$notify({
							type: "error",
							title: "Unsuccessful Remote Login",
							text:
								"Unable to login to the remote region - please try again later, or contact support if the problem persists."
						});
					}
				}
			}
		} catch (err) {
			this.$notify({
				type: "error",
				title: "Process Top Alarm",
				text:
					"Unable to process the top alarm - please try again later, or contact support if the problem persists."
			});
		} finally {
			this.setHandlingEvent(false);
		}
	}
}
