
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";
import api from "@/services/api.service.ts";
import { CameraType, ClipType } from "@/store/site-monitor-cameras/types";
import { EventDetails } from "@/store/site-monitor/types";
import { AuditService, MediaFile } from "@/services/auditService";
import { ActionPlanTask } from "@/store/tasks/types";
import { FeaturesList } from "@/store/types";
import { get } from "lodash";
import { SessionResource } from "@/store/sessions/types";

const SiteMonitor = namespace("siteMonitor");
const SMCameras = namespace("siteMonitorCameras");
const Session = namespace("sessions");

@Component({
	components: {}
})
export default class AuditTask extends Vue {
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Session.Getter getSession: any;

	@SiteMonitor.Getter("getEventDetails") getEventDetails: EventDetails;
	@SiteMonitor.Getter("getAuditService") auditService: any;
	@SiteMonitor.Getter("getSelectedEventRecord") selectedEventRecord: any;

	@SMCameras.Getter("getLayouts") layouts!: number[][];
	@SMCameras.Getter("getMediaMatrixLayers") layers!: any[];
	@SMCameras.Getter("getLayoutIndex") layoutIndex!: number;
	@SMCameras.Getter("getDeviceControllerClips") clips!: ClipType[];
	@SMCameras.Getter("getMediaMatrixClips") getMediaMatrixClips: any;
	@SMCameras.Getter("getShownAuditClips") getShownAuditClips: any;

	@SMCameras.Mutation setPushContents: any;
	@SMCameras.Mutation setAwaitingClip: any;

	@Prop(Boolean)
	public editing: Boolean;

	@Prop()
	public task!: ActionPlanTask;

	private clipsFailed: boolean = false;
	private clipsFailedStatus: string = "";
	private noClipsAvailable: boolean = false;
	private gettingClips: boolean = false;

	public mounted() {
		if (this.editing) {
			// No validation needed so call valid task by default.
			this.task.taskText = "Show Audit";
			this.onValidateTask();
		}
	}

	@Emit("validate-task")
	public onValidateTask() {
		return true;
	}

	@Emit("complete-task")
	public onCompleteTask() {
		return {
			...this.task,
			completed: true
		};
	}

	// Get the total number of cells in the media matrix.
	public get cellCount() {
		return this.layouts[this.layoutIndex][0] * this.layouts[this.layoutIndex][1];
	}

	// Called when the ViewAudit button is clicked
	public async viewAudit() {
		// Get the event details.
		const event = await api.loadEventRecords(this.task.eventID, 0);

		// Load audit clips to be displayed.
		await this.getClips(event.data[0].eventRecordID);

		// Complete the task.
		this.onCompleteTask();
	}

	public async getClips(eventRecordID: number) {
		var auth = this.getSession(SessionResource.AuditServiceSession);

		// Ensure the audit service is available.
		if (!this.auditService) {
			console.error("No audit service available.");
			return;
		}

		// Ensure clips are not already being loaded.
		if (this.gettingClips) {
			console.info("Already fetching clips.");
			return;
		}

		// Mark that we're getting clips; prevent duplicate operations.
		this.gettingClips = true;

		// Do request to audit service to get media list.
		let rawClips = await this.getMediaList(auth, eventRecordID);

		// Ensure clips were found.
		if (rawClips) {
			this.clipsFailed = false;
			this.clipsFailedStatus = "";
			this.noClipsAvailable = rawClips.length == 0;
		} else {
			rawClips = [];
			this.noClipsAvailable = true;
			this.clipsFailed = false;
			this.clipsFailedStatus = "";
		}

		// Filter the list of clips to ensure they have the data needed.
		let clips = this.filterClips(rawClips);

		// Check if all the clips required are already on the media matrix.
		if (!this.checkIfAllClipsLoaded(clips)) {
			// Load the clips from audit.
			this.loadClips(clips, eventRecordID);
		}

		// Reset flag as the process of loading clips is now done.
		this.gettingClips = false;
	}

	private async getMediaList(auth, eventRecordID) {
		try {
			// Use the audit service to try and get a list of all media attached to the event.
			return (await this.auditService.mediaList(auth, eventRecordID)).data;
		} catch(e) {
			this.gettingClips = false;
			return;
		}
	}

	private filterClips(rawClips: Array<any>) {
		// Filter out any clips that do not have a unique file identifier and any other relevant information.
		rawClips = rawClips.filter((clip) => {
			return clip.UniqueFileIdentifier && (clip.DeviceID || clip.DeviceTitle || clip.StartTime)
		});

		// Check that there are still clips to be loaded.
		this.noClipsAvailable = rawClips.length == 0;
		return rawClips;
	}

	private checkIfAllClipsLoaded(clips: Array<any>): boolean {
		// Get all the clips currently on the Media Matrix.
		let matrixClips = this.getShownAuditClips;

		// Check if all the clips are currently loaded.
		return clips.every((clip: any) => matrixClips.includes(clip.UniqueFileIdentifier));
	}

	private loadClips(clips: Array<any>, eventRecordID: number) {
		let index = 1;

		// Set clips
		clips.some((clip: any) => {
			this.setPushContents({
				index: index,
				newContents: {
					clip: {
						eventRecordID: eventRecordID,
						clip: clip
					}
				},
				eventId: this.task.eventID
			});

			index++;
			// Return true if there are no more cells in the media matrix.
			return index > this.cellCount;
		});
	}

	private get featureEnabled() {
		return get(this.featuresList, ["Actions", "ViewAudit"], false)
	}
}
