

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Mutation, namespace, Getter } from 'vuex-class';

const SiteMonitor = namespace('siteMonitor');

@Component({})
export default class ErrorWarning extends Vue {
  @Getter isFullUser: boolean;
  @SiteMonitor.Getter('getEventNoPermission') showOverlay!: boolean;

  close() {
    this.$router.push({ path: '/event-queue' });
  }
}
