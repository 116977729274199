import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Mobile } from "./types";
import { RootState } from "../types";
import { getDefaultState } from "./state";

export const state: Mobile = getDefaultState();

const namespaced: boolean = true;

export const mobile: Module<Mobile, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
