
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { AudioDevice, AudioTypes } from "@/types/audio/audio";

@Component({})
export default class AudioList extends Vue {
    @Prop({default:"Devices"})
    private listTitle: string

    @Prop({default: []}) 
    private audioDevices!: AudioDevice[];

    @Prop({default: null})
    private selectedAudioDevice: AudioDevice | null;

    private open: boolean = true;
    private audioOptionTypes: any = AudioTypes;

    @Emit("set-selected-device")
    private setSelectedAudioDevice(audioDevice: AudioDevice | null): AudioDevice | null {
        return audioDevice;
    }
}
