
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VueTreeselect from "@riophae/vue-treeselect";
import api from "@/services/api.service";
import EventCategory from "@/types/sv-data/events/EventCategory";

const EventCategorySetup = namespace("eventCategorySetup");
const GenericTableStore = namespace("GenericTable");

@Component({
	components: {
		"vue-treeselect": VueTreeselect
	}
})
export default class EventOutcomeSelect extends Vue {
	private eventCategoriesTree: EventCategory[] = [];
	@GenericTableStore.Getter getModalRow: any;

	// Prop required by generic table to pass selected value down
	// to this component.
	@Prop()
	public value: number;
	
	private async beforeMount() {
		this.eventCategoriesTree = await api.fetchEventOutcomeTree();
	}

	private normalizer(e) {
		// Case for hiding the database ID in case value has been deleted after being selected
		if (!e.hasOwnProperty("eventOutcomeID")) {
			e.id = null;
			e.label = "This outcome may have been deleted or disabled. Please select another."
		} else {
			e.id = e.eventOutcomeID;
			e.label = e.title;
			e.children = e.childOutcomes;
		}
		return e;
	}

	private onInputChanged(input: number) {
		this.$emit("input", input);
		this.$emit("hasChildren", this.hasChildren(input))
	}

	private hasChildren(input: number): boolean {
		let outcome = this.eventCategoriesTree.firstOrDefault(c => c.eventOutcomeID === input);
		return !!outcome && outcome.hasOwnProperty("childOutcomes") && outcome.childOutcomes.length > 0;
	}
}
