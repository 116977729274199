
import { Component, Mixins } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import RegionalHandlerMixin from "@/mixins/RegionalHandlerMixin";
import EventQueueAreaInfoIcon from "@/components/event-queue/EventQueueAreaInfoIcon.vue";
import ResetActionsCCure from "@/components/ResetActionsCCure.vue";
import { FilteredEvent } from "@/store/eventqueue/types";
import { get } from "lodash";
import { FeaturesList } from "@/store/types";

const Eventqueue = namespace("eventqueue");
const Subscription = namespace("subscription");

@Component({
	components: {
		VuePerfectScrollbar: VuePerfectScrollbar,
		"event-queue-area-info-icon": EventQueueAreaInfoIcon,
		"reset-actions-ccure": ResetActionsCCure
	}
})
export default class ParkedQueue extends Mixins(RegionalHandlerMixin) {
	@Eventqueue.Getter getActiveEvents!: FilteredEvent[];
	@Eventqueue.Mutation setCountByType: any;
	@Eventqueue.Mutation setHoverEvent: any;
	@Eventqueue.Mutation setPeekEventId: (eventId: number | null) => void;
	@Subscription.Getter isSubscriptionActive: boolean;

	@Getter("getFeaturesList") featuresList: FeaturesList;

	private peekEvent(eventId: number): void {
		this.setPeekEventId(eventId);
	}

	public get parkedNoteEnabled() {
		return get(this.featuresList, ["Alarms", "EventQueue", "ParkedNote"]);
	}

	public get events(): FilteredEvent[] {
		let events = this.getActiveEvents;

		if (events == null) return null;

		let filteredEvents = events.filter((q: any) => q.eventTypeID == 1 && q.inProcessing == 0 && q.unParkAt != null);
		this.setCountByType({
			countType: "parked",
			count: filteredEvents.length
		});

		return filteredEvents;
	}

	public hoverEvent(event: FilteredEvent) {
		this.setHoverEvent(event);
	}

	public unhoverEvent(event: FilteredEvent) {
		this.setHoverEvent({});
	}

	public get noEvents() {
		return typeof this.events == "undefined" || this.events == null || this.events.length == 0;
	}

	public get resetOnParkedEnabled() {
		// We use lodash here as native ES does not have null propagation
		return get(this.featuresList, ["Integration", "CCure", "UI", "ResetOnParked"]);
	}

	private get isEventPeekEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventQueue", "Peek"], false);
	}
}
