import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { CaseManagementState } from "@/store/case-management/types";

export const actions: ActionTree<CaseManagementState, RootState> = {
    async fetchCaseTypes({ commit }, eventId: number) {
        let caseTypes = await api.getTemplates(eventId);
        if (caseTypes) {
            commit("setCaseTypes", caseTypes.data);
        }
    },

    async casesLogin({ commit }, suiteSession: boolean) {
        return await api.casesLogin(suiteSession);
    },

    async fetchCaseTypesForUser({ commit }) {
        let caseTypes = await api.getTemplatesForUser();
        commit("setCaseTypesForUser", caseTypes.data);
    }
};
