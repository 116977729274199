
	import { Component, Vue, Prop, Watch, Emit, Mixins } from "vue-property-decorator";
	import VuePerfectScrollbar from "vue-perfect-scrollbar";
	import { validationMixin } from "vuelidate";
	import { required, requiredIf, minValue, maxValue, numeric, minLength, maxLength } from "vuelidate/lib/validators";
	import { namespace, Getter } from "vuex-class";
	import moment from "moment";
	import DatePicker from "@sureview/vuejs-datepicker";

	import api from "@/services/api.service";

	import { UserPermissions } from "@/store/types";
	import { EventDetails } from "../../store/site-monitor/types";

	const SiteMonitor = namespace("siteMonitor");

	const MaxOnTestDurationMinutes = 2147483647;
	const MaxOnTestDurationHours = MaxOnTestDurationMinutes / 60;

	class OnTestRequest {
		groupID?: number | null;
		responseID?: number | null;
		hours: number | null;
		minutes: number | null;
		date: string;
		onTestUntilDate: Date;
		time: string;
		scheduleType: number;
		auditMode: boolean;
		onTestNote: string;
		eventID?: number | null;
	}

	@Component({
		mixins: [validationMixin],
		components: {
			DatePicker: DatePicker
		},
		validations: {
			onTestRequest: {
				hours: {
					numeric,
					required: requiredIf(function() {
						return (
							this.onTestRequest.scheduleType == this.ScheduleType.ForPeriod && !this.onTestRequest.minutes
						);
					}),
					minValue: minValue(0),
					validateRange: function(newValue: number) {
						if (this.onTestRequest.minutes == 0)
						{
							return newValue > 0 && newValue <= MaxOnTestDurationHours;
						}

                        return newValue >= 0 && newValue <= MaxOnTestDurationHours;
                    }
				},
				minutes: {
					numeric,
					required: requiredIf(function() {
						return this.onTestRequest.scheduleType == this.ScheduleType.ForPeriod && !this.onTestRequest.hours;
					}),
					minValue: minValue(0),
					validateRange: function(newValue: number) {
						if (this.onTestRequest.hours == 0)
						{
							return newValue > 0 && newValue <= 59;
						}

                        return newValue >= 0 && newValue <= 59;
                    }
				},
				onTestUntilDate: {
					required: requiredIf(function() {
						return this.onTestRequest.scheduleType == this.ScheduleType.UntilDate;
					}),
					minValue: minValue(new Date().setHours(0, 0, 0, 0)),
					maxDate: function() {
						const duration = moment.duration(moment(this.onTestRequest.onTestUntilDate).diff(moment())).asMinutes();
						return !duration || duration < MaxOnTestDurationMinutes;
					}
				},
				time: {
					pattern: function(value) {
						if (typeof value == "undefined" || value == null || value == "") return true;
						return /^[0-9]{2}:[0-9]{2}$/.test(value);
					},
					minTime: function(value) {
						/**
						 * Validator to ensure that the time entered by the user is in the future
						 */
						if (
							typeof value == "undefined" ||
							value == null ||
							value == "" ||
							typeof this.onTestRequest.onTestUntilDate == "undefined" ||
							this.onTestRequest.onTestUntilDate == null ||
							this.onTestRequest.scheduleType !== this.ScheduleType.UntilDate
						)
							return true;

						const now = new Date().setHours(0, 0, 0, 0);
						const currentRequestDate = new Date(this.onTestRequest.onTestUntilDate).setHours(0, 0, 0, 0);

						if (now == currentRequestDate) {
							// If the user has chosen today as the re-arm date, we need to make sure that the time they've
							// entered is greater than the current time.
							var momentTime = moment(value, "HH:mm", true).toDate();
							return momentTime > new Date();
						} else {
							// If the user hasn't chosen today, just make sure that the date they've entered is in the future.
							return currentRequestDate > now;
						}
					},
					required: requiredIf(function() {
						return this.onTestRequest.scheduleType == this.ScheduleType.UntilDate;
					})
				},
				onTestNote: {
					required: requiredIf(function() {
						return !this.extendMode;
					}),
					minLength: minLength(1)
				}
			}
		},
		filters: {
			displayDate: function(value: Date) {
				return moment(value).format("LL LT");
			}
		}
	})
	export default class PutOnTest extends Vue {
		@Prop({ default: null })
		areaPermissions: any;

		@Prop({ default: null })
		areaOnTestEvent: any;

		@Prop({ default: null })
		selectedArea: any;

		@Prop({ default: null })
		selectedAlarmPoint: any;

		@Prop({ default: false })
		extendMode: boolean;

		@Prop({ default: false })
		showOnTest: boolean;

		@SiteMonitor.Getter("getEventDetails") eventDetails: EventDetails;

		public testAlarmsLoaded: boolean = false;
		public testAlarms: any[] = [];
		public maxEventRecordID: number = 0;

		public submitting: boolean = false;

		public ScheduleType = {
			ForPeriod: 1,
			UntilDate: 2
		};

		// == Consts == //
		public Today = new Date();

		public calendarDisabledDates = {
			to: new Date(this.Today.setDate(this.Today.getDate() - 1))
		};

		public MaxNotesLength = 500;

		/**
		 * May not be entirely required - this is just used to set the min property for the HTML5 time input,
		 * but we don't actually use HTML5 validation.
		 */
		public get minTimeForSelectedDate() {
			const today = new Date().setHours(0, 0, 0, 0);
			const defaultMinTime = "00:00";

			if (typeof this.onTestRequest.onTestUntilDate == "undefined" || this.onTestRequest.date == null)
				return defaultMinTime;
			var selectedDate = this.onTestRequest.onTestUntilDate.setHours(0, 0, 0, 0);

			if (selectedDate == today) {
				return moment().format("HH:mm");
			} else {
				return defaultMinTime;
			}
		}

		public calendarDateFormatter(date: string) {
			return moment(date).format("L");
		}

		public get untilDate() {
			return this.areaOnTestEvent == null || this.areaOnTestEvent.unParkAt == null
				? this.areaOnTestEvent.unParkAt
				: new Date();
		}

		public get time() {
			return this.areaOnTestEvent == null || this.areaOnTestEvent.unParkAt == null
				? moment()
						.add({ hours: 1 })
						.format("HH:mm")
				: moment(this.areaOnTestEvent.unParkAt)
						.add({ hours: 1 })
						.format("HH:mm");
		}

		public get onTestItemType() {
			if (this.selectedAlarmPoint) {
				return "alarm point";
			} else if (this.selectedArea) {
				return "area";
			} else {
				return "";
			}
		}

		public get permissions() {
			if (this.areaPermissions) {
				return this.areaPermissions;
			} else if (this.selectedAlarmPoint) {
				return this.selectedAlarmPoint.permissions;
			}

			return {};
		}

		public onTestAudit: boolean = false;
		public onTestRequest = {
			groupID: null,
			hours: null,
			minutes: null,
			onTestUntilDate: new Date(),
			date: null,
			time: this.time,
			scheduleType: this.ScheduleType.ForPeriod,
			auditMode: false,
			onTestNote: "",
			eventID: null
		} as OnTestRequest;

		@Watch("areaOnTestEvent")
		public onAreaOnTestEventChanged(value: string) {
			this.onTestRequest.time = this.time;
		}

		public async startTest() {
			this.$v.$reset();

			// If someone has entered either minutes or hours, set the other to 0
			if (
				this.onTestRequest.scheduleType == this.ScheduleType.ForPeriod &&
				(this.onTestRequest.hours || this.onTestRequest.minutes)
			) {
				this.onTestRequest.hours = this.onTestRequest.hours || 0;
				this.onTestRequest.minutes = this.onTestRequest.minutes || 0;
			}

			this.$nextTick(async () => {
				// force re-validation
				this.$v.$touch();

				// if there are validation errors, stop the submission
				if (this.$v.$error) {
					console.log(
						"Validation error: Hours: " +
							this.$v.onTestRequest.hours.$error +
							", Minutes: " +
							this.$v.onTestRequest.minutes.$error +
							", Date: " +
							this.$v.onTestRequest.onTestUntilDate.$error
					);
					return;
				}

				if (this.selectedAlarmPoint) {
					this.onTestRequest.responseID = this.selectedAlarmPoint.responseID;
				} else if (this.selectedArea) {
					this.onTestRequest.groupID = this.selectedArea.groupID;
				}

				var testRequest = this.onTestRequest;

				if (this.eventDetails && this.eventDetails.eventID) {
					testRequest.eventID = this.eventDetails.eventID;
				}

				if (this.onTestRequest.scheduleType == this.ScheduleType.UntilDate) {
					// Get the hours and minutes out of the time value
					var momentTime = moment(this.onTestRequest.time, "HH:mm", true);
					var hours = momentTime.hours();
					var minutes = momentTime.minutes();

					// Calculate the datetime when this alarm is to be un-masked, and convert it to UTC
					var formattedDate = moment(this.onTestRequest.onTestUntilDate)
						.set({ hours, minutes, seconds: 0 })
						.utc()
						.format();

					// Update the date value within our mask request, set hours and mins to 0
					// (nulls don't work - unsure why, probably because we're posting the form and not a JSON object)
					testRequest = {
						...this.onTestRequest,
						date: formattedDate,
						hours: 0,
						minutes: 0
					};
				} else {
					testRequest = { ...this.onTestRequest };

					delete testRequest.date;
					delete testRequest.time;
				}

				this.areaOnTestEvent = await api.startTest(testRequest);
			});
		}

		public async loadOnTestRecords() {
			if (this.areaOnTestEvent) {
				var onTestRecords = await api.loadGroupOnTestRecords(this.areaOnTestEvent.eventID, 0);

				this.maxEventRecordID = onTestRecords.maxEventRecordID;
				this.testAlarms = onTestRecords.records;
				this.testAlarmsLoaded = true;
			}
		}

		public async endTest() {
			if (this.areaOnTestEvent) {
				await api.endTest(this.areaOnTestEvent.eventID);
				this.areaOnTestEvent = null;

				this.testAlarms = [];
				this.testAlarmsLoaded = false;
			}
		}

		@Emit("open")
		public open() {}

		@Emit("close")
		public close() {
			this.onTestRequest = {
				groupID: null,
				hours: null,
				minutes: null,
				onTestUntilDate: new Date(),
				date: null,
				time: this.time,
				scheduleType: this.ScheduleType.ForPeriod,
				auditMode: false,
				onTestNote: "",
				eventID: null
			} as OnTestRequest;

			this.$v.$reset();
		}

		public async extendTest() {
			this.submitting = true;

			await this.$nextTick();

			// If someone has entered either minutes or hours, set the other to 0
			if (
				this.onTestRequest.scheduleType == this.ScheduleType.ForPeriod &&
				(this.onTestRequest.hours || this.onTestRequest.minutes)
			) {
				this.onTestRequest.hours = this.onTestRequest.hours || 0;
				this.onTestRequest.minutes = this.onTestRequest.minutes || 0;
			}

			// force re-validation
			this.$v.$touch();

			// if there are validation errors, stop the submission
			if (this.$v.$error) {
				console.log(
					"Validation error: Hours: " +
						this.$v.onTestRequest.hours.$error +
						", Minutes: " +
						this.$v.onTestRequest.minutes.$error +
						", Date: " +
						this.$v.onTestRequest.onTestUntilDate.$error +
						", Time: " +
						this.$v.onTestRequest.time.$error +
						", Note: " +
						this.$v.onTestRequest.onTestNote.$error
				);
				this.submitting = false;
				return;
			}

			try {
				if (this.extendMode) {
					let extendRequest = {
						groupID: this.areaOnTestEvent.groupID,
						eventID: this.areaOnTestEvent.eventID,
						hours: 0,
						minutes: 0,
						date: null,
						scheduleType: this.onTestRequest.scheduleType
					};

					if (this.onTestRequest.scheduleType == this.ScheduleType.UntilDate) {
						delete extendRequest.hours;
						delete extendRequest.minutes;

						var momentTime = moment(this.onTestRequest.time, "HH:mm", true);
						var hours = momentTime.hours();
						var minutes = momentTime.minutes();

						// Calculate the datetime when this alarm is to be un-masked, and convert it to UTC
						var formattedDate = moment(this.onTestRequest.onTestUntilDate)
							.set({ hours, minutes, seconds: 0 })
							.utc()
							.format();

						extendRequest.date = formattedDate;
					} else {
						delete extendRequest.date;

						extendRequest.hours = this.onTestRequest.hours;
						extendRequest.minutes = this.onTestRequest.minutes;
					}

					let eventQueueRow = await api.extendTest(extendRequest);
					this.$emit("unParkAtChanged", eventQueueRow.unParkAt);
				}

				this.submitting = false;
			} catch (err) {
				this.submitting = false;
				console.log(err);
			}
		}

		private get hoursRangeWarning(): string {
			if(this.onTestRequest.minutes == 0 && this.onTestRequest.hours == 0) {
				return ("Hours and Minutes cannot both be 0.");
			}

			return (`Hours must be between 0 and ${~~MaxOnTestDurationHours}.`);
		}

		private get minutesRangeWarning(): string {
			if(this.onTestRequest.minutes == 0 && this.onTestRequest.hours == 0) {
				return ("Hours and Minutes cannot both be 0.");
			}

			return (`Minutes must be between 0 and 59.`);
		}

		private get onTestUntilMaxDate(): string {
			return new Date(new Date().getTime() + MaxOnTestDurationMinutes * 60000).toLocaleString();
		}
	}
