
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace, State, Mutation } from "vuex-class";
import { ContactInfoModel, SignUpStep } from "@/store/signup/types";
import api from "@/services/api.service";
import VueSelect from "vue-select";

const SignUp = namespace("signup");

@Component({
    components: {
        "vue-select" : VueSelect
    }
})
export default class ContactInfo extends Vue {
    @SignUp.State countryOptions: [];
    @SignUp.State contactInfo: ContactInfoModel;
    @SignUp.State steps: SignUpStep[];
    @SignUp.Mutation setContactInfo: any;
    @SignUp.Mutation advanceCurrentStep: any;

    private contactInfoInput: ContactInfoModel = {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        addressLine1: "",
        addressLine2: "",
        country: "",
        state: "",
        city: "",
        zipCode: "",
        currency: ""
    };

    private isUserAgreement = false;

    private errorMessage: string = "";

    private stateOptions = [];

    private mounted() {
        this.contactInfoInput = { ...this.contactInfo };
    }
    private validateEmail() {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.contactInfoInput.email).toLowerCase());
    }

    private async isValid() {
        this.errorMessage = "";
        if (!this.isFormValid()) {
            return false;
        }
        let validationResult = await api.validateContactInfo(this.contactInfoInput);
        if (!validationResult.success) {
            this.errorMessage = validationResult.message;
        }

        return validationResult.success;
    }

    @Watch("contactInfoInput", { deep: true })
    private onContactInfoChanged() {
        this.setContactInfo({ ...this.contactInfoInput });
    }

    private async submit() {
        if (await this.isValid()) {
            this.advanceCurrentStep();
        }
    }

    private isFormValid() {
        if (this.contactInfoInput.firstName.length <= 0 ||
            this.contactInfoInput.lastName.length <= 0 ||
            this.contactInfoInput.telephone.length <= 0) {
            return false;
        }

        if (!this.validateEmail()) {
            return false;
        }

        let isAddressLine1Complete = this.contactInfoInput.addressLine1.length > 0;
        let isCountryComplete = this.contactInfoInput.country.length > 0;
        let isCityComplete = this.contactInfoInput.city.length > 0;
        let isZipCodeComplete = this.contactInfoInput.zipCode.length > 0;
        let isAddressValid = isAddressLine1Complete && isCountryComplete && isCityComplete && isZipCodeComplete;
        if (!isAddressValid) {
            return false;
        }

        if(!this.contactInfoInput.currency){
            return false;
        }

        return this.isUserAgreement;
    }

    private get mappedCountryOptions() {
        return this.countryOptions.map(co => { return { title: co[0], code: co[1] } });
    }

    @Watch("contactInfoInput.country")
    private async countryStateOptions(newVal) {
        if (newVal)
        {
            if (newVal !== this.contactInfo.country) {
                this.contactInfoInput.state = "";
            }

            let countryStateFile = await fetch(`/resources/country_states/${newVal}.json`);
            let countryStateFileContents = await countryStateFile.json();
            this.stateOptions = countryStateFileContents[newVal].map(co => { return { title: co[0], code: co[1] } });
        }
    }
}

