
import { Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { EventDetails } from "@/store/site-monitor/types";
import IncidentReportDownload from "./IncidentReportDownload.vue";

const SiteMonitor = namespace("siteMonitor");
const EventSearch = namespace("eventSearch");

@Component({})
export default class IncidentReport extends IncidentReportDownload {
	@Prop(Number) eventid?: number;

	@SiteMonitor.Getter("getEventDetails") EventDetails: EventDetails;
	@SiteMonitor.Getter("getIncidentReportShown") incidentReportShown: any;
	@EventSearch.State IncidentReportOpen: boolean;
	@SiteMonitor.Mutation setIncidentReportShown: any;
	@EventSearch.Mutation setEventSearchIncidentReportShown: any;
	@SiteMonitor.Mutation setIncidentReportDownloaded: any;
	@SiteMonitor.Mutation setActivity;
	@EventSearch.Mutation setEventSearchIncidentReportDownloaded: any;

	private get hasLocationDetails(): boolean {
		return !(this.EventDetails == null || this.EventDetails.latLong == "");
	}

	public eventSummary: string = "";
	public generatingReport = false;
	private isReportModalShown = false;

	private async onGenerateReportClick(): Promise<void> {
		if (this.eventid == null) {
			return;
		}

		this.setActivity();

		try {
			this.generatingReport = true;

			await this.generateReport(this.eventid, this.eventSummary);

			this.generatingReport = false;
			this.closeModal();
			this.setIncidentReportDownloaded(true);
			this.setEventSearchIncidentReportDownloaded(true);
		}
		catch (e) {
			this.$notify({
				type: "error",
				title: "Situation Report",
				text: "Unable to generate the report - please try again later, or contact support if the problem persists"
			});
		}
	}

	private created() {
		this.setIncidentReportShown(false);
		this.setEventSearchIncidentReportShown(false);
	}

	@Watch("incidentReportShown")
	onIncidentReportShownChanged(value: boolean, oldValue: boolean) {
		if (value) {
			this.setIncidentReportDownloaded(false);
			this.isReportModalShown = true;
		} else {
			this.isReportModalShown = false;
		}
	}

	@Watch("IncidentReportOpen")
	onEventSearchIncidentReportShownChanged(value: boolean, oldValue: boolean) {
		if (value) {
			this.setEventSearchIncidentReportDownloaded(false);
			this.isReportModalShown = true;
		} else {
			this.isReportModalShown = false;
		}
	}

	private closeModal() {
		this.setIncidentReportShown(false);
		this.setEventSearchIncidentReportShown(false);
			this.setActivity();
		}

	@Watch("eventSummary")
	private onEventSummaryChanged() {
		this.setActivity();
	}

	@Watch("selections", { deep: true })
	private onSelectionChanged() {
		this.setActivity();
	}
}
