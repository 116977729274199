
import _Vue from "vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api.service";
import { Getter } from 'vuex-class';

export interface EventQueueStatusInfo {
	inQueue: number, 
	inProc: number, 
	parked: number
}

@Component({
	components: {}
})
export default class EqStatusInfoBar extends Vue {
	private status: any = null;
	private updatedAt: string = "";
	private timeoutId: NodeJS.Timeout = null;

	@Getter("getFeature") private getFeature: (featureName: string[]) => boolean;


	@Prop({type:Boolean, default: false })
	private readonly: Boolean; 

	private mounted(): void {
		this.poll();
	}

	private beforeDestroy(): void {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
	}

	private async poll(): Promise<void> {
		this.status = await api.getEventQueueState();
		this.updatedAt = new Date().toLocaleString();
		this.timeoutId = setTimeout(this.poll, 10000);
	}

	private openEventQueue(): void {
		if(this.readonly)
			return;
			
		this.$router.push("/event-queue");
	}

	private get isNewAlarmAudioEnabled(): boolean {
		return this.getFeature(["SystemInfoBar", "NewAlarmAudio"]);
	}

	@Watch("status", {deep: true})
	private statusWatcher(newValue: EventQueueStatusInfo | null, oldValue: EventQueueStatusInfo | null): void {
		if(!newValue || !oldValue || !this.isNewAlarmAudioEnabled)
			return;
			
		const oldValueTotal = oldValue.inQueue + oldValue.inProc + oldValue.parked;
		const newValueTotal = newValue.inQueue + newValue.inProc + newValue.parked;

		if(newValueTotal > oldValueTotal){
			if (this.isNewAlarmAudioEnabled){
				const audio = document.getElementById("newAlarmAudio");
				(audio as HTMLAudioElement).play();
			}
		}
	}
}

