import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import IMarker from "@sureview/v2-mapping-saas";

export interface MapLayersState {
	mapData: MapData;
	defaultMapBounds: MapBounds;
	mapSelectedElevation: number;
	mapItemIcons: any;
	mapLayerItemTypes: MapLayerItemType[];
	advancedFilters: AdvancedFilters;
	filterChanged: number;
	defaultViewForUser: google.maps.LatLngBounds;
	computeDefaultView: DefaultViewTriggers;
}

export interface Elevation {
	name: string;
	elevationValue: number;
}

export interface MapData {
	elevations: Elevation[];
	items: MapLayerItem[];
	layers: MapLayer[];
	areas: AreaMapDetails[];
}

export interface MapLayerItem {
	mapLayerItemId: number;
	mapLayerItemTypeId: number;
	minElevation: number | null;
	maxElevation: number | null;
	objectId: number | null;
	extraValue: string;
	title: string;
	regionPath: string | null;
	latLong: string | null;
	mapLayerIds: number[] | null;
	hideOnMap: boolean;
	groupId: number | null;
}

export interface MapLayerItem {
	mapLayerItemId: number;
	mapLayerItemTypeId: number;
	minElevation: number | null;
	maxElevation: number | null;
	objectId: number | null;
	extraValue: string;
	title: string;
	regionPath: string | null;
	latLong: string | null;
	mapLayerIds: number[] | null;
	hideOnMap: boolean;
}

export interface MapLayer {
	mapLayerId: number;
	minElevation: number | null;
	maxElevation: number | null;
	groupId: number;
	image: string;
	title: string;
	ne: string;
	nw: string;
	se: string;
	sw: string;
	rotation: number;
	calculatedNe?: string;
	calculatedNw?: string;
	calculatedSe?: string;
	calculatedSw?: string;
}

export interface MapLayerItemType {
	mapLayerItemTypeId: number;
	title: string;
	groupID: number | null;
	isAsset: boolean;
	visible: boolean;
	any: boolean;
	hideByDefault: boolean;
	icon: string;
}

export enum MapLayerItemTypeIds {
	Camera = 1,
	Audio = 2,
	Relay = 3,
	Alarm = 4,
	Output = 5,
	Geofence = 6,
	User = 7,
	Asset = 8,
	TrackedAsset = 9,
	Area = 10,
	Guard = 100,
	Waypoint = 101,
	FinalSureviewMapLayerItemType = 1000
}

export interface MapItemLocationIdentifierOption {
	id: number;
	title: string;
}

export interface MapItemLocationIdentifier {
	mapLayerItemId: number;
	locationIdentifierId: string;
	locationIdentifierTypeId: number;
}

export interface MapBounds {
	elevation?: number | null;
	north: number;
	south: number;
	east: number;
	west: number;
}

export interface AreaMapDetails {
	groupId: number;
	title: string;
	markerLocation: string;
	armedDisarmed: number | null;
	maskedStatus: number | null;
}

export interface AdvancedFilterOption {
	filterType: string;
	filterCondition: boolean | Function;
	mapLayerItemTypeId: number | null;
}

export interface AdvancedFilters {
	assetTypes: number[];
	asset: AdvancedFilterOption;
	trackedAsset: AdvancedFilterOption;
	user: AdvancedFilterOption;
	alarm: AdvancedFilterOption;
	relay: AdvancedFilterOption;
	output: AdvancedFilterOption;
	audio: AdvancedFilterOption;
	camera: AdvancedFilterOption;
	area: AdvancedFilterOption;
	regions: AdvancedFilterOption;
}

export interface DefaultViewTriggers {
	assets: boolean;
	areas: boolean;
}
