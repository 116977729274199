
	import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
	import {
		DistanceConfiguration,
		DistanceConfigurationAlarmDecoder,
		DistanceConfigurationDto,
		defaultDistanceConfiguration
	} from "@/store/distance-configuration/types";
	import Component from "vue-class-component";
	import { Emit, Mixins, Prop, Watch } from "vue-property-decorator";
	import vselect3 from "vselect3";
	import MapRadius from "./MapRadius.vue";
	import api from "@/services/api.service";
	import PaginatedSearch from "@/mixins/PaginatedSearch";
	import AlarmDecoder from "@/types/sv-data/alarms/AlarmDecoder";
	import { Getter, namespace } from "vuex-class";
	import { validationMixin } from "vuelidate";
	import { convertStringToLatLng, ILatLng } from "@sureview/v2-mapping-saas";
	import { AreaNode } from "@/types/sv-data/groups/AreaNode";
	import ServerTypeEvent from "@/types/sv-data/events/ServerTypeEvent";
	import { isEqual } from "lodash";

    const DistanceConfigurationStore = namespace("distanceConfiguration");

	@Component({
		components: {
			"area-tree-select": AreaTreeSelect,
			"v-select-3": vselect3,
			"map-radius": MapRadius
		},
	})
	export default class DistanceConfigurationEditModal extends Mixins(PaginatedSearch, validationMixin) {

		@Getter("getUserTenantGroupId") private tenantId: number;
        @DistanceConfigurationStore.Action private retrieveMaxRadius: () => Promise<void>;
        @DistanceConfigurationStore.State private maxRadius: number;

		@Prop({type: Object, required: true})
		private distanceConfiguration: DistanceConfigurationDto;

		@Prop({ default: false, type: Boolean })
		private showModal: boolean;

	    @Prop({ required: false, default: false })
		private readonly: boolean;

		private alarmDecoders: DistanceConfigurationAlarmDecoder[] = [];
		private alarmDecoderServerTypeEvents: ServerTypeEvent[] = [];
		private selectedAlarmDecoder: AlarmDecoder = null;
		private isLoading: boolean = false;
		private currentLocation: ILatLng = {};

		private currentDistanceConfiguration: DistanceConfiguration = {...defaultDistanceConfiguration};

		private async mounted(): Promise<void> {
			await this.loadDecoders();
			await this.retrieveMaxRadius();
			this.onDistanceConfigurationChanged();
		}

		private get existingDistanceConfiguration(): boolean {
			return !!this.distanceConfiguration?.distanceConfigurationId;
		}

		private get distanceConfigurationInvalid(): boolean {
			return !this.currentDistanceConfiguration ||
			!this.currentDistanceConfiguration.groupId ||
			!this.currentDistanceConfiguration.serverTypeId ||
			!this.currentDistanceConfiguration.radius;
		}

		private get distanceConfigurationModified(): boolean {
			return isEqual(this.currentDistanceConfiguration, this.distanceConfiguration);
		}

		private get serverTypeEvents(): ServerTypeEvent[] {
			return this.alarmDecoderServerTypeEvents ?
				this.alarmDecoderServerTypeEvents : [];
		}

		public async loadDecoders(): Promise<void> {
			try {
				this.isLoading = true;
				this.selectedAlarmDecoder = null;
				this.alarmDecoders = await api.retrieveDistanceConfigurationAlarmDecoders();
			}
			catch (ex) {
				console.error("Unexpected error fetching alarm decoders", ex);
			}
			finally {
				this.isLoading = false;
			}
		}

		private async onSave(): Promise<void> {
			if (this.distanceConfigurationInvalid) {
				return;
			}

			await api.createOrUpdateDistanceConfiguration(this.currentDistanceConfiguration);
			this.$emit("reloadDistanceConfigurations");
		}

		@Watch("distanceConfiguration")
		private async onDistanceConfigurationChanged(): Promise<void> {
			if (this.existingDistanceConfiguration) {
				this.currentDistanceConfiguration = {...this.distanceConfiguration};
				this.setAlarmDecoder();
			} else {
				this.currentDistanceConfiguration = {...defaultDistanceConfiguration};
				this.currentDistanceConfiguration.tenantId = this.tenantId;
				this.currentDistanceConfiguration.groupId  = this.tenantId;
			}

			const result = await api.getGroupTreeNode(this.currentDistanceConfiguration.groupId, ["CanViewSiteSetup"]);
			await this.onGroupSelected(result);
		}

		@Watch("currentDistanceConfiguration.serverTypeId")
		private onServerTypeIdChanged(newValue: number, oldValue: number): void {
			if(oldValue) {
				this.currentDistanceConfiguration.eventNum = null;
			}

			this.setAlarmDecoder();
		}

		private setAlarmDecoder(): void {
			// If we have a ServerTypeId we need to set the selectedDecoder to get the events
			if(this.currentDistanceConfiguration.serverTypeId) {
				const selected = this.alarmDecoders.firstOrDefault((ad: AlarmDecoder) => ad.serverTypeId == this.currentDistanceConfiguration.serverTypeId);

				if (selected) {
					this.alarmDecoderServerTypeEvents = selected.serverTypeEvents;
				}
			}
		}

		@Emit("closeEditModal")
		private onClose(): void {}

		private onAlarmDecoderSelected(alarmDecoder: AlarmDecoder): void {
			this.selectedAlarmDecoder = alarmDecoder;
			this.currentDistanceConfiguration.serverTypeId = this.selectedAlarmDecoder.serverTypeId;

			if (this.currentDistanceConfiguration.eventNum) {
				this.currentDistanceConfiguration.eventNum = null;
			}
		}

		private async onGroupSelected(group: AreaNode): Promise<void> {
			this.currentDistanceConfiguration.groupId = group.id;
			this.setLocationByLatLng(group.latLong);
		}

		private setLocationByLatLng(latLngStr?: string): void {
			if(latLngStr) {
				const latLng = convertStringToLatLng(latLngStr);
				const isLatAndLngSet = !isNaN(latLng.lat) && !isNaN(latLng.lng);
				if (isLatAndLngSet) {
					this.currentLocation = latLng;
				}
			}
		}

		private get eventTypesPlaceholder(): string {
			return !!this.currentDistanceConfiguration.serverTypeId &&
			(!this.serverTypeEvents || this.serverTypeEvents.length === 0) ?
			"No Event Types have been configured for the Alarm Decoder" :
			"Select an Alarm Decoder";
		}
	}
