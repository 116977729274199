
  import { Component, Vue } from 'vue-property-decorator';
  import { Action } from 'vuex-class';

@Component({
  components: {
  },
})
export default class TempLogin extends Vue {
  @Action singleEventLogin: any;

  public accessCode: string = '';
  public loggingIn: boolean = false;
  public error: string = '';

  public login() {
      this.loggingIn = true;

      this.singleEventLogin({
          sessionID: this.$route.params.sessionID, 
          accessCode: this.accessCode
      }).then((loginResult: any) => {
          this.loggingIn = false;
          var isViewLoginLink = this.$router.currentRoute.path.includes("view");
          if (isViewLoginLink) {
              this.$router.push({ path: `/views/`, query: { sessionId: this.$route.params.sessionID } });
              return;
          }

          this.$router.push({ path: '/site-monitor/' + loginResult.eventID });
      }, (errorResult: any) => {
          this.loggingIn = false;

          if (errorResult.error != null && errorResult.error != "") {
              this.error = errorResult.error;
          } else {
              switch (errorResult.status) {
                  case 1:
                      this.error = "Invalid session or access code.";
                      break;
                  case 2:
                      this.error = "You have been locked out due to too many password attempts.";
                      break;
                  case 3:
                      this.error = "Your account has been disabled.";
                      break;
                  case 5:
                      this.error = "This event has ended or your access has been removed.";
                      break;
                  default:
                      this.error = "Error during login";
              }
          }
      });
  }

  public onSubmit() {

  }
}
