import { render, staticRenderFns } from "./LinksSelector.vue?vue&type=template&id=33cc9fce&scoped=true"
import script from "./LinksSelector.vue?vue&type=script&lang=ts"
export * from "./LinksSelector.vue?vue&type=script&lang=ts"
import style0 from "./LinksSelector.vue?vue&type=style&index=0&id=33cc9fce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33cc9fce",
  null
  
)

export default component.exports