
    import { Component, Vue } from "vue-property-decorator";
    import { namespace, Getter, Action } from "vuex-class";
    import { get } from "lodash";
    import moment from "moment";
    import vSelect from "vue-select";
    import NavHeader from "@/components/NavHeader.vue";
	import { FeaturesList, UserPermissions } from "@/store/types";
    import { SubscriptionDto } from "@/store/subscription/types";
    import SingleSignOnSettings from '@/components/organization-settings/SingleSignOnSettings.vue';
    import { BvTableField } from 'bootstrap-vue';
	import HousekeepingSettingsForm from "@/components/organization-settings/HousekeepingSettingsForm.vue";
	import QuickEndSettingsForm from "@/components/organization-settings/QuickEndSettings.vue";
	import TenantSystemPrefsForm from "@/components/organization-settings/TenantSystemPrefsForm.vue";
    import AlarmQueuePriorityColors from "@/components/event-queue/AlarmQueuePriorityColors.vue";

    interface Customer {
        id: number;
        name: string;
        email: string;
    }

    const Subscription = namespace("subscription");

    @Component({
        components: {
            "nav-header": NavHeader,
            "vue-select": vSelect,
            "sso-settings": SingleSignOnSettings,
			"housekeeping-settings-form": HousekeepingSettingsForm,
			"quick-end-settings-form": QuickEndSettingsForm,
			"tenant-system-prefs-form": TenantSystemPrefsForm,
            "alarm-queue-priority-colors": AlarmQueuePriorityColors
        }
    })
    export default class SubscriptionDetails extends Vue {
        @Getter getPermissions: UserPermissions;
        @Action loadFeaturesList: any;
		@Getter("getFeaturesList") featuresList: FeaturesList;
		@Getter getUserTenantGroupId: number;

        @Subscription.Action fetchSubscription: any;
        @Subscription.Action updateSubscription: any;
        @Subscription.State subscription: SubscriptionDto;
        @Subscription.Getter('getSubscriptionType') subscriptionType: string;

        private isSubscriptionLoaded = false;

        private get subscriptionFeaturesTableFields(): BvTableField[] {
            var fields = [
                {
                    key: "component",
                    label: "Component",
                    thStyle: { width: '50px'}
                },
                {
                    key: "definition",
                    label: "Definition",
                }
            ];

            if (this.isPricePerUnitEnabled && !this.isLimitedBillingModeEnabled) {
                fields.push({
                    key: "pricePerUnit",
                    label: "Price per unit",
                    thStyle: { width: '160px'},
                });
            }

            fields.push({
                key: "usage",
                label: "Usage",
            });

            return fields;
        }

        private defaultSubscriptionFeaturesTableData = [
            { component: 'Operators', definition: "The total number of users that have the permission to process alarms", usage: 0, pricePerUnit: "" },
            { component: 'Mobile Officers', definition: "The total number of users that have the permission to use the mobile application", usage: 0, pricePerUnit: "" },
            { component: 'Cameras', definition: "The total number of cameras in use", usage: 0, pricePerUnit: "" }
        ];
        private subscriptionFeaturesTableData = [];

        private isEditing: boolean = false;
        private subscriptionUpdateRequest = {
            tenantGroupId: 0,
            firstName: "",
            lastName: "",
            email: "",
        }

        private isSamlAuthEnabledEnv: boolean = process.env.VUE_APP_AUTHENTICATION_SAML_ENABLED === "true";

        private get isSubscriptionSettingsShown(): boolean {
			return (get(this.featuresList, ["Billing"], false) && !this.isSuiteEnabled &&
					(this.getPermissions.isSystemAdmin ||
					this.getPermissions.isAccountAdmin ||
					this.getPermissions.canViewSubscriptionDetails));
		}

		private get isSamlAuthEnabled(): boolean {
			return (get(this.featuresList, ["SAMLAuthentication"], false)) && this.isSamlAuthEnabledEnv;
		}

		private get isBillingEnabled(): boolean {
			return get(this.featuresList, ["Billing"], false);
		}

		private get isSuiteEnabled(): boolean {
			return get(this.featuresList, ["Suite"], false);
		}

        private get isPricePerUnitEnabled(): boolean {
			return get(this.featuresList, ["Billing", "PricePerUnit"], false);
		}

        private get isLimitedBillingModeEnabled(): boolean {
			return get(this.featuresList, ["Billing", "LimitedMode"], false);
		}

        private get isShowPortalEnabled(): boolean {
			return get(this.featuresList, ["Billing", "Portal"], false);
		}

		private get isQuickEndEnabled() {
			return get(this.featuresList, ["Alarms", "SiteMonitor", "QuickEnd"], false);
		}

		private get isTenantSettingsEnabled() {
			return ( typeof( get(this.featuresList, ["TenantSettings"], false)) == "object" );
		}

        private get isAlarmAgeWarningEnabled(): boolean {
            return get(this.featuresList, ["Alarms", "AlarmAgeWarning"], false);
        }

		private get isShowHouseKeepingSettings() : boolean {
			if (this.isSuiteEnabled) {
				return this.getPermissions.isAccountAdmin;
			} else {
				return true;
			}
		}

        private get isPriorityColorsEnabled(): boolean {
            return get(this.featuresList, ["Alarms", "EventQueue", "PriorityColors"], false);
        }

		private get canViewBillingPortal(): boolean{
            if(this.isShowPortalEnabled &&
            this.getPermissions.isAccountAdmin &&
            this.subscription.customer &&
            this.subscription.customer.billingPortalLink &&
            this.subscription.customer.billingPortalLink.url){
                return true;
            }

            return false;
        }

		private get isUserPermittedToViewPage(): boolean {
			return (this.getPermissions.isSystemAdmin ||
				this.getPermissions.isAccountAdmin ||
				this.getPermissions.canViewSubscriptionDetails);
		}

		private get isPageEnabled(): boolean {
			return get(this.featuresList, ["Billing"], false)
                || get(this.featuresList, ["SAMLAuthentication"], false)
                || get(this.featuresList, ["Suite"], false)
                || get(this.featuresList, ["Alarms","SiteMonitor","QuickEnd"], false)
                || get(this.featuresList, ["TenantSettings"], false)
                || get(this.featuresList, ["Alarms","AlarmAgeWarning"], false)
                || get(this.featuresList, ["Alarms","EventQueue","PriorityColors"], false);
		}

        public async mounted() {
            await this.loadSubscriptionForTenant();
		}

        private async loadSubscriptionForTenant() {
            this.isSubscriptionLoaded = false;
            try {
				await this.fetchSubscription(this.getUserTenantGroupId);
				await this.updateSubscriptionDetailsTable();
				if (this.subscription.customer) {
					this.subscriptionUpdateRequest.firstName = this.subscription.customer.firstName;
					this.subscriptionUpdateRequest.lastName = this.subscription.customer.lastName;
					this.subscriptionUpdateRequest.email = this.subscription.customer.email;
				}
			} catch (ex) {
				this.isSubscriptionLoaded = true;
            	throw ex;
			}

			this.isSubscriptionLoaded = true;
        }

        private async updateSubscriptionDetailsTable() {
            this.subscriptionFeaturesTableData = this.defaultSubscriptionFeaturesTableData.slice();
            this.subscriptionFeaturesTableData[0].usage = this.subscription.limitedSubscription ? this.subscription.operators + ` / ${this.subscription.operatorsLimit}` : this.subscription.operators;
            this.subscriptionFeaturesTableData[1].usage = this.subscription.limitedSubscription ? this.subscription.mobileOfficers + ` / ${this.subscription.mobileOfficersLimit}` : this.subscription.mobileOfficers;
            this.subscriptionFeaturesTableData[2].usage = this.subscription.limitedSubscription ? this.subscription.cameras + ` / ${this.subscription.camerasLimit}` : this.subscription.cameras;

            //Don't map the values if the limited billing mode is enabled as we they won't get returned
            if (this.isPricePerUnitEnabled && !this.isLimitedBillingModeEnabled) {
                this.subscriptionFeaturesTableData[0].pricePerUnit = this.subscription.operatorsPricePerUnit;
                this.subscriptionFeaturesTableData[1].pricePerUnit = this.subscription.mobileOfficersPricePerUnit;
                this.subscriptionFeaturesTableData[2].pricePerUnit = this.subscription.camerasPricePerUnit;
            }
        }

        private isSubscriptionValid() {
        	return this.subscription.state === "trialing" || this.subscription.state === "active";
        }

        private get getSubscriptionDetailsCardTitle() {
            return this.isSubscriptionValid() ? "Subscription is Valid" : "Subscription is invalid";
        }

        private get getSubscriptionExpiryDate() {
            return moment(this.subscription.expirationDate).format('LL LT');
        }

        private get getLastUpdatedDate() {
            return moment(this.subscription.updatedAt).format('LL LT');
        }

        private async postUpdateSubscription() {
            this.isEditing = false;
            try {
                this.isSubscriptionLoaded = false;
                this.subscriptionUpdateRequest.tenantGroupId = this.getUserTenantGroupId;
                await this.updateSubscription(this.subscriptionUpdateRequest);
            }
            catch {
                this.$notify({
                    type: "error",
                    title: "Error updating Subscription",
                    text: "Failed to update Subscription, please try again later or contact support for assistance."
                })
            }

            await this.updateSubscriptionDetailsTable();
            this.loadFeaturesList();
            this.isSubscriptionLoaded = true;
        }

        private openEdit(){
            this.isEditing = true;
        }

    }
