
import { Component, Vue, Emit } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import MobileCameraSelect from "@/components/mobile/core/MobileCameraSelect.vue";

const Mobile = namespace("mobile");

@Component({
	components: {
		"camera-select": MobileCameraSelect
	}
})
export default class LiveCameras extends Vue {
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Emit("deviceSelected")
	public deviceSelected() {}

	public get isMobileLiveCamerasEnabled() {
		return this.getFeature(["Mobile", "Live"]);
	}
}
