import { CachedMarkerIcon } from "@/scripts/mapping/markerTypes";

export const walkingIcon: string = "m178.434991,90.102926c10.188692,0 18.45499,-8.266298 18.45499,-18.45499s-8.266298,-18.45499 -18.45499,-18.45499s-18.45499,8.266298 -18.45499,18.45499s8.266298,18.45499 18.45499,18.45499zm36.333261,57.325812l-8.95836,-4.536852l-3.729446,-11.303681c-5.651841,-17.147761 -21.415478,-29.143505 -39.293749,-29.181953c-13.841242,-0.038448 -21.492374,3.883237 -35.871887,9.68887c-8.304745,3.344967 -15.110023,9.68887 -19.108604,17.762928l-2.576009,5.228914c-2.998936,6.074768 -0.576718,13.456763 5.459601,16.494147c5.997872,3.037384 13.302972,0.576718 16.340356,-5.498049l2.576009,-5.228914c1.345676,-2.691353 3.575654,-4.805987 6.343903,-5.920976l10.304036,-4.152373l-5.84408,23.337873c-1.999291,7.997162 0.153792,16.494147 5.728736,22.607363l23.030289,25.144924c2.768248,3.037384 4.729091,6.689934 5.728736,10.650067l7.035965,28.182308c1.65326,6.57459 8.343193,10.611619 14.917784,8.95836c6.57459,-1.65326 10.611619,-8.343193 8.95836,-14.917784l-8.535433,-34.218627c-0.999645,-3.960133 -2.960488,-7.651131 -5.728736,-10.650067l-17.493793,-19.108604l6.613038,-26.413704l2.114634,6.343903c2.037738,6.190111 6.420799,11.303681 12.187983,14.225721l8.95836,4.536852c5.997872,3.037384 13.302972,0.576718 16.340356,-5.498049c2.960488,-6.03632 0.538271,-13.495211 -5.498049,-16.532595zm-88.007233,54.096189c-1.230333,3.11428 -3.075832,5.920976 -5.459601,8.266298l-19.223948,19.262396c-4.805987,4.805987 -4.805987,12.61091 0,17.416897s12.572462,4.805987 17.378449,0l22.83805,-22.83805c2.345322,-2.345322 4.190821,-5.152018 5.459601,-8.266298l5.190466,-12.995389c-21.261686,-23.184081 -14.879336,-16.07122 -18.224303,-20.64652l-7.958714,19.800666z";
export const starIcon: string = "m150.771,68.28346l-24.41129,49.49548l-54.61699,7.96264c-9.79442,1.42057 -13.71967,13.49537 -6.61684,20.41128l39.51414,38.50479l-9.34582,54.39269c-1.68225,9.83181 8.67292,17.19631 17.34585,12.59817l48.85996,-25.68232l48.85996,25.68232c8.67292,4.56076 19.0281,-2.76636 17.34585,-12.59817l-9.34582,-54.39269l39.51414,-38.50479c7.10283,-6.91591 3.17758,-18.99071 -6.61684,-20.41128l-54.61699,-7.96264l-24.41129,-49.49548c-4.37385,-8.82246 -17.04678,-8.93461 -21.45801,0l-0.00001,0z";
export const trackedAssetIcon: string = "M 212.0213 110.99 L 95.0831 164.9619 c -13.4916 6.2972 -8.9944 26.0859 5.3972 26.0859 h 49.4747 v 49.4747 c 0 14.3916 19.7888 18.8916 26.0859 5.3972 l 53.9719 -116.9381 c 4.4972 -10.7972 -7.1972 -22.4916 -17.9916 -17.9916 z";
export let cachedTrackedAssetIcon: string = "";
export const iconCache: CachedMarkerIcon[] = [];

export const getCachedIconUrl = (isAsset:boolean, selected: boolean, color: string, activeEventShare: boolean) => {
	var cachedIcon = iconCache.find(i => i.isAsset === isAsset && i.isSelect === selected && i.iconColor === color && i.hasActiveShare === activeEventShare);
	return cachedIcon? cachedIcon.iconUrl : null;
}

export const setCachedIconUrl = (isAsset:boolean, selected: boolean, color: string, activeEventShare: boolean, url: string) => {
	if(!getCachedIconUrl(isAsset, selected, color, activeEventShare)){
		iconCache.push({
			isAsset: isAsset,
			isSelect: selected,
			hasActiveShare: activeEventShare,
			iconColor: color,
			iconUrl: url
		});
	}
}

export const getAssetIcon = (color: string, isAsset: boolean, selected: boolean, activeEventShare: boolean = false) => {
    // If we have the icon cached, dont regenerate it
	const cachedUrl = getCachedIconUrl(isAsset, selected, color, activeEventShare);
	if (cachedUrl) {
		return cachedUrl;
	}

	const svgString = `
	<svg class="blob" width="32px" height="40px" viewBox="0 0 320 401" xmlns="http://www.w3.org/2000/svg">
		<g>
			<path d="m320,164.045692c0,127.591088 -160,237 -160,237s-160,-109.36379 -160,-237a160,164 0 0 1 320,0z"
				stroke="null"  fill="${color}" />
			${selected ? '<circle id="Oval" cx="160" cy="160" r="127" fill="#fff" stroke="null"/>' : ""}
			<path stroke="null" id="svg_1" d="${isAsset ? starIcon : walkingIcon}" fill="${selected ? color : "#fff"}"/>
			${activeEventShare ? '<path fill="#FF9800" d="M 121.5 101.7 C 123.4902 103.1427 115.2 94.8354 115.2 62.4 c 0 -23.8182 -17.3493 -43.5807 -40.0989 -47.349 A 9.5535 9.5535 90 0 0 76.8 9.6 c 0 -5.3019 -4.2981 -9.6 -9.6 -9.6 s -9.6 4.2981 -9.6 9.6 c 0 2.025 0.6285 3.9024 1.6989 5.451 C 36.5493 18.8193 19.2 38.5818 19.2 62.4 c 0 32.4354 -8.2929 40.7427 -14.9679 47.4177 C -4.8126 118.8624 1.5975 134.4 14.4144 134.4 H 48 c 0 10.6038 8.5962 19.2 19.2 19.2 s 19.2 -8.5962 19.2 -19.2 h 33.5829 c 12.7914 0 19.2453 -15.5193 10.1823 -24.5823 z M 67.2 141.6 a 2.4 2.4 90 0 1 0 4.8 c -6.6168 0 -12 -5.3832 -12 -12 h 4.8 c 0 3.9702 3.2298 7.2 7.2 7.2 z"></path>' : ""}
		</g>
    </svg>`;
	const blob = new Blob([svgString], { type: "image/svg+xml" });
	const url = URL.createObjectURL(blob);

	// since we generated a new icon - save it in our cache so we dont have to do it again
	setCachedIconUrl(isAsset, selected, color, activeEventShare, url);
	return url;
};

export const getTrackedAssetIcon = () => {
	// If we have the icon cached, dont regenerate it
	if (cachedTrackedAssetIcon) {
		return cachedTrackedAssetIcon;
	}

	const svgString = `
    <svg class="blob" width="32px" height="40px" viewBox="0 0 320 401" xmlns="http://www.w3.org/2000/svg">
		<g>
			<path d="m320,164.045692c0,127.591088 -160,237 -160,237s-160,-109.36379 -160,-237a160,164 0 0 1 320,0z"
				stroke="null"  fill="#378805" />
			<path stroke="null" id="svg_1" d="${trackedAssetIcon}" fill="#fff"/>
        </g>
    </svg>`;

	const blob = new Blob([svgString], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);

    // since we generated a new icon - save it in our cache so we dont have to do it again
    cachedTrackedAssetIcon = url;
    return url;
};