import PagedResponse from "@/types/sv-data/PagedResponse";
import { PaginatedSearchQueryParams, PaginatedSearchQueryParamsCustomField } from "@/store/types";
import axios, { CancelTokenSource } from "axios";
import { PaginatedSearchRequest, PaginatedSearchRequestCustomField } from "@/mixins/PaginatedSearch";

let activePaginatedSearchCancelTokenSource: CancelTokenSource = null;
let mostRecentSearchParams: PaginatedSearchQueryParams;
const defaultPaginatedSearchParams: PaginatedSearchQueryParams = {
	page: 1,
	pageSize: 25
};

export const paginatedSearchExtendedMixins = {
	methods: {
		async fetchAllResults<Type extends PagedResponse>(api: any, method: (paginatedSearchRequest: PaginatedSearchRequest) => Promise<Type>, request: PaginatedSearchRequest): Promise<Type> {
			const pageSizeLimit: number = 200;
			let data = [] as any[];

			request.params.page = 1;
			request.params.pageSize = pageSizeLimit;

			// Fetch first page
			let results: Type = await method.call(api, request);
			data.push(...results.data)

			// Fetch next pages until all data consumed
			while (results.totalRecords > data.length) {
				request.params.page += 1;
				results = await method.call(api, request);
				data.push(...results.data)
			}

			results.pageNumber = 1;
			results.pageSize = data.length;
			results.totalRecords = data.length;
			results.data = data;
			return results;
		},

		generateNewPaginatedSearchQueryParamsCustomField(params?: PaginatedSearchQueryParamsCustomField): PaginatedSearchRequestCustomField {
			if (!params) {
				params = defaultPaginatedSearchParams;
			}

			mostRecentSearchParams = params;

			if (activePaginatedSearchCancelTokenSource) {
				activePaginatedSearchCancelTokenSource.cancel();
			}

			activePaginatedSearchCancelTokenSource = axios.CancelToken.source();
			return {
				params,
				cancelToken: activePaginatedSearchCancelTokenSource.token
			}
		}
	}
}
