
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace, Mutation, Getter } from "vuex-class";

@Component({})
export default class TileReport extends Vue {
    @Prop({type: Object, default: null}) tile;
    @Prop({type: Object, default: null}) data;
    @Prop({type: Object, default: null}) filters;

	private compare: boolean = false;
	private isPositive: boolean = false;
    private calulatedPercentage: number = 0;
    private containsData: boolean = false;
    private compatable: boolean = true;

	public get getTileResults() {
        this.verifyData();

        // Check for custom filter 'historicalComparisonDirection' to show comparison values.
        if(this.filters && this.filters.hasOwnProperty('historicalComparisonDirection')) {
            return this.historicalComparisonDirection();
        } else {
            this.compare = false;
            return [this.data.data[0].count];
        }
    }

    public get tileStyle() {
        if (this.tile && this.tile.colour) {
            return { 
                "background-color" : this.tile.colour.background,
                "color" : this.tile.colour.text
            };
        } else {
            return null;
        }
    }

    private historicalComparisonDirection() {
        const com: number = parseInt(this.filters.historicalComparisonDirection[0].id);
        this.isPositive = com == 1 ? true : false;

        // Checks if the historical comparision is positive or negitive, below represents index's for the data.
        const positive = this.isPositive ? 0 : 1;
        const negitive = this.isPositive ? 1 : 0;
        
        const calPercentage = this.getPercentageChange(this.data.data[positive].count, this.data.data[negitive].count);
        this.calulatedPercentage = Math.round((calPercentage + Number.EPSILON) * 100) / 100

        this.containsData = true;
        this.compare = true;
        return [this.data.data[0].count,this.data.data[1].count];
    }

	private getPercentageChange(oldNumber, newNumber){
		var decreaseValue = oldNumber - newNumber;
		if(isFinite((decreaseValue / oldNumber) * 100)) {
			return (decreaseValue / oldNumber) * 100;
		}
		return 0;
    }
    
    private mounted() {
        this.verifyData();
    }

    @Watch("data", { deep: true })
    private verifyData() {
        if (this.data && this.data.data && this.data.data.length > 0) {
            this.compatable = this.data.data[0].hasOwnProperty("count") ? true : false;
            this.containsData = true;
        } else {
            this.containsData = false;
        }
    }
}
