import { PollingStatus } from "@/types/PollingStatus";
import { MutationTree } from "vuex";
import {
	SiteMonitorState,
	EventRecordFilter,
	ApplianceServerDetails,
	DeviceServerDetails
} from "./types";
import { AuditService } from "../../services/auditService";
import { formatDateMixin } from "@/mixins";
import { EventRecord, MaskReason, MaskedAlarm, ShareWithPerson } from "./types";
import { getDefaultState } from "./state";
import Vue from "vue";
import api from "@/services/api.service";
import EventRecordTypes from "@/types/sv-data/enums/EventRecordTypes";
import { EventOutcomeResponse } from "@/types/EventOutcome";

const { dateStringWithZeroHourOffset } = formatDateMixin.methods;
const largeEventSize: number = 150; // Number of eventRecords until an event is considered "Large"

export const mutations: MutationTree<SiteMonitorState> = {
	setAreaNotesVisible(state, isVisible: boolean) {
		state.AreaNotesVisible = isVisible;
	},
	resetEventRecords(state) {
		state.EventRecords = [];
		state.LatestEventRecordID = 0;
		state.AdhocLocationEventRecords = [];
		state.ActiveResponseIDs = {};

		state.EventRecordsAwaitingAck = {};
		state.EventRecordsAwaitingAckCount = 0;
		state.EventRecordsAwaitingRestore = {};
		state.EventRecordsAwaitingRestoreCount = 0;
		state.EventRecordsWithFiles = [];

		state.EventNoPermission = false;
		state.EventRecordsNoFilterRenderIndex = 0;

		state.EventRecordFilters.forEach((filter: EventRecordFilter) => {
			if (filter.showNew) {
				filter.newCount = 0;
			}
			filter.renderIndex = 0;
			filter.filterRecords = [];
		});
	},
    setEventRecordFilters(state, filters) {
        state.EventRecordFilters = filters;
    },
	setEventRecordFilter(state, filterIndex: number) {
		state.EventRecordsFilterActiveIndex = filterIndex;
		const filter = state.EventRecordFilters[filterIndex];
		if (filter != null && filter.clearNewOnView) {
			filter.newCount = 0;
		}
	},
	setEventRecordFilterRenderIndex(state, { filterIndex, renderIndex }) {
		state.EventRecordFilters[filterIndex].renderIndex = renderIndex;
	},
	setEventRecordsNoFilterRenderIndex(state, renderIndex: number) {
		state.EventRecordsNoFilterRenderIndex = renderIndex;
	},
	async setEventRecords(state, eventRecords: EventRecord[]): Promise<void> {
		if (eventRecords.length > 0) {
			const currentFilterIndex = state.EventRecordsFilterActiveIndex;
			const currentFilter = currentFilterIndex > -1 ? state.EventRecordFilters[currentFilterIndex] : null;
			const filters = state.EventRecordFilters;

			const newCheckingFilters = state.EventRecordFilters.filter(function(filter: any) {
				return filter.showNew && (!filter.clearNewOnView || currentFilterIndex !== filter.filterIndex);
			});

			const currentEventRecords = state.EventRecords;
			const activeResponseIDs = state.ActiveResponseIDs;

			const recordsAwaitingRestore = state.EventRecordsAwaitingRestore;
			const recordsAwaitingAck = state.EventRecordsAwaitingAck;
			let newAdhocLocationEventRecords = [];

			const addedFilterCounts = {};
			filters.forEach((filter: any) => {
				addedFilterCounts[filter.filterIndex.toString()] = {
					count: 0,
					records: []
				};
			});

			for (let i = 0; i < eventRecords.length; i++) {
				const eventRecord = eventRecords[i];
				eventRecord.selected = false;
				eventRecord.expanded = false;
				eventRecord.camerasExpanded = false;

				if (eventRecord.eventRecordTypeID === 1) {
					if (eventRecord.awaitingAcknowledgement) {
						recordsAwaitingAck[eventRecord.eventRecordID.toString()] = eventRecord;
					}

					if (eventRecord.awaitingRestore != null) {
						recordsAwaitingRestore[eventRecord.eventRecordID.toString()] = eventRecord;
						Vue.set(eventRecord, "restoredAt", null);
					}

					if (eventRecord.location !== "" && eventRecord.eventRecordID != eventRecords[0].eventRecordID) {
						newAdhocLocationEventRecords.push(eventRecord)
					}

					if (eventRecord.details != null) {
						// Detect if the alarm has been identified as a runaway
						eventRecord.isRunaway = eventRecord.details.toUpperCase().startsWith("RUNAWAY");
					}
				}

				if (eventRecord.eventRecordTypeID === 21 && eventRecord.objectID !== null) {
					if (recordsAwaitingAck[eventRecord.objectID.toString()] != null) {
						recordsAwaitingAck[eventRecord.objectID.toString()].awaitingAcknowledgement = false;
						delete recordsAwaitingAck[eventRecord.objectID.toString()];
					}
				}

				if (
					eventRecord.eventRecordTypeID === 23 &&
					eventRecord.objectID !== null
				) {
					if (
						!state.EventRecordsWithFiles.includes(
							eventRecord.objectID
						)
					) {
						state.EventRecordsWithFiles.push(eventRecord.objectID);
					}
				} else if (
					/* Accounts for uploading a file using the older Audit method called 'upload' */
					eventRecord.eventRecordTypeID === 23 &&
					eventRecord.objectID === null &&
					eventRecord.details
						.toLocaleLowerCase()
						.startsWith("attached file:")
				) {
					state.EventRecordsWithFiles.push(eventRecord.eventRecordID);
				}

				if (
					(eventRecord.eventRecordTypeID === 30 || eventRecord.eventRecordTypeID === 105) &&
					eventRecord.objectID !== null
				) {
					if (recordsAwaitingRestore[eventRecord.objectID.toString()] != null) {
						recordsAwaitingRestore[eventRecord.objectID.toString()].awaitingRestore = false;
						recordsAwaitingRestore[
							eventRecord.objectID.toString()
						].restoredAt = dateStringWithZeroHourOffset(eventRecord.created);
						// eventRecord.displayCreatedMoment;

						delete recordsAwaitingRestore[eventRecord.objectID.toString()];
					}
				}

				// Set eventCaseId when the event has been raised
				if (eventRecord.eventRecordTypeID === EventRecordTypes.CaseCreated && !state.eventCaseId) {
					try
					{
						// We want everything after the first '#', e.g.
						// "Raised to Case Management - Record Id: #123" -> "123"
						const regex: RegExp = /#([\s\S]*)/;
						const caseRecordId = regex.exec(eventRecord.details)[1];
						state.eventCaseId = caseRecordId;
					}
					catch(ex)
					{
						console.error(ex);
					}
				}

				filters.forEach((filter: EventRecordFilter) => {
					if (filter.filterRecordIds.indexOf(eventRecord.eventRecordTypeID) > -1) {
						if (filter.showNew) {
							addedFilterCounts[filter.filterIndex.toString()].count++;
						}

						addedFilterCounts[filter.filterIndex.toString()].records.push(eventRecord);
					}
				});
			}

			filters.forEach((filter: EventRecordFilter) => {
				if (filter.showNew) {
					filter.newCount += addedFilterCounts[filter.filterIndex.toString()].count;
				}

				filter.filterRecords.push(...addedFilterCounts[filter.filterIndex.toString()].records);
			});

			currentEventRecords.push(...eventRecords);

			state.EventRecordsAwaitingAck = recordsAwaitingAck;
			if (state.EventDetails?.eventRecordCount >= largeEventSize){
				try
				{
					state.EventRecordsAwaitingAckCount = await api.retrieveAwaitingAckCountForEvent(state.EventDetails.eventID);
				}
				catch(err)
				{
					console.error(`Failed to retrieve ack count for event ${state.EventDetails?.eventID} ex: ${err}`)
					state.EventRecordsAwaitingAckCount = Object.keys(recordsAwaitingAck).length;
				}
			}
			else
			{
				state.EventRecordsAwaitingAckCount = Object.keys(recordsAwaitingAck).length;
			}

			let eventRecordsAwaitingRestoreCount = 0;
			for (const eventRecordID in recordsAwaitingRestore) {
				if (recordsAwaitingRestore[eventRecordID].awaitingRestore) {
					eventRecordsAwaitingRestoreCount++;
				}
			}

			// update adhoc location event records if any have been found
			if (newAdhocLocationEventRecords.length > 0) {
				state.AdhocLocationEventRecords = state.AdhocLocationEventRecords.concat(newAdhocLocationEventRecords);
			}

			state.EventRecordsAwaitingRestoreCount = eventRecordsAwaitingRestoreCount;
			state.EventRecordsAwaitingRestore = recordsAwaitingRestore;

			state.ActiveResponseIDs = activeResponseIDs;
			state.EventRecords = currentEventRecords;
		}
	},
	updateFirstEventRecordLocation(state, location: string)
	{
		state.EventRecords[0].location = location;
		state.EventRecords[0].latLong = location;
	},
	async setEventAckCount(state): Promise<void> {
		state.EventRecordsAwaitingAckCount = await api.retrieveAwaitingAckCountForEvent(state.EventDetails.eventID);
	},
	setLatestEventRecordID(state, eventRecordID: number) {
		state.LatestEventRecordID = eventRecordID;
	},
	setEventDetails(state, eventDetails: any | null) {
		state.EventDetails = eventDetails;

		if (eventDetails == null) {
			state.IsController = false;
			state.auditService = null;
			state.GroupID = null;
			state.EventRecordsFilterActiveIndex = -1;
			state.activeMapItems = null;
			state.EventRecords = [];
			state.EventRecordsNoFilterRenderIndex = 0;
			state.SelectedEventRecord = null;
			state.EventRecordFilters.forEach((filter: any) => {
				if (filter.showNew) {
					filter.newCount = 0;
				}
				filter.renderIndex = 0;
				filter.filterRecords = [];
			});

			state.LatestEventRecordID = 0;
			state.LastActivity = null;
		} else {
			state.auditService = new AuditService(api.getAuditServiceAddress(), eventDetails?.auditEndpoint);

			state.GroupID = eventDetails.groupID;
			state.LastActivity = new Date().valueOf();

			state.EventRecordsFilterActiveIndex = eventDetails.eventTypeID === 1 ? 0 : -1;
		}

		state.eventLocationLastSet = new Date().valueOf();
	},
	setEventRecordExpanded(state, { eventRecordID, expanded }: any) {
		state.EventRecords.find(e => e.eventRecordID == eventRecordID).expanded = expanded;
	},
	setEventRecordEventRecordDetails(state, { eventRecordID, EventRecordDetails }: any) {
		state.EventRecords.find(e => e.eventRecordID == eventRecordID).EventRecordDetails = EventRecordDetails;
	},
	setGroupID(state, groupID: number) {
		state.GroupID = groupID;
	},
	setEventCloseShown(state, shown: boolean) {
		state.EventCloseOpen = shown;
	},
	setEventRaiseShown(state, shown: boolean) {
		state.EventRaiseOpen = shown;
	},
	setIncidentReportShown(state, shown: boolean) {
		state.IncidentReportOpen = shown;
	},
	setIncidentReportDownloaded(state, downloaded: boolean) {
		state.IncidentReportDownloaded = downloaded;
	},
	setEventDetailsShown(state, shown: boolean) {
		state.EventDetailsOpen = shown;
		state.LastActivity = new Date().valueOf();
	},
	setPutEventOnTestShown(state, shown: boolean) {
		state.PutEventOnTestOpen = shown;
	},
	setEventShare(state, sharing: any[]) {
		state.EventShares = sharing;
	},
	setMobileEventShares(state, sharing: any[]) {
		state.MobileEventShares = sharing;
	},
	setAdHocShareDetails(state, { eventUserID, shareLink, accessCode }: any) {
		state.AdhocShareDetails[eventUserID] = {
			shareLink,
			accessCode
		};
	},
	setShareWithPersons(state, data: ShareWithPerson[]) {
		state.ShareWithPersons = data;
	},
	setEventShareShown(state, shown: boolean) {
		state.EventSharingOpen = shown;
	},
	setInvolvedPartiesShown(state, shown: boolean) {
		state.InvolvedPartyOpen = shown;
	},
	setUserID(state, UserID: number) {
		state.UserID = UserID;
	},
	setСontrollerUserID(state, id: number) {
		state.ControllerUserID = id;
	},
	setIsСontroller(state, isController: boolean) {
		state.IsController = isController;
	},
	setEventLeaveShown(state, shown: boolean) {
		state.EventLeaveOpen = shown;
	},
	setExternalUsersAllowed(state, allow: boolean) {
		state.ExternalUsersAllowed = allow;
	},
	setForceRestoreEventRecord(state, record: EventRecord) {
		state.ForceRestoreEventRecord = record;
	},
	forceRestore(state) {
		const recordsAwaitingRestore = state.EventRecordsAwaitingRestore;

		state.ForceRestoreEventRecord.awaitingRestore = false;
		const eventRecord = state.ForceRestoreEventRecord;

		if (recordsAwaitingRestore[eventRecord.eventRecordID.toString()] != null) {
			recordsAwaitingRestore[eventRecord.eventRecordID.toString()].awaitingRestore = false;
			recordsAwaitingRestore[eventRecord.eventRecordID.toString()].restoredAt = eventRecord.displayCreatedMoment;

			delete recordsAwaitingRestore[eventRecord.eventRecordID.toString()];
		}

		let eventRecordsAwaitingRestoreCount = 0;
		for (const eventRecordID in recordsAwaitingRestore) {
			if (recordsAwaitingRestore[eventRecordID].awaitingRestore) {
				eventRecordsAwaitingRestoreCount++;
			}
		}

		state.EventRecordsAwaitingRestoreCount = eventRecordsAwaitingRestoreCount;
		state.EventRecordsAwaitingRestore = recordsAwaitingRestore;

		state.ForceRestoreEventRecord = null;
	},
	/* setUserShares(state, userShares: any) {
    state.userShares = userShares;
  	}, */
	setEventSharesSet(state, set) {
		state.EventSharesSet = set;
	},
	setRequestingEventRecords(state, requesting: any) {
		state.requestingEventRecords = requesting;
	},
	setSelectedEventRecord(state, eventRecord: any) {
		if (state.SelectedEventRecord != null) {
			state.SelectedEventRecord.selected = false;
		}

		state.SelectedEventRecord = eventRecord;
		if (eventRecord != null) {
			state.SelectedEventRecord!.selected = true;
		}
	},
	setGoToLocation(state, location: string) {
		state.goToLocation = location;
	},
	setLocation(state, data: any) {
		state.EventDetails!.latLong = data.latLong;
		state.EventDetails!.minElevation = data.minElevation;
		state.EventDetails!.maxElevation = data.maxElevation;

		state.eventLocationLastSet = new Date().valueOf();
	},
	setActiveMapItems(state, data: any) {
		state.activeMapItems = data;
	},
	setEventNoPermission(state, noPermission: boolean) {
		state.EventNoPermission = noPermission;
	},
	setMapCircleCenter(state, mapCircleCenter: string) {
		state.mapCircleCenter = mapCircleCenter;
	},
	setEventRecordShares(state, userShares) {
		state.EventRecords.forEach(eventRecord => {
			if (eventRecord.eventUserID != null) {
				const userShare = userShares[eventRecord.eventUserID];

				if (userShare) {
					eventRecord.colorIndex = userShare.colorIndex;
					eventRecord.initials = userShare.initials;
					eventRecord.userName = userShare.name;
				} else {
					eventRecord.colorIndex = null;
					eventRecord.initials = null;
					eventRecord.userName = null;
				}
			}
		});
	},
	setMaskReasons(state, data: MaskReason[]) {
		state.MaskReasons = data;
	},
	setGroupMaskReasons(state, data: MaskReason[]) {
		state.GroupMaskReasons = data;
	},
	setMaskedAlarms(state, data: MaskedAlarm[]) {
		state.MaskedAlarms = data;
	},
	setActivity(state, date) {
		state.LastActivity = date || new Date().valueOf();
	},
	setLastActivity(state, activityAt) {
		state.LastActivity = activityAt;
	},
	setActiveMapItemsRequired(state, required: boolean) {
		state.ActiveMapItemsRequired = required;
	},
	setEventRecordResponse(state, { eventRecordID, title, priority, responseID }: any) {
		const recordToUpdate = state.EventRecords.find(er => er.eventRecordID === eventRecordID);

		recordToUpdate.details = title;
		recordToUpdate.responsePriority = priority;
		recordToUpdate.objectID = responseID;
	},
	ackEventRecord(state, eventRecordID: number) {
		state.LastActivity = new Date().valueOf();
		const recordToUpdate = state.EventRecords.find(er => er.eventRecordID === eventRecordID);
		recordToUpdate.awaitingAcknowledgement = false;
	},

	/**
	 * Sets the FlaggedForReview property for the current event (EventDetails).
	 * @param state The vuex state.
	 * @param flagged Whether or not the event has been flagged for review.
	 */
	setFlaggedForReview(state, flagged: boolean) {
		state.EventDetails.flaggedForReview = flagged;
	},

	setIsUploadingFile(state, uploading: boolean) {
		state.IsUploadingFile = uploading;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	setHideMapFlag(state, hideMapFlag: boolean) {
		state.LastActivity = new Date().valueOf();
		state.hideMapFlag = hideMapFlag;
	},
	setApplianceServerDetails(state, applianceServers: ApplianceServerDetails[]) {
		state.applianceServerDetails = applianceServers;
	},
	setDeviceServerDetails(state, deviceServerDetails: DeviceServerDetails[]) {
		var ids = new Set(deviceServerDetails.map(dev => dev.deviceId));

		// Take new over the existing as there may have been an update.
		state.deviceServerDetails = [...deviceServerDetails, ...state.deviceServerDetails.filter(dev => !ids.has(dev.deviceId))];
    },
	setApplianceServerAndDeviceServerPollingStatus(state, pollingStatus: PollingStatus) {
		state.applianceServerAndDeviceServerPollingStatus = pollingStatus;
	},
	setEventCaseId(state, id: string) {
		state.eventCaseId = id;
	},
	setAuditService(state) {
		state.auditService = new AuditService(api.getAuditServiceAddress());
	},

	setEventOutcomes(state, eventOutcomeResponse: EventOutcomeResponse) {
		state.eventOutcomes = eventOutcomeResponse;
	},
	setAreaDetailsTabIndex(state, tab: number) {
		state.areaDetailsTabIndex = tab;
	},
};
