import { SystemHealthState } from "./types";

export function getDefaultState(): SystemHealthState {
	return {
		status: {
			all: 0,
			healthy: 0,
			unhealthy: 0
		},
		selectedFilter: "All"
	}
}
