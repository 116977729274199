
import { Component, Vue, Emit, Watch, Prop } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import { Settings } from "luxon";
import vSelect from "vue-select";
import { multiselectMixin } from "vue-multiselect";
import { EventOutcome } from '@/types/reports/Filters';

@Component({
	components: {
		"vue-select": vSelect
	}
})
export default class EventOutcomeSelector extends Vue {
	public nestedOutcomes: any[] = [];
	public outcomePath: any[] = [];
	public currentPathIndex: number = 0;
	public visiblePath: boolean = true;
	public showDropdown: boolean = false;
	public showNextDropdown: boolean = true;
	private initialValueHasBeenSet = false;

	@Prop({ type: Array, default: [] })
	eventCategories: any[];

	@Prop({ type: Boolean, default: false })
	clearClicked: boolean;

	@Prop({ type: Boolean, default: false })
	dataLoaded: boolean;

	@Prop({ type: Boolean, default: false })
	multiselect: boolean;

	@Prop(Object)
	value: any;

	@Prop({ type: Array, default: null })
	initialValue: any;

	private initialEventOutcome : EventOutcome[] = null;

	public get currentOutcomes() {
		if (this.nestedOutcomes[this.currentPathIndex]) {
			return this.nestedOutcomes[this.currentPathIndex].children;
		}

		return [];
	}

	public get selectedOutcome() {
		if (this.outcomePath[this.currentPathIndex]) {
			return this.outcomePath[this.currentPathIndex];
		}

		return null;
	}

	public set selectedOutcome(value) {
		if (value) {
			this.outcomePath[this.currentPathIndex] = value;
		}
	}

	public get parentCategories() {
		return this.eventCategories.filter(cat => cat.parentId === null);
	}

	async created() {
		this.nestedOutcomes.push({
			parentId: null,
			children: this.eventCategories.filter(cat => cat.parentId === null)
		});
	}

	@Watch("initialEventOutcome", { immediate: true })
	private setupInitialValue(newInitialValue: EventOutcome[], oldInitialValue: EventOutcome[]) {
		if (!newInitialValue || newInitialValue == oldInitialValue || newInitialValue.length == 0 || this.initialValueHasBeenSet === true){
			return;
		}

		let outcomes = [];
		let lastOutcome = {
			...this.eventCategories.find(outcome => outcome.id == newInitialValue[0].id)
		};

		outcomes.push(lastOutcome);
		while (lastOutcome.parentId) {
			lastOutcome = {
				...this.eventCategories.find(outcome => outcome.id == lastOutcome.parentId)
			};
			outcomes.push(lastOutcome);
		}

		this.outcomePath = outcomes.reverse();

		this.outcomePath.forEach((outcome) => {
			this.eventOutcomeSelectionChange(outcome);
		});

		this.initialValueHasBeenSet = true;
	}

	private async mounted(): Promise<void> {
		this.initialEventOutcome = this.initialValue;

		if(this.initialEventOutcome){
			this.setupInitialValue(this.initialValue, null)
		}
	}

	public childCategories(parentId) {
		return this.eventCategories.filter(cat => cat.parentId === parentId);
	}

	public eventOutcomeSelectionChange(latestChoice: any) {
		// TODO: Need to add multiselect logic
		// if (this.multiselect)
		if (latestChoice !== null) {
			const changeParentId = latestChoice.parentId;

			if (changeParentId != null) {
				let outcomes = this.nestedOutcomes;
				let paths = this.outcomePath;
				let outcomeIndex = outcomes.findIndex(
					p => p.parentId === changeParentId
				);

				outcomes.splice(outcomeIndex + 1, outcomes.length);
				paths.splice(outcomeIndex, paths.length);
			} else {
				this.outcomePath = [];
				this.nestedOutcomes = [];
				this.nestedOutcomes.push({
					parentId: null,
					children: this.eventCategories.filter(
						cat => cat.parentId === null
					)
				});
			}

			const childCats = this.childCategories(latestChoice.id);

			if (childCats.length > 0) {
				this.nestedOutcomes.push({
					parentId: latestChoice.id,
					children: childCats
				});

				this.currentPathIndex++;
			} else {
				this.showNextDropdown = false;
			}

			this.outcomePath.push(latestChoice);
		} else {
			if (this.dataLoaded) {
				this.deleteOutcome(this.currentPathIndex);
			}
		}

		this.resetToLatestOutcome();
	}

	public deleteOutcome(index) {
		this.showDropdown = false;
		this.nestedOutcomes.splice(index + 1, this.nestedOutcomes.length);
		this.outcomePath.splice(index, this.outcomePath.length);

		// Take the last element of the array using slice(-1)
		const outcomeId =
			this.outcomePath.length > 0
				? this.outcomePath.slice(-1)[0].id
				: null;
		this.$emit("input", outcomeId);
	}

	public outcomeLevelChange(index) {
		this.currentPathIndex = index;
		this.showDropdown = true;
		this.showNextDropdown = false;
	}

	public resetToLatestOutcome() {
		this.showDropdown = false;

		if (this.nestedOutcomes.length > this.outcomePath.length) {
			this.showNextDropdown = true;
		} else {
			this.showNextDropdown = false;
		}
	}

	public clear() {
		this.outcomePath = [];
		this.nestedOutcomes = [];
		this.nestedOutcomes.push({
			parentId: null,
			children: this.eventCategories.filter(cat => cat.parentId === null)
		});
		this.showNextDropdown = true;
		this.currentPathIndex = 0;
	}

	@Watch("clearClicked")
	public onClearClicked(value: boolean) {
		if (value) {
			this.clear();
			this.$emit("cleared");
		}
	}

	@Watch("dataLoaded")
	public onDataLoaded(value: boolean) {
		if (value) {
			this.nestedOutcomes = [];
			this.nestedOutcomes.push({
				parentId: null,
				children: this.eventCategories.filter(
					cat => cat.parentId === null
				)
			});
		}
	}

	@Watch("outcomePath")
	public outcomePathString(): void {
		const outcomeId = this.outcomePath.length > 0 ? this.outcomePath.slice(-1)[0].id : 0;
		let pathString = "";

		this.outcomePath.forEach(outcome =>
			pathString += `${!pathString ? '' : ' / '}${outcome.title}`);

		this.$emit("outcome-path", pathString);
		this.$emit("input", outcomeId);
	}
}
