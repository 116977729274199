
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { GChart } from "vue-google-charts";
import TileReport from "@/components/reports/TileReport.vue";
import { formatDateMixin } from "@/mixins";
import { UserPermissions } from "@/store/types";
import { parse } from "qs";

const Reports = namespace("reports");
const { relativeHoursDateFormatted } = formatDateMixin.methods;

enum RenderType {
	graph = 1,
	tile = 2
}

@Component({
	components: {
		GChart,
		"tile-report": TileReport
	}
})
export default class InsightsGraph extends Vue {
	@Prop({
		default: () => ({
			chartType: null,
			reportUrl: null,
			tile: null,
			canDrilldown: false,
			filters: null,
			ready: false
		})
	}) chartObject;
	@Prop({default: false}) isPlugin;
	@Prop({default: false, type: Boolean}) fiterPadlockStatus;
	@Prop({default: null, type: Object}) filters;
	@Prop({default: "", type: String}) customText;

	@Getter getPermissions: UserPermissions;

	@Reports.Action runReport: any;
	@Reports.Action fetchReport: any;
	@Reports.Action fetchReportServiceEndpoint: any;
	@Reports.Action fetchReportTypes: any;
	@Reports.Action heartbeatToReportServer: any;
	@Reports.Action heartbeatToCurrentServer: any;
	@Reports.Getter("getReport") report;
	@Reports.Getter("getCurrentReportUrl") currentReportUrl;
	@Reports.Getter("getReportLoadingStatus") loading;
	@Reports.Getter("getReportErrorStatus") error;
	@Reports.Getter("getReportApiEndpoint") reportApiEndpoint;
	@Reports.Getter("getReportTypes") reportTypes;
	@Reports.Mutation setRelativeHoursToReport: any;
	@Reports.Mutation setCurrentReportUrl: any;
	@Reports.State("RelativeHoursToReport") relativeHoursToReport: number;

	$refs: {
		gChart: any;
		chartContainer: any;
	};

	private reportUrl: string = "";
	private queryReport: any = {};
	private groupingType: string = "";
	private reportData: any = null;

	private renderType: any = RenderType;
	private selectedRenderType: any = 1;

	// Holds the heartbeat interval to keep our session alive on reports
	private heartbeatInterval = null;

	// variable to hold the url link to a report so the user can save it
	private reportPermalink = "";

	// variable used to mark the persistance of filters across reports
	private chartType = "ColumnChart";
	private timeZone = "UTC";

	private renderOptions: any = [
		{ title: 'LineChart', type: this.renderType.graph, legendPosition: 'top' },
		{ title: 'ColumnChart', type: this.renderType.graph, legendPosition: 'top' },
		{ title: 'Table', type: this.renderType.graph, legendPosition: 'top' },
		{ title: 'PieChart', type: this.renderType.graph, legendPosition: 'Right' },
		{ title: 'Tile', type: this.renderType.tile, legendPosition: 'top' }
	];

	// object to hold report filtering options
	public selectedFilters: any = {
		groupId: [],
		referenceId: [],
		responseId: [],
		eventTypeId: [],
		userId: [],
		eventOutcomeId: [],
		serverTypeEventNum: [],
		excludeAutoHandled: { id: 1, title: "Yes" },
		resultCount: { id: 100, title: "100" },
		startDate: relativeHoursDateFormatted(this.relativeHoursToReport),
		endDate: relativeHoursDateFormatted(0)
	};

	// set a flag as to if we have applied any filters
	private get filterApplied() {
		return (
			(this.selectedFilters.groupId && this.selectedFilters.groupId.length > 0) ||
			(this.selectedFilters.referenceId && this.selectedFilters.referenceId.length > 0) ||
			(this.selectedFilters.responseId && this.selectedFilters.responseId.length > 0) ||
			(this.selectedFilters.eventTypeId && this.selectedFilters.eventTypeId.length > 0) ||
			(this.selectedFilters.userId && this.selectedFilters.userId.length > 0) ||
			(this.selectedFilters.eventOutcomeId && this.selectedFilters.eventOutcomeId.length > 0) ||
			(this.selectedFilters.serverTypeEventNum && this.selectedFilters.serverTypeEventNum.length > 0) ||
			(this.selectedFilters.resultCount && this.selectedFilters.resultCount.id != 100) ||
			(this.selectedFilters.excludeAutoHandled && this.selectedFilters.excludeAutoHandled.id == 0)
		);
	}

	private get validGraphData() {
		// Difference between 'this.reportData' and 'this.report'
		// is this.report is a vuex instance and this.reportData is a local instance to contain the returning report.
		if (this.isPlugin) {
			return this.reportData && this.reportData.data && this.reportData.data.length > 0 ? true : false;
		} else {
			return this.report && this.report.data && this.report.data.length > 0 ? true : false;
		}
	}

	// JSON array of chart options for the Goolge chart component
	private get chartOptions() {
		return {
			focus: "category",
			width: "100%",
			height: this.chartType == "Table" ? null : "100%",
			chartArea: {
				width: "85%",
				height: "80%"
			},
			colors: ["#0a6ebd", "#0c7cd5", "#0d8aee", "#2196f3", "#39a1f4", "#51adf6", "#6ab8f7"],
			backgroundColor: "#333",
			hAxis: {
				textStyle: {
					color: "#b0bec5",
					fontSize: 12
				}
			},
			legend: {
				position: this.renderOptions.find(x => this.chartType == x.title).legendPosition,
				alignment: "center",
				textStyle: {
					color: "#b0bec5"
				}
			},
			vAxis: {
				gridlines: { color: "#ccc", count: 2 },
				baselineColor: "transparent",
				textStyle: {
					color: "#b0bec5",
					fontSize: 12
				},
				titleTextStyle: { color: "#b0bec5", fontSize: 14 },
				title: this.report.vaxisTitle ? this.report.vaxisTitle : "Count"
			},
			animation: {
				duration: this.isPlugin ? 0 : 1000,
				easing: this.isPlugin ? "" : "inAndOut",
				startup: this.isPlugin ? false : true
			},
			trendlines: { 0: { visibleInLegend: true } },
			showRowNumber: true,
			magnifyingGlass: { enabled: true },
			region: "US-NJ", // US, world
			datalessRegionColor: "#eee",
			resolution: "metros",
			page: "enable",
			pageSize: 100,
			allowHtml: true
		};
	}

	private get customTitle() {
		if (this.isPlugin) {
			if (this.customText) {
				return this.customText;
			} else {
				return this.reportData && this.reportData.title ? this.reportData.title : 'No Title found';
			}
		} else {
			return this.currentReportUrl == "" ? "" : this.report.title;
		}
	}

	public get tileStyle()  {
		if (this.selectedRenderType == 2 && this.isPlugin) {
			return {
				"background-color" : this.chartObject.tile.colour.background,
				"color" : this.chartObject.tile.colour.text
			};
		}
		return null;
	}

	@Watch("chartObject", { deep: true })
	private updateChartTypeAndFilters(newData: any, oldData: any) {
		if (!oldData || !newData || !newData.chartType) return;

		if (newData.reportUrl !== oldData.reportUrl) {
			this.resetFilters();
		}

		// Route has chnaged, our new filters are computed within our reportBuilder component *parent*
		if (!this.isPlugin) {
			this.selectedFilters = this.filters;
		}

		// Check if our ready flag has been set.
		if (this.chartObject.ready) {
			this.applyFiltersFromRoute();
		}

		// Set the chart type we want to use + our render type.
		this.chartType = this.chartObject.chartType;
		const renderType = this.renderOptions.find(x => x.title == this.chartObject.chartType);
		this.selectedRenderType = renderType.type;
	}

	@Watch("relativeHoursToReport")
	// update the relative date to report on and re run the report
	private onRelativeHoursToReportChanged(newHours: number): void {
		if (this.chartObject.filters) {
			this.selectedFilters.startDate = relativeHoursDateFormatted(this.chartObject.filters.startDate);
			this.selectedFilters.endDate = relativeHoursDateFormatted(0);
		} else {
			this.selectedFilters.startDate = relativeHoursDateFormatted(newHours);
			this.selectedFilters.endDate = relativeHoursDateFormatted(0);
		}

		if (this.isPlugin) {
			return;
		}

		this.runV2Report(this.currentReportUrl, true);
	}

	@Watch("$route")
	// update the relative date to report on and re run the report
	private onRouteChanged(): void {
		this.applyFiltersFromRoute();
	}

	private async created(): Promise<void> {
		if (!this.getPermissions.canViewReports) {
			this.$router.push("/event-queue");
		}

		await this.fetchReportServiceEndpoint();
		await this.fetchReportTypes();

		if (this.reportApiEndpoint && !this.isPlugin) {
			this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			this.$emit('time-zone', this.timeZone);
			// load filter option objects into the UI, then
			this.applyFiltersFromRoute();
		}
	}

	@Watch("timeZone")
	private updateTimezone(): void {
		this.$emit('time-zone', this.timeZone);
	}

	private mounted(): void {
		// Start heartbeat to reports server so our session never expires if we have this page open
		this.heartbeatInterval = setInterval(() => {
			this.heartbeatToReportServer();
			this.heartbeatToCurrentServer();
		}, 30000);
	}

	private destroyed(): void {
		this.resetFilters();
		clearInterval(this.heartbeatInterval);
	}

	private parseurlObject(parsedUrlObject) {
		// Cycle through the properties of the filter object and deal with unique static edge cases
		return Object.keys(parsedUrlObject)
			.filter(p => p !== "groupingTypeEnum" && p !== "timeZone")
			.forEach(key => {
				// if are url contains a report count, use that instead of the default
				if (key === "resultCount") {
					// Parse the count filter object into a human readable object for the drop down list
					const count = parseInt(parsedUrlObject[key]);
					const labeltxt = count > 500 ? "All" : count.toString();
					this.selectedFilters.resultCount = {
						id: count,
						title: labeltxt
					};
				} else if (key === "relativeHours") {
					// Parse the relative hours filter object into a human readable object for the drop down list
					const hours = parseInt(parsedUrlObject[key]);
					this.setRelativeHoursToReport(hours);
					if (this.selectedFilters) {
						this.selectedFilters.startDate = relativeHoursDateFormatted(hours);
						this.selectedFilters.endDate = relativeHoursDateFormatted(0);
					}
				} else if (key === "excludeAutoHandled") {
					// Parse the exlude auto handled filter object into the correct human readable format
					const exclude = parseInt(parsedUrlObject[key]);
					const label = exclude === 1 ? "Yes" : "No";
					this.selectedFilters.excludeAutoHandled = {
						id: exclude,
						title: label
					};
				} else if (key === "startDate" || key === "endDate") {
					// ensure our dates are formatted correcly as the date-time component expects it to be formatted correctly
					let dateString = parsedUrlObject[key];
					let dateobj = new Date(dateString);
					let isoDateString = dateobj.toISOString();
					this.selectedFilters[key] = isoDateString;
				} else {
					this.selectedFilters[key] = parsedUrlObject[key];
				}
			});
	}

	private applyFiltersFromRoute(query = null): void {
		// if the route is set, we are loading a custom report with filters applied
		if (this.$route.params.reportType) {
			// Start building report url with report type and grouping
			let reportType =
				"/" +
				this.$route.params.reportType +
				(this.$route.query.groupingTypeEnum ? "?groupingTypeEnum=" + this.$route.query.groupingTypeEnum : "");

			// Parse the params in the URL to a filter object
			const parsedUrlObject = parse(this.$route.query as any);
			this.parseurlObject(parsedUrlObject);
			this.runV2Report(reportType, Object.keys(this.$route.query).length > 1, this.selectedFilters);
		} else if(this.isPlugin) {
			this.reportUrl = query ? query : this.chartObject.reportUrl;
			// use the parsed url and check for edge cases
			const url = this.getJsonFromUrl(this.reportUrl);
			let reportType = "/" + url['reportType'] + (url['groupingTypeEnum'] ? "?groupingTypeEnum=" + url['groupingTypeEnum'] : "");
			this.groupingType = url['groupingTypeEnum'];
			const parsedUrlObject = parse(this.reportUrl as any);
			delete parsedUrlObject[Object.keys(parsedUrlObject)[0]];
			this.parseurlObject(parsedUrlObject);

			// run the report, any of the custom filters will be applied as normal
			this.runV2Report(reportType, this.chartObject.canDrilldown, this.selectedFilters);
		}
	}

	private resetFilters(emitFilters: boolean = false) {
		this.selectedFilters = {
			groupId: [],
			referenceId: [],
			responseId: [],
			eventTypeId: [],
			userId: [],
			eventOutcomeId: [],
			serverTypeEventNum: [],
			excludeAutoHandled: { id: 1, title: "Yes" },
			resultCount: { id: 100, title: "100" },
			startDate: relativeHoursDateFormatted(this.relativeHoursToReport),
			endDate: relativeHoursDateFormatted(0)
		};

		if (emitFilters) {
			this.$emit('report-filters', this.selectedFilters);
		}
	}

	private goToReport(url: string): void {
		if(this.isPlugin) {
			this.applyFiltersFromRoute(url);
		} else {
			this.$router.push(`/reports${url}`);
		}
	}

	private getJsonFromUrl(url: string): any {
		let query = url.split('?')[1];
		let result = {};
		let reportType = url.split('?')[0].substring(1);
		result[['reportType'][0]] = decodeURIComponent(reportType);
		if (!query) {
			return result;
		}
		query.split("?").forEach(function(part) {
			var item = part.split("&");
			item.forEach((element) => {
				var subpart = element.split("=");
				if(subpart[1]) {
					result[subpart[0]] = decodeURIComponent(subpart[1]);
				}
			});
		});
		return result;
	}

	/** This is added here due to emitting being outside of the scope
	 * while within the google events.
	 */
	private emitDrilldownUrl(drilldownUrl: string): void {
		this.$emit('drilldown-url', drilldownUrl);
	}

	private lockFilters(status: boolean): void {
		this.$emit('lockFilter', status);
	}

	// event listeners for chart events, such as clicking on a graph item
	private chartEvents = {
		// on click of a chart item
		select: async () => {
			if(!this.chartObject.canDrilldown) {
				return;
			}
			// get chart object
			const table = this.$refs.gChart.chartObject;
			//find the selected item index
			const selection = table.getSelection();

			if (selection[0]) {
				let report = this.getReport();
				//const reportDrilldown = isPlugin ? : report.data[0].drillDown;
				// if the current report has a drill down url, and the current chart object has the `getDataTable()` method, aliased as J
				if ((report.drillDownReport || report.data[0].drillDown) && table.J != undefined) {
					//NOTE : to get the correct data of the item that was selected in the graph there are a few hoops to jump through
					// Google charts only gives you a row and column index of the selected item, so using that we need to look up the data in our own result set

					// label of chart item clicked on, such as Area
					let label = table.J.getColumnLabel(0);

					// value of chart item user clicked on, such as 200 Liberty
					let selectedValue = table.J.getValue(selection[0].row, 0);

					// find the matching object property based on the chart label,
					//eg Area, from our object we want to use the Area property
					let labelName = this.getLowerCaseKey(report.data[0], label);

					// find the matching object in our dataset, that matches the value of the item clicked on
					// eg find the result that has the value of 200 Liberty
					let paramResult = report.data.find(row => row[labelName] == selectedValue);
					if (paramResult.drillDown) {
						this.lockFilters(true);
						this.goToReport(paramResult.drillDown);
					} else {
						// get the column name from the current report, that will be used to filter the next report
						// for example groupId, this would apply the groupId filter to the next report to only show the group that was just clicked on
						let idColumnName = this.getLowerCaseKey(report.data[0], report.idColumnName);

						// get the value to be used as the id for the filter, based on the idColumnName
						// eg using the column of groupId we would pull out the value of 5 to be used as the id to filter on
						let filterValue = paramResult[idColumnName];
						let drillDownUrl = `${report.drillDownReport}&${idColumnName}`;

						switch (report.idColumnType) {
							case "Complex": {
								const complexObject = JSON.parse(filterValue);
								drillDownUrl += `[0][id]=${complexObject.id}&${idColumnName}[0][id2]=${complexObject.id2}`;
								break;
							}
							case "Multi": {
								drillDownUrl += `[0][id]=${filterValue}`;
								break;
							}
							case "Object":
							case "String":
							default: {
								drillDownUrl += `=${filterValue}`;
								break;
							}
						}
						if(this.isPlugin) {
							this.emitDrilldownUrl(drillDownUrl);
						}
						this.goToReport(drillDownUrl);
						this.lockFilters(true);
					}
				}
			}
		},
		regionClick: region => {
			// on click of a map region
		}
	};

	// run the report with any filter options
	private async runV2Report(reportUrl, isDrillDown = false, overrideFilters = null) {
		if (!this.loading) {
			if(!this.isPlugin) {
				//store the current report url, for later use and to highlight selected report in the sidebar
				this.setCurrentReportUrl(reportUrl);
			}

			let payload = {
				groupingType: this.isPlugin ? this.groupingType : this.$route.query.groupingTypeEnum,
				timeZone: this.timeZone,
				multiFilters: {},
				complexMultiFilters: {},
				stringMultiFilters: {},
				dateFilters: {},
				idFilters: {},
				reportUrl: {}
			};

			if(overrideFilters) {
				this.selectedFilters = overrideFilters;
				this.$emit('report-filters', this.selectedFilters);
			} else {
				this.selectedFilters = this.filters;
			}

			const urlFiltered = this.loadFilters(reportUrl, payload, this.selectedFilters);

			// Emit to parent if needed.
			this.$emit('report-options-payload', payload);


			this.$emit("report-perma-link", { reportUrl:reportUrl, urlFiltered: urlFiltered });

			if (this.isPlugin) {
				payload.dateFilters['endDate'] = relativeHoursDateFormatted(0); // Get current time NOW.

				if (payload.dateFilters['startDate'] == null) payload.dateFilters['startDate'] = relativeHoursDateFormatted(this.relativeHoursToReport);

				if (reportUrl == "/")  {
					return;
				}

				this.reportData = await this.fetchReport({payload, reportUrl});

				this.$emit('chart-results', this.reportData);
				this.$emit('invalid-reporturl', this.reportData === null);
			} else {

				this.runReport(payload).then(res => {
					this.$emit('error-msg', '');
					this.setSelectedFilters();
				}).catch(err => {
				 	!err || !err.response || !err.response.data ? this.$emit('error-msg', 'Something went wrong') : this.$emit('error-msg', err.response.data);
				});
			}

			if (this.$refs.chartContainer) {
				//scroll the window so the chart is in view (typically for mobile)
				this.$refs.chartContainer.scrollIntoView();
			}
		}
	}

	private loadFilters(reportUrl, payload, selectedFilters) {
			if(!selectedFilters && this.isPlugin) selectedFilters = this.selectedFilters;
			let urlFiltered = {};
			// iterate over each filter option and populate the payload
			Object.keys(selectedFilters).forEach((key, index) => {
				if (selectedFilters[key] && selectedFilters[key].constructor.name == "Array") {
					// If the filter is an array, strip everything apart from the IDs
					if (selectedFilters[key].some(filter => filter.id && filter.id2)) {
						payload.complexMultiFilters[key] = selectedFilters[key].map(filter => {
							return {
								id: parseInt(filter.id),
								id2: parseInt(filter.id2)
							};
						});
					} else if (selectedFilters[key].some(filter => filter.title === filter.id)) {
						payload.stringMultiFilters[key] = selectedFilters[key].map(filter => filter.id);
					}
					else {
						payload.multiFilters[key] = selectedFilters[key].map(filter => parseInt(filter.id));
					}
				}

				if (selectedFilters[key] && selectedFilters[key].constructor.name == "Object") {
					// eg &ExcludedAutoHandled=1
					payload.idFilters[key] = parseInt(selectedFilters[key].id);
				}

				if (selectedFilters[key] && selectedFilters[key].constructor.name == "String") {
					// eg &startDate=2019-05-22T10:46:57.000Z
					payload.dateFilters[key] = selectedFilters[key];
				}

				this.populateFilteredUrlObject(key, urlFiltered, !selectedFilters && this.isPlugin);
			});

			return urlFiltered;
	}

	private populateFilteredUrlObject(key: string, urlFiltered: any, requiresDefault: boolean) {
		// Strip out all the titles from the filter object so that it is not serialized into the URL
		if (this.selectedFilters[key]) {
			if (this.selectedFilters[key].length > 0 && this.selectedFilters[key].constructor.name == "Array") {
				const removedTitles = this.selectedFilters[key].map(filter => {
					const { title, ...ids } = filter;
					return ids;
				});

				urlFiltered[key] = removedTitles;
			} else if (
				this.selectedFilters[key].constructor.name == "Object" ||
				this.selectedFilters[key].constructor.name == "String"
			) {
				if (key == "startDate"|| key == "endDate") {
					urlFiltered[key] = this.selectedFilters[key];
				} else {
					urlFiltered[key] = this.selectedFilters[key].id;
				}
			}
		}
	}

	private setSelectedFilters() {
		if (!this.report.selectedOptions) {
			return;
		}

		this.report.selectedOptions.forEach(option =>{
				(this.selectedFilters[option.title] = option.type === "Array" ? option.data : option.data[0])
			}
		);
	}

	private getReport() {
		return this.isPlugin ? this.reportData : this.report;
	}

	// method that can be run when the chart is created
	private onChartReady() {}

	private getChartDataPlugin(isTable) {
		let data = [];
		let headings = [];

		if (!this.reportData) {
			return {none: 'none'};
		}
		// get the columns from the report object that will be used on the graphy, ie Area and Count
		let columns = this.reportData.metricColumns;

		// if our chart type is a table, and specific table columns have been set, use those instead
		if (isTable && this.reportData.tableColumns && this.reportData.tableColumns.length > 0) {
			columns = this.reportData.tableColumns;
		}
		// if our chart type is a table, but no specific columns have been set, use all the columns
		else if (isTable && !this.reportData.tableColumns) {
			columns = Object.keys(this.reportData.data[0]);
		}

		// use our list of columns to as the heading for the chart
		Object.keys(columns).forEach(function(key, index) {
			headings.push(columns[key]);
		});

		// add the headings to the data array, by default Google charts will use the first row in the array as the heading columns
		data.push(headings);

		// iterate over data to create a subset with only the columns we want for the graph
		this.reportData.data.forEach(element => {
			let row = [];
			headings.forEach(prop => {
				// find the matching key for our heading
				let propLower = this.getLowerCaseKey(element, prop);
				row.push(element[propLower]);
			});
			data.push(row);
		});

		return data;
	}

	// convert our result data into the correct format for Google Charts
	private getChartData(isTable) {
		if (this.isPlugin) {
			return this.getChartDataPlugin(isTable);
		}

		let data = [];
		let headings = [];
		// get the columns from the report object that will be used on the graphy, ie Area and Count
		let columns = this.report.metricColumns;

		// if our chart type is a table, and specific table columns have been set, use those instead
		if (isTable && this.report.tableColumns && this.report.tableColumns.length > 0) {
			columns = this.report.tableColumns;
		}
		// if our chart type is a table, but no specific columns have been set, use all the columns
		else if (isTable && !this.report.tableColumns) {
			columns = Object.keys(this.report.data[0]);
		}

		// use our list of columns to as the heading for the chart
		Object.keys(columns).forEach(function(key, index) {
			headings.push(columns[key]);
		});

		// add the headings to the data array, by default Google charts will use the first row in the array as the heading columns
		data.push(headings);

		// iterate over data to create a subset with only the columns we want for the graph
		this.report.data.forEach(element => {
			let row = [];
			headings.forEach(prop => {
				// find the matching key for our heading
				let propLower = this.getLowerCaseKey(element, prop);
				row.push(element[propLower]);
			});
			data.push(row);
		});

		return data;
	}

	// function to find the key for a property due to case sensitivity
	private getLowerCaseKey(object, findKey) {
		let foundKey = "";
		Object.keys(object).forEach((key, index) => {
			if (key.toLowerCase() == findKey.toLowerCase()) {
				foundKey = key;
			}
		});
		return foundKey;
	}

	private get noReportResults() {
		return !this.error && !this.loading && this.report && this.report.data != undefined && this.report.data.length == 0 || this.isPlugin && this.reportData &&  this.reportData.data.length == 0
	}

	private get getReportData() {
		return this.isPlugin ? this.reportData : this.report;
	}
}
