



















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from 'vuex-class';

@Component({
    components: {
    }
})
export default class ErrorWarning extends Vue {

    @Getter('getDataError') hideOverlay!: boolean;

}
