
import {  Prop, Vue, Component, Watch } from "vue-property-decorator";
import SystemViewTreeNode from '@/types/sv-data/system-view/SystemViewTreeNode';
import api from "@/services/api.service";
import { Getter } from 'vuex-class';
import { UserPermissions, FeaturesList } from '@/store/types';
import { get } from 'lodash';
import  { SetupSummaryResponse, ApplianceSummary } from '@/types/sv-data/system-view/SetupSummary';

@Component({})
export default class SetupOverviewAppliance extends Vue {
	@Prop({ required: true, default: null }) private selectedNode: SystemViewTreeNode;

	@Getter getPermissions: UserPermissions;
	@Getter getFeaturesList: FeaturesList;

	private setupResponse: SetupSummaryResponse =  null;
	private applianceSummary: ApplianceSummary = null;

	private async mounted(): Promise<void> {
		await this.retrieveApplianceDetails();
	}

	@Watch("selectedNode", { deep: true })
	private async retrieveApplianceDetails(): Promise<void> {
		if(this.selectedNode && this.canUseSystemView)
		{
			this.applianceSummary = null; // Reset previous display data

			this.setupResponse = await api.getSetupSummary(this.selectedNode.objectTypeId, this.selectedNode.objectId)

			if(this.setupResponse && !this.setupResponse?.error ) {
				try {
					this.applianceSummary =  JSON.parse(this.setupResponse.summary) as ApplianceSummary
				}
				catch(e) {
					console.error("Failed to Parse appliance summary: "+ e)
					this.setupResponse.error = "Failed to Parse appliance summary";
				}
			}
		}
	}

	private get canUseSystemView(): boolean {
        return get(this.getFeaturesList, ["SystemView"]) && (this.getPermissions.canViewSystemView || this.getPermissions.isSystemAdmin);
    }
}

