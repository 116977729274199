
	import { Component, Vue, Prop } from "vue-property-decorator";
	import GenericTable, { TableHeader } from "../table/generic-table.vue";
	import { Getter, namespace,  State, Action } from "vuex-class";
	import VuePerfectScrollbar from "vue-perfect-scrollbar";
	import { AreaNote } from "@/store/area-notes/types";
	import AreaNotesTableItem from "@/components/area-notes/AreaNotesTableItem.vue";
	import { UserPermissions, TimeZone } from "@/store/types";
	import { DateTime, Settings } from "luxon";

	const AreaNotes = namespace("areaNotes");
	const AreaNotesModule = namespace("areaNotes");

	Settings.defaultLocale = "en";

	@Component({
		components: {
			"vue-perfect-scrollbar": VuePerfectScrollbar,
			"area-notes-table-item": AreaNotesTableItem,
			"generic-table": GenericTable
		}
	})
	export default class AreaNotesTable extends Vue {
		@AreaNotes.Action public fetchAreaNotesForGroup: any;
		@AreaNotes.Getter getCurrentGroupId: number;
		@AreaNotes.Getter getIsFormShown: number;
		@AreaNotes.Getter("getAreaNotes") areaNotes: AreaNote[];
		@AreaNotes.Action startEditingNote: any;

		@AreaNotesModule.Action public deleteAreaNote: (noteId: number) => Promise<void>;

		@Getter getPermissions: UserPermissions;

		// For delete confirmation
		private deletingNote: AreaNote | null = null;

		private columns: TableHeader[] = [
		{
			title: "Note",
			key: "entry",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The note entry",
			searchable: true,
			visible: true,
			dataType: "title",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
		},
		{
			title: "Created",
			key: "created",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The time the note was created",
			searchable: true,
			visible: true,
			dataType: "date",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
			useCustomCell: true
		},
		{
			title: "Created By",
			key: "userName",
			order: 3,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The user who created the note",
			searchable: true,
			visible: true,
			dataType: "string",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
		},
		{
			title: "Starts",
			key: "startFrom",
			order: 4,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The start date and time of the note",
			searchable: true,
			visible: true,
			dataType: "date",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
			useCustomCell: true
		},
		{
			title: "Ends",
			key: "endAt",
			order: 5,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The end date and time of the note",
			searchable: true,
			visible: true,
			dataType: "date",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
			useCustomCell: true
		},
	];

		private isFetchingNotes: boolean = true;

		public async mounted(): Promise<void> {
			this.fetchAreaNotesForGroup(this.getCurrentGroupId)
				.finally(() => {
					this.isFetchingNotes = false;
				});
		}

		private async onModalOpen(note: AreaNote): Promise<void> {
			this.startEditingNote(note);
    	}

		private startDeleteAreaNote(note: AreaNote): void {
			this.deletingNote = note;
		}

		private async deleteNote(note: AreaNote): Promise<void> {
			await this.deleteAreaNote(note.noteId);
			this.deletingNote = null;
		}

		private hasPermissionsToEditNotes(): boolean {
			return this.getPermissions.canViewSiteSetup;
		}

		private displayDateFromString(date: string): string {
			if (!date) {
				return "";
			}
			const utcOffset = "Z";
			const dateTimeFormat = "MM/dd/yyyy hh:mm:ss a";

			if (typeof(date) === "string") {
				// for areas in UTC timezone there is a 'Z' at the end of the DateTime string.
				// remove the offset from the string.
				if (date.endsWith(utcOffset)) {
					date = date.replace(utcOffset, "");
				}
			}

			return DateTime.fromJSDate(new Date(date)).toFormat(dateTimeFormat);
		}
	}
