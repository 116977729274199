
import api from "@/services/api.service";
import { ActionTree } from "vuex";
import { SessionsState } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<SessionsState, RootState> = {
	async updateSession({ commit, rootState }, { resourceId, eventId }: any) {
		return new Promise(function(resolve, reject) {
			if (resourceId === undefined) {
				console.log("Undefined resourceId");
				resolve(404);
				return;
			}

			api.sessionGet(resourceId, eventId, rootState.activeRegion.regionUrl).then(
				sessionResponse => {
					if (sessionResponse.status === 200) {
						commit("setResourceSessionId", {
							resourceId,
							sessionId: sessionResponse.data.authSessionID
						});
					}

					commit("setResourceStatus", sessionResponse.status);
					resolve(sessionResponse.status);
				}
			);
		});
	},
	clearSessions({ commit }) {
		commit("clearResourceSessionId", 2);
		commit("clearResourceSessionId", 3);
	}
};
