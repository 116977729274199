





























































import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import _ from 'lodash'

import EmailFromTo from '@/components/emails/base/email-from-to.vue'
import EmailAttachments from '@/components/emails/base/email-attachments.vue'
import {
    getObjectUrl,
} from '@/utils/formatterEmailAttachments'
import moment from "moment";
const Emails = namespace("emails");

@Component({
    components: {
        EmailFromTo,
        EmailAttachments
    }
})
export default class EmailHolder extends Vue {
    @Prop(String)
    emailId!: string;

    @Emails.Getter getEmail: any;
    @Emails.Getter getEmailFrom: any;
    @Emails.Getter getAttachments: any;
	@Emails.Getter mainHTMLWithImages: any;

    public get emailData() {
        return this.getEmail(this.emailId)
    }

	public get textPlain() {
		return _.get(this.emailData, 'text')
	}

	public get isShowTextPlain() {
		return !this.htmlData && !!this.textPlain
	}

    public get emailFrom() {
        return this.getEmailFrom(this.emailId)
    }

    public get headerEmailData() {
        return {
            fromEmail: this.emailFrom,
            toEmails: _.get(this.emailData, 'to'),
            emailDate: _.get(this.emailData, 'date'),
            subject: _.get(this.emailData, 'subject'),
        }
    }

    public get htmlData() {
        return _.get(this.emailData, 'html')
    }

    public get hasHtmlDataRendered(): boolean
    {
        try
        {
            return this.urlHtmlData ? true : false;
        }
        catch(ex)
        {
            return false;
        }
    }

    public get urlHtmlData(): any 
    {
        try
        {
            return (
                this.mainHTMLWithImages(this.emailId) &&
                getObjectUrl(
                    [this.mainHTMLWithImages(this.emailId)],
                    'text/html'
                )
            )
        }
        catch(ex)
        {
            console.error(ex);
            return null;
        }
    }

    public get emailAttachments() {
        return this.getAttachments(this.emailId)
    }

    public get formattedDate() {
    	const date = this.headerEmailData.emailDate
        return date ? moment(date).format("LLL") : ''
    }

    onIframeLoad() {
		const iframe = this.$refs.iframe as HTMLIFrameElement;
		const iframeDocument = iframe.contentDocument;
		iframeDocument.body.innerHTML = iframeDocument.body.innerHTML + `<style>
			body {
				word-break: break-word;
			}
		</style>`;
	}

    private triggerDownload(): void
    {
        this.$emit("downloadEmail");
    }

}
