import PagedResponse from "@/types/sv-data/PagedResponse";
import ServerTypeEvent from "@/types/sv-data/events/ServerTypeEvent";

export interface DistanceConfigurationState {
	distanceConfigurations: DistanceConfigurationPagedResponse;
	maxRadius: number;
}

export interface DistanceConfiguration {
	distanceConfigurationId?: number;
	groupId?: number;
	serverTypeId?: number;
	eventNum?: number;
	radius?: number;
	tenantId: number;
	inherited?: boolean;
	created?: Date;
	lastUpdate?: Date;
}

export interface DistanceConfigurationDto extends DistanceConfiguration {
	areaTitle: string;
	serverTypeTitle: string;
	serverTypeEventTitle: string;
}

export interface DistanceConfigurationWithTableActions extends DistanceConfigurationDto {
	controls: boolean;
}

export interface DistanceConfigurationDtoWithTableActions extends DistanceConfigurationDto {
	controls: boolean;
}

export interface DistanceConfigurationPagedResponse extends PagedResponse {
	pageNumber: number;
	pageSize: number;
	totalRecords: number;
	data: DistanceConfigurationDto [];
}

export interface DistanceConfigurationAlarmDecoder {
	serverTypeId: number;
	title: string;
	tenantId: number | null;
	serverTypeEvents: ServerTypeEvent[];
}

export const defaultDistanceConfiguration: DistanceConfiguration = {
	groupId: null,
	serverTypeId: null,
	eventNum: null,
	radius: null,
	tenantId: null,
	inherited: false,
	created: null,
	lastUpdate: null
}

/* max radius is 50 miles and this is the number of meters in 50 miles */
export const maxRadiusAsMetersInMiles = 80468;

/* The number of decimal places to go to when display meters as miles */
export const metersAsMilesDecimalPlaces: number = 4;