
import { Component, Vue } from "vue-property-decorator";

export interface Positions {
    clientX: any; 
    clientY: any; 
    movementX: number;
    movementY: number;
}
@Component({})
export default class Draggable extends Vue {

    private containerPosition: Positions = {
        clientX: undefined, 
        clientY: undefined, 
        movementX: 0, 
        movementY: 0
    };

    $refs!: {
        container: any;
    };

    private mounted(){
        this.containerPosition.clientX = 0;
        this.containerPosition.clientY = 0;
    }

    private startDrag(event: any) {
        this.containerPosition.clientX = event.clientX;
        this.containerPosition.clientY = event.clientY;

        //assign the on mouse events to the drag and stop drag
        document.onmousemove = this.dragging;
        document.onmouseup = this.endDrag;
    }

    private dragging(event: any) {
        event.preventDefault()
        this.containerPosition.movementX = this.containerPosition.clientX - event.clientX;
        this.containerPosition.movementY = this.containerPosition.clientY - event.clientY;
        this.containerPosition.clientX = event.clientX;
        this.containerPosition.clientY = event.clientY;

        // set the element's new position:
        this.$refs.container.style.top = (this.$refs.container.offsetTop - this.containerPosition.movementY) + 'px';
        this.$refs.container.style.left = (this.$refs.container.offsetLeft - this.containerPosition.movementX) + 'px';
    }

    private endDrag () {
        // clear functions
        document.onmouseup = null;
        document.onmousemove = null;
    }

}
