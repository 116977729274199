// tslint:disable-next-line:class-name
export default class cornerBounds {
	public raw: google.maps.LatLngBounds;
	public ne: google.maps.LatLng;
	public sw: google.maps.LatLng;
	constructor(
		raw: google.maps.LatLngBounds,
		ne: google.maps.LatLng,
		sw: google.maps.LatLng
	) {
		this.ne = ne;
		this.sw = sw;
		this.raw = raw;
	}
}
