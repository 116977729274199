
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import vSelect from "vue-select";
import vselect3 from "vselect3";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { validationMixin } from "vuelidate";
import { isEqual } from "lodash";

export interface ModalItem {
	title: string;
	key: string;
	dataType: string;
	readOnly?: boolean;
	readOnlyMessage?: string;
	newOnly?: boolean;
	readOnlyIfEdit?: any;
	updateOnly?: boolean;
	data?: any;
	placeholder?: string;
	required?: boolean;
	requiredMethod?: any;
	customPermissions?: any[];
	useCustomRender?: boolean;
	maxLength?: number;
	order?: number;
	defaultValue?: any;
	props?: any;
	visible?: any;
	visibleMethod?: any;
	description?: string;
	max?: number | null;
	min?: number | null;
	validations?: any;
	componentHasValidation?: boolean;
	csvComponent?: string;
	useCustomCell?: boolean;
	excludeCSV?: boolean;
	readOnlyMethod?: (item: any, row: any) => boolean;
	formatter?: (value: any) => any;
}

const GenericTable = namespace("GenericTable");

@Component({
	mixins: [validationMixin],
	validations() {
		var validationModel = {
			newEntry: {}
		};

		var modalItems = this.getModalItems;

		modalItems.forEach(modalItem => {
			if (modalItem.validations) {
				validationModel.newEntry[modalItem.key] = modalItem.validations;
			}
		});
		return validationModel;
	},
	components: {
		"vue-select": vSelect,
		vselect3,
		scrollbar: VuePerfectScrollbar
	}
})
export default class GenericUpdateModal extends Vue {
	$refs!: {
		genericUpdateModal: any;
	};

	@Prop() public deletePermissions!: any[];
	@Prop() public modalItems!: ModalItem[];
	@Prop() public dataList!: any[];
	@Prop({ default: "" }) public term!: string;
	@Prop() public title!: string;

	@Prop() public onSave;
	@Prop() public onDelete;
	@Prop() public onAddNew;
	@Prop({ default: "" }) public idColumn!: string;

	@Prop({ type: Boolean, default: false}) public refreshAllowed: boolean;
	@Prop({ type: Boolean, default: false}) public clearOnClose: boolean;

	@Prop({ type: Boolean, default: false }) isSaveConfirmationEnabled: boolean;
	@Prop() saveActionMessages: any;
	@Prop() saveActionErrors: any;
	@Prop({ type: Boolean, default: true }) closeOnSave: boolean = true;

	@GenericTable.Getter getModalRow: any;
	@GenericTable.Mutation setModalRow: any;
	@GenericTable.Mutation setRowHasChanges: any;

	index: number = null;
	isAddingNew: boolean = false;
	isShown: boolean = false;
	readOnly: boolean = false;
	isValid: boolean = false;
	hasChanged: boolean = false;
	isConfirmationShown: boolean = false;
	isCloseConfirmationShown: boolean = false;
	modalItemRefresh: boolean = false;

	private isSaveConfirmDialogShown: boolean = false;
	private isCheckingEffectsOfSave: boolean = false;
	private saveActionMessageResult = [];
	private saveActionErrorsResult = [];
	private validationComponent = {};

	public newEntry: any = {};

	// == Methods == //
	public showUpdateDialog(dataRow?, readOnly?, addNew?) {
		if (readOnly) {
			this.readOnly = true;
		} else {
			this.readOnly = false;
		}
		if (addNew) {
			this.isAddingNew = true;
			this.isValid = false;
			this.hasChanged = false;
			this.clearNewEntryModelValues();
		} else {
			this.isAddingNew = false;
		}
		if (dataRow) {
			if (this.idColumn) {
				this.index = this.dataList.findIndex(x => x[this.idColumn] === dataRow[this.idColumn]);

				// force update the data list with provided row
				this.dataList.splice(this.index, 1, dataRow);
			} else {
				this.index = this.dataList.findIndex(x => x === dataRow);
			}
		} else {
			this.index = -1;
		}
		// Update our object in the store
		this.setupEntry();
		this.isShown = true;
		this.getModalItems;
	}

	public hideUpdateDialog(confirmed) {
		if (this.hasChanged && !confirmed) {
			// confirmation
			this.isShown = true;
			this.isCloseConfirmationShown = true;
		} else {
			this.isShown = false;
			this.isConfirmationShown = false;
			this.isCloseConfirmationShown = false;
			this.index = -1;
			this.$emit("closed");
		}
	}

	private showCloseConfirmationDialog() {
		this.isCloseConfirmationShown = true;
	}

	private hideCloseConfirmationDialog() {
		this.isCloseConfirmationShown = false;
	}

	private showDeleteConfirmationDialog() {
		this.isConfirmationShown = true;
	}

	private hideDeleteConfirmationDialog() {
		this.isConfirmationShown = false;
	}

	private async performSave(itemToUpdate) {
		if (this.isSaveConfirmationEnabled) {
			if (!this.saveActionMessages && !this.saveActionErrors) {
				return [];
			}

			try {
				this.isCheckingEffectsOfSave = true;

				this.saveActionMessageResult = this.saveActionMessages ? await this.saveActionMessages(itemToUpdate) : [];
				if (!this.saveActionMessageResult) {
					this.saveActionMessageResult = [];
				}

				this.saveActionErrorsResult = this.saveActionErrors ? await this.saveActionErrors(itemToUpdate) : [];
				if (!this.saveActionErrorsResult) {
					this.saveActionErrorsResult = [];
				}

				if (this.saveActionMessageResult.length === 0 && this.saveActionErrorsResult.length === 0) {
					this.onSave(itemToUpdate);
					if (this.closeOnSave) {
						this.hideUpdateDialog(true);
					}
				} else {
					this.isSaveConfirmDialogShown = true;
				}
			} catch (ex) {
				console.log("Unexpected error checking save action messages: " + ex);
			} finally {
				this.isCheckingEffectsOfSave = false;
			}

		} else {
			this.onSave(itemToUpdate);
			if (this.closeOnSave) {
				this.hideUpdateDialog(true);
			}
		}
	}

	private confirmAdd(itemToAdd) {
		this.onAddNew(itemToAdd);
		this.hideUpdateDialog(true);
	}

	public async validate(): Promise<boolean> {
		let row = this.newEntry;
		for (var item in row) {
			let header = this.modalItems.find(modalItem => modalItem.key == item);
			if (header && header.hasOwnProperty("max") && header.hasOwnProperty("dataType") && header.dataType == "number") {
				if (Number(row[item]) > header.max) {
					return false;
				}
			}

			if (header && header.hasOwnProperty("min") && header.hasOwnProperty("dataType") && header.dataType == "number") {
				if (Number(row[item]) < header.min) {
					return false;
				}
			}

			if (header && header.dataType === "component" && header.componentHasValidation) {
				var componentRef = this.$refs[header.key + "-component"];
				if (componentRef && componentRef[0]) {
					if (this.$refs[header.key + "-component"][0].validate) {
						//Only return if the component validation fails as we may still want to check the required flag
						var result = await this.$refs[header.key + "-component"][0].validate();
						if (result === false) {
							return false;
						}
					} else {
						throw "component does not have a validate function";
					}
				}
			}

			if (header && header.required) {
				if (header.newOnly && header.newOnly == this.isAddingNew && !row[item]) {
					return false;
				} else if (!header.newOnly && !row[item]) {
					// Account for cases in which numerical falsy values are treated as invalid
					if (!(header.dataType == "number" && typeof row[item] === 'number')) {
						return false;
                    }
				}

				if ((header.dataType === "component" || header.dataType === "vselect3") && header.props && header.props.multiple && row[item].length === 0) {
					return false;
				}

				let isComponentWithRequiredFields = header.dataType === "component" && header.props && header.props.multiple && header.props.requiredFields;
				let isAnArrayWithALeastOneItem = Array.isArray(row[item]) && row[item].length > 0;
				if (isComponentWithRequiredFields && isAnArrayWithALeastOneItem) {
					return row[item].every(i => {
						return header.props.requiredFields.every(f => {
							return f.isSet(i[f.key]);
						});
					});
				}
			}
		}
		return true;
	}

	private setupEntry() {
		if (this.index == -1 && this.isAddingNew) {
			for (var i in this.modalItems) {
				Vue.set(this.newEntry, this.modalItems[i].key, "null");
				const modelItem = this.modalItems[i];
				this.newEntry[modelItem.key] = modelItem.defaultValue || null;
			}
			this.setModalRow({ ...this.newEntry });
		} else {
			for (var j in this.modalItems) {
				Vue.set(this.newEntry, this.modalItems[j].key, "null");
			}
			for (var key in this.dataList[this.index]) {
				const data = this.dataList[this.index][key];

				if (typeof data === "object") {
					if (Array.isArray(data)) {
						// Deep clone array contents
						const dataCopy = [];
						data.forEach(item => {
							if (typeof item === "object") {
								const itemClone = (Vue as any).util.extend({}, item);
								dataCopy.push(itemClone);
							} else {
								dataCopy.push(item);
							}
						});
						this.newEntry[key] = dataCopy;
					} else if (!data) {
						this.newEntry[key] = data;
					} else {
						// Deep clone object
						this.newEntry[key] = (Vue as any).util.extend({}, data);
					}
				} else {
					this.newEntry[key] = data;
				}
			}
			this.setModalRow({ ...this.newEntry });
			this.hasChanged = false;
		}
	}

	@Watch("newEntry", { deep: true })
	public async entryChanged() {
		// ensure we have the latest component data.
		await this.$forceUpdate();

		//only trigger if allowed
		this.modalItemRefresh = !this.modalItemRefresh;
		this.getModalItems;
		this.isValid = await this.validate();
		this.hasChanged = this.checkHasChanged();
		this.setModalRow({ ...this.newEntry });
	}

	@Watch("isShown")
	private clearEntry(): void {
		if (this.clearOnClose){
			if (!this.isShown) {
				this.newEntry = {};
			}
		}
	}

	public getRow() {
		return this.newEntry;
	}

	created() {
		for (var i in this.modalItems) {
			Vue.set(this.newEntry, this.modalItems[i].key, "null");
		}
	}

	private checkHasChanged() {
		if (this.index != -1) {
			for (var key in this.dataList[this.index]) {
				let valueA = this.newEntry[key];
				let valueB = this.dataList[this.index][key];
				if (valueA != null && valueB != null) {
					if (typeof valueA === "object") {
						if (!isEqual(valueA, valueB)) {
							return true;
						}
					} else {
						if (valueA.toString() != valueB.toString()) {
							return true;
						}
					}
				} else if (valueA != valueB) {
					return true;
				}
			}
		}
		return false;
	}

	private isEquivalent(a, b) {
		// Create arrays of property names
		var aProps = Object.getOwnPropertyNames(a);
		var bProps = Object.getOwnPropertyNames(b);

		// If number of properties is different,
		// objects are not equivalent
		if (aProps.length != bProps.length) {
			return false;
		}

		for (var i = 0; i < aProps.length; i++) {
			var propName = aProps[i];

			// If values of same property are not equal,
			// objects are not equivalent
			if (a[propName] !== b[propName]) {
				return false;
			}
		}

		// If we made it this far, objects
		// are considered equivalent
		return true;
	}

	private get getModalItems() {
		if(this.refreshAllowed)
		{
			//trigger the getModalItems to reset if enabled.
			var triggerRefresh = this.modalItemRefresh; // Important: Assigning local variable in get causes re-render, preventing bugs.
		}

		let items = [];
		if (this.isAddingNew) {
			items = this.modalItems.filter(item => item.updateOnly != true);
		} else {
			items = this.modalItems.filter(item => item.newOnly != true);
		}
		items.sort(function(a, b) {
			return a.order - b.order;
		});

		// only show inputs that are marked as visible or with the value not set
		items.forEach(item => {
			if (typeof (item.visibleMethod) === "function") {
				try {
					item.visible = item.visibleMethod(this.getRow());
				} catch (ex) {
					throw "visibleMethod Exception: " + ex.message;
				}
			}
		});

		// only show inputs that are marked as visible or with the value not set
		items.filter(item => typeof (item.requiredMethod) === "function").forEach(item => {
			try {
				item.required = item.requiredMethod(this.getRow());
			} catch (ex) {
				throw "requiredMethod Exception: " + ex.message;
			}
		});

		items = items.filter(item => item.visible || item.visible == undefined);

		return items;
	}

	private getSelectedModalItem(item, id) {
		let value = null;
		if (item && item.data && id) {
			value = item.data.find(i => {
				return i[item.key] == id;
			});
		}
		return value;
	}

	private checkPermissions(required, current) {
		if (required == null || this.isAddingNew) {
			return true;
		}

		// no permissions were passed in
		if (!current) {
			return false;
		}
		return required.some(r => Object.keys(current).includes(r));
	}

	private async componentItemChanged(newValue: any) {
		this.isValid = await this.validate();
		this.hasChanged = this.checkHasChanged();
	}

	@Watch("hasChanged")
	private onHasChangedUpdated() {
		this.setRowHasChanges(this.hasChanged);
	}

	private getValidationErrorForItem(field: string) {

		// the custom error message exists within vuelidates $params property
		var fieldParams = this.$v.newEntry[field].$params;
		for (var property in fieldParams) {

			// check for and retreive custom error message
			if (fieldParams[property] && fieldParams[property].hasOwnProperty("errorMessage")) {
				if (!this.$v.newEntry[field][property]) {
					return fieldParams[property].errorMessage;
				}
			}
		}

		// if we got here the field doesn't have any custom validations or custom errorMessages
		return null;
	}

	private clearNewEntryModelValues() {
		// get every attribute in the current newEntry model
		let fields = this.$v.newEntry.$model;

		// null out all the fields to prevent previously edited values bleeding into the new item
		for (let property in fields) {
			// if the value is one of our modal items - null it out
			if (this.propertyInModalItems(property)) {
				fields[property] = null;
			} else {
				// else just delete the attribute from the model object, it wont be used anyway
				// this situation only happens when adding a new item after editing an item
				delete this.$v.newEntry.$model[property];
			}
		}
	}

	private propertyInModalItems(key: string) {
		return this.modalItems.some((modalItem) => {
			return modalItem.key == key;
		});
	}

	private confirmSave(row) {
		this.isSaveConfirmDialogShown = false;
		this.onSave(row);
		this.hideUpdateDialog(true);
	}

	private cancelSave() {
		this.isSaveConfirmDialogShown = false;
	}

	private checkIsValid(value) {
		if (value?.isValid !== undefined) {
			this.validationComponent = {
				...this.validationComponent,
				[value.name]: value?.isValid
			};
		}
	}

	private get isValidCustomComponents() {
		const values = Object.values(this.validationComponent);

		if (!values.length) return false;

		return values.some(isValid => !isValid);
	}

	private componentChangeNewEntry({ key, value }) {
		this.newEntry[key] = value;
	}

	private isReadOnly(item: ModalItem): boolean {
		return (!this.checkPermissions(item.customPermissions, this.getRow().permissions)
			|| item.readOnly
			|| (!this.isAddingNew && item.readOnlyIfEdit)
			|| this.readOnly
			|| (item.readOnlyMethod && item.readOnlyMethod(item, this.getRow())));
	}

	private get isSaveConfirmButtonDisabled(): boolean {
		if (this.saveActionErrorsResult && this.saveActionErrorsResult.length > 0) {
			return true;
		}
		return false;
	}

	public updateNewEntry(entry: any, keys: string[]): void {
		for (let i = 0; i < keys.length; i++) {
			Vue.set(this.newEntry, keys[i], entry[keys[i]]);
		}
	}
}
