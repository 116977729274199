import { UserManagementState } from "./types";

export function getDefaultState(): UserManagementState {
    return {
        Permissions: [],
        UserRoles: [],
		userGroups: [],
		userGroupDictionary: {},
		userRoleDictionary: {}
    }
}
