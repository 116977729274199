
import { convertStringToLatLng } from "@sureview/v2-mapping-saas";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AssetMapLayerItem } from "../../mobile-assets/types";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { AreaNode } from "@/types/sv-data/groups/AreaNode";
import GPSService from "@/services/gps.service";
import { ILatLng } from "@sureview/v2-mapping-saas";
import { Getter } from "vuex-class";
import vselect3 from "vselect3";
import api from "@/services/api.service";

@Component({
    components:{
        "area-tree-select": AreaTreeSelect,
        "v-select3" : vselect3
    }
})

export default class AssetMove extends Vue {
    @Getter("getMapKey") public mapKey: any;

    @Prop() private onlineAssets: AssetMapLayerItem[] | null;
    @Prop() private selectedItem: AssetMapLayerItem | null;
	@Prop({ default: false }) private disabled: boolean;

    private gpsService: GPSService = null;
    public selectedMoveAsset: AssetMapLayerItem = null;
    public selectedGroup: AreaNode = null;

    public async mounted() {
        this.gpsService = new GPSService();
    }

    private get filteredAssets() : AssetMapLayerItem[] {
        return this.onlineAssets.filter(ast =>
            ast.assetId !== (this.selectedItem ? this.selectedItem.assetId : -1)
            && ast.latLong);
    }

    @Watch("selectedItem", {deep:true})
    private async showSelected(item: AssetMapLayerItem | null){
        // Set our selected Item and clear out any existing selections
        this.selectedItem = item;
        this.clearSelected();
    }

    private async updateLocation(): Promise<void> {
		const showErrorNotification = () => {
			this.$notify({
				type: "error",
				text: "Issue decoding address for " + this.selectedGroup.label + "(" + (this.selectedGroup.address ? this.selectedGroup.address : "No Address Set") + ") - asset " + this.selectedItem.title + " will not be moved"
			})
		};

		try {
			// if we are moving an asset to an area location
			if (this.selectedGroup) {
				if (this.selectedGroup.latLong) {
					this.moveAssetToLatLng(this.selectedItem, this.selectedGroup.latLong);
					await this.clearSelected();
					return;
				}

				if (this.selectedGroup.address) {
					let locationBounds = await this.gpsService.decodeLocation(this.selectedGroup.address, this.mapKey);
					if (!this.selectedGroup.latLong) {
						try {
							await api.updateAreaLatLong(this.selectedGroup.id, { lat: locationBounds.location.lat, lng: locationBounds.location.lng });
						}
						catch (e) {
							//ignore error
						}
					}
					this.moveAssetToLatLng(this.selectedItem, locationBounds.location);
					await this.clearSelected();
					return;
				} else {
					showErrorNotification();
				}
			}
			// if we are moving an asset to a different assets location
			else if (this.selectedMoveAsset) {
				this.moveAssetToLatLng(this.selectedItem, this.selectedMoveAsset.latLong);
				await this.clearSelected();
			}
		} catch (e) {
			showErrorNotification();
			console.error(e);
		}
    }

    private moveAssetToLatLng(asset: AssetMapLayerItem, latLong: string | ILatLng): void {
    	// create a copy of the asset to avoid updating mutating the selectedItem prop.
		let assetCopy = { ...asset };
		if (typeof latLong !== "string"){
			assetCopy.latLong = latLong;
		} else {
			assetCopy.latLng = convertStringToLatLng(latLong);
			const isLatAndLngSet = !isNaN(assetCopy.latLng.lat) && !isNaN(assetCopy.latLng.lng);
			if (!isLatAndLngSet) {
				throw `Failed to get latLng from string ${latLong}`;
			}
		}
		this.$emit("moveAssetToLatLng", assetCopy)
	}

    private async clearSelected(){
        // Clear out our selected group
        this.$set(this, "selectedGroup", null);
        this.$set(this, "selectedMoveAsset", null);
    }
}

