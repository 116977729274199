
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import { EventRecord } from '@/store/site-monitor/types';
import api from '../services/api.service';

const SiteMonitor = namespace('siteMonitor');

@Component({
    components: {
        'multiselect': Multiselect
    }
})
export default class EventLeave extends Vue {
    @Prop(String) eventid?: string;

    @SiteMonitor.Getter('getForceRestoreEventRecord') forceRestoreEventRecord!: any;
    @SiteMonitor.Mutation setForceRestoreEventRecord: any;
    @SiteMonitor.Mutation forceRestore: any;

    $refs!: {
        restoreModal: any;
    };

    restoralReason: string = "";
    
    @Watch("forceRestoreEventRecord")
    onEventRaiseShownChanged(value: EventRecord | null, oldValue: EventRecord | null) {
        if (value) {
            this.restoralReason = "";
            this.$refs.restoreModal.show();
        } else {
            this.$refs.restoreModal.hide();
        }
    }

    created() {
        this.setForceRestoreEventRecord(null);
    }

    ok() {
        if (this.restoralReason.length > 0) {
            api.forceRestore({
                eventRecordID: this.forceRestoreEventRecord.eventRecordID,
                restoralReason: this.restoralReason
            }).then((response) => {
                this.restoralReason = "";
                this.setForceRestoreEventRecord(null);
            });
        }
    }

    cancel() {
        this.setForceRestoreEventRecord(null);
    }
}
