
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { namespace, Getter, Mutation } from "vuex-class";
import { ActionPlanTask } from "@/store/tasks/types";
import { stringMixin } from "@/mixins";
import SVButton from "@/components/form/SVButton.vue";

const { isNullOrWhitespace } = stringMixin.methods;

@Component({
	components: {
		"sv-button": SVButton
	}
})
export default class YesNoTask extends Vue {
	@Prop(Boolean)
	public editing: Boolean;

	@Prop()
	public task!: ActionPlanTask;

	public hasValidationErrors: boolean = false;
	public showErrorAnimation: boolean = false;

	private btnDisabled: boolean = false;

	public mounted() {
		if (this.editing) {
			this.onValidateTask();
		}
	}

	@Watch("task")
	private onTaskChanged(newVal: ActionPlanTask, oldVal: ActionPlanTask) {
		// re enable the button when our task status changes
		if (newVal.completed != oldVal.completed) {
			this.btnDisabled = false;
		}
	}

	public onCompleteTask(verdict: string) {
		if (!this.btnDisabled) {
			// disable the button until our task request has completed and the status changed
			this.btnDisabled = true;
			this.$emit("complete-task", {
				...this.task,
				result: verdict,
				completed: true
			});
		}
	}

	@Emit("validate-task")
	private onValidateTask() {
		let isValid = !isNullOrWhitespace(this.task.taskText);

		if (!isValid) {
			this.hasValidationErrors = true;
			this.showErrorAnimation = true;

			setTimeout(() => {
				this.showErrorAnimation = false;
			}, this.$config.ANIMATION_DURATION);
		}

		return isValid;
	}
}
