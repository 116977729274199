
import { Component, Mixins, Vue, Watch } from "vue-property-decorator";
import TextHighlight from "vue-text-highlight"
import { Action, Getter, namespace } from "vuex-class";
import AreaTreePath from "@/views/AreaSetup/AreaTreePath.vue";
import { cloneDeep, debounce, get, invert } from "lodash";
import api from "@/services/api.service";
import {
	FeaturesList,
	TimeZone,
	UserPermissions
} from "@/store/types";
import GroupRow from "@/types/sv-data/groups/GroupRow";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import AreaSetupDisableAreaButton from "@/components/areas/AreaSetupDisableAreaButton.vue";
import { ModalItemCSV } from "@/store/csv-form-handler/types";
import GenericUpdateModal from "@/components/table/generic-update-modal.vue";
import CsvUploaderWrapper from "@/components/csv-uploader/csv-uploader-wrapper.vue";
import MapAreaRegion from '@/types/sv-data/maps/MapAreaRegion';
import AreaLocationSetup from './AreaLocationSetup.vue';
import GPSService from '@/services/gps.service';
import Script from '@/types/sv-data/events/Script';
import { AreaLocation } from "@/views/AreaSetup/AreaLocationSetup.vue";
import AreaRegionSetup from "@/views/AreaSetup/AreaRegionSetup.vue";
import GroupRowPagedResponse from "@/types/sv-data/groups/GrowRowPagedResponse";
import { GetChildGroupsListRequest } from "@/types/sv-data/groups/GetChildGroupsListRequest";
import { TableHeader } from "@/components/table/generic-table.vue";
import HideMapMixin from "@/mixins/HideMapMixin";
import { CustomFieldDto, CustomFieldTableTypes } from "@/store/custom-fields/types";
import { MapCustomFieldToModalItem } from "@/utils/CustomFieldLogic";
import { customFieldMixins } from "@/mixins/customFieldMixins";

const { mapArrayFieldsToObject } = customFieldMixins.methods;

const geoCoder = new GPSService();
const Areas = namespace("areas");
const UserContext = namespace("userContext");
const CSVFormHandler = namespace("csvFormHandler");
const CustomFields = namespace("customFields");

interface SelectedGroupRow extends GroupRowDetails {
	totalRecords: number
}

interface TimeZoneItem {
	timeZoneID: number,
	title: string
}

interface GroupRowDetails extends GroupRow {
	location: AreaLocation,
	readOnly?: Boolean,
	readOnlyMessage?: string
}

interface GroupErrorDetails {
	rowId: number,
	errorMessage: string
}

@Component({
	components: {
		"text-highlight": TextHighlight,
		"area-tree-path": AreaTreePath,
		"area-tree-select": AreaTreeSelect,
		"csv-uploader-wrapper": CsvUploaderWrapper,
		"generic-update-modal": GenericUpdateModal
	}
})
export default class AreaTable extends Mixins(HideMapMixin) {
	$refs!: {
		genericUpdateModal: GenericUpdateModal;
		row0: any;
		selectedArea: any
	};

	@Getter("getTimeZones") timeZoneList: TimeZone[];
	@Getter("getUserTenantGroupId") private tenantId: number;
	@Getter("getFeature") getFeature: (featureName: string[]) => boolean;
	@Getter getPermissions: UserPermissions;
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Action getTimeZones: () => Promise<void>;

	@Areas.Action fetchAreaDictionary: () => Promise<void>;
	@Areas.State areaDictionary: Map<number, string>;

	@CustomFields.Action retrieveCustomFields: ({ tableType: number, live: boolean }) => Promise<void>;
	@CustomFields.State areaCustomFields: CustomFieldDto[];

	@UserContext.Action("fetchOverview") fetchTenantOverview: () => Promise<void>;

	private defaultGroupRow: GroupRowDetails = {
		groupID: 0,
		title: "",
		timeZoneID: 0,
		groupTypeID: 1,
		location: {
			mapAreaRegion: null,
			address: "",
			latLong: ""
		},
		latLong: null
	};

	private selectedAreaRow: GroupRowDetails = this.defaultGroupRow;
	private visibleAreas: GroupRow[] = [];
	private areaToDelete: GroupRow = this.defaultGroupRow;
	private safeDeleteInput: string = "";
	private viewPermissions: string [] = ['CanViewSiteSetup', 'CanEditSiteSetup'];
	private editPermissions: string [] = ['CanEditSiteSetup'];
	private groupsWithEditPermissions: number [] = [];
	private timeZonesFormatted: TimeZoneItem [] = [];
	private isDeleteConfirmDialogShown: boolean = false;
	private deleteActionMessages: string [] = [
		"Delete the audit trail for this area",
		"Delete all users for this area",
		"Delete all notes associated with this area"
	];
	private progressBulk = {};
	private selectedAreaPath: GroupRow[] = [];
	private actionPlans: Script[] = [];
	private bulkNestedLevels: any[] = [];
	private bulkUploadResults = [];
	private batchCurrentValue: number = 0;
	private readonly customFieldTableType: number = CustomFieldTableTypes.Area;

	private get getModalItems(): ModalItemCSV[] {
		let modalItems = [
			{
				title: "Title",
				key: "title",
				dataType: "text",
				placeholder: "Area Title",
				required: true,
				maxLength: 200,
				csvComponent: 'input',
				csvValidation: {
					validationCell({ currentElement }) {
						return { isValid: !!currentElement.csvValue }
					}
				},
				readOnly: this.isSuiteEnabled,
				order: 1
			},
			{
				title: "Reference Id",
				key: "referenceId",
				visible: true,
				dataType: "text",
				placeholder: "Reference Id",
				maxLength: 50,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
				order: 2
			},
			{
				title: "Location Details",
				key: "location",
				dataType: "component",
				visible: this.isAreaLocationEnabled,
				required: this.isAreaLocationEnabled,
				csvExclude: true,
				props: {
					value: this.selectedAreaRow.location,
					readonly: !this.groupsWithEditPermissions.includes(this.selectedAreaRow.groupID) || this.isSuiteEnabled
				},
				data: AreaLocationSetup,
				readOnly: this.isSuiteEnabled,
				order: 3
			},
			{
				title: "Address",
				key: "address",
				dataType: "text",
				newOnly: false,
				required: !this.isAreaLocationEnabled,
				visible: !this.isAreaLocationEnabled,
				placeholder: "123 High Street",
				maxLength: 500,
				csvComponent: 'input',
				csvValidation: {
					validationCell({ currentElement }: any) {
						return { isValid: !!currentElement.csvValue }
					}
				},
				readOnly: this.isSuiteEnabled,
				order: 4
			},
			{
				title: "Telephone",
				key: "telephone",
				dataType: "text",
				maxLength: 50,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
				order: 5
			},
			{
				title: "Alt. Telephone",
				key: "telephone2",
				dataType: "text",
				maxLength: 50,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
				order: 6
			},
			{
				title: "Telephone Fire",
				key: "telephoneFire",
				dataType: "text",
				maxLength: 50,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
				order: 7
			},
			{
				title: "Telephone Police",
				key: "telephonePolice",
				dataType: "text",
				maxLength: 50,
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
				order: 8
			},
			{
				title: "Time Zone",
				key: "timeZoneID",
				dataType: "vselect3",
				props: {
					reduce: (t) => t.timeZoneID
				},
				defaultValue: this.selectedArea.timeZoneID,
				data: this.timeZonesFormatted,
				placeholder: "Choose Timezone Area",
				required: true,
				csvComponent: 'select',
				csvData: this.timeZoneList && this.timeZoneList.map(v => v.id) || [],
				csvValidation: {
					validationCell: ({ currentElement }) => {
						return {
							isValid: !!this.timeZoneList.find(({ id }) => currentElement.csvValue === id)
						}
					},
				},
				readOnly: this.isSuiteEnabled,
				order: 8
			},
			{
				title: "Parent Area",
				key: "parentGroupID",
				dataType: "component",
				data: AreaTreeSelect,
				props: {
					reduce: (a) => a.id,
					clearable: false
				},
				defaultValue: this.selectedArea.groupID,
				required: this.isParentRequired(),
				visible: this.isParentRequired(),
				csvComponent: 'select',
				csvData: Object.keys(this.areasByTitle),
				csvUniteData: true,
				csvUnitedKey: "Title",
				csvValidation: {
					validationCell: ({ currentElement, formData }: any) => {
						const currentRow = formData[currentElement.rowId]
						const indexTitle = currentRow.findIndex(({ title }) => title === 'Title')
						const indexParentArea = currentRow.findIndex(({ title }) => title === 'Parent Area')
						const currentTitle = currentRow[indexTitle]
						const findTitle = currentElement.unionData.find(v => v === currentElement.csvValue)

						if(!findTitle) return { isValid: false }

						const isTitleAlreadyExist = this.areasByTitle[currentElement.csvValue] || currentElement.csvValue === ""
						const isSameName = currentTitle.csvValue === currentElement.csvValue

						if (isTitleAlreadyExist) return { isValid: isTitleAlreadyExist }
						if (isSameName) return { isValid: false }

						const linkedRows = formData.filter(v => {
							const { csvValue } = v[indexTitle]

							return csvValue === currentElement.csvValue
						})

						return {
							isValid: !linkedRows.some(v => v[indexParentArea].csvValue === currentTitle.csvValue)
						}
					}
				},
				readOnly: this.isSuiteEnabled,
				order: 9
			},
			{
				title: "Manual Tour Action Plan",
				key: "scriptId",
				description: "Action plan to use when a manual tour is raised against this area",
				dataType: "vselect3",
				csvExclude: !this.isManualTourActionPlanEnabled,
				data: this.actionPlans,
				visible: this.isManualTourActionPlanEnabled,
				props: {
					reduce: script => script.scriptID,
					readonly: !this.groupsWithEditPermissions.includes(this.selectedAreaRow.groupID)
				},
				placeholder: "Choose Action Plan",
				csvComponent: 'select',
				csvData: this.actionPlans && this.actionPlans.map(v => v.title) || [],
				csvValidation: {
					validationCell: ({ currentElement }) => {
						return {
							isValid: !!this.actionPlans.find(({ title }) => currentElement.csvValue === title)
						}
					},
				},
				order: 10
			},
			{
				title: "Area Status",
				key: "disabledAt",
				dataType: "component",
				props: {
					areaProp: this.selectedAreaRow,
					readonly: !this.groupsWithEditPermissions.includes(this.selectedAreaRow.groupID)
				},
				data: AreaSetupDisableAreaButton,
				updateOnly: true,
				csvExclude: true,
				order: 11
			},
			{
				title: "Region",
				key: "location",
				dataType: "component",
				csvExclude: true,
				props: {
					value: this.selectedAreaRow.location,
					areaLocation: this.selectedAreaRow.location.latLong,
					readonly: !this.groupsWithEditPermissions.includes(this.selectedAreaRow.groupID)
				},
				data: AreaRegionSetup,
				visible: !this.isAreaLocationEnabled && this.isAreaRegionsEnabled,
				readOnly: this.isSuiteEnabled,
				order: 12
			},
			{
				title: "Summary",
				key: "notes",
				dataType: "textarea",
				placeholder: "Area Summary...",
				csvComponent: 'input',
				readOnly: this.isSuiteEnabled,
				order: 13
			},
			{
				title: "Latitude and Longitude",
				key: "latLong",
				dataType: "text",
				visible: false,
				csvComponent: 'input',
				csvValidation: {
					validationCell({ currentElement }) {
						if(!currentElement.csvValue){
							return {
								isValid: false,
								message: "A latitude and longitude is required."
							};
						}

						try
						{
							const latLng = geoCoder.tryConvertStringToGeoCodeLocation(currentElement.csvValue);
							if (!latLng && !this.isHideMapsEnabled) throw "invalid latlng";

							return {
								isValid: true
							}
						}
						catch {
							return {
								isValid: false,
								message: "Latitude and longitude is invalid."
							};
						}

					}
				},
				readOnly: this.isSuiteEnabled,
				order: 14
			},
			{
				title: "Max Mask Duration (minutes)",
				key: "maskDurationLimit",
				dataType: "number",
				maxLength: 10,
				min: 0,
				csvComponent: 'input',
				visible: this.isMaxMaskDurationEnabled,
				order: 15
			},
		] as ModalItemCSV[];

		if (this.isCustomFieldsEnabled) {
			try {
			var genericModalItem: ModalItemCSV = {
				title: "",
				key: "",
				dataType: null,
				readOnly: true,
				order: modalItems.length + 1
			};

			const areaCustomFieldIds: number[] = this.selectedAreaRow.customFieldValues ? this.selectedAreaRow.customFieldValues.map(cf => cf.id) : [];
			const populatedCustomFields = this.areaCustomFields.filter(cf => areaCustomFieldIds.includes(cf.id));

			for (let i = 0; i < populatedCustomFields.length; i++) {
				let customFieldModalItem = MapCustomFieldToModalItem(populatedCustomFields[i], cloneDeep(genericModalItem));
				modalItems.push(customFieldModalItem as ModalItemCSV);
			}
			} catch (err) {
				console.error("Unexpected error displaying custom fields: ", err);
			}
		}

		return modalItems;
	}

	private get fields(): any[] {
		return [
			{
				key: "groupID",
				label: "GroupId",
				visible: false,
				sortable: true
			},
			{
				key: "title",
				label: "Title",
				sortable: true,
				sortingBy: "none",
				visible: true,
				searchable: true,
			},
			{
				key: "address",
				label: "Address",
				sortable: true,
				sortingBy: "none",
				visible: true,
				searchable: true,
				thStyle: "width:30%;max-width:300px!important;"
			},
			{
				key: "telephone",
				label: "Telephone",
				sortable: true,
				sortingBy: "none",
				visible: true,
				searchable: true,
				thStyle: "width:120px;"
			},
			{
				key: "telephone2",
				label: "Alt. Telephone",
				sortable: true,
				sortingBy: "none",
				visible: true,
				searchable: true,
				thStyle: "width:120px;",
				sortKey: "AltTelephone",
			},
			{
				key: "telephoneFire",
				label: "Telephone Fire",
				sortable: true,
				sortingBy: "none"
			},
			{
				key: "telephonePolice",
				label: "Telephone Police",
				sortable: true,
				sortingBy: "none"
			},
			{
				key: "timeZoneID",
				label: "Time Zone",
				sortable: true,
				sortingBy: "none",
				sortKey: "TimeZone",
			},
			{
				key: "notes",
				label: "Summary",
				sortable: true,
				sortingBy: "none",
				visible: true,
				searchable: true,
				sortKey: "Summary"
			},
			{
				key: "disabledAt",
				label: "Status",
				sortable: true,
				sortingBy: "none",
				sortKey: "Status",
			},
			{
				key: "maskDurationLimit",
				label: "Max Mask Duration (minutes)",
				sortable: false,
				sortingBy: "none",
				visible: this.isMaxMaskDurationEnabled,
			},
			{
				key: "options",
				visible: true
			}
		];
	}

	private selectedArea: SelectedGroupRow = {
		groupID: -1,
		title: "My Areas",
		groupTypeID: 1,
		children: [],
		isParent: true,
		armed: false,
		timeZoneID: -1,
		totalRecords: 0,
		location: null
	};
	private selectedPage: number = 1;
	private resultsPerPage: string = "25";
	private resultsPerPageUpdated: string = "25";
	private debounceUpdateSearchFilter;
	private searchQuery: string = "";
	private selectionHistory: SelectedGroupRow[] = [];
	private currentSelectionHistoryIndex: number = 0;
	private areaTreePath: GroupRow[] = [];
	private areaTreePathHistory: GroupRow[][] = [];
	private currentAreaTreePathHistoryIndex: number = 0;
	private isLoading: boolean = false;
	private showFilter: boolean = false;
	private tableHeight: string = "";
	private shortcuts = {
		navigateBackward: ["alt", "arrowleft"],
		navigateForward: ["alt", "arrowright"]
	};

	private parentIsRequired = true;

	private sortByColumn: string = "title";
	private sortDirection: string = "ascending";

	private isParentRequired() {
		return this.parentIsRequired;
	}

	//Getters
	private get isAreaRegionsEnabled(): boolean {
		return this.getFeature(["Areas", "Regions"]) && !this.isHideMapsEnabled;
	}

	private get isAreaLocationEnabled(): boolean {
		return this.getFeature(["Areas", "Location"]) && !this.isHideMapsEnabled;
	}

	private get isManualTourActionPlanEnabled(): boolean {
		return this.getFeature(["Areas", "ManualTourActionPlans"]);
	}

	private get isMaxMaskDurationEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Masking", "MaxMaskDuration"], false);
	}

	private get isSuiteEnabled(): boolean {
		return get(this.featuresList, ["Suite"]);
	}

	private get isCustomFieldsEnabled(): boolean {
		return get(this.featuresList, ["CustomFields"]);
	}

	private get areasByTitle() {
		return invert(this.areaDictionary);
	}

	private get defaultFile() {
		const modalItems = this.getModalItems;
		const headers = modalItems.filter(({ csvExclude }) => !csvExclude).map(({ title }) => title);
		const body = headers.map( v => '');
		return {
			name: 'AreaTemplate.csv',
			data: `${headers.join()}\r\n${body.join()}`
		};
	}

	private get isDeleteConfirmed(): boolean {
		if(!this.areaToDelete || !this.areaToDelete.title)
			return false;

		return this.safeDeleteInput == `delete ${this.areaToDelete.title}`
	}

	private get isBulkAddEnabled(): boolean {
		return get(this.featuresList, ["Areas", "BulkAdd"]);
	}

	public async refresh() {
		try {
			this.fetchTenantOverview();

			let currentFocus = document.activeElement.id;
			this.isLoading = true;
			this.selectedArea = await api.getArea(this.selectedArea.groupID, true);
			this.selectedArea.location = {
				mapAreaRegion: null,
				latLong: this.selectedArea.latLong,
				address: this.selectedArea.address,
			};
			await this.loadChildGroups();

			let currentFocusElement = document.getElementById(currentFocus);
			if (currentFocusElement) {
				currentFocusElement.focus();
			}

			await this.fetchAreaDictionary();
		} catch (ex) {
			console.log("Unexpected exception refreshing area table: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	@Watch("selectedArea", { deep: true })
	private async onSelectedAreaUpdated(): Promise<void> {
		this.visibleAreas = [];
		if (this.selectedArea?.children && this.selectedArea?.children.length > 0) {
			this.visibleAreas = [...this.selectedArea?.children];
		}
		let selectedAreaClone = cloneDeep(this.selectedArea);
		delete selectedAreaClone.children;
		selectedAreaClone.location = {
			mapAreaRegion: null,
			latLong: selectedAreaClone.latLong,
			address: selectedAreaClone.address,
		}

		this.visibleAreas.push(selectedAreaClone);

		if (this.isAreaRegionsEnabled) {
			const region = await this.getRegionForArea(selectedAreaClone.groupID);
			Vue.set(selectedAreaClone.location, "mapAreaRegion", region);
		}
	}

	private async mounted(): Promise<void> {
		this.tableHeight = this.calculateTableHeight();

		if (this.isCustomFieldsEnabled){
			await this.retrieveCustomFields({ tableType: this.customFieldTableType, live: true });
		}

		await this.loadInitialState();
		this.debounceUpdateSearchFilter = debounce(() => {
			this.updateSearchFilter();
		}, 250);
		window.addEventListener('resize', () => {
			this.tableHeight = this.calculateTableHeight();
		});

		if (this.isManualTourActionPlanEnabled) {
			this.actionPlans = await api.loadActionPlans();
		}
	}

	private async loadInitialState() {
		try {
			this.isLoading = true;

			await this.fetchAreaDictionary();
			this.groupsWithEditPermissions = await api.groupsWithPermission(this.editPermissions);
			await this.getTimeZones();

			if (this.timeZoneList) {
				// get timezones and map to the format needed for v-select
				this.timeZonesFormatted = this.timeZoneList.map(t => {
					return { timeZoneID: t.timeZoneID, title: t.id };
				});
			}

			this.areaTreePath = [{
				groupID: -1,
				title: "Loading...",
				timeZoneID: 0,
				groupTypeID: 1,
			}];

			const rootGroupsPagedResponse = await api.getRootGroups(this.viewPermissions, this.selectedPage, parseInt(this.resultsPerPage));
			this.selectedArea.totalRecords = rootGroupsPagedResponse.totalRecords;
			Vue.set(this.selectedArea, 'children', rootGroupsPagedResponse.data);
			this.areaTreePath.pop();

			// if there's only one root group, select it and load its children.
			if (this.selectedArea.totalRecords === 1) {
				this.selectedArea = {
					...this.selectedArea.children[0],
					totalRecords: 0,
					location: {
						mapAreaRegion: null,
						latLong: this.selectedArea.children[0].latLong,
						address: this.selectedArea.children[0].address,
					}
				};
				this.areaTreePath.push({...this.selectedArea});
				await this.loadChildGroups();
			}
		} catch (ex) {
			console.log("Unexpected exception on initial load: " + ex);
		} finally {
			this.isLoading = false;
		}

		this.currentAreaTreePathHistoryIndex = this.areaTreePath.length - 1;
		this.areaTreePathHistory.push([...this.areaTreePath]);
		this.selectionHistory.push({...this.selectedArea});
	}

	private async loadArea(areaId: number) {
		try {
			if (this.selectedArea.groupID === areaId) { return;}
			this.isLoading = true;
			let groupResponse = await api.loadGroup(areaId);
			delete groupResponse.keyContacts;
			this.selectedArea = groupResponse;
			this.selectedArea.location = {
				mapAreaRegion: null,
				latLong: this.selectedArea.latLong,
				address: this.selectedArea.address
			}

			this.areaTreePath = [...this.selectedAreaPath];
			await this.loadChildGroups();

			if (this.currentAreaTreePathHistoryIndex < this.areaTreePathHistory.length - 1) {
				this.areaTreePathHistory.splice(this.currentAreaTreePathHistoryIndex + 1, this.areaTreePathHistory.length - this.currentAreaTreePathHistoryIndex);
			}

			if (this.currentAreaTreePathHistoryIndex < this.selectionHistory.length - 1) {
				this.selectionHistory.splice(this.currentSelectionHistoryIndex + 1, this.selectionHistory.length - this.currentSelectionHistoryIndex);
			}

			this.selectionHistory.push({...this.selectedArea});
			this.areaTreePathHistory.push([...this.areaTreePath]);

			this.currentAreaTreePathHistoryIndex = this.areaTreePathHistory.length - 1;
			this.currentSelectionHistoryIndex = this.selectionHistory.length - 1;

			await this.updateFocus();
		} catch (ex) {
			console.log("Unexpected error loading area: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private async loadHome() {
		try {
			this.isLoading = true;

			this.areaTreePath = [{
				groupID: -1,
				title: "Loading...",
				timeZoneID: 0,
				groupTypeID: 1
			}];

			const rootGroupsPagedResponse = await api.getRootGroups(this.viewPermissions, this.selectedPage, parseInt(this.resultsPerPage));
			this.selectedArea.totalRecords = rootGroupsPagedResponse.totalRecords;
			Vue.set(this.selectedArea, 'children', rootGroupsPagedResponse.data);
			this.areaTreePath.pop();

			// if there's only one root group, select it and load its children.
			if (this.selectedArea.totalRecords === 1) {
				this.selectedArea = {
					...this.selectedArea.children[0],
					totalRecords: 0,
					location: {
						mapAreaRegion: null,
						latLong: this.selectedArea.children[0].latLong,
						address: this.selectedArea.children[0].address,
					}
				};
				this.areaTreePath.push({...this.selectedArea});
				await this.loadChildGroups();
			}

			this.areaTreePathHistory.push([...this.areaTreePath]);
			this.selectionHistory.push({...this.selectedArea});

			this.currentAreaTreePathHistoryIndex = this.areaTreePathHistory.length - 1;
			this.currentSelectionHistoryIndex = this.selectionHistory.length - 1;

			await this.updateFocus();
		} catch (ex) {
			console.log("Unexpected exception loading home areas: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private async updateSearchFilter() {
		if (this.resultsPerPageUpdated !== this.resultsPerPage) {
			this.selectedPage = 1;
		}
		this.resultsPerPage = this.resultsPerPageUpdated;
		await this.loadChildGroups();
	}

	@Watch("searchQuery")
	private onSearchQueryChanged() {
		this.debounceUpdateSearchFilter();
	}

	private async onAreaSelected(item, updateAreaTreePath: boolean = true) {
		if (!item.isParent) {
			const isReadOnly = !this.groupsWithEditPermissions.includes(item.groupID);
			this.$refs.genericUpdateModal.showUpdateDialog(item, isReadOnly, false);
			return;
		}

		this.selectedArea = item;
		if (item.groupID === 0) {
			await this.loadRootGroups();
		} else {
			await this.loadChildGroups();
		}

		if (updateAreaTreePath) {
			this.areaTreePath.push({...item, children: []});
		}

		if (this.currentAreaTreePathHistoryIndex < this.areaTreePathHistory.length - 1) {
			this.areaTreePathHistory.splice(this.currentAreaTreePathHistoryIndex + 1, this.areaTreePathHistory.length - this.currentAreaTreePathHistoryIndex);
		}

		if (this.currentSelectionHistoryIndex < this.selectionHistory.length - 1) {
			this.selectionHistory.splice(this.currentSelectionHistoryIndex + 1, this.selectionHistory.length - this.currentSelectionHistoryIndex);
		}

		this.areaTreePathHistory.push([...this.areaTreePath]);
		this.selectionHistory.push({...this.selectedArea});
		this.currentAreaTreePathHistoryIndex = this.areaTreePathHistory.length - 1;
		this.currentSelectionHistoryIndex = this.selectionHistory.length - 1;

		await this.updateFocus();
	}

	private async loadRootGroups() {
		try {
			this.isLoading = true;
			const rootGroupsPagedResponse = await api.getRootGroups(this.viewPermissions, this.selectedPage, parseInt(this.resultsPerPage));
			this.selectedArea.totalRecords = rootGroupsPagedResponse.totalRecords;
			Vue.set(this.selectedArea, 'children', rootGroupsPagedResponse.data);
		}
		catch (ex) {
			console.log("Unexpected error loading root groups: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private async loadChildGroups() {
		try {
			this.isLoading = true;
			const getChildGroupsListRequest: GetChildGroupsListRequest = {
				page: this.selectedPage,
				pageSize: parseInt(this.resultsPerPage),
				requiredPermissions: this.viewPermissions,
				groupId: this.selectedArea.groupID,
				searchTerm: this.searchQuery,
				sortBy: this.sortByColumn,
				sortDesc: this.sortDirection === 'descending'
			};
			const childGroupsPagedResponse: GroupRowPagedResponse = await api.getChildGroups(getChildGroupsListRequest);
			this.selectedArea.totalRecords = childGroupsPagedResponse.totalRecords;
			Vue.set(this.selectedArea, 'children', childGroupsPagedResponse.data);
		}
		catch (ex) {
			console.log("Unexpected error loading root groups: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	@Watch("areaTreePath")
	private async onAreaPathChanged() {
		this.searchQuery = "";
		this.selectedAreaPath = this.areaTreePath;

		if (this.isLoading) {
			return;
		}

		if (this.areaTreePath.length === 1 && this.areaTreePath[0].groupID === -1 && this.areaTreePath[0].title === "My Areas") {
			await this.loadInitialState();
			return;
		}

		if (this.areaTreePath === this.areaTreePathHistory[this.currentAreaTreePathHistoryIndex]) {
			return;
		}

		if (this.areaTreePath.length === 1 && this.areaTreePath[0].groupID === -1) {
			return;
		}

		const currentArea = this.areaTreePath[this.areaTreePath.length - 1];

		if (this.selectedArea.groupID !== currentArea.groupID) {
			await this.onAreaSelected(currentArea, false);
		}
	}

	private calculateTableHeight() {
		let result = document.body.clientHeight - 300;
		return `${result}px`;
	}

	private async handleShortcut(event) {
		switch (event.srcKey) {
			case "goBack": {
				await this.goBack();
				return;
			}
			case "navigateBackward": {
				await this.goBack();
				return;
			}
			case "navigateForward": {
				await this.goForward();
				return;
			}
			default:
				return;
		}
	}

	private async goBack() {
		if (this.currentAreaTreePathHistoryIndex > 0) {
			this.currentAreaTreePathHistoryIndex--;
			this.areaTreePath = this.areaTreePathHistory[this.currentAreaTreePathHistoryIndex];
		}

		if (this.currentSelectionHistoryIndex > 0) {
			this.currentSelectionHistoryIndex--;
			this.selectedArea = this.selectionHistory[this.currentSelectionHistoryIndex];
		}
	}

	private async goForward() {
		if (this.currentAreaTreePathHistoryIndex < this.areaTreePathHistory.length - 1) {
			this.currentAreaTreePathHistoryIndex++;
			this.areaTreePath = this.areaTreePathHistory[this.currentAreaTreePathHistoryIndex];
		}

		if (this.currentSelectionHistoryIndex < this.selectionHistory.length - 1) {
			this.currentSelectionHistoryIndex++;
			this.selectedArea = this.selectionHistory[this.currentSelectionHistoryIndex];
		}
	}

	private async updateFocus() {
		await this.$nextTick();
		if (this.$refs.row0[0] && this.$refs.row0[0].$el) {
			this.$refs.row0[0].$el.focus();
		} else {
			this.$refs.selectedArea.$el.focus();
		}
	}

	private async onAddNewArea() {
		this.$refs.genericUpdateModal.showUpdateDialog({ }, false, true);
	}

	private async saveNewArea(area: GroupRowDetails) {
		try {
			this.isLoading = true;

			const groupRow = this.groupRowDetailsToGroupRow(area);

			let newAreaId = await api.addArea(groupRow);

			if (this.isAreaRegionsEnabled) {
				area.location.mapAreaRegion.groupId = newAreaId;
				await api.createOrUpdateRegion(area.location.mapAreaRegion);
			}
			this.groupsWithEditPermissions.push(newAreaId);
		} catch(ex) {
			console.log("Error saving area", ex)
		}
		await this.refresh();
	}

	private async updateArea(area: GroupRowDetails) {
		try {
			this.isLoading = true;

			const groupRow = this.groupRowDetailsToGroupRow(area);
			await api.updateArea(groupRow);
			if (this.isAreaRegionsEnabled) {
				await api.createOrUpdateRegion(area.location.mapAreaRegion);
			}
			await this.refresh();
		} catch(ex) {
			console.log("Error updating area", ex)
		}
		await this.refresh();
	}

	private groupRowDetailsToGroupRow(area: GroupRowDetails): GroupRow {
		return {
			groupID: area.groupID,
			address: this.isAreaLocationEnabled ? area.location.address : area.address,
			latLong: this.isAreaLocationEnabled ? area.location.latLong : area.latLong,
			armed: area.armed,
			parentGroupID: area.parentGroupID,
			groupTypeID: area.groupTypeID,
			title: area.title,
			telephone: area.telephone,
			telephone2: area.telephone2,
			state: area.state,
			telephoneFire: area.telephoneFire,
			telephonePolice: area.telephonePolice,
			notes: area.notes,
			timeZoneID: area.timeZoneID,
			scriptId: area.scriptId,
			referenceId: area.referenceId,
			isParent: area.isParent,
			children: area.children,
			disabledAt: area.disabledAt,
			maskDurationLimit: area.maskDurationLimit,
		};
	}

	private async bulkOnSubmit({ formData }) {
		// Reset any potential previous bulk values
		this.bulkNestedLevels = [];
		this.batchCurrentValue = 0;
		this.bulkUploadResults = [];

		// Convert and sort formData
		const convertedData = this.convertDataToRest({ body: formData });
		const sortedData = this.sortCsvData(convertedData);
		await this.createAreasFromCsv(sortedData);
		await this.refresh();
	}

	private convertDataToRest({ body }) {
		return body
			.map((value) => ({
				...value,
				scriptId: this.actionPlans.find(ap => ap.title == value.scriptId)?.scriptID,
				timeZoneID: this.timeZoneList.find(tz => tz.id === value.timeZoneID).timeZoneID,
			}));
	}

	/*
		Create an array of areas from the form in the following format - [[All Parents], [All Children], [All Grandchildren]] ...etc
		This will allow us to loop through each nested level when uploading, allowing permissions to update for uploading children

		At this point, the parentGroupID property is a NAME, until it is overwritten by areasByTitle once uploading begins where it gets the ID by the NAME
	*/
	private createAreaBatches(areas: any[], nestedLevel: string, depth: number): void {
		const children = areas.filter(a => a.parentGroupID === nestedLevel);
		if (children.length > 0) {
			// Add the current children to the bulkNestedLevels array where the index is the depth of the children
			this.bulkNestedLevels[depth] = this.bulkNestedLevels[depth] ? this.bulkNestedLevels[depth].concat(children) : children

			for (let i = 0; i < children.length; i++) {
				this.createAreaBatches(areas, children[i].title, depth + 1)
			}
		}
	}

	// Finds all areas with existing parents and sorts the children
	private sortCsvData(csvForm: any[]): any[] {
		const areasWithExistingParents = csvForm.filter(a => this.areasByTitle[a.parentGroupID] !== undefined);
		this.bulkNestedLevels.push(areasWithExistingParents);

		for(let i = 0; i < areasWithExistingParents.length; i++) {
			this.createAreaBatches(csvForm, areasWithExistingParents[i].title, 1);
		}

		// Return the original form to retain the length
		return csvForm;
	}

	// Uploads areas in batches for a nested level
	private async uploadBatch(areas: any[], batchSize: number): Promise<void> {
		for (let i = 0; i < areas.length; i += batchSize) {

			// Create a batch from the current list of areas
			const batch = areas.slice(i, i + batchSize);

			// Upload batch of areas
			const uploadResult = await api.bulkAddAreas(batch);

			// Assign any failed uploads
			this.bulkUploadResults = [...this.bulkUploadResults, ...uploadResult];
		}
	}

	private async createAreasFromCsv(areaList: any[]): Promise<void> {
		// Get total areas count to upload
		const areaListCount = areaList.length;

		// Set 100% as the max progress
		const max = 100;

		// Set default progress
		const defaultProgress = {
			max,
			status: 'init',
			successfulMessage: `Successfully added ${areaListCount} Areas`,
			percentage: true
		};

		// progressBulk is passed all the way down to the csv-form so when this changes, the csv-form progress bar updates
		this.progressBulk = {
			...defaultProgress
		};

		const batchSize = 40;

		// Set progress to start with
		let progressOptions = {
			max: max,
			rowIndex: 0,
			percentage: true
		}

		// Upload areas by each nested level and refresh the groupCache each level to avoid permission issues
		for(let i = 0; i < this.bulkNestedLevels.length; i++) {

			// Assign each area's parentGroupID in this level before attempting upload
			for(let j = 0; j < this.bulkNestedLevels[i].length; j++)
			{
				// Increment batch value here so we can show the percentage of how many items have been uploaded out of the total
				this.batchCurrentValue++;
				const currentArea = this.bulkNestedLevels[i][j];
				currentArea.parentGroupID = this.areasByTitle[currentArea.parentGroupID];
			}

			// Upload current nested level
			await this.uploadBatch(this.bulkNestedLevels[i], batchSize);

			// Get the current percentage to display in the progress bar
			const currentValue = this.batchCurrentValue > areaListCount ? areaListCount : this.batchCurrentValue;
			const percentage = Math.floor((currentValue / areaListCount) * 100);
			progressOptions.rowIndex = percentage;
			this.progressBulk = {
				...progressOptions,
				status: 'progress',
			};

			// Refresh the group cache after the bulk upload
			await api.refreshGroupCache();
			// Update areaDictionary for assigning child areas parentGroupIDs
			await this.fetchAreaDictionary();
			// Refresh groupWithEditPermissions
			this.groupsWithEditPermissions = await api.groupsWithPermission(this.editPermissions);
		}

		// Check for areas that failed to upload
		if (this.bulkUploadResults.length > 0) {
			let bulkErrorMessage = [];

			// Configure the returned errors
			for (let i = 0; i < this.bulkUploadResults.length; i++) {
				const errorGroup: GroupErrorDetails = {
					rowId: this.bulkUploadResults[i].rowId,
					errorMessage: `Area - '${this.bulkUploadResults[i].title}' failed. Error - ${this.bulkUploadResults[i].csvError}.`
				};

				bulkErrorMessage.push(errorGroup);
			}

			// Send errors to the csv-form to display
			this.progressBulk = {
					...progressOptions,
					status: 'error',
					error: {
						name: "One or more Areas failed to upload",
						message: `Failed to add ${this.bulkUploadResults.length} areas out of ${areaListCount}.`
					},
					bulkErrors: bulkErrorMessage
			};
		} else {
			progressOptions.rowIndex = max;
			this.progressBulk = {
				...progressOptions,
				status: 'succeed',
				successfulMessage: `Successfully added ${areaListCount} Areas.`
			};
		}

		// Refresh groupWithEditPermissions
		this.groupsWithEditPermissions = await api.groupsWithPermission(this.editPermissions);
	}

	private onCloseBulk() {
		this.progressBulk = {};
		this.bulkUploadResults = [];
		this.bulkNestedLevels = [];
		this.batchCurrentValue = 0;
	}

	private async onDeleteAreaSelected(area: GroupRow) {
		if (this.groupsWithEditPermissions.includes(area.groupID)) {
			this.isDeleteConfirmDialogShown = true;
			this.areaToDelete = area;
		}
	}

	private cancelDelete() {
		this.isDeleteConfirmDialogShown = false;
		this.areaToDelete = this.defaultGroupRow;
	}

	private confirmDelete() {
		if(this.isDeleteConfirmed)
		{
			this.isDeleteConfirmDialogShown = false;
			this.deleteArea();
		}
	}

	private async deleteArea() {
		try {
			this.isLoading = true;
			await api.deleteArea(this.areaToDelete.groupID);

			if (this.selectedArea.groupID === this.areaToDelete.groupID) {
				if (this.areaTreePath.length > 1) {
					await this.goBack();
					await this.refresh();
				} else {
					await this.loadInitialState();
				}
			} else {
				await this.refresh();
			}

			this.areaToDelete = this.defaultGroupRow;
		} catch (ex) {
			console.log("Unexpected error deleting area: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private async setSelectedAreaRow(area: GroupRowDetails): Promise<void> {
		this.parentIsRequired = area.groupTypeID !== 4;
		this.selectedAreaRow = Object.assign({}, this.selectedAreaRow, null);
		this.selectedAreaRow = area;
		this.selectedAreaRow.location = {
			mapAreaRegion: null,
			latLong: area.latLong,
			address: area.address,
		}

		if (!this.canEditGroupSyncAreas && area.groupSyncId) {
			this.selectedAreaRow.readOnlyMessage = "You do not have permission to edit sync areas"
			this.selectedAreaRow.readOnly = true;
		}
		else
		{
			this.selectedAreaRow.readOnly = false;
			this.selectedAreaRow.readOnlyMessage = null
		}

		if (this.isAreaRegionsEnabled) {
			const region = await this.getRegionForArea(area.groupID);
			Vue.set(this.selectedAreaRow.location, "mapAreaRegion", region);
		}

		if (this.isCustomFieldsEnabled) {
			mapArrayFieldsToObject(this.selectedAreaRow, this.selectedAreaRow.customFieldValues, this.areaCustomFields, "id", "value", "cf");
		}
	}

	private async onEditAreaSelected(area: GroupRowDetails) {
		await this.setSelectedAreaRow(area);
		this.$refs.genericUpdateModal.showUpdateDialog(this.selectedAreaRow, false, false);
	}

	private async onViewAreaSelected(area: any) {
		await this.setSelectedAreaRow(area);
		this.$refs.genericUpdateModal.showUpdateDialog(this.selectedAreaRow, true, false);
	}

	private openSelectedArea(selectedArea: GroupRowDetails) {
		if (this.groupsWithEditPermissions.includes(this.selectedArea.groupID)) {
			this.onEditAreaSelected(selectedArea);
		} else {
			this.onViewAreaSelected(selectedArea);
		}
	}

	private async getRegionForArea(groupId: number) : Promise<MapAreaRegion> {
		let region = await api.getRegionForGroup(groupId);
		//Set the group ID if we don't already have a region
		if(!region || !region.groupId)
			region.groupId = groupId;

		return region;
	}

	private removeNonDigitsFromString(value: string): string {
		if(!value)
			return "";

		const digitFind = value.match(/\d+/g);
		if(!digitFind)
			return "";

		if (parseInt(digitFind.join('')) > 200) {
			this.resultsPerPageUpdated = "200";
		}

		return digitFind.join('');
	}

	private thStylingForField(index: number): string {
		return (this.fields[index] && this.fields[index].thStyle) ? this.fields[index].thStyle : '';
	}

	// 1 column sorting
	private updateSorting(field: TableHeader): void {
		if (!field.sortable) {
			return;
		}
		const sortByColumn = field.sortKey ? field.sortKey : field.key;
		if (!this.sortByColumn) {
			this.sortByColumn = sortByColumn;
			this.sortDirection = "ascending";
			this.updateSearchFilter();
			return;
		}

		if (this.sortByColumn === sortByColumn) {
			this.sortDirection = this.sortDirection === "ascending" ? "descending" : "ascending";
		} else {
			this.sortDirection = this.sortDirection = "ascending";
		}
		this.sortByColumn = sortByColumn;

		this.updateSearchFilter();
	}

	private get canEditGroupSyncAreas(): boolean {
		return this.getPermissions.isSystemAdmin || this.getPermissions.canOverrideGroupSync;
	}

	private canEditArea(area: GroupRow): boolean {
		if (!area || !area.groupID) {
			return true;
		}

		return this.groupsWithEditPermissions.includes(area.groupID) && (area.groupSyncId ? this.canEditGroupSyncAreas : true);
	}

	private timeZoneForColumn(timeZoneId: number): string {
		try
		{
			let timeZone = this.timeZoneList.firstOrDefault(tz => tz.timeZoneID == timeZoneId);

			if (timeZone == null) {
				return "Failed to get Time Zone Information";
			}

			return timeZone.id;
		}
		catch (err)
		{
			return "Failed to get Time Zone Information";
		}
	}
}

