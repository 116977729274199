
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import GoogleMap from '@/scripts/mapping/map';
import { getMapStyle } from '@/scripts/mapping/mapStyle';
import getScript from '@/services/insertScript.service';
import { Getter } from 'vuex-class';
import VSwatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.css"
import MapAreaRegion from '@/types/sv-data/maps/MapAreaRegion';
import ILatLng from "@sureview/v2-mapping-saas";
import { mapRegionMixins } from '@/mixins'
import { AreaLocation } from "@/views/AreaSetup/AreaLocationSetup.vue";
import HideMapMixin from "@/mixins/HideMapMixin";

const {
    getPolygonOptionsForRegion,
    defaultPolygonOptions
} = mapRegionMixins.methods;

@Component({
    components: {
        "color-swatch" : VSwatches
    }
})
export default class AreaRegionSetup extends Mixins(HideMapMixin) {
    @Getter getMapType: any;
    @Getter getHideLabels: any;

    @Prop()
    private value: AreaLocation;

    @Prop()
    private areaLocation: any | null;

    @Prop({default: false, type:Boolean})
    private readonly: Boolean;

    public $refs!: {
        mappingContainer: HTMLElement;
    }

    private regionArea: MapAreaRegion = {
        regionId: -1,
        groupId: -1,
        tenantId: -1,
        path: null,
        borderColor: null,
        fillColor: null,
    };

    private drawRegion: boolean = false;
    private map: GoogleMap | null = null;
    private drawingManager: google.maps.drawing.DrawingManager | null = null;
    private regionPolygon: google.maps.Polygon | null = null;
    private mapLightMode: boolean = false;

    @Watch('drawRegion')
    private drawRegionWatch(): void {
        this.drawingManager!.setOptions({
            drawingControl: this.drawRegion,
            drawingMode: this.drawRegion ?
                google.maps.drawing.OverlayType.POLYGON :
                null
        });
    }

    private getPath(ploygon: google.maps.Polygon): ILatLng[] {
        return ploygon.getPath()
            .getArray()
            .map(point => {
                return { lat: point.lat(), lng: point.lng() };
            });
    }

    private renderRegion(): void {
        const polygonOptions = getPolygonOptionsForRegion(this.regionArea);
        this.regionPolygon = new google.maps.Polygon({
            map: this.map!.map,
            paths: JSON.parse(this.regionArea.path),
            ...polygonOptions
        });

         //Add listener when a new point is added
        google.maps.event.addListener(this.regionPolygon.getPath(), 'insert_at', () => {
            this.regionArea.path = JSON.stringify(this.getPath(this.regionPolygon));
        });

        //Add listener when a point is moved
        google.maps.event.addListener(this.regionPolygon.getPath(), 'set_at', () => {
            this.regionArea.path = JSON.stringify(this.getPath(this.regionPolygon));
        });

        var bounds = new google.maps.LatLngBounds();

        this.regionPolygon.getPaths().forEach(function(path) {
            path.forEach(function(latlng) {
                bounds.extend(latlng);
            });
        });

        this.map.map.fitBounds(bounds);
    }

    public async initMap() : Promise<void> {
        if (this.isHideMapsEnabled) {
            return;
        }

        await getScript(
            `https://maps.googleapis.com/maps/api/js?key=`
            + `${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
            + `&libraries=drawing,places,geometry`
        ).then(() => {
            this.map = new GoogleMap(
                this.$refs.mappingContainer,
               { lat: 0, lng: 0}, // start position
                5, // Zoom level
                false,
                this.getMapType,
                getMapStyle(this.getMapType, this.getHideLabels, this.mapLightMode ? 'light' : 'dark'),
            );

            //Display the google maps api zoom control as it doesn't like modals, instead we've got our own
            this.map.map.setOptions({scrollwheel: false, zoomControl: false});

            this.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: null,
                drawingControl: this.drawRegion,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ['polygon']
                },
                polygonOptions: defaultPolygonOptions
            } as any);

            this.drawingManager.setMap(this.map!.map);

            // on zone add
            google.maps.event.addListener(
                this.drawingManager,
                'polygoncomplete',
                (polygon: google.maps.Polygon) => {
                    this.drawRegion = false;
                    this.regionPolygon = polygon;
                    this.regionArea.path = JSON.stringify(this.getPath(polygon));

                    //Add listener when a new point is added
                    google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
                        this.regionArea.path = JSON.stringify(this.getPath(polygon));
                    });

                    //Add listener when a point is moved
                    google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
                        this.regionArea.path = JSON.stringify(this.getPath(polygon));
                    });
                }
            );
        });
    }

    private setPolygonEditState(editable: boolean) : void {
        if(!this.regionPolygon)
            return;

        this.regionPolygon.setEditable(editable);
    }

    private async mounted(): Promise<void> {
        await this.initMap();
        if (this.value && this.value.mapAreaRegion) {
			this.regionArea = {...this.value.mapAreaRegion}
		}

        if(!this.regionArea.borderColor){
            this.regionArea.borderColor = "#2196f3";
        }

        if(!this.regionArea.fillColor) {
            this.regionArea.fillColor = "#2196f3";
        }

        if(this.regionArea.path) {
            this.renderRegion();
        } else {
            this.setMapOnLocation();
        }

        this.mapLightMode = true;
    }

    @Watch("areaLocation")
    private setMapOnLocation(): void {
        if(!this.areaLocation || !this.areaLocation.lat || !this.map.map || (this.regionArea && this.regionArea.path))
            return;

        this.map.map.setCenter(this.areaLocation);
        this.map.map.setZoom(11);
    }

    @Watch("regionArea", { deep: true })
    private regionAreaUpdated(newRegion: MapAreaRegion | null, oldRegion: MapAreaRegion | null): void{
        if(!oldRegion || !this.drawingManager)
            return;

        let polygonOptions = getPolygonOptionsForRegion(newRegion)

        this.drawingManager.setOptions({
            polygonOptions: polygonOptions
        });

        if(!this.regionPolygon)
            return;

        this.regionPolygon.setOptions({...polygonOptions});
        this.$emit("input", {
			mapAreaRegion: this.regionArea
		});
	}


    private removeRegion(): void {
        if(this.regionPolygon){
            this.regionPolygon.setMap(null);
            this.regionPolygon = null;
            this.regionArea.path = null;
        }
    }

    @Watch("mapLightMode")
    private mapModeWatch(): void {
        if(!this.map || !this.map.map)
            return;

        const mapStyles = getMapStyle(this.getMapType, this.getHideLabels, this.mapLightMode ? "light" : "dark");

        this.map.map.setOptions({
            styles: mapStyles,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });
    }

    private zoom(value: number): void {
        if(!this.map || !this.map.map)
            return;

        let zoom = this.map.map.getZoom() + value;
        //Google maps api min zoom = 0, maxZoom = 22
        if(zoom < 0) zoom = 0;
        if(zoom > 22) zoom = 22;
        this.map.map.setZoom(zoom);
    }
}
