
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from 'vuex-class';

const Eventqueue = namespace('eventqueue');

@Component({
    components: {
    }
})
export default class ProcessingError extends Vue {

    @Eventqueue.Getter getProcessingError: any;
    @Eventqueue.Mutation setProcessingError: any;

    @Watch("getProcessingError")
    onProcessingErrorChange(value: string, oldValue: string) {
        if (value) {
            setTimeout(() => {
                this.setProcessingError(null);
            }, 5 * this.$config.ANIMATION_DURATION);
        }
    }

}

