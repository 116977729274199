import { MutationTree } from "vuex";
import { AlarmMaskingState, Response } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<AlarmMaskingState> = {
	setGroupId(state, groupId: number) {
		state.groupId = groupId;
	},
	setResponses(state, responses: Response[]) {
		state.responses = responses;
	},
	resetState(state) {
		Object.assign(state, getDefaultState());
	},
	setMaxAlarmsToShow(state, maxCount: number) {
		if(maxCount !== null && maxCount >= 200) {
			state.maxAlarmsToShow = maxCount;
		}
	},
};
