import { GetterTree } from "vuex";
import { AreaNotesState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<AreaNotesState, RootState> = {
	getAreaNotes: state => state.areaNotes,
	getAreaNotesCount: state => state.areaNotes.length,
	getCurrentGroupId: state => state.currentGroupId,
	getCurrentGroupTitle: state => state.currentGroupTitle,
	getIsFormShown: state => state.isFormShown,
	getAreaNoteToEdit: state => state.areaNoteToEdit,
	getIsEditing: state => state.isEditing
};
