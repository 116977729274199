import { MobileRaiseState } from "./types";

export default function getDefaultState(): MobileRaiseState {
	return {
		templateProjections: [],
		currentTemplateProjectionIdx: 0,
		lastUsedTemplateId: null,
		isProjectionsLoaded: false
	};
}
