import { CustomFieldDto, CustomFieldTypes } from "@/store/custom-fields/types";
// @ts-ignore
import { ModalItem } from "@/components/table/generic-update-modal.vue";

export function MapCustomFieldToModalItem(field: CustomFieldDto, modalItem: ModalItem): ModalItem {
	const newModalItem: ModalItem = {
		key: "cf" + field.id.toString(),
		title: field.title,
		dataType: CustomFieldTypes[field.customFieldTypeId.toString()].toLowerCase(),
		maxLength: field.customFieldTypeId == CustomFieldTypes.Text ? 1000 : field.customFieldTypeId == CustomFieldTypes.TextArea ? 10000 : 10,
		min: field.customFieldTypeId == CustomFieldTypes.Number ? -2147483647 : 10,
		max: field.customFieldTypeId == CustomFieldTypes.Number ? 2147483647 : 10,
		order: modalItem.order + (field.order != null ? field.order : 999),
		placeholder: field.placeholder ? field.placeholder : modalItem.placeholder,
		required: field.required,
		readOnly: modalItem.readOnly
	}

	return newModalItem
}
