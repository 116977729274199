
import { Component, Vue } from "vue-property-decorator";
import api from "@/services/api.service";

@Component({
	components: {},
})
export default class ClearMFA extends Vue {
    public clearAuthCode: string = "";
	public mfaError: string = "";

    private async clearSecret(authCode: string) {
    	try {
			await api.clearMfa(authCode);
		} catch (ex) {
			this.mfaError = ex.response.message;
		}
    }
}
