
import { Component, Emit, Prop, Vue, } from "vue-property-decorator";
import { Rule } from "@/store/rules-engine/types";
import { namespace } from "vuex-class";

const RulesEngineStore = namespace("rulesEngine")

@Component({})
export default class RulesEngineDeleteModal extends Vue {
	@Prop({required: true}) rule: Rule;
	@Prop({ default: false, type: Boolean }) showModal: boolean;

	@RulesEngineStore.Action deleteRule: (ruleId: number) => Promise<void>;

	@Emit("closeDeleteModal")
	private closeModal(): void {}

	private async removeRule(): Promise<void> {
		await this.deleteRule(this.rule.ruleId);
		this.closeModal();
	}
}
