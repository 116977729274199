import { MutationTree } from "vuex";
import {
	RootState,
	KeyboardShortcut,
	FeaturesList,
	TimeZone,
	LoginResult,
	UserPermissions,
	ElevationLabelType,
	AppOptions,
	TenantSystemPrefs
} from "./types";
import { getDefaultState } from "./state";
import { get } from "lodash";
import api from "@/services/api.service";

export const mutations: MutationTree<RootState> = {
	login(state, loginResult: LoginResult) {
		state.dataError = false;
		state.User = {
			loggedIn: true,
			fullName: loginResult.fullName,
			userId: loginResult.userId,
			username: loginResult.username,
			userGroupId: loginResult.groupId,
			error: "",
			isFullUser: true,
			userPermissions: loginResult.permissions,
			userPrefs: loginResult.userPrefs,
			isFirstLogin: loginResult.isFirstLogin,
			tenantGroupId: loginResult.tenantGroupId,
			suiteTenantId: loginResult.suiteTenantId,
			suiteUserId: loginResult.suiteUserId,
			suiteResponseLicenced: loginResult.suiteResponseLicenced,
			suiteCasesLicenced: loginResult.suiteCasesLicenced,
			mobileAppEnabled: loginResult.mobileAppEnabled
		};
		state.systemPrefs = loginResult.systemPrefs;
		state.featuresList = loginResult.features;
		state.tenantSystemPrefs = loginResult.tenantSystemPrefs;
	},
	logout(state) {
		state.User = getDefaultState().User;
		state.UserGroups = [];
	},
	// Stores the local login before we login to a different region
	storeLocalLogin(state) {
		state.localUserData = state.User;
	},
	setAppInitialisedFlag(state, status: boolean) {
		state.appInitialised = status;
	},
	setDataError(state, hasError) {
		state.dataError = hasError;
	},
	setUserGroups(state, groups) {
		state.UserGroups = groups;
	},
	setShortcuts(state, shortCuts: KeyboardShortcut[]) {
		// Filter out any shortcuts that may be disabled via feature flagging
		const validShortcuts = shortCuts.filter(sc => {
			// if the shortcut has no feature flag, always include it
			if (!sc.featureFlag) {
				return true;
			} else {
				return get(state.featuresList, sc.featureFlag, false);
			}
		});

		state.keyboardShortcuts = validShortcuts;
	},
	setLegendKeys(state, legends) {
		state.legendKeys = legends;
	},
	setRoutingGroupsLegend(state, show: boolean) {
		state.showRoutingGroupsLegend = show;
	},
	setTimeZoneAbbr(state, timeZoneAbbr: string) {
		state.timeZoneAbbr = timeZoneAbbr;
	},
	setMapType(state, mapType: string) {
		state.systemPrefs.mapType = mapType;
	},
	setMapStyle(state, mapStyle: string){
		state.mobilePrefs.mapStyle = mapStyle;
	},
	setRoutingGroups(state, routeGroups) {
		state.routingGroups = routeGroups;
	},
	setActiveRegion(state, { regionId, regionUrl }) {
		state.activeRegion.regionId = regionId;
		state.activeRegion.regionUrl = regionUrl;
		api.setBaseApiUrl(regionUrl);
	},
	setUserAlarmScreenTimeoutSeconds(state, alarmScreenTimeoutSeconds) {
		state.systemPrefs.alarmScreenTimeoutSeconds = alarmScreenTimeoutSeconds;
	},
	setAppVersion(state, version: string) {
		state.version = version;
	},
	setFeaturesList(state, config: FeaturesList) {
		state.featuresList = config;
	},
	setTenantSystemPrefs(state, prefs: TenantSystemPrefs){
		state.tenantSystemPrefs = prefs
	},
	setTimeZones(state, config: TimeZone[]) {
		state.timeZones = config;
	},
	setElevationLabels(state, labels: ElevationLabelType[]) {
		state.elevationLabels = labels;
	},
	setisFirstLogin(state, newValue) {
		state.User.isFirstLogin = newValue;
	},
	setAppOptions(state, options: AppOptions)
	{
		state.appOptions = options;
	},
	setRequiresConfiguration(state, requiresConfiguration: boolean)
	{
		state.requiresConfiguration = requiresConfiguration;
	},
	hideSysAdminAccessTimerModal(state) {
		state.isSystemAdminAccessTimeModalHidden = true;
	},
	resetState(state) {

		let { appOptions, requiresConfiguration } = state;

		if (appOptions !== null && typeof appOptions !== "undefined") {
			let appOptionsCopy;
			let requiresConfigCopy;
			Object.assign(appOptionsCopy, appOptions);
			Object.assign(requiresConfigCopy, requiresConfiguration);
			state.appOptions = appOptionsCopy;
			state.requiresConfiguration = requiresConfigCopy;
		}

		Object.assign(state, getDefaultState());
	},
	setReloadEventQueue(state, reloadEventQueue: boolean) {
		state.reloadEventQueue = reloadEventQueue;
	}
};
