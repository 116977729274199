
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { MobileRaiseProjection } from "@/store/mobile-raise/types";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Title from "./Title.vue";

@Component({
	components: {
		"raise-title": Title,
		"vue-perfect-scrollbar": VuePerfectScrollbar
	}
})
export default class SearchSelect extends Vue {
	@Prop() raises: MobileRaiseProjection[];
	@Prop() searchTerm: string;

	get filteredRaises() {
		return this.searchTerm ? this.raises.filter(this.raisePredicate) : this.raises;
	}

	raisePredicate(r: MobileRaiseProjection) {
		return r.titleList.some(t => t.toLowerCase().includes(this.searchTerm.toLowerCase()));
	}
}
