export const downsample = (
    buffer: Float32Array,
    sampleRate: number,
    outSampleRate: number
): Int16Array | Float32Array => {
if (outSampleRate === sampleRate) return buffer;

if (outSampleRate > sampleRate) throw "new sample rate needs to be smaller than the original rate";

const sampleRateRatio = sampleRate / outSampleRate;
let result = new Int16Array(Math.round(buffer.length / sampleRateRatio));

// move through the buffer X elements at a time based on the ratio, taking the average of those X elements
// i.e. 48kHz is 6 times bigger than 8kHz, so take 6 samples from the incoming and average them
// to make 1 for the outgoing
let offsetResult = 0;
let offsetBuffer = 0;
let nextOffsetBuffer = 0;
let accum = 0;
let count = 0;
let i;
while (offsetResult < result.length) {
    nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    accum = 0;
    count = 0;
    for (i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i += 1) {
        accum += buffer[i];
        count += 1;
    }

    result[offsetResult] = Math.min(1, accum / count) * 0x7FFF;
    offsetResult += 1;
    offsetBuffer = nextOffsetBuffer;
}
return result;
}