import { camelCase } from "lodash";

const objectToCamelCase = obj => {
	if (Array.isArray(obj)) {
		return obj.map(v => objectToCamelCase(v));
	} else if (obj != null && obj.constructor === Object) {
		return Object.keys(obj).reduce(
			(result, key) => ({
				...result,
				[camelCase(key)]: objectToCamelCase(obj[key]),
			}),
			{},
		);
	}
	return obj;
};

export default objectToCamelCase;