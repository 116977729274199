
import { Component, Ref, Vue, Emit, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { CameraPreviewDeviceDetails } from "@/store/devices/types";
import { ResourceType, UpdateSessionParams } from "@/store/sessions/types";
import api from "@/services/api.service";

const Sessions = namespace("sessions");

@Component({})
export default class CameraPreview extends Vue {
	@Prop({ default: null, required: false }) previewDetails: CameraPreviewDeviceDetails;

	@Sessions.Action private updateSession: (params: UpdateSessionParams) => Promise<void>;
	@Sessions.Getter private getSession: (resourceId: number) => any;

	@Ref() private cameraControl: any;

	private isVisible: boolean = true;
	private deviceServiceAddress: string = "";
	private deviceTitle: string = "";

	@Emit("hidden")
	private handleModalHidden(): void {
		this.cameraControl.stop();
	}

	private async created(): Promise<void> {
		this.deviceServiceAddress = api.getDeviceServiceAddress();
	}

	private async mounted(): Promise<void> {
		await this.updateSession({ resourceId: ResourceType.Proxy });
		this.deviceTitle = this.previewDetails.title;
		this.cameraControl.startCamera(this.previewDetails.deviceId);
	}

	private get title(): string {
		return `Viewing camera "${this.deviceTitle}"`;
	}
}
