import { Component, Vue } from "vue-property-decorator";
import { AudioDevice, AudioTypes, AudioX, DeviceSpec } from "@/types/audio/audio";

/**
 * Mixin which adds helpers to construct the audio address to stream/transmit audio
 */
@Component({})
export default class AudioAddressMixin extends Vue {
    public audioOptionTypes: any = AudioTypes;
    public auth: string = "";

    /**
     * @summary method which constructs the websocket url depending on if its secure or unsecure
     * and the type of audio
     */
    public constructAddress(deviceServiceEndpoint: string, audioType: AudioX, audioDevice: AudioDevice): string {
        if (!audioDevice) {
            return "";
        }

        // What socket type we gonna use ws / wss
        const secure = location.protocol === "https:";
        return `${secure ? "wss" : "ws"}://${deviceServiceEndpoint}/devices/${audioType}?auth=${this.auth}&deviceId=${audioDevice.deviceId}&eventRecordId=${audioDevice.eventRecordId}`;
    }
}