
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { clone } from 'lodash';
import { CardValidation } from "@/store/rules-engine/types";

@Component({})
export default class RulesNumberInput extends Vue {
    @Prop({type: [String, Number]})
    private value: number | string;

	@Prop({type: String})
    private placeholderText: string;

	@Prop({type: Number})
    private minValue: number;

	@Prop({type: Number})
    private maxValue: number;

	@Prop({type: Boolean})
	private allowDecimal: boolean;

	@Prop({type: Boolean})
	private allowNulls: boolean;

	@Prop({type: Boolean})
	private readOnly: boolean;

    private inputNumber: number = null;

	private inputValidation: CardValidation = { isValid: false, message: ""};

    private mounted(): void {
		this.inputNumber = this.checkValueType();
        this.valueUpdate();
    }

	private checkValueType(): number {
		if (typeof this.value === "string") {
			return parseInt(this.value);
		} else {
			return clone(this.value);
		}
	}

	private get minValueValid(): boolean {
		if (this.minValue) {
			return this.inputNumber ? this.inputNumber >= this.minValue : false;
		}
		return true;
	}

	private get maxValueValid(): boolean {
		if (this.maxValue) {
			return this.inputNumber ? this.inputNumber <= this.maxValue : false;
		}
		return true;
	}

	private get inputValid(): boolean {
		if (this.allowNulls && this.inputNumber?.toString().length < 1) {
			return true;
		} else {
			return (this.minValueValid && this.maxValueValid && this.inputNumber?.toString().length > 0);
		}
	}

	@Watch("inputNumber")
    @Emit("input")
    private valueUpdated(value: string): number
    {
        this.validateInput();
        return value ? parseInt(value) : null;
    }

    @Watch("value")
    private valueUpdate(): void
    {
        this.inputNumber = this.value ? this.checkValueType() : null;
        this.validateInput();
    }

	@Emit("isValid")
    private validateInput(): CardValidation
    {
		if(this.inputValid){
			this.inputValidation.isValid = true;
            return this.inputValidation;
        } else {
			this.inputValidation.isValid = false;
			this.inputValidation.message = "*Field must contain a valid value";
			return this.inputValidation;
		}
	}

	private numberInputFormatter(value): number {
		if (value < this.minValue || value > this.maxValue) {
			return null;
		}

		return value;
	}
}
