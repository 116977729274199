import { MutationTree } from "vuex";
import { SetupState } from "./types";
import { getDefaultState } from "./state";

export const mutations: MutationTree<SetupState> = {
	setAllFeatures(state, features: any[]) {
		state.allFeatures = features;
	},
	setActionPlans: (state: SetupState, actionPlans: any[]) => state.actionPlans = actionPlans,
	setResponseAlarmTypes: (state: SetupState, responseAlarmTypes: any[]) => state.responseAlarmTypes = responseAlarmTypes,
	setCurrentAreas: (state: SetupState, areas: Set<number> | null) => state.currentAreas = areas,
	resetState(state) {
		Object.assign(state, getDefaultState());
	}
};